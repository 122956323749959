import React, { createContext, useContext, useReducer } from "react";

const ReservationContext = createContext();
const ReservationDispatchContext = createContext();
const initialState = {};

function reservationReducer(state, action) {
	switch (action.type) {
		case "applicationOwners": {
			return { ...state, applicationOwner: action.payload };
		}
		case "accounting": {
			return { ...state, accounting: action.payload };
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useReservationState() {
	const context = useContext(ReservationContext);
	if (context === undefined) {
		throw new Error(
			"useReservationState must be used within a ReservationProvider"
		);
	}
	return context;
}

function useReservationDispatch() {
	const context = useContext(ReservationDispatchContext);
	if (context === undefined) {
		throw new Error(
			"ReservationDispatchContext must be used within a ReservationProvider"
		);
	}
	return context;
}

function ReservationProvider({ children }) {
	const [state, dispatch] = useReducer(reservationReducer, initialState);
	return (
		<ReservationContext.Provider value={state}>
			<ReservationDispatchContext.Provider value={dispatch}>
				{children}
			</ReservationDispatchContext.Provider>
		</ReservationContext.Provider>
	);
}

export { ReservationProvider, useReservationState, useReservationDispatch };
