import { useWebBuilderState } from 'context/webBuilder';
import EditableParagraph from "modules/WebBuilder/shared/EditableParagraph";
import React from "react";

function ParagraphItem({ onChange, body ,id}) {
	const { selectedLanguage } = useWebBuilderState()
	return (
		<EditableParagraph
			className="custom-container-paragraph"
			contentEditable={true}
			onInput={(value) => {
				onChange(value, id, selectedLanguage);
			}}
			text={body}
		/>
	);
}

export default ParagraphItem;
