import successImg from "assets/images/wallet/walletSuccessfully.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";


export default function SuccessModal({
	modalsuccess = true,
	togglesuccessModal,
	msg,
	title
}) {
	// const { price } = useMarketplaceState();



	return (
		<>
			<Modal
				size="md"
				className="price-model"
				isOpen={modalsuccess}
				toggle={togglesuccessModal}
			>
				<ModalHeader className="align-items-center" toggle={togglesuccessModal}>
					<p>{title}</p>
				</ModalHeader>
				<ModalBody className="payModal">
					<div className="container ">
						<div className="text-center my-3">
							<img className="mt-2" src={successImg} alt="" />
							<p>{msg}</p>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}
