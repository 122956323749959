import { useSBSState } from "context/global";
import { VisaProvider } from "context/visa";
import DashboardLayout from "layouts/DashboardLayout";
import React from "react";
import { Redirect, Route } from "react-router-dom";

// React Component
export default function VisaRoute({ component: Component, ...props }) {
	const { isAuth } = useSBSState();

	if (isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<VisaProvider>
						{/* <DashboardLayout> */}
							<Component {...matchProps} />
						{/* </DashboardLayout> */}
					</VisaProvider>
				)}
			/>
		);
	} else {
		return <Redirect to="/" />;
	}
}
