import React from "react";
import ThemeFourServiceCard from "./ServiceCard";
import { useWebBuilderState } from "context/webBuilder";
import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import usePreviewMode from "hooks/usePreviewMode";

export default function OurServicesThemeFour({ updateOurServices }) {
	const { ourServices, selectedLanguage } = useWebBuilderState();
	const { isPreview } = usePreviewMode();

	let services = Array.isArray(ourServices?.services)
		? ourServices?.services
		: [];
	services = isPreview
		? services?.filter((service) => service.icon && service.visible)
		: services;

	return (
		<div className="travel-services-container">
			<ServicesContent
				header={ourServices?.title[selectedLanguage]}
				body={ourServices?.body[selectedLanguage]}
				changeHeader={(value) =>
					updateOurServices({
						name: "title",
						value,
						type: null,
						language: selectedLanguage,
					})
				}
				changebody={(value) =>
					updateOurServices({
						name: "body",
						value,
						type: null,
						language: selectedLanguage,
					})
				}
			/>
			<div className="travel-services-wrapper container">
				{services.map((service, index) => (
					<ThemeFourServiceCard
						type={service?.type}
						image={service?.icon}
						header={service?.header[selectedLanguage]}
						body={service?.body[selectedLanguage]}
						key={service?.type + index}
						visible={service?.visible}
						onChangeTitle={(value, name) =>
							updateOurServices({
								name,
								value,
								type: service?.type,
								language: selectedLanguage,
							})
						}
						editCardDetails={(value, name) =>
							updateOurServices({
								name,
								value,
								type: service?.type,
								language: selectedLanguage,
							})
						}
					/>
				))}
			</div>
		</div>
	);
}
