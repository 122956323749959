import React from 'react'
import { ReactComponent as PeopleIcon } from "assets/images/Tds/people.svg";
import { ReactComponent as ClockIcon } from "assets/images/Tds/clock.svg";
import { ReactComponent as GlobalIcon } from "assets/images/Tds/global.svg";
import Locale from 'translations';


export default function TDSVisaDetails({ visaData, package_plan }) {
  const { marketPlace, onlineVisa, tdsVisa, inventory } = Locale;
  return (
    <section className='bg-white p-3 rounded-lg'>
      <h4 className='mb-3 font-weight-bold'>{tdsVisa.packageDetails}</h4>
      <div className='d-flex my-2 '>
        <p className='col-6 p-0'>
          <PeopleIcon />
          <span className='mx-2'>{onlineVisa.Pax}</span>
        </p>
        <span className='col-6 p-0'>{package_plan?.range_from} - {package_plan?.range_to}</span>
      </div>
      <div className='d-flex my-2 '>
        <p className='col-6 p-0'>
          <ClockIcon />
          <span className='mx-2'>{marketPlace.processingTime}</span>
        </p>
        <span className='col-6 p-0'>{visaData.residence?.processing_time}</span>
      </div>
      <div className='d-flex my-2 '>
        <p className='col-6 p-0'>
          <GlobalIcon />
          <span className='mx-2'>{inventory.messages.visaType}</span>
        </p>
        <span className='col-6 p-0'>{visaData?.visa_type?.name}</span>
      </div>
    </section>
  )
}
