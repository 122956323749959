
import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import Locale from 'translations';
import { PackageTravelersModal } from './TravelerModal';
import { ReactComponent as EditIcon } from "assets/images/umrah-operations/edit-icon.svg";
import TextField from 'components/Form/TextField/TextField';
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import moment from 'moment';



export default function PackageReservationTravelers({ details, setReload }) {
  const { packages, marketPlace, inventory, reservation } = Locale;

  const originalTravelers = details?.travelers;
  const [travelerSearchText, setTravelerSearchText] = useState("")
  const [filteredTravelers, setFilteredTravelers] = useState();

  const roomTypesLookup = {
    "1": "Single",
    "2": "Double",
    "3": "Triple",
    "4": "Quadruple",
    "5": "Quintuple",
    "6": "Hexagonal",
    "7": "heptathlon",
    "8": "suite",
    "9": "flat"
  }


  const [travelerModal, setTravelerModal] = useState({
    isOpen: false,
    data: null,
  });

  function toggleTravelerModal(traveler) {
    setTravelerModal({
      isOpen: !travelerModal?.isOpen,
      data: traveler
    })
  }

  function filterTravelersByName(value) {
    value = value.toLowerCase();
    if (value !== "") {
      const filter = originalTravelers?.filter((traveler) => {
        const travelerFullName = `${traveler?.first_name} ${traveler?.last_name}`
        return (
          travelerFullName.toLowerCase().includes(value) && traveler
        );
      });
      setFilteredTravelers(filter);
    } else {
      setFilteredTravelers(originalTravelers);
    }
    setTravelerSearchText(value);
  }

  useEffect(() => {
    setFilteredTravelers([...originalTravelers])
  }, [originalTravelers])

  return (
    <div className='packages-travelers'>

      <div className="col-md-4 col-12 my-3 p-0">
        <TextField
          type="text"
          id="statement-operation-no"
          name="statement-operation-no"
          label={"Search"}
          placeholder={"Type Traveler Name"}
          isImage={true}
          image={SeachIcon}
          prependImage={true}
          onChange={(e) => filterTravelersByName(e.target.value)}
          value={travelerSearchText}
        />
      </div>

      <div className='table-container'>
        <Table className="table table-update">
          <thead>
            <tr>
              <td>{inventory.messages.PassportNo}.</td>
              <td>{marketPlace.messages.name}</td>
              <td>{packages.gender}</td>
              <td>{packages.birthDate}</td>
              <td>{packages.nationality}</td>
              <td>{packages.passportExpireDate}</td>
              <td>{reservation.messages.accommodation}</td>
              {details?.reservation_status !== "pending"
                ? <td>{reservation.messages.Actions}</td>
                : null
              }
            </tr>
          </thead>
          <tbody>
            {filteredTravelers?.length > 0 ?
              filteredTravelers?.map((traveler) => {
                const accommodation = roomTypesLookup[traveler?.roomAvailabilities?.[0]?.room_type];
                return (
                  <tr key={traveler?.id}>
                    <td className='text-blue fw-600'>{traveler?.passport_number}</td>
                    <td className='fw-600'>{traveler?.first_name} {traveler?.last_name} </td>
                    <td>{traveler?.gender}</td>
                    <td>
                      <div className='d-flex flex-column'>
                        <span>{traveler?.is_adult ? inventory.messages.Adult : packages.child}</span>
                        <span>{traveler?.birth_date ? moment(traveler?.birth_date).format("DD-MM-YYYY") : "-"}</span>
                      </div>
                    </td>
                    <td>{traveler?.nationality?.name}</td>
                    <td>{traveler?.passport_expiry ? moment(traveler?.passport_expiry).format("DD-MM-YYYY") : "-"}</td>
                    <td>
                      {traveler?.roomAvailabilities?.length > 0
                        ? <span className='mx-2' style={{ color: "#707170", fontSize: "13px" }}>{accommodation}</span>
                        : "-"
                      }
                    </td>
                    {details?.reservation_status !== "pending" && details?.reservation_status !== "expired" && details?.reservation_status !== "rejected" ?
                      <td>
                        <button className='btn edit-btn shadow-none p-0'
                          onClick={() => toggleTravelerModal(traveler)}
                        >
                          <EditIcon />
                        </button>
                      </td>
                      :
                      null
                    }

                  </tr>
                )
              })
              :
              <tr>
                <td colSpan="10">
                  <div className="product-build__product-no-data fullHeight">
                    <i className="icx icx-eye-slash" aria-hidden="true"></i>
                    <h4>No Travelers</h4>
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </Table>
      </div>
      {travelerModal?.isOpen && travelerModal?.data ?
        <PackageTravelersModal
          isOpen={travelerModal?.isOpen}
          toggle={toggleTravelerModal}
          traveler={travelerModal.data}
          setReload={setReload}
        />
        :
        null
      }
    </div>

  )
}
