export function calculateReservationPrice(reservationDetails, allPaxArray, privatePaxRooms) {
  let totalPrice = 0;
  const productPriceFullPackage = reservationDetails?.product?.product_price?.product_price_type?.id === 1;
  const productPriceBreakDown = reservationDetails?.product?.product_price?.product_price_type?.id === 0;
  // const productItems = reservationDetails?.product?.product_items;
  const reservationCategory = reservationDetails?.reservation_category;

  const adultPaxCount = reservationDetails?.adults_pax;
  const childPaxCount = reservationDetails?.children_pax;
  // paxCount.childsPax
  if (reservationCategory === "private") {
    // calc items for each pax
    let b2bPricing = reservationDetails?.product?.product_price_combinations?.filter(p => p?.pricing_for === "b2b");
    let itemAdultPrices = Number(b2bPricing[0]?.adult_final_items_price) * (adultPaxCount || 0);
    let totalchidlPrice = Number(b2bPricing[0]?.child_price_after_discount) * (childPaxCount || 0);
    // calc hotel rooms prices
    privatePaxRooms?.forEach(room => totalPrice += room?.price);
    totalPrice = totalPrice + itemAdultPrices + totalchidlPrice;
  }
  // reservation shared
  else {
    if (productPriceFullPackage) {
      allPaxArray?.adults?.forEach(adult => {
        totalPrice = totalPrice + (adult?.price || 0);
      });
      allPaxArray?.childs?.forEach(child => {
        totalPrice = totalPrice + (child?.price || 0);
      });
    }
    else if (productPriceBreakDown) {
      // debugger
      totalPrice = 0;
      allPaxArray?.adults?.forEach(adult => totalPrice += adult?.price);
      allPaxArray?.children?.forEach(child => totalPrice += child?.price);
      // let assignedChildCount = allPaxArray?.childs?.filter(child => child?.combination_id).length;
      // let assignedAdultsCount = allPaxArray?.adults?.filter(adult => adult?.combination_id).length;
      // allPaxArray?.adults?.forEach(adult => totalPrice = totalPrice + (adult?.price || 0));
      // allPaxArray?.childs?.forEach(child => totalPrice = totalPrice + (child?.price || 0));
      // reservationDetails?.product?.product_items?.forEach(item => {
      //   if (item?.item?.id !== 6) {
      //     let adultPrice = +item?.itemable?.price_adult ? +item?.itemable?.price_adult * assignedAdultsCount : 0;
      //     let childPrice = +item?.itemable?.price_child && assignedChildCount > 0 ? +item?.itemable?.price_child * assignedChildCount : 0;
      //     totalPrice = totalPrice + (adultPrice + childPrice);
      //   }
      // })
    }
  }
  return totalPrice ? +totalPrice : 0;
}