import { ADD_NEW_ITEM_TO_CUSTOM_CONTAINER_ACTION } from "modules/WebBuilder/interfaces/blockTypes";
import React from "react";
import { useDrag } from "react-dnd";

function FormElement({ icon: Icon, title, type }) {
	const [{ opacity }, drag] = useDrag(
		() => ({
			type,
			item: { type, action: ADD_NEW_ITEM_TO_CUSTOM_CONTAINER_ACTION },
			collect: (monitor) => ({
				opacity: monitor.isDragging() ? 0.4 : 1,
			}),
		}),
		[type]
	);
	return (
		<div className="form-element" ref={drag} style={{ opacity }}>
			<Icon />
			<span>{title}</span>
		</div>
	);
}

export default FormElement;
