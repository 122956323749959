import Locale from 'translations';
import { PlusIcon } from '../shared/Icons';

export default function AddPassportBtn(props) {
  const { ordersViolations } = Locale;

  return (
    <button className="add-passport-btn" {...props}>
      <span className="icon-wrapper">
        <PlusIcon color="#6A4D1B" />
      </span>
      <span>{ordersViolations.addOtherPassport}</span>
    </button>
  );
};