import React from 'react';
import removeIcon from 'assets/images/product-builder/remove-icon.svg';
import TextField from 'components/Form/TextField/TextField';
import SelectField from 'components/Form/SelectField/SelectField';
import { BusIcon, EditIcon } from './Icons';
import Locale from 'translations';

export default function BusHeader({
  busesData,
  selectedBus,
  busTypesLookup,
  handleBusDetailsInputs,
  addNewBus,
  handleSelectBus,
  toggleDeleteConfirmationModal,
  saveBusData,
  editBusData,
  editBusDetails,
  removeBus
}) {
  const { productsBuilder, productElements } = Locale;
  const seatUnsaved = busesData?.find(bus => bus?.seats?.find(seat => seat?.isNew));


  return (
    <div className='bus-header-container'>
      {/* bus tabs */}
      <ul className="buses-tabs">
        {busesData?.map((bus, index) => {
          
          const isSelected = bus?.uuid ? selectedBus?.uuid === bus?.uuid : selectedBus?.id === bus?.id;
          return (
            <li className={`bus-tab ${isSelected ? "active" : ""}`} key={`bus-header${bus?.id || bus?.uuid}`}>
              <button className='btn p-0 shadow-none text-16' onClick={() => handleSelectBus(bus)}>
                <BusIcon fillColor={`${isSelected ? "#6A4D1B" : "#707170"}`} />
                <span className={`${bus.bus_number ? "mx-1" : ""}`}>
                  {bus.bus_number}
                </span>
              </button>

              {busesData?.length > 1 && !isSelected ?
                <button className='btn p-0 shadow-none mx-1 remove-bus'
                  onClick={() => bus?.id ? toggleDeleteConfirmationModal(bus) : removeBus(bus?.id, bus?.uuid)}
                >
                  <img src={removeIcon} alt="remove icon" width="20px" height="20px" />
                </button>
                :
                null
              }
            </li>
          )
        })}
        {/* add bus button */}
        <button className='add-bus-btn btn p-0 text-16' onClick={addNewBus} disabled={seatUnsaved}>
          <i className="fas fa-plus text-yellow"></i>
        </button>
      </ul>


      {/* bue details */}
      <div className="bus-details">
        <div className="row">

          {/* bus number */}
          <div className="col-md-4">
            <TextField
              type="text"
              label={productsBuilder.busNumber}
              name="bus_number"
              id="bus_number"
              value={selectedBus?.bus_number || ""}
              onChange={(e) => handleBusDetailsInputs('bus_number', e.target.value)}
              disabled={!selectedBus?.editMode && selectedBus?.isSaved}
            />
          </div>

          {/* bus type */}
          <div className="col-md-4">
            <SelectField
              label={productsBuilder.busType}
              name="bus_type"
              id="bus_type"
              options={busTypesLookup}
              value={selectedBus?.bus_type?.label || ""}
              onChange={(e) => handleBusDetailsInputs('bus_type', e)}
              disabled={selectedBus?.isSaved}
            />
          </div>

          {/* company name */}
          <div className="col-md-4">
            <TextField
              type="text"
              label={productsBuilder.companyName}
              name="company_name"
              id="company_name"
              value={selectedBus?.company_name || ""}
              onChange={(e) => handleBusDetailsInputs('company_name', e.target.value)}
              disabled={!selectedBus?.editMode && selectedBus?.isSaved}
            />
          </div>

          {/* Driver Name */}
          <div className="col-md-4">
            <TextField
              type="text"
              label={productsBuilder.driverName}
              name="driver_name"
              id="driver_name"
              value={selectedBus?.driver_name}
              onChange={(e) => handleBusDetailsInputs('driver_name', e.target.value)}
              disabled={!selectedBus?.editMode && selectedBus?.isSaved}
            />
          </div>

          {/* Guide name */}
          <div className="col-md-4">
            <TextField
              type="text"
              label={productsBuilder.guideName}
              name="guide_name"
              id="guide_name"
              value={selectedBus?.guide_name}
              onChange={(e) => handleBusDetailsInputs('guide_name', e.target.value)}
              disabled={!selectedBus?.editMode && selectedBus?.isSaved}
            />
          </div>

          {/* save button */}
          <div className="col-md-4">
            {!selectedBus?.isSaved ?

              <button
                className='btn btn-filled w-100'
                style={{ padding: "9px 0", marginTop: "28px" }}
                disabled={!selectedBus?.bus_number || selectedBus?.bus_number.trim().length === 0 || !selectedBus?.bus_type}
                onClick={() => saveBusData()}
              >
                {productElements.save}
              </button>
              :
              <button
                className='btn text-16 w-100'
                style={{ padding: "9px 0", marginTop: "28px" }}
                disabled={!selectedBus?.bus_number || selectedBus?.bus_number.trim().length === 0 || !selectedBus?.bus_type}
                onClick={() => !selectedBus?.editMode ? editBusData() : editBusDetails(selectedBus?.id)}
              >

                {!selectedBus?.editMode && selectedBus?.isSaved ?
                  <>
                    <EditIcon />
                    <span className='mx-1 font-weight-bold text-yellow'>{productElements.edit}</span>
                  </>
                  :
                  <span className='mx-1 font-weight-bold text-yellow'> {productElements.save}</span>
                }
              </button>
            }

          </div>

        </div>
      </div>
    </div>
  )
}