import butImg from "assets/images/statistics/bus.svg";
import hotelImg from "assets/images/statistics/hotel.svg";
import totalImg from "assets/images/statistics/trend.svg";
import { useEffect, useState } from "react";
import { filterStatistics } from "services/statistics";
import Locale from "translations";
import Card from "./Card";
import FilterStatistics from "./FilterStatistics";

export default function RevenueStatistics() {
	const { statistics } = Locale;
	const [filter, setFilter] = useState({ id: "month", name: "Month" });
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		hotels: null,
		transportation: null,
		total: null,
	});

	useEffect(() => {
		fetchStatistics("month");
	}, []);

	const monthLookups = [
		{ id: "month", name: `${statistics.month}` },
		{ id: "2week", name: `${statistics.weeks2}` },
		{ id: "week", name: `${statistics.week}` },
		{ id: "48h", name: `${statistics.hourDay2}` },
		{ id: "24h", name: `${statistics.hourDay1}` },
	];

	let statisticsProps = [
		{
			id: 1,
			cardImg: `${hotelImg}`,
			count: +data.hotels?.price,
			booking: data.hotels?.count,
			name: `${statistics.hotels}`,
			styleClass: "hotels",
		},
		{
			id: 2,
			cardImg: `${butImg}`,
			count: +data.transportations?.price,
			booking: data.transportations?.count,
			name: `${statistics.transportation}`,
			styleClass: "transportation",
		},
		{
			id: 3,
			cardImg: `${totalImg}`,
			count: +data.total?.price,
			booking: data.total?.count,
			name: `${statistics.total}`,
			styleClass: "total",
		},
	];

	const StatisticsData = statisticsProps.map((statistic) => {
		if (data.total && !loading) {
			return (
				<Card
					key={statistic.id}
					cardImg={statistic.cardImg}
					count={+statistic.count}
					booking={statistic.booking}
					name={statistic.name}
					styleClass={statistic.styleClass}
				/>
			);
		}
	});

	const fetchStatistics = async (date) => {
		setLoading(true);
		
		const res = await filterStatistics(date);
		if (res?.status === 200) {
			setData(res?.data?.data);
			setLoading(false);
		}
	};

	const handleChange = (e) => {
		// 
		setFilter(e);
		fetchStatistics(e.value);
	};

	return (
		<>
			<div className="revenue-statistics">
				<div className="header d-flex justify-content-between">
					<div className="title">
						<h2 className="font-weight-bold text-subtitle">
							{statistics.revenueStatistics}
						</h2>
						<p>{statistics.revenueText}</p>
					</div>
					<FilterStatistics
						monthLookups={monthLookups}
						value={filter.label}
						handleChange={handleChange}
					/>
				</div>

				<div className="cards py-3">
					<div className="row m-0">{StatisticsData}</div>
				</div>
			</div>
		</>
	);
}
