import axios from "axios";


import { cleanEmpty } from './general';
const transportationURL = process.env.REACT_APP_API_URL + "/v1/transportation";

export const transportationsLookups = async () => {
  return await axios
    .get(`${transportationURL}/get-destinations`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const paymentTransportation = async (data) => {
  return await axios
    .post(`${transportationURL}/requests`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const transportationsRequestsList = async (data) => {
  return await axios
    .get(`${transportationURL}/requests`, { params: cleanEmpty(data), })
    .then((res) => res)
    .catch((err) => err.response);
};

export const viewTransportationRequest = async (id) => {
  return await axios.get(
    `${transportationURL}/requests/${id}`
  );
};

export const editTransportationRequest = async (id, data) => {
  return await axios
    .put(`${transportationURL}/requests/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const splitRequestPassengers = async (id) => {
  return await axios.post(`${transportationURL}/requests/split-request-passengers/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

export const sendRequestPassengers = async (id, data) => {
  return await axios.post(`${transportationURL}/requests/send-request-passengers/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
}


export const cycleTypeLookup = async () => {
  return await axios
    .get(`${transportationURL}/cycle_type/`)
    .then((res) => res)
    .catch((err) => err.response);
};