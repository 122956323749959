// import { useMarketplaceState } from "context/marketplace";
import React from "react";
import Hotel from "./Hotel";

function Details() {
	// const { searchBy } = useMarketplaceState();

	return <Hotel />;
}

export default Details;
