import { AlertCircleIcon } from 'modules/UmrahOperations/shared/Icons'
import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import Locale from 'translations';

export default function ChangePricingConfirmationModal({ isOpen, toggle, confirmFn }) {
  const { operationStatement } = Locale;

  return (
    <Modal isOpen={isOpen} centered className="change-pricing-Modal">
      <ModalBody>
        <div className="d-flex flex-column gap-10 align-items-start">
          <span className="icon mb-2">
            <AlertCircleIcon color="#D92D20" />
          </span>
          <h6 className="header">{operationStatement.Changepricingtype}</h6>
          <p className="info-text">{operationStatement.ChangepricingtypeMsg} </p>
        </div>
        <div className="d-flex gap-10 mt-4">
          <button className="btn reject-btn" onClick={toggle}>{operationStatement.No}</button>
          <button className="btn confirm-btn" onClick={confirmFn}>{operationStatement.Yes}</button>
        </div>
      </ModalBody>
    </Modal>
  )
}
