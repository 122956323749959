
export const MAXIMUM_DISCLAIMER_CHARACTER = 300;
export const MAXIMUM_HEADER_CHARACTER = 40;

export const SELECT_FLYER_TEMPLETE = "slelect";
export const EDIT_FLYER_TEMPLETE = "edit";
export const DOWNLOAD_FLYER_TEMPLETE = "download";

export const TEMPLETE_TYPE_MULTIPLE = "multiple";
export const TEMPLETE_TYPE_SINGLE = "single";


