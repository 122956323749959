import React from "react";

export default function ProgressCircular() {
	return (
		<>
			<div className="row m-0">
				<div className="col-md-3 bg-gray">
					<div className="main pt-3 d-flex">
						<div className="time">11:58</div>
						<div className="px-3">
							<span className="tes">
								<i class="far fa-circle bg-gray"></i>
							</span>
						</div>
						<div>
							<h2>Aswan, Egypt</h2>
							<h2>Giza, Egypt</h2>
							<h2>Aswan, Egypt</h2>
							<h2>Giza, Egypt</h2>
						</div>
					</div>

					<div className="main pt-3 d-flex">
						<div className="time">11:58</div>
						<div className="px-3">
							<span className="tes">
								<i class="far fa-circle bg-gray"></i>
							</span>
						</div>
						<div>
							<h2>Login</h2>
							<h2>Cairo, Egypt</h2>
						</div>
					</div>

					<div className="main pt-3 d-flex">
						<div className="time">11:58</div>
						<div className="px-3">
							<span className="tes">
								<i class="far fa-circle bg-gray"></i>
							</span>
						</div>
						<div>
							<h2>Login</h2>
							<h2>Alex, Egypt</h2>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					{/* <DonutChart
						className="w-75 h-75"
						data={[
							{
								label: "Total Travel",
								value: 33.3,
							},
							{
								label: "",
								value: 33.3,
							},
							{
								label: "",
								value: 33.3,
							},
						]}
					/> */}
				</div>
				<div className="col-md-3">
					<h2>Users number</h2>
					<div className="text-title d-flex justify-content-between align-items-center">
						<i className="fa fa-users"></i>
						<p> User Number 210</p>
					</div>
				</div>
			</div>
		</>
	);
}
