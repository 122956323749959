import React, { useCallback, useState } from "react";
import Hero from "./Hero";
import OurServices from "./OurServices";
import AboutUs from "./AboutUs";
import OurPackages from "./OurPackages";
import HotelService from "./HotelService";
import ToursService from "./ToursService";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import TransferService from "./Transfer";
import FlightsService from "./Flights";
import CustomContainer from "./CustomContainer";
import DragAndDropContainer from "modules/WebBuilder-V2/shared/DragAndDropContainer";
import {
	SORT_CONTAINER_ACTION,
	blockTypes,
	customContainerItems,
} from "modules/WebBuilder-V2/interfaces/blockTypes";
import Footer from "./Footer";
import { useLocation, useHistory, useParams } from "react-router-dom";
import ContactUs from './ContactUs';
import FAQs from './FAQs';
import Locale from 'translations';
import WebBuilderHeader from './Header';

function Content() {
	const { subscriptionModule } = Locale;
	const { content, ourServices, style, selectedLanguage } = useWebBuilderState();
	const dispatch = useWebBuilderDispatch();
	const history = useHistory();
	const { bundle } = useParams()
	Locale.setLanguage(selectedLanguage || "en")

	const [focusContainer, setFocusContainer] = useState("");

	const location = useLocation();
	const preview = !location.pathname.includes('edit');
	const drapPlaceHolderVisible =
		content?.length === 5 &&
		ourServices?.services.every((service) => !service.visible) &&
		!preview;

	const handleFocus = (value) => {
		setFocusContainer(value);
	};

	const isServiceVisible = (type) => {
		const services = ourServices?.services;
		const index = services?.findIndex((service) => service.type === type);
		if (index === -1) {
			return true;
		} else {
			return services?.[index]?.visible;
		}
	};

	const sortServices = useCallback(({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItem",
			payload: {
				dragIndex,
				hoverIndex,
			},
		});
	}, []);

	const containerAcceptTypes = Object.keys(blockTypes);
	const containerAcceptitemsTypes = Object.keys(customContainerItems);

	const addItemToCustomContainer = (id, item) => {
		dispatch({
			type: "addItemToContainer",
			payload: {
				id,
				item,
			},
		});
	};
	const onDropItemInContainer = (id, item) => {
		addItemToCustomContainer(id, item);
	};

	const bodyElements = {
		aboutUs: { component: AboutUs, acceptTypes: [] },
		contactUs: { component: ContactUs, acceptTypes: [] },
		packages: { component: OurPackages, acceptTypes: [] },
		hotels: { component: HotelService, acceptTypes: [] },
		flights: { component: FlightsService, acceptTypes: [] },
		// transfer: { component: TransferService, acceptTypes: [] },
		tours: { component: ToursService, acceptTypes: [] },
		customContainer: {
			component: CustomContainer,
			acceptTypes: containerAcceptitemsTypes,
			onDrop: onDropItemInContainer,
		},
		faq: { component: FAQs, acceptTypes: [] }
		// contactUs: <p></p>,
	};


	const renderComponent = useCallback(
		(element, index) => {
			const type = element.type;
			const selectedElement = bodyElements[type];
			const Component = selectedElement?.component;
			const onDrop = selectedElement?.onDrop;

			const isActive = isServiceVisible(type);
			// debugger
			return Component && isActive ? (
				<DragAndDropContainer
					accept={[...containerAcceptTypes, ...selectedElement.acceptTypes]}
					type={element.type}
					id={element.id}
					index={index}
					moveItem={(dragIndex, hoverIndex) =>
						sortServices({ dragIndex, hoverIndex })
					}
					action={SORT_CONTAINER_ACTION}
					onDrop={(item) => onDrop(element.id, item)}
					key={element.id}
				>
					<Component
						details={{ ...element, title: element?.title?.[selectedLanguage], body: element?.body?.[selectedLanguage], index, preview }}
						onFocus={handleFocus}
						focusContainer={focusContainer}
					/>
				</DragAndDropContainer>
			) : null;
		},
		[bodyElements, focusContainer]
	);
	return (
		<>
			{content &&
				<div className={`web-builder-content ${preview ? "p-0 web-builder-preview" : ""} ${style.theme}`}>
					{preview && 
						<div className="d-flex justify-content-end my-3">
							<button className="btn bg-nxt py-2" onClick={()=> history.push(`/${bundle}/web-builder-v2/edit`)}>
								{subscriptionModule.BackToEditMode}
							</button>
						</div>
					}
					<WebBuilderHeader />
					<Hero />
					<OurServices />

					{content?.map((element, index) => renderComponent(element, index))}
					{drapPlaceHolderVisible && (
						<DragAndDropContainer
							accept={[...containerAcceptTypes]}
							type={"drag-drop-placeholder-type"}
							id={"drag-drop-placeholder"}
							index={content?.length ?? 0}
							moveItem={(dragIndex, hoverIndex) =>
								sortServices({ dragIndex, hoverIndex })
							}
							action={SORT_CONTAINER_ACTION}
							// onDrop={(item)=> onDrop(element.id,item)}
							// key={element.id}
							canDrag={false}
						>
							<div className="drop-placeholder">Drop your blocks here</div>
						</DragAndDropContainer>
					)}

					<Footer focusContainer={focusContainer} handleFocus={handleFocus} />
				</div>
			}
		</>

	);
}

export default Content;
