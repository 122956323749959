import React, { useCallback, useState } from "react";
import HotelTaps from "./Taps";
import HotelDetails from "./Details";
import Filters from "./Filters";
import RoomsContainer from "./RoomsContainer";
import { ReactComponent as PrintIcon } from "assets/images/hotelAccommodation/print.svg";
import { ReactComponent as SaveIcon } from "assets/images/hotelAccommodation/save.svg";
import {
	useProductsBuilderDispatch,
	useProductsBuilderState,
} from "context/productsBuilder";
import {
	assignTravelers,
	editRoomAccommodation,
} from "services/productbuilder";
import { store } from "react-notifications-component";
import Locale from "translations";
import { Modal, ModalBody } from "reactstrap";

function HotelDetailsAccommodation({
	dawnloadHotelDistribution,
	dawnloadHotelRooming,
	dawnloadRoomHotel,
	dawnloadHotelAccommodationReport,
	dawnloadRoomHotels,
}) {
	const { marketPlace } = Locale;
	const dispatch = useProductsBuilderDispatch();
	const [filterRoomType, setFilterRoomType] = useState("all");
	const {
		hotel_accommodation: hotels,
		hotel_accommodation_travelers: travelers,
		selected_hotel_accommodation_id: selectedHotelId,
	} = useProductsBuilderState();

	const getSelectedHotel = useCallback(() => {
		return hotels?.length > 0
			? hotels.find((hotel) => hotel.id === selectedHotelId)
			: [];
	}, [selectedHotelId, hotels]);

	const selectedHotel = getSelectedHotel();

	const handleFilterRoomType = (type) => {
		setFilterRoomType(type);
	};
	const [duplicateHotel, setDuplicateHotel] = useState([]);
	const handleSaveChanges = async () => {
		const rooms = [];
		const updatedTravelers = [];

		hotels.forEach((hotel) => {
			hotel.accommodationRooms.forEach((room) => {
				let data = {
					id: room.id,
					room_number: room.room_number,
					room_name: room.room_name,
					meal_name: room.meal_name,
				};
				if (room.room_number) rooms.push(data);
			});
		});
		travelers.forEach((traveler) => {
			traveler.roomAvailabilities.forEach((room) => {
				let data = {
					product_hotel_id: room.product_hotel_id,
					traveler_id: traveler.id,
					accommodation_room_id:  typeof room?.roomId === "number"? room?.roomId :room?.roomId?.find(item=>item),
				};
				if (room.roomId) updatedTravelers.push(data);
			});
		});

		if (updatedTravelers.length === 0) {
			store.addNotification({
				title: "Warning",
				message: "No travelers added to rooms",
				type: "warning",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			return;
		}

		const res = await Promise.all([
			editRoomAccommodation({ rooms }),
			assignTravelers({ travelers: updatedTravelers }),
		]);
		if (res[0].status === 200 && res[1].status === 200) {
			store.addNotification({
				title: "success",
				message: "save successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	const [isOpenModel, setIsOpenModel] = useState(false);
	const toggleModel = () => setIsOpenModel(!isOpenModel);
	function dublicateByCheckbox(e) {
		const checked = e.target.checked;
		let checkedArray = duplicateHotel;
		if (checked) {
			checkedArray = [...duplicateHotel, +e.target.value];
		} else {
			checkedArray = duplicateHotel.filter((c) => c !== +e.target.value);
		}
		setDuplicateHotel([...checkedArray]);
	}

	const duplicateHotels = () => {
		
		let hotelClone = [...hotels];
		let selectedHotel = hotelClone?.find((item) => item.id === selectedHotelId);
		hotelClone = hotelClone.map((hotel) => {
			let accommodationRooms = hotel?.accommodationRooms?.map((room, index) => {
				// let roomType = room?.room_type;
				let travelers = room?.travelers?.map(traveler => {
					let roomId = room?.id;
					return {
						...traveler,
						roomAvailabilities: traveler?.roomAvailabilities?.map(ava => {
							if (hotel?.id !== ava?.product_hotel_id) {
								hotels.filter(h => h?.id === ava?.product_hotel_id)?.filter(r => {
									roomId = r?.accommodationRooms?.find(acc => acc?.room_type === ava?.room_type)?.id
									return r
								})
							}
							return {
								...ava,
								roomId: roomId
							}
						})
					}
				})
				return {
					...room,
					travelers: travelers || []
				}
			})

			return {
				...hotel,
				accommodationRooms: accommodationRooms,
			};
		});
		
		let selectedAccommodation = selectedHotel?.accommodationRooms;
		let newHotels = hotelClone?.map(hotel => {
			if (hotel?.id !== selectedHotelId && duplicateHotel.includes(hotel?.id)) {
				return {
					...hotel,
					accommodationRooms: hotel?.accommodationRooms?.map(acc => {
						return {
							...acc,
							travelers: selectedAccommodation?.find(accRes => accRes?.room_type === acc?.room_type &&
								(accRes?.id + selectedAccommodation?.length ===acc?.id ||
								accRes?.id - selectedAccommodation?.length ===acc?.id ||

								accRes?.id + 2*selectedAccommodation?.length ===acc?.id ||
								accRes?.id - 2*selectedAccommodation?.length ===acc?.id 

									)
								)?.travelers,
							meal_name: selectedAccommodation?.find(accRes => accRes?.room_type === acc?.room_type)?.meal_name,
						}
					})
				}
			} else {
				return hotel
			}
		});
		debugger
		let travelerAccommodation = newHotels?.find((item) => item.id === selectedHotelId)?.accommodationRooms;
		let newTravelers = travelers?.map(traveler=> {

			let roomAvailabilities = traveler?.roomAvailabilities?.map(room=> {
				if (room?.product_hotel_id !== selectedHotelId && duplicateHotel.includes(room?.product_hotel_id)) {
				
					const accomm=travelerAccommodation?.filter(accRes => accRes?.room_type === room?.room_type)?.filter(item=> item?.travelers?.length>0)
					return {
						...room,
						product_hotel_id: room?.product_hotel_id,
						roomId:accomm?.map(item=>item?.travelers?.find(accTraveler=> accTraveler?.id === traveler?.id)
						?.roomAvailabilities?.find(accRoom=> accRoom?.id === room?.id)?.roomId )
						
					}
				}else {
					return room
				}
			})
			console.log(roomAvailabilities);
			return {
				...traveler,
				roomAvailabilities
			}
		});

		add(newHotels, newTravelers);

		store.addNotification({
			title: "success",
			message: "Duplicated successfully",
			type: "success",
			insert: "top",
			container: "top-right",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: {
				duration: 1000,
				onScreen: true,
				pauseOnHover: true,
			},
			onRemoval: () => {

			},
		});
		toggleModel();
		setDuplicateHotel([]);
	};



	function add(hotels, newTravelers) {
		// const selectedHotel = hotels?.find((item) => item.id === selectedHotelId);
		// let travelersArr = [];
		// selectedHotel?.accommodationRooms.map((accRoom) => accRoom.travelers && travelersArr.push(accRoom.travelers));
		// let travelarsId = travelersArr?.flat().map((travl) => travl.id);

		// let resultTravelar = travelers.filter((trav) => !travelarsId.includes(trav.id));

		// ///////////////////////////////////////////////////////////
		// const AllTrav = [];

		// let allSelectedHotel = hotels.map((hotel) => {
		// 	if (duplicateHotel.includes(hotel.id)) {
		// 		selectedHotel.accommodationRooms.map((item, index) => ({
		// 			...JSON.parse(JSON.stringify(item)),
		// 			id: hotel.accommodationRooms[index].id,
		// 			product_hotel_id: hotel.id,
		// 			travelers: item?.travelers?.map((trav) => ({
		// 				...trav,
		// 				roomAvailabilities: trav.roomAvailabilities?.map((ava, indx) => {
		// 					return { ...ava, roomId: hotel.accommodationRooms[index].id };
		// 				}),
		// 			})),
		// 		}));
		// 	}
		// 	return hotel;
		// });
		// let addToAllTrav = allSelectedHotel.map((item) =>
		// 	item.accommodationRooms.map(
		// 		(accRoom) => accRoom?.travelers && AllTrav.push(accRoom?.travelers)
		// 	)
		// );
		// const selectedTravaler = [];
		// addToAllTrav = AllTrav.map((item) => selectedTravaler.push(item));
		// ///////start hash table/////
		// let arr = [...selectedTravaler.flat()];

		// let selectedResultTravelar = [];
		// for (let i = arr.length - 1; i > 0; i--) {
		// 	if (
		// 		selectedResultTravelar.filter((aa) => aa.id == arr[i].id)?.length == 0
		// 	) {
		// 		selectedResultTravelar.push(arr[i]);
		// 	}
		// }
		///////end hash table/////
		
		/////////////////////////////////////////////////////////
		dispatch({
			type: "duplicatedHotelAccommodationData",
			payload: {
				hotels,
				travelers: newTravelers, // resultTravelar,
			},
		});
	}


	return (
		<div className="hotel-accommodation-details">
			<div className="hotel-taps-container">
				{hotels.length > 0
					? hotels.map((hotel) => (
						<HotelTaps
							hotelName={hotel.hotel.name}
							hotelId={hotel.id}
							selected={hotel.id === selectedHotelId}
							handleFilterRoomType={handleFilterRoomType}
						/>
					))
					: null}
			</div>

			<HotelDetails hotelDetails={selectedHotel} toggleModel={toggleModel} />
			<hr className="mt-4 mb-3" />
			<Filters
				filterRoomType={filterRoomType}
				handleFilterRoomType={handleFilterRoomType}
				selectedHotel={selectedHotel}
			/>
			<RoomsContainer
				hotelDetails={selectedHotel}
				filterRoomType={filterRoomType}
			/>

			<div className="hotel-accommodation-actions">
				<button
					className="hotel-accommodation-button btn p-2"
					onClick={() => dawnloadRoomHotels(selectedHotelId)}
				>
					<PrintIcon />
					<span>{marketPlace.DaigramRoomHotels}</span>
				</button>
				<button
					className="hotel-accommodation-button btn p-2"
					onClick={() => dawnloadRoomHotel(selectedHotelId)}
				>
					<PrintIcon />
					<span>{marketPlace.DaigramRoomHotel}</span>
				</button>

				<button
					className="hotel-accommodation-button btn p-2"
					onClick={() => window.print()}
				>
					<PrintIcon />
					<span>{marketPlace.Print}</span>
				</button>
				<button
					className="hotel-accommodation-button btn p-2"
					onClick={dawnloadHotelDistribution}
				>
					<PrintIcon />
					<span>{marketPlace.HotelDistribution}</span>
				</button>
				<button
					className="hotel-accommodation-button btn p-2"
					onClick={dawnloadHotelAccommodationReport}
				>
					<PrintIcon />
					{/* <span>Travellers Accommodation</span> */}
					<span>{marketPlace.HotelRooming}</span>
				</button>

				{/* <button
					className="hotel-accommodation-button btn p-2 mx-2"
					onClick={() => dawnloadHotelRooming(selectedHotelId)}
				>
					<PrintIcon />
					<span>{marketPlace.HotelRooming}</span>
				</button> */}
				<button
					className="hotel-accommodation-button btn p-2"
					onClick={handleSaveChanges}
				>
					<SaveIcon />
					<span>{marketPlace.SaveChanges}</span>
				</button>
			</div>
			<Modal
				isOpen={isOpenModel}
				toggle={() => {
					toggleModel(null);
				}}
				className="duplicate-model"
			>
				<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom mx-4 ">
					<p className=" text-secondary text-header">Duplicate Accommodation</p>
					<i
						className="fas fa-times  text-secondary "
						onClick={() => {
							toggleModel(null);
						}}
					></i>
				</div>
				<ModalBody className="lan  mx-4 my-4 p-0 ">
					<div className="duplicate-model">
						<div className="container-fluide">
							<p className="text-secondary ">
								Are you sure you want to duplicate accommodation data to other
								hotel ?
							</p>
							<h6 className="my-3">Please Select Hotel From List</h6>
							<div className="hotels-acc">
								{hotels?.length > 0 &&
									hotels.map((item, index) => {
										return (
											selectedHotelId !== item?.id && (
												<div className="hotel-item">
													<input
														id={item?.id}
														type="checkbox"
														value={item.id}
														onChange={(e) => {
															dublicateByCheckbox(e);
														}}
														checked={duplicateHotel.includes(item.id)}
													/>
													<label data htmlFor={item?.id}>
														{item.hotel?.name}
													</label>
												</div>
											)
										);
									})}
							</div>
							<button
								className="btn btn-dupl w-100 my-3"
								onClick={duplicateHotels}
							>
								Duplicate
							</button>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default HotelDetailsAccommodation;
