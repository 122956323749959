// import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import validate, { isFormValid } from 'helpers/validate';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';
import { CopyIcon } from 'modules/UmrahOperations/shared/Icons';
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { fetchExternalUmrahCompaniesList, sendStatementByEmail, SendUmrahCompanyDetails } from 'services/operationStatement';
import Locale from "translations";




export default function SendToUmrahCompanyModal({ isOpen, toggle, statementRefNumber, setReload }) {
	const { operationStatement } = Locale;

	const [data, setData] = useState({
		companyLicense: null,
		name: "",
		operation_link: "",
		email: null,
	});
	const [companiesList, setCompaniesList] = useState([]);
	const [errors, setErrors] = useState({})



	function checkFormErrors() {
		const requiredKeys = ["email"]
		let submitError = {};
		Object.keys(data).forEach(key => {
			if (!requiredKeys.includes(key)) return
			submitError = {
				...submitError,
				...validate(
					{ name: key, value: data[key] },
					{ required: true, email: key === "email", }
				)
			}
		});
		setErrors(() => submitError);
		return submitError;
	}

	// genearete link
	async function handleGenerateLink() {
		const res = await SendUmrahCompanyDetails(statementRefNumber, { licence_number: data.companyLicense?.licence_number });
		if (res?.status === 200) {
			let domain = window.location.protocol + "//" + window.location.host;
			setData({ ...data, operation_link: `${domain}/public/umrah-operations/${statementRefNumber}` })
		}
	}

	// copy statement publick url
	function handleCopyUrl() {
		navigator.clipboard.writeText(data.operation_link).then(() => {
			handleResponesNotification({ alertType: "success", message: "URL Copied" });
		});
	}


	async function getCompaniesList() {
		const compnaiesRes = await fetchExternalUmrahCompaniesList();
		if (compnaiesRes?.status === 200) {
			setCompaniesList(compnaiesRes?.data?.data);
		}
	};

	async function sendOperationStatement() {
		let formErrors = checkFormErrors();
		if (isFormValid(formErrors)) {
			const res = await sendStatementByEmail(statementRefNumber, { email: data.email });
			if (res?.status === 200) {
				setReload(prev=> !prev)
				handleResponesNotification({ alertType: "success", message: "Statement sent to email" });
				toggle()
			}
		}
	}

	useEffect(() => {
		getCompaniesList();
	}, []);


	return (
		<Modal
			isOpen={isOpen}
			size="md"
			centered={true}
			hasFooter={false}
			className="operation-statement-numbers-model send-company-model"
		>
			<ModalHeader toggle={toggle}>
				{operationStatement.sendToUmrahCompany}
			</ModalHeader>


			<ModalBody>
				<div className="container p-2 pb-4">
					<SelectField
						id="license-no"
						name="license-no"
						label={operationStatement.enterCompanyLicense}
						placeholder={operationStatement.enterCompanyLicense}
						options={companiesList}
						// listAuto={companiesList}
						// setListAuto={setCompaniesList}
						// getListAuto={getCompaniesList}
						value={data.companyLicense?.name || ""}

						onChange={(e) => {
							setData({ ...data, companyLicense: e });
						}}
					// onSelectValue={(e) => {
					// 	setData({ ...data, companyLicense: e });
					// }}

					/>

					{data.operation_link &&
						<>
							{/* comapny name */}
							<div>
								<TextField
									label={operationStatement.name}
									name="company_name"
									id="company_name"
									value={data.companyLicense?.name}
									disabled={true}
								/>
							</div>
							{/* operational link */}
							<div className="mt-3">
								<TextField
									label={operationStatement.operationStatementLink}
									name="link"
									id="link"
									value={data.operation_link}
									extraText={
										<button className="btn py-2 px-4 bg-nxt shadow-none" onClick={handleCopyUrl}>
											<CopyIcon color={"#fff"} />
											<span>{operationStatement.copy}</span>
										</button>
									}
									extraTextPosition={"append"}
									disabled={true}
								/>
							</div>

							<div className="or-container my-3">
								<p className='text'>
									<span>{operationStatement.OR}</span>
								</p>
							</div>


							{/* email */}
							<TextField
								label={operationStatement.sendOperatingStatementByEmailAddress}
								name="email"
								id="email"
								value={data.email}
								onChange={(e) => {
									setData({ ...data, email: e.target.value });
									setErrors({
										...errors,
										...validate(
											{ name: "email", value: e.target.value },
											{ required: true, email: true }
										),
									});
								}}
								color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
								errors={errors?.email}
							/>


						</>
					}

					<button className="btn bg-nxt py-2 w-100 mt-3"
						disabled={!data?.companyLicense?.id}
						onClick={() => {
							if (data.operation_link) {
								sendOperationStatement()
							} else {
								handleGenerateLink();
							}
						}}
					>

						{data.operation_link ? operationStatement.sendOperationStatement : operationStatement.generateLink}

					</button>
				</div>
			</ModalBody>
		</Modal>
	);
}
