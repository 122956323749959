import React from "react";
import { useHistory } from "react-router-dom";
import Locale from "translations";
import paymentFailed from "assets/images/wallet/error.svg";

export default function PaymentFailed(props) {
	const history = useHistory();
	const { payment } = Locale;
	return (
		<>
			<div className="payment-successful">
				<img src={paymentFailed} alt="ImageSuccess" className="pb-3" />
				<h6 className="text-danger mb-1">{payment.messages.paymentFailed}</h6>
				<p>{payment.messages.paymentNotSuccessful}</p>
				<button
					className="btn btn-primary px-5 mb-3 my-2"
					onClick={() => history.push("/wallet")}
				>
					{payment.messages.backToWallet}
				</button>
			</div>
		</>
	);
}
