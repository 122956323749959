import React from "react";
import { uploadFile } from "services/auth";

function UploadFileComponent({
	onUpload,
	icon: Icon,
	label,
	// you must change this Id every time you use this component to avoid any conflict
	id,
	accept="image/png, image/jpeg, image/jpg",
	image,
	disabled
}) {
	const upload = async (file) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("name", file?.name);
		formData.append("bucket", "webBuilder");
		const response = await uploadFile(formData);
		if (response.data.data.uuid) {
			return response.data.data.uuid;
		}
	};

	const handleUpload = async (e) => {
		const files = e.target.files;
		if(!files?.[0]) return
		const uuid = (await upload(files[0])) ?? "";
		const URI = process.env.REACT_APP_FILEUPLOAD_URL;

		const fileUrl = `${URI}/fetch/${uuid}`;
		onUpload(fileUrl);
	};
	return (
		<div className="upload-file-container">
			<label htmlFor={`${id}-upload-file`} className={`${image?"image-file-service":""}`}>
				{image ? image  : <Icon className="me-1" />}
				{label}


			
			</label>
			<input
				onChange={handleUpload}
				style={{ display: "none" }}
				type="file"
				id={`${id}-upload-file`}
				accept={accept}
				disabled={disabled}
			/>
		</div>
	);
}

export default UploadFileComponent;
