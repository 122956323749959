import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";
import moment from "moment";
import { cancelTour, cancelTourItem } from "services/tours";
import { useParams } from "react-router-dom";
import { store } from 'react-notifications-component';
export default function CancelModel({ model, toggleModel, details, cancelType, amount, preCancel, setBookingDetails, toggleReloadData }) {
	const { inventory, marketPlace } = Locale;
	const [acceptCancel, setAcceptCancel] = useState(false);
	const { id } = useParams();

	async function cancelAllTours() {
		// here call api to cancel single tour or all tours
		const res = await cancelTour(id);
		if (res.status === 200) {
			toggleModel();
			setBookingDetails(res?.data?.tour);
			store.addNotification({
				title: "cancel Done",
				message: "cancel Done successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	async function cancelSingleTour() {
		const reservationId = details?.id;
		const cancelTourRes = await cancelTourItem(reservationId);
		if (cancelTourRes?.status >= 200 && cancelTourRes?.status < 300) {
			toggleReloadData();
			toggleModel();
			store.addNotification({
				title: "cancel Done",
				message: "cancel Done successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	return (
		<Modal
			size="lg"
			isOpen={model}
			toggle={toggleModel}
		>
			<ModalHeader
				className="align-items-center font-weight-bold hd-title-model"
				toggle={toggleModel}
			>
				{inventory.messages.cancleService}
			</ModalHeader>

			<ModalBody>
				<div className="my-3 tours-cart-container">
					{/* tours details */}
					{cancelType === "single-item" ? (
						<CancelCardDetails details={details} />
					) : (
						details?.map((item) => {
							return (
								<div key={item?.id}>
									<CancelCardDetails details={item} />
									<hr />
								</div>
							);
						})
					)}
					{/* cancel summary */}
					<div className="d-flex flex-column align-items-end mt-2 py-2">
						<div>
							<span className="text-muted text-15">
								{inventory.messages.cancellationFees} :
							</span>
							<span className="font-weight-bold h6">
								{" "}
								{`${preCancel?.charge} ${preCancel?.currency}`}
							</span>
						</div>
						{preCancel?.charge === 0 && (
							<p className="text-success">{marketPlace.messages.freeCancellation}</p>
						)}
					</div>
					{/* summary */}
					<div className="d-flex flex-column align-items-end border-top-gray my-2 py-2">
						<div>
							<span className="text-muted text-15">
								{inventory.messages.paidAmount}:{" "}
							</span>
							<span className="font-weight-bold h6">{`${+amount?.totalPrice} ${amount?.currency}`}</span>
						</div>
						<div>
							<span className="text-muted text-15">
								{inventory.messages.totalToBeRefunded}:{" "}
							</span>
							<span className="font-weight-bold h6"> {`${+(amount?.totalPrice - preCancel?.charge).toFixed(2)} ${amount?.currency}`}</span>
						</div>
					</div>
					{/* accept and proceed to cancel */}
					<div className="d-flex justify-content-center">
						<input
							type="Checkbox"
							name="acceptCancel"
							id="acceptCancel"
							checked={acceptCancel}
							onChange={() => setAcceptCancel(!acceptCancel)}
						/>
						<label htmlFor='acceptCancel' className="text-muted text-14 my-0 mx-1 pointer user-select-none">
							{inventory.messages.acceptCancelationCharges}
						</label>
					</div>
					<div className="d-flex justify-content-center">
						<button
							className="btn btn-yellow py-2 px-5 font-weight-bold mt-1 text-15"
							disabled={!acceptCancel}
							onClick={() => {
								if (cancelType === "single-item") {
									cancelSingleTour();
								} else {
									cancelAllTours();
								}
							}}
						>
							{inventory.messages.cancelService}
						</button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
}

export function CancelCardDetails({ details }) {
	const { inventory } = Locale;
	return (
		<div className="d-flex justify-content-between flex-md-nowrap flex-wrap">
			<div className="d-flex flex-md-nowrap flex-wrap w-100">
				<div className="tour-card-img">
					<img
						src={details?.details?.images?.[0]}
						alt="tours"
						className="img-fluid transfer-booking-item-img"
					/>
				</div>
				<div className="d-flex flex-column">
					<h2 className="mb-2 font-weight-bold text-dark-blue h5">
						{details?.name}
					</h2>
					<i className="fal fa-sort-alt"></i>

					<div className="transfer-booking-item-info">
						<i className="fas fa-map-marker-alt"></i>
						<p className="mx-1">
							{details?.details?.destinations?.map((city) => {
								return (
									<span key={city?.code}>
										{city?.name} ({city?.code}),{" "}
									</span>
								);
							})}
						</p>
					</div>

					<div className="transfer-booking-item-info">
						<i className="far fa-calendar-alt"></i>
						<span className="mx-1 font-weight-bold">
							{moment(details?.start_date).format("DD/MM/YYYY")} (
							{moment(details?.start_date).format("dddd")})
						</span>
					</div>

					<div className="transfer-booking-item-info">
						<i className="far fa-user"></i>
						<span className="mx-2">
							{details?.adults} {inventory.messages.adults}
						</span>
						{details?.children?.length > 0 ? (
							<span>
								{details?.children?.length} {inventory.messages.children} (
								{details?.children?.map((age, index) => {
									return (
										<span key={`age-${index}-${age}`}>
											{age}
											{index !== details?.children?.length - 1 ? ", " : " "}
										</span>
									);
								})}
								Years Old )
							</span>
						) : null}
					</div>
				</div>
			</div>
			<p className="text-success text-center font-weight-bold">
				<span className='d-md-block'> {details?.price} </span>
				{details?.currency}
			</p>
		</div>
	);
}
