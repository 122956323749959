

export default function ifrem() {
	return (
		<div className=" statistics-container">
			<div className="row m-0  statistics">
				<div className="col-md-12">
				<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeaI354D-oCDgTVYIfQOKx9BPABTe4wkkoO5jA8S4RWUwSW1Q/viewform?embedded=true" width="100%" height="1532" frameborder="0" marginheight="0" marginwidth="0">جارٍ التحميل…</iframe>
					</div>
			</div>
		</div>
	);
}
