import { useSBSState } from "context/global";
import Locale from "translations";

export default function ShowFilters(props) {
	const { data, showMore, setShowMore, filterType,filter,setFilter } = props;
	const { productsBuilder } = Locale;
	const { locale } = useSBSState();
	return (
		<>
			{data.length > 0&& data.length < 4  || data.length > 0&&showMore[filterType]
				? data?.map((accommodation, idx) => {
						return (
							<li key={`accommodation-${idx}-${idx}`}>
								<div className="airlines-checkbox">
									<div className="custom-control custom-checkbox list-with-height">
										<input
											key={`accommodation-${idx}-${idx}`}
											className="custom-control-input permChecks"
											id={accommodation}
											name="accommodations"
											type="checkbox"
											value={accommodation}
											checked={filter.facilities&&filter.facilities.length>0?filter.facilities.includes(accommodation):false}
											onChange={(e)=>{
												let list =filter.facilities&&filter.facilities.length>0?[...filter.facilities]:[]
												if (e.target.checked) {
													list.push(accommodation)
												}else{
													list=list.filter(res=>res!=accommodation)
												}
												setFilter({...filter,facilities:list})
											}}
										/>
										<label
											className="custom-control-label my-1 text-capitalize"
											htmlFor={accommodation?.id}
										>
											{accommodation}
										</label>
									</div>
								</div>
							</li>
						);
				  })
				: data.length > 0? data.slice(0, 4).map((accommodation, idx) => {
						return (
							<li key={`accommodation-${accommodation}-${idx}`}>
								<div className="airlines-checkbox">
									<div className="custom-control custom-checkbox list-with-height">
										<input
											key={`accommodation-${accommodation}-${idx}`}
											className="custom-control-input permChecks"
											id={accommodation}
											name="accommodations"
											type="checkbox"
											checked={filter?.facilities&&filter?.facilities?.length>0?filter.facilities.includes(accommodation):false}
											value={accommodation}
											onChange={(e)=>{
												let list =filter?.facilities&&filter?.facilities?.length>0?[...filter.facilities]:[]
												if (e.target.checked) {
													list.push(accommodation)
												}else{
													list=list.filter(res=>res!=accommodation)
												}
												setFilter({...filter,facilities:list})
											}}
										/>
										<label
											className="custom-control-label my-1 text-capitalize"
											htmlFor={accommodation}
										>
											{accommodation}
										</label>
									</div>
								</div>
							</li>
						);
				  }):null}

			{data.length > 4 && (
				<p
					className="more"
					role="button"
					onClick={() =>
						setShowMore((prev) => ({
							...prev,
							[filterType]: !prev[filterType],
						}))
					}
				>
					{!showMore[filterType]
						? locale === "en"
							? `+${data.length - 4} more`
							: `+${data.length - 4} المزيد`
						: productsBuilder.showLess}
				</p>
			)}
		</>
	);
}
