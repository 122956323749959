


export function visaPassengerFactory(fromType, passengerData, countries) {
  let issue_country = countries.find((country) => +country.id === (+passengerData?.PPIssueCountry || +passengerData?.issue_country))
  let nationality = countries.find((country) => country.id === passengerData?.nationality_id)
  return {
    ...passengerData,
    gender:  passengerData?.gender ?{
      value: passengerData?.gender?.toLowerCase() === "male" ? 1 : passengerData?.gender?.toLowerCase() === "female" ? 2 : 0,
      code: passengerData?.gender?.toLowerCase() === "male" ? "male" : passengerData?.gender?.toLowerCase() === "female" ? "female" : "",
      label: passengerData?.gender,
    }:null,
    title:passengerData?.title? {
      value: passengerData?.title,
      label: passengerData?.title,
    }:null,

    full_name_ar: passengerData?.full_name_ar,
    full_name_en: passengerData?.full_name_en,
    first_name_ar: passengerData?.first_name_ar,
		first_name_en:passengerData?.first_name_en,
		last_name_en: passengerData?.last_name_en,
		last_name_ar: passengerData?.last_name_ar,
    father_name: { ar: passengerData?.father_name_ar, en: passengerData?.father_name_en },
    grandfather_name: { ar: passengerData?.grandfather_name_ar, en: passengerData?.grandfather_name_en },

    passport_type: {
      value: passengerData?.passport_type?.value || passengerData?.passport_type,
      label: passengerData?.passport_type?.name || passengerData?.passport_type,
    },
    marital_status: {
      value: passengerData?.marital_status,
      label: passengerData?.marital_status,
    },
    educational_level: {
      value: passengerData?.educational_level,
      label: passengerData?.educational_level,
    },
    country: passengerData?.country?{
      value: passengerData?.country,
      label: passengerData?.country
    }:null,
    nationality: {
      value: nationality?.id,
      label: nationality?.name
    },
    birth_country: passengerData?.birth_country ?{
      value: passengerData?.birth_country,
      label: passengerData?.birth_country,
    }:null,
    issue_country: issue_country?.id ?{
      value: issue_country?.id,
      label: issue_country?.name
    }:null,
    issue_place: passengerData?.issue_place,
    birth_place: passengerData?.birth_place,
    date_of_birth: passengerData?.date_of_birth,
    issue_date: passengerData?.issue_date,
    expire_date: passengerData?.expire_date,
    job: passengerData?.job,
    passport_number: passengerData?.passport_number,
    passport_photo: passengerData?.passport_photo,
    photo: passengerData?.photo,
    // mehrem_name: {
    //   value: passengerData?.mehrem_passport_number,
    //   label: passengerData?.mehrem_name,
    // },
    // relationship: {
    //   value: passengerData?.mehrem_relation_code,
    //   label: passengerData?.relationship
    // },
    mrz: passengerData?.mrz,
    ...(passengerData?.residence_data && {
        residencey_number: passengerData?.residence_data?.residence_number,
        residencey_issue_date: passengerData?.residence_data?.issue_date,
        residencey_expire_date: passengerData?.residence_data?.expiry_date,
    }),
  }
}