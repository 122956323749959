import React from 'react'
import { DangerIcon, NoteIcon } from 'modules/UmrahOperations/shared/Icons';
import { useHistory, useParams, Link } from 'react-router-dom';
import Locale from 'translations';

export default function AccountingAgentHeader({ agentData, toggle, activeTab }) {
  const { id } = useParams();
  const history = useHistory();

  return (
    <>
      <div className="accounting-agent-profile">
        <button className="btn p-0"
          onClick={() => history.push(`/operations/accounting/agent`)}
        >
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H16C20.4183 0.5 24 4.08172 24 8.5V16.5C24 20.9183 20.4183 24.5 16 24.5H8C3.58172 24.5 0 20.9183 0 16.5V8.5Z" fill="#EBEBEB" />
            <path d="M17.25 12.5H6.75M6.75 12.5L12 17.75M6.75 12.5L12 7.25" stroke="#707170" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>

        <div className="item name">
          <span className="value">{agentData?.name}</span>
          <span className="unit ">{agentData?.country?.name}</span>
        </div>
        {/* Operations */}
        <div className="item">
          <span className="unit">{Locale.operationAccounting.operations}</span>
          <span className="value">{agentData?.operations}</span>
        </div>
        {/* Pilgrims */}
        <div className="item">
          <span className="unit">{Locale.operationAccounting.pilgrims}</span>
          <span className="value">{agentData?.pilgrims}</span>
        </div>
        {/* Total invoices */}
        <div className="item">
          <span className="unit">{Locale.operationAccounting.totalInvoices}</span>
          <span className="value">
            {agentData?.total_invoices ? `$ ${agentData?.total_invoices}` : "-"}
          </span>
        </div>
        {/* Outstanding */}
        <div className="item">
          <span className="unit">{Locale.operationAccounting.outstanding}</span>
          <span className="value">
            {agentData?.outstanding_invoices ? `$ ${agentData?.outstanding_invoices}` : "-"}
          </span>
        </div>
        {/* Violations */}
        <div className="item">
          <span className="unit">{Locale.operationAccounting.violations}</span>
          <span className="value">
            {agentData?.violations_count || "-"}
          </span>
        </div>
        {/* Violations Costs */}
        <div className="item">
          <span className="unit">{Locale.operationAccounting.violationsCost}</span>
          <span className="value">
            {agentData?.violation_cost ? `$ ${agentData?.violation_cost}` : "-"}
          </span>
        </div>
      </div>


      <div className='tabs'>
        <Link to={`/operations/accounting/agent-statement/${id}`} className={`btn shadow-none movement-manage ${activeTab === "statement" ? "selected" : ""}`}
          onClick={() => {
            // toggle("statement");
            // history.push(`/operations/accounting/agent-statement/${id}`);
          }}
        >
          <NoteIcon />
          {Locale.operationAccounting.violationsCost}
        </Link>
        <Link to={`/operations/accounting/agent-violations/${id}`} className={`btn shadow-none movement-group ${activeTab === "violations" ? "selected" : ""}`}
          onClick={() => {
            // toggle("violations");
            // history.push(`/operations/accounting/agent-violations/${id}`)
          }}
        >
          <DangerIcon />
          {Locale.operationAccounting.ordersViolationsAccounting}
        </Link>
      </div>
    </>
  )
}
