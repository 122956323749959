import React from "react";
import { Row, Col } from "reactstrap";
import Locale from "translations";

export default function HotelMadinahAccommodation(props) {
	const { reservation } = Locale;
	return (
		<>
			<Row>
				<Col sm="3">
					<div className="travelers-box">
						<h2 className="head-title">{reservation.messages.travelers}</h2>
						<div className="traverlers-content">
							<div className="main-input-search">
								<input
									className="form-control"
									type="text"
									placeholder={reservation.messages.searchPlaceholder}
								/>
								<i className="fas fa-search fa-fw"></i>
							</div>

							<div className="traverlers-data active-drag">
								<div className="head">
									<h6 className="font-weight-bold">Dina Ahmed Abdallah</h6>
									<h6 className="room-no">Child</h6>
								</div>
								{/* <div className="icon-box">
                                            <i className="fas fa-bed"></i>
                                            <p>x2</p>
                                        </div> */}
								<div className="drag-icon">
									<i className="fas fa-hand-paper"></i>
								</div>
							</div>

							<div className="traverlers-data">
								<div className="head">
									<h6 className="font-weight-bold">Hesham Ali Mahmoud</h6>
									<h6 className="room-no">
										{reservation.messages.roomNo}:A1 | Adult
									</h6>
								</div>
								<div className="icon-box">
									<i className="fas fa-bed"></i>
									<p>x2</p>
								</div>
							</div>

							<div className="traverlers-data">
								<div className="head">
									<h6 className="font-weight-bold">Ahmed Hesham Ali</h6>
									<h6 className="room-no">
										{reservation.messages.roomNo}:A1 | Adult
									</h6>
								</div>
								<div className="icon-box">
									<i className="fas fa-bed"></i>
									<p>x1</p>
								</div>
							</div>

							<div className="traverlers-data">
								<div className="head">
									<h6 className="font-weight-bold">Ahmed Hesham Ali</h6>
									<h6 className="room-no">
										{reservation.messages.roomNo}:A1 | Adult
									</h6>
								</div>
								<div className="icon-box">
									<i className="fas fa-bed"></i>
									<p>x1</p>
								</div>
							</div>

							<div className="traverlers-data">
								<div className="head">
									<h6 className="font-weight-bold">Ahmed Hesham Ali</h6>
									<h6 className="room-no">
										{reservation.messages.roomNo}:A1 | Adult
									</h6>
								</div>
								<div className="icon-box">
									<i className="fas fa-bed"></i>
									<p>x1</p>
								</div>
							</div>

							<div className="traverlers-data">
								<div className="head">
									<h6 className="font-weight-bold">Ahmed Hesham Ali</h6>
									<h6 className="room-no">
										{reservation.messages.roomNo}:A1 | Adult
									</h6>
								</div>
								<div className="icon-box">
									<i className="fas fa-bed"></i>
									<p>x1</p>
								</div>
							</div>

							<div className="traverlers-data">
								<div className="head">
									<h6 className="font-weight-bold">Mahmoud Ahmed Hesham</h6>
									<h6 className="room-no">
										{reservation.messages.roomNo}:A1 | Adult
									</h6>
								</div>
								<div className="icon-box">
									<i className="fas fa-bed"></i>
									<p>Suit</p>
								</div>
							</div>

							<div className="traverlers-data">
								<div className="head">
									<h6 className="font-weight-bold">Ahmed Hesham Ali</h6>
									<h6 className="room-no">
										{reservation.messages.roomNo}:A1 | Adult
									</h6>
								</div>
								<div className="icon-box">
									<i className="fas fa-bed"></i>
									<p>x1</p>
								</div>
							</div>

							<div className="traverlers-data">
								<div className="head">
									<h6 className="font-weight-bold">Mahmoud Ahmed Hesham</h6>
									<h6 className="room-no">
										{reservation.messages.roomNo}:A1 | Adult
									</h6>
								</div>
								<div className="icon-box">
									<i className="fas fa-bed"></i>
									<p>Suit</p>
								</div>
							</div>
						</div>
					</div>
				</Col>
				<Col sm="9">
					<div className="rooms-box">
						<h2 className="head-title">{reservation.messages.rooms}</h2>
						<div className="rooms-content">
							<Row>
								<Col sm="4">
									<div className="room-item">
										<div className="room-head">
											<ul>
												<li className="gray-color">
													<i className="fas fa-bed px-1"></i>
													x2
													<span className="px-1">|</span>
												</li>
												<li>
													{reservation.messages.roomNo}
													<span className="font-weight-bold">A1</span>
												</li>
												<li>
													<button className="btn-clear">
														<i className="far fa-edit fa-fw"></i>
													</button>
												</li>
											</ul>
											<ul>
												<li>
													<button className="btn-clear">
														<i className="fas fa-bed fa-fw add-bed"></i>
													</button>
												</li>
												<li>
													<button className="btn-clear">
														<i className="fas fa-unlock-alt fa-fw"></i>
													</button>
												</li>
												<li>
													<button className="btn-clear">
														<i className="fas fa-trash-alt fa-fw"></i>
													</button>
												</li>
											</ul>
										</div>

										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
									</div>
								</Col>
								<Col sm="4">
									<div className="room-item bg-gray">
										<div className="room-head">
											<ul>
												<li className="gray-color">
													<i className="fas fa-bed px-1"></i>
													x2
													<span className="px-1">|</span>
												</li>
												<li>
													{reservation.messages.roomNo}
													<span className="font-weight-bold">A2</span>
												</li>
												<li>
													<button className="btn-clear">
														<i className="far fa-edit fa-fw"></i>
													</button>
												</li>
											</ul>
											<ul>
												<li>
													<button className="btn-clear">
														<i className="fas fa-bed fa-fw add-bed"></i>
													</button>
												</li>
												<li>
													<button className="btn-clear">
														<i className="fas fa-lock fa-fw text-success"></i>
													</button>
												</li>
												<li>
													<button className="btn-clear">
														<i className="fas fa-trash-alt fa-fw"></i>
													</button>
												</li>
											</ul>
										</div>

										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
									</div>
								</Col>
								<Col sm="4">
									<div className="room-item">
										<div className="room-head">
											<ul>
												<li className="gray-color">
													<i className="fas fa-bed px-1"></i>
													x2
													<span className="px-1">|</span>
												</li>
												<li>
													{reservation.messages.roomNo}
													<span className="font-weight-bold">A3</span>
												</li>
												<li>
													<button className="btn-clear">
														<i className="far fa-edit fa-fw"></i>
													</button>
												</li>
											</ul>
											<ul>
												<li>
													<button className="btn-clear">
														<i className="fas fa-bed fa-fw add-bed"></i>
													</button>
												</li>
												<li>
													<button className="btn-clear">
														<i className="fas fa-unlock-alt fa-fw"></i>
													</button>
												</li>
												<li>
													<button className="btn-clear">
														<i className="fas fa-trash-alt fa-fw"></i>
													</button>
												</li>
											</ul>
										</div>

										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
										<div className="room-box">
											<div className="room-data">
												<h6 className="font-weight-bold">
													Dina Ahmed Abdallah
												</h6>
												<p>Adult</p>
											</div>
											<div className="room-icon">
												<i className="far fa-edit fa-fw"></i>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
}
