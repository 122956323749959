import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
	CalenderDotsIcon,
	DollarCircleIcon,
	InfoCircleIcon,
	ItineraryIcon,
	PreviewCircleIcon
} from './Icons'
import TripPlannerHeader from './Header'
import TripPlannerItinerary from './ItineraryItems'
import TripPlannerPackageDetails from './PackageDetails'
import useLookupsApi from 'modules/products-builder/hooks/useLookups'
import { useParams } from 'react-router-dom'
import { deleteSingleTrip, viewPackageDetails } from 'services/tripPlanner'
import { formatTripPlannerData, successfulResponse } from '../helpers/formatTripPlannerData'
import TripPlannerPricing from './Pricing'
import TripPlannerSettingDates from './SettingDates'
import ItineraryPreview from './ItineraryPreview'
import Locale from 'translations'
import ShowSubscription from 'helpers/ShowSubscription'

export default function TripPlannerViewEdit() {
	// hooks
	const { packagePlanner, productsBuilder, dashboard } = Locale;
	const { id } = useParams();
	// states
	const [activeTab, setActiveTab] = useState('package-details')
	const [selectedDay, setSelectedDay] = useState(null)
	const [tripPlannerData, setTripPlannerData] = useState({
		packageDetails: null,
		itinerary_days: [],
	});
	const [previewItinraryOpen, setPreviewItinraryOpen] = useState(false);

	const [lookups] = useLookupsApi();


	const tripPlannerTabs = [
		{
			id: 'package-details',
			title: packagePlanner.packageDetails,
			icon: <InfoCircleIcon color='#707170' />,
			disabled: false
		},
		{
			id: 'itinerary',
			title: productsBuilder.itinerary,
			icon: <ItineraryIcon color='#707170' />,
			disabled: tripPlannerData.itinerary_days.length === 0
		},
		{
			id: 'setting-dates',
			title: packagePlanner.tripsDates,
			icon: <CalenderDotsIcon color='#707170' />,
			disabled: false
		},
		{
			id: 'pricing',
			title: productsBuilder.pricing,
			icon: <DollarCircleIcon color='#707170' />,
			disabled: false
		}
	];


	// get view edit trip
	async function getPackageDetails() {
		const res = await viewPackageDetails(id);
		if (res?.status === 200) {
			const formateTripData = formatTripPlannerData(res?.data?.data);
			setTripPlannerData(formateTripData);
		}
	}


	useEffect(() => {
		if (id) {
			getPackageDetails()
		}
	}, [])

	// delete Single Trip when not publish
	async function deleteNonPublishTrip(e, product_uuid) {
		e.stopPropagation()
		const res = await deleteSingleTrip(product_uuid);
		if (res?.status === 200) {
			setTripPlannerData({ ...tripPlannerData, trips: tripPlannerData?.trips?.filter(trip => trip?.product_uuid !== product_uuid) });
			successfulResponse("trip deleted successfuly");
		}
	}

	return (
		<ShowSubscription module={"package_planner"}>
			<div className='trip-planner-view-wrapper'>
				{/* bread crumb */}
				<div className='bread-crumb'>
					<div className='text-capitalize'>
						<Link to='/trip-planner'>
							<span className='link'>{dashboard.messages.packagePlanner}/</span>
						</Link>{' '}
						{packagePlanner.packageDetails}
					</div>
				</div>
				{/* trip details header */}
				{id ?
					<TripPlannerHeader
						destinations={tripPlannerData.packageDetails?.destinations}
						duration={tripPlannerData.packageDetails?.duration}
						jourenyType={tripPlannerData.packageDetails?.type}
					/>
					: null}

				{/* tabs contetn */}
				<div className='trip-planner-content'>
					<div className='d-flex justify-content-between align-items-center border-bottom'>
						<TripPlannerTabs
							tabs={tripPlannerTabs}
							activeTab={activeTab}
							setActiveTab={setActiveTab}
						/>
						{activeTab === 'itinerary' ?
							<div className='d-flex align-items-center flex-wrap'>
								<button className="btn  bg-nxt d-flex align-items-center px-4 py-2" onClick={() => setPreviewItinraryOpen(true)}>
									<PreviewCircleIcon color="#fff" />
									<span className="mx-1">{packagePlanner.preview}</span>

								</button>
							</div>
							:
							null
						}
					</div>

					{activeTab === 'package-details' ? (
						<TripPlannerPackageDetails
							productTypes={lookups.product_types}
							productClassifications={lookups.product_classifications}
							destinations={lookups.destination_types}
							setActiveTab={setActiveTab}
							packageDetails={tripPlannerData?.packageDetails}
							setTripPlannerData={setTripPlannerData}
							canEdit={!tripPlannerData?.trips || tripPlannerData?.trips?.length === 0}
						/>
					) : null}

					{activeTab === 'itinerary' ? (
						<TripPlannerItinerary
							selectedDay={selectedDay}
							setSelectedDay={setSelectedDay}
							tripPlannerData={tripPlannerData}
							setTripPlannerData={setTripPlannerData}
						/>
					) : null}
					{activeTab === 'setting-dates' ? (
						<TripPlannerSettingDates
							tripPlannerData={tripPlannerData}
							setTripPlannerData={setTripPlannerData}
						/>
					) : null}

					{activeTab === 'pricing' ? (
						<div className='tab-content'>
							<TripPlannerPricing
								trips={tripPlannerData?.trips}
								productType={tripPlannerData?.packageDetails?.type}
								deleteNonPublishTrip={deleteNonPublishTrip}
							/>
						</div>
					) : null}
				</div>
				{/* preview itinerary */}
				{activeTab === 'itinerary' ? (
					<ItineraryPreview
						isOpen={previewItinraryOpen}
						setPreviewItinraryOpen={setPreviewItinraryOpen}
						itineraryDays={tripPlannerData.itinerary_days}
					/>
				) : null}
			</div>
		</ShowSubscription>
	)
}

export function TripPlannerTabs({ tabs, activeTab, setActiveTab }) {
	return (
		<ul className={`trip-planner-tabs`}>
			{tabs?.map((tab) => {
				return (
					<li
						className={`item ${activeTab === tab?.id ? 'active' : ''}  ${tab?.disabled ? 'disabled-li-Tabs' : ''
							}`}
						key={tab?.url}
						onClick={() => !tab?.disabled && setActiveTab(tab?.id)}
					>
						{tab?.icon}
						<span className='mx-1'>{tab?.title}</span>
					</li>
				)
			})}
		</ul>
	)
}
