import { useSBSState } from "context/global";
import DashboardLayout from "layouts/DashboardLayout";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { MarketPlaceProvider } from "../../context/marketplace";
import { WebBuilderProvider } from 'context/webBuilder';
// React Component
export default function WebBuilderRoute({ component: Component, ...props }) {
	const { isAuth } = useSBSState();

	if (true) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<WebBuilderProvider>
												{/* <DashboardLayout> */}
							<Component {...matchProps} />
												{/* </DashboardLayout> */}
					</WebBuilderProvider>
				)}
			/>
		);
	} else {
		return <Redirect to="/" />;
	}
}
