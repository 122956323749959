import NumberField from 'components/Form/NumberField/NumberField'
import React from 'react'
import Locale from 'translations';

// use in attrations, transportations, other services

export default function AccountingServicePricing({ type, typeCount, sellingPrice, buyingPrice, handleInputsChanges, itemIndex, canEdit }) {
  const { operationStatement } = Locale;
  const profitAmount = (sellingPrice || 0) - (buyingPrice || 0);

  return (
    <div className="pricing-inputs-wrapper">
      <div className="pricing-inputs justify-content-center mt-3">
        {type && <span className="item pax">{type}</span>}
        <span className="item buying">{operationStatement.BuyingCost}</span>
        <span className="item selling">{operationStatement.Sellingcost}</span>
        <span className="item profit">{operationStatement.Profit}</span>
      </div>

      <div className="pricing-inputs justify-content-center">
        {typeCount && <span className="item pax-count">{typeCount}</span>}
        <div className="item p-0">
          <NumberField
            hasLabel={false}
            placeholder={operationStatement.Price}
            removeArrow={true}
            value={buyingPrice}
            onChange={(e) => handleInputsChanges(e.target.value, "buying_price", itemIndex)}
            onWheel={(e) => e.target.blur()}
            disabled={!canEdit}
          />
        </div>
        <div className="item p-0">
          <NumberField
            hasLabel={false}
            placeholder={operationStatement.Price}
            removeArrow={true}
            value={sellingPrice}
            onChange={(e) => handleInputsChanges(e.target.value, "selling_price", itemIndex)}
            onWheel={(e) => e.target.blur()}
            disabled={!canEdit}
          />
        </div>
        <span className="item total-profit mb-0">{profitAmount < 0 ? 0 : profitAmount?.toFixed(2)}</span>
      </div>
    </div>
  )
}
