import React, { useCallback, useState } from "react";
import Search from "./Search";
import TravelerDetails from "./TravelerDetails";
import { useProductsBuilderState } from "context/productsBuilder";

function AccommodationTravelers() {
	const {
		hotel_accommodation_travelers: travelers,
		selected_hotel_accommodation_id: selectedHotelId,
	} = useProductsBuilderState();
	const [travelerFilter, setTravelerFilter] = useState("");


	const showTraveler = useCallback(
		(traveler) => {
			return (
				!traveler?.roomAvailabilities.find((room) => room.product_hotel_id === selectedHotelId)
					?.roomId 
					
					&&
				(`${traveler.first_name} ${traveler.last_name}`
					.toLocaleLowerCase()
					.includes(travelerFilter.toLocaleLowerCase()) ||
					travelerFilter.trim() === "")
			);
		},
		[travelerFilter, travelers, selectedHotelId]
	);
	console.log("sladklaskdlaskdlasdkl",travelers);

	const handleTravelerFilter = (name) => {
		setTravelerFilter(name);
	};

	return (
		<div className="travelers-accommodation">
			<h4 className="travelers-head">Travelers</h4>
			<Search
				travelerFilter={travelerFilter}
				handleTravelerFilter={handleTravelerFilter}
			/>
			<hr className="my-4" />
			{travelers?.length > 0
				? travelers.map((item, index) => {
						return showTraveler(item) ? (
							<TravelerDetails item={item} key={item.id} />
						) : null;
				  })
				: null}
		</div>
	);
}

export default AccommodationTravelers;
