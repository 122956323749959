import React from "react";
import CustomTable from "../Components/CustomTableReserv/CustomTable";

export default function ReservationList() {
  return (
    <>
      <CustomTable />
    </>
  );
}
