import { useSBSState } from "context/global";
import { useEffect } from "react";
import Locale from "translations";

export default function Voucher() {
	const { inventory } = Locale;
	const { voucher } = useSBSState();

	useEffect(() => {
		setTimeout(() => {
			window.print();
		}, 3000);
		// if (window.close()) {
		// 	history.push("/inventory-voucher");
		// }
	}, []);
	const onlineVisaURL = process.env.REACT_APP_API_URL + "/v1/files/fetch/";

	return (
		<div className="voucher container bg-white p-4">
			<div className="container-wrapper p-4">
				<div className="voucher-header d-flex  my-5">
					<div className="d-flex flex-column header-name">
						<div className="header-img  p-4 text-center">
							{voucher?.company?.avatar ? (
								<img
									src={`${onlineVisaURL}${voucher?.company?.avatar}`}
									alt="hotal-logo"
									width={100}
									height={100}
								/>
							) : (
								<div className="letter-logo">{voucher?.company?.name[0]}</div>
							)}
							{/* <img
								src={HotelColored}
								alt="hotal-logo"
								width={100}
								height={100}
							/> */}
							{/* <div className="letter-logo">{voucher?.bookingStatus[0]}</div> */}
						</div>
						<h4>{voucher?.company?.name}</h4>
					</div>

					<div className="header-data">
						<div className="row">
							<div className="col-6">
								<h5>{inventory.messages.bookingReference} :</h5>
							</div>
							<div className="col-6">
								<p>{voucher?.bookingCode}</p>
							</div>
						</div>

						<div className="row">
							<div className="col-6">
								<h5>{inventory.messages.printedOn} :</h5>
							</div>
							<div className="col-6">
								<p>{voucher?.printedOn}</p>
							</div>
						</div>

						<div className="row">
							<div className="col-6">
								<h5>{inventory.messages.confirmationNO} :</h5>
							</div>

							<div className="col-6">
								<p>{voucher?.confirmationNumber}</p>
							</div>
						</div>

						<div className="row">
							<div className="col-6">
								<h5>{inventory.messages.bookingStatus} :</h5>
							</div>
							<div className="col-6 ">
								<p className="text-success">{voucher?.bookingStatus}</p>
							</div>
						</div>

						<div className="row">
							<div className="col-6">
								<h5>{inventory.messages.bookedBy} :</h5>
							</div>
							<div className="col-6">
								<p>{voucher?.bookedBy}</p>
							</div>
						</div>

						{/* <div className="row">
							<div className="col-6">
								<h5>{inventory.messages.referenceNumber} :</h5>
							</div>
							<div className="col-6">
								<p>{voucher?.referenceNumber}</p>
							</div>
						</div> */}
					</div>
				</div>
				<hr />

				<div className="services my-5">
					<h3>{inventory.messages.serviceProviderDetails}</h3>
					<div className="service-data px-4 py-2">
						<div className="row">
							<div className="col-3">
								<h5>{inventory.messages.hotelName} :</h5>
							</div>
							<div className="col-9">
								<p>{voucher?.hotel_name}</p>
							</div>
						</div>

						<div className="row">
							<div className="col-3">
								<h5>{inventory.messages.address} :</h5>
							</div>
							<div className="col-9">
								<p>{voucher?.hotel_address}</p>
							</div>
						</div>

						{voucher?.hotel_phone === "" ||
							voucher?.hotel_phone == undefined ? null : (
							<div className="row">
								<div className="col-3">
									<h5>{inventory.messages.telephone} :</h5>
								</div>
								<div className="col-9">
									<p>{voucher?.hotel_phone}</p>
								</div>
							</div>
						)}
					</div>
				</div>
				<hr />

				<div className="passenger my-5">
					<h3>{inventory.messages.passengerDetails}</h3>
					<div className="passenger-data px-4 py-2">
						<div className="row">
							<div className="col-3">
								<h5>{inventory.messages.passengerName} :</h5>
							</div>
							<div className="col-9">
								<p>{voucher?.passenger?.name}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-3">
								<h5>{inventory.messages.passengerNationality} :</h5>
							</div>
							<div className="col-9">
								<p>{voucher?.passenger?.nationality}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-3">
								<h5>{inventory.messages.CountryResidence} :</h5>
							</div>
							<div className="col-9">
								<p>{voucher?.passenger?.country}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-3">
								<h5>{inventory.messages.serviceType} :</h5>
							</div>
							<div className="col-9">
								<p>{voucher?.passenger?.service_type}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-3">
								<h5>{inventory.messages.city} :</h5>
							</div>
							<div className="col-9">
								<p>{voucher?.passenger?.city}</p>
							</div>
						</div>
						<div className="passenger-info my-4">
							<div className="row">
								<div className="mb-3 col-sm-4">
									<h6 className="">{inventory.messages.checkin}</h6>
									<p className="font-weight-bold">{voucher?.checkin}</p>
								</div>
								<div className="mb-3 col-sm-4">
									<h6>{inventory.messages.checkOut}</h6>
									<p className="font-weight-bold">{voucher?.checkout}</p>
								</div>
								<div className="mb-3 col-sm-4">
									<h6>{inventory.messages.roomType}</h6>
									<p className="font-weight-bold">{voucher?.roomType}</p>
								</div>

								<div className="mb-2 col-sm-8">
									<h6>{inventory.messages.roomCategory}</h6>
									<p className="font-weight-bold">{voucher?.roomCategory}</p>
								</div>
								<div className="mb-2 col-sm-4">
									<h6>{inventory.messages.MealPlan}</h6>
									<p className="font-weight-bold">{voucher?.rateBasis}</p>
								</div>
							</div>
						</div>
						<div className="row mb-5">
							<div className="col-3">
								<h5>{inventory.messages.additionalRequests} :</h5>
							</div>
							<div className="col-9">
								<p>{voucher?.additionalRequests}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
