import React, { useEffect, useState } from 'react'
import ToursBookingDetails from './BookingDetails';
import ToursBookingTopbar from './BookingTopbar';
import ToursBookingSummary from './BookingSummary';
import { getInventory } from 'services/tours';
import { useParams, useHistory } from 'react-router-dom';


export default function TourReservation() {
	const { id } = useParams();
	const history = useHistory();
	const [bookingDetails, setBookingDetails] = useState(null);
	const [reloadData, setReloadData] = useState(false);

	async function fetchTourReservation() {
		const reservationRes = await getInventory(id);
		if (reservationRes?.status === 200) {
			setBookingDetails(reservationRes.data?.tour);
		}
	}

	function toggleReloadData() {
		setReloadData(!reloadData)
	}

	useEffect(() => {
		if (id) {
			fetchTourReservation();
		} else {
			history.push('/inventory/tour');
		}
		return () => { };
	}, [id, reloadData]);

	return (
		<>
			{bookingDetails ?
				<div className="container interest border p-md-0 p-1 transfer-booking-container">
					<ToursBookingTopbar paymentStatus={bookingDetails?.payment_status} reservationStatus={bookingDetails?.status} reservations={bookingDetails?.reservations} />
					<div className="d-flex justify-content-between flex-wrap p-md-3 py-2 bg-white">
						{/* right side */}
						<ToursBookingDetails
							key={bookingDetails?.brn}
							bookingDetails={bookingDetails}
							setBookingDetails={setBookingDetails}
							toggleReloadData={toggleReloadData}
						/>

						{/* left side */}
						<ToursBookingSummary bookingDetails={bookingDetails} setBookingDetails={setBookingDetails} />

					</div>
				</div>
				:
				null
			}
		</>
	);
}
