import { useWebBuilderState } from "context/webBuilder";
import EditableParagraph from "modules/WebBuilder-V2/shared/EditableParagraph";
import React from "react";

export default function FAQTheme4({ handleFaqs, faqs }) {
	const { selectedLanguage } = useWebBuilderState();
	return (
		<div className="faq-container">
			{faqs &&
				faqs?.map((que, index) => (
					<div className="faq-card-holder">


						{/* <h2 className="faq-title">{}</h2> */}

            <EditableParagraph
							className="faq-title"
							contentEditable={true}
							onInput={(value) =>
								handleFaqs({
									value,
									name: "question",
									questionIndex: index,
									language: selectedLanguage,
								})
							}
							text={que.question[selectedLanguage]}
						/>

						<EditableParagraph
							className="faq-desc"
							contentEditable={true}
							onInput={(value) =>
								handleFaqs({
									value,
									name: "body",
									questionIndex: index,
									language: selectedLanguage,
								})
							}
							text={que.body[selectedLanguage]}
						/>
						{/* <p className='faq-desc'>
          {faq?.description}
          </p> */}
					</div>
				))}
		</div>
	);
}
