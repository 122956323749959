import { generateBigBusSeats, generateMiniBusSeats } from './generateBusSeats';

export function formatProductDetails(data, busBluePrint) {
  const productData = data;
  const id = data?.id;
  const busesData = productData?.buses;
  // all disabled seats in all buses
  const disabledSeats = productData?.bus_with_travellers?.filter(t=> t?.traveller === null);
  let productBuses = productData?.buses?.length > 0 ?
    busesData?.map(bus => {
      const travelers = productData?.reservations_travellers;
      const busId = bus?.id
      return {
        ...bus,
        bus_type: {
          id: bus?.bus_type_id,
          value: bus?.bus_type_id,
          label: `${bus?.bus_type_name} ${bus?.capacity}`,
          name: `${bus?.bus_type_name} ${bus?.capacity}`,
        },
        seats: bus?.capacity > 1 && bus?.capacity < 45 ?
          generateMiniBusSeats(bus?.capacity, busId, travelers, disabledSeats) :
          generateBigBusSeats(bus?.capacity, busId, travelers, disabledSeats),
        isSaved: true,
        editMode: false,
      }
    }) :
    [{ ...busBluePrint }];

  let productTravelers = productData?.reservations_travellers?.filter(traveler=> traveler?.first_name && traveler?.last_name && traveler?.passport_number)?.map(traveler => {
    return {
      id: traveler?.id,
      name: `${traveler?.first_name} ${traveler?.last_name}`,
      gender: traveler?.gender?.toLowerCase(),
      passport_number: traveler?.passport_number,
      package_reservation_id: traveler?.package_reservation_id,
      package_reservation_num:traveler?.package_reservation_num,
      reservation_category:traveler?.reservation_category,
      bus: traveler?.bus_seat && busesData?.find(bus => bus?.id === traveler?.bus_seat?.bus_id) ?
        {
          id: traveler?.bus_seat?.bus_id,
          seat: {
            passenger_id: traveler?.id,
            seatStatus: "booked",
            seatNumber: (traveler?.bus_seat?.seat_number).toString().padStart(2, '0'),
          }
        }
        :
        null,
    }
  })
  return { id, busesData: productBuses, travelersData: productTravelers }
}