import React from "react";

export default function PlusIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M6.54534 12.5625H11.1408V17.1579C11.1408 17.625 11.525 18.0167 11.9996 18.0167C12.4742 18.0167 12.8584 17.625 12.8584 17.1579V12.5625H17.4538C17.9209 12.5625 18.3126 12.1783 18.3126 11.7037C18.3126 11.2291 17.9209 10.8449 17.4538 10.8449H12.8584V6.24944C12.8584 5.78237 12.4742 5.39062 11.9996 5.39062C11.525 5.39062 11.1408 5.78237 11.1408 6.24944V10.8449H6.54534C6.07826 10.8449 5.68652 11.2291 5.68652 11.7037C5.68652 12.1783 6.07826 12.5625 6.54534 12.5625Z"
				fill="black"
			/>
		</svg>
	);
}
