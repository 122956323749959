import { useSBSState } from "context/global";
import { useMarketplaceState } from "context/marketplace";
import moment from "moment";
import React from "react";
import Locale from "translations";

function SortFilghtFilter({ title, flightCount, filters, setFilters }) {
	const { inventory, marketPlace } = Locale;
	const { flightsSearch } = useMarketplaceState();

	function handleSort(sort_by, sort_type) {
		if (filters.sortBy === sort_by && filters.sortType === "asc") {
			sort_type = "desc";
		} else if (filters.sortBy === sort_by && filters.sortType === "desc") {
			sort_type = "asc";
		} else {
			sort_type = "desc";
		}
		setFilters({ ...filters, sortBy: sort_by, sortType: sort_type });
	}
	const { allCountries } = useSBSState();

	const fromCountryId =
		flightsSearch.type === "3"
			? flightsSearch?.airLegs[0]?.from?.country_id
			: flightsSearch?.from?.country_id;

	const toCountryId =
		flightsSearch.type === "3"
			? flightsSearch?.airLegs[0]?.to?.country_id
			: flightsSearch?.to?.country_id;

	const fromCountry = allCountries.find((i) => i.id === fromCountryId)?.name;
	const toCountry = allCountries.find((i) => i.id === toCountryId)?.name;
	const departure = moment(
		flightsSearch.type === "3"
			? flightsSearch?.airLegs[0]?.data
			: flightsSearch?.departureDate
	).format("MMM D");
	return (
		<>
			<div className="select-flight">
				<div className="sorting d-flex flex-column align-items-start m-0 ">
					<div className="d-flex justify-content-between align-items-end  available-search w-100">
						<div className="select-flight-title ml-2  mb-1 make-text-hight">
							<h6 className="text-uppercase font-weight-bold search-type h4">
								{title}
							</h6>
							<span>
								{fromCountry} - {toCountry} {departure}
							</span>
						</div>
						<span>{marketPlace.timesLocal}</span>
					</div>
					<div className="sort-filter mx-2 my-1 d-flex align-items-center justify-content-between w-100">
						<div>
							<span style={{ color: "#000000" }}>
								{`${marketPlace.result}: ${flightCount} ${marketPlace.flights}`}
							</span>
						</div>
						<div>
							<span>{marketPlace.messages.sortBy} :</span>
							<button
								className="toggle-sort"
								onClick={(e) => {
									handleSort("price", "asc");
								}}
							>
								<i
									className={`${
										filters?.sortBy === "price" && filters?.sortType === "asc"
											? "fa fa-sort-amount-up"
											: "fa fa-sort-amount-down"
									}  mx-1`}
								></i>
								{marketPlace.messages.price}
							</button>

							{/* <button className="toggle-sort">
								<i className={`fa fa-sort-amount-up  mx-1`}></i>
								{marketPlace.messages.rating}
							</button> */}
							{/* <select
								placeholder="number of stops"
								className="toggle-sort"
								defaultValue={"0"}
							>
								<option disabled defaultValue={"0"} value="0">
									number of stops
								</option>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
							</select> */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SortFilghtFilter;
