import generateUniqueID from "helpers/generateUniqueID";

export default function CheckBox(props) {
	let uniqueID = generateUniqueID();
	return (
		<>
			<input
				type="Checkbox"
				name={props.name}
				value={props.value}
				checked={props.checked}
				onChange={props.onChange}
				id={"checkbox" + uniqueID}
				disabled={props.disabled}
			/>

			<label
				className="form-check-label pointer text-caption mx-2"
				htmlFor={"checkbox" + uniqueID}
			>
				{props.text}
			</label>
		</>
	);
}
