import React, { useMemo, useState } from 'react'
import { CloseIcon } from './Icons'
import { useDetectClickOutside } from "react-detect-click-outside";
import { Collapse } from 'reactstrap';
import { AttractionItinerary, ExternalTransportationItinerary, HotelItinerary, InternalTransportationItinerary, MoreDetailsModal } from 'modules/market-place/NewPackageResponse/Details/ProductItinerary';
import Locale from 'translations';


const itineraryElementsIds = {
  external: 1,
  internal: 2,
  visa: 4,
  insurance: 5,
  hotel: 6,
  sightseeing: 7,
  otherServices: 8
}



export default function ItineraryPreview({ isOpen, setPreviewItinraryOpen, itineraryDays }) {
  const { productsBuilder } = Locale;
  // state
  const [detailsModalData, setDetailsModalData] = useState({
    isOpen: false,
    description: "",
    typeId: null,
  });

  function toggleDetailsModal(description, typeId) {
    setDetailsModalData({
      isOpen: !detailsModalData.isOpen,
      description,
      typeId,
    });
  }

  const detectClickOutsideRef = useDetectClickOutside({
    onTriggered: isOpen ? () => setPreviewItinraryOpen(false) : () => { }
  });

  return (
    <div className={`itinerary-days-preview ${isOpen ? "opened" : ""}`} ref={detectClickOutsideRef}>
      <button className="btn shadow-none close-preview-btn" onClick={() => setPreviewItinraryOpen(false)}>
        <CloseIcon color="#000" />
      </button>
      <h2 className="title mb-3">{productsBuilder.itinerary}</h2>
      <div className="product-itinerary-container">
        {itineraryDays?.filter(day => day?.items?.length > 0)?.map((day, index) => {

          return (
            <ItineraryDayPreview
              key={day.day}
              dayData={day}
              dayIndex={index}
              toggleDetailsModal={toggleDetailsModal}
            />
          )
        })}
      </div>

      {detailsModalData?.isOpen ?
        <MoreDetailsModal
          detailsModalData={detailsModalData}
          toggle={toggleDetailsModal}
          itineraryElementsIds={itineraryElementsIds}
        />
        :
        null
      }
    </div>
  )
}




export function ItineraryDayPreview({ dayData, dayIndex, toggleDetailsModal }) {
  const { productsBuilder } = Locale;
  const [isDayOpen, setIsDayOpen] = useState(dayIndex === 0 ? true : false);


  const viewItineraryElements = useMemo(() => {
    const elementsCount = dayData?.items?.length;
    const hotelWithExternalItems = elementsCount > 1 &&
      dayData?.items?.filter(item => item?.item?.id === itineraryElementsIds.hotel || item?.item?.id === itineraryElementsIds.external).length > 1;

    const isFirstItineraryItemHasHotel =
      dayIndex === 0 &&
      dayData?.items?.filter(item => item?.item?.id === itineraryElementsIds.hotel).length > 0;

    const isLastItineraryItemHasHotel =
      dayIndex === dayData?.items.length - 1 &&
      dayData?.items?.filter(item => item?.item?.id === itineraryElementsIds.hotel).length > 0;

    return dayData?.items?.map((element, index) => {
      switch (element?.item?.id) {
        case 1:
          return (
            <ExternalTransportationItinerary
              key={element?.id}
              flightData={element}
              toggleDetailsModal={toggleDetailsModal}
            />
          )
        case 2:
          return (
            <InternalTransportationItinerary
              key={element?.id}
              toggleDetailsModal={toggleDetailsModal}
              internalData={element}
            />
          )
        case 6:
          return (
            <HotelItinerary
              key={element?.id}
              hotelIndex={index}
              elementsCount={elementsCount}
              hotelData={element}
              hotelWithExternalItems={hotelWithExternalItems}
              toggleDetailsModal={toggleDetailsModal}
              isFirstItineraryItemHasHotel={isFirstItineraryItemHasHotel}
              isLastItineraryItemHasHotel={isLastItineraryItemHasHotel}
            />
          )
        case 7:
          return (
            <AttractionItinerary
              key={element?.id}
              attractionData={element}
              toggleDetailsModal={toggleDetailsModal}
            />
          )
        default:
          return null;
      }
    })
  }, [dayData?.items, dayIndex, toggleDetailsModal])


  return (
    <>
      <div className="d-flex align-items-center justify-content-between pointer mb-3" onClick={() => setIsDayOpen(!isDayOpen)}>
        {productsBuilder.day} {dayData?.day}
        <button className="btn shadow-none">
          {isDayOpen
            ? <i className="fas fa-chevron-up collapse-arrow"></i>
            : <i className="fas fa-chevron-down collapse-arrow"></i>
          }
        </button>
      </div>
      <Collapse isOpen={isDayOpen}>
        <div className="itinerary-timeline">
          {viewItineraryElements}
        </div>
      </Collapse>
    </>
  )
}

