import React from "react";
import AdminBankTransferFilter from "./AdminBankTransferFilter";
import AdminBankTransferTable from "./AdminBankTransferTable";

export default function AdminBankTransfer(props) {
	return (
		<>
			<div className="payment-admin">
				<div className="text-center py-4 text-title ">
					<h2 className="text-decor text-dark">Menu</h2>
				</div>
				<AdminBankTransferFilter />
				<AdminBankTransferTable />
			</div>
		</>
	);
}
