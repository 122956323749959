import NumberField from "components/Form/NumberField/NumberField";
import React from "react";
import { ReactComponent as BedIcon } from "assets/images/umrah-operations/bed.svg";
import Locale from 'translations';

export default function AccountingHotelPricing({ hotel, handlePriceingFields, handlePriceingFieldsTotal, canEdit }) {
	const { operationStatement,marketPlace } = Locale;
	let total_buying_price = hotel.rooms?.reduce((acc, cur) => acc + cur.room_count * cur.buying_price, 0)
	let total_selling_price = hotel.rooms?.reduce((acc, cur) => acc + cur.room_count * cur.selling_price, 0)
	const profit = +total_selling_price - +total_buying_price;
	const roomTypesLookup = {
		single: marketPlace.x1,
		double: marketPlace.x2,
		triple: marketPlace.x3,
		quadruple: marketPlace.x4,
		quintuple: marketPlace.x5,
		sixtuple: marketPlace.x6,
		// 7: marketPlace.x7,
		// 8: marketPlace.suite,
		// 9: marketPlace.flatRate,
	};
	return (
		<div className="hotel-pricing">
			<div className="pricig-row">
				<span className="col-md-2 p-0 pax">{operationStatement.Roomtype}</span>
				<span className="col-md-2 p-0 pax">{operationStatement.Numberrooms}</span>
				<div className="buying">
					<span className="row-head">{operationStatement.BuyingCost}</span>
					<div className="d-flex justify-content-center gap-10">
						<span className="row-sub-head">{operationStatement.Priceperroom}</span>
						<span className="row-sub-head">{operationStatement.Total}</span>
					</div>
				</div>

				<div className="selling">
					<span className="row-head">{operationStatement.Sellingcost}</span>
					<div className="d-flex justify-content-center gap-10">
						<span className="row-sub-head">{operationStatement.Priceperroom}</span>
						<span className="row-sub-head">{operationStatement.Total}</span>
					</div>
				</div>
			</div>
			{/*Start Room Type */}
			{hotel?.rooms?.map((item, index) =>
				<div className="pricig-row" key={`room-${item?.room_type}-${index}`}>
					<span className="col-md-2 p-0 pricing-label">
						<BedIcon />
						<span className="mx-2">{roomTypesLookup[item.room_type]}</span>
					</span>
					<span className="col-md-2 p-0 pricing-label">{item.room_count}</span>

					<div className="buying-content">
						<div className="d-flex justify-content-center gap-10">
							<span className="row-sub-head">
								<NumberField
									hasLabel={false}
									placeholder={operationStatement.Price}
									removeArrow={true}
									value={item.buying_price}
									onChange={(e) => handlePriceingFields(+e.target.value, item.room_type, "buying_price")}
									onWheel={(e) => e.target.blur()}
									disabled={!canEdit}
								/>
							</span>
							<span className="row-sub-head">
								<NumberField
									hasLabel={false}
									placeholder={operationStatement.total}
									removeArrow={true}
									// value={item.buying_price?item.buying_price*item.room_count:""}  
									value={item?.total_buying_price}
									onChange={(e) => handlePriceingFieldsTotal(+e.target.value, item.room_type, "buying_price")}
									onWheel={(e) => e.target.blur()}
									disabled={!canEdit}
								/>
							</span>
						</div>
					</div>

					<div className="selling-content">
						<div className="d-flex justify-content-center gap-10">
							<span className="row-sub-head">
								<NumberField
									hasLabel={false}
									placeholder={operationStatement.price}
									removeArrow={true}
									value={item.selling_price}
									onChange={(e) => handlePriceingFields(+e.target.value, item.room_type, "selling_price")}
									onWheel={(e) => e.target.blur()}
									disabled={!canEdit}
								/>
							</span>
							<span className="row-sub-head">
								<NumberField
									hasLabel={false}
									placeholder={operationStatement.total}
									removeArrow={true}
									// value={item.selling_price?item.selling_price*item.room_count:""} 
									value={item?.total_selling_price}
									onChange={(e) => handlePriceingFieldsTotal(+e.target.value, item.room_type, "selling_price")}
									onWheel={(e) => e.target.blur()}
									disabled={!canEdit}
								/>
							</span>
						</div>
					</div>
				</div>
			)}

			{/*End Room Type */}

			{/* Start Total purchase & sales */}
			<div className="pricig-row sales">
				<span className="col-33 pax">{operationStatement.TotalPurchaseSales}</span>
				<div className="buying-content total" style={{ flexBasis: "30%" }}>
					<span className="row-data buying-amount font-weight-bold">{(+total_buying_price).toFixed(2) || 0}</span>
				</div>
				<div className="selling-content total" style={{ flexBasis: "30%" }}>
					<span className="row-data selling-amount font-weight-bold">{(+total_selling_price).toFixed(2) || 0}</span>
				</div>
			</div>

			{/* End Total purchase & sales */}

			{/* Start Profit */}
			<div className="pricig-row Profit">
			<span className="col-33 pax">{operationStatement.Profit}</span>
				<span className=" profit-amount flex-grow-1">{profit < 0 ? 0 : profit?.toFixed(2)}</span>
			</div>
			{/* End Profit */}
		</div>
	);
}
