import React, { useState } from "react";
import iconNotification from "assets/images/Tds/walletTDS.svg";
import alletTDS from "assets/images/Tds/alletTDS.svg";
import visa from "assets/images/Tds/actVisa.svg";
import disVisa from "assets/images/grayVisa.svg";
import walletgray from "assets/images/walletgray.svg";


import {  Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";

export default function StaticOnlinePaymentAction({
	currency,
	Price,
	isOpen,
	toggleModal,
	onPayment,
}) {

	const { payment, inventory } = Locale;
	const [check, setcheck] = useState("online");

	return (
		<>
			<Modal className="mt-5 tds-payment-modal" isOpen={isOpen} toggle={toggleModal}>
				<ModalHeader className="align-items-center font-weight-bold hd-title-model"
					toggle={toggleModal}
				>
					{inventory.messages.ChoosePaymentMethod}
				</ModalHeader>
				<ModalBody>

					<div className="boxs-payment px-md-5">

						<div className={`item-payment pay-active `}>
							<div className="d-flex align-items-center justify-content-between">
								<div className="raido-label">
									<input
										type="radio"
										onClick={() => {
											setcheck("online");
										}}
										className="check-payment"
										id="online"
										name="check-payment"
										checked={check === "online"}
									/>
									<label htmlFor="online">
										{inventory.messages.PayFromCreditCard}
									</label>
								</div>
								<img
									src={check === "online" ? visa : disVisa}
									alt=""
									srcset=""
								/>
							</div>
						</div>

						<div className={`item-payment pay-active `}>
							<div className="d-flex align-items-center justify-content-between">
								<div className="raido-label">
									<input
										type="radio"
										onClick={() => {
											setcheck("debit");
										}}
										className="check-payment"
										id="debit"
										name="check-payment"
										checked={check === "debit"}
									/>
									<label htmlFor="debit">
									{inventory.messages.PayFromMyWallet}
									</label>
								</div>
								<img
									src={check === "debit" ? alletTDS : walletgray}
									alt=""
									srcset=""
									style={{
										width:"30px",
										height:"30px"
									}}
								/>
							</div>
						</div>
						

						<div className="d-flex justify-content-center">
							<img className="mx-2" src={iconNotification} alt="" />
							{check === "online"?		<div>
								<h5 className="">{inventory.messages.dueAmount}</h5>
								<h3 style={{ fontWeight: "bold" }} className="tds-text-blue">
									{
										(Price + Price * 0.025).toFixed(2)
									}
									{" "}{currency}
								</h3>
								{inventory.messages.AllPaymentsMadeByCredit}
							</div> : <div>
								<h5 className="">{inventory.messages.dueAmount}</h5>
								<h3 style={{ fontWeight: "bold" }} className="tds-text-blue">
									{
										(Price).toFixed(2)
									}
									{" "}{currency}
								</h3>
							</div> }
						</div>
						<button
							className="tds-generic-btn btn"
							disabled={check === ""}
							onClick={() => {
								onPayment(
									check,
									check==="online" ? currency : "SAR",
									Price
								);
							}}
						>
							{" "}
							{payment.messages.pay}
						</button>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}