import React, { useEffect, useState } from "react";
import { ReactComponent as ColorIcon } from "assets/images/webBuilder/color-filter.svg";
import { ReactComponent as FontIcon } from "assets/images/webBuilder/font.svg";
import { ReactComponent as ShapeIcon } from "assets/images/webBuilder/shapes.svg";
import { Collapse } from "reactstrap";
import SelectField from "components/Form/SelectField/SelectField";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import {
	changeBorder,
	changeColorTheme,
	changeFont,
} from "modules/WebBuilder/helpers/styles";

function Style() {
	const [collapseState, setCollapseState] = useState({
		color: true,
		font: true,
		shapes: true,
	});

	const dispatch = useWebBuilderDispatch();
	const { style } = useWebBuilderState();

	const updateStyle = ({ name, value }) => {
		dispatch({
			type: "updateStyle",
			payload: {
				name,
				value,
			},
		});
	};

	const handleCollapseToggle = (name) => {
		setCollapseState((prev) => ({
			...prev,
			[name]: !prev[name],
		}));
	};

	const changeColor = (color, isHexColor) => {
		if (isHexColor) {
			const r = parseInt(color.substr(1, 2), 16)
			const g = parseInt(color.substr(3, 2), 16)
			const b = parseInt(color.substr(5, 2), 16)
			color = `${r},${g},${b}`;
		}
		changeColorTheme(color, updateStyle)
	};
	const handleBorder = (border) => changeBorder(border, updateStyle);
	const handleFont = (font) => changeFont(font, updateStyle);
	console.log(style.rounded);
	return (
		<div className="sidebar-style">
			<div className="content-header">
				<h4>Style</h4>
			</div>
			<CustomCollapse
				icon={ColorIcon}
				name="Color"
				isOpen={collapseState.color}
				toggle={() => handleCollapseToggle("color")}
			>
				<div className="style-color">
					<div className="initial-colors">
						<div className="red-color" onClick={() => changeColor("233, 107, 107", false)} />
						<div
							className="yellow-color"
							onClick={() => changeColor("248, 235, 111", false)}
						/>
						<div
							className="green-color"
							onClick={() => changeColor("127, 240, 179", false)}
						/>
						<div
							className="blue-color"
							onClick={() => changeColor("30, 133, 255", false)}
						/>
						<div
							className="purple-color"
							onClick={() => changeColor("199, 107, 242", false)}
						/>
					</div>
					<div className="color-input">
						<label htmlFor="color-input">Custom:</label>
						<input
							type="color"
							id="color-input"
							value="#EED9BA"
							onChange={(e) => changeColor(e.target.value, true)}
						/>
					</div>
				</div>
			</CustomCollapse>
			<CustomCollapse
				icon={FontIcon}
				name="Font"
				isOpen={collapseState.font}
				toggle={() => handleCollapseToggle("font")}
			>
				<div className="style-font">
					<SelectField
						label={"Choose typeface :"}
						placeholder={"Choose typeface"}
						options={[
							{ name: "cairo", id: 1 },
							{ name: "almarai", id: 2 },
							{ name: "josefin", id: 3 },
						]}
						value={style?.font}
						onChange={(e) => {
							handleFont(e.name);
						}}
					/>
				</div>
			</CustomCollapse>
			<CustomCollapse
				icon={ShapeIcon}
				name="Shapes"
				isOpen={collapseState.shapes}
				toggle={() => handleCollapseToggle("shapes")}
			>
				<div className="style-shape">
					<fieldset>
						<label htmlFor="shape" className="shape-label">
							<input
								checked={style.rounded === "0px"}
								type="radio"
								id="shape"
								name="monster"
								value={"0px"}
								onChange={(e) => handleBorder(e.target.value)}
							/>
							<div className="shape"></div>
							Shape
						</label>

						<label htmlFor="rounded">
							<input
								checked={style.rounded === "4px"}
								type="radio"
								id="rounded"
								name="monster"
								value={"4px"}
								onChange={(e) => handleBorder(e.target.value)}
							/>
							<div className="rounded"></div>
							Rounded
						</label>
					</fieldset>
				</div>
			</CustomCollapse>
		</div>
	);
}

export default Style;

const CustomCollapse = ({ isOpen, name, toggle, icon: Icon, children }) => {
	return (
		<div>
			<div className="collapse-header">
				<div className="collapse-name">
					<Icon />
					<p>{name}</p>
				</div>
				<button className="toggle-collapse" onClick={toggle}>
					<i
						className={`fas fa-fw ${isOpen ? "fa-chevron-up " : "fa-chevron-down"
							}`}
					/>
				</button>
			</div>
			<Collapse isOpen={isOpen}>{children}</Collapse>
		</div>
	);
};
