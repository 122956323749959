import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import Locale from "translations";
import classnames from "classnames";
import toursImg from "assets/images/inventory/tours.svg";
import moment from 'moment';
import { getAllToursVoucher, getToursInvoice } from 'services/tours';
import { ToursInvoicePrint } from 'components/Printing/ToursInvoicePrint';
import { toursVoucher } from "assets/toursVoucher";
import SafaLogo from "assets/images/dashboard/hotelbeds.png";
import safaLogo from "assets/images/safaLogo.svg";
import phone from "assets/images/phone.png";
import { useParams } from 'react-router-dom';

export default function ToursBookingTopbar({ reservations, paymentStatus, reservationStatus }) {
	const { inventory } = Locale;
	const { id } = useParams();
	const canDownload = paymentStatus !== "pending" && reservationStatus !== "expired" && reservationStatus !== "canceled";



	const downloadInvoice = async () => {
		const invoiceRes = await getToursInvoice(id);
		const invoiceData = invoiceRes?.data;
		const confirmedReservations = invoiceData?.reservations?.filter(reservation => reservation?.status !== "canceled");
		if (invoiceData) {
			const keyValuePair = {
				"[ref]": invoiceData?.brn,
				"[name]": invoiceData?.holder,
				"[agency_ref]": invoiceData?.agency_reference,
				"[booking_time]": moment(invoiceData?.created_at).format("DD/MM/YYYY"),
				"[payment_method]": invoiceData?.payment_method,
				"[total_amount]": `${invoiceData?.total_price} ${invoiceData?.currency}`,
				"[services]": confirmedReservations?.map(reservation => {
					return (
						`
						<div class="border-dots" style="padding: 10px 0">
							<h6 class="fw-700">${reservation?.name}</h6>
							<p>From: ${moment(reservation?.start_date).format("DD/MM/YYYY")} (${moment(reservation?.start_date).format("dddd")})</p>
							<p>
								${reservation?.adults} Adults ${reservation?.children?.length > 0 ? reservation?.children?.length + "Children" : ""}
							</p>
							<hr class="border-0"/>
						</div>
						`
					)
				}).join(''),
				"[cancle_policy]": confirmedReservations?.map(reservation => {
					
					return reservation?.details?.cancellationPolicies
						?.filter(operationDate => {
							return moment(reservation?.start_date).isAfter(moment(operationDate?.dateFrom))})
						?.map(charge => {
							return (
								`<div class="d-flex border-dots " style="padding: 10px 0">
									<p class="col-6">${reservation?.name}</p>
									<p class="col-3" style="text-align: center">${moment(charge?.dateFrom).format("DD/MM/YYYY hh:mm")}</p>
									<p class="col-3" style="text-align: center">${charge?.amount} ${invoiceData?.currency}</p>
									<hr class="border-0"/>
								</div>
							`
							)
						}).join('')
				}).join('')

			}

			let printVar = ToursInvoicePrint;
			for (const key in keyValuePair) {
				printVar = printVar.replace(key, keyValuePair[key]);
			}
			printVar = printVar.replace(
				'[SafaLogo]',
				`${invoiceData?.gds === 10 ? safaLogo :SafaLogo}`
			);
			var popupWin = window.open(
				'',
				' ',
				'top=0,left=0,height=100%,width=auto'
			);
			popupWin.document.open();
			popupWin.document.write(`
						<html>
						<head>
						<title> print Invoice</title>
						<style>

						</style>
						</head>
						<body onLoad="{()=>window.print()}">${printVar}</body>
						</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};

	const printVoucher = async () => {
		const vourcherRes = await getAllToursVoucher(id);
		if (vourcherRes.status !== 200) return;
		const vourcherData = vourcherRes?.data?.vouchers;
		let printVar = "";

		vourcherData?.forEach((item) => {
			if(item?.type === 'html') {
				printVar += item.body;
			} else {
				let childrenString = "";
				item?.children?.forEach((age, index) => {
					childrenString = childrenString.concat(
						`<span>${age}${index !== item?.children?.length - 1 ? ", " : ""
						}</span>`
					);
				});
				let remarkString = item?.remarks?.concat(`<p>${item?.remarks}</p>`);
				let destinations = item?.destinations?.map(destination => destination?.name).join(', ');
	
				const keyValuePair = {
					"[reference_number_key]": "Reference Number",
					"[reference_number_value]": item?.reference,
					"[safa_reference_number_key]": "Safa Reference Number",
					"[safa_reference_number_value]": item?.safa_reference,
					"[name]": item?.name,
					"[passenger_name_key]": "Passenger Name",
					"[passenger_name_value]": item?.passenger_name,
					"[booking_date_key]": "Booking Date",
					"[booking_date_value]": moment(item?.booking_date).format(
						"YYYY-MM-DD"
					),
					"[agency_Reference_key]": "Agency Reference",
					"[agency_Reference_value]": item?.agency_reference,
					"[from_key]": "From",
					"[from_value]": moment(item?.from_date).format("YYYY-MM-DD"),
					"[to_key]": "To",
					"[to_value]": moment(item?.from_date).format("YYYY-MM-DD"),
					"[ticket_type_key]": "Ticket Type",
					"[ticket_type_vaue]": item?.ticket_type,
					"[destination_key]": "Destination",
					"[destination_key_value]": destinations,
					// "[service_time_key]": "Service Time",
					// "[service_time_value]": "--",
					"[adult_key]": "Adult",
					"[adult_value]": item?.pax?.adults,
					"[children_key]": "Children",
					"[children_value]": item?.pax?.children,
					"[children_ages_key]": "Children Ages",
					"[children_ages_value]":
						item?.pax?.children_ages?.length > 0 ? item?.pax?.children_ages : "--",
					"[remarks_key]": "Remarks",
					"[remarks_value]": remarkString,
					"[provider_name_value]": item?.provider?.name,
					"[provider_ref_value]": item?.provider?.reference ? item?.provider?.reference : "--",
					"[supplier_name_value]": item?.supplier?.name || "--",
					"[supplier_vat_value]": item?.supplier?.vatNumber || "--",
				};
	
				printVar += toursVoucher;
	
				for (const key in keyValuePair) {
					printVar = printVar?.replace(key, keyValuePair[key]);
				}
	
				printVar = printVar.replace(
					"[logo]",
					`<img width={200} src="${safaLogo}" alt="safa logo" />`
				);
	
				printVar = printVar.replace(
					"[phone_img]",
					`<img src="${phone}" alt="phone" />`
				);
			}
		});

		let popupWin = window.open("", " ", "top=0,left=0,height=80%,width=auto");
		popupWin.document.open();
		popupWin.document.write(`
        <html>
					<head>
						<title> print Voucher</title>
					</head>
					<body>${printVar}</body>
        </html>`);
		popupWin.document.close();
		setTimeout(() => {
			popupWin.print();
		}, 100);
	};

	return (
		<div className="d-flex justify-content-between bg-gray">
			<Nav tabs>
				<NavItem>
					<NavLink className={`border-0  ${classnames({ active: true })}`}>
						<div className="font-weight-bold text">
							<img src={toursImg} alt="" />
							<span className="text mx-1">
								{inventory.messages.bookingDetails}
							</span>
						</div>
					</NavLink>
				</NavItem>
			</Nav>
			{/* actions */}
			{canDownload &&
				<div className="tansfer-details-actions d-flex align-items-center">
					<button
						className="btn bg-nxt mx-md-2 d-flex align-items-center"
						onClick={downloadInvoice}
					>
						<i className="fas fa-download mx-2"></i>
						<span>{inventory.messages.invoice}</span>
					</button>
					<button
						className="btn bg-nxt mx-2 d-flex align-items-center"
						onClick={() => printVoucher()}
					>
						<i className="fas fa-download mx-2"></i>
						<span>{inventory.messages.vouchers}</span>
					</button>
				</div>
			}
		</div>
	);
}
