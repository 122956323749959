import walletImg from "assets/images/wallet/walletSuccessfully.png";

export default function PaymentSucess() {


  return (
    <div className="container">
      <div className="recharge-img my-2 py-4 text-center">
        <img src={walletImg} alt="Wallet" />
        <h6 className="mt-2 modal-head">
          Your Payment has been successfully received
        </h6>
      </div>
    </div>
  )
}
