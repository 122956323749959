import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import React, { useState } from "react";
import { AddElement } from "modules/WebBuilder/shared/AddElement";
import { ServiceCardDetails } from "modules/WebBuilder/shared/ServiceCardDetails";
import CustomCarousel from "modules/WebBuilder/shared/CustomCarousel";

import { SORT_ITEMS_IN_CONTAINER_ACTION } from "../../../interfaces/blockTypes";
import { EditBox } from "modules/WebBuilder/shared/EditBox";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import DragAndDropContainer from "modules/WebBuilder/shared/DragAndDropContainer";
import AddService from "modules/WebBuilder/shared/AddService";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";

const hotelBlocksType = "hotelBlocksType";

function HotelService({ details, onFocus, focusContainer }) {
	const { selectedLanguage } = useWebBuilderState();
	const { id, index, type, title, body, items } = details;
	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);

	const dispatch = useWebBuilderDispatch();

	const handleOpenAddModal = () => {
		setOpenAdd((prev) => !prev);
		setUpdateItem(null)
	};

	const openEditModal = (item) => {
		handleOpenAddModal();
		setUpdateItem(item);
	};

	const editItem = (item, index) => {
		return [
			<span
				key={`${item.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};

	const addItemToHotels = (item) => {
		debugger
		updateItem
			? dispatch({
					type: "editItemInContainer",
					payload: {
						id,
						item,
						itemIndex: updateItem.index,
					},
			  })
			: dispatch({
					type: "addItemToContainer",
					payload: {
						id,
						item,
					},
			  });
		setUpdateItem(null);
	};

	const updateHotel = ({ name, value, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				language
			},
		});
	};

	const sortHotelsItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};
	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={itemId}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "container_item" })
				}
				className="pointer-event"
			>
				<TrashIcon />
			</span>,
		];
	};
	const selectedIds = items ? items.map((i) => i.id) : [];
	return (
		<EditBox editMode={focusContainer === type}>
			<div
				className="web-builder-content-hotels container"
				onClick={() => onFocus(type)}
				id={id}
			>
				<div className="hotels-top-section">
					<ServicesContent
						header={title}
						body={body}
						changeHeader={(value) => updateHotel({ name: "title", value, language: selectedLanguage })}
						changebody={(value) => updateHotel({ name: "body", value, language: selectedLanguage })}
					/>
					<AddElement
						onClick={handleOpenAddModal}
						title="Add Service"
						className={items?.length ? "bg-white border-0 flex-row" : ""}
					/>
				</div>

				<div className="hotels-detials">
					{items && (
						<CustomCarousel>
							{items.map((props, index) => (
								<DragAndDropContainer
									accept={hotelBlocksType}
									type={hotelBlocksType}
									id={props.id}
									index={index}
									action={SORT_ITEMS_IN_CONTAINER_ACTION}
									moveItemInContainer={(dragIndex, hoverIndex) =>
										sortHotelsItems({ dragIndex, hoverIndex })
									}
									key={props.id}
								>
									<EditBox
										editMode={focusContainer === type}
										actions={[
											...editItem(props, index),
											...itemsBoxActions(props.id),
										]}
									>
										<ServiceCardDetails {...props} itemType="hotel" />
									</EditBox>
								</DragAndDropContainer>
							))}
						</CustomCarousel>
					)}
				</div>
				{openAdd&&<AddService
					handleModalState={handleOpenAddModal}
					open={openAdd}
					item_id={6}
					label={"Service"}
					header={"Hotel"}
					onAdd={addItemToHotels}
					details={updateItem}
					selectedIds={selectedIds}
				/>}
			</div>
		</EditBox>
	);
}

export default HotelService;
