import DateTimePickerField from "components/Form/DateTimePickerField/DateTimePickerField";
import React from "react";
import WathcIconsSrc from "assets/images/umrah-operations/watch-icon.svg";
import {
	ClockTimeIcon,
	PlaneIcon,
	PortIcon,
} from "modules/UmrahOperations/shared/Icons";
import Locale from 'translations';
import moment from 'moment';

export default function DurationBusHotel({ state, handleInputs }) {
	const { operationStatement } = Locale;
	const data = state?.port_durations;

	const today = new Date().toLocaleDateString();
	return (
		<div className="item-row port-durations-row mt-3">
			<h4 className="header-title">
				<ClockTimeIcon />
				<span className="mx-3">{operationStatement.DurationBusTripHotels}</span>
			</h4>
			<div className="item-content">
				<div className="header-tabs ">
					<div className="tab">
						<label htmlFor="">{operationStatement.Port}</label>
					</div>
					<div className="tab">
						<label htmlFor="">{operationStatement.Makkah}</label>
					</div>
					<div className="tab">
						<label htmlFor="">{operationStatement.Madinah}</label>
					</div>
					<div className="tab">
						<label htmlFor="">{operationStatement.Jeddah}</label>
					</div>
					<div className="tab">
						<label htmlFor="">{operationStatement.Yanbu}</label>
					</div>
				</div>

				{/*** Jeddah Airport */}
				<div>
					<div className="content-tabs">
						<div className="label-data tab">
							<h1>
								<PlaneIcon />
								<p className="d-inline mx-2">{operationStatement.JeddahAirport}</p>
							</h1>
						</div>
						{/**Makkah */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="Jeddah_port_makkah"
									id="Jeddah_port_makkah"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) => item.city_id === 134 && item.port_type == 2
										)?.makkah_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											2,
											134,
											"makkah_duration"
										)
									}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 2,134,"makkah_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						{/**madinah */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="Jeddah_port_madinah"
									id="Jeddah_port_madinah"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) => item.city_id === 134 && item.port_type == 2
										)?.madinah_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											2,
											134,
											"madinah_duration"
										)
									}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 2,134,"madinah_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						{/**Jeddah */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
				open={false}
									name="jeddah_port_jeddah"
									id="jeddah_port_jeddah"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) => item.city_id === 134 && item.port_type == 2
										)?.jeddah_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											2,
											134,
											"jeddah_duration"
										)
									}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 2,134,"jeddah_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						{/**yanbu */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									id="Jeddah_port_yanbu"
									name="Jeddah_port_yanbu"
									date={
										data?.find(
											(item) => item.city_id === 134 && item.port_type == 2
										)?.yanbu_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											2,
											134,
											"yanbu_duration"
										)
									}
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 2,134,"yanbu_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
					</div>
				</div>

				{/*** Madinah Airport */}
				<div>
					<div className="content-tabs">
						<div className="label-data tab">
							<h1>
								<PlaneIcon />
								<p className="d-inline mx-2">{operationStatement.MadinahAirport}</p>
							</h1>
						</div>
						{/**Makkah */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="madinah_port_makkah"
									id="madinah_port_makkah"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) => item.city_id === 4 && item.port_type == 2
										)?.makkah_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											2,
											4,
											"makkah_duration"
										)
									}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 2,4,"makkah_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						{/**madinah */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="madinah_port_madinah"
									id="madinah_port_madinah"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) => item.city_id === 4 && item.port_type == 2
										)?.madinah_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											2,
											4,
											"madinah_duration"
										)
									}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 2,4,"madinah_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						{/**Jeddah */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="jeddah_port_jeddah"
									id="jeddah_port_jeddah"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) => item.city_id === 4 && item.port_type == 2
										)?.jeddah_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											2,
											4,
											"jeddah_duration"
										)
									}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 2,4,"jeddah_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						{/**yanbu */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									id="madinah_port_yanbu"
									name="madinah_port_yanbu"
									date={
										data?.find(
											(item) => item.city_id === 4 && item.port_type == 2
										)?.yanbu_duration
									}
									onChangeDate={(time) =>
										handleInputs(time, "port_durations", 2, 4, "yanbu_duration")
									}
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 2,4,"yanbu_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
					</div>
				</div>
				{/*** yanbu Airport */}
				<div>
					<div className="content-tabs">
						<div className="label-data tab">
							<h1>
								<PlaneIcon />
								<p className="d-inline mx-2">{operationStatement.YanbuAirport}</p>
							</h1>
						</div>
						{/**Makkah */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="yanbu_port_makkah"
									id="yanbu_port_makkah"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) => item.city_id === 224 && item.port_type == 2
										)?.makkah_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											2,
											224,
											"makkah_duration"
										)
									}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 2,224,"makkah_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						{/**madinah */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="yanbu_port_madinah"
									id="yanbu_port_madinah"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) => item.city_id === 224 && item.port_type == 2
										)?.madinah_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											2,
											224,
											"madinah_duration"
										)
									}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 2,224,"madinah_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						{/**Jeddah */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="yanbu_port_jeddah"
									id="yanbu_port_jeddah"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) => item.city_id === 224 && item.port_type == 2
										)?.jeddah_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											2,
											224,
											"jeddah_duration"
										)
									}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 2,224,"jeddah_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						{/**yanbu */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									id="yanbu_port_yanbu"
									name="yanbu_port_yanbu"
									date={
										data?.find(
											(item) => item.city_id === 224 && item.port_type == 2
										)?.yanbu_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											2,
											224,
											"yanbu_duration"
										)
									}
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 2,224,"yanbu_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
					</div>
				</div>
				{/*** Jeddah Islamic port */}
				<div>
					<div className="content-tabs">
						<div className="label-data tab">
							<h1>
								<PortIcon />
								<p className="d-inline mx-2">{operationStatement.JeddahIslamicport}</p>
							</h1>
						</div>
						{/**Makkah */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="jeddah_is_port_makkah"
									id="jeddah_is_port_makkah"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) => item.city_id === 134 && item.port_type == 3
										)?.makkah_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											3,
											134,
											"makkah_duration"
										)
									}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 3,134,"makkah_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						{/**madinah */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="jeddah_is_port_madinah"
									id="jeddah_is_port_madinah"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) => item.city_id === 134 && item.port_type == 3
										)?.madinah_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											3,
											134,
											"madinah_duration"
										)
									}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 3,134,"madinah_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						{/**Jeddah */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="jeddah_is_port_jeddah"
									id="jeddah_is_port_jeddah"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) => item.city_id === 134 && item.port_type == 3
										)?.jeddah_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											3,
											134,
											"jeddah_duration"
										)
									}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 3,134,"jeddah_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						{/**yanbu */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									id="jeddah_is_port_yanbu"
									name="jeddah_is_port_yanbu"
									date={
										data?.find(
											(item) => item.city_id === 134 && item.port_type == 3
										)?.yanbu_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											3,
											134,
											"yanbu_duration"
										)
									}
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 3,134,"yanbu_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
					</div>
				</div>
				{/*** Yanbu port */}
				<div>
					<div className="content-tabs">
						<div className="label-data tab">
							<h1>
								<PortIcon />
								<p className="d-inline mx-2">{operationStatement.Yanbuport}</p>
							</h1>
						</div>
						{/**Makkah */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="yanbu_po_port_makkah"
									id="yanbu_po_port_makkah"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) => item.city_id === 224 && item.port_type == 3
										)?.makkah_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											3,
											224,
											"makkah_duration"
										)
									}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 3,224,"makkah_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						{/**madinah */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="yanbu_po_port_madinah"
									id="yanbu_po_port_madinah"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) => item.city_id === 224 && item.port_type == 3
										)?.madinah_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											3,
											224,
											"madinah_duration"
										)
									}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 3,224,"madinah_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						{/**Jeddah */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="yanbu_po_port_jeddah"
									id="yanbu_po_port_jeddah"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) => item.city_id === 224 && item.port_type == 3
										)?.jeddah_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											3,
											224,
											"jeddah_duration"
										)
									}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 3,224,"jeddah_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						{/**yanbu */}
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									id="yanbu_po_port_yanbu"
									name="yanbu_po_port_yanbu"
									date={
										data?.find(
											(item) => item.city_id === 224 && item.port_type == 3
										)?.yanbu_duration
									}
									onChangeDate={(time) =>
										handleInputs(
											time,
											"port_durations",
											3,
											224,
											"yanbu_duration"
										)
									}
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												handleInputs( moment(`${today} ${"00:00"}`).toDate(), "port_durations", 3,224,"yanbu_duration")
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
