import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import SelectField from "components/Form/SelectField/SelectField";
import useFetchCountries from "hooks/useFetchCountries";
import Locale from "translations";

export default function AdditionalServiceAccommodation(props) {
	const [countries] = useFetchCountries();
	const [extraItem, setExtraItem] = useState("");
	const [transportationCondition, setTransportationCondition] = useState("");
	const [accomodationCondition, setAccomodationCondition] = useState("");
	const { reservation } = Locale;

	//ForExample To Searchable SelectField
	const countriesList = countries.map((country, index) => (
		<option key={`country-${country.id}${index}`} value={country.id}>
			{country.name}
		</option>
	));

	const [formValidation, setFormValidation] = useState({
		extraItem: true,
		transportationCondition: true,
		accomodationCondition: true,
	});

	// Validate Country
	const validateCountry = () => {
		setFormValidation({
			...formValidation,
			extraItem: extraItem !== 0 ? true : false,
			transportationCondition: transportationCondition !== 0 ? true : false,
		});
	};

	return (
		<>
			<div className="additional-service py-3">
				<h2 className="head">Additional Service</h2>
				<Row className="m-0">
					<Col sm="3">
						<SelectField
							label={reservation.messages.extraItems}
							defaultValue="Select Extra Items"
							options={countries}
							value={extraItem.label}
							feedbackMessage={reservation.messages.productClass}
							onChange={(e) => {
								setExtraItem(e);
							}}
							onBlur={validateCountry}
							color={formValidation.extraItem ? "" : "danger"}
							isInvalid={!formValidation.extraItem}
						></SelectField>
					</Col>
					<Col sm="3">
						<SelectField
							label={reservation.messages.transportationCondition}
							defaultValue="Select Visa Type"
							options={countries}
							value={transportationCondition.label}
							feedbackMessage={reservation.messages.productClass}
							onChange={(e) => {
								setTransportationCondition(e);
							}}
							onBlur={validateCountry}
							color={formValidation.transportationCondition ? "" : "danger"}
							isInvalid={!formValidation.transportationCondition}
						/>
					</Col>
					<Col sm="3">
						<SelectField
							label={reservation.messages.accomodationCondition}
							defaultValue="Select Accomodation option"
							options={countries}
							value={accomodationCondition.label}
							feedbackMessage={reservation.messages.productClass}
							onChange={(e) => {
								setAccomodationCondition(e);
							}}
							onBlur={validateCountry}
							color={formValidation.accomodationCondition ? "" : "danger"}
							isInvalid={!formValidation.accomodationCondition}
						/>
					</Col>
				</Row>
				<Row className="m-0">
					<Col sm="4">
						<ul className="extra-item">
							<li>
								<p className="title">{reservation.messages.extraItem} 1</p>
								<p className="price">200 EGP</p>
							</li>
							<li>
								<p className="title">{reservation.messages.extraItem} 2</p>
								<p className="price">400 EGP</p>
							</li>
							<li>
								<p className="title">{reservation.messages.extraItem} 3</p>
								<p className="price">600 EGP</p>
							</li>
						</ul>
					</Col>
				</Row>
			</div>
		</>
	);
}
