import React from "react";
import { ReactComponent as BoldIcon } from "assets/images/promo/feather-bold.svg";
import { ReactComponent as ItalicIcon } from "assets/images/promo/feather-italic.svg";
import { ReactComponent as UnderlineIcon } from "assets/images/promo/feather-underline.svg";

export function FlyerTextarea({
	handleTextStyle,
	text,
	textLength,
	handleTextChange,
	title,
	maximumCharacter,
	textPlaceholder,
}) {
	return (
		<div>
			<p>{title}</p>
			<div className="mt-3 border textarea-container">
				<div className="d-flex p-2 border-bottom">
					<span
						className="px-1"
						role="button"
						onClick={() => handleTextStyle("bold")}
					>
						<BoldIcon className={`${text.bold ? "active-text" : ""}`} />
					</span>
					<span
						className="px-1"
						role="button"
						onClick={() => handleTextStyle("italic")}
					>
						<ItalicIcon className={`${text.italic ? "active-text" : ""}`} />
					</span>
					<span
						className="px-1"
						role="button"
						onClick={() => handleTextStyle("underline")}
					>
						<UnderlineIcon
							className={`${text.underline ? "active-text" : ""}`}
						/>
					</span>
					<span
						className={`promo-character-count ${
							textLength > maximumCharacter ? "text-danger" : ""
						}`}
					>{`${ textLength > maximumCharacter  ?maximumCharacter - textLength  :textLength} / ${maximumCharacter}`}</span>
				</div>
				<textarea
					className="w-100 border-0 p-2"
					placeholder={textPlaceholder}
					value={text.value}
					onChange={handleTextChange}
				/>
			</div>
		</div>
	);
}
