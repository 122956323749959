import background from "assets/images/packageBackground.png";
import Locale from "translations";
import PackagesCountries from "./PackagesCountries";

export default function Packages() {
	const { marketPlace } = Locale;


	return (
		<>
			<div
				className="w-100 h-100 pl-3 pl-md-4 pt-2 pt-md-5"
				style={{
					backgroundImage: `url(${background})`,
					overflowX: "hidden",
				}}
			>
				<div className="row py-5">
					<div className="title-packages col-12 col-md-3" role="button">
						<h1 className="discover">{marketPlace.bestPackages}</h1>
						<h1 className="here">{marketPlace.hereYou}</h1>
					</div>
					<div className="col-md-9 slider-box-pack">
							<PackagesCountries />
					</div>
				</div>
			</div>
		</>
	);
}
