import validate, { isFormValid } from 'helpers/validate';
import { useState } from 'react'
import { handleResponesNotification } from '../helper/accountingHelpers';
import { useParams } from 'react-router-dom';
import { addNewAgent, updateAgent, viewAgent } from 'services/operationStatement';
import Locale from 'translations';
import { useSBSState } from 'context/global';

const saudiFlagUUID = "f4e1fb09-cc9e-473a-828f-02fb764d8394";
const validationsKeys = ['name', 'country', 'email', 'phone'];
const saudiPhoneCodeObj = {
  flag: saudiFlagUUID,
  label: "+966",
  value: "+966",
}
export default function useAgentHook() {
  // ** hooks
  const { id } = useParams();
  const { operationStatement } = Locale;
  const { allCountries, locale } = useSBSState();

  // ** states
  const [agentData, setAgentData] = useState({
    name: "",
    country: null,
    email: "",
    phone_code: saudiPhoneCodeObj,
    phone: "",
    address: "",
    responsible_name: "",
    responsible_email: "",
    responsible_phone_code: saudiPhoneCodeObj,
    responsible_phone: "",
  });

  const [errors, setErrors] = useState({});

  // ********************************************************* functions ********************************************************* //

  // handle agent inputs changes
  function handleInputChanges(keyName, value) {
    setAgentData({
      ...agentData,
      [keyName]: value,
    });
    if (!validationsKeys.includes(keyName)) return
    setErrors({
      ...errors,
      ...validate(
        { name: keyName, value },
        {
          required: true,
          email: keyName === "email",
        }
      ),
    })
  }

  // check form errors before submit
  function checkFormErrors() {
    let submitError = {};
    validationsKeys.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: agentData[key] },
          {
            required: true,
            email: key === "email",
          }
        ),
      }
    });
    setErrors({ ...submitError });
    return submitError;
  }

  // submit add edit agent
  async function addEditAgent(successCb) {
    let formErrors = checkFormErrors();
    if (!isFormValid(formErrors)) return;
    const data = {
      id,
      name: agentData.name,
      country_id: agentData?.country?.id,
      email: agentData.email,
      phone_code: agentData.phone_code?.phone_code,
      phone: agentData.phone,
      address: agentData?.address,
      responsible_name: agentData?.responsible_name,
      responsible_phone_code: agentData.responsible_phone_code?.phone_code,
      responsible_phone: agentData.responsible_phone,
      responsible_email: agentData?.responsible_email,
    }

    const res = id ? await updateAgent(id, data) : await addNewAgent(data);
    if (res?.status >= 200 && res?.status < 300) {
      const message = id ? operationStatement.agentUpdatedSuccessfully : operationStatement.agentCreatedSuccessfully;
      handleResponesNotification({ alertType: "success", message });
      successCb();
    }
  }

  // get  agent data if there is id
  async function getAgentData() {
    const agentRes = await viewAgent(id);
    if (agentRes?.status === 200) {
      const data = agentRes?.data?.data;
      const formatAgent = {
        ...data,
        country: {
          ...data?.country,
          id: data?.country.country_id,
          label: data?.country.name,
          name: data?.country.name?.[locale],
        },
        phone_code: allCountries?.find(country => country?.phone_code === data?.phone_code),
        responsible_phone_code: allCountries?.find(country => country?.phone_code === data?.responsible_phone_code),
      }
      setAgentData(formatAgent);
    }
  }

  const agentErrors = errors;

  return {
    agentData,
    agentErrors,
    handleInputChanges,
    addEditAgent,
    getAgentData,
  }
}
