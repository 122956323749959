import React, { useEffect, useState } from 'react'
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";
import Locale from 'translations';
import FlightBookingDetails from './BookingDetails';
import FlightBookingRequests from './BookingRequests';
import flightsIcon from "assets/images/flights.svg";
import { viewflight, viewflightRequests } from 'services/inventory';
import { useHistory, useParams } from 'react-router-dom';
import BookingHistory from './BookingHistory';


export default function FlightReservation() {
	const { inventory } = Locale;
	const [activeTab, setActiveTab] = useState("1");
	const [reservation, setReservation] = useState(null);
	const [requests, setRequests] = useState(null);
	const { id, tab } = useParams();
	const history = useHistory();

	// get flight booking details
	const fetchInventory = async () => {
		const res = await viewflight(id);
		if (res?.status === 200) {
			setReservation(res.data.data);
		}
	};
	// get flight booking requests
	const fetchRequest = async () => {
		const flightRequestRes = await viewflightRequests(id);
		if (flightRequestRes.status >= 200 && flightRequestRes.status < 300) {
			setRequests(flightRequestRes?.data?.data);
		}
	};

	useEffect(() => {
		if (id) {
			fetchInventory();
			fetchRequest();
		}
		return () => { };
	}, [])

	useEffect(() => {
		switch (tab) {
			case "booking-details":
				setActiveTab("1");
				break;
			case "requests":
				setActiveTab("2");
				break;
				// case "history":
				// 	setActiveTab("3");
				// 	break;	
			default:
				setActiveTab("1");
				history.push(`/inventory/flight/reservation/${id}/booking-details`);
				break;
		}
	}, [tab, activeTab]);

	

	return (
		<div className='container p-md-0'>
			<Nav tabs className="text-capitalize flight-booking-nav-tabs">
				{/* booking details tab */}

				<NavItem>
					<NavLink
						id="flight-booking-details"
						className={activeTab === "1" ? "active" : ""}
						onClick={() => {
							setActiveTab("1");
							history.push(`/inventory/flight/reservation/${id}/booking-details`);
						}}
					>
						<img style={{ width: "20px" }} src={flightsIcon} alt="flight booking details" />
						<span className='mx-1'>{inventory.messages.bookingDetails}</span>
					</NavLink>
				</NavItem>

				{/* reqeusts tab */}
				<NavItem>
					<NavLink
						id="flight-booking-request"
						className={activeTab === "2" ? "active" : ""}
						onClick={() => {
							setActiveTab("2");
							history.push(`/inventory/flight/reservation/${id}/requests`);
						}}
					>
						<i className="fas fa-history"></i>
						<span className='mx-1'>{inventory.messages.requests}</span>
					</NavLink>
				</NavItem>
				{/* <NavItem>
					<NavLink
						id="flight-booking-history"
						className={activeTab === "3" ? "active" : ""}
						onClick={() => {
							setActiveTab("3");
							history.push(`/inventory/flight/reservation/${id}/history`);
						}}
					>
						<i className="fas fa-history"></i>
						<span className='mx-1'>{inventory.messages.History}</span>
					</NavLink>
				</NavItem> */}
			</Nav>


			{/* <div className='p-md-3 py-2 bg-white'> */}
				<TabContent activeTab={activeTab}>
					{activeTab === "1" ? reservation &&<FlightBookingDetails reservation={reservation} />
					: activeTab === "2" ? requests && <FlightBookingRequests requests={requests} fetchRequest={fetchRequest} />
					// :	activeTab === "3"? <BookingHistory />
							: null}
				</TabContent>
			{/* </div> */}
		</div>
	)
}