import React from 'react'
import { BankIcon, SettingIcon } from '../../shared/Icons'
import TextField from 'components/Form/TextField/TextField'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Locale from 'translations';

export default function SerialBankInfo({ handleInputChanges, basicSettingsData, errors, }) {
  const { operationStatement } = Locale;
  return (
    <div className='setting-serial-bank'>

      {/* oeprating serial */}
      <div className="settings-wrapper operating-serial">
        <h3 className='header-title'>
          <SettingIcon color='#292D32' />
          <span>{operationStatement.operatingSerial}</span>
        </h3>

        <div>
          <label htmlFor="" className="control-field__label">
            <span>{operationStatement.operatingNumberSerial}</span>
            <OverlayTrigger
              placement="top"
              delay={{ show: 100, hide: 100 }}
              overlay={<Tooltip id={"operating-Serial"}>
                <p>{operationStatement.enterCountAlphabetical}</p>
                <p>{operationStatement.countNumbers}</p>
              </Tooltip>
              }
              trigger={["hover", "hover"]}
              onEnter={(e) => {
                e.querySelector('.tooltip-inner').style.maxWidth = '600px'
              }}
            >
              <i className="fas fa-exclamation-circle mx-1"></i>
            </OverlayTrigger>

          </label>

          <div className='row mx-0 align-items-center'>
            <div className="col-md-4 col-12 px-0">
              <TextField
                hasLabel={false}
                label={operationStatement.code}
                id="serial-text"
                name="serial-text"
                placeholder="SA"
                value={basicSettingsData?.operating_serial_code}
                onChange={(e) => {
                  if (e.target.value.length <= 2) {
                    handleInputChanges("operating_serial_code", e.target.value?.toUpperCase())
                  }
                }}
                color={errors?.operating_serial_code?.required ? "danger" : ""}
                errors={errors?.operating_serial_code}
              />
            </div>
            <span className='mx-1'>-</span>

            <div className='col-md-7 col-12 px-0'>
              <TextField
                type="number"
                hasLabel={false}
                label={operationStatement.number}
                id="serial-number"
                name="serial-number"
                placeholder="0100"
                value={basicSettingsData?.operating_serial_start}
                onChange={(e) => handleInputChanges("operating_serial_start", e.target.value)}
                color={errors?.operating_serial_start?.required ? "danger" : ""}
                errors={errors?.operating_serial_start}
              />
            </div>
          </div>

        </div>
      </div>

      {/* bank inofrmations */}
      <div className="settings-wrapper bank-info">
        <h3 className='header-title'>
          <BankIcon color='#292D32' />
          <span>{operationStatement.bankAccountInformation}</span>
        </h3>
        <div className='row mx-0'>
          <div className="col">
            <TextField
              label={operationStatement.bankName}
              placeholder={operationStatement.bankName}
              id="bank_name"
              name="bank_name"
              value={basicSettingsData?.bank_name}
              onChange={(e) => handleInputChanges("bank_name", e.target.value)}
            />
          </div>
          <div className="col">
            <TextField
              label={operationStatement.accountName}
              placeholder={operationStatement.accountName}
              id="bank_account_name"
              name="bank_account_name"
              value={basicSettingsData?.bank_account_name}
              onChange={(e) => handleInputChanges("bank_account_name", e.target.value)}
            />
          </div>
          <div className="col">
            <TextField
              label={operationStatement.accountNumber}
              placeholder={operationStatement.accountNumber}
              id="bank_account_number"
              name="bank_account_number"
              value={basicSettingsData?.bank_account_number}
              onChange={(e) => handleInputChanges("bank_account_number", e.target.value)}
            />
          </div>
          <div className="col">
            <TextField
              label={operationStatement.SWIFTNumber}
              placeholder={operationStatement.SWIFTNumber}
              id="bank_swift_number"
              name="bank_swift_number"
              value={basicSettingsData?.bank_swift_number}
              onChange={(e) => handleInputChanges("bank_swift_number", e.target.value)}
            />
          </div>
          {/* <div className="col">
            <TextField
              label="Licence Number"
              placeholder={operationStatement.bankName}
              id="licence_key"
              name="licence_key"
              value={basicSettingsData?.licence_key}
              onChange={(e) => handleInputChanges("licence_key", e.target.value)}
            />
          </div> */}
        </div>
      </div>

    </div>
  )
}
