import _ from "lodash";
import { roomTypesLookup } from "modules/products-builder/Requestes/RequestView/helpers/localLookups";

export function formatPricingData(pricingData, allCurrencies, selected_trip, selectedPricingFor) {


  // const pricingFor = pricingData?.product_price_combinations?.filter(c => c?.pricing_for)?.length > 0
  //   ? pricingData?.product_price_combinations?.find(c => c?.pricing_for === "b2b") ? { id: 'b2b', name: 'B2B' } : { id: 'b2c', name: 'B2C' }
  //   : pricingData?.product_price_combinations?.find(c => c?.pricing_for === "b2b" || c?.pricing_for === "b2c") ? { id: 'B2B_B2C', name: 'B2B & B2C' }
  //     : null;
  return {
    ...pricingData,
    selected_trip,
    currency: allCurrencies?.find(currency => currency?.id === pricingData?.basic_currency_id) || allCurrencies.find(currency => currency?.currency_code === "SAR"),
    pricing_for: selectedPricingFor || getPricingFor(pricingData) ,
    selected_pricing_for: pricingData?.product_price_combinations?.find(c => c?.pricing_for === "b2b") ? "b2b" : pricingData?.product_price_combinations?.find(c => c?.pricing_for === "b2c") ? "b2c" : null,
    pricing: formatPricingCombinations(pricingData),
    room_types: pricingData?.room_types?.map(room => ({
      ...room,
      label: roomTypesLookup[room?.type],
      name: roomTypesLookup[room?.type],
      value: room?.type,
      id: room?.type,
    }))
  }
}


function getPricingFor(pricingData) {
  let pricingFor;
  const pricingContainB2B = pricingData?.product_price_combinations?.find(c => c?.pricing_for === "b2b");
  const pricingContainB2C = pricingData?.product_price_combinations?.find(c => c?.pricing_for === "b2c");

  if (pricingData?.product_price_combinations?.filter(c => c?.pricing_for)?.length === 0) return null;

  if (pricingContainB2B && !pricingContainB2C) {
    pricingFor = { id: 'b2b', name: 'B2B' }
  } else if (!pricingContainB2B && pricingContainB2C) {
    pricingFor = { id: 'b2c', name: 'B2C' }
  } else if (pricingContainB2B && pricingContainB2C) {
    pricingFor = { id: 'B2B_B2C', name: 'B2B & B2C' }
  }
  return pricingFor
}

function formatPricingCombinations(pricingData) {
  let isNew = pricingData?.product_price_combinations?.find(c => !c?.pricing_for);
  let pricing;
  if (isNew) {
    let rooms = pricingData?.product_price_combinations?.map(combination => ({
      ...combination,
      combinations: combination?.combinations?.map(room => ({
        ...room,
        room_count: pricingData?.room_types?.find(r => r?.type === +room?.room_type)?.count,
        room_type_name: roomTypesLookup[room?.room_type]
      }))
    }))

    pricing = [
      {
        for: "b2b",
        hasVat: false,
        hasDiscount: false,
        rooms: [...rooms],
        product_items: [...pricingData?.product_items]
      },
      {
        for: "b2c",
        hasVat: false,
        hasDiscount: false,
        rooms: [...rooms],
        product_items: [...pricingData?.product_items]
      }

    ];
  }

  else {
    pricing = [
      {
        for: "b2b",
        hasVat: +pricingData?.product_price_combinations[0]?.vat > 0 ? true : false,
        vat: pricingData?.product_price_combinations?.find(discount => discount?.pricing_for === "b2b")?.vat,
        hasDiscount: pricingData?.product_price?.discounts?.find(discount => discount?.pricing_for === "b2b"),
        discount: pricingData?.product_price?.discounts?.find(discount => discount?.pricing_for === "b2b"),
        rooms: pricingData?.product_price_combinations?.filter(c => c?.pricing_for === "b2b")?.map(combination => ({
          ...combination,
          combinations: combination?.combinations?.map(room => ({
            ...room,
            room_count: pricingData?.room_types?.find(r => r?.type === +room?.room_type).count,
            room_type_name: roomTypesLookup[room?.room_type],
          })),
          max_child: 5
        })),
        product_items: pricingData?.product_items?.map(item => {
          if (item?.item?.id !== 6) {
            return {
              ...item,
              itemable: {
                ...item?.itemable,
                price_adult: item?.prices?.find(price => price?.pricing_for === "b2b")?.price_adult,
                price_child: item?.prices?.find(price => price?.pricing_for === "b2b")?.price_child,
                price_infant: item?.prices?.find(price => price?.pricing_for === "b2b")?.price_infant,
              }
            }
          } else {
            return {
              ...item,
              itemable: {
                ...item?.itemable,
                rooms: item?.itemable?.rooms?.map(room => {
                  let combinations = pricingData?.product_price_combinations.reduce((prev, next) => prev.concat(next.combinations), []);
                  let roomCombination = combinations.find(c => c?.room_type === room?.room_type && c?.product_hotel_id === item?.itemable?.id);

                  return {
                    ...room,
                    price_adult: item?.prices?.find(price => price?.pricing_for === "b2b")?.rooms_prices?.find(r => r?.adults_count === +room?.room_type)?.price_adult,
                    price_child: item?.prices?.find(price => price?.pricing_for === "b2b")?.price_child,
                    max_child: roomCombination?.max_child
                  }
                })
              }
            }
          }
        })
      },
      {
        for: "b2c",
        hasVat: +pricingData?.product_price_combinations[0]?.vat > 0 ? true : false,
        vat: pricingData?.product_price_combinations?.find(discount => discount?.pricing_for === "b2c")?.vat,
        hasDiscount: pricingData?.product_price?.discounts?.find(discount => discount?.pricing_for === "b2c"),
        discount: pricingData?.product_price?.discounts?.find(discount => discount?.pricing_for === "b2c"),
        rooms: pricingData?.product_price_combinations?.filter(c => c?.pricing_for === "b2c")?.map(combination => ({
          ...combination,
          combinations: combination?.combinations?.map(room => ({
            ...room,
            room_count: pricingData?.room_types?.find(r => r?.type === +room?.room_type).count,
            room_type_name: roomTypesLookup[room?.room_type],
          })),
          max_child: 1
        })),
        product_items: pricingData?.product_items?.map(item => {
          if (item?.item?.id !== 6) {
            return {
              ...item,
              itemable: {
                ...item?.itemable,
                price_adult: item?.prices?.find(price => price?.pricing_for === "b2c")?.price_adult,
                price_child: item?.prices?.find(price => price?.pricing_for === "b2c")?.price_child,
                price_infant: item?.prices?.find(price => price?.pricing_for === "b2c")?.price_infant,
              }
            }
          } else {
            return {
              ...item,
              itemable: {
                ...item?.itemable,
                rooms: item?.itemable?.rooms?.map(room => {
                  let combinations = pricingData?.product_price_combinations.reduce((prev, next) => prev.concat(next.combinations), []);
                  let roomCombination = combinations.find(c => c?.room_type === room?.room_type && c?.product_hotel_id === item?.itemable?.id);
                  return {
                    ...room,
                    price_adult: item?.prices?.find(price => price?.pricing_for === "b2c")?.rooms_prices?.find(r => r?.adults_count === +room?.room_type)?.price_adult,
                    price_child: item?.prices?.find(price => price?.pricing_for === "b2c")?.price_child,
                    max_child: roomCombination?.max_child
                  }
                })
              }
            }
          }
        })
      }
    ]
  }


  return pricing
}