import React, { Fragment } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Locale from 'translations'
import { useSBSState } from 'context/global'

const SubscriptionPlanModel = ({
	isOpen,
	toggleSubscriptionPlanModal,
	subscriptionModulesData
}) => {
	const { subscriptionModule } = Locale
	const { locale } = useSBSState();

	return (
		<Modal isOpen={isOpen} centered size='lg'>
			<ModalHeader toggle={toggleSubscriptionPlanModal}>
				{subscriptionModule.ModuleDetails}
			</ModalHeader>
			<ModalBody>
				<div className='subscription-modal-wrapper'>
					{subscriptionModulesData?.modules?.map((ele) => (
						<Fragment key={ele?.id}>
							{ele?.status === 'active' ? <div className='d-flex justify-content-between align-items-center custom-border'>
								<div className='sub-package-item custom-padding'>
									<p className='subscriptionModal-text'>{ele?.name?.[locale] || ele?.name}</p>
								</div>
							</div> : null}

						</Fragment>
					))}
				</div>
			</ModalBody>
		</Modal>
	)
}

export default SubscriptionPlanModel
