import NumberField from 'components/Form/NumberField/NumberField'
import React from 'react'
import Locale from 'translations';

export default function AccountingVisaPricing({ index, itemData, handleInputsChanges, canEdit }) {
  const { operationStatement } = Locale;

  const itemPrice = itemData?.pricing;
  let profit = (itemPrice?.total_selling_price || 0) - (itemPrice?.total_buying_price || 0)

  return (
    <div className="visa-pricing">
      {/* buying and selling */}
      <div className="pricig-row">
        <span className="col-md-2 p-0 pax col-text">{operationStatement.Pax}</span>

        <div className="buying">
          <span className="row-head">{operationStatement.BuyingCost}</span>
          <div className="d-flex justify-content-center gap-10">
            <span className="row-sub-head">{operationStatement.PricePerPax}</span>
            <span className="row-sub-head">{operationStatement.Total}</span>
          </div>
        </div>

        <div className="selling">
          <span className="row-head">{operationStatement.Sellingcost}</span>
          <div className="d-flex justify-content-center gap-10">
            <span className="row-sub-head">{operationStatement.PricePerPax}</span>
            <span className="row-sub-head">{operationStatement.Total}</span>
          </div>
        </div>

      </div>

      {/* calc price inputs row */}
      <div className="pricig-row">
        <span className="col-md-2 p-0 pax-count col-text">{itemData?.total_pax}</span>

        <div className="row-data">
          <div className="flex-grow-1">
            <NumberField
              hasLabel={false}
              removeArrow={true}
              placeholder={operationStatement.price}
              id={`buying-price-${index}`}
              name={`buying-price-${index}`}
              value={itemPrice?.buying_price}
              onChange={(e) => handleInputsChanges(e.target.value, "buying_price", "total_buying_price", false, index)}
              onWheel={(e) => e.target.blur()}
              disabled={!canEdit}
            />
          </div>

          <div className="flex-grow-1">
            <NumberField
              hasLabel={false}
              removeArrow={true}
              placeholder={operationStatement.total}
              id={`buying-total-${index}`}
              name={`buying-total-${index}`}
              value={itemPrice?.total_buying_price}
              onChange={(e) => handleInputsChanges(e.target.value, "buying_price", "total_buying_price", true, index)}
              onWheel={(e) => e.target.blur()}
              disabled={!canEdit}
            />
          </div>
        </div>

        <div className="row-data">
          <div className="flex-grow-1">
            <NumberField
              hasLabel={false}
              removeArrow={true}
              placeholder={operationStatement.price}
              id={`selling-price-${index}`}
              name={`selling-price-${index}`}
              value={itemPrice?.selling_price}
              onChange={(e) => handleInputsChanges(e.target.value, "selling_price", "total_selling_price", false, index)}
              onWheel={(e) => e.target.blur()}
              disabled={!canEdit}
            />
          </div>
          <div className="flex-grow-1">
            <NumberField
              hasLabel={false}
              removeArrow={true}
              placeholder={operationStatement.total}
              id={`selling-total-${index}`}
              name={`selling-total-${index}`}
              value={itemPrice?.total_selling_price}
              onChange={(e) => handleInputsChanges(e.target.value, "selling_price", "total_selling_price", true, index)}
              onWheel={(e) => e.target.blur()}
              disabled={!canEdit}
            />
          </div>

        </div>
      </div>

      {/* total */}
      <div className="pricig-row total">
        <span className="col-md-2 col-text">{operationStatement.TotalPurchaseSales}</span>
        <div className="row-data buying-amount">{itemPrice?.total_buying_price || 0}</div>
        <div className="row-data selling-amount">{itemPrice?.total_selling_price || 0}</div>
      </div>

      {/* profit */}
      <div className="pricig-row profit">
      <span className="col-md-2 col-text">{operationStatement.Profit}</span>
        <div className="row-data profit-amount">{profit < 0 ? 0 : profit?.toFixed(2)}</div>
      </div>
    </div>
  )
}
