import React from "react";
import Locale from "translations";

export default function ReservationHeader(props) {
	const { reservation } = Locale;
	return (
		<>
			<div className="reservation__header">
				<div className="reservation__item">
					<h6 className="head">{reservation.messages.productType}</h6>
					<h6 className="text">Umrah</h6>
				</div>
				<div className="reservation__item">
					<h6 className="head">{reservation.messages.productName}</h6>
					<h6 className="text">Umrah Ramdan 2021</h6>
				</div>
				<div className="reservation__item">
					<h6 className="head">{reservation.messages.validation}</h6>
					<h6 className="text">01/05/2021 - 30/05/2020</h6>
				</div>
				<div className="reservation__item">
					<h6 className="head">{reservation.messages.tripDate}</h6>
					<h6 className="text">01/06/2021 - 12/06/2020</h6>
				</div>
				<div className="reservation__item">
					<h6 className="head">{reservation.messages.productItems}</h6>
					<ul className="icons">
						<i className="icx icx-hotel"></i>
						<i className="icx icx-plane"></i>
						<i className="icx icx-transport"></i>
						<i className="icx icx-camera-location"></i>
						<i className="fas fa-passport"></i>
						<i className="fas fa-shield-alt"></i>
					</ul>
				</div>
			</div>
		</>
	);
}
