import EditableParagraph from "modules/WebBuilder-V2/shared/EditableParagraph";
import usePreviewMode from "hooks/usePreviewMode";
import ToggleCheckbox from 'modules/WebBuilder-V2/shared/ToggleCheckbox';
import Locale from 'translations';
import { FlightIcon, HotelIcon, PackageIcon, ToursIcon, TransportIcon, VisaIcon } from '../icons';


export default function ServiceCardThemeTen({
	type,
	header,
	body,
	visible,
	editCardDetails
}) {
	const { webBuilder } = Locale;
	const { isPreview } = usePreviewMode();

	let icon;
	switch (type) {
		case "hotels":
			icon = <HotelIcon />;
			break;
		case "visa":
			icon = <VisaIcon />;
			break;
		case "transport":
			icon = <TransportIcon />;
			break;
		case "transfer":
			icon = <TransportIcon />;
			break;
		case "tours":
			icon = <ToursIcon />;
			break;
		case "packages":
			icon = <PackageIcon />;
			break;
		case "flights":
			icon = <FlightIcon />;
			break;
		default:
			icon = "";
	}

	return (
		<div className="service-card">
			<span className="icon">
				{icon}
			</span>
			<h3 className="title">{webBuilder[type]}</h3>
			{/* <EditableParagraph
				className="sub-title"
				contentEditable={visible}
				onInput={(value) => {
					editCardDetails(value, "header");
				}}
				text={header}
			/> */}
			<EditableParagraph
				className="description"
				contentEditable={visible}
				onInput={(value) => {
					editCardDetails(value, "body");
				}}
				text={body}
			/>
			{!isPreview ? (
				<ToggleCheckbox
					checked={visible}
					onChange={(e) => {
						const value = e.target.checked;
						editCardDetails(value, "visible");
					}}
					label="Visible"
				/>
			) : null}
		</div>
	);
}
