import React, { useState } from "react";
import Locale from "translations";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import ReservationHeader from "./ReservationHeader";
import DetailsTab from "./DetailsTab";
import AccommodationTab from "./AccommodationTab";
// import DashboardLayout from "layouts/DashboardLayout";

export default function ReservationTabs(props) {
	const { reservation } = Locale;
	const [activeTab, setActiveTab] = useState("1");
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	return (
		<>
			{/* <DashboardLayout /> */}
			{/* Header */}
			<ReservationHeader />

			<div className="main-tabs">
				<Nav tabs className="align-items-center main-nav">
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === "1" })}
							onClick={() => {
								toggle("1");
							}}
						>
							{reservation.messages.details}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === "2" })}
							onClick={() => {
								toggle("2");
							}}
						>
							{reservation.messages.accommodation}
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab}>
					<TabPane tabId="1">
						<DetailsTab />
					</TabPane>

					{/* Hotel Tab */}
					<TabPane tabId="2">
						<AccommodationTab />
					</TabPane>
				</TabContent>
			</div>
		</>
	);
}
