import axios from "axios";
import { cleanEmpty } from "./general";

const safavisaURL = process.env.REACT_APP_API_URL + "/v1/safavisa";

export const getToken = async (data) => {
	return await axios
		.post(`${safavisaURL}/login`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const logOutVisa = async (data) => {
	return await axios
		.post(`${safavisaURL}/logout`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewUser = async () => {
	return await axios
		.post(`${safavisaURL}/logout/view`, "")
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchGroups = async (token, size, page, filter) => {
	let formData = "";
	formData = new FormData();
	formData.append("token", token);
	formData.append("data[size]", size);
	formData.append("data[page]", page);
	return await axios
		.post(`${safavisaURL}/groups`, formData, { params: cleanEmpty(filter) })
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchSearchGroups = async (token, size, page, filter) => {
	let formData = "";
	formData = new FormData();
	formData.append("token", token);

	return await axios
		.post(`${safavisaURL}/search/groups`, formData, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchContractRow = async (token) => {
	return await axios
		.post(`${safavisaURL}/contract`, token)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewGroup = async (id, token, search) => {
	return await axios
		.post(`${safavisaURL}/members/in/${id}`, token, {
			params: cleanEmpty(search),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const searchMember = async (token, search) => {
	return await axios
		.post(`${safavisaURL}/search/members`, token, {
			params: cleanEmpty(search),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

//member/309055/14369
export const viewMember = async (onlineId, groupId, token) => {
	return await axios
		.post(`${safavisaURL}/member/${onlineId}/${groupId}`, token)
		.then((res) => res)
		.catch((err) => err.response);
};

///search/visa
export const getVisaNumber = async (token, data) => {
	return await axios
		.post(`${safavisaURL}/search/visa`, cleanEmpty(token, data))
		.then((res) => res)
		.catch((err) => err.response);
};
///search/visa
export const printVisaNumber = async (token, data) => {
	return await axios
		.post(`${safavisaURL}/print/visa`, cleanEmpty(token, data))
		.then((res) => res)
		.catch((err) => err.response);
};
//group dropdown
export const fetchGroupsDropdown = async (token) => {
	return await axios
		.get(`${safavisaURL}/status/dropdwn?token=${token}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const FetchMofaNumber = async (data) => {
	return await axios
		.post(`${safavisaURL}/send/passengers/mofa`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const FetchWayMofa = async (data) => {
	return await axios
		.post(`${safavisaURL}/fetch/mofa/way/groups`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const FetchTableMofa = async (group_id, data) => {
	return await axios
		.post(`${safavisaURL}/fetch-mofa-table/${group_id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const ActiveMofa = async (data) => {
	return await axios
		.post(`${safavisaURL}/activate-mofa`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const SendEup = async (data) => {
	return await axios
		.post(`${safavisaURL}/send-passport-eup`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const SendHayaa = async (data) => {
	return await axios
		.post(`${safavisaURL}/send-passport-hayaa-sudan`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
