import CustomModal from "modules/WebBuilder/shared/CustomModal";
import React, { useEffect, useMemo, useState } from "react";
import Locale from "translations";
import TextField from 'components/Form/TextField/TextField';
import { store } from 'react-notifications-component';
import { publishPublicPage } from 'services/webBuilder';
import { useSBSDispatch, useSBSState } from 'context/global';
import NumberField from 'components/Form/NumberField/NumberField';
import SelectField from 'components/Form/SelectField/SelectField';

const PublishModal = ({ isOpen, toggle, updateWhatsAppNumber, phone_code, phone_number, slug }) => {
	const { productsBuilder } = Locale;
	const { companyInfo, allCountries } = useSBSState();
	const dispatch = useSBSDispatch();

	const [whatsAppPhone, setwhatsAppPhone] = useState({
		phone_code: { id: phone_code, label: phone_code, name: phone_code, phone_code: phone_code },
		phone_number: phone_number,
	});
	const [pageSlug, setPageSlug] = useState(slug)
	const [currentStep, setCurrentStep] = useState("whatsApp");


	const countriesPhoneCodes = useMemo(() => {
		return allCountries?.map(country => ({ ...country, name: country?.phone_code }))
	}, [allCountries]);
	const { webBuilder } = Locale;


	const applySlug = async () => {
		const res = await publishPublicPage({ slug: pageSlug });
		if (res.status === 200) {
			store.addNotification({
				title: "info!",
				message: "Published successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1100,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			dispatch({
				type: "updateCompanyInfo",
				companyInfo: { ...companyInfo, public_page_slug: res?.data?.data?.slug },
			});
			toggle()
		}
	};

	useEffect(()=> {
		if(phone_number) {
			setCurrentStep("publish")
		}
	},[])


	return (
		<CustomModal
			isOpen={isOpen}
			toggle={toggle}
			header={webBuilder.Publish}
			size="md"
			classNames={"operation-modal"}>
			<div className="container main-modal modal-publish">
				<div className=" d-flex justify-content-between flex-wrap">
					<div className="col-md-12">
						<div className="main-label">
							{!phone_number &&
								<div className=' d-flex justify-content-center   align-items-center btn-tab'>
									<span className={`step ${currentStep === "whatsApp" ? "active" : ""}`}> </span>
									<span className={`step ${currentStep === "publish" ? "active" : ""}`}> </span>
								</div>
							}

							{currentStep === "whatsApp" && !phone_number ?
								<>
									<h3>
										You are about to publish your website. <br />
										Please add a What's App number for configuration.
									</h3>
									<div className="contact-us-fields">
										<label htmlFor="">What’s App Number</label>
										<div className="row mx-0">
											{/* phone code */}
											<div className="col-2 px-0 company_phone_code">
												<SelectField
													// label={"whatsApp Number"}
													placeholder="Select"
													hasLabel={false}
													id="phone_code"
													name="phone_code"
													value={whatsAppPhone.phone_code?.label || whatsAppPhone?.phone_code?.phone_code}
													onChange={(e) => setwhatsAppPhone({ ...whatsAppPhone, phone_code: e })}
													options={countriesPhoneCodes}
												/>
											</div>
											{/* phone */}
											<div className="col-9 px-0">
												<NumberField
													type={"phone"}
													label=""
													hasLabel={false}
													id="phone_number"
													name="phone_number"
													value={whatsAppPhone.phone_number}
													removeArrow={true}
													placeholder={"000 0000 000"}
													min={3}
													onChange={(e) => setwhatsAppPhone({ ...whatsAppPhone, phone_number: e.target.value })}
												/>
											</div>
										</div>
									</div>
								</>
								:
								null
							}

							{currentStep === "publish" ?
								<div className="">
									<TextField
										type="text"
										// placeholder={"Type prodect Name ..."}
										placeholder={productsBuilder.productNamePlaceholde}
										value={pageSlug}
										onChange={(e) =>
											setPageSlug(e.target.value)
										}
										label={productsBuilder.Name}
									/>
								</div>
								:
								null
							}

						</div>
					</div>
				</div>
				<hr />
				<div className='modal-foot'>
					<button className="btn  primary-button primary-button-outline " onClick={toggle} >Cancel</button>
					<button className={`btn primary-button`}
						onClick={() => currentStep === "whatsApp" ? updateWhatsAppNumber(whatsAppPhone.phone_code, whatsAppPhone.phone_number, setCurrentStep("publish")) : applySlug()}
					// disabled={currentStep === "whatsApp" && }
					>
						{currentStep === "whatsApp" && !phone_number ? "Next" : "publish"}

					</button>
				</div>
			</div>
		</CustomModal>
	);
};

export default PublishModal;
