import React from 'react'
import Locale from 'translations'
import { ReactComponent as TripDateIcon } from 'assets/images/trip-planner/routing-2.svg'
import { ReactComponent as TrickCircleIcon } from 'assets/images/trip-planner/tick-circle.svg'
import moment from 'moment'

export default function TripDates({ tripsDatesList, selectedTripDate, handleSelectTripDate, deleteNonPublishTrip }) {
	const { packagePlanner } = Locale;
	
	return (
		<div className='trip-dates'>

			<div className='tripDates-header'>
				<TripDateIcon />
				<h6>{packagePlanner.allTripsDates}</h6>
			</div>
			{tripsDatesList?.map(trip => {
				const isActive = trip?.product_uuid === selectedTripDate?.product_uuid;
				return (
					<div className={`tripDates-main  ${isActive ? "active" : "pointer"}`}
						onClick={() => !isActive && handleSelectTripDate(trip)}
						key={trip?.product_uuid}
					>

						<div className='tripDates-items'>
							{trip?.has_price ? <TrickCircleIcon /> : null}
							<p className='dates'>{moment(trip?.departure_date_at).format("DD-MMM-YYYY")}</p>
						</div>
						<div className='tripDates-items justify-content-center'>
							<p className='dates'>{moment(trip?.return_date_at).format("DD-MMM-YYYY")}</p>
						</div>
						{/* hide actions if trip has reservation */}
						<div className='dates-actions mx-3'>
							{!trip?.is_published ?
								<span className={`${isActive ? "hidden" : ""}`} role='button'
									onClick={(e) => !isActive && deleteNonPublishTrip(e, trip?.product_uuid)}
								>
									<svg width="11px" height="11px" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 9L9 1M9 9L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" />
									</svg>
								</span>
								:
								null
							}
						</div>

					</div>
				)
			})}
		</div>
	)
}
