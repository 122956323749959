import clearIcon from "assets/images/wallet/clearIcon.svg";
import correctIcon from "assets/images/wallet/correctIcon.svg";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import { useSBSState } from "context/global";
import useFetchCountries from "hooks/useFetchCountries";
import { useEffect, useState } from "react";
import { cycleTypeLookup } from "services/transportation";
import Locale from "translations";

export default function Filter({ filter, setFilter, search }) {
	const { locale, allCountries } = useSBSState();
	const { payment, productElements } = Locale;
	const status = [
		{ id: 0, name: `${payment.messages.Approved}`, title: "approved" },
		{ id: 1, name: `${payment.messages.Pending}`, title: "pending" },
		{ id: 2, name: `${payment.messages.Expired}`, title: "expired" },
	];
	const [types, setTypes] = useState([]);
	useEffect(() => {
		async function fechType() {
			const res = await cycleTypeLookup();
			const cycleTypes = res?.data?.data?.map((cycleType) => {
				return { id: cycleType?.id, name: cycleType?.name[locale] };
			});
			setTypes(cycleTypes);
		}
		fechType();
	}, []);

	const countries = allCountries;
	
	return (
		<>
			<div className="filter-update bg-filter-gray">
				<div className="product-buttons col-md-11">
					<div className="main-filter-payment d-flex justify-contain-between">
						{/* <div className="main-label controls-field col-2">
							<TextField
								label={payment.messages.search}
								type="text"
								placeholder={payment.messages.search}
								onChange={(e) =>
									setFilter({ ...filter, search: e.target.value })
								}
								value={filter.search}
							/>
						</div> */}
						<div className="main-label  controls-field col-md-2 col-6">
							<SelectField
								label={payment.messages.destination}
								placeholder={payment.messages.destination}
								options={countries}
								value={filter?.destination_id?.name}
								onChange={(e) =>
									setFilter({
										...filter,
										destination_id: e,
									})
								}
							/>
						</div>
						<div className="main-label  controls-field col-md-2 col-6">
							<SelectField
								label={productElements.cycleType}
								placeholder={productElements.cycleType}
								options={types}
								value={filter?.cycle_type_id.name}
								onChange={(e) =>
									setFilter({
										...filter,
										cycle_type_id: e,
									})
								}
							/>
						</div>
						<div className="main-label date-filter col-md-2 col-6">
							{" "}
							<DatePickerField
								label={payment.messages.Date}
								placeholder="DD/MM/YYY"
								date={filter.created_at}
								onChangeDate={(e) => {
									setFilter({ ...filter, created_at: e });
								}}
								isOutsideRange={(day) => {
									return false;
								}}
							/>
						</div>
						<div className="main-label  controls-field col-md-2 col-6">
							<SelectField
								label={payment.messages.status}
								placeholder={payment.messages.status}
								options={status}
								value={filter?.status?.name}
								onChange={(e) =>
									setFilter({
										...filter,
										status: {
											id: e.value,
											name: e.label,
											title: status[e.value].title,
										},
									})
								}
							/>
						</div>
						<div className="filter-btn col-md-4 col6 d-flex align-items-center justify-content-start">
							<div className="apply pointer">
								<button className="btn btn-light px-3" onClick={search}>
									<img src={correctIcon} alt=" " />
									{/* 									<i className="fas  fa-check position-static "></i>
									 */}{" "}
									{payment.messages.apply}
								</button>
							</div>
							<div
								className="clear text-secondary pointer"
								onClick={() => {
									setFilter({
										search: "",
										destination_id: "",
										cycle_type_id: "",
										created_at: "",
										status: "",
										page: 1,
									});
									search();
								}}
							>
								<button className="btn btn-light px-3 mx-2">
									<img src={clearIcon} alt=" " /> {payment.messages.clear}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
