import DatePickerField from 'components/Form/DatePickerField/DatePickerField'
import SelectField from 'components/Form/SelectField/SelectField'
import React from 'react'
import Locale from 'translations'

const SubscriptionPlanFilter = ({ filters, setFilters, getSubscriptionList,resetFilters }) => {
	const {subscriptionModule}=Locale

	const Status = [
		{ id: 'inactive', name: subscriptionModule.inactive },
		{ id: 'active', name: subscriptionModule.active},
		{ id: 'canceled', name: subscriptionModule.canceled },
		{ id: 'expired', name: subscriptionModule.expired }
	]

	const PaymentStatus = [
		{ id: 'pending', name: subscriptionModule.pending},
		{ id: 'free', name: subscriptionModule.free },
		{ id: 'paid', name: subscriptionModule.paid },
		{ id: 'expired', name: subscriptionModule.expired }
	]

	return (
		<div className='filter-plan-wrapper'>
			<div className='filter-plan-item'>
				<div className='filter-filed'>
					<SelectField
						label={subscriptionModule.Status}
						placeholder={subscriptionModule.All}
						options={Status}
						value={filters?.status?.name}
						onChange={(e) => {
							setFilters({ ...filters, status: e })
						}}
					/>
				</div>
				<div className='filter-filed'>
					<SelectField
						label={subscriptionModule.PaymentStatus}
						placeholder={subscriptionModule.All}
						options={PaymentStatus}
						value={filters?.payment_status?.label}
						onChange={(e) => {
							setFilters({ ...filters, payment_status: e })
						}}
					/>
				</div>
				<div className='filter-filed'>
					<DatePickerField
						label={subscriptionModule.Date}
						date={filters.date}
						placeholder='DD/MM/YYYY'
						onChangeDate={(filterDate) => {
							setFilters({
								...filters,
								date: filterDate
							})
						}}
						isOutsideRange={(day) => {
							return false
						}}
						labelInner={false}
						customIcon={true}
					/>
				</div>
			</div>
			<div className='filter-plan-item'>
				<button className='btn btn-filter btn-apply' onClick={() => getSubscriptionList()}>{subscriptionModule.Apply}</button>

				<button className='btn btn-filter btn-clear' onClick={() => resetFilters()}>{subscriptionModule.Clear}</button>
			</div>
		</div>
	)
}

export default SubscriptionPlanFilter
