import {
	DownloadPdf,
	GallaryEditIcon,
	UploadImage,
} from "modules/UmrahOperations/shared/Icons";
import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import generatePdfImg from "assets/images/trip-planner/generatePdf.png";
import { uploadFile } from "services/auth";
import imgIcon from "assets/images/customRegister/image-regular.svg";
import validate, { isFormValid } from "helpers/validate";
import Locale from 'translations';

const fileUrl = `${process.env.REACT_APP_FILEUPLOAD_URL}/fetch`;

export default function GeneratePdfModel({
	isOpen,
	toggle,
	state,
	setState,
	dawnloadPDF,
}) {
	const { packagePlanner } = Locale;
	const imageUrl = process.env.REACT_APP_API_URL + "/v1/files/fetch/";
	const inputFileRef = useRef(null);

	const handleUpload = async (value, e) => {
		let key = value === "logo" ? "logo_preview" : "header_preview";
		const file = e.target.files[0];

		/***** */
		var img = new Image();
		img.src = window.URL.createObjectURL(e.target.files[0]);
		img.onload = function () {
			var width = img.naturalWidth,
				height = img.naturalHeight;

			// unload it
			window.URL.revokeObjectURL(img.src);

			// check its dimensions
			if (width <= 64 && height <= 64) {
				// it fits
			} else {
				// it doesn't fit, unset the value
				// post an error
				// alert("only 64x64 images")
			}
		};

		/****** */
		let formData = new FormData();
		formData.append("name", file?.name);
		formData.append("bucket", "webBuilder");
		formData.append("file", file);
		const isImgSelected =
			e.target.files.length > 0 && formData !== undefined
				? await uploadFile(formData)
				: null;
		if (isImgSelected?.status === 200) {
			setState({
				...state,
				[value]: isImgSelected.data.data.uuid,
				[key]: `${fileUrl}/${isImgSelected.data.data.uuid}`,
			});
		}
		console.log(state);
	};
	const handleBtnClick = () => {
		inputFileRef.current.click();
	};

	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [errors, setErrors] = useState();
	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate({ name: "logo", value: state.logo }, { required: true }),
			...validate({ name: "header", value: state.header }, { required: true }),
			...validate({ name: "footer", value: state.footer }, { required: true }),
		});
	};

	const Download = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	useEffect(() => {
		if (isFormValid(errors)) {
			dawnloadPDF(state);
		}
	}, [isErrorLoaded]);

	return (
		<Modal
			isOpen={isOpen}
			size="lg"
			centered={true}
			hasFooter={false}
			className="operation-statement-numbers-model send-company-model generate-pdf-model"
		>
			<ModalHeader toggle={() => {
				toggle();
				setState({
					logo: undefined,
					header: undefined,
					footer: "",
				})
			}}>
				{packagePlanner.generateMarketingPDF}
			</ModalHeader>
			<ModalBody>
				<div className="container p-2 pb-4">
					<div className="row">
						<div className="col-md-8">
							<h3 className="logo-text">{packagePlanner.yourLogo}</h3>
							<div className="logo">
								<input
									type="file"
									ref={inputFileRef}
									onChange={(e) => {
										handleUpload("logo", e);
										setErrors({
											...errors,
											...validate(
												{ name: "logo", value: e },
												{ required: true }
											),
										});
									}}
									className="d-none"
									accept="image/*"
									name="logo"
								/>
								<div className="image-uploader" onClick={handleBtnClick}>
									<div className="image-uploader__outline position-relative bg-white img-center mx-auto mx-md-0">
										<div className="img-holder ">
											<img
												src={state.logo ? `${imageUrl}${state.logo}` : imgIcon}
												className={"img-fluid"}
												accept="image/*"
												alt="company profile"
											/>
										</div>
										<span className="image-uploader__plus-btn">
											<GallaryEditIcon />
										</span>
										{/* <i className="fas fa-plus-circle image-uploader__plus-btn"></i> */}
									</div>
								</div>
							</div>
							<h5>
								{packagePlanner.uploadHeaderImage} <span>({packagePlanner.dimensions} 560*160)</span>{" "}
							</h5>
							<div
								className={`p-3 d-flex justify-content-between align-items-center  flex-wrap position-relative upload-header-images upload-images `}
							>
								<div className="d-flex justify-content-center w-100">
									<label
										onChange={handleUpload}
										htmlFor="uploadFile"
										className=" text-black px-4 d-flex flex-column align-items-center"
									>
										{state.header ? (
											<img
												src={`${imageUrl}${state.header}`}
												alt=""
												width="100%"
												height="90px"
											/>
										) : (
											<UploadImage />
										)}
									</label>
									<input
										multiple="multiple"
										name="header"
										onChange={(e) => {
											handleUpload("header", e);
										}}
										type="file"
										id="uploadFile"
										accept="image/*"
									/>
								</div>
								<div className="images-gallery w-100"></div>
							</div>
							<div className="d-flex flex-column textarea">
								<label
									htmlFor="footer_details"
									className="control-field__label "
								>
									{packagePlanner.footerText}
								</label>
								<textarea
									placeholder={packagePlanner.enterTextAppearFooter}
									name="footer"
									id="footer"
									rows="3"
									className={`w-100 ${errors?.footer?.required ? "border-danger" : ""
										}`}
									value={state?.footer}
									onChange={(e) => {
										setState({ ...state, footer: e.target.value });
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
											}),
										});
									}}
									color={errors?.footer?.required ? "danger" : ""}
									errors={errors?.footer}
								/>
							</div>
						</div>
						<div className="col-md-4">
							<img src={generatePdfImg} alt="" className="generate-pdf-img" />
						</div>

						<button
							className="btn mt-3  w-100  d-flex justify-content-center"
							style={{
								backgroundColor: "#DAA249",
								fontSize: "16px",
								fontWeight: 700,
								color: "#FFFFFF",
								gap: "5px",
							}}
							onClick={() => Download()}
						>
							<DownloadPdf />
							{packagePlanner.downloadPDF}
						</button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
}
