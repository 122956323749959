import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import Locale from "translations";
import classnames from "classnames";
import carCray from "assets/images/inventory/carCray.svg";

export default function TransferBookingTopbar({
	dawnloadInvoice,
	printVoucher,
	status
}) {
	const { inventory } = Locale;

	const confirmedStatus = status === "CONFIRMED";

	return (
		<div className="d-flex justify-content-between bg-gray">
			<Nav tabs>
				<NavItem>
					<NavLink className={`border-0  ${classnames({ active: true })}`}>
						<div className="font-weight-bold text">
							<img src={carCray} alt="" />
							<span className="text mx-1">
								{inventory.messages.bookingDetails}
							</span>
						</div>
					</NavLink>
				</NavItem>
			</Nav>
			{/* actions */}
			{confirmedStatus&&<div className="tansfer-details-actions d-flex align-items-center">
				<button
					className="btn bg-nxt mx-md-2 d-flex align-items-center"
					onClick={dawnloadInvoice}
				>
					<i className="fas fa-download mx-2"></i>
					<span>{inventory.messages.invoice}</span>
				</button>
				<button
					className="btn bg-nxt mx-2 d-flex align-items-center"
					onClick={() => printVoucher()}
				>
					<i className="fas fa-download mx-2"></i>
					<span className='text-nowrap'>{inventory.messages.transferVoucher}</span>
				</button>
			</div>}
		</div>
	);
}
