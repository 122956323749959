import React from "react";
import Locale from "translations";

const passengersType = {
	ADT: "Adult",
	CHD: "Child",
};

export default function PassengersSelection({ passengersList, setPassengersList }) {
	const { inventory } = Locale;
	function handlePassengersSelect(index) {
		const passengersListClone = [...passengersList];
		const currentSelectedPassenger = passengersListClone[index];
		passengersListClone[index] = {
			...currentSelectedPassenger,
			selected: !currentSelectedPassenger?.selected,
		};
		setPassengersList(passengersListClone);
	}

	return (
		<div className="mx-md-4 mx-1 w-100">
			<h2 className="my-2 font-weight-bold">
				{inventory.messages.selectPassenger}
			</h2>
			{passengersList?.map((passenger, index) => {
				return (
					passenger.status === 'confirmed' &&
					<div
						className={`flight-actions-passenger ${passenger?.selected ? "selected" : ""} col-md-9 col-12`}
						key={index}
						onClick={() => handlePassengersSelect(index)}
					>
						<i className="far fa-user-circle"></i>
						<span className="col-6 mx-1">{`${passenger?.firstName} ${passenger?.lastName}`}</span>
						<span className="col-3">{passengersType?.[passenger?.psgType]}</span>
						{/* <span className="col-4">Ticket No.</span> */}

						{passenger?.selected ? (
							<span className="col-1 text-center check-mark">
								<i className="fas fa-check"></i>
							</span>
						) : null}
					</div>
				);
			})}
			{passengersList?.length > 0 && passengersList.filter((passenger) => passenger.selected).length === 0 ? (
				<small className="text-danger">
					{inventory.messages.selectPassengerError}
				</small>
			) : null}
			{passengersList.filter((passenger) => passenger.status === 'confirmed').length === 0 ? (
				<h2 className="text-muted font-weight-bolder  px-5">
					<i class="fas fa-exclamation-triangle"></i>{" "}
					Not Found Passenger
				</h2>
			) : null}
		</div>
	);
}
