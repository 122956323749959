import React from 'react'

export default function AttractionIcon({size}) {
  return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size ? size : `32`}
			height={size ? size : `32`}
			viewBox="0 0 32 32"
			fill="none"
		>
			<mask
				id="mask0_1326_1250"
				style={{ maskType: "luminance" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="32"
				height="32"
			>
				<path
					d="M30.9999 31V1.00009H1V31H30.9999Z"
					fill="white"
					stroke="white"
					stroke-width="2"
				/>
			</mask>
			<g mask="url(#mask0_1326_1250)">
				<path
					d="M20.939 8.97094H3.44799C2.06737 8.97094 0.937498 10.1007 0.937498 11.4813V28.5521C0.937498 29.9328 2.06737 31.0625 3.44799 31.0625H28.5518C29.9325 31.0625 31.0624 29.9328 31.0624 28.5521V11.4813C31.0624 10.1006 29.9325 8.97094 28.5518 8.97094H27.8509"
					stroke="#707170"
					stroke-width="2"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M24.3952 2.71125L21.0614 0.937571L20.9306 4.71143L17.7278 6.71155L20.9306 8.71167L21.0614 12.4855L24.3952 10.7119L27.7287 12.4855L27.8597 8.71167L31.0624 6.71155L27.8597 4.71143L27.7287 0.937571L24.3952 2.71125Z"
					stroke="#707170"
					stroke-width="2"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M8.97079 21.0208C8.97079 24.9029 12.1179 28.05 16 28.05C19.8823 28.05 23.0291 24.9029 23.0291 21.0208C23.0291 17.1388 19.8823 13.9917 16 13.9917C12.1179 13.9917 8.97079 17.1388 8.97079 21.0208Z"
					stroke="#707170"
					stroke-width="2"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M11.9833 21.0208C11.9833 23.2392 13.7815 25.0376 16 25.0376C18.2183 25.0376 20.0166 23.2392 20.0166 21.0208C20.0166 18.8025 18.2183 17.0042 16 17.0042C13.7815 17.0042 11.9833 18.8025 11.9833 21.0208Z"
					stroke="#707170"
					stroke-width="2"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M10.9792 8.97095H4.95424V5.95839H10.9792V8.97095Z"
					stroke="#707170"
					stroke-width="2"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M6.96248 13.4897C6.96248 14.3215 6.28804 14.996 5.45623 14.996C4.62449 14.996 3.95005 14.3215 3.95005 13.4897C3.95005 12.6578 4.62449 11.9834 5.45623 11.9834C6.28804 11.9834 6.96248 12.6578 6.96248 13.4897Z"
					stroke="#707170"
					stroke-width="2"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
		</svg>
	);
}
