// ** imports
import { TripITinerary } from 'components/Printing/TripItenerary/TripIteneraryInvoice'
import PrintLogo from 'assets/images/umrah-operations/logo.svg'
import { useParams } from 'react-router-dom'
import { B2BReservationVoucher } from 'components/Printing/B2BReservationVoucher'
import { getPakcageVoucher, inventoryInvoice } from 'services/inventory'
import moment from 'moment'

let roomTypesLookup = {
	1: 'Single',
	2: 'Double',
	3: 'Triple',
	4: 'Quadruple',
	5: 'Quintuple',
	6: 'Hexagonal',
	7: 'heptathlon',
	8: 'suite',
	9: 'flat'
}
const mealsOptions = [
	{ id: "3", name: "All Inclusive" },
	{ id: "2", name: "Half board" },
	{ id: "1", name: "Breakfast" },
	{ id: "0", name: "Room Only" },
];

const useInventoryInvoices = () => {
	const { id } = useParams()

	// ** Inventory Invoice
	const printPackageItinerary = async () => {
		const res = await inventoryInvoice(id)
		if (res?.status === 200) {
			let tableData =
				res?.data?.data?.hotels?.length > 0
					? res?.data?.data?.hotels?.map((ele) => {
						return `
							<div class="arrival-container">
								<h6 class="operation-invoice-heading">Hotels Details</h6>
								<div class="arrivalAndDeparture">
									<div class="d-flex align-items-center">
										<p class="prgramType-text mr-2">${ele?.itemable?.city_name}</p>
										<h3 style="font-weight: bolder">${ele?.itemable?.hotel?.name} &ensp;</h3>
									</div>

									<div class="d-flex">
										<div class="col-3 p-0">
											<div class="d-flex align-items-center">
												<p class="prgramType-text mr-2">Nights&ensp;</p>
												<h3>${ele?.itemable?.night_count}</h3>
											</div>
										</div>
										<div class="col-3 p-0">
											<div class="d-flex align-items-center">
												<p class="prgramType-text">Meals&ensp;</p>
												<h3>${mealsOptions.find(meal => meal.id === ele?.itemable?.meal_type || meal.name === ele?.itemable?.meal_type)?.name}</h3>
											</div>
										</div>
										<div class="col-3">
											<div class="d-flex align-items-center">
												<p class="prgramType-text">Check In&ensp;</p>
												<h3>${moment(ele?.itemable?.check_in_at).format('DD/MM/YYYY')}</h3>
											</div>
										</div>
										<div class="col-3 d-flex justify-content-end">
											<div class="d-flex align-items-center">
												<p class="prgramType-text">Check Out&ensp;</p>
												<h3>${moment(ele?.itemable?.check_out_at).format('DD/MM/YYYY')}</h3>
											</div>
										</div>
									</div>
								</div>
								<hr style="background-color: #e2e8f0;width: 95%;display: block;margin: 15px auto;opacity: 0.2;" />
							</div>
							`
					})
					: []

			const arrivalData =
				res?.data?.data?.flights?.length > 0
					? res?.data?.data?.flights?.map((ele) => {
						return `
							<div class="arrival-container">
								<h6 class="operation-invoice-heading">Flight</h6>
								<div class="arrivalAndDeparture">
									<div class="d-flex align-items-center">
										<p class="prgramType-text mr-2">Arrival To</p>
										<h3 style="font-weight: bold">${ele?.itemable?.to_port?.name} - ${ele?.itemable?.to_port?.code
														}</h3>
									</div>
									<div class="d-flex">
										<div class="col-3 p-0">
											<div class="d-flex align-items-center">
												<p class="prgramType-text">AirLine&ensp;</p>
												<h3>${ele?.itemable?.to_country?.name}</h3>
											</div>
										</div>
										<div class="col-3">
											<div class="d-flex align-items-center">
												<p class="prgramType-text">Trip No.</p>
												<h3>${ele?.itemable?.trip_number ? ele?.itemable?.trip_number : '-'}</h3>
											</div>
										</div>
										<div class="col-3">
											<div class="d-flex align-items-center">
												<p class="prgramType-text">Date&ensp;</p>
												<h3>${moment(ele?.itemable?.arrival_at).format('DD/MM/YYYY')}</h3>
											</div>
										</div>
										<div class="col-3 d-flex justify-content-end">
											<div class="d-flex align-items-center">
												<p class="prgramType-text">Time&ensp;</p>
												<h3>${moment(ele?.itemable?.arrival_at).format('hh:mm')}</h3>
											</div>
										</div>
									</div>
								</div>
								<hr style=" background-color: #e2e8f0; width: 95%; display: block; margin: 15px auto; opacity: 0.2;" />
								<div class="arrivalAndDeparture">
									<div class="d-flex align-items-center">
										<p class="prgramType-text mr-2">Departure From</p>
										<h3 style="font-weight: bold">${ele?.itemable?.from_port?.name} - ${ele?.itemable?.from_port?.code}</h3>
									</div>
									<div class="d-flex">
										<div class="col-3 p-0">
											<div class="d-flex align-items-center">
												<p class="prgramType-text">AirLine&ensp;</p>
												<h3>${ele?.itemable?.from_country?.name}</h3>
											</div>
										</div>
										<div class="col-3">
											<div class="d-flex align-items-center">
												<p class="prgramType-text">Trip No.</p>
												<h3>${ele?.itemable?.trip_number ? ele?.itemable?.trip_number : '-'}</h3>
											</div>
										</div>
										<div class="col-3">
											<div class="d-flex align-items-center">
												<p class="prgramType-text">Date&ensp;</p>
												<h3>${moment(ele?.itemable?.updated_at).format('DD/MM/YYYY')}</h3>
											</div>
										</div>
										<div class="col-3 d-flex justify-content-end">
											<div class="d-flex align-items-center">
												<p class="prgramType-text">Time&ensp;</p>
												<h3>${moment(ele?.itemable?.updated_at).format('hh:mm')}</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
				
				`
					})
					: []

			const attractionsDetails =
				res?.data?.data?.attractions?.length > 0
					? res?.data?.data?.attractions?.map((ele) => {
						return `
							<div class="arrival-container">
								<h6 class="operation-invoice-heading">Attractions Details</h6>
								<div class="arrivalAndDeparture">
									<div class="d-flex">
										<div class="col-3 p-0">
											<h3 style="font-weight: bolder">${ele?.itemable?.name?.en}&ensp;</h3>
										</div>
										<div class="col-3">
											<div class="d-flex align-items-center">
												<p class="prgramType-text">City &ensp;</p>
												<h3>${ele?.itemable?.city ? ele?.itemable?.city?.name : '-'
											}</h3>
											</div>
										</div>

										<div class="col-6 p-0">
											<div class="d-flex align-items-center">
												<p class="prgramType-text mr-2">Date&ensp;</p>
												<h3>${moment(ele?.itemable?.date).format("DD/MM/YYYY - hh:mm")}</h3>
											</div>
										</div>
									</div>
								</div>
								<hr
									style="
										background-color: #e2e8f0;
										width: 95%;
										display: block;
										margin: 15px auto;
										opacity: 0.2;
									"
								/>
							</div>
						</div>
					`
					})
					: []

			const transportationDetails =
				res?.data?.data?.transportation?.length > 0
					? res?.data?.data?.transportation?.map((row) => {
						return `
							<div class="arrival-container">
								<h6 class="operation-invoice-heading">Transportation Details</h6>
								<div class="arrivalAndDeparture">
									<div class="d-flex">
										<div class="col-6 p-0">
											<h3 style="font-weight: bolder">
												${row?.item?.name} &ensp;
											</h3>
										</div>
										<div class="col-6">
											<div class="d-flex align-items-center">
												<p class="prgramType-text">Cycle Type&ensp;</p>
												<h3>${row?.itemable?.transportation_cycle_type?.name}</h3>
											</div>
										</div>
									</div>

									<table>
										<thead>
											<tr>
												<th>Date</th>
												<th>Type</th>
												<th>Pickup Point</th>
												<th>Pickup Time</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>${moment(row?.pickup_time).format('DD/MM/YYYY')}</td>
												<td>${row?.transportation_type ? row?.transportation_type : '-'}</td>
												<td>${row?.itemable?.pickup_point ? row?.itemable?.pickup_point : '-'}</td>
												<td>${moment(row?.pickup_time).format('hh:mm')}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
					`
					})
					: []

			let repos = TripITinerary
			const keyValuePair = {
				'[print_logo]': PrintLogo,
				'[invoice_number]': '00112',
				'[invoice_date]': moment(res?.data?.data?.itemable?.arrival_at).format(
					'DD/MM/YYYY'
				),
				'[arrival_details]': arrivalData.join(''),
				'[hotel_details]': tableData.join(''),
				'[transportation_details]': transportationDetails.join(''),
				'[attractions_details]': attractionsDetails.join(''),
				'[today_date]': res?.data?.data?.today ? moment(res?.data?.data?.today).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY") 
			}

			for (const key in keyValuePair) {
				repos = repos.replace(key, keyValuePair[key])
			}

			var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto')
			popupWin.document.open()
			popupWin.document.write(`
					<html>
					<head>
					<title> print Invoice</title>
					<style>
					@page {

						size: A4;
						
						margin:15px;			
						border: 1px solid black;
						
						}
					</style>
					</head>
					<body onLoad="{()=>window.print()}">${repos}</body>
					</html>`)
			popupWin.document.close()

			setTimeout(() => {
				popupWin.print()
			}, 100)
		}
	}

	async function printPackageVoucher() {
		let voucherHTML = B2BReservationVoucher
		const voucherRes = await getPakcageVoucher(id)
		if (voucherRes?.status === 200) {
			const voucherData = voucherRes?.data?.data;
			const hotels = voucherData?.product_details?.filter(item => item?.item?.id === 6);
			const internalTransportation = voucherData?.product_details?.filter(item => item?.item?.id === 2);
			const addedTravelers = voucherData?.travelers?.filter(traveler => traveler?.first_name && traveler?.last_name);

			let count = 0
			const keyValuePair = {
				'[print_logo]': PrintLogo,
				// reservation metadata
				'[today_date]': voucherData?.today ? moment(voucherData?.today).format("DD/MM/YYYY") : "-",
				'[client_name]': voucherData?.leader_info?.name,
				'[agent_name]': voucherData?.user_name,
				'[from_no]': voucherData?.id,
				'[leader_phone]': voucherData?.leader_info?.phone,
				'[user_phone]': voucherData?.user_phone,
				// travelers names
				'[travelers_names]': addedTravelers?.length > 0 ? addedTravelers?.map((traveler, index) => {
					count++;
					if (count === 3) {
						count = 0;
						return `<td width="33%">${index + 1}. ${traveler?.first_name} ${traveler?.last_name}</td><tr/>`
					} else {
						return `<td width="33%">${index + 1}. ${traveler?.first_name} ${traveler?.last_name}</td>`
					}
				}).join('')
					:
					`
				<tr class="text-center">
					<td>There is no Pilgrims Added</td>
				</tr>
				`
				,

				// pax info
				'[adult_count]': voucherData?.adults_pax || '-',
				'[child_count]': voucherData?.children_pax || '-',
				'[infant_count]': voucherData?.infant_pax || '-',
				'[total_pax]': voucherData?.pax || 0,
				// trip info
				'[trip_date]': voucherData?.departure_date ? moment(voucherData?.departure_date).format("DD/MM/YYYY") : "-",
				'[route]': voucherData?.route || '',
				'[departure_date]': voucherData?.departure_date ? moment(voucherData?.departure_date).format("DD/MM/YYYY") : "-",
				'[return_date]': voucherData?.return_date ? moment(voucherData?.return_date).format("DD/MM/YYYY") : "-",
				'[nights_count]': voucherData?.nights || '',
				// resdince data
				'[hotels_data]': hotels?.map(hotel => {
					const hotelDetails = hotel?.itemable;
					return (
						`
						<tr>
							<td class="col-head">Residence in ${hotelDetails?.city_name}</td>
							<td>${hotelDetails?.hotel?.name || "-"}</td>
							<td>${hotelDetails?.night_count || "-"}</td>
							<td>${hotelDetails?.meal_type}</td>
						</tr>
						`
					)
				})?.join(""),
				// Transportation Details
				'[transportation_company]': "",
				'[cycle_type]': internalTransportation?.[0]?.itemable?.transportation_cycle_type?.name,
				'[transportation_data]': internalTransportation?.map(item => {
					return `
					<tr>
						<td>${item?.itemable?.pickup_time ? moment(item?.itemable?.pickup_time).format("DD/MM/YYYY") : ""}</td>
						<td>${item?.itemable?.pickup_point || "-"}</td>
						<td>${item?.itemable?.drop_off_point || "-"}</td>
						<td>${item?.itemable?.pickup_time ? moment(item?.itemable?.pickup_time).format("hh:mm") : ""}</td>
					</tr>
					`
				})
					.join(''),
				// visa details
				'[visa_adults_count]': voucherData?.adults_pax || "-",
				'[visa_childs_count]': voucherData?.children_pax || "-",
				'[visa_infant_count]': voucherData?.infant_pax || "-",

				// rooms data
				'[rooms_data]': voucherData?.room_types?.map((room) => {
					return `<tr>
              <td class="bg-gray-300">${roomTypesLookup[room?.room_type]}</td>
              <td>${room?.adults_count || '-'}</td>
              <td>${room?.children_count || '-'}</td>
            </tr>`
				}).join(""),
				// extra services
				'[extra_services]': voucherData?.extra_services?.length > 0 ? voucherData?.extra_services?.map(service => {
					return `
						<tr col>
              <td class="bg-gray-300">${service?.name}</td>
              <td>${service?.quantity}</td>
            </tr>
					`
				}).join("")
					:
					`
					<tr>
						<td colspan="2">No Other Services</td>
					</tr>
				`

				,
				// payment
				'[payments_outstanding]': voucherData?.payments_outstanding,
				// 
				'[first_patch]': voucherData?.payments_total_paid - voucherData?.payments_outstanding < 0 ? 0 : voucherData?.payments_total_paid - voucherData?.payments_outstanding,
				'[expire_at_date]': voucherData?.expire_at || "",
				'[reminder]': voucherData?.payments_outstanding,

			}
			for (const key in keyValuePair) {
				voucherHTML = voucherHTML.replace(key, keyValuePair[key])
			}

			var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto')
			popupWin.document.open()
			popupWin.document.write(`
					<html>
					<head>
					<title> print Invoice</title>
					<style>
					@page {

						size: A4;
						
						margin:15px;			
						border: 1px solid black;
						
						}
					</style>
					</head>
					<body onLoad="{()=>window.print()}">${voucherHTML}</body>
					</html>`)
			popupWin.document.close()

			setTimeout(() => {
				popupWin.print()
			}, 100)
		}
	}

	return {
		printPackageItinerary,
		printPackageVoucher
	}
}

export default useInventoryInvoices
