import NumberField from 'components/Form/NumberField/NumberField';
import SelectField from 'components/Form/SelectField/SelectField'
import { useSBSState } from 'context/global';
import { pricingForLookup } from 'modules/TripPlanner/helpers/tripPlannerLookups';
import React from 'react'
import { useLocation } from 'react-router-dom';
import Locale from 'translations';

export default function PricingHeader({ pricingData, setPricingData, selectedPricingType, setSelectedPricingType, allCurrencies, saveRoomsAvailability, productType }) {
	// hooks

	const { allLookupsBuilder } = useSBSState();
	const location = useLocation();
	const isProductBuilder = location.pathname.includes("products-builder");
	const { productElements, productsBuilder, packagePlanner, marketPlace } = Locale;
	const productHasHotel = pricingData?.product_items?.find(item => item?.item?.id === 6) ? true : false;
	const isProductServiceOnly = productType?.id === 6;
	const isProductCustomPackage = productType?.id === 5
	const pricingHasCombination = pricingData?.product_price_combinations?.find(c => c?.combinations?.length > 0);
	const adultPax = pricingData?.adults_pax

	function handleChangeInputs({ key, value }) {
		let pricing = pricingData?.pricing;
		if (key === "pricing_for" && pricing.length === 0) {
			pricing = [
				{
					for: "b2b",
					rooms: [{ room_type: null, price_adult: "", price_child: "" }]
				},
				{
					for: "b2c",
					rooms: [{ room_type: null, price_adult: "", price_child: "" }]
				}
			]
		}
		setPricingData({
			...pricingData,
			[key]: value,
			selected_pricing_for: key === "pricing_for" && (!pricingData?.selected_pricing_for || value?.id !== pricingData?.selected_pricing_for)
				? key === "pricing_for" && (value?.id?.toLowerCase() === "b2b" || value?.id === "B2B_B2C") ? "b2b" : value?.id?.toLowerCase() === "b2c" ? "b2c" : null
				: pricingData?.selected_pricing_for,
			pricing
		})
	}


	const updateRoomsCount = (index, value) => {
		let arr = pricingData.room_types;
		arr[index]["count"] = Number(value).toString();
		setPricingData({
			...pricingData,
			room_types: arr,
		});
	};

	const showFullPackagePrice = (isProductCustomPackage && productHasHotel) || (!isProductCustomPackage && !isProductServiceOnly);

	return (
		<div className="pricing-header">
			{adultPax > 0 &&
				<div className="row mx-0 w-100">
					<div className="d-flex align-items-center gap-10">
						<span className="font-weight-bold">{productsBuilder.typeOfPricing} :</span>
						{/* select pricing type */}

						<div className="select-type">
							{showFullPackagePrice ?
								<button className={`btn shadow-none p-0 ${selectedPricingType === "fullpackage" ? "active" : ""}`}
									onClick={() => {
										setSelectedPricingType("fullpackage")
									}}
								>
									{productsBuilder.fullPakcagePerPax}
								</button>
								:
								null
							}

							<button className={`btn shadow-none p-0 ${selectedPricingType === "breakdown" ? "active" : ""}`}
								onClick={() => {
									setSelectedPricingType("breakdown")
								}}
							>
								{productsBuilder.packageBreakdown}
							</button>

						</div>
					</div>
					{/* currency */}
					<div className="select-input">
						<label htmlFor="select_currency">{productsBuilder.currency} : </label>
						<div className="w-50">
							<SelectField
								hasLabel={false}
								id="select_currency"
								name="select_currency"
								options={allCurrencies}
								value={pricingData?.currency?.name}
								onChange={(e) => handleChangeInputs({ key: "currency", value: e })}
							/>
						</div>
					</div>
				</div>
			}
			<div className="row mx-0 gap-10 w-100">
				{/* adults count */}
				<div className="d-flex align-items-center gap-10">
					<label htmlFor="adult_count">{packagePlanner.adultPax}:</label>
					<div>
						<NumberField
							hasLabel={false}
							removeArrow={true}
							id="adult_count"
							name="adult_count"
							value={pricingData?.adults_count}
							onChange={(e) => handleChangeInputs({ key: "adults_count", value: Number(e.target.value).toString() })}
							disabled={isProductBuilder}
						/>
					</div>
				</div>
				{/* children count */}
				<div className="d-flex align-items-center gap-10">
					<label htmlFor="children_count">{packagePlanner.childPax}:</label>
					<div>
						<NumberField
							hasLabel={false}
							removeArrow={true}
							id="children_count"
							name="children_count"
							value={pricingData?.children_count}
							onChange={(e) => handleChangeInputs({ key: "children_count", value: e.target.value })}
							disabled={isProductBuilder}
						/>
					</div>
				</div>
				{adultPax > 0 &&
					<div className="select-input">
						<label htmlFor="pricing_for">{productsBuilder.pricingFor}: </label>
						<div className="w-50">
							<SelectField
								hasLabel={false}
								id="pricing_for"
								name="pricing_for"
								options={pricingForLookup}
								value={pricingData?.pricing_for?.name}
								onChange={(e) => handleChangeInputs({ key: "pricing_for", value: e })}
							/>
						</div>
					</div>
				}
			</div>
			{!isProductBuilder ?
				<>
					{productHasHotel &&

						<div className="row mx-0 w-100">
							<div className="col-md-4">
								<SelectField
									multi
									class="fixH"
									label={productElements.roomsTypes}
									options={allLookupsBuilder?.room_types}
									placeholder={
										pricingData.room_types?.length > 0
											? pricingData.room_types
											: productElements.roomsTypes
									}
									defaultValue={() => {
										return pricingData.room_types?.map(
											(room) =>
											(room = {
												value: room.id || room.value,
												label: room.name || room.label,
											})
										);
									}}
									getOptionLabel={(x) => x.name}
									getOptionValue={(x) => x.id}
									onChange={(values) => {
										if (values && values.length > 0) {
											let x = values.map(res => { return { ...res, count: res.count ? res.count : 1 } })
											setPricingData({ ...pricingData, room_types: x });
										}

									}}
								/>
							</div>
							<div className="col-md-8">
								<div className="room-count mt-4">
									<div className="room-box mt-2">
										{pricingData?.room_types?.map((room, index) => {
											return (
												<div className="d-inline-flex mt-1 mr-2">
													<div className="room-item b-radius-left">
														{room?.name}
													</div>
													<div className="room-count b-radius-right">
														<input
															type="number"
															min="1"
															max="999"
															className="text-input"
															value={room?.count || 1}
															placeholder={productElements.roomCountPlaceholder}
															onChange={(e) => {
																updateRoomsCount(index, e.target.value);
															}}
														// onInput={maxLengthCheck}
														// onBlur={validateRoomsType}
														/>
													</div>
												</div>

											)
										})}
									</div>
								</div>
							</div>
						</div>
					}
					{!pricingHasCombination && adultPax === 0 ?
						<button className="btn btn bg-nxt py-2" onClick={saveRoomsAvailability}>{productsBuilder.Save}</button> :
						// null
						<button className="btn btn bg-nxt py-2" onClick={() => saveRoomsAvailability(true)}>{marketPlace.Edit}</button>

					}
				</>
				:
				null
			}
		</div>
	)
}
