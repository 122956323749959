import React from "react";
import CityBanner from "./cityBanner";
import CityHotelCard from "./CityHotelCard";

export default function CityHotelsSection({
	create,
	cityDetails,
	setSelectedHotels,
	selectedHotels,
}) {
	return (
		<div
			className="w-100 py-5"
			id={`${cityDetails?.city_name}_${cityDetails?.city_id}`}
		>
			<CityBanner cityDetails={cityDetails} />
			<div className="container pt-4">
				<div
					className="d-flex align-items-center justify-content-start flex-wrap "
					style={{ rowGap: "20px", gap: "20px" }}
				>
					{cityDetails?.hotels?.map((hotel) => (
						<CityHotelCard
							key={hotel?.id}
							create={create}
							hotelDetails={hotel}
							setSelectedHotels={setSelectedHotels}
							selectedHotels={selectedHotels}
							cityCurrency={cityDetails?.currency}
						/>
					))}
				</div>
			</div>
		</div>
	);
}
