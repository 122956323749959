import DatePickerField from 'components/Form/DatePickerField/DatePickerField'
import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import { ArrowDownIcon } from 'modules/UmrahOperations/shared/Icons';
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import { ReactComponent as FilterIcon } from "assets/images/umrah-operations/filter-icon.svg";
import { ReactComponent as ResetFilterIcon } from "assets/images/market-place/refresh-circle-icon.svg";
import { ReactComponent as SearchIcon } from "assets/images/umrah-operations/search-icon.svg";
import Locale from 'translations';
import moment from 'moment';
import { useSBSState } from 'context/global';

export default function RawdaReservationsFilters({ filters, setFilters, lookups, searchWithFilters, resetFilters }) {
  const { marketPlace, inventory, payment, operationStatement, productsBuilder, } = Locale;
  const { allCountries } = useSBSState();
  const [isFilterOpen, setIsFilterOpen] = useState(true);

  return (
    <div className='filter-container mt-3'>
      <button className='btn filter-collapse-btn opened px-0'
        onClick={() => setIsFilterOpen(!isFilterOpen)}
      >
        <FilterIcon />
        <span className="title me-2">{operationStatement.advancedFilter}</span>
        <ArrowDownIcon color="#000000" />
      </button>

      <Collapse isOpen={isFilterOpen}>
        <div className="row w-100 mx-0 inputs-container">

          {/* date from */}
          <div className="col-md-2 col-6">
            <DatePickerField
              label={operationStatement.Date}
              placeholder="DD/MM/YYYY"
              id="date_from"
              name="date_from"
              date={filters.date_from || null}
              onChangeDate={(date) =>
                setFilters({
                  ...filters,
                  date_from: date,
                  date_to: filters?.date_to && moment(filters?.date_to).isBefore(moment(date)) ? null : filters?.date_to
                })
              }
              isOutsideRange={() => false}
            />
          </div>

          {/* date to */}
          <div className="col-md-2 col-6">
            <DatePickerField
              label=""
              placeholder="DD/MM/YYYY"
              id="date_to"
              name="date_to"
              date={filters.date_to || null}
              onChangeDate={(date) =>
                setFilters({
                  ...filters,
                  date_to: date
                })
              }
              isOutsideRange={(day) => filters?.date_from ? !day.isAfter(moment(filters.date_from)) : false}
            />
          </div>

          {/* pax */}
          <div className="col-md-2 col-12 row mx-0 p-0">
            {/* Pax from */}
            <div className='col-6'>
              <TextField
                type="number"
                label={marketPlace.messages.pax}
                placeholder={productsBuilder.From}
                id="pax_from"
                name="pax_from"
                value={filters?.pax_from}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    pax_from: e.target.value,
                    // pax_to: filters?.pax_to !== "" && +filters?.pax_to < +e.target.value ? "" : filters?.pax_to
                  })
                }}
              />
            </div>
            {/* Pax to */}
            <div className='col-6'>
              <TextField
                type="number"
                label={""}
                placeholder={productsBuilder.To}
                id="pax_to"
                name="pax_to"
                value={filters?.pax_to}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    pax_to: e.target.value,
                    // pax_from: filters?.pax_from !== "" && +filters?.pax_from > +e.target.value ? "" : filters?.pax_from
                  })
                }}
              />
            </div>
          </div>

          {/* agent */}
          <div className="col-md-2 col-6">
            <SelectField
              label={operationStatement.Agent}
              placeholder={productsBuilder.select}
              id="agent_name"
              name="agent_name"
              options={lookups?.agents}
              value={filters?.agent?.name}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  agent: e
                })
              }}
            />
          </div>

          {/* Port */}
          <div className="col-md-2 col-6">
            <SelectField
              label={inventory.messages.country}
              placeholder={productsBuilder.select}
              id="country"
              name="country"
              options={allCountries}
              value={filters?.country?.name}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  country: e
                })
              }}
            />
          </div>

          {/* Status */}
          <div className="col-md-2 col-6">
            <SelectField
              label={inventory.messages.Status}
              placeholder={productsBuilder.select}
              id="status"
              name="status"
              options={lookups?.statusListLookup}
              value={filters?.status?.name}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  status: e
                })
              }}
            />
          </div>
          {/* filters action buttons */}
          <div className='d-flex align-items-center col-12'>
            <button className='btn bg-nxt search-btn  mt-0' onClick={() => searchWithFilters(filters)}>
              <SearchIcon />
              <span className='mx-2'>{marketPlace.messages.search}</span>
            </button>

            <button className='btn reset-btn mx-2 mt-0' onClick={resetFilters}>
              <ResetFilterIcon />
              <span className='mx-2'>{payment.messages.clear}</span>
            </button>
          </div>

        </div>
      </Collapse>

    </div>
  )
}
