import { useSBSState } from "context/global";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
import { allNotifications, readNotification } from "services/notifaction";
import io from "socket.io-client";
import Locale from "translations";
import iconNotification from "../../assets/images/customHeader/Notifications.svg";

// const socket = io("http://localhost:3000");

export const NotifactionHeader = () => {
	const { userInfo } = useSBSState();
	const { notifaction } = Locale;
	const history = useHistory();
	const [isConnected, setIsConnected] = useState(false);
	const [notifyData, setNotifyData] = useState();
	const search = window.location.search;
	const name = new URLSearchParams(search);
	const notifactionData = async () => {
		const res = await allNotifications();

		setNotifyData(res?.data?.data);
	};

	useEffect(() => {
		let x = {};
		for (const [key, value] of name) {
			x = { ...x, [key]: value };
		}

		if (Notification?.permission === "granted") {
			// alert("we have permission");
		} else if (Notification?.permission !== "denied") {
			Notification.requestPermission().then((permission) => {
				
			});
		}

		if (!("Notification" in window)) {
			
		} else {
			
		}

		if (!x.hasOwnProperty("token")) {
			notifactionData();
			const socket = io(process.env.REACT_APP_NOTIFICATION_API);

			socket.on("connect", () => {
				setIsConnected(true);

				socket.emit("new-user", "sbs", userInfo?.id);
			});

			socket.on("new-notification", (data) => {
				
				async function  showNotification() {
					const res = await allNotifications();

					setNotifyData(res?.data?.data);
					let title = "JavaScript Jeep";
					let icon =
						"https://homepages.cae.wisc.edu/~ece533/images/airplane.png";
						let body = res?.data?.data[0].title;
						var notification = new Notification("SBS", { body, icon });
					notification.onclick = () => {
						notification.close();
						window.parent.focus();
					};
				}
				showNotification();
			});

			socket.on("disconnect", () => {
				setIsConnected(false);
			});

			socket.on("pong", () => {
				// setLastPong(new Date().toISOString());
			});

			return () => {
				socket.off("connect");
				socket.off("disconnect");
				socket.off("pong");
			};
		}
	}, []);
	/****Start Api Notifaction */

	const readNotify = async (id1) => {
		const res = await readNotification(id1);
		notifactionData();
	};

	let notificationLength = notifyData?.filter(
		(data) => data["read_at"] == null
	);

	let allNotifyData =
		notifyData?.length > 3 ? notifyData.slice(0, 3) : notifyData;

	const AllNotification =
		allNotifyData?.length > 0 ? (
			allNotifyData.map((data, index) => (
				<DropdownItem
					key={index}
					tag="button"
					className={`d-flex justify-content-start flex-wrap py-2 border-top DropdownItem font-weight-bold m- ${
						data.read_at == null ? "not-read" : "read"
					}`}
					onClick={() => {
					
						// setTimeout(() => {
						// 	window.location.reload();
						// }, 550);
						if (data?.item_type==="groupReservation") {
							window.open(`/Hotels/inventory-group/${data.item_id}`, "_blank");
							// window.location.href = ;
						}
						if (data?.item_type==="payment_card") {
							// window.location.href = `/wallet`;
							window.open("/wallet", "_blank");
						}
						if (data?.item_type == "flight_request") {
							window.open(`/inventory/flight/reservation/${data?.item_id}/booking-details`, "_blank");
						}  if (data?.item_type == "tour_booking") {
							window.open(`/inventory/tour/reservation/${data?.item_id}`, "_blank");
						}
						if (data?.item_type == "transfer_Reservation") {
							window.open(`/inventory/transfer/reservation/${data?.item_id}`, "_blank");
						}

						if (data?.item_type == "online_visa_request_issued") {
							window.open(`/inventory/visa-requests/view/${data?.item_id}`, "_blank");
						}
						if (data?.item_type == "online_visa_request") {
							window.open(`/inventory/visa-requests/view/${data?.item_id}`, "_blank");
						}
						readNotify(data.id);
						// history.push(`${data.url}`);
					}}
				>
					<h5 className="w-100 mx-3">
						{data?.title.length < 35
							? data?.title
							: data?.title.slice(0, 35) + "..."}
					</h5>

					<br />
					<p className="mx-3 ">
						{data?.body.length < 36
							? data?.body
							: data?.body.slice(0, 36) + "..."}
						{/* {data.body} */}
					</p>
				</DropdownItem>
			))
		) : (
			<DropdownItem tag="button" className="d-flex justify-content-center py-2">
				<p>{notifaction.noNotification}</p>
			</DropdownItem>
		);
	return (
		<>
			<UncontrolledDropdown setActiveFromChild className="notification ">
				<DropdownToggle   className="bell-notifaction  ">
					<div className="custom-notification">
						<img src={iconNotification} alt=""/>
						{	notificationLength && notificationLength?.length >0 ? 	<span>{notificationLength?.length}</span> :null}

					</div>

					{/* <span>{notifyData?.length}</span> */}
				</DropdownToggle>

				<DropdownMenu className="bg-notify no-padding notify-items notifaction-items-header">
					{AllNotification}
					<DropdownItem
						onClick={() => {
							history.push("/notification");
						}}
						className="d-flex justify-content-center w-100 notify-btn notify-show-more py-2"
					>
						<h6>{notifaction.ViewAllNotification}</h6>
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
		</>
	);
};
