import React from "react";
import banner from "assets/images/hotelsBanner.png";

export default function PageTamplate({
	logoSrc,
	headerText,
	disclaimerText,
	children,
	hotelNumber,
}) {
	const headerBold = headerText?.bold ? "font-weight-bold" : "";
	const headerItalic = headerText?.italic ? "font-italic" : "";
	const headerUnderline = headerText?.underline ? "font-underline" : "";

	const disclaimerBold = disclaimerText?.bold ? "font-weight-bold" : "";
	const disclaimerItalic = disclaimerText?.italic ? "font-italic" : "";
	const disclaimerUnderline = disclaimerText?.underline ? "font-underline" : "";

	// this font size to control of card section size
	const cardSectionFontSize = {
		8: 9,
		7: 11,
		6: 13,
	};
	const fontSize = cardSectionFontSize[hotelNumber] || 16;
	return (
		<div className="hotels-page">
			<div className="px-3 bg-white">
				{logoSrc && (
					<img
						src={logoSrc}
						alt="logo"
						className="img-fluid my-3"
						style={{ maxHeight: 55 }}
					/>
				)}
			</div>
			<header className="header-hotels">
				<img src={banner} alt="" className="w-100" />
				<div className="title">
					<h1 className={`${headerBold}  ${headerItalic}  ${headerUnderline} `}>
						{" "}
						{headerText.value}
					</h1>
				</div>
			</header>
			<div className="cards-section" style={{ fontSize }}>
				{children}
			</div>
			<footer className="footer-hotels">
				<p
					className={`footer-des ${disclaimerBold}  ${disclaimerItalic}  ${disclaimerUnderline}`}
				>
					{disclaimerText.value ||
						`You can use /n this field to type here your name, contact details and
						address. You can also add details about any specific promotion that
						may apply.`}
				</p>
				<div className="footer-logo">
					<img src={logoSrc} alt="logo" srcset="" className="img-fluid" />
				</div>
			</footer>
		</div>
	);
}
