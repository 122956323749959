import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import React from "react";
import ServiceCard from "./ServiceCard";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { useLocation } from 'react-router-dom';
import Locale from 'translations';

function OurServices() {
	const { webBuilder } = Locale;
	const { ourServices, selectedLanguage } = useWebBuilderState();
	const dispatch = useWebBuilderDispatch();
	const updateOurServices = ({ name, value, type, language }) => {
		dispatch({
			type: "updateOurServices",
			payload: {
				name,
				value,
				type,
				language: name === "visible" || name === "icon" ? null : language
			},
		});
	};


	const services = Array.isArray(ourServices?.services)
		? ourServices?.services
		: [];
	const location = useLocation();
	const preview = !location.pathname.includes('edit');
	return (
		<div className={preview ? services?.filter(res => res.visible).length > 0 ? `web-builder-content-our-service` : null : `web-builder-content-our-service`} id="web-builder-our-service">
			{preview ? services?.filter(res => res.visible).length > 0 ?
				<ServicesContent
					header={ourServices?.title[selectedLanguage]}
					body={ourServices?.body[selectedLanguage]}
					changeHeader={(value) => updateOurServices({ name: "title", value, type: null, language: selectedLanguage })}
					changebody={(value) => updateOurServices({ name: "body", value, type: null, language: selectedLanguage })}
				/> : null :
				<ServicesContent
					header={ourServices?.title[selectedLanguage]}
					body={ourServices?.body[selectedLanguage]}
					changeHeader={(value) => updateOurServices({ name: "title", value, type: null, language: selectedLanguage })}
					changebody={(value) => updateOurServices({ name: "body", value, type: null, language: selectedLanguage })}
				/>}

			{services.map((service, index) => (
				preview ? service?.visible ? <ServiceCard
					type={webBuilder[service?.type] || service?.type}
					serviceType={service?.type}
					icon={service?.icon}
					title={service?.header[selectedLanguage]}
					key={service?.title + "aaa" + index}
					body={service?.body[selectedLanguage]}
					visible={service?.visible}
					editCardDetails={(value, name) =>
						updateOurServices({ name, value, type: service?.type, language: selectedLanguage })
					}
					onChangeTitle={(value) =>
						updateOurServices({ name: "title", value, type: service?.type, language: selectedLanguage })
					}
					onChangeBody={(value) =>
						updateOurServices({ name: "body", value, type: service?.type, language: selectedLanguage })
					}
					onChangeVisible={(value) =>
						updateOurServices({ name: "visible", value, type: service?.type, language: selectedLanguage })
					}
				/> : null : <ServiceCard
				
					type={webBuilder[service?.type] || service?.type}
					serviceType={service?.type}
					icon={service?.icon}
					title={service?.header[selectedLanguage]}
					key={service?.title + "bbb" + index}
					body={service?.body[selectedLanguage]}
					visible={service?.visible}
					editCardDetails={(value, name) =>
						service?.type === "transfer" ? false : updateOurServices({ name, value, type: service?.type, language: selectedLanguage })
					}
					onChangeTitle={(value) =>
						service?.type === "transfer" ? false : updateOurServices({ name: "header", value, type: service?.type, language: selectedLanguage })
					}
					onChangeBody={(value) =>
						service?.type === "transfer" ? false : updateOurServices({ name: "body", value, type: service?.type, language: selectedLanguage })
					}
					onChangeVisible={(value) =>
						service?.type === "transfer" ? false : updateOurServices({ name: "visible", value, type: service?.type, language: selectedLanguage })
					}
				/>
			))}
		</div>
	);
}

export default OurServices;
