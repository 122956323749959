import { useWebBuilderDispatch, useWebBuilderState } from 'context/webBuilder';
import usePreviewMode from 'hooks/usePreviewMode';
import { useState } from 'react';
import { isObject } from "lodash"

const URI = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

export default function useWBHeader() {
  // *** hooks
  const dispatch = useWebBuilderDispatch();
  const { isPreview } = usePreviewMode();
  const { hero, mainMenu, selectedLanguage } = useWebBuilderState();


  // *** variables
  const headerData = hero?.header;
  const visibleMenuItems = mainMenu?.filter(item => item?.visible);
  

  const logoImage = isObject(headerData?.logo) ?
    <img src={`${headerData?.logo[selectedLanguage]?.includes("http")
      ? headerData?.logo[selectedLanguage]
      : URI + "/" + headerData?.logo[selectedLanguage]}`} alt="Logo" />
    :
    <img src={`${headerData?.logo[selectedLanguage]}`} alt="Logo" />;


  // *** states
  const [openButtonModal, setOpenButtonModal] = useState(false);
  const [changeLogoModal, setChangeLogoModal] = useState(false);
  const [contactPhonesOpen, setContactPhonesOpen] = useState(false);



  function toggleButtonModal(e) {
    e?.stopPropagation();
    setOpenButtonModal((prev) => !prev);
  };


  function toggleLogoChangeModal(e) {
    e?.stopPropagation();
    setChangeLogoModal((prev) => !prev);
  };
  function toggleContactPhonesModal(e) {
    e?.stopPropagation();
    setContactPhonesOpen((prev) => !prev);
  };


  function updateHeroHeader({ name, value, type }) {
    dispatch({
      type: "updateHeroHeader",
      payload: {
        name,
        value,
        type,
      },
    });
  };


  function onSaveButton({ name, id }) {
    updateHeroHeader({ name: "name", value: name, type: "contactUs" });
    updateHeroHeader({ name: "id", value: id, type: "contactUs" });
  };

  function saveCompanyLogos(logos) {
    updateHeroHeader({ name: "logo", value: logos })
  };


  function scrollToSection(id) {
    if (id) {
      const element = document.getElementById(id);
      element?.scrollIntoView({ behavior: "smooth" });
    }
  };

  function clickContactButton() {
    if (isPreview) {
      scrollToSection(headerData?.contactUs?.id);
      return;
    }
    toggleButtonModal();
  };

  return {
    onSaveButton,
    saveCompanyLogos,
    toggleButtonModal,
    toggleLogoChangeModal,
    openButtonModal,
    changeLogoModal,
    updateHeroHeader,
    scrollToSection,
    clickContactButton,
    isPreview,
    headerData,
    visibleMenuItems,
    logoImage,
    contactPhonesOpen,
    toggleContactPhonesModal
  }
}
