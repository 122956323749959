import React from 'react';
import DragAndDropContainer from 'modules/WebBuilder-V2/shared/DragAndDropContainer';
import { SORT_ITEMS_IN_CONTAINER_ACTION } from 'modules/WebBuilder-V2/interfaces/blockTypes';
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import OverLayServiceCard from 'modules/WebBuilder-V2/shared/OverLayServiceCard';


const hotelBlocksType = "hotelBlocksType";


export default function HotelGridView({ items, sortHotelsItems, focusContainer, editItem, itemsBoxActions, openEditModal, type }) {

  return (
    <div className="hotels-grid-view">
      {items.map((props, index) => (
        <DragAndDropContainer
          accept={hotelBlocksType}
          type={hotelBlocksType}
          id={props?.id}
          index={index}
          action={SORT_ITEMS_IN_CONTAINER_ACTION}
          moveItemInContainer={(dragIndex, hoverIndex) =>
            sortHotelsItems({ dragIndex, hoverIndex })
          }
          key={props?.id || index}
          
        >
          <EditBox
            editMode={focusContainer === type}
            actions={[
              ...editItem(props, index),
              ...itemsBoxActions(props?.id),
            ]}
          >
            <>
              <OverLayServiceCard index={index} {...props} openEditModal={openEditModal} cardType="hotels" />
            </>
          </EditBox>
        </DragAndDropContainer>
      ))}
    </div>
  )
}