import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import React, { useState } from "react";
import { AddElement } from "modules/WebBuilder/shared/AddElement";
import { ServiceCardDetails } from "modules/WebBuilder/shared/ServiceCardDetails";
import { useWebBuilderDispatch } from "context/webBuilder";

import {
	SORT_ITEMS_IN_CONTAINER_ACTION,
} from "../../../interfaces/blockTypes";
import { EditBox } from "modules/WebBuilder/shared/EditBox";
import DragAndDropContainer from "modules/WebBuilder/shared/DragAndDropContainer";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import AddService from "modules/WebBuilder/shared/AddService";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";

const flightBlocksType = "flightBlocksType";
function FlightsService({ details, onFocus, focusContainer }) {
	const { id, index, type, body, title, items } = details;
	const dispatch = useWebBuilderDispatch();
	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);

	const handleOpenAddModal = () => {
		setOpenAdd((prev) => !prev);
		setUpdateItem(null);
	};
	const openEditModal = (item) => {
		handleOpenAddModal();
		setUpdateItem(item);
	};

	const editItem = (item, index) => {
		return [
			<span
				key={`${item.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};

	const addItemToFlight = (item) => {
		updateItem
			? dispatch({
					type: "editItemInContainer",
					payload: {
						id,
						item,
						itemIndex: updateItem.index,
					},
			  })
			: dispatch({
					type: "addItemToContainer",
					payload: {
						id,
						item,
					},
			  });
		setUpdateItem(null);
	};

	const updateFlights = ({ name, value }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
			},
		});
	};

	const sortFlightsItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};
	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={itemId}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "container_item" })
				}
				className="pointer-event"
			>
				<TrashIcon />
			</span>,
		];
	};
	const selectedIds = items ? items.map((i) => i.id) : [];
	return (
		<EditBox editMode={focusContainer === type}>
			<div
				onClick={() => onFocus(type)}
				className="web-builder-content-flights"
				id={id}
			>
				<div className="flights-top-section">
					<ServicesContent
						header={title}
						body={body}
						changeHeader={(value) => updateFlights({ value, name: "title" })}
						changebody={(value) => updateFlights({ value, name: "body" })}
					/>
					<AddElement
						onClick={handleOpenAddModal}
						title="Add Service"
						className={
							items?.length ? "bg-white border-0 flex-row height-90" : ""
						}
					/>
				</div>

				<div className="flights-detials">
					{items.map((item, index) => (
						<DragAndDropContainer
							accept={flightBlocksType}
							type={flightBlocksType}
							id={item.id}
							index={index}
							action={SORT_ITEMS_IN_CONTAINER_ACTION}
							moveItemInContainer={(dragIndex, hoverIndex) =>
								sortFlightsItems({ dragIndex, hoverIndex })
							}
							key={item.id}
						>
							<EditBox
								editMode={focusContainer === type}
								actions={[
									...editItem(item, index),
									...itemsBoxActions(item.id),
								]}
							>
								<ServiceCardDetails {...item} itemType="flight" />
							</EditBox>
						</DragAndDropContainer>
					))}
				</div>
				{openAdd && (
					<AddService
						handleModalState={handleOpenAddModal}
						open={openAdd}
						item_id={1}
						label={"Service"}
						header={"Flight"}
						onAdd={addItemToFlight}
						details={updateItem}
						selectedIds={selectedIds}
					/>
				)}
			</div>
		</EditBox>
	);
}

export default FlightsService;
