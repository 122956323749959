// ** imports
import React, { useState } from 'react'

// ** components
import { ReactComponent as ChangeIcon } from 'assets/images/crm/change.svg'
import { ReactComponent as PriorityIcon } from 'assets/images/crm/priority.svg'
// import { ReactComponent as DeleteIcon } from 'assets/images/crm/delete.svg'
import { ReactComponent as RefreshIcon } from 'assets/images/crm/refresh.svg'
import { ReactComponent as ExportIcon } from 'assets/images/crm/export.svg'
import { PlusIcon } from 'modules/UmrahOperations/shared/Icons'

// ** hooks
import Locale from 'translations'
import PriorityBox from './components/PriorityBox';

const Actions = ({
	toggleCreateApplicationModal,
	resetFilters,
	getAllReservationPackage,
	selectedRows,
	setSelectedRows,
	getAvailablePackages,
	searchedPackagesOpen,
	exportToExcel
}) => {
	// ** hooks
	const { CRM } = Locale
	const [isPopupShow, setIsPopupShow] = useState(false);

	const showPriorityPopup = () => {
		setIsPopupShow(!isPopupShow)
	}

	return (
		<>
			<div className='actions-wrapper'>
				<div className='items-wrapper'>
					<button
						className='btn item'
						onClick={() => getAvailablePackages(selectedRows[0], { search_for: "change" })}
						disabled={selectedRows.length !== 1 || searchedPackagesOpen}
					>
						<ChangeIcon />
						<p className='text'>{CRM.changePackage}</p>
					</button>
					<button className='btn item'
						onClick={showPriorityPopup}
						disabled={selectedRows.length === 0 || searchedPackagesOpen}
					>
						<PriorityIcon />
						<p className='text'>{CRM.priority}</p>
					</button>
					{/* <button className='btn item'>
						<DeleteIcon />
						<p className='text'>{CRM.delete}</p>
					</button> */}
				</div>
				<div className='items-wrapper'>
					<button className='btn item' onClick={() => resetFilters()}>
						<RefreshIcon />
						<p className='text'>{CRM.refresh}</p>
					</button>
					<button className='btn item' onClick={() => exportToExcel()}>
						<ExportIcon />
						<p className='text'>{CRM.exportExcel}</p>
					</button>
					<button
						className='btn item active w-350'
						onClick={toggleCreateApplicationModal}
					>
						<PlusIcon color='#FFF' />
						<p className='text'>{CRM.addApplication}</p>
					</button>
				</div>
			</div>
			<PriorityBox
				isPopupShow={isPopupShow}
				selectedItems={selectedRows}
				actionsAfterSuccess={() => {
					setIsPopupShow(false);
					setSelectedRows([]);
					getAllReservationPackage()
				}}
			/>
		</>
	)
}

export default Actions
