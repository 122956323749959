import { useWebBuilderDispatch, useWebBuilderState } from 'context/webBuilder';
import { EditBox } from 'modules/WebBuilder/shared/EditBox'
import EditableParagraph from 'modules/WebBuilder/shared/EditableParagraph'
import React, { useState } from 'react';
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location.svg";
import { ReactComponent as PhoneIcon } from "assets/images/webBuilder/callcalling.svg";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting.svg";
import { ReactComponent as FacebookIcon } from 'assets/images/webBuilder/Facebook.svg'
// import { ReactComponent as TwitterIcon } from 'assets/images/webBuilder/Twitter.svg'
import { ReactComponent as XIcon } from 'assets/images/webBuilder/XIcon.svg'
import { ReactComponent as YouTubeIcon } from 'assets/images/webBuilder/youtube.svg'
import { ReactComponent as LinkedInIcon } from 'assets/images/webBuilder/linkedin.svg'
import { ReactComponent as InstagramIcon } from 'assets/images/webBuilder/instagram.svg'
import { ReactComponent as SnapchatIcon } from 'assets/images/webBuilder/snapchat-icon.svg'
import { ReactComponent as TiktokIcon } from 'assets/images/webBuilder/tiktok-icon.svg'

import SocialMediaModal from './SocialMediaModal';


export default function Footer({ focusContainer, handleFocus }) {
  const { footer: footerDetails } = useWebBuilderState();
  const dispatch = useWebBuilderDispatch();
  const type = 'footer';
  // const phoneNumbers = [...footerDetails?.phoneNumbers]
  const [socialMediaModal, setSocialMediaModal] = useState(false);
  function toggleSocialMediaModal() {
    setSocialMediaModal(!socialMediaModal);
  }

  const socialMediaIcons = {
    facebook: <FacebookIcon />,
    twitter: <XIcon />,
    youtube: <YouTubeIcon />,
    instagram: <InstagramIcon />,
    linkedin: <LinkedInIcon />,
    snapchat: <SnapchatIcon />,
    tiktok: <TiktokIcon />
  }
  const containerBoxActions = [
    <span onClick={toggleSocialMediaModal} className="pointer">
      <SettingIcon />
    </span>,
  ];
  function updateFooterContent(keyName, value, index) {
    dispatch({
      type: "updateFooter",
      payload: {
        type: keyName,
        value,
        name: index
      }
    });
  }


  return (
    <footer className='footer-container'>
      <div className="container">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex align-items-center">
            <span className='mx-1'><LocationIcon  /></span>
            <EditableParagraph
              className={"text-white"}
              contentEditable={true}
              text={footerDetails?.address}
              onInput={(value) => updateFooterContent("address", value, "address")}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between mt-3">
            <span className='mx-1'><PhoneIcon  /></span>
            {footerDetails?.phoneNumbers?.map((phone, index) => {
              return (
                <div className='d-flex mx-2 justify-content-between' key={index}>
                  <EditableParagraph
                    className={"text-white"}
                    contentEditable={true}
                    text={phone}
                    onInput={(value) => updateFooterContent("phoneNumbers", value, index)}
                  />
                </div>
              )
            })}
          </div>

          {/* social icons */}
          <div onClick={() => handleFocus(type)} className='mt-4'>
            <EditBox editMode={focusContainer === type} defaultIcon={containerBoxActions}>
              <div className="d-flex align-items-center justify-content-between py-2">
                {footerDetails?.links?.map(link => {
                  if (link?.visible) {
                    return (
                      <div key={link?.id} className='mx-2'>
                        <a href={link?.url} target='_blank' rel="noreferrer">
                          {socialMediaIcons[link?.id]}
                        </a>
                      </div>
                    )
                  } else {
                    return null
                  }

                })}
              </div>
            </EditBox>
          </div>
          
          <div className="footer-copyrights">
            <EditableParagraph
              contentEditable={true}
              text={footerDetails?.allRights}
              onInput={(value) => updateFooterContent("allRights", value, "allRights")}
            />
          </div>
        </div>
      </div>
      {socialMediaModal && footerDetails?.links ?

        <SocialMediaModal
          isOpen={socialMediaModal}
          toggle={toggleSocialMediaModal}
          header={"Social links"}
          socialMediaLinks={footerDetails?.links}
        />
        :
        null
      }
    </footer>
  )
}
