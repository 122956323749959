import { useSBSDispatch, useSBSState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
// import { fetchImages } from "services/auth";
import { fetchUserProfile } from "services/profile";
import Locale from "translations";
import sitting from "../assets/images/setting.png";

export default function UserSettings(props) {
	const { messages, backOffice, dashboard } = Locale;


	const SBSState = useSBSState();
	const { isAuth, companyName, allDataLogin } = SBSState
	const dispatch = useSBSDispatch();
	const history = useHistory();
	const [avatar, setAvatar] = useState("");
	const [userData, setUserData] = useState();
	const URI = process.env.REACT_APP_FILEUPLOAD_URL;

	useEffect(() => {
		if (isAuth) {
			async function fetchData() {
				const user = await fetchUserProfile();
				setUserData(user);
				dispatch({ type: "getAllDataLogin", payload: user });

				if (user.avatar) {
					// const fetchImage = await fetchImages(user.avatar);
					// if (fetchImage.status === 200) {
					// 	setAvatar(fetchImage);
					// }
					const fetchImage = `${URI}/fetch/${user.avatar}`;
					setAvatar(fetchImage);
				}
			}
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuth]);

	return (
		<UncontrolledDropdown className="setting-dropdown lang-btn">
			<DropdownToggle
				caret
				className="btn-light px-2 d-flex align-items-center "
				style={{ gap: "12px", padding: "1px" }}
			>
				<div className="d-flex align-items-center" style={{ gap: "4px" }}>
					{avatar ? (
						<img
							className="img-lang p-1"
							src={avatar ? avatar : sitting}
							width="40"
							alt="sitting"
						/>
					) : (
						<div className="letter-logo">
							{userData?.full_name?.[0] || companyName[0]}
						</div>
					)}

					<div className="d-flex flex-column align-items-start mt-2">
						<span className="font-weight-bold" style={{ lineHeight: 0.8 }}>
							{companyName}
						</span>
						<span style={{ textTransform: "capitalize" }}>
							{backOffice.hello}
							{allDataLogin?.full_name ? allDataLogin?.full_name.slice(0, 12) : null}
						</span>
					</div>
				</div>
			</DropdownToggle>

			<DropdownMenu right >
				<div className="d-flex flex-column gap-10">
					<Link to="/user-profile">
						<DropdownItem className="d-flex align-items-center justify-content-between gap-10 px-3">
							{messages.userProfile}
							<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0.875 0.75L7.125 7L0.875 13.25" stroke="#D4A655" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</DropdownItem>

					</Link>
					<ShowForPermission permission={["View-Company-Settings", "Manage-Company-Settings"]}>
						<Link to="/company-profile">
							<DropdownItem className="d-flex align-items-center justify-content-between gap-10 px-3">
								{messages.companyProfile}
								<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0.875 0.75L7.125 7L0.875 13.25" stroke="#D4A655" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</DropdownItem>
						</Link>
					</ShowForPermission>

					{/* change passpowrd */}
					<Link to="/change-password">
						<DropdownItem className="d-flex align-items-center justify-content-between gap-10 px-3">
							{backOffice.changePassword}
							<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0.875 0.75L7.125 7L0.875 13.25" stroke="#D4A655" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</DropdownItem>
					</Link>

					{/* my team */}
					<Link to="/team-managementlist">
						<DropdownItem className="d-flex align-items-center justify-content-between gap-10 px-3">
							{dashboard.messages.MyTeam}
							<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0.875 0.75L7.125 7L0.875 13.25" stroke="#D4A655" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</DropdownItem>
					</Link>

					{/* activity Logs */}
					<Link to="/activity-logs">
						<DropdownItem className="d-flex align-items-center justify-content-between gap-10 px-3">
							{dashboard.messages.activityLogs}
							<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0.875 0.75L7.125 7L0.875 13.25" stroke="#D4A655" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</DropdownItem>
					</Link>

					{/* log out */}
					<DropdownItem
						className="border-top text-center pt-2" style={{ color: "#D4A655" }}
						onClick={() => {
							dispatch({ type: "logout" });
							history.push("/auth/login");
						}}
					>
						<span>{messages.logout}</span>
					</DropdownItem>
				</div>

			</DropdownMenu>
		</UncontrolledDropdown>
	);
}
