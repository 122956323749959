import { useMarketplaceState } from 'context/marketplace';
import ShowForPermission from 'helpers/showForPermission';
import TourSearchAvailability from 'modules/market-place/ToursResponse/Details/SearchAvailability';
import TourSelectCategory from 'modules/market-place/ToursResponse/Details/SelectCategory';
import TourSelectDates from 'modules/market-place/ToursResponse/Details/SelectDates';
import TourDetailsSummary from 'modules/market-place/ToursResponse/Details/Summary';
import { calcCategoryPrice } from 'modules/market-place/ToursResponse/helpers/calcSelectedCategory';
import moment from 'moment';
import React, { useMemo, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { modifyTour } from 'services/tours';
import Locale from 'translations';



export default function ModifyTourModal({ isOpen, toggle, reservationItem, modifiedResults, getTourModifyAvailability }) {
  const { marketPlace } = Locale;
  const { toursSearch } = useMarketplaceState();
  const [selectedDate, setSelectedDate] = useState(moment(reservationItem?.start_date));
  const [selectedCategory, setSelectedCategory] = useState({ category: null, language: null });

  const modifySearchData = {
    date_from: reservationItem?.start_date,
    date_to: reservationItem?.end_date,
    adults: { label: reservationItem?.adults, value: reservationItem?.adults },
    childs: { label: reservationItem?.children?.length, value: reservationItem?.children?.length },
    childs_ages: reservationItem?.children?.map(childAge => {
      return { label: childAge, value: childAge }
    }),
  }

  

  const tourSummary = useMemo(() => {
    return calcCategoryPrice(reservationItem?.adults, reservationItem?.children, selectedCategory);
  }, [reservationItem?.adults, reservationItem?.children, selectedCategory]);


  async function modifyTourItem() {
    const data = {
      date_from: moment(selectedDate).format("YYYY-MM-DD"),
      date_to: moment(selectedDate).format("YYYY-MM-DD"),
      noOfAdults: toursSearch?.adults?.value || toursSearch?.adults,
      rateKey: selectedCategory?.rateKey
    }
    const modifyTourRes = modifyTour(reservationItem?.id, data);
    if (modifyTourRes?.status >= 200 && modifyTourRes?.status < 300) {
      
    }
  }


  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={toggle}
    >

      <ModalHeader
        className="align-items-center font-weight-bold hd-title-model"
        toggle={toggle}
      >
        <span className='font-weight-bold'>
          {marketPlace.modify} {reservationItem?.name}
        </span>
        {/* {inventory.messages.cancleService} */}
      </ModalHeader>
      <ModalBody>
        <div className='tour-details'>
          {/* search avalibilty */}
          <TourSearchAvailability
            searchData={modifySearchData}
            modify={true}
            handleModifySearch={getTourModifyAvailability}
          />
          {modifiedResults &&
            <>
              <TourSelectDates
                tourData={modifiedResults}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedCategory={selectedCategory}
                tourSummary={tourSummary}
              />

              <TourSelectCategory
                tourData={modifiedResults}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
              <div className="tour-details-summary mt-2">
                <TourDetailsSummary
                  tourData={modifiedResults}
                  tourSummary={tourSummary}
                  selectedCategory={selectedCategory}
                  originalPrice={+reservationItem?.details?.price}
                />
                {/* add to cart */}
                <ShowForPermission permission={["Manage-Marketplace-Tours"]}>
                  <button
                    className="btn w-100 bg-nxt py-2 mt-2"
                    onClick={() => {
                      modifyTourItem();
                    }}
                  >
                    {marketPlace.messages.book}
                  </button>
                </ShowForPermission>
              </div>
            </>
          }
        </div>
      </ModalBody>
    </Modal>
  )
}
