export const hotelAccommodationReport = `

<style>
* {
  font-family: sans-serif;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid black;
  padding: 5px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  padding: 8px 16px;
  margin-bottom: 8px;
}
.subheader {
  text-align: center;
  font-size: 16px;
}

.room-details {
  margin-top: 16px;
}
th,
td {
  text-align: center;
}
.footer {
  display: flex;
  border: 1px solid;
  margin-top: 8px;
  background: lightgray;
}
.footer div {
  flex: auto;
  border-inline-end: 1px solid;
  padding: 8px;
  margin-block: auto;
}

.footer div:last-of-type {
  border-inline-end: none;
}
.footer-label {
  flex: 0 0 auto !important;
  font-size: 20px;
  font-weight: bold;
}
.checkout {
  margin-inline-start: 40px;
}
.city{
  margin-inline-end: 30px;
}
.logo{
  width: 120px;
  height: auto;
}
</style>
<div class="header">
<div>
  <p>
    <span>[company_name_key] :</span> <span>[company_name_value] </span>
  </p>
  <p><span> [report_key] : </span> <span>[report_value] </span></p>
  <p>
    <span> [city_key] : </span> <span class="city">[city_value] </span> |
    <span> [hotel_key] : </span> <span>[hotel_value] </span>
  </p>
  <p>
    <span> [check_in_key] : </span> <span>[check_in_value] </span>
    <span class="checkout"> [check_out_key] : </span>
    <span>[check_out_value] </span>
  </p>
</div>
<img class="logo" src="[avater]" alt="logo" />
</div>

<table style="border-collapse: separate">
<thead>

  <tr>
    <th>[num]</th>
    <th>[motamer_name]</th>
    <th>[passport_numer]</th>
    <th>[room_type]</th>
    <th>[meals_type]</th>
    <th>[room_number]</th>
  </tr>
</thead>
<tbody>
  [table_rows]
</tbody>
</table>

<table class="room-details">
<thead>
  <tr>
    <th>[rooms_type]</th>
    <th>[one_key]</th>
    <th>[two_key]</th>
    <th>[three_key]</th>
    <th>[four_key]</th>
    <th>[five_key]</th>
    <th>[six_key]</th>
    <th>[seven_key]</th>
  </tr>
</thead>
<tbody>
  <tr>
    <td>[number]</td>
    <td>[1_value]</td>
    <td>[2_value]</td>
    <td>[3_value]</td>
    <td>[4_value]</td>
    <td>[5_value]</td>
    <td>[6_value]</td>
    <td>[7_value]</td>
  </tr>
</tbody>
</table>

<div class="footer">
<div class="footer-label">[total_travellers_key]</div>
<div>[total_travellers_value]</div>
<div class="footer-label"> [total_men_key]</div>
<div>[total_men_value]</div>
<div class="footer-label"> [total_women_key]</div>
<div>[total_women_value]</div>
</div>


`