import NumberField from "components/Form/NumberField/NumberField";
import React from "react";
import Locale from 'translations';

export default function AccountingCateringPricing({caterPrice,handlePriceingFields,handlePriceingFieldsTotal, canEdit}) {
	const { operationStatement } = Locale;

	let total_buying_price= caterPrice?.reduce((acc, cur) => acc+ ((cur?.pricing?.buying_price||0)*cur.pax),0)
  let total_selling_price= caterPrice?.reduce((acc, cur) => acc+ ((cur?.pricing?.selling_price||0)*cur.pax),0)
  const profit=+total_selling_price - +total_buying_price;
	return (
		<div className="hotel-pricing">
			<div className="pricig-row">
				<span className="col-md-2 p-0 pax">{operationStatement.Pax}</span>
				<span className="col-md-2 p-0 pax">{operationStatement.Meals}</span>
				<span className="col-md-2 p-0 pax">{operationStatement.Suplier}</span>

				<div className="buying">
					<span className="row-head">{operationStatement.BuyingCost}</span>
					<div className="d-flex justify-content-center gap-10">
						<span className="row-sub-head">{operationStatement.PricePerPax}</span>
						<span className="row-sub-head">{operationStatement.Total}</span>
					</div>
				</div>

				<div className="selling">
					<span className="row-head">{operationStatement.Sellingcost}</span>
					<div className="d-flex justify-content-center gap-10">
						<span className="row-sub-head">{operationStatement.PricePerPax}</span>
						<span className="row-sub-head">{operationStatement.Total}</span>
					</div>
				</div>
			</div>
			{/*Start Room Type */}
			{caterPrice?.length>0 && caterPrice.map((cateringItem)=>(
				<div className="pricig-row" key={cateringItem?.id}>
				<span className="col-md-2 p-0 pricing-label">
				{cateringItem.pax}
				</span>
				<span className="col-md-2 p-0 pricing-label">{cateringItem.meal_type} </span>
				<span className="col-md-2 p-0 pricing-label">{cateringItem.supplier_name} </span>

				<div className="buying-content">
					<div className="d-flex justify-content-center gap-10">
						<span className="row-sub-head">
							<NumberField
								hasLabel={false}
								placeholder={operationStatement.Price}
								removeArrow={true}
								value={cateringItem?.pricing?.buying_price}
								onChange={(e)=>handlePriceingFields(+e.target.value,"buying_price",cateringItem.id)}
								disabled={!canEdit}
							/>
						</span>
						<span className="row-sub-head">
							<NumberField
								hasLabel={false}
								placeholder={operationStatement.total}
								removeArrow={true}
								//value={cateringItem?.pricing?.buying_price*cateringItem.pax}
								value={cateringItem?.pricing?.total_buying_price}
								onChange={(e)=>handlePriceingFieldsTotal(+e.target.value,"buying_price",cateringItem.id)}
								disabled={!canEdit}
							/>
						</span>
					</div>
				</div>

				<div className="selling-content">
					<div className="d-flex justify-content-center gap-10">
						<span className="row-sub-head">
							<NumberField
								hasLabel={false}
								placeholder={operationStatement.price}
								removeArrow={true}
								value={cateringItem?.pricing?.selling_price}
								onChange={(e)=>handlePriceingFields(+e.target.value,"selling_price",cateringItem.id)}
								disabled={!canEdit}
							/>
						</span>
						<span className="row-sub-head">
							<NumberField
								hasLabel={false}
								placeholder={operationStatement.total}
								removeArrow={true}
								///value={cateringItem?.pricing?.selling_price*cateringItem.pax}
								value={cateringItem?.pricing?.total_selling_price}
								onChange={(e)=>handlePriceingFieldsTotal(+e.target.value,"selling_price",cateringItem.id)}
								disabled={!canEdit}
							/>
						</span>
					</div>
				</div>
			</div>
			))}
			
			{/*End Room Type */}

			{/* Start Total purchase & sales */}
			<div className="pricig-row sales">
				<span className="col-50 pax">{operationStatement.TotalPurchaseSales}</span>

				<div className="buying-content total">
					<span className="row-data buying-amount font-weight-bold" style={{ flexBasis: "100%" }}>
						{(+total_buying_price).toFixed(2) || 0}
					</span>
				</div>

				<div className="selling-content total">
					<span className="row-data selling-amount font-weight-bold" style={{ flexBasis: "100%" }}>
					{	(+total_selling_price).toFixed(2) || 0}
					</span>
				</div>

			</div>

			{/* End Total purchase & sales */}

			{/* Start Profit */}
			<div className="pricig-row Profit">
				<span className="col-50 pax">{operationStatement.Profit}</span>
				<span className="profit-amount flex-grow-1">{profit < 0 ? 0 : profit?.toFixed(2)}</span>
			</div>
			{/* End Profit */}
		</div>
	);
}
