import React, { createContext, useContext, useReducer } from "react";
const WalletContext = createContext();
const WalletDispatchContext = createContext();
const initialState = {
	myBalance: "",
	safaBalance: "",
};

function WalletReducer(state = initialState, action) {
	switch (action.type) {
		// case "getSafaBalance":
		// 	return { ...state, safaBalance: action?.payload };

		// case "getMyBalance":
		// 	return { ...state, myBalance: action?.payload };
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useWalletState() {
	const context = useContext(WalletContext);
	if (context === undefined) {
		throw new Error("useWallet State must be used within a Wallet Provider");
	}
	return context;
}

function useWalletDispatch() {
	const context = useContext(WalletDispatchContext);
	if (context === undefined) {
		throw new Error("useWalletDispatch must be used within a WalletProvider");
	}
	return context;
}

function WalletProvider({ children }) {
	const [state, dispatch] = useReducer(WalletReducer, initialState);
	return (
		<WalletContext.Provider value={state}>
			<WalletDispatchContext.Provider value={dispatch}>
				{children}
			</WalletDispatchContext.Provider>
		</WalletContext.Provider>
	);
}

export { WalletProvider, useWalletState, useWalletDispatch };
