import { SORT_ITEMS_IN_CONTAINER_ACTION } from 'modules/WebBuilder-V2/interfaces/blockTypes';
import DragAndDropContainer from 'modules/WebBuilder-V2/shared/DragAndDropContainer'
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import React from 'react'
import OverLayServiceCard from 'modules/WebBuilder-V2/shared/OverLayServiceCard';
import Carousel from 'react-multi-carousel';
import CustomRightArrow from 'modules/WebBuilder-V2/shared/CustomRightArrow';
import CustomLeftArrow from 'modules/WebBuilder-V2/shared/CustomLeftArrow';


const toursBlocksType = "hotelBlocksType";

export default function ToursCarouselOverLayView(props) {
  const { items, sortToursItems, focusContainer, editItem, itemsBoxActions, openEditModal } = props;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1
    }
  };

  return (
    <Carousel
      responsive={responsive}
      itemClass={"tour-slide"}
      className="tours-carousel-overlay"
      slidesToSlide={1}
      keyBoardControl={true}
      customRightArrow={< CustomRightArrow />}
      customLeftArrow={< CustomLeftArrow />}
      renderButtonGroupOutside={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      renderArrowsWhenDisabled={true}
      infinite={true}
    >
      {
        items.map((props, index) => (
          <div className="px-2" key={props.id}>
            <DragAndDropContainer
              accept={toursBlocksType}
              type={toursBlocksType}
              id={props.id}
              index={index}
              action={SORT_ITEMS_IN_CONTAINER_ACTION}
              moveItemInContainer={(dragIndex, hoverIndex) =>
                sortToursItems({ dragIndex, hoverIndex })
              }
              key={props.id}
            >
              <EditBox
                editMode={focusContainer === "tours"}
                actions={[
                  ...editItem(props, index),
                  ...itemsBoxActions(props.id),
                ]}
              >
                <OverLayServiceCard index={index} {...props} openEditModal={openEditModal} cardType="tours" />
              </EditBox>
            </DragAndDropContainer>
          </div>
        ))
      }
    </Carousel>
  )
}


