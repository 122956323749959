// import usePreviewMode from 'hooks/usePreviewMode'

import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import UploadFileComponent from "modules/WebBuilder-V2/shared/UploadFile";
import EditableParagraph from "modules/WebBuilder-V2/shared/EditableParagraph";
import usePreviewMode from "hooks/usePreviewMode";
import ToggleCheckbox from 'modules/WebBuilder-V2/shared/ToggleCheckbox';

export default function ThemeFourServiceCard(props) {
	const { isPreview } = usePreviewMode();

	const logoImage = props.image ? <img src={props.image} alt="Logo" /> : null;
	const logo = isPreview ? (
		logoImage
	) : (
		props.visible ? <UploadFileComponent
			icon={AddIcon}
			id={`${props.type}-service`}
			label=""
			onUpload={(value) => props.editCardDetails(value, "icon")}
			image={logoImage}
			disabled={!props.visible}
		/> : <div className="upload-file-container"></div>
	);

	return (
		<div className="travel-service-card">
			<div
				className={`travel-service-image-container ${isPreview ? "overlay" : ""
					}`}
			>
				{logo}
			</div>

			<div className="text">

				{/* <h3 className="">{webBuilder[props.type]}</h3> */}

				<EditableParagraph
					className="travel-service-title"
					contentEditable={props.visible}
					onInput={(value) => {
						props.onChangeTitle(value, "header");
					}}
					text={props.header}
				/>
				<EditableParagraph
					className="travel-service-description"
					contentEditable={props.visible}
					onInput={(value) => {
						props.onChangeTitle(value, "body");
					}}
					text={props.body}
				/>
				{!isPreview ? (
					<ToggleCheckbox
						checked={props.visible}
						onChange={(e) => {
							const value = e.target.checked;
							props.editCardDetails(value, "visible");
						}}
						label="Visible"
					/>
				) : null}
			</div>
		</div>
	);
}
