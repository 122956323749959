import React from 'react'
import ItineraryExternalTransportation from './ExternalTransportation';
import ItineraryInternalTransportation from './InternalTransportation';
import ItineraryInsurance from './Insurance';
import ItinerarySightseeing from './Sightseeing';
import ItineraryOtherService from './Other';
import ItineraryHotel from './Hotel';
import Locale from 'translations';


export default function PackageReservatoinItinerary({ details, isMobile, itineraryElementsIds }) {
  const {packages} = Locale;
  
  const itineraryElements = () => {
    return details?.product_details?.map((element, index) => {
      switch (element ? element?.item?.id : "") {
        case itineraryElementsIds?.internal:
          return (
            <ItineraryInternalTransportation
              key={`internal-${element?.id}-${index}`}
              element={element}
              index={index}
              id={element?.id}
              isMobile={isMobile}
            />
          );

        case itineraryElementsIds?.external:
          return (
            <ItineraryExternalTransportation
              key={`external-${element?.id}-${index}`}
              element={element}
              index={index}
              id={element.id}
              type={"transportations"}
              isMobile={isMobile}

            />
          );
        case itineraryElementsIds?.insurance:
          return (
            <ItineraryInsurance
              key={`insurance-${element?.id}-${index}`}
              element={element}
              index={index}
              id={element.id}
            />
          );
        case itineraryElementsIds?.otherServices:
          return (
            <ItineraryOtherService
              key={`otherServices-${element?.id}-${index}`}
              element={element}
              index={index}
              id={element.id}
            />
          );
        case itineraryElementsIds?.hotel:
          return (
            <ItineraryHotel
              key={`hotel-${element?.id}-${index}`}
              element={element}
              index={index}
              id={element.id}
              isMobile={isMobile}
              type={"hotel"}
            />
          );
        case itineraryElementsIds?.sightseeing:
          return (
            <ItinerarySightseeing
              key={`sightseeing-${element?.id}-${index}`}
              element={element}
              index={index}
              id={element.id}
            />
          );

        default:
          break;
      }
    });
  };


  return (
    <>
      <h3 className='font-weight-bold terms-color my-2'>
        {packages.packageItinerary}
      </h3>
      <div className="itineraryBox">
        {details?.product_details?.length > 0 && itineraryElements()}
      </div>
    </>
  );
}
