import arFLag from "assets/images/ar-flag.svg";
import enFLag from "assets/images/en-flag.svg";

import langIcon from "assets/images/market-place/servicesIcon/langIcon.svg";

import { useSBSDispatch, useSBSState } from "context/global";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from "reactstrap";
import Locale from "./LanguageSwitcher.locale";
export default function LanguageSwitcherMobile({show}) {
	const { locale } = useSBSState();
	Locale.setLanguage(locale);
	const dispatch = useSBSDispatch();
	return (
		<UncontrolledDropdown className={`lang-dropdown lang-dropdown-mobile p-0 ${show?"visibility-hidden":""}`}>
			<DropdownToggle caret className="btn-light lang-btn d-flex align-items-center" >
				<div>
				<img
					className="img-lang px-1"
					src={locale=="en"?enFLag:arFLag}
					width="30"
					alt="lang"
				/>
				{/* {Locale[locale]} */}
				</div>
			</DropdownToggle>

			<DropdownMenu className="lang-dropdown-item">
				{locale === "ar" ? (
					<DropdownItem
						onClick={() => {
							if (locale === "ar") {
								dispatch({ type: "setLoading", payload: true });

								localStorage.setItem("currentLocale", "en");
								window.location.reload();
							}
						}}
					>
						<img className="img-lang px-2" src={enFLag} width="40" alt="lang" />
						{/* <span className={`${locale === "en" ? "ml-2" : "mr-2"}`}>
							English
						</span> */}
					</DropdownItem>
				) : (
					<DropdownItem
						onClick={() => {
							if (locale === "en") {
								dispatch({ type: "setLoading", payload: true });

								localStorage.setItem("currentLocale", "ar");
								window.location.reload();
							}
						}}
					>
						<img className="img-lang px-2" src={arFLag} width="40" alt="lang" />
						{/* <span className={`${locale === "en" ? "ml-2" : "mr-2"}`}>
							العربية
						</span> */}
					</DropdownItem>
				)}
			</DropdownMenu>
		</UncontrolledDropdown>
	);
}
