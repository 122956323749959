import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import CheckBox from 'components/chekbox';

export default function SelectLanguagesModal({
  isOpen,
  toggle,
  setLanguagesModal,
  languagesList,
  defaultLanguage,
  selectTemplateConfig,
  languagesLookup
}) {

  function handleSelectLanguages(isChecked, value, isDefaultLanguage) {
    let languagesListClone = [...languagesList];
    if (isChecked) {
      languagesListClone = languagesListClone?.filter(language => language.code !== value.code);
    } else {
      languagesListClone = [...languagesListClone, value]
    }
    setLanguagesModal({
      isOpen: true,
      languagesList: languagesListClone,
      defaultLanguage: (isDefaultLanguage && isChecked) ? languagesListClone[0]?.code : defaultLanguage
    })
  }

  function handleSelectDefaultLanguage(language) {
    setLanguagesModal({ isOpen: true, languagesList, defaultLanguage: language })
  }


  return (
    <Modal isOpen={isOpen} centered className="web-builder-languages-modal" >
      <ModalHeader toggle={() => toggle(null)} className='web-builder-custom-modal-title'>
        Choose your website languages
      </ModalHeader>

      <ModalBody>
        <div className="languages-list">
          {languagesLookup.map(language => {
            const selectedLanguage = languagesList?.find(l => l.code === language.code);
            const isChecked = selectedLanguage ? true : false;
            const isDefault = defaultLanguage && selectedLanguage?.code === defaultLanguage;

            return (
              <div className="d-flex justify-content-between" key={language?.code}>
                <div className='checkbox'>
                  <CheckBox
                    checked={isChecked}
                    id={language.code}
                    name={language.code}
                    text={language.name}
                    onChange={(e) =>
                      handleSelectLanguages(isChecked, language, isDefault)
                    }
                  />
                </div>
                {isChecked &&
                  <button className={`btn p-0 shadow-none ${isDefault ? "default-lang" : ""}`}
                    onClick={() => handleSelectDefaultLanguage(language?.code)}
                    disabled={isDefault}
                  >
                    {isDefault ? "Default" : "Set as default"}
                  </button>
                }
              </div>
            )
          })}
        </div>
        <button className="btn p-0 shadow-none mt-3 py-2 w-100 bg-nxt"
          disabled={!defaultLanguage}
          onClick={selectTemplateConfig}
        >
          Done
        </button>
      </ModalBody>
    </Modal>
  )
}
