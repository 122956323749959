import UploadFileComponent from "modules/WebBuilder/shared/UploadFile";
import React from "react";
import { ReactComponent as ImageIcon } from "assets/images/webBuilder/image.svg";

function PhotoItem({ url, onChange, id }) {
	return (
		<div className="custom-container-photo">
			{url ? (
				<img src={url} alt="" />
			) : (
				<div className="custom-container-add-photo">
					<UploadFileComponent
						icon={ImageIcon}
						id="custom-container-add-photo"
						label="Add Photo"
						onUpload={(value) => onChange(value, id)}
					/>
				</div>
			)}
		</div>
	);
}

export default PhotoItem;
