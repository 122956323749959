import React from 'react';
import walletImgSrc from 'assets/images/inventory/walletSm.svg';
import Locale from 'translations';
import { useMarketplaceState } from 'context/marketplace';
import BookingSummaryItem from './BookingSummaryItem';
import { formatPrice } from 'helpers/utils';

export default function BookingSummary({ calcTotalCartPrice }) {
  const { inventory, marketPlace } = Locale;
  const { toursCart } = useMarketplaceState();

  // calc selected category price for adults and childs passed as prop in summary and dates
  // const calcSelectedCategoryPrice = useMemo(() => {
  //   return calcCategoryPrice(toursSearch, selectedCategory);
  // }, [toursSearch, selectedCategory]);

  return (
    <div className='tours-summary-booking'>
      <div className='tours-summary-header'>
        <h1>{marketPlace.messages.bookingSummary}</h1>
      </div>
      <div className='mt-4 bg-white p-2 border'>
        {toursCart?.cartItems?.map((cartItem, index) => {
          return (
            <BookingSummaryItem
              key={cartItem?.id}
              index={index}
              cartItem={cartItem}
              toursCart={toursCart}
            />
          )
        })}
      </div>
      <div className='d-flex justify-content-end mt-2'>
        <img src={walletImgSrc} alt="wallet" className='img-fluid' />
        <p className='d-flex flex-column mx-2 '>
          <span className='text-dark-blue'>{inventory.messages.ReservationTotalCost} </span>
          <span className='text-success font-weight-bold h5'>{formatPrice(calcTotalCartPrice.total)} {calcTotalCartPrice?.currency}</span>
        </p>
      </div>
    </div>
  )
}
