export default function CalendarIcon(props) {
  return (
    <svg width={props?.width ? props.width : "21"} height={props?.height ? props.height : "20"} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.33398 1.66602V4.16602" stroke="#00B7AC" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14 1.66602V4.16602" stroke="#00B7AC" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3.58398 7.57422H17.7507" stroke="#00B7AC" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.167 7.08268V14.166C18.167 16.666 16.917 18.3327 14.0003 18.3327H7.33366C4.41699 18.3327 3.16699 16.666 3.16699 14.166V7.08268C3.16699 4.58268 4.41699 2.91602 7.33366 2.91602H14.0003C16.917 2.91602 18.167 4.58268 18.167 7.08268Z" stroke="#00B7AC" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.6631 11.4167H10.6706" stroke="#00B7AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.57908 11.4167H7.58657" stroke="#00B7AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.57908 13.9167H7.58657" stroke="#00B7AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}