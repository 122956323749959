import React from 'react'

export default function VisaIcon({ size }) {
	return (
		<svg width={size ? size : `32`}
			height={size ? size : `32`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path id="Vector" d="M30.9999 31V1.00009H1V31H30.9999Z" fill="white" stroke="white" stroke-width="2" />

			<path id="Vector_2" d="M23.4245 31.0625H5.14172V4.77651H23.4245C25.3209 4.77651 26.8582 6.31382 26.8582 8.21013V27.6289C26.8582 29.5252 25.3209 31.0625 23.4245 31.0625Z" stroke="#707170" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
			<path id="Vector_3" d="M5.14185 4.77649L19.4636 1.067C21.7362 0.397127 24.0187 2.43112 23.728 4.77649" stroke="#707170" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
			<path id="Vector_4" d="M22.9829 16.5112C22.9829 20.3678 19.8566 23.4942 15.9999 23.4942C12.1434 23.4942 9.01709 20.3678 9.01709 16.5112C9.01709 12.6547 12.1434 9.52831 15.9999 9.52831C19.8566 9.52831 22.9829 12.6547 22.9829 16.5112Z" stroke="#707170" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
			<path id="Vector_5" d="M15.4053 9.58444C12.8134 14.7421 12.7932 18.2992 15.3635 23.4365" stroke="#707170" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
			<path id="Vector_6" d="M16.6237 23.4364C19.1964 18.2906 19.1973 14.7336 16.6077 9.58438" stroke="#707170" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
			<path id="Vector_7" d="M9.22412 16.5112H22.7634" stroke="#707170" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
			<path id="Vector_8" d="M9.27368 27.3062H12.5154" stroke="#707170" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
			<path id="Vector_9" d="M16.2717 27.3062H22.9828" stroke="#707170" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />

		</svg>
	);
}

