import NumberField from 'components/Form/NumberField/NumberField'
import SelectField from 'components/Form/SelectField/SelectField'
import TextField from 'components/Form/TextField/TextField'
import { useSBSState } from 'context/global'
import Locale from 'translations'

const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";


export default function AddEditAgentForm({ agentData, handleInputChanges, errors, isModal }) {
  const { locale, allCountries } = useSBSState();
  const { operationStatement, userDetails, login, inventory, companyDetails } = Locale;

  return (
    <div className="row mx-0">
      {/* agent name */}
      <div className="col-md-4 col-12 mb-3">
        <TextField
          type="text"
          id="agent-name"
          name="agent-name"
          label={operationStatement.agentName}
          placeholder={operationStatement.agentName}
          value={agentData?.name}
          onChange={(e) => handleInputChanges("name", e.target.value)}
          color={errors.name?.required ? "danger" : ""}
          errors={errors.name}
        />
      </div>
      {/* agent country */}
      <div className="col-md-4 col-12 mb-3">
        <SelectField
          id="agent-country"
          name="agent-country"
          label={inventory.messages.country}
          options={allCountries}
          value={agentData?.country?.label[locale] || agentData?.country?.label}
          onChange={(e) => handleInputChanges("country", e)}
          color={errors.country?.required ? "danger" : ""}
          errors={errors.country}
        />
      </div>
      {/* agent email */}
      <div className="col-md-4 col-12 mb-3">
        <TextField
          type="email"
          id="agent-email"
          name="agent-email"
          label={login.emailAddress}
          placeholder={login.emailAddress}
          value={agentData?.email}
          onChange={(e) => handleInputChanges("email", e.target.value)}
          color={errors.email?.required || errors.email?.email ? "danger" : ""}
          errors={errors.email}
        />
      </div>
      {/* agent phone */}
      <div className={`${isModal ? "col-md-6" : "col-md-4"} col-12 row mx-0 mb-3`}>
        <label className="control-field__label col-12">{operationStatement.PhoneNumber}</label>
        {/* phone code */}
        <div className="col-md-3 col-4 company_phone_code">
          <SelectField
            label={"Code"}
            hasLabel={false}
            id="agent-phone_code"
            name="agent-phone_code"
            value={
              agentData?.phone_code?.flag ?
                <img
                  src={`${fetchFlag}/${agentData?.phone_code?.flag}`}
                  width="35px"
                  alt={agentData.phone_code?.label}
                />
                :
                agentData.phone_code?.label || agentData.phone_code
            }
            onChange={(e) => handleInputChanges("phone_code", e)}
            options={allCountries}
            color={errors?.phone_code?.required ? "danger" : ""}
            errors={errors?.phone_code}
          />
        </div>
        {/* phone */}
        <div className="col-md-9 col-8 px-0">
          <NumberField
            type={"phone"}
            label={userDetails.phoneNumber}
            hasLabel={false}
            id="agent-phone"
            name="agent-phone"
            value={agentData.phone}
            removeArrow={true}
            placeholder={"000 0000 000"}
            min={3}
            onChange={(e) => handleInputChanges("phone", e.target.value)}
            extraTextPosition="prepend"
            extraText={
              agentData?.phone_code?.phone_code ||
              agentData.phone_code?.label ||
              agentData?.phone_code?.value
            }
            color={errors?.phone?.required || errors?.phone_code?.required ? "danger" : ""}
            errors={errors?.phone || errors?.phone_code}
          />
        </div>
      </div>

      {/* agent address */}
      <div className={`${isModal ? "col-md-6" : "col-md-8"} col-12 mb-3`}>
        <TextField
          type="text"
          id="agent-address"
          name="agent-address"
          label={companyDetails.address}
          placeholder={companyDetails.address}
          value={agentData?.address}
          onChange={(e) => handleInputChanges("address", e.target.value)}
        />
      </div>
      {/* Responsible Employee */}
      <div className="col-md-4 col-12 mb-3">
        <TextField
          type="text"
          id="employee-name"
          name="employee-name"
          label={operationStatement.responsibleEmployee}
          placeholder={operationStatement.responsibleEmployee}
          value={agentData?.responsible_name}
          onChange={(e) => handleInputChanges("responsible_name", e.target.value)}
        />
      </div>
      {/* Responsible Employee email */}
      <div className="col-md-4 col-12 mb-3">
        <TextField
          type="email"
          id="employee-email"
          name="employee-email"
          label={login.emailAddress}
          placeholder={login.emailAddress}
          value={agentData?.responsible_email}
          onChange={(e) => handleInputChanges("responsible_email", e.target.value)}
        />
      </div>
      {/* Responsible Employee phone */}
      <div className="col-md-4 col-12 row mx-0 mb-3">
        <label className="control-field__label col-12">{operationStatement.PhoneNumber}</label>
        {/* phone code */}
        <div className="col-md-3 col-4 company_phone_code">
          <SelectField
            label={"Code"}
            hasLabel={false}
            name="employee-phonecode"
            id="employee-phonecode"
            placeholder={'Code'}
            value={
              agentData?.responsible_phone_code?.flag ?
                <img
                  src={`${fetchFlag}/${agentData?.responsible_phone_code?.flag}`}
                  width="35px"
                  alt={agentData.responsible_phone_code?.label}
                />
                :
                agentData.responsible_phone_code?.label || agentData.responsible_phone_code
            }
            options={allCountries}
            onChange={(e) => handleInputChanges("responsible_phone_code", e)}
          />
        </div>
        {/* phone */}
        <div className="col-md-9 col-8 px-0">
          <NumberField
            type={"phone"}
            label={userDetails.phoneNumber}
            hasLabel={false}
            name="employee-phone"
            id="employee-phone"
            value={agentData.responsible_phone}
            removeArrow={true}
            placeholder={"000 0000 000"}
            min={3}
            onChange={(e) => handleInputChanges("responsible_phone", e.target.value)}
            extraTextPosition="prepend"
            extraText={
              agentData?.responsible_phone_code?.phone_code ||
              agentData.responsible_phone_code?.label ||
              agentData?.responsible_phone_code?.value
            }
          />
        </div>
      </div>

    </div>
  )
}
