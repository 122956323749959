import { ADD_NEW_BLOCK_TO_BODY_ACTION } from "modules/WebBuilder/interfaces/blockTypes";
import React from "react";
import { useDrag } from "react-dnd";

function ContentBlock({ image, title, type,isFaqs,canDrag=true }) {
	const [{ opacity }, drag] = useDrag(
		() => ({
			canDrag:()=> canDrag,
			type,
			item: { type, action: ADD_NEW_BLOCK_TO_BODY_ACTION,isFaqs },
			collect: (monitor) => ({
				opacity: monitor.isDragging() ? 0.4 : 1,
			}),
		}),
		[type,canDrag]
	);
	return (
		<div ref={drag} style={{ opacity }} className="content-block-details">
			<div className="image-container">
				<img src={image} alt={title} />
			</div>
			<p>{title}</p>
		</div>
	);
}

export default ContentBlock;
