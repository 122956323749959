import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useSBSDispatch, useSBSState } from "context/global";
import libphonenumber from "google-libphonenumber";
import validate, { isFormValid } from "helpers/validate";
import useFetchCountries from "hooks/useFetchCountries";
import { useEffect, useRef, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { uploadFile } from "services/auth";
import { fetchUserProfile, updateUserProfile } from "services/profile";
import Locale from "translations";
import imgIcon from "../../assets/images/customRegister/image-regular.svg";

export default function UserProfile(props) {
	const { userDetails, companyDetails, companyAvatar, commons,teamManagement } = Locale;
	const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
	const dispatch = useSBSDispatch();
	let history = useHistory();
	const { locale } = useSBSState();
	const [countries] = useFetchCountries();
	const [userProfileState, setUserProfileState] = useState({
		name: "",
		email: "",
		country: 0,
		countryPhoneCode: null,
		phone: "",
		password: "",
		passwordConfirmation: "",
		companyLogo: null,
		filePreview: null,
		description: "",
		position: "",
		avatar: "",
		countryCode: null,
		spin: false,
		avatarID: null,
	});
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const validatePhone = (phone) => {
		try {
			return phoneUtil.isValidNumberForRegion(
				phoneUtil.parse(phone.toString(), userProfileState.countryCode),
				userProfileState.countryCode
			);
		} catch (err) {
			return false;
		}
	};
	const URI = process.env.REACT_APP_FILEUPLOAD_URL;
	const inputFileRef = useRef(null);
	const [editing, setEditing] = useState(true);
	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "name", value: userProfileState.name },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "position", value: userProfileState.position },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "country", value: userProfileState.country },
				{ required: true }
			),
			...validate(
				{ name: "phone", value: validatePhone(userProfileState.phone) },
				{ required: true, phone: true }
			),
		});
	};
	// Use Effect for [Country]
	useEffect(() => {
		const Data = countries?.filter(
			(obj) => obj.id === userProfileState.country?.value
		);
		setUserProfileState({
			...userProfileState,
			countryPhoneCode: Data.length > 0 ? Data[0]["phone_code"] : "-",
			countryCode: Data.length > 0 ? Data[0]["code"] : null,
		});
	}, [countries, userProfileState.country?.value]);

	useEffect(() => {
		async function fetchData() {
			if (countries.length !== 0) {
				const user = await fetchUserProfile();
				const country = countries.find(
					(country) => country.nationality_id === user.nationality
				);
				setUserProfileState({
					...userProfileState,
					name: user.full_name,
					email: user.user_id,
					phone: user.phone.phone,
					position: user.position,
					avatar: user.avatar,
					description: user.extra_data?.description,
					country: { value: country.id, label: country.name },
					countryPhoneCode: country.phone_code,
					countryCode: country.code,
				});
			}
		}
		fetchData();
	}, [countries]);
	const [isPreview, setIsPreview] = useState(true);
	useEffect(() => {
		async function fetchData() {
			if (userProfileState.avatar && isPreview) {
				//const fetchImage = await fetchImages(userProfileState.avatar);
				const fetchImage = `${URI}/fetch/${userProfileState.avatar}`;
				if (fetchImage) {
					updateLogo(fetchImage);
					setUserProfileState({
						...userProfileState,
						filePreview: fetchImage,
					});
				}
				setIsPreview(false);
			}
		}
		fetchData();
	}, [userProfileState.avatar]);

	const updateLogo = (file) => {
		setUserProfileState({ ...userProfileState, companyLogo: file });
	};

	const onFileChange = async (e) => {
		const fileType = e.target.files[0];
		const formData = new FormData();
		formData.append("file", fileType);
		formData.append("name", fileType.name);
		formData.append("bucket", "user");
		if (!fileType.name.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
			store.addNotification({
				title: commons.somethingWentWrong,
				message: companyAvatar.companyAvatarExtensionValidation,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		} else if (fileType.size > 500000) {
			store.addNotification({
				title: commons.somethingWentWrong,
				message: companyAvatar.companyAvatarSizeValidation,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		} else {
			const response = await uploadFile(formData);

			if (response.status === 200 && response.data.data.uuid) {
				setUserProfileState({
					...userProfileState,
					avatar: response.data.data.uuid,
				});

				//const fetchImage = await fetchImages(response.data.data.uuid);
				const fetchImage = `${URI}/fetch/${response.data.data.uuid}`;
				updateLogo(fetchImage);
				setUserProfileState({
					...userProfileState,
					filePreview: fetchImage,
					avatar: userProfileState.avatarID
						? userProfileState.avatarID
						: response.data.data.uuid,
					avatarID: userProfileState.avatarID
						? userProfileState.avatarID
						: response.data.data.uuid,
				});
				dispatch({
					type: "Save_User_Logo",
					userLogo: fetchImage,
				});
				setIsPreview(true);
			}
		}
	};

	const handleBtnClick = () => {
		inputFileRef.current.click();
	};
	useEffect(async () => {
		const data = {
			lang: locale,
			user_id: userProfileState.email,
			full_name: userProfileState.name,
			phone: `${userProfileState.countryPhoneCode}${userProfileState.phone}`,
			nationality: userProfileState.country?.value,
			avatar: userProfileState.avatar,
			extra_data: {
				description: userProfileState.description,
			},
			position: userProfileState.position,
		};
		if (isFormValid(errors)) {
			const response = await updateUserProfile(data);
			if (response.status === 200) {
				setEditing(false);
				store.addNotification({
					title: commons.updatedSuccessfully,
					message: "User profile updated Successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 2000,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						history.push("/user-profile");
					},
				});
			}
		}
		setUserProfileState({
			...userProfileState,
			spin: false,
		});
	}, [isErrorLoaded]);

	const updateProfile = () => {
		setUserProfileState({
			...userProfileState,
			spin: true,
		});
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	return (
		<>
			<div className=" container bg-white  border rounded p-0">
				<div className="d-flex justify-content-between p-3 border-bottom">
					<h6 className="font-weight-bold">{userDetails.userProfile}</h6>
				</div>
				<div className="row m-0 p-3 align-items-center">
					<div className="col-3 px-4">
						<input
							type="file"
							ref={inputFileRef}
							onChange={onFileChange}
							className="d-none"
							accept="image/*"
						/>
						<div
							className="image-uploader"
							onClick={editing ? handleBtnClick : false}
						>
							<div className="image-uploader__outline position-relative bg-white img-center">
								<div className="img-holder ">
									<img
										src={
											userProfileState.filePreview
												? userProfileState.filePreview
												: imgIcon
										}
										className="img-fluid"
										alt="logo placeholder"
										accept="image/*"
									/>
								</div>
								<i className="fas fa-plus-circle image-uploader__plus-btn"></i>
							</div>
						</div>
					</div>

					{/* <h6 className="text-center font-weight-bold py-2 text-primary">
								{userProfileState.name}
							</h6>
							<div className="description">
								<h6 className="py-1 font-weight-bold text-uppercase">
									{companyDetails.description}
								</h6>
								<p>{userProfileState.description}</p>
							</div> */}

					<div className="col-md-6">
						{userProfileState.errorMessage ? (
							<div
								className="alert alert-danger alert-dismissible fade show text-center mx-5"
								role="alert"
								id="alert"
							>
								{userProfileState.errorMessage}
							</div>
						) : null}

						<div className="col-md-12">
							<div className="form-group full-input">
								<TextField
									label={userDetails.fullName}
									placeholder={userDetails.fullNamePlaceholder}
									value={userProfileState.name}
									disabled={!editing}
									name="name"
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
												min: 3,
											}),
										});
										setUserProfileState({
											...userProfileState,
											name: e.target.value,
										});
									}}
									min={3}
									color={
										errors?.name?.required || errors?.name?.min ? "danger" : ""
									}
									errors={errors?.name}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group full-input">
								<TextField
									label={userDetails.businessEmail}
									placeholder={userDetails.businessEmailPlaceholder}
									value={userProfileState.email}
									name="country"
									disabled={true}
									onChange={(e) => {
										setUserProfileState({
											...userProfileState,
											email: e.target.value,
										});
									}}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group full-input">
								<SelectField
									label={userDetails.country}
									value={userProfileState.country?.label}
									placeholder={userDetails.countryPlaceholder}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: "country", value: e.value },
												{ required: true }
											),
										});
										setUserProfileState({
											...userProfileState,
											country: e,
											phone: "",
										});
									}}
									onBlur={() =>
										setErrors({
											...errors,
											...validate(
												{
													name: "country",
													value: userProfileState.country,
												},
												{ required: true }
											),
										})
									}
									disabled={!editing}
									options={countries}
									color={errors?.country?.required ? "danger" : ""}
									errors={errors?.country}
								></SelectField>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group full-input">
								<TextField
									label={userDetails.phoneNumber}
									placeholder={userDetails.phoneNumberPlaceholder}
									disabled={true}
									name="phone"
									value={userProfileState.phone}
									extraText={userProfileState.countryPhoneCode ?? "---"}
									extraTextPosition={
										userProfileState.country === 0 ? "" : "prepend"
									}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{
													name: "phone",
													value: validatePhone(e.target.value),
												},
												{
													required: true,
													phone: true,
												}
											),
										});
										setUserProfileState({
											...userProfileState,
											phone: e.target.value,
											countryPhoneCode: userProfileState.countryPhoneCode,
											countryCode: userProfileState.countryCode,
										});
									}}
									color={
										errors?.phone?.required || errors?.phone?.phone
											? "danger"
											: ""
									}
									errors={errors?.phone}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group full-input">
								<TextField
									label={teamManagement.position}
									placeholder={teamManagement.positionPlaceholder}
									value={userProfileState.position}
									disabled={!editing}
									name="position"
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
												min: 3,
											}),
										});
										setUserProfileState({
											...userProfileState,
											position: e.target.value,
										});
									}}
									min={3}
									color={
										errors.position?.required || errors.position?.min
											? "danger"
											: ""
									}
									errors={errors.position}
								/>
							</div>
						</div>
						{/* <div className="col-md-12">
									<div className="form-group full-input">
										<TextAreaField
											label={userDetails.description}
											placeholder={userDetails.descriptionPlaceholder}
											rows={5}
											disabled={!editing}
											value={userProfileState.description}
											onChange={(e) => {
												setUserProfileState({
													...userProfileState,
													description: e.target.value,
												});
											}}
										/>
									</div>
								</div> */}

						<div className="d-flex justify-content-end mt-3">
							<button className="btn bg-bk" onClick={() => history.push("/user-profile")}>
								{userDetails.cancel}
							</button>

							<button
								onClick={updateProfile}
								className="btn bg-nxt mx-2"
								disabled={userProfileState.spin}
							>
								{userProfileState.spin ? (
									<Spinner color="light" size="sm" />
								) : null}{" "}
								{companyDetails.updateProfileBtn}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
