import React from 'react'
import bannerSrc from "assets/images/bannerLogin.png";
import { ReactComponent as FlightIcon } from "assets/images/customRegister/flight.svg";
import { ReactComponent as PassportIcon } from "assets/images/customRegister/passport.svg";
import { ReactComponent as HotelIcon } from "assets/images/customRegister/stars-hotel.svg";
import { ReactComponent as TourBusIcon } from "assets/images/customRegister/tour_Bus.svg";
import { ReactComponent as ToursIcon } from "assets/images/customRegister/tours.svg";
import { ReactComponent as TransporationIcon } from "assets/images/customRegister/transporation.svg";
import Locale from 'translations';




export default function PreAuthBanner() {
  const { login } = Locale;
  const iconsList = [
    { id: "flight-icon", component: <FlightIcon /> },
    { id: "passport-icon", component: <PassportIcon /> },
    { id: "hotels-icon", component: <HotelIcon /> },
    { id: "tourbus-icon", component: <TourBusIcon /> },
    { id: "tours-icon", component: <ToursIcon /> },
    { id: "transporation-icon", component: <TransporationIcon /> },
  ]

  return (
    <div className="banner">
      <img src={bannerSrc} alt="safa solutions & services" className="img-fluid" />
      <div>
        <h1 className="my-3">{login.safaLive}</h1>
        <p className="about">
          {login.aboutMessage}
        </p>

        <div className="icons mt-3">
          {iconsList.map(icon => {
            return (
              <span key={icon.id}>
                {icon.component}
              </span>
            )
          })}
        </div>

      </div>
    </div>
  )
}
