import { useSBSState } from "context/global";
export default function TableContract({ contract, index }) {
	const { locale } = useSBSState();
	return (
		<tr key={index}>
			<td>{contract?.id}</td>
			<td>{contract?.Name}</td>
			<td>
				{locale == "en" ? contract?.UASP?.UASP_LA : contract?.UASP?.UASP_AR}
			</td>
			<td>{contract?.EgyUser}</td>
			<td>{contract?.username}</td>
		</tr>
	);
}
