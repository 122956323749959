import React from 'react'
import { Modal } from 'reactstrap';
import Locale from 'translations';


export default function PassangerModel({ isOpen, toggle, flightData }) {
  const { marketPlace, inventory } = Locale;


  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={'lg'}
      centered={true}
    >
      <div className="d-flex align-items-center justify-content-between p-2 px-4 pt-0 w-100">
        <h3>
          {inventory.messages.Passenger}
        </h3>
        <a href="##"
          onClick={toggle} className='btn-x'
        >
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div className='table-container table-responsive flight-table-height '>
      <table
        striped
        className="table-update p-2 bg-white m-0 table table-striped"
      >
        <thead>
          <tr>
            <th>{inventory.messages.name}</th>
            <th>{inventory.messages.gender}</th>
            <th>{inventory.messages.dateOfBirth}</th>
            <th>{inventory.messages.IDNumber}</th>
            <th>{inventory.messages.IDType}</th>
            <th>{inventory.messages.IDExpiryDate}</th>
            <th>{inventory.messages.frequentFlyerProgram}</th>

          </tr>
        </thead>
        <tbody>
         { flightData?.passengers?.length>0?flightData?.passengers?.map((item,index)=>(
          <tr>
            <td>{item?.lastName}/{item?.firstName}</td>
            <td>{item?.sex=="F"?"Female":"Male"}</td>
            <td>{item?.birthday}</td>
            <td>{item?.cardNum}</td>
            <td>{item?.cardType=="P"?"Passport":item?.cardType}</td>
            <td>{item?.cardExpiredDate}</td>
            <td></td>

          </tr>
         )):<tr>
         <td colSpan="7">
           <div className="product-build__product-no-data ">
             <i className="icx icx-eye-slash" aria-hidden="true"></i>
             <h4>{"No Data Found"}</h4>
           </div>
         </td>
       </tr>}
          
       
        </tbody>
      </table>
      </div>
     
    </Modal>
  )
}
