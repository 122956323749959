import walletImg from "assets/images/wallet/walletSm.svg";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";

export default function TransferModal({
	modalTransfer,
	walletsList,
	toggleModalTransfer,
	modalSendRequest,
	toggleModalSendRequest,
	data,
	submit,
	setAmount,
	amount,
	AddDebit,
}) {

	const { payment } = Locale;
	const [Validation, setValidation] = useState("f");
	const validateField = () => {
		setValidation(isNaN(amount) ? true : false);
	};
	const { locale } = useSBSState();
	const [defaulte, setDefault] = useState(
		walletsList.filter((res) => res.default)[0]
	);

	return (
		<div>
			<Modal
				size="md"
				isOpen={modalTransfer}
				toggle={toggleModalTransfer}
				className="recharge-Modal"
			>
				<ModalHeader
					className="align-items-center py-1  modal-header"
					toggle={toggleModalTransfer}
				>
					{payment.messages.paySafaWallet}
				</ModalHeader>
				<ModalBody>
					<div className="container">
						<div className="modal-layer">
							<div className="row m-0 pt-3">
								<div className="col-6 ">
									<div className="box-amount py-3">
										<h6>{payment.messages.creditLeft}</h6>
										<p>{`${data?.amount} ${data?.currency}`}</p>
									</div>
								</div>
								<div className="col-6 ">
									<div className="box-amount py-3">
										<h6>{payment.messages.totalLimit}</h6>
										<p>{`${data?.limit} ${data?.currency}`}</p>
									</div>
								</div>
								<div className="col-12">
									<div className="box-pay-wallet my-3 px-2">
										{locale == "en" ? (
											<img src={walletImg} alt="" width={25} height={35} />
										) : (
											""
										)}
										<div>
											<h6>
												{payment.messages.payFrom} {data?.currency}{" "}
												{payment.messages.wallet}
											</h6>
											<p>
												{payment.messages.balance}: {defaulte?.ref_num}{" "}
												{data?.currency}
											</p>
										</div>
										{locale == "ar" ? (
											<img src={walletImg} alt="" width={25} height={25} />
										) : (
											""
										)}
									</div>
								</div>
								{/*payment.messages.typeAmount */}
								<div className="col-12">
									<TextField
										label={payment.messages.amount}
										placeholder={"0.00"}
										value={amount}
										onChange={(e) => {
											setAmount(e.target.value);
											validateField();
										}}
										onBlur={validateField}

									//color={Validation ? "danger" : ""}
									></TextField>
								</div>
								{/* {Validation ? (
								<small className="text-danger">*Amount must to be number</small>
							) : null} */}
							</div>
							<button
								disabled={Validation}
								className="btn btn-yellow my-3 py-2 w-100 modal-btn text-uppercase"
								onClick={() => {
									submit();
									toggleModalTransfer(null);
									// setTimeout(() => {
									// 	toggleModalSendRequest(modalSendRequest);
									// }, 2000);

									//setAmount(null);
								}}
							>
								{payment.messages.pay}
								{/* {payment.messages.transferWallet} */}
							</button>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}
