import PasswordField from "components/Form/PasswordField/PasswordField";
import TextField from "components/Form/TextField/TextField";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import { useSBSDispatch, useSBSState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import { useState } from "react";
import { store } from 'react-notifications-component';
import OTPInput from 'react-otp-input';
import { Link, useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { resendMassage, userLogin, verifyEmail, verifyOtp } from "services/auth";
import bg from "../../../assets/images/customRegister/Group 3787.png";
import bgAR from "../../../assets/images/customRegister/Group 3787AR.png";
import safaLogo from "../../../assets/images/safa_logo.png";
import Locale from "../../../translations";
import safaLogo2 from "assets/images/bannerLogin.png";
import Logosafa from "assets/images/LogoSafa.svg";
import bus from "assets/images/main-icon/bus.svg";
import flight from "assets/images/main-icon/flight.svg";
import hotel from "assets/images/main-icon/hotel.svg";
import toury from "assets/images/main-icon/toury.svg";
import passport from "assets/images/main-icon/passport.svg";
import transfer from "assets/images/main-icon/transfer.svg";
import SafaLogo from "assets/images/dashboard/safaLogoFull.svg";


export default function Otp(props) {
	const { login, onlineVisa,newLand } = Locale;
	const search = window.location.search;
	const isMobile = window.innerWidth < 426 ? true : false;

	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { locale,userInfoOtp } = useSBSState();
	const [otp, setOtp] = useState("");


	const dispatch = useSBSDispatch();
	const [loginState, setLoginState] = useState({
		email: "",
		password: "",
		showPasswordField: false,
		errorPasswordMessage: "",
	});
	const [spin, setSpin] = useState(false);
	const [errors, setErrors] = useState({});
	let location = useHistory();
	const	resned= async ()=>{
		const response = await resendMassage(userInfoOtp.resend_otp_url);
		if (response.status==200) {
			store.addNotification({
				title: "info!",
				message: response.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1100,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------

	const checkOTPErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "otp", value: otp },
				{ required: true }
			),
		});
	};


	// Login User
	const loginUser = async () => {
		checkOTPErrors();
		setSpin(true);
		
		let requestBody = {
			email: userInfoOtp.email,
			phone: userInfoOtp.phone,
			otp:otp
		};
		if (isFormValid(errors)) {
			const response = await verifyOtp(requestBody);
			if (response?.status === 200) {
				// Store Response In Redux
				// setSpin(true);

				// dispatch({
				// 	type: "login",
				// 	isAuth: true,
				// 	token: response.data.data.token,
				// 	userInfo: { ...response.data.data.user, is_connected_to_safa_visa: response.data.data.company?.is_connected_to_safa_visa, is_first_login: isMobile ? 0 : response?.data?.data?.user?.is_first_login },
				// 	companyInfo: response.data.data.company,
				// });
				// localStorage.setItem("loginData", JSON.stringify(action?.payload));

				// if (response.data.data.user.is_first_login === true) {
				//Redirect to Interests
				// location.push("/interest-module");
				// } else {
				//Redirect to Dashboard
				store.addNotification({
					title: "info!",
					message: response.data.message,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1100,
						onScreen: true,
						pauseOnHover: true,
					},
				});

			
				location.push("/auth/login");

				// }
			}
		}
		setSpin(false);
	};

	// ----------------------------------------------------------------------------------------------------
	// Mini Components UI
	// ----------------------------------------------------------------------------------------------------



	const redirect = () => {
		window.location.reload();
		window.location.href = "/auth/select-register-type";
	};
	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	return (
		<>
			<div className="bg-layout  login-page">
				<div className="d-flex container  justify-content-around ">
					{/* Left Banner */}
					<figure className="col-6 m-0 login-page-img">
					<div className="d-flex hidden-mobile justify-content-between py-2 align-items-center w-100">
							<Link to="/" className="text-dark">
								<img src={SafaLogo} alt="" srcset="" />
							</Link>
							
						</div>
						<div className="d-flex justify-content-center align-items-center" style={{flexDirection:"column",minHeight:"calc(100vh - 40px )"}}>
						<img
							src={ safaLogo2 }
							alt=""
							className="img-fluid"
						/>

						<p className="m-0 mt-2 Safa-title w-100">{login.SafaLive}</p>
						<p className="m-0 w-100 mt-2 Safa-dec">{login.AnOnl}</p>
						<p className="m-0 w-100 Safa-dec">
{login.introducesAnOnl}
						</p>

						<div className='d-flex align-items-center mt-3 w-100 justify-content-between '>
								<img src={flight} alt="flight" srcset="" />
								<img src={toury} alt="toury" srcset="" />
								<img src={hotel} alt="hotel" srcset="" />
								<img src={passport} alt="passport" srcset="" />
								<img src={transfer} alt="transfer" srcset="" />
								<img src={bus} alt="bus" srcset="" />
						</div>
<div>

</div>
						</div>
					</figure>

					{/* Login Form */}
					<div className="col-md-5 col-sm-9 d-flex  flex-column  justify-content-between login-page-contant">
					<div className="d-flex hidden-mobile justify-content-between py-2 align-items-center w-100">
						<Link to="/" className="text-dark linkHeader linkHeaderActive">
								{newLand.home}
							</Link>
							<Link to="/about-us" className="text-dark linkHeader">
								{newLand.aboutUs}
							</Link>
							<Link to="/contact-us" className="text-dark linkHeader">
								{newLand.contactUs}
							</Link>
						
							<LanguageSwitcher />
						</div>
						<div className="w-100  login-page-data">
							<img
								src={safaLogo}
								alt=""
								className="safa_logo d-none"
							/>
							<h1 className="text-title font-weight-bold">
								{login.welcomeBack}
							</h1>
							<p className="text-headline">{login.loginMessage}</p>

							<div className="mt-3">
							<OTPInput
									className="w-100"
									value={otp}
									onChange={setOtp}
									numInputs={4}
									renderSeparator={<span className="mx-2">-</span>}
									inputStyle={"w-25 input-OTP"}
									renderInput={(props) => (
										<input style={{ width: "25%" }} {...props} />
									)}
								/>
							</div>
							<div className="d-flex justify-content-between align-items-center">
								
								<a
									href={()=>false}
									onClick={()=>resned()}
									className="my-2 d-block text-right text-dark-blue"
								>
									{login.resendMassage}
								</a>
							</div>
							<button
								className="btn bg-nxt w-100"
								onClick={
								 loginUser 
								}
								disabled={spin}
							>
								{spin ? <Spinner color="light" size="sm" /> : null}
								{"  "} {login.verify}
							</button>
						</div>
						<div></div>
					</div>
				</div>
			</div>
		</>
	);
}
