import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import React, { useState } from "react";
import { AddElement } from "modules/WebBuilder/shared/AddElement";
import { ServiceCardDetails } from "modules/WebBuilder/shared/ServiceCardDetails";
import CustomCarousel from "modules/WebBuilder/shared/CustomCarousel";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { SORT_ITEMS_IN_CONTAINER_ACTION } from "../../../interfaces/blockTypes";
import { EditBox } from "modules/WebBuilder/shared/EditBox";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import AddService from "modules/WebBuilder/shared/AddService";
import DragAndDropContainer from "modules/WebBuilder/shared/DragAndDropContainer";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";

const toursBlocksType = "hotelBlocksType";

function ToursService({ details, onFocus, focusContainer }) {
	const { selectedLanguage } = useWebBuilderState();
	const { id, type, body, title, items } = details;
	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);

	const dispatch = useWebBuilderDispatch();

	const handleOpenAddModal = () => {
		setOpenAdd((prev) => !prev);
		setUpdateItem(null);
	};

	const openEditModal = (item) => {
		handleOpenAddModal();
		setUpdateItem(item);
	};

	const editItem = (item, index) => {
		return [
			<span
				key={`${item.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};

	const updateTours = ({ name, value, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				language
			},
		});
	};

	const sortToursItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};

	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={itemId}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "container_item" })
				}
				className="pointer-event"
			>
				<TrashIcon />
			</span>,
		];
	};

	const addItemToTours = (item) => {
		updateItem
			? dispatch({
					type: "editItemInContainer",
					payload: {
						id,
						item,
						itemIndex: updateItem.index,
					},
			  })
			: dispatch({
					type: "addItemToContainer",
					payload: {
						id,
						item,
					},
			  });
		setUpdateItem(null);
	};
	const selectedIds = items ? items.map((i) => i.id) : [];
	return (
		<EditBox editMode={focusContainer === type}>
			<div
				onClick={() => onFocus(type)}
				className="web-builder-content-tours container"
				id={id}
			>
				<div className="tours-top-section">
					<ServicesContent
						header={title}
						body={body}
						changeHeader={(value) => updateTours({ value, name: "title", language: selectedLanguage })}
						changebody={(value) => updateTours({ value, name: "body", language: selectedLanguage })}
					/>
					<AddElement
						onClick={handleOpenAddModal}
						title="Add Service"
						className={
							items?.length ? "bg-white border-0 flex-row height-90" : ""
						}
					/>
				</div>

				<div className="tours-details">
					<CustomCarousel>
						{items.map((props, index) => (
							<DragAndDropContainer
								accept={toursBlocksType}
								type={toursBlocksType}
								id={props.id}
								index={index}
								action={SORT_ITEMS_IN_CONTAINER_ACTION}
								moveItemInContainer={(dragIndex, hoverIndex) =>
									sortToursItems({ dragIndex, hoverIndex })
								}
								key={props.id}
							>
								<EditBox
									editMode={focusContainer === type}
									actions={[
										...editItem(props, index),
										...itemsBoxActions(props.id),
									]}
								>
									<ServiceCardDetails {...props} itemType="tour" />
								</EditBox>
							</DragAndDropContainer>
						))}
					</CustomCarousel>
				</div>
				{openAdd&&<AddService
					handleModalState={handleOpenAddModal}
					open={openAdd}
					item_id={7}
					label={"Service"}
					header={"Tour"}
					onAdd={addItemToTours}
					details={updateItem}
					selectedIds={selectedIds}
				/>}
			</div>
		</EditBox>
	);
}

export default ToursService;
