import React, { useMemo } from "react";
import { ReactComponent as ParagraphIcon } from "assets/images/webBuilder/paragraph.svg";
import { ReactComponent as ImageIcon } from "assets/images/webBuilder/image.svg";
import { ReactComponent as VideoIcon } from "assets/images/webBuilder/video.svg";
// import { ReactComponent as MapIcon } from "assets/images/webBuilder/map.svg";
import aboutSectionImage from "assets/images/webBuilder/about-section.png";
import containerImage from "assets/images/webBuilder/container.png";
import packagesImage from "assets/images/webBuilder/packages.png";
import faqsImage from "assets/images/webBuilder/faqs.png";
import contactFormImage from "assets/images/webBuilder/contact-form.png";
import ContentBlock from "./ContentBlock";
import {
	blockTypes,
	customContainerItems,
} from "../../../interfaces/blockTypes";
import FormElement from "./FormElement";
import { useWebBuilderState } from "context/webBuilder";

function ContentBlocks() {
	const { content } = useWebBuilderState();

	const hasAboutItem = useMemo(
		() => content && content.some((i) => i.id === "about-us-section"),
		[JSON.stringify(content)]
	);

	const hasContactUsItem = useMemo(
		() => content && content.some((i) => i.id === "contact-us-section"),
		[JSON.stringify(content)]
	
	)
	

	const {
		// customContainerMap,
		customContainerParagraph,
		customContainerPhoto,
		customContainerVideo,
	} = customContainerItems;

	const blocks = [
		{
			image: aboutSectionImage,
			title: "About Section",
			type: blockTypes.aboutUs,
			canDrag: !hasAboutItem,
			action: () => {},
		},
		{
			image: containerImage,
			title: "Container",
			type: blockTypes.customContainer,
			action: () => {},
		},
		{
			image: packagesImage,
			title: "Packages",
			type: blockTypes.packages,
			action: () => {},
		},
		{
			image: faqsImage,
			title: "FAQs",
			type: blockTypes.customContainer,
			// canDrag: !hasContactUsItem,
			isFaqs: true,
			action: () => {},
		},
		{
			image: contactFormImage,
			title: "Contact form",
			type: blockTypes.contactUs,
			canDrag: !hasContactUsItem,
			action: () => {},
		},
	];
	const formElements = [
		{ icon: ParagraphIcon, title: "Paragraph", type: customContainerParagraph },
		{ icon: ImageIcon, title: "Image", type: customContainerPhoto },
		{ icon: VideoIcon, title: "Video", type: customContainerVideo },
		// { icon: MapIcon, title: "map", type: customContainerMap },
	];

	return (
		<div className="sidebar-content-blocks">
			<div className="content-header">
				<h4>Content Block</h4>
			</div>
			<div className="content-blocks">
				{blocks.map((block) => (
					<ContentBlock {...block} />
				))}
			</div>
			<div className="content-forms">
				<p className="form-title">Insert Elements</p>
				<div className="form-details">
					{formElements.map((ele) => {
						return <FormElement {...ele} />;
					})}
				</div>
			</div>
		</div>
	);
}

export default ContentBlocks;
