import React, { useEffect, useState } from 'react'
import heroimg from 'assets/images/herosec.png'
import Locale from 'translations';
import moment from 'moment';

function HeroSection({hotelPromoList}) {
	const [BigOffer, setBigOffer] = useState()
    const { marketPlace } = Locale;
		const date = moment().format('MMM YYYY')
		useEffect(() => {
			if(hotelPromoList.length > 0){
			
			let prev = 0 
			for(let i=0;i < hotelPromoList.length;i++){
			 let discont  =	hotelPromoList[i]?.discounts[0]?.discount
			 if(prev < discont ){
				 prev = discont
			 }
			}
			setBigOffer(prev);
		}
		}, [hotelPromoList])
		
  return (
		<div className="w-100 hero-sec">
			<img src={heroimg} alt="" srcset="" />
			<div className="detlis">
				<p className="manth">{marketPlace.ourMonthly}</p>
				<h1 className="big-title">{marketPlace.bestDeals}</h1>
				<p className="save">{marketPlace.saveupto} {BigOffer}% | {date}</p>
			</div>
		</div>
	);
}

export default HeroSection