import React, { useState } from "react";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";

import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";

import { EditBox } from "modules/WebBuilder/shared/EditBox";

import ProfileIcon from "assets/images/webBuilder/profile.svg";
import SmsIcon from "assets/images/webBuilder/sms.svg";
import EditableParagraph from "modules/WebBuilder/shared/EditableParagraph";
import MobileIcon from "assets/images/webBuilder/mobile-icon.svg";

import TextField from "components/Form/TextField/TextField";
import { useLocation, useParams } from "react-router-dom";
import { sendGetInTouch } from "services/webBuilder";
import { store } from "react-notifications-component";
import UploadFileComponent from "modules/WebBuilder/shared/UploadFile";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import Locale from 'translations';

function ContactUsWithImage({ details, onFocus, focusContainer }) {
	const { selectedLanguage } = useWebBuilderState();
	const { webBuilder } = Locale;
	const { id, body, title, image } = details;
	const location = useLocation();
	const { name } = useParams();

	const preview = !location.pathname.includes("edit");
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phone: "",
		notes: "",
	});

	const dispatch = useWebBuilderDispatch();

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};

	const containerBoxActions = [
		<span
			onClick={() => deleteBodyContent({ deleteType: "container" })}
			className="pointer-event"
		>
			<TrashIcon />
		</span>,
	];

	const handleFormData = ({ name, value }) => {
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	const onSubmitForm = async (e) => {
		e.preventDefault();
		const data = {
			...formData,
			first_name: formData.name,
			message: formData.notes,
		};
		if (name) {
			const res = await sendGetInTouch(name, data);
			if (res.status === 200) {
				store.addNotification({
					message: res?.data?.message || "Email Sent Successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
				setFormData({
					name: "",
					email: "",
					phone: "",
					notes: "",
				});
			}
		}
	};

	const updateContactusContent = ({ name, value, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				language
			},
		});
	};

	const updateContactus = (value) => {
		dispatch({
			type: "updateContactus",
			payload: {
				name: "image",
				value,
				id,
			},
		});
	};
	const Image = image ? <img src={image} alt="Logo" /> : null;

	const logo = preview ? (
		Image
	) : (
		<UploadFileComponent
			icon={AddIcon}
			id={`contact-us-section`}
			label=""
			onUpload={(value) => {
				updateContactus(value);
			}}
			image={Image}
		/>
	);
	return (
		<EditBox editMode={focusContainer === id} actions={containerBoxActions}>
			<div
				onClick={() => onFocus(id)}
				className="web-builder-content-contact-us-with-image"
				id={id}
			>
				<div className="container">
					<div className="contact-us-layout">
						<div className="contact-us-data">
							<div className="content-top-section">
								<EditableParagraph
									contentEditable={true}
									onInput={(value) => updateContactusContent({ value, name: "title", language: selectedLanguage })}
									className="h4 t-title"
									text={title}
								/>

								<EditableParagraph
									contentEditable={true}
									onInput={(value) => updateContactusContent({ value, name: "body", language: selectedLanguage })}
									className="text-body t-body"
									text={body}
								/>
							</div>

							<form onSubmit={onSubmitForm} className="contact-us-form">
								<div className="contact-us-fields">
									<TextField
										hasLabel={false}
										placeholder={webBuilder.name}
										value={formData.name}
										onChange={(e) =>
											handleFormData({ name: "name", value: e.target.value })
										}
										isImage
										image={ProfileIcon}
										prependImage
										required
									/>
								</div>
								<div className="contact-us-fields">
									<TextField
										hasLabel={false}
										placeholder={webBuilder.email}
										type="email"
										value={formData.email}
										onChange={(e) =>
											handleFormData({ name: "email", value: e.target.value })
										}
										isImage
										image={SmsIcon}
										prependImage
										required
									/>
								</div>

								{/* phone  */}
								<div className="contact-us-fields">
									<TextField
										type="number"
										hasLabel={false}
										id="form-phone"
										name="form-phone"
										placeholder={webBuilder.phone}
										value={formData.phone}
										isImage
										image={MobileIcon}
										prependImage
										required
										onChange={(e) =>
											handleFormData({ name: "phone", value: e.target.value })
										}
									/>
								</div>

								<textarea
									rows={5}
									placeholder={webBuilder.message}
									value={formData.notes}
									onChange={(e) =>
										handleFormData({ name: "notes", value: e.target.value })
									}
									required
									className="textarea-field"
								/>

								<button
									type={preview ? "submit" : "button"}
									className="contact-us-submit-button"
								>
									{webBuilder.submit}
								</button>
							</form>
						</div>
						<div className="contact-us-image">{logo}</div>
					</div>
				</div>
			</div>
		</EditBox>
	);
}

export default ContactUsWithImage;
