import arrowTwoDirectionsSrc from "assets/images/inventory/arrow-two-directions.svg";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useState } from "react";
import Locale from "translations";
import CancelModel from "./CancelModel";

export default function TransferDetailsCard({
	Details,
	allData,
	printVoucher,
	setTransferDetails,
}) {
	const { inventory, marketPlace } = Locale;

	const [modalCancel, setModalCancel] = useState(false);
	const toggleModalCancel = () => setModalCancel(!modalCancel);

	const confirmedStatus = Details.status === "CONFIRMED";
	const canceledStatus = Details.status === "CANCELED";
	const freecancelDate = Details?.pickup_time;
	const canCancel =
		moment().isBefore(moment(freecancelDate)) && confirmedStatus;

	return (
		<>
			<div className="transfer-booking-item">
				<div className="d-flex flex-wrap">
					<img
						src={Details?.image}
						alt={`${Details?.type} ${Details?.category}	${Details?.vehicle}`}
						className="img-fluid transfer-booking-item-img"
						onError={(e) => {
							e.target.onerror = null;
							e.target.crossOrigin = null;
						}}
					/>
					<div className="d-flex flex-column mx-md-2">
						<h2 className="mb-2">
							<span className="font-weight-bold text-dark-blue h5">
								{Details?.type}{" "}
							</span>
							<span className="text-dark-blue">{Details?.category} </span>
							<span className="h6 text-uppercase text-muted">
								{Details?.vehicle}{" "}
							</span>
						</h2>
						<i class="fal fa-sort-alt"></i>

						<div className="transfer-booking-item-info">
							<i className="fas fa-map-marker-alt"></i>
							<p className="mx-1">
								<span>{Details?.from_name}</span>
								<img
									src={arrowTwoDirectionsSrc}
									alt=""
									className="img-fluid mx-1"
								/>
								<span>{Details?.to_name}</span>
							</p>
						</div>

						<div className="transfer-booking-item-info">
							<i className="far fa-calendar-alt"></i>
							<span className="mx-1 font-weight-bold">
								{`${moment(Details?.pickup_time).format(
									"DD/MM/YYYY HH:mm"
								)}  (${moment(Details?.pickup_time).format("dddd")})`}
							</span>
						</div>

						<div className="transfer-booking-item-info">
							<i className="far fa-user"></i>
							<span className="mx-1 font-weight-bold">
								{`${Details?.trip_type ==="DEPARTURE"?inventory.messages.departure:inventory.messages.arrival} , ${Details?.adults} ${marketPlace.adult} , ${Details?.children} ${marketPlace.messages.child}, ${Details?.infants} ${marketPlace.infant}`}
							</span>
						</div>
					</div>
				</div>
				<p className="font-weight-bold text-dark-blue mt-1">
					{Details?.trip_type ==="DEPARTURE"?inventory.messages.departureFlightNumber:inventory.messages.arrivalFlightNumber } {" "}
					{Details?.flight_number},{" "}
					{ Details?.flight_time &&
						`${marketPlace.flightTime} ${moment( Details?.flight_time).format("HH:mm")}`}
				</p>

				<p className="transfer-booking-item-warning mt-1">
					<i className="fas fa-exclamation-triangle"></i>
					<span className="mx-1">
						{marketPlace.transferBookingWarning}
					</span>
				</p>

				{/* cancelations info */}
				{Details?.cancellation_policies?.length > 0 ? (
					<div className="mt-3 transfer-booking-item-cancelation py-3">
						<span className="font-weight-bold h6 m-0">
							{inventory.messages.cancellationFees}
						</span>

						<div className="d-flex align-items-center flex-wrap">
							<div className="col-md-6">
								{Details?.cancellation_policies?.map((policy, index) => {
									return (
										<div className="" key={index}>
											<div className="d-flex justify-content-between text-success">
												<span>
													{inventory.viewReservation.until}{" "}
													{moment(policy?.from)
														.subtract(1, "minute")
														.format("hh:mm A")}{" "}
													{moment(policy?.from)
														.subtract(1, "day")
														.format("DD/MM/YYYY")}
												</span>
												<span className="font-weight-bold text-capitalize">
													{inventory.viewReservation.free}
												</span>
											</div>
											<div className="d-flex justify-content-between text-danger">
												<span>
													{marketPlace.after}{" "}
													{moment(policy?.from).format("hh:mm A")}{" "}
													{moment(policy?.from).format("DD/MM/YYYY")}
												</span>
												<span className="font-weight-bold text-capitalize">
													{" "}
													{policy?.amount} {policy?.currencyId}
												</span>
											</div>
										</div>
									);
								})}
							</div>

							<div className="col-md-6 text-muted">
								{marketPlace.transferTermsAndCondition}
							</div>
						</div>
					</div>
				) : null}

				<div className="d-flex justify-content-between py-2 tour-booking-item-action">
					{confirmedStatus && (
						<div
							className="text-light-warning"
							onClick={() => printVoucher(Details.id)}
						>
							<i class="fas fa-download"></i>
							<span className="mx-1">{inventory.messages.downloadVoucher}</span>
						</div>
					)}
					<ShowForPermission permission="Manage-Inventory-Transfer">
						{canCancel && (
							<div className="text-light-danger" onClick={toggleModalCancel}>
								<i class="fas fa-ban text-light-danger"></i>
								<span className="mx-1">{inventory.messages.cancelTransfer}</span>
							</div>
						)}
					</ShowForPermission>

					{canceledStatus && <h5 className="bold text-danger">{inventory.messages.cancelled}</h5>}
				</div>
			</div>
			{modalCancel && (
				<CancelModel
					details={Details}
					model={modalCancel}
					cancelType="single-item"
					toggleModel={toggleModalCancel}
					setTransferDetails={setTransferDetails}
				/>
			)}
		</>
	);
}
