import SelectField from 'components/Form/SelectField/SelectField'
import React from 'react'
import Locale from 'translations'

const SearchBarVertical = ({ product_types, countries, preview, searchState, handleSearchInputs, handleSearch }) => {
	const {commons} = Locale;
	// lookups
	return (
		<div className='search-form-web-builder search-form-vertical mt-3'>
			<div className='search-form-select'>
				<SelectField
					hasLabel={false}
					placeholder={commons.selectCountry}
					id="search-country"
					name="search-country"
					options={countries}
					value={searchState?.country?.label}
					onChange={(e) => handleSearchInputs(e, "country")}
				/>
			</div>

			<div className='search-form-select'>
				<SelectField
					hasLabel={false}
					placeholder={commons.packageType}
					id="search-package-type"
					name="search-package-type"
					options={product_types}
					value={searchState.proudctType?.label}
					onChange={(e) => handleSearchInputs(e, "proudctType")}
				/>
			</div>

			{/* <div className='grid-full-row'> */}
				<button className='btn search-form-button'
					onClick={()=> preview && handleSearch()}
				>
					{commons.search}
				</button>
			{/* </div> */}
		</div>
	)
}

export default SearchBarVertical
