// Reacrt Bootstrab
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
// Translation
import Locale from 'translations';
// Assets
import { ReactComponent as CallIcon } from "assets/images/market-place/call.svg";
import { ReactComponent as WhatsAppIcon } from "assets/images/market-place/whatsapp.svg";
// -----------------------------------------------------------------------------------------------------------

const ReservationTeamModal = ({ isOpen, toggleModal }) => {

  const { inventory } = Locale;

  // ---------- JSX Code ---------
  return (
    <div className='relative'>
      <Modal className="mt-5 " isOpen={isOpen} toggle={toggleModal}>
        <ModalHeader
					className="align-items-center font-weight-bold payment-title hd-title-model text-center"
					toggle={toggleModal}
				>
				</ModalHeader>
        <ModalBody className='p-4' style={{ borderRadius: '12px !important' }}>
					{/* {inventory.messages.ChoosePaymentMethod} */}
          {/* <span
            style={{
              position: 'absolute',
              right: '15px',
              top: '15px',
              cursor: 'pointer',
            }}
            onClick={toggleModal}
          >
            <CloseIcon />
          </span> */}

          <div className='py-2 d-flex justify-content-start mb-2'>
            <CallIcon />
          </div>

          {/* Reservation caption */}
          <div style={{ marginBottom: '32px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

            <h2 style={{ fontSize: '18px', fontWeight: '700', marginBottom: '8px', color: '#112233' }}>
              {inventory?.contact_the_reservation_team}
            </h2>

            <p style={{ fontSize: '16px', fontWeight: '400', marginBottom: '8px', color: '#667085' }}>
              {inventory?.reservation_message}
            </p>

          </div>

          {/* Whats app Button */}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <a
              style={{
                background: '#D4A655',
                display: 'flex',
                width: '175px',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '14px 26px',
                height: '48px',
                borderRadius: '8px',
                gap: "6px",
                color: '#fff',
                fontWeight: '600'
              }}
              href="https://api.whatsapp.com/send?phone=201017555338"
            >
              <WhatsAppIcon />
              {inventory?.contact_us}
            </a >
          </div>

        </ModalBody>
      </Modal>
    </div>
  )
}

export default ReservationTeamModal