import { formatTripPlannerData, successfulResponse } from 'modules/TripPlanner/helpers/formatTripPlannerData';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
  addNewAttractionSegemet,
  addNewFlightSegemet,
  addNewHotelSegemet,
  addNewTransportationSegemet,
  deleteSegemet,
  updateAttractionSegemet,
  updateFlightSegemet,
  updateHotelSegemet,
  updateTransportationSegemet
} from 'services/tripPlanner';

export default function useItinerary({ setTripPlannerData, selectedDay, tripPlannerData }) {
  const { id } = useParams();

  const canEdit = !tripPlannerData?.trips || tripPlannerData?.trips?.length === 0

  // call hotel api
  async function saveHotelItem(hotelData) {
    let hotelRes;
    let isUpdatingItem = hotelData?.id || null;
    let messsage = isUpdatingItem ? "Segemet updated successfully" : "Segemet added successfully"
    // send api request
    let data = {
      hotel_id: hotelData?.hotel_name?.id,
      country_id: hotelData?.country?.id,
      city_id: hotelData?.city?.id,
      nights: +hotelData?.nights,
      meal_type: hotelData?.meal_plan?.name,
      notes: hotelData?.notes,
      description: hotelData?.description,
      day: hotelData?.day ? hotelData?.day : selectedDay?.day,
      sort: hotelData?.sort
    }
    if (isUpdatingItem) {
      hotelRes = await updateHotelSegemet(id, hotelData?.id, data)
    } else {
      hotelRes = await addNewHotelSegemet(id, data);
    }
    if (hotelRes?.status === 200) {
      successfulResponse(messsage);
      const formateTripData = formatTripPlannerData(hotelRes?.data?.data);
      setTripPlannerData(formateTripData);
      // setCurrentView("view");
    }
  }

  async function saveFlightItem(flightData) {
    let isUpdatingItem = flightData?.id || null;
    let flightRes;
    let messsage = isUpdatingItem ? "Segemet updated successfully" : "Segemet added successfully"
    // send api request
    let data = {
      flight_type: flightData?.flight_type?.id,
      return_day: flightData?.return_day?.id,
      transporter_id: flightData?.carrier_name?.id,
      from_country_id: flightData?.from_country?.id,
      from_port_id: flightData?.from_airport?.id,
      to_country_id: flightData?.to_country?.id,
      to_port_id: flightData?.to_airport?.id,
      day: selectedDay.day,
      sort: flightData?.sort
    }
    if (isUpdatingItem) {
      flightRes = await updateFlightSegemet(id, flightData?.id, data);
    } else {
      flightRes = await addNewFlightSegemet(id, data)
    }
    if (flightRes?.status === 200) {
      successfulResponse(messsage);
      const formateTripData = formatTripPlannerData(flightRes?.data?.data);
      setTripPlannerData(formateTripData);
    }
  }


  async function saveAttractionItem(attractionData) {
    let isUpdatingItem = attractionData?.id || null;
    let attractionRes;
    let messsage = isUpdatingItem ? "Segemet updated successfully" : "Segemet added successfully"
    // send api request
    let data = {
      name: attractionData?.name,
      country_id: attractionData?.country?.id,
      city_id: attractionData?.city?.id,
      area_id: attractionData?.area?.id,
      photos: attractionData?.photos,
      duration: attractionData?.duration,
      pickup: attractionData?.pickup,
      drop_off: attractionData?.drop_off,
      services: attractionData?.services?.filter(service => service?.length > 0),
      terms: attractionData?.terms,
      policy: attractionData?.policy,
      notes: attractionData?.notes,
      description: attractionData?.description,
      day: selectedDay?.day,
      sort: attractionData?.sort
    }
    if (isUpdatingItem) {
      // res will call update api
      attractionRes = await updateAttractionSegemet(id, attractionData?.id, data);
    } else {
      // res will call add api
      attractionRes = await addNewAttractionSegemet(id, data);
    }
    if (attractionRes?.status === 200) {
      successfulResponse(messsage);
      const formateTripData = formatTripPlannerData(attractionRes?.data?.data);
      setTripPlannerData(formateTripData);
    }
  }

  async function saveTransportationItem(transportation) {
    let isUpdatingItem = transportation?.id || null;
    let res;
    let messsage = isUpdatingItem ? "Segemet updated successfully" : "Segemet added successfully"
    // send api request
    let data = {
      option: transportation?.option,
      from_item_id: transportation?.from?.id || null,
      to_item_id: transportation?.to?.id || null,
      pickup_point: transportation?.pickup_point,
      drop_off_point: transportation?.drop_off_point,
      pickup_time: transportation?.pickup_time ? moment(transportation?.pickup_time).format("HH:mm") : null,
      drop_off_time: transportation?.drop_off_time ? moment(transportation?.drop_off_time).format("HH:mm") : null,
      from_city_id: transportation?.from_city?.id,
      to_city_id: transportation?.to_city?.id,
      from_area_id: transportation?.from_area?.id,
      to_area_id: transportation?.to_area?.id,
      vehicle_type_id: transportation?.vehicle_type?.id,
      vehicle_model: transportation?.vehicle_model?.id,
      vehicle_count: transportation?.vehicle_count,
      vehicle_photo: transportation?.vehicle_photo,

      description: transportation?.description,
      notes: transportation?.notes,
      terms: transportation?.terms,
      day: selectedDay?.day,
      sort: transportation?.sort
    }
    if (isUpdatingItem) {
      res = await updateTransportationSegemet(id, transportation?.id, data)
    } else {
      res = await addNewTransportationSegemet(id, data);
    }
    if (res?.status === 200) {
      successfulResponse(messsage);
      const formateTripData = formatTripPlannerData(res?.data?.data);
      setTripPlannerData(formateTripData);
    }
  }


  // delete segment
  async function removeSegment(tripPlannerData, itemIndex, itemId) {
    // check if item saved in database
    if (itemId) {
      const res = await deleteSegemet(id, itemId);
      if (res?.status === 200) {
        const formateTripData = formatTripPlannerData(res?.data?.data);
        setTripPlannerData(formateTripData);
        successfulResponse("Segemet deleted successfully");
      }
    } else {
      let tripPlannerClone = { ...tripPlannerData }
      let daysClone = tripPlannerClone.itinerary_days;
      let selectedDayIndex = selectedDay?.day - 1;
      let updatedDay = daysClone[selectedDayIndex];
      updatedDay.items.splice(itemIndex, 1);
      setTripPlannerData(tripPlannerClone);
    }
  }



  return {
    saveHotelItem,
    saveFlightItem,
    saveTransportationItem,
    saveAttractionItem,
    removeSegment,
    canEdit
  }
}
