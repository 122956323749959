import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export default function usePreviewMode(){
  const location = useLocation();
  const [isPreview, setIsPreview] = useState(false);
  useEffect(() => {
		if(location.pathname.includes("preview"))
      setIsPreview(true);
    else setIsPreview(false);
	}, [location]);
  return {isPreview}
}