import RangeSlider from "components/Form/RangeSlider";
import TextField from "components/Form/TextField/TextField";
import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import Locale from "translations";
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import chevronUpIcon from "assets/images/market-place/servicesIcon/chevron_down.png";
import { useSBSState } from "context/global";
import ShowFilters from "./ShowFilters";
import ArrowDownIcon from 'modules/market-place/NewMarketplace/icons/arrowDownIcon copy';
export default function Filters({filter,setFilter,listSpecial,lookup}) {
	const { marketPlace, productsBuilder,visa } = Locale;
	const { locale,currency } = useSBSState();
	const [filtersIsOpen, setFiltersIsOpen] = useState({
		search: true,
		priceRange: true,
		rating: true,
		propertyType: true,
		mealOptions: true,
		cancellationPolicy: true,
		hotelFacilities: true,
		allFilters:true
	});
	const [showMore, setShowMore] = useState({
		propertyType: false,
		mealOptions: false,
		hotelFacilities: false,
	});

  const selectedCurrencyCode = currency;
	
	const [filterFacilities, setfilterFacilities] = useState("");
	function compareNumbers(a, b) {
		return b - a;
	}


	const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 600px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(max-width: 600px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);


	useEffect(() => {
		if (matches) {
			setFiltersIsOpen((prev) => ({ ...prev, allFilters: false }))
		}else{
			setFiltersIsOpen((prev) => ({ ...prev, allFilters: true }))
		}
		
  }, [matches]);

	return (
		
		<div className="special-filter">
			<h2 	className="font-weight-bold  mx-2">
				
		{matches ? <span onClick={() =>
							setFiltersIsOpen((prev) => ({ ...prev, allFilters: !prev.allFilters }))
						}>
		{productsBuilder.filters} 
			
			{filtersIsOpen.allFilters ? (
		<img src={chevronUpIcon} alt="" />
	) : (
		<ArrowDownIcon />
	)}
		</span> : productsBuilder.filters}
			<a href={()=>false} onClick={()=>setFilter({
    "name": "",
    "rating": null,
    "is_refundable": null,
    "facilities": [],
    "price":{
        "min": Math.floor(lookup?.price?.min),
        "max": Math.floor(lookup?.price?.max)
    }
  })} className='float-right text-underline h6 mt-1'>{visa.resetFilter}</a> 
	
	
		</h2>
			

	<Collapse isOpen={filtersIsOpen.allFilters}>
			<div className="filters">
				{/** Search */}
				<div className="rounded mt-2">
					<h5
						className=" mx-2"
						onClick={() =>
							setFiltersIsOpen((prev) => ({ ...prev, search: !prev.search }))
						}
					>
						{marketPlace.messages.search}
						<span className="float-right">
							{filtersIsOpen.search ? (
								<img src={chevronUpIcon} alt="" />
							) : (
								<ArrowDownIcon />
							)}
						</span>
					</h5>
					<Collapse isOpen={filtersIsOpen.search}>
						<div className="col-md-12 mt-3">
							<TextField
								labelInner={true}
								isImage={true}
								image={SeachIcon}
								prependImage={true}
								onChange={(e) => {
									
									setFilter({
										...filter,
										name: e.target.value,
									});
								}}
								value={filter?.name}

								type="text"
								hasLabel={false}
								placeholder={marketPlace.messages.search}
							/>{" "}
						</div>
					</Collapse>
				</div>

				{lookup?.price?.min&&lookup?.price?.max&&listSpecial&&listSpecial.length>1 ?	<><hr className="mx-2" />
				<div className="rounded mt-2">
					<h5
						className=" mx-2"
						onClick={() =>
							setFiltersIsOpen((prev) => ({
								...prev,
								priceRange: !prev.priceRange,
							}))
						}
					>
						{marketPlace.messages.priceRange}
						<span className="float-right">

							{filtersIsOpen.priceRange ? (
								<img src={chevronUpIcon} alt="" />
							) : (
								<ArrowDownIcon />
							)}
						</span>
					</h5>
					<Collapse isOpen={filtersIsOpen.priceRange}>
						<div className="col-md-12 mt-4 pt-1">
							<RangeSlider
							labels={[Math.floor(lookup?.price?.min-1)+" "+selectedCurrencyCode, Math.floor(lookup?.price?.max+1)+" "+selectedCurrencyCode]}
								min={Math.floor(lookup?.price?.min-1)}
								max={Math.floor(lookup?.price?.max+1)}
								lab
								step="10"
								onChange={(e)=>{			
									 if (filter?.price?.min!==e.minValue||filter?.price?.max!==e.maxValue) {
										setFilter({...filter,price:{min:e.minValue,max:e.maxValue}});

									}		

								}}
				
								maxValue={Math.floor(filter?.price?.max?  filter?.price?.max:lookup?.price?.max)}
								minValue={Math.floor(filter?.price?.min?  filter?.price?.min:lookup?.price?.min)}
								ruler={false}
							/>
						</div>
					</Collapse>
				</div></>	 : null}
				<hr className="mx-2" />
				{/**Rating */}
				<div className="starts rounded mt-2">
					<h5
						className="mx-2 "
						role="button"
						onClick={() =>
							setFiltersIsOpen((prev) => ({ ...prev, rating: !prev.rating }))
						}
					>
						{productsBuilder.rating}

						<span className="float-right">
							{filtersIsOpen.rating ? (
								<img src={chevronUpIcon} alt="" />
							) : (
								<ArrowDownIcon />
							)}
						</span>
					</h5>
					<Collapse isOpen={filtersIsOpen.rating}>
						<ul className="list-unstyled mt-3 mx-2">
							{[1, 2, 3, 4, 5].sort(compareNumbers).map((el) => {
								return (
									<li key={el}>
										<div className="airlines-checkbox">
											<div className="custom-control custom-checkbox ">
												<input
													className="custom-control-input permChecks"
													id={`classification-0${el}`}
													name="rating"
													type="checkbox"
													value={el}
													checked={filter?.rating &&filter?.rating?.length > 0?filter.rating.includes(el):false}
													onChange={(e)=>{
														
														let rate= filter?.rating&&filter?.rating?.length>0?filter.rating: []
																if (e.target.checked) {
																	rate.push(el)
																}else{
																	rate=rate.filter(res=>res!==el)
																}
																
																setFilter({...filter,rating:rate})

													}}
												/>
												<label
													className="custom-control-label"
													htmlFor={`classification-0${el}`}
												>
													{[1, 2, 3, 4, 5].map((_, i) => {
														return el <= i ? (
															<i key={i} className="far fa-star solid-star"></i>
														) : (
															<i key={i} className="fas fa-star "></i>
														);
													})}
												</label>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					</Collapse>
				</div>
				{/* <hr className="mx-2" /> */}
				{/* proprety type */}
				{/* <div className=" rounded mt-2">
					<h5
						className="mx-2 "
						role="button"
						onClick={() =>
							setFiltersIsOpen((prev) => ({
								...prev,
								propertyType: !prev.propertyType,
							}))
						}
					>
						{marketPlace.propertyType}

						<span className="float-right">
							{filtersIsOpen.propertyType ? (
								<img src={chevronUpIcon} alt="" />
							) : (
								<ArrowDownIcon />
							)}
						</span>
					</h5>
					<Collapse isOpen={filtersIsOpen.propertyType} className="mx-2">
						<div className="mt-3">
							<TextField
								labelInner={true}
								isImage={true}
								image={SeachIcon}
								prependImage={true}
								type="text"
								hasLabel={false}
								placeholder={marketPlace.messages.search}
							/>
						</div>

						<ul className="list-unstyled mt-3 list-with-height1">
							<ShowFilters
								data={property}
								showMore={showMore}
								setShowMore={setShowMore}
								filterType="propertyType"
							/>
						</ul>
					</Collapse>
				</div> */}
				{/* <hr className="mx-2" /> */}
				{/* Meal Option*/}
				{/* <div className=" rounded mt-2">
					<h5
						className="mx-2 "
						role="button"
						onClick={() =>
							setFiltersIsOpen((prev) => ({
								...prev,
								mealOptions: !prev.mealOptions,
							}))
						}
					>
						{marketPlace.messages.mealOptions}

						<span className="float-right">
							{filtersIsOpen.mealOptions ? (
								<img src={chevronUpIcon} alt="" />
							) : (
								<ArrowDownIcon />
							)}
						</span>
					</h5>
					<Collapse isOpen={filtersIsOpen.mealOptions} className="mx-2">
						<div className="mt-3">
							<TextField
								labelInner={true}
								isImage={true}
								image={SeachIcon}
								prependImage={true}
								type="text"
								hasLabel={false}
								placeholder={marketPlace.messages.search}
							/>
						</div>

						<ul className="list-unstyled mt-3 list-with-height1">
							<ShowFilters
								data={property}
								showMore={showMore}
								setShowMore={setShowMore}
								filterType="mealOptions"
							/>
						</ul>
					</Collapse>
				</div> */}
				<hr className="mx-2" />
				{/* Cancellation Policy */}
				<div className="rounded mt-2">
					<h5
						className="mx-2  "
						onClick={() =>
							setFiltersIsOpen((prev) => ({
								...prev,
								cancellationPolicy: !prev.cancellationPolicy,
							}))
						}
					>
						{marketPlace.messages.cancellationPolicy}
						<span className="float-right">
							{filtersIsOpen.cancellationPolicy ? (
								<img src={chevronUpIcon} alt="" />
							) : (
								<ArrowDownIcon />
							)}
						</span>
					</h5>
					<Collapse isOpen={filtersIsOpen.cancellationPolicy} className="mx-2">
						<ul className="list-unstyled mt-3">
							<li>
								<div className="airlines-checkbox">
									<div className="custom-control custom-checkbox ">
										<input
											className="custom-control-input permChecks"
											id={`isRefundable`}
											name="isRefundable"
											type="checkbox"
											checked={filter.is_refundable}
											value="1"
											onChange={(e)=>{
												setFilter({...filter,is_refundable:e.target.checked? 1:0})
											}}
										/>
										<label
											className="custom-control-label"
											htmlFor={`isRefundable`}
										>
											{marketPlace.messages.isRefundable}
										</label>
									</div>
								</div>
							</li>
						</ul>
					</Collapse>
				</div>
				<hr className="mx-2" />
				{/* Hotel Facilities  */}
				{lookup.facilities&&lookup.facilities.length>0 ?	<div className=" rounded mt-2">
					<h5
						className="mx-2 "
						role="button"
						onClick={() =>
							setFiltersIsOpen((prev) => ({
								...prev,
								hotelFacilities: !prev.hotelFacilities,
							}))
						}
					>
						{marketPlace.messages.hotelFacilities}

						<span className="float-right">
							{filtersIsOpen.hotelFacilities ? (
								<img src={chevronUpIcon} alt="" />
							) : (
								<ArrowDownIcon />
							)}
						</span>
					</h5>
					<Collapse isOpen={filtersIsOpen.hotelFacilities} className="mx-2">
						<div className="mt-3">
							<TextField
								labelInner={true}
								isImage={true}
								image={SeachIcon}
								prependImage={true}
								onChange={(e)=>{
									
									setfilterFacilities(e.target.value)
								}}
								type="text"
								value={filterFacilities}
								hasLabel={false}
								placeholder={marketPlace.messages.search}
							/>
						</div>

						<ul className="list-unstyled mt-3 list-with-height1">
							<ShowFilters
								data={filterFacilities!==""?[...lookup.facilities.filter(res=>res.toLowerCase().includes(filterFacilities.toLowerCase()))]:lookup.facilities}
								showMore={showMore}
								setShowMore={setShowMore}
								filterType="hotelFacilities"
								setFilter={setFilter}
								filter={filter}
							/>
						</ul>
					</Collapse>
				</div> : null}
			</div>
	</Collapse>

		</div>

	
	);
}
