import { useCountUp } from "react-countup";
import Locale from "translations";

export default function Card({ cardImg, count, booking, name, styleClass }) {
	const { statistics } = Locale;

	const { countUp } = useCountUp({
		end: count,
		duration: 0.8,
	});

	return (
		<>
			<div
				className={`col-md-4 ${
					styleClass === "transportation" ? "light-opacity" : ""
				}`}
			>
				<div
					className={`${styleClass} d-flex justify-content-between align-items-center rounded shadow-sm p-4 bg-white`}
				>
					<div className="desc">
						<h6 className="text-subtitle">{name}</h6>
						<div className="pt-3">
							<p className="count">
								{countUp}
								<span>{statistics.sar}</span>
							</p>
							<p className="booking-text">
								{booking} {statistics.booking}
							</p>
						</div>
					</div>
					<div className="img-box">
						<img src={cardImg} alt="hotelImg" />
					</div>
				</div>
			</div>
		</>
	);
}
