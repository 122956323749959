import globalSrc from "assets/images/market-place/hotel-1.jpg";
import LocationIcon from "modules/market-place/NewMarketplace/icons/location";
import CalenderSm from "modules/market-place/NewMarketplace/icons/calenderSm";
import Locale from "translations";
import MoonIcon from "modules/market-place/NewMarketplace/icons/moon";
import BedIcon from "modules/market-place/NewMarketplace/icons/bed";
import { useSBSState } from "context/global";
import moment from 'moment';
export default function HotelInfo({hotelRequest}) {
	const { locale } = useSBSState();
	const { productsBuilder } = Locale;

	return (
		<div className="info-data">
			<div className="image">
				<img src={hotelRequest?.image} alt="" />
			</div>
			<div className="data">
				<h3>{hotelRequest?.name}</h3>
				<p className="stars">
					{[...Array(+hotelRequest?.rating).keys()].map((i) => (
						<i key={i} className="fa fa-star" aria-hidden="true"></i>
					))}
					{[...Array(5 - +hotelRequest?.rating).keys()].map((i) => (
						<i className="far fa-star solid-star" key={i} aria-hidden="true"></i>
					))}
				</p>
				<p className="location">
					<LocationIcon />
					<span>{hotelRequest?.address}</span>
				</p>
				<div className="more-info">
					<div>
						<CalenderSm />
						<p>{moment(hotelRequest?.date_from).format("DD MMM y")}</p>
					</div>
					<div>
						<MoonIcon />

						{locale === "en" ? (
							<p>
								You Will Stay here <span>{moment(hotelRequest?.date_to).diff(moment(hotelRequest?.date_from), "days")}</span> nights{" "}
							</p>
						) : (
							<p>
								سوف تقيم هنا <span>{moment(hotelRequest?.date_to).diff(moment(hotelRequest?.date_from), "days")}</span> ليال
							</p>
						)}
					</div>
					<div>
						<BedIcon />
						<p>{hotelRequest?.room?.length} {productsBuilder.rooms}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
