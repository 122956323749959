import { useEffect, useState } from "react";
import { fetchActivityLogin } from "services/statistics";
import Locale from "translations";

export default function Activity() {
	const { statistics } = Locale;
	const [activities, setActivities] = useState([]);

	const fetchActivity = async () => {
		const { data } = await fetchActivityLogin();
		setActivities(data.data);
	};

	useEffect(() => {
		fetchActivity();
	}, []);

	const Activities =
		activities.length > 0 ? (
			activities.map((res, index) => {
				return (
					<div key={index}>
						<div className="date">
							<p>{res.date}</p>
						</div>
						{res.activity.map((activity) => {
							return (
								<div className="main pt-2" key={activity.id}>
									<div className="d-flex">
										<div className="time font-weight-bold">{activity.time}</div>
										<div className="px-2">
											<span className="tes">
												<i
													className={`far ${
														activity.status === "success"
															? "text-success fa-check-circle"
															: "text-danger fa-times-circle"
													}`}
												></i>
											</span>
										</div>
										<div>
											<h2 className="text-uppercase text-yellow font-weight-bold">
												{statistics.signIn}
											</h2>
											<p>{activity.address}</p>
											<p>
												{activity.browser_platform} | {activity.os_platform} |{" "}
												{activity.ip}
											</p>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				);
			})
		) : (
			<div className="text-center py-3">
				<h2 class="font-weight-bold text-subtitle text-secondary">
					{statistics.noActivities}
				</h2>
			</div>
		);

	return (
		<>
			<div className="p-2 activity">
				<div className="title">
					<h2 className="font-weight-bold text-subtitle">
						{statistics.myActivity}
					</h2>
					<p className="text">{statistics.activityText}</p>
				</div>
				{Activities}
			</div>
		</>
	);
}
