import classnames from "classnames";
import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Locale from "translations";
import Logs from "./logs";

export default function ActivityLogs({ id, currency }) {
	const {  dashboard } = Locale;
	const [activeTab, setActiveTab] = useState("1");

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	return (
		<div className="container  interest active-log-details">
			<div className="main-nav-sbs">
				<Nav tabs className="d-flex align-items-center ">
					<div className="d-flex w-100">
						<NavItem className="bg-white" style={{ margin: "1px" }}>
							<NavLink
								className={classnames({ active: activeTab === "1" })}
								onClick={() => {
									toggle("1");
								}}
							>
								<div className="product-build-list-item d-flex">
									{/* <img
										src={
											activeTab == "1" ? transactionColored : transactionGray
										}
										alt="transaction"
									/> */}
									{/* <i
										class="far fa-file-alt"
										style={{ color: "#cb9a51", fontSize: "25px" }}
									></i> */}
									<span className="text mx-1">
										{dashboard.messages.activityLogs}
									</span>
								</div>
							</NavLink>
						</NavItem>
					</div>
				</Nav>
			</div>

			<div className="tab-content product-build__tabs-content">
				<TabContent activeTab={activeTab} className="bg-white">
					<TabPane tabId="1" className="m-0">
						<Logs />
					</TabPane>
				</TabContent>
			</div>
		</div>
	);
}
