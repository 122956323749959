import React from 'react'
import { ReactComponent as EmailVerificationIcon } from "assets/images/Tds/email-verification-icon.svg";
import { ReactComponent as ApplyVisaIcon } from "assets/images/Tds/apply-for-visa-icon.svg";
import { ReactComponent as VisaPaymentIcon } from "assets/images/Tds/payment-visa-icon.svg";
import { ReactComponent as ConfirmVisaIcon } from "assets/images/Tds/confirm-visa-icon.svg";
import { ReactComponent as ArrowIcon } from "assets/images/Tds/chevron-right.svg";
import Locale from 'translations';


export default function TDSVisaStepper({ currentStep }) {
  const { tdsVisa, inventory, productsBuilder } = Locale;
  return (
    <div className='d-flex justify-content-around align-items-center flex-wrap p-md-5 p-2'>
      <div className={`${currentStep === 1 || currentStep > 1 ? "active-step stroke" : ""} stepper-item my-2 `}>
        <EmailVerificationIcon />
        <p className='mt-3'>{tdsVisa.verification}</p>
      </div>
      <ArrowIcon className='d-md-block d-none' />
      <div className={`${currentStep === 2 || currentStep > 2 ? "active-step stroke" : ""} stepper-item my-2`}>
        <ApplyVisaIcon />
        <p className='mt-3'>{tdsVisa.applyOnlineVisa}</p>
      </div>
      <ArrowIcon className='d-md-block d-none' />
      <div className={`${currentStep === 3 || currentStep > 3 ? "active-step fill" : ""} stepper-item my-2`}>
        <VisaPaymentIcon />
        <p className='mt-3'>{inventory.messages.payment}</p>
      </div>
      <ArrowIcon className='d-md-block d-none' />
      <div className={`${currentStep === 4 || currentStep > 4 ? "active-step stroke" : ""} stepper-item my-2`}>
        <ConfirmVisaIcon />
        <p className='mt-3'>{productsBuilder.confirmation}</p>
      </div>
    </div>
  )
}
