
import SelectField from 'components/Form/SelectField/SelectField';
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";

export default function GenerateSeviceModal(props) {
	const { operationStatement } = Locale;
	const {
		isOpen,
		toggle,
    option,state,setState,generateFn,
		header,
	} = props;

	return (
		<Modal
			isOpen={isOpen}
			size="lg"
			centered={true}
			hasFooter={false}
			className="operation-statement-numbers-model send-company-model"
		>
			<ModalHeader toggle={toggle}>{header}</ModalHeader>
			<ModalBody>
				<div className="container p-2 pb-4">
					<div className="">
						<SelectField
							label={operationStatement.product}
							name="product"
              options={option}

							value={state?.name}
							onChange={(e) => {
								setState(e);
							}}
						/>
					</div>
				
					<div className="d-flex justify-content-end mt-2">
						
					{/* <button
							className="btn btn-secondary px-5 text-light mx-3"
							style={{
								fontSize: "16px",
								fontWeight: 600,
							}}
							onClick={() => {
								setState();
								toggle();
								
							}}
						>
							Cancel
						</button> */}
						<button
							className="btn btn-nxt px-5 text-light"
							style={{
								fontSize: "16px",
								fontWeight: 600,
							}}
							onClick={() => {
								generateFn();
							}}
						>
							{operationStatement.generate}
						</button>
						
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
}
