import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import validate, { isFormValid } from "helpers/validate";
import TransferBookingSummary from "./BookingSummary";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { transferBooking } from "services/marketplace";
import Locale from "translations";
import TransferPayment from "../Modals/TransferPayment";
import TransferReservationDetails from "./TransferReservationDetails";
import NewPaymentAction from 'components/NewPaymentAction';

function TransferReservations() {
	const { marketPlace, onlineVisa } = Locale;
	const [paymentMethod, setPaymentMethod] = useState(false);
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [passengerType, setPassengerType] = useState("lead");
	const [errors, setErrors] = useState();
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);


  const [actionType, setActionType] = useState('');
  const [formData, setFormData] = useState({});

	const [passengersData, setPassengersData] = useState({
		leadName: "",
		leadSurname: "",
		allPassengers: [],
		countryCode: "",
		mobile: "",
		email: "",
		agencyReference: "",
	});

	const { transferCart } = useMarketplaceState();
	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK? JSON.parse(process.env.REACT_APP_OTP_CHECK):false

	const handelpaymentModal = () => {
		setActionType('')
		setFormData({})
		setPaymentMethod((prev) => !prev);
	
	};

	const checkFormErrors = () => {
		let SubmitError = {};
		let validationKeys = [
			"leadName",
			"leadSurname",
			"countryCode",
			"mobile",
			"email",
		];

		validationKeys.forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{ name: key, value: passengersData[key] },
					{ required: true, ...(key === "email" && { email: true }), min: key === "mobile" ? 9 : "", max: key === "mobile" ? 13 : "" }
				),
			};
		});

		if (passengerType === "all") {
			let allPassengersError = [];
			
			passengersData.allPassengers.forEach((passenger, index) => {
				allPassengersError[index] = {
					...validate(
						{ name: "name", value: passenger["name"] },
						{ required: true }
					),
					...validate(
						{ name: "surname", value: passenger["surname"] },
						{ required: true }
					),
				};
			});
			SubmitError.all = allPassengersError;
		}
		setErrors(() => SubmitError);
	};
	const dispatch = useMarketplaceDispatch();
	const history = useHistory();

	const submit = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const booking = async (paymentMethod,currency,otp) => {

		const handledData = {
			name: passengersData.leadName,
			surname: passengersData.leadSurname,
			phone: `+${passengersData.countryCode.value}${passengersData.mobile}`,
			email: passengersData.email,
			...(passengerType === "all" && {
				all_passengers: passengersData.allPassengers,
			}),
			...(passengersData.agencyReference && {
				agency_ref: passengersData.agencyReference,
			}),
			otp:otp,
			payment_method:paymentMethod,
			currency
		};

		const data = {
			...handledData,
		};
		const res = await transferBooking(data);

		if (res.status === 200 || res.status === 201) {
			handelpaymentModal();
			history.push("/successfullyTransferPayment", {
				reference: res?.data?.data?.reference,
			});
		

			dispatch({
				type: "transferCart",
				payload: [],
			});
		} 

			 if (res?.status === 202){
				setActionType(res?.data?.actionType)
				setFormData(res?.data?.data?.formData)
			 }
		

		if (
			res.status === 400 &&
			res.data?.custom_error_code === "TRANSFER-CRT-001"
		) {
			dispatch({
				type: "transferCart",
				payload: [],
			});
		}
	};

	useEffect(() => {
		if (!transferCart?.cart?.cartItems) {
			history.push("/transfer");
		} else {
			const pax = transferCart?.cart?.cartItems.reduce(
				(prev, cur) => prev + +cur.adults,
				0
			);

			;

			const allPassengers = Array(pax - 1)
				.fill(0)
				.map((i) => ({ name: "", surname: "" }));
			setPassengersData((prev) => ({ ...prev, allPassengers }));
		}

		return () => {};
	}, [transferCart]);

	useEffect(() => {
		const flag = errors?.all?.every((error) => {
			if (!isFormValid(error)) {
				return false;
			}
			return true;
		});

		if (!flag && passengerType === "all") {
			return;
		}

		if (isFormValid(errors)) {
			handelpaymentModal();
		}
	}, [isErrorLoaded]);

	return (
		<div className="d-flex justify-content-between flex-wrap px-md-5 mx-md-5 py-2 px-3 row container_layout">
			<TransferReservationDetails
				passengersData={passengersData}
				setPassengersData={setPassengersData}
				errors={errors}
				setErrors={setErrors}
				passengerType={passengerType}
				setPassengerType={setPassengerType}
			/>
			<TransferBookingSummary  Details={transferCart?.cart} />
			<div className="d-flex col-md-8 flex-md-row flex-column justify-content-between align-items-center mt-3">
				<div className="d-flex align-items-center">
					<input
						type="checkbox"
						id="acceptedTermsId"
						checked={acceptedTerms}
						onChange={(e) => setAcceptedTerms(e.target.checked)}
					/>
					<label role="button" htmlFor="acceptedTermsId" className="mx-2 mb-0">
						{marketPlace.messages.acceptedTerms}
					</label>
				</div>
				<button
					className={`${!acceptedTerms ? "" : "btn-success"} btn-lg`}
					onClick={submit}
					disabled={!acceptedTerms}
				>
					{onlineVisa.PROCEEDTOCHECKOUT}
				</button>
			</div>

			{paymentMethod && (
				<NewPaymentAction
				actionType={actionType}
				formData={formData}
					isOpen={paymentMethod}
					toggleModal={handelpaymentModal}
					onPayment={booking}
					canPayLater={transferCart?.cart?.canPayLater}
					currency={transferCart?.cart?.provider_currency}
					secondCurrency={transferCart?.cart?.currency}
					Price={transferCart?.cart?.provider_price}
					secondPrice={transferCart?.cart?.price}
					setActionType={setActionType}
					setFormData={setFormData}
					OTP={OTP_CHECK}
				/>
			)}
		</div>
	);
}

export default TransferReservations;
