import React, { useEffect, useState } from "react";
import axios from "axios";

import { Table } from "reactstrap";
import AdminBankTransferPagination from "./AdminBankTransferPagination";

export default function AdminBankTransferTable(props) {
	const [TeamManageList, setTeamManageList] = useState([]);

	useEffect(() => {
		axios.get("../bankTransferAdmin.json").then((res) => {
			setTeamManageList(res.data.teamManageList);
		});
	}, []);

	const removeItem = (index) => {
		setTeamManageList(TeamManageList.filter((k, i) => i !== index));
	};

	const memberStatus = (status) => {
		if (status === "Active") {
			return "Active";
		} else {
			return "Deactive";
		}
	};

	const memberCheck = (status) => {
		if (status === 0) {
			return "fa-stop text-danger";
		} else {
			return "fa-play text-success";
		}
	};

	//Edit New Member
	// const { classNameEdit } = props;
	const [modalEdit, setModalEdit] = useState(false);
	const toggleEdit = () => setModalEdit(!modalEdit);

	const AllProducts = TeamManageList.map((teamManageList, index) => {
		return (
			<tr key={teamManageList.id}>
				<th scope="row">{teamManageList.id}</th>
				<td>{teamManageList.bankName}</td>
				<td>{teamManageList.selectedCountries}</td>
				<td>
					<p className={`${memberStatus(teamManageList.status)} member-status`}>
						{memberStatus(teamManageList.status)}
					</p>
				</td>
				<td className="actions">
					<i
						className="fas fa-edit fa-fw text-primary"
						onClick={toggleEdit}
					></i>
					<i
						className={`fas fa-fw ${memberCheck(teamManageList.checkICon)}`}
					></i>
					<i
						className="fas fa-trash-alt fa-fw text-danger"
						onClick={() => removeItem(index)}
					></i>
				</td>
			</tr>
		);
	});
	return (
		<>
			<div className="product-builder-list">
				<div className="product-header-list p-0">
					<h6 className="font-weight-bold dark-blue-color">Bank Transfer</h6>
					<div className="product-buttons">
						<span>Show</span>
						<select className="py-1 px-3 mx-2">
							<option>10</option>
							<option>15</option>
							<option>25</option>
						</select>
						<span>entries</span>
					</div>
				</div>

				<Table striped className="custom-table">
					<thead>
						<tr>
							<th>#</th>
							<th>Bank Name</th>
							<th>Selected Countries</th>
							<th>Status</th>
							<th width="11%">Tools</th>
						</tr>
					</thead>
					<tbody>{AllProducts}</tbody>
				</Table>

				<AdminBankTransferPagination />
			</div>
		</>
	);
}
