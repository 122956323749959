import React, { useCallback, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ReactComponent as VerifyEmailIcon } from "assets/images/Tds/verify-email.svg";
import { ReactComponent as ArrowRightIcon } from "assets/images/Tds/arrow-right.svg";
import OTPInput from 'react-otp-input';
import { TDSSendOTP, TDSVerifyEmailOTP } from 'services/TDSVisa';
import { store } from 'react-notifications-component';
import Locale from 'translations';




export default function VerifyEmailModal({ isOpen, toggle, visaData, setVisaData }) {
  const { marketPlace, tdsVisa, inventory, login } = Locale;
  const timeoutValue = 60;
  const [otpCode, setOtpCode] = useState("");
  const [countDown, setCountDown] = useState(timeoutValue);
  const [resendOtpBtnDisabled, setResendOtpBtnDisabled] = useState(false);
  let countDownTimeOut;
  const currentEmail = visaData?.email;

  async function handleVerifyOtpCode() {
    const data = {
      email: currentEmail,
      code: otpCode
    }
    const otpRes = await TDSVerifyEmailOTP(data);
    if (otpRes.status === 200) {
      store.addNotification({
        message: otpRes.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      setVisaData({ ...visaData, isEmailVerified: true })
      toggle()
    }
  }

  async function handleResendOtp() {
    const res = await TDSSendOTP({ email: currentEmail, source: "tds" });
    if (res?.status === 200) {
      setResendOtpBtnDisabled(true);
      store.addNotification({
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      handleCountDown();
    } else {
      setResendOtpBtnDisabled(false);
    }
  }

  // count down function
  const handleCountDown = useCallback(() => {
    countDown > 0 && setTimeout(() => setCountDown(countDown - 1), 1000);
  }, [countDown]);


  useEffect(() => {
    if (countDown > 0 && countDown < timeoutValue) {
      setResendOtpBtnDisabled(true);
      handleCountDown();
    }
    if (countDown === 0) {
      setResendOtpBtnDisabled(false);
      setCountDown(timeoutValue);
    }
    return () => {
      clearTimeout(countDownTimeOut);
    };
  }, [countDown, countDownTimeOut, handleCountDown]);

  return (
    <Modal isOpen={isOpen}>

      <ModalHeader toggle={toggle} />

      <ModalBody>
        <div className='text-center d-flex flex-column align-items-center'>
          <h4 className='font-weight-bold my-2'>{tdsVisa.verifyYourEmail}</h4>
          <VerifyEmailIcon className='my-2' />
          <p className='my-2'>
            {tdsVisa.weHaveSentVerificationCode}
            <span className='font-weight-bold'> {currentEmail} </span>
            {tdsVisa.pleaseCheckYourEmail}
          </p>
          <div className='d-flex flex-column w-100 my-2'>
            <OTPInput
              value={otpCode}
              onChange={setOtpCode}
              numInputs={6}
              renderSeparator={<span className="mx-2">-</span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={"w-25 input-OTP"}
              shouldAutoFocus={true}
            />
            <button className='tds-change-btn align-self-end p-0 my-2 shadow-none'
              disabled={resendOtpBtnDisabled}
              onClick={handleResendOtp}
            >
              {inventory.messages.resendCode} {countDown} {marketPlace.messages.second}
            </button>
          </div>

          <button className='btn align-self-end tds-generic-btn d-flex justify-content-center align-items-center shadow-none'
            disabled={otpCode.length < 6}
            onClick={handleVerifyOtpCode}
          >
            <span className='mx-1'>{login.continue}</span>
            <ArrowRightIcon />
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}
