import React, { useEffect } from 'react';
import bannerUmrah from "assets/images/market-place/marketplace-umrah-bg.jpg";
import bannerGlobal from "assets/images/market-place/marketplace-global-bg.jpg";
import { useParams, useHistory } from 'react-router-dom';
import Locale from 'translations';
import { useSBSState } from 'context/global';
import hotelIcon from "assets/images/market-place/servicesIcon/hotel-icon.svg";
import packagesIcon from "assets/images/market-place/servicesIcon/packagesIcon.png";
import mosqueIcon from "assets/images/market-place/servicesIcon/mazaratIcon.png";
import toursIcon from "assets/images/market-place/servicesIcon/tour-global.png";
import visaIcon from "assets/images/market-place/servicesIcon/visaIcon.png";
import flightIcon from "assets/images/market-place/servicesIcon/fligtIcon.png";
import busIcon from "assets/images/market-place/servicesIcon/umrah-transfer.png";
import busGlogalIcon from "assets/images/market-place/servicesIcon/global-transfer.png";
import Hotels from './Hotels';
import OnlineVisa from './OnlineVisa';
import TransferSearch from './Transfer';
import ToursSearch from './Tours';
import Flights from './FlightsForm';
import Packages from './Packages';
import SpectialAllotmentForm from './spectialAllotmentForm';

export default function NewMarketServicesSearch() {
  const { marketPlace,inventory } = Locale;
  const { allCountries } = useSBSState();
  const { status, tab } = useParams();
  const history = useHistory();

  // redirect if url has umrah
  // useEffect(() => {
  //   if (status === "umrah") {
  //     history.push("/market-place-new/umrah/hotel");
  //   }else {
  //     history.push("/market-place-new/global/hotel");
  //   }
  // }, [status])

  return (
    <div className={`search-services-wrapper`}
      style={{ backgroundImage: `url(${status === "umrah" ? bannerUmrah : bannerGlobal})` }}
    >
    
        <ul className="services-list">
     

          {/* hotels */}
          <li className={`service-item ${tab === "hotel" ? "active-item" : ""}`}
            onClick={() => history.push("hotel")}
          >
            {tab === "hotel"
              ? <img className='mr-1' src={hotelIcon} alt="hotel icon" width={23} />
              : null
            }
            <span >{marketPlace.hotels}</span>
          </li>

          {/* online visa */}
          <li className={`service-item ${tab === "visa" ? "active-item" : ""}`}
            onClick={() => history.push("visa")}
          >
            {tab === "visa"
              ? <img className='mr-1' src={visaIcon} alt="visa icon" width={23} />
              : null
            }
            <span >{marketPlace.onlineVisa}</span>
          </li>

          {/* transers */}
          <li className={`service-item ${tab === "transfer" ? "active-item" : ""}`}
            onClick={() => history.push("transfer")}
          >
            {tab === "transfer"
              ? <img className='mr-1' src={status === "umrah" ? busIcon : busGlogalIcon} alt=" Transfer Icon" width={23} />
              : null
            }
            <span >{marketPlace.transfer}</span>
          </li>

          {/* tours or mazarat */}
          <li className={`service-item ${tab === "mazarat" || tab === "tours" ? "active-item" : ""}`}
            onClick={() => history.push(status === "umrah" ? "mazarat" : "tours")}
          >
            {tab === "mazarat" || tab === "tours"
              ? <img className='mr-1' src={status === "umrah" ? mosqueIcon : toursIcon} alt="mazarat tours Icon" width={23} />
              : null
            }
            <span >{status === "umrah" ? marketPlace.mazarat : marketPlace.tours}</span>
          </li>

          {/* flights */}
          <li  className={`service-item ${tab === "flight" ? "active-item" : ""}`}
            onClick={() => history.push("flight")}
          >
            {tab === "flight"
              ? <img className='mr-1' src={flightIcon} alt="flight Icon" width={23} />
              : null
            }
            {/* <span className="Soon-span mt-3" >{inventory.messages.soon}</span> */}

            <span className='mx-2'>{marketPlace.flights}</span>
          </li>

          {/* packages */}
          <li style={{opacity: status === "global" ? ".5" : "1"}} className={`service-item  ${tab === "packages" ? "active-item" : ""}`}
          onClick={() => status === "umrah" ? history.push("packages"): null}  
          >
          {status === "global" &&
            <span className="Soon-span mt-3" >{inventory.messages.soon}</span>
          }
            {tab === "packages"
              ? <img className='mr-1' src={packagesIcon} alt="packages Icon" width={23} />
              : null
            }
            <span >{marketPlace.packages}</span>
          </li>

               {/* Special Allotment */}
               {status === "umrah" ?
            <li className={`service-item ${tab === "special-allotment" ? "active-item" : ""}`}
              onClick={() => history.push("special-allotment")}
            >
              {tab === "special-allotment"
                ? <img className='mr-1' src={hotelIcon} alt="special-allotment icon" width={23} />
                : null
              }
              <span className='mx-2'>{marketPlace.SpecialOffers}</span>
            </li>
            :
            null
          }
        </ul>
  
      {/* forms for selected tab */}
      <div className="services-form position-relative w-100">
        {tab === "hotel" ? (
          <div className="container service-form-item">
            <Hotels allCountries={allCountries} />
          </div>
        ) : tab === "visa" ? (
         <div className="col-md-10 m-auto">
           <div className="service-form-item mx-2">
            <OnlineVisa allCountries={allCountries} />
          </div>
         </div>
        ) : tab === "transfer" ? (
          <div className="container service-form-item">
            <TransferSearch />
          </div>
        ) : tab === "tours" || tab === "mazarat" ? (
          <div className="container service-form-item">
            <ToursSearch />
          </div>
        ) : tab === "flight" ? (
          <div className="container service-form-item">
            <Flights />
          </div>
        ) : tab === "packages" ? (
          <div className="container service-form-item">
            <Packages />
          </div>
        ) :tab === "special-allotment" ? (
          <div className="container service-form-item">
            <SpectialAllotmentForm />
          </div>
        ):""
      }
      </div>

    </div>
  )
}
