import React, { useEffect, useState } from 'react'
import ArrivalDeparturePricing from './ArrivalDeparturePricing'
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import moment from 'moment';
import Locale from 'translations';
import ShowForPermission from 'helpers/showForPermission';

export default function AccountingArrivalDeparture({ isPricePerService, accountingStatement, setAccountingStatement, canEdit }) {
  const { operationStatement } = Locale;
  const [isPriceTableShown, setIsPriceTableShown] = useState(false);
  const tripData = accountingStatement?.trip;

  function handleInputsChanges(value, paxCount, priceKey, totalKey, isTotal) {
    let accountingStatementClone = { ...accountingStatement }
    let tripClone = { ...tripData }
    let total;
    let pricePerPax;
    if (isTotal) {
      total = +value;
      pricePerPax = +value / paxCount;
    } else {
      total = +value * paxCount;
      pricePerPax = +value;
    }
    let newPrices = {
      ...tripClone,
      pricing: {
        ...tripClone?.pricing,
        [priceKey]: pricePerPax.toString(),
        [totalKey]: total.toString(),
      }

    }
    accountingStatementClone = { ...accountingStatementClone, trip: newPrices };
    setAccountingStatement(accountingStatementClone);
  }

  function deleteArrivalPricing() {
    let accountingStatementClone = {
      ...accountingStatement,
      trip: {
        ...tripData,
        pricing: null
      }
    }
    setAccountingStatement(accountingStatementClone);
  }

  // show price table if there is price
  useEffect(() => {
    if (+tripData?.pricing?.buying_adult_price > 0 || +tripData?.pricing?.selling_adult_price > 0) {
      setIsPriceTableShown(true);
    }
  }, []);



  return (
    <div className='accounting-item py-0 mb-3'>
      {/* arrival */}
      <div>
        <h4 className='header-title mb-2'>{operationStatement.Arrivaldetails}</h4>

        <div className="content">
          <div className='d-flex flex-column flex-grow-1 gap-10'>
            <p className='d-flex align-items-center flex-wrap'>
              <span className='col-md-5 col-12 p-0 label'>{operationStatement.Arrivaldate}</span>
              <span className='col-md-7 col-12 p-0'>{tripData?.arrival_date ? moment(tripData?.arrival_date).format("DD/MM/YYYY") : "-"}</span>
            </p>
            <p className='d-flex align-items-center flex-wrap'>
              <span className='col-md-5 col-12 p-0 label'>{operationStatement.Arrivaltime}</span>
              <span className='col-md-7 col-12 p-0'>{tripData?.arrival_date ? moment(tripData?.arrival_date).format("hh:mm") : "-"}</span>
            </p>
            <p className='d-flex align-items-center flex-wrap'>
              <span className='col-md-5 col-12 p-0 label'>{operationStatement.Arrivalairport}</span>
              <span className='col-md-7 col-12 p-0'>
                {tripData?.arrival_port?.name}
                {tripData?.arrival_hall?.name ? ` - ${tripData?.arrival_hall?.name}` : ""}
              </span>
            </p>
          </div>
        </div>

      </div>
      {/* departure */}
      {tripData?.departures?.length > 0 ?
        <div className='mt-4'>
          <h4 className='header-title mb-2'>{operationStatement.Departuredetails}</h4>
          {tripData?.departures?.map(departure => {
            return (
              <div className="content my-3" key={departure?.id}>
                <div className='d-flex flex-column flex-grow-1 gap-10'>
                  <p className='d-flex align-items-center flex-wrap'>
                    <span className='col-md-5 col-12 p-0 label'>{operationStatement.Departureairport} </span>
                    <span className='col-md-7 col-12 p-0'>
                      {departure?.port?.name}
                      {departure?.hall?.name ? ` - ${departure?.hall?.name}` : ""}
                    </span>
                  </p>
                  <p className='d-flex align-items-center flex-wrap'>
                    <span className='col-md-5 col-12 p-0 label'>{operationStatement.Tripnumber}</span>
                    <span className='col-md-7 col-12 p-0'>{departure?.trip_number}</span>
                  </p>
                  <p className='d-flex align-items-center flex-wrap'>
                    <span className='col-md-5 col-12 p-0 label'>{operationStatement.Airline} </span>
                    <span className='col-md-7 col-12 p-0'>{departure?.carrier?.name}</span>
                  </p>
                </div>
              </div>
            )
          })}
        </div>
        :
        null
      }




      {isPricePerService && !isPriceTableShown && canEdit ?
        <ShowForPermission permission='Manage-operation-accounting'>
          <button className="btn-plus-operation mt-3 p-0" onClick={() => setIsPriceTableShown(true)}>
            <span>
              <i className="fas fa-plus"></i>
            </span>

            {operationStatement.AddBuyingSelling}
          </button>
        </ShowForPermission>
        :
        null
      }

      {isPricePerService && isPriceTableShown ?
        <div className='mt-4'>
          <ArrivalDeparturePricing
            handleInputsChanges={handleInputsChanges}
            accountingStatement={accountingStatement}
            canEdit={canEdit}
          />

          {canEdit &&
            <ShowForPermission permission='Manage-operation-accounting'>
              <button className="btn delete-pricing-btn"
                onClick={() => {
                  setIsPriceTableShown(false);
                  deleteArrivalPricing();
                }}
              >
                <DeleteIcon />
                <span>{operationStatement.DeleteBuyingSelling}</span>
              </button>
            </ShowForPermission>
          }
        </div>
        :
        null
      }
    </div>
  )
}
