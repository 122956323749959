import SelectField from "components/Form/SelectField/SelectField";
import { useProductsBuilderDispatch } from 'context/productsBuilder';
import React, {  useState } from "react";
import { MEALS_TYPE } from '../helpers/RoomsTypes';

function Filters({ filterRoomType, handleFilterRoomType, selectedHotel }) {
	const [mealTypes, setMealTypes] = useState("")
	const dispatch = useProductsBuilderDispatch();

	const updateAllRoomMealType = (value) => {
		setMealTypes(value)
		dispatch({
			type: "updateAllRoomMealType",
			payload: {
				meal_name: value.name,
			},
		});
	};

	return (
		<div className="hotel-accommodation-filter">
			<div className="room-type-filter">
				<div
					className={`filter-type-badge ${
						filterRoomType === "all" ? "active-filter-badge" : ""
					}`}
          role='button'
          onClick={() => handleFilterRoomType("all")}
				>
					All
				</div>
				{selectedHotel?.accommodationRooms?.length > 0
					? selectedHotel?.accommodationRooms.map((room,index,array) => (
            array[index - 1]?.room_type !== room.room_type ? <div
                role='button'
								className={`filter-type-badge ${
									filterRoomType === room.room_type ? "active-filter-badge" : ""
								}`}
								onClick={() => handleFilterRoomType(room.room_type)}
								key={room.id}
							>{`X${room.room_type}`}</div> : null
					  ))
					: null}
			</div>
			<div className="meal-type">
				<span>Meal type: </span>
				<SelectField
					class="meal-type-input"
					hasLabel={false}
					placeholder={"Select Meal"}
					options={MEALS_TYPE}
					value={mealTypes?.name}
						onChange={(e) => updateAllRoomMealType(e)}
				/>
			</div>
		</div>
	);
}

export default Filters;
