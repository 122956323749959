import React from 'react'

export default function ClearIcon() {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A4D1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.01001 14.5099C8.19001 14.8099 8.41 15.09 8.66 15.34C10.5 17.18 13.49 17.18 15.34 15.34C16.09 14.59 16.52 13.6399 16.66 12.6699" stroke="#6A4D1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.34009 11.3298C7.48009 10.3498 7.9101 9.40979 8.6601 8.65979C10.5001 6.81979 13.4901 6.81979 15.3401 8.65979C15.6001 8.91979 15.8101 9.1998 15.9901 9.4898" stroke="#6A4D1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.82007 17.1797V14.5098H10.4901" stroke="#6A4D1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.18 6.81982V9.48981H13.51" stroke="#6A4D1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>  )
}
