import React from "react";
import Locale from 'translations';

export default function CityHotelCard({
	create,
	hotelDetails,
	setSelectedHotels,
	selectedHotels,
	cityCurrency
}) {
			    const { marketPlace } = Locale;
	const maxmumHotelNumber = selectedHotels.length === 8;

	const selectHotel = () => {
		setSelectedHotels((prev) => {
			const hotelsClone = [...prev];
			const hotelIndex = hotelsClone.findIndex((i) => i.id === hotelDetails.id);
			if (hotelIndex !== -1) {
				hotelsClone.splice(hotelIndex, 1);
			} else if (hotelsClone.length < 8) {
				hotelsClone.push({...hotelDetails,currency:cityCurrency});
			}

			return hotelsClone;
		});
	};
	const isHotelSelected =
		selectedHotels.findIndex((i) => i.id === hotelDetails.id) !== -1;

	const hotelRating = +hotelDetails?.rating > 5 ? 5 : +hotelDetails?.rating;
	return (
		<div className="hotel-card" style={{backgroundImage:`url(${hotelDetails?.main_image})`}} >
			{/* <img src={hotelDetails?.main_image} alt="" /> */}
			{create &&
				(isHotelSelected ? (
					<div
						className="plus-btn"
						style={{
							backgroundColor: "lightgreen",
						}}
						onClick={selectHotel}
						role="button"
					>
						<i className="fas fa-check"></i>
					</div>
				) : (
					<div
						className={`plus-btn ${maxmumHotelNumber ? "btn-opacity" : ""}`}
						onClick={selectHotel}
						role="button"
					>
						<i className="fas fa-plus"></i>
					</div>
				))}
			<div className="details-hotel">
				<div className="row-hotel">
					<div className="text-left">
						<div style={{ fontSize: "14px" }}>
							{Array(hotelRating)
								.fill(2)
								.map((i, index) => (
									<i key={i + index + ""} className="fas fa-star"></i>
								))}
						</div>
						<p className="bolder">{hotelDetails?.name}</p>
						<p className="light">
							<i className="fas fa-map-marker-alt"></i>
							{hotelDetails?.address}
						</p>
					</div>
					<div className="text-right">
						<p className="light">{marketPlace.startingFrom} </p>
						<p className="bolder">
							{" "}
							{`${cityCurrency} ${hotelDetails.price_starting_from} / double room `}
						</p>
						<button className="btn btn-agen">{marketPlace.bookHotel}</button>
					</div>
				</div>
			</div>
		</div>
	);
}
