import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import Locale from "translations";

export default function OnlineBankingFilter(props) {
	const { paymentAdmin } = Locale;
	const history = useHistory();

	//Add New Member
	// const { className } = props;
	// const [modal, setModal] = useState(false);
	// const toggle = () => setModal(!modal);

	return (
		<>
			<div className="main-filter">
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-md-2 px-1">
						<div className="main-label">
							{/*  onChange={this.filterList} */}
							<input
								id="input-search"
								className="main-input-search"
								type="text"
								placeholder={paymentAdmin.messages.search}
								onChange={(e) => props.onSearch(e.target.value)}
								value={props.value}
							/>
							<i className="fas fa-search fa-fw"></i>
						</div>
					</div>

					<div className="col-md-1 px-1">
						<select id="product-type" className="main-input-search">
							<option checked="chacked">{paymentAdmin.messages.status}</option>
							<option>Status 2</option>
							<option>Status 3</option>
							<option>Status 4</option>
							<option>Status 5</option>
						</select>
					</div>

					<div className="col-md px-1">
						<div
							onClick={props.onReset}
							className="d-flex align-items-center text-secondary pointer"
						>
							<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
							<p className="text-caption">
								{paymentAdmin.messages.resetFilter}
							</p>
						</div>
					</div>

					<div className="product-buttons">
						<Button
							color="success"
							onClick={() => history.push("/add-new-bank")}
						>
							{paymentAdmin.messages.addNew}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}
