import axios from "axios";
import { cleanEmpty } from './general';
const baseURL = process.env.REACT_APP_API_URL + "/v1";
// tours
export const searchTours = async (data, page) => {
  return await axios
    .post(`${baseURL}/tours/search?page=${page}`, data,)
    .then((res) => res)
    .catch((err) => err.response);
};
export const showSingleTourDetails = async (data) => {
  return await axios
    .post(`${baseURL}/tours/show`, data)
    .then((res) => res)
    .catch((err) => err.response);
};
export const getCart = async (id) => {
  return await axios
    .get(`${baseURL}/tours/cart/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};
export const addTourToCart = async (data) => {
  return await axios
    .post(`${baseURL}/tours/add-to-cart`, data)
    .then((res) => res)
    .catch((err) => err.response);
};
export const deleteTourFromCart = async (data) => {
  return await axios
    .delete(`${baseURL}/tours/cart`, { params: cleanEmpty(data) })
    .then((res) => res)
    .catch((err) => err.response);
};
export const getFiltersLookup = async (cacheKey) => {
  return await axios
    .get(`${baseURL}/tours/side-filters/${cacheKey}`)
    .then((res) => res)
    .catch((err) => err.response);
};
export const bookTours = async (data) => {
  return await axios
    .post(`${baseURL}/tours/book`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getInventory = async (id) => {
  return await axios
    .get(`${baseURL}/tours/inventory/booking/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};
export const getVoucher = async (book_id, voucher_id) => {
  return await axios
    .get(`${baseURL}/tours/inventory/booking/${book_id}/reservation-voucher/${voucher_id}`)
    .then((res) => res)
    .catch((err) => err.response);
};
export const getAllToursVoucher = async (book_id) => {
  return await axios
    .get(`${baseURL}/tours/inventory/booking/${book_id}/reservation-voucher`)
    .then((res) => res)
    .catch((err) => err.response);
};




export const getToursInvoice = async (id) => {
  return await axios
    .get(`${baseURL}/tours/inventory/booking/${id}/invoice`)
    .then((res) => res)
    .catch((err) => err.response);
};


export const payLaterTour = async (id, data) => {
  return await axios
    .post(`${baseURL}/tours/inventory/booking/${id}/pay`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getPreCancelTour = async (id) => {
  return await axios
    .get(`${baseURL}/tours/inventory/booking/${id}/cancel`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const cancelTour = async (id) => {
  return await axios
    .post(`${baseURL}/tours/inventory/booking/${id}/cancel`)
    .then((res) => res)
    .catch((err) => err.response);
};
export const preCancelTourItem = async (id) => {
  return await axios
    .get(`${baseURL}/tours/inventory/reservation/${id}/cancel`)
    .then((res) => res)
    .catch((err) => err.response);
}
export const cancelTourItem = async (id) => {
  return await axios
    .post(`${baseURL}/tours/inventory/reservation/${id}/cancel`)
    .then((res) => res)
    .catch((err) => err.response);
}

export const getModifyAvailable = async (id, data) => {
  return await axios
    .get(`${baseURL}/tours/inventory/reservation/${id}/modify`, {params: cleanEmpty( data)})
    .then((res) => res)
    .catch((err) => err.response);
}
export const modifyTour = async (id, data) => {
  return await axios
    .put(`${baseURL}/tours/inventory/reservation/${id}/modify`, data)
    .then((res) => res)
    .catch((err) => err.response);
}
