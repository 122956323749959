import { useSBSState } from "context/global";
import { Link } from "react-router-dom";
export default function TableViewGroupContent({
	member,
	index,
	//handleCheckboxChange,
	viewSelect,
	setViewSelect,
	groupId,
	loader,
	loaderMofa,
	loaderActiveMofa,
	setSelectItems,
	selectItems,
	UASP,
}) {
	const { locale } = useSBSState();

	const handleCheckboxChange = (event) => {
		const name = event?.target?.name;
		if (event?.target.checked === true) {
			setViewSelect({
				...viewSelect,
				//event.target.value
				[name]: [...viewSelect[name], Number(member.id)],
				dataRow: [
					...viewSelect["dataRow"],
					[
						member.CNationality,
						member.PPNo,
						member.e_number,
						member.mofa,
					].toString(),
				],
				printData: [
					...viewSelect["printData"],
					{ visa: member.visa, enum: member.e_number },
				],
				viewMehram: [
					...viewSelect["viewMehram"],
					[member.mehrem_name].toString(),
				],
				mofa: [
					...viewSelect["mofa"],
					{ PPNo: member.PPNo, CNationality: member.CNationality },
				],
				all: [
					...viewSelect["all"],
					{
						PPNo: member.PPNo,
						CNationality: member.CNationality,
						mofa: member.mofa,
						online_id: member.online_id,
					},
				],
				sudan: [...viewSelect["sudan"], member],
			});
			setSelectItems([...selectItems, member.id]);
		} else {
			setViewSelect({
				...viewSelect,
				[name]: [...viewSelect[name].filter((e) => e !== member.id)],
			});

			let newListChecked = selectItems.filter((id) => id != member.id);
			setSelectItems(newListChecked);
		}
	};
	const gender = (status) => {
		switch (status) {
			case 1:
				return "Male";
			case 2:
				return "Female";
			default:
				return "Male";
		}
	};

	const x = viewSelect.selectItem.includes(member.id);
	return (
		<tr key={index}>
			<td>
				{" "}
				<input
					type="checkbox"
					checked={selectItems.includes(member.id)}
					name="selectItem"
					className="select-item"
					value={index}
					onChange={handleCheckboxChange}
				/>
			</td>

			<td>{member?.id}</td>
			<td>{member?.PPNo}</td>
			<td>{member?.FullName}</td>
			<td>{gender(member?.Gender)}</td>
			<td>{member?.BirthDate}</td>
			<td>{member?.mehrem_name ? member?.mehrem_name : "-"}</td>
			<td>{member?.RelationWithSponser[locale]}</td>
			<td>
				{member?.mofa !== "" || !loaderMofa ? (
					member?.mofa
				) : x ? (
					<div class="spinner-border" role="status">
						<span class="sr-only">Loading...</span>
					</div>
				) : null}
			</td>
			<td>{member?.border}</td>
			<td>
				{/* {member?.e_number} */}

				{member?.e_number !== "" || !loaderActiveMofa ? (
					member?.e_number
				) : x && member?.mofa !== "" && member?.mofa !== "-" ? (
					<div class="spinner-border" role="status">
						<span class="sr-only">Loading...</span>
					</div>
				) : null}
			</td>
			<td>
				{member?.visa !== "" || !loader ? (
					member?.visa
				) : x ? (
					<div class="spinner-border" role="status">
						<span class="sr-only">Loading...</span>
					</div>
				) : null}
			</td>
			<td className="actions">
				<Link to={`/visa/view-member/${member?.online_id}/${groupId}`}>
					<i
						className={`fas ${locale === "en" ? "fa-angle-right" : "fa-angle-left"
							}`}
						style={{ fontSize: "18px " }}
					></i>
				</Link>
			</td>
		</tr>
	);
}
