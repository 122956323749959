import React, { useState } from "react";
import { backgrounds } from "./backgrounds";
import CheckBox from "components/chekbox";
import { ReactComponent as CloseIcon } from "assets/images/product-builder/close-circle.svg";


function getUniqueListBy(arr, key) {
	return [...new Map(arr.map(item => [item[key], item])).values()]
}

const Library = ({
	handleUpload,
	slides,
	handleSliderImages,
	toggle,
	hasSlider
}) => {


	const currentSlides = slides.map(slide => {
		if (slide?.src) {
			return slide
		} else {
			return { src: slide, id: slide, source: "upload", selected: true }
		}
	});

	const [allSlides, setAllSlides] = useState(getUniqueListBy([...backgrounds, ...currentSlides], "id"));


	console.log("sadasdasdasd", allSlides);

	function handleSelectSlide(imageObj, checked, index) {
		let allSlidesClone = [...allSlides]
		allSlidesClone[index] = { ...imageObj, selected: !checked }
		setAllSlides(allSlidesClone)
	}
	function handleRemoveSlide(imageId) {
		setAllSlides((prev) => prev.filter(slide => slide.id !== imageId))
	}

	// const changeBackground = (uuid) => {
	// 	if (hasSlider) {
	// 		setSlider((oldArray) => [...oldArray, uuid]);
	// 		console.log({ slider });
	// 	} else handleUpload(uuid);
	// };

	const saveBackgrounds = () => {
		handleSliderImages(allSlides.filter(slide => slide?.selected));
		toggle();
	};

	return (
		<div className="web-builder-library">
			<div className="web-builder-library-images">
				{allSlides?.map((image, idx) => {
					// const isChecked = allSlides.findIndex(slide => slide.id === image.id) > -1;
					const isChecked = image?.selected;
					console.log(isChecked);
					if (hasSlider) {
						return (
							<div key={`${image?.id}-${idx}`} className="form-check form-check-modal">
								<CheckBox
									key={idx}
									name="permissions"
									checked={isChecked}
									value={image?.id}
									text={
										<img
											src={image?.src}
											alt="background"
											width="100%"
											className={`gallery-img ${isChecked ? "b-multiSelect-image" : ""}`}
										/>
									}
									onChange={() => handleSelectSlide(image, isChecked, idx)}
								/>
								{image.source === "upload" &&
									<button className="btn p-0 delete-slide-btn"
										onClick={() => handleRemoveSlide(image.id)}
									>
										<CloseIcon />
									</button>
								}
							</div>
						)
					}
					return (
						<div key={`${image?.id}-${idx}`} className="form-check form-check-modal pointer"
							onClick={() => {
								handleUpload(image)
							}}
						>
							<img
								src={image?.src}
								alt="background"
								width="100%"
								className={`gallery-img`}
							/>
						</div>
					)
				})
				}
			</div>

			{hasSlider && (
				<div className="d-flex justify-content-end p-1 my-3">
					<button
						onClick={saveBackgrounds}
						className="btn btn-block w-25 p-1"
						style={{
							background: "#292d32",
							color: "#FFF",
							fontSize: "1rem",
						}}
						disabled={allSlides.filter(slide => slide.selected)?.length <= 1}
					>
						Save
					</button>
				</div>
			)}
		</div>
	);
};

export default Library;
