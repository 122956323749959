import axios from "axios";
const interestsURL = process.env.REACT_APP_API_URL;

export const hotelPromo = async () => {
	return await axios
		.get(`${interestsURL}/v1/hotel-promo`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const downloadAllOffers = async () => {
	return await axios
		.get(`${interestsURL}/v1/hotel-promo/download-offers`,{
			responseType: "arraybuffer",
			headers:{
				Accept:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			}
		})
		.then((res) => res)
		.catch((err) => err.response);
};
