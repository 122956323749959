import React, { useCallback, useEffect, useRef, useState } from "react";
import SearchResultData from "./searchResultData";
import SearchFilter from "./filter";
import { useWebBuilderState } from "context/webBuilder";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { handleSearch } from "modules/WebBuilder-V2/helpers/handleSearch";
import { ReactComponent as FilterSearchIcon } from "assets/images/webBuilder/filter-search.svg";

export default function SeachResult() {
	const { packagesResult, content, pagesData } = useWebBuilderState();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const country = searchParams.get("country");
	const productType = searchParams.get("type");
	const initalResultData = useRef();
	// const filterRef = useRef(null);
	// states
	const [resultData, setResultData] = useState(null);
	const [initalPrices, setInitalPrices] = useState({ min: 0, max: 0 });
	const [filter, setFilter] = useState({
		packageType: [],
		fromPriceRange: 0,
		toPriceRange: 0,
		starRating: [],
		sortBy: null
	});

	// const visibleServices = ourServices
	// 	? ourServices?.services
	// 		?.filter((service) => service?.visible)
	// 		?.map((service) => ({
	// 			id: service?.type,
	// 			name: service?.type,
	// 		}))
	// 	: [];

	// const product_types = [
	// 	{ id: "umrah", name: "Umrah" },
	// 	{ id: "umrah-plus", name: "Umrah-plus" },
	// 	{ id: "tourism", name: "Tourism" },
	// 	...visibleServices,
	// ];
	// search with params
	const getSearchResults = useCallback(() => {
		let searchResults = [];
		// check there is search result in context
		if (packagesResult) {
			searchResults = packagesResult;
		}
		searchResults = handleSearch(country, productType, content, pagesData)?.sort((a, b) => a.price - b.price);
		let maxPrice = Math.max(...searchResults?.map((result) => result?.price));
		setInitalPrices({ min: 0, max: maxPrice });
		setResultData(searchResults);
		initalResultData.current = searchResults;
	}, [content, country, packagesResult, productType]);

	useEffect(() => {
		getSearchResults();
	}, [content, getSearchResults, packagesResult]);

	function resetFilters() {
		setFilter({
			packageType: [],
			fromPriceRange: 0,
			toPriceRange: initalPrices.max,
			starRating: [],
			sortBy: null
		});
	}
	// filter by prices
	useEffect(() => {
		// (proudctType === "hotels" || proudctType === "packages") &&filter.starRating.length > 0
		if (initalResultData.current?.length > 0 && filter.toPriceRange > 0) {
			let filteredPrices = initalResultData.current?.filter((item) => {
				return item.price >= filter?.fromPriceRange && item?.price <= filter.toPriceRange 
					// productType.includes("tour") ? +item?.stars === 0 : +item?.stars > 0
			});
			setResultData(filteredPrices);
		} else {
			setResultData(initalResultData.current);
		}
	}, [
		filter?.fromPriceRange,
		filter.starRating,
		filter.toPriceRange,
		productType,
	]);

	//filter by stars
	useEffect(() => {
		//(proudctType === "hotels" || proudctType === "packages") &&
		if (filter.starRating.length > 0 && !productType.includes("tour")) {
			let filteredRate = initalResultData.current?.filter(
				(item) =>
					item?.stars > 0 &&
					filter?.starRating?.includes(item?.stars?.toString())
			);
			if (filter.starRating.length === 0) {
				setResultData(initalResultData.current);
				return;
			}
			setResultData(filteredRate);
		} else {
			setResultData(initalResultData.current);
		}
	}, [filter?.starRating, productType]);
	//filter by package type
	useEffect(() => {
		if (filter.packageType.length > 0) {
			let filteredRate = initalResultData.current?.filter((item) =>
				filter.packageType.includes(item?.product_type.name)
			);

			setResultData(filteredRate);
		} else {
			setResultData(initalResultData.current);
		}
	}, [filter?.packageType, productType]);


	function handleSort(sortType) {
		// debugger
		if (sortType) {
			let sortedPrice;
			if (sortType === "asc") {
				sortedPrice = [...initalResultData.current]?.sort((a, b) => a.price - b.price);
			} else {
				sortedPrice = [...initalResultData.current]?.sort((a, b) => b.price - a.price);
			}
			setResultData(sortedPrice);
		} else {
			setResultData(initalResultData.current);
		}
	}

	function toggleOpenFilter() {
		const filterEle =  document.getElementsByClassName('search-filter')?.[0];
		if(filterEle) {
			filterEle.classList.contains('opened') ? filterEle.classList.remove('opened') : filterEle.classList.add('opened')
		}
	}




	return (
		<div className="all-search-result">
			<div className="row">
				<div className="col-md-3">
					<SearchFilter
						filter={filter}
						setFilter={setFilter}
						initalPrices={initalPrices}
						length={resultData?.length}
						resetFilters={resetFilters}
						// product_types={product_types}
						productType={productType}
						toggleOpenFilter={toggleOpenFilter}
					/>
				</div>
				<div className="col-md-9">
					<SearchResultData
						resultData={resultData}
						initalPrices={initalPrices}
						setInitalPrices={setInitalPrices}
						productType={productType}
						filter={filter}
						setFilter={setFilter}
						handleSort={handleSort}
					/>
				</div>
			</div>
			<button className="btn filter-mobile-btn shadow-none" onClick={toggleOpenFilter}>
				<FilterSearchIcon />
			</button>
		</div>
	);
}
