import React from "react";
// import cardimg from "assets/images/packagecard.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import Locale from "translations";

export default function CountryCard({ packages }) {
	// const { marketPlace } = Locale;
  const FILEUPLOAD_URL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

	return (
		<Link to={`/global/city-packages/${packages.country_id}`}>
			<div
				className="package-card"
				style={{ backgroundImage: `url(${FILEUPLOAD_URL}/${packages.image})` }}
			>
				<div className="layer-top"></div>
				<div className="package-name">
					<h1>{packages.name}</h1>

					<p className="badge-pac">{`${packages.packages} Package`}</p>
				</div>
				{/* <div className="package-price">
					<p>{marketPlace.startingFrom}</p>
					<h1>{`${packages.least_price} ${packages.currency}`}</h1>
				</div> */}
			</div>{" "}
		</Link>
	);
}
