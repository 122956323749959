import React from "react";
import WalletCurrenciesTable from "./WalletCurrenciesTable";

export default function PaymentAdmin(props) {
	return (
		<>
			<div className="payment-admin">
				<div className="text-center py-4 text-title ">
					<h2 className="text-decor text-dark">Menu</h2>
				</div>
				<WalletCurrenciesTable />
			</div>
		</>
	);
}
