import clearIcon from "assets/images/wallet/clearIcon.svg";
import correctIcon from "assets/images/wallet/correctIcon.svg";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import Locale from "translations";

export default function FilterTransaction({
	filter,
	setFilter,
	transaction_type,
	search,
}) {
	const { payment } = Locale;
	const status = [
		{ id: 0, name: `${payment.messages.add}`, title: "Add" },
		{ id: 1, name: `${payment.messages.deduct}`, title: "Deduct" },
	];

	return (
		<>
			<div className="filter-update bg-filter-gray">
				<div className="product-buttons col-md-11">
					<div className="main-filter-payment d-flex justify-contain-between">
						<div className="main-label controls-field">
							{/*  onChange={this.filterList} */}
							{/* <input
								id="input-search"
								className="main-input-search"
								type="text"
								placeholder={payment.messages.search}
								onChange={(e) =>
									setFilter({ ...filter, transaction_number: e.target.value })
								}
								value={filter.transaction_number}
							/>
							<i className="fas fa-search fa-fw"></i> */}
							<TextField
								label={payment.messages.search}
								type="text"
								placeholder={payment.messages.transactionId}
								onChange={(e) =>
									setFilter({ ...filter, transaction_number: e.target.value })
								}
								value={filter.transaction_number}
							/>
							{/* <i className="fas fa-search fa-fw"></i> */}
						</div>
						<div className="main-label mx-2 controls-field">
							<SelectField
								label={payment.messages.paymentType}
								placeholder={payment.messages.paymentType}
								options={status}
								value={filter?.transaction_type?.name}
								onChange={(e) =>
									setFilter({
										...filter,
										transaction_type: {
											id: e.value,
											name: e.label,
											title: status[e.value].title,
										},
									})
								}
							/>
						</div>
						<div className="main-label date-filter controls-field1">
							{" "}
							{/* <p>{payment.messages.date}</p> */}
							<DatePickerField
								label={"Date"}
								placeholder="DD/MM/YYY"
								date={filter.created_at}
								onChangeDate={(e) => {
									setFilter({ ...filter, created_at: e });
								}}
								isOutsideRange={(day) => {
									return false;
								}}
							/>
						</div>
						{/* <div>
							<button
								className="btn btn-link mt-3 mx-1"
								onClick={() => {
									setFilter({
										transaction_number: "",
										transaction_date: undefined,
										page: 1,
									});
								}}
							>
								<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
							</button>
						</div> */}
						<div className="filter-btn col-md-4 d-flex align-items-center justify-content-start">
							<div className="apply pointer">
								<button className="btn btn-light px-3" onClick={search}>
									{/* <i className="fas  fa-check position-static "></i> */}
									<img src={correctIcon} alt=" " /> {payment.messages.apply}
								</button>
							</div>
							<div
								className="clear text-secondary pointer"
								onClick={() => {
									setFilter({
										transaction_number: "",
										transaction_date: "",
										page: 1,
									});
									search();
								}}
							>
								<button className="btn btn-light px-3 mx-2">
									{/* <i className="fas  fa-retweet position-static px-1 text-secondary text-caption"></i> */}
									<img src={clearIcon} alt=" " /> {payment.messages.clear}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
