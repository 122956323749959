import TextField from 'components/Form/TextField/TextField'
import React from 'react'
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import { ReactComponent as ResetFilterIcon } from "assets/images/market-place/refresh-circle-icon.svg";
import { ReactComponent as SearchIcon } from "assets/images/umrah-operations/search-icon.svg";
import Locale from 'translations';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import moment from 'moment';

export default function DelegationManagementFilter({ filters, handleFiltersChanges, getDelegationsList, resetFilters }) {
  const { marketPlace, payment } = Locale;

  return (
    <div className="row mx-0 align-items-end">
      {/* search */}
      <div className="col-md-4 col-12">
        <TextField
          hasLabel={false}
          type="text"
          name="delegation-search"
          id="delegation-search"
          placeholder={Locale.movementManagement.search}
          isImage={true}
          image={SeachIcon}
          prependImage={true}
          value={filters.search}
          onChange={(e) => handleFiltersChanges({ key: "search", value: e.target.value })}
        />
      </div>
      {/* deleageation date */}
      <div className="col-md-3 col-12">
        <DatePickerField
          name="delegation-date"
          id="delegation-date"
          placeholder="DD/MM/YYYY"
          date={filters.date}
          onChangeDate={(filterDate) => handleFiltersChanges({ key: "date", value: filterDate })}
          isOutsideRange={() => false}
        />
      </div>
      {/* actions buttons */}

      <div className="col-4 d-flex mb-1">
        <button className="btn bg-nxt search-btn mt-0"
          onClick={() => {
            let formatedFitlers = { ...filters, date: filters?.date ? moment(filters?.date).format("YYYY-MM-DD") : null }
            getDelegationsList(formatedFitlers)
          }}
        >
          <SearchIcon />
          <span className='mx-2'>{marketPlace.messages.search}</span>
        </button>

        <button className="btn reset-btn mx-2 mt-0" onClick={resetFilters}>
          <ResetFilterIcon />
          <span className='mx-2'>{payment.messages.clear}</span>
        </button>
      </div>
    </div>
  )
}
