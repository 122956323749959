import { useSBSState } from 'context/global';
import { useEffect, useState } from 'react';
import Locale from 'translations';
import validate, { isFormValid } from "helpers/validate";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TextField from 'components/Form/TextField/TextField';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import moment from 'moment';
import SelectField from 'components/Form/SelectField/SelectField';
import { packageEditTravler } from 'services/inventory';
import { store } from 'react-notifications-component';
import { uploadFile } from 'services/auth';
import { ReactComponent as CloseIcon } from "assets/images/product-builder/close-circle.svg";


const fileUrl = `${process.env.REACT_APP_FILEUPLOAD_URL}/fetch`;

export function PackageTravelersModal({ isOpen, toggle, traveler, setReload }) {
  const { inventory, onlineVisa } = Locale;
  const { locale, allCountries } = useSBSState();

  const genderLookups = [
    {
      id: 1,
      name: `${locale === "en" ? "Male" : "ذكر"}`,
      code: "M",
    },
    {
      id: 2,
      name: `${locale === "en" ? "Female" : "أنثي"}`,
      code: "F"
    },
  ];

  const [travelerData, setTravelerData] = useState({
    ...traveler,
    gender: traveler?.gender ? {
      label: traveler?.gender === "M" ? genderLookups[0]?.name : traveler?.gender === "F" ? genderLookups[1]?.name : "",
      value: traveler?.gender === "M" ? genderLookups[0]?.code : traveler?.gender === "F" ? genderLookups[1]?.code : "",
      code: traveler?.gender === "M" ? genderLookups[0]?.code : traveler?.gender === "F" ? genderLookups[1]?.code : "",
    } : null,
  });
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  const keysNotRequired = ["visa_number", "visa_status", "e_number", "is_adult", "country_id", "nationality_id", "passport_photo", "personal_photo", "national_id_photo", "reservation_category"];


  function handleInputsChanges(value, keyName) {
    setTravelerData({
      ...travelerData,
      [keyName]: value,
    });
    setErrors({
      ...errors,
      ...validate(
        { name: keyName, value },
        { required: keysNotRequired.includes(keyName) ? false : true }
      ),
    });
  }

  function checkFormErrors() {
    let submitError = {};
    Object.keys(travelerData).forEach((key) => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: travelerData[key] },
          { required: keysNotRequired.includes(key) ? false : true }
        ),
      };
    });
    setErrors(submitError);
  }
  function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }
  
  async function editPackageTraveler() {
    const travelerId = travelerData?.id;
    const data = {
      first_name: travelerData?.first_name,
      last_name: travelerData?.last_name,
      gender: travelerData?.gender?.code,
      birth_date: travelerData?.birth_date ? moment(travelerData?.birth_date).format("YYYY-MM-DD") : null,
      nationality_id: `${travelerData?.nationality?.id}`,
      country_id: `${travelerData?.country?.id}`,
      passport_expiry: travelerData?.passport_expiry ? moment(travelerData?.passport_expiry).format("YYYY-MM-DD") : null,
      passport_number: travelerData?.passport_number,
      visa_number: travelerData?.visa_number,
      e_number: travelerData?.e_number,
      passport_photo: travelerData?.passport_photo,
      personal_photo: travelerData?.personal_photo,
      national_id_photo: travelerData?.national_id_photo,
    }
    const res = await packageEditTravler(travelerId, data);
    if (res.status === 200 || res.status === 201) {
      store.addNotification({
        title: "info!",
        message: "Traveler edited Successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
          pauseOnHover: true,
        },
        onRemoval: () => {
          setReload((prev) => !prev);
          toggle();
        },
      });
    }
  }

  async function uploadImagesToServer(e, key) {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", file?.name);
      formData.append("bucket", "productsBuilder");
      const response = await uploadFile(formData);
      if (response?.data?.data?.uuid) {
        setTravelerData({ ...travelerData, [key]: response.data.data.uuid })
      }
    }
  }


  useEffect(() => {
    if (isFormValid(errors)) {
      editPackageTraveler();
    }
  }, [isErrorLoaded])



  return (
    <Modal isOpen={isOpen} size='lg' className='package-traveler-modal add_edit_passenger_modal'>
      <ModalHeader toggle={toggle} className='align-items-center'>
        Edit Traveler
      </ModalHeader>
      <ModalBody>
        <div className='row mx-0'>
          {/* gender */}
          <div className="col-md-4 col-12">
            <SelectField
              label={inventory.messages.gender}
              placeholder={inventory.messages.select}
              options={genderLookups}
              value={travelerData?.gender?.label}
              name="gender"
              onChange={(e) => handleInputsChanges(e, 'gender')}
              color={errors?.gender?.required ? "danger" : ""}
              errors={errors?.gender}
            />
          </div>
          {/* first name */}
          <div className="col-md-4 col-12">
            <TextField
              label={inventory.messages.firstName}
              placeholder={inventory.messages.enterFirstName}
              type="text"
              name="first_name"
              value={travelerData.first_name}
              onChange={(e) => handleInputsChanges(e.target.value, 'first_name')}
              color={errors?.first_name?.required ? "danger" : ""}
              errors={errors?.first_name}
            />
          </div>
          {/* last name */}
          <div className="col-md-4 col-12">
            <TextField
              label={inventory.messages.lastName}
              placeholder={inventory.messages.enterLastName}
              type="text"
              name="last_name"
              value={travelerData.last_name}
              onChange={(e) => handleInputsChanges(e.target.value, 'last_name')}
              color={errors?.last_name?.required ? "danger" : ""}
              errors={errors?.last_name}
            />
          </div>
          {/* birth date */}
          <div className="col-md-4 col-12">
            <DatePickerField
              label={inventory.messages.BirthDate}
              placeholder={"DD/MM/YYYY"}
              date={travelerData?.birth_date ? moment(travelerData?.birth_date) : ""}
              name="birth_date"
              hasYears={true}
              onChangeDate={(e) => handleInputsChanges(e, "birth_date")}
              isOutsideRange={(day) => {
                return !day.isBefore(moment(), "day");
              }}
              color={errors?.birth_date?.required ? "danger" : ""}
              errors={errors?.birth_date}
              startYear={travelerData?.is_adult ? moment().subtract(120, "years") : moment().subtract(18, "years")}
              endYear={travelerData?.is_adult ? moment().subtract(18, "years") : moment().subtract(1, "years")}
              initialVisibleMonth={() =>
                !travelerData?.birth_date && travelerData?.is_adult ? moment().subtract(18, "years") :
                  !travelerData?.birth_date && !travelerData?.is_adult ? moment().subtract(1, "years") : moment(travelerData?.birth_date)
              }
              readOnly={true}
            />
          </div>
          {/* nationality */}
          <div className="col-md-4 col-12">
            <SelectField
              label={inventory.messages.nationality}
              placeholder={inventory.messages.select}
              name="nationality_id"
              value={travelerData.nationality?.name}
              options={allCountries}
              onChange={(e) => handleInputsChanges(e, "nationality")}
              color={errors?.nationality?.required ? "danger" : ""}
              errors={errors?.nationality}
            />

          </div>
          {/* country */}
          <div className="col-md-4 col-12">
            <SelectField
              label={inventory.messages.country}
              placeholder={inventory.messages.select}
              name="country_id"
              value={travelerData.country?.name}
              options={allCountries}
              onChange={(e) => handleInputsChanges(e, "country")}
              color={errors?.country?.required ? "danger" : ""}
              errors={errors?.country}
            />
          </div>
          {/* passport Expiry Date */}
          <div className="col-md-4 col-12">
            <DatePickerField
              label={inventory.messages.passportExpiryDate}
              placeholder={"DD/MM/YYYY"}
              date={travelerData?.passport_expiry ? moment(travelerData?.passport_expiry) : ""}
              name="passport_expiry"
              hasYears={true}
              onChangeDate={(e) => handleInputsChanges(e, "passport_expiry")}
              isOutsideRange={(day) => {
                return (
                  day.isBefore(moment().add("6", "month")) ||
                  day.isBefore(moment().subtract(10, "years"))
                );
              }}
              color={errors?.passport_expiry?.required ? "danger" : ""}
              errors={errors?.passport_expiry}
              startYear={moment().add(6, "month")}
              endYear={moment().add(10, "years")}
              initialVisibleMonth={() =>
                moment().add("6", "month") || moment(travelerData.passport_expiry)
              }
              readOnly={true}
            />
          </div>
          {/* passport number */}
          <div className="col-md-4 col-12">
            <TextField
              label={onlineVisa.PassportNumber}
              placeholder={inventory.messages.EnterPassportNo}
              type="text"
              name="passport_number"
              value={travelerData.passport_number}
              onChange={(e) => handleInputsChanges(e.target.value, "passport_number")}
              color={errors?.passport_number?.required ? "danger" : ""}
              errors={errors?.passport_number}
            />
          </div>
          {/* visa number */}
          <div className="col-md-4 col-12">
            <TextField
              label={inventory.messages.VisaNo}
              placeholder={inventory.messages.EnterVisaNo}
              type="text"
              name="visa_number"
              value={travelerData.visa_number}
              onChange={(e) => handleInputsChanges(e.target.value, "visa_number")}
              color={errors?.visa_number?.required ? "danger" : ""}
              errors={errors?.visa_number}
            />
          </div>
          {/* e-number */}
          <div className="col-12">
            <TextField
              label={inventory.messages.enumber}
              type="text"
              placeholder={inventory.messages.EnterENumber}
              name="e_number"
              value={travelerData.e_number}
              onChange={(e) => handleInputsChanges(e.target.value, "e_number")}
            />
          </div>


          {/* photos */}
          <div className="row mx-0 my-3 w-100">
            {/* Passport Photo (Optional) */}
            <div className="col-md-4 col-12">
              <div className="upload_box">
                <label htmlFor="passport_photo" className="mt-0">Passport Photo</label>
                <div className="upload_box_input">
                  <input type="file"
                    id="passport_photo"
                    name="passport_photo"
                    accept="image/png, image/jpeg, application/pdf, image/jpg"
                    onChange={(e) => uploadImagesToServer(e, "passport_photo")}
                  />
                  <span className="opacity-50">Upload Passport Photo</span>
                  <i class="fas fa-upload mx-2"></i>
                </div>
              </div>
              {travelerData.passport_photo ?
                <div className="preview_img mt-2">
                  <img src={`${fileUrl}/${travelerData.passport_photo}`} alt={travelerData.first_name} />
                  <button className="btn delete_img_btn"
                    onClick={() => setTravelerData({ ...travelerData, passport_photo: null })}
                  >
                    <CloseIcon />
                  </button>
                </div>
                :
                null
              }
            </div>
            {/* Personal Photo (Optional) */}
            <div className="col-md-4 col-12">
              <div className="upload_box">
                <label htmlFor="personal_photo" className="mt-0">Personal Photo</label>
                <div className="upload_box_input">
                  <input type="file"
                    id="personal_photo"
                    name="personal_photo"
                    accept="image/png, image/jpeg, application/pdf, image/jpg"
                    onChange={(e) => uploadImagesToServer(e, "personal_photo")}
                  />
                  <span className="opacity-50">Upload Personal Photo</span>
                  <i class="fas fa-upload mx-2"></i>
                </div>
              </div>
              {travelerData.personal_photo ?
                <div className="preview_img mt-2">
                  <img src={`${fileUrl}/${travelerData.personal_photo}`} alt={travelerData.first_name} />
                  <button className="btn delete_img_btn"
                    onClick={() => setTravelerData({ ...travelerData, personal_photo: null })}
                  >
                    <CloseIcon />
                  </button>
                </div>
                :
                null
              }
            </div>
            {/* National ID Photo (Optional) */}
            <div className="col-md-4 col-12">
              <div className="upload_box">
                <label htmlFor="national_id_photo" className="mt-0">National ID Photo</label>
                <div className="upload_box_input">
                  <input type="file"
                    id="national_id_photo"
                    name="national_id_photo"
                    accept="image/png, image/jpeg, application/pdf, image/jpg"
                    onChange={(e) => uploadImagesToServer(e, "national_id_photo")}
                  />
                  <span className="opacity-50">Upload National ID Photo</span>
                  <i class="fas fa-upload mx-2"></i>
                </div>
              </div>
              {travelerData.national_id_photo ?
                <div className="preview_img mt-2">
                  <img src={`${fileUrl}/${travelerData.national_id_photo}`} alt={travelerData.first_name} height="150px" />
                  <button className="btn delete_img_btn"
                    onClick={() => setTravelerData({ ...travelerData, national_id_photo: null })}
                  >
                    <CloseIcon />
                  </button>
                </div>
                :
                null
              }
            </div>
          </div>

          <div className="col-12 mt-3">
            <button className='btn bg-nxt w-100 py-2' onClick={submit}>
              Edit
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
