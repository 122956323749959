import { useWebBuilderState } from "context/webBuilder";
import { EditBox } from "modules/WebBuilder/shared/EditBox";
import React, { useState } from "react";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting.svg";
import SocialMediaModal from "./SocialMediaModal";
import { useLocation, useHistory, useParams } from "react-router-dom";
import Locale from "translations";
import { isObject } from "lodash"

const URI = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";


export default function FooterThemeFour({
	focusContainer,
	handleFocus,
	socialMediaIcons,
}) {
	const { webBuilder } = Locale;

	const location = useLocation();
	const history = useHistory();
	const params = useParams();
	const preview = !location.pathname.includes("edit");

	const {
		footer: footerDetails,
		hero,
		selectedLanguage,
	} = useWebBuilderState();
	const header = hero?.header;

	const type = "footer";
	const [socialMediaModal, setSocialMediaModal] = useState(false);
	function toggleSocialMediaModal() {
		setSocialMediaModal(!socialMediaModal);
	}

	const containerBoxActions = [
		<span onClick={toggleSocialMediaModal} className="pointer">
			<SettingIcon />
		</span>,
	];

	const scrollToSection = (id) => {
		if (id) {
			const element = document.getElementById(id);
			element?.scrollIntoView({ behavior: "smooth" });
		}
	};

	const nav = (
		<nav className="nav-list">
			<ul className="links-list d-flex gap-10">
				<li
					onClick={() => {
						if (preview) {
							if (params.name) {
								history.push(`/public-page-v2/${params.name}`);
							} else {
								history.push(`/web-builder-v2/preview`);
							}
							scrollToSection("main-web-builder");
						}
					}}
					className={"footer-text"}
				>
					{webBuilder.home}
				</li>

				<li
					onClick={() => {
						let url = params.name
							? `/public-page-v2/${params.name}`
							: "/web-builder-v2/preview";
						if (params.id) {
							history.push({ pathname: `${url}`, state: "contact-us-section" });
						} else {
							scrollToSection("contact-us-section");
						}
					}}
					className={"footer-text"}
				>
					{webBuilder.getHelp}
				</li>

				<li
					onClick={() => {
						let url = params.name
							? `/public-page-v2/${params.name}`
							: "/web-builder-v2/preview";
						if (params.id) {
							history.push({
								pathname: `${url}`,
								state: "web-builder-our-service",
							});
						} else {
							scrollToSection("web-builder-our-service");
						}
					}}
					className={"footer-text"}
				>
					{webBuilder.ourServices}
				</li>
			</ul>
		</nav>
	);

	const logoImage = isObject(header?.logo) ?
		<img src={`${header?.logo[selectedLanguage].includes("http")
			? header?.logo[selectedLanguage]
			: URI + "/" + header?.logo[selectedLanguage]}`
		}
			className="footer-photo"
			alt="Logo"
		/>
		:
		<img src={`${header?.logo[selectedLanguage]}`} alt="Logo" className="footer-photo" />;
	return (
		<>
			<div className="container">
				<div className=" footer-holder">
					<div className="footer-top">
						{/* logo and Page Links */}
						<div>
							{logoImage}

							<div className="footer-link-holder">{nav}</div>
						</div>

						{/* social */}
						<div className="footer-social" onClick={() => handleFocus(type)}>
							<p className="mb-3 footer-text">{webBuilder.followUsOn}</p>
							<EditBox
								editMode={focusContainer === type}
								defaultIcon={containerBoxActions}
							>
								<div className="d-flex my-1 footer-social-holder">
									{footerDetails?.links?.map((link) => {
										if (link?.visible) {
											return (
												<div key={link?.id}>
													{/* <a href={link?.url} target="_blank" rel="noreferrer"> */}
													{socialMediaIcons[link?.id]}
													{/* </a> */}
												</div>
											);
										} else {
											return null;
										}
									})}
								</div>
							</EditBox>
						</div>
					</div>

					{/* copy right */}
					<div className="footer-copy-right d-flex justify-content-center align-items-center">
						<p className="footer-text mt-1">
							© 2024 Company Name - All rights reserved.
						</p>
					</div>
				</div>
			</div>
			{socialMediaModal && footerDetails?.links ? (
				<SocialMediaModal
					isOpen={socialMediaModal}
					toggle={toggleSocialMediaModal}
					header={"Social links"}
					socialMediaLinks={footerDetails?.links}
				/>
			) : null}
		</>
	);
}
