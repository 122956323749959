import { arrayToObject } from 'modules/WebBuilder-V2/helpers/formatPageData';
import { customContainerItems } from "./blockTypes";
import { languagesLookup } from 'modules/WebBuilder-V2/ChooseTemplate';

const languagesCodes = languagesLookup.map(l => l?.code);

const {
	customContainerMap,
	customContainerParagraph,
	customContainerPhoto,
	customContainerVideo,
	customContainerFaqs,
} = customContainerItems;

export const initialContainersData = {
	aboutUs: {
		type: "aboutUs",
		items: [
			{
				id: "about-us-media",
				mediaType: "video",
				mediaUrl: "",
			},
			{
				id: "about-us-content",
				title: "About us",
				head: arrayToObject(languagesCodes, {}, "this is example"),
				body: arrayToObject(languagesCodes, {}, "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore"),
				textAlign: "start"
			},
		],
		id: "about-us-section",
	},
	contactUs: {
		type: "contactUs",
		title: arrayToObject(languagesCodes, {}, "Get Free consultation"),
		body: arrayToObject(languagesCodes, {}, "Need travel advice worldwide?"),
		id: "contact-us-section",
	},
	customContainer: {
		type: "customContainer",
		title: arrayToObject(languagesCodes, {}, "Custom container"),
		body: arrayToObject(languagesCodes, {}, "this is example for Custom container body"),
		items: [],
	},
	customContainerFaqs: {
		type: "customContainer",
		title: arrayToObject(languagesCodes, {}, "Custom container"),
		body: arrayToObject(languagesCodes, {}, "this is example for Custom container body"),
		items: [
			{
				type: customContainerFaqs,
				faqs: [
					{
						question: arrayToObject(languagesCodes, {}, "Question?"),
						body: arrayToObject(languagesCodes, {}, "Question Body"),
					},
				],
			},
		],
	},
	packages: {
		type: "packages",
		title: arrayToObject(languagesCodes, {}, "packages"),
		body: arrayToObject(languagesCodes, {}, "this is example for packages body"),
		items: [],
	},
};

/// items container types

export const initialCustomContainerItemsData = {
	[customContainerMap]: {
		type: customContainerMap,
		latLng: {
			lng: "",
			lat: "",
		},
	},
	[customContainerParagraph]: {
		type: customContainerParagraph,
		body: arrayToObject(languagesCodes, {}, "Enter your text"),
	},
	[customContainerPhoto]: {
		type: customContainerPhoto,
		url: "",
	},
	[customContainerVideo]: {
		type: customContainerVideo,
		url: "",
	},
	[customContainerFaqs]: {
		type: customContainerFaqs,
		faqs: [{ question: "what 1?", body: "text 1" }],
	},
};
