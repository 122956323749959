import React from 'react'
import AccountingOtherServicesItem from './OtherServicesItem'

export default function AccountingOtherservices({ isPricePerService, accountingStatement, setAccountingStatement, canEdit}) {
  const otherServicesData = accountingStatement?.other_services;

  function handleInputsChanges(value, keyName, index) {
    let accountingStatementClone = { ...accountingStatement };
    let otherServicesClone = [...otherServicesData];
    let selectedService = { ...otherServicesClone?.[index] };
    selectedService = {
      ...selectedService,
      pricing: {
        ...selectedService?.pricing,
        [keyName]: value,
      }
    }
    otherServicesClone[index] = selectedService;
    accountingStatementClone = { ...accountingStatementClone, other_services: otherServicesClone };
    setAccountingStatement(accountingStatementClone);
  }

  function deleteItemPricing(serviceIndex) {
    let accountingStatementClone = { ...accountingStatement }
    let otherServicesClone = [...otherServicesData];
    let selectedService = { ...otherServicesClone?.[serviceIndex], pricing: null };
    otherServicesClone[serviceIndex] = selectedService;
    accountingStatementClone = { ...accountingStatementClone, other_services: otherServicesClone };
    setAccountingStatement(accountingStatementClone);
  }


  return (
    <div className='accounting-item  py-0'>

      <div className="d-flex flex-column gap-10">
        {otherServicesData?.map((item, index) => {
          return (
            <div className="accounting-item-wrapper" key={`other-service-${item?.id}`}>
              <AccountingOtherServicesItem
                key={item}
                index={index}
                otherServiceItem={item}
                isPricePerService={isPricePerService}
                handleInputsChanges={handleInputsChanges}
                deleteItemPricing={deleteItemPricing}
                canEdit={canEdit}
              />
            </div>
          )
        })}
      </div>

    </div>
  )
}
