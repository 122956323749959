import React from "react";
import Locale from 'translations';

function SubscriptionItem({ details, togglePaymentModal }) {
	const checkIcon = <i class="fas fa-check text-success" />;
	const closeIcon = <i class="fas fa-times text-danger" />;
	const {  marketPlace,inventory } = Locale;

	const priceing = details?.pricing;
	return (
		<div className="Subscription_Item">
			<div className="sub-header">
				<p className="sub-name">{details?.name}</p>
				<h1 className="sub-price">
					{details?.commercial?.amount}{" "}
					<span className="currence">
						{details?.commercial?.currency?.code}
					</span>
				</h1>
				<p>{`${marketPlace.validFrom} (${priceing?.ss?.peroid_start}) ${marketPlace.till} (${priceing?.ss?.peroid_end})`}</p>
			</div>
			<div className="sub-body">
				<ul>
					<li className="det-item">
						<p>{marketPlace.permuimAccount}</p> {checkIcon}
					</li>
					<li className="det-item">
						<p>{priceing?.ss?.visas?.count} {inventory.messages.visa}</p>{" "}
						{priceing?.ss?.visas?.visible ? checkIcon : closeIcon}
					</li>
					<li className="det-item">
						<p> {priceing?.ss?.contracts?.count} {marketPlace.contracts}</p>{" "}
						{priceing?.ss?.contracts?.count ? checkIcon : closeIcon}
					</li>
					<li className="det-item">
						<p> {marketPlace.liveSupport}</p> {checkIcon}
					</li>
					<li className="det-item">
						<p>{marketPlace.freeTraining}</p> {checkIcon}
					</li>
					<li className="det-item">
						<p>{marketPlace.mrz}</p> {closeIcon}
					</li>
					<li className="det-item">
						<p>{marketPlace.safaHajj}</p> {checkIcon}
					</li>
				</ul>
				<div className="note_box">
					{details?.notes.map((note) => (
						<p key={note?.title}>
							<p className="warning-text">
								{" "}
								<i class="fas fa-info-circle"></i> {note?.title}
							</p>
							<p className="ms-3">{note?.body}</p>
						</p>
					))}
				</div>
				<button
					className="Suscribe-btn btn w-100"
					onClick={() => togglePaymentModal(details)}
				>
					{marketPlace.subscribe}
				</button>
			</div>
		</div>
	);
}

export default SubscriptionItem;
