import React from "react";
import { Table } from 'reactstrap';
import Locale from 'translations';


const GENDER = {M:"Male",F:"Female"}
function PnrList({list}) {
	const {backOffice,marketPlace} = Locale
	return (
		<div className="table-container mt-3">
			<p className='mb-1'>PNR List</p>
			<Table
				striped
				className="table-update p-2 bg-white flight-inventory-table"
				style={{ verticalAlign: "center", borderRadius: "10px" }}
			>
				<thead>
					<tr>
						<th>{marketPlace.Segment}</th>
						<th>Flight</th>
						<th>PAX Name(PTC)</th>
						<th>{marketPlace.gender}</th>
						<th>PNR</th>
						<th>Airline PNR</th>
						<th>{marketPlace.TKTNumber}</th>
					</tr>
				</thead>
				<tbody>
					{/* transfer list */}
					{list?.length > 0 ?
              list?.map(item => {
                return (
					<tr>
						<td>{`${item?.departure?.code} - ${item?.arrival?.code}`}</td>
						<td>{item?.flight_num}</td>
						<td>{item?.passenger_name}</td>
						<td>{GENDER[item?.passenger_sex]}</td>
						<td>{item?.pnr}</td>
						<td>{item?.air_pnr}</td>
						<td>{item?.ticket_number}</td>
					</tr>
					 )
              })
              :
              <tr>
                <td colSpan="10">
                  <div className="product-build__product-no-data fullHeight">
                    <i className="icx icx-eye-slash" aria-hidden="true"></i>
                    <h4>{backOffice.noResult}</h4>
                  </div>
                </td>
              </tr>
            } 
				</tbody>
			</Table>
		</div>
	);
}

export default PnrList;
