import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL + "/v1";
const marketPlaceURL = process.env.REACT_APP_API_URL + "/v1/marketplace";

export const filterStatistics = async (date) => {
	return await axios
		.get(`${baseURL}/statistics/marketplace-company-revenue?date=${date}`)
		.catch((err) => err.response);
};

//FetchHotelRecent
export const fetchHotelRecent = async (limit) => {
	return await axios.get(
		`${baseURL}/statistics/list-brns?limit=${limit}`
	);
};

//FetchTransportationRecent
export const fetchTransportationRecent = async (limit) => {
	return await axios.get(
		`${marketPlaceURL}/transportaions/inventory/list_brns?limit=${limit}`
	);
};

//fetchActivityLogin
export const fetchActivityLogin = async () => {
	return await axios.get(`${baseURL}/user-management/activity_login_log`);
};
