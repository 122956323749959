import SelectField from 'components/Form/SelectField/SelectField'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import {
	addApplication,
	productsListForApplication
} from 'services/productbuilder'
import Locale from 'translations'
import validate, { isFormValid } from 'helpers/validate'

import { ReactComponent as PAXIcon } from 'assets/images/product-builder/profile.svg'
import TextField from 'components/Form/TextField/TextField'
import NumberField from 'components/Form/NumberField/NumberField'
import { store } from 'react-notifications-component'
import { useHistory } from 'react-router-dom'
import { debounce } from "lodash"

const CreateApplicationModel = ({
	createApplicationIsOpen,
	toggleCreateApplicationModal,
	countries
}) => {
	// ** hooks
	const { inventory, productsBuilder, CRM } = Locale;
	const history = useHistory();

	// ** state
	const [newApplicationData, setNewApplicationData] = useState({
		tripname: '',
		ownername: '',
		owneremail: '',
		phoneCode: '',
		adultsPax: '',
		childPax: '',
		phoneNumber: '',
		clientemail: '',
		clientname: '',
		reservation_category: null
	})
	const [applicationProducts, setApplicationProducts] = useState([])
	const [errors, setErrors] = useState()
	const [isErrorLoaded, setIsErrorLoaded] = useState(false)

	// get products list for new application
	async function getProductList(type, adultsPax = 0, childPax = 0) {
		let data = {
			reservation_category: type,
			adults_pax: adultsPax || 0,
			children_pax: childPax || 0
		}

		const productListRes = await productsListForApplication(data)
		if (productListRes?.status >= 200 && productListRes?.status < 300) {
			setApplicationProducts(productListRes?.data?.data)
		}
	}

	const debounceProductList = useMemo(() => debounce(getProductList, 500), []);


	const checkFormErrors = () => {
		let submitError = {}
		Object.keys(newApplicationData).forEach((key) => {
			if (key !== 'childPax') {
				submitError = {
					...submitError,
					...validate(
						{ name: key, value: newApplicationData[key] },
						{
							required:
								newApplicationData.reservation_category === 'private' &&
									key === 'adultsPax'
									? false
									: true
						}
					)
				}
			}
		})
		setErrors(() => submitError)
		if (!isErrorLoaded) {
			setIsErrorLoaded(true)
		} else {
			setIsErrorLoaded(false)
		}
	}

	// add new offline application
	async function createNewApplication() {
		let data = {
			product_uuid: newApplicationData?.tripname?.product_uuid,
			contact_info: {
				full_name: newApplicationData?.ownername,
				email: newApplicationData?.owneremail,
				phone: newApplicationData?.phoneNumber,
				phone_code: newApplicationData?.phoneCode?.id,
				client_name: newApplicationData?.clientname,
				client_email: newApplicationData?.clientemail,
			},
			adults_pax: +newApplicationData?.adultsPax,
			children_pax: +newApplicationData?.childPax,
			reservation_category: newApplicationData?.reservation_category
		}

		const createApplicationRes = await addApplication(data)
		if (
			createApplicationRes?.status >= 200 &&
			createApplicationRes?.status < 300
		) {
			store.addNotification({
				title: '',
				message: 'Your application created successfully',

				type: 'success',
				insert: 'top',
				container: 'top-right',
				animationIn: ['animated', 'fadeIn'],
				animationOut: ['animated', 'fadeOut'],
				dismiss: {
					duration: 5000,
					onScreen: true,
					pauseOnHover: true
				}
			})
			toggleCreateApplicationModal();
			history.push(`/crm/main/${createApplicationRes?.data?.data?.id}`);
		}
	}

	useEffect(() => {
		if (isFormValid(errors)) {
			createNewApplication()
		} else {
		}
	}, [isErrorLoaded])

	return (
		<Modal
			isOpen={createApplicationIsOpen}
			size='md'
			centered={true}
			hasFooter={false}
			className='application-form'
		>
			<ModalHeader toggle={toggleCreateApplicationModal}>
				{CRM.addApplication}
			</ModalHeader>
			<ModalBody>
				<div className='container p-2 pb-4'>
					<div className='row'>
						<div className='col-12 row align-items-center mb-2'>
							{/* private reservation */}
							<div className='col-md-4 col-6 d-flex align-items-center'>
								<input
									class='mx-2 radio-input'
									type='radio'
									name='private'
									id='private'
									value='private'
									checked={
										newApplicationData.reservation_category === 'private'
									}
									onChange={(e) => {
										setNewApplicationData({
											...newApplicationData,
											reservation_category: e.target.value,
											tripname: null,
											adultsPax: 0,
											childPax: 0
										})
										// debounceProductList(e.target.value)
									}}
								/>
								<label className='form-check-label pointer' for='private'>
									{productsBuilder.private}
								</label>
							</div>
							{/* shared reservation  */}
							<div className='col-md-4 col-6 d-flex align-items-center'>
								<input
									class='mx-2 radio-input'
									type='radio'
									name='shared'
									id='shared'
									value='shared'
									checked={newApplicationData.reservation_category === 'shared'}
									onChange={(e) => {
										setNewApplicationData({
											...newApplicationData,
											reservation_category: e.target.value,
											tripname: null
										})
										setApplicationProducts([])
									}}
								/>
								<label className='form-check-label pointer' for='shared'>
									{productsBuilder.shared}
								</label>
							</div>
						</div>

						{/* adult pax */}
						{/* {newApplicationData.reservation_category === 'shared' ? ( */}
							<>
								<div className='col-md-6 col-12 mb-1 application-pax'>
									<span className='control-field__label'>
										{productsBuilder.adultsPax}
									</span>
									<div className='box control-field py-2 px-1 mt-1'>
										<p className='d-flex align-items-center'>
											<PAXIcon />
											<span className='mx-1'>{inventory.messages.adults}</span>
										</p>
										<div className='d-flex align-items-center'>
											<button
												className={`pax-btn ${!newApplicationData?.adultsPax ||
														+newApplicationData?.adultsPax === 0
														? 'disabled'
														: ''
													}`}
												onClick={() => {
													const newAdultValue =
														+newApplicationData?.adultsPax === 0
															? 0
															: +newApplicationData?.adultsPax - 1
													setNewApplicationData({
														...newApplicationData,
														tripname: '',
														adultsPax: newAdultValue
													})
													debounceProductList(
														'shared',
														newAdultValue,
														newApplicationData.childPax
													)
												}}
												disabled={+newApplicationData?.adultsPax === 0}
											>
												<i className='fas fa-minus' />
											</button>
											<span className='mx-2'>
												{newApplicationData.adultsPax || 0}
											</span>
											<button
												className='pax-btn'
												onClick={() => {
													const newAdultValue =
														+newApplicationData?.adultsPax + 1
													setNewApplicationData({
														...newApplicationData,
														tripname: '',
														adultsPax: newAdultValue
													})
													debounceProductList(
														'shared',
														newAdultValue,
														newApplicationData.childPax
													)
												}}
											>
												<i className='fas fa-plus' />
											</button>
										</div>
									</div>
								</div>
								{/* child pax */}
								<div className='col-md-6 col-12 mb-2 application-pax'>
									<span className='control-field__label  '>
										{productsBuilder.childrenPax}
									</span>
									<div className='box control-field py-2 px-1 mt-1'>
										<p className='d-flex align-items-center'>
											<PAXIcon />
											<span className='mx-1'>
												{inventory.messages.children}
											</span>
										</p>
										<div className='d-flex align-items-center'>
											{/* decrease */}
											<button
												className={`pax-btn ${!newApplicationData?.childPax ||
														+newApplicationData?.childPax === 0
														? 'disabled'
														: ''
													}`}
												onClick={() => {
													const newChildValue =
														+newApplicationData?.childPax === 0
															? 0
															: +newApplicationData?.childPax - 1
													setNewApplicationData({
														...newApplicationData,
														tripname: '',
														childPax: newChildValue
													})
													debounceProductList(
														'shared',
														newApplicationData.adultsPax,
														newChildValue
													)
												}}
												disabled={+newApplicationData?.childPax === 0}
											>
												<i className='fas fa-minus' />
											</button>

											<span className='mx-2'>
												{newApplicationData.childPax || 0}
											</span>
											{/* increase */}
											<button
												className='pax-btn'
												onClick={() => {
													const newChildValue =
														+newApplicationData?.childPax + 1
													setNewApplicationData({
														...newApplicationData,
														tripname: '',
														childPax: newChildValue
													})
													debounceProductList(
														'shared',
														newApplicationData.adultsPax,
														newChildValue
													)
												}}
											>
												<i className='fas fa-plus' />
											</button>
										</div>
									</div>
								</div>
							</>
						{/* ) : null} */}
						{/* package name */}
						<div className='col-12 my-2'>
							<SelectField
								label={`${inventory.messages.packageName} *`}
								placeholder={inventory.messages.select}
								name={'tripname'}
								id='tripname'
								value={newApplicationData.tripname?.label}
								onChange={(e) => {
									setNewApplicationData({
										...newApplicationData,
										tripname: e
									})
									setErrors({
										...errors,
										...validate(
											{ name: 'tripname', value: e },
											{ required: true }
										)
									})
								}}
								options={applicationProducts}
								color={errors?.tripname?.required ? 'danger' : ''}
								errors={errors?.tripname}
							/>
						</div>
						{/* owner name */}
						<div className='col-md-6 col-12 my-2'>
							<TextField
								value={newApplicationData.ownername}
								label={`${inventory.messages.ownername} *`}
								type='text'
								name='ownername'
								id='ownername'
								placeholder={inventory.messages.ownername}
								onChange={(e) => {
									setNewApplicationData({
										...newApplicationData,
										ownername: e.target.value
									})
									setErrors({
										...errors,
										...validate(
											{ name: 'ownername', value: e.target.value },
											{ required: true }
										)
									})
								}}
								color={errors?.ownername?.required ? 'danger' : ''}
								errors={errors?.ownername}
							/>
						</div>
						{/* owner email */}
						<div className='col-md-6 col-12 my-2'>
							<TextField
								label={`${inventory.messages.owneremail} *`}
								type='email'
								name='owneremail'
								id='owneremail'
								value={newApplicationData.owneremail}
								placeholder={inventory.messages.owneremail}
								min={3}
								onChange={(e) => {
									setNewApplicationData({
										...newApplicationData,
										owneremail: e.target.value
									})
									setErrors({
										...errors,
										...validate(
											{ name: 'owneremail', value: e.target.value },
											{ required: true, email: true }
										)
									})
								}}
								color={errors?.owneremail?.required ? 'danger' : ''}
								errors={errors?.owneremail}
							/>
						</div>
						{/* phone */}
						<div className='row w-100 mx-0 my-2'>
							{/* phone code */}
							<div className='col-3'>
								<SelectField
									label={`${inventory.messages.phoneCode} *`}
									placeholder={inventory.messages.select}
									name={'tripname'}
									id='tripname'
									value={newApplicationData.phoneCode?.label}
									onChange={(e) => {
										setNewApplicationData({
											...newApplicationData,
											phoneCode: e
										})
										setErrors({
											...errors,
											...validate(
												{ name: 'phoneCode', value: e },
												{ required: true }
											)
										})
									}}
									options={countries?.map((country) => {
										return {
											id: country?.phone_code,
											label: country?.phone_code,
											name: country?.phone_code,
											image: country?.image
										}
									})}
									color={errors?.phoneCode?.required ? 'danger' : ''}
									errors={errors?.phoneCode}
								/>
							</div>
							{/* phone */}
							<div className='col-9'>
								<NumberField
									type={'phone'}
									name='phoneNumber'
									id='phoneNumber'
									label={`${inventory.messages.phoneNumber} *`}
									readOnly={true}
									removeArrow={true}
									value={newApplicationData.phoneNumber}
									placeholder={inventory.messages.phoneNumber}
									min={3}
									onChange={(e) => {
										setNewApplicationData({
											...newApplicationData,
											phoneNumber: e.target.value
										})
										setErrors({
											...errors,
											...validate(
												{ name: 'phoneNumber', value: e.target.value },
												{ required: true }
											)
										})
									}}
									color={
										errors?.phoneNumber?.required || errors?.phoneNumber?.min
											? 'danger'
											: ''
									}
									errors={errors?.phoneNumber}
								/>
							</div>
						</div>

						{/* client name */}
						<div className='col-md-6 col-12'>
							<TextField
								value={newApplicationData.clientname}
								label={`${inventory.messages.clientname} *`}
								type='text'
								name='ownername'
								id='ownername'
								placeholder={inventory.messages.clientname}
								onChange={(e) => {
									setNewApplicationData({
										...newApplicationData,
										clientname: e.target.value
									})
									setErrors({
										...errors,
										...validate(
											{ name: 'clientname', value: e.target.value },
											{ required: true }
										)
									})
								}}
								color={errors?.clientname?.required ? 'danger' : ''}
								errors={errors?.clientname}
							/>
						</div>
						{/* client email */}
						<div className='col-md-6 col-12'>
							<TextField
								label={`${inventory.messages.clientemail} *`}
								type='email'
								name='owneremail'
								id='owneremail'
								value={newApplicationData.clientemail}
								placeholder={inventory.messages.clientemail}
								min={3}
								onChange={(e) => {
									setNewApplicationData({
										...newApplicationData,
										clientemail: e.target.value
									})
									setErrors({
										...errors,
										...validate(
											{ name: 'clientemail', value: e.target.value },
											{ required: true, email: true }
										)
									})
								}}
								color={errors?.clientemail?.required ? 'danger' : ''}
								errors={errors?.clientemail}
							/>
						</div>

						<button
							className='btn mt-3  w-100 '
							onClick={checkFormErrors}
							style={{
								backgroundColor: '#DAA249',
								fontSize: '18px',
								color: '#FFFFFF'
							}}
							disabled={
								!newApplicationData.reservation_category ||
								(newApplicationData.reservation_category === 'shared' &&
									!newApplicationData.adultsPax)
							}
						>
							{CRM.create}
						</button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	)
}

export default CreateApplicationModel
