import axios from "axios";
import { useAuthState } from "context/auth";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Locale from "translations";
import emailImg from "assets/images/customRegister/mailbox.png";

export default function SafaRegisterationSucceed() {
  const { success, forgetPassword } = Locale;
  const userEmail = useAuthState();
  const [counter, setCounter] = React.useState(60);
  const [isResend, setIsResend] = useState(true);
  const [email, setEmail] = useState(null);
  const [title, setTitle] = useState(success.successTitle);
  const [message, setMessage] = useState(success.successMessage);
  const { pathname, state } = useLocation();

  async function resendEmailVerification() {
    const response = await axios.post(`${process.env.REACT_APP_AUTH_API}/resend-activation-token?email=${email}`);
    if (response?.status === 200) {
      setIsResend(true);
      setCounter(60);
    }
  };

  useEffect(() => {
    if (isResend) {
      const timer =
        counter > 0
          ? setInterval(() => setCounter(counter - 1), 1000)
          : setIsResend(false);
      return () => {
        clearInterval(timer);
      };
    }
  }, [counter, isResend]);

  useEffect(() => {
    if (pathname.indexOf("activation") >= 0) {
      setEmail(state?.email);
      setTitle("Activation mail");
      setMessage("We just sent you an activation email");
    } else setEmail(userEmail.safaUserDetails?.email);
    if (email != null && pathname.indexOf("activation") >= 0)
      resendEmailVerification();
  }, [email, state?.email, userEmail.safaUserDetails?.email]);


  return (
    <>
      <div className=" p-4 px-3 radius-10 mt-2">
        <div className="row">
          <div className="col-md-6 mx-auto text-center py-2">
            <img
              src={emailImg}
              width="180"
              alt="email-verification"
              title="email-verification"
            />
            <p className="text-headline font-medium mt-3">{title}</p>
            <p className="text-headline">{message}</p>
            <p className="text-headline">{success.successMessage2}</p>
            {!isResend ? (
              <>
                <p className="text-headline mt-5">{success.notReceive}</p>
                <p className="text-headline">
                  {success.checkSpam}
                  <button
                    className="btn-clear text-danger"
                    onClick={resendEmailVerification}
                  >
                    {success.resendEmail}
                  </button>
                </p>
              </>
            ) : (
              <p>
                {success.pleaseWait}{" "}
                <span className="text-danger">00:{counter}</span>{" "}
                {success.receiveMail}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="row mt-3 justify-content-center m-0">
        <div className="col-md-4">
          <Link to="/auth/login" className="btn btn bg-nxt   w-100">
            {forgetPassword.backLogin}
          </Link>
        </div>
      </div>
    </>
  )
}
