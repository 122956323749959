import SelectField from 'components/Form/SelectField/SelectField'
import TextField from "components/Form/TextField/TextField";
import React from 'react';
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import Locale from 'translations';
import { ReactComponent as ResetFilterIcon } from "assets/images/market-place/refresh-circle-icon.svg";



export default function OperationsAgentsFilter({ filters, countriesLookup, setFilters, resetFilters }) {
  const { marketPlace, inventory, payment } = Locale;
  return (
    <div className='agents-filter'>
      <div className="d-flex flex-wrap align-items-center">
        <div className="col-md-4 col-12">
          <TextField
            type="text"
            id="agent-search"
            name="agent-search"
            label={marketPlace.messages.search}
            placeholder={marketPlace.messages.search}
            isImage={true}
            image={SeachIcon}
            prependImage={true}
            onChange={(e) => {
              setFilters({
                ...filters,
                name: e.target.value,
              });
            }}
            value={filters?.name}
          />
        </div>
        <div className="col-md-2">
          <SelectField
            id="agent-country"
            name="agent-country"
            label={inventory.messages.country}
            options={countriesLookup}
            value={filters.country_id?.label}
            onChange={(e) => {
              setFilters({ ...filters, country_id: e })
            }}
          />
        </div>
        <button className='btn reset-btn' onClick={resetFilters}>
          <ResetFilterIcon />
          <span className='mx-2'>{payment.messages.clear}</span>
        </button>
      </div>
    </div>
  )
}
