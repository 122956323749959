import React, { useEffect, useState } from "react";
import PassengersSelection from "./shared/PassengersSelection";
import ActionReason from "./shared/ActionReason";
import ContactInformation from "./shared/ContactInformation";
import FlightSideStep from "./shared/SideSteps";
import { useSBSState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import Locale from "translations";
import JourneysSelection from "./shared/JourneySelection";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { flightChangeRequest, viewflight } from "services/inventory";
import { uploadFile } from "services/auth";
import SuccessModal from "modules/market-place/HotelResponse/Summary/successModal";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default function FlightChange() {
	const { inventory, marketPlace } = Locale;
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const [passengersList, setPassengersList] = useState([]);
	const [journeysList, setJourneysList] = useState([]);

	const [reasonData, setReasonData] = useState({
		reason: null,
		certFiles: [],
		remarks: "",
	});

	const [successModalOpen, setModalsuccessModal] = useState(false);
	const [reservation, setReservation] = useState(null);

	const { id } = useParams();
	const history = useHistory();

	useEffect(() => {
		const fetchFlights = async () => {
			const res = await viewflight(id);
			if (res.status === 200) {
				const data = res.data.data;

				const flights = data.flights.map((flight) => ({
					departure: flight.departure,
					arrival: flight.arrival,
					id: flight.id,
					cabinClass:flight?.segments?.[0]?.cabin_class
				}));
				
				setJourneysList(flights);
				setReservation(data);
				setPassengersList(data.passengers);
			}
		};
		fetchFlights();
		return () => {};
	}, []);

	const { allCountries } = useSBSState();
	const countries = allCountries
		? allCountries?.map((country) => {
				return { ...country, label: country?.name };
		  })
		: null;
	const reasonsLookup = [
		{ id: 1, name: "Voluntarily" },
		{ id: 2, name: "Flight reschedule/ cancellation" },
		{ id: 3, name: "Reissue due to medical reason" },
		{ id: 4, name: "Reissue due to visa refusal" },
		{ id: 5, name: "Others" },
	];

	const [contactInfoData, setContactInfoData] = useState({
		name: "",
		email: "",
		phoneCode: "",
		phone: "",
	});

	const [errors, setErrors] = useState({
		journey: [],
	});
	const validations = { ...reasonData, ...contactInfoData };

	function checkFormErrors() {
		let submitError = {};
		Object.keys(validations).forEach((key) => {
			if (key === "remarks") return;
			submitError = {
				...submitError,
				...validate(
					{ name: key, value: validations[key] },
					{
						required:
							key === "certFiles" && reasonData.reason?.name === "Voluntarily"
								? false
								: true,
						email: key === "email",
						min: key === "phone" ? 9 : "",
						max: key === "phone" ? 13 : "",
					}
				),
			};
		});
		journeysList.forEach((journey, index) => {
			const errorClone = { ...errors };
			errorClone.journey[index] = {
				...validate(
					{ name: "class", value: journey.class },
					{
						required: journey.selected,
					}
				),
				...validate(
					{ name: "booking_date", value: journey.booking_date },
					{
						required: journey.selected,
					}
				),
			};

			submitError = {
				...submitError,
				journey: errorClone.journey,
			};
		});

		setErrors(() => submitError);
	}

	async function submitChangeRequest() {
		const data = {
			flight_booking_id: reservation.id,
			reason: reasonData.reason.name,
			remarks: reasonData.remarks,
			contact_info: {
				full_name: contactInfoData.name,
				email: contactInfoData.email,
				phone_code: contactInfoData.phoneCode.phone_code,
				phone: contactInfoData.phone,
			},
			passengers: passengersList
				.filter((passenger) => passenger.selected)
				?.map((passenger) => passenger.id),
			journeys: journeysList
				.filter((journey) => journey.selected)
				?.map((journey) => ({
					flight_journey_id: journey.id,
					date: moment(journey.booking_date).format("YYYY-MM-DD HH:mm:ss"),
					cabin_class: journey.class.name,
				})),
		};

		if (reasonData?.reason?.name !== "Voluntarily") {
			let promises = [];
			// upload multiple files
			reasonData.certFiles?.forEach(async (file) => {
				const formData = new FormData();
				formData.append("file", file);
				formData.append("name", file.name);
				formData.append("bucket", "flightrequests");
				promises.push(uploadFile(formData).then((res) => res.data.data.uuid));
			});
			Promise.all(promises).then(async (resFiles) => {
				const changeRes = await flightChangeRequest({
					...data,
					certification_files: resFiles,
				});
				if (changeRes?.status >= 200 && changeRes?.status < 300) {
					setModalsuccessModal(true);
				}
			});
		} else {
			const changeRes = await flightChangeRequest(data);
			if (changeRes?.status >= 200 && changeRes?.status < 300) {
				setModalsuccessModal(true);
			}
		}
	}

	useEffect(() => {
		const journeyIsValid =
			errors.journey.length &&
			errors.journey.every((journey) => isFormValid(journey));
		;
		if (isFormValid(errors) && journeyIsValid) {
			submitChangeRequest();
		}
	}, [isErrorLoaded]);

	function submit() {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	}

	return (
		<div className="container flight-actions">
			<div className="row">
				<div className="col-12">
					{/* action title */}
					<h1 className="flight-actions-title">
						{inventory.messages.change}
						<span>{inventory.messages.order} {reservation?.air_order}</span>
					</h1>

					<div className="col-md-9 col-12 p-0">
						<div className="flight-action-content">
							{/* passengers list */}
							<div className="d-flex position-relative my-2">
								<FlightSideStep step={"1"} />
								<PassengersSelection
									passengersList={passengersList}
									setPassengersList={setPassengersList}
								/>
							</div>

							{/* change reason */}
							<div className="d-flex position-relative my-2">
								<FlightSideStep step={"2"} />
								<JourneysSelection
									errors={errors}
									setErrors={setErrors}
									journeysList={journeysList}
									setJourneysList={setJourneysList}
								/>
								{/* <SelectJourneyChange
									
								/> */}
							</div>
							{/* change reason */}
							<div className="d-flex position-relative my-2">
								<FlightSideStep step={"3"} />
								<ActionReason
									reasonTitle={inventory.messages.changeReason}
									areaPlaceholder={inventory.messages.remarksChangeReason}
									reasonsLookup={reasonsLookup}
									reasonData={reasonData}
									setReasonData={setReasonData}
									errors={errors}
									setErrors={setErrors}
								/>
							</div>
							{/* contact information */}
							<div className="d-flex position-relative my-2">
								<FlightSideStep step={"4"} />
								<ContactInformation
									countries={countries}
									contactInfoData={contactInfoData}
									setContactInfoData={setContactInfoData}
									errors={errors}
									setErrors={setErrors}
								/>
							</div>
						</div>
						{/* submit button */}
						<div className="d-flex justify-content-end mx-md-4 mx-1">
							<button
								className="btn flight-checkout-btn"
								onClick={submit}
								disabled={
									!passengersList.some((passenger) => passenger.selected) ||
									!journeysList.some((journey) => journey.selected)
								}
							>
								{marketPlace.submit}
							</button>
						</div>
					</div>
				</div>
			</div>

			{successModalOpen && (
				<SuccessModal
					isOpen={successModalOpen}
					title={"Change information confirmation"}
					msg={
						<div className="mt-2">
							<p>Change request is submitted</p>
							<p>Please contact customer service to verify change price</p>
							<button
								className="flight-checkout-btn h6 w-100 mt-3 mb-0 border-0"
								onClick={() =>
									history.push(`/inventory/flight/reservation/${id}/requests`)
								}
							>
								View Order
							</button>
						</div>
					}
				/>
			)}
		</div>
	);
}
