import { useHistory } from "react-router-dom";
//import { useCountUp } from "react-countup";
import Locale from "translations";

export default function CurrentBalance(props) {
	const { payment } = Locale;
	const history = useHistory();
	const pathname = window.location.pathname;

	return (
		<>
			<div className="d-flex flex-column justify-content-center align-items-center mt-4">
				<button
					className={`rounded p-1 btn-success my-3 w-75 ${pathname === "/credit-card" ? "activeWallet" : ""
						}`}
					onClick={() => history.push("/credit-card")}
				>
					<i className="fab fa-cc-mastercard fa-2x"></i>
					<i className="fab fa-cc-visa px-1 fa-2x"></i>
					<p className="h6">{payment.messages.addBalance}</p>
				</button>
				{/* <button
					type="button"
					class="rounded p-1 btn-success my-3 w-75 "
				>
					<i class="fas fa-money-bill-wave fa-2x"></i>
					<p className="h6">{"add_credit"}</p>
				</button> */}

				<button
					className={`rounded p-1 btn-success my-3 w-75 ${pathname === "/bank-transfer" ? "activeWallet" : ""
						}`}
					onClick={() => history.push("/bank-transfer")}
				>
					<i class="fas fa-exchange-alt fa-2x"></i>
					<p className="h6">{payment.messages.bankTransfer}</p>
				</button>

				<button
					className={`rounded p-1 btn-success my-3 w-75 ${pathname === "/wallet" ? "activeWallet" : ""
						}`}
					onClick={() => history.push("/wallet")}
				>
					<i class="fas fa-info fa-2x"></i>

					<p className="h6">{payment.messages.list}</p>
				</button>
				{/* <button
					type="button"
					class="rounded p-1 btn-success my-2 w-75 "	
					
				>
					<i class="fas fa-exchange-alt fa-2x"></i>
					<p className="h6">{"TransferAccounts"}</p>
				</button> */}
			</div>
			{/* <div className="current-balance-box">
				<h5 className="current-head font-weight-bold">
					{payment.messages.currentBalance}
				</h5>

				<div className="d-flex align-items-center ">
					<h2 className="font-weight-bold current-price">{countUp}</h2>
					<select className="mx-2  select-balance">
						<option selected="selected" value="1">
							SAR
						</option>
						<option value="2">EGP</option>
						<option value="3">DSP</option>
					</select>
				</div>
			</div> */}
		</>
	);
}
