import React from "react";
import RoomCard from "./RoomCard";

function RoomsContainer({ hotelDetails, filterRoomType }) {
	return (
		<div className="room-container">
			{hotelDetails?.accommodationRooms?.length > 0
				? hotelDetails.accommodationRooms.map((room) =>
						room.room_type === filterRoomType || filterRoomType === "all" ? (
							<RoomCard roomDetails={room} key={room.id} />
						) : null
				  )
				: null}
		</div>
	);
}

export default RoomsContainer;
