import React from "react";
import CityCard from "./cityCard";

import { downloadAllOffers } from "services/promo";
import Locale from 'translations';

function CitiesSection({ cityList, scrollToCity }) {
	    const { marketPlace } = Locale;

	const downloadOffers = async () => {
		const response = await downloadAllOffers();
		if (response.status === 200) {
			if (response.data) {
				const blob = new Blob([response.data], {
					type: response.headers['content-type'],
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.click();
			}
		}
	};
	return (
		<div className="w-100 py-5">
			<div className="w-100 pb-5">
				<p className="check"> {marketPlace.checkOut}</p>
				<p className="begin"> {marketPlace.clickOn}</p>
			</div>
			<div className="container">
				<div
					className="d-flex align-items-stretch justify-content-start flex-wrap "
					style={{ gap: "16px" }}
				>
					{cityList.map((city) => (
						<CityCard
							key={city?.id}
							cityDetails={city}
							scrollToCity={scrollToCity}
						/>
					))}

					<div
					role='button'
						className="card-city download-all-offer p-3"
						onClick={() => downloadOffers()}
					>
						<h4 className="h4 font-weight-bold">{marketPlace.downloadAll}</h4>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CitiesSection;
