import React, { useState } from 'react';
import { ReactComponent as VisaRequestIcon } from "assets/images/Tds/visa-requests-icon.svg";
import { ReactComponent as BookVisaIcon } from "assets/images/Tds/book-visa-icon.svg";
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TextField from 'components/Form/TextField/TextField';
import { TDSviewVisaOnline } from 'services/TDSVisa';

export default function TDSVisaIntro() {
  const history = useHistory();
  const [tokenModalOpen, setTokenModalOpen] = useState(false);
  const [token, setToken] = useState("");
  function toggleTokenModal() {
    setTokenModalOpen(!tokenModalOpen);
    setToken("");
  }
  async function checkToken() {
    const res = await TDSviewVisaOnline(token);
    if (res?.status === 200) {
      toggleTokenModal();
      history.push(`/tds/visa-reservation/edit/${token}`)
    }
  }
  return (
    <div className='tds-visa-container'>
      <div className="intro">
        <div className='intro-item'>
          <BookVisaIcon />
          <span className='my-1'>Book online visa</span>
          <button className='btn tds-generic-btn w-100' onClick={() => history.push('/tds-visa-packages')}>Start</button>
        </div>
        <div className='intro-item'>
          <VisaRequestIcon />
          <span className='my-1'>My Visa requests</span>
          <button className='btn tds-generic-btn w-100' onClick={toggleTokenModal}>Check</button>
        </div>
      </div>

      <Modal isOpen={tokenModalOpen} className='tds-visa-token-modal' centered>
        <ModalHeader toggle={toggleTokenModal}>
          Visa Request
        </ModalHeader>
        <ModalBody>
          <div>
            <div>
              <TextField
                label={"Enter the Token"}
                type={'text'}
                name="fullName"
                id="fullName"
                value={token}
                onChange={(e) => {
                  setToken(e.target.value);
                }}
              />
            </div>
            <button className='btn tds-generic-btn w-100 mt-4'
              onClick={checkToken}
              disabled={!token || token.length < 3}
            >
              Enter
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}
