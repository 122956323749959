import React from "react";

export default function ArrowRightIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M9.57 5.92969L3.5 11.9997L9.57 18.0697M20.5 11.9997H3.67"
				stroke="#707170"
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
