import React, { useEffect, useState } from "react";
import { ReactComponent as OutGoingCallIcon } from "assets/images/crm/call-outgoing.svg";
import { ReactComponent as CallIcon } from "assets/images/crm/call-incoming.svg";
import { ReactComponent as OutGoingCallRedIcon } from "assets/images/crm/red-call-outgoing.svg";

import { ReactComponent as IncomingCallIcon } from "assets/images/crm/callIncoming.svg";

import Locale from "translations";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { addCallLog, callLogList } from "services/CRM";
import moment from "moment";


const CallLog = () => {
	const { id } = useParams();
	const { inventory,CRM } = Locale;
	const [callLogState, setCallLogState] = useState();
	const [reload, setReload] = useState(false)
	//[1=>inbound, 2=>outbound]
	const [log, setLog] = useState({
		type: "1",
		note: "",
	});
	useEffect(() => {
		async function fetchCallLog() {
			const res = await callLogList(id);
			setCallLogState(res?.data?.data);
		}
		fetchCallLog();
	}, [reload]);
	async function addLog() {
		const res = await addCallLog(id, log);
		if (res.status === 200 || res.status === 201) {
			// getViewReservation()
			setLog({
				...log,
				note: "",
			});
			store.addNotification({
				title: "info!",
				message: res.data.message ?? "Log Successfully Added",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1100,
					onScreen: true,
					pauseOnHover: true,
				},onRemoval:()=>{
					setReload(prev=>!prev)
				}
			});
		}
	}

	return (
		<div className="call-log">
			<div className="call-log-buttons">
				<button
					className={`btn btn-item btn-ingoing ${
						log.type !== "1" ? "opacity-1 " : "active"
					}`}
					onClick={() => setLog({ ...log, type: "1" })}
				
				>
					<CallIcon  />
					<span>{CRM.Inbound}</span>
				</button>
				<button
					onClick={() => setLog({ ...log, type: "2" })}
					className={`btn btn-item btn-outgoing ${
						log.type !== "2" ? "opacity-1" : "active"
					}`}
				>
					<OutGoingCallIcon  />
					<span>{CRM.Outbound}</span>
				</button>
			</div>


			
			<div className="call-log-text">
				<label>{CRM.Note}</label>
				<textarea
					className=" w-100 p-3"
					placeholder={inventory.messages.notecall}
					value={log.note}
					onChange={(e) => setLog({ ...log, note: e.target.value })}
				/>
			</div>

			<button
				onClick={() => addLog()}
				className="btn btn-color text-white  px-3"
				// disabled
			>
				{inventory.messages.save}
			</button>
			{callLogState && callLogState?.length > 0 ? (
				<div className="call-logs-history">
					<h3>{CRM.CallLog}</h3>
					{callLogState.map((call) => (
						<>
							<div className="d-flex justify-content-between align-items-center">
								<div
									className="d-flex align-items-center calls--logs"
									style={{ gap: "5px" }}
								>
									{call.type === 1 ? (
										<IncomingCallIcon  />
									) : (
										<OutGoingCallRedIcon />
									)}
									<p className="crm-details-text-secondary">Logged by {call.user_name}</p>
								</div>
								<p className="crm-details-text-secondary-date">
									{call.created_at}
								</p>
							</div>
							<p
								style={{
									fontSize: "14px",
									fontWeight: "400",
									color: "rgba(5, 0, 1, 1)",
									paddingLeft: "2rem",
								}}
							>
								{call?.note}
							</p>
						</>
					))}
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default CallLog;
