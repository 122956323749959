export const HotelDistributionPrint = `<style>
.w-25 {
	width: 25% !important;
}

.w-50 {
	width: 50% !important;
}

.w-75 {
	width: 75% !important;
}

.w-100 {
	width: 100% !important;
}

.mr-3,
.mx-3 {
	margin-right: 1rem !important;
}

.ml-3,
.mx-3 {
	margin-left: 1rem !important;
}

html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
	padding-right: 5px;
	padding-left: 5px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
	padding-right: 5px;
	padding-left: 5px;
}

.col-2 {
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
}

.col-8 {
	flex: 0 0 66.6666666667%;
	max-width: 66.6666666667%;
}
.col-7 {
	flex: 0 0 62.6666666667%;
	max-width: 62.6666666667%;
}
.col-1 {
	flex: 0 0 8.3333333333%;
	max-width: 8.3333333333%;
}

.col-3 {
	flex: 0 0 25%;
	max-width: 25%;
}

.col-6 {
	flex: 0 0 50%;
	max-width: 50%;
}

.col-4 {
	flex: 0 0 29.3333333333%;
	max-width: 29.3333333333%;
}
.mb-3,
.my-3 {
	margin-bottom: 1rem !important;
}
.mb-1 {
	margin-bottom: 0.2rem !important;
}
* {
	font-family: Helvetica;
}

.bg-gray-100 {
	background: #f4f4f4 !important;
}

.container {
	max-width: 1140px;
	margin: auto;
}

.ml-5,
.mx-5 {
	margin-left: 3rem !important;
	margin-right: 3rem;
}

.pt-2,
.py-2 {
	padding-top: 0.5rem !important;
}

.d-flex {
	display: flex !important;
}

.text-center {
	text-align: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.flex-column {
	flex-direction: column;
}

.align-items-center {
	align-items: center;
}

.nav {
	background-color: #ebebeb;
	border-bottom: 1px solid #a7a7a7;
	padding: 1rem 0 1rem 1rem;
}
.hero-section {
	padding: 0.5rem 1rem;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
}

.v-label {
	transform: rotate(342deg);
}

.hero-section ul li:first-child {
	padding-bottom: 10px;
}
.border {
	border: 2px solid #0c3b5c;
}
.border-bottom {
	border-bottom: 1px solid #0c3b5c;
}
.border-bottom-bold {
	border-bottom: 2px solid #0c3b5c;
}
.sm-font {
	font-size: 13px;
	color: #2d2d2d;
	font-weight: 600;
}

.sm-font-light {
	font-size: 13px;
	color: #2d2d2d;
	font-weight: lighter;
}

table {
	width: 100%;
}

.table,
.th,
.td {
	border: 1px solid #0c3b5c;
	border-collapse: collapse;
}

.th {
	color: #2d2d2d;
	font-weight: bold;
	padding: 10px;
}

.td {
	color: #2d2d2d;
	font-weight: lighter;
	font-size: 13px;
	padding: 11px;
	text-align: center;
}

ul {
	list-style: none;
}

.main-color {
	color: #0c3b5c;
}
.nav1 {
	justify-content: center;
	padding: 12px 0;
}
.font-bold {
	font-weight: 800 !important;
}
.justify-content-start {
	justify-content: flex-start !important;
}
.py-5 {
	padding-bottom: 2.5rem !important;
	padding-top: 2.5rem !important;
}
.py-3 {
	padding-bottom: 1.5rem !important;
	padding-top: 1.5rem !important;
}
.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}
.my-1 {
	margin-top: 2rem !important;
	margin-bottom: 2rem !important;
}
.mx-3 {
	margin-left: 1rem !important;
	margin-right: 1rem !important;
}
@page {
	size: A4;
	margin: 15px;
}
@media print {
	.container {
		width: 100% !important;
	}
}
.hotel-distribution {
	padding: 45px 0;
}

.room-type .hotel-name {
	padding: 0 20px;
}

.room-type-head td,
.room-type td {
	font-weight: 600;
	font-size: 18px;
}
.room-type,
.room-type-head {
	margin-top: 15px;
	padding: 20px;
}

.hotel-name {
	font-size: 20px;
	font-weight: 700;
	border: 2px solid gray;
	padding: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-inline-end: 28px;
	justify-content: space-around;
}
.image img {
	width: 120px;
	height: auto;
}

.first-tr td{
padding-bottom: 16px;
}

.padding-start {
padding-inline-start: 8px;
}
.max-width-260{
max-width: 260px;
}
.max-width-130{
max-width: 130px;
}
.width-50{
width: 50px;
padding-inline: 5px;
}
.hotel-icon{
width: 24px;
height: 24px;
margin-inline-end: 8px ;
}
</style>
<div class="hotel-distribution" style="direction: [dir]">
<div class="container">
	<div class="hotel-name">
		<div class="d-flex">
			<div class="header">
				<span>[CompanyKey]:</span>
				<span>[tripNumKey]:</span>
				<span>[dateKey]:</span>
			</div>
			<div class="header">
				<span>[Companyvalue] </span>
				<span>[tripNumValue]</span>
				<span>[dateKeyValue]</span>
			</div>
		</div>
		<div class="image">
			<img src="[avater]" alt="" width="120" height="60" />
		</div>
	</div>
	<div class="room-type-head border">
		<table>
			<tr>
				<td class="max-width-260" > <i class="fas fa-hotel hotel-icon"></i>[hotelsKey]</td>

				<td class="max-width-130" ></td>
				<td class="width-50">[oneKey]</td>
				<td class="width-50">[twoKey]</td>
				<td class="width-50">[threeKey]</td>
				<td class="width-50">[fourKey]</td>
				<td class="width-50">[fiveKey]</td>
				<td class="width-50">[sixKey]</td>
				<td class="width-50">[sevenKey]</td>
			</tr>
		</table>
	</div>

	[allRooms]
</div>
</div>
`;
