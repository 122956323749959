import myWalletImg from "assets/images/dashboard/3.svg";
import { useMarketplaceState } from "context/marketplace";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Locale from "translations";
import VisaSearch from "../VisaSearchOld";
import CustomModal from "./Modal";

export default function ListHeader() {
	const { marketPlace, onlineVisa, inventory, payment, reservation } = Locale;
	const [openModal, setOpenModal] = useState(false);
	const [agreementModal, setAgreementModal] = useState(false);
	const { onlineVisaSearch } = useMarketplaceState();

	const toggleModel = () => {
		setOpenModal(!openModal);
	};
	const toggleAgreementModal = () => {
		setAgreementModal(!agreementModal);
	};
	const { id } = useParams();
	const statusLocale = (status) => {
		switch (status) {
			case "pending":
				return inventory.messages.pending;
			case "waiting":
				return inventory.messages.waiting;
			case "cancelled":
				return inventory.messages.cancelled;
			case "issued":
				return inventory.messages.issued;
			case "in progress":
				return inventory.messages.inProgress;
			case "expired":
				return inventory.messages.expired;
			case "incomplete":
				return inventory.messages.incomplete;
				case "rejected":
				return payment.messages.Reject;
			default:
				return status;
		}
	};

	return (
		<>
			<div className="d-flex justify-content-between flex-wrap bg-white border p-0 rounded">
				{/* header */}
				<div className=" bg-gray visa_header_title p-md-5 p-3">
					<p
						className="m-0 font-weight-bold"
						style={{ whiteSpace: "break-spaces" }}
					>
						{reservation.messages.visaDetails}
					</p>
				</div>
				{/* details */}
				<div className="d-flex justify-content-between align-items-center flex-wrap col-md-8 col-12">
					<div className="row col-md-6 col-12 p-2 m-0">
						<div className="col-6 txt-blue">
							<p className="m-0 ">ID</p>
							<p className="m-0 ">{onlineVisa.Destination}</p>
							<p className="m-0 ">{onlineVisa.VisaType}</p>
							{/* <p className="m-0 ">{onlineVisa.TransportationType}</p> */}
							<p className="m-0 ">{marketPlace.messages.pax}</p>
							{/* <p className="m-0 ">{onlineVisa.Nationality}</p> */}
							{/* <p className="m-0 ">{onlineVisa.Embassy}</p> */}
							{/* <p className="m-0 ">{onlineVisa.VisaPriceOne}</p> */}
						</div>

						<div className="col-6">
							<p className="m-0">{id}</p>
							<p className="m-0">
								{onlineVisaSearch?.destination?.country ||
									onlineVisaSearch?.destination}
							</p>
							<p className="m-0">
								{onlineVisaSearch?.visaType?.name
									? onlineVisaSearch?.visaType?.name
									: "-"}
							</p>
							{/* <p className="m-0 text-capitalize">
								{onlineVisaSearch?.transportation_type?.label
									? onlineVisaSearch?.transportation_type?.label
									: "-"}
							</p> */}
							<p className="m-0">{onlineVisaSearch?.pax}</p>
							{/* <p className="m-0">{onlineVisaSearch?.residence?.label}</p> */}
							{/* <p className="m-0">{onlineVisaSearch?.embassy?.label}</p> */}
							{/* <p className="m-0">
								{onlineVisaSearch?.transportation_type?.price}{" "}
								{onlineVisaSearch?.transportation_type?.currency?.toLocaleUpperCase()}
							</p> */}
						</div>
					</div>

					<div className="row col-md-6 col-12 p-2 m-0">
						<div className="col-6 txt-blue font-weight-bold">
							{/* <p className="m-0 ">{onlineVisa.departureDate}</p>
							<p className="m-0 ">{onlineVisa.returnDate}</p> */}
							<p className="m-0 ">{inventory.messages.requestDate}</p>
							<p className="m-0 ">{marketPlace.messages.requestExpireDate}</p>

							<p className="m-0 ">{inventory.messages.Status}</p>
							{onlineVisaSearch?.entry_stamp ? (
								<p className="m-0 ">{onlineVisa.contractAndAgreement}</p>
							) : null}
						</div>
						<div className="col-6">
							{/* <p className="m-0">
								{onlineVisaSearch?.departure_date
									? moment(onlineVisaSearch?.departure_date).format(
										"DD/MM/YYYY"
									)
									: "-"}
							</p>
							<p className="m-0">
								{onlineVisaSearch?.return_date
									? moment(onlineVisaSearch?.return_date).format("DD/MM/YYYY")
									: "-"}
							</p> */}

							<p className="m-0 ">
								{moment(onlineVisaSearch?.requestDate).format("DD/MM/YYYY")}
							</p>
							<p className="m-0 text-danger">
								{moment(onlineVisaSearch?.expirytDate).format("DD/MM/YYYY")}

								<p
									className={`font-weight-normal text-capitalize text-${
										onlineVisaSearch?.status === "waiting"
											? "waiting"
											: onlineVisaSearch?.status === "incomplete" ||
											  onlineVisaSearch?.status === "expired"||
											  onlineVisaSearch?.status === "rejected"
											? "danger"
											: onlineVisaSearch?.status === "pending"
											? "pending"
											: "issued"
									}`}
								>
									{onlineVisaSearch?.status === "waiting"
										? inventory.messages.waitingVisa
										: statusLocale(onlineVisaSearch?.status)}
								</p>
							</p>
							{onlineVisaSearch?.entry_stamp ? (
								<p className="m-0 orange-color pointer" onClick={toggleAgreementModal}>
									<i className="fa fa-eye me-1" />
									{inventory.messages.view}
								</p>
							) : null}
						</div>
					</div>
				</div>
				{/* price */}
				<div className="visa_header_price d-flex justify-content-center align-items-center bg-gray px-4  text-center rounded">
					<img src={myWalletImg} alt="" width="28px" />
					<div className="mx-3">
						<p className="m-0 txt-blue">{onlineVisa.TotalPrice}</p>
						<p className="m-0 text-success font-weight-bold">
							{onlineVisaSearch?.currency}{" "}
							{(onlineVisaSearch?.total)?.toFixed(2) || ""}
							{" "}
						</p>
					</div>
				</div>
			</div>

			<CustomModal
				modalIsOpen={openModal}
				header={onlineVisa.EditPax}
				toggle={toggleModel}
				size={"md"}
				centered={true}
			>
				<VisaSearch isMarketSearch={false} toggleModel={toggleModel} />
			</CustomModal>

			<CustomModal
				modalIsOpen={agreementModal}
				header={onlineVisa.contractAndAgreement}
				toggle={toggleAgreementModal}
				size={"md"}
				centered={true}
			>
				Agreement test
			</CustomModal>
		</>
	);
}
