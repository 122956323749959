import Locale from "translations";
import walletImg from "assets/images/newWallat.svg";
import masterCardImg from "assets/images/wallet/masterCard.svg";
import masterCardImgGray from "assets/images/wallet/masterCardGray.svg";
import { useEffect, useState } from "react";
import { getPrebookListWallets } from "services/wallet";
import CustomModal from "modules/market-place/TransferResponse/Modals/Modal";
import { useSBSState } from 'context/global';

export default function SubscriptionPaymentModal({
	isOpen,
	toggleModal,
	onPayment,
	selectedItem,
}) {
	const { onlineVisa, payment, inventory } = Locale;
	const [paymnet, setPaymnet] = useState({});
	const ONLINE_CURRENCY = process.env.REACT_APP_ONLINE_CURRENCY
	const { companyInfo , currency:selectedCurrency } = useSBSState();

	useEffect(() => {
		async function fetchSafaWallet() {
			const walletRes = await getPrebookListWallets({
				currency: selectedItem?.commercial?.currency?.code + ","+ONLINE_CURRENCY,
				price: +selectedItem?.commercial?.amount,
			});
			if (walletRes.status >= 200 && walletRes?.status < 300) {
				let onlinePay=companyInfo?.country?.nat_code === "IDN" && selectedCurrency=="IDR" ? "IDR" : ONLINE_CURRENCY

				const dataSAR = walletRes?.data?.data?.filter(
					(i) => i.currency === onlinePay
				)[0];
				setPaymnet(dataSAR);
			}
		}
		fetchSafaWallet();
		return () => {};
	}, []);

	return (
		<CustomModal
			modalIsOpen={isOpen}
			header={onlineVisa.ChoosePayment}
			toggle={toggleModal}
			size="md"
			centered={true}
		>
			<div className="px-3">
				<div className="col-12 mb-2">
					<div className="d-flex justify-content-center">
						<img src={walletImg} alt="wallet" className="img-fluid" />
						<p className="d-flex flex-column mx-2 ">
							<span className="text-dark-blue">{inventory.messages.Amount} </span>
							<span className="text-success font-weight-bold h5">
								{(
									paymnet?.exchange_rate_price +
									paymnet?.exchange_rate_price * 0.025
								).toFixed(2)}
								{paymnet?.currency}
							</span>
						</p>
					</div>
				</div>
				<div className="d-flex flex-wrap justify-content-center">
					<label
						htmlFor="onlineId"
						className={`radio-control box-item box-radio my-2 pointer text-center border-active`}
					>
						<input
							className="mx-1"
							type="radio"
							name="onlineRadios"
							id="onlineId"
							checked={true}
						/>
						<img src={masterCardImg} alt="master card" className="my-0" />
						<p>{inventory.messages.PayFromCreditCard}</p>
					</label>
				</div>

				<div className="bg-gray mx-4 my-2 px-2 py-1 price-commission-text">
					<p>
						<i className="fas fa-info mx-1"></i>{" "}
						{inventory.messages.AllPaymentsMadeByCredit}
						<small>({inventory.messages.BankFees})</small>{" "}
					</p>
				</div>

				<div className="col-12 mt-3">
					<button
						onClick={() =>
							onPayment({
								package_name:selectedItem?.name,
								package_id: selectedItem?._id,
							})
						}
						className="btn px-3 bg-nxt px-0 w-100"
					>
						{payment.messages.pay}
					</button>
				</div>
			</div>
		</CustomModal>
	);
}
