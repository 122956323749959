import React, { useEffect, useState } from 'react';
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import ServicePricing from '../ServicePricing';
import moment from 'moment';
import Locale from 'translations';
import ShowForPermission from 'helpers/showForPermission';


export default function AccountingTransportation({ isPricePerService, accountingStatement, setAccountingStatement, canEdit }) {
  const { operationStatement } = Locale;
  const [isPriceTableShown, setIsPriceTableShown] = useState(false);
  const transportation = accountingStatement?.transportation;
  const totalVehichelsCount = transportation?.vehicles?.reduce((acc, curr) => acc + (+curr?.count), 0);


  function handleInputsChanges(value, keyName) {
    let accountingStatementClone = { ...accountingStatement };
    let transportationPrices = { ...transportation };
    transportationPrices = { ...transportationPrices, pricing: { ...transportationPrices?.pricing, [keyName]: (+value).toString() } };
    accountingStatementClone = { ...accountingStatementClone, transportation: transportationPrices };
    setAccountingStatement(accountingStatementClone);
  }

  function deleteTransportationPricing() {
    let accountingStatementClone = {
      ...accountingStatement,
      transportation: {
        ...transportation,
        pricing: null
      }
    }
    setAccountingStatement(accountingStatementClone);
  }

  // show price table if there is price
  useEffect(() => {
    if (transportation?.pricing) {
      setIsPriceTableShown(true)
    }
  }, [])

  return (
    <div className='accounting-item transportations py-0'>

      <div className="accounting-item-wrapper">
        <div className="transportations-info">
          <h4 className='header-title mb-2'>{transportation?.transporter?.name}</h4>

          <div className="content">
            <div className='d-flex flex-column flex-grow-1'>
              <p className='d-flex align-items-center flex-wrap mt-2'>
                <span className='col-md-5 col-12 p-0 label'>{operationStatement.Cycletype}</span>
                <span className='col-md-7 col-12 p-0'>{transportation?.cycle_type?.name}</span>
              </p>
              <p className='d-flex align-items-center flex-wrap mt-2'>
                <span className='col-md-5 col-12 p-0 label'>{operationStatement.RequestType}</span>
                <span className='col-md-7 col-12 p-0'>{transportation?.request_type?.name}</span>
              </p>
            </div>

            <div className='d-flex flex-column flex-grow-1'>
              <p className='d-flex align-items-center flex-wrap mt-2'>
                <span className='col-md-5 col-12 p-0 label'>{operationStatement.Novehicle} </span>
                <span className='col-md-7 col-12 p-0'>{totalVehichelsCount}</span>
              </p>
            </div>
          </div>

          {/* movemtn table */}
          <div className="movements-table">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>{operationStatement.Date}</th>
                  <th>{operationStatement.Movementtype}</th>
                  <th>{operationStatement.Pickuppoint}</th>
                  <th>{operationStatement.Destination}</th>
                  <th>{operationStatement.PickupTime}</th>
                </tr>
              </thead>
              <tbody>
                {transportation?.movements?.map(movement => {
                  return (
                    <tr key={movement?.id}>
                      <td>{movement?.movement_date ? moment(movement?.movement_date).format("DD/MM/YYYY") : "-"}</td>
                      <td>{movement?.movement_type}</td>
                      <td>
                        {movement?.pickup_points?.map(point => {
                          return (
                            <div key={`point-${point?.id}`} className="mt-2">
                              {point?.title}
                            </div>
                          )
                        })}
                      </td>
                      <td>
                        {movement?.destinations?.map(destination => {
                          return (
                            <div key={`point-${destination?.id}`} className="mt-2">
                              {destination?.title}
                            </div>
                          )
                        })}
                      </td>
                      <td>{movement?.movement_date ? moment(movement?.movement_date).format("hh:mm A") : "-"}</td>
                    </tr>
                  )
                })}

              </tbody>
            </table>
          </div>
        </div>

        {isPricePerService && !isPriceTableShown ?
          <ShowForPermission permission='Manage-operation-accounting'>
            <button className="btn-plus-operation p-0 align-self-baseline" onClick={() => setIsPriceTableShown(true)}>
              <span>
                <i className="fas fa-plus"></i>
              </span>
              {operationStatement.AddBuyingSelling}
            </button>
          </ShowForPermission>
          :
          null
        }

        {isPricePerService && isPriceTableShown ?
          <div className='mt-4'>
            <ServicePricing
              type={operationStatement.Novehicle}
              typeCount={totalVehichelsCount}
              handleInputsChanges={handleInputsChanges}
              buyingPrice={transportation?.pricing?.buying_price}
              sellingPrice={transportation?.pricing?.selling_price}
              canEdit={canEdit}
            />
            {canEdit &&
              <ShowForPermission permission='Manage-operation-accounting'>
                <button className="btn delete-pricing-btn"
                  onClick={() => {
                    setIsPriceTableShown(false);
                    deleteTransportationPricing();
                  }}
                >
                  <DeleteIcon />
                  <span>{operationStatement.DeleteBuyingSelling}</span>
                </button>
              </ShowForPermission>
            }
          </div>
          :
          null
        }

      </div>
    </div>
  )
}
