import { DepartureIcon, HotelIcon, TransportationIcon } from 'modules/UmrahOperations/shared/Icons'
import React, { useEffect, useMemo, useState } from 'react'
import { CameraFlashIcon } from '../Icons'
import ItineraryDays from './ItineraryDays'
import TripPlannerItineraryFlight from './Flights'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { ReactComponent as NoActivityIcon } from "assets/images/trip-planner/no-activity.svg";
import { initialAttractionItemObj, initialFlightItemObj, initialHotelItemObj, initialTransportationItemObj } from 'modules/TripPlanner/helpers/tripPlannerLookups'
import TripPlannerItineraryHotel from './Hotels'
import TripPlannerItineraryAttraction from './Attractions'
import { useSBSState } from 'context/global'
import TripPlannerItineraryTransportation from './Transportation'
import useItinerary from './useItinerary'
import Locale from 'translations'
import { fetchVehicleTypes } from 'services/lookups'


// no - activity.svg
export default function TripPlannerItinerary({ selectedDay, setSelectedDay, tripPlannerData, setTripPlannerData }) {
  const { packagePlanner } = Locale;
  const { allCountries } = useSBSState();
  const { saveHotelItem, saveFlightItem, saveAttractionItem, saveTransportationItem, canEdit } = useItinerary({ setTripPlannerData, selectedDay, tripPlannerData });
  // states
  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false);
  const [vehiclesList, setVehiclesList] = useState([]);

  // variables
  const isSelectedDay = selectedDay?.day > 0;
  const currentSelectedDayItems = tripPlannerData.itinerary_days.find((day) => day?.day === selectedDay?.day)?.items;
  const isDaysDisabled = currentSelectedDayItems?.find(item => item?.isNew);
  const packageIsUmrahOrHajj = tripPlannerData?.packageDetails?.type?.name === "Umrah" || tripPlannerData?.packageDetails?.type?.name === "Hajj";
  const availableServicesArray = tripPlannerData?.packageDetails?.services;

  console.log(availableServicesArray);
  // toggle add new activity modal
  function toggleActivityModal() {
    setIsActivityModalOpen(!isActivityModalOpen);
  }

  // add new item to current day
  function addNewItineraryItem(itemType, initialItemObj) {
    let tripPlannerClone = { ...tripPlannerData }
    let daysClone = tripPlannerClone.itinerary_days;
    let selectedDayIndex = selectedDay?.day;
    let updatedDay = daysClone[selectedDayIndex - 1]
    updatedDay.items = [
      ...(updatedDay?.items || []),
      {
        item_type: itemType,
        ...initialItemObj,
        isNew: true,
        currentView: "form",
        sort: updatedDay?.items?.length === 0 ? 1 : updatedDay?.items[updatedDay?.items?.length - 1]?.sort + 1
      }
    ];
    setTripPlannerData(tripPlannerClone);
    setIsActivityModalOpen(false);
  }



  // handle
  function handleSortItems(currentItem, currentItemIndex, direction, currentItemType) {
    if (!selectedDay || !tripPlannerData?.itinerary_days?.length) return;

    let selectedDayIndex = selectedDay?.day - 1;
    let updatedDayItems = [...tripPlannerData.itinerary_days[selectedDayIndex]?.items];
    let replacedItemIndex;

    if (direction === "up") {
      replacedItemIndex = currentItemIndex - 1;
    } else if (direction === "down") {
      replacedItemIndex = currentItemIndex + 1;
    }
    // returen
    if (replacedItemIndex < 0 || replacedItemIndex >= updatedDayItems.length) return;

    const replacedItem = updatedDayItems[replacedItemIndex];
    const replacedItemType = replacedItem?.item_type;

    // update items sort
    currentItem.sort = currentItem?.sort + (direction === "up" ? -1 : 1);
    replacedItem.sort = replacedItem?.sort + (direction === "down" ? -1 : 1);

    const updateItemsFunctions = new Map([
      ["hotel", saveHotelItem],
      ["flight", saveFlightItem],
      ["attraction", saveAttractionItem],
      ["transportation", saveTransportationItem],
    ]);

    const updateCurrentItem = updateItemsFunctions.get(currentItemType); // Get save function based on type
    const updateReplacedItem = updateItemsFunctions.get(replacedItemType);

    if (updateCurrentItem) {
      updateCurrentItem(currentItem); // Call appropriate save function
    }
    if (updateReplacedItem) {
      updateReplacedItem(replacedItem); // Save replaced item if applicable
    }
  }


  function handleSelectDay(dayItems, day) {
    setSelectedDay({ day, dayItems })
  }
  // get countries from selected destinations
  const availableCountries = useMemo(() => {
    return allCountries?.filter(country =>
      tripPlannerData?.packageDetails?.destinations?.find(destination => destination?.id === country?.id) ? true : false);
  }, [allCountries, tripPlannerData?.packageDetails?.destinations])


  useEffect(() => {
    async function getVehicle() {
      let vehiclesListRes = await fetchVehicleTypes();
      setVehiclesList(vehiclesListRes);
    }
    if (vehiclesList.length === 0) {
      getVehicle()
    }
  }, [vehiclesList.length])

  function renderItineraryItem(item, itemIndex) {
    const itemComponent = {
      "flight":
        <TripPlannerItineraryFlight
          flight={item}
          itemIndex={itemIndex}
          selectedDay={selectedDay}
          tripPlannerData={tripPlannerData}
          setTripPlannerData={setTripPlannerData}
          allCountries={allCountries}
        />,

      "hotel":
        <TripPlannerItineraryHotel
          hotel={item}
          itemIndex={itemIndex}
          selectedDay={selectedDay}
          tripPlannerData={tripPlannerData}
          setTripPlannerData={setTripPlannerData}
          packageIsUmrahOrHajj={packageIsUmrahOrHajj}
          availableCountries={availableCountries}
        />,
      "transportation":
        <TripPlannerItineraryTransportation
          transportation={item}
          itemIndex={itemIndex}
          selectedDay={selectedDay}
          tripPlannerData={tripPlannerData}
          setTripPlannerData={setTripPlannerData}
          currentSelectedDayItems={currentSelectedDayItems}
          vehiclesList={vehiclesList}
        />,

      "attraction":
        <TripPlannerItineraryAttraction
          attractionData={item}
          itemIndex={itemIndex}
          selectedDay={selectedDay}
          tripPlannerData={tripPlannerData}
          setTripPlannerData={setTripPlannerData}
          availableCountries={availableCountries}
        />,
    }
    return itemComponent
  }


  const servicesList = useMemo(() => {
    const isServiceDisabled = isDaysDisabled || !selectedDay || !canEdit;
    let services = [
      {
        name: packagePlanner.flight,
        disabled: isServiceDisabled || (availableServicesArray?.length > 0 ? !availableServicesArray.find(service => service?.id === 1) : false),
        icon: <DepartureIcon color="#00CFE8" />,
        type: "flight",
        add: () => addNewItineraryItem("flight", initialFlightItemObj),
      },
      {
        name: packagePlanner.hotel,
        disabled: isServiceDisabled || (availableServicesArray?.length > 0 ? !availableServicesArray.find(service => service?.id === 6) : false),
        icon: <HotelIcon color="#FF9F43" />,
        type: "hotel",
        add: () => addNewItineraryItem("hotel", initialHotelItemObj),
      },
      {
        name: packagePlanner.transportation,
        disabled: isServiceDisabled || (availableServicesArray?.length > 0 ? !availableServicesArray.find(service => service?.id === 2) : false),
        icon: <TransportationIcon color="#EA5455" />,
        type: "transportation",
        add: () => addNewItineraryItem("transportation", initialTransportationItemObj),
      },
      {
        name: packagePlanner.attraction,
        disabled: isServiceDisabled || (availableServicesArray?.length > 0 ? !availableServicesArray.find(service => service?.id === 7) : false),
        icon: <CameraFlashIcon color="#7367F0" />,
        type: "attraction",
        add: () => addNewItineraryItem("attraction", initialAttractionItemObj),
      },
    ]
    return services;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableServicesArray, canEdit, isDaysDisabled, selectedDay])

  return (
    <div className="itinerary-items-wrapper">
      {/* itinerary header */}
      <ul className="itinerary-header">
        {servicesList.map(service => {
          return (
            <li>
              <button className={`service-item ${service.disabled ? "disabled" : ""}`}
                onClick={() => service.add()}
                disabled={service.disabled}
              >
                <span className={`trip-planner-icon-wrapper ${service.type}`}>
                  {service.icon}
                </span>
                {service.name}
              </button>
            </li>
          )
        })}
      </ul>


      <div className="d-flex gap-10 border-top mt-3">
        <ItineraryDays
          tripPlannerData={tripPlannerData}
          selectedDay={selectedDay}
          handleSelectDay={handleSelectDay}
          isDaysDisabled={isDaysDisabled}
          handleSortItems={handleSortItems}
          canEdit={canEdit}
        />

        <div className="d-flex flex-column flex-grow-1 gap-24 mt-4" style={{ maxWidth: 'calc(100% - 320px)' }}>
          {isSelectedDay && currentSelectedDayItems?.length > 0 ?
            currentSelectedDayItems?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {renderItineraryItem(item, index)?.[item?.item_type]}
                </React.Fragment>
              )
            })
            :
            null
          }

          {isSelectedDay && currentSelectedDayItems?.length === 0 && canEdit ?
            <div className="d-flex flex-column align-items-center gap-24 py-4 flex-grow-1">
              <NoActivityIcon />
              <span className="text-secondary">"{packagePlanner.noActivityAdded}"</span>
              <button className="btn bg-nxt px-4" onClick={toggleActivityModal}>
                {packagePlanner.addActivity}
              </button>
            </div>
            :
            null
          }
        </div>
      </div>

      <AddActivityModal
        isOpen={isActivityModalOpen}
        toggle={toggleActivityModal}
        addNewItineraryItem={addNewItineraryItem}
        servicesList={servicesList}
      />
    </div>
  )
}

export function AddActivityModal({ isOpen, toggle, servicesList }) {
  const { packagePlanner } = Locale
  return (
    <Modal isOpen={isOpen} centered size="lg" className="itinerary-add-activity-modal">
      <ModalHeader toggle={toggle}>
        {packagePlanner.addActivity}
      </ModalHeader>
      <ModalBody>
        <div className="activites-items">
          {servicesList.map(service => {
            return (
              <div className={`activity-item  ${service.disabled ? " disabled" : ""}`}
                onClick={() => service.add()}
              >
                <span className={`trip-planner-icon-wrapper ${service.type}`}>
                  {service.icon}
                </span>
                {service.name}
              </div>
            )
          })}
        </div>
      </ModalBody>
    </Modal>
  )
}
