import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import { ReactComponent as EditIcon } from "assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import { CameraFlashIcon } from '../../Icons';
import Locale from 'translations';



export default function ItineraryAttractionView({ item, setCurrentView, removeItem, canEdit }) {
  const { packagePlanner, operationStatement, companyDetails } = Locale;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="itinerary-item-view-wrapper">
      <div className="item-view">
        <div className="item-info" onClick={() => setIsOpen(!isOpen)}>
          <span className="trip-planner-icon-wrapper attraction">
            <CameraFlashIcon color="#7367F0" />
          </span>
          <p className="d-flex flex-column">
            <span className="text-capitalize">{item?.name}</span>
            <span className="secondry-text">{item?.country?.name} - {item?.city?.name}</span>
          </p>
          <span> - </span>
          <p className="d-flex flex-column">
            <span className="secondry-text">{item?.duration}</span>
          </p>
          {/* arrow */}
          {isOpen
            ? <i className="fas fa-chevron-up collapse-arrow"></i>
            : <i className="fas fa-chevron-down collapse-arrow"></i>
          }
        </div>

        {canEdit ?
          <div className="item-action-btns">
            <button className="btn shadow-none edit-btn" onClick={() => setCurrentView("form")}>
              <EditIcon />
            </button>
            <button className="btn shadow-none delete-btn" onClick={() => removeItem()}>
              <DeleteIcon />
            </button>
          </div>
          :
          null
        }

      </div>


      <Collapse isOpen={isOpen}>
        <div className="item-data attraction-data">
          <div className="d-flex gap-10 w-75">
            <span className="trip-planner-icon-wrapper attraction">
              <CameraFlashIcon color="#7367F0" />
            </span>
            <div className="d-flex flex-column">
              <span className="duration">{item?.duration}</span>
              <span className="mt-1 text-capitalize">{item?.name} ({item?.country?.name} - {item?.city?.name}) </span>
              <span className="secondry-text mt-1">{item?.meal_plan?.name}</span>
            </div>
            <div className="d-flex flex-column ml-auto">
              <p className="d-flex">
                <span className="text-secondary">{operationStatement.Pickuppoint}:</span>
                <span className="mx-1">{item?.pickup}</span>
              </p>
              <p className="d-flex mt-1">
                <span className="text-secondary">{operationStatement.DropoffPoint}:</span>
                <span className="mx-1">{item?.drop_off}</span>
              </p>
            </div>
          </div>


          {item?.photos?.length > 0 ?
            <>
              <span className="font-weight-bold mt-3">{packagePlanner.attractionImageGallery}</span>
              <div className="attraction-photos">
                {item?.photos?.map(photo => {
                  return (
                    <img src={photo} alt={item?.name} />
                  )
                })}
              </div>
            </>
            :
            null
          }

          {/* hotel description */}
          {item?.description &&
            <>
              <p className="mt-3 font-weight-bold">{companyDetails.description}:</p>
              <p className="secondry-text">
                {item?.description}
              </p>
            </>
          }

        </div>
      </Collapse>
    </div>

  )
}
