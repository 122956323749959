import React from 'react';
import { ReactComponent as DepartmentDetailsIcon } from "assets/images/umrah-operations/other-services-icon.svg";
import TextField from 'components/Form/TextField/TextField';
import SelectField from 'components/Form/SelectField/SelectField';
import NumberField from 'components/Form/NumberField/NumberField';
import Locale from 'translations';



export default function SettingsDepartmentDetails({ countriesLookup, basicSettingsData, handleInputChanges }) {
  const { operationStatement, productsBuilder } = Locale;


  return (
    <div className="settings-wrapper department-details">
      <h3 className='header-title'>
        <DepartmentDetailsIcon />
        <span>{operationStatement.departmentDetails}</span>
      </h3>
      {/*  Operations Management */}
      <div className='row mx-0 justify-content-between flex-wrap'>
        <div className="col-md-2 col-12 management-title">
          {operationStatement.operationsManagement}
        </div>
        {/* Operations Management name */}
        <div className="col-md-2 col-12">
          <TextField
            label={productsBuilder.Name}
            placeholder={operationStatement.supervisorName}
            id="operation_management_name"
            name="operation_management_name"
            value={basicSettingsData?.operation_management_name}
            onChange={(e) => handleInputChanges("operation_management_name", e.target.value)}
          />
        </div>

        {/* Operations Management phone number */}
        <div className="col-md-3 col-12 px-0">
          <div className="row mx-0">
            <PhoneWithFlagInput
              valueObject={basicSettingsData}
              countriesLookup={countriesLookup}
              handleInputChanges={handleInputChanges}
              phoneCodeKey={"operation_management_phone_code"}
              phoneKey={"operation_management_phone"}
              label={operationStatement.PhoneNumber}
            />
          </div>
        </div>

        {/* Operations Management whats app */}
        <div className="col-md-3 col-12 px-0">
          <div className="row mx-0">
            <PhoneWithFlagInput
              valueObject={basicSettingsData}
              countriesLookup={countriesLookup}
              handleInputChanges={handleInputChanges}
              phoneCodeKey={"operation_management_whats_app_phone_code"}
              phoneKey={"operation_management_whats_app_phone"}
              label={operationStatement.whatsAppNo}
            />
          </div>
        </div>

        {/* Operations Management email */}
        <div className="col-md-2 col-12">
          <TextField
            label={operationStatement.emailAddress}
            placeholder={operationStatement.emailAddress}
            id="operation_management_email"
            name="operation_management_email"
            value={basicSettingsData?.operation_management_email}
            onChange={(e) => handleInputChanges("operation_management_email", e.target.value)}
          />
        </div>
      </div>


      {/*  Reservations Management */}
      <div className='row mx-0 justify-content-between'>
        <div className="col-md-2 col-12  management-title">
          {operationStatement.reservationsManagement}
        </div>
        {/* Reservations Management name */}
        <div className="col-md-2 col-12">
          <TextField
            label={productsBuilder.Name}
            placeholder={operationStatement.supervisorName}
            id="reservation_management_name"
            name="reservation_management_name"
            value={basicSettingsData?.reservation_management_name}
            onChange={(e) => handleInputChanges("reservation_management_name", e.target.value)}
          />
        </div>

        {/* Reservations Management phone number */}
        <div className="col-md-3 col-12 px-0">
          <div className="row mx-0">
            <PhoneWithFlagInput
              valueObject={basicSettingsData}
              countriesLookup={countriesLookup}
              handleInputChanges={handleInputChanges}
              phoneCodeKey={"reservation_management_phone_code"}
              phoneKey={"reservation_management_phone"}
              label={operationStatement.PhoneNumber}
            />
          </div>
        </div>

        {/* Reservations Management whats app */}
        <div className="col-md-3 col-12 px-0">
          <div className="row mx-0">
            <PhoneWithFlagInput
              valueObject={basicSettingsData}
              countriesLookup={countriesLookup}
              handleInputChanges={handleInputChanges}
              phoneCodeKey={"reservation_management_whats_app_phone_code"}
              phoneKey={"reservation_management_whats_app_phone"}
              label={operationStatement.whatsAppNo}
            />
          </div>
        </div>

        {/* Reservations Management email */}
        <div className="col-md-2 col-12">
          <TextField
            label={operationStatement.emailAddress}
            placeholder={operationStatement.emailAddress}
            id="reservation_management_email"
            name="reservation_management_email"
            value={basicSettingsData?.reservation_management_email}
            onChange={(e) => handleInputChanges("reservation_management_email", e.target.value)}
          />
        </div>
      </div>



      {/*  Account Management */}
      <div className='row mx-0 justify-content-between'>
        <div className="col-md-2 col-12  management-title">
          {operationStatement.accountManagement}
        </div>
        {/* Account Management name */}
        <div className="col-md-2 col-12">
          <TextField
            label={productsBuilder.Name}
            placeholder={operationStatement.supervisorName}
            id="account_management_name"
            name="account_management_name"
            value={basicSettingsData?.account_management_name}
            onChange={(e) => handleInputChanges("account_management_name", e.target.value)}
          />
        </div>

        {/* Account Management phone number */}
        <div className="col-md-3 col-12 px-0">
          <div className="row mx-0">
            <PhoneWithFlagInput
              valueObject={basicSettingsData}
              countriesLookup={countriesLookup}
              handleInputChanges={handleInputChanges}
              phoneCodeKey={"account_management_phone_code"}
              phoneKey={"account_management_phone"}
              label={operationStatement.PhoneNumber}
            />
          </div>
        </div>

        {/* Account Management whats app */}
        <div className="col-md-3 col-12 px-0">
          <div className="row mx-0">
            <PhoneWithFlagInput
              valueObject={basicSettingsData}
              countriesLookup={countriesLookup}
              handleInputChanges={handleInputChanges}
              phoneCodeKey={"account_management_whats_app_phone_code"}
              phoneKey={"account_management_whats_app_phone"}
              label={operationStatement.whatsAppNo}
            />
          </div>
        </div>

        {/* Account Management email */}
        <div className="col-md-2 col-12">
          <TextField
            label={operationStatement.emailAddress}
            placeholder={operationStatement.emailAddress}
            id="account_management_email"
            name="account_management_email"
            value={basicSettingsData?.account_management_email}
            onChange={(e) => handleInputChanges("account_management_email", e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}


export function PhoneWithFlagInput({ valueObject, handleInputChanges, countriesLookup, phoneCodeKey, phoneKey, ...props }) {
  const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

  return (
    <>
      {/* phone code */}
      <div className="col-4 company_phone_code">
        <SelectField
          {...props}
          id={phoneCodeKey}
          name={phoneCodeKey}
          value={
            valueObject?.[phoneCodeKey]?.flag ?
              <img
                src={`${fetchFlag}/${valueObject?.[phoneCodeKey]?.flag}`}
                alt={valueObject?.[phoneCodeKey]?.label}
                width="35px"
              />
              :
              valueObject?.[phoneCodeKey]?.label
          }
          onChange={(e) => handleInputChanges(phoneCodeKey, e)}
          options={countriesLookup}
        />
      </div>

      {/* phone */}
      <div className="col-8 px-0">
        <NumberField
          {...props}
          type={"phone"}
          label=""
          id={phoneKey}
          name={phoneKey}
          value={valueObject?.[phoneKey]}
          removeArrow={true}
          placeholder={"000 0000 000"}
          min={3}
          onChange={(e) => handleInputChanges(phoneKey, e.target.value)}
          extraTextPosition="prepend"
          extraText={valueObject?.[phoneCodeKey]?.phone_code || valueObject?.[phoneCodeKey]?.value}

        />
      </div>
    </>
  )
}