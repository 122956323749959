import React, { useEffect, useState } from 'react'
import SelectField from 'components/Form/SelectField/SelectField'
import TextField from 'components/Form/TextField/TextField'
import { useWebBuilderState } from 'context/webBuilder';
import { MinusIcon, PlusIcon } from 'modules/UmrahOperations/shared/Icons';
import GlobalIcon from "assets/images/webBuilder/global.svg";
import EditIcon from "assets/images/webBuilder/form-edit-icon.svg";
import SmsIcon from "assets/images/webBuilder/sms.svg";
import MobileIcon from "assets/images/webBuilder/mobile-icon.svg";
import WhatsappIcon from "assets/images/webBuilder/whatsapp.svg";
import { ReactComponent as ProfileIcon } from "assets/images/webBuilder/profile.svg";
import { useParams } from "react-router-dom";
import { sendRequestPackage } from 'services/webBuilder';
import useFetchCountries from 'hooks/useFetchCountries';
import { store } from 'react-notifications-component';
import validate, { isFormValid } from 'helpers/validate';



export default function WebBuilderRequestForm({ selectedRoomType }) {
  const { id, name } = useParams();
  const { style } = useWebBuilderState();
  const [allCountries] = useFetchCountries();


  const [requestFrom, setRequestForm] = useState({
    first_name: "",
    last_name: "",
    adults: 1,
    children: 0,
    nationality: null,
    email: "",
    phone: "",
    whatsapp_number: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);


  function checkFormErrors() {
    let submitErrors = {};
    Object.keys(requestFrom).forEach(key => {
      if (key !== "message" && key !== "children") {
        submitErrors = {
          ...submitErrors,
          ...validate(
            { name: key, value: requestFrom[key] },
            {
              required: true,
              email: key === "email",
            }
          )
        }
      }
    });
    setErrors(() => submitErrors)
  }

  async function sendRequet() {
    const data = {
      package_uuid: id,
      ...requestFrom,
      phone: `${requestFrom.nationality?.phone_code}${requestFrom.phone}`,
      whatsapp_number: `${requestFrom.nationality?.phone_code}${requestFrom.whatsapp_number}`,
      nationality: requestFrom?.nationality?.label,
      adults: +requestFrom.adults,
      children: +requestFrom.children,
      room_type: selectedRoomType?.label,
    }

    if (name) {
      const res = await sendRequestPackage(name, data);
      if (res.status === 200) {
        store.addNotification({
          message: res?.data?.message || "Email Sent Successfully",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        setRequestForm({
            first_name: "",
            last_name: "",
            adults: 1,
            children: 0,
            nationality: null,
            email: "",
            phone_code: "",
            phone: "",
            whatsapp_number: "",
            message: "",
        })
      }
    }
  }
  async function onSubmitForm(e) {
    e.preventDefault();
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  useEffect(()=> {
    setRequestForm({...requestFrom, adults: 1})
  },[selectedRoomType])


  useEffect(() => {
    if (isFormValid(errors)) {
      sendRequet();
    }
  }, [isErrorLoaded]);


  return (
    <form  className="request-form">
      <div className="row mx-0">
        {/* first name */}
        <div className="col-md-6 col-12">
          <TextField
            hasLabel={false}
            id="first_name"
            name="first_name"
            placeholder="First name"
            label="First name"
            value={requestFrom.first_name}
            onChange={(e) => {
              setRequestForm({ ...requestFrom, first_name: e.target.value });
              setErrors({
                ...errors,
                ...validate(
                  { name: "first_name", value: e.target.value },
                  { required: true }
                ),
              })
            }}
            isImage
            image={EditIcon}
            prependImage
            color={errors?.first_name?.required ? "danger" : ""}
            errors={errors?.first_name}
          />
        </div>
        {/* last name */}
        <div className="col-md-6 col-12">
          <TextField
            hasLabel={false}
            id="last_name"
            name="last_name"
            placeholder="Last name"
            label="Last name"
            value={requestFrom.last_name}
            onChange={(e) => {
              setRequestForm({ ...requestFrom, last_name: e.target.value });
              setErrors({
                ...errors,
                ...validate(
                  { name: "last_name", value: e.target.value },
                  { required: true }
                ),
              })
            }}
            isImage
            image={EditIcon}
            prependImage
            color={errors?.last_name?.required ? "danger" : ""}
            errors={errors?.last_name}
          />
        </div>
        {/* adult */}
        <div className="col-md-6 col-12 mt-3 ">
          <div className="pax-box d-flex justify-content-between">
            <div className="d-flex align-items-center gap-10">
              <ProfileIcon width="25px" height="25px" />
              <span>Adults</span>
            </div>
            <div>
              {/* decrease */}
              <button type="button" className="btn px-0 shadow-none"
                onClick={() => setRequestForm({ ...requestFrom, adults: +requestFrom?.adults - 1 })}
                disabled={requestFrom.adults === 1}
              >
                <MinusIcon color={`rgb(${style?.color})`} />
              </button>
              <input
                type="number"
                id="form-adults"
                className=""
                value={requestFrom.adults}
                disabled
              />
              {/* increase */}
              <button type="button" className="btn px-0 shadow-none"
                onClick={() => setRequestForm({ ...requestFrom, adults: +requestFrom?.adults + 1 })}
                disabled={requestFrom.adults === selectedRoomType?.room_type}
              >
                <PlusIcon color={`rgb(${style?.color})`} />
              </button>
            </div>

          </div>
        </div>
        {/* children */}
        <div className="col-md-6 col-12 mt-3 ">
          <div className="pax-box d-flex justify-content-between">
            <div className="d-flex align-items-center gap-10">
              <ProfileIcon width="25px" height="25px" />
              <span>Children</span>
            </div>
            <div>
              {/* decrease */}
              <button type="button" className="btn px-0 shadow-none"
                onClick={() => setRequestForm({ ...requestFrom, children: +requestFrom?.children - 1 })}
                disabled={requestFrom.children === 0}
              >
                <MinusIcon color={`rgb(${style?.color})`} />
              </button>
              <input
                type="number"
                id="form-children"
                className=""
                value={requestFrom.children}
              />
              {/* increase */}
              <button type="button" className="btn px-0 shadow-none"
                onClick={() => setRequestForm({ ...requestFrom, children: +requestFrom?.children + 1 })}
              >
                <PlusIcon color={`rgb(${style?.color})`} />
              </button>
            </div>
          </div>
        </div>

        {/* Nationality */}
        <div className="col-md-6 col-12 mt-3">
          <SelectField
            hasLabel={false}
            id="nationality"
            name="nationality"
            placeholder=" Choose Nationality"
            label="Nationality"
            options={allCountries}
            value={requestFrom.nationality?.name || ""}
            onChange={(e) => {
              setRequestForm({ ...requestFrom, nationality: e, })
              setErrors({
                ...errors,
                ...validate(
                  { name: "nationality", value: e },
                  { required: true }
                ),
              })
            }}
            isImage
            image={GlobalIcon}
            prependImage
            color={errors?.nationality?.required ? "danger" : ""}
            errors={errors?.nationality}
          />
        </div>

        {/* email */}
        <div className="col-md-6 col-12 mt-3">
          <TextField
            type="email"
            hasLabel={false}
            id="form-email"
            name="form-email"
            placeholder="Email"
            label="Email"
            value={requestFrom.email}
            isImage
            image={SmsIcon}
            prependImage
            onChange={(e) => {
              setRequestForm({ ...requestFrom, email: e.target.value });
              setErrors({
                ...errors,
                ...validate(
                  { name: "email", value: e.target.value },
                  { required: true, email: true }
                ),
              });
            }}
            color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
            errors={errors?.email}
          />
        </div>

        {/* phone  */}
        <div className="col-md-6 col-12 mt-3">
          <TextField
            type="number"
            hasLabel={false}
            id="form-phone"
            name="form-phone"
            placeholder="Phone"
            label="Phone"
            value={requestFrom.phone}
            isImage
            image={MobileIcon}
            prependImage
            extraText={requestFrom.nationality?.phone_code || "---"}
            extraTextPosition="prepend"
            onChange={(e) => {
              setRequestForm({ ...requestFrom, phone: e.target.value });
              setErrors({
                ...errors,
                ...validate(
                  { name: "phone", value: e.target.value },
                  { required: true }
                ),
              });
            }}
            color={errors?.phone?.required ? "danger" : ""}
            errors={errors?.phone}
          />
        </div>
        {/* whats app */}
        <div className="col-md-6 col-12 mt-3">
          <TextField
            type="number"
            hasLabel={false}
            id="form-whatsapp_number"
            name="form-whatsapp_number"
            placeholder="Whatsapp"
            label="Whatsapp"
            value={requestFrom.whatsapp_number}
            isImage
            image={WhatsappIcon}
            prependImage
            extraText={requestFrom.nationality?.phone_code || "---"}
            extraTextPosition="prepend"
            onChange={(e) => {
              setRequestForm({ ...requestFrom, whatsapp_number: e.target.value });
              setErrors({
                ...errors,
                ...validate(
                  { name: "whatsapp_number", value: e.target.value },
                  { required: true }
                ),
              });
            }}
            color={errors?.whatsapp_number?.required ? "danger" : ""}
            errors={errors?.whatsapp_number}
          />
        </div>
        <div className="col-12 mt-3">
          <textarea
            id="form-notes"
            name="form-notes"
            cols="30"
            rows="6"
            className="textarea"
            placeholder="Note.."
            value={requestFrom.message}
            onChange={(e) => setRequestForm({ ...requestFrom, message: e.target.value })}
          />
        </div>
        {/* submit btn */}
        <div className="col-md-3 col-12 mt-3">
          <button type="button" className="btn submit-btn" style={{ backgroundColor: style?.color }} onClick={onSubmitForm}>
            Send request
          </button>
        </div>

      </div>
    </form>
  )
}
