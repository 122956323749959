import usePreviewMode from 'hooks/usePreviewMode'
import EditableParagraph from 'modules/WebBuilder-V2/shared/EditableParagraph';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import Locale from 'translations';
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import UploadFileComponent from 'modules/WebBuilder-V2/shared/UploadFile';
import ToggleCheckbox from 'modules/WebBuilder-V2/shared/ToggleCheckbox';

export default function ThemeSevenServiceCard(props) {
  const {isPreview} = usePreviewMode();
  const { webBuilder } = Locale;
  const location = useLocation();

  const preview = !location.pathname.includes("edit");


  const logoImage = props.image ? <img src={props.image} alt="Logo" /> : null;
	const logo = preview ? (
		logoImage
	) : (
		<UploadFileComponent
			icon={AddIcon}
			id={`${props.type}-service`}
			label=""
			onUpload={(value) => props.editCardDetails(value, "icon")}
			image={logoImage}
			// disabled={isTransfer}
		/>
	);

  return (
    <div className="theme_service_card">
      <div className="image">
        {/* <img
          src={props.image ? props.image : null}
          alt={props.title ? props.title : 'Service Title'}
        /> */}
        {logo}
      </div>
      <div className="text">
        <p className="sub text-capitalize">{webBuilder[props.type]}</p>

        <EditableParagraph
          className="title"
          contentEditable={true}
          onInput={(value) => {
            props.onChangeTitle(value, "header");
          }}
          text={props.header}
        />
      </div>
      {!isPreview ? (
        <ToggleCheckbox
          checked={props.visible}
          onChange={(e) => {
            const value = e.target.checked;
            props.editCardDetails(value, "visible");
          }}
          label="Visible"
        />
      ) : null}
    </div>
  )
}