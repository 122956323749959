import React, { memo, useState } from "react";
import RoomTypeBadge from "../shared/RoomTypeBadge";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import { ReactComponent as MaleIcon } from "assets/images/hotelAccommodation/male.svg";
import { ReactComponent as FemaleIcon } from "assets/images/hotelAccommodation/female.svg";
import { ReactComponent as UnselectIcon } from "assets/images/hotelAccommodation/unselect.svg";
import SelectField from "components/Form/SelectField/SelectField";
import { useDrop } from "react-dnd";
import { MEALS_TYPE, ROOMS_TYPES } from "../helpers/RoomsTypes";
import {
	useProductsBuilderDispatch,
	useProductsBuilderState,
} from "context/productsBuilder";
import Locale from "translations";

function RoomCard({ roomDetails }) {
	const { marketPlace } = Locale;
	const dispatch = useProductsBuilderDispatch();
	const { selected_hotel_accommodation_id: selectedHotelId } =
		useProductsBuilderState();
	const { travelers, room_type, id, meal_name, room_name, room_number } =
		roomDetails;

		const travelersLength = travelers?.length 

	const onDrop = (item) => {
		dispatch({
			type: "addTravelerToRoom",
			payload: {
				travelerId: item.id,
				roomId: id,
			},
		});
	};

	const canDropMethod = (item) => {
		const adultCount =
			travelers &&
			travelers.reduce((prev, traveler) => {
				if (traveler.is_adult) {
					return prev + 1;
				} else {
					return prev;
				}
			}, 0);
		if (item.type === "child") {
			return adultCount >= 1 ? true : false;
		} else {
			if (adultCount === room_type) return false;
		}

		return true;
	};

	const [{ isOver, canDrop }, drop] = useDrop(
		{
			canDrop: canDropMethod,
			accept: ROOMS_TYPES[room_type],
			drop: onDrop,
			collect: (monitor) => ({
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop(),
			}),
		},
		[selectedHotelId, room_type, id, travelers]
	);

	const deleteAllTraveler = () => {
		dispatch({
			type: "deleteAllTraveler",
			payload: {
				roomTravelers: travelers,
				roomId: id,
			},
		});
	};

	const updateRoomDetails = (name, value) => {
		dispatch({
			type: "updateRoomDetails",
			payload: {
				value,
				name,
				roomId: id,
			},
		});
	};

	const deleteTraveler = (travelerId, is_adult) => {
		const adultsCount = travelers?.reduce((prev, current) => {
			if (current.is_adult) {
				return ++prev;
			} else {
				return prev;
			}
		}, 0);

		if (adultsCount === 1 && is_adult) {
			deleteAllTraveler();
			return;
		}
		dispatch({
			type: "deleteTravelerFromRoom",
			payload: {
				travelerId,
				roomId: id,
			},
		});
	};

	const isActive = isOver && canDrop;

	let style = {};
	if (isActive) {
		style = {
			backgroundColor: "#faf4eb",
			border: "2px dashed gray",
		};
	} else if (canDrop) {
		style = {
			backgroundColor: "#faf4eb",
			border: "2px dashed gray",
		};
	}

	const onChangeRoomNumber = (e) =>
		updateRoomDetails("room_number", e.target.value);
	const onChangeRoomName = (e) =>
		updateRoomDetails("room_name", e.target.value);
	return (
		<div className="room-card">
			<div className="room-card-header">
				<RoomTypeBadge roomType={room_type} />
				<div className="room-details">
					<HeaderField
						label={room_number}
						labelClass="room-number"
						placeholder="Room Number"
						onChange={onChangeRoomNumber}
					/>
					<HeaderField
						label={room_name}
						labelClass="room-name"
						placeholder="Room Name"
						onChange={onChangeRoomName}
					/>
				</div>
				<button className='btn' disabled={!travelersLength} onClick={deleteAllTraveler}>

				<TrashIcon role="button"  />
				</button>
			</div>

			<div
				className="room-card-body"
				ref={drop}
				style={{ ...style }}
				data-testid="dustbin"
			>
				{travelers?.length > 0
					? travelers.map((traveler) => (
							<div key={traveler.id} className="traveler-details">
								{traveler.gender === "M" ? (
									<MaleIcon className="person-icon" />
								) : (
									<FemaleIcon className="person-icon" />
								)}
								<div className="traveler-type">
									<span className="traveler-name">{`${traveler.first_name} ${traveler.last_name}`}</span>
									<span className="person-type">
										{traveler.is_adult
											? marketPlace.adult
											: marketPlace.children}
									</span>
								</div>
								<UnselectIcon
									role="button"
									onClick={() => deleteTraveler(traveler.id, traveler.is_adult)}
								/>
							</div>
					  ))
					: null}
			</div>

			<div className="room-card-footer">
				<hr className="my-3" />
				<div className="meal-type">
					<span>Meal type: </span>
					<SelectField
						class="meal-type-input"
						hasLabel={false}
						placeholder={"Select Meal"}
						options={MEALS_TYPE}
						value={meal_name}
						onChange={(e) => updateRoomDetails("meal_name", e.name)}
					/>
				</div>
			</div>
		</div>
	);
}

export default memo(RoomCard);

const HeaderField = ({ onChange, label, labelClass, placeholder }) => {
	const [showInput, setShowInput] = useState(false);
	const onClickLabel = () => setShowInput(true);
	const onCheck = () => setShowInput(false);

	return showInput ? (
		<div className="room-info">
			<input
				type="text"
				name={labelClass}
				placeholder={placeholder}
				onChange={onChange}
				value={label}
			/>
			<span className="input-check" onClick={onCheck}>
				<i class="fas fa-check"></i>
			</span>
		</div>
	) : (
		<div className={labelClass} onClick={onClickLabel}>
			{label || placeholder}
		</div>
	);
};
