import { store } from 'react-notifications-component';
import { ReactComponent as OperatingHotelsIcon } from "assets/images/umrah-operations/hotels.svg";
import { ReactComponent as OperatingArrivalDepartureIcon } from "assets/images/umrah-operations/arrival-departure.svg";
import { ReactComponent as OperatingAttractionIcon } from "assets/images/umrah-operations/attraction-icon.svg";
import { ReactComponent as OperatingTransportationIcon } from "assets/images/umrah-operations/car-icon.svg";
import { ReactComponent as OperatingVisaIcon } from "assets/images/umrah-operations/visa-icon.svg";
import { ReactComponent as OperatingOtherIcon } from "assets/images/umrah-operations/other-services-icon.svg";
import { ReactComponent as OperatingCateringIcon } from "assets/images/umrah-operations/catering.svg";
import Locale from 'translations';
import { getOperationsLookup } from './operationsLookup';




export function renderOperationTabsItems(id, accountingStatement) {
  const { operationStatement } = Locale;

  if (id && accountingStatement) {
    return [
      {
        title: operationStatement.ArrivalDeparture,
        icon: <OperatingArrivalDepartureIcon />,
        url: "accounting-arrival-departure",
        disabled: id && accountingStatement?.trip ? false : true,
      },
      {
        title: operationStatement.hotel,
        icon: <OperatingHotelsIcon />,
        url: "accounting-hotels",
        disabled: id && accountingStatement?.hotels.length > 0 ? false : true,
      },
      {
        title: operationStatement.catering,
        icon: <OperatingCateringIcon />,
        url: "accounting-catering",
        disabled: id && accountingStatement?.catering.length > 0 && accountingStatement?.catering_type === 1 ? false : true,
        hidden: accountingStatement?.catering_type === 0
      },
      {
        title: operationStatement.attractions,
        icon: <OperatingAttractionIcon />,
        url: "accounting-attraction",
        disabled: id && accountingStatement?.attractions.length > 0 ? false : true,
      },
      {
        title: operationStatement.transportation,
        icon: <OperatingTransportationIcon />,
        url: "accounting-transportation",
        disabled: id && accountingStatement?.transportation ? false : true,
      },
      {
        title: operationStatement.visa,
        icon: <OperatingVisaIcon />,
        url: "accounting-visa",
        disabled: id && accountingStatement?.visas.length > 0 ? false : true,
      },
      {
        title: operationStatement.otherServices,
        icon: <OperatingOtherIcon />,
        url: "accounting-other",
        disabled: id && accountingStatement?.other_services.length > 0 ? false : true,
      },
    ];
  }
  return []
}

export function formatViewAccounting(data) {
  const { cycleTypesLookup, requestTypeLookup, citiesLookup } = getOperationsLookup(Locale);
  const trip = data?.trip;
  return {
    ...data,
    trip: trip ? {
      ...trip,
      pricing: {
        ...trip?.pricing,
        buying_adult_total: (trip?.pricing?.buying_adult_price || 0) * (data?.adults || 0),
        buying_child_total: (trip?.pricing?.buying_child_price || 0) * (data?.children || 0),
        buying_infant_total: (trip?.pricing?.buying_infant_price || 0) * (data?.infants || 0),
        selling_adult_total: (trip?.pricing?.selling_adult_price || 0) * (data?.adults || 0),
        selling_child_total: (trip?.pricing?.selling_child_price || 0) * (data?.children || 0),
        selling_infant_total: (trip?.pricing?.selling_infant_price || 0) * (data?.infants || 0),
      },
    } : null,
    attractions: data?.attractions?.map(attraction => {
      return {
        ...attraction,
        city: citiesLookup.find(city => city?.id === attraction?.city_id),
        pricing: attraction?.pricing ? {
          buying_price: attraction?.pricing?.buying_price || null,
          selling_price: attraction?.pricing?.selling_price || null,
        } : null,
      }
    }),
    transportation: data?.transportation ? {
      ...data?.transportation,
      cycle_type: cycleTypesLookup.find(type => type.id === data?.transportation?.cycle_type),
      request_type: requestTypeLookup.find(type => type.id === data?.transportation?.request_type),
      pricing: data?.transportation?.pricing ? {
        buying_price: data?.transportation?.pricing?.total_buying_price || 0,
        selling_price: data?.transportation?.pricing?.total_selling_price || 0,
      } : null,
    } : null,

    per_all_attraction_pricing: {
      buying_price: data?.per_all_attraction_pricing?.buying_price,
      selling_price: data?.per_all_attraction_pricing?.selling_total,
    },

    visas: data?.visas?.map(visa => {
      const totalPaxCount = (visa?.adults || 0) + (visa?.children || 0) + (visa?.infants || 0);
      return {
        ...visa,
        pricing: visa?.pricing ? {
          total_buying_price: (visa?.pricing?.buying_price * totalPaxCount) || 0,
          total_selling_price: (visa?.pricing?.selling_price * totalPaxCount) || 0,
          buying_price: visa?.pricing?.buying_price || 0,
          selling_price: visa?.pricing?.selling_price || 0,
        } : null,
        total_pax: totalPaxCount,
      }
    }),

    hotels: data?.hotels.length > 0 ? data?.hotels.map((hotel) => {
      let rooms = [];
      Object.keys(hotel.rooms_count).map((key) =>
        hotel.rooms_count[key] !== 0
          ? (rooms = [
            ...rooms,
            {
              room_type: key,
              room_count: hotel.rooms_count[key],
              buying_price:
                hotel?.pricing !== null &&
                  hotel?.pricing?.filter((item) => item.room_type === key)[0]
                    ?.buying_price ? hotel?.pricing?.filter((item) => item.room_type === key)[0]
                  ?.buying_price : "",
              selling_price:
                hotel?.pricing !== null &&
                  hotel?.pricing?.filter((item) => item.room_type === key)[0]
                    ?.selling_price ? hotel?.pricing?.filter((item) => item.room_type === key)[0]
                  ?.selling_price : "",
              total_buying_price: hotel?.pricing !== null && (hotel?.pricing?.filter((item) => item.room_type === key)[0]
                ?.buying_price ? hotel?.pricing?.filter((item) => item.room_type === key)[0]
                ?.buying_price : 0) * hotel.rooms_count[key],


              total_selling_price: hotel?.pricing !== null && (hotel?.pricing?.filter((item) => item.room_type === key)[0]
                ?.selling_price ? hotel?.pricing?.filter((item) => item.room_type === key)[0]
                ?.selling_price : 0) * hotel.rooms_count[key]
            },
          ])
          : (rooms = [...rooms])
      );
      return { ...hotel, rooms };
    }) : [],
    other_services: data?.other_services?.map(service => {
      return {
        ...service,
        pricing: service?.pricing ? {
          buying_price: service?.pricing?.buying_price || null,
          selling_price: service?.pricing?.selling_price || null,
        } : null,
      }
    }),
    total_package_buying: data?.buying_cost,
    total_package_selling: data?.selling_cost,
    attraction_pricing: data?.attraction_pricing || "all"
  }
}

export function calculateAllService(accountingStatement, isPricePerService) {
  let totalBuying = 0;
  let totalSelling = 0
  // if pricing type is per service
  if (isPricePerService) {
    // trip
    let tripTotalBuying =
      (+accountingStatement?.trip?.pricing?.buying_adult_total || 0) +
      (+accountingStatement?.trip?.pricing?.buying_child_total || 0) +
      (+accountingStatement?.trip?.pricing?.buying_infant_total || 0);
    let tripTotalSelling =
      (+accountingStatement?.trip?.pricing?.selling_adult_total || 0) +
      (+accountingStatement?.trip?.pricing?.selling_child_total || 0) +
      (+accountingStatement?.trip?.pricing?.selling_infant_total || 0);

    // hotels
    let totalHotelsBuying = 0;
    let totalHotelsSelling = 0;
    for (let index = 0; index < accountingStatement?.hotels?.length; index++) {
      const hotelPricing = accountingStatement?.hotels[index]?.rooms;
      if (!hotelPricing) break;
      totalHotelsBuying = totalHotelsBuying + hotelPricing?.reduce((acc, curr) => acc + (+curr?.total_buying_price || 0), 0) || 0;
      totalHotelsSelling = totalHotelsSelling + hotelPricing?.reduce((acc, curr) => acc + (+curr?.total_selling_price || 0), 0) || 0;
    }

    // Attractions 
    let attractionsTotalBuying = 0;
    let attractionsTotalSelling = 0;
    if (accountingStatement?.attraction_pricing === "all") {
      attractionsTotalBuying = +accountingStatement?.per_all_attraction_pricing?.buying_price || 0;
      attractionsTotalSelling = +accountingStatement?.per_all_attraction_pricing?.selling_price || 0;
    } else {
      attractionsTotalBuying = accountingStatement?.attractions?.reduce((acc, curr) => acc + (+curr?.pricing?.buying_price || 0), 0) || 0;
      attractionsTotalSelling = accountingStatement?.attractions?.reduce((acc, curr) => acc + (+curr?.pricing?.selling_price || 0), 0) || 0;
    }

    // Transportation
    let transportationTotalBuying = +accountingStatement?.transportation?.pricing?.buying_price || 0;
    let transportationTotalSelling = +accountingStatement?.transportation?.pricing?.selling_price || 0;
    // visas 
    let visasTotalBuying = accountingStatement?.visas?.reduce((acc, curr) => acc + (+curr?.pricing?.total_buying_price || 0), 0) || 0;
    let visasTotalSelling = accountingStatement?.visas?.reduce((acc, curr) => acc + (+curr?.pricing?.total_selling_price || 0), 0) || 0;

    // other Services
    let otherServicesTotalBuying = accountingStatement?.other_services?.reduce((acc, curr) => acc + (+curr?.pricing?.buying_price || 0), 0) || 0;
    let otherServicesTotalSelling = accountingStatement?.other_services?.reduce((acc, curr) => acc + (+curr?.pricing?.selling_price || 0), 0) || 0;


    //catering 
    let totalCateringBuying = 0;
    let totalCateringSelling = 0;
    for (let index = 0; index < accountingStatement?.catering?.length; index++) {
      const cateringPricing = accountingStatement?.catering[index]?.catering;
      if (!cateringPricing) break;
      totalCateringBuying = totalCateringBuying + cateringPricing?.reduce((acc, curr) => acc + (+curr?.pricing?.total_buying_price || 0), 0) || 0;
      totalCateringSelling = totalCateringSelling + cateringPricing?.reduce((acc, curr) => acc + (+curr?.pricing?.total_selling_price || 0), 0) || 0;
    }

    totalBuying = (tripTotalBuying + totalHotelsBuying + attractionsTotalBuying + transportationTotalBuying + visasTotalBuying + otherServicesTotalBuying + totalCateringBuying) || 0;
    totalSelling = (tripTotalSelling + totalHotelsSelling + attractionsTotalSelling + transportationTotalSelling + visasTotalSelling + otherServicesTotalSelling + totalCateringSelling) || 0;

  } else {
    totalBuying = accountingStatement?.total_package_buying || 0;
    totalSelling = accountingStatement?.total_package_selling || 0;
  }
  return {
    totalBuying,
    totalSelling,
  }
}

export function handleResponesNotification({ alertType, message, title }) {
  store.addNotification({
    title: title || "",
    message: message,
    type: alertType,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
      pauseOnHover: true,
    },
  });
}
