import React from "react";

export default function OverviewIcon({ color }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<rect
				x="3.5"
				y="4"
				width="18"
				height="16"
				rx="3"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<rect
				x="3.5"
				y="4"
				width="18"
				height="16"
				rx="3"
				stroke="white"
				stroke-opacity="0.2"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<circle
				cx="9.5"
				cy="10"
				r="2"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<circle
				cx="9.5"
				cy="10"
				r="2"
				stroke="white"
				stroke-opacity="0.2"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M15.5 8H17.5"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M15.5 8H17.5"
				stroke="white"
				stroke-opacity="0.2"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M15.5 12H17.5"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M15.5 12H17.5"
				stroke="white"
				stroke-opacity="0.2"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7.5 16H17.5"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7.5 16H17.5"
				stroke="white"
				stroke-opacity="0.2"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
