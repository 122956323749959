import image1 from 'assets/images/webBuilder/background/1.jpg'
import image2 from 'assets/images/webBuilder/background/2.jpg'
import image3 from 'assets/images/webBuilder/background/3.jpg'
import image4 from 'assets/images/webBuilder/background/4.jpg'
import image5 from 'assets/images/webBuilder/background/5.jpg'
import image6 from 'assets/images/webBuilder/background/6.jpg'
import image7 from 'assets/images/webBuilder/background/7.jpg'

export const backgrounds = [
	{ id: 1, src: image1 },
	{ id: 2, src: image2 },
	{ id: 3, src: image3 },
	{ id: 4, src: image4 },
	{ id: 5, src: image5 },
	{ id: 6, src: image6 },
	{ id: 7, src: image7 }
]
