import { SBSProvider } from "context/global";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import App from "./App";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";

const tagManagerArgs = {
	gtmId: "GTM-MQX9NBZ",
};

// console.log("test");
// 	Sentry.init({
// 				dsn: "https://9cd1054574799188283b673a69aafab6@o4505985431699456.ingest.sentry.io/4505985450704896",
// 				integrations: [
// 					new Sentry.BrowserTracing({
// 						// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
// 						tracePropagationTargets: [process.env.REACT_APP_API_URL],
// 					}),
// 					new Sentry.Replay(),
// 				],
// 				// Performance Monitoring
// 				tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
// 				// Session Replay
// 				replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 				replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// 			});
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
	<React.StrictMode>
		<SBSProvider>
			<Provider store={store}>
				<App />
			</Provider>
		</SBSProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
