import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import reservationError from "assets/images/inventory/error.png";
import Locale from 'translations';
import { useHistory } from 'react-router-dom';

export default function ToursErrorModal({ isOpen, toggleModal, message, withButton }) {
  const { commons, marketPlace } = Locale;
  const history = useHistory();

  return (
    <Modal isOpen={isOpen} size="md" >
      <ModalHeader toggle={toggleModal}>
        {commons.somethingWentWrong}
      </ModalHeader>
      <ModalBody>
        <div className="container d-flex flex-column align-items-center ">
          <div class="text-center" role="alert">
            <img src={reservationError} alt="error message" />
            <p className="m-0 mt-3 h5 text-gray py-4">
              {message}
            </p>
          </div>
          <div className="box">
            <p className="title-modal-sm"></p>
          </div>
          {withButton &&
            <button className="btn bg-nxt" onClick={() => history.push('/market-place-new')}>
              {marketPlace.gotoMarketPlace}
            </button>
          }
        </div>
      </ModalBody>
    </Modal>
  )
}
