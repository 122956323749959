import React, { useState } from 'react'
import { Collapse, Modal, ModalBody, ModalHeader } from 'reactstrap';
import RequestItemHeader from './RequestItemHeader';
import Locale from 'translations';
import axios from 'axios';
import { flightRefundPendingApproval } from 'services/inventory';
import { store } from 'react-notifications-component';
import questionMarkIcon from 'assets/images/icons/question-mark.svg'

export default function RefundRequest({ request, fetchRequest }) {
  const { inventory, interests } = Locale;
  const [isOpen, setIsOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  function toggleCollapse() {
    setIsOpen(!isOpen);
  }

  function toggleConfirmModal() {
    setConfirmModalOpen(!confirmModalOpen);
  }

  async function handleChangeStatus(action) {
    const data = {
      request_id: request?.id,
      action: action
    }
    const changeStatusRes = await flightRefundPendingApproval(data);
    if (changeStatusRes?.status === 200) {
      fetchRequest();
      store.addNotification({
        title: interests.messages.updateSuccessfully,
        message: interests.messages.updateSuccessfully,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      setConfirmModalOpen(false);
    }
  }

  function downloadCertFiles() {
    
    const certFiles = request?.certification_files;
    const fetchFilesURL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
    const config = {
      fetchFilesURL,
      method: "GET",
      responseType: "blob",
    };
    for (let i = 0; i < certFiles.length; i++) {
      axios.get(`${fetchFilesURL}/${certFiles[i]}`, config).then((res) => {
        const blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        const objectUrl = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.target = "_blank";
        anchor.href = objectUrl;
        anchor.setAttribute(
          "download",
          `${certFiles[i]}.${res.data.type.split("/")[1]}`
        );
        anchor.click();
      });
    }
  }

  // "pending" | "rejected" | "pending approval" | "under review" | "confirmed";
  const status = request?.status;

  return (
    <div className='my-3'>
      <RequestItemHeader
        isOpen={isOpen}
        toggleCollapse={toggleCollapse}
        requestTypeText={inventory.messages.refundRequest}
        status={status}
        details={request}
      />

      <Collapse isOpen={isOpen}>
        <div className='flight-requests-details'>
          <div className="row m-0">

            <div className="col-md-6 col-12">

              {/* passengers data */}
              <div className='d-flex flex-column'>
                <h2 className='text-capitalize blue'>{inventory.messages.passengers}</h2>
                {request?.passengers?.map(passenger => {
                  return (
                    <div className='flight-requests-refund-passenger'>
                      <div className="col-4">
                        <i className="far fa-user-circle"></i>
                        <span className='mx-1'>{passenger?.passenger_info?.full_name}</span>
                      </div>
                      <span className="col-3">{passenger?.passenger_info?.type}</span>
                      {/* <span className="col-5">Ticket No. {passenger?.ticketNumber}</span> */}
                    </div>
                  )
                })}
              </div>

              {/* refund reason */}
              <div className='mt-3'>
                <h3 className='my-2 text-capitalize blue'>{inventory.messages.refundReason}</h3>
                <div className='d-flex align-items-center justify-content-between'>
                  <span className='flight-requests-box p-1'>
                    {request?.reason}
                  </span>

                  {request?.certification_files &&
                    request?.certification_files?.length > 0 &&
                    <button className="download-cert" onClick={downloadCertFiles}>
                      <i class="fas fa-download"></i>
                      <span className='mx-1'>
                        {inventory.messages.downloadCertificationFile}
                      </span>
                    </button>
                  }

                </div>
                <p className='flight-requests-box p-1 mt-2 lh-1'>
                  {request?.remarks}
                </p>
              </div>

            </div>

            <div className="col-md-6 col-12">
              {/* contact info */}
              <RequestContactInfo requestDetails={request} />
              
              {/* actions and status */}
              {status === "pending_approval" &&
                <div className='flight-requests-refund-amount p-2 mt-3'>

                  <h3 className='text-capitalize mb-2'>{inventory.messages.refundAmount}</h3>

                  <p className='d-flex align-items-center justify-content-between font-weight-bold px-3 py-2 bg-white'>
                    <span>{inventory.messages.Amount}</span>
                    <span className='text-danger'>{request?.amount} {request?.currency}</span>
                  </p>

                  <div className="flight-requests-refund-actions mt-2">
                    <button className='btn reject-btn mx-3' onClick={() => handleChangeStatus('reject')}>
                      {inventory.messages.reject}
                    </button>
                    <button className='btn accept-btn' onClick={toggleConfirmModal}>
                      {inventory.messages.confirm}
                    </button>
                  </div>
                </div>
              }

              {(status === "waiting_approval"||status === "confirmed" )&&
                <div className='flight-requests-refund-amount p-2 mt-3'>
                  <h3 className='text-capitalize mb-2'>{inventory.messages.refundAmount}</h3>
                  <p className='d-flex align-items-center justify-content-between font-weight-bold px-3 py-2 bg-white'>
                    <span>{inventory.messages.Amount}</span>
                    <span className='text-danger'>{request?.amount} {request?.currency}</span>
                  </p>
                </div>
              }

              {status === "rejected" && request?.reject_reason &&
                <div className='flight-requests-danger-box py-1 px-2 mt-3'>
                  <h3 className='text-capitalize my-2'>{inventory.messages.refundRejectReason}</h3>
                  <p>
                    {request?.reject_reason}
                  </p>
                </div>
              }
              

            </div>
          </div>
        </div>
      </Collapse>

      {/* confirm request modal */}
      {toggleConfirmModal &&
        <Modal isOpen={confirmModalOpen} centered={true}>
          <ModalHeader toggle={toggleConfirmModal}>
            <span>{inventory.messages.confirmRequest}</span>
          </ModalHeader>
          <ModalBody>
            <div className='p-3 text-center'>
              <img src={questionMarkIcon} alt="question mark icon" />
              <p className='py-4 h5'>{inventory.messages.youSureConfirmRequest}</p>
              <button className='flight-checkout-btn w-100 font-weight-bold border-0 rounded'
                onClick={() => handleChangeStatus('accept')}
              >
                {inventory.messages.confirm}
              </button>
            </div>
          </ModalBody>
        </Modal>
      }
    </div>
  )
}


export function RequestContactInfo({ requestDetails }) {
  const { inventory, payment } = Locale;
  return (
    <>
      <h2 className='text-capitalize blue'>{inventory.messages.contact}</h2>
      <div className='flight-requests-contact'>
        <p className='d-flex flex-wrap justify-content-between align-content-center my-1'>
          <span className='font-weight-bold'>{inventory.messages.contactName}</span>
          <span>{requestDetails?.contact_info?.full_name}</span>
        </p>
        <p className='d-flex flex-wrap justify-content-between align-content-center my-1'>
          <span className='font-weight-bold'>{payment.messages.Email}</span>
          <span>{requestDetails?.contact_info?.email}</span>
        </p>
        <p className='d-flex flex-wrap justify-content-between align-content-center my-1'>
          <span className='font-weight-bold'>{inventory.messages.cellphone}</span>
          <span>
            {requestDetails?.contact_info?.phone_code}
            {requestDetails?.contact_info?.phone}{" "}
          </span>
        </p>
      </div>
    </>
  )
}