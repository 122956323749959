import React, { useEffect, useState } from "react";

import { useMarketplaceState } from "context/marketplace";
import { useSBSState } from "context/global";
import Locale from "translations";
import { useHistory } from "react-router-dom";
import ContactInformation from "./ContactInformation";
import validate, { isFormValid } from "helpers/validate";
import { PackageCheckAvailability, PackagesBooking } from "services/marketplace";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import NewPaymentAction from "components/NewPaymentAction";
import { formatPrice } from 'helpers/utils';

export default function CheckoutPackage() {
	const { packages: t } = Locale;
	let history = useHistory();

	const { PackagesDetails: contextPackageDetails } = useMarketplaceState();

	const PackagesDetails = contextPackageDetails ?? JSON.parse(localStorage.getItem("PackagesDetails"));
	const { allCountries } = useSBSState();

	const { uuid } = useParams();

	const [countries, setCountries] = useState([]);
	const [leaderData, setLeaderData] = useState({
		name: "",
		email: "",
		phoneCode: null,
		phone: ""
	})
	const [packageAvailability, setPackageAvailability] = useState(null);
	const [actionType, setActionType] = useState("");
	const [formData, setFormData] = useState({});
	const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK ? JSON.parse(process.env.REACT_APP_OTP_CHECK) : false;

	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const checkFormErrors = () => {
		let submitError = {};
		Object.keys(leaderData).forEach((key) => {
			submitError = {
				...submitError,
				...validate(
					{ name: key, value: leaderData[key] },
					{ required: true, email: key === "email" ? true : false, }
				),
			};
		})
		setErrors(submitError);
	};
	// countries lookups
	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries?.map((country) => ({
				id: country.id,
				label: country.name,
				name: country.name,
				code: country.country_code,
				phone_code: country.phone_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);


	async function bookOnPayment(payment_method, currency) {
		let calcAdultPax = PackagesDetails?.roomsData?.adult?.reduce((accumulator, currentValue) => accumulator + (+currentValue?.roomCount * +currentValue?.roomType?.room_type), 0,)||0
		let calcChildtPax = PackagesDetails?.roomsData?.children?.reduce((accumulator, currentValue) => accumulator + (+currentValue?.childCount), 0)||0
		let pax = {
			adults_count: +PackagesDetails.paxCount.adultPax || calcAdultPax,
			children_count: +PackagesDetails.paxCount.childsPax || calcChildtPax,
		};
		let rooms;
		let rooms_availability;

		if (PackagesDetails?.reservationCategory?.value === "shared") {
			rooms_availability = [...PackagesDetails?.roomsData.adults, ...PackagesDetails?.roomsData.children].map(room => ({
				combination_id: room?.combination_id,
				is_adult: room?.is_adult ? 1 : 0
			}))
		} else {
			rooms = PackagesDetails?.roomsData?.filter(room => room?.combination_id)?.map(room => {
				return {
					combination_id: room?.combination_id,
					rooms_count: +room?.roomCount,
					children_count: +room?.childCount,
				}
			});
		}


		let data = {
			product_uuid: uuid,
			payment_method: payment_method,
			currency: currency,
			leader_info: {
				name: leaderData?.name,
				email: leaderData?.email,
				phone: `${leaderData?.phoneCode?.phone_code}${leaderData?.phone}`
			},
			pax,

			reservation_category: PackagesDetails?.reservationCategory?.value || "shared"
		};
		data = { ...data, ...(PackagesDetails?.reservationCategory?.value === "shared" ? { rooms_availability } : { rooms }) }

		const bookRes = await PackagesBooking(data);

		if (packageAvailability?.booking_type === "auto") {
			if (bookRes?.status >= 200 && bookRes?.status < 300) {
				store.addNotification({
					title: "info!",
					message: bookRes.data.message ?? "Booking Successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1100,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						history.push("/inventory/packages");
					},
				});
			}
		} else {
			if (bookRes?.status >= 200 && bookRes?.status < 300) {
				store.addNotification({
					title: "info!",
					message: "Send Request Successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1100,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						history.push("/inventory/packages");
					},
				});
			}
		}
	}

	useEffect(() => {
		if (isFormValid(errors)) {
			CheckAvailability();
		}
	}, [isErrorLoaded]);

	const togglePaymentModal = () => {
		setActionType("");
		setFormData({});
		setPaymentModalIsOpen(!paymentModalIsOpen);
	};

	// check Availability
	async function CheckAvailability() {
		debugger
		let calcAdultPax = PackagesDetails?.roomsData?.adults?.reduce((accumulator, currentValue) => accumulator + (+currentValue?.roomCount * +currentValue?.roomType?.room_type), 0)||0
		let calcChildtPax = PackagesDetails?.roomsData?.children?.reduce((accumulator, currentValue) => accumulator + (+currentValue?.childCount), 0) ||0
		let pax = {
			adults_count: +PackagesDetails.paxCount.adultPax || calcAdultPax,
			children_count: +PackagesDetails.paxCount.childsPax || calcChildtPax,
		};
		let rooms;
		let rooms_availability;

		if (PackagesDetails?.reservationCategory?.value === "shared") {
			rooms_availability = [...PackagesDetails?.roomsData.adults, ...PackagesDetails?.roomsData.children].map(room => ({
				combination_id: room?.combination_id,
				is_adult: room?.is_adult ? 1 : 0
			}))
		} else {
			rooms = PackagesDetails?.roomsData?.filter(room => room?.combination_id)?.map(room => {
				return {
					combination_id: room?.combination_id,
					rooms_count: +room?.roomCount,
					children_count: +room?.childCount,
				}
			});
		}

		let data = {
			product_uuid: uuid,
			pax,
			reservation_category: PackagesDetails?.reservationCategory?.value || "shared"
		};
		data = { ...data, ...(PackagesDetails?.reservationCategory?.value === "shared" ? { rooms_availability } : { rooms }) }

		const checkFlightAbaliabilityRes = await PackageCheckAvailability(data);

		if (checkFlightAbaliabilityRes.status === 200) {
			setPackageAvailability(checkFlightAbaliabilityRes?.data);
			if (checkFlightAbaliabilityRes?.data?.booking_type === "auto") {
				togglePaymentModal();
			} else {
				bookOnPayment("debit", PackagesDetails?.currency);
			}
		} else if (checkFlightAbaliabilityRes?.status === 202) {
			setActionType(checkFlightAbaliabilityRes?.data?.actionType);
			setFormData(checkFlightAbaliabilityRes?.data?.data?.formData);
		}
	}
	//
	const submitValidation = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	return (
		<div className="container">
			<div className="col-md-12 mt-4">
				<div className="row mt-3">
					<div className="col-md-9">
						<p className="Review_title ">{t.contactInformation}</p>
						<ContactInformation
							leaderData={leaderData}
							setLeaderData={setLeaderData}
							countries={countries}
							errors={errors}
							setErrors={setErrors}
						/>

						<div className="d-flex w-100 justify-content-end">
							<button
								color="success"
								variant="contained"
								className="btn col-12 col-6 mt-2 mb-4 text-light"
								type="submit"
								style={{ background: "#10A711" }}
								onClick={submitValidation}
							>
								{t.bookNow}
							</button>
						</div>
					</div>
					<div className="col-md-3">
						<div
							style={{
								color: "#0F3E5E",
								fontWeight: "800",
								fontSize: "15px",
								marginY: "7px",
								marginX: "10px",
							}}
						>
							{t.priceSummary}
						</div>
						<div className={`package_summary p-3`}>
							<div
								className="d-flex flex-column justify-content-between my-1"
								style={{ color: "#0F3E5E", fontWeight: "700", }}
							>
								<p>{t.totalPackagePrice}</p>
								<p style={{ fontSize: 18 }}>
									{formatPrice(PackagesDetails?.totalPrice)} {PackagesDetails?.reservationDetails?.currency}

								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{paymentModalIsOpen && (
				<NewPaymentAction
					isOpen={paymentModalIsOpen}
					toggleModal={togglePaymentModal}
					canPayLater={packageAvailability?.canPayLater}
					formData={formData}
					actionType={actionType}
					Price={+packageAvailability?.provider_total_price}
					currency={packageAvailability?.provider_currency}
					secondCurrency={packageAvailability?.currency}
					OTP={OTP_CHECK}
					onPayment={bookOnPayment}
					setActionType={setActionType}
					setFormData={setFormData}
				/>
			)}
		</div>
	);
}
