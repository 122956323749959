import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
//import useFetchCountries from "hooks/useFetchCountries";
import HotelMakkah from "./HotelMakkah";
import AdditionalServiceAccommodation from "./AdditionalServiceAccommodation";
import AccountingAccommodation from "./AccountingAccommodation";
import HotelMadinah from "./HotelMadinah";

export default function AccommodationTab(props) {
	//const [countries] = useFetchCountries();

	const [activeTabHotel, setActiveTabHotel] = useState("1");
	const toggleHotel = (tab) => {
		if (activeTabHotel !== tab) setActiveTabHotel(tab);
	};

	//ForExample To Searchable SelectField
	// const countriesList = countries.map((country, index) => (
	// 	<option key={`country-${country.id}${index}`} value={country.id}>
	// 		{country.name}
	// 	</option>
	// ));

	return (
		<>
			<Nav tabs className="hotels-tab">
				<NavItem>
					<NavLink
						className={classnames({ active: activeTabHotel === "1" })}
						onClick={() => {
							toggleHotel("1");
						}}
					>
						{"Hotel Makkah"}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTabHotel === "2" })}
						onClick={() => {
							toggleHotel("2");
						}}
					>
						{"Hotel Madinah"}
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTabHotel} className="makkah-content">
				<TabPane tabId="1">
					<HotelMakkah />
				</TabPane>
				<TabPane tabId="2">
					<HotelMadinah />
				</TabPane>
			</TabContent>

			<AdditionalServiceAccommodation />

			<AccountingAccommodation />

			<div className="d-flex flex-row-reverse py-4">
				<button className="btn btn-primary px-5 mx-1">Save Changes</button>
				<button className="btn btn-light mx-1 border">Cancle</button>
			</div>
		</>
	);
}
