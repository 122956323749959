import visaImg from "assets/images/visaImg.svg";
import PasswordField from "components/Form/PasswordField/PasswordField";
import TextField from "components/Form/TextField/TextField";
import { useSBSDispatch, useSBSState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { getToken } from "services/visa";
import Locale from "translations";

export default function LoginVisa(props) {
	const { login, messages } = Locale;
	const { userInfo } = useSBSState();
	const dispatch = useSBSDispatch();


	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	// const { locale } = useSBSState();
	const dispatchSBS = useSBSDispatch();
	// const [spin, setSpin] = useState(false);
	const [errors, setErrors] = useState({});
	let location = useHistory();
	const [loginData, setLoginData] = useState({
		safa_key: "",
		email: "",
		password: "",
		country_id: "",
	});

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{
					name: "safa_key",
					value: loginData.safa_key,
				},
				{
					required: true,
				}
			),
			...validate(
				{
					name: "email",
					value: loginData.email,
				},
				{
					required: true,
					email: true,
				}
			),
			...validate(
				{
					name: "password",
					value: loginData.password,
				},
				{ required: true }
			),
		});
	};

	// Login User
	const loginUser = async () => {
		checkFormErrors();
		let requestBody = {
			safa_key: loginData.safa_key,
			email: loginData.email,
			pwd: loginData.password,
		};
		if (isFormValid(errors)) {
			const response = await getToken(requestBody);
			if (response?.status === 200) {
				dispatch({
					type: "updateUserInfo",
					userInfo: { ...userInfo, is_connected_to_safa_visa: true },
				});
				localStorage.setItem("VisaToken", response?.data?.data?.token);
				//
				// setLoginData({
				// 	...loginData,
				// 	country_id: response.data.data.country_id,
				// });
				// dispatch({
				// 	type: "getVisaToken",
				// 	payload: response?.data?.data?.token,
				// });
				// dispatch({
				// 	type: "getLoginData",
				// 	payload: { ...loginData, country_id: response.data.data.country_id },
				// });
				// localStorage.setItem("VisaToken", response?.data?.data?.token);
				location.push("/visa");
			} else {
				dispatchSBS({
					type: "setError",
					payload: true,
					message: {
						title: "Something Went Wrong!",
						body: response?.data?.message,
					},
				});
				setTimeout(() => {
					dispatchSBS({
						type: "setError",
						payload: false,
						message: {
							title: "Something Went Wrong!",
							body: response?.data?.message,
						},
					});
				}, 1700);
			}
		}
	};

	return (
		<>
			<div className="container ">
				<div className="visa-login d-flex align-items-center   ">
					<div className="d-flex container flex-md-row flex-column  justify-content-around align-items-center">
						{/* Left Banner */}
						<figure className="col-4 pt-3 m-0 d-flex justify-content-center">
							<img src={visaImg} alt="" className="img-fluid" />
						</figure>

						{/* Login Form */}
						<div className="col-md-5 col-12 mt-2 d-flex  flex-column  justify-content-between">
							<div className="w-100 ">
								<h1 className="text-title font-weight-bold ">
									{messages.linkeyourAccount}
								</h1>
								<p className="mb-4">
									{" "}
									{messages.afterLinke}
								</p>
								<div>
									<TextField
										// type="text"
										label={login.safa_key}
										placeholder={login.safaPlaceholder}
										name="safa_key"
										value={loginData.safa_key}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
											setLoginData({
												...loginData,
												safa_key: e.target.value,
											});
										}}
										color={errors?.safa_key?.required ? "danger" : ""}
										errors={errors?.safa_key}
									/>
								</div>
								<div>
									<TextField
										placeholder={login.emailPlaceholder}
										label={login.emailAddress}
										value={loginData.email}
										name="email"
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
													email: true,
												}),
											});
											setLoginData({
												...loginData,
												email: e.target.value,
											});
										}}
										color={
											errors?.email?.required || errors?.email?.email
												? "danger"
												: ""
										}
										errors={errors?.email}
									/>
								</div>

								<div>
									<PasswordField
										placeholder={login.passwordPlaceholder}
										label={login.password}
										name="password"
										value={loginData.password}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(
													{ name: "password", value: e.target.value },
													{
														required: true,
													}
												),
											});
											setLoginData({
												...loginData,
												password: e.target.value,
											});
										}}
										// min={8}
										color={errors?.password?.required ? "danger" : "success"}
										errors={errors?.password}
									/>
								</div>

								<button
									className="btn bg-nxt w-100 mt-3"
									onClick={loginUser}
								//disabled={spin}
								>
									{/* {spin ? <Spinner color="light" size="sm" /> : null} */}
									{"  "} {login.loginTitle}
								</button>
							</div>
							<div></div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
