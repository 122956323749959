// ** imports
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

// component
import { ReactComponent as EyeIcon } from 'assets/images/crm/eye.svg'
import { ReactComponent as RightICon } from 'assets/images/crm/right.svg'
import CheckBox from 'components/chekbox'
import moment from 'moment'
import { Tooltip } from 'reactstrap'
import { ReactComponent as CallIcon } from 'assets/images/crm/call.svg'
import { ReactComponent as SMSIcon } from 'assets/images/crm/sms.svg'
import { ReactComponent as ProfileIcon } from 'assets/images/crm/profile.svg'
import Locale from 'translations'
import { formatPrice } from 'helpers/utils'

const CRMList = ({
	reservationList,
	selectedRows,
	handleSelectRows,
}) => {
	// ** translation
	const { inventory,CRM } = Locale;

	// ** router
	const history = useHistory()
	// ** states
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const [usersTooltip, setUsersTooltip] = useState(false);


	const toggle = (id) => {
		setTooltipOpen((prevState) => ({
			...prevState,
			[id]: !prevState[id]
		}))
	}


	const toggleUsers = (id) => {
		setUsersTooltip((prevState) => ({
			...prevState,
			[id]: !prevState[id]
		}))
	}



	const statusLocaleAndClasses = (status) => {
		switch (status) {
			case "pending":
				return { locale: inventory.messages.pending, className: "crm-status-bg-pending" };
			case "approved":
				return { locale: inventory.messages.approved, className: "crm-status-bg-accepted" };
			case "paid":
				return { locale: inventory.messages.paid, className: "crm-status-bg-accepted" };
			case "confirmed":
				return { locale: inventory.messages.confirmed, className: "crm-status-bg-accepted" };
			case "rejected":
				return { locale: inventory.messages.rejected, className: "crm-status-bg-rejected" };
			case "expired":
				return { locale: inventory.messages.expired, className: "crm-status-bg-rejected" };
			case "canceled":
				return { locale: inventory.messages.canceled, className: "crm-status-bg-rejected" };
			default:
				return { locale: "", className: "" };
		}
	};

	return (
		<div className='crm-table-wrapper table-responsive'>
			<table class='table'>
				<thead className='table-tableHead'>
					<tr className={`tableHead`}>
						<th scope='col'></th>
						<th scope='col'>{CRM.ReferenceNo}</th>
						<th scope='col'>{CRM.Owner}</th>
						<th scope='col'>
							<div className='d-flex flex-column'>
								<span className='text'>{CRM.DepartureNight}</span>
							</div>
						</th>
						<th scope='col'>
							<div className='d-flex flex-column'>
								<span className='text'>{CRM.PackagePax}</span>
							</div>
						</th>
						<th scope='col'>{CRM.Total}</th>
						<th scope='col'>
							<div className='d-flex flex-column'>
								<span className='text'>{CRM.Paid} /</span>
								<span className='text'>{CRM.OutStanding}</span>
							</div>
						</th>
						<th scope='col'>{CRM.RequestDate}</th>
						<th scope='col'>{CRM.Source}</th>
						<th scope='col'>{CRM.DealStatus}</th>
						<th scope='col'>{CRM.StatusBy}</th>
						<th scope='col'></th>
					</tr>
				</thead>
				<tbody className='table-tableBody'>
					{reservationList?.length > 0
						? reservationList?.map((ele) => (
							<tr
								className={`tableBody ${ele?.priority === 'low'
									? 'crm-bg-row-low'
									: ele?.priority === 'medium'
										? 'crm-bg-row-medium'
										: ele?.priority === 'high'
											? 'crm-bg-row-high'
											: ''
									}`}
								key={ele?.id}
							>
								<td>
									<div className="select-row-checkbox">
										<CheckBox
											key={ele?.reservation_num}
											id={ele?.reservation_num}
											name={ele?.reservation_num}
											checked={selectedRows.includes(ele?.reservation_num)}
											value={ele?.reservation_num}
											text={""}
											onChange={(e) => {
												handleSelectRows(e.target.checked, e.target.value);
											}}
										/>
									</div>
								</td>
								<td>{ele?.reference_num}</td>

								<td>
									{ele?.owner?.full_name ? (
										<div className='d-flex-flex-column gap-2'>
											<span className='table-text'>
												{ele?.owner?.full_name}
											</span>

											<span>
												<div
													className='d-flex align-items-center'
													style={{ gap: '3px' }}
													id={`Tooltip-user-info-${ele?.id}`}
												>
													<EyeIcon />
													<span className='table-text text-orange'>
														{CRM.ViewDetails}
													</span>
												</div>

												<Tooltip
													placement='bottom'
													isOpen={tooltipOpen && tooltipOpen[ele?.id]}
													target={`Tooltip-user-info-${ele?.id}`}
													toggle={() => toggle(ele?.id)}
												>
													<div
														className='d-flex flex-column justify-content-start'
														style={{ gridGap: '3px', gap: '16px' }}
													>
														<p
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'start',
																gap: '8px'
															}}
														>
															<CallIcon />
															<span>{ele?.owner?.phone}</span>
														</p>
														<p
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'start',
																gap: '3px'
															}}
														>
															<SMSIcon />
															<span>{ele?.owner?.email}</span>
														</p>
													</div>
												</Tooltip>
											</span>
										</div>
									) : (
										'_'
									)}
								</td>

								<td>
									<div className='d-flex flex-column'>
										<span className='table-text'>
											{ele?.departure_date
												? moment(ele?.departure_date).format('DD MMM YYYY')
												: '_'}
										</span>
										<span className='table-text'>{`${ele?.nights ? `${ele?.nights} Nights` : '_'
											}`}</span>
									</div>
								</td>

								<td>
									<div className='d-flex flex-column gap-2'>
										<span className='table-text'>
											{ele?.trip_type ? ele?.trip_type : '_'}
										</span>
										{ele?.travelers?.filter(traveler => traveler?.first_name && traveler?.last_name).length > 0 ? (
											<div className='d-flex justify-content-between align-items-center'>
												<span className='table-text'>{`${ele?.pax ? `${ele?.pax} Pax` : '_'
													}`}</span>

												<span>
													<div
														id={`Tooltip-users-${ele?.id}`}
														className='d-flex gap-1 align-items-center'
													>
														<EyeIcon />
														<span className='table-text text-orange'>
															{CRM.View}
														</span>
													</div>

													<Tooltip
														placement='bottom'
														isOpen={usersTooltip && usersTooltip[ele?.id]}
														target={`Tooltip-users-${ele?.id}`}
														toggle={() => toggleUsers(ele?.id)}
													>
														{ele?.travelers?.filter(traveler => traveler?.first_name && traveler?.last_name)?.map((person) => (
															<div
																className='d-flex justify-content-start align-items-center'
																key={person?.id}
																style={{ gap: '8px', margin: '8px 0' }}
															>
																<ProfileIcon />
																<span>
																	{person?.first_name} {` `}
																	{person?.last_name}
																</span>
															</div>
														))}
													</Tooltip>
												</span>
											</div>
										) : (
											'_'
										)}
									</div>
								</td>

								<td>
									<span className='table-text'>{`${ele?.total_price
										? `${formatPrice(ele?.total_price)} ${ele?.currency}`
										: '_'
										}`}</span>
								</td>

								<td>
									<div className='d-flex flex-column gap-2'>
										<span className='table-text text-success'>
											{`${formatPrice(ele?.payments_total_paid) || 0} ${ele?.currency}`}
										</span>
										<span className='table-text text-orange'>
											{`${formatPrice(ele?.payments_outstanding) || 0} ${ele?.currency}`}
										</span>
									</div>
								</td>

								<td>
									<div className='d-flex flex-column gap-2'>
										<span className='table-text'>
											{ele?.created_at
												? moment(ele?.created_at).format('DD MMM YYYY')
												: '-'}
										</span>
										<span className='table-text'>
											{ele?.created_at
												? moment(ele?.created_at).format('hh:mm')
												: '-'}
										</span>
									</div>
								</td>

								<td>
									<p className='text offline text-capitalize'>
										{ele?.reservation_from ? ele?.reservation_from : '_'}
									</p>
								</td>

								<td>
									<p
										className={`table-text new text-capitalize  ${statusLocaleAndClasses(ele?.reservation_status).className}`}
									>
										{ele?.reservation_status ? statusLocaleAndClasses(ele?.reservation_status).locale : '-'}
									</p>
								</td>

								<td>
									<div className='d-flex flex-column gap-2'>
										<span className='table-text text-orange'>Called</span>
										<span className='table-text'>
											{ele?.call_log?.by_user ? `By ${ele?.call_log?.by_user || ''}` : "-"}
										</span>
									</div>
								</td>

								<td>
									<button
										className='btn'
										onClick={() => history.push(`/crm/main/${ele?.id}`)}
									>
										<RightICon />
									</button>
								</td>
							</tr>
						))
						:
						<tr>
							<td colSpan="11" className="text-center">{CRM.NoData}</td>
						</tr>

					}
				</tbody>
			</table>
		</div>
	)
}

export default CRMList
