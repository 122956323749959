import SelectField from "components/Form/SelectField/SelectField";
import React, { useEffect, useState } from "react";
import locationIconSrc from "assets/images/market-place/servicesIcon/location.svg";
import VisaIconSrc from "assets/images/market-place/services-visaonline-icon.svg";
import NationalityIconSrc from "assets/images/market-place/global-nationality-icon.svg";
import PaxIconSrc from "assets/images/market-place/people-pax-icon.svg";
import TextField from "components/Form/TextField/TextField";
import Search from "modules/market-place/NewMarketplace/icons/search";
import Locale from "translations";
import { useHistory, useParams } from "react-router-dom";
import { onlineVisaLookups, searchGlobalVisa } from "services/VisaOnline";
import { useMarketplaceDispatch } from "context/marketplace";
import validate, { isFormValid } from "helpers/validate";
import { store } from "react-notifications-component";


// const UAE_ID = 971;
const SAUDI_ID = 966;

export default function GlobalVisaSearch({ allCountries }) {
	const { marketPlace, productsBuilder, inventory, messages } = Locale;
	const history = useHistory();
	const { status } = useParams();
	const dispatch = useMarketplaceDispatch();


	const [gloablSearchData, setGlobalSearchData] = useState({
		destination: null,
		visa_type: null,
		nationality: null,
		residence: null,
		pax: "",
	});
	const [lookups, setLookups] = useState({
		destinations: [],
		visaTypes: [],
		nationalities: [],
		residencesNames: [],
	});

	const [matches, setMatches] = useState(
		window.matchMedia("(max-width: 600px)").matches
	);

	useEffect(() => {
		window
			.matchMedia("(max-width: 600px)")
			.addEventListener("change", (e) => setMatches(e.matches));
	}, []);

	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	function checkFormErrors() {
		let submitError = {};
		Object.keys(gloablSearchData).forEach((key) => {
			submitError = {
				...submitError,
				...validate(
					{ name: key, value: gloablSearchData[key] },
					{ required: true }
				),
			};
		});
		setErrors(submitError);
	}

	function submit() {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	}
	//
	async function searchVisa() {
		// dispatch visa data
		const data = {
			destination_id: gloablSearchData.destination.id,
			visa_type_id: gloablSearchData.visa_type?.id,
			residence_id: gloablSearchData.residence?.id,
			nationality_id: gloablSearchData?.nationality?.id,
			pax: gloablSearchData.pax,
		};
		const res = await searchGlobalVisa(data);
		if (res?.status === 200 && res?.data?.data?.length > 0) {
			let visaData = res?.data?.data[0];
			visaData = {
				destination: {
					country_code: visaData?.country_code,
					country: visaData?.country,
					country_photo: visaData?.country_photo,
					terms: visaData?.terms,
					service_name: "visa",
				},
				visa_type: {
					id: visaData?.visa_types?.[0]?.id,
					name: visaData?.visa_types?.[0]?.name,
				},
				package_plan: {
					...visaData?.visa_types?.[0]?.residences?.[0]?.package_plans?.[0],
				},
				residence: { ...visaData?.visa_types?.[0]?.residences?.[0] },
				residence_id: gloablSearchData.residence?.id,
				terms: {
					ar: visaData?.terms?.ar
						?.split("\n")
						.filter((terms) => terms?.length > 3),
					en: visaData?.terms?.en
						?.split("\n")
						.filter((terms) => terms?.length > 3),
				},
				pax: gloablSearchData.pax,
				nationality: gloablSearchData?.nationality
			};
			dispatch({
				type: "onlineVisaSearch",
				payload: { ...visaData },
			});
			history.push("/market-search/global-visa-details");
		} else {
			store.addNotification({
				title: messages.noResults,
				message: messages.noSearchResults,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}
	// get visa configurations
	useEffect(() => {
		async function fetchDestinations() {
			const response = await onlineVisaLookups({ type: status });
			if (response?.status === 200) {
				const destinations =
					response?.data?.data?.filter((res) => res?.country_code !== SAUDI_ID)?.map(visa => ({
						...visa,
						id: visa?.country_code,
						name: visa?.country
					})) || [];
				setLookups({ ...lookups, destinations });
			}
		}
		fetchDestinations();
	}, [status]);

	function getResidencesLookups(residences) {
		let residencesNames = {};
		const isAllResidences =
			residences?.filter((residence) => residence?.all_residences).length > 0;
		if (isAllResidences) {
			residencesNames = allCountries;
		} else {
			residences?.forEach((visaResidence) => {
				visaResidence?.residencesNames?.forEach((residence) => {
					const residenceCountryId = residence?.country_id;
					if (residencesNames[residenceCountryId] === undefined) {
						residencesNames[residenceCountryId] = residence;
					}
				});
			});
			residencesNames = Object.values(residencesNames).map((residence) => ({
				...residence,
				id: residence?.country_id,
			}));
		}

		setLookups({ ...lookups, residencesNames: residencesNames });
	}

	//
	function getNationalitiesLookups(selectedResidence) {
		let nationalities = [];
		const allResidences = gloablSearchData.visa_type?.residences;

		const matchedResidences = allResidences?.filter((residence) => {
			if (!residence?.all_residences) {
				return residence?.residencesNames?.find(
					(residenceName) => residenceName?.country_id === selectedResidence?.id
				);
			} else {
				return allResidences;
			}
		});

		const isAllNationalities =
			matchedResidences?.filter((residence) => residence?.all_nationalities)
				.length > 0;

		if (isAllNationalities) {
			nationalities = allCountries;
		} else {
			matchedResidences?.forEach((residence) => {
				nationalities = [...nationalities, ...residence?.nationalities];
			});
			nationalities = nationalities.map((nationality) => ({
				...nationality,
				id: nationality?.country_id,
			}));
		}
		setLookups({ ...lookups, nationalities });
	}

	useEffect(() => {
		if (isFormValid(errors)) {
			searchVisa();
		}
	}, [isErrorLoaded]);

	return (
		<div className="global-visa-form">
			{/* country */}
			<div className="global-visa-form-item">
				{/* <label>{inventory.messages.country}</label> */}
				<SelectField
					label={inventory.messages.country}
					// hasLabel={false}
					isImage={true}
					image={locationIconSrc}
					prependImage={true}
					value={gloablSearchData.destination?.name}
					options={lookups.destinations}
					onChange={(e) => {
						setGlobalSearchData({
							...gloablSearchData,
							destination: e,
							visa_type: null,
							nationality: null,
							residence: null,
						});
						setLookups({...lookups, visaTypes: e?.visa_types})
						setErrors({
							...errors,
							...validate({ name: "destination", value: e }, { required: true }),
						});
					}}
					errors={errors?.destination}
					color={errors?.destination?.required ? "danger" : ""}
					// disabled={true}
				/>
			</div>

			{/* Visa Type */}
			<div className="global-visa-form-item">
				<label>{inventory.messages.visaType}</label>
				<SelectField
					hasLabel={false}
					label={inventory.messages.visaType}
					isImage={true}
					image={VisaIconSrc}
					prependImage={true}
					placeholder={productsBuilder.select}
					options={lookups?.visaTypes}
					value={gloablSearchData.visa_type?.name}
					onChange={(e) => {
						if (e?.id !== gloablSearchData?.visa_type?.id) {
							setGlobalSearchData({
								...gloablSearchData,
								visa_type: e,
								nationality: null,
								residence: null,
							});
							getResidencesLookups(e?.residences);
						}
						setErrors({
							...errors,
							...validate({ name: "visa_type", value: e }, { required: true }),
						});
					}}
					errors={errors?.visa_type}
					color={errors?.visa_type?.required ? "danger" : ""}
				/>
			</div>

			{/* Residence */}
			<div className="global-visa-form-item">
				<label>{inventory.messages.residence}</label>
				<SelectField
					hasLabel={false}
					label={inventory.messages.residence}
					isImage={true}
					image={NationalityIconSrc}
					prependImage={true}
					placeholder={productsBuilder.select}
					options={lookups.residencesNames}
					value={gloablSearchData.residence?.name}
					onChange={(e) => {
						setGlobalSearchData({
							...gloablSearchData,
							residence: e,
							nationality: null,
						});
						getNationalitiesLookups(e);
						setErrors({
							...errors,
							...validate({ name: "residence", value: e }, { required: true }),
						});
					}}
					errors={errors?.residence}
					color={errors?.residence?.required ? "danger" : ""}
				/>
			</div>

			{/* nationality */}
			<div className="global-visa-form-item">
				<label>{inventory.messages.nationality}</label>
				<SelectField
					hasLabel={false}
					label={inventory.messages.nationality}
					isImage={true}
					image={NationalityIconSrc}
					prependImage={true}
					placeholder={productsBuilder.select}
					options={lookups.nationalities}
					value={gloablSearchData.nationality?.name}
					onChange={(e) => {
						setGlobalSearchData({ ...gloablSearchData, nationality: e });
						setErrors({
							...errors,
							...validate(
								{ name: "nationality", value: e },
								{ required: true }
							),
						});
					}}
					errors={errors?.nationality}
					color={errors?.nationality?.required ? "danger" : ""}
				/>
			</div>

			{/* Pax */}
			<div className="global-visa-form-item fixFormAll">
				<label>{marketPlace.messages.pax}</label>
				<TextField
					type="number"
					hasLabel={false}
					label={marketPlace.messages.pax}
					isImage={true}
					image={PaxIconSrc}
					prependImage={true}
					placeholder={marketPlace.typePax}
					value={gloablSearchData?.pax}
					onChange={(e) => {
						setGlobalSearchData({ ...gloablSearchData, pax: e.target.value });
						setErrors({
							...errors,
							...validate(
								{ name: "pax", value: e.target.value },
								{ required: true }
							),
						});
					}}
					errors={errors?.pax}
					color={errors?.pax?.required ? "danger" : ""}
				/>
			</div>

			{/* search button */}
			<button
				className="btn btn-yellow w-auto d-flex align-self-center "
				style={{ paddingBlock: "12px" }}
				onClick={submit}
			>
				<Search /> {marketPlace.messages.search}
			</button>
		</div>
	);
}
