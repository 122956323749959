export const TripITinerary = `
<!DOCTYPE html>
<html lang="en">
	<head>
		<meta charset="UTF-8" />
		<meta name="viewport" content="width=device-width, initial-scale=1.0" />
		<title>Operation Invoice Report</title>

		<style>
			*,
			*::after,
			*::before {
				box-sizing: border-box;
			}
			* {
				margin: 0;
				padding: 0;
				font: inherit;
			}
			img,
			svg {
				display: block;
				max-width: 100%;
			}
			body {
				min-height: 100%;
			}
			html,
			body,
			div,
			dl,
			dt,
			dd,
			ul,
			ol,
			li,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			pre,
			code,
			form,
			fieldset,
			legend,
			input,
			textarea,
			p,
			blockquote,
			th,
			td {
				margin: 0;
				padding: 0;
			}

			a {
				text-decoration: none !important;
				cursor: pointer;
			}

			a:hover {
				text-decoration: none !important;
			}

			fieldset,
			img {
				border: 0;
			}

			address,
			caption,
			cite,
			code,
			dfn,
			em,
			strong,
			th,
			var {
				font-style: normal;
				font-weight: normal;
			}

			ol,
			ul {
				list-style: none;
			}

			caption,
			th {
				text-align: var(--left);
			}
			.text-danger {
				color: #ff5f59 !important;
			}
			.col-5 {
				flex: 0 0 41.6666666667%;
				max-width: 41.6666666667%;
			}
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-size: 100%;
				font-weight: normal;
			}

			q:before,
			q:after {
				content: '';
			}

			abbr,
			acronym {
				border: 0;
				font-variant: normal;
			}

			sup {
				vertical-align: text-top;
			}

			sub {
				vertical-align: text-bottom;
			}

			input,
			textarea,
			select {
				font-family: inherit;
				font-size: inherit;
				font-weight: inherit;
			}

			legend {
				color: #000;
			}

			input:focus,
			select:focus,
			textarea:focus,
			button:focus {
				outline: none;
			}

			* {
				font-family: 'Cairo', sans-serif;
				box-sizing: border-box;
			}

			.container,
			.container-fluid,
			.container-xl,
			.container-lg,
			.container-md,
			.container-sm {
				width: 100%;
				padding-right: 10px;
				padding-left: 10px;
				margin-right: auto;
				margin-left: auto;
			}

			.justify-content-end {
				justify-content: end;
			}

			.container {
				max-width: 1160px;
			}

			.py-4 {
				padding-bottom: 1.5rem !important;
				padding-top: 1.5rem !important;
			}

			.mx-3 {
				margin-left: 1rem !important;
				margin-right: 1rem !important;
			}

			.mt-2 {
				margin-top: 0.5rem !important;
			}

			.mr-2 {
				margin-right: 0.5rem !important;
			}

			.mt-3 {
				margin-top: 1rem !important;
			}

			.bg-gray {
				background: #e8e8e8 !important;
			}

			.p-0 {
				padding: 0 !important;
			}

			.p-4 {
				padding: 1.5rem !important;
			}

			.align-items-center {
				align-items: center !important;
			}

			.justify-content-around {
				justify-content: space-around !important;
			}

			.font-weight-bold {
				font-weight: 700 !important;
			}

			.mb-1,
			.my-1 {
				margin-bottom: 0.25rem !important;
			}

			.overFlow-hide {
			}

			element.style {
			}

			.col,
			.col-1,
			.col-10,
			.col-11,
			.col-12,
			.col-2,
			.col-3,
			.col-4,
			.col-5,
			.col-6,
			.col-7,
			.col-8,
			.col-9,
			.col-auto,
			.col-lg,
			.col-lg-1,
			.col-lg-10,
			.col-lg-11,
			.col-lg-12,
			.col-lg-2,
			.col-lg-3,
			.col-lg-4,
			.col-lg-5,
			.col-lg-6,
			.col-lg-7,
			.col-lg-8,
			.col-lg-9,
			.col-lg-auto,
			.col-md,
			.col-md-1,
			.col-md-10,
			.col-md-11,
			.col-md-12,
			.col-md-2,
			.col-md-3,
			.col-md-4,
			.col-md-5,
			.col-md-6,
			.col-md-7,
			.col-md-8,
			.col-md-9,
			.col-md-auto,
			.col-sm,
			.col-sm-1,
			.col-sm-10,
			.col-sm-11,
			.col-sm-12,
			.col-sm-2,
			.col-sm-3,
			.col-sm-4,
			.col-sm-5,
			.col-sm-6,
			.col-sm-7,
			.col-sm-8,
			.col-sm-9,
			.col-sm-auto,
			.col-xl,
			.col-xl-1,
			.col-xl-10,
			.col-xl-11,
			.col-xl-12,
			.col-xl-2,
			.col-xl-3,
			.col-xl-4,
			.col-xl-5,
			.col-xl-6,
			.col-xl-7,
			.col-xl-8,
			.col-xl-9,
			.col-xl-auto {
				padding-right: 5px;
				padding-left: 5px;
			}

			element.style {
			}

			.col,
			.col-1,
			.col-10,
			.col-11,
			.col-12,
			.col-2,
			.col-3,
			.col-4,
			.col-5,
			.col-6,
			.col-7,
			.col-8,
			.col-9,
			.col-auto,
			.col-lg,
			.col-lg-1,
			.col-lg-10,
			.col-lg-11,
			.col-lg-12,
			.col-lg-2,
			.col-lg-3,
			.col-lg-4,
			.col-lg-5,
			.col-lg-6,
			.col-lg-7,
			.col-lg-8,
			.col-lg-9,
			.col-lg-auto,
			.col-md,
			.col-md-1,
			.col-md-10,
			.col-md-11,
			.col-md-12,
			.col-md-2,
			.col-md-3,
			.col-md-4,
			.col-md-5,
			.col-md-6,
			.col-md-7,
			.col-md-8,
			.col-md-9,
			.col-md-auto,
			.col-sm,
			.col-sm-1,
			.col-sm-10,
			.col-sm-11,
			.col-sm-12,
			.col-sm-2,
			.col-sm-3,
			.col-sm-4,
			.col-sm-5,
			.col-sm-6,
			.col-sm-7,
			.col-sm-8,
			.col-sm-9,
			.col-sm-auto,
			.col-xl,
			.col-xl-1,
			.col-xl-10,
			.col-xl-11,
			.col-xl-12,
			.col-xl-2,
			.col-xl-3,
			.col-xl-4,
			.col-xl-5,
			.col-xl-6,
			.col-xl-7,
			.col-xl-8,
			.col-xl-9,
			.col-xl-auto {
				padding-right: 5px;
				padding-left: 5px;
			}

			.col-2 {
				flex: 0 0 16.6666666667%;
				max-width: 16.6666666667%;
			}

			.col-8 {
				flex: 0 0 66.6666666667%;
				max-width: 66.6666666667%;
			}

			.col-1 {
				flex: 0 0 8.3333333333%;
				max-width: 8.3333333333%;
			}

			.col-3 {
				flex: 0 0 25%;
				max-width: 25%;
			}

			.col-6 {
				flex: 0 0 50%;
				max-width: 50%;
			}

			.col-4 {
				flex: 0 0 33.3333333333%;
				max-width: 33.3333333333%;
			}

			.img-fluid {
				max-width: 100%;
				height: auto;
			}

			.w-50 {
				width: 50%;
			}

			element.style {
			}

			.col,
			.col-1,
			.col-10,
			.col-11,
			.col-12,
			.col-2,
			.col-3,
			.col-4,
			.col-5,
			.col-6,
			.col-7,
			.col-8,
			.col-9,
			.col-auto,
			.col-lg,
			.col-lg-1,
			.col-lg-10,
			.col-lg-11,
			.col-lg-12,
			.col-lg-2,
			.col-lg-3,
			.col-lg-4,
			.col-lg-5,
			.col-lg-6,
			.col-lg-7,
			.col-lg-8,
			.col-lg-9,
			.col-lg-auto,
			.col-md,
			.col-md-1,
			.col-md-10,
			.col-md-11,
			.col-md-12,
			.col-md-2,
			.col-md-3,
			.col-md-4,
			.col-md-5,
			.col-md-6,
			.col-md-7,
			.col-md-8,
			.col-md-9,
			.col-md-auto,
			.col-sm,
			.col-sm-1,
			.col-sm-10,
			.col-sm-11,
			.col-sm-12,
			.col-sm-2,
			.col-sm-3,
			.col-sm-4,
			.col-sm-5,
			.col-sm-6,
			.col-sm-7,
			.col-sm-8,
			.col-sm-9,
			.col-sm-auto,
			.col-xl,
			.col-xl-1,
			.col-xl-10,
			.col-xl-11,
			.col-xl-12,
			.col-xl-2,
			.col-xl-3,
			.col-xl-4,
			.col-xl-5,
			.col-xl-6,
			.col-xl-7,
			.col-xl-8,
			.col-xl-9,
			.col-xl-auto {
				padding-right: 5px;
				padding-left: 5px;
			}

			.txt-blue {
				color: #0c3b5c;
				font-weight: 700;
			}

			.container-wrapper {
				border: 1px solid #0c3b5c;
			}

			.justify-content-between {
				justify-content: space-between !important;
			}

			.row {
				display: flex;
				flex-wrap: wrap;
				margin-right: -15px;
				margin-left: -15px;
			}

			.border-header {
				border-bottom: 1px solid #2d2d2d;
			}

			.border-section {
				border-bottom: 1px solid #0c3b5c;
			}

			.invoice-word {
				color: #0c3b5c;
				font-size: 40px;
				font-weight: 700;
			}

			.f-12 {
				font-size: 12px;
			}

			table {
				border-collapse: collapse;
			}

			td {
				border: 1px solid #0c3b5c;
				padding: 0.5rem;
			}

			th {
				border: 1px solid #0c3b5c;
				padding: 0.5rem;
			}

			thead th {
				border: 1px solid #0c3b5c;
				padding: 0.5rem;
			}

			tr:first-child td {
				border-top: 0;
			}

			tr td:first-child {
				border-left: 0;
			}

			tr td:last-child {
				border-right: 0;
			}

			tr th:first-child {
				border-left: 0;
			}

			tr th:last-child {
				border-right: 0;
			}

			.last-td-border {
				border: 0 !important;
				font-size: 12px;
				border-bottom: 1px solid #0c3b5c !important;
			}

			.remove-last-td-border {
				border-bottom: 0 !important;
				border-top: 0 !important;
				font-size: 12px;
			}

			.bordered-img-holder {
				display: flex;
				align-items: center;
				height: 20vh;
			}

			.width-10 {
				width: 11% !important;
			}

			.invoice {
				height: 95vh !important;
				width: 100%;
				padding-right: 5px;
				padding-left: 5px;
			}
			.label-margin {
				margin-inline-start: 5px;
			}

			.company-logo-font {
				font-size: 2rem !important;
			}

			.container-wrapper {
				height: 95vh !important;
			}

			.p-5 {
				padding: 2rem;
			}

			.d-flex {
				display: flex !important;
			}

			.justify-content-center {
				justify-content: center !important;
			}

			.flex-column {
				flex-direction: column;
			}

			.border {
				border: 1px solid #dfdfdf;
			}

			.letter-logo {
				width: 40px;
				height: 40px;
				font-size: 16px;
				font-weight: 600;
				color: white;
				background-color: #cb9a51;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				text-transform: capitalize;
			}

			.header_boxes {
				flex-wrap: wrap;
			}

			.header_boxes .box {
				width: 49%;
			}

			.header_boxes .box div {
				padding: 21px 10px;
				border: 2px solid rgb(185, 185, 185);
				height: 135px;
			}

			.header_boxes .box h3 {
				font-size: 22px;
				font-weight: 600;
			}

			.header_boxes .box h3,
			.header_boxes .box p {
				line-height: 1.3;
			}

			.box-container {
				margin-top: 15px;
				border: 1px solid rgb(185, 185, 185);
			}

			.box-container .box-header {
				background-color: #0077a3;
				padding: 7px;
				font-size: 19px;
				font-weight: 700;
				color: #fff;
			}

			.box-container .box-containt {
				display: flex;
				padding: 10px;
				flex-wrap: wrap !important;
			}

			.box-container .box-containt h3 {
				font-size: 17px;
				font-weight: 700;
			}

			.fw-600 {
				font-weight: 600;
			}

			.arrival-container h6 {
				margin-bottom: 10px;
			}

			.fw-700 {
				font-weight: 700;
			}

			.hr-lg {
				height: 4px;
				background-color: #f3f5f5;
				border: none !important;
			}

			.border-dots {
				border-bottom: 1px #d5d5d5 dashed;
			}
			.wrapper {
				padding: 1rem;
			}
			.operation-invoice {
				display: grid;
				grid-template-rows: repeat(4, auto);
				grid-gap: 10px;
				padding: 0px, 16px, 10px, 16px;
			}

			.operation-invoice-logo {
				width: 85px;
				height: 65px;
				object-fit: contain;
			}

			.operation-invoice-heading {
				text-align: center;
				font-weight: bolder;
			}

			.operationInvoice-info {
				display: grid;
				grid-template-columns: 1fr 1fr;
				justify-content: space-between;
				align-items: center;
			}

			.operationInvoice-no {
				display: flex;
				align-items: center;
			}

			.operationInvoice-no .invoice-number {
				font-weight: bold;
				font-size: 14px;
			}

			.align-items-end {
				justify-content: end;
			}

			.operationInvoice-details {
				display: grid;
				justify-content: space-between;
				align-items: center;
				grid-template-columns: 1fr 50px 1fr;
			}

			.operationInvoice-programType {
				display: flex;
			}

			.operation-invoice-icon {
				width: 20px;
				height: 20px;
				margin-right: 0.5rem;
			}

			.prgramType-text {
				font-size: 12px;
				color: #707170;
			}

			.vertical-line {
				border-left: 1px solid black; /* Adjust thickness and color as needed */
				height: 100px; /* Adjust height as needed */
			}

			.arrival-container {
				padding: 15px 10px;
				background-color: #f7f6f699;
				page-break-inside: avoid;
			}

			.arrivalAndDeparture {
				border: 1px solid #f7f6f699;
				display: grid;
				grid-template-rows: repeat(3, auto);
				grid-gap: 15px;
			}

			thead {
				background-color: #f7f6f6;
			}

			thead tr th,
			tr td {
				border: none !important;
				text-align: center;
			}
			tr {
				border: 1px solid #dbdade;
			}

			tbody tr {
				background-color: #fff;
			}

			.tax-container {
				background-color: #faf4eb;
				border: 2px solid #d4a655;
				padding: 8px 10px 8px 10px;
			}

			.operationInvoices-tax {
				display: grid;
				grid-template-rows: repeat(3, auto);
				grid-gap: 13px;
			}

			.prices {
				display: grid;
				grid-template-columns: 1fr 2fr;
			}
		</style>
	</head>
	<body>
		<div class="wrapper">
			<div class="operation-invoice">
				<img
					class="operation-invoice-logo"
					src=[print_logo]
					alt="hotal-logo"
					width="300px"
					height="140px"
				/>

				<h3 class="operation-invoice-heading">Trip Itinerary</h3>

				<div class="operationInvoice-info">
					<div class="operationInvoice-no align-items-start">
						<p>Date.</p>
						<p class="operationInvoice-no">[today_date]</p>
					</div>
				</div>

				[arrival_details]

				[hotel_details]
				
				[transportation_details]
	
				[attractions_details]

			</div>
		</div>
	</body>
</html>
`
