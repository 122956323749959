import React from 'react'

export default function LocationIcon({width}) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width={width || "11.156"} height={width || "13.413"} viewBox="0 0 11.156 13.413">
  <g id="Group_3769" data-name="Group 3769" transform="translate(0.5 0.5)">
    <path id="Path_3382" data-name="Path 3382" d="M10.156,5.078c0,3.949-5.078,7.335-5.078,7.335S0,9.027,0,5.078a5.078,5.078,0,1,1,10.156,0Z" fill="none" stroke="#616a71" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" fill-rule="evenodd"/>
    <circle id="Ellipse_44" data-name="Ellipse 44" cx="1.754" cy="1.754" r="1.754" transform="translate(3.508 3.643)" fill="none" stroke="#616a71" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
  </g>
</svg>)
}
