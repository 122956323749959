import React from "react";

export default function AccountingAccommodation(props) {
	return (
		<>
			<div className="accounting-item">
				<h2 className="head">Accounting</h2>
				<div className="row  m-0 bg-white border p-3 my-2 rounded">
					<div className="col-md-4">
						<div className="account-item">
							<p className="title">Amount</p>
							<p className="price">10,000 EGP</p>
						</div>
					</div>
					<div className="col-md-4">
						<div className="account-item">
							<p className="title">Additional Service</p>
							<p className="price">8,000 EGP</p>
						</div>
					</div>
					<div className="col-md-4">
						<div className="account-item">
							<p className="title">Total Price</p>
							<p className="price text-success">18,000 EGP</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
