// import { Icon, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
// import styles from "../../../../styles/packages/modals/packagedetails.module.css"
import moment from 'moment/moment'
import { useSBSState } from 'context/global'
// import { useRouter } from 'next/router'
// import OtherServiceIcon from '../../../../Icons/OtherServiceIcon'

export default function ItineraryOtherService({ isMobile, element }) {
  const [opened, setopened] = useState(false)
  const handelcollapse = () => {
    setopened(!opened)
  }
  const { locale } = useSBSState();

  return (<>
    <div className="boxcollapse">
      <div className="closecollapse">
        <div className={`styles.collapseIcon ${opened && "opencoll"}`}>

          {/* <OtherServiceIcon /> */}

        </div>
        {isMobile && <p style={{ color: "##0F3E5E", fontWeight: "bold", marginRight: "auto", paddingLeft: "5px" }} component={"p"} >{"OtherService"}</p>}
        {!isMobile && <>
          <div className={"info"}>
            <div className='d-flex wrap'>
              <p style={{ color: "#0F3E5E" }} component={"p"} >{element?.item?.name} [{element?.itemable?.name[locale]}] </p>
            </div>
            <span style={{ color: "##2D2D2D" }} component={"span"} >{moment(element?.itemable?.date).format("DD/MM/YYYY")}</span>
          </div>

        </>}

        {isMobile &&
          <div>
            <button onClick={handelcollapse} className='btn'>
              <i class="fas fa-chevron-down fa-1x text-grey"></i>
            </button>
          </div>
        }
      </div>

    </div>
  </>
  )
}
