import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import React from 'react';
import Locale from 'translations';
import correctIcon from "assets/images/wallet/correctIcon.svg";
import clearIcon from "assets/images/wallet/clearIcon.svg";



export default function FlightsFilter({ filters, setFilters,search}) {
  const { payment, inventory ,marketPlace} = Locale;
  const clear = ()=>{
    setFilters({
      search:"",
      bookingDate:"",
      tourDate:"",
      status:"",
    })
    search()
  } 
  const status = [
    { id: 0, name: `${marketPlace.IssueProcessing}`, title: "pending" },
    { id: 1, name: inventory.messages.issued, title: "Issued" },
    { id: 2, name: inventory.messages.cancelled, title: "Canceled" },

  ];



  return (
    <div className="filter-update bg-filter-gray">
      <div className="product-buttons col-md-11">
        <div className="main-filter-payment d-flex justify-contain-between">
          {/* search text */}
          <div className="main-label controls-field col-md-2 col-6">
            <TextField
            onChange={(e)=>{
              setFilters({...filters,search:e.target.value})
            }}
              label={payment.messages.search}
              type="text"
              placeholder={payment.messages.search}
              value={filters.search}
              
            />
          </div>
          {/* booking date */}
          <div className="main-label controls-field  col-md-2 col-6">
            <DatePickerField
              label={inventory.messages.bookingDate}
              placeholder="DD/MM/YYY"
              onDateChange={(e)=>{
                setFilters({...filters,bookingDate:e})
              }}
              date={filters.bookingDate}
              isOutsideRange={(day) => {
                return false;
              }}
            />
          </div>
          {/* transfer date */}
          <div className="main-label controls-field  col-md-2 col-6">
          <DatePickerField
              label={inventory.messages.tourDate}
              placeholder="DD/MM/YYY"
              onDateChange={(e)=>{
                setFilters({...filters,tourDate:e})
              }}
              date={filters.tourDate}
              isOutsideRange={(day) => {
                return false;
              }}
            />
          </div>
          {/* status */}
          <div className="main-label controls-field col-md-2 col-6">
            <SelectField
              label={payment.messages.status}
              placeholder={payment.messages.status}
              onChange={(e)=>{
                setFilters({...filters,status:e.name})
              }}
              value={filters.status}
              options={status}
            />
          </div>
          {/* fiter buttons */}
          <div className="filter-btn col-md-4 col-12 d-flex align-items-center justify-content-start">
            <div className="apply pointer">
              <button className="btn btn-light px-3" onClick={search} >
                <img src={correctIcon} alt=" " />
                {payment.messages.apply}
              </button>
            </div>
            <div
              className="clear text-secondary pointer"
            >

              <button className="btn btn-light px-3 mx-2" onClick={clear}>
                <img src={clearIcon} alt=" " />
                {payment.messages.clear}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
