import React from "react";

export default function TimesIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M6.16194 16.3291C5.84554 16.6456 5.83047 17.2106 6.16948 17.542C6.50095 17.8735 7.06596 17.866 7.38237 17.5496L11.9929 12.9315L16.6109 17.5496C16.9348 17.8735 17.4923 17.8735 17.8238 17.542C18.1477 17.203 18.1552 16.6531 17.8238 16.3291L13.2133 11.7111L17.8238 7.10063C18.1552 6.77669 18.1552 6.21921 17.8238 5.88774C17.4848 5.5638 16.9348 5.55627 16.6109 5.8802L11.9929 10.4982L7.38237 5.8802C7.06596 5.5638 6.49342 5.54873 6.16948 5.88774C5.838 6.21921 5.84554 6.78422 6.16194 7.10063L10.78 11.7111L6.16194 16.3291Z"
				fill="black"
			/>
		</svg>
	);
}
