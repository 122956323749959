import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
	fetchMarketPackagesCountries,
	fetchMarketPackagesSearch,
} from "services/marketplace";
import Carousel from "react-multi-carousel";
import { ReactComponent as PackagesCountIcon } from "assets/images/market-place/package-count-icon.svg";
import Locale from "translations";
import { useHistory } from "react-router-dom";
import SearchColoredIcon from "../../../icons/searchColoredIcon";
import CardPackage from "./CardPackage";
import { useSBSState } from "context/global";

export default function Packages() {

	const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
		const { carouselState: { currentSlide, slidesToShow, totalItems } } = rest;
		return (
			<div className="carousel-button-group-new">
				<button className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--right  ${(currentSlide + slidesToShow) == totalItems ? 'disable' : ''}`} onClick={() => next()} ></button>
				<button className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--left  ${currentSlide === 0 ? 'disable' : ''}`} onClick={() => previous()}>  </button>
			</div>
		);
	};
	const { locale } = useSBSState();
	const { status } = useParams();
	const { marketPlace, inventory } = Locale;
	const FILEUPLOAD_URL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
	const [packagesData, setPackagesData] = useState([]);
	const [countriesPackages, setcountriesPackages] = useState([]);
	const history = useHistory();

	const getPackages = async () => {
		const res = await fetchMarketPackagesSearch({ country_id: 966 });
		if (res.status === 200) {
			// const packageSorted = res.data.data?.sort((a, b) => a.price - b.price);
			const packageSorted = res.data.data;
			setPackagesData(packageSorted.slice(0, 3));
		}
	};

	const getCountries = async () => {
		const res = await fetchMarketPackagesCountries();

		if (res.status === 200) {
			let data = res.data.data;
			if (status === "global") {
				data = data.filter((i) => i.country_id !== 966);
			}
			setcountriesPackages(data);
		}
	};

	useEffect(() => {
		if (status === "umrah") {
			getPackages();
		} else {
			getCountries();
		}
		return () => { };
	}, [status]);

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 8,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 6,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 3,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 2,
		},
	};
	return (
		<div className={`market-place-packages ${countriesPackages?.length === 0 ? "text-center p-5" : ""}` }>
			{status === "global" ? (
				<>

					{countriesPackages?.length > 0 ?
						<>
							<h2 className="discover font-weight-bold">
								{inventory.availablePackages}
							</h2>
							<Carousel
								arrows={false} customButtonGroup={<ButtonGroup />}
								renderButtonGroupOutside={true}
								responsive={responsive}
								itemClass={"package-slide"}
								slidesToSlide={1}
								keyBoardControl={true}
								className="new-market-packages-countries"
								rtl={locale === "ar"}
							>
								{countriesPackages.map((packages) => {
									return (
										<Link
											to={`/global/city-packages/${packages.country_id}`}
											className="new-market-package-card"
											key={packages?.id}
										>
											<img
												src={`${FILEUPLOAD_URL}/${packages.image}`}
												alt={packages?.name}
												width="60px"
												height="60px"
											/>
											<h3 className="title">{packages?.name}</h3>
											<p className="content">
												<PackagesCountIcon />
												<span className="mx-2">
													{packages?.packages} {marketPlace.packages}
												</span>
											</p>
										</Link>
									);
								})}

							</Carousel>
						</>
						:
						<span className="font-weight-bold text-capitalize">{marketPlace.noPackagesAvailable}</span>
					}

				</>
			) : null}
			{status === "umrah" && (
				<>
					<div className="title-packages-services text-center mb-3 w-100">
						<p className="discover font-weight-bold" style={{ lineHeight: 1 }}>
							{marketPlace.bestPackages}
						</p>
						<p className="here font-gray text-gray">{marketPlace.hereYou}</p>
					</div>
					<div
						className="d-flex flex-column justify-content-center align-items-center pb-2"
						style={{ gap: "12px" }}
					>
						<div
							className="d-flex justify-content-center flex-wrap w-100"
							style={{ gap: "12px" }}
						>
							{packagesData.map((packageDetails) => (
								<CardPackage packageDetails={packageDetails} />
							))}
						</div>
						<button
							className="btn btn-outer-yellow w-50"
							onClick={() => history.push("/global/city-packages/966")}
						>
							<SearchColoredIcon /> {marketPlace.viewMorePackages}
						</button>
					</div>
				</>
			)}
		</div>
	);
}
