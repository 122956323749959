import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher'
import React from 'react'
import { Link } from 'react-router-dom'
import Locale from 'translations';
import SafaLogo from "assets/images/customRegister/safalogo-ar-en.png";

export default function PreAuthHeaderV2() {
  const { newLand } = Locale;

  return (
    <header className="header">
      <img
        src={SafaLogo}
        alt="safa soft logo"
        width="195px"
      />
      <nav className="nav">
        <Link to="/contact-us">
          {newLand.contactUs}
        </Link>
        <Link to="/about-us">
          {newLand.aboutUs}
        </Link>
        <LanguageSwitcher />
      </nav>
    </header>
  )
}
