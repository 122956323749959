import React from 'react'
import { ReactComponent as HotelIcon } from "assets/images/hotelAccommodation/hotel.svg";
import { useProductsBuilderDispatch } from 'context/productsBuilder';

function HotelTaps({selected,hotelName, hotelId,handleFilterRoomType}) {
  const dispatch = useProductsBuilderDispatch();
  const changeHotel = ()=> {
    handleFilterRoomType("all")
    dispatch({type:"changeSelectedHotelAccommodation",payload:hotelId})}
  return (
    <div className={`hotel-tap ${selected ? "active" : ""}` } role='button' onClick={changeHotel}>
      <HotelIcon />
      <span className='hotel-name'>{hotelName}</span>
    </div>
  )
}

export default HotelTaps