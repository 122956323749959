import React, { useEffect, useState } from 'react'
import { getListOperation } from 'services/operationStatement';
import Pagination from 'components/Pagination';
import { useParams } from 'react-router-dom';
import AccountingList from '../../List';

export default function ViewAgentOperations() {
  const { id } = useParams();


  const [statusCount, setStatusCount] = useState({
    all: 0,
    new: 0,
    paid: 0,
    unpaid: 0
  });

  
  const [filters, setFilters] = useState({
    agents_id: [id],
    page: 1,
    payment_status: 'all',
  });


  const [accountingStatemnet, setAccountingStatement] = useState([]);
  const [metaData, setMetaData] = useState(null);
  const [activeStatus, setActiveStatus] = useState('all')

  // get based on agent
  async function getStatementAccounting(params) {
    const res = await getListOperation(params)
    if (res?.status === 200) {
      const { meta, status_counts, data } = res?.data
      setMetaData(meta)
      setStatusCount(status_counts)
      setAccountingStatement(data)
    }
  }

  function goTo(page) {
    getStatementAccounting({ ...filters, page })
  }


  useEffect(() => {
    getStatementAccounting(filters);
  }, [filters])

  return (
    <div className="operation-statement-container">
      {/* <AgentViewHeader /> */}

      <AccountingList
        statusCount={statusCount}
        accountingStatement={accountingStatemnet}
        activeStatus={activeStatus}
        setActiveStatus={setActiveStatus}
        filters={filters}
        setFilters={setFilters}
        getStatementAccounting={getStatementAccounting}
      />

      <div className='mt-4 pb-3'>
        <Pagination info={metaData} goTo={goTo} />
      </div>
    </div>
  )
}
