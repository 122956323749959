import React, { useState } from "react";
import SelectField from "components/Form/SelectField/SelectField";
import TextFieldWithSelect from "components/Form/TextFieldWithSelect/TextFieldWithSelect";
import { useHistory } from "react-router-dom";
import useFetchCountries from "hooks/useFetchCountries";
import Locale from "translations";

export default function AddBankTransfer(props) {
	const history = useHistory();
	const { paymentAdmin } = Locale;

	const [currency, setCurrency] = useState("");
	const [countries] = useFetchCountries();
	const [country, setCountry] = useState("");
	const [specifiedCompany, setSpecifiedCompany] = useState("");

	const [formValidation, setFormValidation] = useState({
		currency: true,
		country: true,
		specifiedCompany: true,
	});

	// Validate CurrencyName Field
	const validateCurrencyField = () => {
		setFormValidation({
			...formValidation,
			currency: currency.length > 2 ? true : false,
		});
	};

	// Validate Country
	const validateCountry = () => {
		setFormValidation({
			...formValidation,
			country: country !== 0 ? true : false,
		});
	};

	//Submit Form
	const submit = () => {
		if (Object.values(formValidation).every((item) => item === true)) {
			alert(`
			Currency Name => : ${currency}
			Countries => : ${country.label}
			SPECIFIED Companies => : ${specifiedCompany.label}
		`);
		}
	};

	return (
		<>
			<div className="payment-admin">
				<div className="text-center py-4 text-title ">
					<h2 className="text-decor text-dark">{paymentAdmin.messages.menu}</h2>
				</div>

				<div className="bg-white py-3 px-2">
					<h2 className="font-weight-bold">
						{paymentAdmin.messages.addNewCurrency}
					</h2>
				</div>

				<div className="my-2">
					<div className="row m-0">
						<div className="col-md-6">
							<div className="form-group full-input">
								<TextFieldWithSelect
									label={paymentAdmin.messages.currencyName}
									placeholder={paymentAdmin.messages.currencyNamePlaceholder}
									selectPosition="append"
									feedbackMessage={
										paymentAdmin.validation.currencyNameValidationMessage
									}
									value={currency}
									onChange={(e) => setCurrency(e.target.value)}
									onBlur={validateCurrencyField}
									color={formValidation.currency ? "" : "danger"}
									isInvalid={!formValidation.currency}
								>
									<option value="en">EGP</option>
									<option value="ar">SAR</option>
								</TextFieldWithSelect>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group full-input">
								<SelectField
									label={paymentAdmin.messages.selectCountries}
									placeholder={paymentAdmin.messages.selectCountriesPlaceholder}
									defaultValue={
										paymentAdmin.messages.selectCountriesPlaceholder
									}
									feedbackMessage={
										paymentAdmin.currencyCountryValidationMessage
									}
									onChange={(e) => {
										setCountry(e);
									}}
									onBlur={validateCountry}
									color={formValidation.country ? "" : "danger"}
									isInvalid={!formValidation.country}
									options={countries}
									value={country.label}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group full-input">
								<SelectField
									label={paymentAdmin.messages.selectSpecifiedCompanies}
									placeholder={paymentAdmin.messages.selectCountriesPlaceholder}
									defaultValue={
										paymentAdmin.messages.selectSpecifiedCompaniesPlaceholder
									}
									feedbackMessage={
										paymentAdmin.currencySpecifiedValidationMessage
									}
									onChange={(e) => {
										setSpecifiedCompany(e);
									}}
									onBlur={validateCountry}
									color={formValidation.country ? "" : "danger"}
									isInvalid={!formValidation.country}
									options={countries}
									value={specifiedCompany.label}
								/>
							</div>
						</div>

						<div className="save-buttons d-flex py-4">
							<button
								className="btn btn-default mx-1 border"
								onClick={() => history.push("/payment-admin")}
							>
								{paymentAdmin.messages.back}
							</button>
							<button
								className="btn btn-primary px-5 mx-1"
								onClick={submit}
								disabled={
									!Object.values(formValidation).every(
										(item) => item === true
									) || [currency, country, specifiedCompany].includes("")
								}
							>
								{paymentAdmin.messages.saveChange}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
