export function SearchIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 22L20 20M11.5 21C12.7476 21 13.9829 20.7543 15.1355 20.2769C16.2881 19.7994 17.3354 19.0997 18.2175 18.2175C19.0997 17.3354 19.7994 16.2881 20.2769 15.1355C20.7543 13.9829 21 12.7476 21 11.5C21 10.2524 20.7543 9.0171 20.2769 7.86451C19.7994 6.71191 19.0997 5.66464 18.2175 4.78249C17.3354 3.90033 16.2881 3.20056 15.1355 2.72314C13.9829 2.24572 12.7476 2 11.5 2C8.98044 2 6.56408 3.00089 4.78249 4.78249C3.00089 6.56408 2 8.98044 2 11.5C2 14.0196 3.00089 16.4359 4.78249 18.2175C6.56408 19.9991 8.98044 21 11.5 21Z" stroke="#707170" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
export function DragDotsIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.75 6.75C8.55109 6.75 8.36032 6.67098 8.21967 6.53033C8.07902 6.38968 8 6.19891 8 6C8 5.80109 8.07902 5.61032 8.21967 5.46967C8.36032 5.32902 8.55109 5.25 8.75 5.25C8.94891 5.25 9.13968 5.32902 9.28033 5.46967C9.42098 5.61032 9.5 5.80109 9.5 6C9.5 6.19891 9.42098 6.38968 9.28033 6.53033C9.13968 6.67098 8.94891 6.75 8.75 6.75ZM8.75 12.75C8.55109 12.75 8.36032 12.671 8.21967 12.5303C8.07902 12.3897 8 12.1989 8 12C8 11.8011 8.07902 11.6103 8.21967 11.4697C8.36032 11.329 8.55109 11.25 8.75 11.25C8.94891 11.25 9.13968 11.329 9.28033 11.4697C9.42098 11.6103 9.5 11.8011 9.5 12C9.5 12.1989 9.42098 12.3897 9.28033 12.5303C9.13968 12.671 8.94891 12.75 8.75 12.75ZM8.75 18.75C8.55109 18.75 8.36032 18.671 8.21967 18.5303C8.07902 18.3897 8 18.1989 8 18C8 17.8011 8.07902 17.6103 8.21967 17.4697C8.36032 17.329 8.55109 17.25 8.75 17.25C8.94891 17.25 9.13968 17.329 9.28033 17.4697C9.42098 17.6103 9.5 17.8011 9.5 18C9.5 18.1989 9.42098 18.3897 9.28033 18.5303C9.13968 18.671 8.94891 18.75 8.75 18.75Z" stroke="#707170" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.75 6.75C14.5511 6.75 14.3603 6.67098 14.2197 6.53033C14.079 6.38968 14 6.19891 14 6C14 5.80109 14.079 5.61032 14.2197 5.46967C14.3603 5.32902 14.5511 5.25 14.75 5.25C14.9489 5.25 15.1397 5.32902 15.2803 5.46967C15.421 5.61032 15.5 5.80109 15.5 6C15.5 6.19891 15.421 6.38968 15.2803 6.53033C15.1397 6.67098 14.9489 6.75 14.75 6.75ZM14.75 12.75C14.5511 12.75 14.3603 12.671 14.2197 12.5303C14.079 12.3897 14 12.1989 14 12C14 11.8011 14.079 11.6103 14.2197 11.4697C14.3603 11.329 14.5511 11.25 14.75 11.25C14.9489 11.25 15.1397 11.329 15.2803 11.4697C15.421 11.6103 15.5 11.8011 15.5 12C15.5 12.1989 15.421 12.3897 15.2803 12.5303C15.1397 12.671 14.9489 12.75 14.75 12.75ZM14.75 18.75C14.5511 18.75 14.3603 18.671 14.2197 18.5303C14.079 18.3897 14 18.1989 14 18C14 17.8011 14.079 17.6103 14.2197 17.4697C14.3603 17.329 14.5511 17.25 14.75 17.25C14.9489 17.25 15.1397 17.329 15.2803 17.4697C15.421 17.6103 15.5 17.8011 15.5 18C15.5 18.1989 15.421 18.3897 15.2803 18.5303C15.1397 18.671 14.9489 18.75 14.75 18.75Z" stroke="#707170" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
export function RemoveIcon({ className }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path id="Vector" d="M15 12H9M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="#F31A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
export function BusIcon({ fillColor }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.75 5.25H5.25C4.42157 5.25 3.75 5.92157 3.75 6.75V12.75C3.75 13.5784 4.42157 14.25 5.25 14.25H18.75C19.5784 14.25 20.25 13.5784 20.25 12.75V6.75C20.25 5.92157 19.5784 5.25 18.75 5.25Z" stroke={fillColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.75 14.25H5.25C4.42157 14.25 3.75 14.9216 3.75 15.75V18.75C3.75 19.5784 4.42157 20.25 5.25 20.25H18.75C19.5784 20.25 20.25 19.5784 20.25 18.75V15.75C20.25 14.9216 19.5784 14.25 18.75 14.25Z" stroke={fillColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.75 5.25H5.25C4.85252 5.24889 4.47163 5.09049 4.19057 4.80943C3.90951 4.52837 3.75111 4.14748 3.75 3.75C3.75111 3.35252 3.90951 2.97163 4.19057 2.69057C4.47163 2.40951 4.85252 2.25111 5.25 2.25H18.75C19.1475 2.25111 19.5284 2.40951 19.8094 2.69057C20.0905 2.97163 20.2489 3.35252 20.25 3.75C20.2489 4.14748 20.0905 4.52837 19.8094 4.80943C19.5284 5.09049 19.1475 5.24889 18.75 5.25ZM6.75 20.25V21.2812C6.75 21.4056 6.70061 21.5248 6.61271 21.6127C6.5248 21.7006 6.40557 21.75 6.28125 21.75H4.96875C4.84443 21.75 4.7252 21.7006 4.63729 21.6127C4.54939 21.5248 4.5 21.4056 4.5 21.2812V20.25H6.75ZM19.5 20.25V21.2812C19.5 21.4056 19.4506 21.5248 19.3627 21.6127C19.2748 21.7006 19.1556 21.75 19.0312 21.75H17.7188C17.5944 21.75 17.4752 21.7006 17.3873 21.6127C17.2994 21.5248 17.25 21.4056 17.25 21.2812V20.25H19.5Z" stroke={fillColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.25 18C17.6642 18 18 17.6642 18 17.25C18 16.8358 17.6642 16.5 17.25 16.5C16.8358 16.5 16.5 16.8358 16.5 17.25C16.5 17.6642 16.8358 18 17.25 18Z" stroke={fillColor} strokeWidth="1.5" strokeLinejoin="round" />
      <path d="M6.75 18C7.16421 18 7.5 17.6642 7.5 17.25C7.5 16.8358 7.16421 16.5 6.75 16.5C6.33579 16.5 6 16.8358 6 17.25C6 17.6642 6.33579 18 6.75 18Z" stroke={fillColor} strokeWidth="1.5" strokeLinejoin="round" />
      <path d="M12 5.25V14.25M3.75 3.75V17.25M20.25 3.75V17.25" stroke={fillColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export function SaveIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16L21 8V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21Z" stroke="#D4A655" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17 21V13H7V21" stroke="#D4A655" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 3V8H15" stroke="#D4A655" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
export function PrintIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M6.72 13.829C6.48 13.859 6.24 13.891 6 13.925M6.72 13.829C10.2263 13.3891 13.7737 13.3891 17.28 13.829M6.72 13.829L6.34 18M17.28 13.829C17.52 13.859 17.76 13.891 18 13.925M17.28 13.829L17.66 18L17.889 20.523C17.9032 20.6787 17.8848 20.8356 17.8349 20.9837C17.7851 21.1319 17.7049 21.268 17.5995 21.3834C17.4942 21.4989 17.3659 21.5911 17.2229 21.6542C17.0799 21.7173 16.9253 21.7499 16.769 21.75H7.231C6.569 21.75 6.051 21.182 6.111 20.523L6.34 18M6.34 18H5.25C4.65326 18 4.08097 17.7629 3.65901 17.341C3.23705 16.919 3 16.3467 3 15.75V9.456C3 8.375 3.768 7.441 4.837 7.281C5.47295 7.18587 6.11074 7.10352 6.75 7.034M17.658 18H18.749C19.0446 18.0001 19.3372 17.942 19.6103 17.829C19.8834 17.716 20.1316 17.5503 20.3406 17.3413C20.5497 17.1324 20.7155 16.8843 20.8286 16.6113C20.9418 16.3382 21 16.0456 21 15.75V9.456C21 8.375 20.232 7.441 19.163 7.281C18.5271 7.18588 17.8893 7.10353 17.25 7.034M17.25 7.034C13.7603 6.6543 10.2397 6.6543 6.75 7.034M17.25 7.034V3.375C17.25 2.754 16.746 2.25 16.125 2.25H7.875C7.254 2.25 6.75 2.754 6.75 3.375V7.034M18 10.5H18.008V10.508H18V10.5ZM15 10.5H15.008V10.508H15V10.5Z" stroke="#D4A655" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export function AutoFillIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M10.4467 8.35792C9.86966 7.78093 8.93424 7.78085 8.35733 8.35776C7.78042 8.93467 7.78049 9.87009 8.35749 10.4471L18.8037 20.8933C19.3807 21.4703 20.3161 21.4703 20.893 20.8934C21.4699 20.3165 21.4698 19.3811 20.8928 18.8041L10.4467 8.35792Z" stroke="#D4A655" strokeWidth="1.5" strokeMiterlimit="10" />
      <path id="Vector_2" d="M8.3615 8.36126C8.22375 8.49898 8.11448 8.66249 8.03993 8.84245C7.96537 9.02241 7.927 9.21529 7.927 9.41008C7.927 9.60487 7.96537 9.79776 8.03993 9.97772C8.11448 10.1577 8.22375 10.3212 8.3615 10.4589L10.4648 12.5622L12.5624 10.4645L10.4592 8.36126C10.3214 8.2235 10.1579 8.11423 9.97796 8.03968C9.798 7.96513 9.60512 7.92676 9.41033 7.92676C9.21554 7.92676 9.02265 7.96513 8.84269 8.03968C8.66273 8.11423 8.49922 8.2235 8.3615 8.36126Z" fill="#D4A655" />
      <path d="M2.25 9H4.5H2.25ZM4.22719 4.22719L5.81813 5.81813L4.22719 4.22719ZM9 2.25V4.5V2.25ZM13.7728 4.22719L12.1819 5.81813L13.7728 4.22719ZM5.81813 12.1819L4.22719 13.7728L5.81813 12.1819Z" fill="#D4A655" />
      <path d="M2.25 9H4.5M4.22719 4.22719L5.81813 5.81813M9 2.25V4.5M13.7728 4.22719L12.1819 5.81813M5.81813 12.1819L4.22719 13.7728" stroke="#D4A655" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>
  )
}

export function NoBusesIcon() {
  return (
    <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame" clipPath="url(#clip0_721_20872)">
        <g id="Group">
          <g id="Clip path group">
            <mask id="mask0_721_20872" maskUnits="userSpaceOnUse" x="0" y="0" width="110" height="110">
              <g id="a">
                <path id="Vector" d="M108.5 108.5V1.50032H1.5V108.5H108.5Z" fill="white" stroke="white" strokeWidth="3" />
              </g>
            </mask>
            <g mask="url(#mask0_721_20872)">
              <g id="Group_2">
                <path id="Vector_2" d="M39.9609 106.777H70.039" stroke="#A5A3AE" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_3" d="M74.3358 3.22296H35.664C30.9179 3.22296 27.0703 7.07059 27.0703 11.8167V56.9337C27.0703 61.6798 30.9179 65.5275 35.664 65.5275H74.3358C79.0819 65.5275 82.9295 61.6798 82.9295 56.9337V11.8167C82.9295 7.07059 79.0819 3.22296 74.3358 3.22296Z" stroke="#A5A3AE" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_4" d="M65.7421 16.1136H44.2578C41.8846 16.1136 39.9609 18.0373 39.9609 20.4104V33.301H70.039V20.4104C70.039 18.0373 68.1153 16.1136 65.7421 16.1136Z" stroke="#EDD7B2" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_5" d="M70.039 33.301H39.9609V46.1916H70.039V33.301Z" stroke="#EDD7B2" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_6" d="M65.7422 46.1916V52.6369" stroke="#EDD7B2" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_7" d="M44.2578 46.1916V52.6369" stroke="#EDD7B2" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_8" d="M55 65.5275V106.777" stroke="#A5A3AE" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_721_20872">
          <rect width="110" height="110" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function DisableSeatIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M18.3641 18.364C20.0519 16.6762 21.0001 14.387 21.0001 12C21.0001 9.61303 20.0519 7.32383 18.3641 5.63599C16.6762 3.94816 14.387 2.99994 12.0001 2.99994C9.61309 2.99994 7.32389 3.94816 5.63606 5.63599M18.3641 18.364C16.6762 20.0518 14.387 21 12.0001 21C9.61309 21 7.32389 20.0518 5.63606 18.364C3.94822 16.6762 3 14.387 3 12C3 9.61303 3.94822 7.32383 5.63606 5.63599M18.3641 18.364L5.63606 5.63599" stroke="#D4A655" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
export function DeleteAllSeatsIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M9.75 9.75L14.25 14.25M14.25 9.75L9.75 14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="#D4A655" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export function EditIcon() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="vuesax/linear/edit-2">
        <g id="edit-2">
          <path id="Vector" d="M13.9264 3.59997L5.71636 12.29C5.40636 12.62 5.10636 13.27 5.04636 13.72L4.67636 16.96C4.54636 18.13 5.38636 18.93 6.54636 18.73L9.76636 18.18C10.2164 18.1 10.8464 17.77 11.1564 17.43L19.3664 8.73997C20.7864 7.23997 21.4264 5.52997 19.2164 3.43997C17.0164 1.36997 15.3464 2.09997 13.9264 3.59997Z" stroke="#D4A655" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path id="Vector_2" d="M12.5557 5.04999C12.9857 7.80999 15.2257 9.91999 18.0057 10.2" stroke="#D4A655" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path id="Vector_3" d="M3.66602 22H21.666" stroke="#D4A655" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
      </g>
    </svg>

  )
}



export function SeatIcon({ bgColor, seatNumber, textColor = "#050001", outlineColor = "#000", onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none" className='pointer user-select-none' onClick={onClick}>
      <mask id="mask0_850_1888" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
        <path d="M62.4998 62.5V1.50019H1.5V62.5H62.4998Z" fill="white" stroke="white" strokeWidth="3" />
      </mask>

      <path d="M12.875 30.0421H7.49999C4.73849 30.0421 2.5 32.2806 2.5 35.042V49.667C2.5 52.4285 4.73849 54.667 7.49999 54.667H56.4998C59.2613 54.667 61.4998 52.4285 61.4998 49.667V35.042C61.4998 32.2806 59.2613 30.0421 56.4998 30.0421H51.1249" fill={bgColor} />
      <path d="M12.875 30.0421H7.49999C4.73849 30.0421 2.5 32.2806 2.5 35.042V49.667C2.5 52.4285 4.73849 54.667 7.49999 54.667H56.4998C59.2613 54.667 61.4998 52.4285 61.4998 49.667V35.042C61.4998 32.2806 59.2613 30.0421 56.4998 30.0421H51.1249" stroke={outlineColor} strokeWidth="2" strokeMiterlimit="10" />
      <path d="M12.5 53.792V18.1671C12.5 14.025 15.8579 10.6671 20 10.6671H43.9999C48.142 10.6671 51.4999 14.025 51.4999 18.1671V53.792" fill={bgColor} />
      <path d="M12.5 53.792V18.1671C12.5 14.025 15.8579 10.6671 20 10.6671H43.9999C48.142 10.6671 51.4999 14.025 51.4999 18.1671V53.792" stroke={outlineColor} strokeWidth="2" strokeMiterlimit="10" />
      <path d="M12.5 49.417C12.5 46.6555 14.7385 44.417 17.5 44.417H46.4999C49.2614 44.417 51.4999 46.6555 51.4999 49.417" stroke={outlineColor} strokeWidth="2" strokeMiterlimit="10" />
      <text x="22" y="34" fontSize="18" fontWeight="600" fill={textColor}>{seatNumber}</text>
    </svg>
  )
}
