import GalleryIcon from "modules/market-place/NewMarketplace/icons/galleryIcon";
import HotelIcon from "modules/market-place/NewMarketplace/icons/hotelIcon";
import HotelSpecialIcon from "modules/market-place/NewMarketplace/icons/hotelSpecialIcon";
import OverviewIcon from "modules/market-place/NewMarketplace/icons/overviewIcon";
import PricingIcon from "modules/market-place/NewMarketplace/icons/pricingIcon";
import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Pricing from "./pricing";
import Overview from "./overview";
import HotelFacilities from "./hotelFacilities";
import Gallery from "./gallery";
import Locale from 'translations';

const Details = ({item,index}) => {
	const { productsBuilder } = Locale;

	const [activeTab, setActiveTab] = useState(1);
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	const tab = [
		{
			id: 1,
			name: productsBuilder.pricing,
			activeImg: <PricingIcon color="#D4A655" />,
			image: <PricingIcon color="#707170" />,
		},
		{
			id: 2,
			name: productsBuilder.gallery,
			activeImg: <GalleryIcon color="#D4A655" />,
			image: <GalleryIcon color="#707170" />,
		},
		{
			id: 3,
			name: productsBuilder.overview,
			activeImg: <OverviewIcon color="#D4A655" />,
			image: <OverviewIcon color="#4B465C" />,
		},
		// {
		// 	id: 4,
		// 	name: "Hotel Facilities",
		// 	activeImg: <OverviewIcon color="#D4A655" />,
		// 	image: <OverviewIcon color="#4B465C" />,
		// },

		{
			id: 4,
			name: productsBuilder.hotelFacilities,
			activeImg: <HotelSpecialIcon color="#D4A655" />,
			image: <HotelSpecialIcon color="#4B465C" />,
		},
	];
	return (
		<div className="allotment-details w-100" >
			<Nav tabs className="align-items-center main-nav my-0">
				{tab.map((item, index) => (
					<NavItem key={item.id+"tab"}>
						<NavLink
							className={` ${activeTab === item.id && "active"}`}
							onClick={() => {
								toggle(item.id);
							}}
						>
							{activeTab === item.id ? item.activeImg : item.image} {item.name}
						</NavLink>
					</NavItem>
				))}
			</Nav>
			<TabContent activeTab={activeTab} className="tab-content">
				<TabPane tabId={1}>
					<Pricing item={item} />
				</TabPane>
				<TabPane tabId={2}>
					<Gallery item={item} />
				</TabPane>
				<TabPane tabId={3}>
					<Overview item={item} />
				</TabPane>
				<TabPane tabId={4}>
					<HotelFacilities item={item} />
				</TabPane>
			</TabContent>
		</div>
	);
};

export default Details;
