import NumberField from 'components/Form/NumberField/NumberField'
import React from 'react'
import Locale from 'translations';
// import Locale from 'translations'

export default function AccountingTotalPricing({ isPricePerService, totalPrice, accountingStatement, setAccountingStatement, applyTaxesOnSelling, canEdit }) {
  const { operationStatement } = Locale;


  return (
    <div className="total-pricing px-new-26 w-100">
      <h4 className="header">
        {operationStatement.Price}
      </h4>
      <div style={{ width: "70%" }} className="mx-auto">
        <div className="pricing-inputs">
          <span className="item pax">{operationStatement.Pax}</span>
          <span className="item buying">{operationStatement.BuyingCost}</span>
          <span className="item selling">{operationStatement.Sellingcost}</span>
          <span className="item profit">{operationStatement.Profit}</span>
        </div>

        <div className="pricing-inputs">
          <span className="item pax-count">
            {(accountingStatement?.adults || 0) + (accountingStatement?.children || 0) + (accountingStatement?.infants || 0)}
          </span>
          <div className="item p-0">
            <NumberField
              hasLabel={false}
              placeholder={operationStatement.Price}
              removeArrow={true}
              value={isPricePerService ? (+totalPrice?.totalBuying).toFixed(2) : accountingStatement?.total_package_buying}
              onChange={(e) => setAccountingStatement({ ...accountingStatement, total_package_buying: e.target.value })}
              onWheel={(e) => e.target.blur()}
              disabled={isPricePerService || !canEdit}
            />
          </div>
          <div className="item p-0">
            <NumberField
              hasLabel={false}
              placeholder={operationStatement.Price}
              removeArrow={true}
              value={isPricePerService ? (+totalPrice?.totalSelling).toFixed(2) : accountingStatement?.total_package_selling}
              onChange={(e) => setAccountingStatement({ ...accountingStatement, total_package_selling: e.target.value })}
              onWheel={(e) => e.target.blur()}
              disabled={isPricePerService || !canEdit}
            />
          </div>
          <span className="item total-profit">{(totalPrice?.totalSelling - totalPrice?.totalBuying) < 0 ? 0 : (totalPrice?.totalSelling - totalPrice?.totalBuying).toFixed(2)}</span>
        </div>

        <div className="apply-discount">
          <input
            type="checkbox"
            id="apply-discount"
            name="apply-discount"
            checked={accountingStatement?.with_taxes}
            onChange={() => applyTaxesOnSelling()}
            disabled={!canEdit}
          />
          <label htmlFor="apply-discount" className="mx-1 mb-0 pointer user-select-none text-black-600">{operationStatement.Apply15taxes}</label>
        </div>

        <div className="price-with-taxes mt-4">
          <span className="text-black-600">
            {accountingStatement?.with_taxes ? operationStatement.Totalpricewithtaxes : operationStatement.Totalprice}
          </span>
          <p>
            <span className="price-amount">
              {accountingStatement?.with_taxes ?
                (+totalPrice?.totalSelling + (+totalPrice?.totalSelling * 0.15)).toFixed(2)
                :
                (+totalPrice?.totalSelling).toFixed(2)
              }

            </span>
            <span className="price-currency"> SAR</span>
          </p>
        </div>
      </div>

    </div>
  )
}
