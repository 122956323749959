import filterReset from "assets/images/filterReset.svg";
import TimePickerField from "components/Form/TimePickerField/TimePickerField";
import { useSBSState } from "context/global";
import Locale from "translations";
import CollapseSidebar from "./collapseSidebar";

function SideFilghtFilter({ filtersLookup, filters, setFilters }) {
	const { payment, marketPlace } = Locale;
	const { locale } = useSBSState();

	function filterByCheckbox(e, obj, filterName, transfer) {
		const checked = e.target.checked;
		let checkedArray = filters?.[filterName];
		if (checked) {
			checkedArray = [...filters[filterName], transfer ? obj : obj?.code];
		} else {
			if (transfer) {
				checkedArray = checkedArray.filter((c) => c !== obj);
			} else {
				checkedArray = checkedArray.filter(
					(c) => c.toString() !== obj?.code.toString()
				);
			}
		}
		setFilters({ ...filters, [filterName]: checkedArray, page: 1 });
	}

	function reset() {
		setFilters({
			transferCount: [],
			stops: [],
			airLines: [],
			departureTime: {},
			arrivalTime: {},
			durationTime: {},
			sortBy: "price",
			sortType: "desc",
			page: 1,
		});
	}
	return (
		<>
			<div className="d-flex justify-content-between align-items-center mt-2">
				<div className="filter-head terms-color text-uppercase ">
					<h6 className="font-weight-bold h6 hotel-found">
						{marketPlace.messages.filterBy}:
					</h6>
				</div>
				<button className="btn bg-white clear-filter px-3 mx-2" onClick={reset}>
					<img src={filterReset} alt="" className="mx-2" />
					{payment.messages.clear}
				</button>
			</div>
			<div>
				<CollapseSidebar title={marketPlace.Stops}>
					{filtersLookup?.transferCount?.map((transfer) => (
						<div key={transfer}>
							<input
								className="me-2"
								id={"transfer" + transfer}
								type="checkbox"
								value={transfer}
								onChange={(e) => {
									filterByCheckbox(e, transfer, "transferCount", true);
								}}
								checked={filters?.transferCount?.includes(transfer)}
							/>
							<label data htmlFor={"transfer" + transfer}>
								{transfer}
							</label>
						</div>
					))}
				</CollapseSidebar>

				<CollapseSidebar title={marketPlace.Airlines}>
					{filtersLookup?.airLines?.map((air) => (
						<div key={air?.code}>
							<input
								className="me-2"
								id={air?.code}
								type="checkbox"
								value={air?.code}
								onChange={(e) => {
									filterByCheckbox(e, air, "airLines");
								}}
								checked={filters?.airLines?.includes(air?.code)}
							/>
							<label data htmlFor={air?.code}>
								{air?.name?.[locale]}
							</label>
						</div>
					))}
				</CollapseSidebar>

				<CollapseSidebar title={marketPlace.TakeoffTime}>
					<div className="d-flex  align-items-center mt-3 filter-price-range">
						{/* price range from */}
						<div>
							<TimePickerField
								// label={marketPlace.returnDate}
								date={filters?.departureTime?.from}
								placeholder="00:00"
								onChangeDate={(date) => {
									setFilters((prev) => ({
										...prev,
										page: 1,
										departureTime: {
											...prev.departureTime,
											from: date,
										},
									}));
								}}
							/>
						</div>
						<span className="mx-2 text-uppercase">{marketPlace.to}</span>
						<div>
							<TimePickerField
								// label={marketPlace.returnDate}
								date={filters?.departureTime?.to}
								placeholder="00:00"
								onChangeDate={(date) => {
									setFilters((prev) => ({
										...prev,
										page: 1,
										departureTime: {
											...prev.departureTime,
											to: date,
										},
									}));
								}}
							/>
						</div>
						{/* price range to */}
					</div>
				</CollapseSidebar>

				<CollapseSidebar title={marketPlace.LandingTime}>
					<div className="d-flex  align-items-center mt-3 filter-price-range">
						{/* price range from */}
						<div>
							<TimePickerField
								// label={marketPlace.returnDate}
								date={filters?.arrivalTime?.from}
								placeholder="00:00"
								onChangeDate={(date) => {
									setFilters((prev) => ({
										...prev,
										page: 1,
										arrivalTime: {
											...prev.arrivalTime,
											from: date,
										},
									}));
								}}
							/>
						</div>
						<span className="mx-2 text-uppercase">{marketPlace.to}</span>
						<div>
							<TimePickerField
								// label={marketPlace.returnDate}
								date={filters?.arrivalTime?.to}
								placeholder="00:00"
								onChangeDate={(date) => {
									setFilters((prev) => ({
										...prev,
										page: 1,
										arrivalTime: {
											...prev.arrivalTime,
											to: date,
										},
									}));
								}}
							/>
						</div>
						{/* price range to */}
					</div>
				</CollapseSidebar>
				<CollapseSidebar title={marketPlace.DurationTravel}>
					<div className="d-flex  align-items-center mt-3 filter-price-range">
						{/* price range from */}
						<div>
							<TimePickerField
								// label={marketPlace.returnDate}
								date={filters?.durationTime?.from}
								placeholder="00:00"
								onChangeDate={(date) => {
									setFilters((prev) => ({
										...prev,
										page: 1,
										durationTime: {
											...prev.durationTime,
											from: date,
										},
									}));
								}}
								timeFormat="HH:mm"
							/>
						</div>
						<span className="mx-2 text-uppercase">{marketPlace.to}</span>
						<div>
							<TimePickerField
								// label={marketPlace.returnDate}
								date={filters?.durationTime?.to}
								placeholder="00:00"
								onChangeDate={(date) => {
									setFilters((prev) => ({
										...prev,
										page: 1,
										durationTime: {
											...prev.durationTime,
											to: date,
										},
									}));
								}}
								timeFormat="HH:mm"
							/>
						</div>
						{/* price range to */}
					</div>
				</CollapseSidebar>
				<CollapseSidebar title={marketPlace.Stop}>
					{filtersLookup?.stops?.map((stop) => (
						<div key={stop?.code}>
							<input
								className="me-2"
								id={stop?.code}
								type="checkbox"
								value={stop?.code}
								onChange={(e) => {
									filterByCheckbox(e, stop, "stops");
								}}
								checked={filters?.stops?.includes(stop?.code)}
							/>
							<label data htmlFor={stop?.code}>
								{stop?.names?.[locale]}
							</label>
						</div>
					))}
				</CollapseSidebar>
			</div>
		</>
	);
}

export default SideFilghtFilter;
