import React from 'react'
import safaLogo from 'assets/images/whiteLogo.svg'
import Locale from 'translations';

export default function BestDealsFooter() {
				    const { marketPlace } = Locale;
  return (
		<footer className="w-100">
			<div className="deals-footer py-4">
				<img src={safaLogo} alt="" className="white-logo" />
				<p className="text-center">
					{marketPlace.caption1}
					<br />
					{marketPlace.caption2}
				</p>
				<div className="list-social">
					<ul className="social">
						<li className="facebook ">
							<a
								href="https://www.facebook.com/Safasoftofficial"
								target="_blank"
								rel="noreferrer"
							>
								<i className="fab fa-facebook-f"></i>
							</a>
						</li>

						<li className="twitter">
							<a
								href="https://twitter.com/safa_soft"
								target="_blank"
								rel="noreferrer"
							>
								<i className="fab fa-twitter"></i>
							</a>
						</li>
						<li className="pinterest">
							<a
								href="https://www.linkedin.com/company/safa-soft/"
								target="_blank"
								rel="noreferrer"
							>
								<i className="fab fa-linkedin"></i>
							</a>
						</li>
						<li className="instagram">
							<a
								href="https://www.instagram.com/safasoftofficial/"
								target="_blank"
								rel="noreferrer"
							>
								<i className="fab fa-instagram"></i>
							</a>
						</li>
						<li className="youtube">
							<a
								href="https://www.youtube.com/@safasoftofficial"
								target="_blank"
								rel="noreferrer"
							>
								<i className="fab fa-youtube"></i>
							</a>
						</li>
					</ul>
				</div>
				<p className="all-right">© 2023 SAFA SOFT . All Rights Reserved.</p>
			</div>
		</footer>
	);
}
