import { useState } from 'react';
import Locale from 'translations';

export default function SummaryInfo({hotelRequest,checkFormallErrors}) {
  const { productsBuilder } = Locale;

	const [checked, setChecked] = useState(false)

	let total=0
	return (
		<div className="summary-info">
			<h6>{productsBuilder.Summary}</h6>
			{hotelRequest.room && hotelRequest.room.length > 0
				? hotelRequest.room.map((room, index) =>
						room.room_rates && room.room_rates.length > 0
							? room.room_rates.map((rate) => 
							{		
								total=+total+	rate?.room_total_price*+rate?.count
								return		<SummaryRoom room={room} rate={rate} index={index} key={index} />}
							  )
							: null
				  )
				: null}
			<hr />
			<div className="total-cost">
				<h6>{productsBuilder.ReservationTotalCost}</h6>
				<p>
				{total}<span>{hotelRequest.currency}</span>
				</p>
			</div>
			<hr />
			<div className="terms">
				<div className="custom-control custom-checkbox list-with-height">
					<input
						className="custom-control-input permChecks"
						id={"terms"}
						checked={checked}
						name="accommodations"
						type="checkbox"
						onChange={(e)=>setChecked(e.target.checked)}
					/>
					<label
						className="custom-control-label my-1 text-capitalize"
						htmlFor={"terms"}
					>
						{productsBuilder.AgreeTermsConditions}
					</label>
				</div>
			</div>
			<button className="btn btn-yellow" disabled={!checked} onClick={()=>checkFormallErrors()}>
				{productsBuilder.RequestReservation}
			</button>
		</div>
	);
}

export function SummaryRoom({index,room,rate}) {
  const { productsBuilder } = Locale;

	return (
		<div className="room">
			<p className="room-num">
				{" "}
				<i class="fas fa-square"></i> {productsBuilder.Room} #{index+1}
			</p>
			<div className="info">
				<span>{productsBuilder.RoomType}</span>
				<p>{room?.name}</p>
			</div>

			<div className="info">
				<span>{productsBuilder.BoardBasis}</span>
				<p>{rate?.mealTitle}</p>
			</div>
			<div className="info">
				<span>{productsBuilder.Roomoccupancy}</span>
				<p>{room?.paxes?.children} {productsBuilder.children}, {room?.paxes?.adults} {productsBuilder.Adult}</p>
			</div>
			<div className="info">
				<span>{productsBuilder.Quantity}</span>
				<p>{rate?.count}</p>
			</div>
			<hr />
			<div className="info">
				<span>{productsBuilder.RoomCost}</span>
				<p className="cost">
				{+rate?.room_total_price*+rate?.count} <p className="cur">{rate?.room_price_currency}</p>
				</p>
			</div>
		</div>
	);
}
