import { DepartureIcon, HotelIcon, TransportationIcon } from 'modules/UmrahOperations/shared/Icons'
import React from 'react'
import { CameraFlashIcon } from '../Icons';
import Locale from 'translations';

export default function ItineraryDays({ tripPlannerData, selectedDay, handleSelectDay, isDaysDisabled, handleSortItems, canEdit }) {
  const { productsBuilder, packagePlanner } = Locale;

  return (
    <ul className="itinerary-days">
      {tripPlannerData.itinerary_days.map((dayItem, index) => {
        const dayItemIsSelected = selectedDay?.day === dayItem?.day;
        return (
          <li className={`day-item`} key={`day-${index}`}>

            <div className={`day-info ${dayItemIsSelected ? "active" : ""} ${isDaysDisabled ? "disabled" : ""}`}
              onClick={() => !isDaysDisabled && handleSelectDay(dayItem?.items, dayItem?.day)}
            >
              <span>{productsBuilder.day} {dayItem?.day}</span>

              {dayItem?.items?.length > 0 ?
                <span className="items-count">
                  {dayItem?.items?.length} {" "}
                  {dayItem?.items?.length > 0 && dayItem?.items?.length === 1 ? packagePlanner.activity : null}
                  {dayItem?.items?.length > 0 && dayItem?.items?.length > 1 ? packagePlanner.activities : null}
                </span>
                :
                null
              }
            </div>

            {dayItem?.items?.length > 0 ?
              <div className="day-activities">
                {dayItem?.items.map((service, serviceIndex) => {
                  return (
                    <div className="activity text-capitalize" key={`day-activity-${serviceIndex}`}>

                      {service?.item_type === "hotel" &&
                        <>
                          <div className="sort-btn-group">
                            {/* sort up button */}
                            <button className="btn shadow-none p-0"
                              disabled={serviceIndex === 0 || !dayItemIsSelected || !canEdit}
                              onClick={() => handleSortItems(service, serviceIndex, "up", "hotel")}
                            >
                              <i className="fas fa-chevron-up"></i>
                            </button>
                            {/* sort down button */}
                            <button className="btn shadow-none p-0"
                              disabled={serviceIndex === dayItem?.items.length - 1 || !dayItemIsSelected || !canEdit}
                              onClick={() => handleSortItems(service, serviceIndex, "down", "hotel")}
                            >
                              <i className="fas fa-chevron-down"></i>
                            </button>
                          </div>

                          <span className="trip-planner-icon-wrapper">
                            <HotelIcon color="#707170" />
                          </span>
                          {service?.hotel_name?.name}
                        </>
                      }
                      {service?.item_type === "flight" &&
                        <>
                          <div className="sort-btn-group">
                            {/* sort up button */}
                            <button className="btn shadow-none p-0"
                              disabled={serviceIndex === 0 || !dayItemIsSelected || !canEdit}
                              onClick={() => handleSortItems(service, serviceIndex, "up", "flight")}
                            >
                              <i className="fas fa-chevron-up"></i>
                            </button>
                            {/* sort down button */}
                            <button className="btn shadow-none p-0"
                              disabled={serviceIndex === dayItem?.items.length - 1 || !dayItemIsSelected || !canEdit}
                              onClick={() => handleSortItems(service, serviceIndex, "down", "flight")}
                            >
                              <i className="fas fa-chevron-down"></i>
                            </button>
                          </div>
                          <span className="trip-planner-icon-wrapper">
                            <DepartureIcon color="#707170" />
                          </span>
                          {service?.from_country?.name} - {service?.to_country?.name}
                        </>
                      }
                      {service?.item_type === "transportation" &&
                        <>
                          <div className="sort-btn-group">
                            {/* sort up button */}
                            <button className="btn shadow-none p-0"
                              disabled={serviceIndex === 0 || !dayItemIsSelected || !canEdit}
                              onClick={() => handleSortItems(service, serviceIndex, "up", "transportation")}
                            >
                              <i className="fas fa-chevron-up"></i>
                            </button>
                            {/* sort down button */}
                            <button className="btn shadow-none p-0"
                              disabled={serviceIndex === dayItem?.items.length - 1 || !dayItemIsSelected || !canEdit}
                              onClick={() => handleSortItems(service, serviceIndex, "down", "transportation")}
                            >
                              <i className="fas fa-chevron-down"></i>
                            </button>
                          </div>
                          <span className="trip-planner-icon-wrapper">
                            <TransportationIcon color="#707170" />
                          </span>
                          {service?.from?.name} - {service?.to?.name}
                        </>
                      }
                      {service?.item_type === "attraction" &&
                        <>
                          <div className="sort-btn-group">
                            {/* sort up button */}
                            <button className="btn shadow-none p-0"
                              disabled={serviceIndex === 0 || !dayItemIsSelected || !canEdit}
                              onClick={() => handleSortItems(service, serviceIndex, "up", "attraction")}
                            >
                              <i className="fas fa-chevron-up"></i>
                            </button>
                            {/* sort down button */}
                            <button className="btn shadow-none p-0"
                              disabled={serviceIndex === dayItem?.items.length - 1 || !dayItemIsSelected || !canEdit}
                              onClick={() => handleSortItems(service, serviceIndex, "down", "attraction")}
                            >
                              <i className="fas fa-chevron-down"></i>
                            </button>
                          </div>
                          <span className="trip-planner-icon-wrapper">
                            <CameraFlashIcon color="#707170" />
                          </span>
                          {service?.name}
                        </>
                      }
                    </div>
                  )
                })}
              </div>
              :
              null
            }
          </li>
        )
      })}
    </ul>

  )
}
