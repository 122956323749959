
import React from 'react'
import ToursSearchForm from './ToursSearchForm';

export default function ToursSearch() {

  return (
    <ToursSearchForm />
  )
}
