import { useWebBuilderDispatch } from 'context/webBuilder';
import { useState } from 'react';
import { ReactComponent as SettingIcon } from 'assets/images/webBuilder/setting.svg'

export default function useWBFooter() {

  const dispatch = useWebBuilderDispatch();

  const [socialMediaModalOpened, setSocialMediaModalOpened] = useState(false)
  function toggleSocialMediaModal() {
    setSocialMediaModalOpened(!socialMediaModalOpened)
  }

  function updateFooterText({ name, value, language }) {
    dispatch({
      type: 'updateFooter',
      payload: {
        name,
        value,
        language
      }
    })
  }


  function updateFooterContent(keyName, value, index) {
    dispatch({
      type: 'updateFooter',
      payload: {
        type: keyName,
        value,
        name: index
      }
    })
  }

  const scrollToSection = (id) => {
    if (id) {
      const element = document.getElementById(id)
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const containerBoxActions = [
    <span onClick={toggleSocialMediaModal} className='pointer'>
      <SettingIcon />
    </span>
  ]

  return {
    updateFooterContent,
    updateFooterText,
    scrollToSection,
    socialMediaModalOpened,
    toggleSocialMediaModal,
    containerBoxActions
  }
}
