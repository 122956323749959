
export default function sbsReducer(state, action) {

	switch (action.type) {
		case "setLocale":
			localStorage.setItem("currentLocale", action.payload);
			window.location.reload();
			return { ...state, locale: action.payload };

		case "setLoading":
			return { ...state, loading: action.payload };

		case "setError":
			return {
				...state,
				isError: action.payload,
				ErrorMassage: { ...action.message },
			};


		case "setIsOpenSurveyModal":
			return {
				...state,
				isOpenSurveyModal: action.payload,
				ErrorMassage: { ...action.message },
			}
		case "serverErrors":
			return {
				...state,
				serverErrors: action.payload,
			};

		case "searchOffer":
			return {
				...state,
				token_data: action.payload,
			};

		case "searchOfferClear":
			return {
				...state,
				token_data: undefined,
			};

		case "login":
			localStorage.setItem("isAuth", true);
			localStorage.setItem("token", action.token);
			localStorage.setItem(
				"companyName",
				action.companyInfo.name && action.companyInfo.name.hasOwnProperty("en")
					? action.companyInfo?.name?.en
					: action.companyInfo?.name?.ar
			);
			
			localStorage.setItem(
				"companyInfo",
				JSON.stringify(action.companyInfo)
			);
			localStorage.setItem("userInfo", JSON.stringify(action.userInfo));

			localStorage.setItem("userInfo", JSON.stringify(action.userInfo));
			localStorage.setItem("companyInfo", JSON.stringify(action?.companyInfo));
			localStorage.setItem("permissions", JSON.stringify(action?.permissions));
			localStorage.setItem("role", action?.role);
			localStorage.setItem("share_b2b", action?.share_b2b);

			return {
				...state,
				isAuth: action.isAuth,
				token: action.token,
				userInfo: action.userInfo,
				companyInfo: action.companyInfo,
				companyName:
					action?.companyInfo?.name &&
						action?.companyInfo?.name.hasOwnProperty("en")
						? action.companyInfo?.name?.en
						: action.companyInfo?.name?.ar,
				permissions: action?.permissions,
				role: action?.role,
				share_b2b: action?.share_b2b,
			};


			case "updateCompanyInfo":
				localStorage.setItem(
					"companyInfo",
					JSON.stringify(action.companyInfo)
				);
				return {
					...state,
					companyInfo: action.companyInfo,
				};

		case "updateUserInfoOtp":
			localStorage.setItem("userInfoOtp", JSON.stringify(action.userInfoOtp));
			return {
				...state,
				userInfoOtp: action.userInfoOtp,
			}
		case "updateUserInfo":
			localStorage.setItem("userInfo", JSON.stringify(action.userInfo));
			return {
				...state,
				userInfo: action.userInfo,
			}

		case "logout":
			localStorage.removeItem("isAuth");
			localStorage.removeItem("token");
			localStorage.removeItem("VisaToken");
			localStorage.removeItem("userInfo");

			return {
				...state,
				isAuth: false,
				token: null,
				userInfo: null,
				companyInfo: null,
				dynamicNav: null,
			};

		case "dynamicNav": {
			return {
				...state,
				dynamicNav: action?.payload,
			};
		}

		case "getPermissions":
			localStorage.setItem("role", action?.payload?.role);
			localStorage.setItem(
				"permissions",
				JSON.stringify(action?.payload?.permissions)
			);
			return {
				...state,
				permissions: action?.payload?.permissions,
				role: action?.payload?.role,
			};

		case "subscriptionPlan": {
			localStorage.setItem(
				"subscriptionPlan",
				JSON.stringify(action?.payload)
			);
			return {
				...state,
				subscriptionPlan: action?.payload,
			};
		}

		case "getSafaBalance":
			return { ...state, safaBalance: action?.payload };
		case "getVoucher":
			return { ...state, voucher: action?.payload };
		case "getMyBalance":
			return { ...state, myBalance: action?.payload };
		case "Save_User_Logo":
			return {
				...state,
				userLogo: action.userLogo,
			};

		case "guide": {
			return { ...state, guide: action.payload }
		}

		case "removeFirstLogin": {
			localStorage.setItem("userInfo", JSON.stringify(action.payload));
			return { ...state, userInfo: action.payload }
		}
		case "getCountries": {
			return { ...state, allCountries: action.payload }
		}
		case "getCurrencies": {
			return { ...state, allCurrencies: action.payload }
		}
		case "getAllDataLogin": {
			return { ...state, allDataLogin: action.payload }
		}
		case "getLookupsBuilder": {
			return { ...state, allLookupsBuilder: action.payload }
		}
		case "setCurrency": {
			let currentUrl = window.location.href
			localStorage.setItem("currentCurrency", action.payload);
			// hotels 
			if (window.location.href.includes("/confirm-hotel")) {
				const status = window.location.pathname.split("/")[1];
				currentUrl = '/'+ status + '/market-view';
			}
			// tours
			if ( !window.location.href.includes("inventory") && (window.location.href.includes("/tour") || window.location.href.includes("/tours"))  ) {
				localStorage.removeItem("toursCart");
				let toursSearch = JSON.parse(localStorage.getItem('toursSearch'));
				localStorage.setItem('toursSearch', JSON.stringify({ ...toursSearch, cacheKey: null }));
				const status = window.location.pathname.split("/")[1];
				currentUrl = '/' + status + '/tours-result';
			}
			// transfer
			if (!window.location.href.includes("/inventory") && window.location.href.includes("/transfer")) {
				localStorage.removeItem("transferCart");
				const status = window.location.pathname.split("/")[1];
				currentUrl = '/' + status + '/transfer'
			}
			// flights
			if (window.location.href.includes("/outbound-view") || window.location.href.includes("/inbound-view") ) {
				localStorage.removeItem("flightsSearch");
				const status = window.location.pathname.split("/")[1];
				currentUrl = '/' + status + '/market-place-new/flight'
			}
			// visa saudi umrah
			if (window.location.href.includes("/market-search/umrah-visa-details")) {
				currentUrl = '/market-place-new/umrah/visa'
			}
			// visa global
			if (window.location.href.includes("/market-search/global-visa-details")) {
				currentUrl = '/market-place-new/global/visa'
			}

			window.location.href = currentUrl;
			return { ...state, currency: action.payload };
		}
		
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}
