export const ROOMS_TYPES = {
	1: "SINGLE",
	2: "DOUBLE",
	3: "TRIPLE",
	4: "QUADRUPLE",
	5: "QUINTUPLE",
	6: "SEXTUPLE",
	7: "SETUPLE",
};
export const MEALS_TYPE = [
	{ id: "3", name: "All Inclusive" },
	{ id: "2", name: "Half board" },
	{ id: "1", name: "Breakfast" },
	{ id: "0", name: "Room Only" },
]
