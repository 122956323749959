import { useWebBuilderState } from 'context/webBuilder'
import { EditBox } from 'modules/WebBuilder/shared/EditBox'
import SocialMediaModal from './SocialMediaModal'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import Locale from 'translations'
import useWBFooter from './useWBFooter'
import { logoURL } from 'helpers/utils'


const currentYear = new Date().getFullYear();

export default function FooterThemeOne({ focusContainer, handleFocus, socialMediaIcons }) {
	const { webBuilder } = Locale;
	const { footer: footerDetails, hero, selectedLanguage } = useWebBuilderState();
	const { scrollToSection, socialMediaModalOpened, toggleSocialMediaModal, containerBoxActions } = useWBFooter()
	const location = useLocation()
	const history = useHistory()
	const params = useParams()
	const preview = !location.pathname.includes('edit')

	const header = hero?.header


	const type = 'footer'


	const nav = (
		<nav className='nav-list'>
			<ul className='links-list'>
				<li
					onClick={() => {
						if (preview) {
							if (params.name) {
								history.push(`/public-page-v2/${params.name}`)
							} else {
								history.push(`/web-builder-v2/preview`)
							}
							scrollToSection('main-web-builder')
						}
					}}
					className={'text-secondary-color'}
				>
					{webBuilder.home}
				</li>

				<li
					onClick={() => {
						let url = params.name
							? `/public-page-v2/${params.name}`
							: '/web-builder-v2/preview'
						if (params.id) {
							history.push({ pathname: `${url}`, state: 'contact-us-section' })
						} else {
							scrollToSection('contact-us-section')
						}
					}}
					className={'text-secondary-color'}
				>
					{webBuilder.getHelp}
				</li>

				<li
					onClick={() => {
						let url = params.name
							? `/public-page-v2/${params.name}`
							: '/web-builder-v2/preview'
						if (params.id) {
							history.push({
								pathname: `${url}`,
								state: 'web-builder-our-service'
							})
						} else {
							scrollToSection('web-builder-our-service')
						}
					}}
					className={'text-secondary-color'}
				>
					{webBuilder.ourServices}
				</li>
			</ul>
		</nav>
	)

	const logoImage = header?.logo[selectedLanguage] ? (
		<img className='footer-photo' src={logoURL(header?.logo, header?.logo[selectedLanguage])} alt='Logo' />
	) : null

	return (
		<>
			<div className='container'>
				<div className='web-builder-footer'>
					<div className='d-flex align-items-center footer-icon footer-logo justify-content-center'>
						{header?.hasLogo ? logoImage : null}
					</div>

					<div className='footer-links'>{nav}</div>

					{/* social icons */}
					<div className='footer-socialIcons' onClick={() => handleFocus(type)}>
						<p className='footer-text'>{webBuilder.followUsOn}</p>
						<EditBox editMode={focusContainer === type} defaultIcon={containerBoxActions}>
							<div className='d-flex align-items-center gap-10 py-2'>
								{footerDetails?.links?.map((link) => {
									if (!link?.visible) return null
									return (
										<div key={link?.id}>
											<a href={link?.url} target='_blank' rel='noreferrer'>
												{socialMediaIcons[link?.id]}
											</a>
										</div>
									)

								})}
							</div>
						</EditBox>
					</div>

					<div className='footer-copyrights align-items-center'>
						<p>
							{webBuilder.allRightsReserved} {" - "}
							<a href="https://safasoft.com/" target="_blank" rel="noreferrer" className="text-white">{webBuilder.safasoft}</a>
							{" "} © {currentYear}
						</p>
					</div>
				</div>
			</div>
			{socialMediaModalOpened && footerDetails?.links ? (
				<SocialMediaModal
					isOpen={socialMediaModalOpened}
					toggle={toggleSocialMediaModal}
					header={'Social links'}
					socialMediaLinks={footerDetails?.links}
				/>
			) : null}
		</>
	)
}