import axios from 'axios'
import { cleanEmpty } from './general'

const subscriptionPlanURL = process.env.REACT_APP_API_URL + '/v1'

export const subscriptionPlanList = async (filters = {}) => {
	return await axios
		.get(`${subscriptionPlanURL}/subscription-plan`, {
			params: cleanEmpty(filters)
		})
		.then((res) => res)
		.catch((err) => err.response)
}

export const subscriptionModules = async (planId) => {
	return await axios
		.get(`${subscriptionPlanURL}/subscription-plan/${planId}`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const paySubscriptionPlan = async (planId, data) => {
	return await axios
		.post(`${subscriptionPlanURL}/subscription-plan/${planId}/pay`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const payPackagePlan = async (id,data) => {
	return await axios
		.post(`${subscriptionPlanURL}/subscription-package/${id}/pay`, data)
		.then((res) => res)
		.catch((err) => err.response)
}

export const FetchEmailForAgree = async (id, data) => {
	return await axios
		.post(`${subscriptionPlanURL}/subscription-plan/${id}/email-for-agree-terms`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const SendSubscriptionOTP = async (id, data) => {
	return await axios
		.post(`${subscriptionPlanURL}/subscription-plan/${id}/code-for-agree-terms`, data)
		.then((res) => res)
		.catch((err) => err.response)
}