import React, { useState } from "react";
import { Collapse, Modal, ModalBody, ModalHeader } from "reactstrap";
import RequestItemHeader from "./RequestItemHeader";
import Locale from "translations";
import axios from "axios";
import { store } from "react-notifications-component";
import ChangePayment from "./ChangePaymentModal";
import { flightChangePendingApproval } from 'services/inventory';
import moment from 'moment';
import NewPaymentAction from 'components/NewPaymentAction';

export default function ChangeRequest({ request, fetchRequest }) {
	const { inventory, interests } = Locale;
	const [isOpen, setIsOpen] = useState(false);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [paymentModal, setPaymentModal] = useState(false);
	const [passengerCollapse, setPassengerCollapse] = useState([]);


	const [actionType, setActionType] = useState('');
  const [formData, setFormData] = useState({});

	function togglePassengerCollapse(index) {
		setPassengerCollapse((prev) => {
			const clone = [...prev];
			clone[index] = !clone[index];
			return clone;
		});
	}
	function toggleCollapse() {
		setIsOpen(!isOpen);
	}
	function toggleConfirmModal() {
		setConfirmModalOpen(!confirmModalOpen);
	}
	function togglePaymentModal() {
		setActionType('')
		setFormData({})
		setPaymentModal((prev) => !prev);
	}
	// "pending" | "rejected" | "pending approval" | "under review" | "confirmed";
	const status = request?.status;

	function downloadCertFiles() {
		const certFiles = request?.certification_files;
		const fetchFilesURL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
		const config = {
			fetchFilesURL,
			method: "GET",
			responseType: "blob",
		};
		for (let i = 0; i < certFiles.length; i++) {
			axios.get(`${fetchFilesURL}/${certFiles[i]}`, config).then((res) => {
				const blob = new Blob([res.data], {
					type: res.headers["content-type"],
				});
				const objectUrl = window.URL.createObjectURL(blob);
				const anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`${certFiles[i]}.${res.data.type.split("/")[1]}`
				);
				anchor.click();
			});
		}
	}

	async function handleChangeStatus({ action, payment_method,currency }) {
		const data = {
			request_id: request?.id,
			action,
			currency,
			...(payment_method && { payment_method }),
		};
		const changeStatusRes = await flightChangePendingApproval(data);
		
		if (changeStatusRes?.status === 200) {
			fetchRequest();
			store.addNotification({
				title: interests.messages.updateSuccessfully,
				message: interests.messages.updateSuccessfully,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setConfirmModalOpen(false);
			payment_method&&togglePaymentModal()
		}else if (changeStatusRes?.status === 202){
			setActionType(changeStatusRes?.data?.actionType)
			setFormData(changeStatusRes?.data?.data?.formData)

		}
		
	}

	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK
	? JSON.parse(process.env.REACT_APP_OTP_CHECK)
	: false;
	const newPayload = request?.new_payload;
	const originalPayload = request?.original_payload;
	const passengerPayload = newPayload ?? originalPayload;

	const getOldValue = (passengerIndex, ticket, key, time) => {
		const originalTicket = originalPayload?.[passengerIndex]?.tickets.find(
			(i) => i.id === ticket.id
		);

		let changedValue = null;
		let newPayloadValue = ticket?.[key];
		let originalPayloadValue = originalTicket?.[key];

		///// check for Date and Time /////
		if (
			key.includes("date") &&
			time &&
			originalPayloadValue &&
			newPayloadValue
		) {
			newPayloadValue = moment(newPayloadValue).format("HH:mm");
			originalPayloadValue = moment(originalPayloadValue).format("HH:mm");
		} else if (
			key.includes("date") &&
			originalPayloadValue &&
			newPayloadValue
		) {
			newPayloadValue = moment(newPayloadValue).format("DD/MM/YYYY");
			originalPayloadValue = moment(originalPayloadValue).format("DD/MM/YYYY");
		}

		///// check for Date and Time /////

		if (
			typeof newPayloadValue == "string" &&
			typeof originalPayloadValue == "string"
		) {
			newPayloadValue = newPayloadValue.toLowerCase();
			originalPayloadValue = originalPayloadValue.toLowerCase();
		}

		if (newPayload && newPayloadValue !== originalPayloadValue) {
			changedValue = originalPayloadValue;
		}
		return changedValue;
	};

	return (
		<div className="my-3">
			<RequestItemHeader
				isOpen={isOpen}
				toggleCollapse={toggleCollapse}
				requestTypeText={inventory.messages.changeRequest}
				status={status}
				details={request}
			/>

			<Collapse isOpen={isOpen}>
				<div className="flight-requests-details">
					<div className="row m-0">
						<div className="col-md-6 col-12">
						<div className="flight-requests-refund-passenger mb-1">
								{inventory.messages.changedJourneys}
							</div>

							{request?.journeys?.map((item) => (
								<div
									key={item?.id}
									className="flight-requests-change-passenger pt-3 row justify-content-start "
								>
									<div className="col-md-3 col-6 pt-2">
										<p className="mx-1 mb-0">Route</p>
										<p className="mx-1 mb-0 font-weight-bold">
											{`${item?.journey_info?.departure} - ${item?.journey_info?.arrival}`}
										</p>
									</div>
									<div className="col-md-3 col-6 pt-2">
										<p className="mx-1 mb-0">Date</p>
										<p className="mx-1 mb-0 font-weight-bold">
											{moment(item?.date).format("YYYY/MM/DD")}
										</p>
									</div>
									<div className="col-md-3 col-6 pt-2">
										<p className="mx-1 mb-0">Time</p>
										<p className="mx-1 mb-0 font-weight-bold">
											{moment(item?.date).format("HH:mm")}
										</p>
									</div>
									<div className="col-md-3 col-6 pt-2">
										<p className="mx-1 mb-0">Class</p>
										<p className="mx-1 mb-0 font-weight-bold">
											{item?.cabin_class}
										</p>
									</div>
								</div>
							))}

							{/* passengers data */}
							<div className="d-flex flex-column mt-3">
								<h2 className="text-capitalize blue">
									{inventory.messages.passengers}
								</h2>
								{passengerPayload?.map((passenger,index) => {
									return (
										<>
											<div className="flight-requests-refund-passenger p-0 mb-1">
												<div className="ps-2 py-2">
													<i className="far fa-user-circle"></i>
													<span className="mx-1">
														{`${passenger?.firstName} / ${passenger.lastName}`}
													</span>
												</div>
												<span className="col-md-3 col-6">{passenger?.psgType}</span>

												<button
													className="btn-toggel btn"
													onClick={() => togglePassengerCollapse(index)}
												>
													{passengerCollapse[index] ? (
														<i className="fas fa-chevron-up"></i>
													) : (
														<i className="fas fa-chevron-down"></i>
													)}
												</button>
											</div>
											<Collapse isOpen={Boolean(passengerCollapse[index])}>
												{passenger.tickets?.map((item) => (
													<>
														<div className="d-flex justify-content-between">
															<div className="segment-header">
																<span className="font-weight-bold">
																	{" "}
																	{`${item?.departure?.code} - ${item?.arrival?.code} | ${item?.airline?.name} |`}
																</span>{" "}
																<span>Flight No.:</span>
																<span className="font-weight-bold">
																	{item.flight_num}
																</span>
															</div>
															
														</div>
														<div className="flight-requests-change-passenger pt-3 row justify-content-start ">
															<div className="col-md-3 col-6 pt-2">
																<p className="mx-1 mb-0">Departure Terminal</p>
																<p className="mx-1 mb-0 font-weight-bold">
																	{item?.departure_terminal}
																	{getOldValue(
																		index,
																		item,
																		"departure_terminal"
																	) && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"departure_terminal"
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-md-3 col-6 pt-2">
																<p className="mx-1 mb-0">Arrival Terminal </p>
																<p className="mx-1 mb-0 font-weight-bold">
																	{item?.arrival_terminal}
																	{getOldValue(
																		index,
																		item,
																		"arrival_terminal"
																	) && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"arrival_terminal"
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-md-3 col-6 pt-2">
																<p className="mx-1 mb-0">Ticket No.</p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{item?.ticket_number}
																	{getOldValue(
																		index,
																		item,
																		"ticket_number"
																	) && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"ticket_number"
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-md-3 col-6 pt-2">
																<p className="mx-1 mb-0">PNR </p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{item?.pnr}
																	{getOldValue(index, item, "pnr") && (
																		<span className="old-value">
																			<span>
																				{getOldValue(index, item, "pnr")}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>

															<div className="col-md-3 col-6 pt-2">
																<p className="mx-1 mb-0">Segment ID </p>
																<p className="mx-1 mb-0 font-weight-bold text-break">
																	{item?.segment_id}
																	{getOldValue(index, item, "segment_id") && (
																		<span className="old-value">
																			<span>
																				{getOldValue(index, item, "segment_id")}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-md-3 col-6 pt-2">
																<p className="mx-1 mb-0">Class </p>
																<p className="mx-1 mb-0 font-weight-bold">
																	{item?.cabin_class}
																	{getOldValue(index, item, "cabin_class") && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"cabin_class"
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-md-3 col-6 pt-2">
																<p className="mx-1 mb-0">Equipment </p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{item?.equipment}
																	{getOldValue(index, item, "equipment") && (
																		<span className="old-value">
																			<span>
																				{getOldValue(index, item, "equipment")}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-md-3 col-6 pt-2">
																<p className="mx-1 mb-0">Departure Date</p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{moment(item?.departure_date).format(
																		"DD/MM/YYYY"
																	)}
																	{getOldValue(
																		index,
																		item,
																		"departure_date"
																	) && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"departure_date"
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-md-3 col-6 pt-2">
																<p className="mx-1 mb-0">Departure Time</p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{moment(item?.departure_date).format(
																		"hh:mm a"
																	)}
																	{getOldValue(
																		index,
																		item,
																		"departure_date",
																		true
																	) && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"departure_date",
																					true
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-md-3 col-6 pt-2">
																<p className="mx-1 mb-0">Arrival Date</p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{moment(item?.arrival_date).format(
																		"DD/MM/YYYY"
																	)}
																	{getOldValue(index, item, "arrival_date") && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"arrival_date"
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-md-3 col-6 pt-2">
																<p className="mx-1 mb-0">Arrival Time</p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{moment(item?.arrival_date).format("hh:mm a")}
																	{getOldValue(
																		index,
																		item,
																		"arrival_date",
																		true
																	) && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"arrival_date",
																					true
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-md-3 col-6 pt-2">
																<p className="mx-1 mb-0">Flight Time </p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{`${item?.flight_time}M`}
																	{getOldValue(index, item, "flight_time") && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"flight_time"
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
														</div>
													
													</>
												))}
											</Collapse>
										</>
									);
								})}
									
							</div>
							{/* change reason */}
							<div className="mt-3">
								<h3 className="my-2 text-capitalize blue">
									{inventory.messages.changeReason}
								</h3>
								<div className="d-flex align-items-center justify-content-between">
									<span className="flight-requests-box p-1">
										{request?.reason}
									</span>
									{request?.certification_files &&
										request?.certification_files?.length > 0 && (
											<button
												className="download-cert btn"
												onClick={downloadCertFiles}
											>
												<i class="fas fa-download"></i>
												<span className="mx-1">
													{inventory.messages.downloadCertificationFile}
												</span>
											</button>
										)}
								</div>
								{request?.remarks && (
									<p className="flight-requests-box p-1 mt-2 lh-1">
										{request?.remarks}
									</p>
								)}
							</div>
						</div>

						<div className="col-md-6 col-12">
							{/* contact info */}
							<RequestContactInfo details={request} />

							{/* actions and status */}
							{status === "rejected" && request?.reject_reason && (
								<div className="flight-requests-danger-box py-1 px-2 mt-3">
									<h3 className="text-capitalize my-2">
										{inventory.messages.changeRejectReason}
									</h3>
									<p>{request?.reject_reason}</p>
								</div>
							)}

							{status === "pending_approval" && (
								<div className="flight-requests-refund-amount p-2 mt-3">
									<h3 className="text-capitalize mb-2">
										{inventory.messages.changeFees}
									</h3>

									<p className="d-flex align-items-center justify-content-between font-weight-bold px-3 py-2 bg-white">
										<span>{inventory.messages.feثs}</span>
										<span className="text-danger">
											{request?.amount} {request?.currency}
										</span>
									</p>

									<div className="flight-requests-refund-actions mt-2">
										<button
											className="btn reject-btn mx-3"
											onClick={() => handleChangeStatus({ action: "reject" })}
										>
											{inventory.messages.reject}
										</button>
										<button
											className="btn accept-btn"
											onClick={toggleConfirmModal}
										>
											{inventory.messages.confirm}
										</button>
									</div>
								</div>
							)}

							{status === "waiting_approval" && (
								<div className="flight-requests-refund-amount p-2 mt-3">
									<h3 className="text-capitalize mb-2">
										{inventory.messages.refundAmount}
									</h3>
									<p className="d-flex align-items-center justify-content-between font-weight-bold px-3 py-2 bg-white">
										<span>{inventory.messages.Amount}</span>
										<span className="text-danger">
											{request?.amount} {request?.currency}
										</span>
									</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</Collapse>

			{/* confirm request modal */}
			{toggleConfirmModal && (
				<Modal isOpen={confirmModalOpen} centered={true}>
					<ModalHeader toggle={toggleConfirmModal}>
						<span>{inventory.messages.confirmRequest}</span>
					</ModalHeader>
					<ModalBody>
						<div className="p-3 text-center">
							<i
								className="fas fa-question-circle"
								style={{ fontSize: "3.6rem", color: "#F0E5D3" }}
							></i>
							<p className="py-4 h5 font-weight-bold">
								{inventory.messages.youSureConfirmRequest}
							</p>
							<button
								className="flight-checkout-btn w-100 font-weight-bold border-0 rounded"
								onClick={() => {
									togglePaymentModal();
									toggleConfirmModal();
								}}
							>
								{inventory.messages.confirm}
							</button>
						</div>
					</ModalBody>
				</Modal>
			)}

			{paymentModal && (
	

				<NewPaymentAction
				isOpen={paymentModal}
				toggleModal={togglePaymentModal}
				canPayLater={false}
				formData={formData}
				actionType={actionType}
				Price={request?.amount}
				currency={request?.currency}
				onPayment={(e,currency)=>{handleChangeStatus({action: "accept",payment_method:e,currency:currency})}}
				shouldPayCurrency={request?.currency}
				setActionType={setActionType}
				setFormData={setFormData}
				OTP={OTP_CHECK}
			/>
			)}
		</div>
	);
}

export function RequestContactInfo({ details }) {
	const { inventory, payment } = Locale;
	return (
		<>
			<h2 className="text-capitalize blue">{inventory.messages.contact}</h2>
			<div className="flight-requests-contact">
				<p className="d-flex flex-wrap justify-content-between align-content-center my-1">
					<span className="font-weight-bold">
						{inventory.messages.contactName}
					</span>
					<span>{details?.contact_info?.full_name}</span>
				</p>
				<p className="d-flex flex-wrap justify-content-between align-content-center my-1">
					<span className="font-weight-bold">{payment.messages.Email}</span>
					<span>{details?.contact_info?.email}</span>
				</p>
				<p className="d-flex flex-wrap justify-content-between align-content-center my-1">
					<span className="font-weight-bold">
						{inventory.messages.cellphone}
					</span>
					<span>
						{details?.contact_info?.phone_code}
						{details?.contact_info?.phone}{" "}
					</span>
				</p>
			</div>
		</>
	);
}
