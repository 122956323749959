import TextField from "components/Form/TextField/TextField";
import GusetDetails from "./gusetDetails";
import HotelInfo from "./hotelInfo";
import ImportantInfoItem from "./importantInfoItem";
import { useEffect, useState } from "react";
import SummaryInfo from "./summaryInfo";
import Locale from "translations";
import { useMarketplaceState } from "context/marketplace";
import { bookHotel, checkHotelAvailability } from "services/marketplace";
import { store } from "react-notifications-component";
import { isFormValid } from "helpers/validate";
import NewPaymentAction from "components/NewPaymentAction";
import useShowPermission from "hooks/useShowPermission";
import { useSBSDispatch, useSBSState } from "context/global";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ConfirmModel from 'modules/market-place/HotelResponse/Summary/Umrah-holidays-hotel/ConfirmModel';

export default function SummaryBooking() {
	const { productsBuilder, inventory } = Locale;
	const [roomIsOpen, setRoomIsOpen] = useState(0);
	const { HotelsSearch } = useMarketplaceState();
	const dispatchSBS = useSBSDispatch();
	const [hotelRequest, setHotelRequest] = useState(HotelsSearch);
	const { companyInfo} = useSBSState();



	const [modalConfirm, setModalConfirm] = useState(false);
	const toggleModalConfirm = () => setModalConfirm(!modalConfirm);

	const [doneCheck, setDoneCheck] = useState(false);
	const [errorsClients, setErrorsClients] = useState([]);
	const [checked, setChecked] = useState(false);
	const [startchecked, setStartChecked] = useState(false);
	const [checkAvailability, setCheckAvailability] = useState({})
	
	const history = useHistory();

	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK
		? JSON.parse(process.env.REACT_APP_OTP_CHECK)
		: false;
	const [actionType, setActionType] = useState("");
	const [formData, setFormData] = useState({});
	const [modalPay, setModalPay] = useState(false);
	const togglePayModal = () => setModalPay(!modalPay);
	const inventoryPermission = useShowPermission({
		permission: ["View-Inventory-Hotels", "Manage-Inventory-Hotels"],
	});

	console.log("hotelRequest", hotelRequest);
	const { locale, serverErrors } = useSBSState();

	const checkFormallErrors = () => {
			setChecked(!checked);
	
	};

	useEffect(() => {
		if (startchecked) {

		saveClients();
	}
	setStartChecked(true)
	}, [doneCheck]);



	useEffect(() => {
		localStorage.removeItem('HotelData')

		let HotelsSearchData={...HotelsSearch}
		let list =[]
		if (HotelsSearchData.room && HotelsSearchData.room.length > 0) {
			HotelsSearchData.room.map((room, index) =>{
			if (room.room_rates && room.room_rates.length > 0) {
				room.room_rates.map((rate, rateIndex) =>{
					[...Array(+rate.count).keys()].map(res=>{
						list.push(room)
					})
				})
			}
		})
		}
	
		setHotelRequest({...hotelRequest,room:list})
	}, [])
	

	const saveClients = async () => {
		
		const flag = errorsClients.every((errors) => {
			return errors.every((error) => {
				if (!isFormValid(error)) {
					return false;
				}
				return true;
			});
		});
		
		let data = {
			search_token: hotelRequest.id,
			date_from: hotelRequest.date_from,
			date_to: hotelRequest.date_to,
			nationality:companyInfo?.country?.country_code,
			residency:companyInfo?.country?.country_code,
			lang: locale,
			rooms_data: hotelRequest.room.map((roo) => {
				return roo.room_rates.map((rate) => {
					return {
						roomKey: roo.roomKey,
						number_Room:rate.count,
						roomConfigurationId: rate.roomConfigurationId,
						allocationDetails: rate.allocationDetails,
						beddingPreference: 0,
						number_of_adult: roo.paxes.adults,
						guests: rate.guests,
						children: [],
					};
				});
			}).flat(),

			notes: hotelRequest.note,
		};

		if (flag && hotelRequest && hotelRequest.room.length > 0) {
			const availability = await checkHotelAvailability(data);
			debugger
			if (availability?.status === 200) {
				// setresAvailability(availability?.data?.data);
				setCheckAvailability(availability?.data?.data)

				if (
					(!availability?.data?.data.requires_payment &&
						+availability?.data?.data.provider_id == 3) ||
					(!availability?.data?.data.requires_payment &&
						+availability?.data?.data.provider_id == 8)
				) {
					setModalConfirm(true);
				} else {
					setActionType("");
					setFormData({});
					setModalPay(true);
				}
			}
		}
	};

	useEffect(() => {
		if (serverErrors) {
			setErrorsClients(serverErrors);

			dispatchSBS({
				type: "serverErrors",
				payload: null,
			});
		}
		return () => {};
	}, [serverErrors]);

	const pay = async (radio, currency, otpNumber) => {
		
		const roomData = {
			special_request: "",
			hotel_availability_cache_id: checkAvailability.hotel_check_id,

			provider_id: hotelRequest.gds,
			// dummy room_guests

			payment_info: {
				payment_method:
					radio === "online"
						? "online"
						: radio === "pay_later"
						? "pay_later" : radio === "split" ? "split"
						: "debit",
				account_number: null,
				otp: null,
				pay_now: radio === "pay_later" ? 0 : 1,
				currency: currency,
			},
			otp: otpNumber,

			lang: locale,
		};
		const res = await bookHotel(roomData);

		if (res?.status === 200 || res?.status === 201) {
			store.addNotification({
				title: "info!",
				message: inventory.messages.bookingSuccessfully,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					history.push(inventoryPermission ? "/inventory" : "/statistics");
				},
			});
			window.dataLayer.push({ event: "buyNowtest" });

			togglePayModal(null);
		} else if (res?.status === 202) {
			setActionType(res?.data?.actionType);
			setFormData(res?.data?.data?.formData);
		}
	};

	return (
		<div className="allotment-summary-booking">
			<p className="p-hotel-info">{productsBuilder.BookingSummary}</p>

			<div className="row">
				<div className="col-md-8">
					<div className="hotel-info">
						<HotelInfo hotelRequest={hotelRequest} />
					</div>
					<div className="gust-details">
						<h6>{productsBuilder.GusetDetails}</h6>
						<div className="box">
							{hotelRequest.room && hotelRequest.room.length > 0
								? hotelRequest.room.map((room, index) =>
										room.room_rates && room.room_rates.length > 0
											? room.room_rates.map((rate, rateIndex) =>
													[...Array(+rate.minPassengersNum).keys()].map(
														(res, inde) => (
															<GusetDetails
																doneCheck={doneCheck}
																setErrorsClients={setErrorsClients}
																errorsClients={errorsClients}
																setDoneCheck={setDoneCheck}
																setHotelRequest={setHotelRequest}
																hotelRequest={hotelRequest}
																checkFormallErrors={checked}
																key={inde}
																room={room}
																rate={rate}
																RoomIndex={index}
																rateIndex={rateIndex}
																index={inde}
															/>
														)
													)
											  )
											: null
								  )
								: null}
						</div>
					</div>
					<div className="remark">
						<h6>{productsBuilder.BookingRemarks}</h6>
						<div className="input-item">
							<TextField
								value={hotelRequest.note}
								onChange={(e) => {
									setHotelRequest({ ...hotelRequest, note: e.target.value });
								}}
								placeholder={productsBuilder.BookingRemarks}
							/>
						</div>
					</div>

					<div className="important-info">
						<h6>{productsBuilder.ImportantInformations}</h6>
						{hotelRequest.room && hotelRequest.room.length > 0
							? hotelRequest.room.map((room, ind) =>
									room.room_rates && room.room_rates.length > 0
										? room.room_rates.map((item, index) => (
												<ImportantInfoItem
												room={room}
												rate={item}
												hotelRequest={hotelRequest}
													setRoomIsOpen={setRoomIsOpen}
													roomIsOpen={roomIsOpen}
													key={index}
													index={index+ind+"re"}
												/>
										  ))
										: null
							  )
							: null}
					</div>
				</div>
				<div className="col-md-4">
					<SummaryInfo
						hotelRequest={HotelsSearch}
						checkFormallErrors={checkFormallErrors}
					/>
				</div>
			</div>

			{modalPay ? (
				<NewPaymentAction
					setActionType={setActionType}
					setFormData={setFormData}
					onPayment={pay}
					formData={formData}
					actionType={actionType}
					toggleModal={togglePayModal}
					isOpen={modalPay}
					OTP={OTP_CHECK}
					canPayLater={checkAvailability?.is_auto_reservation}
					currency={checkAvailability?.providerCurrency}
					secondCurrency={checkAvailability?.currency}
					secondPrice={checkAvailability?.total_price}
					Price={checkAvailability?.providerPrice}
				/>
			) : null}


{modalConfirm ?<ConfirmModel
				modal={modalConfirm}
				toggleModal={toggleModalConfirm}
				availability={{hotel_check_id:hotelRequest.id,currency:hotelRequest.currency}}
			/>: null}


		</div>
	);
}
