import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { addToTransferCart } from "services/marketplace";
import Locale from "translations";
import CustomModal from "./Modal";

function AddToCart({ isOpen, toggleModal, selectedTransfer }) {
	const { inventory, marketPlace } = Locale;

	const [cartData, setCartData] = useState({
		departure_flight_code: null,
		departureMm: null,
		departureHh: null,
		arrival_flight_code: null,
		arrivalMm: null,
		arrivalHh: null,
	});
	const dispatch = useMarketplaceDispatch();
	const [errors, setErrors] = useState();
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const { transferCriteria } = useMarketplaceState();

	const toTypeTerminal =
		transferCriteria?.to_type === "terminal" || transferCriteria?.returnDate;

	const flightCodeHeader = toTypeTerminal
		? marketPlace.departureTransfer
		: marketPlace.arrivalTransfer;
	const terminalTypeCode = toTypeTerminal
		? "departure_flight_code"
		: "arrival_flight_code";
		const history = useHistory();

	// const terminalTypeHour = toTypeTerminal ? "departureHh" : "arrivalHh";
	// const terminalTypeMinute = toTypeTerminal ? "departureMm" : "arrivalMm";

	// const hourLookup = Array(24)
	// 	.fill(0)
	// 	.map((_, index) => {
	// 		return {
	// 			id: index.toString().padStart(2, 0),
	// 			name: index.toString().padStart(2, 0),
	// 			label: index.toString().padStart(2, 0),
	// 		};
	// 	});
	// const minuteLookup = Array(60)
	// 	.fill(0)
	// 	.map((_, index) => {
	// 		return {
	// 			id: index.toString().padStart(2, 0),
	// 			name: index.toString().padStart(2, 0),
	// 			label: index.toString().padStart(2, 0),
	// 		};
	// 	});

	const checkFormErrors = () => {
		let SubmitError = {};
		let validationKeys = transferCriteria?.returnDate
			? [
					"departure_flight_code",
					// "departureHh",
					// "departureMm",
					"arrival_flight_code",
					// "arrivalHh",
					// "arrivalMm",
			  ]
			: [];

		if (
			transferCriteria?.from_type === "terminal" &&
			!transferCriteria?.returnDate
		) {
			// validationKeys = ["arrival_flight_code", "arrivalHh", "arrivalMm"];
			validationKeys = ["arrival_flight_code"];
		}
		if (
			transferCriteria?.to_type === "terminal" &&
			!transferCriteria?.returnDate
		) {
			// validationKeys = ["departure_flight_code", "departureHh", "departureMm"];
			validationKeys = ["departure_flight_code"];
		}

		validationKeys.forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{ name: key, value: cartData[key] },
					{ required: cartData[key] === "00" ? false : true }
				),
			};
		});
		setErrors(() => SubmitError);
	};

	const submit = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	useEffect(() => {
		if (isFormValid(errors)) {
			const handleAddToCart = async () => {
				let data = {};
				if (
					(transferCriteria?.from_type === "terminal" &&
						!transferCriteria?.returnDate) ||
					transferCriteria?.returnDate
				) {
					data = {
						arrival_flight_code: cartData.arrival_flight_code,

						// arrival_time: `${String(cartData.arrivalHh)?.padStart(
						// 	2,
						// 	0
						// )}:${String(cartData.arrivalMm)?.padStart(2, 0)}`,
					};
				}
				if (
					(transferCriteria?.to_type === "terminal" &&
						!transferCriteria?.returnDate) ||
					transferCriteria?.returnDate
				) {
					data = {
						...data,
						departure_flight_code: cartData.departure_flight_code,
						// departure_time: `${String(cartData.departureHh).padStart(
						// 	2,
						// 	0
						// )}:${String(cartData.departureMm).padStart(2, 0)}`,
					};
				}

				data = {
					...data,
					rateKey: selectedTransfer.rateKey,
				};
				const res = await addToTransferCart(data);

				if (res.status === 200) {
					dispatch({
						type: "transferCart",
						payload: res.data,
					});
				}
				if (
					res.status === 400 &&
					res.data.custom_error_code === "TRANSFER-CRT-001"
				) {
					history.push("/market-place-new");
					dispatch({
						type: "transferCart",
						payload: [],
					});
				}
				toggleModal();
			};
			handleAddToCart();
		} else {
		}
	}, [isErrorLoaded]);

	return (
		<CustomModal
			modalIsOpen={isOpen}
			header={" "}
			toggle={toggleModal}
			size="lg"
			centered={true}
		>
			<div className="add-to-cart">
				<div
					className="rounded p-3 mb-3"
					style={{ border: "1px solid #D29D4D" }}
				>
					<h6 className="h6 text-pending">{inventory.messages.notes}</h6>
					<p className="mb-2">
						1. {marketPlace.firstTransferBookingNotes}
					</p>
					<p>
						2. {marketPlace.secondTransferBookingNotes}
					</p>
				</div>

				<h6 className="h6 font-weight-bold">{flightCodeHeader}</h6>
				<div className="row m-0 mb-3">
					<div className="col-md-4 col-6">
						<label>{marketPlace.flightCode}</label>
						<TextField
							type="text"
							hasLabel={false}
							// placeholder={marketPlace.flightCode.search}
							label={marketPlace.flightCode}
							value={cartData[terminalTypeCode]}
							onChange={(e) => {
								setCartData((prev) => ({
									...prev,
									[terminalTypeCode]: e.target.value,
								}));
								setErrors({
									...errors,
									...validate(
										{ name: terminalTypeCode, value: e.target.value },
										{ required: true }
									),
								});
							}}
							errors={errors?.[terminalTypeCode]}
							color={errors?.[terminalTypeCode]?.required ? "danger" : ""}
						/>
					</div>

				</div>

				{transferCriteria?.returnDate && (
					<>
						<h6 className="h6 font-weight-bold">
							{marketPlace.returnTransfer}
						</h6>

						<div className="row m-0">
							<div className="col-md-4 col-6">
								<label>{marketPlace.flightCode}</label>
								<TextField
									type="text"
									hasLabel={false}
									// placeholder={marketPlace.flightCode.search}
									label={marketPlace.flightCode}
									value={cartData.arrival_flight_code}
									onChange={(e) => {
										setCartData((prev) => ({
											...prev,
											arrival_flight_code: e.target.value,
										}));
										setErrors({
											...errors,
											...validate(
												{ name: "arrival_flight_code", value: e.target.value },
												{ required: true }
											),
										});
									}}
									errors={errors?.arrival_flight_code}
									color={errors?.arrival_flight_code?.required ? "danger" : ""}
								/>
							</div>
						</div>
					</>
				)}

				<div className="w-100 mt-3 d-flex justify-content-end">
					<button
						className="btn px-3 bg-nxt px-0 font-weight-bold"
						type="button"
						onClick={submit}
					>
						<p className="d-flex align-items-center justify-content-center">
							{marketPlace.addToCart}
						</p>
					</button>
				</div>
			</div>
		</CustomModal>
	);
}

export default AddToCart;
