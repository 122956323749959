import React from 'react';
import { ReactComponent as PaymentCardIcon } from "assets/images/Tds/payment-card.svg";
import Locale from 'translations';


export default function TDSVisaCheckout({ visaData, togglePayModal }) {
  const { tdsVisa, marketPlace, inventory } = Locale;
  return (
    <div className='base-border p-3 d-flex flex-column align-items-center text-center'>
      <PaymentCardIcon />
      <div>
        <h6 className='font-weight-bold my-2'>{marketPlace.messages.totalPrice}</h6>
        <span className='visa-total-price'>{visaData?.totalPrice} {visaData?.residence?.provider_currency} {" "}</span>
        <span>
          {`${tdsVisa.amountRequiredPaid} (${visaData?.pax}) ${tdsVisa.passengersInThe} (${tdsVisa.package} ${visaData?.package_plan?.range_from} - ${visaData?.package_plan?.range_to}) `}
        </span>
      </div>
      <button className='btn verify-btn shadow-none' onClick={togglePayModal}>
        {inventory.messages.payNow}
      </button>
    </div>
  )
}
