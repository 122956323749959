import React from 'react'

export default function ViewIcon({size}) {
  return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M15.5801 11.9999C15.5801 13.9799 13.9801 15.5799 12.0001 15.5799C10.0201 15.5799 8.4201 13.9799 8.4201 11.9999C8.4201 10.0199 10.0201 8.41992 12.0001 8.41992C13.9801 8.41992 15.5801 10.0199 15.5801 11.9999Z"
				stroke="#D4A655"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12.0001 20.2707C15.5301 20.2707 18.8201 18.1907 21.1101 14.5907C22.0101 13.1807 22.0101 10.8107 21.1101 9.4007C18.8201 5.8007 15.5301 3.7207 12.0001 3.7207C8.47015 3.7207 5.18015 5.8007 2.89015 9.4007C1.99015 10.8107 1.99015 13.1807 2.89015 14.5907C5.18015 18.1907 8.47015 20.2707 12.0001 20.2707Z"
				stroke="#D4A655"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
