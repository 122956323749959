import React, { useEffect, useState } from 'react'
import SelectField from 'components/Form/SelectField/SelectField';
import { ReactComponent as ProfileIcon } from "assets/images/webBuilder/profile.svg";



export default function WebBuilderPackagePricing({ packageData, packageContainHotel, selectedRoomType, setSelectedRoomType, calcTotalPrices }) {
  const [roomsTypes, setRoomsTypes] = useState([]);

  const roomTypesLookup = {
    "1": "Single",
    "2": "Double",
    "3": "Triple",
    "4": "Quadruple",
    "5": "Quintuple",
    "6": "Hexagonal",
    "7": "heptathlon",
    "8": "suite",
    "9": "flat"
  }

  // format room availablities
  function createRoomsTypes() {
    let roomAvailabilitiesFormat = packageData?.roomsAvailabilities?.filter((room) => room?.room_remaining_availability > 0)
      .map((room) => {
        return {
          id: room?.id,
          room_type: room?.room_type,
          name: roomTypesLookup?.[room?.room_type],
          value: room?.room_type,
          label: roomTypesLookup?.[room?.room_type],
        };
      });
    setRoomsTypes(roomAvailabilitiesFormat);
  };


  function handleSelectRoomType(roomType) {
    setSelectedRoomType(roomType);
  }

  useEffect(() => {
    if (!packageData?.roomAvailabilities) {
      createRoomsTypes();
    }
  }, [packageData]);


  return (
    <div className='package-pricing'>

      <div className='hotels-pricing'>
        <div className="header">
          <span className="header-item col-md-4">
            <ProfileIcon />
            Adult
          </span>
          <span className="header-item col-md-4">
            <ProfileIcon />
            Child
          </span>
        </div>

        <div className="content">
          <div className='d-flex align-items-center justify-content-between'>
            {/* hotels name */}
            <div className='hotel-names px-0 col-md-4'>
              {packageContainHotel ?
                <>
                  <span>Package Room Type</span>
                  <div className="w-100">
                    <SelectField
                      hasLabel={false}
                      value={selectedRoomType?.label}
                      options={roomsTypes}
                      onChange={(e) => handleSelectRoomType(e)}
                    />
                  </div>
                </>
                :
                <span>Total Price</span>
              }
            </div>

            <span className="d-flex justify-content-center font-weight-bold col-4">
              {calcTotalPrices.adultTotalPrice} {packageData?.product_price?.currency_code}
            </span>
            <span className="d-flex justify-content-center font-weight-bold col-4">
              {calcTotalPrices.childTotalPrice} {packageData?.product_price?.currency_code}
            </span>
          </div>


        </div>
      </div>

    </div>
  )
}
