import React, { useState } from 'react'
import PreAuthLayoutV2 from './Components/Layout'
import TextField from 'components/Form/TextField/TextField';
import validate, { isFormValid } from 'helpers/validate';
import Locale from 'translations';
import PasswordField from 'components/Form/PasswordField/PasswordField';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Spinner } from 'reactstrap';
import { userLogin } from 'services/auth';
import { useSBSDispatch } from 'context/global';

export default function LoginV2() {
  // ** hooks
  const { login } = Locale;
  const dispatch = useSBSDispatch();
  const search = window.location.search;
  const isMobile = window.innerWidth < 426 ? true : false;
  let location = useHistory();

  // ** states
  const [loginData, setloginData] = useState({
    email: "",
    password: ""
  });
  const [errors, setErrors] = useState({});
  const [spin, setSpin] = useState(false);

  // ** functions
  function checkFormErrors() {
    let submitError = {};
    Object.keys(loginData).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: loginData[key] },
          {
            required: true,
            email: key === "email",
            password: key === "password",
            min: key === "password" ? 8 : key === "name" || key === "companyName" ? 3 : "",
          }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }


  async function submit(e) {
    e.preventDefault();
    let formErrors = checkFormErrors();
    setSpin(true);
    if (!isFormValid(formErrors)) {
      setSpin(false);
      return
    }
    let requestBody = {
      email: loginData.email,
      password: loginData.password,
    };
    const response = await userLogin(requestBody);
    if (response?.status === 200) {
      // Store Response In Redux
      setSpin(true);

      dispatch({
        type: "login",
        isAuth: true,
        token: response.data.data.token,
        userInfo: { ...response.data.data.user, is_connected_to_safa_visa: response.data.data.company?.is_connected_to_safa_visa, is_first_login: isMobile ? 0 : response?.data?.data?.user?.is_first_login },
        companyInfo: response.data.data.company,
        share_b2b: response.data.data.company?.share_b2b,
      });
      if (search.includes("guest")) {
        window.location.href = "/Umrah/confirm-hotel"
        // location.push("/Umrah/confirm-hotel");
      }
      else if (response.data.data.company?.company_category === "services_company") {
        location.push("/operations/movements/delegations-management");
      }
      else if (response.data.data.company?.company_category === "umrah_company" && response?.data?.data?.has_bravo) {
        location.push("/operations/operation-statement");
      }
      else {
        location.push("/dashboard")
      }
    } else if (response.status === 400 && response.data.err_code === "AUTH-012") {
      dispatch({
        type: "updateUserInfoOtp",
        userInfoOtp: { ...response.data.data },
      });
      location.push("/auth/otp");
    } else {
      setSpin(false);
    }
  }


  return (
    <PreAuthLayoutV2>
      <div className="form-header">
        <h2>{login.welcomeBack} </h2>
        <h3>{login.loginMessage}</h3>
      </div>
      <form onSubmit={submit} className="mt-4">
        {/* email address */}
        <div className="col-12 mt-3">
          <TextField
            type="email"
            label={login.emailAddress}
            placeholder={login.emailPlaceholder}
            name="email"
            id="safa_login_email"
            value={loginData.email}
            onChange={(e) => {
              setloginData({
                ...loginData,
                email: e.target.value
              })
              setErrors({
                ...errors,
                ...validate(
                  { name: "email", value: e.target.value },
                  { required: true, email: true }
                ),
              });
            }}
            color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
            errors={errors?.email}
          />
        </div>

        {/* password */}
        <div className="col-12 mt-3">
          <PasswordField
            hasLabel={true}
            label={login.password}
            placeholder={login.passwordPlaceholder}
            name="password"
            id="safa_register_password"
            min={8}
            value={loginData.password}
            onChange={(e) => {
              setloginData({
                ...loginData,
                password: e.target.value
              })
              setErrors({
                ...errors,
                ...validate(
                  { name: "password", value: e.target.value },
                  { required: true, password: true, min: 8 }
                ),
              });
            }}
            color={errors?.password?.required || errors?.password?.password || errors?.password?.min ? "danger" : ""}
            errors={errors?.password}
          />
        </div>

        <div className="col-12 d-flex justify-content-between align-items-center mt-3">
          <div className="d-flex align-items-center">
            <input
              type="Checkbox"
              name={"eheck1"}
              id={"exampleCheck1"}
            />

            <label
              className="form-check-label pointer text-caption mx-2 rememberme-label"
              htmlFor={"exampleCheck1"}
            >
              {login.rememberMe}
            </label>
          </div>
          <Link to="/auth/forgot_password" className="my-2 d-block text-right text-dark-blue">
            {login.forgotPassword}
          </Link>
        </div>

        <div className="col-12 d-flex flex-column justify-content-center gap-10 mt-3">
          <button type="submit" className="btn main-btn"
            disabled={spin}
          >
            <span className="btn-overlay"></span>
            <span className="text d-flex align-items-center gap-10 justify-content-center">
              {spin ? <Spinner color="light" size="sm" /> : null}
              {login.loginTitle}
            </span>
          </button>

          <Link to="/auth/login_safa" className="btn secondary-btn">
            <span className="btn-overlay"></span>
            <span className="text">{login.loginWihtSafaKey}</span>
          </Link>

          <span className="text-center">
            {login.newCompanySignup} | {" "}
            <Link to="/auth/register">
              {login.signup}
            </Link>
          </span>
        </div>

      </form>
    </PreAuthLayoutV2>
  )
}
