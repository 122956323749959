import ImagePlaceholder from "assets/images/image_placeholder.png";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import useFetchCountries from "hooks/useFetchCountries";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { listMehrem, uploadImageOnlineVisa } from "services/VisaOnline";
import Locale from "translations";
import CustomModal from "./components/Modal";
import { ReactComponent as UploadIcon } from "assets/images/visa/upload.svg";
import { ReactComponent as MRZIcon } from "assets/images/visa/mrz.svg";
import { uploadFile } from "services/auth";
import AddNewPassengerModal from "./components/AddNewPassenger";
import validate, { isFormValid } from "helpers/validate";

export default function PassangerProfile() {
	const { visa, reservation, productsBuilder, inventory, payment, onlineVisa } =
		Locale;
	const data = useLocation().state;
	const { locale } = useSBSState();
	const [changingImage, setchangingImage] = useState(false);
	const dispatch = useMarketplaceDispatch();
	let history = useHistory();
	// const [relationsLookups, setRelationsLookups] = useState([]);
	const { onlineVisaPassenger, redirectURL, onlineVisaSearch } =
		useMarketplaceState();
	const [modalOpen, setModalOpen] = useState(false);
	const [residenceyImage, setResidenceyImage] = useState();
	const [passportImage, setPassportImage] = useState();
	const [ModalNewMember, setModalNewMember] = useState(false);
	const [errors, setErrors] = useState({});
	const residenceRequired = onlineVisaSearch?.residence_data;

	const [member, setMember] = useState({
		first_name_ar: "",
		first_name_en: "",
		last_name_en: "",
		last_name_ar: "",
		national_id: "",
		passport_number: "",
		passport_photo: "",
		father_name: { ar: "", en: "" },
		grandfather_name: { ar: "", en: "" },
		gender: null,
		title: "",
		passport_type: { value: "1", label: locale === "en" ? "normal" : "normal" },
		marital_status: { value: "99", label: locale === "en" ? "other" : "other" },
		educational_level: {
			value: "99",
			label: locale === "en" ? "other" : "other",
		},
		country: data?.country ? data?.country : data?.issue_country,
		nationality: null,
		birth_country: null,
		birth_place: "",
		issue_country: null,
		issue_place: "",
		date_of_birth: "",
		issue_date: "",
		expire_date: null,
		job: "",
		address: "",
		photo: "",
		mrz: "",
	});

	const checkedIds = onlineVisaPassenger.map((item) =>
		item.id ? item.id : item.uniqueIdFrontend
	);

	//////// handle Errors ////////
	function checkFormErrors() {
		let errorsFields = [
			"first_name_en",
			"last_name_en",
			"nationality_id",
			"passport_number",
			"gender",
			"title",
			"country",
			"birth_country",
			"issue_country",
			"issue_place",
			"birth_place",
			"date_of_birth",
			"issue_date",
			"expire_date",
			"passport_photo",
			"photo",
			"passport_type",
		];
		if (residenceRequired) {
			errorsFields = [
				...errorsFields,
				"residencey_number",
				"residencey_issue_date",
				"residencey_expire_date",
				"residence_photo",
			];
		}
		let submitError = {};

		errorsFields.forEach((key) => {
			let value =
				key === "nationality_id" ? member["nationality"]?.value : member[key];
				if(key === "residence_photo") value = residenceyImage;
				if(key === "photo") value = member?.["photo"] || member?.["imgFile"];
				if(key === "passport_photo") value = member?.["passport_photo"] || passportImage;

			submitError = {
				...submitError,
				...validate(
					{ name: key, value },
					{
						required: true,
					}
				),
			};
		});
		setErrors(() => submitError);
	}

	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	function submit() {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	}


	const toggleModelNewMember = () => {
		setModalNewMember(!ModalNewMember);
	};

	const handleUploadPassportPhoto = (e) => {
		const files = e.target.files;
		setPassportImage(files[0]);
	};

	const handleUpload = (e) => {
		const files = e.target.files;
		setResidenceyImage(files[0]);
	};

	function toggleModal() {
		setModalOpen(!modalOpen);
	}

	const makeLookups = (arr) => {
		return arr.map((item, index) => {
			return {
				id: item.id,
				name: locale === "ar" ? item.Name_En : item.Name_En,
			};
		});
	};

	const [countries] = useFetchCountries();

	const genderLookups = [
		{
			id: 1,
			name: `${locale === "en" ? "Male" : "ذكر"}`,
			code: "male"
		},
		{ id: 2, name: `${locale === "en" ? "Female" : "أنثي"}`,code: "female" },
		
	];

	// useEffect(() => {
	// 	const fetchMehremRelations = async () => {
	// 		const respones = await listMehrem();
	// 		if (respones.status === 200 || respones.status === 201) {
	// 			setRelationsLookups(respones.data.data);
	// 		}
	// 	};
	// 	fetchMehremRelations();
	// }, []);

	// const mehermList = onlineVisaPassenger.map((passenger) => {
	// 	return {
	// 		id: passenger.passport_number,
	// 		name: locale === "ar" ? passenger.name_ar : passenger.name_en,
	// 	};
	// });

	useEffect(() => {
		if (countries && data) {
			let issue_country = countries.find(
				(country) => country.id === member.issue_country
			);
			let nationality = countries.find(
				(country) => country.id === member.nationality
			);
			let country = countries.find((country) => country.id === member.country);
			setMember({
				...member,
				issue_country: { value: issue_country?.id, label: issue_country?.name },
				nationality: { value: nationality?.id, label: nationality?.name },
				birth_country: { value: nationality?.id, label: nationality?.name },
				country: { value: country?.id, label: country?.name },
			});
		}
	}, [countries, data]);

	const PassType = makeLookups([
		{
			id: 1,
			Name_En: "Normal",
			Name_Ar: "عادي",
		},
		{
			id: 2,
			Name_En: "Diplomatic",
			Name_Ar: "دبلوماسي",
		},
		{
			id: 3,
			Name_En: "Travel Document",
			Name_Ar: "وثيقة سفر",
		},
		{
			id: 4,
			Name_En: "UN Passport",
			Name_Ar: "جواز الأمم المتحدة",
		},
		{
			id: 99,
			Name_En: "Other",
			Name_Ar: "اخرى",
		},
	]);

	const TitleList = makeLookups([
		{
			id: 1,
			Name_En: "Mr",
			Name_Ar: "السيد",
		},
		{
			id: 2,
			Name_En: "Miss",
			Name_Ar: "الأنسة",
		},
		{
			id: 3,
			Name_En: "Mrs",
			Name_Ar: "السيدة",
		},
		{
			id: 4,
			Name_En: "Dr",
			Name_Ar: "دكتور",
		},
		{
			id: 5,
			Name_En: "His Excellency",
			Name_Ar: "سعادة",
		},
		{
			id: 6,
			Name_En: "His Royal Highness",
			Name_Ar: "سمو",
		},
		{
			id: 99,
			Name_En: "Other",
			Name_Ar: "اخرى",
		},
	]);
	const MaritalStatus = makeLookups([
		{
			id: 1,
			Name_En: "Single",
			Name_Ar: "أعزب",
		},
		{
			id: 2,
			Name_En: "Married",
			Name_Ar: "متزوج",
		},
		{
			id: 3,
			Name_En: "Divorced",
			Name_Ar: "مطلق",
		},
		{
			id: 4,
			Name_En: "Widowed",
			Name_Ar: "أرمل",
		},
		{
			id: 99,
			Name_En: "Other",
			Name_Ar: "اخرى",
		},
	]);
	const EduLevel = makeLookups([
		{
			id: 1,
			Name_Ar: "بدون تعليم",
			Name_En: "No Education",
		},
		{
			id: 2,
			Name_Ar: "تعليم ابتدائي",
			Name_En: "Primary School",
		},
		{
			id: 3,
			Name_Ar: "تعليم ثانوي",
			Name_En: "High School",
		},
		{
			id: 4,
			Name_Ar: "تعليم جامعي",
			Name_En: "Higher Education",
		},
		{
			id: 99,
			Name_Ar: "اخرى",
			Name_En: "Other",
		},
	]);



	useEffect(() => {
		if (data) {
			setMember({
				...member,
				first_name_en: data?.first_name,
				first_name_ar: "",
				father_name: { ar: "", en: data?.second_name },
				grandfather_name: { ar: "", en: data?.third_name },
				last_name_en: data?.last_name,
				last_name_ar: "",
				date_of_birth: data?.birth_date || null,
				expire_date: data?.ex_date || null,
				issue_date: data?.issue_date || null,
				issue_place: data?.issuplace,
				issue_country: data?.issue_country,
				nationality: data?.nat ? data?.nat : data?.issue_country,
				birth_country: data?.nat ? data?.nat : data?.issue_country,
				passport_number: data?.ppno,
				gender: {
					value: data?.gender,
					code: +data?.gender === 1 ? "male" : "female",
					label:
						+data?.gender === 1
							? `${locale === "en" ? "male" : "male"}`
							: +data?.gender === 2
							? `${locale === "en" ? "female" : "female"}`
							: "",
				},
				title: {
					value: +data?.gender === 1 ? "Mr" : +data?.gender === 2 ? "Mrs" : "",
					label:
						+data?.gender === 1
							? `${locale === "en" ? "Mr" : "Mr"}`
							: +data?.gender === 2
							? `${locale === "en" ? "Mrs" : "Mrs"}`
							: "",
				},
				photo: data?.face_image_url,
				passport_photo: data?.full_image_url,
				mrz: data?.mrz,
			});
		}
	}, [data]);


	const uploadResidenceyImage = async () => {
		const formData = new FormData();
		formData.append("file", residenceyImage);
		formData.append("name", residenceyImage?.name);
		formData.append("bucket", "visaResidencey");
		const response = await uploadFile(formData);
		if (response.data.data.uuid) {
			return response.data.data.uuid;
		}
	};


	const uploadPassportImage = async () => {
		let formData = new FormData();
		formData.append("photo", passportImage);
		formData.append("photo_type", "full_image")
		formData.append(
			"nationality_id",
			member?.nationality?.value ?? member.issue_country
		);
		formData.append("passport_number", member.passport_number);
		formData.append(
			"offline_company_id",
			member.offline_company_id ? member.offline_company_id : ""
		);
		const res = await uploadImageOnlineVisa(formData);
		if (res?.data?.data?.faceImageUrl) {
			return res?.data?.data?.faceImageUrl;
		}
	};


	const savePassanger = async () => {
		let photo = "";
		if (changingImage) {
			const res = await editImage();
			if (!res) {
				return;
			}
			photo = res;
		}
		let residenceyImageUUID;
		let passportImageUUID
		if (residenceyImage) {
			residenceyImageUUID = await uploadResidenceyImage();
		}
		if (passportImage) {
			passportImageUUID = await uploadPassportImage();
		}
		const uniqueId = Math.random().toString(36).substring(2, 9);
		dispatch({
			type: "onlineVisaMRZ",
			payload: {
				uniqueIdFrontend: uniqueId,
				full_name_ar: `${member?.first_name_ar} ${member?.father_name?.ar} ${member?.grandfather_name?.ar} ${member?.last_name_ar}`,
				full_name_en: `${member?.first_name_en} ${member?.father_name?.en} ${member?.grandfather_name?.en} ${member?.last_name_en}`,
				first_name_ar: member?.first_name_ar,
				name_ar: `${member?.first_name_ar} ${member?.father_name?.ar} ${member?.grandfather_name?.ar} ${member?.last_name_ar}`,
				name_en: `${member?.first_name_en} ${member?.father_name?.en} ${member?.grandfather_name?.en} ${member?.last_name_en}`,
				birth_date:
					typeof member.date_of_birth === "object"
						? moment(member.date_of_birth).format("YYYY-MM-DD")
						: member.date_of_birth,
				first_name_en: member?.first_name_en,
				father_name_ar: member?.father_name?.ar,
				father_name_en: member?.father_name?.en,
				grandfather_name_ar: member?.grandfather_name?.ar,
				grandfather_name_en: member?.grandfather_name?.en,
				last_name_ar: member?.last_name_ar,
				last_name_en: member?.last_name_en,
				national_id: member?.national_id,
				gender: member?.gender?.code,
				title: member?.title?.label,
				passport_type: member?.passport_type?.label,
				passport_number: member?.passport_number?.toString(),
				marital_status: member?.marital_status?.label,
				educational_level: member?.educational_level?.label,
				country: member?.country?.label,
				nationality: member?.nationality?.label,
				nationality_id: member?.nationality?.value ?? data.issue_country,
				birth_country: member?.birth_country?.label,
				birth_place: member?.birth_place,
				issue_country_label: member?.issue_country?.label,
				issue_country: member?.issue_country?.value,
				issue_place: member?.issue_place,
				date_of_birth:
					typeof member.date_of_birth === "object"
						? moment(member.date_of_birth).format("YYYY-MM-DD")
						: member.date_of_birth,
				issue_date: member?.issue_date ? moment(member?.issue_date).format("YYYY-MM-DD"):member?.issue_date,
				expire_date: member?.expire_date ? moment(member?.expire_date).format("YYYY-MM-DD"):member?.expire_date,
				job: member?.job,
				address: member?.address,
				relationship: member?.relationship?.label,
				// mehrem_relation_code: member?.relationship?.value?.toString(),
				// mehrem_passport_number: member?.mehrem_name?.value,
				// mehrem_name: member?.mehrem_name?.label,
				photo: changingImage ? photo : member?.photo,
				passport_photo: passportImageUUID || member?.passport_photo,
				mrz: member?.mrz,
				offline_company_id: member?.offline_company_id
					? member?.offline_company_id
					: "",
				...(residenceRequired && {
					residence_data: {
						residence_photo: residenceyImageUUID,
						residence_number: member?.residencey_number,
						issue_date: member?.residencey_issue_date ? moment(member?.residencey_issue_date).format("YYYY-MM-DD"):member?.residencey_issue_date,
						expiry_date: member?.residencey_expire_date ? moment(member?.residencey_expire_date).format("YYYY-MM-DD"):member?.residencey_expire_date,
					},
				}),
			},
		});
		history.push({ pathname: redirectURL, state: "editPassenger" });
	};

	useEffect(() => {
		if (isFormValid(errors)) {
			savePassanger();
		}
		return () => {};
	}, [isErrorLoaded]);

	const editImage = async () => {
		let formData = new FormData();
		formData.append("photo", member.imgFile);
		formData.append("photo_type", "face_image")
		formData.append(
			"nationality_id",
			member?.nationality?.value ?? member.issue_country
		);
		formData.append("passport_number", member.passport_number);
		formData.append(
			"offline_company_id",
			member.offline_company_id ? member.offline_company_id : ""
		);
		const res = await uploadImageOnlineVisa(formData);
		if (res?.data?.data?.faceImageUrl) {
			setMember({
				...member,
				photo: res?.data?.data?.faceImageUrl,
			});
			setchangingImage(false);
			return res?.data?.data?.faceImageUrl;
		}
	};


	const formatData = (data) => {
		let passdata = {
			first_name_en: data?.first_name,
			first_name_ar: "",
			father_name: { ar: "", en: data?.second_name },
			grandfather_name: { ar: "", en: data?.third_name },
			last_name_en: data?.last_name,
			last_name_ar: "",
			date_of_birth: data?.birth_date || null,
			expire_date: data?.ex_date || null,
			issue_date: data?.issue_date || null,
			issue_place: data?.issuplace,
			issue_country: data?.issue_country,
			country: data?.country ? data?.country : data?.issue_country ,
			nationality: data?.nat ? data?.nat : data?.issue_country ,
			birth_country: data?.nat ? data?.nat : data?.issue_country ,
			passport_number: data?.ppno,
			gender: {
				value: data?.gender,
				code: +data?.gender === 1 ? "male" : "female",
				label:
					+data?.gender === 1
						? `${locale === "en" ? "Male" : "Male"}`
						: +data?.gender === 2
						? `${locale === "en" ? "Female" : "Female"}`
						: "",
			},
			title: {
				value: +data?.gender === 1 ? "Mr" : +data?.gender === 2 ? "Mrs" : "",
				label:
					+data?.gender === 1
						? `${locale === "en" ? "Mr" : "Mr"}`
						: +data?.gender === 2
						? `${locale === "en" ? "Mrs" : "Mrs"}`
						: "",
			},
			photo: data?.face_image_url,
			passport_photo: data?.full_image_url,
			mrz: data?.mrz,
		};
		let issue_country = countries.find(
			(country) => country.id === passdata.issue_country
		);
		let nationality = countries.find(
			(country) => country.id === passdata.nationality
		);
		let country = countries.find((country) => country.id === passdata.country);
		setMember((prev) => ({
			...prev,
			...passdata,
			issue_country: issue_country?.id? { value: issue_country?.id, label: issue_country?.name }:null,
			nationality: nationality?.id? { value: nationality?.id, label: nationality?.name }:null,
			birth_country: nationality?.id? { value: nationality?.id, label: nationality?.name }:null,
			country: country?.id? { value: country?.id, label: country?.name }:null,
		}));
	};

	const setPassengerData = (data) => {
		setPassportImage()
		formatData(data);
		toggleModelNewMember();
	};
	return (
		<div className="list-container">
			<div className="row">
				{/* images */}
				<div className="member-photo m-0 col-md-2 col-12 d-flex flex-column align-items-center text-center online-vise-img mt-4">
				
				<div className={` ${
          (errors?.photo?.required )
              && !member.photo && !member.imgPrev
            ? `border-danger`
            : ""
        }`}>	<img
						className="img-fluid rounded w-md-75"
						src={
							member.imgPrev
								? member.imgPrev
								: member.photo
								? `${member.photo}`
								: ImagePlaceholder
						}
						alt=""
					/>
					</div>

					{changingImage ? (
						<>
							{/* <button className="btn btn-link text-center w-75 file-btn mt-2" onClick={editImage}>
								{productsBuilder.save}
							</button> */}
							<button className="btn btn-link text-center w-100 file-btn mt-2">
								<i class="fas fa-edit mx-2"></i>
								{/* {visa.ChangePhoto} */}
								{onlineVisa.uploadImage}
								<input
									type="file"
									className="custom-file"
									accept="image/png, image/gif, image/jpeg"
									onChange={(e) => {
										setMember({
											...member,
											imgPrev: URL.createObjectURL(e.target.files[0]),
											imgFile: e.target.files[0],
										});
										setErrors({
											...errors,
											...validate(
												{
													name: "photo",
													value: e.target.files[0].name
												},
												{ required: true }
											),
										});
										setchangingImage(true);
									}}
								/>
							</button>
						</>
					) : (
						<button className="btn btn-link text-center w-100 file-btn mt-2">
							<i class="fas fa-edit mx-2"></i>
							{/* {visa.ChangePhoto} */}
							{onlineVisa.uploadImage}
							<input
								type="file"
								className="custom-file"
								accept="image/png, image/gif, image/jpeg"
								onChange={(e) => {
									setMember({
										...member,
										imgPrev: URL.createObjectURL(e.target.files[0]),
										imgFile: e.target.files[0],
									});
									setchangingImage(true);
								}}
							/>
						</button>
					)}

					<p className="h6">{visa.profilePictureHint}</p>

					{/* passport photo */}
					<div className={`mt-4 pointer text-center ${
																errors?.passport_photo?.required 
                                    && !passportImage
																	? `border-danger`
																	: ""
															}`} onClick={toggleModal}>
						<img
							className="img-fluid rounded w-75"
							src={
							passportImage? URL.createObjectURL(passportImage)	:member.passport_photo ? member.passport_photo : ImagePlaceholder
							}
							alt="passport"
						/>
					</div>
					<button className="btn btn-link text-center w-75 file-btn mt-2">
              <i class="fas fa-edit mx-2"></i>
              {visa.ChangePassportPhoto}
              <input
                type="file"
                className="custom-file"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => {
									handleUploadPassportPhoto(e)
                  setErrors({
                    ...errors,
                    ...validate(
                      {
                        name: "passport_photo",
                        value: e.target.files[0].name
                      },
                      { required: true }
                    ),
                  });
                }}
              />
            </button>

					{!data ? (
						<button
							onClick={toggleModelNewMember}
							className="btn bg-nxt text-white mt-2 d-flex align-items-center"
						>
							<MRZIcon className="me-1" style={{ flex: "1 0 auto" }} />
							<span>{onlineVisa.insertDateUploadPassport}</span>
						</button>
					) : null}
				</div>

				{/* details */}
				<div className="col-md-10 col-12 p-0">
					<div className=" d-flex align-items-start justify-content-between">
						<div className=" w-100">
							<div className="box1">
								<div className="row align-items-center w-100 no-gutter  m-0 ">
									<div className="col-3">
										<TextField
											hasLabel={true}
											type="text"
											disabled={data?.ppno}
											label={reservation.messages.passportNo}
											placeholder={reservation.messages.passportNoPlaceholder}
											value={member.passport_number}
											onChange={(e) => {
												setMember({
													...member,
													passport_number: e.target.value,
												});

												setErrors({
													...errors,
													...validate(
														{ name: "passport_number", value: e.target.value },
														{ required: true }
													),
												});
											}}
											color={errors?.passport_number?.required ? "danger" : ""}
											errors={errors?.passport_number}
										/>
									</div>
									<div className="col-md-3 ">
										<TextField
											hasLabel={true}
											type="text"
											label={visa.NATIONALID}
											placeholder={visa.NATIONALIDPlaceholder}
											value={member.national_id}
											onChange={(e) => {
												setMember({
													...member,
													national_id: e.target.value,
												});
											}}
										/>
									</div>
									<div className="col-md-3 ">
										<SelectField
											name="contract"
											placeholder={visa.SelectGender}
											disabled={data?.gender}
											options={genderLookups}
											label={visa.Gender}
											value={member.gender?.label}
											onChange={(e) => {
												const titleBasedOnGender = e.code?.toLowerCase() === "male" 
												? { id: 1, value: 1, label: "Mr", name: "Mr"} 
												: e.code?.toLowerCase() === "female" ? { id: 2, value: 2, label: "Miss", name: "Miss"}
												: ""
												setMember({
													...member,
													gender: e,
													title: titleBasedOnGender
												});
												setErrors({
													...errors,
													...validate(
														{ name: "gender", value: e },
														{ required: true }
													),
													...validate(
														{ 
															name: "title", 
															value: titleBasedOnGender
														},
														{ required: true }
													),
												});
											}}
											color={errors?.gender?.required  ? "danger" : ""}
											errors={errors?.gender}
										/>
									</div>

									<div className="col-md-3 ">
										<SelectField
											name="group-status"
											placeholder={visa.SelectTitle}
											label={visa.title}
											options={TitleList}
											value={member.title?.label}
											onChange={(e) => {
												setMember({
													...member,
													title: e,
												});
												setErrors({
													...errors,
													...validate(
														{ name: "title", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.title?.required   ? "danger" : ""}
											errors={errors?.title}
										/>
									</div>
									<div className="col-md-3"></div>
								</div>
							</div>

							<div className="box2 bg-gray p-1 mx-1  input-padding-fix px-2 rounded">
								<h3 className="p-1 font-dark-blue"> {visa.ArabicName}</h3>
								<div className="row align-items-center w-100 no-gutter m-0 ">
									<div className="col-md-3 ">
										<TextField
											hasLabel={false}
											type="text"
											placeholder={visa.firstNamePlaceholder}
											value={member.first_name_ar}
											onChange={(e) => {
												setMember({
													...member,
													first_name_ar: e.target.value,
												});
											}}
										/>
									</div>
									<div className="col-md-3">
										<TextField
											hasLabel={false}
											type="text"
											placeholder={visa.fatherNamePlaceholder}
											value={member.father_name.ar}
											onChange={(e) => {
												setMember({
													...member,
													father_name: {
														en: member.father_name.en,
														ar: e.target.value,
													},
												});
											}}
										/>
									</div>
									<div className="col-md-3">
										<TextField
											hasLabel={false}
											type="text"
											placeholder={visa.grandFatherNamePlaceholder}
											value={member.grandfather_name.ar}
											onChange={(e) => {
												setMember({
													...member,
													grandfather_name: {
														en: member.grandfather_name.en,
														ar: e.target.value,
													},
												});
											}}
										/>
									</div>

									<div className="col-md-3">
										<TextField
											hasLabel={false}
											type="text"
											placeholder={visa.lastNamePlaceholder}
											value={member.last_name_ar}
											onChange={(e) => {
												setMember({
													...member,
													last_name_ar: e.target.value,
												});
											}}
										/>
									</div>
									<div className="col-md-3"></div>
								</div>
								<h3 className="p-1 font-dark-blue"> English Name</h3>
								<div className="row align-items-center w-100 no-gutter m-0  ">
									<div className="col-md-3 ">
										<TextField
											hasLabel={false}
											type="text"
											placeholder={visa.firstNamePlaceholder}
											label={visa.firstName}
											value={member.first_name_en}
											onChange={(e) => {
												setMember({
													...member,
													first_name_en: e.target.value,
												});
												setErrors({
													...errors,
													...validate(
														{ name: "first_name_en", value: e.target.value },
														{ required: true }
													),
												});
											}}
											color={errors?.first_name_en?.required ? "danger" : ""}
											errors={errors?.first_name_en}
										/>
									</div>
									<div className="col-md-3">
										<TextField
											hasLabel={false}
											type="text"
											//label={visa.fatherName}
											placeholder={visa.fatherNamePlaceholder}
											value={member.father_name.en}
											onChange={(e) => {
												setMember({
													...member,
													father_name: {
														en: e.target.value,
														ar: member.father_name.ar,
													},
												});
											}}
										/>
									</div>
									<div className="col-md-3">
										<TextField
											hasLabel={false}
											type="text"
											//label={visa.grandFatherName}
											placeholder={visa.grandFatherNamePlaceholder}
											value={member.grandfather_name.en}
											onChange={(e) => {
												setMember({
													...member,
													grandfather_name: {
														en: e.target.value,
														ar: member.grandfather_name.ar,
													},
												});
											}}
										/>
									</div>

									<div className="col-md-3">
										<TextField
											hasLabel={false}
											type="text"
											label={visa.lastName}
											placeholder={visa.lastNamePlaceholder}
											value={member.last_name_en}
											onChange={(e) => {
												setMember({
													...member,
													last_name_en: e.target.value,
												});
												setErrors({
													...errors,
													...validate(
														{ name: "last_name_en", value: e.target.value },
														{ required: true }
													),
												});
											}}
											color={errors?.last_name_en?.required ? "danger" : ""}
											errors={errors?.last_name_en}
										/>
									</div>
									<div className="col-md-3"></div>
								</div>
							</div>

							<div className=" my-2  ">
								<div className="row align-items-center w-100 no-gutter m-0 ">
									<div className="col-md-3">
										<SelectField
											name="contract"
											label={visa.passportType}
											placeholder={visa.passportTypePlaceholder}
											options={PassType}
											value={member?.passport_type?.label}
											onChange={(e) => {
												setMember({
													...member,
													passport_type: e,
												});
												setErrors({
													...errors,
													...validate(
														{ name: "passport_type", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.passport_type?.required  ? "danger" : ""}
											errors={errors?.passport_type}
										/>
									</div>
									<div className="col-md-3">
										<SelectField
											name="contract"
											label={visa.maritalStatus}
											placeholder={visa.maritalStatusPlaceholder}
											options={MaritalStatus}
											value={member?.marital_status?.label}
											onChange={(e) => {
												setMember({
													...member,
													marital_status: e,
												});
											}}
										/>
									</div>

									<div className="col-md-3">
										<SelectField
											name="group-status"
											label={visa.educationLevel}
											placeholder={visa.educationLevelPlaceholder}
											options={EduLevel}
											value={member.educational_level?.label}
											onChange={(e) => {
												setMember({
													...member,
													educational_level: e,
												});
											}}
										/>
									</div>
									<div className="col-md-3">
										<SelectField
											name="contract"
											label={visa.country}
											disabled={member.country}
											placeholder={visa.countryPlaceholder}
											options={countries}
											value={member?.country?.label}
											onChange={(e) => {
												setMember({
													...member,
													country: e,
													nationality: !member.country ? e : member.nationality,
													birth_country: !member.country ? e : member?.birth_country,
													issue_country: !member.country ? e : member?.issue_country
												});
												setErrors({
													...errors,
													...validate(
														{ name: "country", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.country?.required ? "danger" : ""}
											errors={errors?.country}
										/>
									</div>
								</div>
								<div className="row align-items-center w-100 no-gutter m-0 ">
									<div className="col-md-3">
										<SelectField
											name="contract"
											label={visa.nationality}
											disabled={data}
											placeholder={visa.nationalityPlaceholder}
											options={countries}
											value={member?.nationality?.label}
											onChange={(e) => {
												setMember({
													...member,
													nationality: e,
												});
												setErrors({
													...errors,
													...validate(
														{ name: "nationality_id", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.nationality_id?.required ? "danger" : ""}
											errors={errors?.nationality_id}
										/>
									</div>
									<div className="col-md-3">
										<SelectField
											name="contract"
											label={visa.birthCountry}
											placeholder={visa.birthCountryPlaceholder}
											options={countries}
											value={member?.birth_country?.label}
											onChange={(e) => {
												setMember({
													...member,
													birth_country: e,
												});
											  setErrors({
													...errors,
													...validate(
														{ name: "birth_country", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.birth_country?.required  ? "danger" : ""}
											errors={errors?.birth_country}
										/>
									</div>

									<div className="col-md-3">
										<SelectField
											name="group-status"
											label={visa.issueCountry}
											placeholder={visa.issueCountryPlaceholder}
											options={countries}
											value={member.issue_country?.label}
											onChange={(e) => {
												setMember({
													...member,
													issue_country: e,
												});
												setErrors({
													...errors,
													...validate(
														{ name: "issue_country", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.issue_country?.required  ? "danger" : ""}
											errors={errors?.issue_country}
										/>
									</div>
									<div className="col-md-3">
										<TextField
											hasLabel={true}
											type="text"
											label={visa.issuePlace}
											placeholder={visa.issuePlacePlaceholder}
											value={member.issue_place}
											onChange={(e) => {
												setMember({
													...member,
													issue_place: e.target.value,
												});
												setErrors({
                          ...errors,
                          ...validate(
                            {
                              name: "issue_place",
                              value: e.target.value,
                            },
                            { required: true }
                          ),
                        });
                      }}
                      color={
                        errors?.issue_place?.required  ? "danger" : ""
                      }
                      errors={errors?.issue_place}
										/>
									</div>
								</div>

								<div className="row align-items-center w-100 no-gutter m-0 ">
									<div className="col-md-3">
										<TextField
											hasLabel={true}
											type="text"
											label={visa.birthPlace}
											placeholder={visa.birthPlacePLaceholder}
											value={member.birth_place}
											onChange={(e) => {
												setMember({
													...member,
													birth_place: e.target.value,
												});
												setErrors({
                          ...errors,
                          ...validate(
                            {
                              name: "birth_place",
                              value: e.target.value,
                            },
                            { required: true }
                          ),
                        });
                      }}
                      color={
                        errors?.birth_place?.required  ? "danger" : ""
                      }
                      errors={errors?.birth_place}
											hasYears={true}
											startYear={moment().subtract(120, "years")}
											endYear={moment().subtract(0, "years")}
										/>
									</div>
									<div className="col-md-3 ">
										<DatePickerField
											label={visa.Birthday}
											placeholder={"DD/MM/YYYY"}
											date={
												member?.date_of_birth
													? moment(member?.date_of_birth)
													: ""
											}
											value={moment(member?.date_of_birth)}
											onDateChange={(e) => {
												setMember({
													...member,
													date_of_birth: e,
												});
												setErrors({
													...errors,
													...validate(
														{ name: "date_of_birth", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.date_of_birth?.required   ? "danger" : ""}
											errors={errors?.date_of_birth}
											isOutsideRange={(day) => {
												return (
													!day.isAfter(moment().subtract(120, "years"), "day") ||
													!day.isBefore(moment().subtract(0, "years"), "day")
												);
											}}
											hasYears={true}
											startYear={moment().subtract(120, "years")}
											endYear={moment().subtract(0, "years")}
										/>
									</div>

									<div className="col-md-3 ">
										<DatePickerField
											label={visa.issueDate}
											minDate={false}
											placeholder={"DD/MM/YYYY"}
											date={
												member?.issue_date ? moment(member?.issue_date) : ""
											}
											value={
												member?.issue_date ? moment(member?.issue_date) : ""
											}
											onDateChange={(e) => {
												setMember({
													...member,
													issue_date: e,
												});
												setErrors({
													...errors,
													...validate(
														{ name: "issue_date", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.issue_date?.required  ? "danger" : ""}
											errors={errors?.issue_date}
											hasYears={true}
											startYear={moment().subtract(120, "years")}
											endYear={moment().subtract(0, "years")}
											isOutsideRange={(day) => {
												return !day.isBefore(moment(), "day");
											}}
										/>
									</div>
									<div className="col-md-3 ">
										<DatePickerField
											label={visa.expiryDate}
											disabled={data?.ex_date}
											placeholder={"DD/MM/YYYY"}
											date={
												member?.expire_date ? moment(member?.expire_date) : ""
											}
											value={
												member?.expire_date ? moment(member?.expire_date) : ""
											}
											onDateChange={(e) => {
												setMember({
													...member,
													expire_date: e,
												});
												setErrors({
													...errors,
													...validate(
														{ name: "expire_date", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.expire_date?.required ? "danger" : ""}
											errors={errors?.expire_date}
											isOutsideRange={(day) => {
												return (
													day.isBefore(moment().add("6", "month")) ||
													day.isBefore(moment().subtract(10, "years"))
												);
											}}
											hasYears={true}
											startYear={moment().add(6, "month")}
											endYear={moment().add(10, "years")}
											initialVisibleMonth={() =>
												moment().add("6", "month") || null
											}
										/>
									</div>
								</div>

								<div className="row align-items-center w-100 no-gutter m-0 ">
									<div className="col-md-3">
										<TextField
											hasLabel={true}
											type="text"
											label={visa.JOB}
											placeholder={visa.jobPlaceholdre}
											value={member?.job}
											onChange={(e) => {
												setMember({
													...member,
													job: e.target.value,
												});
											}}
										/>
									</div>
								</div>

								<div className="row align-items-center w-100 no-gutter m-0 ">
									{/* <div className="col-md-6">
										<TextField
											hasLabel={true}
											type="text"
											label={visa.ADDRESS}
											//6placeholder={visa.ADDRESSPlaceholder}
											value={member?.CAddaCity}
									
										
										/>
									</div> */}

									{/* <div className="col-md-3">
										<SelectField
											name="group-status"
											label={visa.MAHRAM}
											placeholder={visa.MAHRAMPlaceholder}
											options={mehermList}
											value={member?.mehrem_name?.label}
											onChange={(e) => {
												setMember({
													...member,
													mehrem_name: e,
												});
											}}
										/>
									</div> */}

									{/* <div className="col-md-3">
										<SelectField
											name="group-status"
											label={visa.RELATION}
											placeholder={visa.relationPlaceholder}
											options={relationsLookups}
											value={member?.relationship?.label}
											onChange={(e) => {
												setMember({
													...member,
													relationship: e,
												});
											}}
										/>
									</div> */}
								</div>

								{/* Residence Data */}
								{residenceRequired ? (
									<div className="my-2">
										<h4 className="mb-2">{inventory.messages.residenceData}</h4>
										<div className="row residence-section">
											<div className="col-3">
												<div className={`p-2 upload-residence w-100 ${
																errors?.residence_photo?.required && !residenceyImage
																	? `border-danger`
																	: ""
															}`}>
													<p className="text-caption mb-2">
														{inventory.messages.uploadResidencyphoto}
													</p>
													{residenceyImage ? (
														<p className="text-gray d-flex align-items-center">
															<span className="flex-fill overflow-hidden">
																{residenceyImage.name}
															</span>
															<i
																className="fas fa-times-circle pointer text-danger flex-fill"
																onClick={() => setResidenceyImage(null)}
															></i>
														</p>
													) : (
														<div>
															<label
																htmlFor="upload-residence"
																className="upload-residence-button bg-nxt"
															>
																{payment.messages.upload}
																<UploadIcon className="ms-1" />
															</label>
															<input
																onChange={handleUpload}
																style={{ display: "none" }}
																type="file"
																id="upload-residence"
																accept="image/*"
															/>
														</div>
													)}
												</div>
											</div>
											<div className="col-3">
												<TextField
													hasLabel={true}
													type="text"
													// disabled={data?.ppno}
													label={inventory.messages.residencyNumber}
													placeholder={inventory.messages.residencyNumber}
													value={member.residencey_number}
													onChange={(e) => {
														setMember({
															...member,
															residencey_number: e.target.value,
														});
														setErrors({
															...errors,
															...validate(
																{
																	name: "residencey_number",
																	value: e.target.value,
																},
																{ required: true }
															),
														});
													}}
													color={
														errors?.residencey_number?.required ? "danger" : ""
													}
													errors={errors?.residencey_number}
												/>
											</div>
											<div className="col-md-3 ">
												<DatePickerField
													label={visa.issueDate}
													minDate={false}
													placeholder={"DD/MM/YYYY"}
													date={
														member?.residencey_issue_date
															? moment(member?.residencey_issue_date)
															: ""
													}
													onDateChange={(e) => {
														setMember({
															...member,
															residencey_issue_date: e,
														});
														setErrors({
															...errors,
															...validate(
																{ name: "residencey_issue_date", value: e },
																{ required: true }
															),
														});
													}}
													hasYears={true}
													isOutsideRange={(day) => {
														return !day.isBefore(moment(), "day");
													}}
													color={
														errors?.residencey_issue_date?.required
															? "danger"
															: ""
													}
													errors={errors?.residencey_issue_date}
												/>
											</div>
											<div className="col-md-3 datepicker-centerd">
												<DatePickerField
													label={visa.expiryDate}
													// disabled={data?.ex_date}
													placeholder={"DD/MM/YYYY"}
													date={
														member?.residencey_expire_date
															? moment(member?.residencey_expire_date)
															: ""
													}
													value={
														member?.residencey_expire_date
															? moment(member?.residencey_expire_date)
															: ""
													}
													onDateChange={(e) => {
														setMember({
															...member,
															residencey_expire_date: e,
														});
														setErrors({
															...errors,
															...validate(
																{ name: "residencey_expire_date", value: e },
																{ required: true }
															),
														});
													}}
													isOutsideRange={(day) => {
														return day.isBefore(moment().add(1, "day"), "day");
													}}
													hasYears={true}
													color={
														errors?.residencey_expire_date?.required
															? "danger"
															: ""
													}
													errors={errors?.residencey_expire_date}
												/>
											</div>
										</div>
									</div>
								) : null}

								<div className="row align-items-center w-100 no-gutter m-0  round mrz   px-2">
									<div className="mrz-label col-md-2 bg-grey py-2 text-light text-center rounded ">
										<p>MRZ</p>
									</div>
									<div className="col-md-10 py-2 text-16 bg-gray">
										<p style={{ wordBreak: "break-word" }}>{member?.mrz}</p>
									</div>
								</div>

								<div className="d-flex justify-content-end my-4 px-2">
									<button
										className="btn bg-gray mx-2"
										onClick={() => {
											history.push({ pathname: redirectURL });
										}}
									>
										{productsBuilder.cancel}
									</button>
									<button className="btn bg-nxt" onClick={submit}>
										{productsBuilder.save}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{member.passport_photo && member.passport_photo !== "" && modalOpen && (
				<CustomModal
					modalIsOpen={modalOpen}
					header={
						<span className="h5 font-weight-bold text-capitalize">
							{visa.passportPhotoPreview}
						</span>
					}
					toggle={toggleModal}
					size={"md"}
					centered={true}
				>
					<div className="mt-3">
						<img
							className="img-fluid rounded w-100"
							src={
								member.passport_photo ? member.passport_photo : ImagePlaceholder
							}
							alt="full size passport"
						/>
					</div>
				</CustomModal>
			)}

			<CustomModal
				modalIsOpen={ModalNewMember}
				header={onlineVisa.AddNew}
				toggle={toggleModelNewMember}
				size={"md"}
				centered={true}
				onClosed={checkFormErrors}
			>
				<AddNewPassengerModal
					checkedIds={checkedIds}
					manaul
					setPassengerData={setPassengerData}
				/>
				{/* <MRZ checkedIds={checkedIds} /> */}
			</CustomModal>
		</div>
	);
}
