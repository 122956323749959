import React, { useState } from "react";
import { TabContent, Nav, NavItem, NavLink } from "reactstrap";
import ProductHeader from "../ProductHeader";
import classnames from "classnames";
import AccommodationTab from "./AccommodationTab";
import DetailsTab from "./DetailsTab";

export default function Travelers(props) {

	const [activeTab, setActiveTab] = useState("1");
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	return (
		<>
			<ProductHeader />
			<div className="container-custom">
				<div className="traveler-tab">
					<div className="main-tabs ">
						<Nav tabs className="align-items-center main-nav">
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === "1" })}
									onClick={() => {
										toggle("1");
									}}
								>
									{"Details"}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === "2" })}
									onClick={() => {
										toggle("2");
									}}
								>
									{"Accommodation"}
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={activeTab}>
							{activeTab === "1" ? <DetailsTab /> : <AccommodationTab />}
						</TabContent>
					</div>
				</div>
			</div>
		</>
	);
}
