import SelectField from 'components/Form/SelectField/SelectField'
import TextAreaField from 'components/Form/TextAreaField/TextAreaField'
import TextField from 'components/Form/TextField/TextField'
import CheckBox from 'components/chekbox'
import React, { useEffect, useMemo, useState } from 'react'
import Locale from 'translations'
import validate, { isFormValid } from 'helpers/validate'
import { useSBSState } from 'context/global'
import { createNewPackage, updateExistPackage } from 'services/tripPlanner'
import { store } from 'react-notifications-component'
import { categroyTypes } from 'modules/TripPlanner/helpers/tripPlannerLookups'
import NumberField from 'components/Form/NumberField/NumberField'
import { useHistory, useParams } from 'react-router-dom'
import { formatTripPlannerData } from 'modules/TripPlanner/helpers/formatTripPlannerData'
 

const hajjId = 1;
const umrahId = 2
const tourismId = 4;
const customServicesId = 5;
const oneServiceId = 6

export default function TripPlannerPackageDetails({
	productTypes,
	productClassifications,
	setActiveTab,
	packageDetails,
	setTripPlannerData,
	canEdit
}) {
	// states & hooks
	const { productsBuilder, companyDetails , operationStatement } = Locale
	const { allCountries, locale } = useSBSState()
	const { id } = useParams()
	const history = useHistory()

	const [packageDetailsData, setPackageDetailsData] = useState({
		name: '',
		type: { product_type_id: 0, name: '' },
		product: { category_type: '', name: '' },
		destinations: [],
		country: { country_id: 0, name: '' },
		duration: '',
		rate: { rate: 0, name: '' },
		has_visa: 0,
		has_insurance: 0,
		visa_type: '',
		visa_count: '',
		terms: '',
		service_items: [],
	})

	const [errors, setErrors] = useState({})
	const [isErrorLoaded, setIsErrorLoaded] = useState(false)

	const [filteredCountries, setFilteredCountries] = useState([])


	// variables
	const saudiCountry = allCountries?.find(country => country?.id === 966);

	const servicesItems = useMemo(() => {
		return [
			{ label: productsBuilder.hotel, name: productsBuilder.hotel, id: 6 },
			{
				label: productsBuilder.externalTransportations,
				name: productsBuilder.externalTransportations,
				id: 1,
			},
			{
				label: productsBuilder.internalTransportations,
				name: productsBuilder.internalTransportations,
				id: 2,
			},
			{ label: productsBuilder.attractionLandmark, name: productsBuilder.attractionLandmark, id: 7 }
		]
	}, []);

	//Check Errors
	const checkFormErrors = () => {
		setErrors({
			...errors,

			...validate(
				{ name: 'name', value: packageDetailsData.name },
				{ required: true }
			),

			...validate(
				{ name: 'type', value: packageDetailsData.type.name },
				{ required: true }
			),

			...validate(
				{ name: 'product', value: packageDetailsData.product.name },
				{ required: packageDetailsData.type.id === tourismId ? true : false }
			),

			...validate(
				{ name: 'duration', value: packageDetailsData.duration },
				{ required: true }
			),

			...validate(
				{ name: 'destinations', value: packageDetailsData.destinations },
				{
					required:
						packageDetailsData.type.name === 'Hajj' ||
							packageDetailsData.type.name === 'Umrah'
							? false
							: true
				}
			),

			...validate(
				{ name: 'rate', value: packageDetailsData.rate.name },
				{ required: true }
			),

			...validate(
				{ name: 'country', value: packageDetailsData.country.name },
				{ required: true }
			)
		})
	}

	const handleCreatePackage = async () => {
		checkFormErrors()
		if (!isErrorLoaded) {
			setIsErrorLoaded(true)
		} else {
			setIsErrorLoaded(false)
		}
	}

	const createUpdatePackage = async () => {
		let res
		const {
			name,
			type,
			product,
			duration,
			rate,
			has_insurance,
			has_visa,
			terms,
			visa_count,
			visa_type,
			country,
			service_items
		} = packageDetailsData
		let data = {
			name,
			product_type_id: type.product_type_id || type?.id,
			category_type: product.category_type,
			duration,
			destinations: packageDetailsData?.destinations?.find(
				(country) => country?.value === 'all'
			)
				? null
				: packageDetailsData?.destinations?.map((country) => country?.id),

			rate: rate.rate || rate?.value,
			has_insurance,
			has_visa,
			visa_count,
			visa_type,
			terms,
			country_id: country.country_id || country.id,
			service_items: service_items?.length > 0 ? service_items?.map(item => item?.id) : service_items?.id ? [service_items?.id] : []
		}

		if (id) {
			res = await updateExistPackage(id, data)
		} else {
			res = await createNewPackage(data)
		}

		if (res.status === 200 || res.status === 201) {
			store.addNotification({
				title: 'Info!',
				message: productsBuilder.addNewProduct,
				type: 'success',
				insert: 'top',
				container: 'top-right',
				animationIn: ['animated', 'fadeIn'],
				animationOut: ['animated', 'fadeOut'],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true
				}
			})
			setActiveTab('itinerary')
			if (!id) {
				history.push(`/trip-planner/edit/${res?.data?.data?.id}`)
			}
			const formateTripData = formatTripPlannerData(res?.data?.data)
			setTripPlannerData(formateTripData)
		}
	}

	// set package details data from api response
	useEffect(() => {
		let data = {
			name: packageDetails?.name,
			type: packageDetails?.type || { product_type_id: 0, name: '' },
			product: categroyTypes.find(type => type.name === packageDetails?.product) || { category_type: '', name: '' },
			destinations: packageDetails?.destinations || [],
			country: packageDetails?.country || { country_id: 0, name: '' },
			duration: packageDetails?.duration || '',
			rate: packageDetails?.rate || { rate: 0, name: '' },
			has_visa: packageDetails?.has_visa || 0,
			has_insurance: packageDetails?.has_insurance || 0,
			visa_type: packageDetails?.visa_type || '',
			visa_count: packageDetails?.visa_count || '',
			terms: packageDetails?.terms || '',
			service_items: packageDetails?.services?.map(service => ({ id: service?.id, value: service?.id, name: service?.name[locale], label: service?.name[locale] }))
		}
		setPackageDetailsData(data)
	}, [packageDetails])

	// set filterd countries in case edit
	useEffect(() => {
		if (packageDetails) {
			const ids = packageDetailsData.destinations.map((ele) => ele.value)
			const countries = allCountries.filter((ele) => ids.includes(ele.id))
			setFilteredCountries(countries)
		}
	}, [allCountries, packageDetails])

	// call api after check errors
	useEffect(() => {
		if (isFormValid(errors)) {
			createUpdatePackage()
		}
	}, [isErrorLoaded]);



	return (
		<div className='summery-details-wrapper'>
			<div className='summery-items'>
				<div className='summery-item'>
					<TextField
						type='text'
						label={productsBuilder.tripName}
						placeholder={productsBuilder.enterName}
						name='name'
						value={packageDetailsData.name}
						onChange={(e) => {
							setPackageDetailsData({
								...packageDetailsData,
								name: e.target.value
							});
							setErrors({
								...errors,
								...validate(
									{ name: "name", value: e.target.value },
									{ required: true }
								),
							});
						}}
						color={errors?.name?.required ? 'danger' : ''}
						errors={errors?.name}
						disabled={!canEdit}
					/>
				</div>

				<div className='summery-item'>
					<SelectField
						label={productsBuilder.journeyType}
						placeholder={productsBuilder.Category}
						value={packageDetailsData.type.name}
						onChange={(e) => {
							setPackageDetailsData({
								...packageDetailsData,
								type: {
									id: e.value,
									product_type_id: e.value,
									name: e.label
								},
								product: {
									category_type: '',
									name: ''
								},
								service_items: [],

								destinations: e?.value === hajjId || e?.value === umrahId
									? [{ ...saudiCountry, label: saudiCountry?.name }]
									: packageDetailsData.destinations,

								country: e?.value === hajjId || e?.value === umrahId
									? saudiCountry
									: packageDetailsData?.country,
							});
							setErrors({
								...errors,
								...validate(
									{ name: "type", value: e },
									{ required: true }
								),
							});
						}}
						options={productTypes}
						color={errors?.type?.required ? 'danger' : ''}
						errors={errors?.type}
						disabled={!canEdit}
					/>
				</div>


				{packageDetailsData.type?.id === customServicesId || packageDetailsData.type?.id === oneServiceId ?
					<div className='summery-item'>
						<SelectField
							multi={packageDetailsData.type?.id === customServicesId ? true : false}
							placeholder={packageDetailsData.service_items?.length > 0 ? packageDetailsData.service_items :  `${operationStatement.Select}`}
							label={productsBuilder.selectService}
							options={servicesItems}
							value={packageDetailsData?.service_items[0]?.name || packageDetailsData?.service_items?.name}
							getOptionLabel={packageDetailsData.type?.id === customServicesId ? (x) => x.name : null}
							getOptionValue={packageDetailsData.type?.id === customServicesId ? (x) => x.id : null}
							onChange={(values, { action }) => {
								if (packageDetailsData.type?.id === customServicesId) {
									if (action === 'remove-value' && !values) {
										setPackageDetailsData({
											...packageDetailsData,
											service_items: []
										})
										return
									}
								}
								setPackageDetailsData({
									...packageDetailsData,
									service_items: values
								})
								setErrors({
									...errors,
									...validate(
										{ name: "service_items", value: values },
										{ required: true }
									),
								});
							}}
							disabled={!canEdit}
						/>
					</div>
					:
					<div className='summery-item'>
						<SelectField
							label={productsBuilder.categoryType}
							placeholder={productsBuilder.choose}
							disabled={packageDetailsData.type.id !== tourismId || !canEdit}
							value={
								packageDetailsData.type.id === tourismId
									? packageDetailsData.product.name
									: productsBuilder.choose
							}
							onChange={(e) => {
								setPackageDetailsData({
									...packageDetailsData,
									product: {
										category_type: e.id,
										name: e.name
									}
								});
								setErrors({
									...errors,
									...validate(
										{ name: "product", value: e },
										{ required: packageDetailsData.type.id === tourismId ? true : false }
									),
								});
							}}
							options={categroyTypes}
							color={errors?.product?.required ? 'danger' : ''}
							errors={errors?.product}

						/>
					</div>

				}


				<div className='summery-item'>
					<TextField
						type='number'
						label={`${productsBuilder.duration} ${packageDetailsData?.duration
							? ` (${packageDetailsData?.duration} ${packageDetailsData?.duration > 1 ? productsBuilder.days : productsBuilder.day} 
								${packageDetailsData?.duration - 1} ${productsBuilder.nights} )`
							: ``
							}`}
						placeholder={productsBuilder.nightsNumber}
						value={packageDetailsData.duration}
						onChange={(e) => {
							if (e.target.value > -1) {
								setPackageDetailsData({
									...packageDetailsData,
									duration: e.target.value
								})
								setErrors({
									...errors,
									...validate(
										{ name: "duration", value: e.target.value },
										{ required: true }
									),
								});
							}
						}}
						color={errors?.duration?.required ? 'danger' : ''}
						errors={errors?.duration}
						disabled={!canEdit}
					/>
				</div>

				<div className='summery-item'>
					<SelectField
						multi
						placeholder={
							packageDetailsData.destinations?.length > 0
								? packageDetailsData.destinations
								: productsBuilder.destinationTypePlaceholder
						}
						getOptionLabel={(x) => x.name}
						getOptionValue={(x) => x.id}
						onChange={(values, { action }) => {
							if (action === 'remove-value' && !values) {
								setPackageDetailsData({
									...packageDetailsData,
									destinations: []
								})
								return
							}
							setPackageDetailsData({
								...packageDetailsData,
								destinations: values
							})
							setErrors({
								...errors,
								...validate(
									{ name: "destinations", value: values },
									{ required: true }
								),
							});

							const ids = values.map((ele) => ele.value)
							const countries = allCountries.filter((ele) => ids.includes(ele.id))
							setFilteredCountries(countries)
						}}
						label={productsBuilder.destinations}
						options={allCountries}
						disabled={
							packageDetailsData.type?.id === hajjId || packageDetailsData.type?.id === umrahId || !canEdit
						}
						color={errors?.destinations?.required ? 'danger' : ''}
						errors={errors?.destinations}
					/>
				</div>

				<div className='summery-item'>
					<SelectField
						label={productsBuilder.firstDestination}
						disabled={
							// filteredCountries?.length ||
							packageDetailsData.type?.id === hajjId || packageDetailsData.type?.id === umrahId || !canEdit
						}
						options={filteredCountries}
						value={packageDetailsData?.country?.name}
						onChange={(e) => {
							setPackageDetailsData({
								...packageDetailsData,
								country: {
									country_id: e.id,
									name: e.name
								}
							});
							setErrors({
								...errors,
								...validate(
									{ name: "country", value: e },
									{ required: true }
								),
							});
						}}
						color={errors?.country?.required ? 'danger' : ''}
						errors={errors?.country}
					/>
				</div>

				<div className='summery-item'>
					<SelectField
						label={productsBuilder.rating}
						placeholder={productsBuilder.choose}
						value={packageDetailsData.rate.name}
						onChange={(e) => {
							setPackageDetailsData({
								...packageDetailsData,
								rate: {
									rate: e.value,
									name: e.label
								}
							});
							setErrors({
								...errors,
								...validate(
									{ name: "rate", value: e },
									{ required: true }
								),
							});
						}}
						options={productClassifications}
						color={errors?.rate?.required ? 'danger' : ''}
						errors={errors?.rate}
						disabled={!canEdit}
					/>
				</div>
			</div>
			<p className='summery-title'>{productsBuilder.addOns}</p>
			<div className='summery-checkBox'>
				<CheckBox
					checked={packageDetailsData.has_insurance}
					id={'package_has_insurance'}
					name={'package_has_insurance'}
					text={productsBuilder.includeInsurance}
					onChange={() =>
						setPackageDetailsData({
							...packageDetailsData,
							has_insurance: packageDetailsData.has_insurance === 0 ? 1 : 0
						})
					}
					disabled={!canEdit}
				/>
			</div>
			<div className='summery-checkBox'>
				<CheckBox
					checked={packageDetailsData.has_visa}
					id={'package_has_visa'}
					name={'package_has_visa'}
					text={productsBuilder.includeVisa}
					onChange={() =>
						setPackageDetailsData({
							...packageDetailsData,
							has_visa: packageDetailsData.has_visa === 0 ? 1 : 0
						})
					}
					disabled={!canEdit}
				/>
			</div>

			{packageDetailsData.has_visa ? (
				<>
					<div className='row'>
						<div className='col-6'>
							<TextField
								type='text'
								label={productsBuilder.visaType}
								placeholder={productsBuilder.enterPlace}
								value={packageDetailsData.visa_type}
								onChange={(e) => {
									setPackageDetailsData({
										...packageDetailsData,
										visa_type: e.target.value
									})
								}}
								disabled={!canEdit}
							/>
						</div>

						<div className='col-6'>
							<NumberField
								type='number'
								removeArrow={true}
								label={productsBuilder.numberVisa}
								placeholder={productsBuilder.enterNumber}
								value={packageDetailsData.visa_count}
								onChange={(e) => {
									setPackageDetailsData({
										...packageDetailsData,
										visa_count: e.target.value
									})
								}}
								onWheel={(e) => e.target.blur()}
								disabled={!canEdit}
							/>
						</div>
					</div>

					<div className='row'>
						<div className='col-12'>
							<TextAreaField
								type='text'
								label={productsBuilder.visaTerms}
								placeholder={productsBuilder.enterNotes}
								value={packageDetailsData.terms}
								onChange={(e) => {
									setPackageDetailsData({
										...packageDetailsData,
										terms: e.target.value
									})
								}}
								disabled={!canEdit}
							/>
						</div>
					</div>
				</>
			) : null}

			<div className='d-flex justify-content-end'>
				<button
					className='btn summery-details-button'
					type='submit'
					onClick={() => canEdit ? handleCreatePackage() : setActiveTab('itinerary')}
				>
					{companyDetails.next}
				</button>
			</div>
		</div>
	)
}
