// import visaColored from "assets/images/1Online.svg";
// import visaCray from "assets/images/2Online.svg";
// import groundService from "assets/images/groundService.svg";
// import groundServiceAcive from "assets/images/groundServiceAcive.svg";
// import carColored from "assets/images/inventory/carColored.svg";
// import carCray from "assets/images/inventory/carCray.svg";
// import toursImg from "assets/images/inventory/tours.svg";
// import flightImg from "assets/images/flights.svg";

// import HotelGray from "assets/images/inventory/HotelGray.svg";
// import HotelColored from "assets/images/inventory/HotelsColored.svg";
// import classnames from "classnames";
import { useSBSState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { useHistory, useParams, NavLink } from "react-router-dom";
import { Nav, NavItem, TabContent, TabPane } from "reactstrap";
import Locale from "translations";
import GroundServices from "./GroundServices";
import ToursRequests from "./Tour";
import TransferRequests from "./Transfer";
// import TransportationRequests from "./Transportation";
import CustomTableInventoryHotel from "./Hotels";
import VisaRequests from "./VisaRequests";
import useShowPermission from "hooks/useShowPermission";
import FlightsList from './Flights';
// import packageImg from "assets/images/packages.svg";
import PackagesRequests from './Packages';
import { FlightIcon, HotelIcon, PackagesIcon, ToursIcon, TransportationIcon, VisaIcon } from 'modules/UmrahOperations/shared/Icons';
// import TransportationRequests from './Transportation';

export default function InventoryList() {
	const history = useHistory();
	const { permissions, role } = useSBSState();
	const { inventory } = Locale;
	const transferPermission = useShowPermission({
		permission: ["View-Inventory-Transfer", "Manage-Inventory-Transfer"],
	});
	const toursPermission = useShowPermission({
		permission: ["View-Inventory-Tours", "Manage-Inventory-Tours"],
	});
	const hotalsPermission = useShowPermission({
		permission: ["View-Inventory-Hotels", "Manage-Inventory-Hotels"],
	});
	const visasPermission = useShowPermission({
		permission: ["View-Inventory-Visa", "Manage-Inventory-Visa"],
	});
	const flightPermission = useShowPermission({
		permission: ["View-Inventory-Flights", "Manage-Inventory-Flights"],
	});
	// const groundWorkPermission = useShowPermission({
	// 	permission: [
	// 		"View-Inventory-Ground-Service,"
	// 		"Manage-Inventory-Ground-Service",
	// 	],
	// });
	const [activeTab, setActiveTab] = useState(0);
	const { tab } = useParams();

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	useEffect(() => {
		switch (tab) {
			case "hotels":
				setActiveTab("1");
				break;
			case "transfer":
				setActiveTab("2");
				break;
			case "visa-requests":
				setActiveTab("3");
				break;
			case "ground-services":
				setActiveTab("4");
				break;
			case "tour":
				setActiveTab("5");
				break;
			case "flights":
				setActiveTab("6");
				break;
			case "packages":
				setActiveTab("8");
				break;
			// case "transportations":
			// 	setActiveTab("7");
			// 	break;
			default:
				setActiveTab("1");
				history.push("/inventory/hotels");
				break;
		}
	}, [tab, activeTab]);

	const redirect = () =>
		history.push(
			hotalsPermission
				? "/inventory/hotels"
				: transferPermission
					? "/inventory/transfer"
					: visasPermission
						? "/inventory/visa-requests"
						: toursPermission
							? "/inventory/tour"
							: flightPermission
								? "/inventory/flights"
								: null
		);
	useEffect(() => {
		if ((permissions && permissions.length > 0) || role) {
			if (!hotalsPermission && tab === "hotels") {
				redirect();
				return;
			}
			if (!transferPermission && tab === "transfer") {
				redirect();
				return;
			}
			if (!visasPermission && tab === "visa-requests") {
				redirect();
				return;
			}
			// if (!groundWorkPermission && tab === "ground-services") {
			// 	redirect();
			// 	return;
			// }
			if (!toursPermission && tab === "tour") {
				redirect();
				return;
			}
			if (!flightPermission && tab === "flights") {
				redirect();
				return;
			}
		}
	}, [permissions, role]);


	console.log(activeTab);


	return (
		<>
			{/* <FilterInventory /> */}
			<div className="inventory-container">
				<Nav tabs className="main-inventory-tabs">
					{/* hotels tab */}
					<ShowForPermission permission={["View-Inventory-Hotels", "Manage-Inventory-Hotels"]}>
						<NavItem>
							<NavLink to="/inventory/hotels" className="nav-link" activeClassName="active">
								<HotelIcon color="#505050" />
								<span className={`text`}>
									{inventory.messages.hotels}
								</span>
							</NavLink>
						</NavItem>
					</ShowForPermission>

					{/* transfer tab */}
					<ShowForPermission permission={["View-Inventory-Transfer", "Manage-Inventory-Transfer"]}>
						<NavItem>
							<NavLink to="/inventory/transfer" className="nav-link" activeClassName="active">
								<TransportationIcon color="#505050" />
								<span>
									{inventory.messages.transfer}
								</span>
							</NavLink>
						</NavItem>
					</ShowForPermission>

					{/* visa requests tab */}
					<ShowForPermission permission={["View-Inventory-Visa", "Manage-Inventory-Visa"]}>
						<NavItem>
							<NavLink to="/inventory/visa-requests" className="nav-link" activeClassName="active">
								<VisaIcon color="#505050" />
								<span>
									{inventory.messages.visaRequests}
								</span>
							</NavLink>
						</NavItem>
					</ShowForPermission>

					{/* Tours tab */}
					<ShowForPermission permission={["View-Inventory-Tours", "Manage-Inventory-Tours"]}>
						<NavItem>
							<NavLink to="/inventory/tour" className="nav-link" activeClassName="active">
								<ToursIcon color="#505050" />
								<span>
									{inventory.messages.tours}
								</span>
							</NavLink>
						</NavItem>
					</ShowForPermission>

					{/* fligths tab */}
					<ShowForPermission permission={["View-Inventory-Flights", "Manage-Inventory-Flights"]}>
						<NavItem>
							<NavLink to="/inventory/flights" className="nav-link" activeClassName="active">
								<FlightIcon color="#505050" />
								<span>
									{inventory.messages.flights}
								</span>
							</NavLink>
						</NavItem>
					</ShowForPermission>

					{/* package */}
					<NavItem>
						<NavLink to="/inventory/packages" className="nav-link" activeClassName="active">
							<PackagesIcon color="#505050" />
							<span>
								{inventory.messages.packages}
							</span>
						</NavLink>
					</NavItem>
				</Nav>

				<TabContent activeTab={activeTab} style={{ minHeight: "100%" }}>
					{/* hotels tab */}
					<TabPane tabId="1">
						{activeTab === "1" ? <CustomTableInventoryHotel /> : null}
					</TabPane>
					{/* hotels tab */}
					<TabPane tabId="2">
						{activeTab === "2" ? <TransferRequests /> : null}
					</TabPane>

					{/* Transportation Tab */}
					{/* <TabPane tabId="7">
								{activeTab === "7" ? <TransportationRequests /> : null}
							</TabPane> */}
					{/* Visa Requests Tab */}
					<TabPane tabId="3">
						{activeTab === "3" ? <VisaRequests /> : null}
					</TabPane>

					<TabPane tabId="4">
						{activeTab === "4" ? <GroundServices /> : null}
					</TabPane>

					<TabPane tabId="5">
						{activeTab === "5" ? <ToursRequests /> : null}
					</TabPane>

					<TabPane tabId="6">
						{activeTab === "6" ? <FlightsList /> : null}
					</TabPane>
					<TabPane tabId="8">
						{activeTab === "8" ? <PackagesRequests /> : null}
					</TabPane>
				</TabContent>
			</div >
		</>
	);
}
