import React from "react";
import {
	DOWNLOAD_FLYER_TEMPLETE,
	SELECT_FLYER_TEMPLETE,
} from "../helpers/globales";

export function ModalActions({
	handlePrevPage,
	downloadFlyerFunction,
	handleNextPage,
	pageType,
}) {
	const downloadPage = pageType === DOWNLOAD_FLYER_TEMPLETE;
	const templatePage = pageType === SELECT_FLYER_TEMPLETE;
	return (
		<div className="col-12 d-flex justify-content-end mt-3">
			{!templatePage && (
				<button
					className="btn px-4 text-blue mx-3"
					style={{ textDecoration: "underline", fontSize: 18 }}
					onClick={handlePrevPage}
				>
					prev
				</button>
			)}
			{downloadPage ? (
				<button className="btn bg-nxt px-4" onClick={downloadFlyerFunction}>
					Download
				</button>
			) : (
				<button className=" btn bg-nxt px-4" onClick={handleNextPage}>
					next
				</button>
			)}
		</div>
	);
}
