import NumberField from "components/Form/NumberField/NumberField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";
import validate, { isFormValid } from "helpers/validate";
// import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import { getAgentsLookup, sendToCompany } from 'services/operationStatement';
import { store } from 'react-notifications-component';
import { CopyIcon, WhatsAppIcon } from 'modules/UmrahOperations/shared/Icons';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';
import AddEditAgentForm from 'modules/UmrahOperations/Agents/AddEditForm';
import useAgentHook from 'modules/UmrahOperations/Agents/useAgentHook';
import { ReactComponent as ArrowLeftIcon } from "assets/images/Tds/arrow-left.svg";


const saudiFlagUUID = "f4e1fb09-cc9e-473a-828f-02fb764d8394";
const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";


export default function SendToCompanyModal({ isOpen, toggle, setReload, agentsList }) {
	const { operationStatement, productsBuilder, companyDetails, productElements } = Locale;
	const { allCountries, token } = useSBSState();

	const { agentData, addEditAgent, handleInputChanges, agentErrors } = useAgentHook();

	const isPreLogin = token ? false : true;
	const addNewObj = { id: "addnew", name: productsBuilder.addNew }

	// ** states 
	const [companyData, setCompanyData] = useState({
		agent_name: "",
		company_email: "",
		company_country: "",
		company_phone_code: {
			flag: saudiFlagUUID,
			label: "+966",
			value: "+966",
		},
		company_phone: "",
		operation_link: null,
		notes: ""
	});
	const [errors, setErrors] = useState();
	const [activeForm, setActiveForm] = useState("companyForm")
	const [hasSentToCompany, setHasSentToCompany] = useState(false);

	const [agentsLookup, setAgentsLookup] = useState(
		agentsList[0].id === "all" ? [...agentsList.slice(1), addNewObj] : [...agentsList, addNewObj]
	)

	async function fetchAgents() {
		const res = await getAgentsLookup();
		setAgentsLookup([...res?.data?.data, addNewObj]);
	}

	function checkFormErrors() {
		let submitError = {};
		Object.keys(companyData).forEach((key) => {
			submitError = {
				...submitError,
				...validate(
					{ name: key, value: companyData[key] },
					{
						required: ["company_phone", "operation_link", "notes"].includes(key) ? false : true,
						email: key === "company_email",
					}
				),
			};
		});
		setErrors(() => submitError);
		return submitError
	};

	async function sendToCompanyFn() {
		let formErrors = checkFormErrors();
		if (isFormValid(formErrors)) {
			let data =
				companyData?.agent_name?.value != null
					? {
						agent_id: companyData.agent_name.value,
						company_name: companyData.company_name,
						company_email: companyData.company_email,
						company_country_id: String(
							companyData.company_country?.country_id
						),
						company_phone_code: companyData.company_phone_code?.value,
						company_phone: companyData?.company_phone,
						notes: companyData.notes
					}
					: {
						company_name: companyData.agent_name?.label, //
						company_email: companyData.company_email,
						company_country_id: String(companyData.company_country?.value),
						company_phone_code: companyData.company_phone_code?.value,
						company_phone: companyData?.company_phone,
						notes: companyData.notes
					};

			const res = await sendToCompany(data);
			if (res?.status >= 200 && res?.status < 300) {
				store.addNotification({
					title: "info!",
					message: res?.data?.message ?? "Send To Company successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 2000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
				// toggle();
				setReload((prev) => !prev);
				setHasSentToCompany(true);
				let domain = window.location.protocol + "//" + window.location.host;
				let statementRefNumber = res?.data?.data?.reference_number;
				let operation_link = `${domain}/public/umrah-operations/${statementRefNumber}`;
				let whatsAppLink = `https://wa.me/${data.company_country_id}${data.company_phone}?text=${operation_link}`
				setCompanyData({ ...companyData, operation_link, whatsAppLink })
			}
		}
	};

	function handleCopyUrl() {
		navigator.clipboard.writeText(companyData.operation_link).then(() => {
			handleResponesNotification({ alertType: "success", message: "URL Copied" });
		});
	}

	function sendPublicLinkToWhatsapp() {
		if (companyData.whatsAppLink) {
			window.open(companyData.whatsAppLink, '_blank').focus();
			toggle()
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			size="lg"
			centered={true}
			hasFooter={false}
			className="operation-statement-numbers-model send-company-model operation-modal"
		>
			<ModalHeader toggle={toggle}>
				{activeForm === "companyForm" && operationStatement.SendToCompany}
				{activeForm === "agentForm" && operationStatement.addNewAgent}

			</ModalHeader>
			<ModalBody>

				{activeForm === "companyForm" && !hasSentToCompany &&
					<div className="container p-2 pb-4">
						<div className="row">
							{/* agent */}
							<div className="col-md-6 col-12 mb-2">
								<SelectField
									disabled={isPreLogin}
									label={operationStatement.AgentName}
									placeholder={operationStatement.TypeAgentName}
									options={agentsLookup}
									value={companyData.agent_name?.label || ""}
									onChange={(e) => {
										if (e.id === "addnew") {
											// hide current form and show agent form
											setActiveForm("agentForm")
										} else {
											setCompanyData({
												...companyData,
												agent_name: e,
												company_country: e?.country,
												company_email: e?.email,
												company_phone: e?.phone,
												company_phone_code: { value: e?.phone_code, label: e?.country?.label, flag: e?.country?.flag }
											})
											setErrors({
												...errors,
												...validate(
													{ name: "agent_name", value: e },
													{ required: true }
												),
											});
										}
									}}
									color={errors?.agent_name?.required ? "danger" : ""}
									errors={errors?.agent_name}
								/>
							</div>
							{/* company_country_id */}
							<div className="col-md-6 col-12">
								<SelectField
									label={operationStatement.Country}
									name={"company_country"}
									value={companyData.company_country?.label}
									onChange={(e) => {
										setCompanyData({
											...companyData,
											company_country: e,
											company_phone_code: { label: e?.phone_code, value: e?.phone_code, flag: e?.flag }
										});
										setErrors({
											...errors,
											...validate(
												{ name: "company_country", value: e },
												{ required: true }
											),
										});
									}}
									disabled={companyData?.agent_name?.value != null}
									options={allCountries}
									color={errors?.company_country?.required ? "danger" : ""}
									errors={errors?.company_country}
								/>
							</div>
						</div>

						<hr />
						<div className="row">
							{/* Email */}
							<div className="col-md-5 col-5">
								<TextField
									label={operationStatement.EmailAddress}
									type="email"
									name="company_email"
									value={companyData.company_email}
									placeholder={operationStatement.EmailAddress}
									onChange={(e) => {
										setCompanyData({
											...companyData,
											company_email: e.target.value,
										});
										setErrors({
											...errors,
											...validate(
												{ name: "company_email", value: e.target.value },
												{ required: true, email: true }
											),
										});
									}}
									color={errors?.company_email?.required || errors?.company_email?.email ? "danger" : ""}
									errors={errors?.company_email}
								/>
							</div>

							<div className="col-md-2 col-2 d-flex justify-content-center pt-2" style={{ maxHeight: " 50px" }}>
								<p className='or'>
									<span>{operationStatement.OR}</span>
								</p>
							</div>


							<div className="col-md-5 col-5">
								<div className="row mx-0">
									{/* phone code */}
									<div className="col-4 company_phone_code">
										<SelectField
											label={operationStatement.WhatsAppNumber}
											name={"company_phone_code"}
											value={
												companyData.company_phone_code?.flag ?
													<img src={`${fetchFlag}/${companyData.company_phone_code?.flag}`} width="30px" alt='' />
													:
													companyData.company_phone_code?.label
											}
											// value={data.company_phone_code?.label}
											onChange={(e) => {
												setCompanyData({
													...companyData,
													company_phone_code: e,
												});
											}}
											options={allCountries}
										/>
									</div>
									{/* phone */}
									<div className="col-8 px-0">
										<NumberField
											type={"phone"}
											name="company_phone"
											value={companyData.company_phone}
											removeArrow={true}
											placeholder={"000 0000 000"}
											min={3}
											onChange={(e) => {
												setCompanyData({
													...companyData,
													company_phone: e.target.value,
												});
											}}
											extraTextPosition="prepend"
											extraText={companyData.company_phone_code?.value}
										/>
									</div>
								</div>
							</div>
						</div>
						{/* notes */}
						<div className="col-12 mt-3 textarea-container">
							<label htmlFor="notes" className="control-field__label">{productElements.notes}</label>
							<textarea
								name="notes"
								id="notes"
								rows={5}
								className={"my-1"}
								value={companyData.notes}
								onChange={(e) => setCompanyData({ ...companyData, notes: e.target.value })}
							/>
						</div>

						<button
							className="btn mt-3 w-100"
							onClick={sendToCompanyFn}
							style={{
								backgroundColor: "#DAA249",
								fontSize: "18px",
								color: "#FFFFFF",
							}}
						>
							{operationStatement.Send}
						</button>
					</div>
				}

				{hasSentToCompany &&
					<SuccessMessage
						agentName={companyData.agent_name}
						handleCopyUrl={handleCopyUrl}
						sendPublicLinkToWhatsapp={sendPublicLinkToWhatsapp}
						operationPublicLink={companyData.operation_link}
					/>
				}

				{activeForm === "agentForm" && !hasSentToCompany &&
					<div className="pb-3">
						<AddEditAgentForm
							agentData={agentData}
							handleInputChanges={handleInputChanges}
							errors={agentErrors}
							isModal={true}
						/>
						<div className="d-flex align-items-center justify-content-between gap-10">
							<button className="btn save-btn back-btn w-100" onClick={() => setActiveForm("companyForm")}>
								<ArrowLeftIcon />
								<span className='mx-2'>{companyDetails.back}</span>
							</button>
							<button className="btn save-btn" onClick={() => {
								addEditAgent(() => {
									fetchAgents();
									setActiveForm("companyForm")
								})
							}}>
								{operationStatement.add}
							</button>
						</div>
					</div>

				}
			</ModalBody>
		</Modal>
	);
}


function SuccessMessage({ agentName, operationPublicLink, handleCopyUrl, sendPublicLinkToWhatsapp }) {

	const { operationStatement } = Locale;
	return (
		<div className="p-2 pb-4 text-center">
			<p className="font-weight-bold" style={{ color: "#101828", fontSize: "18px" }}>
				{operationStatement.operatingStatementSentSuccessfully} <span className="text-capitalize" style={{ color: "#D4A655" }}>{agentName?.label}</span>
			</p>
			<div className="d-flex align-items-start justify-content-center mt-3">
				<span style={{ color: "#667085" }}>{operationStatement.link}: </span>
				<span className="text-link mx-1">{operationPublicLink}</span>

				<button className="btn shadow-none p-0" title={operationStatement.copy} onClick={handleCopyUrl}>
					<CopyIcon color="#D4A655" />
				</button>
			</div>
			<p className="my-3">{operationStatement.OR}</p>

			<button className="btn send-whatsapp-btn d-flex align-items-center gap-10 mx-auto" onClick={sendPublicLinkToWhatsapp}>
				<WhatsAppIcon color="#fff" />
				{operationStatement.SendWhatsApp}
			</button>
		</div>
	)
}
