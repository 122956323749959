import { useVisaState } from "context/visa";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import VisaData from "./visaData";

export default function PrintVisa() {
	const { printData } = useVisaState();

	const [data, setData] = useState([]);
	let history = useHistory();

	useEffect(() => {
		if (printData?.length > 0) {
			setData(printData);
		}
	}, [printData]);


	const printRaw =
		data?.length > 0
			? data?.map((data, index) => <VisaData data={data} index={index} />)
			: null;

	// useEffect(() => {
	// 	setData(printData);
	// 	setTimeout(() => {
	// 		window.print();
	// 	}, 950);
	// }, []);
	const printDoc = () => {
		window.print();
	};

	return (
		<div className="container">
			<div className="print-header d-flex justify-content-between align-items-center p-2 bg-white">
				<h3 className="font-weight-bold">Print Visa</h3>
				<div>
					{data.length > 0 ? <button
						className="btn btn-blue-dark px-5 py-2 font-16"
						onClick={() => printDoc()}
					>
						Print
					</button> : null}
					<button
						className="btn btn-yellow px-4 mx-2  py-2 font-16"
						onClick={() => history.goBack()}
					>
						Back
					</button>
				</div>
			</div>
			<div className="print-visa ">{printRaw}</div>
		</div>
	);
}
