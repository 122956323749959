import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { uploadFile } from "services/auth";
import Locale from "translations";

export default function UploadTravellerFileModal(props) {
	const { operationStatement, inventory } = Locale;
	const { isOpen, toggle, data, setFileTraveller, fileTraveller,uploadFn } = props;
	const fetchFile = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
	const handleUpload = async (e) => {
		const file = e.target.files[0];
		let formData = new FormData();
		formData.append("name", file?.name);
		formData.append("bucket", "webBuilder");
		formData.append("file", file);
		const isImgSelected =
			e.target.files.length > 0 && formData !== undefined
				? await uploadFile(formData)
				: null;
		if (isImgSelected?.status === 200) {
			setFileTraveller({
				name: file?.name,
				uuid: isImgSelected.data.data.uuid,
				file: `${fetchFile}/${isImgSelected.data.data.uuid}`,
			});
		}
	};
	
	return (
		<Modal
			isOpen={isOpen}
			size="lg"
			centered={true}
			hasFooter={false}
			className="operation-statement-numbers-model send-company-model"
		>
			<ModalHeader toggle={toggle}>{operationStatement.uploadTraveler}</ModalHeader>
			<ModalBody>
				<div className="container p-2 pb-4">
					<div
						className={`p-3 d-flex justify-content-between align-items-center  flex-wrap position-relative upload-header-images upload-images `}
					>
						<div className="d-flex justify-content-center w-100">
							<label
								onChange={handleUpload}
								htmlFor="uploadFile"
								className=" text-black px-4 d-flex flex-column align-items-center"
							>
								{fileTraveller ? fileTraveller?.name :data?.travellers_file?`${fetchFile}/${data?.travellers_file}`:"" }
								<button className="btn btn-secondary">
									{fileTraveller ? operationStatement.replaceExcelFile : operationStatement.addExcelFile}
								</button>
							</label>
							<input
								multiple="multiple"
								name="header"
								onChange={(e) => {
									handleUpload(e);
								}}
								type="file"
								id="uploadFile"
								accept=".xls,.xlsx"
							/>
						</div>
					</div>
					<div className="d-flex justify-content-end  w-100 mt-2">
						{data?.travellers_file && (
							<button
								className="btn btn-nxt mx-2 text-light"
								onClick={() => {
									const anchor = document.createElement("a");
									anchor.target = "_blank";
									anchor.href=`${fetchFile}/${data?.travellers_file}`;
									anchor.setAttribute(
										"download",
										`${"Download File"}`
									);
									anchor.click();
									toggle();
								}}
							>
								{inventory.messages.download}
							</button>
						)}
						<button className="btn btn-nxt text-light"
						onClick={()=>uploadFn()}
						>{operationStatement.upload}</button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
}
