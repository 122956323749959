import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import SelectField from 'components/Form/SelectField/SelectField';
import { useSBSState } from 'context/global';
import validate, { isFormValid } from 'helpers/validate';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { fetchPorts, fetchTransporters } from 'services/lookups';
import { generateTripFromStatement } from 'services/operationStatement';
import Locale from 'translations'

const airTransportationId = 2;

export default function ConvertStatementToTripModal({ isOpen, toggle, statementId, setReload }) {
  const { operationStatement } = Locale;
  const { locale, companyInfo } = useSBSState();
  const location = useLocation()

  // ** states

  const [transportersList, setTransportersList] = useState([]);
  const [ports, setPorts] = useState([])

  const [tripData, setTripData] = useState({
    departure_port: null,
    return_port: null,
    transporter: null
  });
  const [errors, setErrors] = useState({});


  function checkFormErrors() {
    let submitError = {};
    Object.keys(tripData).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: tripData[key] },
          { required: true }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }
  // get air transporters list
  async function getTransportersList(inputValue) {
    if (inputValue.length > 2) {
      const transportersRes = await fetchTransporters(airTransportationId, inputValue);
      let transporters = transportersRes?.map((item) => {
        return {
          ...item,
          name: item.name?.[locale] || item.name,
          value: item.id,
          label: item.name?.[locale] || item.name,
        };
      });
      setTransportersList(transporters);
    }
  };

  async function handleconvertToTrip() {
    let formErrors = checkFormErrors();
    if (isFormValid(formErrors)) {
      let data = {
        departure_port_id: tripData.departure_port?.id,
        return_port_id: tripData?.return_port?.id,
        transporter_id: tripData.transporter?.id,
      }

      if (location.pathname.startsWith("/safa_offline")) {
				data.show_for = "offline"
			} else {
        data.show_for = null
      }


      const res = await generateTripFromStatement(statementId, data);
      if (res?.status === 200) {
        handleResponesNotification({ alertType: "success", message: "Statement converted to trip" });
        toggle();
        setReload((prev) => !prev);
      }
    }
  }


  // get ports based on compnay country 
  useEffect(() => {
    async function getFromPorts() {
      let countryId = companyInfo?.country?.id;
      let portsList = await fetchPorts(countryId, airTransportationId);
      setPorts(portsList);
    };
    if (companyInfo?.country?.id) {
      getFromPorts()
    }
  }, [companyInfo?.country?.id])


  return (
    <Modal isOpen={isOpen} centered className="operation-modal">
      <ModalHeader toggle={toggle}>
        {operationStatement.convertToTrip}
      </ModalHeader>
      <ModalBody>
        <div className="row mx-0">
          {/* Transporter */}
          <div className="col-6">
            <AutoCompleteField
              label={operationStatement.Transporter}
              placeholder={operationStatement.Transporter}
              id={`transporter`}
              name={`transporter`}
              isSearchable={true}
              listAuto={transportersList}
              setListAuto={setTransportersList}
              getListAuto={getTransportersList}
              value={tripData?.transporter?.name || ""}
              onChange={(e) => {
                setTripData({ ...tripData, transporter: { name: e } })
              }}
              onSelectValue={(e) => {
                setTripData({ ...tripData, transporter: { id: e?.value, name: e?.label } })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "transporter", value: e.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.transporter?.required ? "danger" : ""}
              errors={errors?.transporter}
            />
          </div>
        </div>
        <div className="row mx-0">

          {/* Departure from port */}
          <div className="col-md-6 col-12">
            <SelectField
              label={operationStatement.departureFromPort}
              placeholder={operationStatement.departureFromPort}
              id={`departure_port`}
              name={`departure_port`}
              options={ports}
              value={tripData?.departure_port?.name || ""}
              onChange={(e) => {
                setTripData({ ...tripData, departure_port: e });
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "departure_port", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.departure_port?.required ? "danger" : ""}
              errors={errors?.departure_port}
            />
          </div>

          {/* Return from port */}
          <div className="col-md-6 col-12">
            <SelectField
              label={operationStatement.returnFromPort}
              placeholder={operationStatement.returnFromPort}
              id={`return_port`}
              name={`return_port`}
              options={ports}
              value={tripData?.return_port?.name || ""}
              onChange={(e) => {
                setTripData({ ...tripData, return_port: e });
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "return_port", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.return_port?.required ? "danger" : ""}
              errors={errors?.return_port}
            />
          </div>
        </div>


        <button className="btn bg-nxt py-2 w-100 mt-3" onClick={handleconvertToTrip}>
          {operationStatement.convertToTrip}
        </button>

      </ModalBody>
    </Modal>
  )
}
