import LocationMarkerIcon from 'modules/WebBuilder-V2/shared/icons/LocationMarker';
import StarIcon from 'modules/WebBuilder-V2/shared/icons/Star';
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import ArrowRightIcon from 'modules/WebBuilder-V2/shared/icons/ArrowRight';
import usePreviewMode from 'hooks/usePreviewMode';
import Locale from 'translations';
import { useWebBuilderState } from 'context/webBuilder';

export default function ThemeSevenHotelCard(props) {
  let { openEditModal, name, price, image, currency, stars, product_uuid, initialName, locationName, city, index } = props;
  const { isPreview } = usePreviewMode();
  const { webBuilder } = Locale;
  const { selectedLanguage } = useWebBuilderState();


  return (
    <div className="theme_hotel_card">
      <div className="image">
        {/* <a href="/">
          <img src="https://back-sbs.staging.safa.asia/api/v1/files/fetch/1847cef6-e5c0-40bb-b1dd-d2dc7827cd58"
            alt="Safa Visa" />
        </a> */}

        <div className={`service-img`}>
          {!product_uuid && !isPreview ? (
            <button
              className="btn add-service-btn"
              onClick={() => openEditModal({ ...props, index })}
            >
              <AddIcon />
            </button>
          ) : null}

          {image ? (
            <img src={image} alt="Safa Visa" />
          ) : (
            <img src="https://back-sbs.staging.safa.asia/api/v1/files/fetch/1847cef6-e5c0-40bb-b1dd-d2dc7827cd58"
              alt="Safa Visa" />
          )}
        </div>

      </div>
      <div className="content">
        <div className="location">
          <p className='destination_name'>
            <LocationMarkerIcon />
            <span>{locationName?.[selectedLanguage] || locationName}</span>
            {city &&
              <>
                <span className='mx-1'>/</span>
                <span>{city}</span>
              </>
            }
          </p>
        </div>
        <div className="package_name">
          <h5 className='package_title'>
            {/* <a href="/"> */}
              {name || initialName}
            {/* </a> */}
          </h5>
          <div className="package_rating">
            {[...Array(5)].map((i, index) =>
              index < stars ? (
                <StarIcon fill="#F5B950" width="16" height="14" />
              ) : (
                <StarIcon fill="#DDDCDC" width="16" height="14" />
              )
            )}
          </div>
        </div>
        <div className="package_footer">
          <div className="pricing">
            <p className="from_text">{webBuilder.from}</p>
            <p className="actual_price">{price} <span>{currency}</span></p>
          </div>
          <div className="cta">
            <button className='theme_btn'>
              <span className="btn_content">
                <ArrowRightIcon className="mirror-rtl" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}