import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import validate from "helpers/validate";
import useFetchCountries from "hooks/useFetchCountries";
import moment from "moment";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Locale from "translations";

export default function DetailsTab(props) {
	const [countries] = useFetchCountries();
	const dispatch = useDispatch();
	const history = useHistory();
	const { reservation } = Locale;

	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	//Store
	const reservationApp = useSelector((state) => state.reservation_reducer);

	const [traveler, setTraveler] = useState({
		passportNo: reservationApp.passportNo ?? "",
		nationalId: reservationApp.nationalId ?? "",
		firstNameEn: reservationApp.firstNameEn ?? "",
		fatherNameEn: reservationApp.fatherNameEn ?? "",
		grandFatherNameEn: reservationApp.grandFatherNameEn ?? "",
		lastNameEn: reservationApp.lastNameEn ?? "",
		national: reservationApp.national ?? [],
		gender: reservationApp.gender ?? [],
		issuedPlace: reservationApp.issuedPlace ?? "",
		jobTitle: reservationApp.jobTitle ?? "",
		address: reservationApp.address ?? "",
		relationMuharram: reservationApp.relationMuharram ?? [],
		muharramName: reservationApp.muharramName ?? "",
		firstNameAr: reservationApp.firstNameAr ?? "",
		fatherNameAr: reservationApp.fatherNameAr ?? "",
		grandFatherNameAr: reservationApp.grandFatherNameAr ?? "",
		lastNameAr: reservationApp.lastNameAr ?? "",
		visaNo: reservationApp.visaNo ?? "",
		visaType: reservationApp.visaType ?? "",
		consular: reservationApp.consular ?? [],
		passportPhoto: reservationApp.passportPhoto ?? "",
	});

	const [dateBirth, setDateBirth] = useState(
		reservationApp.dateBirth ?? moment()
	);
	const [issuedDate, setIssuedDate] = useState(
		reservationApp.issuedDate ?? moment()
	);
	const [expiryDate, setExpiryDate] = useState(
		reservationApp.expiryDate ?? moment()
	);

	const [passportPhoto, setPassportPhoto] = useState(
		reservationApp.passportPhoto ?? ""
	);

	//PassportImage
	const inputFileRef = useRef(null);

	// On File Change
	const onFilechange = (e) => {
		setPassportPhoto(window.URL.createObjectURL(e.target.files[0]));
	};

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "passportNo", value: traveler.passportNo },
				{ required: true }
			),
			...validate(
				{ name: "nationalId", value: traveler.nationalId },
				{ required: true }
			),
			...validate(
				{ name: "firstNameEn", value: traveler.firstNameEn },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "fatherNameEn", value: traveler.firstNameEn },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "grandFatherNameEn", value: traveler.grandFatherNameEn },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "lastNameEn", value: traveler.lastNameEn },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "national", value: traveler.national },
				{ required: true }
			),
			...validate(
				{ name: "gender", value: traveler.gender },
				{ required: true }
			),
			...validate(
				{ name: "issuedPlace", value: traveler.issuedPlace },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "jobTitle", value: traveler.jobTitle },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "address", value: traveler.address },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "relationMuharram", value: traveler.relationMuharram },
				{ required: true }
			),
			...validate(
				{ name: "muharramName", value: traveler.muharramName },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "firstNameAr", value: traveler.firstNameAr },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "fatherNameAr", value: traveler.fatherNameAr },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "grandFatherNameAr", value: traveler.grandFatherNameAr },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "lastNameAr", value: traveler.lastNameAr },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "visaNo", value: traveler.visaNo },
				{ required: true }
			),
			...validate(
				{ name: "visaType", value: traveler.visaType },
				{ required: true }
			),
			...validate(
				{ name: "consular", value: traveler.consular },
				{ required: true }
			),
		});
	};

	//New Validation
	const handleCreate = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
		alert(`
			passportNo => ${traveler.passportNo}
			NationalId => ${traveler.nationalId}
			FirstNameEn => ${traveler.firstNameEn}
			FatherNameEn => ${traveler.fatherNameEn}
			GrandFatherNameEn => ${traveler.grandFatherNameEn}
			LastNameEn	=> ${traveler.lastNameEn}
			Nationality => ${traveler.national.label}
			Gender => ${traveler.gender?.label}
			DateOfBirth => ${traveler.dateBirth}
			IssuedDate => ${traveler.issuedDate}
			ExpiryDate => ${traveler.expiryDate}
			IssuedPlace => ${traveler.issuedPlace}
			JobTitle => ${traveler.jobTitle}
			Address => ${traveler.address}
			RelationToMuharram => ${traveler.relationToMuharram?.label}
			MuharramName => ${traveler.muharramName}
			FirstNameAr => ${traveler.firstNameAr}
			FatherNameAr => ${traveler.fatherNameAr}
			GrandFatherNameAr => ${traveler.grandFatherNameAr}
			LastNameAr => ${traveler.lastNameAr}
			VisaNo => ${traveler.visaNo}
			VisaType => ${traveler.visaType.label}
			Consular => ${traveler.consular.label}
		`);
	};

	// 

	return (
		<>
			{/* Passport Number */}
			<Row className="m-0 mb-2">
				<Col sm="3">
					<div className="form-group full-input">
						<TextField
							placeholder={reservation.messages.passportNoPlaceholder}
							label={reservation.messages.passportNo}
							value={traveler.passportNo}
							name="passportNo"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
								setTraveler({
									...traveler,
									passportNo: e.target.value
										.replace(/[^\d]/g, "")
										.replace(/[^\w\s]/gi, "")
										.replace(/^0/, ""),
								});
							}}
							errors={errors.passportNo}
							color={errors?.passportNo?.required ? "danger" : ""}
						/>
					</div>
				</Col>
				<Col sm="3">
					<div className="form-group full-input">
						<TextField
							placeholder={reservation.messages.nationalIdPlaceholder}
							label={reservation.messages.nationalId}
							value={traveler.nationalId}
							name="nationalId"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
								setTraveler({
									...traveler,
									nationalId: e.target.value
										.replace(/[^\d]/g, "")
										.replace(/[^\w\s]/gi, "")
										.replace(/^0/, ""),
								});
							}}
							errors={errors.nationalId}
							color={errors?.nationalId?.required ? "danger" : ""}
						/>
					</div>
				</Col>
			</Row>

			{/* English Name */}
			<h2 className="px-2 font-weight-bold">
				{reservation.messages.englishName}
			</h2>
			<Row className="m-0 mb-2">
				<Col sm="3">
					<div className="form-group full-input">
						<TextField
							placeholder={reservation.messages.firstNamePlaceholder}
							label={reservation.messages.firstName}
							value={traveler.firstNameEn}
							name="firstNameEn"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
								setTraveler({
									...traveler,
									firstNameEn: e.target.value,
								});
							}}
							min={3}
							errors={errors.firstNameEn}
							color={errors?.firstNameEn?.required ? "danger" : ""}
						/>
					</div>
				</Col>
				<Col sm="3">
					<div className="form-group full-input">
						<TextField
							placeholder={reservation.messages.fatherNamePlaceholder}
							label={reservation.messages.fatherName}
							value={traveler.fatherNameEn}
							name="fatherNameEn"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
								setTraveler({
									...traveler,
									fatherNameEn: e.target.value,
								});
							}}
							min={3}
							errors={errors.fatherNameEn}
							color={errors?.fatherNameEn?.required ? "danger" : ""}
						/>
					</div>
				</Col>
				<Col sm="3">
					<div className="form-group full-input">
						<TextField
							placeholder={reservation.messages.grandFatherNamePlaceholder}
							label={reservation.messages.grandFatherName}
							value={traveler.grandFatherNameEn}
							name="grandFatherNameEn"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
								setTraveler({
									...traveler,
									grandFatherNameEn: e.target.value,
								});
							}}
							min={3}
							errors={errors.grandFatherNameEn}
							color={errors?.grandFatherNameEn?.required ? "danger" : ""}
						/>
					</div>
				</Col>
				<Col sm="3">
					<div className="form-group full-input">
						<TextField
							placeholder={reservation.messages.lastNamePlaceholder}
							label={reservation.messages.lastName}
							value={traveler.lastNameEn}
							name="lastNameEn"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
								setTraveler({
									...traveler,
									lastNameEn: e.target.value,
								});
							}}
							min={3}
							errors={errors.lastNameEn}
							color={errors?.lastNameEn?.required ? "danger" : ""}
						/>
					</div>
				</Col>
			</Row>

			{/* Traveler Details */}
			<h2 className="px-2 font-weight-bold">
				{reservation.messages.travelerDetails}
			</h2>
			<div className="traveler-row">
				<div className="traveler-col">
					<div className="form-group full-input">
						<SelectField
							label={reservation.messages.nationality}
							defaultValue="Select Nationality"
							options={countries}
							value={traveler.national?.label}
							name="national"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{ name: "national", value: e.value },
										{ required: true }
									),
								});
								setTraveler({
									...traveler,
									national: e,
								});
							}}
							errors={errors.national}
							color={errors?.national?.required ? "danger" : ""}
						/>
					</div>
				</div>
				<div className="traveler-col">
					<div className="form-group full-input">
						<SelectField
							label={reservation.messages.gender}
							defaultValue="Select Gender"
							options={countries}
							value={traveler.gender?.label}
							name="gender"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{ name: "gender", value: e.value },
										{ required: true }
									),
								});
								setTraveler({
									...traveler,
									gender: e,
								});
							}}
							errors={errors.gender}
							color={errors?.gender?.required ? "danger" : ""}
						/>
					</div>
				</div>
				<div className="traveler-col">
					<div className="form-group full-input">
						<DatePickerField
							placeholder="DD/MM/YYY"
							label={reservation.messages.dateOfBirth}
							name="dateBirth"
							date={dateBirth}
							onChangeDate={setDateBirth}
							minDate={dateBirth}
						/>
					</div>
				</div>
				<div className="traveler-col">
					<div className="form-group full-input">
						<DatePickerField
							placeholder="DD/MM/YYY"
							label={reservation.messages.issuedDate}
							name="issuedDate"
							date={issuedDate}
							onChangeDate={setIssuedDate}
							minDate={issuedDate}
						/>
					</div>
				</div>
				<div className="traveler-col">
					<div className="form-group full-input">
						<div className="form-group full-input">
							<DatePickerField
								placeholder="DD/MM/YYY"
								label={reservation.messages.expiryDate}
								name="expiryDate"
								date={expiryDate}
								onChangeDate={setExpiryDate}
								minDate={expiryDate}
							/>
						</div>
					</div>
				</div>
				<div className="traveler-col">
					<div className="form-group full-input">
						<TextField
							placeholder={reservation.messages.issuedDatePlaceholder}
							label={reservation.messages.issuedPlace}
							value={traveler.issuedPlace}
							name="issuedPlace"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
								setTraveler({
									...traveler,
									issuedPlace: e.target.value,
								});
							}}
							onBlur={() => {
								setErrors({
									...errors,
									...validate(
										{
											name: "issuedPlace",
											value: traveler.issuedPlace,
										},
										{ required: true }
									),
								});
							}}
							min={3}
							errors={errors.issuedPlace}
							color={errors?.issuedPlace?.required ? "danger" : ""}
						/>
					</div>
				</div>
				<div className="traveler-col">
					<div className="form-group full-input">
						<TextField
							placeholder="Type"
							label={reservation.messages.jobTitle}
							value={traveler.jobTitle}
							name="jobTitle"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
								setTraveler({
									...traveler,
									jobTitle: e.target.value,
								});
							}}
							min={3}
							errors={errors.jobTitle}
							color={errors?.jobTitle?.required ? "danger" : ""}
						/>
					</div>
				</div>
				<div className="traveler-col">
					<div className="form-group full-input">
						<TextField
							placeholder="Type Address"
							label={reservation.messages.address}
							value={traveler.address}
							name="address"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
								setTraveler({
									...traveler,
									address: e.target.value,
								});
							}}
							min={3}
							errors={errors.address}
							color={errors?.address?.required ? "danger" : ""}
						/>
					</div>
				</div>
				<div className="traveler-col">
					<div className="form-group full-input">
						<SelectField
							label={reservation.messages.relationToMuharram}
							defaultValue="Select"
							options={countries}
							value={traveler.relationMuharram?.label}
							name="relationMuharram"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{ name: "relationMuharram", value: e.value },
										{ required: true }
									),
								});
								setTraveler({
									...traveler,
									relationMuharram: e,
								});
							}}
							errors={errors.relationMuharram}
							color={errors?.relationMuharram?.required ? "danger" : ""}
						/>
					</div>
				</div>
				<div className="traveler-col">
					<div className="form-group full-input">
						<TextField
							placeholder={reservation.messages.muharramNamePlaceholder}
							label={reservation.messages.muharramName}
							value={traveler.muharramName}
							name="muharramName"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
								setTraveler({
									...traveler,
									muharramName: e.target.value,
								});
							}}
							min={3}
							errors={errors.muharramName}
							color={errors?.muharramName?.required ? "danger" : ""}
						/>
					</div>
				</div>
			</div>

			{/* Arabic Name */}
			<h2 className="px-2 font-weight-bold">
				{reservation.messages.arabicName}
			</h2>
			<div className="traveler-row">
				<div className="traveler-col">
					<div className="form-group full-input">
						<TextField
							placeholder={reservation.messages.firstNamePlaceholder}
							label={reservation.messages.firstName}
							value={traveler.firstNameAr}
							name="firstNameAr"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
								setTraveler({
									...traveler,
									firstNameAr: e.target.value,
								});
							}}
							min={3}
							errors={errors.firstNameAr}
							color={
								errors?.firstNameAr?.required || errors.firstNameAr?.min
									? "danger"
									: ""
							}
						/>
					</div>
				</div>
				<div className="traveler-col">
					<div className="form-group full-input">
						<div className="form-group full-input">
							<TextField
								placeholder={reservation.messages.fatherNamePlaceholder}
								label={reservation.messages.fatherName}
								value={traveler.fatherNameAr}
								name="fatherNameAr"
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(e.target, {
											required: true,
											min: 3,
										}),
									});
									setTraveler({
										...traveler,
										fatherNameAr: e.target.value,
									});
								}}
								min={3}
								errors={errors.fatherNameAr}
								color={errors?.fatherNameAr?.required ? "danger" : ""}
							/>
						</div>
					</div>
				</div>
				<div className="traveler-col">
					<div className="form-group full-input">
						<TextField
							placeholder={reservation.messages.grandFatherName}
							label={reservation.messages.grandFatherNamePlaceholder}
							value={traveler.grandFatherNameAr}
							name="grandFatherNameAr"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
								setTraveler({ ...traveler, grandFatherNameAr: e.target.value });
							}}
							min={3}
							errors={errors.grandFatherNameAr}
							color={errors?.grandFatherNameAr?.required ? "danger" : ""}
						/>
					</div>
				</div>
				<div className="traveler-col">
					<div className="form-group full-input">
						<TextField
							placeholder={reservation.messages.lastNamePlaceholder}
							label={reservation.messages.lastName}
							value={traveler.lastNameAr}
							name="lastNameAr"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
								setTraveler({
									...traveler,
									lastNameAr: e.target.value,
								});
							}}
							min={3}
							errors={errors?.lastNameAr}
							color={errors?.lastNameAr?.required ? "danger" : ""}
						/>
					</div>
				</div>
			</div>

			{/* Visa Details */}
			<h2 className="px-2 font-weight-bold">{reservation.messages.lastName}</h2>
			<div className="traveler-row">
				<div className="traveler-col">
					<div className="form-group full-input">
						<TextField
							placeholder={reservation.messages.visaNoPlaceholder}
							label={reservation.messages.visaNo}
							value={traveler.visaNo}
							name="visaNo"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
								setTraveler({
									...traveler,
									visaNo: e.target.value
										.replace(/[^\d]/g, "")
										.replace(/[^\w\s]/gi, "")
										.replace(/^0/, ""),
								});
							}}
							errors={errors.visaNo}
							color={errors?.visaNo?.required ? "danger" : ""}
						/>
					</div>
				</div>
				<div className="traveler-col">
					<div className="form-group full-input">
						<SelectField
							label={reservation.messages.visaType}
							defaultValue="Select Visa Type"
							options={countries}
							value={traveler.visaType?.label}
							name="visaType"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{ name: "visaType", value: e.value },
										{ required: true }
									),
								});
								setTraveler({
									...traveler,
									visaType: e,
								});
							}}
							errors={errors.visaType}
							color={errors.visaType?.required ? "danger" : ""}
						/>
					</div>
				</div>
				<div className="traveler-col">
					<div className="form-group full-input">
						<SelectField
							label={reservation.messages.consular}
							defaultValue="Select"
							options={countries}
							value={traveler.consular?.label}
							name="consular"
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{ name: "consular", value: e.value },
										{ required: true }
									),
								});
								setTraveler({
									...traveler,
									consular: e,
								});
							}}
							errors={errors.consular}
							color={errors.consular?.required ? "danger" : ""}
						/>
					</div>
				</div>
			</div>

			{/* Passport Photo */}
			<h2 className="px-2 font-weight-bold">
				{reservation.messages.passportPhoto}
			</h2>
			<div className="traveler-row align-items-center">
				<div className="traveler-col">
					<label htmlFor="passportUpload" className="passport-upload">
						<div className="icon">
							{passportPhoto ? (
								<img
									src={passportPhoto}
									className="image-uploader__img w-100 rounded-0"
									alt="logo placeholder"
									accept="image/*"
								/>
							) : (
								<i className="fa fa-plus"></i>
							)}
						</div>
					</label>
				</div>
				<div className="traveler-col">
					<input
						type="file"
						id="passportUpload"
						className="d-none"
						ref={inputFileRef}
						onChange={onFilechange}
					/>
					<label
						htmlFor="passportUpload"
						className="btn btn-primary"
						ref={inputFileRef}
					>
						{reservation.messages.uploadPassport}
					</label>
				</div>
			</div>

			<div className="save-buttons d-flex flex-row-reverse py-4">
				<button className="btn btn-primary px-5 mx-1" onClick={handleCreate}>
					{reservation.messages.saveChange}
				</button>
				<button
					className="btn btn-default mx-1 border"
					onClick={() => history.push("../reservation")}
				>
					{reservation.messages.cancle}
				</button>
			</div>
		</>
	);
}
