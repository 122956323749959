import SelectField from "components/Form/SelectField/SelectField";
import { useSBSState } from "context/global";
import { useMarketplaceState } from "context/marketplace";
import { useEffect, useState } from "react";
import { getGroupPassengers, OnlineVisaGroups } from "services/VisaOnline";
import Locale from "translations";
import CustomPagination from "./pagination";

export default function AddPassengers({ addSelectedPassangers, checkedIds, setCheckedIds, }) {
	const { onlineVisa } = Locale;
	const [groupsLookups, setGroupsLookups] = useState([]);
	const [tags, setTags] = useState([]);
	const tokenSafaVisa = localStorage.getItem("tokenSafaVisa");
	const [passenger, setPassenger] = useState([]);
	const { locale } = useSBSState();
	const [ListOfChecked, setListOfChecked] = useState([]);
	const { groundServiceSearch } = useMarketplaceState();
	const [originalPassangers, setOriginalPassangers] = useState([]);
	const [ListDisabled, setListDisabled] = useState([...checkedIds]);
	const [searchKeys, setSearchKeys] = useState({
		group: null,
		tag: null,
	});

	const handelCheck = (passenger, flag) => {
		if (flag) {
			setListOfChecked([
				...ListOfChecked,
				{
					...passenger,
					full_name_ar: passenger.name_ar,
					full_name_en: passenger.name_en,
					expire_date: passenger.passport_expire_date,
					date_of_birth: passenger.birth_date,
					photo: passenger.photo ?? "",
					first_name_ar: "",
					first_name_en: "",
					father_name_ar: "",
					father_name_en: "",
					grandfather_name_ar: "",
					grandfather_name_en: "",
					last_name_ar: "",
					last_name_en: "",
					national_id: "",
					title: "",
					passport_type: "",
					marital_status: "",
					educational_level: "",
					country: "",
					birth_country: "",
					birth_place: "",
					issue_country: "",
					issue_place: "",
					issue_date: "",
					job: "",
					address: "",
					mrz: "",
				},
			]);
			setListDisabled([...ListDisabled, passenger.id]);
		} else {
			setListOfChecked([
				...ListOfChecked.filter((item) => item.id != passenger.id),
			]);
			setListDisabled([...ListDisabled.filter((item) => item != passenger.id)]);
		}
	};

	const handelCheckAll = (flag) => {
		if (flag) {
			setListOfChecked([
				...originalPassangers.map((item) => {
					return {
						...item,
						full_name_ar: item.name_ar,
						full_name_en: item.name_en,
						expire_date: item.passport_expire_date,
						date_of_birth: item.birth_date,
						photo: item.photo ?? "",
						first_name_ar: "",
						first_name_en: "",
						father_name_ar: "",
						father_name_en: "",
						grandfather_name_ar: "",
						grandfather_name_en: "",
						last_name_ar: "",
						last_name_en: "",
						national_id: "",
						title: "",
						passport_type: "",
						marital_status: "",
						educational_level: "",
						country: "",
						birth_country: "",
						birth_place: "",
						issue_country: "",
						issue_place: "",
						issue_date: "",
						job: "",
						address: "",
						mrz: "",
						issue_country_label: ""
					};
				}),
			]);
			setListDisabled(originalPassangers.map((item) => item.id));
		} else {
			setListOfChecked([]);
			setListDisabled([]);
		}
	};

	useEffect(() => {
		const fetchGroups = async () => {
			const response = await OnlineVisaGroups({ token: tokenSafaVisa });
			if (response.status === 200) {
				const formattedGroup = response.data.data.map((item) => {
					return {
						id: item.id,
						name: item.group_name,
					};
				});
				const formattedTags = response.data.data.map((item) => {
					return {
						id: item.id,
						name: item.tag,
					};
				});

				setGroupsLookups(formattedGroup);
				setTags(formattedTags);
			}
		};
		fetchGroups();
	}, []);

	const sumbit = async () => {
		const respones = await getGroupPassengers(
			{ token: tokenSafaVisa },
			searchKeys.group ? searchKeys.group.value : searchKeys.tag.value
		);

		if (respones.status === 200) {
			let filterData = respones.data.data.members.filter(
				(item) => !checkedIds.includes(item.id)
			);
			setPassenger(filterData);
			setOriginalPassangers(filterData);

		}
	};

	const insertPassengers = async () => {
		addSelectedPassangers(ListOfChecked);
		let ids = ListOfChecked.map((item) => item.id);
		setCheckedIds([...checkedIds, ...ids]);
	};

	const allPassengers =
		passenger.length > 0 ? (
			passenger.map((passenger) => {
				return (
					<tr key={passenger.id}>
						<td className="d-flex align-items-center">
							<input
								type="checkbox"
								checked={ListDisabled.includes(passenger.id)}
								className="mx-1 insert-group-checkbox "
								disabled={
									ListDisabled.includes(passenger.id)
										? false
										: groundServiceSearch?.pax === ListDisabled?.length.toString()
								}
								onChange={(e) => {
									handelCheck(passenger, e.target.checked);
								}}
							/>
							{passenger.passport_number}
						</td>
						<td>{locale === "en" ? passenger.name_en : passenger.name_ar}</td>
						<td>{passenger.gender}</td>
						<td>{passenger.birth_date}</td>
						<td>{passenger.age}</td>
						<td>{passenger.relationship[locale]}</td>
						{/* <td>{passenger.national_id}</td> */}
						<td>{passenger.nationality}</td>
					</tr>
				);
			})
		) : (
			<tr>
				<td colSpan={8} className="text-center text-gray">
					No Result
				</td>
			</tr>
		);

	return (
		<div className="">
			{groundServiceSearch?.pax.toString() !== checkedIds.length.toString() ? (
				<div className="  d-flex justify-content-center align-items-center pb-3">
					<div className="col-3">
						<SelectField
							label={onlineVisa.GroupName}
							options={groupsLookups}
							value={searchKeys?.group?.label}
							disabled={searchKeys.tag}
							onChange={(e) => {
								setSearchKeys({
									...searchKeys,
									group: e,
								});
							}}
						/>
					</div>

					<div className="col-3">
						<SelectField
							label={onlineVisa.tag}
							options={tags}
							value={searchKeys?.tag?.label}
							disabled={searchKeys.group}
							onChange={(e) => {
								setSearchKeys({
									...searchKeys,
									tag: e,
								});
							}}
						/>
					</div>

					<div className="mt-4">
						<button
							className="insert-group-search btn text-white onlineVisa-clear-bottom px-3 "
							onClick={sumbit}
							disabled={!searchKeys.group && !searchKeys.tag}
						>
							{onlineVisa.search}
						</button>
					</div>

					<div className="mt-4 mx-3 text-center">
						<p className="m-0">Available</p>
						<p className="m-0">
							(
							<span className="text-success font-weight-bold">
								{Number(groundServiceSearch.pax) - +ListDisabled.length}{" "}
							</span>{" "}
							/{" "}
							<span className="text-danger font-weight-bold">
								{groundServiceSearch.pax}
							</span>{" "}
							)
						</p>
					</div>
				</div>
			) : (
				<p className="text-center p-5 text-gray h4">
					You have completed your pax
				</p>
			)}

			{passenger.length > 0 ? (
				<>
					<div>
						<table class="table-update p-2 bg-white  table table-striped">
							<thead>
								<tr>
									<th>
										{groundServiceSearch.pax - +ListDisabled.length >=
											originalPassangers.length ? (
											<input
												type="checkbox"
												disabled={
													groundServiceSearch?.pax === checkedIds.length.toString()
												}
												onChange={(e) => {
													handelCheckAll(e.target.checked);
												}}
												className="mx-1 insert-group-checkbox "
											/>
										) : null}
										{onlineVisa.PassportNumber}
									</th>
									<th>{onlineVisa.Name}</th>
									<th>{onlineVisa.Gender}</th>
									<th>
										<i class="fas fa-sort"></i>
										{onlineVisa.Birthday}
									</th>
									<th>{onlineVisa.Age}</th>
									<th>{onlineVisa.Relationship}</th>
									{/* <th>{onlineVisa.NationalId}</th> */}
									<th>{onlineVisa.Nationality}</th>
								</tr>
							</thead>
							<tbody>{allPassengers}</tbody>
						</table>
					</div>

					{/* pagination */}
					<CustomPagination
						perPage={10}
						data={passenger}
						setData={setPassenger}
					/>

					<div className="d-flex justify-content-end ">
						<button className="btn bg-nxt m-1" onClick={insertPassengers}>
							{onlineVisa.INSERTSELECTED}
						</button>
					</div>
				</>
			) : (
				groundServiceSearch?.pax > checkedIds.length.toString() && (
					<p className="text-center p-5 text-gray h4">No Result</p>
				)
			)}
		</div>
	);
}
