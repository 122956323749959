import React from "react";
import { ReactComponent as SearchIcon } from "assets/images/hotelAccommodation/searchnormal1.svg";
import { useProductsBuilderState } from "context/productsBuilder";

function Search({ travelerFilter, handleTravelerFilter }) {
	const {
		hotel_accommodation_travelers: travelers,
		selected_hotel_accommodation_id: selectedHotelId,
	} = useProductsBuilderState();

	const remainTravelers = travelers.reduce((prev, current) => {
		const roomId = current.roomAvailabilities.find(
			(room) => room.product_hotel_id === selectedHotelId
		)?.roomId;
		if (!roomId) {
			return prev + 1;
		} else {
			return prev;
		}
	}, 0);
	const travelersLength = travelers?.length ?? 0;
	return (
		<div className="travelers-search">
			<div className="travelers-search-head">
				<p>Accommodated Travelers</p>
				<p>{`${remainTravelers}/${travelersLength}`}</p>
			</div>
			<div className="traveler-search-input">
				<SearchIcon className="search-icon" />
				<input
					type="text"
					name="seaech"
					placeholder="Search by name"
					onChange={(e) => handleTravelerFilter(e.target.value)}
          value={travelerFilter}
				/>
			</div>
		</div>
	);
}

export default Search;
