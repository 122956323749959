import React, { useEffect } from "react";
import FlightSideStep from "./shared/SideSteps";
import PassengersSelection from "./shared/PassengersSelection";
import ContactInformation from "./shared/ContactInformation";
import { useSBSState } from "context/global";
import { useState } from "react";
import Locale from "translations";
import validate, { isFormValid } from "helpers/validate";
import { createVoidRequest, viewflight } from "services/inventory";
import {
	useHistory,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { store } from "react-notifications-component";

export default function FlightVoid() {
	const { inventory, marketPlace } = Locale;
	const { allCountries } = useSBSState();
	const { id } = useParams();
	let history = useHistory();
	const countries = allCountries
		? allCountries?.map((country) => {
				return { ...country, label: country?.name };
		  })
		: null;
	const [flightDetails, setFlightDetails] = useState({});
	const [passengersList, setPassengersList] = useState([]);
	const [contactInfoData, setContactInfoData] = useState({
		name: "",
		email: "",
		phoneCode: "",
		phone: "",
	});
	const [errors, setErrors] = useState({});
	function checkFormErrors() {
		let submitError = {};
		Object.keys(contactInfoData).forEach((key) => {
			submitError = {
				...submitError,
				...validate(
					{ name: key, value: contactInfoData[key] },
					{
						required: true,
						email: key === "email",
						min: key === "phone" ? 9 : "",
						max: key === "phone" ? 13 : "",
					}
				),
			};
		});
		setErrors(() => submitError);
	}

	useEffect(() => {
		const fetchFlights = async () => {
			const res = await viewflight(id);
			if (res.status === 200) {
				const data = res.data.data;
				setFlightDetails(data);
				setPassengersList(data.passengers);
			}
		};
		fetchFlights();
		return () => {};
	}, []);

	async function submitVoidRequest() {
		checkFormErrors();
		if (isFormValid(errors)) {
			let requestBody = {
				flight_booking_id: flightDetails.id,
				contact_info: {
					full_name: contactInfoData?.name,
					email: contactInfoData?.email,
					phone_code: contactInfoData?.phoneCode?.phone_code,
					phone: contactInfoData?.phone,
				},
				passengers: passengersList
					?.filter((item) => item.selected)
					?.map((item) => item.id),
				// passengers: passengersList
				// 	?.filter((item) => item.selected === true)
				// 	?.map((item) => ({
				// 		flight_passenger_id: item.id,
				// 		passenger_info: {
				// 			full_name: item.firstName +" " + item.lastName,
				// 			type: item.psgType,
				// 		},
				// 	})),
			};
			const response = await createVoidRequest(requestBody);
			if (response?.status >= 200 && response?.status < 300) {
				store.addNotification({
					title: "Create Void Request",
					message: "Create Void Request Successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						history.push(`/inventory/flight/reservation/${id}/requests`);
					},
				});
			}
		}
	}
	return (
		<div className="container flight-actions">
			<div className="row">
				<div className="col-12">
					{/* action title */}
					<h1 className="flight-actions-title">
						{inventory.messages.void}
						<span>
							{inventory.messages.order} {flightDetails.air_order}
						</span>
					</h1>
					<div className="col-md-9 col-12 p-0">
						<div className="flight-action-content">
							{/* passengers list */}
							<div className="d-flex position-relative my-2">
								<FlightSideStep step={"1"} />
								<PassengersSelection
									passengersList={passengersList}
									setPassengersList={setPassengersList}
								/>
							</div>
							{/* contact information */}
							<div className="d-flex position-relative my-2">
								<FlightSideStep step={"2"} />
								<ContactInformation
									countries={countries}
									contactInfoData={contactInfoData}
									setContactInfoData={setContactInfoData}
									errors={errors}
									setErrors={setErrors}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-end mx-md-4 mx-1">
							<button
								className="btn flight-checkout-btn"
								onClick={submitVoidRequest}
								disabled={
									passengersList.filter((passenger) => passenger.selected)
										.length === 0
								}
							>
								{marketPlace.submit}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
