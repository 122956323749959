import React, { useEffect, useState } from "react";
import Locale from "translations";
import CustomModal from "./Modal";
import location from "assets/images/transfer/location.svg";
import date from "assets/images/transfer/date.svg";
import man from "assets/images/transfer/man.svg";
import arrow from "assets/images/transfer/arrow.svg";
import bin from "assets/images/transfer/bin.svg";
import { useHistory, useParams } from "react-router-dom";
import cart from "assets/images/transfer/cart.svg";
import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import { deleteFromTransferCart, showTransferCart } from "services/marketplace";
import moment from "moment";
import { TransferDetails } from "./TransferDetails";
import { Collapse } from "reactstrap";

function CartModal() {
	const { marketPlace } = Locale;
	const history = useHistory();
	const { status } = useParams()
	const [modalState, setModalState] = useState(false);
	const [moreDetailsCollapse, setMoreDetailsCollapse] = useState([]);

	const { transferCart } = useMarketplaceState();
	const dispatch = useMarketplaceDispatch();

	const toggleModal = () => setModalState((prev) => !prev);

	async function fetchCartDetails() {
		const res = await showTransferCart();

		if (res.status === 200) {
			dispatch({
				type: "transferCart",
				payload: res.data,
			});
		}
		if (
			res.status === 400 &&
			res.data?.custom_error_code === "TRANSFER-CRT-001"
		) {
			dispatch({
				type: "transferCart",
				payload: [],
			});
		}
	}

	useEffect(() => {
		fetchCartDetails();

		return () => {};
	}, []);

	const deleteFromCart = async (id) => {
		const res = await deleteFromTransferCart(id);
		if (res.status === 200) {
			dispatch({
				type: "transferCart",
				payload: res.data,
			});
		}
		if (
			res.status === 400 &&
			res.data?.custom_error_code === "TRANSFER-CRT-001"
		) {
			dispatch({
				type: "transferCart",
				payload: [],
			});
		}
	};

	const cartData = transferCart?.cart;
	const totalPrice = cartData?.cartItems?.reduce(
		(prev, current) => (prev = prev + current.details?.price),
		0
	);
	const cartLength = cartData?.cartItems?.length;
	const formatter = new Intl.NumberFormat('en-US');

	return (
		<>
			<button
				className="btn transfer-cart"
				onClick={toggleModal}
				disabled={!transferCart?.cart?.cartItems?.length}
			>
				{transferCart?.cart?.cartItems?.length && (
					<div className="badge">{transferCart?.cart?.cartItems?.length}</div>
				)}
				<img src={cart} alt="cart" />
			</button>
			<CustomModal
				modalIsOpen={modalState}
				header={marketPlace.yourCart}
				toggle={toggleModal}
				size="lg"
				centered={true}
			>
				<div className="transfer-cart-model">
					{cartLength > 0 &&
						cartData?.cartItems?.map((cartItem, index) => (
							<div key={cartItem.id} className="w-100 d-flex flex-wrap flex-column flex-md-row">
								<div
									style={{ height: 112, width: 112, minHeight: 112 }}
									className="border"
								>
									<img
										alt="Transfer"
										src={cartItem?.image}
										height="100%"
										className="img-fluid"
									/>
								</div>

								<div
									style={{ textAlign: "start" }}
									className="ms-3  me-2 flex-grow-1 d-flex flex-column"
								>
									<div className="flex-grow-1 d-flex justify-content-between">
										<div>
											<span className="font-weight-bold text-dark-blue h5">
												{cartItem?.type}{" "}
											</span>
											<span className="text-dark-blue">
												{cartItem?.category}{" "}
											</span>
											<span className="h6 text-uppercase text-muted">
												{cartItem?.vehicle}{" "}
											</span>
										</div>
										<p className="text-issued">
											({`${cartItem?.price} ${cartItem?.currency}`})
										</p>
									</div>

									<div>
										<img alt="Transfer" width="18px" src={location} />
										<span className="ms-1">
											{cartItem?.from_name}
											<img
												alt="arrow"
												width="14px"
												className="mx-2"
												src={arrow}
											/>
											{cartItem?.to_name}
										</span>
									</div>
									<div>
										<img alt="Transfer" width="14px" src={date} />
										<span className="ms-2 font-weight-bold">
											{`${moment(cartItem?.pickup_time).format(
												"DD/MM/YYYY"
											)}  (${moment(cartItem?.pickup_time).format("dddd")})`}
										</span>
									</div>
									<div>
										<img alt="Transfer" width="14px" src={man} />
										<span className="ms-2 font-weight-bold">
											{`${cartItem?.trip_type} , ${cartItem?.adults} ${
												marketPlace.adult
											} , ${cartItem?.children} ${
												marketPlace.messages.child
											}, ${
												cartItem?.infant
													? cartItem?.infant + " " + marketPlace.infant
													: ""
											} `}
										</span>
									</div>
								</div>

								<button
									className="btn p-2 rounded-0 bin justify-content-center mt-md-0 mt-2"
									onClick={() => deleteFromCart(cartItem.id)}
								>
									<img alt="Transfer" src={bin} />
								</button>
								<div className="col-12 mt-3 border-bottom d-flex justify-content-end flex-wrap">
									<button
										className="btn mb-2 text-primary"
										onClick={() =>
											setMoreDetailsCollapse((prev) => {
												const clone = [...prev];
												clone[index] = !clone[index];
												return clone;
											})
										}
									>
										{marketPlace.messages.viewMoreDetails}
										<i style={{marginInlineStart:8}}
											className={`fas ${
												moreDetailsCollapse[index]
													? "fa-chevron-up "
													: "fa-chevron-down"
											} `}
										></i>
									</button>
									<Collapse isOpen={moreDetailsCollapse[index]}>
										{moreDetailsCollapse[index]&&<TransferDetails details={cartItem} />}
									</Collapse>
								</div>
								{index !== cartLength - 1 && <hr className="w-100" />}
							</div>
						))}

					{cartLength > 0 && (
						<div className="total-cost">
							<p>{marketPlace.ReservationTotalCost}</p>
							<p className="font-weight-bold text-issued h5 m-0">
								({`${cartData?.price&&cartData?.price>0 ? formatter.format(cartData?.price):null} ${cartData?.currency}`})
							</p>
						</div>
					)}

					<div className="row m-0 mt-3 justify-content-between ">
						<div className="col-md-5 col-12">
							<button
								className="btn w-100 continue-btn px-0 font-weight-bold"
								type="button"
								onClick={() => toggleModal()}
							>
								<p className="d-flex align-items-center justify-content-center">
									{marketPlace.messages.continueSearch}
								</p>
							</button>
						</div>
						<div className="col-md-7 col-12 mt-md-0 mt-2">
							<button
								className="btn w-100 bg-nxt px-0 font-weight-bold"
								type="button"
								disabled={!cartLength}
								onClick={() => history.push(`/${status}/transfer/reservations`)}
							>
								<p className="d-flex align-items-center justify-content-center">
									{marketPlace.messages.checkOutPay}
								</p>
							</button>
						</div>
					</div>
				</div>
			</CustomModal>
		</>
	);
}

export default CartModal;
