import transactionColored from "assets/images/wallet/transaction.svg";
import transactionGray from "assets/images/wallet/transactionGray.svg";

import bankTransferColortImg from "assets/images/wallet/bankTransferColor.svg";
import requestGray from "assets/images/wallet/requestGray.svg";
import requstColored from "assets/images/wallet/requstColored.svg";
import classnames from "classnames";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { uploadFile } from "services/auth";
import {
	getBankDetails,
	getWalletTransactions,
	payFromBankTransfer,
	payFromSafa,
	rechargeWallet,
} from "services/wallet";
import Locale from "translations";
import validator from "validator";
import Requests from "./lists/requests/requests";
import Transaction from "./lists/transaction/transaction";
import BankModal from "./modal/bankModal";
import RechargeModal from "./modal/rechargeModal";
import SendErrRequestModal from "./modal/sendErrRequestModal";
import SendRequestModal from "./modal/sendRequestModal";
import TransferModal from "./modal/transferModal";
import ShowForPermission from "helpers/showForPermission";

const WalletTables = ({ id, wallet, walletsList, getWallets, currency }) => {
	const { payment } = Locale;
	let history = useHistory();
	const { tab } = useParams();

	const [actionType, setActionType] = useState('');
  const [formData, setFormData] = useState({});

	const [modalRecharge, setModalRecharge] = useState(false);
	const toggleModalRecharge = () => {
		setActionType('')
		setFormData({})
		setModalRecharge(!modalRecharge)};

	const [modalTransfer, setModalTransfer] = useState(false);
	const toggleModalTransfer = () => setModalTransfer(!modalTransfer);

	const [modalSendRequest, setModalSendRequest] = useState(false);
	const toggleModalSendRequest = () => setModalSendRequest(!modalSendRequest);

	const [modalErrSendRequest, setModalErrSendRequest] = useState(false);
	const toggleModalErrSendRequest = () =>
		setModalErrSendRequest(!modalErrSendRequest);

	const [modalBank, setModalBank] = useState(false);
	const toggleModalBank = () => setModalBank(!modalBank);

	const [bankDetails, setbankDetails] = useState([]);
	/******** Modal API ********** */
	//getBankDetails
	async function bankInfo() {
		const res = await getBankDetails(wallet?.currency);
		if (res.status === 200 || res.status === 201) {
			setbankDetails(res?.data?.data);
			toggleModalBank(modalBank);
		}
	}

	/**Start transfer wallet from safa */
	const [amount, setAmount] = useState(null);
	async function transferWallet() {
		const res = await payFromSafa({
			amount: +amount,
			currency: wallet?.currency,
			//description: "edf333",
		});
		if (res.status === 200 || res.status === 201) {
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1100,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			getWallets();
			setAmount(null);
			setTimeout(() => {
				toggleModalSendRequest(modalSendRequest);
			}, 2000);
		} else {
			setResponseBankTransfer(res);
			setTimeout(() => {
				toggleModalErrSendRequest(modalErrSendRequest);
			}, 100);
		}
	}
	const submit = () => {
		if (validator.isNumeric(`${amount}`, ["ar-EG"])) {
			transferWallet();
		} else {
			store.addNotification({
				title: "info!",
				message: "Amount should be Number",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	/**End Transfer Wallet from safa */

	/**Start Bank Transer  */
	const [bankAmount, setBankAmount] = useState(null);
	const [image, setImage] = useState();
	const [responseBankTransfer, setResponseBankTransfer] = useState();
	async function bankTranfer() {
		const formData1 = new FormData();
		formData1.append("file", image);
		formData1.append("name", image?.name);
		formData1.append("bucket", "companiesLogo");
		const response = await uploadFile(formData1);
		if (response.status === 200) {
			const formData = new FormData();
			formData.append("amount", bankAmount);
			formData.append("photo", response.data.data.uuid);
			formData.append("currency", wallet?.currency);

			const res = await payFromBankTransfer(formData);
			if (res?.status === 200 || res?.status === 201) {
				store.addNotification({
					title: "info!",
					message: res.data.message,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1100,
						onScreen: true,
						pauseOnHover: true,
					},
				});

				toggleModalRecharge(null);
				setBankAmount(null);
				setImage(null);

				setTimeout(() => {
					toggleModalSendRequest(modalSendRequest);
				}, 2000);
			} else {
				toggleModalRecharge(null);
				setResponseBankTransfer(res);
				setTimeout(() => {
					toggleModalErrSendRequest(modalErrSendRequest);
				}, 2000);
			}
		}
	}
	/**End Bank Transfer */

	/**Start Credit/Master Card */
	const [creditAmount, setCreditAmount] = useState();
	async function AddDebit() {
		const res = await rechargeWallet({
			amount: creditAmount,
			payment_card_id: id.toString(),
		});
		if (res.status === 200 || res.status === 201) {
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					history.push("/wallet");
				},
			});
			toggleModalRecharge(null);
			setTimeout(() => {
				toggleModalSendRequest(modalSendRequest);
			}, 200);
		}else if (res?.status === 202){
			setActionType(res?.data?.actionType)
			setFormData(res?.data?.data?.formData)

		}
	}
	/**End Credit/Master Card  */

	/**Transaction list */
	const [transactionList, setTransactionList] = useState([]);
	useEffect(() => {
		let id = wallet?.id;
		async function fetchTransaction() {
			const res = await getWalletTransactions(id);
			setTransactionList(res?.data?.data.slice(0, 3));
		}
		fetchTransaction();
	}, []);
	const [activeTab, setActiveTab] = useState("1");

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	useEffect(() => {
		switch (tab) {
			case "transactions":
				setActiveTab("1");
				break;
			case "requests":
				setActiveTab("2");
				break;

			default:
				setActiveTab("1");
				history.push("/wallet/transactions");
				break;
		}
	}, [activeTab, tab]);

	return (
		<div>
			{/* <Transaction id={id} />
			{wallet?.type !== "credit" ? <Requests id={id} /> : ""} */}
			<div className="wallet-tabs interest " id="walletTabContentRef">
				<div className="tab-icons  main-nav-sbs">
					<Nav tabs className="d-flex align-items-center ">
						<div className="d-flex w-50">
							<NavItem className="bg-white">
								<NavLink
									className={classnames({ active: activeTab === "1" })}
									onClick={() => {
										toggle("1");
										history.push("/wallet/transactions");
									}}
								>
									<div className="product-build-list-item wallet-transaction-tab d-flex px-5">
										<img
											src={
												activeTab === "1" ? transactionColored : transactionGray
											}
											alt="transaction"
										/>
										<span className="text mx-1">
											{payment.messages.transactions}
										</span>
									</div>
								</NavLink>
							</NavItem>
							{wallet?.type !== "credit" ? (
								<NavItem className="bg-white">
									<NavLink
										className={classnames({ active: activeTab === "2" })}
										onClick={() => {
											toggle("2");
											history.push("/wallet/requests");
										}}
									>
										<div className="product-build-list-item">
											<img
												src={activeTab === "2" ? requstColored : requestGray}
												alt="request"
											/>
											<span className="text mx-1">
												{payment.messages.requests}
											</span>{" "}
										</div>
									</NavLink>
								</NavItem>
							) : null}
						</div>

						<div className="ml-auto mx-2 w-50 wallet-pay-buttons">
							{wallet?.type === "credit" ? (
								<button
									className="btn btn-success w-100 font-18 px-4"
									onClick={(e) => {
										toggleModalTransfer(modalTransfer);
									}}
								>
									{payment.messages.payWallet}
								</button>
							) : (
								<div className="d-flex recharge-buttons">
									<button
										className="btn btn-link w-100 font-18 btn-bank d-flex align-items-center justify-content-center"
										onClick={() => {
											bankInfo();
										}}
									>
										<img
											src={bankTransferColortImg}
											alt=""
											width={19}
											height={22}
											className=""
										/>
										{payment.messages.safaBankDetails}
									</button>
								
									<ShowForPermission permission="Manage-Wallets">
										<button
											className="btn bg-nxt w-100 font-18 "
											onClick={(e) => {
												toggleModalRecharge(modalRecharge);
											}}
										>
											{payment.messages.rechargeWallet}
										</button>
									</ShowForPermission>
								</div>
							)}
						</div>
					</Nav>
				</div>

				<div className="tab-content product-build__tabs-content">
					<TabContent
						activeTab={activeTab}
						className=" pt-2 pb-4   bg-white responsive-wallet-tap"
					>
						<TabPane tabId="1" className="m-0">
							<Transaction id={id} currency={currency} />
						</TabPane>
						<TabPane tabId="2" className="m-0">
							{wallet?.type !== "credit" && activeTab === "2" ? (
								<Requests id={id} currency={currency} />
							) : (
								""
							)}
						</TabPane>
					</TabContent>
				</div>
			</div>
			{/****Start Recharge Model  */}
			{modalRecharge ? (
				<RechargeModal
					modalRecharge={modalRecharge}
					currency={currency}
					toggleModalRecharge={toggleModalRecharge}
					modalSendRequest={modalSendRequest}
					toggleModalSendRequest={toggleModalSendRequest}
					data={wallet}
					bankTranfer={bankTranfer}
					bankAmount={bankAmount}
					setBankAmount={setBankAmount}
					image={image}
					setImage={setImage}
					creditAmount={creditAmount}
					actionType={actionType}
					formData={formData}
					setCreditAmount={setCreditAmount}
					AddDebit={AddDebit}
				/>
			) : null}
			{/****End Recharge Model  */}
			{/****Start Transfer Model  */}
			{modalTransfer ? (
				<TransferModal
					walletsList={walletsList}
					modalTransfer={modalTransfer}
					toggleModalTransfer={toggleModalTransfer}
					modalSendRequest={modalSendRequest}
					toggleModalSendRequest={toggleModalSendRequest}
					data={wallet}
					submit={submit}
					amount={amount}
					setAmount={setAmount}
				/>
			) : null}
			{/****End Transfer Model  */}
			{/****Start Send Request Model  */}
			<SendRequestModal
				modalSendRequest={modalSendRequest}
				toggleModalSendRequest={toggleModalSendRequest}
				data={wallet}
			/>
			<SendErrRequestModal
				modalErrSendRequest={modalErrSendRequest}
				toggleModalErrSendRequest={toggleModalErrSendRequest}
				data={wallet}
				responseBankTransfer={responseBankTransfer}
			/>
			{/****Start Bank Model  */}
			{modalBank ? (
				<BankModal
					modalBank={modalBank}
					toggleModalBank={toggleModalBank}
					bankDetails={bankDetails}
				/>
			) : null}
			{/****End Bank Model  */}
		</div>
	);
};

export default WalletTables;
