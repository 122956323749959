import React, { useEffect, useState } from 'react'
import ServicePricing from '../ServicePricing';
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import Locale from 'translations';
import ShowForPermission from 'helpers/showForPermission';

export default function AccountingOtherServicesItem({ isPricePerService, otherServiceItem, handleInputsChanges, deleteItemPricing, index, canEdit }) {
  const { operationStatement } = Locale;
  const [isPriceTableShown, setIsPriceTableShown] = useState(false);

  // show price table if there is price
  useEffect(() => {
    if (
      (otherServiceItem?.pricing?.buying_price && otherServiceItem?.pricing?.buying_price > 0) ||
      (otherServiceItem?.pricing?.selling_price && otherServiceItem?.pricing?.selling_price > 0)
    ) {
      setIsPriceTableShown(true)
    }
  }, []);

  return (
    <>
      <div className="attraction-item">
        <h4 className='header-title'>{otherServiceItem?.name}</h4>
        <div className="content">
          <p className='d-flex align-items-center flex-wrap col-md-6 col-12 mt-2 p-0'>
            <span className='col-md-5 col-12 p-0 label'>{operationStatement.Pax}</span>
            <span className='col-md-7 col-12 p-0'>{otherServiceItem?.pax}</span>
          </p>
        </div>
      </div>

      {isPricePerService && !isPriceTableShown && canEdit ?
        <ShowForPermission permission='Manage-operation-accounting'>
          <button className="btn-plus-operation p-0 align-self-baseline"
            onClick={() => setIsPriceTableShown(true)}
          >
            <span>
              <i className="fas fa-plus"></i>
            </span>
            {operationStatement.AddBuyingSelling}
          </button>
        </ShowForPermission>
        :
        null
      }

      {isPricePerService && isPriceTableShown ?
        <div className='mt-4'>
          <ServicePricing
            type={operationStatement.Pax}
            typeCount={otherServiceItem?.pax}
            buyingPrice={otherServiceItem?.pricing?.buying_price}
            sellingPrice={otherServiceItem?.pricing?.selling_price}
            itemIndex={index}
            handleInputsChanges={handleInputsChanges}
            canEdit={canEdit}
          />
          {canEdit &&
            <ShowForPermission permission='Manage-operation-accounting'>
              <button className="btn delete-pricing-btn"
                onClick={() => {
                  deleteItemPricing(index);
                  setIsPriceTableShown(false)
                }}
              >
                <DeleteIcon />
                <span>{operationStatement.DeleteBuyingSelling}</span>
              </button>
            </ShowForPermission>
          }
        </div>
        :
        null
      }
    </>
  )
}
