import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

function CustomModal({
	isOpen,
	toggle,
	header,
	hideHeader = false,
	children,
	...restProps
}) {
	return (
		<Modal
			{...restProps}
			onClick={(e) => e.stopPropagation()}
			isOpen={isOpen}
			centered
			className='web-builder-custom-modal'
		>
			{hideHeader ? null : (
				<ModalHeader toggle={toggle} className='web-builder-custom-modal-title'>
					{header}
				</ModalHeader>
			)}
			<ModalBody className={`${hideHeader && 'p-0'}`}>{children}</ModalBody>
		</Modal>
	)
}

export default CustomModal
