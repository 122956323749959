import Vector1 from "assets/images/welcome-lp/vector-1.svg"
import Vector2 from "assets/images/welcome-lp/vector-2.svg"
import Shape from "assets/images/welcome-lp/shape.svg"
import Share from "assets/images/welcome-lp/share.svg"
import Groups from "assets/images/welcome-lp/groups.svg"
import Trip from "assets/images/welcome-lp/trip.svg"
// import Plane from "../../assets/images/welcome-lp/plane2.png"
// import Play from "../../assets/images/welcome-lp/play.svg"
// import Video1 from "../../assets/images/welcome-lp/video1.png"
// import Video2 from "../../assets/images/welcome-lp/video2.png"
import ArrowRight from "./icons/ArrowRight"
import { useLocation, Link } from "react-router-dom"
import Locale from 'translations'

export default function LandingPage() {
  const { visa } = Locale;
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const redirectParam = searchParams.get("redirect");

  return (

    <section className='welcome_lp_container'>
      <img src={Vector1} alt="safa live" className="shape_abstract_1" />
      <img src={Vector2} alt="safa live" className="shape_abstract_2" />
      {/* <img src={Plane} alt="Safa Visa" className='plane' /> */}
      <img src="" alt="" />
      <div className="container">
        <div className="lp_banner_container">
          <div className="row">
            <div className="col-lg-6 d-flex justify-content-start align-items-center mb-lg-0 mb-5">
              <div className="text_wrap">
                <h1>{visa.welcome}</h1>
                <div className="desc">
                  <p>{visa.subscriptionUpgradedSafaLive}</p>
                </div>
                <div className="banner_cta">
                  <Link to={redirectParam || "/dashboard"} className='lp_cta_btn'>
                    <span className="text_layer">
                      {visa.skipIntro}
                      <span className='mx-1'></span>
                      <ArrowRight className="mirror-rtl" />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center">
              <div className="banner_shapes_container">
                <div className="shape">
                  <span className="bg" style={{
                    backgroundImage: `url(${Shape})`
                  }}></span>
                  <div className="icon">
                    <img src={Groups} alt="Safa Live Groups" />
                  </div>
                  <p className="txt">{visa.groups}</p>
                </div>
                <div className="shape">
                  <span className="bg" style={{
                    backgroundImage: `url(${Shape})`
                  }}></span>
                  <div className="icon">
                    <img src={Trip} alt="Safa Live Trips" />
                  </div>
                  <p className="txt">{visa.tripsManagement}</p>
                </div>
                <div className="shape">
                  <span className="bg" style={{
                    backgroundImage: `url(${Shape})`
                  }}></span>
                  <div className="icon">
                    <img src={Share} alt="Safa Live Operations" />
                  </div>
                  <p className="txt">{visa.operation}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="howto_use_section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="heading">
                  <h2>How To Use</h2>
                </div>
              </div>
            </div>
            <div className="inner_content">
              <div className="row">
                <div className="col-lg-4">
                  <div className="video_card">
                    <div className="image">
                      <img className="play_icon" src={Play} alt="Safa Visa" />
                      <img className="main_img" src={Video1} alt="Safa Live Video" />
                    </div>
                    <div className="content">
                      <div className="meta">
                        <p>1 Min Watch</p>
                      </div>
                      <div className="card_title">
                        <h4>Add & Manage Groups</h4>
                      </div>
                      <div className="card_cta">
                        <a href=".">
                          Start Now
                          <ArrowRight width="35" height="14" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>

  )
}