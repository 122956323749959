import React from 'react'
import Locale from 'translations'

export default function TDSVisaSuccessfulPayment({ visaData }) {
  const { tdsVisa } = Locale;

  return (
    <div className='p-3 base-border d-flex flex-column align-items-center text-center px-5'>
      <h6 className='font-weight-bold mb-3'>{tdsVisa.thankYou} 😇</h6>
      <p className='my-1 font-grey-400'>{tdsVisa.applicationPlaced}</p>
      <p className='my-1 font-grey-400'>
        {tdsVisa.sentEmailTo}
        {" "}<span className='font-weight-bold font-grey-900'>{visaData?.contact_info?.email || visaData?.email}</span> {" "}
        {tdsVisa.applicationConfirmation}
      </p>
      <p className='my-1 font-grey-400'>
        {tdsVisa.emailHasNotArrived}
      </p>
      {/* <p className='my-1'>Time placed: 25/05/2023 13:35pm</p> */}
    </div>
  )
}
