import EditableParagraph from "modules/WebBuilder/shared/EditableParagraph";
import ToggleCheckbox from "modules/WebBuilder/shared/ToggleCheckbox";
import UploadFileComponent from "modules/WebBuilder/shared/UploadFile";
import React from "react";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import { useLocation } from 'react-router-dom';

function ServiceCard({ icon, title, header, body, type, visible, editCardDetails, serviceType }) {
	const location = useLocation();
	const preview = !location.pathname.includes('edit');
	const isTransfer = serviceType === "transfer"

	const logoImage = icon ? <img src={icon} alt="Logo" /> : null;
	const logo = preview ? (
		logoImage
	) : (
		<UploadFileComponent
			icon={AddIcon}
			id={`${type}-service`}
			label=""
			onUpload={(value) => editCardDetails(value, "icon")}
			image={logoImage}
			disabled={isTransfer}
		/>
	);
	return (
		<div className={`web-builder-content-our-service_card`} >
			{isTransfer ? <span className="soon">soon</span> : null}
			<div className={`${isTransfer ? "opacity-50 user-select-none pe-defualt" : ""}`}>
				<div className="our-service-icon">
					{logo}
				</div>
				<div>
					<EditableParagraph
						className="service-header h5 mb-1"
						contentEditable={!isTransfer}
						onInput={(value) => {
							editCardDetails(value, "header");
						}}
						text={header || title || ""}
					/>
					<p className="service-body text-secondary-color">
						{type?.toLocaleUpperCase()}
					</p>

					<EditableParagraph
						className="my-3 text-secondary-color"
						contentEditable={!isTransfer}
						onInput={(value) => {
							editCardDetails(value, "body");
						}}
						text={body}
					/>
				</div>

				{!preview ? <ToggleCheckbox
					checked={visible}
					onChange={(e) => {
						const value = e.target.checked;
						editCardDetails(value, "visible");
					}}
					disabled={isTransfer}
					label="Visible"
				/> : null}
			</div>
		</div>
	);
}

export default ServiceCard;
