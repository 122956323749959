import React from 'react'
import AccountingAttractionItem from './AttractionItem';
import ServicePricing from '../ServicePricing';
import Locale from 'translations';


export default function AccountingAttractions({ isPricePerService, accountingStatement, setAccountingStatement, attractionPricingType, canEdit }) {
  const { operationStatement } = Locale;
  const isPriceForAll = isPricePerService && attractionPricingType === "all";

  function handleInputsChanges(value, keyName, index) {
    let accountingStatementClone = { ...accountingStatement };
    if (!isPriceForAll) {
      let attractionsPricesClone = [...accountingStatementClone?.attractions];
      let attractionItem = attractionsPricesClone[index];
      attractionItem = {
        ...attractionItem,
        pricing: {
          ...attractionItem?.pricing,
          [keyName]: (+value).toString(),
        }
      }
      attractionsPricesClone[index] = attractionItem;
      accountingStatementClone = { ...accountingStatementClone, attractions: attractionsPricesClone };
    } else {
      accountingStatementClone = {
        ...accountingStatementClone,
        per_all_attraction_pricing: {
          ...accountingStatement?.per_all_attraction_pricing,
          [keyName]: (+value).toString(),
        }
      }
    }
    setAccountingStatement(accountingStatementClone);
  }
  
  function deleteItemPricing(serviceIndex) {
    let accountingStatementClone = { ...accountingStatement }
    let attractionsClone = [...accountingStatementClone?.attractions];
    let selectedService = { ...attractionsClone?.[serviceIndex], pricing: null };
    attractionsClone[serviceIndex] = selectedService;
    accountingStatementClone = { ...accountingStatementClone, attractions: attractionsClone };
    setAccountingStatement(accountingStatementClone);
  }

  function changeAttractionPriceType(value) {
    let accountingStatementClone = { ...accountingStatement };
    let attractionsClone = [...accountingStatementClone?.attractions].map(attraction=> ({...attraction, pricing: null}));
    let attractionPriceType = value;
    accountingStatementClone = { 
      ...accountingStatementClone, 
      attractions: attractionsClone, 
      attraction_pricing: attractionPriceType,
      per_all_attraction_pricing: null
    };
    setAccountingStatement(accountingStatementClone);
  }

  return (
    <div className='accounting-item attractions  py-0'>

      {isPricePerService &&
        <div className='radio-group-container pricing-type'>
          <div className='d-flex flex-wrap align-items-center'>
            <div className='item'>
              <input
                type="radio"
                id={"one"}
                name={"one"}
                checked={attractionPricingType === "one"}
                onChange={(e) => changeAttractionPriceType(e.target.name)}
                disabled={!canEdit}
              />
              <span className='checkmark'></span>
              <label htmlFor="one" className="user-select-none">{operationStatement.Pricingperone}</label>
            </div>

            <div className='item'>
              <input
                type="radio"
                id={"all"}
                name={"all"}
                checked={attractionPricingType === "all"}
                onChange={(e) => changeAttractionPriceType(e.target.name)}
                disabled={!canEdit}
              />
              <span className='checkmark'></span>
              <label htmlFor="all" className="user-select-none">{operationStatement.Pricingperall}</label>
            </div>
          </div>
        </div>
      }

      <div className="d-flex flex-column gap-10">
        {accountingStatement?.attractions?.map((attractionItem, index) => {
          return (
            <div className="accounting-item-wrapper" key={`attraction-${attractionItem?.id}-${index}`}>
              <AccountingAttractionItem
                index={index}
                item={attractionItem}
                isPricePerService={isPricePerService}
                attractionPricingType={attractionPricingType}
                handleInputsChanges={handleInputsChanges}
                deleteItemPricing={deleteItemPricing}
                canEdit={canEdit}
              />
            </div>
          )
        })}

        {/* price for all attractions */}
        {isPriceForAll &&
          <ServicePricing
            handleInputsChanges={handleInputsChanges}
            sellingPrice={accountingStatement?.per_all_attraction_pricing?.selling_price}
            buyingPrice={accountingStatement?.per_all_attraction_pricing?.buying_price}
            canEdit={canEdit}
          />
        }
      </div>
    </div>
  )
}
