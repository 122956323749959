import React, { useEffect, useMemo, useState } from "react";
import PublicPageHeader from "./PublicPageHeader";
import HeroHeader from "../Components/Content/Hero/Header";
import { useParams } from "react-router-dom";
import FLightView from "./Flights";
import {
	getPackage,
	getPageBuilder,
	getPageBuilderByCompany,
} from "services/webBuilder";
import { itineraryElementsIds } from "modules/market-place/NewPackageResponse/helpers/formatServicesItems";
// import { useSBSState } from "context/global";
import Locale from "translations";
import PackageAttractionsView from "modules/market-place/NewPackageResponse/Details/AttractionsView";
import PackageHotelView from "modules/market-place/NewPackageResponse/Details/HotelView";
// import Company from "modules/market-place/NewPackageResponse/Details/shared/company";
import ProductItinerary from "modules/market-place/NewPackageResponse/Details/ProductItinerary";
import { ReactComponent as RequiredDocIcon } from "assets/images/market-place/required-document-text.svg";
import { ReactComponent as CheckMarkCircleIcon } from "assets/images/market-place/tick-circle.svg";
import { ReactComponent as StopIcon } from "assets/images/market-place/stop-icon.svg";
import { ReactComponent as ArrowIcon } from "assets/images/webBuilder/arrowright.svg";
// import { ReactComponent as DollarCircleIcon } from "assets/images/market-place/dollar-circle.svg";
import { useWebBuilderDispatch } from "context/webBuilder";
import { changeBorder, changeColorTheme, changeFont } from "../helpers/styles";
import initialData from "../page.json";
import WebBuilderPackagePricing from './PackagePricing';
import WebBuilderRequestForm from './RequestForm';

function PreviewServices() {
	const { service, id, name } = useParams();
	const [packageData, setPackageData] = useState({});
	const { packages, productsBuilder, inventory } = Locale;
	const itineraryId = "tab-101";
	const [activeTab, setActiveTab] = useState(itineraryId);
	const dispatch = useWebBuilderDispatch();
	const [selectedRoomType, setSelectedRoomType] = useState(null);

	const [packageTerms, setPackageTerms] = useState("");
	const shortTermsAndCondition = packageData?.share_terms?.substring(0, 300)

	const updateStyle = ({ name, value }) => {
		dispatch({
			type: "updateStyle",
			payload: {
				name,
				value,
			},
		});
	};
	const getPageDetails = async () => {
		let res;
		if (!name) {
			res = await getPageBuilder();
		} else {
			res = await getPageBuilderByCompany(name);
		}

		if (res.status === 200) {
			const pageBuilderData = res.data.page_builder_config ?? initialData;
			dispatch({
				type: "setInitialData",
				payload: pageBuilderData,
			});
			const style = pageBuilderData?.style;

			changeColorTheme(style?.color, updateStyle);
			changeBorder(style?.rounded, updateStyle);
			changeFont(style?.font, updateStyle);
		}
	};

	useEffect(() => {
		getPageDetails();
		window.scrollTo(0, 0);
		return () => { };
	}, []);

	useEffect(() => {
		if (service) {
			async function getDataView() {
				const res = await getPackage(id);
				setPackageTerms(res?.data?.data?.share_terms?.substring(0, 300))

				setPackageData(res?.data?.data);
			}

			getDataView();
		}
	}, []);


	function scrollToElement(eleId) {
		if (document && eleId) {
			document?.getElementById(eleId)?.scrollIntoView({ behavior: "smooth" });
		}
	}

	const productItemsTabs = useMemo(() => {
		let items = [];
		const productHasItinerary =
			packageData?.itinerary && packageData?.itinerary?.length > 0;
		if (productHasItinerary) {
			items.push({ name: productsBuilder.itinerary, id: 101 });
		}
		packageData?.product_items?.forEach((element) => {
			const elemetID = element?.item?.id;
			switch (elemetID) {
				case itineraryElementsIds.external:
					items.push({ name: inventory.messages.flights, id: elemetID });
					break;
				case itineraryElementsIds.hotel:
					items.push({ name: inventory.messages.hotels, id: elemetID });
					break;
				case itineraryElementsIds.sightseeing:
					items.push({ name: productsBuilder.attraction, id: elemetID });
					break;
				default:
					break;
			}
		});

		items = [
			...new Map(items.map((item) => [item["name"], item])).values(),
			{ name: "Package Pricing", id: "package-pricing" },
			{ name: "Request Form", id: "request-form" },
		];
		return items;
	}, [packageData?.product_items]);

	const packageContainHotel =
		productItemsTabs.filter((item) => item?.id === itineraryElementsIds.hotel)
			.length > 0;
	const packageContainAttraction =
		productItemsTabs.filter(
			(item) => item?.id === itineraryElementsIds.sightseeing
		).length > 0;

	const HotelElements = () => {
		return packageData?.product_items?.map((element, index) => {
			if (element?.item?.id === itineraryElementsIds.hotel) {
				return <PackageHotelView hotelData={element?.itemable} key={index} />;
			}
			return null;
		});
	};

	const AttractionElements = () => {
		return packageData?.product_items?.map((element, index) => {
			if (element?.item?.id === itineraryElementsIds.sightseeing) {
				return (
					<PackageAttractionsView
						attractionData={element?.itemable}
						key={index}
					/>
				);
			}
			return null;
		});
	};

	// const ExternalElements = () => {
	// 	//To Return uniqe External Element
	// 	let externalElementAirLines = packageData?.product_items?.filter(
	// 		(element) => element?.item?.id === itineraryElementsIds.external
	// 	);
	// 	let uniqueAirLinesIds = {};
	// 	const uniqueExtrenalAirLines = externalElementAirLines?.filter((item) => {
	// 		const airLineId = item?.itemable?.transporter?.id;
	// 		if (uniqueAirLinesIds[airLineId] === undefined) {
	// 			uniqueAirLinesIds = { ...uniqueAirLinesIds, [airLineId]: item };
	// 			return item;
	// 		}
	// 	});
	// 	if (uniqueExtrenalAirLines?.length > 0) {
	// 		return uniqueExtrenalAirLines?.map((externalTransport) => {
	// 			return (
	// 				<Company
	// 					key={externalTransport.id}
	// 					title={marketPlace.airlines}
	// 					name={externalTransport?.itemable?.transporter?.name}
	// 					logo={externalTransport?.itemable?.transporter?.logo}
	// 				/>
	// 			);
	// 		});
	// 	} else {
	// 		return null;
	// 	}
	// };

	const calcTotalPrices = useMemo(() => {
		let adultTotalPrice = 0;
		let childTotalPrice = 0;
		let isFullPackagePrice = packageData?.product_price?.product_price_type?.id === 1;

		if (packageData) {
			// calc hotel
			if (packageContainHotel && selectedRoomType) {
				const combinations = packageData?.product_price_combinations;
				let hotelCombination = combinations?.find(comb => +comb?.combinations[0]?.room_type === selectedRoomType?.room_type);
				if (!isFullPackagePrice) {
					hotelCombination?.combinations?.forEach(hotel => {
						adultTotalPrice = adultTotalPrice + (hotel?.price_adult || 0);
						childTotalPrice = childTotalPrice + (hotel?.price_child || 0);
					});
					// calc other services
					packageData?.product_items?.forEach(service => {
						if(service?.item?.id !== 6) {
							adultTotalPrice = adultTotalPrice + (+service?.itemable?.price_adult || 0);
							childTotalPrice = childTotalPrice + (+service?.itemable?.price_child || 0);
						}
					})
				} else {
					adultTotalPrice = hotelCombination?.full_price_after_discount;
					childTotalPrice = hotelCombination?.child_price_after_discount;
				}
			} else if (!packageContainHotel) {
				// calc other services
				packageData?.product_items?.forEach(service => {
					adultTotalPrice = adultTotalPrice + (+service?.itemable?.price_adult || 0);
					childTotalPrice = childTotalPrice + (+service?.itemable?.price_child || 0);
				})
			}
		}
		return { adultTotalPrice, childTotalPrice };
	}, [packageContainHotel, packageData, selectedRoomType]);


	return (
		// 
		<div className="public-page-preview-services" id='main-web-builder'>
			<HeroHeader />
			<PublicPageHeader service={service} packageData={packageData} />

			<div className="package-details-container">
				{service === "package" ? <ul className="packages-navigation">
					{productItemsTabs?.map((tab) => {
						return (
							<li
								key={`tab-${tab.id}`}
								className={`text-capitalize ${+activeTab === tab.id ? "" : ""} `}
								onClick={() => {
									setActiveTab(tab.id);
									scrollToElement(`tab-${tab.id}`);
								}}
							>
								{tab.name}
							</li>
						);
					})}
				</ul> : null}


				<div className="w-85 m-auto">
					<div className="row">
						<div className="col-lg-12 col-12 mb-5">
							{/* Itinerary */}
							{service === "package" ? packageData?.itinerary?.length > 0 ? (
								<div className="package-item" id="tab-101">
									<h2 className="package-title">{productsBuilder.itinerary}</h2>
									<ProductItinerary
										itineraryData={packageData?.itinerary}
										itineraryElementsIds={itineraryElementsIds}
									/>
								</div>
							) : null : null}
							{/* flight */}
							{service === "package" || service === "flight" ? <div id={`tab-${itineraryElementsIds.external}`}>
								{packageData?.product_items
									?.filter((item) => item?.item?.id === 1)
									?.map((item, index) => {
										return (
											<div
												className="public-page-preview-services mt-4"
												index={index}
												key={item?.itemable?.id}
											>
												<FLightView details={item} />
											</div>
										);
									})}
							</div> : null}

							{/* Hotels */}
							{service === "package" || service === "hotel" ? packageContainHotel ? (
								<div
									className="package-item"
									id={`tab-${itineraryElementsIds.hotel}`}
								>
									<h2 className="package-title">{inventory.messages.hotels}</h2>
									{HotelElements()}
								</div>
							) : null : null}

							{/* Attraction */}
							{service === "package" || service === "tour" ? packageContainAttraction ? (
								<div
									className="package-item"
									id={`tab-${itineraryElementsIds.sightseeing}`}
								>
									<h2 className="package-title">
										{productsBuilder.attraction}
									</h2>
									{AttractionElements()}
								</div>
							) : null : null}

							{/* package prices */}
							<div className="package-item" id="tab-package-pricing">
								<h2 className="package-title">
									Package Pricing
								</h2>
								<div className="mt-4">
									<WebBuilderPackagePricing
										packageData={packageData}
										packageContainHotel={packageContainHotel}
										selectedRoomType={selectedRoomType}
										setSelectedRoomType={setSelectedRoomType}
										calcTotalPrices={calcTotalPrices}
									/>
								</div>
							</div>


							{/* services */}
							{service === "package" ? <div className="package-item">
								<h2 className="package-title">Services</h2>
								<div className="mt-4">
									<p className="d-flex align-items-center mb-2">
										{packageData?.product_items?.filter(
											(item) => item?.item?.id === itineraryElementsIds.internal
										)?.length > 0 ? (
											<CheckMarkCircleIcon />
										) : (
											<StopIcon />
										)}
										<span className="mx-1">
											{productsBuilder.internalTransportations}
										</span>
									</p>
									<p className="d-flex align-items-center mb-2">
										{packageData?.product_items?.filter(
											(item) =>
												item?.item?.id === itineraryElementsIds.visa &&
												item?.itemable?.included
										)?.length > 0 ? (
											<CheckMarkCircleIcon />
										) : (
											<StopIcon />
										)}
										<span className="mx-1">{productsBuilder.visa}</span>
									</p>
									<p className="d-flex align-items-center mb-2">
										{packageData?.product_items?.filter(
											(item) =>
												item?.item?.id === itineraryElementsIds.insurance &&
												item?.itemable?.included
										)?.length > 0 ? (
											<CheckMarkCircleIcon />
										) : (
											<StopIcon />
										)}
										<span className="mx-1">{productsBuilder.insurance}</span>
									</p>
									<p className="d-flex align-items-center mb-2">
										{packageData?.product_items?.filter(
											(item) =>
												item?.item?.id === itineraryElementsIds.otherServices
										)?.length > 0 ? (
											<CheckMarkCircleIcon />
										) : (
											<StopIcon />
										)}
										<span className="mx-1">{productsBuilder.other}</span>
									</p>
								</div>
							</div> : null}

							{/* Terms & conditions */}
							<div className="package-item">
								<h2 className="package-title">{packages.termsAndConditions}</h2>
								<div className="mt-4">
									{packageTerms ?
										<div className="d-flex flex-column align-items-start shared-terms">
											<p>{packageTerms}</p>
											{packageData?.share_terms?.length > 300 &&
												<button className='btn more-details-btn p-0 font-16 mt-3'
													onClick={() => setPackageTerms((prev) => prev.length > 300 ? shortTermsAndCondition : packageData?.share_terms)}
												>
													{packageTerms?.length > 300 ? "Read less" : "Read more"}
													<ArrowIcon className="ms-2" />
												</button>
											}
										</div>
										:
										<p className="text-muted text-center">Not Found Data</p>
									}
								</div>
							</div>

							{/* request form */}
							<div className="package-item" id="tab-request-form">
								<h2 className="package-title">{"Request form"}</h2>
								<div className="mt-4">
									<WebBuilderRequestForm selectedRoomType={selectedRoomType} />
								</div>
							</div>
							{/* Required Documents */}
							<div className="package-item required-docs">
								<div className="d-flex align-items-center">
									<RequiredDocIcon />
									<h2 className="package-title mx-2">
										{productsBuilder.requiredDocuments}
									</h2>
								</div>
								<div className="mt-4">
									{packageData?.share_required_documents ?? (
										<p className="text-muted text-center">Not Found Data</p>
									)}
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PreviewServices;
