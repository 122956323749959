import React from "react";

export default function HotelSpecialIcon({ color }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
		
			<g >
				<path
					d="M4.96875 23.2969H20.0313V4.46873H4.96875V23.2969Z"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M20.0323 2.20937C20.0323 1.80986 19.8737 1.42689 19.5909 1.14447C19.3085 0.861672 18.9255 0.70314 18.526 0.70314H6.47601C6.07645 0.70314 5.69348 0.861672 5.41106 1.14447C5.12831 1.42689 4.96973 1.80986 4.96973 2.20937V4.46875H20.0323V2.20937Z"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M10.9932 23.2969H14.0057V18.7781H10.9932V23.2969Z"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M7.98145 7.48128V8.23438"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M10.9941 7.48128V8.23438"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M14.0068 7.48128V8.23438"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M17.0186 7.48128V8.23438"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M7.98145 11.2469V12"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M10.9941 11.2469V12"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M14.0068 11.2469V12"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M17.0186 11.2469V12"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M7.98145 15.0125V15.7656"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M10.9941 15.0125V15.7656"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M14.0068 15.0125V15.7656"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M17.0186 15.0125V15.7656"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M7.98145 18.7782V19.5312"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M17.0186 18.7782V19.5312"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M1.20312 23.2969H23.7969"
					stroke={color}
					stroke-width="1.25"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
		</svg>
	);
}
