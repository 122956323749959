import React from 'react'
import { HotelIcon } from 'modules/UmrahOperations/shared/Icons'
import RoomsPricing from './shared/RoomsPricing'
import PricingDiscountVAT from './shared/PricingDiscountVAT'
import ServicesPricing from './shared/ServicesPricing'

export default function TripPlannerBreakdownPricing({ pricingForText, setPricingData, pricingData, toggleVatDiscount, selectedPricingType }) {

  const breakdownPricing = pricingData?.pricing?.find(price => price?.for === pricingData?.selected_pricing_for);
  const servicesList = breakdownPricing?.product_items?.filter(service => service?.item?.id !== 6);
  const hotelsList = breakdownPricing?.product_items?.filter(service => service?.item?.id === 6);


  function handleServicesInputsChanges({ key, value, roomIndex, serviceId }) {
    let pricingClone = [...pricingData?.pricing];
    let selectedPricingIndex = pricingClone?.findIndex(price => price?.for === pricingData?.selected_pricing_for)
    let serviceIndex = pricingClone[selectedPricingIndex].product_items.findIndex(item => item?.id === serviceId);
    let service = { ...pricingClone[selectedPricingIndex].product_items[serviceIndex] }
    if (roomIndex !== null && roomIndex > -1) {
      let roomsClone = [...service?.itemable?.rooms]
      roomsClone[roomIndex] = { ...roomsClone[roomIndex], [key]: Number(value).toString() }
      pricingClone[selectedPricingIndex].product_items[serviceIndex] = {
        ...service,
        itemable: {
          ...service?.itemable,
          rooms: roomsClone
        }
      };
    } else {
      pricingClone[selectedPricingIndex].product_items[serviceIndex] = {
        ...service,
        itemable: {
          ...service?.itemable,
          [key]: Number(value).toString()
        }
      };
    }
    setPricingData({ ...pricingData, pricing: pricingClone })
  }

  function handleVatDiscountInputs({ key, value }) {
    let pricingClone = [...pricingData?.pricing];
    let selectedPricingIndex = pricingClone?.findIndex(price => price?.for === pricingData?.selected_pricing_for)
    pricingClone[selectedPricingIndex][key] = value;
    setPricingData({ ...pricingData, pricing: pricingClone })
  }

  return (
    <div className="pricing-type-wrapper">

      <div className="service-pricing">
        <ServicesPricing
          pricingForText={pricingForText}
          servicesList={servicesList}
          handleServicesInputsChanges={handleServicesInputsChanges}
        />
      </div>

      {hotelsList?.map((hotel, index) => {
        return (
          <RoomsPricing
            pricing={hotel}
            handleInputsChanges={handleServicesInputsChanges}
            selectedPricingType={selectedPricingType}
            productHasHotel={hotelsList.length > 0}
          >
            <div className="header text-start d-flex align-items-center">
              <HotelIcon />
              <span className="mx-1">{hotel?.itemable?.rooms[0]?.hotel_name} (Hotel {index + 1})</span>
            </div>
          </RoomsPricing>
        )
      })}


      <PricingDiscountVAT
        pricing={breakdownPricing}
        pricingForText={pricingForText}
        toggleVatDiscount={toggleVatDiscount}
        handleVatDiscountInputs={handleVatDiscountInputs}
      />

    </div>
  )
}
