// import { useSBSDispatch } from "context/global";
// import { useVisaDispatch } from "context/visa";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Table } from "reactstrap";
import { fetchGroups, fetchGroupsDropdown } from "services/visa";
import Locale from "translations";
import CustomPagination from "./CustomPagination/CustomPagination";
import Filter from "./Filter";
import TableContent from "./tableContent";
import ShowForPermission from 'helpers/showForPermission';
// import ShowSubscription from 'helpers/ShowSubscription';
export default function Visa() {

	const { visa } = Locale;
	let history = useHistory();
	/**login safavisa */
	// const loginData = {
	// 	safa_key: "9999 | 100000",
	// 	email: "h.jabban@safavisa.com | Botros1@safavisa.com",
	// 	pwd: "2133549 | 123",
	// };
	const [groupsList, setGroupsList] = useState([]);
	const [filter, setFilter] = useState({
		status: "",
		search: "",
		page: 0,
		size: 10,
		clear: true,
	});
	useEffect(() => {
		async function fetchGroupsList() {
			const res = await fetchGroups(null, filter?.size, filter?.page, {
				...filter,
				status: filter.status.id,
			});
			setGroupsList(res?.data);
			// setTotalListSize();
			if (res.status === 203) {
				history.push("/visa/login");
			}
		}
		fetchGroupsList();
	}, [filter.clear, filter.page]);

	const [groupStatus, setGroupStatus] = useState();
	useEffect(() => {
		async function groupStatus() {
			const res = await fetchGroupsDropdown(null);
			setGroupStatus(res.data?.data);
		}
		groupStatus();
	}, []);

	const search = async () => {
		// const res = await fetchSearchGroups(
		// 	tokenSafaVisa,
		// 	filter?.size,
		// 	filter?.page,
		// 	{
		// 		...filter,
		// 		status: filter.status.name,
		// 	}
		// );
		// setGroupsList(res.data);
		setFilter({
			...filter,
			clear: !filter.clear,
		});
	};

	const groupsRaw =
		groupsList?.data?.length > 0 ? (
			groupsList?.data?.map((group, index) => (
				<TableContent group={group} index={index} />
			))
		) : (
			<tr>
				<td colSpan="10">
					<div className="product-build__product-no-data fullHeight">
						<i className="icx icx-eye-slash" aria-hidden="true"></i>
						<h4>{"No Result"}</h4>
					</div>
				</td>
			</tr>
		);

	return (
		<>
			{/* <ShowSubscription module={"safa_offline"}> */}
			{/* <div className="container py-3"> */}
			<div className="visa-header">
				<div className=" d-flex align-items-center justify-content-between mx-3">
					<h3 className="font-weight-bold h4    mt-2 dark-blue-color">
						{visa.Group}
					</h3>
					<ShowForPermission permission="Manage-Safa-Offline" >

						<Link
							to="/visa/manage-contract"
							className="btn bg-nxt px-4 hpy-2 bg-nxt"
						>
							{visa.ManageContract}
						</Link>
					</ShowForPermission>
				</div>
			</div>
			{/* Filter */}
			<Filter
				filter={filter}
				setFilter={setFilter}
				search={search}
				groupStatus={groupStatus}
			/>
			{/* custom-table */}
			<div className="table-container">
				<Table striped className="table-update  p-2 bg-white border">
					<thead>
						<tr>
							<th>{visa.No}</th>
							<th>{visa.GroupName}</th>
							<th>{visa.GroupTag}</th>
							<th>{visa.status}</th>
							<th>{visa.Contract}</th>
							<th>
								{/* <i class="fas fa-sort"></i> */}
								{visa.date}
							</th>
							<th>
								{/* <i class="fas fa-sort"></i> */}
								{visa.TravelDate}
							</th>
							<th>
								{/* <i class="fas fa-sort"></i> */}
								{visa.Level}
							</th>
							<th>
								{/* <i class="fas fa-sort"></i> */}
								{visa.Nights}
							</th>
							<th>
								{/* <i class="fas fa-sort"></i> */}
								{visa.pax}
							</th>

							<th width="1%"></th>
						</tr>
					</thead>

					<tbody>{groupsRaw}</tbody>
				</Table>
			</div>
			<div className="d-flex justify-content-between">
				<p className='tabel-footer-info'>
					{/* Show {groupsList?.data?.length} of  {groupsList?.total}	{visa.itemsPerPage} */}
					Show {groupsList?.data?.length} of  {groupsList?.total}	Items
				</p>
				<CustomPagination
					groupsList={groupsList}
					filter={filter}
					setFilter={setFilter}
				/>
			</div>
			{/* </div> */}
			{/* </ShowSubscription> */}
		</>
	);
}
