export function getDistinctAirLines(productAirlines) {
  let distinctedAirLinesIds = {};
  const uniqueExtrenalAirLines = productAirlines?.filter((item) => {
    const airLineId = item?.itemable?.transporter?.id;
    if (distinctedAirLinesIds[airLineId] === undefined) {
      distinctedAirLinesIds = { ...distinctedAirLinesIds, [airLineId]: item };
      return item;
    } else {
      return null
    }
  });
  return uniqueExtrenalAirLines
}

export function getProductHotels(productHotels, city) {
  const hotels = productHotels?.filter(hotel => hotel?.itemable?.city_name?.toLowerCase() === city?.toLowerCase()).map(hotelItem=> {
    return {
      ...hotelItem,
      name: hotelItem?.itemable?.hotel?.name,
      rating: hotelItem?.itemable?.hotel?.rating,
    }
  });
  return hotels;
}

export function getItineraryItems(itinerary) {
  let itineraryItems = [];
  for (let i = 0; i < itinerary.length; i++) {
    const element = itinerary[i];
    itineraryItems.push([...element?.items])
  }
  
  return itineraryItems.flat(2);
}