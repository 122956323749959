import { ItinareyHotelIcon } from 'modules/market-place/PackageResponse/Details/shared/PackageIcon';
import moment from "moment/moment";
import React, { useState } from "react";
import { Collapse } from "reactstrap";

export default function ItineraryHotel({ type, isMobile, element }) {
	const [opened, setopened] = useState(false);
	const handelcollapse = () => {
		setopened(!opened);
	};
	const hotelRating = +element.itemable?.hotel?.rating > 5 ? 5 : +element.itemable?.hotel?.rating

	return (
		<>
			<div className={"boxcollapse"}>
				<div className={"closecollapse"}>
					<div className={`collapseIcon ${opened && "opencoll"}`}>
						<ItinareyHotelIcon />
					</div>
					{isMobile && (
						<p
							style={{
								color: "##0F3E5E",
								fontWeight: "bold",
								marginRight: "auto",
								paddingLeft: "5px",
							}}
						>
							{"Hotels"}
						</p>
					)}
					{!isMobile && (
						<div className={"info"}>
							<div className="d-flex wrap">
								<p style={{ color: "#0F3E5E" }} >
									{element.itemable?.hotel?.name}
								</p>

								<div className="hotel-star-rate d-inline px-2">
									{[...Array(hotelRating).keys()].map((i) => (
										<i key={i} className="fa fa-star" aria-hidden="true"></i>
									))}
								</div>

							</div>
							<p style={{ color: "#2D2D2D" }}>
								{element.itemable?.hotel?.country}
							</p>
							<p style={{ color: "##2D2D2D" }}>
								{moment(element.itemable?.check_in_at).format("DD/MM/YYYY")}
							</p>
						</div>
					)}
					
					<div className={"movebox"}>
						<div className={"nightbox"}>
							<i className="fas fa-cloud-moon"></i>
							<span>
								{element?.itemable?.night_count} {"nights"}
							</span>
						</div>
					</div>

					{isMobile && (
						<div>
							<button onClick={handelcollapse} className='btn'>
								<i className="fas fa-chevron-down fa-1x text-grey"></i>
							</button>
						</div>
					)}
				</div>

				{type === "hotel" && isMobile ? (
					<Collapse isOpen={opened}>
						<div className={"opendcollapse"}>
							<div className={"info"}>

								<div>
									<p style={{ color: "#0F3E5E" }} >
										{element.itemable?.hotel?.name}
									</p>

									<div className="hotel-star-rate d-inline px-md-2">
										{[...Array(hotelRating).keys()].map((i) => (
											<i key={i} className="fa fa-star" aria-hidden="true"></i>
										))}
									</div>
								</div>

								<p style={{ color: "#2D2D2D" }}>
									{element.itemable?.hotel?.country}
								</p>

								<p style={{ color: "##2D2D2D" }}>
									{moment(element.itemable?.check_in_at).format("DD/MM/YYYY")}
								</p>
							</div>
						</div>
					</Collapse>
				) : null}
			</div>
		</>
	);
}
