import React from "react";
import { useSBSState } from 'context/global';
import { InternalTransportationIcon } from 'modules/market-place/PackageResponse/Details/shared/PackageIcon';
import moment from 'moment';

export default function ItineraryInternalTransportation({ isMobile, element }) {
	const { locale } = useSBSState();
	return (
		<>
			<div className="boxcollapse">
				<div className="closecollapse">
					<div className="collapseIcon">
						<InternalTransportationIcon />
					</div>
					{isMobile && 
						<p
							style={{
								color: "##0F3E5E",
								fontWeight: "bold",
								marginRight: "auto",
								paddingLeft: "5px",
							}}
						>
							{element?.item?.name}
						</p>
					}
					{!isMobile && 
						<>
							<div className={"info"}>
								<div >
									<p style={{ color: "#0F3E5E" }}>
									{element?.item?.name}
									</p>
									<div className="d-flex wrap w-100 justify-content-between">
               
                <span style={{ color: "##2D2D2D" }} >
                  {moment(element?.created_at).format("DD/MM/YYYY")}
                </span>
                </div>
								</div>

							</div>
						</>
					}
				</div>
			</div>
		</>
	);
}
