import { useSBSState } from "context/global";
import { useMarketplaceDispatch } from "context/marketplace";
import moment from "moment";
import { useEffect, useState } from "react";
import {
	fetchMealPlanLookups,
	fetchRoomTypeLookups,
	fetchRoomViewLookups,
} from "services/marketplace";
import Locale from "translations";
import { fetchCitiesSearch } from "services/marketplace";
import { fetchLookups } from "services/lookups";
import HotelSearch from "./Search/HotelSearch";

export default function Hotels({ allCountries, allCurrenciesData }) {
	//Locale

	const { marketPlace } = Locale;
	const { permissions, role } = useSBSState();
	const dispatch = useMarketplaceDispatch();
	const hotelInitial = {
		checkIn: moment(new Date()).add(1, "d"),
		checkOut: moment(new Date()).add(2, "d"),
		goingTo: null,
		adult: 1,
		child: 0,
		roomType: null,
		rooms: [{ number_of_adult: null, BoxChild: false }],
		RadioOptions: "WithoutMaqam",
	};

	const transportationInitial = {
		validTo: moment(new Date()).add(1, "d"),
		goingTo: null,
		country: null,
		busType: null,
		busClass: null,
		busCount: 1,
		peopleNumber: 1,
	};
	const [activeTab, setActiveTab] = useState("1");
	const [mealPlanLookups, setMealPlanLookups] = useState(null);
	const [roomTypesLookups, setRoomTypeLookps] = useState(null);
	const [roomViewsLookups, setRoomViewsLookups] = useState(null);
	const [allCitiesVacation, setAllCitiesVacation] = useState();
	const [allLookups, setAllLookups] = useState([]);

	const [transRoutes, setTransRoutes] = useState(null);
	const [hotels, setHotels] = useState(hotelInitial);

	useEffect(() => {
		if ((permissions && permissions.length > 0) || role) {
			setActiveTab("1");
		}
	}, [permissions, role]);

	const hotelCountries = [
		{
			code: "SA1",
			name: marketPlace.messages.makkah,
		},
		{
			code: "SA4",
			name: marketPlace.messages.medina,
		},
	];

	useEffect(() => {
		activeTab === "1"
			? setHotels(hotelInitial)
			: setHotels(transportationInitial);
		dispatch({
			type: "fetchCountries",
			payload: activeTab === "1" ? hotelCountries : transRoutes,
		});
	}, [activeTab]);

	// fetch lookups
	useEffect(() => {
		async function fetchLookups() {
			const [meals, roomTypes, roomViews] = await Promise.all([
				fetchMealPlanLookups(),
				fetchRoomTypeLookups(),
				fetchRoomViewLookups(),
			]);
			const formatMealPlanLookup = meals?.data?.data?.map((mealPlan) => {
				return { ...mealPlan, label: mealPlan?.name, value: mealPlan?.id };
			});
			const formatRoomTypeLookup = roomTypes?.data?.data?.map((roomType) => {
				return { ...roomType, label: roomType?.name, value: roomType?.id };
			});
			const formatRoomViewLookup = roomViews?.data?.data?.map((roomView) => {
				return { ...roomView, label: roomView?.name, value: roomView?.id };
			});
			setMealPlanLookups(formatMealPlanLookup);
			setRoomTypeLookps(formatRoomTypeLookup);
			setRoomViewsLookups(formatRoomViewLookup);
		}
		fetchLookups();
	}, []);

	// useEffect(() => {
	// 	async function fetchCitiesVacation() {
	// 		const countries = await fetchCitiesSearch("is_vacation");

	// 		setAllCitiesVacation(countries);
	// 	}
	// 	fetchCitiesVacation();
	// }, []);

	// TODO remove it
	// useEffect(() => {
	// 	async function fetchData() {
	// 		const response = await fetchLookups();
	// 		setAllLookups(response?.room_types);
	// 	}
	// 	fetchData();
	// }, []);
	const handleChange = (e, flag) => {
		const name = e.target?.name;
		const value = e.target?.value;
		if (flag) {
			setHotels({
				...hotels,
				country: e["value"], // countries[value],
				goingTo: e.value, //countries[value]?.code,
			});
		} else setHotels({ ...hotels, [name]: value });
	};
	return (
		<>
		

			<HotelSearch
				hotel={hotels}
				setHotel={setHotels}
				handleChange={handleChange}
				allCountries={allCountries}
				allCitiesVacation={allCitiesVacation}
				allCurrenciesData={allCurrenciesData}
				allLookups={allLookups}
				mealPlanLookups={mealPlanLookups}
				roomTypesLookups={roomTypesLookups}
				roomViewsLookups={roomViewsLookups}
			/>
		</>
	);
}
