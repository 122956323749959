import React from 'react'

export default function ServiecIcon({size}) {
  return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size ? size : `32`}
			height={size ? size : `32`}
			viewBox="0 0 32 32"
			fill="none"
		>
			<mask
				id="mask0_1326_1491"
				style={{ maskType: "luminance" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="32"
				height="32"
			>
				<path
					d="M30.9999 31V1.00009H1V31H30.9999Z"
					fill="white"
					stroke="white"
					stroke-width="2"
				/>
			</mask>
			<g mask="url(#mask0_1326_1491)">
				<path
					d="M22.4665 19.0182C23.0638 18.054 23.5114 16.9899 23.7761 15.8528H25.9374V11.0222H23.7761C23.5096 9.87749 23.0579 8.80655 22.4545 7.83724L23.9846 6.30706L20.5679 2.89038L19.0378 4.4205C18.0684 3.81707 16.9975 3.36544 15.8528 3.09888V0.937576H11.0222V3.09888C9.87741 3.36544 8.80647 3.81707 7.8371 4.4205L6.30698 2.89038L2.8903 6.30706L4.42043 7.83724C3.81699 8.80655 3.36537 9.87749 3.0988 11.0222H0.937498V15.8528H3.0988C3.36537 16.9976 3.81699 18.0685 4.42043 19.0378L2.8903 20.568L6.30704 23.9847L7.83716 22.4546C8.80647 23.058 9.87741 23.5096 11.0222 23.7762V25.9375H15.8528V23.7762C16.9898 23.5114 18.0539 23.0638 19.0181 22.4666M13.4375 19.6875C9.98572 19.6875 7.18748 16.8893 7.18748 13.4375C7.18748 9.98574 9.98566 7.18756 13.4375 7.18756C16.8892 7.18756 19.6874 9.98574 19.6874 13.4375C19.6874 16.8893 16.8893 19.6875 13.4375 19.6875Z"
					stroke="#707170"
					stroke-width="2"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M18.5624 24.8125C18.5624 21.3607 21.3606 18.5625 24.8124 18.5625C28.2642 18.5625 31.0624 21.3607 31.0624 24.8125C31.0624 28.2643 28.2642 31.0625 24.8124 31.0625C21.3606 31.0625 18.5624 28.2643 18.5624 24.8125Z"
					stroke="#707170"
					stroke-width="2"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M22.8237 24.8125L24.1495 26.1383L26.8012 23.4867"
					stroke="#707170"
					stroke-width="2"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
		</svg>
	);
}
