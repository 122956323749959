import DatePickerField from 'components/Form/DatePickerField/DatePickerField'
import NumberField from 'components/Form/NumberField/NumberField'
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import validate, { isFormValid } from "helpers/validate";
import moment from 'moment';
import { CRMAddPayment } from 'services/CRM';
import { store } from 'react-notifications-component';
import { useParams } from 'react-router-dom';
import { formatPrice } from 'helpers/utils';

const AddPaymentModal = ({ isOpen, toggle, crmDetails, setReload }) => {
	// hooks
	const { id } = useParams();
	// states
	const [paymentsState, setPaymentsState] = useState({
		amount: "",
		pay_date: null,
	});
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);


	// check form errors
	function checkFormErrors() {
		let SubmitError = {};
		Object.keys(paymentsState).forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{ name: key, value: paymentsState[key] },
					{ required: true }
				),
			};
		});
		setErrors(() => SubmitError);
	}

	function submit() {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	}

	// add new payment 
	async function addNewPayment() {
		let data = {
			package_reservation_id: +id,
			amount: +paymentsState?.amount,
			pay_date: moment(paymentsState?.pay_date).format("YYYY-MM-DD"),
		};
		const response = await CRMAddPayment(data);
		if (response.status === 200 || response.status === 201) {
			store.addNotification({
				title: "info!",
				message: response.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setReload((prev) => !prev);
			toggle();
			setPaymentsState({
				amount: "",
				pay_date: undefined,
			})
		}
	}


	useEffect(() => {
		if (isFormValid(errors)) {
			addNewPayment()
		}
	}, [isErrorLoaded]);



	return (
		<Modal
			isOpen={isOpen}
			size='lg'
			centered={true}
			hasFooter={false}
			className='application-form'
		>
			<ModalHeader toggle={toggle}>Payment</ModalHeader>
			<ModalBody>
				<div className='row'>
					<div className='col-md-4 col-sm-6'>
						<div className='d-flex flex-column payment-prices-items'>
							<h5 className='crm-details-text-primary  text-totalPrice'>Total Price</h5>
							<h6 className='crm-details-text-secondary text-totalPrice'>{formatPrice(crmDetails?.total_price)} {crmDetails?.currency}</h6>
						</div>
					</div>
					<div className='col-md-4 col-sm-6'>
						<div className='d-flex flex-column payment-prices-items'>
							<h5 className='crm-details-text-primary text-payment-success'>Paid</h5>
							<h6 className='crm-details-text-secondary text-payment-success'>{formatPrice(crmDetails?.payments_total_paid)} {crmDetails?.currency}</h6>
						</div>
					</div>
					<div className='col-md-4 col-sm-6'>
						<div className='d-flex flex-column payment-prices-items'>
							<h5 className='crm-details-text-primary text-payment-orange'>Outstanding</h5>
							<h6 className='crm-details-text-secondary text-payment-orange'>{formatPrice(crmDetails?.payments_outstanding)} {crmDetails?.currency}</h6>
						</div>
					</div>
				</div>

				<div className='row mt-3 p-2'>
					<div className='col-md-4'>
						<NumberField
							label='Amount'
							placeholder='Enter Amount'
							removeArrow={true}
							id="amount"
							name="amount"
							value={paymentsState.amount}
							onChange={(e) => {
								setPaymentsState({
									...paymentsState,
									amount: e.target.value,
								});
								setErrors({
									...errors,
									...validate(
										{ name: "amount", value: e.target.value },
										{ required: true }
									),
								});
							}}
							color={errors?.amount?.required ? "danger" : ""}
							errors={errors?.amount}
						/>
					</div>
					<div className='col-md-4'>
						<DatePickerField
							label='On Date'
							placeholder='DD/MM/YYYY'
							isOutsideRange={(day) => {
								return !day.isBefore(moment(), "day");
							}}
							labelInner={false}
							customIcon={true}
							date={paymentsState.pay_date ? moment(paymentsState.pay_date) : ""}
							onChangeDate={(e) => {
								setPaymentsState({ ...paymentsState, pay_date: e });
								setErrors({
									...errors,
									...validate(
										{ name: "pay_date", value: e },
										{ required: true }
									),
								});
							}}
							color={errors?.pay_date?.required ? "danger" : ""}
							errors={errors?.pay_date}
						/>
					</div>
					<div className='col-md-12' style={{ margin: '1rem 0' }}>
						<button className='btn crm-button-add btn-block' onClick={submit}>
							Add Payment
						</button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	)
}

export default AddPaymentModal
