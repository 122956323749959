
import Translation from "translations";
import walletIcon from "assets/images/walletMobile.png"
import { useSBSState } from "context/global";
import { useEffect, useState } from "react";
import {  Link } from "react-router-dom";
export default function WalletHeaderMobile() {
	const { dashboard } = Translation;
	const { myBalance } = useSBSState();
	const [toggle, setToggle] = useState(false);


	const el = document.getElementById("item");
	const [showClass, setShowClass] = useState(false);
	useEffect(() => {
		setShowClass(el?.className.includes("show"));
	}, [toggle]);

	return (
		<div
			className={`custom-notification1 wallet-icon ${
				toggle ? "bg-wallets" : ""
			}`}
		>
			
					<Link to="/wallet/transactions">
					<div className="btn-light bg-white  pointer lang-btn" style={{padding:"10px 12px"}}>
						<img src={walletIcon} alt="my wallet" width={20} height={25} />
						 {/* <span className='mx-1'>{dashboard.messages.myWallet}</span> */}
					</div>
					</Link>
				
		
		</div>
	);
}
