import EditableParagraph from "modules/WebBuilder-V2/shared/EditableParagraph";
import ToggleCheckbox from "modules/WebBuilder-V2/shared/ToggleCheckbox";
import React from "react";
import Locale from 'translations';

export default function ServicesWithImage({
	logo,
	type,
	editCardDetails,
	header,
	body,
	preview,
	visible,
}) {
	const { webBuilder } = Locale;

	return (
		<div className="our-service_card-with-image h-100">
			<div className="our-service-icon">
				{logo}
				<div className="img-data">
					<p className="service-body text-secondary-color1">
						{webBuilder[type] || ""}
					</p>
					<EditableParagraph
						className="service-header h5 mb-0"
						contentEditable={visible}
						onInput={(value) => {
							editCardDetails(value, "header");
						}}
						text={header}
					/>
				</div>
			</div>
			<div>
				<EditableParagraph
					className="text-secondary-color service-content"
					contentEditable={visible}
					onInput={(value) => {
						editCardDetails(value, "body");
					}}
					text={body}
				/>
			</div>

			{!preview ? (
				<ToggleCheckbox
					checked={visible}
					onChange={(e) => {
						const value = e.target.checked;
						editCardDetails(value, "visible");
					}}
					label="Visible"
				/>
			) : null}
		</div>
	);
}
