import { useSBSState } from "context/global";
import { Link } from "react-router-dom";
// import Locale from "translations";

export default function TableContent({ group, toggle, index }) {
	const { locale } = useSBSState();
	// const { visa } = Locale;

	// const groupStatus = (status) => {
	// 	switch (status) {
	// 		case 1:
	// 			return `${visa.active}`;
	// 		default:
	// 			return `${visa.inactive}`;
	// 	}
	// };


	return (
		<tr key={index}>
			<td>{group?.id}</td>
			<td>{group?.name}</td>
			<td>{group?.tag}</td>
			<td>
				<span className="bg-status px-2">
					{locale === "en"
						? group?.status.status_name_la
						: group?.status.status_name_ar}
				</span>
			</td>
			<td>{group?.contract}</td>
			<td>{(group?.date).slice(0, 10)}</td>
			<td>{group?.return_date}</td>
			<td>{group?.level}</td>
			<td>{group?.nights}</td>
			<td>{group?.pax}</td>

			<td className="actions">
				<Link to={`/visa/view-group/${group.id}`}>
					<i
						className={`fas ${locale === "en" ? "fa-angle-right" : "fa-angle-left"
							}`}
						style={{ fontSize: "16px " }}
					></i>
				</Link>
			</td>
		</tr>
	);
}
