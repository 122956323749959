import { useSBSDispatch, useSBSState } from "context/global";
import { useState } from "react";
import { fetchCitiesCountrySearch } from "services/marketplace";
import Locale from "translations";
import SuccessModal from "../../../../../HotelResponse/Summary/successModal";
import Groups from "./Groups";
import Individual from "./Individual";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useParams } from "react-router-dom";

function HotelSearch({
	hotel,
	setHotel,
	allCountries,
	mealPlanLookups,
	roomTypesLookups,
	roomViewsLookups,
}) {
	const {status}=useParams()

	const { locale, allCurrencies } = useSBSState();
	const dispatch = useSBSDispatch();
	const { marketPlace } = Locale;
	const [modalsuccess, setModalsuccess] = useState(false);
	const togglesuccessModal = () => setModalsuccess(!modalsuccess);

	const [modalGroup, setModalGroup] = useState(false);
	const toggleModalGroup = () => {
		setModalGroup(!modalGroup);
		dispatch({
			type: "guide",
			payload: { enabled: false, initialStep: 0, steps: [] },
		});
	};

	
	const [msgResponse, setMsgResponse] = useState(null);

	// AutoCompleteField
	const [listAuto, setListAuto] = useState([]);
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const countries = await fetchCitiesCountrySearch("is_vacation", inputValue,status == "umrah"?'966':"");

			let result = countries.map((item) => {
				return {
					...item,
					id: item.id,
					name: item.name + " - " + item.country.name[locale],
					value: item.id,
					label: item.name + " - " + item.country.name[locale],
				};
			});
			setListAuto(result);
		}
	};

	return (
		<div className="">
			<Individual
				hotel={hotel}
				setHotel={setHotel}
				listAuto={listAuto}
				setListAuto={setListAuto}
				getListAuto={getListAuto}
				allCountries={allCountries}
				CurrenciesData={allCurrencies}
			/>

			<SuccessModal
				modalsuccess={modalsuccess}
				togglesuccessModal={togglesuccessModal}
				msg={msgResponse}
				title={"Group Request"}
			/>

			<a
				onClick={() => toggleModalGroup()}
				href={() => false}
				className="link text-center w-100 text-underline d-block font-weight-bold"
				style={{ fontSize: ".9rem" }}
				id="hotel-vacations-groups"
			>
				{marketPlace.moreRooms}
			</a>
 {/* Group Modal */}
			<Modal
				size="lg"
				isOpen={modalGroup}
				toggle={toggleModalGroup}
				className="ModalGroup-Modal"
			>
				<ModalHeader
					className="align-items-center py-1  border-none"
					toggle={toggleModalGroup}
				>
					{marketPlace.groups}
				</ModalHeader>
				<ModalBody>
					<Groups
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
						allCountries={allCountries}
						allCurrenciesData={allCurrencies}
						modalsuccess={modalsuccess}
						setModalsuccess={setModalsuccess}
						mealPlanLookups={mealPlanLookups}
						roomTypesLookups={roomTypesLookups}
						roomViewsLookups={roomViewsLookups}
						msgResponse={msgResponse}
						setMsgResponse={setMsgResponse}
					/>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default HotelSearch;
