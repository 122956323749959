import moment from 'moment';
import React, { useEffect, useState } from 'react'
import SaudiArabiaImg from "assets/images/SaudiArabia.jpg";
import UAEImg from "assets/images/UAE.jpg";
import VerifyEmailModal from './Modals/VerifyEmailModal';
import ChangeEmailModal from './Modals/ChangeEmailModal';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import TDSVisaDetails from './VisaDetails';
import TDSVisaStepper from './Stepper';
import TDSEmailVerification from './EmailVerification';
import validate, { isFormValid } from "helpers/validate";
import TDSVisaContactInfo from './VisaContactInfo';
import TDSVisaSummary from './VisaSummary';
import TDSVisaCheckout from './VisaCheckout';
import { ReactComponent as ArrowRightIcon } from "assets/images/Tds/arrow-right.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/images/Tds/arrow-left.svg";
import { TDSCreateVisaRequest, TDSSendOTP } from 'services/TDSVisa';
import { store } from 'react-notifications-component';
import Locale from 'translations';
import StaticOnlinePaymentAction from 'components/StaticOnlinePaymentAction';
import { fetchCountries } from 'services/lookups';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TDSVisaSuccessfulPayment from './SuccessfulPayment';


export default function TDSVisaInfo() {
  const TDSVisaData = JSON.parse(localStorage.getItem("tdsVisa"));
  const TDSSessionData = JSON.parse(sessionStorage.getItem("TdsData"));
  const { marketPlace, companyDetails, visa, inventory } = Locale;

  const [payModal, setPayModal] = useState(false);
  const [emailActionModal, setEmailActionModal] = useState({ isOpen: false, type: null });
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const [countriesLookup, setCountriesLookup] = useState(null);
  const [someNationalitiesModal, setSomeNationalitiesModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(TDSSessionData && TDSSessionData?.source === "tds" ? 4 : 1);

  const [visaData, setVisaData] = useState({
    destination: TDSVisaData?.destination,
    visa_type: TDSVisaData?.visa_type,
    residence: TDSVisaData?.residence,
    expiration_days: moment(new Date()).add(
      TDSVisaData?.residence?.expiration_days,
      "d"
    ),
    price_per_one: +TDSVisaData?.package_plan?.package_provider_price,
    package_plan: TDSVisaData?.package_plan,
    email: "",
    name: "",
    phone: "",
    phoneCode: "",
    pax: "",
    residenceCountry: "",
    isEmailVerified: false,
  });

  const MIN_MAX_STEPS = { min: 1, max: 3 }
  const package_plan = visaData?.package_plan;
  const nationalities = visaData?.residence?.nationalities;


  function togglePayModal() {
    setPayModal(!payModal);
  }

  function toggleEmailActionModal(type) {
    setEmailActionModal({ isOpen: !emailActionModal.isOpen, type });
  }

    function toggleSomeNationalitiesModal() {
    setSomeNationalitiesModal((prev) => !prev);
  }

  // handle contact info input changes
  function handleInputChanges(value, keyName) {
    setVisaData(
      {
        ...visaData,
        [keyName]: value,
        totalPrice: keyName === "pax" ? +value * visaData?.price_per_one : visaData?.totalPrice
      }
    );
    setErrors({
      ...errors,
      ...validate(
        { name: keyName, value },
        {
          required: true,
          email: keyName === "email" ? true : false,
          minNumber: keyName === "pax" ? +package_plan?.range_from : false,
          maxNumber: keyName === "pax" ? +package_plan?.range_to : false,
        }
      ),
    })
  }
  // check contact info errors
  function checkContactErrors() {
    const validations = ['name', "phoneCode", "phone", "pax", "residenceCountry"];
    let submitError = {};
    validations.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: visaData[key] },
          {
            required: true,
            email: key === "email" ? true : false,
            minNumber: key === "pax" ? +package_plan?.range_from : false,
            maxNumber: key === "pax" ? +package_plan?.range_to : false,
          }
        ),
      }
    });
    setErrors({ ...submitError });
  }

  // check email errors
  function checkEmailErrors() {
    setErrors({
      ...validate(
        { name: "email", value: visaData.email },
        { required: true, email: true }
      ),
    })
  }


  function submit() {
    if (currentStep === 1) {
      checkEmailErrors();
    } else if (currentStep === 2) {
      checkContactErrors();
    }
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  function goToNextStep() {
    setCurrentStep((prev) => prev < MIN_MAX_STEPS.max ? prev + 1 : MIN_MAX_STEPS.max);
  }

  async function handlesSendOtp() {
    const res = await TDSSendOTP({ email: visaData?.email });
    if (res?.status === 200) {
      store.addNotification({
        message: marketPlace.messages.otpHasSent,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
    toggleEmailActionModal('verify');
  }

  async function payTDSVisa(paymentType, currency, otp, price) {
    const data = {
      configuration_uuid: visaData?.residence?.uuid,
      destination_id: visaData?.destination?.country_code,
      visa_type_id: visaData?.visa_type?.id,
      residence_id: visaData?.residenceCountry?.id,
      package_uuid: package_plan?.package_uuid,
      pax: visaData?.pax,
      currency: currency,
      expiration_days: visaData?.residence?.expiration_days,
      payment_method: paymentType,
      contact_info: {
        name: visaData?.name,
        phone: visaData?.phone,
        email: visaData?.email
      }
    };
    const res = await TDSCreateVisaRequest(data);
    if (res?.status === 200) {
      setCurrentStep(4)
      setPayModal(false);
    }
  }

  // check email or contact error errors
  useEffect(() => {
    if (isFormValid(errors)) {
      if (currentStep === 1 && !visaData?.isEmailVerified) {
        handlesSendOtp();
      } else {
        goToNextStep();
      }
    }
  }, [isErrorLoaded]);

  // get countries
  useEffect(() => {
    async function fetchCountriesLookup() {
      if (visaData.residence?.all_residences) {
        const countriesRes = await fetchCountries();
        setCountriesLookup(countriesRes);
      } else {
        setCountriesLookup(visaData.residence?.residencesNames);
      }
    }
    if (!countriesLookup) {
      fetchCountriesLookup();
    }
  }, [])


  function currentStepView() {
    switch (currentStep) {
      case 1:
        return (
          <TDSEmailVerification
            visaData={visaData}
            errors={errors}
            handleInputChanges={handleInputChanges}
            toggleEmailActionModal={toggleEmailActionModal}
            emailActionModal={emailActionModal}
            submitEmail={submit}
          />
        )
      case 2:
        return (
          <div className='row mx-0'>
            <TDSVisaContactInfo
              visaData={visaData}
              errors={errors}
              handleInputChanges={handleInputChanges}
              countriesLookup={countriesLookup}
            />
            <TDSVisaSummary visaData={visaData} />
          </div>
        )
      case 3:
        return (
          <TDSVisaCheckout
            visaData={visaData}
            togglePayModal={togglePayModal}
          />
        )
      case 4:
        return (
          <TDSVisaSuccessfulPayment
            visaData={TDSSessionData || visaData}
          />
        )

      default:
        return (
          <TDSEmailVerification
            visaData={visaData}
            errors={errors}
            handleInputChanges={handleInputChanges}
            toggleEmailActionModal={toggleEmailActionModal}
            emailActionModal={emailActionModal}
            submitEmail={submit}
          />
        )
    }
  }

  return (
		<div className="tds-visa-container">
			{/* banner */}
			<div
				className="visa-details-container"
				style={{
					background: `linear-gradient(180deg, #00000050, rgb(0 0 4 / 60%)),url(${
						visaData?.destination?.country === "Saudi Arabia"
							? SaudiArabiaImg
							: visaData?.destination?.country === "UAE"
							? UAEImg
							: visaData.destination?.country_photo
					}) no-repeat center/cover`,
				}}
			>
				<div className="container-fluid d-flex align-items-center">
					<div className="col-md-10 col-12 p-0 mx-auto">
						<h2 className="font-weight-bold h3 m-0">
							{visaData?.destination?.country}
						</h2>
					</div>
				</div>
			</div>

			{/* page content */}
			<div className="container-fluid">
				<div className="col-md-10 col-12 mx-auto">
					<div className="mt-3 col-12">
						<Link to="/tds-visa-packages" style={{ color: "#1BAE70" }}>
							Online visa /
						</Link>
						<span> {visaData?.destination?.country}</span>
					</div>

					<div className="row mt-3 mx-0 flex-wrap">
						<div className="col-md col-12 mt-md-0 mt-2">
							<TDSVisaDetails visaData={visaData} package_plan={package_plan} />
						</div>
						{/* Visa Inclusive */}
						{package_plan?.visa_include?.length > 0 && (
							<div className="col-md col-12 mt-md-0 mt-2">
								<div className="bg-white rounded-lg p-3 h-100">
									<h4 className="font-weight-bold mb-2">
										{marketPlace.VisaInclusive}
									</h4>
									{package_plan?.visa_include?.map((item, index) => (
										<p
											className="d-flex align-items-center my-2"
											key={`${item}-${index}`}
										>
											<i class="fas fa-check fa-xs"></i>
											<span className="mx-2">{item}</span>
										</p>
									))}
								</div>
							</div>
						)}
						{/* Travelers Nationality */}
						<div className="col-md col-12 mt-md-0 mt-2">
							<div className="p-3 rounded-lg bg-white h-100">
								<h4 className="font-weight-bold mb-2">
									{marketPlace.messages.travelersNationality}
								</h4>
								{visaData?.residence?.all_nationalities ? (
									<p>
										<i class="fas fa-check fa-xs"></i>{" "}
										<span className="mx-2">{marketPlace.allNationalities}</span>
									</p>
								) : nationalities?.length > 5 ? (
									<p className="d-flex align-items-center my-2 align-items-center">
										<i class="fas fa-check fa-xs"></i>
										<span className="mx-2">
											{marketPlace.someNationalities}
										</span>
										<i
											class="fas fa-eye"
											role="button"
											onClick={toggleSomeNationalitiesModal}
										></i>
									</p>
								) : nationalities && nationalities?.length > 0 ? (
									nationalities?.map((item) => {
										return (
											<p
												className="d-flex align-items-center my-2"
												key={item.country_id}
											>
												<i class="fas fa-check fa-xs"></i>
												<span className="mx-2">{item.name}</span>
											</p>
										);
									})
								) : null}
							</div>
						</div>

						{/* package note */}
						{package_plan?.notes ? (
							<div className="col-md col-12 mt-md-0 mt-2">
								<div className="bg-white p-3 rounded-lg h-100">
									<h4 className="font-weight-bold mb-2">
										{inventory.viewReservation.Notes}
									</h4>
									{package_plan?.notes}
								</div>
							</div>
						) : null}
					</div>

					<div className="col-12">
						<div className="bg-white w-100 p-3 mt-3 rounded-lg">
							<section className="w-100">
								<TDSVisaStepper currentStep={currentStep} />
								<hr />
								<div className="my-4">{currentStepView()}</div>
							</section>

							{/* next perv buttons */}
							<div
								className={`d-flex w-100 mt-4 ${
									currentStep > 1 && currentStep <= MIN_MAX_STEPS.max
										? "justify-content-between"
										: "justify-content-end"
								}`}
							>
								{/* perv button */}
								{currentStep > 1 && currentStep <= MIN_MAX_STEPS.max ? (
									<button
										className="btn tds-prev-btn d-flex justify-content-center align-items-center shadow-none"
										onClick={() =>
											setCurrentStep((prev) =>
												prev > MIN_MAX_STEPS.min ? prev - 1 : MIN_MAX_STEPS.min
											)
										}
									>
										<ArrowLeftIcon />
										<span className="mx-1">{visa.previous}</span>
									</button>
								) : null}
								{/* next button */}
								{currentStep < MIN_MAX_STEPS.max ? (
									<button
										className="btn tds-generic-btn d-flex justify-content-center align-items-center shadow-none"
										disabled={currentStep === 1 && !visaData.isEmailVerified}
										onClick={() => {
											submit();
										}}
									>
										<span className="mx-1">{companyDetails.next}</span>
										<ArrowRightIcon />
									</button>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>

			{emailActionModal.type === "verify" ? (
				<VerifyEmailModal
					isOpen={emailActionModal.isOpen}
					toggle={() => toggleEmailActionModal(null)}
					visaData={visaData}
					setVisaData={setVisaData}
				/>
			) : null}

			{emailActionModal.type === "change" ? (
				<ChangeEmailModal
					isOpen={emailActionModal.isOpen}
					toggle={() => toggleEmailActionModal(null)}
					visaData={visaData}
					setVisaData={setVisaData}
				/>
			) : null}

			{payModal ? (
				<StaticOnlinePaymentAction
					isOpen={payModal}
					toggleModal={togglePayModal}
					Price={visaData?.totalPrice}
					currency={visaData?.residence?.provider_currency}
					onPayment={payTDSVisa}
				/>
			) : null}

			<Modal isOpen={someNationalitiesModal} centered size="lg">
				<ModalHeader toggle={toggleSomeNationalitiesModal}>
					{marketPlace.availableNationalities}
				</ModalHeader>
				<ModalBody className="row m-0">
					{nationalities?.map((item, index) => {
						return (
							<p
								className="d-flex align-items-center my-2 col-4"
								key={item.country_id}
							>
								<i class="fas fa-check fa-xs"></i>
								<span className="mx-2">{item.name}</span>
							</p>
						);
					})}
				</ModalBody>
			</Modal>
		</div>
	);
}
