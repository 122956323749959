export const transferVoucher = `<style>
.ps-3 {
  -webkit-padding-start: 1rem !important;
  padding-inline-start: 1rem !important;
}
.ms-2 {
  -webkit-margin-start: 0.5rem !important;
  margin-inline-start: 0.5rem !important;
}
.border-left-8{
  border-left: 8px solid white;
}
.red-bg {
  background-color: #b52555;
	padding: 4px;
	color: white;
}
.red-light-bg {
  background-color: #d592aa
}
.telephone-bg {
  background-color: #c4d600
}
.telephone-border {
  border:1px solid #c4d600 
}
.align-items-center {
  -webkit-align-items: center !important;
  align-items: center !important;
}
.justify-content-between {
  -webkit-justify-content: space-between !important;
  justify-content: space-between !important;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-right: 5px;
  padding-left: 5px;
}
.text-right {
  text-align: right !important;
}
.text-white {
  color: #fff !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}
.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-2, .py-2 {
  padding-top: 0.5rem !important;
}
.pb-2, .py-2 {
  padding-bottom: 0.5rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-0 {
  padding: 0 !important;
}
h3, .h3 {
  font-size: 1.75rem;
}
.ml-5, .mx-5 {
  margin-left: 3rem !important;
}
.mr-5, .mx-5 {
  margin-right: 3rem !important;
}
.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}
.mb-2, .my-2 {
  margin-bottom: 0.5rem !important;
}
.mt-2, .my-2 {
  margin-top: 0.5rem !important;
}
.mt-5, .my-5 {
  margin-top: 3rem !important;
}
.ms-5 {
  -webkit-margin-start: 3rem !important;
  margin-inline-start: 3rem !important;
}
.m-0 {
  margin: 0 !important;
}
.h-100 {
  height: 100% !important;
}
.w-100 {
  width: 100% !important;
}
.align-items-end {
  -webkit-align-items: flex-end !important;
  align-items: flex-end !important;
}
.justify-content-center {
  -webkit-justify-content: center !important;
  justify-content: center !important;
}
.flex {
  -webkit-flex: 1 !important;
  flex: 1 !important;
  padding-inline-end: 1rem;
}
.flex-column {
  -webkit-flex-direction: column !important;
  flex-direction: column !important;
}
.d-flex {
  display: -webkit-flex !important;
  display: flex !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
.col-10 {
  -webkit-flex: 0 0 83.3333333333%;
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.col-9 {
  -webkit-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col-6 {
  -webkit-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-4 {
  -webkit-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.col-3 {
  -webkit-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-2 {
  -webkit-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
h6, .h6 {
  font-size: 1rem;
}
h5, .h5 {
  font-size: 1.25rem;
}
h4, .h4 {
  font-size: 1.5rem;
}
h3, .h3 {
  font-size: 1.75rem;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}
* {
  font-family: "Cairo", sans-serif;
}
*, *::before, *::after {
  box-sizing: border-box;
}

@media print {
	.pagebreak {
			clear: both;	
			page-break-before: always;
	}
	.pagebreak > div{
		margin-top: 1px
	}
}
</style>

			<div class="mx-5">
				<div class="d-flex align-items-end flex-column w-100 text-center">
					<div>
						<p class="mb-2 font-weight-bold">Guaranteed by</p>
						[logo]
					</div>
				</div>
				<div class="text-center bg-light p-2 mt-2">
					<h3 class="h3 font-weight-bold">VOUCHER - TRANSFERS</h3>
					<p>Booking confirmed and guaranteed - Voucher - Ticket</p>
				</div>

				<div class="mt-2">
					<div class="row m-0 ">
						<div class="col-3 text-center py-4 d-flex justify-content-center flex-column text-white red-bg">
							<p class="font-weight-bold ">[reference_number_key] :</p>
							<h5 class="h5 font-weight-bold ">[reference_number_value]</h5>
						</div>
						<div class="col-3 border-left py-4 d-flex  flex-column red-light-bg">
							<p class="font-weight-bold ">[supplier_reference_key] :</p>
							<h5 class="h5 font-weight-bold ">[supplier_reference_value]</h5>
							<p class="font-weight-bold ">[supplier_name_key] :</p>
							<h5 class="h5 font-weight-bold ">[supplier_name_value]</h5>
						</div>
						<div class="col-3 py-4 d-flex  flex-column">
							<p class="font-weight-bold ">[passenger_name_key]</p>
							<h5 class="h5 font-weight-bold ">[passenger_name_value]</h5>
						</div>
					</div>

					<div class="bg-light p-2 mt-2 d-flex justify-content-between align-items-center">
						<div>
							<h5 class="h5 font-weight-bold">[product_type_key]</h5>
							<p class="h4">[product_type_value]</p>
						</div>
						<img alt="" height="150" src="[product_image]" />
					</div>

					<div class="py-2 row m-0">
						<div class="col-4 bg-light">
							<p class="h5 p-2 m-0 font-weight-bold">[from_key]</p>
							<p class="p-2 h4">[from_value]</p>
						</div>
						<div class="col-4 bg-light">
							<p class="h5 p-2 m-0 font-weight-bold">[to_key]</p>
							<p class="p-2 h4">[to_value]</p>
						</div>
						<div class="col-4 bg-light border-left-8 p-0">
							<div class="red-bg">[passengers_key]</div>
							<p class="p-2">
							<span>[adults_value] adults,</span>
							<span>[children_value] children,</span>
							<span>[infants_value] infants</span>
						</p>
						</div>
					</div>

					<div class="row m-0 p-0 bg-light">
						<div class="col-2  p-0">
							<div class="red-bg">[service_date_key]</div>
							<p class="p-2">[service_date_value]</p>
							<div class="red-bg">[pickUp_time_key]</div>
							<p class="p-2">[pickUp_time_value]</p>
						</div>
						<div class="col-10  border-left-8 p-0">
							<div class="red-bg">[pickUp_point_key]</div>
							<p class="p-2">[pickUp_point_value]</p>
						</div>
					</div>
				</div>

				<div class="text-center bg-light p-2 my-2">
					<p>
						Please note that the pick-up time doesn´t apply for shared transfers
						INVOLVING A PORT. Customers must confirm the pick-up time by calling
						the supplier. Contact details can be found in the Pick-up point
						section.
					</p>
				</div>

				<div class="row m-0 p-0 bg-light pagebreak">
					<div class="col-3  p-0">
						<div class="red-bg">[travel_information]</div>
						<div class="p-2">
							<h6 class="font-weight-bold">[arrival_flight_key]</h6>
							<p>[arrival_flight_value]</p>
							<h6 class="font-weight-bold mt-2">[arrival_time_key]</h6>
							<p>[arrival_time_value]</p>
						</div>
					</div>
					<div class="col-9  border-left-8 p-0">
						<div class="red-bg">[included_services_key]</div>
						<div class="p-2">[included_services_value]</div>
					</div>
				</div>

				<div class="mt-2">
					<div class="w-100 p-2 telephone-bg">
						<h6 class="h6 font-weight-bold">[telephone]</h6>
					</div>
					<div class="p-3 d-flex">
						[phone_img]
						<div class="ms-5">
							<h6 class="h6 font-weight-bold">Assistance</h6>
							<h6 class="h6 font-weight-bold">+34871180153</h6>
						</div>
					</div>
				</div>
				<h6 class="h6 mt-2">
					Voucher generated on  
					<span class="font-weight-bold">[voucher_date]</span>
				</h6>
			</div>`;
