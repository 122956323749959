import SelectField from 'components/Form/SelectField/SelectField'
import NumberField from 'components/Form/NumberField/NumberField';
import GuestsPerRoom from 'modules/CRM/Main/components/GuestsPerRoom';
import GuestsPerPax from 'modules/CRM/Main/components/GuestsPerPax';
import validate from 'helpers/validate';
import { ReactComponent as PaxIcon } from 'assets/images/market-place/people-pax-icon.svg'
import { addMissingTravelers, formatPaxForSharedReservation } from 'modules/CRM/Main/helpers/roomsAvalibitiesHelpers';
import { useEffect } from 'react';
import Locale from 'translations';



export default function PackageCategoryPricing({
  reservationDetails,
  paxCount,
  setPaxCount,
  errors,
  setErrors,
  privatePaxRooms,
  setPrivatePaxRooms,
  sharedPaxRooms,
  setSharedPaxRooms,
  categoryType,
  setCategoryType
}) {

const {marketPlace}=Locale
  const totalAdultPax = reservationDetails?.adults_pax;
  const totalChildrenPax = reservationDetails?.children_pax;

  function adjustPaxCount(targetCount, currentList) {
    if (currentList.length > targetCount) {
      return currentList.slice(0, targetCount);
    } else {
      const newTravelers = addMissingTravelers(targetCount, currentList, currentList[0]?.is_adult);
      return [...currentList, ...newTravelers];
    }
  };

  useEffect(() => {
    if (categoryType?.value !== "shared") return

    if (sharedPaxRooms?.adults?.length > 0) {
      const { adultPax, childsPax } = paxCount;
      const { adults, children } = sharedPaxRooms;

      const newAdults = adjustPaxCount(+adultPax, adults);
      const newChildren = adjustPaxCount(+childsPax, children);

      setSharedPaxRooms({ adults: newAdults, children: newChildren });

    } else {
      const { adults, children } = formatPaxForSharedReservation(reservationDetails, paxCount.adultPax, paxCount.childsPax);
      setSharedPaxRooms({ adults, children });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryType, paxCount]);


  return (
    <div className='package-pricing package-category-pricing mt-3'>
      {/* pax  */}
      <div className="d-flex flex-wrap align-items-center  gap-10">
        <div className="d-flex align-items-center me-5">
          <PaxIcon />
          <span className="mx-2">{marketPlace.Pax}: </span>
        </div>
        <div className="d-flex align-items-center gap-10">
          <span>{marketPlace.Adults}</span>
          <div className="col-md-5 col-6">
            <NumberField
              hasLabel={false}
              label={marketPlace.Adults}
              id="adults_count"
              name="adults_count"
              removeArrow={true}
              value={paxCount?.adultPax}
              max={totalAdultPax}
              onChange={(e) => {
                if (e.target.value <= totalAdultPax) {
                  setPaxCount({ ...paxCount, adultPax: Number(e.target.value)?.toString() });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "adultPax", value: +e.target.value },
                      { required: true, maxNumber: +totalAdultPax }),
                  })
                }
              }}
              onWheel={(e) => e.target.blur()}
              errors={errors?.adultPax}
              color={errors?.adultPax?.required ? "danger" : ""}
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap-10">
          <span>{marketPlace.Childs}</span>
          <div className="col-md-5 col-6">
            <NumberField
              hasLabel={false}
              label={marketPlace.Childs}
              id="childs_count"
              name="childs_count"
              removeArrow={true}
              max={totalChildrenPax}
              value={paxCount?.childsPax}
              onChange={(e) => {
                if (e.target.value >= 0 && e.target.value <= totalChildrenPax) {
                  setPaxCount({ ...paxCount, childsPax: Number(e.target.value)?.toString() });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "childsPax", value: +e.target.value },
                      { required: false, maxNumber: +totalChildrenPax }),
                  })
                }
              }}
              onWheel={(e) => e.target.blur()}
              errors={errors?.childsPax}
              color={errors?.childsPax?.required ? "danger" : ""}
            />
          </div>
        </div>
      </div>

      <div>
        <SelectField
          label={marketPlace.Reservationtype}
          id="reservation_type"
          name="reservation_type"
          options={[{ id: "private", name: marketPlace.Private }, { id: "shared", name: marketPlace.Shared }]}
          value={categoryType?.name}
          onChange={(e) => {
            setCategoryType(e);
            if (e?.value === "shared") {

            }
          }}
          disabled={!paxCount?.adultPax}
        />
      </div>

      {categoryType?.id === "private" &&
        <div className="mt-3">
          <GuestsPerRoom
            productDetails={reservationDetails}
            privatePaxRooms={privatePaxRooms}
            setPrivatePaxRooms={setPrivatePaxRooms}
            adultCount={paxCount?.adultPax}
            childrenCount={paxCount?.childsPax}
            isMarketPlace={true}
          />
        </div>
      }

      {categoryType?.id === "shared" &&
        <div className="mt-3">
          <GuestsPerPax
            productDetails={reservationDetails}
            sharedPaxRooms={sharedPaxRooms}
            setSharedPaxRooms={setSharedPaxRooms}
            adultCount={paxCount?.adultPax}
            childrenCount={paxCount?.childsPax}
            isMarketPlace={true}
          />
        </div>
      }

      {/* {roomsData?.map((room, index) => {
        return (
          <div className='d-flex align-items-center my-3' key={`room-${index}`}>
            <div className='col-md-4'>
              <SelectField
                label={marketPlace.roomType}
                value={room?.roomType?.label}
                options={roomsAvailabilities}
                onChange={(e) => handleRoomType(index, e)}
              />
            </div>
            <div className='col-md-2'>
              <SelectField
                label={inventory.messages.RoomCount}
                value={room?.roomCount}
                options={roomsAvailabilities?.filter(r => r?.room_type === room?.roomType?.room_type)[0]?.roomRemainingAvailability}
                onChange={(e) => handleRoomsCount(index, e.value)}
                disabled={!room?.roomType}
              />
            </div>
            <div className='col-md-2'>
              <SelectField
                label={inventory.messages.childPax}
                value={room?.childCount}
                options={room?.childrenAvailability}
                onChange={(e) => handleRoomsChildCount(index, e.value)}
                disabled={!room?.roomCount}
              />
            </div>
            {index > 0 ?
              <button className='btn mt-4' onClick={() => deleteRoom(index)}>
                <TrashIcon />
              </button>
              :
              null
            }
          </div>
        )
      })}
      <button className='btn add-room-btn p-1 shadow-none d-flex align-items-center'
        onClick={addNewRoom}
        disabled={roomsAvailabilities?.length === roomsData?.length}
      >
        <i className="fas fa-plus"></i>
        <span className='ms-3'>{productsBuilder.addNew}</span>
      </button> */}

    </div>
  )
}
