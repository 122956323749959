import SelectField from 'components/Form/SelectField/SelectField'
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import validate from 'helpers/validate';
import React from 'react'
import Locale from 'translations';

export default function ActionReason({ reasonTitle, reasonsLookup, reasonData, setReasonData, errors, setErrors, areaPlaceholder }) {
  const { inventory, visa } = Locale;
  const allowedFileExt = ['image/jpg', 'image/jpeg', 'image/png', 'application/x-rar-compressed', 'application/x-zip-compressed'];
  const maxFileSizeMB = 2 * 1000 * 1000; // 2 megabyte
  let maxFilesCount = 5;

  function handleFileUpload(e) {
    let files = e.target.files;
    files = Object.keys(files).map((key) => files[key]);
    const remainingFilesCount = maxFilesCount - reasonData?.certFiles?.length;
    files = files.length > remainingFilesCount ? files.slice(0, remainingFilesCount) : files;
    let acceptedFiles = [];
    // check if there uploaded files less than max files count 5
    if (remainingFilesCount > 0 && remainingFilesCount <= maxFilesCount) {
      files.forEach((file) => {
        if (file.size < maxFileSizeMB && allowedFileExt.includes(file.type)) {
          acceptedFiles.push(file);
        }
      });
      acceptedFiles = [...reasonData?.certFiles, ...acceptedFiles]
      setReasonData({ ...reasonData, certFiles: acceptedFiles });
      setErrors({
        ...errors,
        ...validate(
          { name: "certFiles", value: acceptedFiles.length > 0 },
          { required: true }
        )
      })
    }
  }


  function handleRemoveFile(index) {
    let certFilesClone = [...reasonData?.certFiles];
    certFilesClone.splice(index, 1);
    setReasonData({ ...reasonData, certFiles: certFilesClone });
    setErrors({
      ...errors,
      ...validate(
        { name: "certFiles", value: certFilesClone.length > 0 },
        { required: true }
      )
    })
  }



  return (
    <div className='mx-md-4 mx-1 col-md-9'>
      <h2 className='my-2 font-weight-bold'>
        {visa.select} {reasonTitle}
      </h2>
      {/* select reason */}
      <div className="col-md-6 col-9 p-0">
        <SelectField
          label={reasonTitle}
          hasLabel={false}
          placeholder={reasonTitle}
          value={reasonData?.reason?.name}
          options={reasonsLookup}
          onChange={(e) => {
            setReasonData({ ...reasonData, reason: e });
            setErrors({
              ...errors,
              ...validate(
                { name: "reason", value: e },
                { required: true }
              )
            })
          }}
          errors={errors?.reason}
          color={errors?.reason?.required ? "danger" : ""}
        />
      </div>

      {/* upload input */}
      {reasonData?.reason?.name !== "Voluntarily" &&
        <div>
          <div className='file-upload-container'>
            <div className='file-upload-control'>
              <label htmlFor="" className='m-0'>{inventory.messages.uploadCertificationFile}</label>
              <i className="fas fa-upload mx-2"></i>
              <input
                type="file"
                accept={allowedFileExt.join(',')}
                onChange={handleFileUpload}
                multiple
                disabled={reasonData?.certFiles?.length >= maxFilesCount}
                className={`file-upload-input ${reasonData?.certFiles?.length >= maxFilesCount ? "disabled" : ""}`}
              />
            </div>
            <span className='mx-1'>
              {inventory.messages.certificationFileValidations}
            </span>
          </div>

          {errors?.certFiles?.required ?
            <small className="text-danger">
              {inventory.messages.certificationFileRequired}
            </small>
            :
            null
          }

          {/* files list */}
          <ul className='cert-files-list'>
            {reasonData?.certFiles?.map((certFile, index) => {
              return (
                <li className='cert-file text-lowercase' key={index}>
                  <i className="fas fa-paperclip file-attach"></i>
                  <span className='mx-2'>{certFile?.name}</span>
                  <i className="fas fa-times-circle pointer remove-file" onClick={() => handleRemoveFile(index)}></i>
                </li>
              )
            })}
          </ul>
        </div>
      }

      {/* reason remarks */}
      <div className='my-2'>
        <TextAreaField
          label={inventory.messages.remarks}
          id="reasonRemars"
          name="reasonRemars"
          placeholder={areaPlaceholder}
          height={"150px"}
          rows="5"
          className='textarea'
          value={reasonData?.remarks}
          onChange={(e) => {
            setReasonData({ ...reasonData, remarks: e.target.value });
            // setErrors({
            //   ...errors,
            //   ...validate(
            //     { name: "remarks", value: e.target.value },
            //     { required: true }
            //   )
            // })
          }}
          // errors={errors?.remarks}
          // color={errors?.remarks?.required ? "danger" : ""}
        />
      </div>

    </div>
  )
}
