import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import logo from "assets/images/safa_logo.png";
import { AddFlyerContent } from "../Components/AddFlyerContent";
import { DownLoadFlyer } from "../Components/DownLoadFlyer";
import {
	SELECT_FLYER_TEMPLETE,
	EDIT_FLYER_TEMPLETE,
	DOWNLOAD_FLYER_TEMPLETE,
	MAXIMUM_HEADER_CHARACTER,
	MAXIMUM_DISCLAIMER_CHARACTER,
	TEMPLETE_TYPE_MULTIPLE,
} from "../helpers/globales";
import { SelecetTemplate } from "../Components/SelecetTemplate";
import { ModalActions } from "../Components/ModalActions";
import { SortHotelCard } from "../Components/SortHotelCard";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function AddPromoDetailsModal({
	openModal,
	handelModalState,
	selectedHotels,
	setSelectedHotels,
}) {
	const [pageType, setPageType] = useState(SELECT_FLYER_TEMPLETE);
	const [logoSrc, setLogoSrc] = useState(logo);
	const [templateType, setTemplateType] = useState(TEMPLETE_TYPE_MULTIPLE);
	const templateTypeHandler = (e) => {
		const value = e.target.value;
		setTemplateType(value);
	};

	const handleNextPage = () => {
		;
		if (pageType === SELECT_FLYER_TEMPLETE) setPageType(EDIT_FLYER_TEMPLETE);
		if (pageType === EDIT_FLYER_TEMPLETE) setPageType(DOWNLOAD_FLYER_TEMPLETE);
	};

	const handlePrevPage = () => {
		if (pageType === EDIT_FLYER_TEMPLETE) setPageType(SELECT_FLYER_TEMPLETE);
		if (pageType === DOWNLOAD_FLYER_TEMPLETE) setPageType(EDIT_FLYER_TEMPLETE);
	};

	// Add Header state
	const [headerText, setHeaderText] = useState({
		bold: false,
		italic: false,
		underline: false,
		value: "",
	});

	const handleHeaderTextStyle = (name) => {
		setHeaderText((prev) => ({
			...prev,
			[name]: !prev[name],
		}));
	};
	const handleHeaderChange = (e) => {
		const value = e.target.value;
		if (value?.length <= MAXIMUM_HEADER_CHARACTER) {
			setHeaderText((prev) => ({ ...prev, value }));
		}
	};

	// Add disclaimer state
	const [disclaimerText, setDisclaimerText] = useState({
		bold: false,
		italic: false,
		underline: false,
		value: "",
	});

	const handleDisclaimerTextStyle = (name) => {
		setDisclaimerText((prev) => ({
			...prev,
			[name]: !prev[name],
		}));
	};
	const handleDisclaimerChange = (e) => {
		const value = e.target.value;
		if (value?.length <= MAXIMUM_DISCLAIMER_CHARACTER) {
			setDisclaimerText((prev) => ({ ...prev, value }));
		}
	};

	/////////////////////////////////////////////////////

	return (
		<div>
			<Modal isOpen={openModal} size="xl">
				<ModalHeader toggle={handelModalState}></ModalHeader>
				<ModalBody>
					{/* Select Template */}
					{pageType === SELECT_FLYER_TEMPLETE && (
						<SelecetTemplate
							templateType={templateType}
							templateTypeHandler={templateTypeHandler}
						/>
					)}

					{/* View, Edit, And Download Template */}
					{pageType !== SELECT_FLYER_TEMPLETE && (
						<div className="row m-0">
							{pageType === EDIT_FLYER_TEMPLETE ? (
								// Edit template Component
								<AddFlyerContent
									logoSrc={logoSrc}
									setLogoSrc={setLogoSrc}
									handleHeaderTextStyle={handleHeaderTextStyle}
									headerText={headerText}
									handleHeaderChange={handleHeaderChange}
									handleDisclaimerTextStyle={handleDisclaimerTextStyle}
									disclaimerText={disclaimerText}
									handleDisclaimerChange={handleDisclaimerChange}
								/>
							) : (
								// Sort Hotel Card
								<DndProvider backend={HTML5Backend}>
									<SortHotelCard
										selectedHotels={selectedHotels}
										setSelectedHotels={setSelectedHotels}
									/>
								</DndProvider>
							)}

							{/* View and Download Template */}
							<DownLoadFlyer
								selectedHotels={selectedHotels}
								logoSrc={logoSrc}
								headerText={headerText}
								disclaimerText={disclaimerText}
								pageType={pageType}
								handleNextPage={handleNextPage}
								handlePrevPage={handlePrevPage}
								templateType={templateType}
							/>
						</div>
					)}

					{/* Next, Prev, and Download Buttons */}
					{pageType === SELECT_FLYER_TEMPLETE && (
						<ModalActions
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							pageType={pageType}
						/>
					)}
				</ModalBody>
			</Modal>
		</div>
	);
}

export default AddPromoDetailsModal;
