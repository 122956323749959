import React from 'react'
import TitlePackage from './Title'
import PackageReservatoinItinerary from './Itinerary'
import { Hotels } from './Hotels';
import CustomBox from 'modules/market-place/PackageResponse/Details/shared/CustomBox';
import PackageReservationSummary from './Summary';
import Locale from 'translations';

export default function PackageReservationDetails({ details,setPackageDetails,dawnloadPackageInvoice }) {
  const {packages, productsBuilder} = Locale;
  const isMobile = window.innerWidth < 550 ? true : false;
  const itineraryElementsIds = {
    external: 1,
    internal: 2,
    visa: 4,
    insurance: 5,
    hotel: 6,
    sightseeing: 7,
    otherServices: 8
  }
  const HotelElements = () => {
    return details?.product_details?.map((element, index) => {
      if (element?.item?.id === itineraryElementsIds.hotel) {
        return <Hotels details={element} key={index} />;
      }
      return null;
    });
  };
  return (
    <div className="container">
      <div className="row pt-5">
        <div className="d-flex justify-content-between flex-wrap w-100">
          <TitlePackage details={details} />
          <div className="col-md-4">
            <div className='cardtitle  col-12 p-2 mb-1 rounded d-flex justify-content-between align-items-center flex-row'
              style={{ fontSize: "0.875rem", backgroundColor: "#faf2e8" }}
            >
              <span className='font-weight-bold terms-color'>{packages.referenceNo}. </span>
              <span>{details?.reservation_num}</span>
            </div>
            {details?.expire_at ?
              <p className="mb-3">
                Option Date:<span className="font-weight-bold">{details?.expire_at} </span>
              </p>
              :
              null
            }
          </div>
        </div>
        <div className="col-md-8 col-12">
          {/* Package Itinerary */}
          <PackageReservatoinItinerary
            details={details}
            isMobile={isMobile}
            itineraryElementsIds={itineraryElementsIds}
          />
          {/* package hotels */}
          {HotelElements()}
          {/* services */}
          <CustomBox title={packages.services}>
            {/* internal transportation */}
            <p className="d-flex align-items-center mb-2">
              {details?.product_details?.filter(
                (item) => item?.item?.id === itineraryElementsIds.internal
              )?.length > 0 ? (
                <i className="fas fa-check text-success"></i>
              ) : (
                <i className="fas fa-times text-danger"></i>
              )}
              <span className="mx-1">{"Internal Transportation"}</span>
            </p>

            {/* visa */}
            <p className="d-flex align-items-center mb-2">
              {details?.product_details?.filter(
                (item) => item?.item?.id === itineraryElementsIds.visa
              )?.length > 0 ? (
                <i className="fas fa-check text-success"></i>
              ) : (
                <i className="fas fa-times text-danger"></i>
              )}
              <span className="mx-1">{"Visa"}</span>
            </p>

            {/* sightseeing */}
            <p className="d-flex align-items-center mb-2">
              {details?.product_details?.filter(
                (item) => item?.item?.id === itineraryElementsIds.sightseeing
              )?.length > 0 ? (
                <i className="fas fa-check text-success"></i>
              ) : (
                <i className="fas fa-times text-danger"></i>
              )}
              <span className="mx-1">{"Sightseeing"}</span>
            </p>
            {/* other service */}
            <p className="d-flex align-items-center mb-2">
              {details?.product_details?.filter(
                (item) => item?.item?.id === itineraryElementsIds.otherServices
              )?.length > 0 ? (
                <i className="fas fa-check text-success"></i>
              ) : (
                <i className="fas fa-times text-danger"></i>
              )}
              <span className="mx-1">{"Other Services"}</span>
            </p>
          </CustomBox>
          {/* terms and conditions */}
          <CustomBox title={packages.termsAndConditions}>
            <p>
              {details?.share_terms ?? (
                <p className="text-muted text-center">Not Found Data</p>
              )}
            </p>
          </CustomBox>
          {/* required documents */}
          <CustomBox title={productsBuilder.requiredDocuments}>
            {details?.share_required_documents ?? (
              <p className="text-muted text-center">Not Found Data</p>
            )}
          </CustomBox>
        </div>

        <div className='col-md-4 col-12'>
          <PackageReservationSummary details={details} itineraryElementsIds={itineraryElementsIds} setPackageDetails={setPackageDetails}
          dawnloadPackageInvoice={dawnloadPackageInvoice}
          />
        </div>
      </div>
    </div>
  )
}
