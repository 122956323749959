import { useSBSState } from "context/global";
import React from "react";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import SafaLogo from "assets/images/dashboard/safaLogoFull.svg";
import Locale from 'translations';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import AppFooter from 'layouts/AppFooter';


export default function TermsAndConditions() {
	const { locale, isAuth } = useSBSState();
	const { newLand } = Locale;

	return (
		<div className={isAuth ? "" : "pre-auth-layout"}>
			{!isAuth &&
				<div className="container px-3">
					<div className="row m-0 mb-2 align-items-center">
						<figure className="col-6 m-0 hidden-mobile login-page-img">
							<div className="d-flex justify-content-between py-2 align-items-center w-100">
								<Link to="/" className="text-dark">
									<img src={SafaLogo} alt="" width="160px" />
								</Link>

							</div>
						</figure>
						<div className="col-md-6 col-sm-9 d-flex hidden-mobile  flex-column  justify-content-between login-page-contant">
							<div className="d-flex hidden-mobile justify-content-between py-2 align-items-center w-100">
								<Link to="/" className="text-dark linkHeader ">
									{newLand.home}
								</Link>
								<Link to="/about-us" className="text-dark linkHeader">
									{newLand.aboutUs}
								</Link>
								<Link to="/contact-us" className="text-dark linkHeader linkHeaderActive">
									{newLand.contactUs}
								</Link>

								<LanguageSwitcher />
							</div>
						</div>
					</div>
				</div>
			}
			<div className="container" dir={locale === "ar" ? 'rtl' : "ltr"}>
				<header className="w-100 d-flex justify-content-center">
					<img
						src="/safaImage.png"
						alt="Safa Soft Logo"
						className="img-fluid"
						style={{ maxWidth: "400px" }}
					/>
				</header>

				<h1
					className="text-center font-weight-bold mt-5"
					style={{ fontSize: 28, lineHeight: 1.5 }}
				>
					{content[locale].headerTitle.split("\n").map((title, index) => (
						<React.Fragment key={index}>
							{title}
							<br />
						</React.Fragment>
					))
					}
				</h1>

				{content[locale].sections.map((section, index) => (
					<section key={index} className="mx-auto mt-5" style={{ maxWidth: 800 }}>
						<h2 className="mb-3 font-weight-bold">{section.title}</h2>
						{section.description.map((desc, index) => (
							<p key={index} className="mb-3">
								{desc.split("\n").map((line, i) => (
									<React.Fragment key={i}>
										{line === "بقبول الشروط والاحكام يتم اعلان قبولك الاتى:" || line === "You accept:" ? <b>{line}</b> : line}
										<br />
									</React.Fragment>
								))}
							</p>
						))}
					</section>
				))}

				<footer className="text-center font-weight-bold display-4 mt-5">
					{content[locale].thanks}
				</footer>
			</div>

			{!isAuth &&
				<div className="mt-3">
					<AppFooter />
				</div>
			}
		</div>
	);
}

const content = {
	en: {
		headerTitle: "Terms and Conditions \n “Safa Soft - Safa Live” ",
		thanks: "Thanks",
		sections: [
			{
				title: "A) General:",
				description: [
					"THESE TERMS CONSTITUTE A LEGAL AGREEMENT.",
					"By your use of the Service, you agree to comply with all of the terms and conditions set out herein, as amended periodically. Safa Soft may terminate your account at any time, with or without notice, for conduct that is in breach of these Terms, for conduct that we believe is harmful to our business, or for conduct where the use of the Service is harmful to any other party. We may, in our sole discretion, change or modify these Terms at any time, with or without notice. You are responsible to read this document from time to time to ensure that your use of the Service remains in compliance with these Terms.",
				],
			},
			{
				title: "B) Services:",
				description: [
					"We offer to our business partners & agents “Hotels, flight bookings, transportation and travel services” \n Services are provided on the basis of facility and availability. We reserve the right to modify, change, or discontinue any aspect of the Services at any time. Access to the Site is terminated upon expiry or termination of the Service.",
				],
			},
			{
				title: "C) Online System Accessibility:",
				description: [
					"You accept:",
					"1. To use the login and password provided for access and use the online reservation system.",
					"2. he data information from the online reservation system are the property of SAFA SOFT - SAFA LIVE which is strictly confidential and you or any employee working for your esteemed company must not disclose it to any third party without prior written approval from SAFA SOFT - SAFA LIVE.",
					"3. You will have to take full responsibility for the online reservations coming from login ID allocated to you and SAFA SOFT - SAFA LIVE will consider all online reservations generated through the login allocated to you as your fully obligation.",
					"4. If any employee, who has a login / password of the online reservation system, resigns / terminated from your organization, please inform SAFA SOFT - SAFA LIVE immediately so that we can deactivate his / her user login. In case SAFA SOFT - SAFA LIVE is not informed in due course, you will be held responsible for all the bookings made from that login of the resigned / terminated employee.",
				],
			},
			{
				title: "D) Terms of bookings:",
				description: [
					"1. Bookings should be made only for genuine reservations. We reserve the right to cancel bookings which appear to have been made to block or hold spaces even if it is only for visa purposes.",
					"2. A maximum of 9 PAX can be booked online. For bookings above 9 PAX, the request should be provided as a group request. For any group of 10 PAX or more booked online, the agent should confirm the booking with Safa Soft – Safa Live before confirming to their clients, as specific group terms & conditions of the hotel may apply for amendment & cancellation, and the rate may be subject to availability.",
					"3. Reservations of 10 PAX or more clients for the same hotel and the same dates made under different reference numbers may constitute a group reservation, therefore different hotel cancellation policies and different rates may apply.",
					"4. Should the client submit a booking of more than 4 twin/double rooms or more than 9 single rooms per stay, it will be considered a group booking. This applies even if the rooms are booked in several bookings but are clearly part of the same stay.",
					"5. Kindly note that some hotels might differ from the above in the exact number of rooms/guests that they consider a group.",
					"6. In such cases of points (3 & 4), Safa Soft – Safa Live reserves the right to contact the agent in order to cancel the bookings, and new bookings will have to be made.",
					"7. Hotels are classified (as examples) as 5 star, 4 star, 3 star, 2 star, Deluxe, and Standard Apartments. The classification is provided to us by the supplier of the hotel travel component, and we endeavor to validate and authenticate this information. We cannot be held responsible for wrong and inaccurate information provided to us.",
					"8. Images, descriptions, and a list of amenities/facilities are also provided, and this information is obtained from the supplier of the hotel travel component.",
					"9. The number of guests in the booking must equate to the room type, e.g., Single – 1 Adult, Double or Twin – 2 Adults.",
					"10. Triple rates can apply to either a full-size triple room, designed to take three single beds, or to a twin/double room with an extra rollaway bed.",
					"11. If children are being booked, the child’s age must be specified and they may be booked as Child Share with or without an extra bed according to the hotel policy, room setup, and availability.",
					"12. It is your responsibility to check all remarks and special conditions of the bookings.",
					"13. It is your responsibility to ensure that all information in the booking is correct.",
					"14. You will be liable for any charges incurred due to incorrect information given on the booking.",
					"15. All booking cancellations are subject to the cancellation conditions of the booking.",
					"16. All booking amendments are subject to the amendment policy stated on the booking. If within the booking deadline, it will follow the same deadlines & charges as the cancellation condition.",
					"17. Bookings cannot be canceled or amended after the check-in date. A written request should be sent to our operations department to action these requests (subject to availability).",
					"18. A no-show normally attracts 100% charges.",
					"19. Any requests such as early check-in, late check-out, high floors, adjoining, interconnecting, non-smoking rooms, and others will be requested from the hotel but cannot be guaranteed and are subject to availability.",
					"20. Bedding type should be requested at the time of booking and is subject to the hotel’s availability.",
					"21. If a triple room is booked, the extra bed provided by the hotel is commonly a rollaway bed.",
					"22. Any booking quotation obtained from the system does not guarantee the rate until the booking is confirmed, at which point the rate will be locked.",
					"23. A booking is considered to be 'on request' when our allocations are sold out, and communication will be maintained with the concerned supplier until they either confirm or reject the booking.",
					"24. Restricted products cannot be amended or cancelled once confirmed and require immediate payment at the time of booking. Invoices will be issued at the time of booking. Cancellation, amendments or no show will be %100 and under no circumstances will be refunded.",
					"25. n the occasion where hotel is under renovation, all possible steps will be taken to limit disruptions to the guests. If the hotel is carrying out renovation while guests are in-house, this will not entitle you to any discounts or refunds.",
					"26. We guarantee only the first night of the booking. Hotels may release the rooms after midnight on the day of arrival. If your guests are arriving late, it is your responsibility to inform us via remarks, email, or contact the emergency number for last-minute changes, so we can advise the hotel to hold the rooms.",
					"27. Duplicated bookings may result in both bookings being charged.",
					"28. Hotels may request a credit card/cash to be given at the time of check-in as a guarantee for any extras, which they will refund upon check-out. SAFA SOFT - SAFA LIVE is not responsible for any extras incurred by the guests.",
					"29. The hotel has the right to charge a corporate rate if they discover upon arrival that the guest is not a regular FIT (Free Independent Traveler).",
					"30. Booking will not be guaranteed through SAFA SOFT - SAFA LIVE if the booking is made for an event such as a conference, wedding, etc., if the event is organized by a particular organizer.",
					"31. SAFA SOFT - SAFA LIVE is not liable for any loss arising directly or indirectly out of the actions of any hotel, transport company, or any other person providing services comprised in the SAFA SOFT - SAFA LIVE inventory.",
					"32. In situations where the hotel is required to close due to reasons such as overbooking, system errors, or the government taking over the hotel/property for their use, the hotel will endeavor to find suitable alternative accommodation for your guests. The hotel will try to offer alternatives of the same category and location whenever possible. You will be invoiced based on the hotel or accommodation bearing the lower cost.",
					"33. Any booking complaints or account queries should be raised no later than 21 days after the departure date, and any rate dispute should be accompanied by backup documents as proof.",
					"34. All complaints should be sent by e-mail and supported with proof.",
					"35. Any unsupported complaints will not be entertained.",
					"36. Certain bookings may not be subject to modifications and may require canceling the initial booking and creating a new one, though this may not be included in the concerned booking terms and conditions.",
					"37. With regard to the use of the system, the user shall not engage in the following or acts that may constitute the following:",
					// shuold be dots
					"• Acts involving the use of the System for unauthorized purposes.",
					"• Acts linked to crime.",
					"• Acts involving infringement or possible infringement of a copyright, trademark, or similar intellectual property right or publicity right of a third party or property of a third party.",
					"• Acts involving entry of false or unlawful information regarding the reservation of accommodation.",
					"• Acts involving the use of the System while impersonating a third party.",
					"• Acts involving sending or writing of harmful computer programs or similar.",
					"• Acts involving violation of laws, these Terms of Use, or public order and morals.",
					"• Acts involving interference with the operation of the System.",
					"38. “SAFA SOFT - SAFA LIVE may stop part or all of the System without prior notice to the user in any of the following situations:",
					"• When performing maintenance, inspection, or repair of the System",
					"• When it has become or may become difficult to operate the System due to natural disaster, war, rioting, domestic conflict, or other emergency.",
					"• When “SAFA SOFT - SAFA LIVE judges that it is necessary to stop the System for the purposes of operating a home page or the system.",
				],
			},
			{
				title: "E) Safa Soft - Safa Live Privacy Policy",
				description: [
					`“Safa Soft – Safa Live” takes your Privacy concerns seriously and will not share your confidential information with any 3rd party without your consent.`,
					"Using and disclosing confidential Information",
					`Your confidential information and that of your customer will only be used or disclosed as is necessary to provide the services available through the website offered by “Safa Soft - Safa Live” or the organizations identified there, for administrative purposes (including fraud and security checks) and to assist us to improve our website and services, and to provide you with information and offers relevant to our services.`,
					`We will disclose confidential information as required to the providers of the products and services you have purchased for your customer.`,
					`You accept that not all recipients of your information may have privacy policies or be subject to privacy laws equivalent to “Safa Soft - Safa Live” and consent to the disclosure of your confidential information and that of your customer for that purpose.`,
					`“Safa Soft - Safa Live” will not, without your prior consent, disclose confidential information for any purpose other than those purposes described in this Privacy Policy to which you have already consented.`,
					`We may disclose your confidential information or that of your customer where required or authorized by law to do so.`,
				],
			},
			{
				title: "F) Other Terms and Conditions:",
				description: [
					"1. SAFA SOFT - SAFA LIVE will provide you from time to time with information which it considers will be of assistance to you as travel agency such as brochures, photographs & newsletter.",
					"2. SAFA SOFT - SAFA LIVE will investigate any complaint / account query would be received from your side related to bookings generated through SAFA SOFT - SAFA LIVE reservation system and provides you with a response as quickly as possible and will keep you updated.",
					"3. SAFA SOFT - SAFA LIVE shall have the right to suspend credit facilities, cease or decline bookings and cancel future bookings if you exceed the agreed credit limit or fail to pay for the due outstanding balance to SAFA SOFT - SAFA LIVE by any due date.",
					"4. SAFA SOFT - SAFA LIVE shall have the right to terminate this agreement in case of breach of any of its terms and conditions.",
					"5. SAFA SOFT - SAFA LIVE not be liable for any failure to perform any of its obligations to provide services to the Client where it is unable to do so directly due to an event of 'force majeure' which is beyond its reasonable control, including but not limited to fire, flood, earthquake or other natural disaster, pandemic, nuclear disaster, riot, war, terrorist activity, governmental action or labor strike.",
				],
			},
			{
				title: "G) Invoicing",
				description: [
					"• All the invoices will be issued on a regular basis and every booking will be having a particular invoice.",
					"• The invoices will be generated upon vouchering the related service of the booking.",
				],
			},
			{
				title: "H) Payment Mode",
				description: [
					"Unless there is an approved and signed credit application form attached to this agreement, all bookings should be on pre-payment basis online through many options as follow:",
					"- Online payment through credit cards (MasterCard or Visa).",
					"- Safa Credit Wallet.",
					"- Bank Transfer.",
					"- Cash basis.",
				],
			},
			{
				title: "I) Copyrights:",
				description: [
					"The copyright in the contents of this website belong to Safa Soft. Accordingly, Safa Soft reserves all rights.",
					"Copying of part or all the contents of this website without permission of Safa Soft is prohibited except to the extent that such copying is necessary for the purposes of availing of the Services herein.",
				],
			},
		],
	},
	ar: {
		headerTitle: "شروط وأحكام \n “Safa Soft - Safa Live”",
		thanks: "شكراً",
		sections: [
			{
				title: "أ) الأحكام العامة:",
				description: [
					`تشكل هذه الشروط اتفاقية قانونية، واستخدامك للخدمة يعتبر موافقة على الامتثال لجميع الشروط والأحكام المنصوص عليها في هذه الوثيقة، بصيغتها المعدلة دوريًا. ويجوز لشركة صفا سوفت إنهاء حسابك في أي وقت، بإشعار أو بدون إشعار، \n بسبب سلوك ينتهك هذه الشروط، أو بسبب سلوك نعتقد أنه ضار بعملنا، أو بسبب سلوك يكون استخدام الخدمة فيه ضارًا لأي  طرف اخر، كما يجوز لنا، وفقًا لتقديرنا الخاص، تغيير أو تعديل هذه الشروط في أي وقت، بإشعار أو بدون إشعار.
تقع المسؤولية عليك لمراجعة هذه الشروط والأحكام من وقت لآخر للتأكد من أن استخدامك للخدمة يظل متوافقًا مع هذه الشروط.
`,
				],
			},
			{
				title: "ب) الخدمات:",
				description: [
					`نحن نقدم لشركائنا ووكلائنا التجاريين خدمات ”حجوزات الفنادق وحجوزات الطيران وخدمات النقل والسفر“ 
يتم تقديم الخدمات على أساس توافر الامكانية، ونحتفظ بالحق في تعديل أو تغيير أو إيقاف أي جانب الخدمات في أي وقت.`,
				],
			},
			{
				title: "ج) الدخول إلى النظام عبر الإنترنت:",
				description: [
					"بقبول الشروط والاحكام يتم اعلان قبولك الاتى:",
					"1. استخدام معلومات تسجيل الدخول وكلمة المرور لاستخدام نظام الحجز عبر الإنترنت.",
					"2. معلومات البيانات من نظام الحجز عبر الإنترنت هي ملك لشركة Safa Soft - Safa Live وهي سرية للغاية ويجب عليك أو أي موظف يعمل لدى شركتك الموقرة عدم الإفصاح عنها لأي طرف ثالث دون موافقة كتابية مسبقة من صفا سوفت - صفا لايف.",
					"3. يتوجب عليك تحمل المسؤولية الكاملة عن الحجوزات الاونلاين التى نتجت عن استخدام اسم الدخول المخصص لك، وسوف تعتبر صفا سوفت - صفا لايف جميع الحجوزات التي تم إنشاؤها عبر الإنترنت من خلال تسجيل الدخول المخصص لك التزامًا كاملاً منك.",
					"4. في حالة استقالة / إنهاء خدمة أي موظف لديه تسجيل دخول / كلمة مرور لنظام الحجز عبر الإنترنت من شركتك، يرجى إبلاغ شركة صفا سوفت - صفا لايف على الفور حتى نتمكن من إلغاء تفعيل دخول المستخدم الخاص به. في حالة عدم إبلاغ Safa Soft - Safa Live في الوقت المناسب، ستكون مسؤولاً عن جميع الحجوزات التي تمت من خلال اسم المستخدم الخاص بالموظف المستقيل / المنتهية خدمته.",
				],
			},
			{
				title: "د) شروط الحجوزات:",
				description: [
					"1. يجب إجراء الحجوزات للحجوزات الحقيقية فقط. نحن نحتفظ بالحق في إلغاء الحجوزات التي يبدو أنها تمت لحجب أو حجز أماكن حتى لو كان ذلك لغرض التأشيرة فقط.",
					"2. يمكن حجز 9 أشخاص كحد أقصى عبر النظام. بالنسبة للحجوزات التي تزيد عن 9 أشخاص كحد أقصى، يجب تقديم الطلب كحجز مجموعة، وبالنسبة لأي مجموعة مكونة من 10 أشخاص أو أكثر يتم حجزها عبر النظام، يجب على شركة السياحة تأكيد الحجز مع شركة Safa Soft - Safa Live قبل التأكيد لعملائها حيث قد يتم تطبيق شروط وأحكام محددة للمجموعات في الفنادق للتعديل أو الإلغاء وقد يخضع السعر للتعديل على حسب توافر الامكانية.",
					"3. قد تشكل حجوزات (10 عملاء أو أكثر) لنفس الفندق ونفس التواريخ التي تتم تحت أرقام مرجعية مختلفة حجزًا جماعيًا وبالتالي قد يتم تطبيق سياسات إلغاء مختلفة للفندق وتطبيق سعر مختلف.",
					"4. في حالة تقديم العميل حجز أكثر من 4 غرف مزدوجة/مزدوجة أو أكثر من 9 غرف فردية في الإقامة الواحدة سيتم اعتباره حجزًا جماعيًا، وسيكون هذا هو الحال أيضًا حتى إذا تم حجز الغرف في عدة حجوزات ولكن يبدو أنها جزء من نفس الإقامة.",
					"5. يُرجى ملاحظة أن بعض الفنادق قد تختلف عن ما سبق في العدد الدقيق للغرف/الضيوف الذين يعتبرونهم مجموعة.",
					"6. في مثل هذه الحالات من النقاط (3 و4) تحتفظ شركة Safa Soft - Safa Live بالحق في الاتصال بشركة السياحة من أجل إلغاء الحجوزات ويجب إجراء حجز جديد.",
					"7. تُصنَّف الفنادق (على سبيل المثال) إلى 5 نجوم، و4 نجوم، و3 نجوم، ونجمتين، وشقق وعادية. يتم تزويدنا بالتصنيف من قِبل مزودي الخدمات الفندقية ونسعى للتحقق من صحة هذه المعلومات والتحقق من صحتها، ولا يمكن أن نتحمل المسؤولية عن المعلومات الخاطئة وغير الدقيقة المقدمة لنا.",
					"8. يتم أيضًا توفير الصور والخدمات وقائمة بوسائل الراحة/المرافق، ويتم الحصول على هذه المعلومات من قِبل مزودي الخدمات الفندقية.",
					"9. يجب أن يكون عدد النزلاء في الحجز مساويًا لنوع الغرفة، على سبيل المثال فردى( 1 بالغ)، ثنائى( 2 بالغين) وهكذا.",
					"10. يمكن تطبيق أسعار الغرف الثلاثية إما على غرفة ثلاثية بالحجم الكامل، مصممة لاستيعاب ثلاثة أسرّة فردية أو غرفة بسرير ثنائي مع سرير إضافي قابل للطيّ.",
					"11. في حالة حجوزات الأطفال، يجب تحديد عمر الطفل ويمكن حجز الغرفة على أنها تحتوى على سرير إضافي للطفل أو بدون سرير إضافي وفقًا لسياسة الفندق وإعدادات الغرفة وتوافرها.",
					"12. تقع على عاتقك مسؤولية التحقق من جميع الملاحظات والشروط الخاصة بالحجوزات.",
					"13. تقع على عاتقك مسؤولية التأكد من صحة جميع المعلومات الواردة في الحجز.",
					"14. ستكون مسؤولاً عن أي رسوم يتم تحمل دفعها بسبب المعلومات غير الصحيحة الواردة في الحجز.",
					"15. تخضع جميع عمليات الإلغاء للحجوزات لشروط إلغاء الحجز.",
					"16. تخضع جميع تعديلات الحجز لسياسة التعديل المذكورة في الحجز إذا كانت ضمن الموعد النهائي للتعديل، وسوف تتبع نفس المواعيد النهائية والرسوم الخاصة بشرط الإلغاء.",
					"17. لا يمكن إلغاء الحجوزات أو تعديلها بعد تاريخ الدخول للفندق ، يجب إرسال طلب كتابي إلى قسم العمليات لدينا لاتخاذ إجراء بشأن هذه الطلبات (حسب امكانية القبول أو الرفض).",
					"18. عدم الدخول للفندق المحجوز فى اول يوم للحجز عادةً ما يترتب عليه غرامة كاملة لكل مبلغ الحجز بنسبة 100% ولا يوجد إمكانية الاسترجاع.",
					"19. أى طلبات خاصة مثل الدخول المبكر للغرف، تأخير الخروج من الغرف، حجز الغرف فى الطوابق العالية ، حجز غرف متجاورة أو الغرف المتصلة ببعضها داخليًا، وغيرها من الطلبات، تخضع لتوافر الامكانية من الفنادق ولا يمكن تأكيدها بأى حال من الأحوال.",
					"20. يجب طلب نوع الأسرّة عند الحجز ويخضع ذلك لتوافر الإمكانية وقت التسكين في الفندق.",
					"21. إذا تم حجز غرفة ثلاثية، فإن السرير الإضافي الذي يوفره الفندق عادةً ما يكون سريراً قابل للطيّ.",
					"22. أي عرض أسعار للحجوزات يتم الحصول عليه من النظام لا يعني أن السعر مضمون حتى يتم الحجز الفعلي، فى هذه الحالة لن يتم يتم تغيير السعر.",
					"23. تعتبر الحجوزات (تحت الطلب) فى حالة عدم توافر الامكانية، ويتم التواصل مع مزود الخدمات الفندقية حتى يقوم بتأكيد الحجز أو رفضه.",
					"24. لا يمكن تعديل أو إلغاء الحجوزات الغير مسترجعة بمجرد تأكيد الحجز ويجب الدفع الفوري وقت الحجز، وسيتم إصدار الفواتير وقت الحجز. وفى حالة إلغاء الحجز أو تعديله أو عدم الدخول للفندق يتم تطبيق غرامة كاملة بنسبة 100% ولن يتم استرداد المبلغ المدفوع تحت أي ظرف من الظروف.",
					"25. في حالة وجود الفندق قيد التجديد، سيتم اتخاذ جميع الخطوات الممكنة للحد من أي تعطيل للنزلاء. إذا كان الفندق يقوم بالتجديد أثناء وجود النزلاء في الفندق، فإن هذا لن يعطيك الحق في الحصول على أي خصومات أو استرداد الأموال.",
					"26. نضمن لك الليلة الأولى فقط من الحجز. قد تقوم الفنادق بإلغاء الحجز بعد منتصف الليل في يوم الوصول إذا لم يدخل العميل إلى الفندق، إذا كان ضيوفك سيصلون في وقت متأخر، تقع على عاتقك مسؤولية إبلاغنا عبر الملاحظات أو البريد الإلكتروني أو الاتصال برقم الطوارئ لإعلامنا بالتغييرات حتى نتمكن من إبلاغ الفندق بحجز الغرف حتى وصول العميل في الوقت المحدد.",
					"27. قد يترتب اللإلغاء على الحجوزات المتطابقة (Duplicate) وفرض رسوم اللإلغاء عليهم جميعا.",
					"28. قد تطلب الفنادق من العميل تقديم بطاقة ائتمان أو تطلب الدفع النقدي عند الوصول كضمان لأي إضافات يطلبها العميل فى الغرفة وسيتم ردها للعميل عند المغادرة وشركة ”Safa Soft - Safa Live“ غير مسؤولة عن أي إضافات يتحمل مسؤوليتها النزلاء.",
					"29. يحق للفندق فرض رسوم على أسعار الشركات إذا علموا أن النزيل ليس من النزلاء المنتظمين عند الوصول.",
					"30. لن يكون الحجز مضمونًا من خلال ”Safa Soft - Safa Live“  في حالة الحجز للأحداث الخاصة  مثل المؤتمرات أو حفلات الزفاف وما إلى ذلك، او إذا كان حدث يتطلب التنظيم من خلال منظم معين.",
					"31. ”Safa Soft - Safa Live“  غير مسؤولة عن أي خسارة من أي نوع تنشأ بشكل مباشر أو غير مباشر عن تصرف أي فندق أو شركة نقل أو أي شخص آخر يقدم الخدمات التي يتضمنها ”Safa Soft - Safa Live“ ",
					"32. في الحالات التي يُطلب فيها إغلاق الفندق لأسباب مثل الحجز الزائد، أو أخطاء النظام، أو الحجز الحكومي للفندق / العقار، سيسعى الفندق إلى إيجاد مكان إقامة بديل مناسب لضيوفك. سيحاول الفندق تقديم بديل من نفس الفئة والموقع كلما أمكن ذلك. وسيتم إصدار فاتورة حسابية لك حسب الفندق أو مكان الإقامة الذي يتحمل التكلفة الأقل.",
					"33. يجب تقديم أي شكاوى حجز أو استفسارات عن الحساب في موعد أقصاه 21 يوماً بعد تاريخ المغادرة مع الأخذ في الاعتبار أن أي نزاع على السعر يجب أن يكون مصحوبًا بمستندات كدليل.",
					"34. يجب إرسال جميع الشكاوى عن طريق البريد الإلكتروني مدعومة بالإثباتات.",
					"35. لن يتم قبول أي شكاوى غير مدعومة بمستندات.",
					"36. قد لا تخضع بعض الحجوزات للتعديل، وسيتطلب الأمر إلغاء الحجز الأولي وإنشاء حجز جديد، ولكن قد لا يكون ذلك مدرجًا في شروط وأحكام الحجز المعنية.",
					"37. فيما يتعلق باستخدام النظام، لا يجوز للمستخدم المشاركة في ما يلي أو الأفعال التي قد تشكل ما يلي:",
					// shuold be dots
					"• الأفعال التي تنطوي على استخدام النظام لأغراض غير مصرح بها.",
					"• الأفعال المرتبطة بالجريمة.",
					"• الأفعال التي تنطوي على انتهاك أو انتهاك محتمل لحقوق الطبع والنشر أو العلامة التجارية أو حقوق الملكية الفكرية المماثلة أو حق الدعاية لطرف ثالث أو ملكية طرف ثالث",
					"• الأفعال التي تنطوي على إدخال معلومات خاطئة أو غير قانونية فيما يتعلق بحجز مكان الإقامة",
					"• الأعمال التي تنطوي على استخدام النظام عن طريق طرف ثالث",
					"• الأفعال التي تنطوي على إرسال أو كتابة برامج كمبيوتر ضارة أو ما شابه ذلك",
					"• الأعمال التي تنطوي على انتهاك القوانين أو شروط الاستخدام هذه أو النظام العام والآداب العامة",
					"• الأعمال التي تنطوي على التدخل في تشغيل النظام",
					"38. ”Safa Soft - Safa Live“ قد تُوقِف جزء من النظام أو كله دون إشعار مسبق للمستخدم في أي من الحالات التالية",
					"• عند إجراء الصيانة اللازمة أو الفحص أو إصلاح النظام",
					"• عندما يصبح أو قد يصبح من الصعب تشغيل النظام بسبب كارثة طبيعية أو حرب أو أعمال شغب أو نزاع محلي أو أي حالة طوارئ أخرى.",
				],
			},
			{
				title: "هـ) سياسة الخصوصية الخاصة بشركة”Safa Soft - Safa Live“",
				description: [
					`تأخذ شركة  ”Safa Soft - Safa Live“ مخاوفك المتعلقة بالخصوصية على محمل الجد، ولن تقوم بمشاركة معلوماتك السرية مع أي طرف ثالث دون موافقتك.`,
					"استخدام المعلومات السرية والإفصاح عنها",
					`لن يتم استخدام معلوماتك السرية ومعلومات عميلك السرية أو الإفصاح عنها إلا بما هو ضروري لتوفير الخدمات المتاحة من خلال الموقع الإلكتروني الذي تقدمه  ”Safa Soft - Safa Live“ أو المؤسسات المحددة فيه، وذلك لأغراض إدارية (بما في ذلك عمليات التحقق من الاحتيال والتحقق من الأمن) ولمساعدتنا في تحسين موقعنا وخدماتنا، ولتزويدك بالمعلومات والعروض ذات الصلة بخدماتنا.`,
					`سنقوم بالإفصاح عن المعلومات السرية على النحو المطلوب لمقدمي المنتجات والخدمات التي اشتريتها لعميلك.`,
					`أنت توافق على أنه قد لا يكون لدى جميع المتلقين لمعلوماتك سياسات خصوصية أو قد يخضعون لقوانين خصوصية معادلة لقوانين الخصوصية الخاصة بـ  ”Safa Soft - Safa Live“ وتوافق على الإفصاح عن معلوماتك السرية ومعلومات عميلك لهذا الغرض.`,
					`لن تقوم  ”Safa Soft - Safa Live“، دون موافقتك المسبقة، بالإفصاح عن معلوماتك السرية لأي غرض آخر غير الأغراض الموضحة في سياسة الخصوصية هذه والتي سبق لك الموافقة عليها.`,
					`ويجوز لنا الإفصاح عن معلوماتك السرية أو معلومات عميلك حيثما يقتضي القانون أو يصرح بذلك.`,
				],
			},
			{
				title: "و) شروط وأحكام أخرى:",
				description: [
					"1. سوف تقوم ”Safa Soft - Safa Live“ بتزويدك من وقت لآخر بالمعلومات التي ترى أنها ستكون مفيدة لك كوكالة سفر مثل الكتيبات والصور الفوتوغرافية والنشرات الإخبارية.",
					"2. سوف تقوم  ”Safa Soft - Safa Live“ بالتحقيق في أي شكوى / استفسار عن الحساب سيتم استلامه من جانبك فيما يتعلق بالحجوزات التي تم إنشاؤها من خلال نظام الحجز  ”Safa Soft - Safa Live“ وتزويدك بالرد في أسرع وقت ممكن وسوف تبقيك على اطلاع دائم.",
					"3. يحق لشركة ”Safa Soft - Safa Live“ تعليق التسهيلات الائتمانية وإيقاف أو رفض الحجوزات وإلغاء الحجوزات المستقبلية إذا تجاوزت الحد الائتماني المتفق عليه أو لم تسدد الرصيد المستحق ل  ”Safa Soft - Safa Live“في أي تاريخ استحقاق.",
					"4. يحق لشركة  ”Safa Soft - Safa Live“إنهاء هذه الاتفاقية في حالة الإخلال بأي من شروطها وأحكامها.",
					"5. لا تكون  ”Safa Soft - Safa Live“ مسؤولة عن أي فشل في أداء أي من التزاماتها في تقديم الخدمات للعميل في حالة عدم قدرتها على القيام بذلك مباشرة بسبب احداث او ”قوة إجبارية“ خارجة عن سيطرتها المعقولة، بما في ذلك على سبيل المثال لا الحصر الحرائق أو الفيضانات أو الزلازل أو غيرها من الكوارث الطبيعية أو الأوبئة أو الكوارث النووية أو أعمال الشغب أو الحروب أو الأنشطة الإرهابية أو الإجراءات الحكومية أو الإضرابات العمالية.",
				],
			},
			{
				title: "ز) الفواتير:",
				description: [
					"• سيتم إصدار جميع الفواتير بشكل منتظم وسيكون لكل حجز فاتورة معينة.",
					"• سيتم إصدار الفواتير عند إصدار قسيمة الخدمة ذات الصلة بالحجز.",
				],
			},
			{
				title: "ح) طريقة الدفع:",
				description: [
					"ما لم يكن هناك نموذج طلب ائتمان معتمد وموقع مرفق بهذه الاتفاقية، يجب أن تكون جميع الحجوزات على أساس الدفع المسبق عبر الإنترنت من خلال العديد من الخيارات على النحو التالي",
					"- الدفع عبر الإنترنت من خلال بطاقات الائتمان (ماستر كارد أو فيزا).",
					"- محفظة الصفا الائتمانية.",
					"- التحويل المصرفي.",
					"- الدفع نقدًا.",
				],
			},
			{
				title: "ط) حقوق التأليف والنشر:",
				description: [
					"حقوق الطبع والنشر في محتويات هذا الموقع الإلكتروني مملوكة لشركة Safa Soft . وبناءًا على ذلك، تحتفظ Safa Soft بجميع الحقوق.",
					"يحظر نسخ جزء من أو كل محتويات هذا الموقع الإلكتروني دون إذن من شركة Safa Soft إلا بالقدر الذي يكون فيه هذا النسخ ضروريًا لأغراض الاستفادة من الخدمات الواردة في هذه الوثيقة.",
				],
			},
		],
	},
};
