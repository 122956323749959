import React from "react";
import singleTemplet from "assets/images/promo/single-hotel.png";
import multipleTemplet from "assets/images/promo/multiple-hotels.png";
import {
	TEMPLETE_TYPE_MULTIPLE,
TEMPLETE_TYPE_SINGLE
} from "../helpers/globales";
import Locale from 'translations';

export function SelecetTemplate({ templateType, templateTypeHandler }) {
	const { marketPlace } = Locale;

	return (
		<div>
			<h3 className="h3">{marketPlace.SelectFavorite}</h3>
			<div className="templete-image-input-container">
				<label
					className={`templete-image-radio ${templateType === TEMPLETE_TYPE_MULTIPLE ? "checked-active" : ""}`}
					htmlFor="multipleRadios"
				>
					<img
						className="m-auto img-fluid"
						src={multipleTemplet}
						alt="multipleTemplet" />
					<input
						type="radio"
						name="multipleRadios"
						id="multipleRadios"
						value={TEMPLETE_TYPE_MULTIPLE}
						checked={templateType === TEMPLETE_TYPE_MULTIPLE}
						onChange={templateTypeHandler} />
				</label>

				<label
					className={`templete-image-radio px-2 ${templateType === TEMPLETE_TYPE_SINGLE ? "checked-active" : ""}`}
					htmlFor="singleRadios"
				>
					<img
						className="m-auto img-fluid"
						src={singleTemplet}
						alt="singleTemplet" />
					<input
						type="radio"
						name="singleRadios"
						id="singleRadios"
						value={TEMPLETE_TYPE_SINGLE}
						checked={templateType === TEMPLETE_TYPE_SINGLE}
						onChange={templateTypeHandler} />
				</label>
			</div>
		</div>
	);
}
