import axios from 'axios'
import { cleanEmpty } from './general'
const productBuilderURL =
	process.env.REACT_APP_PRODUCT_BUILDER_BREAKDOWN + '/products'
const productBuilderURLV2 =
	process.env.REACT_APP_PRODUCT_BUILDER_BREAKDOWN + '/v2' + '/products'
const baseURL = process.env.REACT_APP_API_URL;
const operationStatementURL = baseURL + "/v1/operation-statement";


export const createProduct = async (data) => {
	return await axios
		.post(`${productBuilderURL}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response)
}

export const getAllProducts = async (data, page = 1) => {
	return await axios
		.post(
			`${process.env.REACT_APP_PRODUCTS_BUILDER}/products_list?page=${page}`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const getSingleProduct = async (id) => {
	return await axios
		.get(`${productBuilderURL}/${id}`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const getSingleProductPricing = async (id) => {
	return await axios
		.get(`${productBuilderURL}/${id}/pricing-info`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const getIteneraryProduct = async (id) => {
	return await axios
		.get(`${productBuilderURL}/${id}/itenerary`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const deleteProduct = async (id) => {
	return await axios
		.delete(`${productBuilderURL}/${id}`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const unpublishedProduct = async (id) => {
	return await axios
		.post(`${productBuilderURL}/${id}/unpublish`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const cloneProduct = async (id) => {
	return await axios
		.post(`${productBuilderURL}/${id}/clone`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const updateProduct = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response)
}

export const addProductsPrice = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/price`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response)
}

export const deleteItem = async (uuid, id) => {
	return await axios
		.delete(`${productBuilderURL}/${uuid}/items/${id}`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const deleteItemInternal = async (uuid, id) => {
	return await axios
		.get(`${productBuilderURLV2}/${uuid}/internal-transportations/delete`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const createHotel = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/hotels`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response)
}
export const updateHotel = async (id, hotel_id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/hotels/${hotel_id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response)
}

export const getFullCycle = async (id) => {
	return await axios
		.get(`${productBuilderURLV2}/${id}/internal-transportations/get-full-cycle`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const createExternal = async (id, data) => {
	return await axios
		.post(
			`${productBuilderURL}/${id}/external-transportations`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const updateExternal = async (id, compId, data) => {
	return await axios
		.post(
			`${productBuilderURL}/${id}/update-external-transportations`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const createDomestic = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/domestics`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response)
}

export const updateDomestic = async (id, compId, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/domestics/${compId}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response)
}

export const createInternal = async (id, data) => {
	return await axios
		.post(
			`${productBuilderURL}/${id}/internal-transportations`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const updateInternal = async (id, compId, data) => {
	return await axios
		.post(
			`${productBuilderURL}/${id}/internal-transportations/${compId}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const excludeInternalTransportations = async (id) => {
	return await axios
		.get(`${productBuilderURL}/${id}/exclude-internal-transportations`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const createLandmark = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/sightseeings`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response)
}
export const updateLandmark = async (id, compId, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/sightseeings/${compId}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response)
}

export const createVisa = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/visa`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const updateVisa = async (id, compId, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/visa/${compId}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response)
}

export const createInsurance = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/insurance`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const updateInsurance = async (id, compId, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/insurance/${compId}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response)
}

export const createOther = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/other-services`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const updateOther = async (id, compId, data) => {
	return await axios
		.post(
			`${productBuilderURL}/${id}/other-services/${compId}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const getHotelMapToolHotels = async (param) => {
	return await axios
		.get(`${baseURL}/trip-management/hotels`, { params: cleanEmpty(param) })
		.then((res) => res.data)
		.catch((err) => err.response)
}

export const getInventoryHotels = async (param) => {
	return await axios
		.get(`${baseURL}/trip-management/inventory_hotels`, {
			params: cleanEmpty(param)
		})
		.then((res) => res.data)
		.catch((err) => err.response)
}

export const getAllCurrencies = async () => {
	return await axios
		.get(`${baseURL}/trip-management/get-all-currencies`)
		.then((res) => res.data)
		.catch((err) => err.response)
}

export const paymentActionDone = async (param, tap) => {
	return await axios
		.post(`${baseURL}/online-payment/webhook/${tap}`, cleanEmpty(param))
		.then((res) => res.data)
		.catch((err) => err.response)
}
export const sharePackage = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/publish`, data)
		.then((res) => res)
		.catch((err) => err.response)
}

export const productsListForApplication = async (data) => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/list-products-for-application`, {
			params: data
		})
		.then((res) => res)
		.catch((err) => err.response)
}

export const getApplicationHistory = async (id) => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/applications/` + id + '/history')
		.then((res) => res)
		.catch((err) => err.response)
}

export const addApplication = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/add-application`, data)
		.then((res) => res)
		.catch((err) => err.response)
}

export const requestsListReservationsB2b = async (param) => {
	return await axios
		.get(`${baseURL}/v1/b2b/packages/list-reservations`, {
			params: cleanEmpty(param)
		})
		.then((res) => res)
		.catch((err) => err.response)
}
export const requestViewReservationB2b = async (id) => {
	return await axios
		.get(`${baseURL}/v1/b2b/packages/view-reservations/${id}`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const requestsListReservations = async (param) => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/list-reservations`, {
			params: cleanEmpty(param)
		})
		.then((res) => res)
		.catch((err) => err.response)
}
export const getPassengers = async (reservation_num) => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/list-travelers/${reservation_num}`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const addPassanger = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/add-traveler`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const requestViewReservation = async (id) => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/view-reservations/${id}`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const packageExtraService = async (id) => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/applications/${id}/extra-services`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const addPackageExtraService = async (id, data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/applications/${id}/extra-services`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const editPackageExtraService = async (id, serviceId, data) => {
	return await axios
		.put(
			`${baseURL}/v1/b2c/packages/applications/${id}/extra-services/${serviceId}`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response)
}
export const deletePackageExtraService = async (id, serviceId) => {
	return await axios
		.delete(
			`${baseURL}/v1/b2c/packages/applications/${id}/extra-services/${serviceId}`
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const changeRequestStatus = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/accept-reject-request`, data)
		.then((res) => res)
		.catch((err) => err.response)
}

export const addCallLog = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/add-call-log`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const addPackageAvailabilities = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/add-package-availabilities`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const editPackageAvailabilities = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/edit-package-availabilities`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const setPackageAvailabilities = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/set-package-availabilities`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const setPackageRoomAvailabilities = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/set-package-room-availabilities`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const addPayments = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/add-payment`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const deletePayment = async (id) => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/delete-payment/${id}`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const editPassanger = async (id, data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/edit-traveler/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const deletePassanger = async (id) => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/delete-traveler/${id}`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const getCompanies = async (data) => {
	return await axios
		.get(`${baseURL}/v1/lookups/companies`, {
			params: data
		})
		.then((res) => res)
		.catch((err) => err.response)
}

export const addBus = async (data) => {
	return await axios
		.post(`${baseURL}/trip-management/bus/add`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const editBus = async (id, data) => {
	return await axios
		.put(`${baseURL}/trip-management/bus/edit/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const deleteBus = async (id) => {
	return await axios
		.delete(`${baseURL}/trip-management/bus/delete/${id}`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const assignBusTravelers = async (data) => {
	return await axios
		.post(`${baseURL}/trip-management/bus/assign-travellers`, data)
		.then((res) => res)
		.catch((err) => err.response)
}

export const hotelAccommodationListHotelTravelers = async (product_uuid) => {
	return await axios
		.get(
			`${baseURL}/trip-management/hotel-accommodation/list-hotel-travelers/${product_uuid}`
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const editRoomAccommodation = async (data) => {
	return await axios
		.post(
			`${baseURL}/trip-management/hotel-accommodation/edit-room-accommodation`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const assignTravelers = async (data) => {
	return await axios
		.post(
			`${baseURL}/trip-management/hotel-accommodation/assign-travelers`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response)
}
export const fetchHotelRooming = async (hotel_id) => {
	return await axios
		.get(
			`${baseURL}/trip-management/hotel-accommodation/hotel-rooming-report/${hotel_id}`
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const fetchRoomingTravellers = async (id) => {
	return await axios
		.get(
			`${baseURL}/trip-management/hotel-accommodation/rooming-travellers/hotel/${id}`
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const fetchHotelDistribution = async (product_id) => {
	return await axios
		.get(
			`${baseURL}/trip-management/hotel-accommodation/hotel-distribution-report/${product_id}`
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const fetchApplicationReport = async (id) => {
	return await axios.get(
		`${baseURL}/v1/b2c/packages/applications/${id}/request-form`
	)
}

export const fetchApplicationReservationReport = async (id) => {
	return await axios.get(
		`${baseURL}/v1/b2c/packages/applications/${id}/request-pre-reservation-form`
	)
}
export const downloadPackageAmountDocuments = async (id) => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/applications/amount-statement/${id}`, {
			responseType: 'blob'
		})
		.then((res) => res)
		.catch((err) => err.response)
}

export const downloadPackagebranchDocuments = async () => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/applications/amount-branch-statement`, {
			responseType: 'blob'
		})
		.then((res) => res)
		.catch((err) => err.response)
}

export const FetchProductLookupd = async () => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/product-lookups`)
		.then((res) => res)
		.catch((err) => err.response)
}
/////// Attraction verison 2 //////////////

export const createSightseeings = async (uuid, data) => {
	return await axios
		.post(`${productBuilderURLV2}/${uuid}/sightseeings`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response)
}
export const updateSightseeings = async (uuid, elementId, data) => {
	return await axios
		.post(
			`${productBuilderURLV2}/${uuid}/sightseeings/${elementId}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const createInternalTrans = async (uuid, data) => {
	return await axios
		.post(
			`${productBuilderURLV2}/${uuid}/internal-transportations`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response)
}
export const updateInternalTrans = async (uuid, data) => {
	return await axios
		.post(
			`${productBuilderURLV2}/${uuid}/internal-transportations/edit`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const downloadBusDocuments = async (id) => {
	return await axios
		.get(`${baseURL}/trip-management/bus/get-report/${id}`)
		.then((res) => res)
		.catch((err) => err.response)
}



export const fetchRoomHotelReport = async (uuid, hotel_id) => {
	return await axios
		.get(
			`${baseURL}/trip-management/hotel-accommodation/hotel-rooming-report/${uuid}?hotel_id=${hotel_id}`
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const fetchRoomHotelsReport = async (uuid) => {
	return await axios
		.get(
			`${baseURL}/trip-management/hotel-accommodation/hotel-rooming-report/${uuid}`
		)
		.then((res) => res)
		.catch((err) => err.response)
}

export const webPageRequests = async (param) => {
	return await axios
		.get(`${baseURL}/v1/b2b/packages/list-requests`, {
			params: cleanEmpty(param)
		})
		.then((res) => res)
		.catch((err) => err.response)
}

export const generateStatementFromProduct = async (data) => {
	return await axios
		.post(`${operationStatementURL}/statements/generate-from-product`, data)
		.then((res) => res)
		.catch((err) => err.response)
}




export const downloadtripDocuments = async (id, transportprovider) => {
	return await axios

		.get(`${process.env.REACT_APP_PRODUCTS_BUILDER}/reports/flights/${id}?transporter=${transportprovider}`, { responseType: "blob" })
		.then((res) => res)
		.catch((err) => err.response)
}


export const downloadtripDocumentsexcel = async (id, type) => {
	return await axios

		.get(`${process.env.REACT_APP_PRODUCTS_BUILDER}/reports/excel/${id}?type=${type}`, { responseType: "blob" })
		.then((res) => res)
		.catch((err) => err.response)
}



export const downloadtriptravelers = async (id) => {
	return await axios

		.get(`${process.env.REACT_APP_PRODUCTS_BUILDER}/reports/travelers/${id}`)
		.then((res) => res)
		.catch((err) => err.response)
}


export const downloadtripoperation = async (id) => {
	return await axios

		.get(`${process.env.REACT_APP_PRODUCTS_BUILDER}/reports/operation/${id}`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const addGroupReservation = async (uuid, data) => {
	return await axios
		.post(`${process.env.REACT_APP_PRODUCTS_BUILDER}/add-group-reservation/${uuid}`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
