import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import EditableParagraph from "modules/WebBuilder/shared/EditableParagraph";
import React from "react";

function HeroContent() {
	const { hero, selectedLanguage } = useWebBuilderState();
	const dispatch = useWebBuilderDispatch();
	const mainContent = hero?.mainContent;
	const updateHero = ({ name, value, type, language }) => {
		dispatch({
			type: "updateHeroContent",
			payload: {
				name,
				value,
				type,
				language
			},
		});
	};
	const bgImage = hero?.mainContent?.backgroundImage ?? "";
	const layoutType = hero?.mainContent?.layout?.layoutType;

	return (
		<div className={`web-builder-hero-content  content-layout-${layoutType}`}>
			{layoutType === 2 || layoutType === 3 ? (
				<img src={bgImage} alt="" className="img-fluid" />
			) : null}
			<div className="text-center hero-content">
				<EditableParagraph
					className="header h3"
					contentEditable={true}
					onInput={(value) => {
						updateHero({ name: `title`, value, type: null, language: selectedLanguage });
					}}
					text={mainContent?.title[selectedLanguage]}
				/>
				<EditableParagraph
					className="body"
					contentEditable={true}
					onInput={(value) => {
						updateHero({ name: `body`, value, type: null, language: selectedLanguage });
					}}
					text={mainContent?.body[selectedLanguage]}
				/>
			</div>
		</div>
	);
}

export default HeroContent;
