import React, { useEffect, useState } from 'react'
import SearchComponent from '../components/Search'
import { EditIcon } from 'modules/UmrahOperations/shared/Icons'
import { ReactComponent as DeleteIcon } from 'assets/images/crm/delete.svg'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Modal, ModalBody } from 'reactstrap'
import validate, { isFormValid } from 'helpers/validate'
import {
	addPackageExtraService,
	deletePackageExtraService,
	editPackageExtraService
} from 'services/productbuilder'
import TextField from 'components/Form/TextField/TextField'
import { ReactComponent as CloseIcon } from "assets/images/close.svg";
import { store } from 'react-notifications-component'
import { formatPrice } from 'helpers/utils'



const initialData = {
	name: '',
	quantity: '',
	price: ''
}

const ExtraServices = ({ extraServicesData, setReload }) => {
	const { id } = useParams();


	// ** states
	const [filteredServices, setFilteredServices] = useState(extraServicesData);
	const [searchText, setSearchText] = useState("")
	const [modalStatus, setModalStatus] = useState({
		isOpen: false,
		type: null,
		data: initialData
	})
	
	const [errors, setErrors] = useState({})
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);


	// ** toggle
	const toggle = (type = null, data = initialData) => {
		setModalStatus((prev) => ({
			data,
			type,
			isOpen: !prev.isOpen
		}))
	}

	const handleServiceInputs = ({ key, value }) => {
		setModalStatus((prev) => ({
			...prev,
			data: { ...prev.data, [key]: value }
		}))
		setErrors({
			...errors,
			...validate(
				{
					name: key,
					value
				},
				{ required: true }
			)
		})
	}

	function checkFormErrors() {
		let submitError = {}
		const data = modalStatus.data
		Object.keys(data).forEach((key) => {
			submitError = {
				...submitError,
				...validate({ name: key, value: data[key] }, { required: true })
			}
		})
		setErrors(() => submitError)
	}

	function submit() {
		checkFormErrors()
		if (!isErrorLoaded) {
			setIsErrorLoaded(true)
		} else {
			setIsErrorLoaded(false)
		}
	}
	// delete services integration
	const onDeleteservice = async (serviceId) => {
		const res = await deletePackageExtraService(id, serviceId)
		if (res.status === 200) {
			store.addNotification({
				title: "info!",
				message: "Service deleted Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setReload(prev => !prev)
		}
	}
	// add new service or edit existing service integration
	const addEditService = async () => {
		const res =
			modalStatus.type === 'edit'
				? await editPackageExtraService(
					id,
					modalStatus.data.id,
					modalStatus.data
				)
				: await addPackageExtraService(id, modalStatus.data)

		if (res.status === 200) {
			store.addNotification({
				title: "info!",
				message: modalStatus.type === "edit" ? "Service Edited Successfully" : "Service added Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			toggle();
			setReload(prev => !prev)
		}
	}

	function searchByServiceName(value) {
		value = value.toLowerCase();
		if (value !== "") {
			const filter = extraServicesData?.filter((service) => {
				return (
					service?.name.toLowerCase().includes(value) && service
				);
			});
			setFilteredServices(filter);
		} else {
			setFilteredServices(extraServicesData);
		}
		setSearchText(value);
	}

	useEffect(() => {
		if (isFormValid(errors)) {
			addEditService()
		}
	}, [isErrorLoaded])

	useEffect(() => {
		setFilteredServices(extraServicesData)
	}, [extraServicesData])



	return (
		<>
			<div className='extra-service-wrapper'>

				<SearchComponent onChangeHandler={searchByServiceName} value={searchText} buttonAction={() => toggle('add')} />

				<div className='table-responsive table-bordered'>
					<table className='table'>
						<thead className='thead-light'>
							<tr>
								<th className='scope'>Name</th>
								<th className='scope'>Quantity</th>
								<th className='scope'>Price</th>
								<th className='scope'>Actions</th>
							</tr>
						</thead>
						<tbody>
							{filteredServices?.length > 0 ?
								filteredServices?.map((ele) => (
								<tr key={ele?.id} className="py-3">
									<td>{ele?.name}</td>
									<td>{ele?.quantity}</td>
									<td>
										{formatPrice(ele?.price)} {ele?.currency ? ele?.currency : ''}
									</td>
									<td>
										<button className='btn p-0' onClick={() => toggle('edit', ele)}>
											<EditIcon color='#d4a655' />
										</button>
										
										<button className='btn p-0 mx-2' onClick={() => onDeleteservice(ele?.id)}>
											<DeleteIcon />
										</button>
									</td>
								</tr>
							))
							:
							<tr>
								<td colSpan="4" className="text-center">No Services</td>
							</tr>
						}
						</tbody>
					</table>
				</div>
			</div>


			{modalStatus.isOpen ?
				<Modal
					isOpen={modalStatus.isOpen}
					size='md'
					centered={true}
					hasFooter={false}
					className='application-form'
				>
					<div className='extra-service-modal'>
						<div className='extra-service-modal-header'>
							<div />
							<p>Extra Services</p>
							<CloseIcon
								className='pointer opacity'
								onClick={() => toggle()}
							/>
						</div>
						<hr />
						<ModalBody>
							<div className='row' style={{ gap: '16px' }}>
								<div className='col-md-12'>
									<TextField
										label='Service Name'
										placeholder='Service Name'
										value={modalStatus.data.name}
										onChange={(e) => {
											const value = e.target.value
											handleServiceInputs({ key: 'name', value })
										}}
										color={errors?.name?.required ? 'danger' : ''}
										errors={errors?.name}
									/>
								</div>
							</div>

							<div className='row'>
								<div className='col-md-6'>
									<TextField
										type='number'
										label='Quantity'
										placeholder='Quantity'
										value={modalStatus.data.quantity}
										onChange={(e) => {
											const value = e.target.value
												? +e.target.value
												: e.target.value
											handleServiceInputs({ key: 'quantity', value })
										}}
										color={errors?.quantity?.required ? 'danger' : ''}
										errors={errors?.quantity}
									/>
								</div>
								<div className='col-md-6'>
									<TextField
										type='number'
										label='Price'
										placeholder='Price'
										value={modalStatus.data.price}
										onChange={(e) => {
											const value = e.target.value
												? +e.target.value
												: e.target.value
											handleServiceInputs({ key: 'price', value })
										}}
										color={errors?.price?.required ? 'danger' : ''}
										errors={errors?.price}
									/>
								</div>
								<div className='col-md-12 mt-3'>
									<button
										onClick={submit}
										className='btn crm-button-add btn-block'
									>
										{modalStatus.type === 'add' ? 'Add' : 'Edit'}
									</button>
								</div>
							</div>
						</ModalBody>
					</div>
				</Modal>
				:
				null
			}

		</>
	)
}

export default ExtraServices
