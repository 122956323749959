import { useEffect, useState } from 'react';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField'
import moment from 'moment';
import CheckBox from 'components/chekbox'
// helpers
import validate, { isFormValid } from 'helpers/validate';
import { tripsDurationDates } from 'services/tripPlanner';
import { useParams } from 'react-router-dom';
import { formatTripPlannerData, successfulResponse } from 'modules/TripPlanner/helpers/formatTripPlannerData';
import { AddIcon } from '../Icons';
import Locale from 'translations';



let requiredKeys = ["start_date", "end_date"];


export default function TripPlannerSettingDates({ tripPlannerData, setTripPlannerData }) {
  // ** hooks
  const { packagePlanner, operationStatement } = Locale;
  const { id } = useParams();

  const settingDates = tripPlannerData?.setting_dates;

  // states
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  // ** logic functions
  function handleInputChanges({ key, value }) {
    let settingDatesClone = { ...tripPlannerData?.setting_dates }
    if (key === "exclude_type" && value === "days_off") {
      settingDatesClone = { ...settingDatesClone, [key]: value, dates_off: [] };
    } else if (key === "exclude_type" && value === "dates_off") {
      settingDatesClone = { ...settingDatesClone, [key]: value, days_off: [] };
    } else if (key === "start_date" && moment(value).isAfter(moment(settingDates?.end_date))) {
      settingDatesClone = { ...settingDatesClone, [key]: value, end_date: null };
    } else {
      settingDatesClone = { ...settingDatesClone, [key]: value };
    }

    setTripPlannerData({ ...tripPlannerData, setting_dates: settingDatesClone });

    setErrors({
      ...errors,
      ...validate(
        { name: key, value },
        { required: requiredKeys.includes(key) ? true : false }
      ),
    });
  }

  function addDateOff() {
    let settingDatesClone = { ...tripPlannerData?.setting_dates }
    settingDatesClone = { ...settingDatesClone, dates_off: [...settingDatesClone?.dates_off, ""] };
    setTripPlannerData({ ...tripPlannerData, setting_dates: settingDatesClone })
  }

  function removeDateOff(dateIndex) {
    let settingDatesClone = { ...tripPlannerData?.setting_dates }
    let datesOff = settingDatesClone?.dates_off;
    datesOff.splice(dateIndex, 1);
    settingDatesClone = { ...settingDatesClone, dates_of: datesOff };
    setTripPlannerData({ ...tripPlannerData, setting_dates: settingDatesClone })
  }

  function handleSelectDays(checked, value) {
    let settingDatesClone = { ...tripPlannerData?.setting_dates };
    let daysOff;
    if (checked) {
      daysOff = settingDatesClone?.days_off?.filter(day => day !== value);
    } else {
      daysOff = [...settingDatesClone?.days_off, value]
    }
    settingDatesClone = { ...settingDatesClone, days_off: daysOff };
    setTripPlannerData({ ...tripPlannerData, setting_dates: settingDatesClone })
  }

  function handleChangeDatesOff({ dateIndex, value }) {
    let settingDatesClone = { ...tripPlannerData?.setting_dates }
    let datesOff = settingDatesClone?.dates_off;
    datesOff[dateIndex] = value;
    settingDatesClone = { ...settingDatesClone, dates_of: datesOff };
    setTripPlannerData({ ...tripPlannerData, setting_dates: settingDatesClone })
  }

  // check form errors
  function checkFormErrors() {
    let submitError = {}
    Object.keys(settingDates).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: settingDates[key] },
          { required: requiredKeys.includes(key) ? true : false }
        ),
      }
    });
    setErrors(() => submitError)
  }

  function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  // ** send to api functions
  async function addDuration() {
    let data = {
      start_date: settingDates?.start_date ? moment(settingDates?.start_date).format("YYYY-MM-DD") : null,
      end_date: settingDates?.end_date ? moment(settingDates?.end_date).format("YYYY-MM-DD") : null,
      days_off: settingDates?.exclude_type === "days_off"
        ? settingDates?.days_off
        : [],
      dates_off: settingDates?.exclude_type === "dates_off"
        ? settingDates?.dates_off?.filter(date => date)?.map(date => moment(date).format("YYYY-MM-DD"))
        : [],
    }
    let res = await tripsDurationDates(id, data);
    if (res?.status === 200) {
      const formateTripData = formatTripPlannerData(res?.data?.data);
      setTripPlannerData(formateTripData);
      successfulResponse("success")
    }
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      addDuration();
    }
  }, [isErrorLoaded]);



  return (
    <div className="dates-setting">
      <h3 className="font-weight-bold">{packagePlanner.chooseDurationForPricing}</h3>
      <div className="row mx-0 gap-10 my-3">
        <div className="col-md-3 col-12 p-0">
          <DatePickerField
            label={operationStatement.startDate}
            placeholder={"DD/MM/YYYY"}
            id={"start_date"}
            name={"start_date"}
            date={settingDates?.start_date}
            onDateChange={(date) => handleInputChanges({ key: "start_date", value: date })}
            color={errors?.start_date?.required ? "danger" : ""}
            errors={errors?.start_date}
          />
        </div>
        <div className="col-md-3 col-12 p-0">
          <DatePickerField
            label={operationStatement.endDate}
            placeholder={"DD/MM/YYYY"}
            id={"end_date"}
            name={"end_date"}
            date={settingDates?.end_date}
            onDateChange={(date) => handleInputChanges({ key: "end_date", value: date })}
            isOutsideRange={(day) => {
              return !day.isAfter(moment(settingDates?.start_date), "day");
            }}
            color={errors?.end_date?.required ? "danger" : ""}
            errors={errors?.end_date}
          />
        </div>

      </div>

      <div>
        <h4 className="mb-2">{packagePlanner.excludedType}</h4>
        <div className="radio-group d-flex gap-24">
          {/* Days Off input */}
          <div className="d-flex align-items-center">
            <input type="radio"
              id="days_off"
              name="days_off"
              value={"days_off"}
              checked={settingDates?.exclude_type === "days_off"}
              onChange={(e) => handleInputChanges({ key: "exclude_type", value: e.target.value })}
            />
            <label htmlFor="days_off" className="control-field__label mx-1 pointer">{packagePlanner.daysOff}</label>
          </div>
          {/* Dates Off input */}
          <div className="d-flex align-items-center">
            <input type="radio"
              id="dates_off"
              name="dates_off"
              value={"dates_off"}
              checked={settingDates?.exclude_type === "dates_off"}
              onChange={(e) => handleInputChanges({ key: "exclude_type", value: e.target.value })}
            />
            <label htmlFor="dates_off" className="control-field__label mx-1 pointer">{packagePlanner.datesOff}</label>
          </div>

        </div>

        {/* choose day if days off */}
        {settingDates?.exclude_type === "days_off" ?
          <div className="exclude-type-wraper mt-2">
            <span className="font-weight-bold">{packagePlanner.chooseDaysOff}</span>
            <div className="week-days">
              {moment.weekdays().map((weekDay, index) => {
                return (
                  <div className='summery-checkBox' key={weekDay + index}>
                    <CheckBox
                      checked={settingDates.days_off.includes(weekDay)}
                      id={`days_off_${index}`}
                      name={`days_off_${index}`}
                      text={weekDay}
                      value={weekDay}
                      onChange={(e) => handleSelectDays(settingDates.days_off.includes(weekDay), e.target.value)}
                    />
                  </div>
                )
              })}
            </div>
          </div>
          :
          null
        }


        {/* choose dates if dates off */}
        {settingDates?.exclude_type === "dates_off" ?
          <div className="exclude-type-wraper d-flex flex-column align-items-start mt-2">
            <span className="mb-3">{packagePlanner.addDatesOff}</span>
            {settingDates?.dates_off?.map((date, dateIndex) => {
              return (
                <div className="d-flex">
                  <div>
                    <DatePickerField
                      hasLabel={false}
                      placeholder={"DD/MM/YYYY"}
                      id={`date_off_${dateIndex}`}
                      name={`date_off_${dateIndex}`}
                      date={date}
                      onDateChange={(date) => handleChangeDatesOff({ dateIndex, value: date })}
                    />
                  </div>
                  <button className="btn px-2 shadow-none" onClick={() => removeDateOff(dateIndex)}>
                    <svg width="11px" height="11px" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9L9 1M9 9L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                  </button>
                </div>
              )
            })}
            <button className="btn add-row-btn p-0 shadow-none mt-2 align-self-baseline" onClick={() => addDateOff()}>
              <AddIcon color="#6A4D1B" />
              {packagePlanner.addDate}
            </button>
          </div>
          :
          null
        }
      </div>


      {/* button */}
      <div className="d-flex justify-content-end">
        <button className="btn px-5 py-2 mt-2 bg-nxt" onClick={submit}>
          {packagePlanner.addDuration}
        </button>
      </div>

    </div>
  )
}
