import { useState } from "react";
import Locale from "translations";

/**
 * @Component PasswordField
 * @Description This component for the password input
 * @Props
 *    -> label: Text label for input field
 *    -> placeholder: Input field placeholder
 *    -> className: HTML classes for input field
 *    -> id: HTML ID
 *    -> color: Bootstrap color
 *    -> value: Input value
 *    -> onBlur: Input Function
 *    -> onChange: Input Function
 */

const PasswordField = (props) => {
	const { commons, passwordReset } = Locale;
	const [isFoucsed, setIsFoucsed] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const generateErrorsMsgs = (errors) => {
		let message = [];
		if (errors) {
			for (const [k, v] of Object.entries(errors)) {
				if (k === "required" && v) {
					message.push(commons.isRequired);
				} else if (k === "min" && v) {
					message.push(
						`${passwordReset.newPasswordLengthValidationMessage} `
					);
				} else if (k === "password" && v) {
					message.push(commons.ShouldMatch);
				} else if (k === "confirm" && v) {
					message.push(commons.ShouldMatchPassword);
				}
			}
			if (message.length === 1) {
				return `${props.label} ${message[0]}`;
			} else if (message.length > 1) {
				return `${props.label} ${message.join(" & ")}`;
			} else {
				return;
			}
		}
	};
	return (
		<>
			{/* Label */}
			{props.hasLabel ? (
				<label
					className={`control-field__label ${
						props.color ? " control-field__label--" + props.color : ""
					}  ${
						(isFoucsed && props.color !== "danger") ||
						(isFoucsed && props.value && props.color !== "danger")
							? "custom-color"
							: ""
					}
				`}
				>
					{props.label}
				</label>
			) : null}
			<div
				className={`my-1  input-group control-field + custom-input overflow-hidden  ${
					(isFoucsed && props.color !== "danger") ||
					(isFoucsed && props.value && props.color !== "danger")
						? "custom-border-color"
						: ""
				} 
				 ${props.color !== "success" ? " control-field--" + props.color : ""} 
				 `}
			>
				{/* Text Field Body */}
				<div className="control-field__body">
					{/* Password Input */}
					<div className="row flex-nowrap no-gutters justify-content-between">
						<input
							type={showPassword ? "text" : "password"}
							name={props.name}
							className={(props.className ?? "") + " control-field__input"}
							value={props.value}
							onChange={props.onChange}
							min={props.min}
							max={props.max}
							// onBlur={props.onBlur}
							onBlur={() => {
								if (props.onBlur !== undefined) {
									props.onBlur();
								}
								setIsFoucsed(false);
							}}
							onFocus={() => {
								setIsFoucsed(true);
							}}
							placeholder={props.placeholder}
							// onKeyDown={props.onEnter}
							autoComplete="off"
							tabIndex={props.tabIndex}
							onKeyDown={props?.onEnter}
						/>

						{/* Show/Hide Password */}
						<span
							className={
								showPassword
									? "icx icx-eye-slash text-19 pointer text-gray-100"
									: "icx icx-eye text-19 pointer text-gray-100"
							}
							onClick={() => setShowPassword(!showPassword)}
						></span>
					</div>
				</div>
			</div>

			{/* Feedback */}
			<small
				className={`control-field__feedback control-field__feedback--${
					props.color
				} ${props.color ? "d-block" : "d-none"} error-message`}
			>
				{generateErrorsMsgs(props.errors)}
			</small>
		</>
	);
};

export default PasswordField;
