import React from "react";
import { Link } from "react-router-dom";
export default function TableContent({ member, toggle, index }) {
	const memberStatus = (status) => {
		switch (status) {
			case 1:
				return "Active";
			default:
				return "Inactive";
		}
	};

	// const memberCheck = (status) => {
	// 	switch (status) {
	// 		case 1:
	// 			return "fa-check text-success";
	// 		default:
	// 			return "fa-ban text-danger";
	// 	}
	// };
	return (
		<tr key={index}>
			<td>{member.ref}</td>
			<td>{member.app_no}</td>
			<td>{member.app_owner}</td>
			<td>{member.pax}</td>
			<td>{member.booking_date}</td>
			<td>{member.source}</td>
			<td>{member.price}</td>

			<td>
				<i className="fas fa-check text-success"></i>
			</td>

			<td className="actions">
				<Link to="/aplication/add-traveler">
					<i
						className="fas fa-edit fa-fw text-primary"
						style={{ fontSize: "18px " }}
					></i>
				</Link>

				<i
					className="fas fa-trash-alt fa-fw text-danger"
					style={{ fontSize: "18px " }}
					//onClick={() => removeItem(index)}
				></i>
			</td>
		</tr>
	);
}
