// ** imports
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import Locale from 'translations'

// ** views
import CRMTabs from 'modules/CRM/shared/CRMTabs'
import SummeryDetails from './SummeryDetails'
import PaxDetails from './PaxDetails'
import ProductItinerary from 'modules/market-place/NewPackageResponse/Details/ProductItinerary'
import ExtraServices from './ExtraServices'
import Payment from './Payment'
import { fetchSearchedPackages, viewCRMPackageDetails } from 'services/CRM'
import HistoryLogs from './HistoryLog'
import CallLog from './CallLog'
import PriorityBox from '../components/PriorityBox'
import GuestsPerRoom from '../components/GuestsPerRoom'
import GuestsPerPax from '../components/GuestsPerPax'

// ** icons
import { ReactComponent as ChangeIcon } from 'assets/images/crm/change.svg'
import { ReactComponent as PriorityIcon } from 'assets/images/crm/priority.svg'
// import { ReactComponent as DeleteIcon } from 'assets/images/crm/delete.svg'
import { ReactComponent as PrintIcon } from 'assets/images/crm/print.svg'
import { ReactComponent as CRMMainIcon } from 'assets/images/crm/main.svg'
import { ReactComponent as CSRequestsIcon } from 'assets/images/crm/cs-requests.svg'
import { ReactComponent as UserIcon } from 'assets/images/crm/user.svg'
import { ReactComponent as AccountingIcon } from 'assets/images/crm/accounting.svg'


// ** helpers
import { formatPaxForSharedReservation, formatPrivateRooms } from '../helpers/roomsAvalibitiesHelpers'
import moment from 'moment'
import { calculateReservationPrice } from '../helpers/calculateReservationPrice'
import { changeRequestStatus } from 'services/productbuilder'
import { store } from 'react-notifications-component'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import DatePickerField from 'components/Form/DatePickerField/DatePickerField'
import CurrentPackage from '../CurrentPackage'
import FiltersAvailablePackages from '../FiltersAvailablePackages'
import ListAvailablePackage from '../ListAvailablePackage'


const CRMDetails = () => {
	// ** hooks
	const { CRM, inventory, productsBuilder, payment } = Locale

	// ** Tabs
	const { tab, id } = useParams();

	const CRM_TABS = [
		{ title: CRM.cRM, icon: <CRMMainIcon />, url: 'main' },
		{
			title: CRM.accounting,
			icon: <AccountingIcon />,
			url: 'accounting'
		},
		{
			title: CRM.csRequests,
			icon: <CSRequestsIcon />,
			url: 'cs-requests'
		},
		{ title: CRM.customers, icon: <UserIcon />, url: 'customers' }
	]

	const CRM_Details_TABS = [
		{ title: payment.messages.summardetails, url: '1' },
		{
			title: CRM.paxDetails,
			url: '2'
		},
		{
			title: CRM.extraServices,
			url: '3'
		},
		{ title: productsBuilder.itinerary, url: '4' },
		{ title: payment.messages.Payments, url: '5' }
	]

	const CRM_CALL_LOGS_TABS = [
		{ title: CRM.CallLog, url: '1' },
		{
			title: CRM.History,
			url: '2'
		}
	]

	// ** states
	const [activeTab, setActiveTab] = useState(!tab ? CRM_TABS[0].url : tab)
	const [activeCRMDetailsTab, setActiveCRMDetailsTab] = useState('1')
	const [callLogTabs, setCallLogTabs] = useState('1')
	const [reloadData, setReloadData] = useState(false)
	const [crmDetails, setCRMDetails] = useState();
	const [isPopupShow, setIsPopupShow] = useState(false);
	const [sharedPaxRooms, setSharedPaxRooms] = useState(null);
	const [privatePaxRooms, setPrivatePaxRooms] = useState(null);
	const [totalPrice, setTotalPrice] = useState(0);
	const [actionModal, setActionModal] = useState({
		isOpen: false,
		statusType: "",
		reject_reason: "",
		expire_at: null
	});

	const [searchedPackages, setSearchedPackages] = useState({
		isOpen: false,
		currentPackage: null,
		availablePackagesList: [],
	});

	const toggleTabs = (tab) => {
		setActiveCRMDetailsTab(tab)
	}

	const showPriorityPopup = () => {
		setIsPopupShow(!isPopupShow)
	}

	const toggleCallTabs = (tab) => {
		setCallLogTabs(tab)
	}

	async function getViewReservation() {
		const res = await viewCRMPackageDetails(id)
		if (res?.status === 200) {
			const reservationData = res?.data?.data;

			// get rooms from reserved pax (roomAvailabilities) when reservation category is private
			if (reservationData?.reservation_category === "private") {
				let privateRoomsList = formatPrivateRooms(reservationData);
				privateRoomsList = privateRoomsList?.length > 0 ? privateRoomsList : [{ roomType: null, roomCount: 0, childCount: 0, }];
				setPrivatePaxRooms(privateRoomsList);
			}

			// rooms if the reservation category is shared
			else {
				const paxRoomsForShared = formatPaxForSharedReservation(reservationData, reservationData?.adults_pax, reservationData?.children_pax)
				setSharedPaxRooms(paxRoomsForShared);
			}
			setCRMDetails(reservationData);
			setTotalPrice(reservationData?.total_price);
		}
	}

	function toggleActionModal(statusType) {
		setActionModal((prev) => ({
			isOpen: !prev.isOpen,
			statusType,
			reject_reason: "",
			expire_at: null,
		}))
	}

	async function changePackageStatus(status) {
		const data = {
			reservation_num: crmDetails?.reservation_num,
			status: status,
			expire_at: actionModal.expire_at ? moment(actionModal.expire_at).format("DD-MM-YYYY") : null,
			reject_reason: actionModal.reject_reason,
		};
		const res = await changeRequestStatus(data);

		if (res?.status === 200 || res?.status === 201) {
			store.addNotification({
				title: "info!",
				message: status === "accept" ? "Request Accepted Successfully" : "Request Rejected Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					pauseOnHover: true,
				},
			});
			toggleActionModal(null);
			getViewReservation();
		}
	}


	// calculate Total package Price
	const calculateTotalReservationPrice = useMemo(() => {
		if (crmDetails) {
			return calculateReservationPrice(crmDetails, sharedPaxRooms, privatePaxRooms);
		} else {
			return 0
		}
	}, [crmDetails, privatePaxRooms, sharedPaxRooms]);

	// handle calc total price of the package
	useEffect(() => {
		setTotalPrice(calculateTotalReservationPrice);
	}, [calculateTotalReservationPrice]);

	// ** useEffect to set tabs
	useEffect(() => {
		if (id === undefined) {
			setActiveTab(tab)
		} else {
			setActiveTab(tab)
		}
	}, [id, tab]);

	useEffect(() => {
		if (id) getViewReservation(id)
	}, [id, reloadData]);



	function statusLocaleAndClasses(status) {
		switch (status) {
			case "pending":
				return { locale: inventory.messages.pending, className: "crm-status-bg-pending" };
			case "approved":
				return { locale: inventory.messages.approved, className: "crm-status-bg-accepted" };
			case "paid":
				return { locale: inventory.messages.paid, className: "crm-status-bg-accepted" };
			case "confirmed":
				return { locale: inventory.messages.confirmed, className: "crm-status-bg-accepted" };
			case "rejected":
				return { locale: inventory.messages.rejected, className: "crm-status-bg-rejected" };
			case "expired":
				return { locale: inventory.messages.expired, className: "crm-status-bg-rejected" };
			case "canceled":
				return { locale: inventory.messages.canceled, className: "crm-status-bg-rejected" };
			default:
				return { locale: "", className: "" };
		}
	};

	// get searched pacakges
	async function getAvailablePackages(packageReservationNumber, packagesFilters) {
		const res = await fetchSearchedPackages(packageReservationNumber, packagesFilters);
		if (res?.status === 200 && res?.data?.data?.length > 0) {
			setSearchedPackages({
				isOpen: true,
				currentPackage: { ...res?.data?.package_data, reservation_num: packageReservationNumber, pax: crmDetails?.pax },
				availablePackagesList: res?.data?.data
			});
		} else {
			store.addNotification({
				message: "No Packages found",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2500,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}


	return (
		<div className='bg-white'>
			<div className='bg-gray-50'>
				<CRMTabs
					tabs={CRM_TABS}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					classNames='px-4 col-md-12 col-12 mx-auto'
				/>
			</div>

			<div className='crm-details-wrapper crm-wrapper'>
				{searchedPackages.isOpen ?
					<>
						<FiltersAvailablePackages
							setSearchedPackages={setSearchedPackages}
							getAvailablePackages={getAvailablePackages}
							currentPackageReservationNum={crmDetails?.reservation_num}
							currentPackage={searchedPackages.currentPackage}
						/>
						<CurrentPackage
							packageDetails={searchedPackages.currentPackage}
						/>
						<ListAvailablePackage
							availablePackagesList={searchedPackages.availablePackagesList}
							currentPackage={searchedPackages.currentPackage}
							setSearchedPackages={setSearchedPackages}
						/>
					</>
					:

					<>
						<div className='actions-wrapper'>
							<div className='items-wrapper'>

								<button className='btn item'
									onClick={() => getAvailablePackages(crmDetails?.reservation_num, { search_for: "change" })}
									disabled={crmDetails?.reservation_status === "canceled" || crmDetails?.reservation_status === "expired"}
								>
									<ChangeIcon />
									<p className='text'>{CRM.changePackage}</p>
								</button>
								<button className='btn item' onClick={showPriorityPopup}>
									<PriorityIcon />
									<p className='text'>{CRM.priority}</p>
								</button>
							</div>
							{/* <div className='items-wrapper'>
								<button className='btn item active'>
									<PrintIcon color='#FFF' />
									<p className='text'>Print</p>
								</button>
							</div> */}
						</div>

						<div className='crm-tabs-wrapper position-relative'>
							<PriorityBox
								isPopupShow={isPopupShow}
								priority={crmDetails?.priority}
								selectedItems={[crmDetails?.reservation_num]}
								actionsAfterSuccess={() => {
									setIsPopupShow(false);
									setReloadData(prev => !prev);
								}}
							/>
							<div className='crm-tabs-left'>
								<div className='d-flex justify-content-between px-4'>
									<div className='d-flex flex-column request-date'>
										<p className='crm-details-text-primary'>{CRM.RequestDate}</p>
										<p className='crm-details-text-secondary'>
											{moment(crmDetails?.created_at).format('DD.MM.YYYY | hh:mm')}
										</p>
									</div>
									<div class='vr'></div>
									<div className='d-flex flex-column status'>
										<p className='crm-details-text-primary'>{CRM.Status}</p>
										<p className={`text-capitalize new ${statusLocaleAndClasses(crmDetails?.reservation_status).className}`}>
											{statusLocaleAndClasses(crmDetails?.reservation_status).locale}
										</p>
									</div>
								</div>

								<div className='crm-tabs-m'>
									<CRMTabs
										tabs={CRM_Details_TABS}
										activeTab={activeCRMDetailsTab}
										setActiveTab={setActiveCRMDetailsTab}
										classNames='px-4 col-md-12 col-12 mx-auto'
										hasBorder={true}
										toggleTabs={toggleTabs}
									/>

									{/* Summery Details */}
									{activeCRMDetailsTab === '1' ?
										<SummeryDetails
											crmDetails={crmDetails}
											gotoPayment={() => setActiveCRMDetailsTab("5")}
											totalPrice={totalPrice}
											toggleActionModal={toggleActionModal}
										// changePackageStatus={changePackageStatus}
										>
											{/* geust box  per room */}
											{crmDetails?.reservation_category === "private" ?
												<GuestsPerRoom
													productDetails={crmDetails}
													privatePaxRooms={privatePaxRooms}
													setPrivatePaxRooms={setPrivatePaxRooms}
													setReload={setReloadData}
												/>
												:
												null
											}
											{/* geust box  per pax */}
											{crmDetails?.reservation_category === "shared" ?
												<GuestsPerPax
													productDetails={crmDetails}
													sharedPaxRooms={sharedPaxRooms}
													setSharedPaxRooms={setSharedPaxRooms}
													setReload={setReloadData}
													adultCount={crmDetails?.adults_pax}
													childrenCount={crmDetails?.children_pax}
												/>
												:
												null
											}
										</SummeryDetails >
										:
										null
									}

									{/* Pax Details */}
									{activeCRMDetailsTab === '2' && crmDetails?.travelers ?
										<PaxDetails
											setReloadData={setReloadData}
											travelers={crmDetails?.travelers}
											roomsCombinations={crmDetails?.product?.product_price_combinations}
										/>
										:
										null
									}

									{/* extra services */}
									{activeCRMDetailsTab === '3' && crmDetails?.extra_services ?
										<ExtraServices
											extraServicesData={crmDetails?.extra_services}
											crmDetails={crmDetails}
											setReload={setReloadData}
										/>
										:
										null
									}

									{/* Product Itinerary */}
									{activeCRMDetailsTab === '4' && crmDetails?.product?.itinerary ?
										<ProductItinerary itineraryData={crmDetails?.product?.itinerary} />
										:
										null
									}

									{/* Payments */}
									{activeCRMDetailsTab === '5' && crmDetails?.payments ?
										<Payment
											paymentsList={crmDetails?.payments}
											crmDetails={crmDetails}
											setReload={setReloadData}
										/>
										:
										null
									}

								</div>
							</div>

							<div className='crm-tabs-right'>
								<CRMTabs
									tabs={CRM_CALL_LOGS_TABS}
									activeTab={callLogTabs}
									setActiveTab={setCallLogTabs}
									classNames='px-4 col-md-12 col-12 mx-auto'
									hasBorder={true}
									toggleTabs={toggleCallTabs}
								/>
								{callLogTabs === '1' ?
									<CallLog
										callLogsData={crmDetails?.call_logs}
										setReload={setReloadData}
									/>
									:
									null
								}
								{callLogTabs === '2' ? <HistoryLogs /> : null}
							</div>
						</div>
					</>
				}

			</div>

			{/* accept or reject modal */}
			{actionModal.isOpen ?
				<Modal isOpen={actionModal.isOpen}>
					<ModalHeader toggle={toggleActionModal}>
						<span className='text-capitalize'>{actionModal.statusType}</span>
					</ModalHeader>
					<ModalBody>
						{actionModal.statusType === "accept" ?
							<DatePickerField
								label={"Optional Date"}
								placeholder={"DD/MM/YYYY"}
								date={actionModal.expire_at}
								name="birth_date"
								hasYears={true}
								onChangeDate={(date) => {
									setActionModal({ ...actionModal, expire_at: date });
								}}
								isOutsideRange={(day) => {
									return !day.isAfter(moment(), "day") || day.isAfter(moment(crmDetails?.departure_date), "day");
								}}
							/>
							:
							<textarea
								className=" w-100 p-3 border rounded-lg"
								placeholder={inventory.messages.rejectReason}
								value={actionModal.reject_reason}
								onChange={(e) => setActionModal({ ...actionModal, reject_reason: e.target.value })}
							/>
						}
						{/* action button */}
						<div className='mt-2'>
							{actionModal.statusType === "reject" &&
								<button
									className="btn btn-guests me-2  w-100"
									style={{ color: "#fff", backgroundColor: "#C30101" }}
									onClick={() => changePackageStatus("reject")}
									disabled={!actionModal.reject_reason || actionModal.reject_reason?.length < 3}
								>
									{inventory.messages.reject}
								</button>
							}
							{actionModal.statusType === "accept" &&
								<button
									style={{ color: "#fff", backgroundColor: "#027B30" }}
									className="btn btn-guests  w-100"
									onClick={() => changePackageStatus("accept")}
									disabled={!actionModal.expire_at}
								>
									{inventory.messages.accept}
								</button>
							}
						</div>
					</ModalBody>
				</Modal>
				:
				null
			}
		</div>
	)
}

export default CRMDetails
