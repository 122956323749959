import NumberField from 'components/Form/NumberField/NumberField'
import { roomTypesLookup } from 'modules/products-builder/Requestes/RequestView/helpers/localLookups';
import React from 'react'
import Locale from 'translations';


export default function RoomsPricing({ children, pricing, handleInputsChanges, selectedPricingType, productHasHotel }) {
  const { packagePlanner ,marketPlace} = Locale;
  const rooms = selectedPricingType === "fullpackage" ? pricing?.rooms : pricing?.itemable?.rooms

  return (
    <>
      {children}

      <div className="table-container mb-3">
        <table className="table room-pricing-table">
          <thead>
            <tr>
              <th>
                <span className="room-th">{productHasHotel ? packagePlanner.roomTypeCount : marketPlace.Services}</span>
              </th>
              <th>
                <span className="room-th">{packagePlanner.pricePerAdult}</span>
              </th>
              <th>
                <span className="room-th">{packagePlanner.pricePerChild}</span>
              </th>
              {productHasHotel &&
                <th>
                  <span className="room-th">{packagePlanner.maxChildPerRoom}</span>
                </th>
              }
            </tr>
          </thead>

          <tbody>
            {rooms?.map((room, index) => {
              return (
                <tr>
                  <td width="40%">
                    <p className="room-type-count py-2 text-center bg-gray-100 justify-content-center">
                      <span>{productHasHotel ? `${room?.combinations?.[0]?.room_type_name || roomTypesLookup[room?.room_type] || ""} ${marketPlace.Room}` : ""}</span>
                      <span>{productHasHotel ? `X${room?.combinations?.[0]?.room_count || room?.count || ""}` : marketPlace.PackagePrice}</span>
                    </p>
                  </td>
                  <td>
                    <div>
                      <NumberField
                        hasLabel={false}
                        removeArrow={true}
                        id={`adult_price_${index}`}
                        name={`adult_price_${index}`}
                        value={room?.full_original_price || room?.price_adult || ""}
                        onChange={(e) => handleInputsChanges({
                          key: selectedPricingType === "fullpackage" ? "full_original_price" : "price_adult",
                          value: e.target.value,
                          roomIndex: index,
                          serviceId: pricing?.id
                        })}
                      />
                    </div>
                  </td>
                  {index === 0 && selectedPricingType === "breakdown" ?
                    <td rowSpan={rooms?.length} className="breakdown-child-price">
                      <NumberField
                        hasLabel={false}
                        removeArrow={true}
                        id={`child_price_${index}`}
                        name={`child_price_${index}`}
                        value={room?.price_child}
                        onChange={(e) => handleInputsChanges({
                          key: "price_child",
                          value: e.target.value,
                          roomIndex: index,
                          serviceId: pricing?.id
                        })}
                      />
                    </td>
                    :
                    null
                  }
                  {/* fullpackage child pricing */}
                  {selectedPricingType === "fullpackage" &&
                    <td>
                      <div>
                        <NumberField
                          hasLabel={false}
                          removeArrow={true}
                          id={`child_price_${index}`}
                          name={`child_price_${index}`}
                          value={room?.child_original_price || ""}
                          onChange={(e) => handleInputsChanges({
                            key: "child_original_price",
                            value: e.target.value,
                            roomIndex: index,
                            serviceId: pricing?.id
                          })}
                        />
                      </div>
                    </td>
                  }
                  {productHasHotel &&
                    <td>
                      <div>
                        <NumberField
                          hasLabel={false}
                          removeArrow={true}
                          id={`max_child_${index}`}
                          name={`max_child_${index}`}
                          value={room?.max_child || ""}
                          onChange={(e) => handleInputsChanges({
                            key: "max_child",
                            value: Number(e.target.value).toString(),
                            roomIndex: index,
                            serviceId: pricing?.id
                          })}
                        />
                      </div>
                    </td>
                  }
                  <td>
                  </td>
                </tr>
              )
            })}

          </tbody>
        </table>

      </div>
    </>
  )
}
