import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
//import { Nav, NavItem, NavLink, TabContent } from "reactstrap";

import {
	getWalletTransactions,
	payFromBankTransfer,
	payFromSafa
} from "services/wallet";
import validator from "validator";
import RechargeModal from "./modal/rechargeModal";
import SendErrRequestModal from "./modal/sendErrRequestModal";
import SendRequestModal from "./modal/sendRequestModal";
import TransferModal from "./modal/transferModal";

const WalletCollapse = ({
	wallet,
	getWallets,
	walletsList,
	allTabs,
	setActiveTab,
}) => {
	// let [Id, setId] = useState("");
	// const history = useHistory();
	// const { payment } = Locale;

	// const [collapse, setCollapse] = useState(false);
	// const toggle = (event) => {
	// 	Id = event.target.id;
	// 	setCollapse(!collapse);
	// };

	// if (wallet?.type == "credit") {

	// }
	/*******Modal States************ */
	const [modalRecharge, setModalRecharge] = useState(false);
	const toggleModalRecharge = () => setModalRecharge(!modalRecharge);

	const [modalTransfer, setModalTransfer] = useState(false);
	const toggleModalTransfer = () => setModalTransfer(!modalTransfer);

	const [modalSendRequest, setModalSendRequest] = useState(false);
	const toggleModalSendRequest = () => setModalSendRequest(!modalSendRequest);

	const [modalErrSendRequest, setModalErrSendRequest] = useState(false);
	const toggleModalErrSendRequest = () =>
		setModalErrSendRequest(!modalErrSendRequest);

	// const locale = localStorage.getItem("currentLocale");


	/******** Modal API ********** */
	/**Start transfer wallet from safa */
	const [amount, setAmount] = useState(null);
	async function transferWallet() {
		const res = await payFromSafa({
			amount: +amount,
			currency: wallet?.currency,
			//description: "edf333",
		});
		if (res.status === 200 || res.status === 201) {
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1100,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			getWallets();
			setAmount(null);
			setTimeout(() => {
				toggleModalSendRequest(modalSendRequest);
			}, 2000);
		} else {
			setTimeout(() => {
				toggleModalErrSendRequest(modalErrSendRequest);
			}, 2000);
		}
	}
	const submit = () => {
		if (validator.isNumeric(`${amount}`, ["ar-EG"])) {
			transferWallet();
		} else {
			store.addNotification({
				title: "info!",
				message: "Amount should be Number",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	/**End Transfer Wallet from safa */

	/**Start Bank Transer  */
	const [bankAmount, setBankAmount] = useState(null);
	const [image, setImage] = useState();
	const [responseBankTransfer, setResponseBankTransfer] = useState();
	async function bankTranfer() {
		const formData = new FormData();
		formData.append("amount", bankAmount);
		formData.append("photo", image?.name);
		formData.append("currency", wallet?.currency);

		///{ amount: amount, photo: image?.name }
		const res = await payFromBankTransfer(formData);
		if (res?.status === 200 || res?.status === 201) {
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1100,
					onScreen: true,
					pauseOnHover: true,
				},
			});

			toggleModalRecharge(null);
			setBankAmount(null);
			setImage(null);

			setResponseBankTransfer(res?.data?.transaction_request);

			setTimeout(() => {
				toggleModalSendRequest(modalSendRequest);
			}, 2000);
		} else {
			setTimeout(() => {
				toggleModalErrSendRequest(modalErrSendRequest);
			}, 2000);
		}
	}
	/**End Bank Transfer */

	/**Start Credit/Master Card */
	const [creditAmount, setCreditAmount] = useState();

	// async function AddDebit() {
	// 	const res = await payByDebit({
	// 		amount: +creditAmount,
	// 	});
	// 	if (res.status == 200 || res.status == 201) {
	// 		store.addNotification({
	// 			title: "info!",
	// 			message: res.data.message,
	// 			type: "success",
	// 			insert: "top",
	// 			container: "top-right",
	// 			animationIn: ["animated", "fadeIn"],
	// 			animationOut: ["animated", "fadeOut"],
	// 			dismiss: {
	// 				duration: 3000,
	// 				onScreen: true,
	// 				pauseOnHover: true,
	// 			},
	// 		});
	// 		toggleModalRecharge(null);
	// 		setTimeout(() => {
	// 			toggleModalSendRequest(modalSendRequest);
	// 		}, 200);
	// 	}
	// }
	/**End Credit/Master Card  */

	/**Transaction list */
	const [transactionList, setTransactionList] = useState([]);
	useEffect(() => {
		let id = wallet?.id;
		async function fetchTransaction() {
			const res = await getWalletTransactions(id);
			setTransactionList(res?.data?.data.slice(0, 3));
		}
		fetchTransaction();
	}, []);


	// const [activeTab, setActiveTab] = useState(
	// 	wallet.type == "credit" ? wallet?.ref_num : ""
	// );
	// 

	// const toggleTab = (tab) => {
	// 	if (activeTab !== tab) setActiveTab(tab);
	// };
	return (
		<>
			{/* {allTabs} */}
			{/* <div className="main-tabs1 d-flex ">
				<Nav tabs className="align-items-center main-nav1 w-25">
					<NavItem>
						<NavLink
							className={`${
								activeTab === wallet.ref_num ? "active" : "no-active"
							}`}
							onClick={() => {
								toggleTab(wallet.ref_num);
							}}
						>
							{wallet?.type == "credit"
								? `${payment.messages.safaWallet}`
								: `${wallet?.currency} ${payment.messages.wallet}`}
						</NavLink>
					</NavItem>
				</Nav>

				<TabContent activeTab={activeTab} className="w-75">
					<div className="main-nav-contant ">
						{activeTab === wallet?.ref_num ? (
							<>
							
								<p>Tahaaaaa {wallet?.ref_num}</p>
							</>
						) : (
							<p>no data</p>
						)}
					</div>
				</TabContent>
			</div> */}

			{/* <h1 className="pt-2 blue font-weight-bolder">
				{wallet?.type == "credit"
					? `${payment.messages.safaWallet}`
					: `${wallet?.currency} ${payment.messages.wallet}`}
			</h1>
			****
			<div className="group-container  ">
				<div className="d-flex">
					<div className="col-containt w-100 ">
						<div className="d-flex flex-row-reverse w-100 px-3 py-2 col-containt-data">
							<div>
								<h4>{payment.messages.accountNo}</h4>
								<span>{wallet?.ref_num}</span>
							</div>
							{wallet?.type == "credit" ? (
								<div>
									<h4>{payment.messages.creditLeft} </h4>
									<span>
										{wallet?.amount}
										{wallet?.currency}
									</span>
								</div>
							) : (
								<div>
									<h4>{payment.messages.balance} </h4>
									<span>
										{wallet?.amount}
										{wallet?.currency}
									</span>
								</div>
							)}
							{wallet?.type == "credit" ? (
								<div>
									<h4>{payment.messages.totalLimit} </h4>
									<span>
										{" "}
										{wallet?.limit}
										{wallet?.currency}
									</span>
								</div>
							) : (
								<div></div>
							)}
							<div>
								{wallet?.type == "credit" ? (
									<button
										className="btn btn-success w-100 font-weight-bold"
										onClick={(e) => {
											toggleModalTransfer(modalTransfer);
										}}
									>
										{payment.messages.payWallet}*****
									</button>
								) : (
									<button
										className="btn btn-success w-100 font-weight-bold"
										onClick={(e) => {
											toggleModalRecharge(modalRecharge);
										}}
									>
										{payment.messages.rechargeWallet}
									</button>
								)}
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-center align-items-center blue ">
						<div>
							<Button
								className="CollapsePlusIcon"
								color="none"
								id="btn1"
								onClick={(e) => toggle(e)}
								style={{
									marginBottom: "1rem",
								}}
							>
								<i
									className={
										collapse
											? "fas fa-lg fa-chevron-down blue"
											: "fas fa-lg fa-chevron-right grey"
									}
								></i>
							</Button>
						</div>
					</div>
				</div>
				<Collapse isOpen={collapse}>
					<Card>
						<CardBody className="card-body">
							<Table className="table table-striped">
								<thead className="text-grey">
									<tr>
										<th> {payment.messages.transactionId}</th>
										<th>{payment.messages.date}</th>
										<th>{payment.messages.amount}</th>
										<th>{payment.messages.type}</th>
										<th style={{ width: "10%" }}>{payment.messages.tools}</th>
									</tr>
								</thead>

								<tbody className="table-stripe ">
									{transactionList && transactionList.length > 0 ? (
										transactionList.map((res) => {
											return (
												<tr>
													<td>{res?.transaction_number}</td>
													<td>{res?.created_at.slice(0, 11)}</td>
													<td>
														{res?.amount}
														{wallet?.currency}
													</td>
													<td>
														{res?.transaction_type == "deduct"
															? payment.messages.deduct
															: payment.messages.add}
													</td>
													
													<td className="action-tools">
														<i class="fas fa-download text-primary"></i>
														<i class="fas fa-print text-primary"></i>
													</td>
												</tr>
											);
										})
									) : (
										<tr>
											<td colSpan="10" className="text-center">
												<div className="product-build__product-no-data">
													<i className="fas fa-info-circle fa-lg"></i>{" "}
													<h4>{"No Result"}</h4>
												</div>
											</td>
										</tr>
									)}
								</tbody>
							</Table>
							<div className="view-btn">
								<div
									className={wallet?.type === "credit" ? "safa-wallet " : ""}
								>
									<button
										className="btn btn-primary px-2"
										onClick={() =>
											history.push(`/wallet/transaction/${wallet?.id}`)
										}
									>
										{payment.messages.viewTransactions}
									</button>
									{wallet?.type !== "credit" ? (
										<button
											className="btn btn-primary px-2"
											onClick={() =>
												history.push(`/wallet/requests/${wallet?.id}`)
											}
										>
											{payment.messages.viewRequests}
										</button>
									) : null}
								</div>
							</div>
						</CardBody>
					</Card>
				</Collapse>
			</div> */}

			{/****Start Recharge Model  */}
			{modalRecharge ? (
				<RechargeModal
					modalRecharge={modalRecharge}
					toggleModalRecharge={toggleModalRecharge}
					modalSendRequest={modalSendRequest}
					toggleModalSendRequest={toggleModalSendRequest}
					data={wallet}
					bankTranfer={bankTranfer}
					bankAmount={bankAmount}
					setBankAmount={setBankAmount}
					image={image}
					setImage={setImage}
					creditAmount={creditAmount}
					setCreditAmount={setCreditAmount}
				/>
			) : null}
			{/****End Recharge Model  */}
			{/****Start Transfer Model  */}
			{modalTransfer ? (
				<TransferModal
					walletsList={walletsList}
					modalTransfer={modalTransfer}
					toggleModalTransfer={toggleModalTransfer}
					modalSendRequest={modalSendRequest}
					toggleModalSendRequest={toggleModalSendRequest}
					data={wallet}
					submit={submit}
					amount={amount}
					setAmount={setAmount}
				/>
			) : null}
			{/****End Transfer Model  */}
			{/****Start Send Request Model  */}
			<SendRequestModal
				modalSendRequest={modalSendRequest}
				toggleModalSendRequest={toggleModalSendRequest}
				data={wallet}
				response={responseBankTransfer}
			/>
			<SendErrRequestModal
				modalErrSendRequest={modalErrSendRequest}
				toggleModalErrSendRequest={toggleModalErrSendRequest}
				data={wallet}
				response={responseBankTransfer}
			/>
			{/****End Send Request Model  */}
		</>
	);
};

export default WalletCollapse;
