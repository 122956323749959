import { useSBSState } from 'context/global';
import { useWebBuilderDispatch, useWebBuilderState } from 'context/webBuilder';
import usePreviewMode from 'hooks/usePreviewMode';
import { handleSearch } from 'modules/WebBuilder-V2/helpers/handleSearch';
import { useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import { useHistory, useParams } from 'react-router-dom';
import Locale from 'translations';

export default function useWBHeroContent() {
  // *** hooks
  const { webBuilder } = Locale;
  const { ourServices, content, pagesData } = useWebBuilderState();
  const dispatch = useWebBuilderDispatch();
  const { allCountries } = useSBSState()
  const history = useHistory();
  const { bundle } = useParams()
  const { isPreview } = usePreviewMode();

  // *** states
  const [countriesLookup, setCountriesLookup] = useState([]);
  const [searchState, setSearchState] = useState({
    country: null,
    proudctType: null,
  });

  

  // *** variables
  const visibleServices = ourServices ? ourServices?.services?.filter(service => service?.visible && service?.type !== "packages")?.map(service => ({
    id: service?.type,
    name: webBuilder[service?.type]
  })) : [];

  const product_types = [
    { id: "hajj", name: webBuilder.hajj },
    { id: "umrah", name: webBuilder.umrah },
    { id: "umrah-plus", name: webBuilder.umrahPlus },
    { id: "tourism", name: webBuilder.tourism },
    ...visibleServices
  ]


  // *** search bar logic
  function handleSearchInputsChanges(value, keyName) {
    if (keyName === "proudctType" && (value.id === "hajj" || value.id === "umrah" || value.id === "umrah-plus")) {
      setCountriesLookup(() => countriesLookup.filter(country => country?.id === 966))
    } else {
      setCountriesLookup(allCountries)
    }
    setSearchState({
      ...searchState,
      country: keyName === "proudctType" && (value.id === "hajj" || value.id === "umrah" || value.id === "umrah-plus") ?
        {
          id: 966,
          name: "Saudi Arabia",
          currency: "SAR",
          value: 966
        }
        :
        searchState.country,
      [keyName]: value,
    });
  }

  function getSearchResults() {
    let searchResults = handleSearch(searchState?.country?.label, searchState?.proudctType?.id, content, pagesData);
    if (searchResults?.length > 0) {
      dispatch({
        type: "addPackagesResult",
        payload: searchResults,
      });
      if (!isPreview) return
      history.push(`/${bundle}/web-builder-v2/preview/packages-results?country=${searchState?.country?.label}&type=${searchState?.proudctType?.id}`);
    } else {
      store.addNotification({
        title: "No data found!",
        message: "No data matches the specified search criteria",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
      });
    }
  }

  // set counties in local 
  useEffect(() => {
    if (allCountries) {
      setCountriesLookup(allCountries);
    }
  }, [allCountries])

  return {
    getSearchResults,
    handleSearchInputsChanges,
    countriesLookup,
    searchState,
    product_types,
    isPreview
  }
}
