import { useSBSState } from 'context/global';
import AppFooter from 'layouts/AppFooter';
import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import SafaLogo from "assets/images/dashboard/safaLogoFull.svg";
import Locale from 'translations';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';

export default function NewPrivacyPolicy() {
  const { isAuth } = useSBSState();
  const { newLand } = Locale;

  const style = {
    color: "#505050",
    lineHeight: "29.98px"
  };
  return (
    <div className={isAuth ? "" : "pre-auth-layout"}>

      {!isAuth &&
        <div className="container px-3">
          <div className="row m-0 mb-2 align-items-center">
            <figure className="col-6 m-0 hidden-mobile login-page-img">
              <div className="d-flex justify-content-between py-2 align-items-center w-100">
                <Link to="/" className="text-dark">
                  <img src={SafaLogo} alt="" width="160px" />
                </Link>

              </div>
            </figure>
            <div className="col-md-6 col-sm-9 d-flex hidden-mobile  flex-column  justify-content-between login-page-contant">
              <div className="d-flex hidden-mobile justify-content-between py-2 align-items-center w-100">
                <Link to="/" className="text-dark linkHeader ">
                  {newLand.home}
                </Link>
                <Link to="/about-us" className="text-dark linkHeader">
                  {newLand.aboutUs}
                </Link>
                <Link to="/contact-us" className="text-dark linkHeader linkHeaderActive">
                  {newLand.contactUs}
                </Link>

                <LanguageSwitcher />
              </div>
            </div>
          </div>
        </div>
      }


      <div className="container py-3">
        <h1 className="font-weight-bold h3 text-center">Privacy Policy</h1>
        <h2 className="font-weight-bold h3 text-center">"Safa Soft - Safa Live"</h2>
        <div className="content mt-5">
          <p>"Safa Soft – Safa Live" takes your Privacy concerns seriously and will not share your
            confidential information with any 3rd party without your consent.
          </p>
          <h3 className="font-weight-bold my-3">Using and disclosing confidential Information</h3>
          <p className="my-3" style={style}>
            Your confidential information and that of your customer will only be used or disclosed as
            is necessary to provide the services available through the website offered by “Safa Soft
            - Safa Live” or the organizations identified there, for administrative purposes (including
            fraud and security checks) and to assist us to improve our website and services, and to
            provide you with information and offers relevant to our services.
          </p>
          <p className="my-3" style={style}>
            We will disclose confidential information as required to the providers of the products and
            services you have purchased for your customer.
          </p>
          <p className="my-3" style={style}>
            You accept that not all recipients of your information may have privacy policies or be
            subject to privacy laws equivalent to “Safa Soft - Safa Live” and consent to the
            disclosure of your confidential information and that of your customer for that purpose.
          </p>
          <p className="my-3" style={style}>
            “Safa Soft - Safa Live” will not, without your prior consent, disclose confidential
            information for any purpose other than those purposes described in this Privacy Policy
            to which you have already consented.
          </p>
          <p className="my-3" style={style}>
            We may disclose your confidential information or that of your customer where required
            or authorized by law to do so.
          </p>
        </div>
      </div>
      {!isAuth &&
        <AppFooter />
      }
    </div>
  )
}
