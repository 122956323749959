import SelectField from 'components/Form/SelectField/SelectField'
import React, { useEffect, useState } from 'react'
import { ReactComponent as DeleteIcon } from 'assets/images/crm/add-square.svg'
import { PlusIcon } from 'modules/UmrahOperations/shared/Icons'
import { generatePrivateRoomsType } from '../helpers/roomsAvalibitiesHelpers'
import Locale from 'translations'
import { setPackageRoomAvailabilities } from 'services/productbuilder'
import { store } from 'react-notifications-component'

function GuestsPerRoom({ productDetails, privatePaxRooms, setPrivatePaxRooms, setReload, isMarketPlace, adultCount, childrenCount }) {
	const { marketPlace, inventory } = Locale;
	const product = isMarketPlace ? productDetails : productDetails?.product;
	const productRoomsAvailabilities = isMarketPlace ? productDetails?.roomAvailabilities : productDetails?.product?.roomsAvailabilities;


	// ** states
	const [roomsAvailabilities, setRoomsAvalibities] = useState(null);
	const [childrenRemaining, setChildrenRemaining] = useState(childrenCount);
	const [maxChildrenCount, setMaxChildrenCount] = useState(2);
	const isReservationPending = isMarketPlace ? true : productDetails?.payment_status === "pending"

	console.log(childrenRemaining);

	function getRoomPriceForEachService(roomType, roomCount, childCount) {
		let totalPrice = 0;
		let b2bPricing = product?.product_price_combinations?.filter(p => p?.pricing_for === "b2b");
		const productPriceBreakDown = product?.product_price_type_id === 0 || product?.product_price?.product_price_type?.id === 0;
		const pricingKey = productPriceBreakDown ? "adult_final_hotel_price" : "full_price_after_discount";

		let hotelCombination = b2bPricing?.find(p => +p?.combinations[0]?.room_type === roomType)
		let hotelsAdultPrices = hotelCombination?.[pricingKey] * +roomCount * +roomType;

		let hotelCombinationId = hotelCombination?.id;
		totalPrice = +hotelsAdultPrices
		let maxChildCount = Math.min(...hotelCombination?.combinations.map(comb => comb?.max_child)) 
		return { totalPrice, combination_id: hotelCombinationId, maxChildrenPerRoom: maxChildCount };
	}

	// select room
	function handleRoomType(roomIndex, value) {
		let roomAvailabilitiesClone;
		let prevRoom = privatePaxRooms[roomIndex];
		let roomsDataClone = [...privatePaxRooms];
		let currentRoom = roomsDataClone[roomIndex];
		let hotelPriceForRoom = getRoomPriceForEachService(value?.room_type, +currentRoom?.roomCount);
		currentRoom = {
			...currentRoom,
			price: hotelPriceForRoom?.totalPrice,
			roomType: value,
			combination_id: hotelPriceForRoom?.combination_id,
		}
		roomsDataClone[roomIndex] = currentRoom;
		// reset Availabilities
		if (prevRoom?.roomType) {
			roomAvailabilitiesClone = handleAvailabilities(value?.room_type, prevRoom?.roomType?.room_type, true);
		} else {
			roomAvailabilitiesClone = handleAvailabilities(value?.room_type, null, true)
		}

		setPrivatePaxRooms(roomsDataClone);
		setRoomsAvalibities(roomAvailabilitiesClone);
		setMaxChildrenCount(hotelPriceForRoom.maxChildrenPerRoom)
	}
	// select room counts
	function handleRoomsCount(roomIndex, value) {
		let roomsDataClone = [...privatePaxRooms];
		let currentRoom = roomsDataClone[roomIndex];
		let hotelPriceForRoom = getRoomPriceForEachService(currentRoom?.roomType?.room_type, +value, currentRoom?.childCount);
		currentRoom = {
			...currentRoom,
			roomCount: value,
			price: hotelPriceForRoom?.totalPrice,
			combination_id: hotelPriceForRoom?.combination_id,
			childrenAvailability: handleChildsAvailabilities(value)
		}
		roomsDataClone[roomIndex] = currentRoom;
		setPrivatePaxRooms(roomsDataClone);
	}
	// select children room counts
	function handleRoomsChildCount(roomIndex, value) {
		let roomsDataClone = [...privatePaxRooms];
		let currentRoom = roomsDataClone[roomIndex];
		const oldChildCount = +currentRoom?.childCount || 0;
		let hotelPriceForRoom = getRoomPriceForEachService(currentRoom?.roomType?.room_type, currentRoom?.roomCount, +value);
		currentRoom = {
			...currentRoom,
			childCount: value,
			price: hotelPriceForRoom?.totalPrice,
			combination_id: hotelPriceForRoom?.combination_id
		}

		const newChildRemainig = (+childrenRemaining + oldChildCount) - +value;
		roomsDataClone[roomIndex] = currentRoom;
		setPrivatePaxRooms(roomsDataClone);
		setChildrenRemaining(newChildRemainig);
	}
	// set new children availabilities
	function handleChildsAvailabilities(roomCount) {
		const currentChildCount = +roomCount * maxChildrenCount > childrenRemaining ? childrenRemaining : +roomCount * maxChildrenCount;
		const childrenAvailability = [...Array(currentChildCount + 1).keys()].map(count => ({ name: `${count}`, id: `${count}`, value: `${count}` }));
		return childrenAvailability;
	}

	function handleAvailabilities(currentRoomType, prevRoomType, disabled) {
		const updatedAvailabilities = roomsAvailabilities.map(availability => {
			if (availability.room_type === currentRoomType) {
				return { ...availability, disabled: disabled };
			} else if (availability.room_type === prevRoomType) {
				return { ...availability, disabled: false };
			}
			return availability;
		});
		return updatedAvailabilities;
	}

	// delete room and reset it's availabilities
	function deleteRoom(roomIndex) {
		const roomsClone = [...privatePaxRooms];
		let currentRoomType = roomsClone[roomIndex]?.roomType;
		let roomAvailabilitiesClone = handleAvailabilities(currentRoomType?.room_type, null, false);
		const oldChildCount = +roomsClone[roomIndex]?.childCount || 0;
		const newChildRemainig = childrenRemaining + oldChildCount;
		roomsClone.splice(roomIndex, 1);
		setPrivatePaxRooms(roomsClone);
		setRoomsAvalibities(roomAvailabilitiesClone);
		setChildrenRemaining(newChildRemainig);
	}
	// add new room
	function addNewRoom() {
		const emptyRoom = {
			roomType: null,
			roomCount: 0,
			childCount: 0,
		};
		setPrivatePaxRooms([...privatePaxRooms, emptyRoom])
	}

	async function savePackageRoomAvalibities() {
		const data = {
			reservation_num: productDetails?.reservation_num,
			rooms: privatePaxRooms?.filter(room => room?.combination_id)?.map(room => {
				return {
					combination_id: room?.combination_id,
					rooms_count: +room?.roomCount,
					children_count: +room?.childCount
				}
			})
		}
		const res = await setPackageRoomAvailabilities(data);
		if (res?.status === 200) {
			store.addNotification({
				title: "info!",
				message: "Rooms updated Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					pauseOnHover: true,
				},
			});
			setReload(prev => !prev)
		}
	}

	// set rooms types base on rooms avalibities and selected rooms
	useEffect(() => {
		const availabileRooms = generatePrivateRoomsType(productRoomsAvailabilities, privatePaxRooms)
		setRoomsAvalibities(availabileRooms);
	}, [privatePaxRooms, productRoomsAvailabilities]);

	return (
		<div className='crm-details-payment'>
			{privatePaxRooms?.map((room, index) => (
				<div key={index} className='guests-row'>
					<div className='guests-item guests-item-flex-2'>
						<SelectField
							label={marketPlace.roomType}
							placeholder={marketPlace.Select}
							id={`room-type-${index}`}
							name={`room-type-${index}`}
							options={roomsAvailabilities}
							value={room?.roomType?.label}
							onChange={(e) => handleRoomType(index, e)}
							disabled={!isReservationPending}
						/>
					</div>
					<div className='guests-item'>
						<SelectField
							label={inventory.messages.RoomCount}
							placeholder={marketPlace.Select}
							id={`room-count-${index}`}
							name={`room-count-${index}`}
							value={room?.roomCount}
							options={roomsAvailabilities?.filter(r => r?.room_type === room?.roomType?.room_type)[0]?.roomRemainingAvailability}
							onChange={(e) => handleRoomsCount(index, e.value)}
							disabled={!isReservationPending}
						/>
					</div>
					<div className='guests-item'>
						<SelectField
							label={inventory.messages.childPax}
							placeholder={marketPlace.Select}
							id={`room-child-count-${index}`}
							name={`room-child-count-${index}`}
							options={room?.childrenAvailability}
							value={room?.childCount}
							onChange={(e) => handleRoomsChildCount(index, e.value)}
							disabled={!isReservationPending || !childrenCount}
						/>
					</div>

					{index > 0 && isReservationPending ? (
						<button className='btn guests-item d-flex justify-content-center align-items-center p-0 mt-3'
							onClick={() => deleteRoom(index)}
						>
							<DeleteIcon />
						</button>
					) : (
						<div className='guests-item d-flex justify-content-center align-items-center'></div>
					)}
				</div>
			))}

			<div className='guests-actions'>
				<button className='btn btn-text-addNew'
					onClick={addNewRoom}
					disabled={roomsAvailabilities?.length === privatePaxRooms?.length || !isReservationPending}
				>
					<PlusIcon />
					<span>{marketPlace.AddNew}</span>
				</button>

			</div>

			{isReservationPending && !isMarketPlace ?
				<div className='guests-actions justify-content-end'>
					<button className='btn btn-active' onClick={savePackageRoomAvalibities}>{marketPlace.SaveChanges}</button>
				</div>
				:
				null
			}
		</div>
	)
}

export default GuestsPerRoom
