import React, { createContext, useContext, useReducer } from "react";
const ApplicationContext = createContext();
const ApplicationDispatchContext = createContext();
const initialState = {};

function ApplicatiobReducer(state = initialState, action) {
	switch (action.type) {
		case "saveBasicDetails": {
			
			return { ...state, isEditMode: false, ...action.payload };
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useApplicationState() {
	const context = useContext(ApplicationContext);
	if (context === undefined) {
		throw new Error(
			"useApplication State must be used within a Application Provider"
		);
	}
	return context;
}

function useApplicationDispatch() {
	const context = useContext(ApplicationDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useApplicationDispatch must be used within a ApplicationProvider"
		);
	}
	return context;
}

function ApplicationProvider({ children }) {
	const [state, dispatch] = useReducer(ApplicatiobReducer, initialState);
	return (
		<ApplicationContext.Provider value={state}>
			<ApplicationDispatchContext.Provider value={dispatch}>
				{children}
			</ApplicationDispatchContext.Provider>
		</ApplicationContext.Provider>
	);
}

export { ApplicationProvider, useApplicationState, useApplicationDispatch };
