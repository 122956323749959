import TextField from 'components/Form/TextField/TextField';
import React, { useEffect, useState } from 'react'
import Locale from 'translations';
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import { ReactComponent as CheckMarkIcon } from "assets/images/umrah-operations/check.svg";
import { CircleArrowLeft, CircleArrowRight } from 'modules/UmrahOperations/shared/Icons';
import { ReactComponent as ExportExcelIcon } from "assets/images/umrah-operations/export-excel-icon.svg";
import RawdaReservationsFilters from './RawdaFilters';
import moment from 'moment';
import { executeRawadReservations, exportRawadReservationsExcel, getRawadReservations } from 'services/operationStatement';
import Pagination from 'components/Pagination';
import { store } from 'react-notifications-component';
import BreadCrump from 'modules/UmrahOperations/shared/BreadCrump';
import ShowForPermission from 'helpers/showForPermission';
import OperationTabs from 'modules/UmrahOperations/shared/Tabs';



export default function OperatingCenterRawdaReservations({ lookups, operationsCenterTabs, activeTab, setActiveTab }) {
  const { operationStatement, inventory, marketPlace, productsBuilder, backOffice } = Locale;
  const fetchFilesURL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
  const initalRawdaFilters = {
    date_from: null,
    date_to: null,
    pax_from: "",
    pax_to: "",
    agent: null,
    country: null,
    status: null,
    search: "",
  };

  const [filters, setFilters] = useState(initalRawdaFilters);
  const [rawdaReservations, setRawdaReservations] = useState(null);
  const [metaData, setMetaData] = useState(null);

  function formatFilters(searchFilters) {
    searchFilters = searchFilters || filters;
    const filter = {
      dates: [
        searchFilters?.date_from ? moment(searchFilters?.date_from).format("YYYY-MM-DD") : null,
        searchFilters?.date_to ? moment(searchFilters?.date_to).format("YYYY-MM-DD") : null,
      ],
      agent_id: searchFilters?.agent?.id,
      country_id: searchFilters?.country?.id,
      'pax[from]': searchFilters?.pax_from,
      'pax[to]': searchFilters?.pax_to,
      status: searchFilters?.status?.id,
      search: searchFilters?.search,
      // page: searchFilters?.page,
    }
    return filter
  }


  async function getRawdaReservationsWithFilters(searchFilters) {
    const formatedFilters = formatFilters(searchFilters || filters);
    const res = await getRawadReservations(formatedFilters);
    if (res?.status === 200) {
      setRawdaReservations(res?.data?.data);
      setMetaData(res?.data?.meta);
    }
  }

  function resetFilters() {
    setFilters(initalRawdaFilters);
    if (initalRawdaFilters?.page === filters?.page && initalRawdaFilters?.search === filters?.search) {
      getRawdaReservationsWithFilters(initalRawdaFilters);
    }
  }

  function goTo(page) {
    setFilters({ ...filters, page });
  }

  async function handleExecuteRawdaReservation(id) {
    const res = await executeRawadReservations(id);
    if (res?.status === 200) {
      resetFilters();
      store.addNotification({
        title: operationStatement.makeExecute,
        message: operationStatement.rawdaReservationtUpdatedSuccessfully,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  }

  async function rawadaReservationExportExcel() {
    const formatedFilters = formatFilters(filters);
    const res = await exportRawadReservationsExcel(formatedFilters);
    if (res?.status === 200 || res?.status === 201) {
      if (res.data) {
        const blob = new Blob([res.data], {
          type: res.data.type,
        });
        const objectUrl = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.target = "_blank";
        anchor.href = objectUrl;
        anchor.setAttribute(
          "download",
          `rawda-reservation-${moment().format("DD-MM-YYYY")}.xlsx`
        );
        anchor.click();
      }
      store.addNotification({
        title: "Info!",
        message: `Export To Excel Successful`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  }

  useEffect(() => {
    getRawdaReservationsWithFilters()
  }, [filters?.search])

  return (
    <div className="">
      <BreadCrump title={operationStatement.operationsCenter}>
        <ShowForPermission permission="Manage-operation-center">
          <button className="btn export-excel-btn mx-2 py-2" onClick={rawadaReservationExportExcel}>
            <ExportExcelIcon />
            <span className="mx-1">{operationStatement.exportExcel}</span>
          </button>
        </ShowForPermission>
      </BreadCrump>
      <div className="wrapper">
        <OperationTabs
          tabs={operationsCenterTabs}
          activeTab={activeTab}
          IsNotPushUrl={true}
          setActiveTab={setActiveTab}
          classNames=" col-md-12 col-12  mx-auto"
          resetFilters={resetFilters}
        />
        <div className='operation-center-list'>
          <RawdaReservationsFilters
            filters={filters}
            lookups={lookups}
            setFilters={setFilters}
            resetFilters={resetFilters}
            searchWithFilters={getRawdaReservationsWithFilters}
          />
          {/* search */}
          <div className="d-flex align-items-center justify-content-end my-4 flex-wrap">
            <div className="">
              <TextField
                type="text"
                id="operations-search"
                name="operations-search"
                hasLabel={false}
                placeholder={inventory.messages.search}
                isImage={true}
                image={SeachIcon}
                prependImage={true}
                value={filters?.search}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    search: e.target.value,
                  });
                }}
              />
            </div>
          </div>


          <div className='operation-movements-table table-container'>
            <table className="table table-update w-100">
              <thead>
                <tr>
                  <th>{operationStatement.OperationNo}</th>
                  <th>{operationStatement.Agent}</th>
                  <th>{operationStatement.MadinahHotel}</th>
                  <th>{operationStatement.hotelInOut}</th>
                  <th>{operationStatement.nusuk}</th>
                  <th>{operationStatement.rawdaDate}</th>
                  <th>{marketPlace.messages.pax}</th>
                  <th>{inventory.messages.Status}</th>
                  <th>{inventory.messages.Actions}</th>
                </tr>
              </thead>
              <tbody>
                {rawdaReservations?.length > 0 ?

                  rawdaReservations?.map((reservation, index) => {
                    return (
                      <tr key={`${reservation?.operation_num}-${index}`}>
                        {/* OperationNo */}
                        <td>
                          <div className="d-flex align-items-center">
                            <span className={`indecator ${reservation?.is_executed ? "approved" : "draft"} `}></span>
                            <span className='mx-1'># {reservation?.operation_num}</span>
                          </div>
                        </td>
                        {/* agent name */}
                        <td>
                          <p>{reservation?.agent?.name}</p>
                          <div className="d-flex align-items-center">
                            <img
                              src={`${fetchFilesURL}/${reservation?.agent?.country?.flag}`}
                              alt={reservation?.agent?.country?.name}
                              className="rounded-circle"
                              width={"16px"}
                              height={"16px"}
                              style={{ flex: "0 0 16px" }}
                            />
                            <span className="font-light mx-1">
                              {reservation?.agent?.country?.name}
                            </span>
                          </div>
                        </td>

                        <td>
                          {reservation?.madinah_hotel || "-"}
                        </td>

                        {/* hotel in out */}
                        <td>
                          <div className='d-flex flex-column'>
                            <p className='d-flex align-items-center'>
                              <CircleArrowRight color="#219653" />
                              <span className='mx-1'>
                                {reservation?.checkin ?
                                  moment(reservation?.checkin).format("DD-MM-YYYY")
                                  :
                                  "-"
                                }
                              </span>
                            </p>
                            <p className='d-flex align-items-center'>
                              <CircleArrowLeft color="#EA5455" />
                              <span className='mx-1'>
                                {reservation?.checkout ?
                                  moment(reservation?.checkout).format("DD-MM-YYYY")
                                  :
                                  "-"
                                }
                              </span>
                            </p>
                          </div>
                        </td>
                        {/* Nusuk */}
                        <td width="130px">
                          <span>{reservation?.nusuk || "-"}</span>
                        </td>
                        {/* rawdaDate */}
                        <td>
                          {reservation?.rawda_date ?
                            moment(reservation?.rawda_date).format("DD/MM/YYYY")
                            :
                            "-"
                          }
                        </td>
                        {/* pax */}
                        <td>{reservation?.pax || "-"}</td>
                        {/* status */}
                        <td>
                          <span className={`status text-capitalize ${reservation?.is_executed ? "executed" : "unexecuted"}`}>
                            {reservation?.is_executed ? productsBuilder.done : inventory.messages.pending}
                          </span>
                        </td>
                        {/* Action */}
                        <td>
                          {!reservation?.is_executed ?
                            <ShowForPermission permission="Manage-operation-center">
                              <button className='btn make-execute-btn'
                                onClick={() => handleExecuteRawdaReservation(reservation?.id)}
                              >
                                <CheckMarkIcon />
                                {operationStatement.makeExecute}
                              </button>
                            </ShowForPermission>
                            :
                            null
                          }
                        </td>
                      </tr>
                    )
                  })
                  :
                  <tr>
                    <td colSpan="10">
                      <div className="product-build__product-no-data fullHeight">
                        <i className="icx icx-eye-slash" aria-hidden="true"></i>
                        <h4>{backOffice.noResult}</h4>
                      </div>
                    </td>
                  </tr>
                }

              </tbody>
            </table>
          </div>

          <div className='mt-4 pb-3'>
            <Pagination info={metaData} goTo={goTo} />
          </div>
        </div>
      </div>
    </div>
  )
}
