import React from 'react';

export default function CustomPagination() {
    return (
        <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="text-body">
                Showing 1 to 10 of 4,951 entries
            </div>
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                    <li className="page-item">
                        <a className="page-link mr-2" href="#" aria-label="Previous">
                            <span aria-hidden="true" className="p-0">
                                <i className="icx icx-left-solid-arrow"></i>
                            </span>
                            <span className="sr-only">Previous</span>
                        </a>
                    </li>
                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item"><a className="page-link" href="#">4</a></li>
                    <li className="page-item ml-2">
                        <a className="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true" className="p-0">
                                <i className="icx icx-right-solid-arrow"></i>
                            </span>
                            <span className="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}