import { useWebBuilderState } from 'context/webBuilder';
import EditableParagraph from 'modules/WebBuilder-V2/shared/EditableParagraph';
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import { useState } from 'react';
import { Collapse } from 'reactstrap';
import { ReactComponent as PlusIcon } from "assets/images/webBuilder/plus.svg";
import { ReactComponent as MinusIcon } from "assets/images/webBuilder/minus.svg";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import UploadFileComponent from 'modules/WebBuilder/shared/UploadFile';
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
export default function FAQTheme7({
  title,
  handleFaqs,
  faqs,
  image,
  focusContainer,
  id,
  itemsBoxActions
}) {
  const { selectedLanguage, content } = useWebBuilderState();
  const [collapsedItems, setCollapsedItems] = useState([]);
  const location = useLocation();
  const preview = !location.pathname.includes("edit");
  const faqContent = content?.find(item => item?.type === 'faq')
  const faqBackgroundIamge = faqContent?.image


  const Image = image ? <img src={faqBackgroundIamge} onError={(e) => {
    e.target.onerror = null;
    e.target.src = "https://back-sbs.dev.safa.asia/api/v1/files/fetch/e8d45d73-34c5-4818-95eb-8ff018cb1f32";
  }} alt="Logo" /> : null;

  const logo = preview ? (
    Image
  ) : (
    <UploadFileComponent
      icon={AddIcon}
      id={`theme_faq_section`}
      label=""
      onUpload={(value) => {
        handleFaqs({
          value,
          name: "image",
        })
      }}
      image={Image}
    />
  );

  return (
    <>
      <div className="container">
        <section
          id={`theme_faq_section`}
          className="theme_faq_section"
          // style={{ backgroundImage: `url(${faqBackgroundIamge || image})` }}
        >
          <div className="img-bg">{logo}</div>
          <div className="col-xl-6 col-lg-7 col-md-8">
            <div className="theme_faq_card">
              <EditableParagraph
                className="mb-3 font-weight-bold h4"
                contentEditable={true}
                onInput={(value) =>
                  handleFaqs({
                    value,
                    name: "title",
                    language: selectedLanguage,
                  })
                }
                text={title}
              />

              {/* <h3 className="mb-3 font-weight-bold h4">{title}</h3> */}
              {faqs &&
                faqs?.map((que, index) => {
                  const isOpen = collapsedItems.includes(index);
                  const openCollapse = () => {
                    const updated = [...collapsedItems, index];
                    setCollapsedItems(updated);
                  };
                  const closeCollapse = () =>
                    setCollapsedItems(
                      collapsedItems.filter((i) => i !== index)
                    );
                  return (
                    <div className="faqs-question-wrapper" key={que.id}>
                      <EditBox
                        editMode={focusContainer === id}
                        actions={[itemsBoxActions(que.id)]}
                        defaultIcon=""
                        className="mb-2"
                      >
                        <div className="faqs-container-question">
                          <span> {que.question[selectedLanguage]} </span>
                          {isOpen ? (
                            <button
                              className="bg-transparent border-0 faq-btn"
                              onClick={closeCollapse}
                            >
                              <MinusIcon />
                            </button>
                          ) : (
                            <button
                              className="bg-transparent border-0 faq-btn"
                              onClick={openCollapse}
                            >
                              <PlusIcon />
                            </button>
                          )}
                        </div>
                        <Collapse isOpen={isOpen}>
                          <EditableParagraph
                            className="faqs-container-body"
                            contentEditable={true}
                            onInput={(value) =>
                              handleFaqs({
                                value,
                                name: "body",
                                questionIndex: index,
                                language: selectedLanguage,
                              })
                            }
                            text={que.body[selectedLanguage]}
                          />
                        </Collapse>
                      </EditBox>
                    </div>
                  )
                })}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}