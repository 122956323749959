import CustomModal from 'modules/WebBuilder/shared/CustomModal'
import React, { useEffect, useState } from 'react';
import TextField from "components/Form/TextField/TextField";
import { useWebBuilderDispatch } from 'context/webBuilder';
import { ReactComponent as EyeIcon } from "assets/images/webBuilder/eye.svg";
import { ReactComponent as EyeSlashIcon } from "assets/images/webBuilder/eye-slash.svg";
import validate, { isFormValid } from 'helpers/validate';



export default function SocialMediaModal({ isOpen, toggle, header, socialMediaLinks }) {
  const dispatch = useWebBuilderDispatch();
  const [socialMediaData, setSocialMediaData] = useState(socialMediaLinks);
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  function checkFormErrors() {
    let submitError = {};
    socialMediaData?.forEach((item, index) => {
      if (item?.visible) {
        submitError = {
          ...submitError,
          ...validate(
            { name: `url${index}`, value: item?.url },
            { required: true }
          )
        }
      }
    });
    setErrors(() => submitError);
  }

  function handleChangeLinks(keyName, value, index) {
    let clone = [...socialMediaData];
    let selected = clone[index];
    selected = { ...selected, [keyName]: value };
    clone[index] = selected;
    setSocialMediaData(clone);
    setErrors({
      ...errors,
      ...validate(
        { name: [`url${index}`], value },
        { required: keyName === "visible" && value ? true : keyName === "visible" && !value ? false : true },
      ),
    });
  }

  function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  };

  function handleInsert() {
    dispatch({
      type: "updateFooter",
      payload: {
        name: "links",
        value: socialMediaData
      }
    });
    toggle()
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      handleInsert();
    }
  }, [isErrorLoaded]);


  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      header={header}
    >
      <div className='footer-social-modal'>


        {socialMediaData?.map((link, index) => {
          return (
            <div key={link?.id} className='d-flex align-items-center my-2'>
              <div className='w-100'>
                <TextField
                  type="text"
                  label={link?.name}
                  placeholder={"URL link"}
                  id={`${link?.name}Url`}
                  name={`${link?.name}Url`}
                  disabled={!link?.visible}
                  value={link?.url}
                  onChange={(e) => handleChangeLinks('url', e.target.value, index)}
                  errors={errors[`url${index}`]}
                  color={errors[`url${index}`]?.required ? "danger" : ""}
                />
              </div>
              <span className='mx-2 mt-3 pointer' onClick={() => handleChangeLinks('visible', !link?.visible, index)}>
                {link?.visible ?
                  <EyeIcon />
                  :
                  <EyeSlashIcon />
                }
              </span>
            </div>
          )
        })}

        <div className='d-flex justify-content-end mt-5'>
          <button className="primary-button w-25" onClick={submit}>
            Insert
          </button>
        </div>
      </div>
    </CustomModal>

  )
}
