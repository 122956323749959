import { useSBSDispatch } from "context/global";
import { getCredit, getDebit } from "services/wallet";

const useWallet = () => {
	const dispatch = useSBSDispatch();
	const fetchWallets = async () => {
		const res = await getCredit();
		dispatch({ type: "getSafaBalance", payload: res?.data?.data });

		const res1 = await getDebit();
		dispatch({ type: "getMyBalance", payload: res1?.data?.data });
	};

	return {
		fetchWallets,
	};
};

export default useWallet;
