import BreadCrump from 'modules/UmrahOperations/shared/BreadCrump';

import { getAccountingAgents } from 'services/operationStatement'
import { useEffect, useState } from 'react';

import AccountingAgentsList from './List';
import Locale from 'translations';
import ShowSubscription from 'helpers/ShowSubscription';



export default function AccountingAgents() {


  // ** states
  const [agentsList, setagentsList] = useState([]);
  const [meta, setMeta] = useState(null);



  async function getAgents(params) {
    const res = await getAccountingAgents(params);
    if (res?.status === 200) {
      setagentsList(res?.data?.data);
      setMeta(res?.data?.meta);
    }
  };

  // get initial agents without filters
  useEffect(() => {
    if (!meta) {
      getAgents({});
    }
  }, [meta]);


  return (
    <ShowSubscription module="agent_accounting">
      <div className="official-movement-container px-new-26">
        <BreadCrump title={Locale.operationAccounting.allAgentsAccounting} />
        <AccountingAgentsList
          agentsList={agentsList}
          meta={meta}
          getAgents={getAgents}
        />
      </div>
    </ShowSubscription>

  )
}