import axios from 'axios'
import { cleanEmpty } from './general'

const crm = process.env.REACT_APP_API_URL + '/v1'

export const reservationPackagesList = async (filters = {}) => {
	return await axios
		.get(`${crm}/crm/packages/list-all-reservations`, {
			params: cleanEmpty(filters)
		})
		.then((res) => res)
		.catch((err) => err.response)
}
export const fetchSearchedPackages = async (packageId, filters) => {
	return await axios
		.get(`${crm}/crm/packages/search-packages/${packageId}`, {
			params: cleanEmpty(filters)
		})
		.then((res) => res)
		.catch((err) => err.response)
}

export const CRMChangeReservation = async (data) => {
	return await axios
		.post(`${crm}/crm/packages/change-reservation`, data)
		.then((res) => res)
		.catch((err) => err.response)
}

export const CRMListChangePriority = async (data = {}) => {
	return await axios
		.post(`${crm}/crm/packages/change-priority`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const CRMEditTraveler = async (travelerId, data) => {
	return await axios
		.post(`${crm}/b2c/packages/edit-traveler/${travelerId}`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const CRMAddPayment = async (data) => {
	return await axios
		.post(`${crm}/b2c/packages/add-payment`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const CRMDeletePayment = async (paymentId) => {
	return await axios
		.get(`${crm}/b2c/packages/delete-payment/${paymentId}`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const CRMexportExcel = async (filters) => {
	return await axios
		.get(`${crm}/crm/packages/export-all-reservations/`, {
			params: cleanEmpty(filters),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response)
}



export const CSRequestsList = async (filter) => {
	return await axios
		.get(`${crm}/customer-service`, {
			params: cleanEmpty(filter)
		})
		.then((res) => res)
		.catch((err) => err.response)
}
export const CSRequestsDetails = async (id) => {
	return await axios
		.get(`${crm}/customer-service/${id}`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const addCallLog = async (id, data) => {
	return await axios
		.post(`${crm}/customer-service/${id}/add-log`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const callLogList = async (id) => {
	return await axios
		.get(`${crm}/customer-service/${id}/log-list`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const historyList = async (id) => {
	return await axios
		.get(`${crm}/customer-service/${id}/history-list`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const changePackageStatus = async (id, data) => {
	return await axios
		.post(`${crm}/customer-service/${id}/change-status`, data)
		.then((res) => res)
		.catch((err) => err.response)
}

export const getProductTypeLookups = async (id) => {
	return await axios
		.get(`${crm}/customer-service/product-types`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const getServicesLookups = async (id) => {
	return await axios
		.get(`${crm}/customer-service/services`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const addRequest = async (data) => {
	return await axios
		.post(`${crm}/customer-service`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const editRequest = async (id, data) => {
	return await axios
		.put(`${crm}/customer-service/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const accountingList = async (filters) => {
	return await axios
		.get(`${crm}/crm/packages/accounting-list`, {
			params: cleanEmpty(filters)
		})
		.then((res) => res)
		.catch((err) => err.response)
}

export const accountingPaymentsList = async (id, filters) => {
	return await axios
		.get(`${crm}/crm/packages/package-reservations/${id}/payments`, {
			params: cleanEmpty(filters)
		})
		.then((res) => res)
		.catch((err) => err.response)
}
export const deletePayment = async (id) => {
	return await axios
		.get(`${crm}/b2c/packages/delete-payment/${id}`).then((res) => res)
		.catch((err) => err.response)
}


export const viewCRMPackageDetails = async (id) => {
	return await axios
		.get(`${crm}/crm/packages/view-reservations/${id}`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const getExtraServices = async (id) => {
	return await axios
		.get(`${crm}/b2c/packages/applications/${id}/extra-services`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const historyListCRM = async (id) => {
	return await axios
		.get(`${crm}/b2c/packages/applications/${id}/history`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const callLogListCRM = async (id) => {
	return await axios
		.get(`${crm}/b2c/packages/applications/${id}/log-list`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const addCallLogCRM = async (data) => {
	return await axios
		.post(`${crm}/b2c/packages/add-call-log`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const listCustomrrs = async (filters) => {
	return await axios
		.get(`${crm}/crm/packages/list-customers`, {
			params: cleanEmpty(filters)
		})
		.then((res) => res)
		.catch((err) => err.response)
}

export const listallTravelers = async (filters) => {
	return await axios
		.get(`${crm}/b2c/packages/list-all-travelers`, {
			params: cleanEmpty(filters)
		})
		.then((res) => res)
		.catch((err) => err.response)
}