import { store } from 'react-notifications-component';
import { flightTypes } from './tripPlannerLookups';
import moment from 'moment';

export function formatTripPlannerData(responseData) {
  let formatedData = {
    packageDetails: {
      name: responseData?.name,
      type: responseData?.product_type,
      product: responseData?.category_type,
      destinations: responseData?.destinations?.map(destination => ({ ...destination, id: destination?.country_id, value: destination?.country_id, label: destination?.name })),
      country: { ...responseData?.country, id: responseData?.country?.country_id, value: responseData?.country?.country_id },
      duration: responseData?.duration,
      rate: { id: responseData?.rate, name: responseData?.rate, value: responseData?.rate },
      has_visa: responseData?.has_visa,
      has_insurance: responseData?.has_insurance,
      visa_type: responseData?.visa_type,
      visa_count: responseData?.visa_count,
      terms: responseData?.terms,
      services: responseData?.services
    },
    itinerary_days: generateItineraryDaysArray(responseData),

    setting_dates: {
      start_date: null,
      end_date: null,
      exclude_type: responseData?.dates_off?.length > 0 ? "dates_off" : responseData?.days_off?.length > 0 ? "days_off" : "" ,
      days_off: responseData?.days_off || [],
      dates_off: responseData?.dates_off?.map(date=> moment(date)) || []
    },
    trips: responseData?.trips
  };
  return formatedData;
}


function generateItineraryDaysArray(responseData) {
  let days = [];
  const responseDays = responseData?.itinerary?.map(day => {
    return {
      ...day,
      items: day?.items?.map(segement => {
        if (segement?.item?.id === 6) {
          return formatHotelItem(segement)
        }
        if (segement?.item?.id === 1) {
          return formatFlightItem(segement)
        }
        if (segement?.item?.id === 7) {
          return formatAttractionItem(segement)
        }
        if (segement?.item?.id === 2) {
          return formatTransportationItem(segement)
        }
        return segement
      })
    };
  });
  for (let i = 0; i < responseData?.duration; i++) {
    const currentDay = i + 1;
    const dayExist = responseDays?.find(dayItem => dayItem?.day === currentDay);
    if (dayExist) {
      days.push(dayExist)
    } else {
      days.push({ day: currentDay, items: [] })
    }

  }
  return days;
}


function formatHotelItem(hotelItem) {
  return {
    ...hotelItem,
    item_type: "hotel",
    item_type_id: 6,
    id: hotelItem?.id,
    day: hotelItem?.day,
    country: { ...hotelItem?.itemable?.country, id: hotelItem?.itemable?.country?.country_id, value: hotelItem?.itemable?.country?.country_id },
    city: hotelItem?.itemable?.city,
    hotel_name: { name: hotelItem?.itemable?.hotel?.name, id: hotelItem?.itemable?.hotel?.hotel_code },
    nights: hotelItem?.itemable?.nights,
    meal_plan: { id: hotelItem?.itemable?.meal_type, name: hotelItem?.itemable?.meal_type },
    description: hotelItem?.itemable?.description,
    notes: hotelItem?.itemable?.notes,
    currentView: "view",
    isNew: false,
  }
}

function formatFlightItem(flightItem) {
  return {
    ...flightItem,
    item_type: "flight",
    item_type_id: 1,
    id: flightItem?.id,
    flight_type: flightTypes.find(type => type?.id === flightItem?.itemable?.flight_type),
    carrier_name: flightItem?.itemable?.transporter,
    return_day: null,
    from_country: {
      ...flightItem?.itemable?.from_country,
      id: flightItem?.itemable?.from_country?.country_id,
      value: flightItem?.itemable?.from_country?.country_id
    },
    from_airport: flightItem?.itemable?.from_port,
    to_country: {
      ...flightItem?.itemable?.to_country,
      id: flightItem?.itemable?.to_country?.country_id,
      value: flightItem?.itemable?.to_country?.country_id
    },
    to_airport: flightItem?.itemable?.to_port,
    currentView: "view",
    isNew: false,
  }
}

function formatAttractionItem(attractionItem) {
  const { itemable } = attractionItem;
  return {
    ...attractionItem,
    item_type: "attraction",
    item_type_id: attractionItem?.item?.id,
    id: attractionItem?.id,
    name: itemable?.name,
    country: {
      ...itemable?.country,
      id: itemable?.country?.country_id,
      value: itemable?.country?.country_id
    },
    city: itemable?.city,
    area: itemable?.area,
    photos: itemable?.photos,
    services: itemable?.services,
    description: itemable?.description,
    duration: itemable?.duration,
    terms: itemable?.terms,
    policy: itemable?.policy,
    other: itemable?.other,
    notes: itemable?.notes,
    pickup: itemable?.pickup,
    drop_off: itemable?.drop_off,
    currentView: "view",
    isNew: false,
  }
}


function formatTransportationItem(transportationItem) {
  const { itemable } = transportationItem;
  return {
    ...transportationItem,
    item_type: "transportation",
    item_type_id: transportationItem?.item?.id,
    id: transportationItem?.id,
    from: { name: itemable?.from_item?.itemable?.name || itemable?.from_item?.itemable?.hotel?.name || itemable?.from_item?.itemable?.to_port?.name || itemable?.from_item?.item?.name, id: itemable?.from_item?.id },
    to: { name: itemable?.to_item?.itemable?.name || itemable?.to_item?.itemable?.hotel?.name || itemable?.to_item?.itemable?.to_port?.name || itemable?.to_item?.item?.name, id: itemable?.to_item?.id },
    from_city: itemable?.from_city,
    to_city: itemable?.to_city,
    from_area: itemable?.from_area,
    to_area: itemable?.to_area,
    vehicle_type: itemable?.vehicle,
    vehicle_model: { id: +itemable?.vehicle_model, name: itemable?.vehicle_model },
    vehicle_count: itemable?.vehicle_count,
    vehicle_photo: itemable?.vehicle_photo,
    pickup_point: itemable?.pickup_point,
    pickup_time: moment(itemable?.pickup_time, "hh:mm:ss A"),
    drop_off_point: itemable?.drop_off_point,
    drop_off_time: moment(itemable?.drop_off_time, "hh:mm:ss A"),
    description: itemable?.description,
    notes: itemable?.notes,
    terms: itemable?.terms,
    option: itemable?.option,
    currentView: "view",
    isNew: false,
  }
}


export function successfulResponse(message) {
  store.addNotification({
    title: "success",
    message: message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 2000,
      onScreen: true,
      pauseOnHover: true,
    },
  });
}