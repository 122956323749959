import React from "react";

function ToggleCheckbox({ onChange, checked,label, ...restProps }) {
	return (
		<div className='toggle-check-box'>
      <span className='checkbox-label'>{label} </span>
			<label className="switch">
				<input
					{...restProps}
					type="checkbox"
					onChange={onChange}
					checked={checked}
				/>
				<span className="slider round"></span>
			</label>
		</div>
	);
}

export default ToggleCheckbox;
