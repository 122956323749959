import TextField from 'components/Form/TextField/TextField';
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { ReactComponent as CarIcon } from "assets/images/umrah-operations/car-icon.svg";
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import { ReactComponent as SaveIcon } from "assets/images/umrah-operations/save-icon.svg";
import SelectField from 'components/Form/SelectField/SelectField';
import validate, { isFormValid } from "helpers/validate";
import Locale from 'translations';
import { getDriversLookup } from 'services/operationStatement';
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';

export default function VehiclesDriverModal({ toggle, driversData, countries, setDriversModal, saveDriversData }) {
  const { operationStatement, marketPlace } = Locale;
  // let goingToPrevValue = useRef(null);
  const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const notRequiredKeys = ["phone_country", "vehicle_no", "id"];


  const [driversListLookup, setDriversListLookup] = useState({});

  async function getDriversList(inputValue, index) {
    if (inputValue.length > 2 && !isNaN(inputValue)) {
      const driversList = await getDriversLookup({ phone: inputValue, movement_id: driversData?.movmentId });
      let result = [];

      driversList?.data?.data.map((item) => {
        result.push({
          ...item,
          id: null,
        });
      });
      setDriversListLookup({ ...result, [index]: result });
    }
  };

  function handleInputChanges(keyName, value, index) {
    let driversClone = [...driversData?.data];
    let currentDriver = { ...driversClone[index] }
    currentDriver = {
      ...currentDriver,
      [keyName]: value
    }
    driversClone[index] = currentDriver;
    setDriversModal({ ...driversData, data: driversClone });

    setErrors({
      ...errors,
      ...validate(
        { name: `${keyName}-${index}`, value: currentDriver[keyName] },
        { required: !notRequiredKeys.includes(keyName) }
      ),
    })
  }

  function handleSelectDriver(driverData, index) {
    let driversClone = [...driversData?.data];
    let currentDriver = { ...driversClone[index] }
    currentDriver = { ...driverData, phone_code: driverData?.phone_country }
    driversClone[index] = currentDriver;
    setDriversModal({ ...driversData, data: driversClone });
  }

  function handleDeleteVehicle(index) {
    let driversClone = [...driversData?.data];
    driversClone.splice(index, 1);
    setDriversModal({ ...driversData, data: driversClone });
  }


  function checkFormErrors() {
    let submitErrors = {}
    driversData?.data.forEach((driver, index) => {
      Object.keys(driver).forEach(key => {
        if (!notRequiredKeys.includes(key)) {
          submitErrors = {
            ...submitErrors,
            ...validate(
              { name: `${key}-${index}`, value: driver?.[key] },
              { required: true }
            ),
          }
        }
      })
    })
    setErrors({ ...submitErrors });
  }


  function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  };

  // function clearSearchText(e, index) {
  //   goingToPrevValue.current = driversData.data[index];
  //   const value = e.target.value;
  //   if (value.length > 0) {
  //     let driversClone = [...driversData?.data];
  //     let currentDriver = { ...driversClone[index], phone: null, phone_code: null, phone_country: null, name: "" };
  //     driversClone[index] = currentDriver;
  //     setDriversModal({ ...driversData, data: driversClone });
  //   }
  // }

  // function handleBlur(index) {
  //   let driversClone = [...driversData?.data];
  //   let currentDriver = {
  //     ...driversClone[index],
  //     phone: goingToPrevValue.current.phone,
  //     phone_code: goingToPrevValue.current.phone_code,
  //     phone_country: goingToPrevValue.current.phone_country,
  //     name: goingToPrevValue.current.name
  //   };
  //   driversClone[index] = currentDriver;
  //   setDriversModal({ ...driversData, data: driversClone });
  // }

  useEffect(() => {
    if (isFormValid(errors)) {
      saveDriversData();
    }
  }, [isErrorLoaded]);



  return (
    <Modal isOpen={driversData?.isOpen} size='lg' className='operation-modal'>
      <ModalHeader toggle={toggle} className='align-items-center'>
        {operationStatement.vehiclesDriver}
      </ModalHeader>
      <ModalBody>
        {driversData?.data?.map((driver, index) => {
          console.log("asdasdasdasd", driver?.phone?.name, driver?.phone, index);
          return (
            <div key={`driver-${index}`} className='mb-3'>
              <div className='d-flex align-items-center justify-content-between mb-2 px-1'>
                <p className='d-flex align-items-center text-uppercase' style={{ color: "#777" }}>
                  <CarIcon width="16px" />
                  <span className='mx-1'>{operationStatement.vehicleData} ({index + 1}) </span>
                </p>
                {index > 0 &&
                  <button className='btn p-0' onClick={() => handleDeleteVehicle(index)}>
                    <DeleteIcon width="18px" />
                  </button>
                }
              </div>
              {/* form */}
              <div className="row mx-0">
                {/* driver phone number */}
                <div className="col-md-5 col-12">
                  <div className="row mx-0">
                    {/* phone code */}
                    <div className="col-4 company_phone_code">
                      <SelectField
                        label={operationStatement.PhoneNumber}
                        id={`driver_phone_code-${index}`}
                        name={`driver_phone_code-${index}`}
                        value={
                          driver?.phone_code?.flag ?
                            <img
                              src={`${fetchFlag}/${driver?.phone_code?.flag}`}
                              width="35px"
                              alt={driver?.phone_code?.label}
                            />
                            :
                            driver?.phone_code?.label || driver?.phone_code?.phone_code
                        }
                        onChange={(e) => handleInputChanges("phone_code", e, index)}
                        options={countries}
                      />
                    </div>
                    {/* phone */}
                    <div className="col-8 px-0">
                      <AutoCompleteField
                        type={"phone"}
                        label=""
                        id={`driver_phone-${index}`}
                        name={`driver_phone-${index}`}
                        placeholder={"000 0000 000"}
                        listAuto={driversListLookup[index]}
                        setListAuto={setDriversListLookup}
                        getListAuto={(e) => getDriversList(e, index)}
                        value={driver?.phone?.name === undefined ? driver?.phone : driver?.phone?.name}
                        // onFocus={(e) => clearSearchText(e, index)}
                        // onBlur={() => handleBlur(index)}
                        onChange={(e) => {
                          handleInputChanges("phone", { name: e }, index)
                        }}
                        onSelectValue={(e) => {
                          handleSelectDriver(e, index)
                        }}
                        extraTextPosition="prepend"
                        extraText={
                          driver?.phone_code?.phone_code ||
                          driver.phone_code?.label ||
                          driver?.phone_code?.value
                        }
                        color={errors?.[`phone-${index}`]?.required ? "danger" : ""}
                        errors={errors?.[`phone-${index}`]}
                      />


                      {/* <NumberField
                        type={"phone"}
                        label=""
                        id={`driver_phone-${index}`}
                        name={`driver_phone-${index}`}
                        value={driver?.phone}
                        removeArrow={true}
                        placeholder={"000 0000 000"}
                        min={3}
                        onChange={(e) => handleInputChanges("phone", e.target.value, index)}
                        extraTextPosition="prepend"
                        extraText={
                          driver?.phone_code?.phone_code ||
                          driver.phone_code?.label ||
                          driver?.phone_code?.value
                        }
                        color={errors?.[`phone-${index}`]?.required ? "danger" : ""}
                        errors={errors?.[`phone-${index}`]}
                      /> */}
                    </div>
                  </div>
                </div>
                {/* driver name */}
                <div className='col-md-4 col-12'>
                  <TextField
                    label={marketPlace.messages.name}
                    placeholder={operationStatement.typeName}
                    id={`driver_name-${index}`}
                    name={`driver_name-${index}`}
                    value={driver?.name}
                    onChange={(e) => handleInputChanges("name", e.target.value, index)}
                    color={errors?.[`name-${index}`]?.required ? "danger" : ""}
                    errors={errors?.[`name-${index}`]}
                  />
                </div>
                {/* Vehicle No. */}
                <div className="col-md-3">
                  <TextField
                    label={operationStatement.vehicleNo}
                    placeholder={operationStatement.typeVehicleNo}
                    id={`vehicle_no-${index}`}
                    name={`vehicle_no-${index}`}
                    value={driver?.vehicle_no}
                    onChange={(e) => handleInputChanges("vehicle_no", e.target.value, index)}
                    color={errors?.[`vehicle_no-${index}`]?.required ? "danger" : ""}
                    errors={errors?.[`vehicle_no-${index}`]}
                  />
                </div>
              </div>
              {index < driversData?.data?.length - 1 && <hr />}

            </div>
          )
        })}


        <div className="col-12">
          <button className='btn save-btn' onClick={submit}>
            <SaveIcon />
            <span className='mx-1'>{marketPlace.SaveChanges}</span>
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}
