import moment from "moment";
import React, {  useState } from "react";
import {
	ButtonDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from "reactstrap";

const PackageMonth = ({ label, placeholder, onChange,value,avilableDates }) => {
	const [first, setfirst] = useState(false);
	const [isChildOpen, setIsChildOpen] = useState();
	const toggleChild = (index) => {
		let value = index === isChildOpen ? null : index;
		setIsChildOpen(value);
	};
	const toggleParent = () => {
		setfirst(!first);
	};



	const chooseDate = ( date) => {
		onChange(date);
		toggleParent();
		setIsChildOpen(null);
	};
	return (
		<div className="w-100">
			<label class="control-field__label  ">{label}</label>
			<ButtonDropdown
				isOpen={first}
				toggle={toggleParent}
				size="lg"
				className="w-100 my-1 control-field"
			>
				<DropdownToggle className="bg-white text-black-50 toggle-parent">
					<span>{value ? value : placeholder}</span>
					<i className="fas fa-chevron-down"></i>
				</DropdownToggle>
				<DropdownMenu className="w-100" style={{minWidth:180}}>
					{avilableDates.map((data, index) => (
						<PackageMonthChild
							key={data.month}
							data={data}
							isOpen={isChildOpen === index}
							toggle={() => toggleChild(index)}
							chooseDate={chooseDate}
							selectedDate={value}
						/>
					))}
				</DropdownMenu>
			</ButtonDropdown>
		</div>
	);
};

export default PackageMonth;

function PackageMonthChild({ isOpen, toggle, chooseDate, data, selectedDate }) {
	const {  days, month } = data;

	const numberOfDaysInMonth = moment(month).daysInMonth();
	const dayInweek = moment(`${month}-1`).format("dd");

	const weekDays = {
		Su: 1,
		Mo: 2,
		Tu: 3,
		We: 4,
		Th: 5,
		Fr: 6,
		Sa: 7,
	};

	const daysOrder = weekDays[dayInweek];
	const firstDayStyle = { gridColumnStart: daysOrder };

	const selectMonth = () => {
		chooseDate(month);
	};
	const monthWord = moment(month).format("MMMM YYYY");
	return (
		<div>
			<DropdownItem className="p-0 dropdown_item" toggle={false}>
				<ButtonDropdown
					direction="right"
					isOpen={isOpen}
					toggle={() => {}}
					className="bg-transparent w-100 py-0 dropdown-hover"
				>
					<DropdownToggle className="bg-transparent text-black-50 border-0 w-100 child-dropdown">
						<span onClick={selectMonth} className="px-2 py-2 ">
							{monthWord}
						</span>{" "}
						<i
							className="fas fa-calendar-alt text-warning px-1 py-2"
							onClick={toggle}
						/>
					</DropdownToggle>
					{isOpen && (
						<DropdownMenu className="p-0 dropdown-menu-arrow">
							<DropdownItem className="p-0 dropdown_item">
								<div className="w-100 text-center py-1">{monthWord}</div>
								<div className="month-container">
									<div>Su</div>
									<div>Mo</div>
									<div>Tu</div>
									<div>We</div>
									<div>Th</div>
									<div>Fr</div>
									<div>Sa</div>
									{Array(numberOfDaysInMonth)
										.fill(1)
										.map((i, index) => {
											const dayNumber = i + index;
											const activeDay = days.includes(dayNumber);
											const day = `${month}-${dayNumber}`;
											const onClick = activeDay
												? () => {
														chooseDate( day );
												  }
												: undefined;
											return (
												<div
													key={month + dayNumber}
													className={`${activeDay ? "active" : ""} ${
														day === selectedDate ? "selected-day" : ""
													}`}
													style={{ ...(index === 0 && firstDayStyle) }}
													onClick={onClick}
												>
													{dayNumber}
												</div>
											);
										})}
								</div>
							</DropdownItem>
						</DropdownMenu>
					)}
				</ButtonDropdown>
			</DropdownItem>
		</div>
	);
}

export { PackageMonthChild };
