import React, { useState } from "react";
import { ReactComponent as OutGoingCallIcon } from "assets/images/crm/call-outgoing.svg";
import { ReactComponent as CallIcon } from "assets/images/crm/call-incoming.svg";
import { ReactComponent as OutGoingCallRedIcon } from "assets/images/crm/red-call-outgoing.svg";

import { ReactComponent as IncomingCallIcon } from "assets/images/crm/callIncoming.svg";

import Locale from "translations";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { addCallLogCRM } from "services/CRM";
import moment from 'moment';


const CallLog = ({ callLogsData, setReload }) => {
	const { id } = useParams();
	const { inventory, CRM } = Locale;

	//[1=>inbound, 2=>outbound]
	const [log, setLog] = useState({
		type: "inbound",
		note: "",
	});

	async function addLog() {
		const data = {
			call_type: log.type,
			note: log.note,
			package_reservation_id: +id,
		}
		const res = await addCallLogCRM(data);
		if (res.status === 200 || res.status === 201) {
			setLog({
				...log,
				note: "",
			});
			setReload(prev => !prev);
			store.addNotification({
				title: "info!",
				message: res.data.message ?? "Log Successfully Added",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1100,
					onScreen: true,
					pauseOnHover: true,
				}
			});
		}
	}

	return (
		<div className="call-log">
			<div className="call-log-buttons">
				<button className={`btn btn-item btn-ingoing shadow-none ${log.type === "inbound" ? "active" : ""}`}
					onClick={() => setLog({ ...log, type: "inbound" })}
				>
					<CallIcon />
					<span>{CRM.Inbound}</span>
				</button>
				<button className={`btn btn-item btn-outgoing shadow-none ${log.type === "outbound" ? "active" : ""}`}
					onClick={() => setLog({ ...log, type: "outbound" })}
				>
					<OutGoingCallIcon />
					<span>{CRM.Outbound}</span>
				</button>
			</div>

			<div className="call-log-note">
				<label htmlFor="call-log-note" className="mb-1">{CRM.Note}</label>
				<textarea
					className="w-100 p-3"
					placeholder={inventory.messages.notecall}
					id="call-log-note"
					name="call-log-note"
					value={log.note}
					onChange={(e) => setLog({ ...log, note: e.target.value })}
				/>
			</div>

			<button className="btn save-log-btn"
				onClick={() => addLog()}
				disabled={!log.note}
			>
				{inventory.messages.save}
			</button>
			{callLogsData && callLogsData?.length > 0 ?
				<div className="call-logs-history">
					<h3 className="font-weight-bold mb-4 mt-3 h5">Calls Log</h3>
					{callLogsData.map((call) => (
						<div className="call-log-box">
							<div className="call-log-info">
								<p className="">
									{call.call_type === "inbound" ?
										<IncomingCallIcon />
										:
										<OutGoingCallRedIcon />
									}
									<span className="ms-2">
										Logged by {call.by_user}
									</span>
								</p>
								<span className="text-secondary">
									{call.call_date ?
										<>
											{moment(call.call_date).format("DD.MMM.YYYY")}
											<span className="mx-2">|</span>
											{moment(call.call_date).format("HH:mm")}
										</>
										:
										null
									}
								</span>
							</div>
							<p className="call-note">
								{call?.note}
							</p>
						</div>
					))}
				</div>
				:
				null
			}
		</div>
	);
};

export default CallLog;
