import { SaveIcon } from 'modules/UmrahOperations/shared/Icons';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import { useState } from 'react';
import validate, { isFormValid } from 'helpers/validate';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';
import { createGroup, updateGroup } from 'services/operationStatement';
import Locale from 'translations';
import { getOperationsLookup } from 'modules/UmrahOperations/helper/operationsLookup';


const availableTimelookup = [
  { id: "24", name: "24h" },
  { id: "12", name: "12h" },
  { id: "6", name: "6h" },
];


export default function AddEditMovementGroupModal({ groupModal, toggleGroupModal, setGroupModal, getGoupsList }) {
  // translations
  const { movementManagement } = Locale;

  // states
  const [errors, setErrors] = useState({});

  const { citiesLookup } = getOperationsLookup(Locale);

  // check if group form has errors
  function checkFormErrors() {
    let submitError = {};
    Object.keys(groupModal.data).forEach((key) => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: groupModal.data[key] },
          { required: true }
        ),
      };
    });
    setErrors(() => submitError);
    return submitError;
  }




  // group modal input changes
  function handleModalInputsChanges({ key, value }) {
    let groupDataClone = { ...groupModal.data }
    groupDataClone = { ...groupDataClone, [key]: value };
    setErrors({
      ...errors,
      ...validate(
        { name: key, value },
        { required: true }
      ),
    });
    setGroupModal({ ...groupModal, data: groupDataClone })
  }

  // handle create and update group operations
  async function handleCreateUpdateGroup(groupId) {
    let formErrors = checkFormErrors();
    let res;
    if (isFormValid(formErrors)) {
      let message;
      let data = {
        name: groupModal.data.groupName,
        available_time: +groupModal.data.available_time?.id,
        city_id: groupModal.data.city?.id
      }
      res = groupId ? await updateGroup(groupId, data) : await createGroup(data);
      if (res?.status === 200 || res?.status === 201) {
        message = groupId ? "Group Updated Succesfully" : "Group Added Succesfully";
        handleResponesNotification({ alertType: "success", message, title: "" });

        toggleGroupModal({ data: null, type: null });
        getGoupsList({});
      } else {
        message = groupId ? "Faild to Update Group" : "Faild to Add Group";
        handleResponesNotification({ alertType: "danger", message, title: "" });

      }
    }
  }


  return (
    <Modal isOpen={groupModal.isOpen} centered className="operation-modal">
      <ModalHeader toggle={() => toggleGroupModal({ data: null, type: null })}>
        {groupModal.type === "Edit" ? movementManagement.editDelegateGroup : movementManagement.addDelegateGroup}
      </ModalHeader>
      <ModalBody>
        <div className="row mx-0">
          {/* Group Name */}
          <div className='col-md-6 col-12'>
            <TextField
              label={movementManagement.groupName}
              placeholder={movementManagement.groupName}
              name="group-name"
              id="group-name"
              value={groupModal.data?.groupName}
              onChange={(e) => handleModalInputsChanges({ key: "groupName", value: e.target.value })}
              color={errors?.groupName?.required ? "danger" : ""}
              errors={errors?.groupName}
            />
          </div>

          {/* Available Time */}
          <div className='col-md-6 col-12'>
            <SelectField
              label={movementManagement.availableTime}
              name="available-time"
              id="available-time"
              options={availableTimelookup}
              value={groupModal.data?.available_time?.name}
              onChange={(e) => handleModalInputsChanges({ key: "available_time", value: e })}
              color={errors?.available_time?.required ? "danger" : ""}
              errors={errors?.available_time}
            />
          </div>

          {/* city */}
          <div className='col-12'>
            {/*  */}
            <SelectField
              label={movementManagement.city}
              name="city"
              id="city"
              options={citiesLookup}
              value={groupModal.data?.city?.name}
              onChange={(e) => handleModalInputsChanges({ key: "city", value: e })}
              color={errors?.city?.required ? "danger" : ""}
              errors={errors?.city}
            />
          </div>
          {/* action button */}
          <div className='col-12 mt-3'>
            <button className="btn save-btn"
              onClick={() => handleCreateUpdateGroup(groupModal.id)}
            >
              <SaveIcon color="#D4A655" />
              {movementManagement.save}
            </button>
          </div>
        </div>


      </ModalBody>

    </Modal>
  )
}
