import CheckIcon from 'modules/market-place/NewUI/icons/check';

export default function HotelFacilities({item}){
  return (
   <div className='hotel-facilities'>
    {
      item.facilities&&item.facilities.length >0 ?item.facilities.map((facility,index)=>
      { return facility?<p>
        <CheckIcon /><span> {facility}</span>
      </p>:null}):null
    }

   </div>
  )
}