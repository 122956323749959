import { createContext, useContext, useReducer } from "react";
const OnlineVisaSearch = JSON.parse(localStorage.getItem("onlineVisaSearch"));
const groundServiceSearch = JSON.parse(
	localStorage.getItem("groundServiceSearch")
);
const groundServicePassengers = JSON.parse(
	localStorage.getItem("groundServicePassengers")
);
const transportationSearch = JSON.parse(
	localStorage.getItem("transportationSearch")
);
const transportationPassengers = JSON.parse(
	localStorage.getItem("transportationPassengers")
);
const onlineVisaCashedPassengers = JSON.parse(
	localStorage.getItem("onlineVisaCashedPassengers")
);
const redirectURL = JSON.parse(localStorage.getItem("redirectURL"));

// const searchBy = JSON.parse(localStorage.getItem("searchBy"));

const filters = JSON.parse(localStorage.getItem("filters"));

const hotelFilters = JSON.parse(localStorage.getItem("hotelFilters"));

const priceRanges = JSON.parse(localStorage.getItem("priceRanges"));

const toursSearch = JSON.parse(localStorage.getItem("toursSearch"));

const toursCart = JSON.parse(localStorage.getItem("toursCart")) || [];
const transferCart = JSON.parse(localStorage.getItem("transferCart")) || [];
const transferCriteria = JSON.parse(localStorage.getItem("transferCriteria")) || null;
const flightsSearch = JSON.parse(localStorage.getItem("flightsSearch")) || null;
const outboundFlight = JSON.parse(localStorage.getItem("outboundFlight")) || null;
const inboundFlight = JSON.parse(localStorage.getItem("inboundFlight")) || null;
const HotelsSearch = JSON.parse(localStorage.getItem("HotelsSearch")) || null;
const umrah =  false;

const MarketPlaceContext = createContext();
const MarketPlaceDispatchContext = createContext();

const initialState = {
	onlineVisaPassenger: onlineVisaCashedPassengers ?? [],
	transportationPassengers: transportationPassengers ?? [],
	onlineVisaSearch: OnlineVisaSearch ?? "",
	groundServiceSearch: groundServiceSearch ?? "",
	groundServicePassengers: groundServicePassengers ?? [],
	transportationSearch: transportationSearch ?? "",
	redirectURL: redirectURL ?? "/",
	filters: filters ?? {},
	hotelFilters: hotelFilters ?? {},
	priceRanges: priceRanges ?? {},
	toursSearch: toursSearch ?? null,
	toursCart: toursCart ?? [],
	transferCart: transferCart ?? [],
	transferCriteria: transferCriteria ,
	flightsSearch: flightsSearch ,
	outboundFlight: outboundFlight ,
	inboundFlight: inboundFlight ,
	umrah: umrah ,
	HotelsSearch:HotelsSearch
};
// {
// 	filters: null,
// 	countries: null,
// 	categories: null,
// 	vehicleTypes: null,
// 	companies: null,
// 	hotels: null,
// 	transporters: null,
// 	searchResults: null,
// };

function marketReducer(state, action) {
	switch (action.type) {
		case "fetchCountries": {
			return { ...state, countries: action.payload };
		}
		case "fetchCategories": {
			return { ...state, categories: action.payload };
		}
		case "fetchVehicleTypes": {
			return { ...state, vehicleTypes: action.payload };
		}
		case "fetchCompanies": {
			return { ...state, companies: action.payload };
		}
		case "hasLookups": {
			return { ...state, hasLookups: action.payload };
		}
		case "filter": {
			localStorage.setItem("filters", JSON.stringify(action.payload));
			return { ...state, filters: action.payload };
		}
		case "searchBy": {
			localStorage.setItem("searchBy", action.payload);
			return { ...state, searchBy: action.payload };
		}
		case "roomPrice": {
			return { ...state, price: action.payload };
		}
		case "transferSearch": {
			localStorage.setItem("transferCriteria", JSON.stringify(action.payload.transferCriteria));

			return {
				...state,
				transferSearch: action.payload.searchData,
				transferCriteria: action.payload.transferCriteria,
			};
		}
		case "transferCart": {
			localStorage.setItem("transferCart", JSON.stringify(action.payload));
			return { ...state, transferCart: action.payload };
		}

		case "setHotelFilters": {
			/// new action
			localStorage.setItem(
				"hotelFilters",
				JSON.stringify(action.payload.hotelFilters)
			);
		
			return { ...state, ...action.payload };
		}

		case "newSearch": {
			/// new action
			localStorage.setItem("searchBy", action.payload.searchBy);
			localStorage.setItem("umrah", action.payload.umrah);
			localStorage.setItem("filters", JSON.stringify(action.payload.filters));
			localStorage.setItem(
				"hotelFilters",
				JSON.stringify(action.payload.hotelFilters)
			);
			localStorage.setItem(
				"priceRanges",
				JSON.stringify(action.payload.priceRanges)
			);
			return { ...state, ...action.payload };
		}

		case "searchResults": {
			return { ...state, searchResults: action.payload };
		}
		case "searchResultsList": {
			return {
				...state,
				searchResults: {
					meta: action.payload.meta,
					data: [...state.searchResults.data, ...action.payload.data],
				},
			};
		}
		case "onlineVisaSearch": {
			localStorage.setItem("onlineVisaSearch", JSON.stringify(action.payload));
			return {
				...state,
				onlineVisaSearch: {
					...action.payload,
				},
			};
		}


		case "groundServiceSearch": {
			localStorage.setItem(
				"groundServiceSearch",
				JSON.stringify(action.payload)
			);
			return {
				...state,
				groundServiceSearch: {
					...action.payload,
				},
			};
		}
		case "groundServicePassengers": {
			localStorage.setItem(
				"groundServicePassengers",
				JSON.stringify([...state.groundServicePassengers, ...action.payload])
			);

			return {
				...state,
				groundServicePassengers: [
					...state.groundServicePassengers,
					...action.payload,
				],
			};
		}

		case "groundServiceEditPassenger": {
			return {
				...state,
				groundServiceEditPassenger: action.payload,
			};
		}
		

		case "groundServiceRepalceEditPassenger": {
			let removeOldPassenger = [];

			if (action.payload.id) {
				removeOldPassenger = state.groundServicePassengers.map((passenger) => {
					if (passenger.id === action.payload.id) {
						return action.payload;
					} else {
						return passenger;
					}
				});
			} else {
				removeOldPassenger = state.groundServicePassengers.map((passenger) => {
					if (passenger.uniqueIdFrontend === action.payload.uniqueIdFrontend) {
						return action.payload;
					} else {
						return passenger;
					}
				});
			}
			localStorage.setItem(
				"groundServicePassengers",
				JSON.stringify([...removeOldPassenger])
			);

			return {
				...state,
				groundServicePassengers: [...removeOldPassenger],
			};
		}
		case "setumrah": {
			localStorage.setItem(
				"umrah",
				JSON.stringify(action.payload)
			);
			return {
				...state,
				umrah: action.payload,
			};
		}
		case "groundServicePassengersRemove": {
			localStorage.setItem(
				"groundServicePassengers",
				JSON.stringify(action.payload)
			);
			return {
				...state,
				groundServicePassengers: action.payload,
			};
		}
		case "groundServiceMRZ": {
			localStorage.setItem(
				"groundServicePassengers",
				JSON.stringify([...state.groundServicePassengers, action.payload])
			);

			return {
				...state,
				groundServicePassengers: [
					...state.groundServicePassengers,
					action.payload,
				],
			};
		}

		case "transportationSearch": {
			localStorage.setItem(
				"transportationSearch",
				JSON.stringify(action.payload)
			);

			return {
				...state,
				transportationSearch: {
					...action.payload,
				},
			};
		}

		case "transportationPassengers": {
			localStorage.setItem(
				"transportationPassengers",
				JSON.stringify([...state.transportationPassengers, ...action.payload])
			);

			return {
				...state,
				transportationPassengers: [
					...state.transportationPassengers,
					...action.payload,
				],
			};
		}

		case "transportationEditPassenger": {
			return {
				...state,
				transportationEditPassenger: action.payload,
			};
		}

		case "transportationRepalceEditPassenger": {
			let removeOldPassenger = [];

			if (action.payload.id) {
				removeOldPassenger = state.transportationPassengers.map((passenger) => {
					if (passenger.id === action.payload.id) {
						return action.payload;
					} else {
						return passenger;
					}
				});
			} else {
				removeOldPassenger = state.transportationPassengers.map((passenger) => {
					if (passenger.uniqueIdFrontend === action.payload.uniqueIdFrontend) {
						return action.payload;
					} else {
						return passenger;
					}
				});
			}
			localStorage.setItem(
				"transportationPassengers",
				JSON.stringify([...removeOldPassenger])
			);

			return {
				...state,
				transportationPassengers: [...removeOldPassenger],
			};
		}

		case "transportationPassengersRemove": {
			localStorage.setItem(
				"transportationPassengers",
				JSON.stringify(action.payload)
			);
			return {
				...state,
				transportationPassengers: action.payload,
			};
		}
		case "transportationMRZ": {
			localStorage.setItem(
				"transportationPassengers",
				JSON.stringify([...state.transportationPassengers, action.payload])
			);

			return {
				...state,
				transportationPassengers: [
					...state.transportationPassengers,
					action.payload,
				],
			};
		}

		case "onlineVisaEditPassenger": {
			return {
				...state,
				onlineVisaEditPassenger: action.payload,
			};
		}

		case "onlineVisaRepalceEditPassenger": {
			let removeOldPassenger = [];

			if (action.payload.id) {
				removeOldPassenger = state.onlineVisaPassenger.map(
					(passenger, index) => {
						if (passenger.id === action.payload.id) {
							return action.payload;
						} else {
							return passenger;
						}
					}
				);
			} else {
				removeOldPassenger = state.onlineVisaPassenger.map(
					(passenger, index) => {
						if (
							passenger.uniqueIdFrontend === action.payload.uniqueIdFrontend
						) {
							return action.payload;
						} else {
							return passenger;
						}
					}
				);
			}
			localStorage.setItem(
				"onlineVisaCashedPassengers",
				JSON.stringify([...removeOldPassenger])
			);

			return {
				...state,
				onlineVisaPassenger: [...removeOldPassenger],
			};
		}

		case "onlineVisaPassenger": {
			localStorage.setItem(
				"onlineVisaCashedPassengers",
				JSON.stringify([...state.onlineVisaPassenger, ...action.payload])
			);
			return {
				...state,
				onlineVisaPassenger: [...state.onlineVisaPassenger, ...action.payload],
			};
		}

		case "onlineVisaPassengerRemove": {
			localStorage.setItem(
				"onlineVisaCashedPassengers",
				JSON.stringify(action.payload)
			);
			return {
				...state,
				onlineVisaPassenger: action.payload,
			};
		}

		case "onlineVisaMRZ": {
			localStorage.setItem(
				"onlineVisaCashedPassengers",
				JSON.stringify([...state.onlineVisaPassenger, action.payload])
			);

			return {
				...state,
				onlineVisaPassenger: [...state.onlineVisaPassenger, action.payload],
			};
		}

		case "selected": {
			return { ...state, selected: action.payload };
		}

		case "redirectURL": {
			localStorage.setItem("redirectURL", JSON.stringify(action.payload));
			return { ...state, redirectURL: action.payload };
		}

		case "toursSearchResults": {
			return { ...state, toursSearchResults: action.payload };
		}
		case "saveToursSearch": {
			localStorage.setItem("toursSearch", JSON.stringify(action.payload));
			return { ...state, toursSearch: action.payload };
		}
		case "toursCart": {
			localStorage.setItem("toursCart", JSON.stringify(action.payload));
			return { ...state, toursCart: action.payload };
		}

		case "flightsSearchResults": {
			return { ...state, flightsSearchResults: action.payload };
		}
		case "inboundFlightsSearchResults": {
			return { ...state, inboundFlightsSearchResults: action.payload };
		}
		case "saveFlightsSearch": {
			localStorage.setItem("flightsSearch", JSON.stringify(action.payload));
			return { ...state, flightsSearch: action.payload };
		}

		case "saveHotelsSearch": {
			localStorage.setItem("HotelsSearch", JSON.stringify(action.payload));
			return { ...state, HotelsSearch: action.payload };
		}

		case "filterHotels": {
			return { ...state, filterHotels: action.payload };
		}
		case "outboundFlight": {
			localStorage.setItem("outboundFlight", JSON.stringify(action.payload));
			return { ...state, outboundFlight: action.payload };
		}
		case "inboundFlight": {
			localStorage.setItem("inboundFlight", JSON.stringify(action.payload));
			return { ...state, inboundFlight: action.payload };
		}
		case "setPackagesDetails": {
      localStorage.setItem("PackagesDetails", JSON.stringify(action.payload));
      return { ...state, PackagesDetails: action.payload };
    }
		case "setCount": {
      localStorage.setItem("Count", JSON.stringify(action.payload));
      return { ...state, Count: action.payload };
    }
    case "setPriceRoom": {
      localStorage.setItem("priceRoomsPackages", JSON.stringify(action.payload));
      return { ...state, priceRoomsPackages: action.payload?.obj };
    }
		case "setPackagesGuests": {
      localStorage.setItem("PackagesGuests", JSON.stringify(action.payload));
      return { ...state, PackagesGuests: action.payload };
    }

		
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useMarketplaceState() {
	const context = useContext(MarketPlaceContext);
	if (context === undefined) {
		throw new Error(
			"useMarketplaceState must be used within a MarketPlaceProvider"
		);
	}
	return context;
}

function useMarketplaceDispatch() {
	const context = useContext(MarketPlaceDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useMarketplaceDispatch must be used within a MarketPlaceProvider"
		);
	}
	return context;
}

function MarketPlaceProvider({ children }) {
	const [state, dispatch] = useReducer(marketReducer, initialState);
	return (
		<MarketPlaceContext.Provider value={state}>
			<MarketPlaceDispatchContext.Provider value={dispatch}>
				{children}
			</MarketPlaceDispatchContext.Provider>
		</MarketPlaceContext.Provider>
	);
}

export { MarketPlaceProvider, useMarketplaceState, useMarketplaceDispatch };
