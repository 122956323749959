import walletImg from "assets/images/inventory/walletSm.svg";
import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import Locale from "translations";

const RoomDetails = ({ details }) => {
	const { inventory } = Locale;
	const [RoomsList, setRoomsList] = useState();
	useEffect(() => {
		setRoomsList(details?.rooms);
	}, []);

	let arrPrice = [];
	const AllRooms =
		RoomsList?.length > 0 ? (
			RoomsList.map((item, index) => (
				<tr>
					<td>#{index + 1}</td>
					<td>{item.room_type?.name}</td>
					<td>{item.room_count}</td>
					<td>{item.room_view?.name}</td>
					<td>{item.number_of_adult}</td>
					<td>{item.children}</td>
					<td>{details.meal_plan?.name}</td>
					<td>
						{details?.status != "pending" ? (
							<>
								{item.selling} {details.currency}
							</>
						) : (
							"-"
						)}
					</td>
					<td>
						{details?.status != "pending" ? (
							<>
								{+item.selling * details?.nights}
								{details.currency}
							</>
						) : (
							"-"
						)}
					</td>
					<td className="text-success font-weight-bolder">
						{details?.status != "pending" ? (
							<>
								{+item.selling * details?.nights * +item.room_count}{" "}
								{details.currency}
							</>
						) : (
							"-"
						)}
						<span className="d-hidden">
							{arrPrice.push(
								+item.selling * details?.nights * +item.room_count
							)}
						</span>
					</td>
				</tr>
			))
		) : (
			<tr>
				<td colSpan="10">
					<div className="product-build__product-no-data fullHeight">
						<i className="icx icx-eye-slash" aria-hidden="true"></i>
						<h4>{"No Result"}</h4>
					</div>
				</td>
			</tr>
		);
	let price = 0;
	const totalPrice =
		arrPrice?.length > 0 &&
		arrPrice.map((item, index) => (price = +price + item));
	return (
		<div className="px-4 my-4 room-details">
			<div className="passenger ">
				<h3 className="header-hotel-info">{inventory.messages.Rooms}</h3>
				<div className="mt-3 invetory-table table-responsive  ">
					<Table striped className="table-update p-2 bg-white   m-0">
						<thead>
							<tr>
								<th>No.</th>
								<th>{inventory.messages.RoomType}</th>
								<th>{inventory.messages.RoomCount}</th>
								<th>{inventory.messages.RoomView}</th>
								<th>{inventory.messages.Adult}</th>

								<th>{inventory.messages.Child}</th>
								<th>{inventory.messages.MealPlan}</th>
								<th>{inventory.messages.RateRoomNight}</th>
								<th>{inventory.messages.TotalPerRoom}</th>
								<th>{inventory.messages.TotalPrice}</th>
							</tr>
						</thead>
						<tbody>{AllRooms}</tbody>
					</Table>
					<div className="d-flex justify-content-end px-5 py-2">
						<div className="mx-1 mt-2">
							<img src={walletImg} alt="" width={28} height={28} />
						</div>

						<div className="d-flex flex-column room-total-reservation mt-2">
							<span className="total">
								{inventory.messages.TotalReservation}
							</span>
							<span className="text-center price">
								{details?.status != "pending" ? (
									<>
										{price} {details.currency}
									</>
								) : (
									"-"
								)}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RoomDetails;
