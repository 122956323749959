import { useCallback, useRef,Component } from "react";
import { useDrag, useDrop } from "react-dnd";
import _ from "lodash";
import {
	ADD_NEW_BLOCK_TO_BODY_ACTION,
	ADD_NEW_ITEM_TO_CUSTOM_CONTAINER_ACTION,
	SORT_CONTAINER_ACTION,
	SORT_ITEMS_IN_CONTAINER_ACTION,
} from "../interfaces/blockTypes";
import {
	initialContainersData,
	initialCustomContainerItemsData,
} from "../interfaces/initialContainers";
import { useWebBuilderDispatch } from "context/webBuilder";
import { useLocation } from "react-router-dom";

function DragAndDropContainer({
	children,
	accept,
	id,
	index,
	type,
	moveItem,
	onDrop,
	action,
	moveItemInContainer,
	canDrag = true,
	className = "",
}) {
	const { ref, handlerId, opacity } = useDargDrop({
		accept,
		id,
		index,
		type,
		moveItem,
		onDrop,
		action,
		moveItemInContainer,
		canDrag,
	});
		return (
		<div
			ref={ref}
			style={{ opacity }}
			className={className}
			data-handler-id={handlerId}
		>
			{children}
		</div>
	);
}

export default DragAndDropContainer;

function useDargDrop({
	type,
	accept,
	index,
	moveItem,
	id,
	onDrop,
	action,
	moveItemInContainer,
	canDrag,
}) {
	const location = useLocation();
	const preview = Boolean(!location.pathname.includes('edit'));

	const dispatch = useWebBuilderDispatch();
	const addService = useCallback(({ service, hoverIndex }) => {
		dispatch({
			type: "addService",
			payload: {
				service,
			},
		});
	}, []);

	const ref = useRef(null);
	const [{ handlerId }, drop] = useDrop({
		canDrop: () => !preview,
		accept,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		drop(item, monitor) {
			const action = item?.action;
			const type = item.type;

			if (action === ADD_NEW_ITEM_TO_CUSTOM_CONTAINER_ACTION) {
				const item = _.cloneDeep(initialCustomContainerItemsData[type]);
				item.id = uuidv4();
				onDrop && onDrop(item);
			}
			if (action === ADD_NEW_BLOCK_TO_BODY_ACTION) {
				const isFaqs = item.isFaqs;
				const type = item.type;
				const service = _.cloneDeep(
					isFaqs
						? initialContainersData.customContainerFaqs
						: initialContainersData[type]
				);

				if (isFaqs) {
					service.items[0]["id"] = uuidv4();
					service.items[0]["faqs"][0]["id"] = uuidv4();
				}

				if (!service?.id) {
					service.id = uuidv4();
				}

				const ourServiceContainer = document.getElementById(
					"web-builder-our-service"
				);
				const rect = ourServiceContainer.getBoundingClientRect();
				window.scrollTo({
					top: ourServiceContainer.scrollHeight + rect.height,
					behavior: "smooth",
				});

				addService({ service });
			}

			// onDrop && onDrop(item, monitor);
		},
		hover(item, monitor) {
			if (!ref.current) {
				return;
			}
			const dragedItemAction = item?.action;
			const dragIndex = item.index;
			const hoverIndex = index;
			const sortContainers = dragedItemAction === SORT_CONTAINER_ACTION;
			const hoverY = 4;
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / hoverY;

			// Get horizontal middle
			const hoverMiddleX =
				(hoverBoundingRect.right - hoverBoundingRect.left) / 4;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;

			// Get pixels to the left
			const hoverClientX = clientOffset.x - hoverBoundingRect.left;

			const upwards = dragIndex > hoverIndex && hoverClientY > hoverMiddleY;
			const downwards = dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
			const leftwards = dragIndex > hoverIndex && hoverClientX > hoverMiddleX;
			const rightwards = dragIndex < hoverIndex && hoverClientX < hoverMiddleX;
			if (dragIndex > hoverIndex && sortContainers) {
				// go up
				console.log("up");
				window.scrollBy({ top: -100, behavior: "smooth" });
			}
			if (item.index < hoverIndex && sortContainers) {
				// go down
				console.log("down");
				window.scrollBy({ top: 100, behavior: "smooth" });
			}

			if (upwards && (leftwards || rightwards)) {
				return;
			}

			if (downwards && (leftwards || rightwards)) {
				return;
			}

			// Time to actually perform the action

			// debugger;
			if (sortContainers) {
				moveItem && moveItem(dragIndex, hoverIndex);
			}
			if (dragedItemAction === SORT_ITEMS_IN_CONTAINER_ACTION) {
				moveItemInContainer && moveItemInContainer(dragIndex, hoverIndex);
			}
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag] = useDrag({
		canDrag: () => !preview && canDrag,
		type: type,
		item: () => {
			return { id, index, action };
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});
	const opacity = isDragging ? 0 : 1;
	drag(drop(ref));

	return { ref, opacity, handlerId };
}

export function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		(
			c ^
			(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
		).toString(16)
	);
}
