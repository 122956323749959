import SelectField from "components/Form/SelectField/SelectField";
import Locale from "translations";
import moon from "assets/images/market-place/servicesIcon/mooon.svg";
import locationIcon from "assets/images/market-place/servicesIcon/location.svg";
import hotelIcon from "assets/images/market-place/servicesIcon/hottel.svg";
import calendarIcon from "assets/images/market-place/servicesIcon/callendar.svg";

import searchIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import Search from "modules/market-place/NewMarketplace/icons/search";
import { useEffect, useState } from "react";
import { fetchCities, fetchHotelsAll } from "services/lookups";
import moment from "moment";
import { useMarketplaceDispatch } from "context/marketplace";
import TextField from "components/Form/TextField/TextField";

export default function SpectialAllotmentForm() {
	const { productsBuilder, marketPlace } = Locale;
	const [Cities, setCities] = useState([]);
	const [Hotels, setHotels] = useState([]);
	const [filter, setFilter] = useState({
		city: {
			Disabled: undefined,
			id: 3,
			label: "Makkah",
			name: "Makkah",
			value: 3,
		},
		date: {
			Disabled: undefined,
			id: moment().format("MMMM"),
			label: moment().format("MMMM"),
			name: moment().format("MMMM"),
			value: moment().format("MMMM"),
		},
		nights: null,
	});
	const dispatch = useMarketplaceDispatch();

	// fetch city based on selected safa visa country
	useEffect(() => {
		const fetchCity = async () => {
			const cities = await fetchCities(966);
			setCities(cities);
		};
		fetchCity();


	
	}, []);

	useEffect(() => {
		const fetchHotels = async (e) => {
			const hotels = await fetchHotelsAll(e);
			
			setHotels(hotels.hotels.map(res=>{
				return {
					id:res.id,
					name:res.name,
					label:res.name,
					value:res.id,
				}
			}));
		};
		fetchHotels(filter?.city?.id);
	}, [filter?.city?.id])
	

	return (
			<div className="allotment-form">
			<div className="form-item">
				<label className=' form-label'>{productsBuilder.City}</label>
				<SelectField
					hasLabel={false}
					isImage={true}
					image={locationIcon}
					prependImage={true}
					placeholder={productsBuilder.City}
					options={Cities}
					value={filter?.city?.label}
					onChange={(e) => {
						setFilter({ ...filter, city: e,hotel:{} });
					}}
				/>
			</div>

			<div className="form-item">
				<label className=' form-label'>{productsBuilder.HotelName}</label>
				<SelectField
					hasLabel={false}
					isImage={true}
					image={hotelIcon}
					prependImage={true}
					placeholder={productsBuilder.HotelName}
					options={[{id:null,value:null,label:productsBuilder.HotelName,name:productsBuilder.HotelName},...Hotels]}
					value={filter?.hotel?.label}
					onChange={(e) => {
						setFilter({ ...filter, hotel: e });
					}}
				/>
			</div>

			<div className="form-item">
				<label className=' form-label'>{productsBuilder.Month}</label>
				<SelectField
					hasLabel={false}
					isImage={true}
					image={calendarIcon}
					prependImage={true}
					placeholder={productsBuilder.Month}
					options={moment
						.months()
						.map((res, index) => ({
							label: res,
							value: index + 1,
							name: res,
							id: index + 1,
						}))}
					value={filter?.date?.label}
					onChange={(e) => {
						setFilter({ ...filter, date: e });
					}}
				/>
			</div>

			<div className="form-item">
				<label className=' form-label'>{productsBuilder.Nonights}</label>
				<TextField
					hasLabel={false}
					isImage={true}
					image={moon}
					prependImage={true}
					placeholder={productsBuilder.Nonights}
					value={filter?.nights}
					onChange={(e) => {
						setFilter({ ...filter, nights: e.target.value });
					}}
				/>
			</div>
			<div className="m-m-b">
			<button
				onClick={() => {
					let startOfMonth = moment(
						moment(moment().format("YYYY") + "/" + filter.date.value + "/1")
					)
						.startOf("month")
						.format("YYYY-MM-DD");
					let endOfMonth = moment(
						moment(moment().format("YYYY") + "/" + filter.date.value + "/1")
					)
						.endOf("month")
						.format("YYYY-MM-DD");
					if (moment().format("M") > filter.date.value) {
						startOfMonth = moment(startOfMonth)
							.add(1, "y")
							.format("YYYY-MM-DD");
						endOfMonth = moment(endOfMonth).add(1, "y").format("YYYY-MM-DD");
					}
					dispatch({
						type: "filterHotels",
						payload: {
							...filter,
							date_from: startOfMonth,
							date_to: endOfMonth,
						},
					});
				}}
				className="btn btn-yellow"
			>
				<Search /> 
				{marketPlace.messages.search}
			</button>
			</div>
		</div>
	);
}
