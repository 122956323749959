import React from 'react'
import { Modal } from 'reactstrap';
import Locale from 'translations';


export default function ServiceStanderdModel({ isOpen, toggle, flightData }) {
  const { marketPlace, inventory } = Locale;


  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={'lg'}
      centered={true}
    >
      <div className="d-flex align-items-center justify-content-between p-2 px-4 pt-0 w-100">
        <h3>
          {inventory.messages.serviceStandard}
        </h3>
        <a href="##"
          onClick={toggle} className='btn-x'
        >
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div className='table-container table-responsive'>

      <table
        striped
        className="table-update p-2 bg-white m-0 table table-striped"
      >
        <thead>
          <tr>
            <th>{inventory.messages.serviceType}</th>
            <th>{inventory.messages.serviceTime}</th>
            <th>{inventory.messages.serviceFee}</th>
           
          </tr>
        </thead>
        <tbody>
         { [1,2,3].map((item,index)=>(
          <tr>
            <td>Issue</td>
            <td>Issue In 2 Hours After Payment</td>
            <td>50 CNY</td>
          </tr>
         ))}
          
       
        </tbody>
      </table>
      </div>

     
    </Modal>
  )
}
