import { useWebBuilderState } from "context/webBuilder";
import EditableParagraph from "modules/WebBuilder-V2/shared/EditableParagraph";
import SearchBarHorizontal from './SearchBars/SearchBarHorizontal';
import useWBHeroContent from './useWBHeroContent';
import SearchBarVertical from './SearchBars/SearchBarVertical';
import SearchBarTheme7 from './SearchBars/SearchBarTheme7';
import SearchBarTheme5 from './SearchBars/SearchBarTheme5';


export default function HeroContent({ updateHero }) {
	const { hero, selectedLanguage, style } = useWebBuilderState();
	const mainContent = hero?.mainContent;
	const searchbar_position = mainContent?.searchbarPosition;

	const { product_types, countriesLookup, searchState, handleSearchInputsChanges, getSearchResults, isPreview } = useWBHeroContent();


	const searchbarProps = {
		product_types: product_types,
		countries: countriesLookup,
		searchState: searchState,
		handleSearchInputs: handleSearchInputsChanges,
		handleSearch: getSearchResults,
		preview: isPreview
	}
	function renderSearchForm() {
		return {
			center: <SearchBarHorizontal {...searchbarProps} />,
			start: <SearchBarVertical {...searchbarProps} />,
			"theme-7": <SearchBarTheme7 {...searchbarProps} />,
			"theme-5": <SearchBarTheme5 {...searchbarProps} />,
			"theme-10": <SearchBarVertical {...searchbarProps} />
		};
	}

	return (
		<>
			<div className="hero-content">
				<EditableParagraph
					className="header h3"
					contentEditable={true}
					onInput={(value) => {
						updateHero({ name: `title`, value, type: null, language: selectedLanguage });
					}}
					text={mainContent?.title[selectedLanguage]}
				/>
				<EditableParagraph
					className="body"
					contentEditable={true}
					onInput={(value) => {
						updateHero({ name: `body`, value, type: null, language: selectedLanguage });
					}}
					text={mainContent?.body[selectedLanguage]}
				/>
			</div>

			{/* search bar */}
			{renderSearchForm()?.[searchbar_position || style.theme]}
		</>
	);
}
