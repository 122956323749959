import React, { useState } from "react";
import Locale from "translations";
import TripDates from "./TripDates";
import BreakdownPricing from "./BreakdownPricing";
import FullPackagePricing from "./FullPackagePricing";
import PricingHeader from "./PricingHeader";
import { useSBSState } from "context/global";
import { getSingleProductPricing } from "services/productbuilder";
import { formatPricingData } from "modules/TripPlanner/helpers/formatPricing";
import { successfulResponse } from "modules/TripPlanner/helpers/formatTripPlannerData";
import { saveTripAvailability, saveTripPricing } from "services/tripPlanner";
import { SaveIcon } from "../Icons";

export default function TripPlannerPricing({ trips, productType, deleteNonPublishTrip }) {
	// hooks
	const { allCurrencies } = useSBSState();
	const { productsBuilder, packagePlanner } = Locale;

	const [selectedPricingType, setSelectedPricingType] = useState("breakdown");
	// const [tripsDatesList, setTripsDatesList] = useState([tempData]);
	const [pricingData, setPricingData] = useState({
		selected_trip: null,
		pricing_type: "fullpackage",
		currency: null,
		adults_count: "",
		children_count: "",
		pricing_for: null,
		selected_pricing_for: null,
	});

	const [errors, setErrors] = useState({});

	async function fetchPricingInfo(productUUID, tripDate) {
		const res = await getSingleProductPricing(productUUID);
		if (res?.status === 200) {
			const data = res?.data?.data;
			const formatedPricing = formatPricingData(data, allCurrencies, tripDate);
			setPricingData({
				...formatedPricing,
				adults_count: data?.adults_pax,
				children_count: data?.children_pax,
			});
			setSelectedPricingType(
				data?.product_price?.product_price_type?.id === 0 ||
					data?.product_items?.find((item) => item?.item?.id !== 6) ||
					productType?.id === 5 ||
					productType?.id === 6
					? "breakdown"
					: "fullpackage"
			);
		}
	}

	function handleSelectTripDate(tripDate) {
		setPricingData({ ...pricingData });
		fetchPricingInfo(tripDate?.product_uuid, tripDate);
	}
	async function saveRoomsAvailability(isEdit = false) {
		let data = {
			adults_pax: pricingData?.adults_count,
			children_pax: pricingData?.children_count,
			room_types: pricingData?.room_types?.map((room) => ({
				type: room?.value,
				count: room?.count,
			})),
		};
		const res = await saveTripAvailability(
			pricingData?.selected_trip?.product_uuid,
			data
		);
		if (res?.status === 200) {
			successfulResponse("trip Availability updated");
			const data = res?.data?.data;
			const formatedPricing = formatPricingData(data, allCurrencies, pricingData?.selected_trip, pricingData?.pricing_for);
			setPricingData({
				...formatedPricing,
				selected_pricing_for: pricingData?.pricing_for?.id === "B2B_B2C" ? "b2b" : pricingData?.pricing_for?.id,
				adults_count: data?.adults_pax,
				children_count: data?.children_pax,
			});
		}
	}

	function toggleVatDiscount({ key }) {
		let pricingClone = [...pricingData?.pricing];
		let selectedPricingIndex = pricingClone?.findIndex(
			(price) => price?.for === pricingData?.selected_pricing_for
		);
		pricingClone[selectedPricingIndex][key] =
			!pricingClone[selectedPricingIndex][key];
		setPricingData({ ...pricingData, pricing: pricingClone });
	}

	async function savePricing(applyToAll) {
		let fullpackageData = {
			basic_currency_id: pricingData?.currency?.id,
			pricing:
				pricingData?.pricing_for?.id === "B2B_B2C"
					? pricingData?.pricing?.map((price) => ({
						for: price?.for,
						product_price_combinations: price?.rooms,
						discounts: price?.hasDiscount ? [price?.discount] : [],
						vat: price?.hasVat ? +price?.vat : null,
					}))
					: pricingData?.pricing
						?.filter(
							(price) =>
								price?.for ===
								(pricingData?.pricing_for?.id)
						)
						?.map((price) => ({
							for: price?.for,
							product_price_combinations: price?.rooms,
							discounts: price?.hasDiscount ? [price?.discount] : [],
							vat: price?.hasVat ? +price?.vat : null,
						})),
			product_price_type_id: selectedPricingType === "fullpackage" ? 1 : 0,
			apply_to_all: applyToAll ? 1 : 0,
		};
		let breakdownData = {
			basic_currency_id: pricingData?.currency?.id,
			pricing:
				pricingData?.pricing_for?.id === "B2B_B2C"
					? pricingData?.pricing?.map((price) => ({
						for: price?.for,
						product_items: price?.product_items?.map((service) => {
							if (service?.item?.id === 6) {
								return {
									...service,
									itemable: {
										...service?.itemable,
										price_child: service?.itemable?.rooms[0]?.price_child,
										rooms: service?.itemable?.rooms?.map((room) => ({
											...room,
											max_child: +room?.max_child,
										})),
									},
								};
							} else {
								return { ...service };
							}
						}),
						discounts: price?.hasDiscount ? [price?.discount] : [],
						vat: price?.hasVat ? +price?.vat : null,
					}))
					: pricingData?.pricing?.filter((price) => price?.for === (pricingData?.pricing_for?.id))
						?.map((price) => ({
							for: price?.for,
							product_items: price?.product_items?.map((service) => {
								if (service?.item?.id === 6) {
									return {
										...service,
										itemable: {
											...service?.itemable,
											price_child: service?.itemable?.rooms[0]?.price_child,
											rooms: service?.itemable?.rooms?.map((room) => ({
												...room,
												max_child: +room?.max_child,
											})),
										},
									};
								} else {
									return { ...service };
								}
							}),
							discounts: price?.hasDiscount ? [price?.discount] : [],
							vat: price?.hasVat ? +price?.vat : null,
						})),
			product_price_type_id: selectedPricingType === "fullpackage" ? 1 : 0,
			apply_to_all: applyToAll ? 1 : 0,
		};
		let data =
			selectedPricingType === "fullpackage" ? fullpackageData : breakdownData;

		const res = await saveTripPricing(
			pricingData?.selected_trip?.product_uuid,
			data
		);
		if (res?.status === 200) {
			successfulResponse(productsBuilder.PriceAddedSuccessfully);
		}
	}

	return (
		<div className="trip-planner-pricing">
			<div className="trip-planner-main">
				<TripDates
					tripsDatesList={trips}
					selectedTripDate={pricingData.selected_trip}
					handleSelectTripDate={handleSelectTripDate}
					deleteNonPublishTrip={deleteNonPublishTrip}
				/>
				{pricingData.selected_trip ? (
					<div style={{ width: "calc(100% - 320px)" }}>
						<PricingHeader
							pricingData={pricingData}
							setPricingData={setPricingData}
							selectedPricingType={selectedPricingType}
							setSelectedPricingType={setSelectedPricingType}
							allCurrencies={allCurrencies}
							saveRoomsAvailability={saveRoomsAvailability}
							productType={productType}
						/>


						{(pricingData?.pricing_for &&
							pricingData?.currency &&
							selectedPricingType &&
							pricingData?.adults_pax > 0) ? (
							<>
								<ul className="pricing-for-list mb-4">
									{pricingData?.pricing_for?.id === "B2B_B2C" || pricingData?.pricing_for?.id === "b2b" ? (
										<li className={`item ${pricingData?.selected_pricing_for === "b2b" ? "active" : ""}`}
											onClick={() => {
												setPricingData({
													...pricingData,
													selected_pricing_for: "b2b",
													pricing: [...pricingData?.pricing],
												});
											}}
										>
											{packagePlanner.pricingForB2b}
										</li>
									) : null}
									{pricingData?.pricing_for?.id === "B2B_B2C" || pricingData?.pricing_for?.id === "b2c" ? (
										<li className={`item ${pricingData?.selected_pricing_for === "b2c" ? "active" : ""}`}
											onClick={() =>
												setPricingData({
													...pricingData,
													selected_pricing_for: "b2c",
												})
											}
										>
											{packagePlanner.pricingForB2c}
										</li>
									) : null}
								</ul>

								{selectedPricingType === "fullpackage" ? (
									<FullPackagePricing
										pricingData={pricingData}
										setPricingData={setPricingData}
										toggleVatDiscount={toggleVatDiscount}
										selectedPricingType={selectedPricingType}
										setErrors={setErrors}
										errors={errors}
										pricingForText={pricingData?.selected_pricing_for}

									/>
								) : null}
								{selectedPricingType === "breakdown" ? (
									<BreakdownPricing
										pricingData={pricingData}
										setPricingData={setPricingData}
										toggleVatDiscount={toggleVatDiscount}
										selectedPricingType={selectedPricingType}
										pricingForText={pricingData?.selected_pricing_for}
									/>
								) : null}
								<div className="d-flex justify-content-end gap-10">
									<button
										className="btn save-pricing apply-to-all"
										onClick={() => savePricing(true)}
									>
										<SaveIcon color="#707170" />
										{packagePlanner.applyForAllTimes}
									</button>
									<button
										className="btn save-pricing"
										onClick={() => savePricing(false)}
									>
										<SaveIcon color="#D4A655" />
										{packagePlanner.savePrice}
									</button>
								</div>
							</>
						) : null}
					</div>
				) : null}
			</div>
		</div>
	);
}
