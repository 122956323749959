import { ReactComponent as UploadIcon } from "assets/images/webBuilder/upload-image-icon.svg";
import { useState } from "react";
import CustomModal from "./CustomModal";
import UploadFileComponent from "./UploadFile";
import { getYoutubeId } from '../helpers/videoUrl';

function AddMedia({ open, handleModalState, onAdd, label }) {
	const [image, setImage] = useState(null);
	const [video, setVideo] = useState(null);

	const handleUpload = (value) => {
		setImage(value);
		onAdd({ mediaType: "image", mediaUrl: value });
		handleModalState();
	};

	const onClosedModal = () => {
		setVideo(null);
		setImage(null);
	};

	const addServiec = () => {
		const id = getYoutubeId(video)

		onAdd({ mediaType: "video", mediaUrl: id });
		handleModalState();
	};
	return (
		<CustomModal
			isOpen={open}
			header={`Adding ${label}`}
			toggle={handleModalState}
			onClosed={onClosedModal}
		>
			<div className="adding-service-modal">
				<input
					value={video}
					placeholder="youtube url"
					onChange={(e) => setVideo(e.target.value)}
					className='w-100 p-1 rounded-sm'
				/>
				<UploadFileComponent
					icon={UploadIcon}
					// you must change this Id every time you use this component to avoid any conflict
					id={`${label}-image`}
					label="Change image"
					onUpload={handleUpload}
				/>

				{image && (
					<img alt={label} src={image} height={133} style={{ width: "100%" }} />
				)}
				<hr />

				<button
					className="primary-button"
					onClick={addServiec}
					disabled={!video}
				>
					Add
				</button>
			</div>
		</CustomModal>
	);
}

export default AddMedia;
