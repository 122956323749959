import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import UploadFileComponent from "modules/WebBuilder/shared/UploadFile";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
import { ReactComponent as LogoPlaceholder } from "assets/images/webBuilder/logo-placeholder.svg";
import { useHistory, useLocation, useParams } from "react-router-dom";
import HeaderButtonModal from "../models/HeaderButton";

function HeroHeader({ editHeader }) {
	const { hero, ourServices } = useWebBuilderState();
	const dispatch = useWebBuilderDispatch();
	const [openButtonModal, setOpenButtonModal] = useState(false);

	const location = useLocation();
	const preview = !location.pathname.includes('edit');

	const header = hero?.header;
	const serviecs = ourServices?.services;
	const layoutType = header?.layout?.layoutType;
	const hasButton = header?.layout?.hasButton;
	const hasLogo = header?.layout?.hasLogo;
	const headerBg = header?.layout?.background === "white";

	const scrollToSection = (id) => {
		if(id) {
			const element = document.getElementById(id);
			element?.scrollIntoView({ behavior: "smooth" });
		}
	};

	const updateHeroHeader = ({ name, value,type }) => {
		dispatch({
			type: "updateHeroHeader",
			payload: {
				name,
				value,
				type
			},
		});
	};
	const history = useHistory();
	const params  = useParams();

	// scroll to location from view package page
	useEffect(()=> {
		if(location.state) {
			setTimeout(() => {
				scrollToSection(location.state);
			}, 500);
		}
	},[])

	const nav = (
		<nav>
			<ul>
				<li onClick={()=>{
					if (preview) {
						if (params.name) {
							history.push(`/public-page/${params.name}`)
						} else{
							history.push(`/web-builder-v1/preview`)
						}
				}
					
				}} className={headerBg ? "text-secondary-color" : ""}>Home</li>
				{serviecs&&serviecs.length>0 && serviecs?.find(service=> service?.visible)	?<li className={headerBg ? "text-secondary-color" : ""}>
				<UncontrolledDropdown>
						<DropdownToggle
							caret
							className={`service-dropdown ${
								headerBg ? "has-bg" : ""
							}`}
							style={{ gap: "12px", padding: "1px" }}
						>
							Services
						</DropdownToggle>

						<DropdownMenu right>
							{serviecs &&
								serviecs.map((service) =>
									service.visible ? (
										<DropdownItem
											key={service.type}
											onClick={() => {
												let url = params.name ? `/public-page/${params.name}` : "/web-builder-v1/preview";
												if (params.id) {
													history.push({pathname: `${url}`, state: service.id})
												} else {
													scrollToSection(service.id)
												}
											}}
										>
											{service.type}
										</DropdownItem>
									) : null
								)}
						</DropdownMenu>
					</UncontrolledDropdown>
				</li> : null}
				<li
					onClick={() => {
						let url = params.name ? `/public-page/${params.name}` : "/web-builder-v1/preview";
						if (params.id) {
							history.push({pathname: `${url}`, state: "about-us-section"})
						} else {
							scrollToSection("about-us-section")
						}
					}}
					className={headerBg ? "text-secondary-color" : ""}
				>
					About us
				</li>
			</ul>
		</nav>
	);
	const logoImage = header?.logo ? <img src={header?.logo} alt="Logo" /> : null;
	const logo = preview ? (
		logoImage
	) : (
		<UploadFileComponent
			icon={LogoPlaceholder}
			id="hero-logo"
			label=""
			// label="Change image"
			onUpload={(value) => updateHeroHeader({ name: "logo", value })}
			image={logoImage}
		/>
	);

	const toggleButtonModal = (e) => {
		e?.stopPropagation();
		setOpenButtonModal((prev) => !prev);
	};

	const clickContactButton = () => {
		if (preview) {
			scrollToSection(header?.contactUs?.id);
			return;
		}
		toggleButtonModal()
	};
	

	const contactButton = hasButton && (
		<button className="contact-button" 
			onClick={()=>{ 
				// check if we inside view package page when click scroll back to preview page the scroll using useeffect
				let url = params.name ? `/public-page/${params.name}` : "/web-builder-v1/preview";
				if (params.id) {
					history.push({pathname: `${url}`, state: header?.contactUs?.id})
				} else {
					clickContactButton()
				}
			}}
		>
			{header?.contactUs?.name}
		</button>
	);

	const onSaveButton = ({
		name,
		id
	})=>{
		updateHeroHeader({name:"name",value:name,type:"contactUs"})
		updateHeroHeader({name:"id",value:id,type:"contactUs"})
	}
	return (
		<>
			<div
				className={`web-builder-hero-header ${headerBg ? "bg-white" : ""}`}
				onClick={editHeader}
			>
				{layoutType === 1 || layoutType === 2 ? (
					<header className={`${layoutType === 2 ? "flex-row-reverse " : ""}`}>
						{hasLogo && (
							<div className="logo">
								{header?.layout?.hasLogo ? logo : null}
							</div>
						)}
						{nav}
						{contactButton}
					</header>
				) : (
					<header className={`${layoutType == 4 ? "flex-row-reverse " : ""}`}>
						{contactButton}

						{hasLogo && (
							<div className="logo">
								{header?.layout?.hasLogo ? logo : null}
							</div>
						)}
						{nav}
					</header>
				)}
			</div>
			<HeaderButtonModal
				save={onSaveButton}
				isOpen={openButtonModal}
				toggle={toggleButtonModal}
			/>
		</>
	);
}

export default HeroHeader;
