export const RoomPlanHotels = `
<style>
			html,
			body,
			div,
			dl,
			dt,
			dd,
			ul,
			ol,
			li,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			pre,
			code,
			form,
			fieldset,
			legend,
			input,
			textarea,
			p,
			blockquote,
			th,
			td {
				margin: 0;
				padding: 0;
			}

			a {
				text-decoration: none !important;
				cursor: pointer;
			}

			a:hover {
				text-decoration: none !important;
			}

			fieldset,
			img {
				border: 0;
			}

			address,
			caption,
			cite,
			code,
			dfn,
			em,
			strong,
			th,
			var {
				font-style: normal;
				font-weight: normal;
			}

			ol,
			ul {
				list-style: none;
			}

			caption,
			th {
				text-align: var(--left);
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-size: 100%;
				font-weight: normal;
			}

			q:before,
			q:after {
				content: "";
			}

			abbr,
			acronym {
				border: 0;
				font-variant: normal;
			}

			sup {
				vertical-align: text-top;
			}

			sub {
				vertical-align: text-bottom;
			}

			input,
			textarea,
			select {
				font-family: inherit;
				font-size: inherit;
				font-weight: inherit;
			}

			legend {
				color: #000;
			}

			input:focus,
			select:focus,
			textarea:focus,
			button:focus {
				outline: none;
			}

			* {
				font-family: "Cairo", sans-serif;
				box-sizing: border-box;
			}

			.container,
			.container-fluid,
			.container-xl,
			.container-lg,
			.container-md,
			.container-sm {
				width: 100%;
				padding-right: 10px;
				padding-left: 10px;
				margin-right: auto;
				margin-left: auto;
			}

			/* .container {
				max-width: 1090px;
			} */

			.py-4 {
				padding-bottom: 1.5rem !important;
				padding-top: 1.5rem !important;
			}

			.mx-3 {
				margin-left: 1rem !important;
				margin-right: 1rem !important;
			}

			.mt-2 {
				margin-top: 0.5rem !important;
			}

			.mt-3 {
				margin-top: 1rem !important;
			}

			.bg-gray {
				background: #e8e8e8 !important;
			}

			.p-4 {
				padding: 1.5rem !important;
			}

			.align-items-center {
				align-items: center !important;
			}

			.justify-content-around {
				justify-content: space-around !important;
			}

			.font-weight-bold {
				font-weight: 700 !important;
			}

			.mb-1,
			.my-1 {
				margin-bottom: 0.25rem !important;
			}

			

			.col,
			.col-1,
			.col-10,
			.col-11,
			.col-12,
			.col-2,
			.col-3,
			.col-4,
			.col-5,
			.col-6,
			.col-7,
			.col-8,
			.col-9,
			.col-auto,
			.col-lg,
			.col-lg-1,
			.col-lg-10,
			.col-lg-11,
			.col-lg-12,
			.col-lg-2,
			.col-lg-3,
			.col-lg-4,
			.col-lg-5,
			.col-lg-6,
			.col-lg-7,
			.col-lg-8,
			.col-lg-9,
			.col-lg-auto,
			.col-md,
			.col-md-1,
			.col-md-10,
			.col-md-11,
			.col-md-12,
			.col-md-2,
			.col-md-3,
			.col-md-4,
			.col-md-5,
			.col-md-6,
			.col-md-7,
			.col-md-8,
			.col-md-9,
			.col-md-auto,
			.col-sm,
			.col-sm-1,
			.col-sm-10,
			.col-sm-11,
			.col-sm-12,
			.col-sm-2,
			.col-sm-3,
			.col-sm-4,
			.col-sm-5,
			.col-sm-6,
			.col-sm-7,
			.col-sm-8,
			.col-sm-9,
			.col-sm-auto,
			.col-xl,
			.col-xl-1,
			.col-xl-10,
			.col-xl-11,
			.col-xl-12,
			.col-xl-2,
			.col-xl-3,
			.col-xl-4,
			.col-xl-5,
			.col-xl-6,
			.col-xl-7,
			.col-xl-8,
			.col-xl-9,
			.col-xl-auto {
				padding-right: 5px;
				padding-left: 5px;
			}

			element.style {
			}

			.col,
			.col-1,
			.col-10,
			.col-11,
			.col-12,
			.col-2,
			.col-3,
			.col-4,
			.col-5,
			.col-6,
			.col-7,
			.col-8,
			.col-9,
			.col-auto,
			.col-lg,
			.col-lg-1,
			.col-lg-10,
			.col-lg-11,
			.col-lg-12,
			.col-lg-2,
			.col-lg-3,
			.col-lg-4,
			.col-lg-5,
			.col-lg-6,
			.col-lg-7,
			.col-lg-8,
			.col-lg-9,
			.col-lg-auto,
			.col-md,
			.col-md-1,
			.col-md-10,
			.col-md-11,
			.col-md-12,
			.col-md-2,
			.col-md-3,
			.col-md-4,
			.col-md-5,
			.col-md-6,
			.col-md-7,
			.col-md-8,
			.col-md-9,
			.col-md-auto,
			.col-sm,
			.col-sm-1,
			.col-sm-10,
			.col-sm-11,
			.col-sm-12,
			.col-sm-2,
			.col-sm-3,
			.col-sm-4,
			.col-sm-5,
			.col-sm-6,
			.col-sm-7,
			.col-sm-8,
			.col-sm-9,
			.col-sm-auto,
			.col-xl,
			.col-xl-1,
			.col-xl-10,
			.col-xl-11,
			.col-xl-12,
			.col-xl-2,
			.col-xl-3,
			.col-xl-4,
			.col-xl-5,
			.col-xl-6,
			.col-xl-7,
			.col-xl-8,
			.col-xl-9,
			.col-xl-auto {
				padding-right: 5px;
				padding-left: 5px;
			}

			.col-2 {
				flex: 0 0 16.6666666667%;
				max-width: 16.6666666667%;
			}

			.col-8 {
				flex: 0 0 66.6666666667%;
				max-width: 66.6666666667%;
			}

			.col-1 {
				flex: 0 0 8.3333333333%;
				max-width: 8.3333333333%;
			}

			.col-3 {
				flex: 0 0 25%;
				max-width: 25%;
			}

			.col-6 {
				flex: 0 0 50%;
				max-width: 50%;
			}

			.col-4 {
				flex: 0 0 33.3333333333%;
				max-width: 33.3333333333%;
			}

			.img-fluid {
				max-width: 100%;
				height: auto;
			}

			.w-50 {
				width: 50%;
			}

			element.style {
			}

			.col,
			.col-1,
			.col-10,
			.col-11,
			.col-12,
			.col-2,
			.col-3,
			.col-4,
			.col-5,
			.col-6,
			.col-7,
			.col-8,
			.col-9,
			.col-auto,
			.col-lg,
			.col-lg-1,
			.col-lg-10,
			.col-lg-11,
			.col-lg-12,
			.col-lg-2,
			.col-lg-3,
			.col-lg-4,
			.col-lg-5,
			.col-lg-6,
			.col-lg-7,
			.col-lg-8,
			.col-lg-9,
			.col-lg-auto,
			.col-md,
			.col-md-1,
			.col-md-10,
			.col-md-11,
			.col-md-12,
			.col-md-2,
			.col-md-3,
			.col-md-4,
			.col-md-5,
			.col-md-6,
			.col-md-7,
			.col-md-8,
			.col-md-9,
			.col-md-auto,
			.col-sm,
			.col-sm-1,
			.col-sm-10,
			.col-sm-11,
			.col-sm-12,
			.col-sm-2,
			.col-sm-3,
			.col-sm-4,
			.col-sm-5,
			.col-sm-6,
			.col-sm-7,
			.col-sm-8,
			.col-sm-9,
			.col-sm-auto,
			.col-xl,
			.col-xl-1,
			.col-xl-10,
			.col-xl-11,
			.col-xl-12,
			.col-xl-2,
			.col-xl-3,
			.col-xl-4,
			.col-xl-5,
			.col-xl-6,
			.col-xl-7,
			.col-xl-8,
			.col-xl-9,
			.col-xl-auto {
				padding-right: 5px;
				padding-left: 5px;
			}

			.txt-blue {
				color: #0c3b5c;
				font-weight: 700;
			}

			.container-wrapper {
				border: 1px solid #0c3b5c;
			}

			.justify-content-between {
				justify-content: space-between !important;
			}

			.row {
				display: flex;
				flex-wrap: wrap;
				/* margin-right: -15px;
				margin-left: -15px; */
			}
			.col-6 {
				display: inline-block;
				width: 48%;
			}
			.border-header {
				border-bottom: 1px solid #2d2d2d;
			}

			.f-12 {
				font-size: 12px;
			}

			table {
				border-collapse: collapse;
			}

			td {
				border: 1px solid #0c3b5c;
				padding: 0.5rem;
			}

			th {
				border: 1px solid #0c3b5c;
				padding: 0.5rem;
			}

			thead th {
				border: 1px solid #0c3b5c;
				padding: 0.5rem;
			}

			/* tr:first-child td {
				border-top: 0;
			}

			tr td:first-child {
				border-left: 0;
			}

			tr td:last-child {
				border-right: 0;
			}

			tr th:first-child {
				border-left: 0;
			}

			tr th:last-child {
				border-right: 0;
			} */

			.width-10 {
				width: 11% !important;
			}

			.container-wrapper {
				height: 95vh !important;
			}

			.p-5 {
				padding: 2rem;
			}

			.d-flex {
				display: flex !important;
			}

			.justify-content-center {
				justify-content: center !important;
			}

			.flex-column {
				flex-direction: column;
			}

			.border {
				border: 1px solid #dfdfdf;
			}

			.fw-600 {
				font-weight: 600;
			}

			.fw-700 {
				font-weight: 700;
			}

			.hr-lg {
				height: 4px;
				background-color: #f3f5f5;
				border: none !important;
			}
			.container {
				max-width: 1420px;
			}
			.room_plan{
				margin-block:25px ;
			}
			.room_plan .head{
				display: flex;
				justify-content: space-between;
				border: 1px solid #222;
				padding: 10px 15px;
			}
			.room_plan .all_content{
				display: flex;
				gap: calc(100% - 98%);
				flex-wrap: wrap;
			}
			.room_plan .head .data div{
				display: flex;
				margin-bottom: 18px;
			}
			.room_plan .head .data div p{
				font-weight: 600;
				font-size: 17px;
			}
			.room_plan .head .data div span{
				font-weight: 700;
				font-size: 17px;
				margin-inline: 10px;
			}
			.room_plan .content{
				width: 49%;
				display: flex;
				flex-direction: column;

			}
			.room_plan .content .head_content{
				display: flex;
				border: 1px solid #222;
				padding: 15px 10px;
				margin-block: 10px;
				// gap:10px


			}
			.room_plan .content .head_content .box{
				width:50%;
			}
			.hotel_name_label{
				min-width:85px !important
			}
			.room_plan .content .head_content .box div{
				display: flex;
				margin-bottom: 10px;

			}
			.room_plan .content .body_content{
				border: 1px solid #222;
				padding: 10px 15px;
				min-height:110px

			}
			.room_plan .content .head_content p,.room_plan .content .head_content span,.room_plan .content .body_content p{
				font-size: 17px;
				font-weight: 600;
			}

			p i{
				display: inline-block;
				margin-inline:8px;
			}

			.content  p i{
				width: 15px !important;
			}
			@media print {
			.room_plan .content{
				width:100%;
			}
			.room_plan .content .body_content{
				min-height: 110px;
			}
			}

		</style>
    <div class="room_plan" style="direction:[dir]">
			<div class="container">
				<div class="head">
					<div class="data">
						<div>
							<p> <i class="fas fa-university"></i> [companyName] :</p>
							<span>[company_name]</span>
						</div>
						<div>
							<p> <i class="fas fa-file-export"></i> [CheckinDate] :</p>
							<span>[return_date]</span>
						</div>
						<div>
							<p> <i class="fas fa-file-export fa-flip-horizontal"></i> [CheckoutDate] :</p>
							<span>[departure_date]</span>
						</div>
					</div>
					<div class="image">
						<img src=[avater] alt="" width="120" height="120"/>
					</div>
				</div>

				<div class="all_content">
				[allRoom]
				</div>
				<div class="total mt-3">
					<table style="width: 100%;">
						<thead>
							<tr>
								<td>
								[roomType]
								</td>
								<td>[oneKey]</td>
								<td>[twoKey]</td>
								<td>[threeKey]</td>
								<td>[fourKey]</td>
								<td>[fiveKey]</td>
								<td>[sixKey]</td>
								<td>[sevenKey]</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td width="250px" class="text-center">
									[number]
								</td>
								<td width="70px">[one]</td>
								<td width="70px">[two]</td>
								<td width="70px">[three]</td>
								<td width="70px">[four]</td>
								<td width="70px">[five]</td>
								<td width="70px">[six]</td>
								<td width="70px">[seven]</td>
							</tr>
						</tbody>
					</table>
				</div>		
		</div>
	</div>
`;
