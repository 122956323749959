
import React from "react";

function Company({ title, name, logo }) {

  return (
    <div className={"cardcomapny"}>
      <div className={"comhead"}>
        <p>{title}</p>
        <p style={{ fontWeight: "bold", color: "#0F3E5E" }}>
          {name}
        </p>
      </div>
      <div className={"combody"}>
        <img src={logo} alt={name} />
      </div>
    </div>
  );
}

export default Company;
