import Locale from "translations";
import OptionRow from "./optionRow";
import SelectField from "components/Form/SelectField/SelectField";
import calendar from "assets/images/market-place/servicesIcon/calendar-input.png";
import moon from "assets/images/market-place/servicesIcon/moon.png";
import { useState } from "react";
import AvailableRooms from "./availableRooms";
import { getSpecialAllotmentRates } from 'services/marketplace';
import moment from 'moment';

export default function Pricing({item}) {
	const { productsBuilder } = Locale;
	const [optionId, setOptionId] = useState(false);
	const [filterNights, setFilterNights] = useState({});
	const originInterval=[...item.intervals.map((interval) => ({...interval,NightsCount:moment(interval.to).diff(moment(interval.from), 'days')}))]
	const [intervalsList, setIntervalsList] = useState(originInterval);
	const [hotelRate, sethotelRate] = useState({})
	
	const	getHotelRate =async (e)=>{
		const res = await getSpecialAllotmentRates({...e,code :item.id});
		if (res.status === 200) {
			sethotelRate({...res.data,hotel:{...res.data.hotel,...e}})
			setOptionId(true);
		}
	}



	const AllOption = intervalsList.map((interval, index) => (
		<OptionRow key={index} item={item} interval={interval} setOptionId={setOptionId} getHotelRate={getHotelRate} />
	));

	const AllNights =getUniqueListBy(item.intervals.map((interval, index) => 
	{
		let NightsCount= moment(interval.to).diff(moment(interval.from), 'days') 
	return	{
			label:NightsCount,
			value:NightsCount,
			id:NightsCount,
			name:NightsCount,
		}
	}
	),"label");


	function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}
	return (
		<>
			{!optionId ? (
				<div className="pricing">
					<div className="pricing-filter ">
						{/* <div className="w-100">
							<label>{productsBuilder.selectMonth}</label>
							<SelectField
								hasLabel={false}
								isImage={true}
								image={calendar}
								prependImage={true}
								placeholder={productsBuilder.allMonths}
								options={[]}
							/>
						</div> */}
						{AllNights&&AllNights.length>2?<div className="w-50">
							<label>{productsBuilder.selectNights}</label>
							<SelectField
								hasLabel={false}
								isImage={true}
								image={moon}
								prependImage={true}
								placeholder={productsBuilder.allNights}
								value={filterNights?.nights?.label}
								options={[{label:"all",value:"all",name:"all",id:"all"},...AllNights]}
								onChange={(e)=>{
									if (e.label!=="all") {
										let intervalsListFilter=originInterval.filter(res=> res.NightsCount===e.value)
										setIntervalsList(intervalsListFilter)
										setFilterNights({filterNights,nights:e})
									}else{
										setIntervalsList(originInterval)
									}
									
								}}
							/>
						</div> : null}
					</div>
					<div className="select-option">
						<div className="head mob-head">
							<h6>{productsBuilder.PleaseOption}</h6>
						</div>
						{AllOption}
					</div>
				</div>
			) : (
				<AvailableRooms hotelRate={hotelRate} item={item} sethotelRate={sethotelRate} setOptionId={setOptionId} />
			)}
		</>
	);
}
