import React from "react";
import Locale from "translations";
import { ReactComponent as AscSortIcon } from "assets/images/market-place/sort-asc-icon.svg";
import { ReactComponent as DescSortIcon } from "assets/images/market-place/sort-desc-icon.svg";


export default function SortFilterPackage({
	handleFilters,
	filters,
	getPackages,
}) {
	const { marketPlace, backOffice } = Locale;

	const ascPrice = filters.sort_by === "price" && filters.sort_type === "asc";
	const ascName = filters.sort_by === "name" && filters.sort_type === "asc";
	const ascClassification =
		filters.sort_by === "product_classification_id" &&
		filters.sort_type === "asc";

		const handleSort = ({ sort_type, sort_by }) => {
		getPackages({ sort_type, sort_by });
		handleFilters({
			multipleValues: { sort_type, sort_by },
		});
	};
	return (
		<div className="sort-filter mx-2 my-1">
			<span>{marketPlace.messages.sortBy} :</span>
			<button
				className="bg-white mx-1 px-3 border-0"
				onClick={() =>
					handleSort({
						sort_type: ascPrice ? "desc" : "asc",
						sort_by: "price",
					})
				}
			>
				{ascPrice ? <AscSortIcon /> : <DescSortIcon />}
				{/* <i className={`fa fa-sort-amount-${ascPrice ? "up" : "down"}`} /> */}

				{" "}{marketPlace.messages.price}
			</button>
			<button
				className="bg-white mx-1 px-3 border-0"
				onClick={() =>
					handleSort({
						sort_type: ascClassification ? "desc" : "asc",
						sort_by: "product_classification_id",
					})
				}
			>

				{ascClassification ? <AscSortIcon /> : <DescSortIcon />}

				{" "}{backOffice.stars}
			</button>
			<button
				className="bg-white mx-1 px-3 border-0"
				onClick={() =>
					handleSort({
						sort_type: ascName ? "desc" : "asc",
						sort_by: "name",
					})
				}
			>
				{ascName ? <AscSortIcon /> : <DescSortIcon />}
				{" "} A to Z
			</button>
		</div>
	);
}
