import React from "react";
import PageTamplate from "./pageTemplate";
import HotelCardTamplate from "./hotelCard";
import SinglePromo from "../SinglePromo";

export default function MultiplePromo({
	selectedHotels,
	logoSrc,
	headerText,
	disclaimerText,
}) {
	const singleHotel = selectedHotels.length === 1;
	return singleHotel ? (
		<SinglePromo
			portrait
			hotel={selectedHotels[0]}
			logoSrc={logoSrc}
			headerText={headerText}
			disclaimerText={disclaimerText}
		/>
	) : (
		<PageTamplate
			logoSrc={logoSrc}
			headerText={headerText}
			disclaimerText={disclaimerText}
			hotelNumber ={selectedHotels.length}
		>
			{selectedHotels.map((hotel) => (
				<HotelCardTamplate key={hotel.id} hotel={hotel} />
			))}
		</PageTamplate>
	);
}
