// import { Typography } from '@mui/material'
import React from 'react'
// import styles from "../../../styles/packages/modals/packagedetails.module.css"


function CustomBox({ children, title }) {
  return (
    <div className="custombox my-2">
      <div className="headbox">
        <p className='font-weight-bold'>{title}</p>
      </div>
      <div className="bodybox">
        {children}
      </div>
    </div>
  )
}

export default CustomBox