import dateExpireImage from "assets/images/datePicker.svg";
import walletImage from "assets/images/newWallat.svg";
import NumberField from "components/Form/NumberField/NumberField";
import SelectField from "components/Form/SelectField/SelectField";
import { useTDSVisaDispatch } from "context/TDSVisa";
import validate, { isFormValid } from "helpers/validate";
import moment from 'moment';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TabPane } from "reactstrap";
import { createOnlineVisaRequest, listResidence, onlineVisaLookups } from "services/VisaOnline";
import Locale from "translations";
import CustomModal from "./components/Modal";
import PaymentSucess from './components/PaymentSucess';
import ShowForPermission from 'helpers/showForPermission';
import useShowPermission from 'hooks/useShowPermission';
import { useSBSState } from 'context/global';
import NewPaymentAction from 'components/NewPaymentAction';

export default function VisaSearch({ isMarketSearch }) {
	const { allCountries } = useSBSState();
	const { marketPlace, onlineVisa, inventory } = Locale;
	const [errors, setErrors] = useState({});
	const [destinationLookups, setDestinationLookups] = useState([]);
	const [residenceLookups, setResidenceLookups] = useState([]);
	const [embassiesLookups, setEmbassiesLookups] = useState([]);
	const [visaTypesLookups, setvisaTypesLookups] = useState([]);
	const [nationalitiesLookups, setNationalitiesLookups] = useState([]);
	const [transportationTypeLookups, setTransportationTypeLookups] = useState([]);
	const [packagesLookup, setPackagesLookup] = useState([]);
	const [isSearchDone, setIsSearchDone] = useState(false);
	const [ModalPayment, setModalPayment] = useState(false);
	const [ModalPaymentSucess, setModalPaymentSucess] = useState(false);

	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK ? JSON.parse(process.env.REACT_APP_OTP_CHECK) : false

	const [actionType, setActionType] = useState('');
	const [formData, setFormData] = useState({});

	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const tokenSafaVisa = localStorage.getItem("VisaToken");
	const dispatch = useTDSVisaDispatch();
	const VISA_TYPE_UMRAH = 'Umra';
	const SAUDI_DESTINATION_ID = 966;

	const history = useHistory();

	const inventoryPermission = useShowPermission({
		permission: [
			"View-Inventory-Visa",
			"Manage-Inventory-Visa",
		],
	});
	const [visaData, setVisaData] = useState({
		destination: null,
		VisaType: null,
		pax: "",
		transportation_type: null,
		nationality: null,
		embassy: null,
		expiration_days: null,
		price_per_one: 0,
		package_plan: null,
		visaNotRefundableCheck: false,
	});

	const toggleModelPayment = () => {
		setActionType('')
		setFormData({})
		setModalPayment(!ModalPayment);
	};

	const toggleModelPaymentSucess = () => {
		setModalPaymentSucess(!ModalPaymentSucess);
	};

	useEffect(() => {
		const fetchDestinations = async () => {
			const response = await onlineVisaLookups({ token: tokenSafaVisa });
			if (response.status === 200) {
				const formatDestination = response?.data?.data.map((item) => ({
					id: item.country_code,
					name: item.country,
					visaTypes: item.visa_types.map((visaType) => {
						return {
							id: visaType.id,
							name: visaType.name,
							...visaType,
							residences: visaType.residences.map((residence) => {
								return {
									...residence,
									id: residence.id,
									name: residence.transportation_type,
								};
							}),
						};
					}),
				}));
				setDestinationLookups(formatDestination);
			}
		};
		fetchDestinations();
	}, []);

	useEffect(() => {
		const fetchResidence = async () => {
			const response = await listResidence();
			if (response.status === 200) {
				const formatResidence = response?.data?.data.map((item) => ({
					id: item.id,
					name: item.name,
					embassies: item.embassies,
				}));

				setResidenceLookups(formatResidence);
			}
		};
		fetchResidence();
	}, []);

	const checkFormErrors = () => {
		let SubmitError = {};
		Object.keys(visaData).forEach((key) => {
			if (key !== "currency" && key !== "visaNotRefundableCheck") {
				SubmitError = {
					...SubmitError,
					...validate(
						{ name: key, value: visaData[key], },
						{
							required:
								(key === 'transportation_type' && visaData.destination?.id !== SAUDI_DESTINATION_ID) ||
									(key === 'embassy' && visaData.VisaType?.label !== VISA_TYPE_UMRAH) ? false : true,
							minNumber: key === "pax" && visaData?.package_plan?.range_from && visaData?.package_plan?.range_from,
							maxNumber: key === "pax" && visaData?.package_plan?.range_to && visaData?.package_plan?.range_to,
						}
					),
				}
			}
		});
		setErrors(() => SubmitError);
	};



	const sumbit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	async function payOnlineVisa(paymentType, currency, otp, price) {

		const data = {
			destination_id: visaData?.destination?.id,
			residence_id: visaData?.nationality?.id,
			nationality_id: visaData?.nationality?.id,
			embassy_id: visaData?.embassy?.id,
			transportation_type: visaData?.transportation_type?.label,
			visa_type_id: visaData?.VisaType?.id,
			pax: visaData?.pax,
			currency: currency,
			price_per_one: price / +visaData.pax,
			expiration_days: visaData?.expiration_days,
			payment_method: paymentType,
			otp: otp
		}
		const response = await createOnlineVisaRequest(data);
		if (response.status === 200) {
			history.push({ pathname: inventoryPermission ? "/inventory/visa-requests" : "/statistics" });
			dispatch({
				type: "onlineVisaSearch",
				payload: [],
			});
			toggleModelPayment();
		} else if (response?.status === 202) {
			setActionType(response?.data?.actionType)
			setFormData(response?.data?.data?.formData)

		}
	}


	useEffect(() => {
		if (isFormValid(errors)) {
			// send request to api to get available visa
			const handleSearch = async () => {
				dispatch({ type: "onlineVisaSearch", payload: visaData })

				if (!isSearchDone) {
					setVisaData({
						...visaData,
						expireDate: moment(new Date()).add(visaData?.expiration_days, "d"),
						totalPrice: (visaData?.pax * visaData?.price_per_one?.provider_price),
						currency: visaData?.currency
					})
					setIsSearchDone(true);
				} else {
					setModalPayment(true);
				}
			}
			handleSearch();
		}
	}, [isErrorLoaded]);

	return (
		<>
			<TabPane tabId="1" className="w-100">
				<div className="col-md-10 HotelSearch m-auto pb-3">
					<div className={`row px-2 ${isMarketSearch ? " justify-content-start" : " justify-content-start"}`}>
						{/* destination */}
						<div className="col-md-12">
							<SelectField
								hasLabel={true}
								label={marketPlace.messages.destination}
								errors={errors?.destination}
								color={errors?.destination?.required ? "danger" : ""}
								options={destinationLookups}
								value={visaData?.destination?.label}
								onChange={(e) => {
									setVisaData({
										...visaData,
										destination: e,
										VisaType: null,
										transportation_type: null,
										nationality: null,
										embassy: null,
										package_plan: null,
										currency: null,
										visaNotRefundableCheck: false,
									});
									setvisaTypesLookups(e.visaTypes);
									setEmbassiesLookups([]);
									setNationalitiesLookups([]);
									setPackagesLookup([]);
									setIsSearchDone(false);
									setErrors({
										...errors,
										...validate(
											{ name: "destination", value: e.id },
											{ required: true, }
										),
									});
								}}
							/>
						</div>
						{/* VisaType */}
						<div className={`${visaData.destination?.id === SAUDI_DESTINATION_ID ? "col-md-6" : "col-md-12"} my-1`}>
							<div className="mybooking-input-wrapper marketplace-date">
								<SelectField
									hasLabel={true}
									label={onlineVisa.VisaType}
									errors={errors?.VisaType}
									multi={false}
									color={errors?.VisaType?.required ? "danger" : ""}
									options={visaTypesLookups}
									value={visaData?.VisaType?.label}
									onChange={(e) => {
										setVisaData({
											...visaData,
											VisaType: e,
											transportation_type: null,
											nationality: null,
											embassy: null,
											package_plan: visaData.destination?.id !== SAUDI_DESTINATION_ID ?
												e?.residences[0]?.package_plans?.length > 0 ?
													{
														...e?.residences[0]?.package_plans[0],
														id: e?.residences[0]?.package_plans[0]?.plan_name,
														value: e?.residences[0]?.package_plans[0]?.plan_name,
														name: `${e?.residences[0]?.package_plans[0]?.plan_name} ${e?.residences[0]?.package_plans[0]?.range_from ? `${e?.residences[0]?.package_plans[0]?.range_from}-` : ""}${e?.residences[0]?.package_plans[0]?.range_to || ""}`,
														label: `${e?.residences[0]?.package_plans[0]?.plan_name} ${e?.residences[0]?.package_plans[0]?.range_from ? `${e?.residences[0]?.package_plans[0]?.range_from}-` : ""}${e?.residences[0]?.package_plans[0]?.range_to || ""}`,
													}
													:
													{
														name: marketPlace.messages.noPackages,
														label: marketPlace.messages.noPackages,
														package_provider_price: e?.residences[0]?.provider_price,
														package_price: e?.residences[0]?.price,
														id: marketPlace.messages.noPackages
													}
												:
												null
											,
											expiration_days: visaData.destination?.id !== SAUDI_DESTINATION_ID ? e?.residences[0]?.expiration_days : null,

											currency: visaData.destination?.id !== SAUDI_DESTINATION_ID ?
												{ currency: e?.residences[0]?.currency, provider_currency: e?.residences[0]?.provider_currency }
												:
												null,

											price_per_one: visaData.destination?.id !== SAUDI_DESTINATION_ID ?
												e?.residences[0]?.package_plans?.length > 0 ?
													{ provider_price: +e?.residences[0]?.package_plans[0]?.package_provider_price } :
													{ provider_price: +e?.residences[0]?.provider_price } :
												null,
												visaNotRefundableCheck: false,
										});
										if (visaData.destination?.id !== SAUDI_DESTINATION_ID) {
											if (!e?.residences[0]?.nationalities?.length) {
												setNationalitiesLookups(allCountries);
											} else {
												setNationalitiesLookups(e?.residences[0]?.nationalities?.map(nationality => {
													return { ...nationality, id: nationality?.country_id, name: nationality?.name }
												}))
											}
											setPackagesLookup([
												{
													plan_name: marketPlace.messages.noPackages,
													package_provider_price: e?.residences[0]?.provider_price,
													package_price: e?.residences[0]?.price,
												},
												...e?.residences[0]?.package_plans]?.map(plan => {
													return {
														...plan,
														id: plan?.plan_name,
														value: plan?.plan_name,
														name: `${plan?.plan_name} ${plan?.range_from ? `${plan?.range_from}-` : ""}${plan?.range_to || ""}`,
													}
												}));
										} else {
											setPackagesLookup([]);
											setNationalitiesLookups([]);
											setEmbassiesLookups([]);
										}
										setTransportationTypeLookups(e.residences);
										setIsSearchDone(false);
										setErrors({
											...errors,
											...validate(
												{ name: "VisaType", value: e.id },
												{ required: true, }
											),
										});
									}}
								/>
							</div>
						</div>

						{/* TransportationType */}
						{visaData.destination?.id === SAUDI_DESTINATION_ID ?
							<div className="col-md-6 my-1">
								<div className="mybooking-input-wrapper marketplace-date">
									<SelectField
										hasLabel={true}
										label={onlineVisa.TransportationType}
										errors={errors?.transportation_type}
										multi={false}
										color={errors?.transportation_type?.required ? "danger" : ""}
										value={visaData?.transportation_type?.label}
										options={transportationTypeLookups}
										onChange={(e) => {
											setVisaData({
												...visaData,
												transportation_type: e,
												nationality: null,
												embassy: null,
												package_plan: e?.package_plans?.length > 0 ?
													{
														...e?.package_plans[0],
														id: e?.package_plans[0]?.plan_name,
														value: e?.package_plans[0]?.plan_name,
														name: `${e?.package_plans[0]?.plan_name} ${e?.package_plans[0]?.range_from ? `${e?.package_plans[0]?.range_from}-` : ""}${e?.package_plans[0]?.range_to || ""}`,
														label: `${e?.package_plans[0]?.plan_name} ${e?.package_plans[0]?.range_from ? `${e?.package_plans[0]?.range_from}-` : ""}${e?.package_plans[0]?.range_to || ""}`,
													}
													:
													{
														name: marketPlace.messages.noPackages,
														label: marketPlace.messages.noPackages,
														package_provider_price: e?.provider_price,
														package_price: e?.price,
														id: marketPlace.messages.noPackages
													}
												,
												expiration_days: e?.expiration_days,

												price_per_one: e?.package_plans?.length > 0 ?
													{ provider_price: +e?.package_plans[0]?.package_provider_price } :
													{ provider_price: +e?.provider_price },

												currency: { currency: e?.currency, provider_currency: e?.provider_currency },
												visaNotRefundableCheck: false,
											});
											setIsSearchDone(false);
											// check if country saudi
											setPackagesLookup(
												[{
													plan_name: marketPlace.messages.noPackages,
													package_provider_price: e?.provider_price,
													package_price: e?.price,
												},
												...e?.package_plans]?.map(plan => {
													return {
														...plan,
														id: plan?.plan_name,
														value: plan?.plan_name,
														name: `${plan?.plan_name} ${plan?.range_from ? `${plan?.range_from}-` : ""}${plan?.range_to || ""}`,
														label: `${plan?.plan_name} ${plan?.range_from ? `${plan?.range_from}-` : ""}${plan?.range_to || ""}`,
													}
												}));
											if (!e?.nationalities?.length) {
												setNationalitiesLookups(allCountries);
											} else {
												setNationalitiesLookups(e?.nationalities?.map(nationality => {
													return { ...nationality, id: nationality?.country_id, name: nationality?.name }
												}))
											}

											setErrors({
												...errors,
												...validate(
													{ name: "transportation_type", value: e.id },
													{ required: true, }
												),
											});
										}}
									/>
								</div>
							</div>
							:
							null
						}

						{/* nationality */}
						<div className='col-md-6 my-1'>
							<div className="mybooking-input-wrapper marketplace-date">
								<SelectField
									label={marketPlace.messages.nationality}
									value={visaData?.nationality?.label}
									placeholder={`${marketPlace.select} ${marketPlace.messages.nationality} `}
									name="nationality"
									options={nationalitiesLookups}
									onChange={(e) => {
										setVisaData({
											...visaData,
											nationality: e,
											embassy: null,
											visaNotRefundableCheck: false,
										});
										setEmbassiesLookups(residenceLookups?.filter(residence => residence.id === e.id)[0]?.embassies);
										setErrors({
											...errors,
											...validate(
												{ name: "nationality", value: e.id },
												{ required: true }
											),
										})
									}}
									color={errors?.nationality?.required ? "danger" : ""}
									errors={errors?.nationality}
								/>
							</div>
						</div>


						{/* package plan */}
						<div className='col-md-6 my-1'>
							<div className="mybooking-input-wrapper marketplace-date">
								<SelectField
									label={marketPlace.messages.packagePlan}
									value={visaData?.package_plan?.label}
									placeholder={`${marketPlace.select} ${marketPlace.messages.packagePlan} `}
									name="package_plan"
									id="package_plan"
									options={packagesLookup}
									onChange={(e) => {
										setVisaData({
											...visaData,
											package_plan: e,
											price_per_one: { price: +e?.package_price, provider_price: +e?.package_provider_price },
											pax: e?.plan_name === marketPlace.messages.noPackages ? visaData?.pax : "",
											visaNotRefundableCheck: false,
										});
										setIsSearchDone(false);
										setErrors({
											...errors,
											...validate(
												{ name: "package_plan", value: e.id },
												{ required: true }
											),
											...validate(
												{ name: "pax", value: visaData?.pax },
												{ required: true }
											),
										})
									}}
									color={errors?.package_plan?.required ? "danger" : ""}
									errors={errors?.package_plan}
								/>
							</div>
						</div>

						{/* embassy */}
						{visaData.destination?.id === SAUDI_DESTINATION_ID && visaData?.VisaType?.label === VISA_TYPE_UMRAH ?
							<div className="col-md-6 my-1">
								<div className="mybooking-input-wrapper marketplace-date">
									<SelectField
										hasLabel={true}
										label={onlineVisa.Embassy}
										errors={errors?.embassy}
										multi={false}
										options={embassiesLookups}
										color={errors?.embassy?.required ? "danger" : ""}
										value={visaData?.embassy?.label}
										onChange={(e) => {
											setVisaData({ ...visaData, embassy: e });
											setErrors({
												...errors,
												...validate(
													{ name: "embassy", value: e.id },
													{ required: true, }
												),
											});
										}}
									/>
								</div>
							</div>
							:
							null
						}
						{/* pax */}
						<div className="col-md-12">
							<div className="mybooking-input-wrapper marketplace-date">
								<NumberField
									hasLabel={true}
									removeArrow={true}
									placeholder={onlineVisa.paxPlaceholder}
									label={onlineVisa.Pax}
									name="pax"
									min={visaData?.package_plan?.range_from && +visaData?.package_plan?.range_from}
									max={visaData?.package_plan?.range_to && +visaData?.package_plan?.range_to}
									errors={errors?.pax}
									color={errors?.pax?.required ? "danger" : ""}
									value={visaData.pax}
									type={"number"}
									onChange={(e) => {
										setVisaData({
											...visaData,
											totalPrice: +e.target.value * visaData?.price_per_one?.provider_price,
											pax: e.target.value > 0 ? +e.target.value : ""
										});
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
												minNumber: visaData?.package_plan?.range_from && +visaData?.package_plan?.range_from,
												maxNumber: visaData?.package_plan?.range_to && +visaData?.package_plan?.range_to
											}),
										});
									}}
								/>
							</div>
						</div>
						{/* search result */}
						{isSearchDone ?
							<>
								<div className="col-md-12 d-flex justify-content-between align-items-center mt-3 mb-2 visa_search_result">
									<div className="d-flex align-items-center">
										<img src={dateExpireImage} alt="" width="28px" className="mr-3" />
										<p className="d-flex flex-column font-weight-normal txt-blue">
											{marketPlace.messages.requestExpireDate}
											<span>{moment(visaData?.expireDate).format("DD-MM-YYYY")}</span>

										</p>
									</div>
									<div className="d-flex align-items-center">
										<img src={walletImage} alt="" width="28px" className="mx-3" />
										<p className="d-flex flex-column font-weight-normal txt-blue">
											{marketPlace.messages.totalPrice}
											<span>
												{visaData?.currency?.provider_currency} {" "}
												{visaData?.totalPrice.toFixed(2)}
											</span>
										</p>
									</div>
								</div>

								<div className='col-12 d-flex align-items-center my-2'>
									<input
										type="checkbox"
										name="visa-not-refundable"
										id="visa-not-refundable"
										onChange={() => setVisaData({ ...visaData, visaNotRefundableCheck: !visaData?.visaNotRefundableCheck })}
										checked={visaData?.visaNotRefundableCheck}
									/>
									<label className='mx-2 my-0 pointer user-select-none' htmlFor='visa-not-refundable'>{marketPlace.messages.visaNotRefundable}</label>
								</div>
							</>
							:
							null
						}
						<div className="col-md-12">
							{/* : onlineVisa.search */}
							{isSearchDone ?
								<ShowForPermission permission={["Manage-Marketplace-Visa"]}>
									<button className="btn w-100 bg-nxt mb-3 mt-3 px-0" type="button"
										onClick={sumbit}
										disabled={!visaData?.visaNotRefundableCheck}
									>
										<p className="d-flex align-items-center justify-content-center">
											{inventory.messages.pay}
										</p>
									</button>
								</ShowForPermission> : ""}

							{!isSearchDone ?
								<button className="btn w-100 bg-nxt mb-3 mt-3 px-0" type="button" onClick={sumbit}>
									<p className="d-flex align-items-center justify-content-center">
										{onlineVisa.search}
									</p>
								</button> : ""}
						</div>
					</div>
				</div>
			</TabPane>
			{/* <CustomModal
				modalIsOpen={ModalPayment}
				header={inventory.messages.ChoosePaymentMethod}
				toggle={toggleModelPayment}
				size={"lg"}
			// centered={true}
			>
				<Payment formData={formData} actionType={actionType} payOnlineVisa={payOnlineVisa} isMarketSearch={isMarketSearch} />
			</CustomModal> */}


			{ModalPayment && (
				<NewPaymentAction
					isOpen={ModalPayment}
					toggleModal={toggleModelPayment}
					OTP={OTP_CHECK}
					canPayLater={false}
					formData={formData}
					actionType={actionType}
					Price={visaData?.price_per_one?.provider_price * +visaData?.pax}
					currency={visaData?.currency?.provider_currency}
					// secondCurrency={visaData?.currency?.currency}
					onPayment={payOnlineVisa}
					setActionType={setActionType}
					setFormData={setFormData}
				/>
			)}




			<CustomModal
				modalIsOpen={ModalPaymentSucess}
				header={onlineVisa.ChoosePayment}
				toggle={toggleModelPaymentSucess}
				size={"lg"}
				centered={true}

			>
				<PaymentSucess />
			</CustomModal>
		</>
	);
}
