import PasswordField from 'components/Form/PasswordField/PasswordField';
import validate, { isFormValid } from 'helpers/validate';
import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { resetOfficerPassword } from 'services/auth';
import { convertObjToQueryParams } from 'services/general';
import Locale from 'translations';

export default function ResetOfficerPassword() {
  const { login, userDetails } = Locale;
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(search);

  const [resetData, setResetData] = useState({
    password: "",
    passwordConfirmation: "",
  });
  const [errors, setErrors] = useState({});
  const [spin, setSpin] = useState(false);

  const isPasswordMatch = resetData.passwordConfirmation === resetData.password;

  let requestParams = {};
  searchParams.forEach(function (value, key) {
    requestParams = {
      ...requestParams,
      [key]: value,
      password: resetData.password,
      password_confirmation: resetData?.passwordConfirmation
    };
  });


  function checkFormErrors() {
    let submitError = {};
    Object.keys(resetData).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: key === "passwordConfirmation" ? isPasswordMatch : resetData[key] },
          {
            required: true,
            password: key === "password",
            min: key === "password" ? 8 : key === "name" || key === "companyName" ? 3 : "",
            confirm: key === "passwordConfirmation"
          }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }



  async function submit(e) {
    e.preventDefault();
    let formErrors = checkFormErrors();
    if (isFormValid(formErrors)) {
      setSpin(true);
      let queryParams = convertObjToQueryParams(requestParams)
      console.log(queryParams);
      const res = await resetOfficerPassword(queryParams)
      if (res?.status === 200) {
        history.push("/auth/officer-password-changed/successfully")
      } else {
        setSpin(false);
      }
    }
  }

  useEffect(()=> {
    if (searchParams.size === 0) {
      history.push("/auth/login")
    }
  }, [searchParams])

  return (
    <div className="container d-flex flex-column align-items-center justify-content-center pre-auth-layout" style={{ height: "100vh" }}>
      <div className="form-header">
        <h2 className="font-weight-bold">
          {"Set your password"}
        </h2>
      </div>

      <form onSubmit={submit} className="mt-4 " style={{ width: "50%" }}>
        <div className="col-12 mt-3">
          <PasswordField
            hasLabel={true}
            label={login.password}
            placeholder={login.passwordPlaceholder}
            name="password"
            id="safa_register_password"
            min={8}
            value={resetData.password}
            onChange={(e) => {
              setResetData({
                ...resetData,
                password: e.target.value
              })
              setErrors({
                ...errors,
                ...validate(
                  { name: "password", value: e.target.value },
                  { required: true, password: true, min: 8 }
                ),
              });
            }}
            color={errors?.password?.required || errors?.password?.password || errors?.password?.min ? "danger" : ""}
            errors={errors?.password}
          />
        </div>
        <div className="col-12 mt-3">
          <PasswordField
            hasLabel={true}
            label={userDetails.repeatPassword}
            placeholder={userDetails.repeatPasswordPlaceholder}
            name="passwordConfirmation"
            id="safa_register_passwordConfirmation"
            value={resetData.passwordConfirmation}
            onChange={(e) => {
              setResetData({
                ...resetData,
                passwordConfirmation: e.target.value
              });
              setErrors({
                ...errors,
                ...validate(
                  { name: "passwordConfirmation", value: e.target.value === resetData.password },
                  { required: true, confirm: true }
                ),
              });
            }}
            color={
              errors?.passwordConfirmation?.required || errors?.passwordConfirmation?.confirm
                ? "danger"
                : ""
            }
            errors={errors?.passwordConfirmation}
          />
        </div>
        <div className="col-12 d-flex flex-column justify-content-center gap-10 mt-3">
          <button type="submit" className="btn main-btn">
            <span className="btn-overlay"></span>
            <span className="text d-flex align-items-center gap-10 justify-content-center">
              {spin ? <Spinner color="light" size="sm" /> : null}
              {"Set Password"}
            </span>
          </button>
        </div>
      </form>
    </div>
  )
}
