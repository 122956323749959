import BreadCrump from 'modules/UmrahOperations/shared/BreadCrump'
import React, { useEffect, useState } from 'react'
import Locale from 'translations';
import DelegationManagementFilter from './Filters';
import DelegationManagementTableRow from './TableRow';
import { fetchDelegationsList } from 'services/operationStatement';
import Pagination from 'components/Pagination';
import ChooseOfficialModal from 'modules/UmrahOperations/shared/ChooseOfficialModal';
import useOperationMovementsModals from 'modules/UmrahOperations/OperationsCenter/useOperationMovementsModals';
import ExecuteMovementModal from 'modules/UmrahOperations/shared/ExecuteMovementModal';
import { useSBSState } from 'context/global';
import ShowSubscription from 'helpers/ShowSubscription';




export default function DelegationManagementContainer() {
  // ** hooks
  const { companyInfo } = useSBSState();
  const { operationStatement, marketPlace, inventory, productElements, productsBuilder, backOffice, movementManagement } = Locale;
  const {
    toggleOfficerModal,
    officerModal,
    saveOfficerData,
    toggleExecuteModal,
    executeModalState,
    handleExecuteMovement
  } = useOperationMovementsModals(resetFilters);

  const isServiceCompany = companyInfo?.company_category === "services_company";

  let initalFitlers = {
    search: "",
    date: null,
    page: null
  }

  // ** states
  const [delegationsList, setDelegationsList] = useState([]);
  const [meta, setMeta] = useState(null);
  const [filters, setFilters] = useState(initalFitlers);


  // ** functions

  function handleFiltersChanges({ key, value }) {
    setFilters({ ...filters, [key]: value })
  }

  function resetFilters() {
    setFilters(initalFitlers);
    getDelegationsList(initalFitlers)
  }


  function goTo(page) {
    getDelegationsList({ page })
  };

  async function getDelegationsList(filters) {
    const res = await fetchDelegationsList(filters)
    if (res?.status === 200) {
      setDelegationsList(res?.data?.data);
      setMeta(res?.data?.meta)
    }
  }


  useEffect(() => {
    getDelegationsList({});
  }, []);

  return (
    <ShowSubscription module="delegation_management">
      <div className="operations-center-container px-new-26">
        <BreadCrump title={movementManagement.movement} />

        <DelegationManagementFilter
          filters={filters}
          handleFiltersChanges={handleFiltersChanges}
          getDelegationsList={getDelegationsList}
          resetFilters={resetFilters}
        />

        <div className='operation-movements-table  table-container h-100'>
          <table className="table table-update mb-0">
            <thead>
              <tr>
                <th>{productElements.type}</th>
                <th>{operationStatement.Agent}</th>
                <th>{operationStatement.Supervisor}</th>
                <th>{marketPlace.messages.pax}</th>
                <th>{productsBuilder.From}</th>
                <th>{productsBuilder.To}</th>
                <th>{movementManagement.driver}</th>
                <th scope="col">{isServiceCompany ? movementManagement.requestedBy : movementManagement.requestToCompany}</th>
                <th>{operationStatement.movementOfficial}</th>
                <th>{movementManagement.dateTime}</th>
                <th>{inventory.messages.Actions}</th>
              </tr>
            </thead>
            <tbody>
              {delegationsList?.length > 0 ?
                delegationsList?.map((delegationRow, index) => {
                  return (
                    <DelegationManagementTableRow
                      key={delegationRow?.id}
                      movmentItem={delegationRow}
                      toggleOfficerModal={toggleOfficerModal}
                      toggleExecuteModal={toggleExecuteModal}
                      handleExecuteMovement={handleExecuteMovement}
                    />
                  )
                })
                :
                <tr>
                  <td colSpan="10">
                    <div className="product-build__product-no-data fullHeight">
                      <i className="icx icx-eye-slash" aria-hidden="true"></i>
                      <h4>{backOffice.noResult}</h4>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>


        {/* list Pagination */}
        <div className="py-3">
          <Pagination
            info={meta}
            goTo={goTo}
          />
        </div>


        {officerModal.isOpen &&
          <ChooseOfficialModal
            isOpen={officerModal.isOpen}
            toggle={toggleOfficerModal}
            actionFn={saveOfficerData}
            isEdit={officerModal?.type === "edit"}
            data={officerModal?.data}
          />
        }

        {executeModalState.isOpen &&
          <ExecuteMovementModal
            isOpen={executeModalState.isOpen}
            toggle={toggleExecuteModal}
            actionFn={handleExecuteMovement}
          />
        }
      </div>
    </ShowSubscription>
  )
}
