import React from "react";
import { Row, Col } from "reactstrap";

export default function AccountingAccommodation(props) {
	return (
		<>
			{/* Accounting */}
			<div className="accounting">
				<h2 className="head">Accounting</h2>
				<Row className="m-0 bg-white border p-3 my-2 rounded">
					<Col sm="4">
						<div className="account-item">
							<p className="title">Amount</p>
							<p className="price">10,000 EGP</p>
						</div>
					</Col>
					<Col sm="4">
						<div className="account-item">
							<p className="title">Additional Service</p>
							<p className="price">8,000 EGP</p>
						</div>
					</Col>
					<Col sm="4">
						<div className="account-item">
							<p className="title">Total Price</p>
							<p className="price text-success">18,000 EGP</p>
						</div>
					</Col>
				</Row>
			</div>
		</>
	);
}
