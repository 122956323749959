import Carousel from 'react-multi-carousel';
import { ReactComponent as RightIcon } from "assets/images/webBuilder/right.svg";
import { ReactComponent as LeftIcon } from "assets/images/webBuilder/left.svg";
import ThemeSevenHotelCard from './HotelCard';
import HotelBGSrc from "assets/images/webBuilder/theme-7-hotel-bg.png"
import { AddElement } from 'modules/WebBuilder-V2/shared/AddElement';
import { useWebBuilderState } from 'context/webBuilder';
import EditableParagraph from 'modules/WebBuilder-V2/shared/EditableParagraph';
import Locale from 'translations';
import DragAndDropContainer from 'modules/WebBuilder-V2/shared/DragAndDropContainer';
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import { SORT_ITEMS_IN_CONTAINER_ACTION } from 'modules/WebBuilder-V2/interfaces/blockTypes';


const packagesBlocksType = "packagesBlocksType";


export default function HotelTheme7({
  items,
  sortHotelsItems,
  focusContainer,
  editItem,
  itemsBoxActions,
  type,
  openEditModal,
  handleOpenAddModal,
  title,
  body,
  updateHotel
}) {


  const { webBuilder } = Locale;
  const { selectedLanguage } = useWebBuilderState();


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1200, min: 700 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1.2,
    },
  };

  const CustomRightArrow = ({ onClick }) => (
    <button
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      onClick={onClick}
    >
      <RightIcon />
    </button>
  );

  const CustomLeftArrow = ({ onClick }) => (
    <button
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      onClick={onClick}
    >
      <LeftIcon className="r-left-icon" />
    </button>
  );

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    return (
      <div className="carousel-button-group">
        <CustomLeftArrow onClick={() => previous()} />
        <CustomRightArrow onClick={() => next()} />
      </div>
    );
  };


  return (
    <section className='theme_hotels_section section_padding__lg' id="packages-section">
      <div className="abstract" style={{ backgroundImage: `url(${HotelBGSrc})` }}></div>
      <div className="section_content">
        <div className="heading px-2">
          <div className="wrapper">
            <EditableParagraph
              className="title text-white"
              contentEditable={true}
              onInput={(value) => {
                updateHotel({ name: "title", value, language: selectedLanguage })
              }}
              text={title}
            />
            <EditableParagraph
              className="heading_desc text-white"
              contentEditable={true}
              onInput={(value) => {
                updateHotel({ name: "body", value, language: selectedLanguage })
              }}
              text={body}
            />

            <AddElement
              className="mt-3"
              onClick={handleOpenAddModal}
              title={webBuilder.addMoreHotels}
            />
            {/* <h4 className='title'>Top Hotels</h4>
            <p className="heading_desc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
            </p> */}
          </div>
        </div>

        <div className="hotels_list_container mt-4">
          <Carousel
            responsive={responsive}
            slidesToSlide={1}
            keyBoardControl={true}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside={true}
            showDots={true}
            arrows={false}
            infinite={false}
          >
            {items.map((props, index) => (
              <DragAndDropContainer
                accept={packagesBlocksType}
                type={packagesBlocksType}
                id={props.id}
                index={index}
                action={SORT_ITEMS_IN_CONTAINER_ACTION}
                moveItemInContainer={(dragIndex, hoverIndex) =>
                  sortHotelsItems({ dragIndex, hoverIndex })
                }
              >
                <EditBox
                  editMode={focusContainer === type}
                  actions={[
                    ...editItem(props, index),
                    ...itemsBoxActions(props.id),
                  ]}
                >
                  <ThemeSevenHotelCard {...props} itemType="hotel" openEditModal={openEditModal} />
                </EditBox>
              </DragAndDropContainer>
            ))}
          </Carousel>
        </div>


      </div>
    </section>
  )
}