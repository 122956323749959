import React from "react";

export default function ProductHeader() {
	return (
		<div className="application__header ">
			<div className="container-custom">
				<div className="application__header-containt ">
					{" "}
					{/* */}
					<div>
						<h3>Product Type</h3>
						<p>Umrah</p>
					</div>
					<div>
						<h3>Product Name</h3>
						<p>Umrah Ramdan</p>
					</div>
					<div>
						<h3>Validation</h3>
						<p>05/5/2010 -15/6/2015</p>
					</div>
					<div>
						<h3>Trip Date</h3>
						<p>05/5/2010 -15/6/2015</p>
					</div>
					<div>
						<h3>Productio Item</h3>
						<div className="icons d-flex justify-content-between align-items-center ">
							<i className="far fa-building text-gray "></i>
							<i className="fas fa-plane text-gray"></i>
							<i className="fas fa-bus text-gray"></i>
							<i className="fas fa-passport text-gray"></i>
							<i className="fas fa-shield-alt text-gray"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
