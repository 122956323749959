const fontFamily = {
  cairo: "'Cairo', sans-serif",
  almarai: "'Almarai', sans-serif",
  josefin: "'Josefin Sans', sans-serif",
  noto: "'Noto Sans Arabic', sans-serif",
};


export const changeColorTheme = (color,updateStyle) => {
  const webBuilderDom = document.getElementById("main-web-builder");
  webBuilderDom?.style.setProperty("--web-page-primary-color", color);
  updateStyle({ name: "color", value: color });
};
export const changeFontColor = (color,updateStyle) => {
  const webBuilderDom = document.getElementById("main-web-builder");
  webBuilderDom?.style.setProperty("--web-page-font-color", color);
  updateStyle({ name: "fontColor", value: color });
};

export const changeFont = (font,updateStyle) => {
  const webBuilderDom = document.getElementById("main-web-builder");
  webBuilderDom?.style.setProperty("--web-page-font-family", fontFamily[font]);
  updateStyle({ name: "font", value: font });
};

export const changeBorder = (rounded,updateStyle) => {
  const webBuilderDom = document.getElementById("main-web-builder");
  webBuilderDom?.style.setProperty(
    "--web-page-border-raduis",
    rounded 
  );
  updateStyle({ name: "rounded", value: rounded });
};



