import axios from "axios";
import { cleanEmpty } from "./general";

const activityURL = process.env.REACT_APP_API_URL + "/v1";

export const getActivityLogs = async (filter = {}) => {
	return await axios
		.get(`${activityURL}/logs`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const getUsersDropdown = async () => {
	return await axios
		.get(`${activityURL}/logs/users-dropdown`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getModulesDropdown = async () => {
	return await axios
		.get(`${activityURL}/logs/module-dropdown`)
		.then((res) => res)
		.catch((err) => err.response);
};
