import React from "react";

function KeyValue({ data }) {
	return (
		<div className="bg-white flight-key-value p-2 px-3 d-flex justify-content-between w-100">
			{data.map((item) => (
				<div>
					<p className='text-black-50'>{item.key}</p>
					<p>{item.value}</p>
				</div>
			))}
		</div>
	);
}

export default KeyValue;
