export const InteneraryPrinte = `
<style>
* {
  font-family: "Cairo", sans-serif;
  box-sizing: border-box;
}

html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}

p {
  margin: 0;
}




.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
margin-bottom: 2px;
}


html,
.bg-gray-100 {
background-color: #fff !important;
}




.bg-white {
background-color: #fff !important;
}
.p-4 {
padding: 1.5rem !important;
}
.d-flex {
display: flex !important;
}

.flex-column {
flex-direction: column;
}
.text-center {
text-align: center !important;
}
.text-success {
color: #00b545 !important;
}
.font-weight-bold {
font-weight: 700 !important;
}

.py-2 {
padding-top: 0.5rem !important;
padding-bottom: 0.5rem !important;
}

.py-1 {
padding-top: 0.25rem !important;
padding-bottom: 0.25rem !important;
}
.px-4 {
padding-right: 1.5rem !important;
padding-left: 1.5rem !important;
}
.mb-5 {
margin-bottom: 3rem !important;
}
.mb-3 {
margin-bottom: 1rem !important;
}
.mb-2 {
margin-bottom: 0.5rem !important;
}
.my-2 {
margin-top: 0.5rem !important;
margin-bottom: 0.5rem !important;
}
.my-1 {
margin-top: 0.25rem !important;
margin-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
.col-sm-8,
.col-sm-9 {
flex: 0 0 auto;
}
.col-sm-8 {
width: 66.66666667%;
}
.col-sm-4,
.col-sm-5 {
flex: 0 0 auto;
}

.col-sm-4 {
width: 33.33333333%;
}
}
.col-9 {
width: 75%;
}
.col-8,
.col-9 {
flex: 0 0 auto;
}
.col-8 {
width: 66.66666667%;
}
.col-7 {
width: 58.33333333%;
}
.col-6,
.col-7 {
flex: 0 0 auto;
}
.col-6 {
width: 50%;
}
.col-5 {
width: 41.66666667%;
}
.col-4,
.col-5 {
flex: 0 0 auto;
}
.col-4 {
width: 33.33333333%;
}
.col-3 {
width: 25%;
}
.col-2,
.col-3 {
flex: 0 0 auto;
}
.col-2 {
width: 16.66666667%;
}
.row {
--bs-gutter-x: 20px;
--bs-gutter-y: 0;
display: flex;
flex-wrap: wrap;
margin-right: -0.5;
margin-left: -0.5;
}
.row {
display: flex;
flex-wrap: wrap;
margin-right: -15px;
margin-left: -15px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
padding-right: 5px;
padding-left: 5px;
}
.col-4 {
flex: 0 0 33.3333333333%;
max-width: 33.3333333333%;
}
.col-6 {
flex: 0 0 50%;
max-width: 50%;
}
.col-3 {
flex: 0 0 25%;
max-width: 25%;
}
.col-8 {
flex: 0 0 66.6666666667%;
max-width: 66.6666666667%;
}
.col-2 {
flex: 0 0 16.6666666667%;
max-width: 16.6666666667%;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
padding-right: 5px;
padding-left: 5px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
padding-right: 5px;
padding-left: 5px;
}
.mb-1,
.my-1 {
margin-bottom: 0.25rem !important;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
padding-right: 5px;
padding-left: 5px;
}

.intenerary-header{
	gap: 45px;
}
.intenerary-content {
	gap: 50px;
}
.intenerary-header-box,
.intenerary-header-box1 {
	position: relative;
}
.intenerary-header-box:after {
	content: "";
	position: absolute;
	left: 0%;
	right: 0%;
	border-bottom: 2px dashed #8b8b8b;
	top: 60%;
}
.intenerary-header-box1:after {
	content: "";
	position: absolute;
	left: 12%;
	right: 46%;
	border-bottom: 2px dashed #8b8b8b;
	top: 60%;
}
.intenerary-header .txt-gray {
	font-size: 18px;
	overflow: hidden !important;
	z-index: 5555;
	background: #fff;
}
.intenerary-content-header {
	font-size: 19px;
  font-weight: 600;
	margin:8px 12px
}
.intenerary-content p {
	font-size: 19px;
    margin-bottom: 4px
}
.intenerary-content-footer p{
	font-size: 20px;
	margin-top: 45px;
	color:#2d2d2dfa
}
hr{
	height: 3px; 
	background: #6e6e6e;
	margin: 45px 0;
	border:none
}
.justify-content-between{
	justify-content: space-between;
}
.w-50{
	width:50%
}
.text-align-center{
	text-align:center
}
.intenerary-header-box{
	margin-bottom: 20px
}
.intenerary-header-box p:first-child{
	color: #4a4a4a
}
.d-inline{
	display: inline-block;
}
.align-items-center{
	align-items-center: center
}

	@media print {
		.itinerary-container{
			outline:2px solid #222;
			 padding:15px;	
			 margin: 2px auto !important;
		}
		.intenerary-header-box {
			margin-bottom: 0px;
	  }
		.intenerary-content-footer{
			height: 94% !important;
			padding:50px 0 !important;
		
		}
		hr {
			height: 3px;
			background: #6e6e6e;
			margin: 12px 0 !important;
			border: none;
	   }
		.hr-none-print{
			display:none !important;
		}
	
	
	.pagebreak { page-break-before: always; } 

	}


	.form-head {
		background-color: #e4e4e4;
		color: #0f3e5e;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
	.text-black-50 {
		color: rgba(0, 0, 0, 0.5) !important;
	}
	.form-box {
		border: 1px solid #ededed;
		border-radius: 5px 5px 0px 0px;
		width: 100%;
		background-color: #fff;
		overflow: hidden;
	}

	.flight-key-value {
		overflow-x: auto;
	}
	.flight-key-value div {
		min-width: fit-content;
		padding-inline-end: 16px;
	}
	.d-flex {
		display: flex !important;
	}
	.justify-content-between {
		justify-content: space-between !important;
	}

	.flight_item {
		background-color: #fff;
		width: 100%;
	}

	.flight_item .head-toggel {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		font-size: 14px;
		padding: 4px;
	}
	.flight_item .head-toggel a {
		color: #2d2d2d;
	}
	.flight_item .head-toggel a i {
		margin-left: 4px;
	}

	.flight_item .time-line {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		column-gap: 10px;
		padding-bottom: 10px;
	}
	.flight_item .time-line .infoAir {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		position: relative;
	}
	.flight_item .time-line .Landing {
		display: flex;
		flex-direction: column;
		position: relative;
		align-items: flex-start;
	}

	.flight_item .time-line .airport-name {
		color: #2d2d2d;
		font-size: 14px;
	}
	.flight_item .footer-toogle {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 5px 0;
		justify-content: space-between;
		border-top: 1px solid #ebebeb;
	}
	.flight_item .footer-toogle .img-company {
		width: 50px;
		height: 50px;
	}

	.spent {
		color: #a0a0a0;
		font-size: 14px;
		padding: 5px 20px 0px 20px;
		border-top: 2px dashed #1a61af;
		position: relative;
	}
	.spent::after {
		content: "";
		position: absolute;
		padding: 3px;
		border: 1px solid #1a61af;
		border-radius: 50%;
		top: -4px;
		right: -11px;
		/* [dir="ltr"] & {
			right: -11px;
		}
		[dir="rtl"] & {
			left: -11px;
		} */
	}
	.spent::before {
		content: "";
		position: absolute;
		padding: 3px;
		border: 1px solid #1a61af;
		border-radius: 50%;
		top: -4px;
		left: -11px;

		/* [dir="ltr"] & {
			left: -11px;
		}
		[dir="rtl"] & {
			right: -11px;
		} */
	}
	.text-center {
		text-align: center !important;
	}
	.blue {
		color: #0184fe !important;
	}
	.takeoff {
		font-weight: bold;
		line-height: 1;
	}
	.collapse-box {
		background-color: #f8f8f8;
	}

	.collapse-box .text-body {
		color: #212529 !important;
		font-size: 14px !important;
	}
	.p-2 {
		padding: 0.5rem !important;
	}
	.mx-1 {
		margin-left: 0.25rem !important;
	}

	.collapse-box .img-company {
		width: 50px;
		height: 50px;
	}
	.stop-box {
		position: relative;
	}
	.stop-box::before {
		content: "";
		position: absolute;
		left: -16px;
		top: 95px;
		border: 1px solid #b1b1b1;
		padding: 3px;
		border-radius: 50%;
	}
	.stop-box .blue::before {
		content: "";
		position: absolute;
		top: 20px;
		left: -87px;
		width: 150px;
		border-bottom: 2px dashed #979797;
		-webkit-transform: rotate(270deg);
		transform: rotate(270deg);
	}

	.stop-box .blue::after {
		content: "";
		position: absolute;
		left: -18px;
		top: 20%;
		border: 1px solid #0372bc;
		padding: 5px;
		border-radius: 50%;
	}
	.stop-box::after {
		content: "";
		position: absolute;
		left: -16px;
		top: -62px;
		border: 1px solid #b1b1b1;
		padding: 3px;
		border-radius: 50%;
	}
	.mx-4 {
		margin-left: 1.5rem !important;
		margin-right: 1.5rem !important;
	}
	.w-95 {
		width: 95% !important;
	}
	.border {
		border: 1px solid #dee2e6 !important;
	}
	.mx-auto {
margin-right: auto !important;
margin-left: auto !important;
}
.collapse-box {
	.spent{
		padding: 5px 20px 19px 20px !important;
	}
}
</style>

<div
			class="container itinerary-container"
			style="
				background: #fff;
				width: 1020px !important;
				margin: 25px auto !important;
			"
		>
			<div class="part1">
				<h6 class="fw-700 text-align-center itinerary-h" style="font-size: 45px;margin-top:10px;margin-bottom: 25px">
					ITINERARY
				</h6>
				<div class="intenerary-header d-flex justify-content-between">
					<div class="box w-50">
						<div class="d-flex justify-content-between intenerary-header-box">
							<p class="txt-gray">NAME</p>
							<p class="txt-gray">[Name]</p>
						</div>
						<div class="d-flex justify-content-between intenerary-header-box">
							<p class="txt-gray">ETKT NBR</p>
							<p class="txt-gray">[ETKT_NBR]</p>
						</div>
						<div class="d-flex justify-content-between intenerary-header-box">
							<p class="txt-gray">AIRLINE REF NBR</p>
							<p class="txt-gray">[air_pnr]</p>
						</div>
					</div>
					<div class="box w-50">
						<div class="d-flex justify-content-between intenerary-header-box">
							<p class="txt-gray">ID NUMBER</p>
							<p class="txt-gray">[passport_num]</p>
						</div>

						<div class="d-flex justify-content-between intenerary-header-box">
							<p class="txt-gray">ISSUING AIRLINE</p>
							<p class="txt-gray">[airline_name]</p>
						</div>
						<div class="d-flex justify-content-between intenerary-header-box">
							<p class="txt-gray">ISSUING AGENT</p>
							<p class="txt-gray">Safa Soft</p>
						</div>
						<div class="d-flex justify-content-between intenerary-header-box">
							<p class="txt-gray">TEL</p>
							<p class="txt-gray">+202 25258862
							</p>
						</div>
					</div>
				</div>
				<div class="intenerary-header">
					<div class="box w-100">
						<div class="d-flex justify-content-between intenerary-header-box">
							<p class="txt-gray">AGENCY ADDRESS</p>
							<p class="txt-gray">
							9 Othman Towers, Kornish El-Nile, Maadi – Cairo.
							</p>
						</div>
					</div>
				</div>
			</div>

			<hr />
			[itenerary_body]
			<hr  class="hr-none-print"/>
			<div
			class="intenerary-content-footer"
				style="
					margin:12px 0 ;
					line-height: 1.3;

				"
			>
				<p>
					Please prepare/print your itinerary for customs security check.
					Remember to bring your passport or other identification document with
					you,make sure the information is matched with your itinerary to avoid
					discrepancy.
				</p>
				<p>
					For international flights, you are suggested to arrive at
					the airport at least 3-4 hours prior to your departure time to check
					in and go throughsecurity. For connecting flights, please confirm at
					the counter whether not you can check your luggage through to the
					final destination whenyou check in.
				</p>
				<p>
					Please contact us in case of change
					request at least 1 to 2 business days before the departure time to
					avoid the potential no-show penalty.In case the flight is cancelled or
					delayed when you are at the airport or within 1 to 2 business days
					before the departure time, you are highlyrecommended to submit your
					change request at the airline counter in the airport for free. If the
					staff at the airline counter could not assistyou on processing the
					request, please request them to issue a certificate of flight
					delay/cancellation so you are able to apply involuntarychange/refund
					later.
				</p>
			</div>
		</div>
		`;
