import { Link } from 'react-router-dom';

function BreadCrampItem({ link, isLast }) {
  if (isLast) {
    return <span>{link.name}</span>
  }

  return <Link to={link.url} className="link">
    {link.name}
    <span className='mr-1'>/</span>
  </Link>
}

export default function BreadCrump({ links = [] }) {
  return (
    <div className="breadcrump px-3">
      {
        links.map((link, index) => {
          return <BreadCrampItem
            key={index}
            link={link}
            isLast={index === links.length - 1}
          />
        }
        )
      }
    </div>
  );
}