import React, { useEffect, useState } from 'react'
import CustomModal from "modules/WebBuilder-V2/shared/CustomModal";
import { useWebBuilderState } from 'context/webBuilder';
import { ReactComponent as UpIcon } from "assets/images/product-builder/arrow-up.svg";
import { ReactComponent as GalleryIcon } from "assets/images/product-builder/gallery.svg";
import { uploadFile } from 'services/auth';
import { arrayToObject } from 'modules/WebBuilder-V2/helpers/formatPageData';
import { ReactComponent as SaveIcon } from "assets/images/umrah-operations/save-icon.svg";


const URI = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

export default function ChangeLogosModal({ isOpen, toggle, save }) {
  // ** hooks
  const { languages, hero } = useWebBuilderState();
  const currentLogo = hero?.header?.logo;

  // ** states
  const [logos, setLogos] = useState(null);

  function applyLogos(e) {
    e.preventDefault();
    save(logos);
    toggle()
  }

  useEffect(() => {
    if (currentLogo) {
      const languagesCodes = languages.map(l => l?.code);
      setLogos(arrayToObject(languagesCodes, {}, currentLogo));
    }

    return () => { };
  }, [currentLogo, languages]);


  return (
    <CustomModal isOpen={isOpen} toggle={toggle} header={"Upload Header Logo"} size="md">
      <form onSubmit={applyLogos}>
        {languages?.map(language => {
          const langCode = language?.code?.toLowerCase();
          return (
            <div className="col-12 mb-2" key={langCode}>
              <UploadImageInputRect
                label={`Upload ${language?.name || ""} Logo`}
                onChange={(imageUUID) => {
                  setLogos({ ...logos, [langCode]: imageUUID })
                }}
                image={logos?.[langCode]}
                langCode={langCode}
              />
            </div>
          )
        })}

        <div className="col-12">
          <button type="submit" className="btn py-2 text-white w-100 d-flex align-items-center justify-content-center"
            style={{ backgroundColor: "#D4A655", fontSize: "16px" }}
          >
            <SaveIcon />
            <span className='mx-1'>Save changes</span>
          </button>
        </div>

      </form>
    </CustomModal>
  )
}

function UploadImageInputRect({ label, onChange, image, langCode }) {
  const urlPattern = new RegExp("^(http|https)://", "i");
  const imageSrc = urlPattern.test(image) ? image : `${URI}/${image}`;

  async function handleUpload(e) {
    e.stopPropagation();
    const files = e.target.files;
    if (!files?.[0]) return;

    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("name", files[0]?.name);
    formData.append("bucket", "webBuilder");

    const response = await uploadFile(formData);
    if (response.data.data.uuid) {
      onChange(response.data.data.uuid);
    }
  };

  return (
    <div className={`upload-file-component`} key={langCode}>

      <p className="upload-file-label"> {label}</p>

      <div className="upload-file-component-input flex-column align-items-center">

        <div className="" style={{ height: "120px" }}>
          <img src={imageSrc} alt="" className="img-fluid" style={{ objectFit: "contain", height: "100%" }} />
        </div>

        <label htmlFor={`upload-file-logo-${langCode}`} className="upload-button">
          <GalleryIcon />
          <UpIcon className="up-arrow" />
        </label>


        <input
          onChange={handleUpload}
          style={{ display: "none" }}
          type="file"
          id={`upload-file-logo-${langCode}`}
          accept="image/png, image/jpeg, image/jpg"
        />
      </div>

    </div>
  )
}
