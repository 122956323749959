import DateTimePickerField from "components/Form/DateTimePickerField/DateTimePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import Locale from "translations";
import { useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getSegments } from 'services/operationStatement';
import { getOperationsLookup } from 'modules/UmrahOperations/helper/operationsLookup';



export default function TransportationMovments({
	operationHasSent,
	generateTransportersList,
	handlePickupsInputs,
	movementsList,
	addNewMovment,
	deleteMovment,
	handleMovmentsInputs,
	errors,
}) {
	const { operationStatement } = Locale;
	const { reference } = useParams();
	const statementId = reference;

	const [lookup, setLookup] = useState({ destinations: [], pickup_points: [] });

	const { movmentTypesLookup, citiesLookup } = getOperationsLookup(Locale);

	const getPickupPoints = async (city_id, key) => {
		const Segments = await getSegments(statementId, city_id?.id, key === "pickup_points" ? 'pickup' : 'destination');

		if (Segments.length > 0) {
			setLookup({
				...lookup, [key]: Segments.map(res => {
					return {
						...res,
						label: res.title,
						id: res.item_id,
						value: res.item_id,
						name: res.title,
					}
				})
			})
		}

	}

	return (
		<div className="item-row">
			<h4 className="header d-flex justify-content-between">
				{operationStatement.Movements}
				{!operationHasSent ?
					<button
						className="btn btn-success text-capitalize"
						onClick={() => generateTransportersList()}
					>
						{operationStatement.generateMovements}
					</button>
					: null}
			</h4>
			<div className="row mx-0 p-2">
				<div className="col-md-1 col-12">
					<label htmlFor="" className="label">
						{operationStatement.movementType}
					</label>
				</div>
				<div className="col-md15 col-12">
					<label htmlFor="" className="label">
						{operationStatement.pickupCity}
					</label>
				</div>

				<div className="col-md-2 col-12">
					<label htmlFor="" className="label">
						{operationStatement.Pickuppoint}
					</label>
				</div>

				<div className="col-md15 col-12">
					<label htmlFor="" className="label">
						{operationStatement.DropOffCity}
					</label>
				</div>
				<div className="col-md-2 col-12">
					<label htmlFor="" className="label">
						{operationStatement.DropoffPoint}
					</label>
				</div>
				<div className="col-md-1 col-12">
					<label htmlFor="" className="label">
						{operationStatement.pax}
					</label>
				</div>
				<div className="col-md-2 col-12">
					<label htmlFor="" className="label">
						{operationStatement.startDateTime}
					</label>
				</div>
			</div>

			{movementsList?.map((movement, index) => {
				return (
					<div className="row mx-0 p-2 align-items-start" >

						<div className="col-md-1 bg-movement-color col-12">
							<SelectField
								hasLabel={false}
								label={operationStatement.Movementtype}
								placeholder={operationStatement.Select}
								id={`movement-type-${index}`}
								name={`movement-type-${index}`}
								options={movmentTypesLookup}
								value={movement?.movement_type?.name}
								onChange={(e) =>
									handleMovmentsInputs(e, "movement_type", index)
								}
								color={
									errors?.[`movement_type-${index}`]?.required ? "danger" : ""
								}
								errors={errors?.[`movement_type-${index}`]}
								disabled={operationHasSent}
							/>
						</div>
						<div className="col-md15 bg-movement-color col-12">
							<SelectField
								hasLabel={false}
								label={operationStatement.pickupCity}
								placeholder={operationStatement.Select}
								id={`pickup_city_id-${index}`}
								name={`pickup_city_id-${index}`}
								options={citiesLookup}
								value={movement?.pickup_city_id?.name}
								onChange={(e) => {
									handleMovmentsInputs(e, "pickup_city_id", index)
									getPickupPoints(e, "pickup_points")
								}
								}
								color={
									errors?.[`pickup_city_id-${index}`]?.required ? "danger" : ""
								}
								errors={errors?.[`pickup_city_id-${index}`]}
								disabled={operationHasSent}

							/>
						</div>
						<div className="col-md-2 bg-movement-color col-12">
							{movement.pickup_points && movement.pickup_points.length > 0
								? movement.pickup_points.map((res, indexs) => {
									return (
										<SelectField
											hasLabel={false}
											label={operationStatement.DropOffPoint}
											placeholder={operationStatement.Select}
											id={`movement-type-${index}`}
											name={`movement-type-${index}`}
											options={lookup.pickup_points}
											value={res.name ? res.name : null}
											onChange={(e) =>
												handlePickupsInputs(e, "pickup_points", index, indexs)
											}
											color={
												errors?.[`movement_type-${index}`]?.required
													? "danger"
													: ""
											}
											errors={errors?.[`movement_type-${index}`]}
											disabled={operationHasSent}

										/>
									);
								})
								: null}
						</div>
						<div className="col-md15 bg-movement-color col-12">
							<SelectField
								hasLabel={false}
								label={operationStatement.DropOffPoint}
								placeholder={operationStatement.Select}
								id={`destination_city_id-${index}`}
								name={`destination_city_id-${index}`}
								options={citiesLookup}
								value={movement?.destination_city_id?.name}
								onChange={(e) => {
									handleMovmentsInputs(e, "destination_city_id", index)
									getPickupPoints(e, "destinations")
								}
								}
								color={
									errors?.[`destination_city_id-${index}`]?.required
										? "danger"
										: ""
								}
								errors={errors?.[`destination_city_id-${index}`]}
								disabled={operationHasSent}

							/>
						</div>

						<div className="col-md-2 bg-movement-color col-12">
							{movement.destinations && movement.destinations.length > 0
								? movement.destinations.map((res, indexs) => {
									return (
										<SelectField
											hasLabel={false}
											label={operationStatement.DropOffPoint}
											placeholder={operationStatement.Select}
											id={`movement-type-${index}`}
											name={`movement-type-${index}`}
											options={lookup.destinations}

											value={res.name ? res.name : null}
											onChange={(e) => {
												handlePickupsInputs(e, "destinations", index, indexs)
											}
											}
											color={
												errors?.[`movement_type-${index}`]?.required
													? "danger"
													: ""
											}
											errors={errors?.[`movement_type-${index}`]}
											disabled={operationHasSent}

										/>
									);
								})
								: null}
						</div>
						<div className="col-md-1 bg-movement-color col-12">
							<TextField
								hasLabel={false}
								label={operationStatement.pax}
								placeholder={operationStatement.pax}
								id={`movement-pax-${index}`}
								name={`movement-pax-${index}`}
								value={movement?.pax}
								onChange={(e) =>
									handleMovmentsInputs(e.target.value, "pax", index)
								}
								color={errors?.[`pax-${index}`]?.required ? "pax" : ""}
								errors={errors?.[`pax-${index}`]}
								disabled={operationHasSent}

							/>
						</div>
						<div className="col-md-2 bg-movement-color col-12">
							<DateTimePickerField
								hasLabel={false}
								label={operationStatement.PickupTime}
								placeholder="HH:MM"
								id={`movement_date-${index}`}
								name={`movement_date-${index}`}
								date={movement?.movement_date}
								onChangeDate={(time) =>
									handleMovmentsInputs(time, "movement_date", index)
								}
								dateFormat="dd/MM/yyyy HH:mm"
								color={
									errors?.[`movement_date-${index}`]?.required ? "danger" : ""
								}
								errors={errors?.[`movement_date-${index}`]}
								disabled={operationHasSent}

							/>
						</div>
						{!operationHasSent ?
							<button
								className="btn px-1 pt-2 py-0 font-16"
								onClick={() => deleteMovment(index)}
							>
								<DeleteIcon />
							</button>
							: null}
					</div>
				);
			})}

			<div className="row mx-0 px-2">
				{!operationHasSent ?
					<button
						className="btn-plus-operation my-3"
						onClick={() => addNewMovment()}
					>
						<span className="me-1 ms-0">
							<i class="fas fa-plus"></i>
						</span>
						{operationStatement.AddMovement}
					</button>
					: null}
			</div>
		</div>
	);
}
