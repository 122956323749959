import React from "react";
import Locale from "translations";
import TextField from "components/Form/TextField/TextField";
import SelectField from 'components/Form/SelectField/SelectField';
import validate from "helpers/validate";


export default function ContactInformation({ errors, setErrors, countries, leaderData, setLeaderData }) {
	const { packages: t } = Locale;

	function handleInputChanges(value, keyName) {
		setLeaderData({ ...leaderData, [keyName]: value });
    setErrors({
      ...errors,
      ...validate(
        { name: keyName, value },
        {
          required: true,
          email: keyName === "email" ? true : false,
        }
      ),
    })
	}

	return (
		<div className="ContactInfo p-3">

			{/* full name */}
			<div className="col-md-12">
				<TextField
					label={t.fullName}
					type="text"
					id="name"
					name="name"
					size="small"
					placeholder={t.fullName}
					value={leaderData?.name}
					onChange={(e) => handleInputChanges(e.target.value, "name")}
					color={errors?.name?.required ? "danger" : ""}
					errors={errors?.name}
				/>

				<div className="row mx-0 mt-3">
					<div className="col-md-6">
						<TextField
							label={t.emailAddress}
							id="email"
							name="email"
							size="small"
							placeholder={t.emailAddress}
							value={leaderData?.email}
							onChange={(e) => handleInputChanges(e.target.value, "email")}
							color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
              errors={errors?.email}
						/>
					</div>

					{/* phone */}
					<div className="col-md-6 phone">
						{/* phone code  */}
						<div className='phone_code'>
							<SelectField
								name="phonecode"
								id="phonecode"
								value={leaderData.phoneCode?.phone_code}
								onChange={(e) => handleInputChanges(e, "phoneCode")}
								options={countries}
								color={errors?.phoneCode?.required ? "danger" : ""}
							/>
						</div>

						<TextField
							label={t.phoneNumber}
							type="number"
							name="phone"
							id="phone"
							className="phone_number_textfield control-field__input w-100"
							value={leaderData?.phone}
							min={9}
							max={13}
							onWheel={e => e.currentTarget.blur()}
							onChange={(e) => handleInputChanges(e.target.value, "phone")}
							errors={errors?.phone}
							color={errors?.phone?.required || errors?.phone?.min || errors?.phone?.max ? "danger" : ""}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
