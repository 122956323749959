

import React from 'react'

export default function CalenderIcon() {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.592" viewBox="0 0 24 24.592">
  <path id="schedule-calendar-icon" d="M21.751,24.592H2.249A2.258,2.258,0,0,1,0,22.343V4.513A2.258,2.258,0,0,1,2.25,2.264H4.325V5.031A2.472,2.472,0,0,0,5.26,6.955a2.912,2.912,0,0,0,3.664,0,2.472,2.472,0,0,0,.935-1.923V2.264h4.191V5.031a2.472,2.472,0,0,0,.935,1.923,2.912,2.912,0,0,0,3.664,0,2.472,2.472,0,0,0,.935-1.923V2.264H21.75A2.262,2.262,0,0,1,24,4.513v17.83A2.256,2.256,0,0,1,21.751,24.592ZM16.727,11.362h3.222a.414.414,0,0,1,.412.4v2.682a.414.414,0,0,1-.412.4H16.727a.412.412,0,0,1-.4-.4V11.772a.41.41,0,0,1,.4-.4Zm-6.306,0h3.224a.412.412,0,0,1,.4.4v2.682a.414.414,0,0,1-.4.4H10.421a.412.412,0,0,1-.4-.4V11.772a.412.412,0,0,1,.4-.4Zm-6.372,0H7.273a.412.412,0,0,1,.4.4v2.682a.414.414,0,0,1-.4.4H4.049a.412.412,0,0,1-.41-.4V11.772a.41.41,0,0,1,.41-.4Zm12.678,5.7h3.222a.414.414,0,0,1,.412.4v2.682a.412.412,0,0,1-.412.41H16.727a.412.412,0,0,1-.4-.41V17.474a.412.412,0,0,1,.4-.4Zm-6.306,0h3.224a.412.412,0,0,1,.4.4v2.682a.412.412,0,0,1-.4.41H10.421a.412.412,0,0,1-.4-.41V17.474a.414.414,0,0,1,.4-.4Zm-6.372,0H7.273a.412.412,0,0,1,.4.4v2.682a.412.412,0,0,1-.4.41H4.049a.412.412,0,0,1-.41-.41V17.474a.412.412,0,0,1,.41-.4ZM15.73.891A1,1,0,0,1,16.811,0,1,1,0,0,1,17.9.891V5.031a1,1,0,0,1-1.087.891,1,1,0,0,1-1.085-.891V.891ZM6,.891A1.006,1.006,0,0,1,7.093,0,1,1,0,0,1,8.177.891V5.031a1,1,0,0,1-1.085.891A1,1,0,0,1,6,5.031ZM.72,8.778V22.1A1.775,1.775,0,0,0,2.49,23.872H21.5A1.775,1.775,0,0,0,23.274,22.1V8.778Z" fill="#d5d5d5"/>
</svg> )
}
