import EditableParagraph from "modules/WebBuilder/shared/EditableParagraph";
import React from "react";

function ParagraphItem({ onChange, body ,id}) {
	return (
		<EditableParagraph
			className="custom-container-paragraph"
			contentEditable={true}
			onInput={(value) => {
				onChange(value,id);
			}}
			text={body}
		/>
	);
}

export default ParagraphItem;
