import React, { useEffect } from "react";
import Content from "./Components/Content";
import SideBar from "./Components/SideBar";
import { useWebBuilderDispatch } from "context/webBuilder";
import initialData from "./page.json";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getPageBuilder, getPageBuilderByCompany } from "services/webBuilder";
import { useLocation, useParams } from "react-router-dom";
import { changeBorder, changeColorTheme, changeFont } from "./helpers/styles";
import ShowSubscription from 'helpers/ShowSubscription';
import { formatPageData } from 'modules/WebBuilder-V2/helpers/formatPageData';
import Header from 'modules/WebBuilder-V2/Components/Header';

function WebBuilder() {
	const location = useLocation();
	const preview = !location.pathname.includes('edit');
	const { name } = useParams();

	const dispatch = useWebBuilderDispatch();

	const updateStyle = ({ name, value }) => {
		dispatch({
			type: "updateStyle",
			payload: {
				name,
				value,
			},
		});
	};

	const getPageDetails = async () => {
		let res
		if (!name) {
			res = await getPageBuilder()
		} else {
			res = await getPageBuilderByCompany(name)
		}

		if (res.status === 200) {
			let pageBuilderData = {
				...res.data.page_builder_config,
				public_page_phone: res?.data?.public_page_phone,
				public_page_phone_code: res?.data?.public_page_phone_code,
				slug: res?.data?.public_page_slug,
			};
			const languagesList = pageBuilderData.languages || [{ name: "English", code: "en", direction: "ltr" }];
			if (!pageBuilderData?.languages || pageBuilderData?.languages?.length === 0) {
				pageBuilderData = formatPageData({
					contentData: pageBuilderData,
					languagesList,
					defaultLanguage: "en",
					themeConfig: pageBuilderData
				})
			}
			// console.log("pageBuilderData", pageBuilderData);
			// delete pageBuilderData.languages;
			// delete pageBuilderData.defaultLanguage;
			dispatch({
				type: 'setInitialData',
				payload: pageBuilderData
			})
			const style = pageBuilderData?.style
			changeColorTheme(style?.color, updateStyle)
			changeBorder(style?.rounded, updateStyle)
			changeFont(style?.font, updateStyle)
		}
	}

	useEffect(() => {
		getPageDetails();

		return () => { };
	}, []);

	return (
		<ShowSubscription module={"website_builder"}>
			<DndProvider backend={HTML5Backend}>
				<div id="main-web-builder" className="main-web-builder row m-0">
					<div className="col-12" style={{ display: preview ? "none" : "" }}>
						<Header version="v1" />
					</div>
					<div
						className="col-4"
						style={{ maxWidth: 428, display: preview ? "none" : "" }}
					>
						<SideBar />
					</div>
					<div className={`${preview ? "col-12" : "col-8"}`}>
						<Content />
					</div>
				</div>
			</DndProvider>
		</ShowSubscription>
	);
}

export default WebBuilder;
