import React, { memo, useCallback } from "react";
import { ReactComponent as DragIcon } from "assets/images/promo/drag-icon.svg";
import { useDrag, useDrop } from "react-dnd";


export function SortHotelCard({ selectedHotels, setSelectedHotels }) {
	const unselectHotel = (id) => {
		setSelectedHotels((prev) => {
			const hotelsClone = [...prev];
			const hotelIndex = hotelsClone.findIndex((i) => i.id === id);
			hotelsClone.splice(hotelIndex, 1);
			return hotelsClone;
		});
	};

	const moveCard = useCallback(
		(id, atIndex) => {
			// return hotelsClone;

			const updatedItems = [...selectedHotels];
			const hotelIndex = updatedItems.findIndex((i) => i.id === id);
			const [card] = updatedItems.splice(hotelIndex, 1);
			updatedItems.splice(atIndex, 0, card);

			setSelectedHotels(updatedItems);
		},
		[selectedHotels, setSelectedHotels]
	);

	const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));

	return (
		<div className="col-lg-7 col-12 ">
			<div ref={drop} className="download-sort-container">
				{selectedHotels.map((hotel, index) => (
					<HotelCard
						key={hotel.id}
						hotel={hotel}
						moveCard={moveCard}
						index={index}
						unselectHotel={unselectHotel}
						hotelsNumber={selectedHotels.length}
					/>
				))}
			</div>
		</div>
	);
}
const ItemTypes = {
	CARD: "BOX",
};

const HotelCard = memo(
	({ moveCard, findCard, hotel, index, unselectHotel, hotelsNumber }) => {
		const { id } = hotel;
		const hotelRating = +hotel?.rating > 5 ? 5 : +hotel?.rating;

		const originalIndex = index;
		const [{ isDragging }, drag] = useDrag(
			() => ({
				type: ItemTypes.CARD,
				item: { id, originalIndex },
				collect: (monitor) => ({
					isDragging: monitor.isDragging(),
				}),
				end: (item, monitor) => {
					const { id: droppedId, originalIndex } = item;
					const didDrop = monitor.didDrop();
					if (!didDrop) {
						moveCard(droppedId, originalIndex);
					}
				},
			}),
			[id, originalIndex, moveCard]
		);

		const [, drop] = useDrop(
			() => ({
				accept: ItemTypes.CARD,
				hover({ id: draggedId }) {
					if (draggedId !== id) {
						moveCard(draggedId, index);
					}
				},
			}),
			[findCard, moveCard]
		);

		const opacity = isDragging ? 0 : 1;
		return (
			<div ref={(node) => drag(drop(node))} style={{ opacity }}>
				<div className="download-sort-card">
					<DragIcon width={40} height={40} className="drag-item-icon" />
					<div className="d-flex  sort-card-content">
						<div
							style={{
								backgroundImage: `url(${hotel.main_image})`,
							}}
							className="sort-card-img"
						/>
						<div className="bg-light p-2 flex-fill ">
							<div className="d-flex">
								{Array(hotelRating)
									.fill(2)
									.map((i, index) => (
										<i key={i + index + ""} className="fas fa-star"></i>
									))}
							</div>
							<p className="bolder">{hotel?.name}</p>
							<p className="light">
								<i className="fas fa-map-marker-alt mr-1"></i> {hotel?.address}
							</p>
							<i className="fas fa-globe-africa mr-1"></i> {hotel?.city_name}
						</div>
					</div>
					<div>
						{hotelsNumber === 1 ? (
							<i className="fas fa-trash-alt"></i>
						) : (
							<i
							className="fas fa-trash-alt text-danger"
								onClick={() => unselectHotel(hotel.id)}
								role="button"
							></i>
						)}
					</div>
				</div>
			</div>
		);
	}
);
