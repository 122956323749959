import noHotel from "assets/images/placeHoleder.png";
import { useEffect, useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import { useHistory, useLocation } from "react-router-dom";
import Carousel from "react-spring-3d-carousel";

import Locale from "translations";
export default function ViewBooking() {
	const { marketPlace, inventory } = Locale;
	const state = { tab: "hotel" };
	const [Silders, setSilders] = useState([]);
	const [active, setActive] = useState(0);
	const location = useLocation();
	const details = location.state.detail;

	let history = useHistory();

	useEffect(() => {
		let x =
			details?.hotel_data?.images && details?.hotel_data?.images?.length > 0
				? details.hotel_data.images.map((res, index) => {
					return {
						key: index + "uuid.v4(2)",
						content: (
							<img
								className="bg-white"
								src={res}
								alt={index + 1}
								onError={(e) => {
									e.target.onerror = null;
									e.target.src = noHotel;
								}}
							/>
						),
						onClick: () => setActive(index),
					};
				})
				: null;
		setSilders(x);
	}, []);

	return (
		<>
			<section className="marketplace-details  bg-white py-3 container">
				<div className="d-flex justify-content-between hotel-name">
					<div>
						{/* <h6 className="font-weight-bold dark-blue-color bg-white pt-3 text-headline">
							{details?.hotel_name}
						</h6> */}

						<div className="slider-headers mb-2">
							<h5 className="font-weight-bold text-headline">
								{state.tab === "hotel"
									? details.hotel_data.name
									: details.transporter_data.name}
							</h5>
							<div className="d-flex">
								<p>
									{state.tab === "hotel"
										? details.hotel_data.address
										: details.transporter_data.address}
								</p>
								<span className="px-2">|</span>
								{/* <p>
									{state.tab === "hotel"
										? details.hotel_data.distance + " m from Haram"
										: null}
								</p> */}
								<a
									href={`https://maps.google.com/?q=${details.hotel_data.latitude},${details.hotel_data.longitude}`}
									target="_blank"
									className="text-decor-none text-yellow"
									rel="noreferrer"
								>
									{" "}
									 {inventory.messages.ShowMap}{" "}
								</a>
							</div>

							<ul className="list-unstyled d-flex">
								{state.tab === "hotel"
									? [...Array(details.hotel_data.rating).keys()].map((i) => (
										<li>
											<i
												key={i}
												className="fa fa-star"
												aria-hidden="true"
											></i>
										</li>
									))
									: null}
							</ul>
						</div>
					</div>
					<div>
						<button
							className="mx-1 btn btn-white-border-gray "
							onClick={history.goBack}
						>
							{/* {marketPlace.messages.showInMap} */}
							<i className="fas fa-angle-left"></i> {inventory.messages.BackBookingDetails}
						</button>
					</div>
				</div>
				<hr className="hr my-2" />

				{/* <div className=""> */}
				<div className="">
					{Silders && Silders.length > 0 ? (
						<div
							style={{
								height: "300px",
								width: "100%",
								overflow: "hidden",
							}}
						>
							<Carousel slides={Silders} offsetRadius={2}  goToSlide={active} />
						</div>
					) : null}
				</div>
				
				{/* <!-- Start OverView --> */}

				<div className="rounded bg-overveiw p-1  mb-3">
					<h5 className=" font-weight-bold m-0 px-1 h5 text-dark text-uppercase my-2">
						{marketPlace.messages.overview}
					</h5>
					{details?.hotel_data?.description ||
						details?.transporter_data?.description ? (
						<p className="mb-1 rounded bg-overveiw px-1 ">
							{state.tab === "hotel"
								? details.hotel_data.description
								: details.transporter_data.description}
						</p>
					) : (
						<div className="my-4">
							<p className="text-center text-gray">
								<i className="fas fa-exclamation"></i> No Overview
							</p>
						</div>
					)}
				</div>

				{/* <!-- end OverView --> */}

				{/* <!--Start hotel Facilities--> */}
				{state.tab === "hotel" ? (
					<div className="rounded p-1 bg-overveiw mb-3 ">
						<h5 className=" font-weight-bold m-0 px-1 h5  text-dark text-uppercase my-2">
							{marketPlace.messages.hotelFacility}
						</h5>
						{state.tab === "hotel" &&
							details.hotel_data.facilities.length > 0 ? (
							details.hotel_data.facilities.map((facility, i) => {
								return (
									<ul className="row m-0 p-0">
										<li className="col-4" key={i}>
											<i className="fas fa-check px-1 text-gray"></i>
											{facility}
										</li>
									</ul>
								);
							})
						) : (
							<div className="my-4">
								<p className="text-center text-gray">
										<i className="fas fa-exclamation"></i>{" "}
									{inventory.messages.noFacilities}
								</p>
							</div>
						)}
					</div>
				) : null}
			</section>
		</>
	);
}
