import { useWebBuilderState } from "context/webBuilder";
import { useState } from "react";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting.svg";
import { ReactComponent as PhoneIcon } from "assets/images/webBuilder/call.svg";
import { ReactComponent as EnvlopIcon } from "assets/images/webBuilder/sms.svg";
// import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { EditBox } from "modules/WebBuilder-V2/shared/EditBox";
import SocialMediaModal from "./SocialMediaModal";
import { isObject } from "lodash"
import Locale from "translations";
import EditableParagraph from "modules/WebBuilder-V2/shared/EditableParagraph";
import useWBFooter from "./useWBFooter";


const URI = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";


export default function FooterThemeTen({ focusContainer, handleFocus, socialMediaIcons }) {
	const { webBuilder } = Locale;
	const { footer: footerDetails, hero, selectedLanguage, ourServices, business_email } = useWebBuilderState();
	const type = "footer";
	const [socialMediaModal, setSocialMediaModal] = useState(false);
	const { updateFooterText } = useWBFooter();
	const services = ourServices?.services?.filter(service => service?.visible);


	// const services = ourServices?.services?.filter(service => service?.visible);
	const header = hero?.header;
	const logoImage = isObject(header?.logo) ?
		<img src={`${header?.logo[selectedLanguage].includes("http") ? header?.logo[selectedLanguage] : URI + "/" + header?.logo[selectedLanguage]}`} alt="Logo" />
		:
		<img src={`${header?.logo[selectedLanguage]}`} alt="Logo" />;


	function toggleSocialMediaModal() {
		setSocialMediaModal(!socialMediaModal);
	}


	const scrollToSection = (id) => {
		if (id) {
			const element = document.getElementById(id)
			element?.scrollIntoView({ behavior: "smooth" })
		}
	}

	const containerBoxActions = [
		<span onClick={toggleSocialMediaModal} className="pointer">
			<SettingIcon />
		</span>,
	];


	return (
		<div className="container">
			<div className="footer-content">
				{/* right section */}
				<div className="footer-main-data">

					<div className="logo-container">
						{logoImage}
					</div>

					<div className="footer-text">
						<EditableParagraph
							className="body text-primary-web-builder"
							contentEditable={true}
							onInput={(value) => {
								updateFooterText({ name: "footerText", value, language: selectedLanguage });
							}}
							text={footerDetails?.footerText[selectedLanguage]}
							preventTranslate={false}
						/>
					</div>

					<div className="social-media" onClick={() => handleFocus(type)}>
						<div>
							<EditBox
								editMode={focusContainer === type}
								defaultIcon={containerBoxActions}
							>
								<ul className="links">
									{footerDetails?.links?.map((link) => {
										if (link?.visible) {
											return (
												<div key={link?.id}>
													{/* <a href={link?.url} target="_blank" rel="noreferrer"> */}
													{socialMediaIcons[link?.id]}
													{/* </a> */}
												</div>
											);
										} else {
											return null;
										}
									})}
								</ul>
							</EditBox>
						</div>
					</div>
				</div>


				<ul className="links-list">
					<span className="footer-title">Info</span>
					{services && services.length > 0 && services?.map(service => {
						return (
							<li className="pointer"
								onClick={() => scrollToSection(service?.id)}
							>
								<span> {webBuilder[service?.type]}</span>
							</li>
						)
					})}
				</ul>

				<div className="contact-info">
					<span className="footer-title">CONTACT US</span>
					<ul className="d-flex flex-column gap-24">
						{header?.phoneNumber && header?.phoneNumber?.[0] &&
							<li>
								<PhoneIcon />
								<span className="mx-2">{header?.phoneNumber?.[0]}</span>
							</li>
						}
						{business_email &&
							<li>
								<EnvlopIcon />
								<span className="mx-2">{business_email}</span>
							</li>
						}
						{/* <li>
							<LocationIcon />
							<span className="mx-2">{header?.phoneNumber?.[0]}</span>
						</li> */}
					</ul>
				</div>
			</div>


			{socialMediaModal && footerDetails?.links ? (
				<SocialMediaModal
					isOpen={socialMediaModal}
					toggle={toggleSocialMediaModal}
					header={"Social links"}
					socialMediaLinks={footerDetails?.links}
				/>
			) : null}
		</div>
	)
}