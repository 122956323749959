export const VoucherPrinte = `
<style>
  	* {
		font-family: "Cairo", sans-serif;
		box-sizing: border-box;
		line-height:1.2
	}
  p{
    margin: 0;
  }
  .h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 2px;
    margin-top: 0;
	}
	.bg-white {
		background-color: #fff !important;
	}
	.p-4 {
		padding: 1.5rem !important;
	}
  	.d-flex {
		display: flex !important;
	}
	.mt-3,
	.my-3 {
		margin-top: 1rem !important;
	}
	.voucher-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
    margin-bottom: 1em;
	}
	.voucher-header .tap {
		background-color: #cb9a51;
    padding: 12px 20px;
    font-size: 20px;
    font-weight: bold;
    color: white;
	}
	.voucher-header h3 {
		font-weight: bold;
	}

	.voucher-details-container {
		border: 12px solid #dddddd;
	}
	.start-section .hotel-details {
		border-bottom: 12px solid #dddddd;
		padding: 16px;
	}

	.start-section {
		flex: 1 1 75%;
		border-inline-end: 12px solid #dddddd;
	}
	.end-section {
		flex: 1 1 25%;
		padding: 2em 1em;
	}

	.start-section .hotel-details div {
		margin-bottom: 8px;
	}

	.upper-section {
		display: flex;
	}

	.end-section {
		flex: 1 1 25%;
	}
	.hotel-key {
		color: #767676;
	}
	.hotel-value {
		color: #1a1a1a;
	}

	.booking-details div {
		flex: 1 1 auto;
		padding: 16px 12px;
	}

	.booking-details {
		display: flex;
	}
	.hotel-check {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 2px;
	}
	.hotel-checkout {
		border-inline: 12px solid #dddddd;
	}
	.date {
		font-size: 18px;
		font-weight: bold;
		text-align:center
	}

	.hotel-head {
		color: #767676;
		font-size: 19px;
		font-weight: bold;
	}
	.last-hotel-detail {
		flex: 1 1 35% !important;
	}
	.title {
		color: #767676;
    margin-bottom: .75em;
	}
	.status {
		color: #28a745;
	}
	.guest-info {
		border-block-start: 12px solid #dddddd;
		padding: 16px;
	}
	.bottom-section {
		border-block-start: 12px solid #dddddd;
		padding: 16px;
	}
	.guest-container {
		display: flex;
	}
	.guest-container div {
		flex: 1 1 auto;
	}
	.guest-container h5 {
		color: #767676;
		margin-bottom: .5em;
	}
</style>

<div class="voucher container bg-white p-4" style="
zoom: 80%;
">
	<div class="voucher-header">
		<div class="tap">[SolutionsCompany]</div>
		<h1 style="
    margin-bottom: -14px;
">Hotel Voucher</h1>
	</div>

	<div class="voucher-details-container">
		<div class="upper-section">
			<div class="start-section">
				<div class="hotel-details">
					<div>
						<span class="hotel-key">[messages_bookingReference]: </span>
						<span class="hotel-value">[bookingCode]</span>
					</div>
					<div>
						<span class="hotel-key">[messages_prented_on]: </span>
						<span class="hotel-value">[prented_on]</span>
					</div>
					<div>
						<span class="hotel-key">[messages_confirmationNO]:</span>
						<span class="hotel-value">[confirmationNumber]</span>
					</div>

					<div class="d-flex mt-3" style="margin-bottom:4px">
						<h2>[hotel_name]</h2>
						<p style="
						margin-left: 8px;margin-top:1px
				">[rate_value]</p>
					</div>
					<div>
						<span class="hotel-key">[messages_address] :</span>
						<span class="hotel-key">[hotel_address]</span>
					</div>
					[hotel_phone]
				</div>

				<div class="booking-details">
					<div class="hotel-check">
						<p class="hotel-head">[messages_checkin]</p>
						<small>[checkin_day], </small>
						<p class="date">[checkin]</p>
					</div>
					<div class="hotel-check hotel-checkout">
						<p class="hotel-head">[messages_checkOut]</p>
						<small>[checkout_day], </small>
						<p class="date">[checkout]</p>
					</div>
					<div class="last-hotel-detail">
						<p class="hotel-head">[booking_details]</p>

						<small style="
						display: block;
						margin-top: 5px;
				">[roomType]</small>
						<p>[roomCategory]</p>
						<p>[rateBasis]</p>
					</div>
				</div>

				<div class="guest-info">
					<p class="title">Guest Information</p>

					<div class="guest-container">
						<div>
							<h5>[messages_passengerName] </h5>
							<p>1. [passengerName]</p>
						</div>
						<div>
							<h5>[messages_passengerNationality] </h5>
							<p>[passengerNationality]</p>
						</div>
						<div>
							<h5 style="
							text-transform: capitalize;
					">[messages_CountryResidence] </h5>
							<p>[passengerCountry]</p>
						</div>
					</div>
				</div>
			</div>
			<div class="end-section">
				<p class="hotel-key" style="
				margin-bottom: 4px;
		">[messages_bookedBy]</p>
				<h3 style="
				color: #535353;
		">[bookedBy]</h3>

				<h1 class="mt-2 status" style="
				text-transform: uppercase;
		">[bookingStatus]</h1>
			</div>
		</div>
		<div class="bottom-section">
			<p class="title">[messages_additionalRequests]</p>
			<p class="">[additionalRequests]</p>

		</div>
		<div class="bottom-section">
			<p class="title">[messages_accommodation]</p>
			<p class="">[accommodation_value]</p>

		</div>
	</div>
</div>


`
