import React, { useState } from 'react'
import moment from 'moment/moment'
import { Collapse } from "reactstrap";
import { ItinirareyDirectPlaneIcon, ItinirareyPlaneIcon } from 'modules/market-place/PackageResponse/Details/shared/PackageIcon';


export default function ItineraryExternalTransportation({ type, isMobile, element }) {
  const [opened, setopened] = useState(false)
  const handelcollapse = () => {
    setopened(!opened)
  }
  return (<>
    <div className="boxcollapse">
      <div className="closecollapse">
        <div className={`collapseIcon ${opened && "opencoll"}`}>
          <ItinirareyPlaneIcon />
        </div>
        {isMobile &&
          <p style={{ color: "##0F3E5E", fontWeight: "bold", marginRight: "auto", paddingLeft: "5px" }}>
            External Transportation
          </p>
        }
        {/* show all item info */}
        {!isMobile &&
          <>
            <div className={"infoPackage"}>
              <div className='d-flex wrap'>
                <p style={{ color: "#0F3E5E" }}>{element?.itemable?.from_port?.name} </p>
                {type === 'hotel' &&
                  <div className="hotel-star-rate d-inline px-2">
                    {[...Array(4).keys()].map((i) => (
                      <i key={i} className="fa fa-star" aria-hidden="true"></i>
                    ))}
                  </div>
                }
              </div>
              <span style={{ color: "#2D2D2D" }}>{element?.itemable?.from_country?.name}</span>
              <span style={{ color: "##2D2D2D" }}>{moment(element?.itemable?.arrival_at).format("DD/MM/YYYY")}</span>
            </div>

            <div className="stepsinfo">
              <div className={"point"}></div>
              <span className={"stepsPackage"}>
                {element?.itemable?.transits?.length > 0 ? `${element?.itemable?.transits?.length} Stop` : "Direct"}
              </span>
              <div className={"airplaneicon"}>
                <ItinirareyDirectPlaneIcon />
              </div>
            </div>
            <div className='d-flex flex-row align-items-center'>
              <div className="infoPackage">
                <p style={{ color: "#0F3E5E" }} >{element?.itemable?.to_port?.name}</p>
                <span style={{ color: "#2D2D2D" }} >{element?.itemable?.to_country?.name}</span>
                <span style={{ color: "##2D2D2D" }} > {moment(element?.itemable?.departure_at).format("DD/MM/YYYY")} </span>
              </div>

            </div>
          </>
        }
        <div>
          <button onClick={handelcollapse} className='btn '>
            <i className="fas fa-chevron-down fa-1x text-grey"></i>
          </button>
        </div>
      </div>
      <Collapse isOpen={opened}>
        <div className={"opendcollapse"}>
          <div className={"airstep"}>
            <div className={"imgscompany"}>
              <img
                src={element?.itemable?.transporter?.logo}
                alt=''
              />
              <p>{element?.itemable?.transporter?.name}</p>
            </div>
            <div className={"tripdetails"}>
              <div>
                <p style={{ color: "#0F3E5E" }}>{element?.itemable?.from_port?.name}</p>
                <span style={{ color: "#2D2D2D" }}>{moment(element?.itemable?.departure_at).format("MMM Do YYYY, h:mm A ,ddd")}</span>
              </div>
              <div>
                <span className={"triptime"}> - </span>
              </div>
              <div>
                <p style={{ color: "#0F3E5E" }}>{element?.itemable?.to_port?.name}</p>
                <span style={{ color: "#2D2D2D" }}>{moment(element?.itemable?.arrival_at).format("MMM Do YYYY, h:mm A ,ddd")}</span>
              </div>
            </div>
          </div>

          {element?.itemable?.transits?.map((transit, index) => {
            return (
              <div className={"airstep"} key={index}>
                <p className={"transite"}>
                  Stop
                  <span className='d-inline-block mx-1' style={{ color: "#0372BC" }}>{transit?.city?.name}</span>
                </p>
                <div>
                  <img
                    src={transit?.transporter?.logo}
                    alt=''
                  />
                  <p>{transit?.transporter?.name}</p>
                </div>
                <div className={"tripdetails"}>
                  <div>
                    <p style={{ color: "#0F3E5E" }}>{transit?.departure_port?.name}</p>
                    <p stylr={{ color: "#2D2D2D" }}> {moment(transit?.departure_at).format("MMM Do YYYY, h:mm A ,ddd")}
                    </p>
                  </div>
                  <div>
                    <span className={"triptime"}> - </span>
                  </div>
                  <div>
                    <p style={{ color: "#0F3E5E" }}>{transit?.arrival_port?.name}</p>
                    <span style={{ color: "#2D2D2D" }}>
                      {moment(transit?.arrival_at).format("MMM Do YYYY, h:mm A ,ddd")}
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </Collapse>
    </div>

  </>
  )
}