export const B2BReservationVoucher = `

  <style>
    *,
    *::after,
    *::before {
      box-sizing: border-box;
    }

    * {
      margin: 0;
      padding: 0;
      font: inherit;
    }

    @page {
      size: A4;
      margin: 15px;
    }

    img,
    svg {
      display: block;
      max-width: 100%;
    }

    body {
      min-height: 100%;
    }

    html,
    body,
    div,
    dl,
    dt,
    dd,
    ul,
    ol,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    pre,
    code,
    form,
    fieldset,
    legend,
    input,
    textarea,
    p,
    blockquote,
    th,
    td {
      margin: 0;
      padding: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 100%;
      font-weight: normal;
    }


    * {
      font-family: 'Cairo', sans-serif;
      box-sizing: border-box;
    }

    .img-fluid {
      max-width: 100%;
      height: auto;
    }

    .justify-content-between {
      justify-content: space-between !important;
    }

    table {
      border-collapse: collapse;
    }

    td {
      border: 1px solid #0c3b5c;
      padding: 0.5rem;
    }

    th {
      border: 1px solid #0c3b5c;
      padding: 0.5rem;
    }

    thead th {
      border: 1px solid #0c3b5c;
      padding: 0.8rem;
    }

    tr:first-child td {
      border-top: 0;
    }

    tr td:first-child {
      border-left: 0;
    }

    tr td:last-child {
      border-right: 0;
    }

    tr th:first-child {
      border-left: 0;
    }

    tr th:last-child {
      border-right: 0;
    }

    .last-td-border {
      border: 0 !important;
      font-size: 12px;
      border-bottom: 1px solid #0c3b5c !important;
    }

    .remove-last-td-border {
      border-bottom: 0 !important;
      border-top: 0 !important;
      font-size: 12px;
    }




    .container-wrapper {
      height: 95vh !important;
    }

    .p-5 {
      padding: 2rem;
    }

    .d-flex {
      display: flex !important;
    }

    .justify-content-center {
      justify-content: center !important;
    }

    .flex-column {
      flex-direction: column;
    }

    .fw-600 {
      font-weight: 600;
    }

    .fw-700 {
      font-weight: 700;
    }



    .wrapper {
      display: flex;
      justify-content: center;
      padding: 1rem;
      color: #050001;
    }

    .operation-invoice {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 0px 16px 10px 16px;
    }

    .operation-invoice-logo {
      width: 85px;
      height: 65px;
      object-fit: contain;
    }

    .operation-invoice-heading {
      font-size: 20px;
      text-align: center;
      font-weight: 700;
      margin-bottom: 16px;
    }

    table {
        page-break-inside: avoid;
        page-break-after: auto;
    }
    thead th {
      color: #707170;
      font-weight: 500;
      background-color: #f7f6f6;
    }

    thead tr th,
    tr td {
      border: 1px solid #DBDADE !important;
      text-align: center;

    }

    tr {
      border: 1px solid #dbdade;
      font-weight: 600;
      page-break-inside: avoid;
      page-break-after: auto;
    }

    tbody tr {
      background-color: #fff;
    }




    .gap-10 {
      gap: 10px;
    }

    .residence-details {
      border-top: 1px solid #DBDADE;
      width: 100%
    }

    .residence-details-col {
      display: flex;
      align-items: center;
      width: 25%;
      text-align: center;

    }

    .residence-details-col div {
      width: 100%;
      padding: 0.8rem;
    }

    .col-head {
      background-color: #F7F6F6;
      border-width: 0 1px 1px;
      border-style: solid;
      border-color: #DBDADE;
      color: #707170;
      font-weight: 500;
    }

    .residence-data {
      border-width: 0 1px 1px;
      border-style: solid;
      border-color: #DBDADE;
      font-weight: 600;
    }

    .total-required {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      color: #050001;
      font-weight: 700;
      background-color: #FAF4EB;
      border: 1px solid #D4A655
    }

    .program-otherservices {
      display: flex;
      gap: 8px;
    }

    .program-otherservices table {
      width: calc(50% - 4px);
    }

    .bg-gray-300 {
      background-color: #F7F6F6;
    }

    .bg-white {
      background-color: #fff;
    }

    .text-color-grey {
      color: #707170;
    }

    .reservation-metadata {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      line-height: 26.24px;
    }
    
  </style>
</head>

<body>
  <div class="wrapper">
    <div class="operation-invoice">
      <img class="operation-invoice-logo" src=[print_logo] alt="hotal-logo" width="300px" height="140px" />

      <h3 class="operation-invoice-heading">Reservation form 1446/2024</h3>

      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column">
          <div class="reservation-metadata">
            <p class="text-color-grey">Date: </p>
            <p class=>[today_date]</p>
          </div>
          <div class="reservation-metadata">
            <p class="text-color-grey">Client: </p>
            <p class=""> [client_name]</p>
          </div>
          <div class="reservation-metadata">
            <p class="text-color-grey">Agent: </p>
            <p class=""> [agent_name]</p>
          </div>
        </div>


        <div class="d-flex flex-column">
          <div class="reservation-metadata">
            <p class="text-color-grey">Form No.: </p>
            <p class="">[from_no]</p>
          </div>
          <div class="reservation-metadata">
            <p class="text-color-grey">Phone: </p>
            <p class="">[leader_phone]</p>
          </div>
          <div class="reservation-metadata">
            <p class="text-color-grey"> Phone: </p>
            <p class="">[user_phone]</p>
          </div>
        </div>
      </div>

      <table>
        <thead>
          <th class="text-center" colspan="3">Pilgrims Names</th>
        </thead>
        <tbody>
          <tr>
            [travelers_names]
        </tbody>
      </table>

      <table>
        <thead>
          <th class="text-center">Individuals Number</th>
          <th class="text-center">Adult</th>
          <th class="text-center">Child</th>
          <th class="text-center">Infant</th>
          <th class="text-center">Total</th>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>[adult_count]</td>
            <td>[child_count]</td>
            <td>[infant_count]</td>
            <td>[total_pax]</td>
          </tr>
        </tbody>
      </table>

      <table>
        <thead>
          <th>Trip Date</th>
          <th>Route</th>
          <th>Departure</th>
          <th>Return</th>
          <th>Nights</th>
        </thead>
        <tbody>
          <tr>
            <td>[trip_date]</td>
            <td width="40%">[route]</td>
            <td>[departure_date]</td>
            <td>[return_date]</td>
            <td>[nights_count]</td>
          </tr>
        </tbody>
      </table>

      <table>
        <thead>
          <th>Residence Details</th>
          <th>Hotel</th>
          <th>Nights</th>
          <th>Breakfast</th>
        </thead>
        <tbody>
          [hotels_data]
        </tbody>
      </table>

      <!-- Transportation Details -->
      <table>
        <thead>
          <th colspan="1">Transportation Details</th>
          <th colspan="2" class="bg-white">[transportation_company]</th>
          <th colspan="2" class="bg-white">Cycle type [cycle_type]</th>
        </thead>
        <thead>
          <th>Date</th>
          <th>From</th>
          <th>Destination</th>
          <th>Pick-up time</th>
        </thead>
        <tbody>
          [transportation_data]
        </tbody>
      </table>

      <!-- visa details -->
      <table>
        <thead>
          <th>Visa Details</th>
          <th>Adults</th>
          <th>Child</th>
          <th>Infant</th>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>[visa_adults_count]</td>
            <td>[visa_childs_count]</td>
            <td>[visa_infant_count]</td>
          </tr>
        </tbody>
      </table>

      <div class="program-otherservices">
        <table>
          <thead>
            <th>Full Program</th>
            <th>Adult</th>
            <th>Child</th>
          </thead>
          <tbody>
            [rooms_data]
          </tbody>
        </table>

        <table>
          <thead>
            <th>Other Services</th>
            <th>Individuals</th>
          </thead>
          <tbody>
            [extra_services]
          </tbody>
        </table>
      </div>



      <div class="total-required">
        <span>Total required from the client</span>
        <span>[payments_outstanding]</span>
      </div>

      <table>
        <thead>
          <th>Reservation value</th>
          <th>First batch</th>
          <th>Date</th>
          <th>Second batch</th>
          <th>Date</th>
          <th>Reminder</th>
        </thead>
        <tbody>
          <tr>
            <td>-</td>
            <td>[first_patch]</td>
            <td>[expire_at_date]</td>
            <td>-</td>
            <td>-</td>
            <td>[reminder]</td>
          </tr>
        </tbody>
      </table>


    </div>
  </div>
</body>
`