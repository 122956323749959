import HotelPlaceHolder from "assets/images/new-hotels.svg";
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import NumberField from "components/Form/NumberField/NumberField";
import SelectField from "components/Form/SelectField/SelectField";
import { useSBSDispatch, useSBSState } from "context/global";
import { useMarketplaceDispatch } from "context/marketplace";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { fetchCitiesHotelsSearch, fetchHotels } from "services/marketplace";
import Locale from "translations";
import CityModal from "./CityModal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SearchIcon from 'modules/market-place/NewUI/icons/searchIcon';

export default function Individual({ allCountries, CurrenciesData }) {
	const { status } = useParams();
	const dispatch = useMarketplaceDispatch();
	const dispatchSBS = useSBSDispatch();
	const history = useHistory();
	const { marketPlace, messages } = Locale;
	const { locale } = useSBSState();
	const [errors, setErrors] = useState({ rooms: [] });
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [showMore, setShowMore] = useState(false);

	const [listAuto, setListAuto] = useState([]);
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const countries = await fetchCitiesHotelsSearch(
				"is_vacation",
				inputValue,
				status == "umrah" ? "966" : ""
			);

			let result = countries.map((item) => {
				if (item.type === "city") {
					return {
						...item,
						id: item.id,
						name: item.name + " - " + item.country.name[locale],
						value: item.id,
						label: item.name + " - " + item.country.name[locale],
					};
				} else if (item.type === "hotel") {
					return {
						...item,
						id: item.id,
						country: {
							flag: HotelPlaceHolder,
						},
						name: item.name + " - " + item.city.name[locale],
						value: item.id,
						label: item.name + " - " + item.city.name[locale],
					};
				}
			});
			setListAuto(result);
		}
	};

	const [hotel, setHotel] = useState({
		checkIn: moment(new Date()).add(1, "d"),
		checkOut: moment(new Date()).add(2, "d"),
		goingTo: null,
		adult: 1,
		child: 0,
		roomType: null,
		rooms: [{ number_of_adult: null, BoxChild: false, children: [] }],
	});

	let count =
		hotel?.rooms && hotel?.rooms.length > 0
			? hotel?.rooms.filter((res) => res.BoxChild).length
			: 0;
	const closeAllTaps = () => {
		let list = hotel.rooms.map((res) => {
			return { ...res, BoxChild: false };
		});
		setHotel({ ...hotel, rooms: list });
	};

	const DetectClickOutside = useDetectClickOutside({
		onTriggered: count > 0 ? closeAllTaps : null,
	});

	const setRoomsCount = (name, index, value) => {
		let x = hotel.rooms && hotel.rooms.length > 0 ? hotel.rooms : [];
		x[index] = { ...x[index], [name]: value };
		if (name === "roomLength") {
			let rooms = [...Array(+value).keys()].map((res) => ({
				...res,
				children: [],
			}));
			x[index] = { ...x[index], roomsList: rooms };
		}
		setHotel({ ...hotel, rooms: x });
	};

	const setChildrenCount = (name, index, value) => {
		let x = hotel.rooms;
		let rooms = [...Array(+value).keys()].map((res) => ({
			...res,
			age: "",
		}));
		let roomsList = x[index].roomsList;

		if (roomsList && roomsList.length > 0) {
			roomsList.map((res) => {
				res.children = rooms;
			});
		}
		x[index] = { ...x[index], [name]: rooms };
		setHotel({ ...hotel, rooms: x });
	};

	const setAge = (index, DD, XX, value) => {
		let x = hotel.rooms;
		let c = x[index].roomsList[DD].children;
		const newArr = c.map((ress, indexs) => {
			if (indexs == XX) {
				return { ...ress, age: +value };
			}
			return { ...ress };
		});
		x[index].roomsList[DD].children = newArr;
		setHotel({ ...hotel, rooms: x });
	};

	const handleChange = (e, flag = false) => {
		const name = e.target?.name;
		const value = e.target?.value;
		if (flag) {
			setHotel({
				...hotel,
				country: e["value"], // countries[value],
				goingTo: e.value, //countries[value]?.code,
			});
		} else setHotel({ ...hotel, [name]: value });
	};

	function checkFormErrors() {
		let roomError = [];
		hotel.rooms.map((res) => {
			let newError = {
				...validate(
					{ name: "roomLength", value: res.roomLength },
					{ required: true }
				),
				...validate(
					{ name: "number_of_adult", value: res.number_of_adult },
					{ required: true }
				),
			};
			roomError.push(newError);
		});
		setErrors({
			...errors,
			rooms: roomError,
			...validate(
				{ name: "residence", value: hotel.residence },
				{ required: true }
			),
			...validate(
				{ name: "nationality", value: hotel.nationality },
				{ required: true }
			),
			// ...validate(
			// 	{ name: "currency_code", value: hotel.currency_code },
			// 	{ required: true }
			// ),
			...validate(
				{ name: "goingTo", value: hotel.goingTo },
				{ required: true }
			),
			...validate(
				{ name: "checkIn", value: hotel.checkIn },
				{ required: true }
			),
			...validate(
				{ name: "checkOut", value: hotel.checkOut },
				{ required: true }
			),
			...validate(
				{ name: "checkOut", value: hotel.checkOut },
				{ required: true }
			),
		});
	}

	const handleSearchCheck = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	let goingToPrevValue = useRef(null);
	function clearSearchText(e) {
		goingToPrevValue.current = hotel.goingTo;
		const value = e.target.value;
		if (value.length > 0) {
			setHotel({ ...hotel, goingTo: null });
		}
	}

	useEffect(() => {
		if (Object.values(errors).length > 1) {
			const flag = errors.rooms.every((error) => {
				if (!isFormValid(error)) {
					return false;
				}
				return true;
			});

			if (isFormValid(errors) && flag) {
				const handleSearch = async () => {
					let children = [];
					let rooms_data = hotel?.rooms.map((resa) => {
						resa?.roomsList?.map((res) => {
							children.push({
								number_of_adult: +resa.number_of_adult,
								children: [...res.children],
							});
						});
						return children;
					});
					let data = {
						is_umrah: 0,
						is_vacation: 1,
						special_code: "",
						country: hotel.country?.value,
						city_code: hotel.goingTo?.value,
						date_from: moment(hotel.checkIn).format("YYYY-MM-DD"),
						date_to: moment(hotel.checkOut).format("YYYY-MM-DD"),
						nationality: hotel?.nationality?.code,
						residency: hotel?.residence?.code,
						lang: locale,
						rooms_data: children,
						// currency_code: hotel?.currency_code?.value,
						page: 0,
					};

					if (hotel.goingTo.type === "hotel") {
						data = {
							...data,
							search_filters: { hotel_ids: [hotel.goingTo.id] },
							city_code: hotel.goingTo.city_id,
						};
					}

					localStorage.setItem("hotelOffer", 0);

					const response = await fetchHotels(data);
					if (response.status === 200) {
						if (response.data.data.length > 0) {
							// TO DO fix DTO
							dispatch({
								type: "newSearch",
								payload: {
									searchResults: response.data,
									hotelFilters: data,
									priceRanges: {
										min: response.data.filter_data.price.min_price,
										max: response.data.filter_data.price.max_price,
									},
									filters: hotel,
									searchBy: "hotel",
								},
							});

							dispatchSBS({ type: "searchOfferClear", payload: "" });

							history.push(`/${status}/market-view`);
						} else {
							store.addNotification({
								title: messages.noResults,
								message: messages.noSearchResults,
								type: "danger",
								insert: "top",
								container: "top-right",
								animationIn: ["animated", "fadeIn"],
								animationOut: ["animated", "fadeOut"],
								dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
							});
						}
					}
				};
				handleSearch();
			}
		}
	}, [isErrorLoaded]);

	useEffect(() => {
		setRoomsCount("roomLength", 0, 1);
		setRoomsCount("number_of_adult", 0, 2);
	}, []);

	const onSubmitCityModal = (destination) => {
		setHotel((prev) => ({
			...prev,
			country: destination.hotel, // countries[value],
			goingTo: destination.hotel, //countries[value]?.code,
		}));
	};

	const [matches, setMatches] = useState(
		window.matchMedia('(max-width: 600px)').matches
	)

	useEffect(() => {
		window
			.matchMedia('(max-width: 600px)')
			.addEventListener('change', (e) => setMatches(e.matches))
	}, [])



	return (
		<div className="col-md-12 HotelSearch HotelSearchServices  m-auto">
			<div className="row m-0 p-0">
				<div className="col-md-6 p-0">
					{showMore && (
						<CityModal
							isOpen={showMore}
							setIsOpen={setShowMore}
							onSubmit={onSubmitCityModal}
						/>
					)}
					{/* destination */}
					<div
						className="col-md-12 col-12"
						style={{ position: "relative" }}
						id="hotel-vacations-individual-destination"
					>
							<div className='form-group p-m-p'>
						{matches && (
							<label className='label-m-color'>
								{marketPlace.messages.Destination}
							</label>
						)}

						<AutoCompleteField
							hasLabel={false}
							labelInner={true}
							flag={hotel.goingTo?.country?.flag}
							listAuto={listAuto}
							setListAuto={setListAuto}
							getListAuto={getListAuto}
							label={!matches && marketPlace.messages.destination}
							isSearchable={true}
							placeholder={marketPlace.messages.destination}
							value={hotel.goingTo?.name || ""}
							onFocus={clearSearchText}
							onBlur={() =>
								setHotel({ ...hotel, goingTo: goingToPrevValue.current })
							}
							onChange={(e) =>
								handleChange({ name: "goingTo", value: { name: e } }, true)
							}
							onSelectValue={(e) => {
								handleChange({ name: "goingTo", value: e }, true);
								setErrors({
									...errors,
									...validate(
										{ name: "goingTo", value: e },
										{ required: true }
									),
								});
							}}
							color={errors?.goingTo?.required ? "danger" : ""}
							errors={errors?.goingTo}
							showMore={status == "umrah" ? false : () => setShowMore(true)}
						/>
						</div>
					</div>
				</div>
				{/* checkin */}
				<div className="col-md-3 col-sm-6 col-6" id="hotel-vacations-individual-checkin">
				<div className='form-group p-m-p'>
					{matches && (
								<label className='label-m-color'>
									{marketPlace.messages.checkIn}
								</label>
							)}

						<DatePickerField
							hasLabel={false}
							labelInner={true}
							label={!matches && marketPlace.messages.checkIn}
							date={hotel.checkIn}
							onChangeDate={(date) =>
								setHotel({
									...hotel,
									checkIn: date,
									checkOut: moment(hotel.checkOut).isAfter(
										moment(date).add(1, "d")
									)
										? hotel.checkOut
										: moment(date).add(1, "d"),
								})
							}
							onBlur={() =>
								setErrors({
									...errors,
									...validate(
										{ name: "checkIn", value: hotel.checkIn },
										{ required: true }
									),
								})
							}
							color={errors?.checkIn?.required ? "danger" : ""}
							errors={errors?.checkIn}
						/>
					</div>
				</div>
				{/* checkout */}
				<div className="col-md-3 col-sm-6 col-6" id="hotel-vacations-individual-checkout">
					<div className="form-group p-m-p">
					{matches && (
							<label className='label-m-color'>
								{marketPlace.messages.checkOut}
							</label>
						)}

						<DatePickerField
							hasLabel={false}
							labelInner={true}
							label={!matches && marketPlace.messages.checkOut}
							date={
								hotel.checkOut === null
									? moment(hotel.checkIn).add(1, "d")
									: hotel.checkOut
							}
							onChangeDate={(date) => setHotel({ ...hotel, checkOut: date })}
							onBlur={() =>
								setErrors({
									...errors,
									...validate(
										{ name: "checkOut", value: hotel.checkOut },
										{ required: true }
									),
								})
							}
							color={errors?.checkOut?.required ? "danger" : ""}
							errors={errors?.checkOut}
						/>
					</div>
				</div>
				{/*  rooms  */}

				<div
					className="col-md-12  bg-Rooms py-3 mt-2"
					id="hotel-vacations-individual-rooms"
				>
					<div className=" product-build__product-collpase branch-collapse my-0 mb-2">
						{/* rooms */}
						<div className="row">
							{hotel.rooms && hotel.rooms.length > 0
								? hotel.rooms.map((res, index) => {
										return (
											<>
												<div className="col-6" style={{ padding: "0 15px" }}>
													<div className="button-collapse my-2">
														<div className="title-style-container">
															<div
																className="w-100"
																// onClick={() =>
																// 	setRoomsCount(
																// 		"BoxChild",
																// 		index,
																// 		!res.BoxChild
																// 	)
																// }
															>
																<div className="w-100">
																	<div className="global-input font-weight-bold border-0  d-flex justify-content-between">
																		<div onClick={()=>setRoomsCount(
																			"BoxChild",
																			index,
																			!res.BoxChild
																		)} className='test258'>
																			<span className="roomsNumber">
																				{res.roomLength} {"  "}
																				{marketPlace.messages.room}
																			</span>
																			<span className="adultNumber">
																				({res.number_of_adult}{" "}
																				{marketPlace.adults} {"  "},
																				{res?.children?.length}{" "}
																				{marketPlace.children}){" "}
																				{marketPlace.perroom}
																			</span>
																		</div>

																		<div>
																			{index > 0 ? (
																				<span
																					className="span-remove-services"
																					onClick={() => {
																						let rooms = [...hotel.rooms];
																						rooms.splice(index, 1);
																						setHotel({
																							...hotel,
																							rooms: rooms,
																						});
																					}}
																				>
																					<i class="far fa-trash-alt"></i>
																				</span>
																			) : null}
																		</div>
																	</div>
																</div>
															</div>

															<div className="icon-items-section-services d-flex align-items-center">
																<i
																	className={`fas fa-fw text-gray px-4 fa-lg ${
																		res.BoxChild !== true
																			? "fa-chevron-right "
																			: "fa-chevron-down"
																	}`}
																	onClick={() =>
																		setRoomsCount(
																			"BoxChild",
																			index,
																			!res.BoxChild
																		)
																	}
																></i>
															</div>
														</div>
														{res.BoxChild ? (
															<div
																className="row box-child"
																ref={DetectClickOutside}
															>
																<div
																	className="col-md-12"
																	style={{ padding: "0 15px" }}
																>
																	<div className="row">
																		<div className="col-md-4 col-6">
																			<div className="container-centerLabel px-2">
																				<label htmlFor="">
																					{" "}
																					{marketPlace.messages.rooms}{" "}
																				</label>
																			</div>
																		</div>
																		<div className="col-md-8 col-6">
																			<NumberField
																				name="roomLength"
																				type="number"
																				placeholder={
																					marketPlace.messages.NoOfRooms
																				}
																				hasLabel={false}
																				label={marketPlace.messages.rooms}
																				increase={() => {
																					setRoomsCount(
																						"roomLength",
																						index,
																						res.roomLength
																							? +res.roomLength + 1
																							: 0 + 1
																					);
																					setChildrenCount(
																						"children",
																						index,
																						0
																					);
																				}}
																				decrease={() => {
																					setRoomsCount(
																						"roomLength",
																						index,
																						res.roomLength - 1
																					);
																					setChildrenCount(
																						"children",
																						index,
																						0
																					);
																				}}
																				max={9}
																				min={1}
																				value={res.roomLength}
																				onChange={(e) => {
																					let removeZero = e.target.value;
																					if (e.target.value[0] == 0) {
																						removeZero =
																							e.target.value.substring(1);
																					}
																					setRoomsCount(
																						"roomLength",
																						index,
																						+removeZero > 9
																							? 9
																							: removeZero < 1
																							? 1
																							: removeZero
																					);
																					setChildrenCount(
																						"children",
																						index,
																						0
																					);
																				}}
																				color={
																					errors?.rooms[index]?.roomLength
																						?.required
																						? "danger"
																						: ""
																				}
																				errors={
																					errors?.rooms[index]?.roomLength
																				}
																			/>
																		</div>
																	</div>
																</div>

																<div
																	className="col-md-12"
																	style={{ padding: "0 15px" }}
																>
																	<div className="row">
																		<div className="col-md-4 col-6">
																			<div className="container-centerLabel px-2">
																				<label className="m-0 w-100" htmlFor="">
																					{marketPlace.messages.adult}
																				</label>
																				<p className="small-title">
																					{marketPlace.ForEveryRoom}
																				</p>
																			</div>
																		</div>
																		<div className="col-md-8 col-6">
																			<NumberField
																				type="number"
																				placeholder={
																					marketPlace.messages.NoOfAdults
																				}
																				hasLabel={false}
																				label={marketPlace.messages.adult}
																				value={res.number_of_adult}
																				name="number_of_adult"
																				max={6}
																				min={1}
																				increase={() => {
																					setRoomsCount(
																						"number_of_adult",
																						index,
																						res.number_of_adult
																							? +res.number_of_adult + 1
																							: 0 + 1
																					);
																				}}
																				decrease={() => {
																					setRoomsCount(
																						"number_of_adult",
																						index,
																						res.number_of_adult - 1
																					);
																				}}
																				onChange={(e) => {
																					let removeZero = e.target.value;
																					if (e.target.value[0] == 0) {
																						removeZero =
																							e.target.value.substring(1);
																					}
																					setRoomsCount(
																						"number_of_adult",
																						index,
																						+removeZero > 6
																							? 6
																							: removeZero < 1
																							? 1
																							: removeZero
																					);
																				}}
																				color={
																					errors?.rooms[index]?.number_of_adult
																						?.required
																						? "danger"
																						: ""
																				}
																				errors={
																					errors?.rooms[index]?.number_of_adult
																				}
																			/>
																		</div>
																	</div>{" "}
																</div>

																<div
																	className="col-md-12"
																	style={{ padding: "0 15px" }}
																>
																	<div className="row">
																		<div className="col-md-4 col-6">
																			<div className="container-centerLabel px-2">
																				<label className="m-0 w-100" htmlFor="">
																					{marketPlace.messages.child}
																				</label>
																				<p className="small-title">
																					{marketPlace.ForEveryRoom}
																				</p>
																			</div>
																		</div>
																		<div className="col-md-8 col-6">
																			<NumberField
																				type="number"
																				placeholder={
																					marketPlace.messages.NoOfChildren
																				}
																				hasLabel={false}
																				label={marketPlace.messages.child}
																				name="rooms_count"
																				max={4}
																				min={0}
																				value={res?.children?.length.toString()}
																				increase={() => {
																					setChildrenCount(
																						"children",
																						index,
																						res.children.length
																							? +res.children.length + 1
																							: 0 + 1
																					);
																				}}
																				decrease={() => {
																					setChildrenCount(
																						"children",
																						index,
																						res.children.length - 1
																					);
																				}}
																				onChange={(e) => {
																					let removeZero = e.target.value;
																					if (e.target.value[0] == 0) {
																						removeZero =
																							e.target.value.substring(1);
																					}
																					setChildrenCount(
																						"children",
																						index,
																						+removeZero > 4
																							? 4
																							: removeZero < 0
																							? 0
																							: removeZero
																					);
																				}}
																				color={
																					errors?.rooms[index]?.children
																						?.required
																						? "danger"
																						: ""
																				}
																				errors={errors?.rooms[index]?.children}
																			/>
																		</div>
																	</div>{" "}
																</div>
																<div
																	className="col-md-12"
																	style={{ padding: "0 15px" }}
																>
																	{/* rooms list */}
																	{res.roomsList &&
																	res.roomsList.length > 0 &&
																	res?.children &&
																	res?.children?.length > 0 ? (
																		<p className="text-danger-custom my-3">
																			{marketPlace.oldChildren}
																		</p>
																	) : (
																		""
																	)}
																	{res.roomsList &&
																	res?.children?.length > 0 &&
																	res.roomsList.length > 0
																		? res.roomsList.map((rese, indexRoom) => {
																				return (
																					<div className="row">
																						<div className="col-md-3">
																							<div className="container-centerLabel px-2">
																								<label
																									className="m-0 w-100"
																									htmlFor=""
																								>
																									{marketPlace.messages.room}{" "}
																									{+indexRoom + 1}
																								</label>
																							</div>
																						</div>
																						<div className=" row col-9">
																							{rese?.children &&
																							rese?.children.length > 0
																								? rese?.children.map(
																										(child, indexChild) => {
																											return (
																												<>
																													<div className="col-md-4 ">
																														<NumberField
																															type="number"
																															placeholder={`${
																																marketPlace
																																	.messages.age
																															} ${
																																indexChild + 1
																															}`}
																															hasLabel={false}
																															value={child.age}
																															max={12}
																															min={1}
																															name="setAge"
																															onChange={(e) => {
																																let removeZero =
																																	e.target
																																		.value;
																																setAge(
																																	index,
																																	indexRoom,
																																	indexChild,
																																	+removeZero >
																																		12
																																		? 12
																																		: removeZero <
																																		  1
																																		? 1
																																		: removeZero
																																);
																															}}
																															color={""}
																															errors={""}
																															onEnter={(e) =>
																																false
																															}
																															increase={() => {
																																setAge(
																																	index,
																																	indexRoom,
																																	indexChild,
																																	+child.age + 1
																																);
																															}}
																															decrease={() => {
																																setAge(
																																	index,
																																	indexRoom,
																																	indexChild,
																																	+child.age - 1
																																);
																															}}
																														/>
																													</div>
																												</>
																											);
																										}
																								  )
																								: ""}
																						</div>
																					</div>
																				);
																		  })
																		: ""}
																</div>
															</div>
														) : null}
													</div>
												</div>
											</>
										);
								  })
								: null}
							<div className="col-6">
								{
									<p className="text-success  mt-3 add-room-services mx-3">
										<span
											onClick={() => {
												let x = hotel.rooms;
												x.push({});
												setHotel({ ...hotel, rooms: x });
												setRoomsCount("roomLength", +hotel.rooms.length - 1, 1);
												setRoomsCount(
													"number_of_adult",
													+hotel.rooms.length - 1,
													2
												);
											}}
											style={{ cursor: "pointer" }}
										>
											<i class="fas fa-plus mx-1"></i> {marketPlace.AddNewRoom}
										</span>
									</p>
								}
							</div>
						</div>
					</div>
				</div>
				{/* Residence */}
				<div
					className="col-md-3 col-6"
					id="hotel-vacations-individual-residence"
				>
					<div className='form-group p-m-p'>
						{matches && (
							<label className='label-m-color'>
								{marketPlace.messages.residence}
							</label>
						)}

					<SelectField
						hasLabel={false}
						labelInner={true}
						label={!matches && marketPlace.messages.residence}
						value={hotel.residence?.label}
						placeholder={`${marketPlace.select}  `}
						name="residence"
						options={allCountries}
						onChange={(e) => {
							setHotel({ ...hotel, residence: e });
							setErrors({
								...errors,
								...validate(
									{ name: "residence", value: e.id },
									{ required: true }
								),
							});
						}}
						onBlur={() =>
							setErrors({
								...errors,
								...validate(
									{ name: "residence", value: hotel.residence },
									{ required: true }
								),
							})
						}
						color={errors?.residence?.required ? "danger" : ""}
						errors={errors?.residence}
					/>
					</div>
				</div>
				{/* nationality */}
				<div
					className="col-md-3 col-6"
					id="hotel-vacations-individual-nationality"
				>
					<div className='form-group p-m-p'>
						{matches && (
							<label className='label-m-color'>
								{marketPlace.messages.nationality}
							</label>
						)}

					<SelectField
						hasLabel={false}
						labelInner={true}
						label={!matches && marketPlace.messages.nationality}
						value={hotel.nationality?.label}
						placeholder={`${marketPlace.select} `}
						name="nationality"
						options={allCountries}
						onChange={(e) => {
							setHotel({ ...hotel, nationality: e });
							setErrors({
								...errors,
								...validate(
									{ name: "nationality", value: e.id },
									{ required: true }
								),
							});
						}}
						onBlur={() =>
							setErrors({
								...errors,
								...validate(
									{ name: "nationality", value: hotel.nationality },
									{ required: true }
								),
							})
						}
						color={errors?.nationality?.required ? "danger" : ""}
						errors={errors?.nationality}
					/>
					</div>
				</div>
				{/* currencyName */}
				{/* <div className="col-lg-4 col-md-6 mt-2" id="hotel-vacations-individual-currency">
					<SelectField
						label={marketPlace.messages.currencyName}
						value={hotel.currency_code?.label}
						placeholder={`${marketPlace.select} ${marketPlace.messages.currencyName} `}
						name="currency_code"
						options={CurrenciesData}
						onChange={(e) => {
							setHotel({ ...hotel, currency_code: e });
							setErrors({
								...errors,
								...validate(
									{ name: "currency_code", value: e.id },
									{ required: true }
								),
							});
						}}
						onBlur={() =>
							setErrors({
								...errors,
								...validate(
									{ name: "currency_code", value: hotel.currency_code },
									{ required: true }
								),
							})
						}
						color={errors?.currency_code?.required ? "danger" : ""}
						errors={errors?.currency_code}
					/>
				</div> */}
				{/* search button */}
				<div className="col-md-6">
					<div className="full-input search-button">
						<div className="full-input search-button search-services-button">
							<button
								onClick={handleSearchCheck}
								className="btn w-100 bg-nxt mb-3 text-17 "
								type="button"
							>
								<SearchIcon />{" "} {marketPlace.messages.search}
							</button>
							{/* <Link className="form-control btn-primary text-center" to="/market-view">{marketPlace.messages.search}</Link> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}