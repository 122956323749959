import NumberField from 'components/Form/NumberField/NumberField'
import { WalletIcon } from 'modules/UmrahOperations/shared/Icons'
import React from 'react'
import Locale from 'translations';

export default function ArrivalDeparturePricing({ handleInputsChanges, accountingStatement, canEdit }) {
  const { operationStatement } = Locale;
  const tripPricing = accountingStatement?.trip?.pricing;
  const totalBuying = (+tripPricing?.buying_adult_total || 0) + (+tripPricing?.buying_child_total || 0) + (+tripPricing?.buying_infant_total || 0);
  const totalSelling = (+tripPricing?.selling_adult_total || 0) + (+tripPricing?.selling_child_total || 0) + (+tripPricing?.selling_infant_total || 0);
  const profitAmount = totalSelling - totalBuying;

  return (
    <div >
      <div className='arrival-departure-cost'>
        {/* buying cost  */}
        <div className="cost-table buying">
          <h5>
            <WalletIcon color="#1E85FF" />
            <span>{operationStatement.BuyingCost}</span>
          </h5>
          {/* buying cost table */}
          <table className="w-100">
            <thead>
              <tr>
                <th></th>
                <th>{operationStatement.Adult}</th>
                <th>{operationStatement.Child}</th>
                <th>{operationStatement.Infant}</th>
              </tr>
            </thead>
            <tbody>
              <tr className='pax-count-row'>
                <td width="20%">{operationStatement.pax}</td>
                <td>{accountingStatement?.adults}</td>
                <td>{accountingStatement?.children}</td>
                <td>{accountingStatement?.infants}</td>
              </tr>
              <tr>
                <td width="20%">{operationStatement.PricePerPax}</td>
                <td>
                  <NumberField
                    hasLabel={false}
                    removeArrow={true}
                    placeholder={operationStatement.price}
                    id="buying_adult_price"
                    name="buying_adult_price"
                    value={tripPricing?.buying_adult_price}
                    onChange={(e) => handleInputsChanges(e.target.value, accountingStatement?.adults, "buying_adult_price", "buying_adult_total", false)}
                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  />
                </td>
                <td>
                  <NumberField
                    hasLabel={false}
                    removeArrow={true}
                    placeholder={operationStatement.price}
                    id="buying_child_price"
                    name="buying_child_price"
                    value={tripPricing?.buying_child_price}
                    onChange={(e) => handleInputsChanges(e.target.value, accountingStatement?.children, "buying_child_price", "buying_child_total", false)}

                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  />
                </td>
                <td>
                  <NumberField
                    hasLabel={false}
                    removeArrow={true}
                    placeholder={operationStatement.price}
                    id="buying_infant_price"
                    name="buying_infant_price"
                    value={tripPricing?.buying_infant_price}
                    onChange={(e) => handleInputsChanges(e.target.value, accountingStatement?.infants, "buying_infant_price", "buying_infant_total", false)}

                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  />
                </td>
              </tr>
              {/* total buying */}
              <tr>
                <td width="20%">Total</td>
                <td>
                  <NumberField
                    hasLabel={false}
                    removeArrow={true}
                    placeholder={operationStatement.total}

                    id="buying_adult_total"
                    name="buying_adult_total"
                    value={tripPricing?.buying_adult_total}
                    onChange={(e) => handleInputsChanges(e.target.value, accountingStatement?.adults, "buying_adult_price", "buying_adult_total", true)}
                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  />
                </td>
                <td>
                  <NumberField
                    hasLabel={false}
                    removeArrow={true}
                    placeholder={operationStatement.total}
                    id="buying_child_total"
                    name="buying_child_total"
                    value={tripPricing?.buying_child_total}
                    onChange={(e) => handleInputsChanges(e.target.value, accountingStatement?.children, "buying_child_price", "buying_child_total", true)}
                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  />
                </td>
                <td>
                  <NumberField
                    hasLabel={false}
                    removeArrow={true}
                    placeholder={operationStatement.total}
                    id="buying_infant_total"
                    name="buying_infant_total"
                    value={tripPricing?.buying_infant_total}
                    onChange={(e) => handleInputsChanges(e.target.value, accountingStatement?.infants, "buying_infant_price", "buying_infant_total", true)}
                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  />
                </td>
              </tr>
              <tr>
                <td width="20%" className='total'>{operationStatement.TotalPurchase}</td>
                <td colSpan="3">
                  <span className="total-amount text-black-600">{totalBuying?.toFixed(2)}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>



        {/* selling cost  */}
        <div className="cost-table selling">
          <h5>
            <WalletIcon color="#219653" />
            <span>{operationStatement.Sellingcost}</span>
          </h5>
          {/* selling cost table */}
          <table className="w-100">
            <thead>
              <tr>
                <th></th>
                <th>{operationStatement.Adult}</th>
                <th>{operationStatement.Child}</th>
                <th>{operationStatement.Infant}</th>
              </tr>
            </thead>
            <tbody>
              <tr className='pax-count-row'>
                <td width="20%">{operationStatement.Pax}</td>
                <td>{accountingStatement?.adults}</td>
                <td>{accountingStatement?.children}</td>
                <td>{accountingStatement?.infants}</td>
              </tr>
              {/* price per pax */}
              <tr>
                <td width="20%">{operationStatement.PricePerPax}</td>
                <td>
                  <NumberField
                    hasLabel={false}
                    removeArrow={true}
                    placeholder={operationStatement.price}
                    id="selling_adult_price"
                    name="selling_adult_price"
                    value={tripPricing?.selling_adult_price}
                    onChange={(e) => handleInputsChanges(e.target.value, accountingStatement?.adults, "selling_adult_price", "selling_adult_total", false)}
                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  // color={tripPricing?.buying_adult_price > tripPricing?.selling_adult_price ? "danger" : ""}
                  />
                </td>
                <td>
                  <NumberField
                    hasLabel={false}
                    removeArrow={true}
                    placeholder={operationStatement.price}
                    id="selling_child_price"
                    name="selling_child_price"
                    value={tripPricing?.selling_child_price}
                    onChange={(e) => handleInputsChanges(e.target.value, accountingStatement?.children, "selling_child_price", "selling_child_total", false)}
                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  // color={tripPricing?.buying_child_price > tripPricing?.selling_child_price ? "danger" : ""}
                  />
                </td>
                <td>
                  <NumberField
                    hasLabel={false}
                    removeArrow={true}
                    placeholder={operationStatement.price}
                    id="selling_infant_price"
                    name="selling_infant_price"
                    value={tripPricing?.selling_infant_price}
                    onChange={(e) => handleInputsChanges(e.target.value, accountingStatement?.infants, "selling_infant_price", "selling_infant_total", false)}
                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  // color={tripPricing?.buying_infant_price > tripPricing?.selling_infant_price ? "danger" : ""}
                  />
                </td>
              </tr>

              {/* total selling */}
              <tr>
                <td width="20%">{operationStatement.Total}</td>
                <td>
                  <NumberField
                    hasLabel={false}
                    removeArrow={true}
                    placeholder={operationStatement.total}
                    id="selling_adult_total"
                    name="selling_adult_total"
                    value={tripPricing?.selling_adult_total}
                    onChange={(e) => handleInputsChanges(e.target.value, accountingStatement?.adults, "selling_adult_price", "selling_adult_total", true)}
                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  />
                </td>
                <td>
                  <NumberField
                    hasLabel={false}
                    removeArrow={true}
                    placeholder={operationStatement.total}
                    id="selling_child_total"
                    name="selling_child_total"
                    value={tripPricing?.selling_child_total}
                    onChange={(e) => handleInputsChanges(e.target.value, accountingStatement?.children, "selling_child_price", "selling_child_total", true)}
                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  />
                </td>
                <td>
                  <NumberField
                    hasLabel={false}
                    removeArrow={true}
                    placeholder={operationStatement.total}
                    id="selling_infant_total"
                    name="selling_infant_total"
                    value={tripPricing?.selling_infant_total}
                    onChange={(e) => handleInputsChanges(e.target.value, accountingStatement?.infants, "selling_infant_price", "selling_infant_total", true)}
                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  />
                </td>
              </tr>
              <tr>
                <td width="20%" className='total'>{operationStatement.TotalSales}</td>
                <td colSpan="3">
                  <span className="total-amount text-black-600">{totalSelling?.toFixed(2)}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


      {/* profit */}
      <div className="arrival-departure-profit">
        <span className="box">{operationStatement.Profit}</span>
        <span className="text-black-600 total-profit">{profitAmount < 0 ? 0 : profitAmount?.toFixed(2)}</span>
      </div>
    </div>
  )
}
