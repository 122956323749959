// ** imports
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Locale from "translations";

// ** components
import { ReactComponent as CRMMainIcon } from "assets/images/crm/main.svg";
import { ReactComponent as CSRequestsIcon } from "assets/images/crm/cs-requests.svg";
import { ReactComponent as UserIcon } from "assets/images/crm/user.svg";
import { ReactComponent as AccountingIcon } from "assets/images/crm/accounting.svg";

// ** views
import CRMTabs from "modules/CRM/shared/CRMTabs";
import SummeryDetails from "./SummeryDetails";
import HistoryLog from "./HistoryLog";
import ColLogs from "./CallLog";
import { useSBSState } from "context/global";
import { CSRequestsDetails } from 'services/CRM';

const CsRequestDetails = () => {
	// ** hooks
	const { CRM } = Locale;
	const { locale } = useSBSState();
	const [Reload, setReload] = useState(false)

	// ** Tabs
	const { tab, id } = useParams();
	const CRM_TABS = [
		{ title: CRM.cRM, icon: <CRMMainIcon />, url: "main" },
		{
			title: CRM.accounting,
			icon: <AccountingIcon />,
			url: "accounting",
		},
		{
			title: CRM.csRequests,
			icon: <CSRequestsIcon />,
			url: "cs-requests",
		},
		{ title: CRM.customers, icon: <UserIcon />, url: "customers" },
	];

	const CRM_CALL_LOGS_TABS = [
		{ title: CRM.CallLog, url: "1" },
		{
			title: CRM.History,
			url: "2",
		},
	];

	// ** states
	const [activeTab, setActiveTab] = useState("cs-requests");
	const [activeCRMDetailsTab, setActiveCRMDetailsTab] = useState("1");
	const [callLogTabs, setCallLogTabs] = useState("1");
	const [requestState, setRequestState] = useState()

	const toggleCallTabs = (tab) => {
		setCallLogTabs(tab);
	};

	// ** useEffect to set tabs
	useEffect(() => {
		if (id === undefined) {
			setActiveTab("cs-requests");
		} else {
			setActiveTab("cs-requests");
		}
	}, [tab]);

	useEffect(() => {
		async function fetchTransaction() {
			const res = await CSRequestsDetails(id);
			setRequestState(res?.data?.data);
		}
		fetchTransaction();
	}, [Reload]);

	return (
		<div className="bg-white" style={{ marginTop: "3rem" }}>
			<div className="bg-gray-50">
				<CRMTabs
					tabs={CRM_TABS}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					classNames="px-4 col-md-12 col-12 mx-auto"
				/>
			</div>

			<div className="crm-details-wrapper crm-cs-requests-details bg-white">
				<div className="crm-tabs-wrapper">
					<div className="crm-tabs-left">
						<div className="crm-tabs-m">
							{activeCRMDetailsTab === "1" ? <SummeryDetails  requestData={requestState} setReload={setReload}/> : null}
						</div>
					</div>

					<div className="crm-tabs-right cs-request-log-tab">
						<CRMTabs
							tabs={CRM_CALL_LOGS_TABS}
							activeTab={callLogTabs}
							setActiveTab={setCallLogTabs}
							classNames="px-4 col-md-12 col-12 mx-auto"
							hasBorder={true}
							toggleTabs={toggleCallTabs}
						/>
						{callLogTabs === "1" ? <ColLogs /> : null}
						{callLogTabs === "2" ? <HistoryLog /> : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CsRequestDetails;
