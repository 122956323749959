import React from 'react'
import { ReactComponent as ProfileIcon } from "assets/images/product-builder/profile.svg";
import { MinusIcon, PlusIcon } from './Icons';
import Locale from 'translations';

export default function PaxInput({ isDisabled, title, icon, paxTypeText, value, maxPax, minPax, handleIncrease, handleDecrease, onChange, errors, color }) {
  const { commons, productsBuilder } = Locale;

  const generateErrorsMsgs = (errors) => {
    let message = [];
    if (errors) {
      for (const [k, v] of Object.entries(errors)) {
        if (k === "required" && v) {
          message.push(commons.isRequired);
        } else if (k === "maxNumber" && v) {
          message.push(` ${productsBuilder.shouldnotexceed} ${maxPax}`);
        } else if (k === "minNumber" && v) {
          message.push(` ${commons.shouldBeAtLeast} ${minPax}`);
        }
      }
    }
    if (message.length === 1) {
      return `${title || paxTypeText} ${message[0]}`;
    } else if (message.length > 1) {
      return `${title || paxTypeText} ${message.join(" & ")}`;
    } else {
      return;
    }
  };

  return (
    <div className={`pax-input ${color ? "hasError" : ""}`}>
      <span className='label-pax' style={{ opacity: title ? "" : "0" }}>{title ? title : "asd"}</span>
      <div className={`input-container ${isDisabled ? "disabled" : null}`}
      >
        <div className='pax-type'>
          {icon ? icon : <ProfileIcon />}
          <span className='mx-1'>{paxTypeText}</span>
        </div>
        <div className='action-btns'>
          {/* decrease value */}
          <button className='btn p-0'
            onClick={handleDecrease}
            disabled={+value === 0 || !value || isDisabled}
          >
            <MinusIcon />
          </button>
          <input type="number" name={title}
            id={title} value={value || "0"}
            className={`mx-1 number-input ${isDisabled ? "disabled" : ""} `}
            onChange={(e) => {
              let value = e.target.value;
              if (value[0] === "0") {
                value = value.substring(1);
              }
              onChange(value);
            }}
            disabled={isDisabled}
          />
          {/* <span className='mx-2'>{value}</span> */}
          {/* increase value */}
          <button className='btn p-0'
            onClick={handleIncrease}
            disabled={isDisabled}
          >
            <PlusIcon />

          </button>
        </div>
      </div>
      {errors ?
        <small class="control-field__feedback control-field__feedback--danger d-block  error-message">
          {/* {title || paxTypeText} {commons.isRequired}   */}
          {generateErrorsMsgs(errors)}
        </small>
        :
        null
      }
    </div>
  )
}
