import React from 'react';
import { ReactComponent as CodeIcon } from "assets/images/umrah-operations/code-icon.svg";
import { ReactComponent as FileInputPlaceholderIcon } from "assets/images/umrah-operations/file-input-placeholder.svg";
import SelectField from 'components/Form/SelectField/SelectField';
import Locale from 'translations';


export default function SettingsHeaderFooter({ headerImageSettingsLookup, basicSettingsData, setBasicSettingsData }) {
  const { operationStatement, productsBuilder } = Locale;
  function handlePreviewImage(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setBasicSettingsData({
        ...basicSettingsData,
        header_image_preview: basicSettingsData?.header_image_preview,
        header_image: basicSettingsData?.header_image
      })
      return
    }
    const selectedFile = e.target.files[0];
    const objectUrl = URL.createObjectURL(selectedFile);
    setBasicSettingsData({ ...basicSettingsData, header_image_preview: objectUrl, header_image: selectedFile })
  }


  return (
    <div className='d-flex flex-wrap gap-24'>

      {/* header */}
      <div className="settings-wrapper setting-header">
        <h3 className='header-title'>
          <CodeIcon />
          <span>{operationStatement.header}</span>
        </h3>
        <div className="add-header-image">
          <span className="control-field__label mb-1">{operationStatement.addHeaderImage}</span>
          <div className={`image-input-container 
          ${basicSettingsData.header_image_preview ? `image-${basicSettingsData.header_image_setting?.value}` : ""}`}>

            <input
              type="file"
              id="header_image"
              name="header_image"
              className='input'
              accept="image/png, image/jpg, image/jpeg"
              onChange={(e) => handlePreviewImage(e)}
            />

            <span className="preview">
              {basicSettingsData.header_image_preview ?
                <img src={basicSettingsData.header_image_preview} alt="" width="100%" />
                :
                <FileInputPlaceholderIcon />
              }
            </span>
          </div>
        </div>

        <div className="image-setting mt-3">
          <SelectField
            label={operationStatement.imageSetting}
            placeholder={productsBuilder.select}
            id="header_image_setting"
            name="header_image_setting"
            value={basicSettingsData?.header_image_setting?.name}
            onChange={(e) => {
              setBasicSettingsData({ ...basicSettingsData, header_image_setting: e })
            }}
            options={headerImageSettingsLookup}
          />
        </div>
      </div>

      {/* footer */}
      <div className="settings-wrapper setting-footer">
        <h3 className='header-title'>
          <CodeIcon />
          <span>{operationStatement.footer}</span>
        </h3>
        <div className='d-flex flex-column'>
          <label htmlFor="footer_details" className='control-field__label mb-1'>{operationStatement.footerDetails}</label>
          <textarea
            placeholder='Footer Details'
            name="footer_details"
            id="footer_details"
            rows="8"
            className='w-100 textarea'
            value={basicSettingsData?.footer_details}
            onChange={(e) => {
              setBasicSettingsData({ ...basicSettingsData, footer_details: e.target.value })
            }}
          />
        </div>
      </div>
    </div>
  )
}
