import usePreviewMode from 'hooks/usePreviewMode';
import ThemeSevenServiceCard from './ServiceCard';
import ServicesContent from 'modules/WebBuilder-V2/shared/ServicesContent';
import { useWebBuilderState } from 'context/webBuilder';



export default function ThemeSevenServices({ updateOurServices }) {
  const { isPreview } = usePreviewMode();
  const { ourServices, selectedLanguage, } = useWebBuilderState();

  const services = Array.isArray(ourServices?.services)
    ? ourServices?.services
    : [];

  return (
    <>
      <section className="theme_inner_wrapper" id="our-service">
        <div className={`theme_services_section section_padding__md ${isPreview ? '' : 'edit_mode'}`}>
          <div className="container">
            <div className="section_wrapper">

              <ServicesContent
                header={ourServices?.title[selectedLanguage]}
                body={ourServices?.body[selectedLanguage]}
                changeHeader={(value) => updateOurServices({ name: "title", value, type: null, language: selectedLanguage })}
                changebody={(value) => updateOurServices({ name: "body", value, type: null, language: selectedLanguage })}
              />
              <div className="service_cards_container">
                {services.map((service, index) => {
                  return (
                    <ThemeSevenServiceCard
                      type={service?.type}
                      image={service?.icon}
                      header={service?.header[selectedLanguage]}
                      key={service?.type + index}
                      visible={service?.visible}
                      onChangeTitle={(value, name) =>
                        updateOurServices({
                          name,
                          value,
                          type: service?.type,
                          language: selectedLanguage
                        })
                      }
                      editCardDetails={(value, name) =>
                        updateOurServices({ name, value, type: service?.type, language: selectedLanguage })
                      }
                    />
                  )
                })}

              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <ThemeSevenAbout /> */}
      {/* <ThemeSevenPackages /> */}
    </>
  )
}