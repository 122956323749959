import classnames from "classnames";
import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Locale from "translations";

import companyBlack from "assets/images/inventory/companyBlack.svg";
import companyColored from "assets/images/inventory/companyColored.svg";
// import safaBlack from "assets/images/inventory/safaBlack.svg";
// import safaColored from "assets/images/inventory/safaColored.svg";
import CompanyProfile from "./CompanyProfile";
// import SafaProfile from "./SafaProfile";

export default function CompanyAccountsEdit() {
	const [activeTab, setActiveTab] = useState("1");

	const { messages } = Locale;
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	return (
		<>
			<div className="team-management-tabs container  interest bg-white p-0">
				<div className="tab-icons">
					<Nav tabs>
						<NavItem className="">
							<NavLink
								className={`border-0  ${classnames({
									active: activeTab === "1",
								})}`}
								onClick={() => {
									toggle("1");
								}}
							>
								<div className="product-build-list-item">
									<img
										src={activeTab === "1" ? companyColored : companyBlack}
										alt=""
									/>
									<span className="text mx-1">{messages.companyProfile}</span>
								</div>
							</NavLink>
						</NavItem>
						{/* <NavItem className="">
							<NavLink
								disabled={true}
								className={classnames({ active: activeTab === "2" })}
								onClick={() => {
									toggle("2");
								}}
							>
								<div className="product-build-list-item">
									<img
										src={activeTab === "2" ? safaColored : safaBlack}
										alt=""
									/>
									<span className="text mx-1">
										<span className="text mx-1">{messages.safa}</span>
									</span>
								</div>
							</NavLink>
						</NavItem> */}
					</Nav>
				</div>

				<TabContent activeTab={activeTab} style={{ minHeight: "100%" }}>
					<TabPane tabId="1">
						{activeTab === "1" ? <CompanyProfile /> : null}
					</TabPane>

					{/* <TabPane tabId="2">
						{activeTab === "2" ? <SafaProfile /> : null}
					</TabPane> */}
				</TabContent>
			</div>
		</>
	);
}
