export const Operations = `<!DOCTYPE html>
<html lang="ar">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Booking Form</title>
    <style>
body {
    font-family: Arial, sans-serif;
    direction: rtl;
    margin: 0;
    padding: 10px;
    font-size: 12px;
}

h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 5px;
}

p {
    text-align: center;
    margin-top: 0;
    font-size: 14px;
}

table {
    width: 100%;
    margin-bottom: 10px;
   border-collapse: separate; 
    border-spacing: 0 5px; 
    }

td, th {
    border: 1px solid black;
    padding: 4px;
    text-align: center;
    
    font-weight: normal; 
  
}

th {
    background-color: #d0d0d0;
}

.info-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; 
    direction: rtl; 
}

.info-table td {
    border: 1px solid black;
    padding: 10px;
    text-align: center;
    vertical-align: middle;
    background-color: #f0f0f0; 
}

.info-table td:nth-child(1) {
    width: 33%; 
}

.info-table td:nth-child(2),
.info-table td:nth-child(3) {
    width: 33%; 
}

.info-table tr {
    height: 50px; 
}



.section-title {
    text-align: center;
    padding: 4px;
    font-size: 24px;
}

.footer {
    text-align: center;
    margin-top: 10px;
}
.header-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    border: 1px solid #000;
    margin: 0;
    font-family: Arial, sans-serif;
    font-size: 14px;
}

.header-item {
    flex: 1;
    padding: 10px;
    text-align: right;
    border-left: 1px solid #000;
    display: flex;
    flex-direction: column;
    justify-content: right;
}

.header-itemgray {
    flex: 1;
    padding: 10px;
    text-align: right;
    border-left: 1px solid #000;
    display: flex;
    flex-direction: column;
    justify-content: right;
    background-color: #d0d0d0;
}

.header-item:last-child {
    border-left: none;
}

.header-label {
    margin-bottom: 5px;
    font-size: 14px;
}

.header-value {
    font-size: 0.9em;
}

.date-value {
    direction: ltr;
    text-align: right;
}

.header-row.split,
.header-row.split-second {
    margin: 0; 
}

.header-row.split .header-item:last-child {
    border-left: 1px solid #000;
}

.header-row.split .header-item:nth-last-child(2) {
    border-left: none;
}

.header-row.split-second .header-item {
    border-left: 1px solid #000;
}

.header-row.split-second .header-item:nth-child(2) {
    flex: 2; 
}

.header-row.split-second .header-item.split {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.header-row.split-second .header-item.split .header-value {
    border-right: 1px solid #000;
}

.header-row.split-second .header-item:last-child {
    border-left: none;
}


        .document-header {
            font-family: Arial, sans-serif;
            max-width: 800px;
            margin: 20px auto;
            position: relative;
        }
        .header-main {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .reservation-number {
            padding: 5px 10px;
            margin-bottom: 10px;
           border: 2px solid #000;

        }
        .main-title {
            padding: 5px 10px;
            text-align: center;
            font-size:24px;
        }
        .program-duration {
            position: absolute;
            font-size:20px;
            top: 60;
            left: 0;
            border: 1px solid #000;
            padding: 5px 10px;
        }


    .pilgrims-table th  {
        font-weight: bold; 

}

    .pilgrims-table td  {
        font-weight: bold; 

}
    </style>
</head>
<body>


   <div class="document-header">
        <div class="program-duration">
            مدة البرنامج [program_duration] يوم
        </div>
        <div class="header-main">
            <h1 class="reservation-number">
                حجز رقم [booking_number]
            </h1>
            <div class="main-title">
                (( بيان وصول أفواج المعتمرين للديار المقدسة ))
            </div>
        </div>
    </div>

    <div class="header-row">
        <div class="header-itemgray">
            <div class="header-label">اسم الوكيل الخارجي</div>
            <div class="header-value"></div>
        </div>
        <div class="header-item">
            <div class="header-value date-value">[agent_name]</div>
            <div class="header-value"></div>
        </div>
        <div class="header-itemgray">
            <div class="header-label">تاريخ القدوم للمملكة</div>
            <div class="header-value"></div>
        </div>
        <div class="header-item">
            <div class="header-label"></div>
            <div class="header-value date-value">[arrival_date]</div>
        </div>
    </div>

    



    <div class="header-row split-second">
    <div class="header-itemgray">
        <div class="header-label">اسم مشرف المجموعة</div>
        <div class="header-value"></div>
    </div>
    <div class="header-item split">
        <div class="header-value date-value">-----</div>
        <div class="header-value"></div>
    </div>
  
  <div class="header-itemgray">
        <div class="header-label">رقم الجوال</div>
    </div>
   
    <div class="header-item">
        <div class="header-label">-----</div>
    </div>
    <div class="header-item">
        <div class="header-label">الجنسية : ----</div>
    </div>
</div>


<div class="header-row split-second">
    <div class="header-itemgray">
        <div class="header-label">عدد المعتمرين</div>
        <div class="header-value"></div>
    </div>
    <div class="header-item split">
        <div class="header-label">بالغ : [adult_count]</div>
        <div class="header-value"></div>
    </div>
    <div class="header-item">
        <div class="header-label">طفل : [child_count]</div>
        <div class="header-value"></div>
    </div>
     <div class="header-item">
        <div class="header-label">رضيع : ----</div>
        <div class="header-value"></div>
    </div>
    <div class="header-item">
        <div class="header-label">اجمالي المعتمرين : [pax]</div>
        <div class="header-value"></div>
    </div>
   
    <div class="header-item">
        <div class="header-label">رقم التشغيل :</div>
    </div>
 
</div>

     <div class="header-row">
        <div class="header-itemgray">
            <div class="header-label">رقم مجموعة المخاع</div>
            <div class="header-value"></div>
        </div>
        <div class="header-item">
            <div class="header-value date-value">----</div>
            <div class="header-value"></div>
        </div>
        <div class="header-itemgray">
            <div class="header-label">ملاحظات</div>
            <div class="header-value"></div>
        </div>
        <div class="header-item">
            <div class="header-label"></div>
            <div class="header-value"></div>
        </div>
    </div>

   
    <div class="section-title">(( رحلة الوصول ))</div>
<table>
    <tr>
        <th>منفذ القدوم</th>
        <th>الشركة الناقلة</th>
        <th>تاريخ الوصول</th>

            <th>رقم الرحلة</th>
            <th>وقت الوصول</th>
            <th>وسيلة المواصلات الداخلة</th>
     
    </tr>
    <tr>
        <td>[arrival_port]</td>
        <td>[arrival_carrier_company]</td>
        <td>[arrival_info_date]</td>
          <td>------</td>
             <td>------</td>
             <td>------</td>
     
    </tr>
</table>


        <div class="section-title">(( بيانات المغادرة النهائية ))</div>
    <table>
        <tr>
            <th>منفذ المغادرة</th>
            <th>الشركة الناقلة</th>
            <th>تاريخ المغادرة</th>
               <th>رقم الرحلة</th>
            <th>وقت المغادرة</th>
            <th>صالة المغادرة</th>
          
        </tr>
        <tr>
            <td>[departure_port]</td>
            <td>[carrier_company]</td>
            <td>[return_date]</td>
            <td>------</td>
             <td>------</td>
             <td>------</td>
           
        </tr>
    </table>

    <div class="section-title">(( بيانات التسكين ))</div>
    <table>
        <tr>
            <th>مناطق السكن</th>
            <th>اسم الفندق</th>
            <th>تاريخ الدخول</th>
            <th>تاريخ الخروج</th>
            <th>عدد الليالي</th>
        </tr>
        [allRooms]
    </table>
    <div class="section-title">(( بيانات المعتمرين ))</div>
    <table class="pilgrims-table">
          <tr>
            <th>رقم الجواز</th>
            <th>الاسم</th>
            <th>الجنس</th>
            <th>الجنسية</th>
            <th>رقم الدخولية</th>
                        <th>العمر</th>

        </tr>
     [reservations]
    </table>

    <div class="footer">
    <p>
        POWERED BY: 
        <a href="https://safavisa.com" style="color: blue; text-decoration: none;">
            SAFAVISA.COM
        </a>
    </p>

    <img
        src="[SafaLogo]"
        alt="safa_logo"
        style="width: 187.93px; height: 42.45px"
    />
</div>
</body>
</html>`