import DragAndDropContainer from "modules/WebBuilder-V2/shared/DragAndDropContainer";
import { EditBox } from "modules/WebBuilder-V2/shared/EditBox";
import { SORT_ITEMS_IN_CONTAINER_ACTION } from "modules/WebBuilder-V2/interfaces/blockTypes";
import HotelCardThemeTen from "./HotelCard";

const packagesBlocksType = "packagesBlocksType";

export default function HotelTheme10({
	items,
	sortHotelsItems,
	focusContainer,
	editItem,
	itemsBoxActions,
	type,
	openEditModal,
}) {
	return (
		<div className="container position-relative">
			<div className="hotels-cards-container">
				{items.map((props, index) => (
					<DragAndDropContainer
						accept={packagesBlocksType}
						type={packagesBlocksType}
						id={props.id}
						index={index}
						action={SORT_ITEMS_IN_CONTAINER_ACTION}
						moveItemInContainer={(dragIndex, hoverIndex) =>
							sortHotelsItems({ dragIndex, hoverIndex })
						}
					>
						<EditBox
							editMode={focusContainer === type}
							actions={[
								...editItem(props, index),
								...itemsBoxActions(props.id),
							]}
						>
							<HotelCardThemeTen
								{...props}
								itemType="hotel"
								openEditModal={openEditModal}
							/>
						</EditBox>
					</DragAndDropContainer>
				))}
			</div>
		</div>
	);
}
