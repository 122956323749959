/* eslint-disable no-unused-vars */
import React from 'react'
import Locale from 'translations';
import walletImgSrc from 'assets/images/inventory/walletSm.svg';
import { Link, useParams } from 'react-router-dom';
import { formatPrice } from 'helpers/utils';


export default function TourListItem({ tourData }) {
  const { marketPlace } = Locale;
  const { status } = useParams();
  let tourDescription = tourData?.description ? tourData?.description.replace(/<\/?[^>]+(>|$)/g, "") : '';
  tourDescription = tourDescription?.split(' ').slice(0, 30).join(' ');
  const guidesContainLanguages = tourData?.guides?.includes("German" || "Arabic" || "Spanish" || "English" || "Dutch" || "French" || "Italian" || "Russian");

  return (
    <div className='tour-card' key={tourData?.code}>
      <div className='tour-card-img'>
        <img src={tourData?.images?.[0]} alt={tourData?.name} />
      </div>
      <div className='d-flex flex-column justify-content-between flex-wrap w-100'>
        <div className='tour-card-details mx-md-2'>
          <h2 className='text-dark-blue font-weight-bold h5 m-0'>{tourData?.name}</h2>
          <div className='text-yellow my-1'>
            <i className="fas fa-map-marker-alt"></i>
            <span className='mx-2'>
              {tourData?.destinations?.map((city) => {
                return <span key={city?.code}>{city?.name}, </span>
              })}
              {tourData?.country?.name}
            </span>
          </div>
          <div style={{ fontSize: ".75rem" }}>
            <p>
              {tourDescription}....
            </p>
          </div>
        </div>

        {/* schedules and guides */}
        <div className='tour-card-info d-flex flex-wrap text-dark-blue'>
          <div className='d-flex flex-column mt-2 mx-md-2'>
            {/* schedules */}
            {tourData?.duration?.scheduling ?
              <div>
                <i className="far fa-clock"></i>
                <span className='mx-1'>
                  {tourData?.duration?.scheduling?.type === "AFTER" ? marketPlace.afternoon : ""} {" "}
                  {tourData?.duration?.scheduling?.value} {" "}
                  {marketPlace[tourData?.duration?.scheduling?.metric] || tourData?.duration?.scheduling?.metric} {" "}
                </span>
              </div>
              :
              null
            }
            {/* guides and activity type */}
            {tourData?.guides && tourData?.guides?.length > 0 ?
              <div>
                {guidesContainLanguages || tourData?.guides?.length > 1 ?
                  <>
                    <i className="far fa-comment-dots"></i>
                    <span className='mx-1'>{marketPlace.guides}: </span>
                    {tourData?.guides?.map((guideLang, guideIdx) => {
                      return (
                        <span key={guideIdx + guideIdx}>
                          {guideLang}
                          {guideIdx === tourData?.guides?.length - 1 ? " " : ", "}
                        </span>
                      )
                    })}
                  </>
                  :
                  <span>
                    {marketPlace[tourData?.guides[0]] || tourData?.guides[0]}
                  </span>
                }
              </div>
              :
              null
            }
          </div>
          {/* price */}

          <Link to={`/${status}/tour-details/${tourData?.code}`} className='tour-card-info-price d-flex'>
            <img src={walletImgSrc} alt="price" className='mx-1' />
            <div className='d-flex flex-column'>
              <span className='text-yellow text-capitalize'>{marketPlace.Price}</span>
              <span className='text-dark-blue font-weight-bold total'>{formatPrice(tourData?.price)} {tourData?.currency}</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
