import React, { useEffect, useMemo, useState } from 'react';
import cautionMarkSrc from 'assets/images/cautions-mark.png';
import TextField from 'components/Form/TextField/TextField';
import moment from 'moment';
import Locale from 'translations';
import validate from 'helpers/validate';
import { deleteTourFromCart } from 'services/tours';
import { useMarketplaceDispatch } from 'context/marketplace';
import { formatPrice } from 'helpers/utils';


export default function TourSummaryReservationItem({ tourData, questionsAnswers, setQuestionsAnswers, errors, setErrors, index, cartId }) {

  const { inventory, marketPlace } = Locale;
  const dispatch = useMarketplaceDispatch();
  const modality = tourData?.details?.modalities[0];
  const remarks = modality?.comments;
  const questions = modality?.questions;
  let rateDetails = typeof modality?.rates?.[0]?.rateDetails === "object" ?
    Object.keys(modality?.rates?.[0]?.rateDetails).map((key) => modality?.rates?.[0]?.rateDetails[key])[0]
    :
    modality?.rates?.[0]?.rateDetails[0];
  let operationDates = Object.keys(rateDetails?.operationDates).map(key => rateDetails?.operationDates[key]);
  operationDates = operationDates?.filter(operationDate => moment(tourData?.date_from).format("YYYY-MM-DD") === operationDate?.from)


  // generate state based on questions list
  const generateQuestionsState = useMemo(() => {
    const questionsObj = {};
    questions?.forEach(question => {
      return questionsObj[question?.code?.toLowerCase()] = ""
    });
    return questionsObj;
  }, [questions]);

  const [questionsFields, setQuestionsFields] = useState(generateQuestionsState);

  // set answers questions 
  useEffect(() => {
    let answersList = [...questionsAnswers];
    if (answersList.filter(res => res.id === tourData?.id).length === 0) {
      answersList.push({
        id: tourData?.id,
        answers: questionsFields,
      })
      setQuestionsAnswers((res) => [...res, ...answersList]);
    } else {
      let INDEX = answersList.findIndex(res => res.id === tourData?.id)
      answersList[INDEX] = {
        id: tourData?.id,
        answers: questionsFields,
      }
      setQuestionsAnswers(answersList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, questionsFields]);

  async function deleteItemFromCart(itemId) {
    const deleteItemRes = await deleteTourFromCart({ cartId, itemId });
    if (deleteItemRes?.status >= 200 && deleteItemRes?.status < 300) {
      const cartItems = deleteItemRes?.data?.cart?.cartItems
      dispatch({
        type: "toursCart",
        payload:
          typeof cartItems === "object" ?
            { ...deleteItemRes?.data?.cart, cartItems: Object.keys(cartItems).map((key) => cartItems[key]) } :
            deleteItemRes?.data?.cart
      });
    } else {
      dispatch({
        type: "toursCart",
        payload: null,
      })
    }
  }

  return (
    <div className='tours-summary-reservation'>
      <div className='d-flex justify-content-between'>

        <div className='d-flex flex-wrap flex-md-nowrap w-75'>
          <div className='tour-card-img'>
            <img src={tourData?.details?.images?.[0]} alt={tourData?.details?.name} />
          </div>
          {/* details */}
          <div className='d-flex flex-column mx-3'>
            <h3 className='text-dark-blue font-weight-bold h6'>{tourData?.details?.name}</h3>
            <div>
              <i className="fas fa-map-marker-alt text-yellow"></i>
              <span className='mx-2'>
                {tourData?.details?.destinations?.map((city) => {
                  return <span key={city?.code}>{city?.name} ({city?.code}), </span>
                })}
              </span>
            </div>
            <div className='font-weight-bold'>
              <i className="far fa-calendar-alt text-yellow"></i>
              <span className='mx-2'>{moment(tourData?.date_from).format("DD/MM/YYYY")} ({moment(tourData?.date_from).format("dddd")})</span>
            </div>
            <div className='font-weight-bold'>
              <i className="far fa-user text-yellow"></i>
              <span className='mx-2'>
                {tourData?.noOfAdults} {" "}
                {+tourData?.noOfAdults > 1 ? inventory.messages.adults : inventory.messages.Adult}
              </span>
              {tourData?.children?.length > 0 ?
                <span>
                  {tourData?.children?.length} {inventory.messages.children} {" "}
                  (
                  {tourData?.children?.map((age, index) => {
                    return (
                      <span key={`age-${index}-${age}`}>
                        {age}
                        {index !== tourData?.children?.length - 1 ? ", " : " "}
                      </span>
                    )
                  })}
                  {marketPlace.yearsOld}
                  )
                </span>
                :
                null
              }
            </div>
          </div>
        </div>
        {/* remove btn */}
        <button className='btn tours-cart-item-remove-btn bg-transparent'
          onClick={() => deleteItemFromCart(tourData?.id)}
        >
          <i className="fas fa-trash"></i>
        </button>
      </div>


      {/* Information */}
      {tourData?.details?.content?.importantInfo?.length > 0
        ?
        <div className='mt-2'>
          <span className='text-dark-blue font-weight-bold'>{marketPlace.compulsoryInformation}</span>
          <div className='tours-summary-reservation-cautions'>
            <img src={cautionMarkSrc} alt="img-fluid" />
            <div className='d-flex flex-column'>
              {tourData?.details?.content?.importantInfo?.map((info, infoIdx) => {
                return (
                  <p className='m-2' key={`info-${infoIdx}`} dangerouslySetInnerHTML={{ __html: info }} />
                )
              })}
            </div>
          </div>
        </div>
        :
        null
      }

      {/* questions */}
      <div className='mt-2'>
        {questions?.map((question, INd) => {
          const questionCode = question?.code.toLowerCase();
          const isPhoneNumber = questionCode === "phonenumber" || questionCode === "phone"
          return (
            <div key={questionCode}>
              <label htmlFor={questionCode}>{`${question?.text} ${question?.required ? "*" : ""}`}</label>
              <div className='col-md-6 p-0'>
                <TextField
                  type={isPhoneNumber ? "number" : "text"}
                  label={questionCode}
                  hasLabel={false}
                  id={questionCode}
                  name={questionCode}
                  value={questionsFields[questionCode]}
                  min={isPhoneNumber ? 9 : ""}
                  max={isPhoneNumber ? 15 : ""}
                  onWheel={e => e.currentTarget.blur()}
                  onChange={(e) => {
                    setQuestionsFields({
                      ...questionsFields,
                      [questionCode]: e.target.value
                    });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: questionCode + index + INd, value: e.target.value },
                        {
                          required: question?.required,
                          email: questionCode === "email",
                          min: isPhoneNumber ? 9 : "",
                          max: isPhoneNumber ? 15 : ""
                        },
                      ),
                    });
                  }}
                  errors={errors[questionCode + index + INd]}
                  color={
                    errors[questionCode + index + INd]?.required ||
                      errors[questionCode + index + INd]?.email ||
                      errors[questionCode + index + INd]?.min ||
                      errors[questionCode + index + INd]?.max
                      ?
                      "danger" :
                      ""
                  }
                />
              </div>
            </div>
          )
        })}
      </div>

      {/* remarks */}
      {remarks?.length > 0 ?
        <div className='mt-3 tours-summary-reservation-remarks'>
          <span className='font-weight-bold text-dark-blue'>{inventory.messages.remarks}</span>
          {remarks?.map((remark, remarkIdx) => {
            return (
              <p key={`${remark?.type}-${remarkIdx}`}>{remark?.text}</p>
            )
          })}
        </div>
        :
        null
      }


      {/* pax */}
      <hr />
      <p className='font-weight-bold text-dark-blue my-3'>
        <span className='mx-2'>
          {tourData?.noOfAdults} {" "}
          {+tourData?.noOfAdults > 1 ? inventory.messages.adults : inventory.messages.Adult}
        </span>
        {tourData?.children?.length > 0
          ?
          <span>
            {tourData?.children?.length} {inventory.messages.children} {" "}
            (
            {tourData?.children?.map((age, index) => {
              return (
                <span key={`age-${index}-${age}`}>
                  {age}
                  {index !== tourData?.children?.length - 1 ? ", " : " "}
                </span>
              )
            })}
            {marketPlace.yearsOld})

          </span>
          :
          null
        }
        {" "}(
        {`${tourData?.details?.modalities[0]?.name || ""}
          ${tourData?.details?.modalities[0]?.rates?.[0]?.rateDetails?.[0]?.languages?.[0]?.description || ""} 
            ${tourData?.details?.modalities[0]?.rates?.[0]?.rateDetails?.[0]?.sessions?.[0]?.name || ""}
          `}
        )
      </p>

      {/* cancelations rules */}
      {operationDates?.length > 0 && operationDates[0]?.cancellationPolicies?.length > 0 ?
        <div className='tours-summary-reservation-cancelation d-flex flex-wrap'>
          <div className='col-md-6 col-12 p-2'>
            <div className='d-flex flex-column'>
              <span>
                {inventory.messages.cancellationFees}
              </span>
              {operationDates[0]?.cancellationPolicies?.map((policy, index) => {
                return (
                  <div className='d-flex justify-content-between' key={index}>
                    <span>{marketPlace.From} {moment(policy?.dateFrom).format("hh:mm A")} {moment(policy?.dateFrom).format("DD/MM/YYYY")}</span>
                    <span className='price font-weight-bold'>{formatPrice(policy?.amount)} {tourData?.details?.currency}</span>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="col-md-6 col-12 p-2 text-muted">
            {marketPlace.cancelationWarning}
          </div>
        </div>
        :
        null
      }
    </div>
  )
}
