import React from "react";
import { ReactComponent as UpIcon } from "assets/images/product-builder/arrow-up.svg";
import { ReactComponent as GalleryIcon } from "assets/images/product-builder/gallery.svg";
import { ReactComponent as CloseIcon } from "assets/images/product-builder/close-circle.svg";
import { uploadFile } from "services/auth";

function UploadFile({
	label,
	// you must change this Id every time you use this component to avoid any conflict
	id,
	accept = "image/png, image/jpeg, image/jpg",
	value = [],
	onChange,
	errorMsg
}) {
	const upload = async (file) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("name", file?.name);
		formData.append("bucket", "webBuilder");
		const response = await uploadFile(formData);
		if (response.data.data.uuid) {
			return response.data.data.uuid;
		}
	};

	const handleUpload = async (e) => {
		const files = e.target.files;
		if (!files?.[0]) return;
		const uuid = (await upload(files[0])) ?? "";
		const URI = process.env.REACT_APP_FILEUPLOAD_URL;

		const fileUrl = `${URI}/fetch/${uuid}`;
		const data = value?.concat(fileUrl);
		onChange(data);
	};

	const deleteImage = (image) => {
		const data = value?.filter((img) => img !== image);
		onChange(data);
	};
	return (
		<div className={`upload-file-component `}>
			<p className="upload-file-label"> {label}</p>
			<div className={`upload-file-component-input ${errorMsg ? "field-has-error" : ""}`}>
				{value?.map((image) => (
					<div
						key={image}
						style={{ backgroundImage: `url(${image})` }}
						className="uploaded-image-preview"
					>
						<button className="close-icon"  onClick={()=>deleteImage(image)}>
							<CloseIcon />
						</button>
					</div>
				))}
				<label htmlFor={`${id}-upload-file`} className="upload-button">
					<GalleryIcon />
					<UpIcon className="up-arrow" />
				</label>
				<input
					onChange={handleUpload}
					style={{ display: "none" }}
					type="file"
					id={`${id}-upload-file`}
					accept={accept}
				/>
			</div>
			{errorMsg && (
				<small className={`${errorMsg ? "has-error" : ""}`}>{errorMsg}</small>
			)}
		</div>
	);
}

export default UploadFile;
