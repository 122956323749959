import TextField from 'components/Form/TextField/TextField'
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { SaveIcon } from 'modules/UmrahOperations/shared/Icons';
import validate, { isFormValid } from 'helpers/validate';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';
import { createOfficer, updateOfficer } from 'services/operationStatement';
import SelectField from 'components/Form/SelectField/SelectField';
import NumberField from 'components/Form/NumberField/NumberField';
import Locale from 'translations';
import { useSBSState } from 'context/global';

const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

export default function AddEditMovementOfficerModal({ modalData, toggleOfficerModal, setOfficerModal, getOfficersList, groupsLookup }) {
  const { operationStatement, movementManagement } = Locale;
  const { allCountries } = useSBSState();

  // states
  const [errors, setErrors] = useState({});


  // officer modal input changes
  function handleModalInputsChanges({ key, value }) {
    let modalDataClone = { ...modalData.data }
    modalDataClone = { ...modalDataClone, [key]: value };
    setErrors({
      ...errors,
      ...validate(
        { name: key, value },
        { required: true, email: key === "email" }
      ),
    });
    setOfficerModal({ ...modalData, data: modalDataClone })
  }

  // check if officer form has errors
  function checkFormErrors() {
    let submitError = {};
    Object.keys(modalData.data).forEach((key) => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: modalData.data[key] },
          { required: true, email: key === "email" }
        ),
      };
    });
    setErrors(() => submitError);
    return submitError;
  }

  // handle create and update officer operations
  async function handleCreateUpdateOfficer(officerId) {
    let formErrors = checkFormErrors();
    let res;
    if (isFormValid(formErrors)) {
      let message;
      let data = {
        name: modalData.data.name,
        email: modalData.data.email,
        phone_code: modalData.data.phone_code?.id,
        phone: modalData.data?.phone,
        group_id: modalData.data?.group?.id
      }
      res = officerId ? await updateOfficer(officerId, data) : await createOfficer(data);

      if (res?.status === 200 || res?.status === 201) {
        message = officerId ? "Officer Updated Succesfully" : "Officer Added Succesfully";
        handleResponesNotification({ alertType: "success", message, title: "" });

        toggleOfficerModal({ data: null, type: null });
        getOfficersList({});
      }
    }
  }

  // get phone code object from countries
  useEffect(() => {
    if (modalData.data?.phone_code && typeof modalData.data?.phone_code === "string") {
      let phoneCode = allCountries?.find(country => country?.phone_code === modalData.data.phone_code)
      handleModalInputsChanges({ key: "phone_code", value: phoneCode })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCountries, modalData.data?.phone_code])

  console.log("asdasd", modalData);


  return (
    <Modal isOpen={modalData.isOpen} centered className='operation-modal'>
      <ModalHeader toggle={toggleOfficerModal}>
        {modalData.type === "add" ? movementManagement.addDelegate : movementManagement.editDelegate}
      </ModalHeader>


      <ModalBody className="pt-0">
        <div className="row mx-0">
          {/* officer name */}
          <div className="col-12 mt-2">
            <TextField
              label={movementManagement.name}
              placeholder={movementManagement.typeName}
              name="officer-name"
              id="officer-name"
              value={modalData.data?.name}
              onChange={(e) => handleModalInputsChanges({ key: "name", value: e.target.value })}
              color={errors?.name?.required ? "danger" : ""}
              errors={errors?.name}
            />
          </div>
          {/* officer email */}
          <div className="col-12 mt-2">
            <TextField
              label={movementManagement.email}
              placeholder={movementManagement.enterEmail}
              name="officer-email"
              id="officer-email"
              value={modalData.data?.email}
              onChange={(e) => handleModalInputsChanges({ key: "email", value: e.target.value })}
              color={errors?.email?.email || errors?.email?.required ? "danger" : ""}
              errors={errors?.email}
            />
          </div>

          {/* phone number with country code */}
          <div className="row mx-0 w-100 mt-2">
            <label class="control-field__label col-12">{operationStatement.PhoneNumber}</label>
            {/* phone code */}
            <div className="col-3 company_phone_code">
              <SelectField
                label={movementManagement.code}
                hasLabel={false}
                id="phone_code"
                name="phone_code"
                value={
                  modalData?.data?.phone_code?.flag ?
                    <img
                      src={`${fetchFlag}/${modalData?.data?.phone_code?.flag}`}
                      width="35px"
                      alt={modalData?.data?.phone_code?.label}
                    />
                    :
                    modalData?.data?.phone_code?.label || modalData?.data?.phone_code
                }
                onChange={(e) => handleModalInputsChanges({ key: "phone_code", value: e })}
                options={allCountries}
                color={errors?.phone_code?.required ? "danger" : ""}
                errors={errors?.phone_code}
              />
            </div>
            {/* phone */}
            <div className="col-8 px-0">
              <NumberField
                type={movementManagement.phone}
                label={operationStatement.PhoneNumber}
                hasLabel={false}
                id="phone"
                name="phone"
                value={modalData?.data?.phone}
                removeArrow={true}
                placeholder={"000 0000 000"}
                min={3}
                onChange={(e) => handleModalInputsChanges({ key: "phone", value: e.target.value })}
                extraTextPosition="prepend"
                extraText={
                  modalData?.data?.phone_code?.phone_code ||
                  modalData?.data?.phone_code?.label ||
                  modalData?.data?.phone_code?.value
                }
                color={errors?.phone?.required || errors?.phone_code?.required ? "danger" : ""}
                errors={errors?.phone || errors?.phone_code}
              />
            </div>
          </div>

          {/* select group */}
          <div className="col-12 mt-2">
            <SelectField
              label={movementManagement.selectGroup}
              name="officer-group"
              id="officer-group"
              options={groupsLookup || []}
              value={modalData.data?.group?.name || ""}
              onChange={(e) => {
                handleModalInputsChanges({ key: "group", value: e });
              }}
              color={errors?.group?.required ? "danger" : ""}
              errors={errors?.group}
            />
          </div>


          {/* action button */}
          <div className='col-12 mt-3'>
            <button className="btn save-btn"
              onClick={() => handleCreateUpdateOfficer(modalData.id)}
            >
              <SaveIcon color="#D4A655" />
              {operationStatement.save}
            </button>
          </div>
        </div>
      </ModalBody>





    </Modal>
  )
}
