import { useMarketplaceState } from "context/marketplace";
import moment from "moment";
import Locale from "translations";

export default function BannerInfo({ modeltoggel }) {
	const { marketPlace, inventory } = Locale;
	const { flightsSearch } = useMarketplaceState();

	return (
		<>
			<div className="banner-flight mb-4">
				<div className="container h-100 d-flex align-items-center">
					<div className="modify">
						<div>
							<p>{marketPlace.From}</p>
							<div className="steps">
								{flightsSearch.type === "3"
									? flightsSearch?.airLegs[0]?.from?.code
									: flightsSearch?.from?.code}
							</div>
						</div>
						<div>
							<p>{marketPlace.To}</p>
							<div className="steps">
								{flightsSearch.type === "3"
									? flightsSearch?.airLegs[0]?.to?.code
									: flightsSearch?.to?.code}
							</div>
						</div>
						<div>
							<p>{marketPlace.Depart}</p>
							<div className="steps">
								{moment(
									flightsSearch.type === "3"
										? flightsSearch?.airLegs[0]?.data
										: flightsSearch?.departureDate
								).format("DD/MM/YYYY")}
							</div>
						</div>
						{flightsSearch.type !== "2" && (
							<div>
								<p>{marketPlace.Retrun}</p>
								<div className="steps">
									{moment(
										flightsSearch.type === "1"
											? flightsSearch?.arrivalDate
											: flightsSearch?.airLegs[1]?.data
									).format("DD/MM/YYYY")}
								</div>
							</div>
						)}
						<div>
							<p>{inventory.messages.pax}</p>
							<div className="steps">{`${
								flightsSearch.children + flightsSearch.adults
							}, ${flightsSearch.cabinClass?.name}`}</div>
						</div>
						<div>
							<p>{marketPlace.airlines}</p>
							<div className="steps">{flightsSearch.airline?.label}</div>
						</div>
						<button
							className="btn  bg-nxt"
							onClick={modeltoggel}
							type="button"
							style={{ marginTop: 25 }}
						>
							<p className="d-flex align-items-center justify-content-center">
								{marketPlace.modify}
							</p>
						</button>{" "}
					</div>
				</div>
			</div>
		</>
	);
}
