import React, { useState } from "react";
import Locale from "translations";
import SelectField from "components/Form/SelectField/SelectField";
import TextFieldWithSelect from "components/Form/TextFieldWithSelect/TextFieldWithSelect";
import useFetchCountries from "hooks/useFetchCountries";
import { useSelector} from "react-redux";
import TextField from "components/Form/TextField/TextField";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import validate from "helpers/validate";
import moment from "moment";

export default function Accounting(props) {
	const { reservation,productsBuilder } = Locale;
	// const dispatch = useDispatch();
	//Store
	const reservationApp = useSelector((state) => state.reservation_reducer);
	// const [Travelers, setTravelers] = useState([]);
	const [countries] = useFetchCountries();

	// const [discount, setDiscount] = useState(reservationApp.discount ?? "");
	// const [additionalDiscount, setAdditionalDiscount] = useState(
	// 	reservationApp.additionalDiscount ?? ""
	// );
	// const [amount, setAmount] = useState(reservationApp.amount ?? "");

	const [comment, setComment] = useState(reservationApp.comment ?? "");

	// const [formValidation, setFormValidation] = useState({
	// 	discount: true,
	// 	additionalDiscount: true,
	// 	amount: true,
	// });

	const [accounting, setAccounting] = useState({
		discount: reservationApp.discount ?? [],
		additionalDiscount: reservationApp.additionalDiscount ?? "",
		amount: reservationApp.amount ?? "",
		amountDate: reservationApp.amountDate ?? "",
		comment: reservationApp.comment ?? "",
	});
	const [amountDate, setAmountDate] = useState(
		reservationApp.amountDate ?? moment()
	);

	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const checkFormsErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "discount", value: accounting.discount },
				{ required: true }
			),
			...validate(
				{ name: "amount", value: accounting.amount },
				{ required: true }
			),
			...validate(
				{ name: "amountDate", value: accounting.amountDate },
				{ required: true }
			),
			...validate(
				{ name: "comment", value: accounting.comment },
				{ required: true }
			),
		});
	};

	const handleCreate = async () => {
		checkFormsErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
		alert(`
			${productsBuilder.discountname} => ${accounting.discount.label}
			Amount => ${accounting.amount}
		`);
	};

	//ForExample To Searchable SelectField
	// const countriesList = countries.map((country, index) => (
	// 	<option key={`country-${country.id}${index}`} value={country.id}>
	// 		{country.name}
	// 	</option>
	// ));

	return (
		<>
			<div className="reservation__accounting-box">
				<h2 className="head">{reservation.messages.accounting}</h2>
				<div className="accounting-form">
					<div className="row">
						<div className="col-md-6">
							<div className="form-group full-input">
								<SelectField
									label={reservation.messages.discount}
									placeholder={reservation.messages.discountPlaceholder}
									options={countries}
									value={accounting.discount?.label}
									name="discount"
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: "discount", value: e.value },
												{ required: true }
											),
										});
										setAccounting({
											...accounting,
											discount: e,
										});
									}}
									errors={errors.discount}
									color={errors?.discount?.required ? "danger" : ""}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group full-input">
								<TextFieldWithSelect
									label={reservation.messages.additionalDiscount}
									placeholder={
										reservation.messages.additionalDiscountPlaceholder
									}
									value={accounting.additionalDiscount}
									name="additionalDiscount"
									onChange={(e) => {
										setAccounting({
											...accounting,
											additionalDiscount: e.target.value,
										});
									}}
									selectPosition="append"
								>
									<option value="en">SAR</option>
									<option value="ar">EGP</option>
								</TextFieldWithSelect>
							</div>
						</div>
						<div className="col-md-6">
							<div className="price ">
								<p>{reservation.messages.totalApplicationPrice}</p>
								<p>120,000 EGP</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="price">
								<p>{reservation.messages.paid}</p>
								<p>10,000 EGP</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="price font-weight-bold">
								<p className="text-success">{reservation.messages.paid}</p>
								<p className="text-success">10,000 EGP</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="price font-weight-bold">
								<p className="text-danger">{reservation.messages.unPaid}</p>
								<p className="text-danger">100,000 EGP</p>
							</div>
						</div>
						<div className="col-md-12">
							<h2 className="head">{reservation.messages.paysHistory}</h2>
							<table striped className="custom-table w-100">
								<thead>
									<tr>
										<th>{reservation.messages.amount}</th>
										<th>{reservation.messages.date}</th>
										<th>{reservation.messages.comment}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>10,000 EGP</td>
										<td>21/09/2020</td>
										<td>
											Lorem Ipsum is simply dummy text of the printing text of
											the printing and typesetting industry
										</td>
									</tr>
									<tr>
										<td>10,000 EGP</td>
										<td>21/09/2020</td>
										<td>
											Lorem Ipsum is simply dummy text of the printing text of
											the printing and typesetting industry
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="col-md-12">
							<h2 className="head">{reservation.messages.addPay}</h2>
							<div className="pay-form">
								<div className="pay-input">
									<TextField
										placeholder={reservation.messages.amountPlaceholder}
										label={reservation.messages.amount}
										value={accounting.amount}
										name="amount"
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
											setAccounting({
												...accounting,
												amount: e.target.value
													.replace(/[^\d]/g, "")
													.replace(/[^\w\s]/gi, "")
													.replace(/^0/, ""),
											});
										}}
										errors={errors.amount}
										color={errors?.amount?.required ? "danger" : ""}
									/>
								</div>
								<div className="pay-input">
									<DatePickerField
										placeholder="DD/MM/YYY"
										label={reservation.messages.date}
										date={amountDate}
										onChangeDate={setAmountDate}
										minDate={amountDate}
									/>
								</div>
								<div className="pay-input">
									<TextField
										placeholder={reservation.messages.commentPlaceholder}
										label={reservation.messages.comment}
										value={comment}
										onChange={(e) => setComment(e.target.value)}
									/>
								</div>
								<div className="pay-input">
									<button
										className="btn btn-primary"
										type="submit"
										onClick={handleCreate}
									>
										{reservation.messages.addPay}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
