import { Carousel } from "3d-react-carousal";
import { useSBSDispatch, useSBSState } from "context/global";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Collapse } from "reactstrap";
import Locale from "translations";
import SafaLogo from "../../assets/images/MarketInvoice/SafaLogo.png";
import sliceEn1 from "../../assets/images/newLanding/1.png";
import sliceEn2 from "../../assets/images/newLanding/2.png";
import sliceEn3 from "../../assets/images/newLanding/3.png";
import faqImg from "../../assets/images/newLanding/faq.png";
import masterImg from "../../assets/images/newLanding/master.png";

export default function NewLanding() {
	const { newLand } = Locale;
	const { locale } = useSBSState();
	const dispatch = useSBSDispatch();

	const history = useHistory();
	let slides = [
		<img src={sliceEn1} alt="1" />,
		<img src={sliceEn2} alt="2" />,
		<img src={sliceEn3} alt="4" />,
	];

	const [collapse, setCollapse] = useState(false);
	const [id, seId] = useState(null);

	const toggle = (Id) => {
		seId(Id);
		setCollapse(Id == id ? false : true);
	};
	return (
		<>
			<div className="T-newLanding w-100">
				<div className="newLanding ">
					<div className="">
						<header className="container-custom-land">
							<nav className="navbar navbar-default navbar-fixed dark no-background bootsnav py-3">
								{/* <div className="container"> */}
								<div className="navbar-header">
									<figure className="safa-logo">
										<img src={SafaLogo} alt="" className="img-fluid" />
									</figure>
								</div>
								<div className="attr-nav button">
									<ul className="d-flex justify-content-center alighn-items-center">
										<li
											className="btn-agent-lang pointer mx-2"
											onClick={() => {
												dispatch({
													type: "setLocale",
													payload: locale === "ar" ? "en" : "ar",
												});
												dispatch({ type: "setLoading", payload: true });
											}}
										>
											{locale === "ar" ? "English" : "العربية"}
											<i class="fas fa-flag mx-1"></i>
										</li>
										<li
											className="btn-agent-login pointer"
											onClick={() => history.push("/auth/login")}
										>
											{newLand.agentLogin}
										</li>
									</ul>
								</div>
								{/* </div> */}
							</nav>
						</header>
						<div className="section-1 banner-area with-carousel content-double transparent-nav bg-theme-small normal-text">
							<div className="box-table container-custom-land">
								<div className="box-cell">
									<div className="container-custom-land">
										<div className="double-items">
											<div className="row align-center">
												<div className="col-lg-5 left-info">
													<div className="content">
														<h1>
															{locale === "en" ? (
																<>
																	{newLand.seconeHeader}{" "}
																	<span style={{ fontSize: "38px" }}>
																		{newLand.secOneSpan}
																	</span>
																</>
															) : (
																<>
																	<span style={{ fontSize: "38px" }}>
																		{newLand.secOneSpan}{" "}
																	</span>
																	{newLand.seconeHeader}
																	{"  "}
																</>
															)}
														</h1>
														<span>{newLand.secOneSpan2}</span>
														<p>{newLand.secOneParag} </p>
														<button
															className="btn circle btn-dark border btn-sm"
															onClick={() => history.push("/auth/login")}
														>
															{newLand.getStarted}
														</button>
													</div>
												</div>
												<div className="col-lg-7 right-info carousel-box">
													<Carousel
														offsetRadius={2}
														slides={slides}
														autoplay={false}
														interval={1000}
														arrows={false}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* Start About  */}
						<div id="about" className="section-2 about-area ">
							<div className="container-custom-land">
								<div className="row">
									<div className="col-lg-6 default info">
										<div className="bottom-info">
											<h3>{newLand.WhyChooseUs}</h3>
											<ul>
												<li>
													<i className="fas fa-check"></i>{" "}
													<span>{newLand.about1}</span>
												</li>
												<li>
													<i className="fas fa-check"></i>{" "}
													<span>{newLand.about2}</span>
												</li>
												<li>
													<i className="fas fa-check"></i>{" "}
													<span>{newLand.about3}</span>
												</li>
												<li>
													<i className="fas fa-check"></i>{" "}
													<span>{newLand.about4}</span>
												</li>
												<li>
													<i className="fas fa-check"></i>{" "}
													<span>{newLand.about5}</span>
												</li>
											</ul>
											{/* <br/> */}
											<a
												onClick={() => history.push("/auth/login")}
												className="btn circle btn-theme effect btn-md btn-agent-login my-5 px-5 py-3 pointer"
											>
												{newLand.getStarted}
											</a>
										</div>
									</div>
									<div className="col-lg-6 services-info">
										<div className="row">
											<div className="col-lg-6 col-md-6 single-item">
												<div className="item">
													<i className="fas fa-hotel"></i>
													<h4>{newLand.HotelHeader}</h4>
													<p className="pnew">{newLand.hotelParag}</p>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 single-item">
												<div className="item">
													<i className="fas fa-credit-card"></i>
													<h4>{newLand.PaymentHeader}</h4>
													<p className="pnew">{newLand.PaymentParag}</p>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 single-item">
												<div className="item">
													<i className="fas fa-file-invoice"></i>
													<h4>{newLand.BookHeader}</h4>
													<p className="pnew">{newLand.BookParag}</p>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 single-item">
												<div className="item">
													<i className="fas fa-sync"></i>
													<h4>{newLand.safaHeader}</h4>
													<p className="pnew">{newLand.safaParag}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* End About */}
						{/* Start Service */}
						<div className="section-3 faq-area ">
							<div className="container-custom-land">
								<div className="row">
									<div className="col-lg-8 offset-lg-2">
										<div className="site-heading text-center">
											<h2>{newLand.FAQs}</h2>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-4 thumb">
										<img src={faqImg} alt="Thumb" />
									</div>
									<div className="col-lg-8 faq-items default-padding-bottom">
										{/* Start Accordion  */}
										<div className="faq-content">
											<div className="accordion" id="accordionExample">
												<div className="card">
													<div
														className="card-header  d-flex justify-content-between"
														id="headingOne"
														onClick={() => toggle(1)}
													>
														<h4 className="mb-0 pinter">{newLand.collh1}</h4>
														<i
															className={`fas 
															fa-chevron-right
														`}
														></i>
													</div>
													<Collapse isOpen={id == 1 && collapse}>
														<div id="collapseOne" className="collapse show">
															<div className="card-body">
																<p>{newLand.collp1}</p>
															</div>
														</div>
													</Collapse>
												</div>
												<div className="card">
													<div
														className="card-header d-flex justify-content-between"
														id="headingTwo"
														onClick={() => toggle(2)}
													>
														<h4 className="mb-0 collapsed  ">
															{newLand.collh2}
														</h4>
														<i
															className={`fas 
															fa-chevron-right
														`}
														></i>
													</div>
													<Collapse isOpen={id == 2 && collapse}>
														<div id="collapseTwo" className="collapse show">
															<div className="card-body">
																{locale === "en" ? (
																	<p>
																		Open the URL{" "}
																		<a
																			href="https://sbs.safasoft.com/auth/login"
																			style={{ color: "#123456 " }}
																		>
																			https://sbs.safasoft.com/auth/login
																		</a>{" "}
																		<br />
																		- Click on "Signup” Button, register page
																		would get display.
																		<br />
																		- Fill all mandatory details like agency
																		name, owner name, address.
																		<br />
																		- Click on the "Register" button.
																		<br />- You will receive a verification mail
																		to activate your account, and then you can
																		login easily.
																	</p>
																) : (
																	<p>
																		افتح الرابط التالي
																		<a
																			href="https://sbs.safasoft.com/auth/login"
																			style={{ color: "#123456 " }}
																		>
																			https://sbs.safasoft.com/auth/login{" "}
																		</a>
																		<br />
																		- انقر على سجل وسيتم انتقالك لصفحة البيانات.
																		<br />- قم بإدخال البيانات الخاصة بك مثل اسم
																		الشركة واسم صاحب الشركة والعنوان ..إلخ
																		<br />
																		- اكمل البيانات وبعد ذلك اضغط على تسجيل.
																		<br />- سيتم ارسال بريد إليكترونى لك على
																		البريد الذى قمت بإدخاله فى البيانات وبه رابط
																		التعريف الخاص بتفعيل الحساب الخاص بك لدينا.
																	</p>
																)}
															</div>
														</div>
													</Collapse>
												</div>
												<div className="card">
													<div
														className="card-header d-flex justify-content-between"
														id="headingThree"
														onClick={() => toggle(3)}
													>
														<h4 className="mb-0 collapsed  ">
															{newLand.collh3}
														</h4>
														<i
															className={`fas 
															fa-chevron-right
														`}
														></i>
													</div>
													<Collapse isOpen={id == 3 && collapse}>
														<div id="collapseThree" className="collapse show">
															<div className="card-body">
																{locale === "en" ? (
																	<p>
																		Open the URL{" "}
																		<a
																			href="https://sbs.safasoft.com/auth/login"
																			style={{ color: "#123456 " }}
																		>
																			https://sbs.safasoft.com/auth/login
																		</a>{" "}
																		<br />
																		- Click on the Forgot password link.
																		<br />
																		- Enter e-mail ID which is registered in
																		“SBS”.
																		<br />
																		- Click on the “Reset Password” button.
																		<br />
																		- You will receive an e-mail to reset your
																		password.
																		<br />
																		- Create a new password for you.
																		<br />- Click on the “Reset” button.
																	</p>
																) : (
																	<p>
																		افتح الرابط التالي
																		<a
																			href="https://sbs.safasoft.com/auth/login"
																			style={{ color: "#123456 " }}
																		>
																			https://sbs.safasoft.com/auth/login{" "}
																		</a>
																		<br />
																		- انقر على رابط هل نسيت كلمة السر؟
																		<br />
																		- قم بإدخال البريد الخاص بك المسجل لدينا.
																		<br />
																		- انقر على أعد ضبط كلمة السر وسيتم إرسال
																		بريد لك وبه رابط لإعادة تعيين كلمة سر جديدة.
																		<br />- قم بإختيار كلمة سر جديدة لك وقم بعد
																		ذلك بالضغط على إعادة التعيين.
																	</p>
																)}
															</div>
														</div>
													</Collapse>
												</div>
												<div className="card">
													<div
														className="card-header  d-flex justify-content-between"
														id="headingFour"
														onClick={() => toggle(4)}
													>
														<h4 className="mb-0 collapsed">{newLand.collh4}</h4>
														<i
															className={`fas 
															fa-chevron-right
														`}
														></i>
													</div>
													<Collapse isOpen={id == 4 && collapse}>
														<div id="collapseFour" className="collapse show">
															<div className="card-body">
																{locale === "en" ? (
																	<p>
																		Please drop an e-mail to us on{" "}
																		<a
																			href="mailto:operation@safasoft.com"
																			style={{ color: "#123456 " }}
																		>
																			operation@safasoft.com
																		</a>{" "}
																		with the agency name and contact details.
																	</p>
																) : (
																	<p>
																		برجاء مراسلتنا على البريد التالى{" "}
																		<a
																			href="mailto:operation@safasoft.com"
																			style={{ color: "#123456 " }}
																		>
																			operation@safasoft.com{" "}
																		</a>{" "}
																		مع ذكر اسم الشركة ورقم التواصل معك.
																	</p>
																)}
															</div>
														</div>
													</Collapse>
												</div>

												<div className="card">
													<div
														className="card-header  d-flex justify-content-between"
														id="headingFour"
														onClick={() => toggle(5)}
													>
														<h4 className="mb-0 collapsed">{newLand.collh5}</h4>
														<i
															className={`fas 
															fa-chevron-right
														`}
														></i>
													</div>
													<Collapse isOpen={id == 5 && collapse}>
														<div
															id="collapseFive"
															className="collapse show"
															aria-labelledby="headingFour"
															data-parent="#accordionExample"
														>
															<div className="card-body">
																{locale === "en" ? (
																	<p>
																		There are Three modes of making a payment.
																		<br />
																		1 – Payment by using credit cards
																		(MasterCard or Visa Card).
																		<br />
																		2 – Payment through the Wallet.
																		<br />3 – Payment by making a bank transfer
																		into our bank account.
																	</p>
																) : (
																	<p>
																		هناك ثلاث طرق للسداد:
																		<br />
																		1 – الدفع باستخدام كروت الائتمان (ماستر كارد
																		أو فيزا كارد).
																		<br />
																		2 – الدفع من خلال محفظة صفا الالكترونية.
																		<br />
																		3 – الدفع عن طريق التحويل البنكي.
																		<br />
																	</p>
																)}
															</div>
														</div>
													</Collapse>
												</div>

												<div className="card">
													<div
														className="card-header d-flex justify-content-between"
														id="headingFour"
														onClick={() => toggle(6)}
													>
														<h4 className="mb-0 collapsed show">
															{newLand.collh6}
														</h4>
														<i
															className={`fas 
															fa-chevron-right
														`}
														></i>
													</div>
													<Collapse isOpen={id == 6 && collapse}>
														<div id="collapseSix" className="collapse show">
															<div className="card-body">
																{locale === "en" ? (
																	<p>
																		Yes you can book any hotel room and pay it
																		later within 48 hours “in case the booking
																		is refundable” by selecting the pay later
																		option.
																	</p>
																) : (
																	<p>
																		نعم يمكنك الدفع لاحقا فى خلال 48 ساعة من
																		تاريخ الحجز بشرط ان يكون الحجز قابل للإلغاء
																		من خلال اختيار خاصية "ادفع لاحقا"
																	</p>
																)}
															</div>
														</div>
													</Collapse>
												</div>
											</div>
										</div>
										{/* End Accordion */}
									</div>
								</div>
							</div>
						</div>
						{/* End Service */}

						<footer className="new-padding bg-light">
							<div className="container-custom-land">
								{/* Start Footer Bottom  */}
								<div className="footer-bottom">
									<div className="col-lg-12">
										<div className="row">
											<div className="col-lg-6">
												<p>{newLand.copyright}</p>
											</div>
											<div className="col-lg-6 text-right link">
												<div className="address-items">
													<ul className="social">
														<li className="facebook mx-3">
															<a
																href="https://www.facebook.com/Safasoftofficial"
																target="_blank"
																rel="noreferrer"
															>
																<i className="fab fa-facebook-f"></i>
															</a>
														</li>

														<li className="twitter">
															<a
																href="https://twitter.com/safa_soft"
																target="_blank"
																rel="noreferrer"
															>
																<i className="fab fa-twitter"></i>
															</a>
														</li>
														<li className="pinterest">
															<a
																href="https://www.linkedin.com/company/safa-soft/"
																target="_blank"
																rel="noreferrer"
															>
																<i className="fab fa-linkedin"></i>
															</a>
														</li>
														<li className="instagram">
															<a
																href="https://www.instagram.com/safasoftofficial/"
																target="_blank"
																rel="noreferrer"
															>
																<i className="fab fa-instagram"></i>
															</a>
														</li>
														<li className="youtube">
															<a
																href="https://www.youtube.com/@safasoftofficial"
																target="_blank"
																rel="noreferrer"
															>
																<i className="fab fa-youtube"></i>
															</a>
														</li>
														<li className="instagram"></li>
														<li className="master-card">
															<img src={masterImg} alt="master" width="120" />
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* End Footer Bottom  */}
							</div>
						</footer>
					</div>
				</div>
			</div>
		</>
	);
}
