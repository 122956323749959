import React from 'react'
import { Modal } from 'reactstrap';
import Locale from 'translations';


export default function PriceBreakdownModal({ isOpen, toggle, flightData, adultsTotalPrice, childrenTotalPrice, adultsChildrenCount }) {
  const { marketPlace, inventory } = Locale;


  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={'lg'}
      centered={true}
    >
      <div className="d-flex align-items-center justify-content-between p-2 px-4 pt-0 w-100">
        <h3>
          {marketPlace.Pricebreakdown}
        </h3>
        <a href="##"
          onClick={toggle} className='btn-x'
        >
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div className='table-container table-responsive'>

      <table
        striped
        className="table-update p-2 bg-white m-0 table table-striped"
      >
        <thead>
          <tr>
            <th>{marketPlace.Passengertype}</th>
            <th>{marketPlace.Fare}</th>
            <th>{marketPlace.Taxes}</th>
            <th>{marketPlace.Ticketingfee}</th>
            <th>{marketPlace.passanger}</th>
            <th>{marketPlace.total}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{inventory.messages.Adult}</td>
            <td>{flightData?.adtFare?.toFixed(2)}</td>
            <td>{flightData?.adtTax?.toFixed(2)}</td>
            <td>{flightData?.tktFee?.toFixed(2)}</td>
            <td>x{flightData?.adults}</td>
            <td> {adultsTotalPrice.toFixed(2)} {flightData?.currency}</td>
          </tr>
          {flightData?.children > 0 ?
            <tr>
              <td>{inventory.messages.Child}</td>
              <td>{flightData?.chdFare?.toFixed(2)}</td>
              <td>{flightData?.chdTax?.toFixed(2)}</td>
              <td>{flightData?.tktFee?.toFixed(2)}</td>
              <td>x{flightData?.children}</td>
              <td> {childrenTotalPrice.toFixed(2)} {flightData?.currency}</td>
            </tr>
            :
            null
          }
        </tbody>
      </table>
      </div>

      <div className="d-flex justify-content-between align-items-center p-3">
        <p>
          {`${marketPlace.total} ${marketPlace.passanger} `}
          <strong style={{ color: "#0050A6", fontWeight: "bold" }}>
            X{adultsChildrenCount}
          </strong>
        </p>
        <div className='d-flex flex-column align-items-end'>
          <p className='total' style={{ color: "#0050A6", lineHeight: "0.5", fontSize: "1.2rem" }}>
            {flightData?.totalPrice?.toFixed(2)}{" "}
            {flightData?.currency}</p>
          <span style={{ fontSize: "small" }}>{marketPlace.NotIncludingPayment}</span>
        </div>
      </div>
    </Modal>
  )
}
