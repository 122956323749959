import React from 'react'
import AccountingVisaItem from './VisaItem'

export default function AccountingVisa({ isPricePerService, accountingStatement, setAccountingStatement, canEdit }) {
  const visaData = accountingStatement?.visas;
  function handleInputsChanges(value, priceKey, totalKey, isTotal, visaIndex) {
    let accountingStatementClone = { ...accountingStatement };
    let visasClone = [...visaData];
    let selectedVisa = { ...visasClone?.[visaIndex] };
    let total;
    let pricePerPax;
    if (isTotal) {
      total = +value;
      pricePerPax = +value / selectedVisa?.total_pax;
    } else {
      total = +value * selectedVisa?.total_pax;
      pricePerPax = +value;
    }
    selectedVisa = {
      ...selectedVisa,
      pricing: {
        ...selectedVisa?.pricing,
        [priceKey]: pricePerPax.toString(),
        [totalKey]: total.toString(),
      }
    }
    visasClone[visaIndex] = selectedVisa;
    accountingStatementClone = { ...accountingStatementClone, visas: visasClone };
    setAccountingStatement(accountingStatementClone);
  }

  function deleteVisaItemPricing(visaIndex) {
    let accountingStatementClone = { ...accountingStatement }
    let visasClone = [...visaData];
    let selectedVisa = { ...visasClone?.[visaIndex], pricing: null };
    visasClone[visaIndex] = selectedVisa;
    accountingStatementClone = { ...accountingStatementClone, visas: visasClone };
    setAccountingStatement(accountingStatementClone);
  }

  return (
    <div className='accounting-item attractions  py-0'>
      <div className="d-flex flex-column gap-10">
        {visaData?.map((visaItem, index) => {
          return (
            <div className="accounting-item-wrapper" key={`visa-${visaItem?.id}`}>
              <AccountingVisaItem
                item={visaItem}
                index={index}
                handleInputsChanges={handleInputsChanges}
                deleteVisaItemPricing={deleteVisaItemPricing}
                isPricePerService={isPricePerService}
                canEdit={canEdit}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
