import { useSBSState } from "context/global";
import moment from "moment";
import { useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import datePickerIcon from "assets/images/datePicker.svg";
import calendarIcon from "assets/images/market-place/servicesIcon/calendarIcon.png";
export default function DateRangeField(props) {
	const [focused, setFocused] = useState(false);
	const { locale } = useSBSState();

	const prependText = (
		<div
			className={`control-field__prepend ${props.color ? " control-field__prepend--" + props.color : ""
				}`}
		>
			<span className="form-control border-0 text-headline text-center pt-2">
				{props.extraText}
			</span>
		</div>
	);

	return (
		<>
			{/* Label */}
			<label
				className={`control-field__label ${props.color ? " control-field__label--" + props.color : ""
					}`}
			>
				{props.label} {props.withStar?<span style={{color:"red"}}>*</span>:null}
			</label>
			<div
				className={`my-1 control-field + ${props.color ? " control-field--" + props.color : ""
					}  ${props.disabled ? "isDisabled" : ""
					} `}
			>
				{/* Text Field Prepend */}
				{props.extraTextPosition === "prepend" ? prependText : ""}
				{/* Date Range Body */}
				<div
					className={`control-field__body ${props.disabled && props.readOnly ? "isDisabled" : ""
						}`}
				>


					{/* Date Inputs */}
					<div className="justify-content-between position-relative d-flex">
						<DateRangePicker
							{...props}
							startDatePlaceholderText={props.startDatePlaceholder}
							endDatePlaceholderText={props.endDatePlaceholder}
							startDate={props?.startDate? moment(props.startDate):null}
							endDate={props?.startDate?moment(props.endDate):null}
							onDatesChange={props.onDatesChange}
							focusedInput={focused}
							displayFormat={() => "DD/MM/YYYY"}
							onFocusChange={(focused) => setFocused(focused)}
							disabled={props.disabled}
							required={props.required}
							readOnly={props.readOnly}
							hideKeyboardShortcutsPanel={true}
							customArrowIcon={false}
							noBorder={true}
							isRTL={locale === "en" ? false : true}
							anchorDirection={locale === "en" ? false : true}

						/>
						{/* <i className="fas fa-calendar-alt date-icon"></i> */}
						<img className="date-icon-range p-2" src={props.labelInner?calendarIcon:datePickerIcon} alt=""  width={40} height={40}/>

					</div>
				</div>
			</div>

			{/* Feedback */}
			<small
				className={`control-field__feedback control-field__feedback--${props.color
					} ${props.isInvalid ? "d-block" : "d-none"}`}
			>
				{props.feedbackMessage}
			</small>
		</>
	);
}
