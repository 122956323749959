import React, { useCallback } from 'react'
import SelectField from 'components/Form/SelectField/SelectField'
import TextField from 'components/Form/TextField/TextField';
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import moment from 'moment';
import { useSBSState } from 'context/global';
import Locale from 'translations';



export default function TransportationVehicles({ vehiclesList, vehiclesTypesLookups, addNewVehicle, deleteVehicle, handleVehiclesInputs, errors, operationHasSent }) {
  const { locale } = useSBSState();
  const { operationStatement } = Locale;

  const generateYearsList = useCallback((startYear, endYear) => {
    startYear = startYear ? moment(startYear) : moment();
    endYear = endYear ? moment(endYear) : moment();
    const diffOfDates = endYear.year() - startYear.year() + 1
    const yearsArr = [...Array(diffOfDates).keys()].map(i => {
      return {
        id: i + startYear.year(),
        value: i + startYear.year(),
        name: i + startYear.year(),
        label: i + startYear.year(),
      }
    });
    return yearsArr;
  }, [])

  const modelsList = generateYearsList("2000");

  return (
    <div className='item-row'>
      <h4 className="header">
        {operationStatement.vehicles}
      </h4>
      <div className="row mx-0 p-2">
        <div className="col-md-3 col-12">
          <label htmlFor="" className='label'>{operationStatement.Vehicletype}</label>
        </div>
        <div className="col-md-3 col-12">
          <label htmlFor="" className='label'>{operationStatement.Model}</label>
        </div>
        <div className="col-md-2 col-12">
          <label htmlFor="" className='label'>{operationStatement.Count}</label>
        </div>
      </div>
      {vehiclesList?.map((vehicle, index) => {
        return (
          <div className="row mx-0 px-2" key={`vehicle-${index}`}>
            <div className="col-md-3 col-12">
              <SelectField
                hasLabel={false}
                label={operationStatement.Vehicletype}
                placeholder={operationStatement.Select}
                id={`vehicle-type-${index}`}
                name={`vehicle-type-${index}`}
                options={vehiclesTypesLookups}
                value={vehicle?.vehicle_type_id?.name?.[locale] || vehicle?.vehicle_type_id?.name}
                onChange={(e) => handleVehiclesInputs(e, "vehicle_type_id", index)}
                color={errors?.[`vehicle_type_id-${index}`]?.required ? "danger" : ""}
                errors={errors?.[`vehicle_type_id-${index}`]}
                disabled={operationHasSent}
              />
            </div>
            <div className="col-md-3 col-12">
              <SelectField
                hasLabel={false}
                label={operationStatement.VehicleModel}
                placeholder={operationStatement.Select}
                id={`vehicle-model-${index}`}
                name={`vehicle-model-${index}`}
                options={modelsList}
                value={vehicle?.vehicle_model?.label || operationHasSent}
                onChange={(e) => handleVehiclesInputs(e, "vehicle_model", index)}
                disabled={!vehicle?.vehicle_type_id || operationHasSent}
                color={errors?.[`vehicle_model-${index}`]?.required ? "danger" : ""}
                errors={errors?.[`vehicle_model-${index}`]}

              />
            </div>
            <div className="col-md-2 col-12">
              <TextField
                type="number"
                hasLabel={false}
                label={operationStatement.Count}
                placeholder={operationStatement.Count}
                id={`vehicle-count-${index}`}
                name={`vehicle-count-${index}`}
                value={vehicle?.count}
                onChange={(e) => handleVehiclesInputs(+e.target.value > 0 ? e.target.value : "", "count", index)}
                color={errors?.[`count-${index}`]?.required ? "danger" : ""}
                errors={errors?.[`count-${index}`]}
                disabled={operationHasSent}

              />
            </div>

            {!operationHasSent ?
              <button className='btn px-1 py-0 mt-1 font-16' onClick={() => deleteVehicle(index)}>
                <DeleteIcon />
              </button>
              : null}
          </div>
        )
      })}
      <div className="row mx-0 px-2">
        {!operationHasSent ?
          <button className='btn-plus-operation my-3'
            onClick={() => addNewVehicle()} >
            <span className='me-1 ms-0'><i class="fas fa-plus"></i></span>
            {operationStatement.AddVehicle}
          </button>
          : null}
      </div>

    </div>
  )
}
