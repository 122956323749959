import { useState } from 'react';
import OfficialMovementGroup from './Groups';
import OfficialMovementManagement from './Officers';
import BreadCrump from 'modules/UmrahOperations/shared/BreadCrump';
import { UsersGroupIcon, UserTagIcon } from 'modules/UmrahOperations/shared/Icons';
import Locale from 'translations';
import ShowSubscription from 'helpers/ShowSubscription';


export default function OfficialMovementContainer() {

  const [movementActiveTab, setMovementActiveTab] = useState('officers');

  const [movementOfficers, setMovementOfficers] = useState({
    list: null,
    meta: null
  });

  const [movementGroups, setMovementGroups] = useState({
    list: null,
    meta: null
  });


  // toggle tabs and reset search
  function toggleMovementsTabs(tab) {
    setMovementActiveTab(tab);
  }





  return (
    <ShowSubscription module="official_management">
      <div className="official-movement-container px-new-26">
        <BreadCrump title={movementActiveTab === "officers" ? Locale.movementManagement.delegatesManagement : Locale.movementManagement.delegatesGroup} />
        <div className='tabs'>
          <button className={`btn shadow-none movement-manage  ${movementActiveTab === 'officers' ? 'selected' : ''}`}
            onClick={() => toggleMovementsTabs("officers")}
          >
            <UserTagIcon />
            {Locale.movementManagement.delegations}
          </button>
          <button className={`btn shadow-none movement-group ${movementActiveTab === 'group' ? 'selected' : ''}`}
            onClick={() => toggleMovementsTabs("group")}
          >
            <UsersGroupIcon />
            {Locale.movementManagement.delegatesGroup}
          </button>
        </div>

        <div className='tab-content'>
          {movementActiveTab === 'group' &&
            <OfficialMovementGroup
              groupsList={movementGroups.list}
              groupsMeta={movementGroups.meta}
              setMovementGroups={setMovementGroups}
            />
          }
          {movementActiveTab === 'officers' &&
            <OfficialMovementManagement
              officersList={movementOfficers.list}
              officersMeta={movementOfficers.meta}
              setMovementOfficers={setMovementOfficers}
            />
          }
        </div>

        {/* <Pagination info={movementGroupsList.meta} /> */}




      </div>
    </ShowSubscription>

  )
}
