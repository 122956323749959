import { useParams } from 'react-router-dom';
import ViewAgentOperations from './AgentOperation';
import ViewAgentViolations from './AgentViolations';
import BreadCrump from 'modules/UmrahOperations/shared/BreadCrump';
import { useEffect, useState } from 'react';
import { fetchAccountingAgentViolation } from 'services/operationStatement';
import AccountingAgentHeader from './Header';
import Locale from 'translations';
import ShowSubscription from 'helpers/ShowSubscription';

export default function ViewAccountingAgent() {
  const { id, reference } = useParams();

  const [activeTab, setActiveTab] = useState(reference === "agent-statement" ? "statement" : "violations")

  function toggle(tab) {
    setActiveTab(tab)
  }

  const [agentData, setAgentData] = useState(null);



  useEffect(() => {
    async function getAgentViolationDetails() {
      const res = await fetchAccountingAgentViolation(id);
      if (res?.status === 200) {
        setAgentData(res?.data?.data);
      }
    }
    if (id && !agentData) {
      getAgentViolationDetails();
    }
  }, [id, agentData]);


  useEffect(() => {
    setActiveTab(reference === "agent-statement" ? "statement" : "violations")
  }, [reference])


  return (
    <ShowSubscription module="agent_accounting">
      <div className="official-movement-container px-new-26">
        <BreadCrump title={Locale.operationAccounting.operationStatementAccounting} />

        <AccountingAgentHeader
          agentData={agentData}
          toggle={toggle}
          activeTab={activeTab}
        />
        
        {activeTab === "statement" && <ViewAgentOperations />}
        {activeTab === "violations" && <ViewAgentViolations />}

      </div>
    </ShowSubscription>
  )
}
