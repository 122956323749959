import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ArrowDownIcon } from "./Icons";
import ShowForPermission from "helpers/showForPermission";
import { useSBSState } from 'context/global';
import { ReactComponent as UpgradeSubscription } from "assets/images/upgrade-subscription.svg";

// import ShowSubscription from 'helpers/ShowSubscription';
// import { isFormValid } from 'helpers/validate';

export default function OperationTabs({
	tabs,
	activeTab,
	setActiveTab,
	classNames,
	resetFilters,
	IsNotPushUrl
}) {
	const { subscriptionPlan } = useSBSState();
	const { push } = useHistory();
	const hasAction = true;

	return (
		<ul className={`operation-tabs ${classNames}`}>
			{tabs?.map((tab) => {
				if (tab?.permission) {
					return (
						<ShowForPermission permission={tab?.permission} key={`nav-${tab?.url}`}>
							<TabItem
								tab={tab}
								activeTab={activeTab}
								push={push}
								hasAction={hasAction}
								setActiveTab={setActiveTab}
								resetFilters={resetFilters}
								subscriptionPlan={subscriptionPlan}
								IsNotPushUrl={IsNotPushUrl}
							/>
						</ShowForPermission>
					);
				} else {
					return (
						<TabItem
							key={`nav-${tab?.url}`}
							tab={tab}
							activeTab={activeTab}
							push={push}
							hasAction={hasAction}
							setActiveTab={setActiveTab}
							resetFilters={resetFilters}
							subscriptionPlan={subscriptionPlan}
							IsNotPushUrl={IsNotPushUrl}
						/>
					);
				}

			})}
		</ul>
	);
}


function TabItem({ tab, activeTab, push, hasAction, setActiveTab, resetFilters, subscriptionPlan, IsNotPushUrl }) {
	const moduleHasSubscription = tab?.subscriptionModule && !subscriptionPlan?.includes(tab?.subscriptionModule)

	return (
		<li className={`item ${activeTab === tab?.url ? "active" : ""} ${tab?.disabled ? "disabled-li-Tabs" : ""} ${tab?.hidden ? "d-none" : ""}`}
			onClick={() => {
				if (!tab?.disabled && !tab?.subTabs) {
					if (!IsNotPushUrl) {
						push(`/operations/${tab?.url}`);
					}
					if (hasAction) {
						setActiveTab(tab?.url);
					}
					resetFilters && IsNotPushUrl && resetFilters(tab?.url);
				}

			}}
			key={tab?.url}
		>
			{tab?.icon}

			<div>
				<span className="me-2">{tab?.title}</span>

				{moduleHasSubscription
					? <span className='upgrade-subscription-icon mx-1'><UpgradeSubscription /></span>
					: ""
				}

				{tab?.subTabs?.length > 0 && <ArrowDownIcon color="#707170" />}
			</div>

			{tab?.subTabs?.length > 0 ?
				<ul className="sub-tabs">
					{tab?.subTabs?.map((subTab) => {
						if (subTab?.permission) {
							return (
								<ShowForPermission permission={subTab?.permission} key={`nav-${subTab?.url}`}>
									<SubTabItem
										push={push}
										activeTab={activeTab}
										subTab={subTab}
										tab={tab}
										setActiveTab={setActiveTab}
										subscriptionPlan={subscriptionPlan}
										IsNotPushUrl={IsNotPushUrl}
									/>
								</ShowForPermission>
							)
						} else {
							return (
								<SubTabItem
									key={subTab?.url}
									push={push}
									activeTab={activeTab}
									subTab={subTab}
									tab={tab}
									setActiveTab={setActiveTab}
									subscriptionPlan={subscriptionPlan}
									IsNotPushUrl={IsNotPushUrl}
								/>
							)
						}
					})}
				</ul>
				:
				null
			}

		</li>
	)
}

function SubTabItem({ push, activeTab, subTab, tab, setActiveTab, subscriptionPlan, IsNotPushUrl }) {
	const { pathname } = useLocation();

	const isSubTabActive =
		activeTab === subTab?.parentUrl
		&& (pathname.split("/").pop() === subTab?.url
			|| pathname.split("/").slice(1, -1).pop().includes(subTab?.url));
	const subModuleHasSubscription = subTab?.subscriptionModule && !subscriptionPlan?.includes(subTab?.subscriptionModule);

	return (
		<li className={`sub-tab-item ${isSubTabActive ? "active" : ""}`}
			onClick={() => {
				if (!tab?.disabled) {
					if (!IsNotPushUrl) {
						push(
							`/operations/${subTab?.parentUrl}/${subTab?.url}`
						);
					}
					setActiveTab(subTab?.parentUrl);
				}

			}}
		>
			{subTab?.icon}
			<span className="mx-1">{subTab?.title}</span>
			{subModuleHasSubscription
				? <span className='upgrade-subscription-icon'><UpgradeSubscription /></span>
				: ""
			}
		</li>
	)
}