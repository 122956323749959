import React from "react";
import arrowdown from 'assets/images/down-arrow-svgrepo-com.png'

export default function CityBanner({ cityDetails }) {
	return (
		<div className="banner"
			style={{
				backgroundImage: `url(${cityDetails?.city_banner})`

			}}
		>
			<div className="badge-banner">
				<h1>{`${cityDetails?.discounts[0]?.discount}% OFF`}</h1>
			</div>
			<div className='off-arrow' >
				<img src={arrowdown} alt="" srcset="" />
			</div>
			<div className="banner-name">
				<h1 className="">{cityDetails?.city_name}</h1>
			</div>
		</div>
	);
}
