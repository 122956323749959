import React, { createContext, useContext, useReducer } from "react";

const InterestsContext = createContext();
const InterestsDispatchContext = createContext();

const initialState = {};

function interestsReducer(state, action) {
	switch (action.type) {
		case "fetchMakkahHotel": {
			return { ...state, makkahHotel: action.payload };
		}
		case "fetchMadinaHotel": {
			return { ...state, madinaHotel: action.payload };
		}
		case "fetchTransporterHotel": {
			return { ...state, countries: action.payload };
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useInterestsState() {
	const context = useContext(InterestsContext);
	if (context === undefined) {
		throw new Error(
			"useInterestsState must be used within a InterestsProvider"
		);
	}
	return context;
}

function useInterestsDispatch() {
	const context = useContext(InterestsDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useInterestsDispatch must be used within a InterestsProvider"
		);
	}
	return context;
}

function InterestsProvider({ children }) {
	const [state, dispatch] = useReducer(interestsReducer, initialState);
	return (
		<InterestsContext.Provider value={state}>
			<InterestsDispatchContext.Provider value={dispatch}>
				{children}
			</InterestsDispatchContext.Provider>
		</InterestsContext.Provider>
	);
}

export { InterestsProvider, useInterestsState, useInterestsDispatch };
