import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, Link } from 'react-router-dom'
import { ReactComponent as UpgradeSubscription } from "assets/images/upgrade-subscription.svg";
import useNavigationBar from './useNavigationBar';
import { ReactComponent as DashboradIcon } from "assets/images/dashboard/dashboard-icon.svg";
import {  useSBSState } from 'context/global';
import Locale from 'translations';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';


export default function NavigationBar() {
  // ** hooks
  const { dashboard } = Locale;
  const location = useLocation();

  const { fetchDynamicNav } = useNavigationBar();
  const { dynamicNav } = useSBSState();
  const tripBuilderMatch = useRouteMatch("/:bundle/products-builder");
  const webBuilderMatch = useRouteMatch("/:bundle/web-builder-v2");
  // ** states
  const [activeNav, setActiveNav] = useState(null);

  // ** functions
  useEffect(() => {
    
    if (!dynamicNav) {
      fetchDynamicNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 
  useEffect(() => {
    if (dynamicNav && dynamicNav.length > 0 && location.pathname !== "/") {
      setActiveNav(dynamicNav.find(navTab => {
        if ((tripBuilderMatch?.params?.bundle || webBuilderMatch?.params?.bundle) && navTab?.category) {
          let matchParams = tripBuilderMatch?.params?.bundle || webBuilderMatch?.params?.bundle;
          return matchParams === navTab?.category;
        }
        // check if current location path is contain route or alias route
        return navTab.modules?.find(({ route, aliasRoute }) => {
          if (aliasRoute) {
            return (
              aliasRoute?.includes(location.pathname) ||
              location.pathname.includes(aliasRoute) ||
              location.pathname.includes(route) ||
              route?.includes(location.pathname)
            )
          }
          return (
            location.pathname.includes(route) ||
            route?.includes(location.pathname)
          )
        })
      }));
    }
  }, [location.pathname, dynamicNav, tripBuilderMatch?.params?.bundle, webBuilderMatch?.params?.bundle]);



  return (
    <nav>
      <ul className="main-nav-container">
        <li>
          <NavLink to="/dashboard" className={`item ${location.pathname === "/" ? "active" : ""}`} activeClassName="active">
            <DashboradIcon />
            {dashboard.messages.dashboard}
          </NavLink>
        </li>
        {dynamicNav?.map((navItem, index) => {
          const activeTab =
            location.pathname !== "/" &&
            (activeNav?.modules?.find(tabModule => tabModule?.parentKey === navItem?.key)
              || `/plan-details/${navItem?.category}` === location.pathname);

          return (
            <li key={`${navItem.key}-${index}`}>
              <Link to={navItem?.route || navItem?.modules?.[0]?.route}
                className={`item ${activeTab ? "active" : ""}`}
                activeClassName="active"
              >
                {navItem?.icon}
                {navItem.name}
                {"is_subscribed" in navItem && !navItem?.is_subscribed && <UpgradeSubscription />}
              </Link>
            </li>
          )
        })}
      </ul>
      {/* sub navigation */}
      {activeNav?.modules?.length > 0 &&
        <ul className="subnav-contianer">
          {activeNav?.modules?.map((tabModule, index) => {
            const activeModule = location.pathname.includes(tabModule.aliasRoute || tabModule.route);
            return (
              <li key={`${tabModule.route}-${index}`}>
                <NavLink to={tabModule.route} className={`sub-item ${activeModule ? "active" : ""}`}
                  activeClassName="active"
                >
                  {tabModule.name}
                </NavLink>
              </li>
            )
          })}
        </ul>
      }
    </nav>
  )
}


