import React, { Fragment, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Locale from 'translations'
import { useSBSState } from 'context/global'
import OTPInput from 'react-otp-input'

const SubscriptionTermsModel = ({
	isOpen,
	toggle,
	setIsOpen,
	sentEmail
}) => {
	const { subscriptionModule } = Locale
	const { locale } = useSBSState();
	const [checked, setChecked] = useState(false)

	return (
		<Modal isOpen={isOpen} centered size='lg' className='subscription-terms-model'>
			<ModalHeader toggle={toggle}>
			Terms and Conditions
			</ModalHeader>
			<ModalBody>
				 <div className='terms-condition'>
					<div className='data-wrapper'>
					<div className='data'>
						<p>الجانب التقنى (يجب الموافقة على الشروط والاحكام عند البدء فى كل محاولة لتشيير الباكدج اوالبرنامج او الخدمة من جانب شركة السياحة الراغبة فى التسوق عبر المنصة ) فى صورة الشروط والاحكام والضغط الالزامى للموافقة (ويتم تحويل الموافقة لتوقيع إليكترونى) Token  على ان يتم الموافقة من جانب المدير الخاص للشركة وليس لأى موظف.</p>
						<p>1 – تمنح منصة صفا الامتياز لشركة السياحة للتسويق لبرامجها عبرالمنصة على ان تقوم شركة السياحة بعرض السعر الاجمالى للبرنامج السياحى (سعر البيع الخاص للشركة)</p>					
			      <p>2 – توافق وتقر شركة السياحة على ان تقوم منصة صفا بإضافة مبلغ حوالى 10% عمولة التسويق على المنصة على اجمالى البرنامج السياحى المراد التسويق له حسب حاجة العمل وطبيعة البرنامج.</p>
			    	<p>3 – تتحمل شركة السياحة المسئولية الكاملة عن تنفيذ البرنامج السياحى على أكمل وجه بدون أى تغيير او تعديل بعد مشاركة وعرض البرنامج للتسويق.</p>
				  	<p>4 – تقوم منصة صفا بتحصيل المبلغ الخاص بمبيعات البرامج السياحية على المنصة من العميل شاملا ( سعر البيع الخاص بشركة السياحة + نسبة التسويق على المنصة).</p>
					  <p>5 – تقوم منصة صفا بتوريد (سعر البيع فقط) للبرنامج السياحى المباع إلى حساب شركة السياحة وذلك بعد تنفيذ الشركة لكامل البرنامج المباع.</p>
					  <p>6 – يحق لمنصة صفا مراجعة جميع البرامج السياحية قبل القيام بالتسويق ويحق لها قبول أو رفض أى برنامج يتعارض مع متطلبات السوق او يتعارض مع سياسات التسويق لمنصة صفا.</p>
					  <p>7 – يحق لمنصة صفا الامتناع عن سداد أى برنامج سياحى لشركة السياحة فى حالة الإخلال او الإمتناع عن تنفيذ البرنامج المتفق عليه مع الحق لمنصة صفا فى تطبيق أى غرامات قد تنشأ او قد تقع على العميل نتيجة لتغيير او الاخلال او الامتناع عن البرنامج وذلك طبقا للشروط والاحكام الخاصة لكل برنامج متفق عليه.</p>
					</div>
					</div>
					<div className='check-box mt-2'>
					<input
						// className="custom-control-input permChecks"
						id={"terms"}
						checked={checked}
						name="terms"
						type="checkbox"
						onChange={(e)=>setChecked(e.target.checked)}
					/>
					<label
						className="custom-control-label1 my-1 text-capitalize"
						htmlFor={"terms"}
					>
					I agree to all terms and conditions

					</label>
					</div>

				
					<button className='btn  btn-nxt w-100 text-white py-2' disabled={!checked}
					onClick={()=>{
						setIsOpen(prev=>!prev)
						sentEmail();}}
					>Verify to agree</button>
				 </div>
				
			</ModalBody>
		</Modal>
	)
}

export default SubscriptionTermsModel
