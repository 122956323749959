export function generatePageInitialContent(theme) {
  switch (theme) {
    case "theme-1":
      return [
        {
          id: "packages-section",
          type: "packages",
          hasSlider: true,
          items: []
        },
        {
          id: "hotels-section",
          type: "hotels",
          hotel_type: "theme-1",
          items: []
        },
        {
          id: "tours-section",
          type: "tours",
          tours_type: "theme-1",
          items: []
        }
      ]


    case "theme-2":
      return [
        {
          id: "packages-section",
          type: "packages",
          hasSlider: false,
          items: []
        },
        {
          id: "hotels-section",
          type: "hotels",
          hotel_type: "theme-2",
          items: []
        },
        {
          id: "tours-section",
          type: "tours",
          tours_type: "theme-2",
          items: []
        }
      ]

    case "theme-3":
      return [
        {
          id: "packages-section",
          type: "packages",
          hasSlider: false,
          items: []
        },
        {
          id: "hotels-section",
          type: "hotels",
          hotel_type: "theme-3",
          items: []
        },
        {
          id: "tours-section",
          type: "tours",
          tours_type: "theme-3",
          items: []
        }
      ]
    case "theme-4":
      return [
        {
          id: "packages-section",
          type: "packages",
          hasSlider: false,
          packages_type: "theme-4",
          items: []
        },
        {
          id: "hotels-section",
          type: "hotels",
          hotel_type: "theme-4",
          items: []
        },
        {
          id: "tours-section",
          type: "tours",
          tours_type: "theme-4",
          items: []
        }
      ]
    case "theme-5":
      return [
        {
          id: "packages-section",
          type: "packages",
          packages_type: "theme-5",
          items: []
        },
        {
          id: "hotels-section",
          type: "hotels",
          hotel_type: "theme-5",
          items: []
        },
        {
          id: "tours-section",
          type: "tours",
          tours_type: "theme-5",
          items: []
        }
      ]
    case "theme-7":
      return [
        {
          id: "packages-section",
          type: "packages",
          packages_type: "theme-7",
          items: []
        },
        {
          id: "hotels-section",
          type: "hotels",
          hotel_type: "theme-7",
          items: []
        },
        {
          id: "tours-section",
          type: "tours",
          tours_type: "theme-7",
          items: []
        }
      ]
    case "theme-10":
      return [
        {
          id: "packages-section",
          type: "packages",
          packages_type: "theme-10",
          items: []
        },
        {
          id: "hotels-section",
          type: "hotels",
          hotel_type: "theme-10",
          items: []
        },
        {
          id: "tours-section",
          type: "tours",
          tours_type: "theme-10",
          items: []
        }
      ]
    default:
      return [
        {
          id: "packages-section",
          type: "packages",
          hasSlider: true,
          items: []
        },
        {
          id: "hotels-section",
          type: "hotels",
          hotel_type: "theme-2",
          items: []
        },
        {
          id: "tours-section",
          type: "tours",
          tours_type: "theme-2",
          items: []
        }
      ]
  }
}