import EditableParagraph from "modules/WebBuilder-V2/shared/EditableParagraph";
import ToggleCheckbox from "modules/WebBuilder-V2/shared/ToggleCheckbox";
import React from "react";
// import { ReactComponent as HotelIcon } from "assets/images/market-place/itinerary/itinerary-hotel.svg";
import { FlightIcon, HotelIcon, PackageIcon, ToursIcon, TransportIcon, VisaIcon } from "./icons";
import Locale from 'translations';

export default function ServicesWithIcons({
	logo,
	type,
	typeTitle,
	isTransfer,
	isPackage,
	editCardDetails,
	header,
	body,
	preview,
	visible,
}) {
	const { webBuilder } = Locale;
	let icon;
	switch (type) {
		case "hotels":
			icon = <HotelIcon />;
			break;
		case "visa":
			icon = <VisaIcon />;
			break;
		case "transport":
			icon = <TransportIcon />;
			break;
		case "transfer":
			icon = <TransportIcon />;
			break;
		case "tours":
			icon = <ToursIcon />;
			break;
		case "packages":
			icon = <PackageIcon />;
			break;
		case "flights":
			icon = <FlightIcon />;
			break;
		default:
			icon = "";
	}
	return (
		<div className="our-service_card-with-icon">
			<div className="our-service-icon">{icon}</div>
			<p className="service-body">{webBuilder[type?.toLowerCase()] || typeTitle}</p>
			<EditableParagraph
				className="service-header h5 mb-0"
				contentEditable={visible}
				onInput={(value) => {
					editCardDetails(value, "header");
				}}
				text={header}
			/>

			<div>
				<EditableParagraph
					className="text-secondary-color service-content"
					contentEditable={visible}
					onInput={(value) => {
						editCardDetails(value, "body");
					}}
					text={body}
				/>
			</div>

			{!preview ? (
				<ToggleCheckbox
					checked={visible}
					onChange={(e) => {
						const value = e.target.checked;
						editCardDetails(value, "visible");
					}}
					// disabled={!visible}
					label="Visible"
				/>
			) : null}
		</div>
	);
}
