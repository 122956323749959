import { useCallback, useRef } from 'react';

export default function useDebounce(func, delay) {
  const timeoutRef = useRef(null);

  const debouncedFunc = useCallback(
    (...args) => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    },
    [func, delay]
  );

  return debouncedFunc;
}

