import React, { useEffect, useState } from "react";
import LocationIcon from "modules/market-place/NewMarketplace/icons/location";
import DiscountIcon from "modules/market-place/NewMarketplace/icons/discountIcon";
import Locale from "translations";
import Details from "./Details";
import moment from "moment";
import ArrowDownIcon from 'modules/market-place/NewMarketplace/icons/arrowDownIcon copy';
import chevronUpIcon from "assets/images/market-place/servicesIcon/chevron_down.png";

export default function ItemData(props) {
	const { productsBuilder } = Locale;
	const { item,index,toggleShowDetails } = props;

	
	const [matches, setMatches] = useState(
		window.matchMedia('(max-width: 600px)').matches
	)

	useEffect(() => {
		window
			.matchMedia('(max-width: 600px)')
			.addEventListener('change', (e) => setMatches(e.matches))
	}, [])



	const hasMin = (Array, attrib) => {
		return (
			(Array.length &&
				Array.reduce(function (prev, curr) {
					return prev[attrib] < curr[attrib] ? prev : curr;
				})) ||
			null
		);
	};
	const Min = hasMin(item.intervals, "total_price");
	return (
		<div className="item-data-container">

			
			<div className="item-data">

				<div className="image m-mob-auto">
					<img src={item.image} alt="" />
				</div>

				<div className="data">
					<h3>{item?.name}</h3>
					<p className="starts">
						{[...Array(+item.rating).keys()].map((i) => (
							<i key={i} className="fa fa-star" aria-hidden="true"></i>
						))}


						{item.rating && item.rating < 5
							? [...Array(5 - item.rating).keys()].map((i) => (
									<i
										className="far fa-star solid-star"
										key={i}
										aria-hidden="true"
									></i>
							  ))
							: null}
					</p>
					<span>{matches ? '' : productsBuilder?.specialAllotment}</span>
					<p className="location">
						<LocationIcon width={20}/>
						<p style={{fontSize:'14px', fontWeight:600}}>{item.address}</p>
					</p>

					<hr style={{opacity:0.5}}/>
					<div className="actions actions-mo">
			
						{item.showDetails ?  (
							 <button
							 className="btn btn-link mo-w-100 mob-hide"
							 onClick={() => toggleShowDetails(item,!item.showDetails)}
						 >
							 {productsBuilder.hideAllAllotment}
							 <img src={chevronUpIcon} alt="" />
						 </button> 
						) : (
							<button
							className="btn btn-link mo-w-100"
							onClick={() => toggleShowDetails(item,!item.showDetails)}
						>
							{productsBuilder.viewAllAllotment}
							<ArrowDownIcon />
						</button> 
						)}

						<p className="rate">
							<DiscountIcon /> {productsBuilder.specialRate}
						</p>
						{Min?.total_price ? (
							<div className="price">
								<span>{productsBuilder.startFrom}</span>
								<p>
									{Min?.total_price}{" "}
									<span className="currency">{item.currency}</span>{" "}
									<span className="nights">
										{moment(Min.to).diff(moment(Min?.from), "days")}{" "}
										{productsBuilder.nights}
									</span>
								</p>
							</div>
						) : null}
					</div>

				</div>

			</div>




			{item?.showDetails && <Details index={index} item={item} />}
		</div>
	);
}
