import { useSBSState } from 'context/global';
import AppFooter from 'layouts/AppFooter';
import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import SafaLogo from "assets/images/dashboard/safaLogoFull.svg";
import Locale from 'translations';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';

export default function RefundPolicy() {
  const { isAuth } = useSBSState();
  const { newLand } = Locale;


  const style = {
    color: "#505050",
    lineHeight: "29.98px"
  };


  return (
    <div className={isAuth ? "" : "pre-auth-layout"}>

      {!isAuth &&
        <div className="container px-3">
          <div className="row m-0 mb-2 align-items-center">
            <figure className="col-6 m-0 hidden-mobile login-page-img">
              <div className="d-flex justify-content-between py-2 align-items-center w-100">
                <Link to="/" className="text-dark">
                  <img src={SafaLogo} alt="" width="160px" />
                </Link>

              </div>
            </figure>
            <div className="col-md-6 col-sm-9 d-flex hidden-mobile  flex-column  justify-content-between login-page-contant">
              <div className="d-flex hidden-mobile justify-content-between py-2 align-items-center w-100">
                <Link to="/" className="text-dark linkHeader ">
                  {newLand.home}
                </Link>
                <Link to="/about-us" className="text-dark linkHeader">
                  {newLand.aboutUs}
                </Link>
                <Link to="/contact-us" className="text-dark linkHeader linkHeaderActive">
                  {newLand.contactUs}
                </Link>

                <LanguageSwitcher />
              </div>
            </div>
          </div>
        </div>
      }


      <div className="container py-3">

        <h1 className="font-weight-bold h3 text-center">Refund & Cancelation Policy </h1>
        <h2 className="font-weight-bold h3 text-center">"Safa Soft - Safa Live"</h2>
        <div className="content mt-5">
          <p>
            All booking cancellations for “ Hotels, Transfers, Tours & Flight Tickets) are subject
            to the cancellation conditions of the booking stated at the time of selecting the
            service.
          </p>

          <p className="my-3" style={style}>
            All Visa bookings are non-refundable.
          </p>
          <p className="my-3" style={style}>
            All booking amendments are subject to the amendment policy stated on the
            booking. If within the booking deadline, it will follow the same deadlines &
            charges as the cancellation condition.
          </p>
          <p className="my-3" style={style}>
            Bookings cannot be canceled or amended after the check-in date. A written
            request should be sent to our operations department to action these requests
            (subject to availability).
          </p>
          <p className="my-3" style={style}>
            Restricted products cannot be amended or cancelled once confirmed and require
            immediate payment at the time of booking. Invoices will be issued at the time of
            booking. Cancellation, amendments or no show will be %100 and under no
            circumstances will be refunded.
          </p>
          <p className="my-3" style={style}>
            Certain bookings may not be subject to modifications and may require canceling
            the initial booking and creating a new one, though this may not be included in the
            concerned booking terms and conditions.
          </p>

          <p className="my-3" style={style}>
            SAFA SOFT - SAFA LIVE shall have the right to suspend credit facilities, cease or
            decline bookings and cancel future bookings if you exceed the agreed credit limit
            or fail to pay for the due outstanding balance to SAFA SOFT - SAFA LIVE by any
            due date.
          </p>

          <p className="my-3" style={style}>
            Bookings should be made only for genuine reservations. We reserve the right to
            cancel bookings which appear to have been made to block or hold spaces even if
            it is only for visa purposes.
          </p>
        </div>
      </div>
      {!isAuth &&
        <AppFooter />
      }
    </div>
  )
}
