import React from "react";
import { useSBSState } from "context/global";

const TextFieldWithSelect = (props) => {
	const { locale } = useSBSState();
	const prependSelect = (
		<div
			className={`control-field__prepend pt-3 ${
				props.color ? " control-field__prepend--" + props.color : ""
			}`}
		>
			<select
				className={`control-field__select ${locale === "ar" ? "pl-3" : "pr-3"}`}
				value={props.selectValue}
				onChnage={props.onChnageSelect}
			>
				{props.children}
			</select>
		</div>
	);

	const appendSelect = (
		<div
			className={`control-field__append pt-3 ${
				props.color ? " control-field__append--" + props.color : ""
			}`}
		>
			<select
				className={`control-field__select ${locale === "ar" ? "pl-3" : "pr-3"}`}
				value={props.selectValue}
				onChange={props.onChnageSelect}
				{...props}
			>
				{props.children}
			</select>
		</div>
	);

	return (
		<>
			<div
				className={`my-2 input-group control-field ${
					props.color ? " control-field--" + props.color : ""
				}`}
			>
				{/* Text Field Prepend */}
				{props.selectPosition === "prepend" ? prependSelect : ""}

				{/* Text Field Body */}
				<div className="control-field__body">
					{/* Label */}
					<label
						className={`control-field__label ${
							props.color ? " control-field__label--" + props.color : ""
						}`}
					>
						{props.label}
					</label>

					{/* Text Input */}
					<div className="row no-gutters">
						<input
							className={`${props.className ?? ""} control-field__input w-100`}
							id={props.id}
							value={props.value}
							onChange={props.onChange}
							onBlur={props.onBlur}
							placeholder={props.placeholder}
							onKeyUp={props.onKeyUp}
						/>
					</div>
				</div>

				{/* Text Field Append */}
				{props.selectPosition === "append" ? appendSelect : ""}
			</div>

			{/* Feedback */}
			<small
				className={`control-field__feedback control-field__feedback--${
					props.color
				} ${props.isInvalid ? "d-block" : "d-none"}`}
			>
				{props.feedbackMessage}
			</small>
		</>
	);
};

export default TextFieldWithSelect;
