import React from "react";
import AccountingHotelItem from "./HotelItem";

export default function AccountingHotels({
	isPricePerService,
	accountingStatement,
	setAccountingStatement,
	canEdit
}) {

	return (
		<div className="accounting-item hotels  py-0">
			<div className="d-flex flex-column gap-10">
				{accountingStatement.hotels.map((hotel) => {
					return (
						<div className="accounting-item-wrapper" key={hotel?.id}>
							<AccountingHotelItem
								key={hotel.id}
								isPricePerService={isPricePerService}
								hotel={hotel}
								setAccountingStatement={setAccountingStatement}
								accountingStatement={accountingStatement}
								canEdit={canEdit}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
}
