import React from "react";

export default function DangerInfoIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_109_5615)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C15.5218 0 20 4.47819 20 10C20 15.5218 15.5218 19.9998 10 19.9998C4.47803 20 0 15.5218 0 10C0 4.47819 4.47803 0 10 0ZM8.57536 14.7388H11.3021V17.1493H8.57536V14.7388ZM11.3006 13.5026H8.57601C8.30501 10.1916 7.73633 8.71517 7.73633 5.40869C7.73633 4.18929 8.7251 3.20003 9.9445 3.20003C11.1639 3.20003 12.1532 4.18929 12.1532 5.40869C12.1533 8.71338 11.5771 10.1942 11.3006 13.5026Z" fill="#EA5455"/>
  </g>
  <defs>
    <clipPath id="clip0_109_5615">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>
	);
}
