import React from "react";
import { ReactComponent as CalendarIcon } from "assets/images/webBuilder/calendar-white.svg";
import { ReactComponent as Hotelcon } from "assets/images/webBuilder/hotel-white.svg";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-white.svg";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import moment, { now } from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useWebBuilderState } from 'context/webBuilder';
import Locale from 'translations';

function PackageCardwithImage(props) {
	const { commons } = Locale;
	const {
		onClick,
		image,
		name,
		departure_date_at,
		return_date_at,
		stars,
		product_uuid,
		hotelName,
		price,
		locationName,
		openEditModal,
		initialName
	} = props;
	const { style, selectedLanguage } = useWebBuilderState();
	const location = useLocation();
	const history = useHistory();
	const preview = !location.pathname.includes("edit");
	const params = useParams();

	return (
		<div
			className={`package-card-container ${!image ? "empty-service" : ""}`}
			style={{
				backgroundImage: `url(${image})`,
			}}
			onClick={() => {
				if (product_uuid) {
					if (preview) {
						if (params.name) {
							history.push(`/web-v2/${params.name}/package/${product_uuid}`);
						} else {
							history.push(`/web-v2/package/${product_uuid}`);
						}
					}
				}
			}}
			key={product_uuid}
		>

			<div onClick={onClick} className="package-card-details">
				{!preview && !image ?
					<button className="btn add-service-btn" onClick={() => openEditModal(props)}>
						<AddIcon />
					</button>
					:
					null
				}

				<div className="price">
					<p>{commons.from}</p>
					<span className="amount">
						{price}{" "}
						<span className="currency"> {props?.currency ? props?.currency : ""}</span>
					</span>
				</div>
				<div className="package-info">
					<div className="rate">
						<div className="starts">
							{[...Array(5)].map((i, index) =>
								index < stars ? <GoldenStarIcon key={`package-rate-${index}`} /> : <StarIcon key={`package-rate-${index}`} />
							)}
						</div>
						<p className="font-weight-bold">{name || initialName}</p>
					</div>
					{style?.theme !== "theme-3" ?
						<div className="location">
							<LocationIcon /> <span className="ml-2">{locationName?.[selectedLanguage] || locationName}</span>
						</div>
						:
						null
					}
					<div className="date">
						<CalendarIcon />{" "}
						<span className="ml-2">{`
							${moment(departure_date_at || now()).format("DD MMM YYYY")} - 
							${moment(return_date_at || now()).format("DD MMM YYYY")}`}
						</span>
					</div>
					{hotelName ? (
						<div className="hotel">
							<Hotelcon /> <span className="ml-2">{hotelName}</span>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}

export default PackageCardwithImage;
