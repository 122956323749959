import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import { ReactComponent as PlusIcon } from "assets/images/webBuilder/plus.svg";
import { ReactComponent as MinusIcon } from "assets/images/webBuilder/minus.svg";
import EditableParagraph from "modules/WebBuilder/shared/EditableParagraph";
import { uuidv4 } from "modules/WebBuilder/shared/DragAndDropContainer";
import _ from "lodash";
import CustomModal from "modules/WebBuilder/shared/CustomModal";
import { useWebBuilderState } from 'context/webBuilder';
import { arrayToObject } from 'modules/WebBuilder-V2/helpers/formatPageData';
import { transalteText } from 'services/webBuilder';
import { ReactComponent as MagicPenIcon } from "assets/images/webBuilder/magicpen.svg";
import Locale from 'translations';



function FAQs({ onChange, id, faqs, openModal, handleOpenModal }) {
	const { webBuilder } = Locale;
	const { selectedLanguage, languages } = useWebBuilderState();
	const [collapsedItems, setCollapsedItems] = useState([]);
	const [questions, setQuestions] = useState();
	useEffect(() => {
		if (openModal && faqs) {
			const clone = _.cloneDeep(faqs);

			console.log(clone);
			setQuestions(clone);
		}
		return () => { };
	}, [openModal]);

	const handleFaqs = ({ value, name, questionIndex, language }) => {
		onChange({ value, itemId: id, name, questionIndex, language });
	};

	const insertQuestion = () => {
		onChange({ value: questions, itemId: id, name: "faqs" });
		handleOpenModal();
	};

	const handleQuestion = (value, index, language) => {
		const questionsClone = [...questions];
		questionsClone[index].question[language] = value;
		setQuestions(questionsClone);
	};

	const removeQuestion = (index) => {
		const questionsClone = [...questions];
		questionsClone.splice(index, 1);
		setQuestions(questionsClone);
	};

	const addQuestion = () => {
		const languagesCodes = languages.map(l => l?.code);
		const questionsClone = [
			...questions,
			{
				id: uuidv4(),
				question: arrayToObject(languagesCodes, {}, ""),
				body: arrayToObject(languagesCodes, {}, ""),
			},
		];

		setQuestions(questionsClone);
	};


	async function handleAutoTranslate(text, questionIndex) {
		const data = {
			to: selectedLanguage?.toUpperCase(),
			text: text
		}
		const res = await transalteText(data);
		if (res?.status === 200) {
			// setShowInput(false);
			// onInput(res?.data?.result);
			handleQuestion(res?.data?.result, questionIndex, selectedLanguage)
		}
	}


	return (
		<div className="web-builder-faqs-container">
			<h4 className="h4">{webBuilder.anyQuestionsFindHere}</h4>
			{faqs ? faqs.map((que, index) => {
				const isOpen = collapsedItems.includes(index);
				const openCollapse = () => {
					const updated = [...collapsedItems, index];
					setCollapsedItems(updated);
				};
				const closeCollapse = () =>
					setCollapsedItems(collapsedItems.filter((i) => i !== index));

				return (
					<div key={que.id}>
						<div className="faqs-container-question">
							<span> {que.question[selectedLanguage] || "Question?"} </span>
							{isOpen ? (
								<button
									className="bg-transparent border-0"
									onClick={closeCollapse}
								>
									<MinusIcon />
								</button>
							) : (
								<button
									className="bg-transparent border-0"
									onClick={openCollapse}
								>
									<PlusIcon />
								</button>
							)}
						</div>
						<Collapse isOpen={isOpen}>
							<EditableParagraph
								className="faqs-container-body"
								contentEditable={true}
								onInput={(value) =>
									handleFaqs({ value, name: "body", questionIndex: index, language: selectedLanguage })
								}
								text={que.body[selectedLanguage]}
							/>
						</Collapse>
					</div>
				);
			}) : null}

			<CustomModal isOpen={openModal} header={"FAQs"} toggle={handleOpenModal}>
				<div className="faq-modal">
					{questions?.length > 0 ? questions.map((faq, index) => (
						<div key={faq.id} className="faq-input">
							<div>
								<label htmlFor={`Question ${index + 1}`}>{`Question ${index + 1
									}`}</label>
								{questions[index].question[selectedLanguage]?.length > 0 ?
									<button className="magic-translate-btn"
										onClick={() => handleAutoTranslate(questions[index].question[selectedLanguage], index)}
									>
										<MagicPenIcon />
										<span>Magic translate</span>
									</button>
									:
									null
								}

								<input
									onChange={(e) => handleQuestion(e.target.value, index, selectedLanguage)}
									id={`Question ${index + 1}`}
									placeholder="type"
									value={questions[index].question[selectedLanguage]}
								/>
							</div>
							{index !== 0 ? (
								<button
									className="remove-icon"
									onClick={() => removeQuestion(index)}
								>
									<span>
										<MinusIcon />
									</span>
								</button>
							) : null}
						</div>
					)) : null}

					<button className="add-icon" onClick={addQuestion}>
						{" "}
						<PlusIcon />
					</button>
					<button className="insert-button" onClick={insertQuestion}>
						{" "}
						Insert
					</button>
				</div>
			</CustomModal>
		</div>
	);
}

export default FAQs;
