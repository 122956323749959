export default function PackageTypeIcon(props){
  return(
    <svg className={props?.className ? props.className : ''} width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.9163 31.1673H7.58301C4.74967 31.1673 3.33301 29.7507 3.33301 26.9173V15.584C3.33301 12.7507 4.74967 11.334 7.58301 11.334H14.6663V26.9173C14.6663 29.7507 16.083 31.1673 18.9163 31.1673Z" stroke="#00B7AC" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.8222 5.66602C14.7088 6.09102 14.6663 6.55852 14.6663 7.08268V11.3327H7.58301V8.49935C7.58301 6.94102 8.85801 5.66602 10.4163 5.66602H14.8222Z" stroke="#00B7AC" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.333 11.334V18.4173" stroke="#00B7AC" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M26 11.334V18.4173" stroke="#00B7AC" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M24.583 24.084H21.7497C20.9705 24.084 20.333 24.7215 20.333 25.5007V31.1673H25.9997V25.5007C25.9997 24.7215 25.3622 24.084 24.583 24.084Z" stroke="#00B7AC" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 18.416V24.0827" stroke="#00B7AC" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.667 26.9173V7.08398C14.667 4.25065 16.0837 2.83398 18.917 2.83398H27.417C30.2503 2.83398 31.667 4.25065 31.667 7.08398V26.9173C31.667 29.7507 30.2503 31.1673 27.417 31.1673H18.917C16.0837 31.1673 14.667 29.7507 14.667 26.9173Z" stroke="#00B7AC" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}