import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import imgSrc from "assets/images/market-place/hotel-1.jpg";
import ArrowRightIcon from 'modules/market-place/NewMarketplace/icons/arrowRightIcon';
import ArrowILeft from 'modules/market-place/NewMarketplace/icons/arrowILeft';
import ArrowIRight from 'modules/market-place/NewMarketplace/icons/arrowIRight';

export default function Gallery({item}) {
	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 3,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 2,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};
	const images = [
		"assets/images/market-place/servicesIcon/new-umrah.png",
		"assets/images/market-place/servicesIcon/new-umrah.png",
		"assets/images/market-place/servicesIcon/new-umrah.png",
		"assets/images/market-place/servicesIcon/new-umrah.png",
		"assets/images/market-place/servicesIcon/new-umrah.png",
		"assets/images/market-place/servicesIcon/new-umrah.png",
	];
	return (
		<div className="gallery">
			<Carousel
        draggable={false}
				responsive={responsive}
				itemClass={"pakage-slide1"}
				slidesToSlide={1}
				keyBoardControl={true}
        arrows={false}
        renderButtonGroupOutside={true} 
        customButtonGroup={<ButtonGroup />}
			>
				{item.images.map((image) => (
					<GalleryItem img={image} />
				))}
			</Carousel>
		</div>
	);
}

export function GalleryItem({img}) {
	return <img src={img} alt="" />;
}

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  return (
    <div className="carousel-button-group"> 
      <button className={`btn ${currentSlide === 0 ? 'disable' : ''} `} onClick={() => previous()}><ArrowILeft/></button>
      <button className="btn" onClick={() => next()} ><ArrowIRight/></button>
      {/* <ButtonThree onClick={() => goToSlide(currentSlide + 1)}> Go to any slide </ButtonThree> */}
    </div>
  );
};