import { useWebBuilderState } from "context/webBuilder";
import { useHistory, useParams } from "react-router-dom";
// import Locale from 'translations';
import useWBHeader from './useWBHeader';
import WebBuilderNav from './MainNav';



export default function HeaderThemeSeven({ toggleLogoChangeModal, contactPhoneComponent, clickContactButton }) {
  // *** hooks
  // const { webBuilder } = Locale;
  const { selectedLanguage } = useWebBuilderState();

  const { bundle } = useParams();
  const history = useHistory();

  const {
    headerData,
    isPreview,
    logoImage,
  } = useWBHeader();



  return (
    <header className="wb-header">

      <div className="logo"
        onClick={() => {
          // check if we inside preview 
          if (isPreview) {
            history.push(`/${bundle}/web-builder-v2/preview`);
          } else {
            toggleLogoChangeModal();
          }
        }}
      >
        {logoImage}
      </div>

      <nav className="header-nav">
        <WebBuilderNav />
      </nav>

      {/* contact button */}
      <div className="theme_header_contact_wrap">
      <button
        className="contact-button"
        onClick={() => {
          // check if we inside view package page when click scroll back to preview page the scroll using useeffect
          if (isPreview) {
            history.push({ pathname: `/${bundle}/web-builder-v2/preview`, state: headerData?.contactUs?.id });
          } else {
            clickContactButton()
          }
        }}
      >
        <span>
          {headerData?.contactUs?.name?.[selectedLanguage] || headerData?.contactUs?.name}
        </span>
      </button>
        {contactPhoneComponent}
      </div>

    </header>
  );
}
