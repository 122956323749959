import React, { useEffect, useState } from 'react';
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import AccountingVisaPricing from './VisaPricing';
import Locale from 'translations';
import { useLocation } from 'react-router-dom';
import ShowForPermission from 'helpers/showForPermission';

export default function AccountingVisaItem({ isPricePerService, item, index, handleInputsChanges, deleteVisaItemPricing, canEdit }) {
  const { operationStatement } = Locale;
  const location = useLocation();

  const [isPriceTableShown, setIsPriceTableShown] = useState(false);

  // show price table if there is price
  useEffect(() => {
    if ((item?.pricing?.buying_price && item?.pricing?.buying_price > 0) ||
      (item?.pricing?.selling_price && item?.pricing?.selling_price > 0)
    ) {
      setIsPriceTableShown(true)
    }
  }, []);

  return (
    <>
      <div className="attraction-item">
        <h4 className='header-title'>{item?.visa_type}</h4>
        <div className="content">
          <p className='d-flex align-items-center flex-wrap col-md-6 col-12 mt-2 p-0'>
            <span className='col-md-5 col-12 p-0 label'>{operationStatement.Pax}</span>
            <span className='col-md-7 col-12 p-0'>
              {item?.adults} Adult(s)  |  {item?.children} child / children  |  {item?.infants} infant
            </span>
          </p>
        </div>
      </div>

      {isPricePerService && !isPriceTableShown && canEdit ?
        <ShowForPermission permission='Manage-operation-accounting'>
          <button className="btn-plus-operation p-0 align-self-baseline"
            onClick={() => setIsPriceTableShown(true)}
          >
            <span>
              <i className="fas fa-plus"></i>
            </span>
            {operationStatement.AddBuyingSelling}
          </button>
        </ShowForPermission>
        :
        null
      }

      {isPricePerService && isPriceTableShown ?
        <>
          <AccountingVisaPricing
            index={index}
            itemData={item}
            handleInputsChanges={handleInputsChanges}
            canEdit={canEdit}
          />
          {canEdit &&
            <ShowForPermission permission='Manage-operation-accounting'>
              <button className="btn delete-pricing-btn mt-0"
                onClick={() => {
                  deleteVisaItemPricing(index);
                  setIsPriceTableShown(false)
                }}
              >
                <DeleteIcon />
                <span>{operationStatement.DeleteBuyingSelling}</span>
              </button>
            </ShowForPermission>
          }
        </>
        :
        null
      }
    </>
  )
}
