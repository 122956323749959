import React from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";

export const AddElement = ({ onClick, title, className = "" }) => {
	const location = useLocation();
	const preview = !location.pathname.includes('edit');
	return !preview ? (
		<div className={`add-element-box ${className}`}>
			<button onClick={onClick}>
				<AddIcon />
			</button>
			<p className="">{title}</p>
		</div>
	):null;
};
