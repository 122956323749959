import { useVisaState } from "context/visa";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "reactstrap";
import { fetchContractRow } from "services/visa";
import Locale from "translations";
import { Agents } from "./agents";
import TableContract from "./tableContract";
export default function ManageContract() {
	const { visa } = Locale;
	let history = useHistory();
	const [contractData, setContractData] = useState([]);
	const { tokenSafaVisa } = useVisaState();

	useEffect(() => {
		async function fetchContractData() {
			const res = await fetchContractRow({ token: tokenSafaVisa });
			setContractData(res?.data);
		}
		fetchContractData();
	}, []);

	const contractRaw =
		contractData?.data?.length > 0 ? (
			contractData?.data?.map((contract, index) => (
				<TableContract contract={contract} index={index} />
			))
		) : (
			<tr>
				<td colSpan="9">
					<div className="product-build__product-no-data fullHeight">
						<i className="icx icx-eye-slash" aria-hidden="true"></i>
						<h4>{"No Result"}</h4>
					</div>
				</td>
			</tr>
		);
	return (
		<>
			<div className="container">
				<Agents />
				<div className="table-layout bg-white my-2 rounded ">
					{/* <div
						className="table-header d-flex align-items-center  mx-2 my-1 "
						style={{ gap: "5px" }}
					>
						<button className="btn btn-primary  px-5 py-2 mt-2 ">
							{visa.New}
						</button>
						<button className="btn btn-success  px-5 py-2 mt-2 ">
							{visa.Save}
						</button>
						<button className="btn btn-danger  px-5 py-2 mt-2 ">
							{visa.Delete}
						</button>
						<button
							className="btn btn-secondary  px-5 py-2 mt-2 "
							onClick={() => history.push("/visa")}
						>
							{visa.Close}
						</button>
					</div> */}
					<div className="contract-length">
						<h4>Contract ( {contractData?.data?.length} )</h4>
					</div>
					<div className='table-container'>
						<Table striped className="table-update border">
							<thead>
								<tr>
									<th>{visa.ID}</th>
									<th>{visa.Name}</th>
									<th>{"USAP "}</th>
									<th>{visa.EACode}</th>
									<th width="20%">{visa.UmrahCompany}</th>
								</tr>
							</thead>

							<tbody>{contractRaw}</tbody>
						</Table>
					</div>
				</div>
				<div className="btns d-flex  justify-content-end">
					<button
						className="btn btn-secondary  px-5 py-2 mt-1 mx-2 "
						onClick={() => history.push("/visa")}
					>
						{visa.back}
					</button>
					{/* <button className="btn btn-yellow  px-5 py-2 mt-1 bg-nxt">
						{visa.Save}
					</button> */}
				</div>
			</div>
		</>
	);
}
