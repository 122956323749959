import { PlusIcon } from "modules/UmrahOperations/shared/Icons";
import React, { useEffect, useState } from "react";
import Locale from "translations";
import TripPlannerList from "./List";
import TripPlannerListFilters from "./FIlters";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import GeneratePdfModel from "./generatePdfModel";
import { GeneratedPDF } from "components/Printing/GeneratedPDF";
import {
	ViewTripPlanner,
	fetchPackagesList,
} from "services/tripPlanner";
import { fetchLookups } from "services/lookups";
import ShowSubscription from 'helpers/ShowSubscription';

export default function TripPlanner() {
	// hooks
	const { productsBuilder, dashboard } = Locale;
	const history = useHistory();

	// states
	const [tripData, setTripData] = useState();
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		product_type_id: "",
		package_name: "",
		package_num: "",
		trip_ref: "",
		page: 1,
	});
	const [generatePdfState, setGeneratePdfState] = useState({
		logo: undefined,
		header: undefined,
		footer: "",
	});
	const [selectedPdfId, setSelectedPdfId] = useState();
	const [reloadData, setReloadData] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [packageLookups, setPackageLookups] = useState([]);
	const toggle = (id = null) => {
		setIsOpen(!isOpen);
		setSelectedPdfId(id);
	};

	// Fetch packages list
	useEffect(() => {
		async function fetchList() {
			const res = await fetchPackagesList({
				...filter,
				product_type_id: filter.product_type_id?.id,
			});
			setTripData(res?.data?.data);
			setMeta(res?.data?.meta);
		}
		fetchList();
	}, [filter, reloadData]);
	//Fetch package name lookups

	useEffect(() => {
		async function FetchpackagesLookups() {
			const res = await fetchLookups();
			setPackageLookups(res?.product_types);
		}
		FetchpackagesLookups();
	}, []);

	// handle pagination
	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};

	//Download Generated PDF
	const dawnloadPDF = async (data) => {
		//69 selectedPdfId
		const res = await ViewTripPlanner(selectedPdfId);
		var respos;
		//data
		if (res?.status === 200 || res?.data?.data) {
			respos = GeneratedPDF;
			const color_stars =
				res?.data?.data?.rate > 0
					? [...Array(res?.data?.data?.rate).keys()].map((i) => {
						return `<li>
				<i
					key=${i}
					class="fa fa-star fa-lg"
					aria-hidden="true"
				></i>
			</li> 
			`;
					})
					: null;

			const gray_stars =
				res?.data?.data?.rate > 0
					? [...Array(5 - res?.data?.data?.rate).keys()].map((i) => {
						return `<li class="gray-stars">
				<i
					key=${i}
					class="far fa-star fa-lg"
					aria-hidden="true"
				></i>
			</li> 
			`;
					})
					: null;
			const visa_included =
				res?.data?.data?.has_visa === true
					? `<div class="icon-item-visa no-border">
			<i class="fas fa-check-circle" ></i>
			<span>Visa Included</span>
						</div>`
					: "";

			const hotelItem =
				res?.data?.data?.items.length > 0
					? res?.data?.data?.items.filter((item) => item.item?.name === "hotel")
					: [];

			const hotel_data =
				hotelItem?.length > 0
					? hotelItem.map((hotel, index) => {
						return ` <div class="hotel-row">
													<div>
														<p style="color: #707170">Hotel ${index + 1}</p>
														<p>${hotel?.itemable?.hotel?.name} </p>
													</div>
													<div class="hotel-night">
														<p>Night <span>${hotel?.itemable?.nights}</span></p>
														<p class="mx-3">Meals <span>${hotel?.itemable?.meal_type} </span></p>
													</div>
												</div>
												${hotelItem?.length - 1 === index ? " " : `<hr />`}
												`;
					})
					: [];
			const allHotel = () => {
				return hotelItem?.length !== 0
					? `	<div class="hotel" style="margin-bottom:45px">
						<div class="icon">
							<img
								src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/cd178489-848c-48b7-bd6d-66e669026144"
								alt=""
							/>
							<p>
								<span>Package</span>
								<span>Hotel</span>
							</p>
						</div>
						<div class="data">
						[hotel_data]
							
							
						</div>
					</div>
						`
					: "";
			};
			const flightItem =
				res?.data?.data?.items.length > 0
					? res?.data?.data?.items.filter((item) => item.item?.id === 1)
					: [];
			const flight_data =
				flightItem?.length > 0
					? flightItem.map((flight, index) => {
						return ` <div class="flight">
								<div class="flight-row">
									<div class="icon">
										<img
											src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/c46949e3-e2ad-4471-9e5c-59a5a633f0e1"
											alt=""
										/>
										<p>
											<span>Travel</span>
											<span>Flight</span>
										</p>
									</div>
									<div class="data">
										<span>Arrival To</span>
										<p>${flight?.itemable?.from_country?.name} / ${flight?.itemable?.from_port?.name}</p>
									</div>
								</div>

								<hr />
								<div class="flight-row">
									<div class="icon">
										<img
											src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/c46949e3-e2ad-4471-9e5c-59a5a633f0e1"
											alt=""
										/>
										<p>
											<span>Return</span>
											<span>Flight</span>
										</p>
									</div>
									<div class="data">
										<span>Departure From</span>
										<p>${flight?.itemable?.to_country?.name} / ${flight?.itemable?.to_port?.name}</p>
									</div>
								</div>
							</div>
							`;
					})
					: [];

			const attractionlItem =
				res?.data?.data?.items.length > 0
					? res?.data?.data?.items.filter((item) => item.item?.id === 7)
					: [];

			const attraction_data =
				attractionlItem?.length > 0
					? attractionlItem.map((attraction, index) => {
						return ` <div class="attraction-item">
												<h6>${attraction?.itemable?.name} </h6>
												<p><span>City</span> ${attraction?.itemable?.city?.name}</p>
											</div>
											${attractionlItem?.length - 1 === index ? " " : `<hr class='hr-attraction'/>`}
											`;
					})
					: [];
			const allAttraction = () => {
				return attractionlItem?.length !== 0
					? `
						<div class="hotel">
						<div class="icon">
							<img
								src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/d3f553c9-3448-4c33-99e9-0cefe3c84ce2"
								alt=""
							/>
							<p>
								<span>Package</span>
								<span>Attraction</span>
							</p>
						</div>
						<div class="attraction-data">
							
		
							[attraction_data]
		
							
						</div>
					</div>
						`
					: "";
			};
			const transportItem =
				res?.data?.data?.items.length > 0
					? res?.data?.data?.items.filter((item) => item.item?.id === 2)
					: [];

			const transport_data =
				transportItem?.length > 0
					? transportItem.map((transport, index) => {
						return `
									<tr>
									<td>Internal Movement</td>
									<td>${transport?.itemable?.pickup_point}</td>
									<td>
									
									${transport?.itemable?.to_item?.itemable?.hotel?.name ||
							transport?.itemable?.to_item?.itemable?.name ||
							transport?.itemable?.to_item?.itemable?.to_port?.name
							}
									</td>
								</tr>
								
								`;
					})
					: [];

			const allTransport = () => {
				return transportItem?.length !== 0
					? `<div class="hotel transport"  >
								<div class="icon">
									<img
										src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/3fb9e80d-f890-46ef-880a-928183ecd1c2"
										alt=""
									/>
									<p>
										<span>Transport</span>
									</p>
								</div>
								<div class="transport-data">
									<div class="head">
										<h6>Transportation Name</h6>
										<p>Model <span>2023</span></p>
									</div>
									<table>
										<thead>
											<tr>
												<th>type</th>
												<th>Pickup point</th>
												<th>Destination</th>
											</tr>
										</thead>
										<tbody>
										[transport_data]
										
										
										
										</tbody>
									</table>
								</div>
							</div>`
					: "";
			};
			const keyValuePair = {
				"[dir]": "dir",
				"[logo_img]": data.logo_preview,
				"[header_img]": data.header_preview,
				"[footer]": data.footer,

				"[allAttraction]": allAttraction,
				"[allHotel]": allHotel,
				"[allTransport]": allTransport,

				"[name_trip]": res?.data?.data?.name,
				"[country]":
					res?.data?.data?.destinations?.length > 1
						? `${res?.data?.data?.destinations[0]?.name} - ${res?.data?.data?.destinations[1]?.name}`
						: `${res?.data?.data?.destinations[0]?.name}`,
				"[duration]": res?.data?.data?.duration,
				"[has_visa]": visa_included,
				"[colored_starts]": color_stars?.join(" "),
				"[gray_starts]": gray_stars?.join(" "),
				"[hotel_data]": hotel_data?.join(" "),
				"[flight_data]": flight_data?.join(" "),
				"[attraction_data]": attraction_data?.join(" "),
				"[transport_data]": transport_data?.join(" "),
			};

			for (const key in keyValuePair) {
				respos = respos.replace(key, keyValuePair[key]);
			}

			var params =
				"width=" +
				window.innerWidth +
				", height=" +
				window.innerHeight +
				", top=0, left=0" +
				",toolbar=no,scrollbars=no,status=no,menubar=no";
			//"top=0,left=0,height=80%,width=auto"
			var popupWin = window.open("", " ", params);
			popupWin.document.open();
			popupWin.document.write(`
			<html>
			<head>
			<title> print PDF</title>
			<style>
			
				@page:first {
					size: A4 ;
					margin:0px;			
					//  padding-inline:0px !important;
					//  padding-top:0px !important;

				
				}
				@page {
					size: A4;
					margin-inline:0px;
					margin-top:15px;
					margin-bottom:0px ;			
					border: 1px solid black;
					// padding:0px !important;
				  //margin-bottom:80px !important;

				}
				@media print {
					.transport{
						break-before: auto !important;
						break-after: auto !important;
						margin-top:25px;
						break-inside: avoid !important;
						min-height:190px
					}
					.hotel,.flight{
						break-before: auto !important;
						break-after: auto !important;
						break-inside: avoid !important;
					}
					
					.footer{
						position: fixed !important;
						bottom:0px !important;
						left: 0; 
						right: 0;
						z-index:999999 !important;
						min-height:75px !important;
						break-before: avoid !important;
						break-inside: avoid !important;
					}
									
				}
			</style>
			<link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" rel="stylesheet">

			</head>
			<body onLoad="{()=>window.print()}">${respos}</body>
			</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};

	return (
		<ShowSubscription module={"package_planner"}>
			<div className="product-builder-wrapper">
				{/* <h5 className="productBuilder-title">{dashboard.messages.packagePlanner}</h5> */}
				<div className="productBuilder-bg-wrapper">
					<div className="d-flex justify-content-between align-items-center">
						<h1 className="productBuilder-title-secondary">
							{dashboard.messages.packagePlanner}
						</h1>
						<button
							className="btn btn-addApplication"
							onClick={() => history.push("/trip-planner/add")}
						>
							<PlusIcon color="#FFF" />
							<span>{productsBuilder.addPackage}</span>
						</button>
					</div>
					<hr className="hr-horizontal" />
					<TripPlannerListFilters
						filter={filter}
						setFilter={setFilter}
						packageLookups={packageLookups}
					/>

					<TripPlannerList
						toggle={toggle}
						tripData={tripData}
						goTo={goTo}
						meta={meta}
						setReloadData={setReloadData}
					/>
					<GeneratePdfModel
						isOpen={isOpen}
						toggle={toggle}
						state={generatePdfState}
						setState={setGeneratePdfState}
						dawnloadPDF={dawnloadPDF}
					/>
				</div>
			</div>
		</ShowSubscription>
	);
}
