import walletImg from "assets/images/wallet/walletError.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";

export default function SendErrRequestModal({
	modalErrSendRequest,
	toggleModalErrSendRequest,
	data,
	resData,
	responseBankTransfer,
}) {
	const { payment } = Locale;



	return (
		<div>
			<Modal
				size="md"
				isOpen={modalErrSendRequest}
				toggle={toggleModalErrSendRequest}
				className="send-modal"
			>
				<ModalHeader
					className="align-items-center py-1 border-none"
					toggle={toggleModalErrSendRequest}
				>
					Recharge {data?.currency} Wallet
				</ModalHeader>
				<ModalBody>
					<div className="container">
						<div className="recharge-img my-2 py-4 text-center">
							<img src={walletImg} alt="Wallet" />
							<h6 className="mt-2 modal-head">
								{/* Your payment was declined Check Your card and try again{" "} */}
								{responseBankTransfer?.data?.message}
								<p>
									{" "}
									{responseBankTransfer?.data?.errors?.amount?.length > 0
										? responseBankTransfer?.data?.errors?.amount.toString()
										: null}
								</p>
							</h6>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}
