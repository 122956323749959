import React from 'react'
import { uploadFile } from 'services/auth'

const UploadImages = ({
	title,
	id,
	onUpload,
	accept = 'image/png, image/jpeg, image/jpg',
	disabled,
	setSlider,
	hasSlider
}) => {
	const upload = async (file) => {
		const formData = new FormData()
		formData.append('file', file)
		formData.append('name', file?.name)
		formData.append('bucket', 'webBuilder')
		const response = await uploadFile(formData)
		if (response.data.data.uuid) {
			return response.data.data.uuid
		}
	}

	const handleUpload = async (e) => {
		const files = e.target.files
		if (!files?.[0]) return
		const uuid = (await upload(files[0])) ?? ''
		const URI = process.env.REACT_APP_FILEUPLOAD_URL

		const fileUrl = `${URI}/fetch/${uuid}`
		onUpload(fileUrl)
	}

	const handleUploadMultiImages = async (e) => {
		if (hasSlider) {
			const filesList = e.target.files
			const fileArr = Array.from(filesList)
			fileArr.forEach(async function (file) {
				const uuid = await upload(file)
				const URI = process.env.REACT_APP_FILEUPLOAD_URL
				const fileUrl = `${URI}/fetch/${uuid}`
				setSlider((oldImage) => [...oldImage, fileUrl])
				console.log(fileUrl)
				// setSlider((oldImage) => [...oldImage, uuid])
				// onUpload(fileUrl)
			})
		} else {
			const files = e.target.files
			if (!files?.[0]) return
			const uuid = (await upload(files[0])) ?? ''
			const URI = process.env.REACT_APP_FILEUPLOAD_URL

			const fileUrl = `${URI}/fetch/${uuid}`
			onUpload(fileUrl)
		}
	}
	return (
		<div className='tools-header-button m-modal-1'>
			<label htmlFor={`${id}-upload-file`}>{title}</label>
			{hasSlider ? (
				<input
					onChange={handleUploadMultiImages}
					style={{ display: 'none' }}
					type='file'
					id={`${id}-upload-file`}
					accept={accept}
					disabled={disabled}
					multiple
				/>
			) : (
				<input
					onChange={handleUpload}
					style={{ display: 'none' }}
					type='file'
					id={`${id}-upload-file`}
					accept={accept}
					disabled={disabled}
				/>
			)}
		</div>
	)
}

export default UploadImages
