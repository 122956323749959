import SelectField from 'components/Form/SelectField/SelectField'
import TextField from 'components/Form/TextField/TextField'
import validate from 'helpers/validate';
import React from 'react'
import Locale from 'translations';

export default function ContactInformation({ contactInfoData, setContactInfoData, errors, setErrors, countries }) {
  const { marketPlace, inventory, payment } = Locale;
  function handleContactInformation(value, contactKey) {
    setContactInfoData({
      ...contactInfoData,
      [contactKey]: value
    });
    setErrors({
      ...errors,
      ...validate(
        { name: [contactKey], value: value },
        {
          required: true,
          email: contactKey === "email",
          number: contactKey === "phone",
          min: contactKey === "phone" ? 9 : "",
          max: contactKey === "phone" ? 13 : "",
        }
      )
    });
  }

  return (
    <div className='mx-md-4 mx-1 rounded-lg w-100'>
      <h2 className='my-2 font-weight-bold'>
        {marketPlace.contactInformtion}
      </h2>
      <div className="row m-0 p-2 bg-white">
        {/* contact name */}
        <div className='col-md-6'>
          <TextField
            type="text"
            label={inventory.messages.contactName}
            placeholder={inventory.messages.contactName}
            name="contactName"
            id="contactName"
            value={contactInfoData.name}
            onChange={(e) => handleContactInformation(e.target.value, 'name')}
            errors={errors?.name}
            color={errors?.name?.required ? "danger" : ""}
          />
        </div>
        {/* contact email */}
        <div className='col-md-6'>
          <TextField
            type="email"
            label={payment.messages.Email}
            placeholder={payment.messages.Email}
            name="contactEmail"
            id="contactEmail"
            value={contactInfoData.email}
            onChange={(e) => handleContactInformation(e.target.value, 'email')}
            errors={errors?.email}
            color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
          />
        </div>
        <div className='col-md-6 p-0 d-flex flex-wrap'>
          <div className='col-4'>
            <SelectField
              label={inventory.messages.phoneCode}
              name={"phonecode"}
              id="phonecode"
              // placeholder="select country"
              value={contactInfoData.phoneCode?.phone_code}
              options={countries}
              onChange={(e) => handleContactInformation(e, 'phoneCode')}
              errors={errors?.phoneCode}
              color={errors?.phoneCode?.required ? "danger" : ""}
            />
          </div>
          {/* cellphone */}
          <div className="col-8">
            {/* phone code  */}
            <TextField
              type="number"
              label={inventory.messages.cellphone}
              name="phone"
              id="phone"
              min={9}
              max={13}
              value={contactInfoData?.phone}
              onChange={(e) => handleContactInformation(e.target.value, 'phone')}
              errors={errors?.phone}
              color={errors?.phone?.required || errors?.phone?.min || errors?.phone?.max ? "danger" : ""}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
