import React from 'react'
import { ReactComponent as UploadIcon } from "assets/images/promo/feather-upload-cloud.svg";
import Locale from 'translations';

function UploadLogo({logoSrc, setLogoSrc}) {
	const { marketPlace } = Locale;
	const onDragOver = (e) => {
		e.preventDefault();
		e.currentTarget.style.backgroundColor = "light";
	};
	const onDragLeave = (e) => {
		e.preventDefault();
		e.currentTarget.style.backgroundColor = "white";
	};
	const onDrop = (e) => {
		e.preventDefault();
		e.currentTarget.style.backgroundColor = "white";

		const files = e.dataTransfer.files;
		
		handelFile(files);
	};

	const uploadInputLogo = (e) => {
		const files = e.target.files;
		
		
		handelFile(files);
	};

	function handelFile(files) {
		const file = files[0];
		const reader = new FileReader();
		reader.onload = (e) => {
			const image = e.target.result;
			setLogoSrc(image);
		};
		reader.readAsDataURL(file);
	}
  return (
    <>
    <div className="w-100">
								<h4 className="h4">{marketPlace.Addlogo}</h4>
								{logoSrc && (
									<img
										src={logoSrc}
										alt="logo"
										className="img-fluid my-3"
										style={{ maxHeight: 100 }}
									/>
								)}
							</div>
							<div
								onDragOver={onDragOver}
								onDragLeave={onDragLeave}
								onDrop={onDrop}
								style={{ border: "1px dashed black" }}
								className="w-100 d-flex flex-column justify-content-center align-items-center p-4"
							>
								<UploadIcon className="mb-2" />
								<p>{marketPlace.Drop}</p>
								<label
									htmlFor="file-input"
									className="position-relative my-3"
									role="button"
								>
									<input
										id="file-input"
										type="file"
										onChange={uploadInputLogo}
										className="w-100 h-100 file-upload-input"
									/>
									<button className="btn btn-success text-headline bg-blue px-4 ">
										{marketPlace.choosePhoto}
									</button>
								</label>

								<small>{marketPlace.allowedImage}</small>
							</div>
    </>
  )
}

export default UploadLogo