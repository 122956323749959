import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import { useLocation } from "react-router-dom";

function CustomCarousel({ children, desktopItemNumber = 3 }) {
	const location = useLocation();
	const preview = !location.pathname.includes('edit');
	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 4,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: desktopItemNumber,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

	return preview ? (
		<Carousel
			responsive={responsive}
			slidesToSlide={1}
			keyBoardControl={true}
			className="ms-1 w-100 h-100"
			sliderClass="carousel-items-conteiner"
		>
			{children}
		</Carousel>
	) : (
		<div className='slider-edit-container'>
			{children}

		</div>
	);
}

export default CustomCarousel;
