import Carousel from 'react-multi-carousel';
import { ReactComponent as RightIcon } from "assets/images/webBuilder/right.svg";
import { ReactComponent as LeftIcon } from "assets/images/webBuilder/left.svg";
// import { ReactComponent as SectionBG } from "assets/images/webBuilder/theme-5/theme-5-top-bg.svg";
import SectionBG from "assets/images/webBuilder/theme-5-top-bg.svg"
import DragAndDropContainer from 'modules/WebBuilder-V2/shared/DragAndDropContainer';
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import { SORT_ITEMS_IN_CONTAINER_ACTION } from 'modules/WebBuilder-V2/interfaces/blockTypes';
import HotelCardThemeFive from './HotelCard';
import { AddElement } from 'modules/WebBuilder-V2/shared/AddElement';
import EditableParagraph from 'modules/WebBuilder-V2/shared/EditableParagraph';
import Locale from 'translations';
import { useWebBuilderState } from 'context/webBuilder';
// import ThemeFiveBg from 'modules/WebBuilder-V2/shared/icons/ThemeFiveBg';


const packagesBlocksType = "packagesBlocksType";


export default function HotelTheme5({
  items,
  sortHotelsItems,
  focusContainer,
  editItem,
  itemsBoxActions,
  type,
  openEditModal,
  handleOpenAddModal,
  title,
  body,
  updateHotel
}) {


  const { webBuilder } = Locale;
  const { selectedLanguage } = useWebBuilderState();


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1200, min: 700 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
    },
  };

  const CustomRightArrow = ({ onClick }) => (
    <button
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      onClick={onClick}
    >
      <RightIcon />
    </button>
  );

  const CustomLeftArrow = ({ onClick }) => (
    <button
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      onClick={onClick}
    >
      <LeftIcon className="r-left-icon" />
    </button>
  );

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    return (
      <div className="carousel-button-group">
        <CustomLeftArrow onClick={() => previous()} />
        <CustomRightArrow onClick={() => next()} />
      </div>
    );
  };


  return (
    <>
      <div className="abstract-bg" style={{ maskImage: `url(${SectionBG})` }}>
      </div>

      <div className="container position-relative">
        <div className="services-content">
          <EditableParagraph
            className="service-header h4 text-white"
            contentEditable={true}
            onInput={(value) => {
              updateHotel({ name: "title", value, language: selectedLanguage })
            }}
            text={title}
          />
          <EditableParagraph
            className="heading_desc text-white pb-3"
            contentEditable={true}
            onInput={(value) => {
              updateHotel({ name: "body", value, language: selectedLanguage })
            }}
            text={body}
          />

          <AddElement
            className="mt-3"
            onClick={handleOpenAddModal}
            title={webBuilder.addMoreHotels}
          />
          {/* <h4 className='title'>Top Hotels</h4>
            <p className="heading_desc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
            </p> */}

        </div>
        <div className="hotels-cards-container">
          <Carousel
            responsive={responsive}
            slidesToSlide={1}
            keyBoardControl={true}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside={true}
            arrows={false}
            infinite={true}
          >
            {items.map((props, index) => (
              <DragAndDropContainer
                accept={packagesBlocksType}
                type={packagesBlocksType}
                id={props.id}
                index={index}
                action={SORT_ITEMS_IN_CONTAINER_ACTION}
                moveItemInContainer={(dragIndex, hoverIndex) =>
                  sortHotelsItems({ dragIndex, hoverIndex })
                }
              >
                <EditBox
                  editMode={focusContainer === type}
                  actions={[
                    ...editItem(props, index),
                    ...itemsBoxActions(props.id),
                  ]}
                >
                  <HotelCardThemeFive {...props} itemType="hotel" openEditModal={openEditModal} />
                </EditBox>
              </DragAndDropContainer>
            ))}
          </Carousel>


        </div>
      </div>
    </>
  )
}