import React from "react";

export default function GalleryIcon({ color }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<path
				d="M9.5 22H15.5C20.5 22 22.5 20 22.5 15V9C22.5 4 20.5 2 15.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22Z"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9.5 10C10.6046 10 11.5 9.10457 11.5 8C11.5 6.89543 10.6046 6 9.5 6C8.39543 6 7.5 6.89543 7.5 8C7.5 9.10457 8.39543 10 9.5 10Z"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3.16992 18.9496L8.09992 15.6396C8.88992 15.1096 10.0299 15.1696 10.7399 15.7796L11.0699 16.0696C11.8499 16.7396 13.1099 16.7396 13.8899 16.0696L18.0499 12.4996C18.8299 11.8296 20.0899 11.8296 20.8699 12.4996L22.4999 13.8996"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
