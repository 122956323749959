import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";
import Locale from "translations";
import PackageReservationDetails from "./Details";
import PackageReservationTravelers from "./Travelers";
import {
	fetchPackagesReservation,
	fetchPackagesInvoice,
} from "services/inventory";
import { useParams, useHistory } from "react-router-dom";
import { InvoicePackages } from "components/Printing/InvoicePackages";
import PrintLogo from "assets/images/umrah-operations/logo.svg";

export default function PackageReservation() {
	const { inventory } = Locale;
	const [activeTab, setActiveTab] = useState("1");
	const [packageDetails, setPackageDetails] = useState();
	const [reload, setReload] = useState(false);
	const { id } = useParams();
	const history = useHistory();

	async function getPackageReservationDetails() {
		const PackageRes = await fetchPackagesReservation(id);
		if (PackageRes?.status === 200) {
			setPackageDetails(PackageRes?.data?.data);
		}
	}

	useEffect(() => {
		if (id) {
			getPackageReservationDetails();
		} else {
			history.push("/inventory/packages");
		}
	}, [id, reload]);

	const dawnloadPackageInvoice = async () => {
		const { data: invoice } = await fetchPackagesInvoice(id);
	
		const allServices =
			invoice?.data?.product_details?.length > 0
				? invoice?.data?.product_details
						?.map((service) => {
							return `<tr>
							<td><span style="text-transform: capitalize">${service?.item?.id === 7 ? "Attraction" : service?.item?.name}</span></td>
							<td>${invoice?.data.pax}</td>
						</tr>`;
						})
						.join("")
				: [];
		if (invoice) {
			let respos = InvoicePackages;
			const keyValuePair = {
				"[logo]": PrintLogo,
				"[no_invoice]": invoice?.data?.reference_num,
				"[invoice_date]": invoice?.data?.today,
				"[arrival_date]": invoice?.data?.departure_date,

				"[option_date]": invoice?.data?.expire_at ?? "-",
				"[total]": invoice?.data?.payments_total_paid,
				"[terms]": invoice?.data?.share_terms ?? "-",
				"[share_required_documents]":
					invoice?.data?.share_required_documents ?? "-",
				"[allServices]": allServices,
			};

			for (const key in keyValuePair) {
				respos = respos.replace(key, keyValuePair[key]);
			}

			var params =
				"width=" +
				window.innerWidth +
				", height=" +
				window.innerHeight +
				", top=0, left=0" +
				",toolbar=no,scrollbars=no,status=no,menubar=no";
			//"top=0,left=0,height=80%,width=auto"
			var popupWin = window.open("", " ", params);
			popupWin.document.open();
			popupWin.document.write(`
      <html>
      <head>
      <title> print Invoice</title>
      <style>

      </style>
      </head>
      <body onLoad="{()=>window.print()}">${respos}</body>
      </html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};

	return (
		<div className="container p-md-0">
			<Nav
				tabs
				className="text-capitalize bg-gray flight-booking-nav-tabs d-flex "
			>
				<NavItem className="w-auto">
					<NavLink
						id="package-booking-details"
						className={activeTab === "1" ? "active" : ""}
						onClick={() => setActiveTab("1")}
					>
						<i className="fas fa-calendar-alt"></i>
						<span className="mx-1">{inventory.messages.bookingDetails}</span>
					</NavLink>
				</NavItem>
				{/* tra */}
				<NavItem className="w-auto">
					<NavLink
						id="package-booking-travelers"
						className={activeTab === "2" ? "active" : ""}
						onClick={() => setActiveTab("2")}
					>
						<i className="fas fa-users"></i>
						<span className="mx-1">{inventory.messages.travelers}</span>
					</NavLink>
				</NavItem>
			</Nav>

			<TabContent activeTab={activeTab}>
				{activeTab === "1" ? (
					packageDetails ? (
						<PackageReservationDetails
							details={packageDetails}
							setPackageDetails={setPackageDetails}
							dawnloadPackageInvoice={dawnloadPackageInvoice}
						/>
					) : null
				) : null}
				{activeTab === "2" ? (
					<PackageReservationTravelers
						details={packageDetails}
						setReload={setReload}
					/>
				) : null}
			</TabContent>
		</div>
	);
}
