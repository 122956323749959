import { createContext, useContext, useReducer } from "react";
const OnlineVisaSearch = JSON.parse(localStorage.getItem("TDSonlineVisaSearch"));

const onlineVisaCashedPassengers = JSON.parse(
	localStorage.getItem("TDSonlineVisaCashedPassengers")
);

const redirectURL = JSON.parse(localStorage.getItem("redirectURL"));

const TDSVisaContext = createContext();
const TDSVisaDispatchContext = createContext();

const initialState = {
	onlineVisaPassenger: onlineVisaCashedPassengers ?? [],
	onlineVisaSearch: OnlineVisaSearch ?? "",
	redirectURL: redirectURL ?? "/",

};
// {
// 	filters: null,
// 	countries: null,
// 	categories: null,
// 	vehicleTypes: null,
// 	companies: null,
// 	hotels: null,
// 	transporters: null,
// 	searchResults: null,
// };

function TDSReducer(state, action) {
	switch (action.type) {

		case "onlineVisaSearch": {
			localStorage.setItem("TDSonlineVisaSearch", JSON.stringify(action.payload));
			return {
				...state,
				onlineVisaSearch: {
					...action.payload,
				},
			};
		}


		case "onlineVisaEditPassenger": {
			return {
				...state,
				onlineVisaEditPassenger: action.payload,
			};
		}

		case "onlineVisaRepalceEditPassenger": {
			let removeOldPassenger = [];

			if (action.payload.id) {
				removeOldPassenger = state.onlineVisaPassenger.map(
					(passenger, index) => {
						if (passenger.id === action.payload.id) {
							return action.payload;
						} else {
							return passenger;
						}
					}
				);
			} else {
				removeOldPassenger = state.onlineVisaPassenger.map(
					(passenger, index) => {
						if (
							passenger.uniqueIdFrontend === action.payload.uniqueIdFrontend
						) {
							return action.payload;
						} else {
							return passenger;
						}
					}
				);
			}
			localStorage.setItem(
				"TDSonlineVisaCashedPassengers",
				JSON.stringify([...removeOldPassenger])
			);

			return {
				...state,
				onlineVisaPassenger: [...removeOldPassenger],
			};
		}

		case "onlineVisaPassenger": {
			localStorage.setItem(
				"TDSonlineVisaCashedPassengers",
				JSON.stringify([...state.onlineVisaPassenger, ...action.payload])
			);
			return {
				...state,
				onlineVisaPassenger: [...state.onlineVisaPassenger, ...action.payload],
			};
		}

		case "onlineVisaPassengerRemove": {
			localStorage.setItem(
				"TDSonlineVisaCashedPassengers",
				JSON.stringify(action.payload)
			);
			return {
				...state,
				onlineVisaPassenger: action.payload,
			};
		}

		case "onlineVisaMRZ": {
			
			localStorage.setItem(
				"TDSonlineVisaCashedPassengers",
				JSON.stringify([...state.onlineVisaPassenger, action.payload])
			);

			return {
				...state,
				onlineVisaPassenger: [...state.onlineVisaPassenger, action.payload],
			};
		}
		case "redirectURL": {
			localStorage.setItem("redirectURL", JSON.stringify(action.payload));
			return { ...state, redirectURL: action.payload };
		}
		
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useTDSVisaState() {
	const context = useContext(TDSVisaContext);
	if (context === undefined) {
		throw new Error(
			"useTDSVisaState must be used within a TDSVisaProvider"
		);
	}
	return context;
}

function useTDSVisaDispatch() {
	const context = useContext(TDSVisaDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useTDSVisaDispatch must be used within a TDSVisaProvider"
		);
	}
	return context;
}

function TDSVisaProvider({ children }) {
	const [state, dispatch] = useReducer(TDSReducer, initialState);
	return (
		<TDSVisaContext.Provider value={state}>
			<TDSVisaDispatchContext.Provider value={dispatch}>
				{children}
			</TDSVisaDispatchContext.Provider>
		</TDSVisaContext.Provider>
	);
}

export { TDSVisaProvider, useTDSVisaState, useTDSVisaDispatch };
