import React, { useState } from "react";
// import { useTranslation } from "../../../../hooks/useTranslation";
// import styles from "../../../../styles/umrah/modals/hotel.module.css";
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import ImageGallery from 'modules/market-place/PackageResponse/Details/shared/ImageGallery';
import Locale from 'translations';


function HotelTab({ details }) {
  const {packages} = Locale;
  const roomImages =
    details?.itemable?.hotel?.images?.length > 0
      ? details?.itemable?.hotel?.images?.map((i) => ({
        src: i,
        alt: i,
      }))
      : [];

  // check if hotel facilities is json casue somtimes the facilities is json and sometimes facilities is array
  function isJson(strObj) {
    try {
      JSON.parse(strObj)
    } catch (error) {
      return false
    }
    return true;
  }
  let hotelFacilities = details?.itemable?.hotel?.facilities;
  hotelFacilities = hotelFacilities && isJson(hotelFacilities) ? JSON.parse(hotelFacilities) : hotelFacilities;

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div className="mb-2 border-2 border-top ">
      <div className={"TabsBox"} style={{ px: 0 }}>

        <Nav tabs className="w-100">
          <NavItem>
            <NavLink
              className={`d-flex align-items-center justify-content-center border-0  p-0 
              ${classnames({ active: activeTab === "1", })}
              `}
              onClick={() => {
                toggle("1");
              }}
            >
              <div className="product-build-list-item">
                <span className="text mx-1 text-uppercase">
                  {packages.gallery}
                </span>
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="">
            <NavLink
              className={` d-flex align-items-center justify-content-center border-0  p-0 ${classnames(
                {
                  active: activeTab === "2",
                }
              )}`}
              onClick={() => {
                toggle("2");
              }}
            >
              <div className="product-build-list-item">
                <span className="text mx-1 text-uppercase">{packages.aboutHotel}</span>
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="">
            <NavLink
              className={` d-flex align-items-center justify-content-center border-0  p-0 ${classnames(
                {
                  active: activeTab === "3",
                }
              )}`}
              onClick={() => {
                toggle("3");
              }}
            >
              <div className="product-build-list-item">


                <span className="text mx-1  text-uppercase">{packages.map}</span>
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="">
            <NavLink
              className={` d-flex align-items-center justify-content-center border-0  p-0 ${classnames(
                {
                  active: activeTab === "4",
                }
              )}`}
              onClick={() => {
                toggle("4");
              }}
            >
              <div className="product-build-list-item">
                <span className="text mx-1 text-uppercase">{packages.facilities}</span>
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="">
            <NavLink
              className={` d-flex align-items-center justify-content-center border-0  p-0 ${classnames(
                {
                  active: activeTab === "5",
                }
              )}`}
              onClick={() => {
                toggle("5");
              }}
            >
              <div className="product-build-list-item">
                <span className="text mx-1 text-uppercase">{packages.policies}</span>
              </div>
            </NavLink>
          </NavItem>
        </Nav>


      </div>



      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <ImageGallery images={roomImages} />
        </TabPane>

        <TabPane tabId="2" className='p-3'>
          <dt className='terms-color mb-2'>About Hotel</dt>
          <p>{details?.itemable?.hotel?.description}</p>
        </TabPane>

        <TabPane tabId="3">
          <iframe
            style={{ width: "100%", height: "300px" }}
            src={`https://maps.google.com/?q=${details?.itemable?.hotel?.longitude},${details?.itemable?.hotel?.latitude}&output=embed`}
            title="hotel map"
          ></iframe>
        </TabPane>

        <TabPane tabId="4" className='p-3'>
          <div className='d-flex flex-wrap align-items-center'>
            {Array.isArray(hotelFacilities)?hotelFacilities?.map((facility, index) =>
              <p key={`facility-${index}`} className='mt-1 col-4'>
                <i className="fas fa-check text-success"></i>
                <span className='mx-1'> {facility}</span>
              </p>
            ):hotelFacilities}
          </div>
        </TabPane>

        <TabPane tabId="5" className='p-3'>
          <dt>policies</dt>
          <p>{details?.itemable?.hotel?.terms_conditions}</p>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default HotelTab;
