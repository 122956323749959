import { useEffect } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";

import Locale from "translations";

export default function AWSpay(props) {
	useEffect(() => {
		setTimeout(() => {
			var form = document.getElementById("RunIframe");
			form.submit();
			
			window.addEventListener("message", receiveMessage, false);
		}, 1000);
	}, []);

	const history = useHistory();

	function receiveMessage(event) {
		// check that the message is from the expected iframe window
		if (event.origin !== "https://back-sbs.dev.safa.asia") return;
		let x = event.data;

		console.log("xxxxxxxxxxxxxxxxxxxxxxxxxx",x);
		// do something with the message data
		// 
		// 
		if (x.includes("Payment Successful")) {
			// store.addNotification({
			// 	title: "info!",
			// 	message: payment.messages.paymentSuccessful,
			// 	type: "success",
			// 	insert: "top",
			// 	container: "top-right",
			// 	animationIn: ["animated", "fadeIn"],
			// 	animationOut: ["animated", "fadeOut"],
			// 	dismiss: {
			// 		duration: 2000,
			// 		onScreen: true,
			// 		pauseOnHover: true,
			// 	},
			// 	onRemoval: () => {

			// 			// history.push("/successfullyPayment");

			// 	},
			// });
		setTimeout(() => {
			if (
				x.includes("tour_booking_pay_later") ||
				x.includes("TransferReservation") ||
				x.includes("umrah_holidays_manual_reservation") ||
				x.includes("hotelbeds_manual_reservation") ||
				x.includes("umrah_holidays_update_reservation") 
			) {
				window.location.reload();
			}
			else if (x.includes("wallet_recharge")) {
				history.push("/wallet");
			} else if (x.includes("hotelbeds_transfer_reservation")) {
				history.push("/successfullyPayment");
			} else if (x.includes("flight_booking")) {
				history.push("/inventory/flights");
			} else if (x.includes("tour_booking")) {
				history.push("/inventory/tour");
			} else if (x.includes("hotelbeds_transfer_reservation")) {
				history.push("/inventory/transfer");
			}else if(x.includes("PayOnline/xendit")) {
				history.push("/successfullyPayment/subscription");
			} else   {
				history.push("/successfullyPayment");
			}
			if (localStorage.getItem("toursCart")) {
				localStorage.removeItem("toursCart");
			}
		}, 1000);
		}
	}

	return (
		<>
			<iframe
				name="myframe"
				style={{ width: "100%", height: "450px", border: "none" }}
				title="AWS"
			></iframe>
			<form
				action="https://sbcheckout.payfort.com/FortAPI/paymentPage"
				method="post"
				id="RunIframe"
				target="myframe"
			>
				{Object.keys(props.data).map((res) => {
					return <input type="hidden" name={res} value={props.data[res]} />;
				})}
			</form>
		</>
	);
}

AWSpay.defaultProps = {
	hasFooter: true,
};
