import UploadInput from 'components/UploadInput'
import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { SaveIcon } from './Icons'
import Locale from 'translations'


export default function ExecuteMovementModal({ isOpen, toggle, actionFn }) {
  const {movementManagement} = Locale

  const [executeData, setExecuteData] = useState({
    entrance_stamp: null,
    bus_image: null,
  });

  function handleInputChanges({ key, value }) {
    debugger
    setExecuteData({
      ...executeData,
      [key]: value
    });
  }


  return (
    <Modal isOpen={isOpen} size={"md"} centered className="operation-modal">
      <ModalHeader toggle={toggle}>
        {movementManagement.uploadExecutedFiles}
      </ModalHeader>
      <ModalBody>
        <UploadInput
          label={`${movementManagement.attachEntranceStampFile}  (PDF, Text, Doc)`}
          onUpload={(file, fileId) => handleInputChanges({ key: "entrance_stamp", value: fileId})}
          accept=".pdf, .txt, .doc, .docx, image/png, image/jpeg"
          bucket="official"
        />

        <div className="mt-3">
          <UploadInput
            label={movementManagement.busImage}
            onUpload={(file, fileId) => handleInputChanges({ key: "bus_image", value: fileId })}
            accept=".pdf, .txt, .doc, .docx, image/png, image/jpeg"
            bucket="official"
          />
          {/* <UploadFile
            id={`upload-bus-images`}
            label={`Upload Bus Images`}
            onChange={(value) => {
              handleBusImages(value);
            }}
            value={executeData.bus_images_preview}
          /> */}
        </div>

        <div className="mt-3">
          <button className="btn bg-nxt w-100 d-flex gap-10 align-items-center justify-content-center py-2 font-weight-bold"
            onClick={() => actionFn(executeData)}
            disabled={!executeData.bus_image || !executeData.entrance_stamp}
          >
            <SaveIcon color="#D4A655" />
            {movementManagement.saveChanges}
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}
