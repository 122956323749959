import React, { useState } from "react";
import HeroHeader from "./Header";
import HeroContent from "./MainContent";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { ReactComponent as EditIcon } from "assets/images/webBuilder/edit-hero.svg";
import { ReactComponent as UploadIcon } from "assets/images/webBuilder/upload-hero-img.svg";
import UploadFileComponent from "modules/WebBuilder/shared/UploadFile";
import { EditBox } from "modules/WebBuilder/shared/EditBox";
import NavagationBarModal from "./models/navagationBar";
import MainSectionModal from "./models/mainSection";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import { useLocation } from "react-router-dom";

function Hero() {
	const { hero } = useWebBuilderState();
	const dispatch = useWebBuilderDispatch();
	const location = useLocation();
	const preview = !location.pathname.includes('edit');

	const updateHeroContent = ({ name, value, type }) => {
		dispatch({
			type: "updateHeroContent",
			payload: {
				name,
				value,
				type,
			},
		});
	};
	const updateHeroHeader = ({ name, value, type }) => {
		dispatch({
			type: "updateHeroHeader",
			payload: {
				name,
				value,
				type,
			},
		});
	};

	const handleUpload = (uuid) => {
		updateHeroContent({ name: "backgroundImage", value: uuid });
	};
	const handleHeaderLayout = ({ name, value }) => {
		updateHeroHeader({ type: "layout", name, value });
	};
	const handleContentLayout = ({ name, value }) => {
		updateHeroContent({ type: "layout", name, value });
	};
	const bgImage = hero?.mainContent?.backgroundImage ?? "";
	const contentLayout = hero?.mainContent?.layout?.layoutType;
	//modals
	const [navIsOpen, setNavIsOpen] = useState(false);
	const [mainSecIsOpen, setMainSecIsOpen] = useState(false);
	const [editHeader, setEditHeader] = useState(false);

	const navToggle = () => setNavIsOpen(!navIsOpen);
	const mainToggle = () => setMainSecIsOpen(!mainSecIsOpen);

	const headerActions = (
		<span className="pointer-event" onClick={navToggle}>
			<SettingIcon />
		</span>
	);

	const openEditHeader = (e) => {
		e.stopPropagation();
		setEditHeader(true);
	};
	return (
		<div
			className="web-builder-hero"
			style={{
				...(contentLayout === 1 && {
					backgroundImage: `linear-gradient(#00000021 50%, #00000021), url(${bgImage})`,
				}),
			}}
			onClick={(e) =>  setEditHeader(false)}
		>
			<div className=''>
				<EditBox editMode={editHeader} defaultIcon={headerActions}>
					<HeroHeader editHeader={openEditHeader} />
				</EditBox>
			</div>

			<HeroContent />

			<div>
				{!preview ? (
					<div className="update-hero-layout">
						<button className="hero-content-layout" onClick={mainToggle}>
							<EditIcon />
						</button>
						<UploadFileComponent
							icon={UploadIcon}
							id="hero-bg"
							label="Change image"
							onUpload={handleUpload}
						/>
					</div>
				) : null}
			</div>

			{navIsOpen && (
				<NavagationBarModal
					isOpen={navIsOpen}
					toggle={navToggle}
					handleLayout={handleHeaderLayout}
				/>
			)}
			{mainSecIsOpen && (
				<MainSectionModal
					isOpen={mainSecIsOpen}
					toggle={mainToggle}
					handleLayout={handleContentLayout}
				/>
			)}
		</div>
	);
}

export default Hero;
