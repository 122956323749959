export function SearchIcon() {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke="#DDDCDC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M22 21.9999L20 19.9999" stroke="#DDDCDC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
}


export function TravelerRoomIcon({ color = "#707170", roomType }) {
  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <text x="9.5" y="19.5" fontSize="11px" fontWeight="700" fontFamily="cairo" fill={color}>{roomType}</text>
      <path d="M8.60671 7.24683C8.54004 7.24016 8.46004 7.24016 8.38671 7.24683C6.80004 7.1935 5.54004 5.8935 5.54004 4.2935C5.54004 2.66016 6.86004 1.3335 8.50004 1.3335C10.1334 1.3335 11.46 2.66016 11.46 4.2935C11.4534 5.8935 10.1934 7.1935 8.60671 7.24683Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.49971 14.54C7.28637 14.54 6.07971 14.2333 5.15971 13.62C3.54637 12.54 3.54637 10.78 5.15971 9.70662C6.99304 8.47995 9.99971 8.47995 11.833 9.70662" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}
export function ViewIcon({ color = "#D4A655"}){

  return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M10.3866 7.99999C10.3866 9.31999 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.31999 5.61328 7.99999C5.61328 6.67999 6.67995 5.61333 7.99995 5.61333C9.31995 5.61333 10.3866 6.67999 10.3866 7.99999Z"
   stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1267 14.0732 9.72666C14.6732 8.78666 14.6732 7.20666 14.0732 6.26666C12.5466 3.86666 10.3532 2.48 7.9999 2.48C5.64656 2.48 3.45323 3.86666 1.92656 6.26666C1.32656 7.20666 1.32656 8.78666 1.92656 9.72666C3.45323 12.1267 5.64656 13.5133 7.9999 13.5133Z"
   stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
}