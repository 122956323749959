import CustomModal from "modules/WebBuilder/shared/CustomModal";
import ToggleCheckbox from "modules/WebBuilder/shared/ToggleCheckbox";
import React from "react";
import Locale from "translations";
import { NavBar1, NavBar2, NavBar3, NavBar4 } from "./images";
import { useWebBuilderState } from "context/webBuilder";

const NavagationBarModal = ({ isOpen, toggle, handleLayout }) => {
	const { webBuilder } = Locale;
	const { hero } = useWebBuilderState();
	const header = hero?.header;

	const layoutType = header?.layout?.layoutType;
	const hasLogo = header?.layout?.hasLogo;
	const hasButton = header?.layout?.hasButton;
	const headerBg = header?.layout?.background;

	return (
		<CustomModal
			isOpen={isOpen}
			toggle={toggle}
			header={webBuilder.navBar}
			size="sm"
		>
			<div className="container nav-modal">
				<div className="layout d-flex justify-content-between flex-wrap">
					<p className="w-100 my-2">{webBuilder.layout}</p>
					<div
						className={`${layoutType === 1 ? "active" : ""}`}
						onClick={() => handleLayout({ name: "layoutType", value: 1 })}
					>
						<NavBar1 />
					</div>
					<div
						className={`${layoutType === 2 ? "active" : ""}`}
						onClick={() => handleLayout({ name: "layoutType", value: 2 })}
					>
						<NavBar2 />
					</div>
					<div
						className={`mt-2 ${layoutType === 3 ? "active" : ""}`}
						onClick={() => handleLayout({ name: "layoutType", value: 3 })}
					>
						<NavBar3 />
					</div>
					<div
						className={`mt-2 ${layoutType === 4 ? "active" : ""}`}
						onClick={() => handleLayout({ name: "layoutType", value: 4 })}
					>
						<NavBar4 />
					</div>
				</div>
				<div className="behavior d-flex justify-content-between flex-wrap">
					<p className="w-100 mt-3 mb-2">{webBuilder.behavior}</p>
					<div>
						<input
							className="form-check-input"
							type="radio"
							name="bahavior"
							id="background-radio"
							value="white"
							checked={
								headerBg == "white" ? true : false
							}
							onChange={(e) => {
								const value = e.target.value;
								handleLayout({ name: "background", value });
							}}
						/>
						<label className="text-center" htmlFor="background-radio">
							{webBuilder.background}
						</label>
					</div>
					<div>
						<input
							className="form-check-input"
							type="radio"
							name="bahavior"
							id="transparent-radio"
							value="transparent"
							checked={
								headerBg == "transparent" ? true : false
							}
							onChange={(e) => {
								const value = e.target.value;
								handleLayout({ name: "background", value });
							}}
						/>
						<label className="text-center" htmlFor="transparent-tradio">
							{webBuilder.transparent}
						</label>
					</div>
				</div>
				<hr />
				<div className="toggle">
					<div className="d-flex justify-content-between">
						<p>{webBuilder.logo}</p>
						<ToggleCheckbox
							label="Visible"
							checked={hasLogo}
							onChange={(e) => {
								const value = e.target.checked;
								handleLayout({ name: "hasLogo", value });
							}}
						/>
					</div>
					<div className="d-flex justify-content-between">
						<p>{webBuilder.button}</p>
						<ToggleCheckbox
							checked={hasButton}
							label="Visible"
							onChange={(e) => {
								const value = e.target.checked;
								handleLayout({ name: "hasButton", value });
							}}
						/>
					</div>
				</div>
			</div>
		</CustomModal>
	);
};

export default NavagationBarModal;
