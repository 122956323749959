import React, { useEffect, useState } from "react";
import RoundTripe from "./RoundTripe";
import Oneway from "./Oneway";
import MultiDestination from "./Multidestination";
import Locale from "translations";
import { fetchAirlines, fetchFlightClasses } from "services/marketplace";
import { useSBSState } from "context/global";
import { useMarketplaceState } from "context/marketplace";
function SearchFilghtForm({ closeModal, modify }) {
	const { locale } = useSBSState();
	const { flightsSearch } = useMarketplaceState();

	const { marketPlace } = Locale;
	const [Type, setType] = useState("1");
	const toggleType = (type) => {
		setType(type);
	};

	const [options, setOptions] = useState({
		adults: 
		[
			{ id: 1, name: 1 },
			{ id: 2, name: 2 },
			{ id: 3, name: 3 },
			{ id: 4, name: 4 },
			{ id: 5, name: 5 },
			{ id: 6, name: 6 },
			{ id: 7, name: 7 },
			{ id: 8, name: 8 },
			{ id: 9, name: 9 },
		],
		Children: [
			// { id: "0", name: "0" },
			// { id: 1, name: 1 },
			// { id: 2, name: 2 },
			// { id: 3, name: 3 },
			// { id: 4, name: 4 },
			// { id: 5, name: 5 },
		],
	});

	useEffect(() => {
		const getClass = async () => {
			const res = await fetchFlightClasses();
			// debugger
			setOptions((prev)=>({
				...prev,
				classes: res.map((res, index) => {
					return { id: res, name: res === "PremiumEconomy" ? "PremiumEconomy" : res, };
				}),
			}));
		};
		getClass();
		if (modify) {
			toggleType(flightsSearch?.type);
		}
	}, []);

	function setOptionsChildren(adults) {
		if (10 - adults) {
			setOptions((prev)=>({
				...prev,
				Children: [...Array(10 - adults).keys()].map((res, index) => {
					return {
						id: index,
						name: index,
					};
				}),
			}));
		}
	}

	// AutoCompleteField listAirlines
	const [listAirlines, setListAirlines] = useState([]);
	const getListAirlines = async (inputValue) => {
		if (inputValue.length > 2) {
			const Airlines = await fetchAirlines({ name: inputValue });
			let result = Airlines.map((item) => {
				return {
					...item,
					id: item.code,
					country: {
						flag: item.logo,
					},
					name: item.name[locale],
					value: item.code,
					label: item.name[locale],
				};
			});
			setListAirlines(result);
		}
	};

	return (
		<>
			<div className="col-md-10 m-auto box-types pb-4 px-2">
				<div className="type">
					<input
						type="radio"
						name={marketPlace.roundTrip}
						id="1"
						checked={Type == "1"}
						onClick={() => {
							toggleType("1");
						}}
					/>
					<label htmlFor="1">{marketPlace.roundTrip}</label>
				</div>
				<div className="type">
					<input
						type="radio"
						name={marketPlace.oneway}
						id="2"
						checked={Type == "2"}
						onClick={() => {
							toggleType("2");
						}}
					/>
					<label htmlFor="2">{marketPlace.oneway}</label>
				</div>
				<div className="type">
					<input
						type="radio"
						name={marketPlace.MultiDestination}
						id="3"
						onClick={() => {
							toggleType("3");
						}}
						checked={Type == "3"}
					/>
					<label htmlFor="3">{marketPlace.MultiDestination}</label>
				</div>
			</div>

			{/*****************End Container Tab********************** */}

			{Type === "1" ? (
				<RoundTripe
					Options={options}
					setListAirlines={setListAirlines}
					listAirlines={listAirlines}
					getListAirlines={getListAirlines}
					closeModal={closeModal}
					modify={modify}
					setOptionsChildren={setOptionsChildren}
				/>
			) : Type === "2" ? (
				<Oneway
					Options={options}
					setListAirlines={setListAirlines}
					listAirlines={listAirlines}
					getListAirlines={getListAirlines}
					closeModal={closeModal}
					modify={modify}
					setOptionsChildren={setOptionsChildren}
				/>
			) : (
				<MultiDestination
					Options={options}
					setListAirlines={setListAirlines}
					listAirlines={listAirlines}
					getListAirlines={getListAirlines}
					closeModal={closeModal}
					modify={modify}
					setOptionsChildren={setOptionsChildren}
				/>
			)}
		</>
	);
}

export default SearchFilghtForm;
