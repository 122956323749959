import React from "react";

function CityCard({ cityDetails, scrollToCity }) {
	return (
		<div className="card-city" alt="city image" onClick={()=>scrollToCity(`${cityDetails?.city_name}_${cityDetails?.city_id}`)}>
			<img src={cityDetails?.city_image} alt="" srcset="" />
			<div className="city-name">
				<p>{cityDetails?.city_name}</p>
			</div>
			<div className="layer-top"></div>
		</div>
	);
}

export default CityCard;
