import CustomModal from 'modules/WebBuilder-V2/shared/CustomModal'
import React, { useState } from 'react'
import { Nav, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import UploadNewImage from '../../ToolsTabs/UploadNewImage'
import { ReactComponent as Lib } from 'assets/images/webBuilder/lib.svg'
import { ReactComponent as Upload } from 'assets/images/webBuilder/upload.svg'
import Library from '../../ToolsTabs/Library'
import { ReactComponent as CloseIcon } from 'assets/images/webBuilder/close.svg'

const ToolsModals = ({ isOpen, toggle, updateHeroContent, hasSlider, slides }) => {
	const [activeTab, setActiveTab] = useState('1')
	const [uuids, setUuids] = useState([])

	const toggleTab = (tab) => {
		if (activeTab !== tab) setActiveTab(tab)
	}

	const handleUpload = (uuid) => {
		updateHeroContent({ name: 'backgroundImage', value: uuid })
		toggle()
	}

	const handleSliderImages = (uuids) => {
		updateHeroContent({ name: 'sliders', value: uuids })
	}

	return (
		<CustomModal
			isOpen={isOpen}
			toggle={toggle}
			size='lg'
			header={null}
			hideHeader={true}
		>
			<div className='main-modal'>
				<div className='team-management-tabs   interest bg-white p-0'>
					<div
						style={{ height: '72px' }}
						className='tab-icons fixed-height d-flex align-items-center justify-content-between'
					>
						<Nav className='fixed-height nav-flex-1' tabs>
							<li className='f-a-end'>
								<NavLink
									className={`border-0  w-upload-tabs ${classnames({
										active: activeTab === '1'
									})}`}
									onClick={() => {
										toggleTab('1')
									}}
									style={{
										background:
											activeTab === '1' &&
											'linear-gradient(to right,  #dee2e6, 1%,#FFF 5%)'
									}}
								>
									<div className='toolModal-tabs'>
										<Upload />
										<span className='text mx-1'>Upload New Image</span>
									</div>
								</NavLink>
							</li>
							<li className='f-a-end'>
								<NavLink
									className={`${classnames({
										active: activeTab === '2'
									})}`}
									onClick={() => {
										toggleTab('2')
									}}
									style={{ borderRight: 'none' }}
								>
									<div className='toolModal-tabs'>
										<Lib />
										<span className='text mx-1'>
											<span className='text mx-1'>Library</span>
										</span>
									</div>
								</NavLink>
							</li>
						</Nav>
						<button onClick={toggle} className='btn h-100'>
							<CloseIcon />
						</button>
					</div>

					<TabContent activeTab={activeTab} style={{ minHeight: '100%' }}>
						<TabPane tabId='1'>
							{activeTab === '1' ? (
								<UploadNewImage
									handleUpload={handleUpload}
									slider={uuids}
									setSlider={setUuids}
									handleSliderImages={handleSliderImages}
									toggle={toggle}
									hasSlider={hasSlider}
								/>
							) : null}
						</TabPane>

						<TabPane tabId='2'>
							{activeTab === '2' ? (
								<Library
									handleUpload={handleUpload}
									slides={slides}
									handleSliderImages={handleSliderImages}
									toggle={toggle}
									hasSlider={hasSlider}
								/>
							) : null}
						</TabPane>
					</TabContent>
				</div>
			</div>
		</CustomModal>
	)
}

export default ToolsModals
