import React, { useEffect, useState } from 'react'
import { ReactComponent as ProfileIcon } from 'assets/images/crm/profile-icon.svg'
import { ReactComponent as DoubleProfileIcon } from 'assets/images/crm/profile-2user.svg'
import SelectField from 'components/Form/SelectField/SelectField'
import { generateSharedRoomsType } from '../helpers/roomsAvalibitiesHelpers';
import { roomTypesLookup } from 'modules/products-builder/Requestes/RequestView/helpers/localLookups';
import { store } from 'react-notifications-component';

import { setPackageAvailabilities } from 'services/productbuilder';
import Locale from 'translations';


export default function GuestsPerPax({ productDetails, sharedPaxRooms, setSharedPaxRooms, setReload, adultCount, childrenCount, isMarketPlace }) {
	const {marketPlace} =Locale
	const product = isMarketPlace ? productDetails : productDetails?.product;
	const productRoomsAvailabilities = isMarketPlace ? productDetails?.roomAvailabilities : productDetails?.product?.roomsAvailabilities;
	const productPriceBreakDown = product?.product_price_type_id === 0 || product?.product_price?.product_price_type?.id === 0;
	const productPriceFullPackage = product?.product_price_type_id === 1 || product?.product_price?.product_price_type?.id === 1;

	const [roomsAvailabilities, setRoomsAvalibities] = useState({
		adults: [],
		children: [],
	});

	// get hotels combincations and prices
	function calcPriceFromHotelCombintaions(roomType, paxType) {
		let totalPrice = 0;
		const hotelsCombibation = product?.product_price_combinations;
		let selectedCombination = hotelsCombibation?.find(comb => comb?.combinations[0]?.adults_count === roomType);
		if (productPriceBreakDown) {
			let itemsAdultPrice = +selectedCombination?.breakdown_price_after_discount || 0;
			let itemsChildPrice = +selectedCombination?.child_price_after_discount || 0;
			// let hotelAdultPrice = +selectedCombination?.adult_final_hotel_price || 0;
			totalPrice = paxType === "adults" ? (itemsAdultPrice) : itemsChildPrice;

		} else if (productPriceFullPackage) {
			totalPrice =
				paxType === "adults" ? selectedCombination?.full_price_after_discount :
					paxType === "children" ? selectedCombination?.child_price_after_discount : 0
		}
		return { totalPrice, combination_id: selectedCombination?.id };
	}

	// update pax rooms and room Avalibities
	function handleRoomsAvalibities(roomType, paxType, paxIndex) {
		const { adults } = roomsAvailabilities; // Destructure for clarity

		// Update adults rooms
		const updatedAdultsRooms = adults.map(room => {
			if (room.room_type === roomType?.room_type) {
				return {
					...room,
					room_remaining_availability: room.room_remaining_availability - 1,
					disabled: room.room_remaining_availability - 1 === 0,
				};
			} else {
				return room;
			}
		});

		// Get pax price
		const paxPrice = calcPriceFromHotelCombintaions(roomType?.room_type, paxType);

		// Update sharedPaxRooms
		const sharedPaxClone = { ...sharedPaxRooms };
		let currentPax = sharedPaxClone[paxType][paxIndex];
		currentPax = { ...currentPax, roomType, price: paxPrice.totalPrice, combination_id: paxPrice.combination_id };
		sharedPaxClone[paxType][paxIndex] = currentPax;

		// Update children rooms availability options
		const childrenAvailableRooms = sharedPaxClone?.adults?.filter(adult => adult?.roomType?.room_type)
			.map(room => room?.roomType?.room_type)
			.reduce((acc, roomType) => {
				acc.push({
					id: roomType,
					name: roomTypesLookup?.[roomType],
					value: roomType,
					label: roomTypesLookup?.[roomType],
					room_type: roomType,
				});
				return acc;
			}, []);

		// Update state with the new objects
		setRoomsAvalibities({ adults: updatedAdultsRooms, children: childrenAvailableRooms });
		setSharedPaxRooms(sharedPaxClone);
	}

	// set Avalibities with backend
	async function setPackagesRoomsAvailabilities() {
		const adultsRooms = sharedPaxRooms?.adults?.map(adult => {
			return {
				combination_id: adult?.combination_id,
				is_adult: 1,
				traveler_id: isNaN(adult?.id) ? null : adult?.id
			}
		});
		const childRooms = sharedPaxRooms?.children?.map(child => {
			return {
				combination_id: child?.combination_id,
				is_adult: 0,
				traveler_id: isNaN(child?.id) ? null : child?.id
			}
		});
		const roomsAvailability = [...adultsRooms, ...childRooms].filter(room => room?.combination_id);
		const data = {
			reservation_num: productDetails?.reservation_num,
			rooms_availability: roomsAvailability
		}
		const res = await setPackageAvailabilities(data);
		if (res?.status === 200) {
			store.addNotification({
				title: "info!",
				message: "Traveler added Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true,
					pauseOnHover: true,
				},
			})
			setReload((prev) => !prev);
		}
	}

	useEffect(() => {
		const { adultRooms, childrenRooms } = generateSharedRoomsType(productRoomsAvailabilities, sharedPaxRooms)
		setRoomsAvalibities({ adults: adultRooms, children: childrenRooms });
	}, [productRoomsAvailabilities, sharedPaxRooms]);


	return (
		<div className='crm-details-payment'>
			<div className='items-guests-per-pax'>
				<div className='item-special item-guests-per-pax'>
					<div className='d-flex align-items-center' style={{ gap: '8px' }}>
						<DoubleProfileIcon />
						<span>{adultCount} {marketPlace.Adults}, {childrenCount} {marketPlace.Child}</span>
					</div>
				</div>
				{sharedPaxRooms?.adults?.map((adult, paxIndex) => {
					return (
						<div className='item-guests-per-pax' key={`adult-shared-room-${paxIndex}`}>
							<div className='d-flex align-items-center' style={{ gap: '8px' }}>
								<ProfileIcon />
								<span>{marketPlace.Adult} {paxIndex + 1}</span>
							</div>
							<div className="d-flex align-items-center justify-content-end gap-10 col-md-5 col-12 p-0">
								<div className="col-md-6 p-0">
									<SelectField
										hasLabel={false}
										placeholder={marketPlace.RoomType}
										id={`adult-${paxIndex}-room`}
										options={roomsAvailabilities.adults}
										value={adult?.roomType?.label}
										onChange={(e) => handleRoomsAvalibities(e, "adults", paxIndex)}
									/>
								</div>
								<span>
									{`${adult?.price || 0} ${productDetails?.currency}`}
								</span>
							</div>
						</div>
					)
				})}
				{sharedPaxRooms?.children?.map((child, paxIndex) => {
					return (
						<div className='item-guests-per-pax' key={`child-shared-room-${paxIndex}`}>
							<div className='d-flex align-items-center' style={{ gap: '8px' }}>
								<ProfileIcon />
								<span>{marketPlace.Child} {paxIndex + 1}</span>
							</div>
							<div className="d-flex align-items-center justify-content-end gap-10 col-md-5 col-12 p-0">
								<div className="col-md-6 p-0">
									<SelectField
										hasLabel={false}
										placeholder={marketPlace.RoomType}
										id={`adult-${paxIndex}-room`}
										options={roomsAvailabilities.children}
										value={child?.roomType?.label}
										onChange={(e) => handleRoomsAvalibities(e, "children", paxIndex)}
									/>
								</div>
								<span>
									{`${child?.price || 0} ${productDetails?.currency}`}
								</span>
							</div>
						</div>
					)
				})}
				{!isMarketPlace &&
					<div className='guests-actions justify-content-end mt-3'>
						<button className='btn btn-active' onClick={setPackagesRoomsAvailabilities}>{marketPlace.SaveChanges}</button>
					</div>
				}

			</div>
		</div>
	)
}