import React from 'react'
import AccountingCateringItem from './CateringItem'

export default function AccountingCatering({isPricePerService,accountingStatement,setAccountingStatement, canEdit}) {

  return (
    <div className='accounting-item hotels  py-0'>
      <div className="d-flex flex-column gap-10">
        {accountingStatement?.catering?.length>0&&accountingStatement?.catering.map(item => {
          return (
            <div className="accounting-item-wrapper"  key={item?.hotel?.id}>
              <AccountingCateringItem
                catering={item}
                isPricePerService={isPricePerService}
                accountingStatement={accountingStatement}
                setAccountingStatement={setAccountingStatement}
                canEdit={canEdit}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}



