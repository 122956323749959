import React, { useEffect, useState } from 'react'
import BookingSummary from './BookingSummary'
import SuccessfullyTourPayment from './SuccessfullyTourPayment';
import TourSummaryReservation from './TourSummaryReservation'
import { getCart } from 'services/tours';
import { useMarketplaceDispatch, useMarketplaceState } from 'context/marketplace';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function ToursSummary() {
  const dispatch = useMarketplaceDispatch();
  const { toursCart } = useMarketplaceState();
  const [isSuccessfullPayment, setIsSuccessfullPayment] = useState(false);
  const [reservationRef, setReservationRef] = useState("");
  const history = useHistory();
  const cartId = toursCart?.id;

  const calcTotalCartPrice = {
    total: toursCart?.price,
    currency: toursCart?.currency,
    provider_currency: toursCart?.provider_currency,
    provider_price: toursCart?.provider_price
  };
  
 // const isManual=toursCart?.cartItems.filter((item)=>item.details?.bookingType==="manual" )?.length>0
const [isManual, setIsManual] = useState(false)
  // get updated cart
  async function getUpdatedCart() {
  setIsManual(toursCart?.cartItems.filter((item)=>item.details?.bookingType==="manual" )?.length>0)
  if(cartId && !isManual){
      const tourCartRes = await getCart(cartId);
      if (tourCartRes?.status >= 200 && tourCartRes?.status < 300) {
        dispatch({
          type: "toursCart",
          payload: tourCartRes?.data?.cart,
        })
      } else {
        dispatch({
          type: "toursCart",
          payload: null,
        })
        history.push('/tours-result')
      }
    }
    
  }
  useEffect(() => {
    getUpdatedCart()
  }, []);

  return (
    <div className='container tours-summary-container'>
      {!isSuccessfullPayment ?
        <div className='d-flex justify-content-between flex-wrap'>
          {toursCart && Object.keys(toursCart)?.length > 0 &&
            <>
              <div className="col-md-8">
                <TourSummaryReservation
                  setIsSuccessfullPayment={setIsSuccessfullPayment}
                  setReservationRef={setReservationRef}
                  calcTotalCartPrice={calcTotalCartPrice}
                />
              </div>
              <div className="col-md-4">
                <BookingSummary calcTotalCartPrice={calcTotalCartPrice} />
              </div>
            </>
          }
        </div>
        :
        <SuccessfullyTourPayment reservationRef={reservationRef} isManual={isManual}/>
      }
    </div>
  )
}
