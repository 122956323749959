import CustomModal from "modules/WebBuilder/shared/CustomModal";
import React, { useEffect, useState } from "react";
import Locale from "translations";

import { useWebBuilderState } from "context/webBuilder";
import TextField from "components/Form/TextField/TextField";
import SelectField from "components/Form/SelectField/SelectField";
const HeaderButtonModal = ({ isOpen, toggle, save }) => {
	const [buttonData, setButtonData] = useState({
		name: "",
		linkTo: "",
	});

	const { hero, ourServices } = useWebBuilderState();

	const serviecs = ourServices?.services
		? ourServices?.services.map((service) => ({
				name: service.type,
				id: service.id,
		  }))
		: [];

	const contactUs = hero?.header.contactUs;
	const { webBuilder } = Locale;

	const linkToOptions = serviecs.concat({
		name: "Contact us",
		id: "contact-us-section",
	});

	useEffect(() => {
		if (contactUs) {

			const linkTo = linkToOptions.find((i) => i.id === contactUs?.id );
			setButtonData({
				name: contactUs?.name,
				linkTo,
			});
		}
		return () => {};
	}, []);

	const apply = (e) => {
		e.preventDefault()
		save({ name: buttonData.name, id:buttonData.linkTo?.id });
		toggle()
	};

	const handleButtonData = ({ name, value }) => {
		setButtonData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	return (
		<CustomModal
			isOpen={isOpen}
			toggle={toggle}
			header={webBuilder.mainSection}
			size="md"
		>
			<form className="container main-modal d-flex flex-column" onSubmit={apply}>
				<div className="w-100" > 
					<TextField
						type="text"
						label="Button Name"
						placeholder="Button Name"
						id="buttonName"
						name="buttonName"
						value={buttonData.name}
						onChange={(e) =>
							handleButtonData({ name: "name", value: e.target.value })
						}
						required
					/>
				</div>
				<div className="w-100 my-3">
					<SelectField
						label="Link To"
						placeholder="Link To"
						value={buttonData?.linkTo?.name}
						options={linkToOptions}
						onChange={(e) => {
							handleButtonData({ name: "linkTo", value: e });
						}}
					/>
				</div>
				<hr className='w-100' />
				<button className="btn primary-button contact-us-modal-button mt-2" type='submit'>
					Save
				</button>
			</form>
		</CustomModal>
	);
};

export default HeaderButtonModal;
