import React from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";

export default function AdminBankTransferFilter(props) {
	const history = useHistory();
	return (
		<>
			<div className="main-filter">
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-md-2 px-1">
						<div className="main-label">
							{/*  onChange={this.filterList} */}
							<input
								id="input-search"
								className="main-input-search"
								type="text"
								placeholder="Sreach"
							/>
							<i className="fas fa-search fa-fw"></i>
						</div>
					</div>

					<div className="col-md-1 px-1">
						<select id="product-type" className="main-input-search">
							<option checked="chacked">Status</option>
							<option>Status 2</option>
							<option>Status 3</option>
							<option>Status 4</option>
							<option>Status 5</option>
						</select>
					</div>

					<div className="col-md px-1">
						<div className="d-flex align-items-center text-secondary pointer">
							<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
							<p className="text-caption">Reset Filter</p>
						</div>
					</div>

					<div className="product-buttons">
						<Button
							color="success"
							onClick={() => history.push("/add-bank-transfer")}
						>
							Add Bank
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}
