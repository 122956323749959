import walletGraySm from "assets/images/wallet/walletGraySm.svg";
import { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";
import { getListWallets } from "services/wallet";
import Locale from "translations";
import WalletCollapse from "./walletCollapse";
import WalletTables from "./walletTables";

export default function Wallet() {
		const { payment } = Locale;
	const [walletsList, setWalletsList] = useState();

	//walletGraySm
	const getWallets = async () => {
		const res = await getListWallets();
		if (res?.data?.data.filter(res=>res.currency==="IDR").length>0) {
			let list=res?.data?.data
			const fromIndex = list.findIndex(res=>res.currency==="IDR"); // 👉️ 0
			const toIndex = 0;
			const element = list.splice(fromIndex, 1)[0];
		
			list.splice(toIndex, 0, element);
		}
		setWalletsList(res?.data?.data);
	};
	useEffect(() => {
		getWallets();
	}, []);

	//-------------------New----------------------------------
	//{setActiveTab(wallet.type == "credit" ?? wallet?.ref_num)}

	const [activeTab, setActiveTab] = useState(
		walletsList?.filter((item) => item.type !== "credit")[0]?.ref_num
	);
	useEffect(() => {
		setActiveTab(
			walletsList?.filter((item) => item.type !== "credit")[0]?.ref_num
		);
	}, [walletsList]);


	const toggleTab = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	const scrollToContent = () => {
		const walletTabContentRef = document.getElementById("walletTabContentRef")
		walletTabContentRef.scrollIntoView({block:"start",behavior:"smooth"})
	}

	const allTabs = (
		<div className="main-tabs1 d-flex ">
			<Nav tabs className="align-items-start  justify-content-center main-nav1">
				<NavItem className=" mx-3 ">
					{walletsList?.length > 0
						? walletsList.map((wallet, index) => (
								<NavLink
									className={`box-wallet-tab mb-2 ${
										activeTab === wallet.ref_num ? "active" : "no-active"
									}`}
									onClick={() => {
										toggleTab(wallet.ref_num);
										scrollToContent();
									}}
								>
									<small className="triangle"></small>

									<div className="d-flex  justify-content-between ">
										<div>
											<div className="w-100 d-flex  justify-content-between ">
												<h3 className="bg-yellow title-wallet name-wallet">
													{wallet?.type === "credit" ? (
														`${payment.messages.safaWalletCaptial}`
													) : (
														<>
															<h3 className="bg-yellow title-wallet name-wallet">
																{wallet?.currency}{" "}
																{payment.messages.WalletCaptial}
															</h3>
															<div>
																<h4 className="text-gray-wallet ">
																	{payment.messages.accountNo}:{" "}
																	{wallet?.ref_num}
																</h4>
															</div>
														</>
													)}
												</h3>

												{/* <img src={walletGraySm} alt="wallet img" /> */}
											</div>

											<div className="w-100 d-flex  justify-content-between flex-wrap mt-4 ">
												{wallet?.type === "credit" ? (
													<div className="amount-safa">
														<h4>{payment.messages.creditLeft} </h4>
														<span>
															{wallet?.amount} {wallet?.currency}
														</span>
													</div>
												) : (
													<div>
														<h4>{payment.messages.balance} </h4>
														<span>
															{wallet?.amount} {wallet?.currency}
														</span>
													</div>
												)}
												{wallet?.type === "credit" ? (
													<div className="amount-safa">
														<h4>{payment.messages.totalLimit} </h4>
														<span>
															{" "}
															{wallet?.limit} {wallet?.currency}
														</span>
													</div>
												) : (
													<div></div>
												)}
											</div>
										</div>
										<img
											src={walletGraySm}
											alt="wallet img"
											className="h-100 w-35"
										/>
									</div>
								</NavLink>
						  ))
						: null}
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTab} className="">
				<div className="main-nav-contant ">
					{walletsList?.length > 0 ? (
						walletsList.map((wallet, index) =>
							activeTab === wallet?.ref_num ? (
								<>
									<WalletTables
										id={wallet?.id}
										wallet={wallet}
										walletsList={walletsList}
										getWallets={getWallets}
										currency={wallet?.currency}
									/>
								</>
							) : (
								""
							)
						)
					) : (
						<tr>
							<td colSpan="5">
								<div className="product-build__product-no-data fullHeight">
									<i className="icx icx-eye-slash" aria-hidden="true"></i>
									<h4>{payment.messages.noResults}</h4>
								</div>
							</td>
						</tr>
					)}
				</div>
			</TabContent>
		</div>
	);

	const allWallets =
		walletsList?.length > 0 ? (
			walletsList.map((wallet, index) => (
				<WalletCollapse
					index={index}
					wallet={wallet}
					walletsList={walletsList}
					getWallets={getWallets}
					allTabs={allTabs}
					setActiveTab={setActiveTab}
				/>
			))
		) : (
			<tr>
				<td colSpan="5">
					<div className="product-build__product-no-data fullHeight">
						<i className="icx icx-eye-slash" aria-hidden="true"></i>
						<h4>{payment.messages.noResults}</h4>
					</div>
				</td>
			</tr>
		);

	return (
		<div className="wallet-wrraper wallet-wrraper-responsive">
			<div className="container">
				{allTabs}
				{/* {allWallets} */}
			</div>
		</div>
	);
}
