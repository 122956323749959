import { useWebBuilderState } from "context/webBuilder";

import { EditBox } from "modules/WebBuilder/shared/EditBox";

import ProfileIcon from "assets/images/webBuilder/profile.svg";
import SmsIcon from "assets/images/webBuilder/sms.svg";
import EditableParagraph from "modules/WebBuilder/shared/EditableParagraph";
import MobileIcon from "assets/images/webBuilder/mobile-icon.svg";

import TextField from "components/Form/TextField/TextField";
import { useLocation } from "react-router-dom";
import UploadFileComponent from "modules/WebBuilder/shared/UploadFile";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import Locale from "translations";

export default function ContactUsTheme10({
	details,
	onFocus,
	focusContainer,
	updateContactus,
	containerBoxActions,
	updateContactusContent,
	onSubmitForm,
	handleFormData,
	formData,
}) {
	const { selectedLanguage } = useWebBuilderState();
	const { webBuilder } = Locale;
	const { id, body, title, image } = details;
	const location = useLocation();

	console.log(image);


	const preview = !location.pathname.includes("edit");

	const Image = image ? <img src={image} alt="Logo" /> : null;


	const logo = preview ? (
		Image
	) : (
		<UploadFileComponent
			icon={AddIcon}
			id={`contact-us-section`}
			label=""
			onUpload={(value) => {
				updateContactus({ name: "image", value });
			}}
			image={Image}
		/>
	);
	return (
		<EditBox editMode={focusContainer === id} actions={containerBoxActions}>
			<div onClick={() => onFocus(id)} className="contact-container container" id={id}>


				<div className="contact-form-holder">
					<div className="contact-us-image">{logo}</div>

					<form onSubmit={onSubmitForm} className="contact-us-form">

						<div className="content-top-section title-holder">
							<EditableParagraph
								contentEditable={true}
								onInput={(value) =>
									updateContactusContent({
										value,
										name: "title",
										language: selectedLanguage,
									})
								}
								className="h4 t-title"
								text={title}
							/>

							<EditableParagraph
								contentEditable={true}
								onInput={(value) =>
									updateContactusContent({
										value,
										name: "body",
										language: selectedLanguage,
									})
								}
								className="text-body t-body"
								text={body}
							/>
						</div>


						<div className="contact-us-fields my-3">
							<TextField
								hasLabel={false}
								placeholder={webBuilder.name}
								value={formData.name}
								onChange={(e) =>
									handleFormData({ name: "name", value: e.target.value })
								}
								isImage
								image={ProfileIcon}
								prependImage
								required
							/>
						</div>
						<div className="contact-us-fields mb-3">
							<TextField
								hasLabel={false}
								placeholder={webBuilder.email}
								type="email"
								value={formData.email}
								onChange={(e) =>
									handleFormData({ name: "email", value: e.target.value })
								}
								isImage
								image={SmsIcon}
								prependImage
								required
							/>
						</div>

						{/* phone  */}
						<div className="contact-us-fields mb-3">
							<TextField
								type="number"
								hasLabel={false}
								id="form-phone"
								name="form-phone"
								placeholder={webBuilder.phone}
								value={formData.phone}
								isImage
								image={MobileIcon}
								prependImage
								required
								onChange={(e) =>
									handleFormData({ name: "phone", value: e.target.value })
								}
							/>
						</div>

						<textarea
							rows={5}
							placeholder={webBuilder.message}
							value={formData.notes}
							onChange={(e) =>
								handleFormData({ name: "notes", value: e.target.value })
							}
							required
							className="textarea-field mb-3 w-100"
						/>

						<button
							type={preview ? "submit" : "button"}
							className="btn submit-btn"
						>
							{webBuilder.submit}
						</button>
					</form>
				</div>
			</div>

		</EditBox>
	);
}
