import bankImg from "assets/images/wallet/bankImg.png";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import { useState } from "react";
import { store } from "react-notifications-component";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { payByDebit } from "services/wallet";
import Locale from "translations";
import validator from "validator";
import CurrentBalance from "../CurrentBalance";
import WalletBoxs from "../walletBoxs";
import useWallet from "./useWallet";

export default function BankTransfer(props) {
	const history = useHistory();
	const { payment } = Locale;
	const { safaBalance } = useSBSState();
	const { myBalance } = useSBSState();
	const { fetchWallets } = useWallet();
	const bankTransferReceipt = useSelector((state) => state.payment_reducer);
	const [amount, setAmount] = useState(bankTransferReceipt.amount ?? null);
	const [formValidation, setFormValidation] = useState({
		amount: true,
	});
	const validateAmountField = () => {
		setFormValidation({
			...formValidation,
			amount: validator.isNumeric(`${amount}`, ["ar-EG"]),
		});
	};
	const submit = () => {
		if (validator.isNumeric(`${amount}`, ["ar-EG"])) {
			AddDebit();
		} else {
			store.addNotification({
				title: "info!",
				message: "Amount should be Number",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	async function AddDebit() {
		const res = await payByDebit({
			amount: +amount,
			//description: "edf333",
		});
		if (res.status == 200 || res.status == 201) {
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setTimeout(() => {
				history.push("/payment-successful");
				fetchWallets();
			}, 200);
		}
	}

	return (
		<>
			<div className="wallet-wrraper">
				<div className="container-fluid">
					<div className="row  my-3	">
						<div className="col-md-3">
							<CurrentBalance />
						</div>
						<WalletBoxs />

						{/* <div className="col-md-4 text-center ">
							<Summary />
						</div> */}
					</div>

					{/* <RequestBank /> */}
					<div className="my-5 d-hiden">0</div>

					<div className="bank-transfer-section mt-5">
						<h6 className="credit-card-head ">
							{payment.messages.walletByBankTransfer}
						</h6>
						<div className="row m-0 pt-5">
							<div className="col-md-6 ">
								<h6 className="mb-1 font-weight-bold">
									{payment.messages.bankInfo}
								</h6>
								<div className="bank-information">
									<div className="img-box">
										<img alt="bankImage" src={bankImg} />
									</div>
									<div className="bank-info">
										<h6 className="bank-head font-weight-bold">
											Arab African International Bank
										</h6>
										<p>Maadi, Laselky Branch Cairo-Egypt EGP Account: 526115</p>
									</div>
								</div>
							</div>

							<div className="col-6 ">
								<div className="d-flex flex-column align-items-center border  ">
									<i class="fas fa-arrow-right fa-3x text-blue my-1 "></i>
									<i class="fas fa-arrow-left fa-3x text-danger my-1 "></i>
									<h3>{"TransferAccounts"}</h3>
									<div className="row w-100  justify-content-center my-3 position-relative">
										<div className="medical_box  border rounded box-width p-2 text-center mx-1 col-11 col-md-5 mt-2 mt-md-0">
											<p className="m-0">{"Company Account"}</p>
											<p className="m-0">{myBalance?.amount}</p>
										</div>

										<span className=" rounded-circle bg-white to p-3 text-bolder text-primary ">
											{"to"}
										</span>

										<div className="medical_box border rounded box-width p-2 text-center mx-1 col-11 col-md-5 ">
											<p className="m-0">{"Safa Account"}</p>
											<p className="m-0">{safaBalance?.amount}</p>
										</div>
									</div>
									<div className="col-11 col-md-10 p-0">
										<TextField
											label={payment.messages.amount}
											placeholder={payment.messages.typeAmount}
											value={amount}
											onChange={(e) => setAmount(e.target.value)}
											onBlur={validateAmountField}
											color={formValidation.amount ? "" : "danger"}
											isInvalid={!formValidation.amount}
										></TextField>
									</div>

									<button
										type="button"
										className="btn btn-success py-2 mb-4 col-11 col-md-10 btn btn-primary submit-btn"
										onClick={submit}
										disabled={
											!Object.values(formValidation).every(
												(item) => item === true
											) || [amount].includes(null)
										}
									>
										{payment.messages.payNow}
									</button>
								</div>
							</div>
							{/* <div className="col-md-6">
								<form>
									<h6 className="mb-1 font-weight-bold">
										{payment.messages.sendReceiptInfo}
									</h6>
									<div className="receipt-information">
										<div className="img-box">
											<img src={bankTransfer} alt="BankTransfer" />
										</div>
										<div className="receipt-info">
											<div className="row m-0">
												<div className="col-md-6">
													<TextField
														placeholder={payment.messages.typeAmount}
														label={payment.messages.receiptNo}
														feedbackMessage={
															payment.validation.receiptNumberValidationMessage
														}
														value={receipt}
														onChange={(e) => setReciept(e.target.value)}
														onBlur={validateReceipt}
														color={formValidation.receipt ? "" : "danger"}
														isInvalid={!formValidation.receipt}
													/>
												</div>

												<div className="col-md-6">
													<TextFieldWithSelect
														label={payment.messages.amount}
														placeholder={payment.messages.typeAmount}
														selectPosition="append"
														feedbackMessage={
															payment.validation.receiptAmountValidationMessage
														}
														value={amount}
														onChange={(e) => setAmount(e.target.value)}
														onBlur={validateAmountField}
														color={formValidation.amount ? "" : "danger"}
														isInvalid={!formValidation.amount}
													>
														<option value="ar">EGP</option>
														<option value="en">SAR</option>
													</TextFieldWithSelect>
												</div>

												<div className="col-md-6">
													<DatePickerField
														placeholder="DD/MM/YYY"
														label={payment.messages.amount}
														date={amountDate}
														onChangeDate={setAmountDate}
													/>
												</div>

												<div className="col-md-6">
													<div
														className="input-upload"
														onClick={handleBtnClick}
													>
														<p className="mb-0 pointer px-1">
															{payment.messages.receiptImg}
														</p>
														<label>
															<div className="shapes">
																{filePreview ? (
																	<img
																		src={filePreview}
																		className="image-uploader__img"
																		alt="logo placeholder"
																		accept="image/*"
																	/>
																) : (
																	<i className="fa fa-plus"></i>
																)}
															</div>
														</label>

														<input
															type="file"
															// id="banktransfer-upload"
															ref={inputFileRef}
															onChange={onFilechange}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="transfer-buttons">
										<div>
											<i className="fas fa-caret-left pr-1"></i>
											<a onClick={() => history.push("/wallet")} id="backLogin">
												{payment.messages.back}
											</a>
										</div>
										<button
											type="button"
											className="btn btn-primary submit-btn"
											onClick={submit}
											disabled={
												!Object.values(formValidation).every(
													(item) => item === true
												) || [receipt, amount, amountDate].includes(null)
											}
										>
											{payment.messages.payNow}
										</button>
									</div>
								</form>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
