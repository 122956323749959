import classnames from "classnames";
import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Locale from "translations";
import HotelTable from "./HotelTable";
import TransportationTable from "./TransportationTable";

import hotelColored from "assets/images/interest/hotelColored.svg";
import hotelGrey from "assets/images/interest/hotelGrey.svg";
import transportationImg from "assets/images/statistics/busColor.svg";

export default function BookingList() {
	const { inventory } = Locale;
	const [activeTab, setActiveTab] = useState("1");
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	return (
		<>
			<div className="main-tabs px-1  interest">
				<Nav
					tabs
					className="align-items-center main-nav main-nav-statistics main-nav-sbs"
				>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === "1" })}
							onClick={() => {
								toggle("1");
							}}
						>
							<img
								src={activeTab === "1" ? hotelGrey : hotelColored}
								alt="hotel"
								className="mx-1"
							/>
							{inventory.messages.hotels}
						</NavLink>
					</NavItem>
					<NavItem className="navItem-dashboard-container">
						<NavLink
							className={`${activeTab === "2" ? "active" : ""}`}
							onClick={() => {
								toggle("2");
							}}
							disabled
						>
							{activeTab === "2" ? (
								<img src={transportationImg} alt="hotel" className="mx-1" />
							) : (
								<i className="icx icx-transport fa-fw fa-lg mx-1"></i>
							)}

							{inventory.messages.transportation}
							<span className="soon">{inventory.messages.soon}</span>
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab}>
					<TabPane tabId="1">
						{activeTab === "1" ? <HotelTable /> : null}
					</TabPane>

					{/* Hotel Tab */}
					<TabPane tabId="2">
						{activeTab === "2" ? <TransportationTable /> : null}
					</TabPane>
				</TabContent>
			</div>
		</>
	);
}
