import React from 'react'

export default function InsuranceIcon({size}) {
  return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size ? size : `32`}
			height={size ? size : `32`}
			viewBox="0 0 32 32"
			fill="none"
		>
			<mask
				id="mask0_1326_1349"
				style={{ maskType: "luminance" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="32"
				height="32"
			>
				<path
					d="M30.9999 31V1.00009H1V31H30.9999Z"
					fill="white"
					stroke="white"
					stroke-width="2"
				/>
			</mask>
			<g mask="url(#mask0_1326_1349)">
				<path
					d="M20.9999 0.937592H7.24998C5.86929 0.937592 4.74998 2.0569 4.74998 3.43758V28.5625C4.74998 29.9432 5.86929 31.0625 7.24998 31.0625H24.7499C26.1306 31.0625 27.2499 29.9432 27.2499 28.5625V7.18757L20.9999 0.937592Z"
					stroke="#707170"
					stroke-width="2"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M18.6319 11.3265L18.4999 11.0625H13.5L13.368 11.3265C13.032 11.9984 12.5156 12.5635 11.8766 12.9584C11.2375 13.3533 10.5012 13.5625 9.74997 13.5625V18.9123C9.74997 22.7853 12.2903 26.1996 16 27.3125C19.7095 26.1996 22.2499 22.7853 22.2499 18.9123V13.5625C21.4987 13.5625 20.7623 13.3534 20.1233 12.9584C19.4843 12.5635 18.9678 11.9984 18.6319 11.3265Z"
					stroke="#707170"
					stroke-width="2"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M18.6516 17.4198L15.1161 20.9553L13.3483 19.1876"
					stroke="#707170"
					stroke-width="2"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M27.2499 7.18762H20.9999V0.937641L27.2499 7.18762Z"
					stroke="#707170"
					stroke-width="2"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
		</svg>
	);
}
