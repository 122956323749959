import UploadImages from "modules/WebBuilder-V2/shared/UploadImages";
import React, { useState } from "react";
import { uploadFile } from "services/auth";

const UploadNewImage = ({
	handleUpload,
	slider,
	setSlider,
	handleSliderImages,
	toggle,
	hasSlider
}) => {
	const [imageURL, setImageURL] = useState("");

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const changeImage = () => {
		if (imageURL) handleUpload(imageURL);
	};

	const upload = async (file) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("name", file?.name);
		formData.append("bucket", "webBuilder");
		const response = await uploadFile(formData);
		if (response.data.data.uuid) {
			return response.data.data.uuid;
		}
	};

	const handleDrop = async (event) => {
		event.preventDefault();
		const formData = event.dataTransfer.files[0];
		if (hasSlider) return;
		else {
			const uuid = (await upload(formData)) ?? "";
			const URI = process.env.REACT_APP_FILEUPLOAD_URL;
			const fileUrl = `${URI}/fetch/${uuid}`;
			if (uuid) handleUpload(fileUrl);
		}
	};

	const uploadImages = async () => {
		handleSliderImages(slider);
		toggle();
	};
	return (
		<div className="p-5 bg-white">
			{hasSlider ? (
				<div>
					<div
						className="p-5 tools-main-upload m-modal-2"
						onDragOver={handleDragOver}
						onDrop={handleDrop}
					>
						<UploadImages
							title="Browse your Computer"
							id="image-hero"
							disabled={false}
							onUpload={handleSliderImages}
							hasSlider={hasSlider}
							setSlider={setSlider}
						/>
						<p
							className="tools-header-secondary m-modal-1"
							style={{ fontSize: "18px", color: "#707170" }}
						>
							Only gif, jpeg, png, bmp, ico are accepted up to 10 MB
						</p>
					</div>
					<div className="d-flex justify-content-end p-1">
						<button
							className="btn btn-block w-25 mt-2 p-1"
							onClick={uploadImages}
							style={{
								background: "#292d32",
								color: "#FFF",
								fontSize: "1rem",
							}}
							disabled={slider?.length <= 1}
						>
							Save
						</button>
					</div>
				</div>
			) : (
				<div
					className="p-5 tools-main-upload m-modal-2"
					onDragOver={handleDragOver}
					onDrop={handleDrop}
				>
					<h3 className="tools-header-primary m-modal-1">Drag File here</h3>
					<h3 className="tools-header-secondary m-modal-1">OR</h3>
					<UploadImages
						title="Browse your Computer"
						id="image-hero"
						disabled={false}
						onUpload={handleUpload}
					/>
					<p
						className="tools-header-secondary m-modal-1"
						style={{ fontSize: "18px", color: "#707170" }}
					>
						Only gif, jpeg, png, bmp, ico are accepted up to 10 MB
					</p>
					<h3 className="tools-header-secondary m-modal-1">OR</h3>
					<div className="tools-input m-modal-1 ">
						<input
							placeholder="Enter URL to upload from web"
							value={imageURL}
							onChange={(e) => setImageURL(e?.target?.value)}
						/>
						<button onClick={changeImage} className="ml-2 tools-header-button">
							Done
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default UploadNewImage;
