import walletImg from "assets/images/wallet/walletSuccessfully.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from 'translations';


export default function SendRequestModal({
	modalSendRequest,
	toggleModalSendRequest,
	data,
}) {
	const { payment } = Locale;


	return (
		<div>
			<Modal
				size="lg"
				isOpen={modalSendRequest}
				toggle={toggleModalSendRequest}
				className="send-modal"
			>
				<ModalHeader
					className="align-items-center py-1 border-none"
					toggle={toggleModalSendRequest}
				>
					Recharge {data?.currency} Wallet
				</ModalHeader>
				<ModalBody>
					<div className="container">
						<div className="recharge-img my-4 py-4 text-center">
							<img src={walletImg} alt="Wallet" />
							<h6 className="mt-2 modal-head">
								{payment.messages.yourPaymentsuccessful}
							</h6>

							{/* <p className="text-center text-gray">
								{" "}
								{payment.messages.accountNo}:{data?.ref_num}
							</p> */}
						</div>

						{/* <div className="my-4 sent-request-content">
							<div className="d-flex justify-content-between mt-2">
								<p>{payment.messages.transactionId}</p>
								<p>{response?.receipt_number}</p>
							</div>

							<div className="d-flex justify-content-between mt-2">
								<p> {payment.messages.date}</p>
								<p>{response?.receipt_date}</p>
							</div>
							<div className="d-flex justify-content-between mt-2">
								<p> {payment.messages.time} </p>
								<p>{response?.created_at?.slice(11)}</p>
							</div>

							<div className="d-flex justify-content-between mt-2">
								<p> {payment.messages.amount}</p>
								<p>
									{response?.amount}
									{data?.currency}
								</p>
							</div>
						</div> */}
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}
