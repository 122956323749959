import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useSBSState } from "context/global";
import OTPInput from "react-otp-input";

const SubscriptionOTPModel = ({ isOpen, toggle ,otp,setOtp,sentOTP,sentEmail}) => {
	const { userInfo } = useSBSState();
	return (
		<Modal
			isOpen={isOpen}
			centered
			size="lg"
			className="subscription-terms-model"
		>
			<ModalHeader toggle={toggle}>Verification Code</ModalHeader>
			<ModalBody>
				<div className="subscription-otp">
					<div className="text-verify mb-3">
						<h6>Verify Email</h6>
						<p>
							We sent a 4-digit code to <span>{userInfo?.email}</span>
						</p>
					</div>
					<OTPInput
						className="w-100"
						value={otp}
						onChange={setOtp}
						numInputs={4}
						renderSeparator={<span className="mx-2">-</span>}
						inputStyle={"w-25 input-OTP"}
						renderInput={(props) => (
							<input style={{ width: "25%" }} {...props} />
						)}
					/>
					<button className="btn btn-nxt w-100 text-white py-2" onClick={()=>sentOTP()}>Confirm</button>
				<p className='resend'>Yet to receive code? <span role="button" onClick={()=>sentEmail("resend")}>Resend code in 30s</span></p>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default SubscriptionOTPModel;
