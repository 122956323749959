import { useEffect, useMemo, useState } from "react";

export default function CustomPagination({ data, setData, perPage = 0 }) {
	const [copyOfData, setCopyOfData] = useState(() => data);
	const [Buttons, setButtons] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const numberOfPage = +copyOfData?.length / perPage;

	useMemo(() => {
		let buttons = [];
		if (numberOfPage > 1) {
			for (let index = 0; index < numberOfPage.toFixed(); index++) {
				buttons.push(
					<span
						className={`btn bg-white border rounded font-weight-bold mx-1 ${currentPage === index + 1 ? "customPaginationActive" : ""
							}`}
						onClick={() => {
							handleChangePage(index + 1);
						}}
					>
						{index + 1}
					</span>
				);
			}
			setButtons(buttons);
		}
	}, [numberOfPage, currentPage]);

	// for first initilization
	useEffect(() => {
		let copy = [...copyOfData];
		let newCopy = copy.slice(0, perPage);
		setData(newCopy);
	}, []);

	const handleChangePage = (newIndex) => {
		const prevPage = newIndex - 1;
		let copy = [...copyOfData];

		let newCopy = copy.slice(prevPage.toString() + 0, newIndex.toString() + 0);
		setCurrentPage(newIndex);
		setData(newCopy);
	};

	return (
		<div className="d-flex justify-content-end py-2">
			<div className="d-flex">{Buttons}</div>
		</div>
	);
}
