import React, { useEffect, useState } from 'react';
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import ServicePricing from '../ServicePricing';
import moment from 'moment';
import Locale from 'translations';
import ShowForPermission from 'helpers/showForPermission';


export default function AccountingAttractionItem({ item, isPricePerService, attractionPricingType, handleInputsChanges, deleteItemPricing, index, canEdit }) {
  const { operationStatement } = Locale;
  const [isPriceTableShown, setIsPriceTableShown] = useState(false);
  const isPricePerOne = attractionPricingType === "one" && isPricePerService;

  // show price table if there is price
  useEffect(() => {
    if ((item?.pricing?.buying_price && item?.pricing?.buying_price > 0) ||
      (item?.pricing?.selling_price && item?.pricing?.selling_price > 0)
    ) {
      setIsPriceTableShown(true)
    }
  }, []);




  return (
    <>
      <div className="attraction-item">
        <h4 className='header-title'>{item?.attraction?.name}</h4>
        <div className="content">
          <div className='w-100'>
            <p className='d-flex align-items-center flex-wrap col-md-6 col-12 mt-2 p-0'>
              <span className='col-md-5 col-12 p-0 label'>{operationStatement.City}</span>
              <span className='col-md-7 col-12 p-0'>{item?.city?.name}</span>
            </p>
            <p className='d-flex align-items-center flex-wrap col-md-6 col-12 mt-2 p-0'>
              <span className='col-md-5 col-12 p-0 label'>{operationStatement.Date}</span>
              <span className='col-md-7 col-12 p-0'>{item?.date_time ? moment(item?.date_time).format("DD/MM/YYYY - hh:mm") : "-"}</span>
            </p>
          </div>
        </div>
      </div>

      {isPricePerOne && !isPriceTableShown && canEdit ?
        <ShowForPermission permission='Manage-operation-accounting'>
          <button className="btn-plus-operation p-0 align-self-baseline"
            onClick={() => setIsPriceTableShown(true)}
          >
            <span>
              <i class="fas fa-plus"></i>
            </span>
            {operationStatement.AddBuyingSelling}
          </button>
        </ShowForPermission>
        :
        null
      }

      {isPricePerOne && isPriceTableShown ?
        <div className='mt-4'>
          <ServicePricing
            handleInputsChanges={handleInputsChanges}
            itemIndex={index}
            sellingPrice={item?.pricing?.selling_price}
            buyingPrice={item?.pricing?.buying_price}
            canEdit={canEdit}
          />

          {canEdit &&
            <ShowForPermission permission='Manage-operation-accounting'>
              <button className="btn delete-pricing-btn"
                onClick={() => {
                  deleteItemPricing(index);
                  setIsPriceTableShown(false);
                }}
              >
                <DeleteIcon />
                <span>{operationStatement.DeleteBuyingSelling}</span>
              </button>
            </ShowForPermission>
          }
        </div>
        :
        null
      }
    </>
  )
}
