import React, { useEffect, useState } from "react";
import maleTravelerIcon from "assets/images/product-builder/traveler-male-icon.svg";
import femaleTravelerIcon from "assets/images/product-builder/traveler-female-icon.svg";
import { DragDotsIcon, RemoveIcon, SearchIcon } from "./Icons";
import Locale from "translations";

export default function BusTravelers({
	selectedBus,
	travelersData,
	selectedTraveler,
	handleSelectTraveler,
	unassignTraverlerSeat,
	assignTravelerToSeat,
}) {
	const { productsBuilder } = Locale;
	const [filteredTravelers, setFilteredTravelers] = useState([
		...travelersData,
	]);
	const [searchText, setSearchText] = useState("");
	const assignedTravelers = travelersData?.filter((traveler) => traveler.bus);

	function searchTravelerByName(value) {
		if (value !== "" && value?.length > 0) {
			const matchedTravelers = travelersData?.filter(
				(traveler) =>
					traveler?.name.toLowerCase().includes(value.toLowerCase()) && traveler
			);
			setFilteredTravelers(matchedTravelers);
		} else {
			setFilteredTravelers([...travelersData]);
		}
		setSearchText(value);
	}

	useEffect(() => {
		setFilteredTravelers(travelersData);
	}, [travelersData]);

	return (
		<div className="travelers-container">
			<h2 className="header">{productsBuilder.travelers}</h2>
			<div className="travelers-accommodated">
				<span>{productsBuilder.accommodatedTravelers}</span>
				<span>
					{assignedTravelers?.length}/{travelersData?.length}
				</span>
			</div>
			{/* search input */}
			<div className="travelers-search">
				<SearchIcon />
				<input
					type="text"
					name="search-bus-travelers"
					id="search-bus-travelers"
					placeholder={productsBuilder.searchByName}
					value={searchText}
					onChange={(e) => searchTravelerByName(e.target.value)}
				/>
			</div>

			<hr className="seperator" />

			<ul className="travelers-list">
				{filteredTravelers?.map((traveler, index) => {
					return (
						(!traveler?.bus || selectedBus?.id === traveler?.bus?.id) && (
							<li
								key={traveler.id}
								className={`traveler-item flex-column ${selectedTraveler?.id === traveler?.id ? "selected" : ""
									}`}
								onClick={() => {
									handleSelectTraveler(traveler);
									!traveler?.bus && assignTravelerToSeat(traveler, null);
								}}
							>
								<div className="traveler-info justify-content-between w-100">
									<div className="d-flex">
										<DragDotsIcon />
										{traveler?.gender === "m" ? (
											<img
												src={maleTravelerIcon}
												alt="traveler gender icon"
												width="26px"
												height="26px"
												className="mx-1"
											/>
										) : null}
										{traveler?.gender === "f" ? (
											<img
												src={femaleTravelerIcon}
												alt="traveler gender icon"
												width="26px"
												height="26px"
												className="mx-1"
											/>
										) : null}
										<p className="d-flex flex-column">
											<span className="fw-500">{traveler?.name}</span>
											<span className="text-14 text-grey-500">
												{traveler?.passport_number}
											</span>
										</p>
									</div>

									{traveler?.bus?.seat ? (
										<div className="d-flex align-items-center">
											<button
												className="btn shadow-none p-0 mx-1"
												onClick={() =>
													unassignTraverlerSeat(traveler, null, null)
												}
											>
												<RemoveIcon />
											</button>
											<span className="seat-number">
												{traveler?.bus?.seat?.seatNumber}
											</span>
										</div>
									) : null}
								</div>

								<div className="d-flex justify-content-between w-100 px-4">
									<p className="text-14 text-grey-500 text-capitalize">
										{traveler?.gender === "m" ? "Male" : "Female"}
									</p>
									<p className="text-14 text-grey-500 text-capitalize">
										{traveler?.reservation_category}
									</p>
								</div>
								{/* <div className='d-flex justify-content-between w-100 px-5'>
									<p className="text-14 text-grey-500">
										{traveler?.package_reservation_num}
									</p>
								</div> */}
							</li>
						)
					);
				})}
			</ul>
		</div>
	);
}
