import React, { useEffect, useState } from "react";
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import AccountingCateringPricing from "./CateringPricing";
import { useSBSState } from "context/global";
import Locale from 'translations';
import ShowForPermission from 'helpers/showForPermission';

export default function AccountingCateringItem({
	isPricePerService,
	catering,
	accountingStatement,
	setAccountingStatement,
	canEdit
}) {

	const { operationStatement } = Locale;
	const { locale } = useSBSState();
	const [isPriceTableShown, setIsPriceTableShown] = useState(false);
	const handlePriceingFields = (value, type, id) => {
		const total = `total_${type}`;
		const cateringClone = { ...catering };
		const cateringItem = cateringClone.catering?.filter((item) => item.id === id)[0];
		const pax = cateringClone.catering?.filter((item) => item.id == id)[0]?.pax || 0;
		if (cateringItem?.pricing === null) {
			cateringItem["pricing"] = {
				[type]: +value,
				[total]: +(+value * +pax).toString(),
			};
		} else {
			cateringItem.pricing[type] = value.toString();
			cateringItem.pricing[total] = +(
				cateringItem.pricing[type] * +pax
			).toString();
		}
		setAccountingStatement({
			...accountingStatement,
			catering: accountingStatement.catering.map(item => {
				if (item?.hotel?.id === cateringClone?.hotel?.id) {
					return { ...cateringClone, update: `${cateringClone?.hotel?.id}_${type}_${value}` }

				}
				return item

			})
		});
	};
	const handlePriceingFieldsTotal = (value, type, id) => {
		const total = `total_${type}`;
		const cateringClone = { ...catering };
		const cateringItem = cateringClone?.catering?.filter(
			(item) => item.id == id
		)[0];
		const pax =
			cateringClone?.catering?.filter((item) => item.id == id)[0]?.pax || 0;

		if (cateringItem?.pricing === null) {
			cateringItem["pricing"] = {
				[type]: +value,
				[total]: +(+value * +pax).toString(),
			};
		} else {
			cateringItem.pricing[total] = value.toString();
			cateringItem.pricing[type] = +(
				cateringItem.pricing[total] / +pax
			).toString();
		}

		setAccountingStatement({
			...accountingStatement,
			catering: accountingStatement.catering.map(item => {
				if (item?.hotel?.id === cateringClone?.hotel?.id) {
					return { ...cateringClone, update: `${cateringClone?.hotel?.id}_${total}_${value}` }
				}
				return item
			})
		});
	};
	// show price table if there is price
	useEffect(() => {
		const roomsPricing = catering?.catering?.filter(
			(item) => item.pricing != null
		);
		if (roomsPricing?.length > 0) {
			setIsPriceTableShown(true);
		}
	}, []);


	// remove Cater pricing
	const removeCaterPrice = () => {
		let accountingStatementClone = { ...accountingStatement }
		const CateringClone = { ...catering };
		const caterItem = CateringClone?.catering?.map(item => {
			return {
				...item,
				pricing: null
			}
		})

		accountingStatementClone = {
			...accountingStatementClone, catering: accountingStatementClone.catering.map(item => {
				if (item?.hotel.id === CateringClone.hotel.id) {
					return { ...CateringClone, catering: caterItem, update: `Remove_${CateringClone.hotel.id}_${CateringClone.hotel.name}` }
				}
				return item
			})
		};
		setAccountingStatement(accountingStatementClone);
		setIsPriceTableShown(false);
	}
	return (
		<>
			<div className="attraction-item">
				<h4 className="header-title">{catering?.hotel?.name}</h4>
				<div className="content">
					<div className="d-flex flex-column flex-grow-1 gap-10">
						<p className="d-flex align-items-center flex-wrap">
							<span className="col-md-5 col-12 p-0 label">{operationStatement.City} </span>
							<span className="col-md-7 col-12 p-0">
								{catering?.city_id?.names[locale]}
							</span>
						</p>
					</div>

					<div className="d-flex flex-column flex-grow-1 gap-10">
						<p className="d-flex align-items-center flex-wrap">
							<span className="col-md-5 col-12 p-0 label">{operationStatement.Nights}</span>
							<span className="col-md-7 col-12 p-0">{catering?.nights}</span>
						</p>
					</div>
				</div>
			</div>

			{isPricePerService && !isPriceTableShown && canEdit ? (
				<ShowForPermission permission='Manage-operation-accounting'>
					<button
						className="btn-plus-operation p-0 align-self-baseline"
						onClick={() => setIsPriceTableShown(true)}
					>
						<span>
							<i class="fas fa-plus"></i>
						</span>
						{operationStatement.AddBuyingSelling}
					</button>
				</ShowForPermission>
			) : null}

			{isPricePerService && isPriceTableShown ? (
				<div className="mt-4">
					<AccountingCateringPricing
						caterPrice={catering.catering}
						handlePriceingFields={handlePriceingFields}
						handlePriceingFieldsTotal={handlePriceingFieldsTotal}
						canEdit={canEdit}
					/>
					{canEdit &&
						<ShowForPermission permission='Manage-operation-accounting'>
							<button
								className="btn delete-pricing-btn"
								onClick={() => removeCaterPrice()}
							>
								<DeleteIcon />
								<span>{operationStatement.DeleteBuyingSelling}</span>
							</button>
						</ShowForPermission>
					}
				</div>
			) : null}
		</>
	);
}
