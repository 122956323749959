import { useSBSState } from "context/global";
import DashboardLayout from "layouts/DashboardLayout";
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { TeamManagementProvider } from "../../context/teamManagement";
// React Component
export default function TeamManagementRoute({
	component: Component,
	...props
}) {
	const { isAuth } = useSBSState();

	if (isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<TeamManagementProvider>
						{/* <DashboardLayout> */}
							<Component {...matchProps} />
						{/* </DashboardLayout> */}
					</TeamManagementProvider>
				)}
			/>
		);
	} else {
		return <Redirect to="/" />;
	}
}
