// ** imports
import React from 'react'

// component
import { ReactComponent as SingleRoomIcon } from 'assets/images/crm/1.svg'
import { ReactComponent as DoubleRoomIcon } from 'assets/images/crm/2.svg'
import { ReactComponent as TripleRoomIcon } from 'assets/images/crm/3.svg'
import { ReactComponent as ForthRoomIcon } from 'assets/images/crm/4.svg'
import { ReactComponent as FivthRoomIcon } from 'assets/images/crm/5.svg'
import { ReactComponent as SixthRoomIcon } from 'assets/images/crm/6.svg'
import { ReactComponent as StarIcon } from 'assets/images/crm/star.svg'
import { ReactComponent as ChangeIcon } from 'assets/images/crm/change-2.svg'
import { ReactComponent as EyeIcon } from 'assets/images/crm/eye.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/crm/delete.svg'
import { ReactComponent as SeventhRoomIcon } from 'assets/images/crm/7.svg'
import { ReactComponent as CheckIcon } from 'assets/images/subscription-plan/check.svg'

import { useSBSState } from 'context/global'
import moment from 'moment'
import { getDistinctAirLines, getItineraryItems, getProductHotels } from './helpers/getProductItemsFromProduct'
import { CRMChangeReservation } from 'services/CRM'
import { store } from 'react-notifications-component'
import { HotelIcon, MasgedIcon, PlaneIcon } from 'modules/UmrahOperations/shared/Icons'
import Locale from 'translations'

const ListAvailablePackage = ({ availablePackagesList, currentPackage, setSearchedPackages, resetFilters }) => {
	// context
	const { locale } = useSBSState();
	const { CRM } = Locale


	async function changeReservation(productUUID) {
		const data = {
			reservation_num: currentPackage?.reservation_num,
			product_uuid: productUUID
		}
		const res = await CRMChangeReservation(data);
		if (res?.status > 200 && res?.status < 300) {
			setSearchedPackages({
				isOpen: false,
				currentPackage: null,
				availablePackagesList: [],
			});
			resetFilters && resetFilters();
			store.addNotification({
				message: "Reservation changed successfuly",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});

		}
	}
	return (
		<div className='crm-table-wrapper table-responsive'>
			<table class='table'>
				<thead className='table-tableHead'>
					<tr className='tableHead'>
						<th scope='col'>{CRM.PackageName}</th>
						<th scope='col'>
							<div className='d-flex flex-column'>
								<span className='text'>{CRM.DepartureNight}</span>
							</div>
						</th>
						<th scope='col'>{CRM.Airline}</th>
						<th scope='col'>{CRM.MakkahHotel}</th>
						<th scope='col'>{CRM.MadinahHotel}</th>

						<th scope='col'>{CRM.Mazzar}</th>
						<th scope='col'>
							<SingleRoomIcon />
						</th>
						<th scope='col'>
							<DoubleRoomIcon />
						</th>
						<th scope='col'>
							<TripleRoomIcon />
						</th>
						<th scope='col'>
							<ForthRoomIcon />
						</th>
						<th scope='col'>
							<FivthRoomIcon />
						</th>
						<th scope='col'>
							<SixthRoomIcon />
						</th>
						<th scope='col'>
							<SeventhRoomIcon />
						</th>
						<th scope='col'>
							<div className="text-center">{CRM.Actions}</div>
						</th>
					</tr>
				</thead>
				<tbody className='table-tableBody'>
					{availablePackagesList?.length > 0 ?
						availablePackagesList?.map(packageData => {

							const airlines = getDistinctAirLines(packageData?.product_items?.filter(item => item?.item?.id === 1));
							const makkahHotels = getProductHotels(packageData?.product_items?.filter(item => item?.item?.id === 6), "makkah");
							const madinahHotels = getProductHotels(packageData?.product_items?.filter(item => item?.item?.id === 6), "madinah");

							const nightsCount = packageData?.departure_date_at && packageData?.return_date_at
								? moment(packageData?.return_date_at).diff(moment(packageData?.departure_date_at), "days")
								: 0;
							const packageHasMazar = packageData?.product_items?.filter(item => item?.item?.id === 7)?.length > 0;
							const itineraryItems = getItineraryItems(packageData?.itinerary);
							return (
								<tr className='tableBody position-relative'>
									<td>{packageData?.name?.[locale]}</td>
									<td>
										<div className='d-flex flex-column'>
											<span className='table-text'>
												{packageData?.departure_date_at ? moment(packageData?.departure_date_at).format("DD MMM YYYY") : "-"}
											</span>
											<span className='table-text ms-5'>{nightsCount ? `${nightsCount} ${CRM.Nights}` : "-"} </span>
										</div>
									</td>

									<td>
										{airlines?.length > 0 ?
											airlines?.map(airline => {
												return (
													<p>{airline?.itemable?.transporter?.name}</p>
												)
											})
											:
											"-"
										}
									</td>

									<td>
										<div className='d-flex flex-column gap-2'>
											{makkahHotels?.length > 0 ?
												makkahHotels?.map(hotel => {
													console.log(hotel);
													return (
														<>
															<span className='table-text'>{hotel?.name}</span>
															<div className='d-flex align-items-center'>
																<span className='table-text'>
																	{hotel?.rating >= 5 ? 5 : hotel?.rating} <StarIcon />
																</span>
																<span className='table-text ms-5'>{hotel?.itemable?.night_count} {CRM.Nights}</span>
															</div>
														</>
													)
												})
												:
												"-"
											}

										</div>
									</td>

									<td>
										<div className='d-flex flex-column gap-2'>
											{madinahHotels?.length > 0 ?
												madinahHotels?.map(hotel => {
													return (
														<>
															<span className='table-text'>{hotel?.name}</span>
															<div className='d-flex justify-content-between align-items-center'>
																<span className='table-text'>
																	{hotel?.rating >= 5 ? 5 : hotel?.rating} <StarIcon />
																</span>
																<span className='table-text'>{hotel?.night_count} {CRM.Nights}</span>
															</div>
														</>
													)
												})
												:
												"-"
											}
										</div>
									</td>

									<td>
										<span className='table-text text-green'>
											{packageHasMazar ? <CheckIcon /> : <DeleteIcon />}
										</span>
									</td>
									{[...Array.from({ length: 7 })].map((room, index) => {
										let combinationPrice;
										packageData?.product_price_combinations?.forEach(priceComb => {
											let roomCombination = priceComb?.combinations?.find(comb => +comb?.room_type === index + 1)
											if (roomCombination) {
												combinationPrice = packageData?.product_price?.product_price_type?.id === 0
													? priceComb?.breakdown_price_after_discount
													: priceComb?.full_price_after_discount
											}
										});
										return (
											<td>
												<div className='d-flex flex-column gap-2'>
													<span className='table-text text-success'> {combinationPrice || "-"}</span>
													{combinationPrice ?
														<span className='table-text text-orange'>{packageData?.product_price?.currency_code}</span>
														:
														null
													}
												</div>
											</td>
										)
									})}

									<td>
										<div className='current-package-actions'>
											<button className='btn item active w-350' onClick={() => changeReservation(packageData?.product_uuid)}>
												<ChangeIcon color='#FFF' />
												<p className='text'>{CRM.Change}</p>
											</button>
											<button className='btn view-btn'>
												<EyeIcon />
												{itineraryItems.length > 0 &&
													<AvailablePackageItinerary
														itineraryItems={itineraryItems}
													/>
												}
											</button>

										</div>
									</td>
								</tr>
							)
						})
						:
						<tr>
							<td className="text-center" colSpan="14">{CRM.NoPackagesAvailable}</td>
						</tr>
					}

				</tbody>
			</table>
		</div>
	)
}

export default ListAvailablePackage;



export function AvailablePackageItinerary({ itineraryItems }) {
	function renderItem(itineraryItem) {
		switch (itineraryItem?.item?.id) {
			case 1:
				return {
					name: itineraryItem?.itemable?.from_port?.code,
					type: itineraryItem?.itemable?.destination_type?.name,
					icon: <span className={`icon flight ${itineraryItem?.itemable?.destination_type?.name}`}><PlaneIcon /></span>,
				}
			case 6:
				return {
					name: itineraryItem?.itemable?.hotel?.name,
					type: itineraryItem?.itemable?.city_name + " Hotel",
					icon: <span className="icon hotel"><HotelIcon color="#FF9F43" /></span>,
				}
			case 7:
				return {
					name: itineraryItem?.itemable?.name?.en,
					type: "Mazzarat",
					icon: <span className="icon attraction"><MasgedIcon color="#7367F0" /></span>,
				}
			default:
				return {
					name: "",
					type: "",
					icon: "",
				}
		}
	}

	return (
		<div className="crm-package-itinerary">
			{itineraryItems?.map((ele, index) => {
				if (ele?.item?.id !== 2) {
					const itemData = renderItem(ele);
					const isLastItem = itineraryItems.length - 1 === index
					return (
						<div className="d-flex align-items-center gap-10" style={{ minWidth: "180px" }}>
							{itemData?.icon}
							<div className="d-flex flex-column w-100 text-center text-capitalize">
								<span>
									{itemData.type}
								</span>
								<div className="segement-point">
									<span className="point"></span>
									<span className="line"></span>
									{!isLastItem &&
										<span className="point"></span>
									}
								</div>
								<span>{itemData.name}</span>
							</div>
						</div>
					)
				} else {
					return null
				}
			})}
		</div>
	)
}
