import React from 'react'
import { ReactComponent as ChevronDown } from "assets/images/webBuilder/chevron-down.svg";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';


export default function PackageBreadCrumb() {
  const params = useParams();
  const link = params.name ? `/public-page-v2/${params.name}` : `/web-builder-v2/preview/`;
  return (
    <div className='search-bread-crumb'>
      <Link className='home' to={link}>Home</Link>
      <span> <ChevronDown /></span>
      <span className='search'>Search Results</span>
    </div>
  )
}
