import mail from "assets/images/mail2x.png";
import {  useState } from 'react';
import { Modal, ModalBody } from "reactstrap";
import Locale from "translations";
import { useHistory } from "react-router-dom";

export default function SurveyPage() {
	const { commons } = Locale;

	const [Url, setUrl] = useState(window.location.href)
	const [iframe, setIframe] = useState(false);
	return (
		<>
			<Modal size="lg" className="mt-5	" isOpen={true}>
				<div className="p-3 border-bottom d-flex justify-content-between align-items-center">
{				!Url.includes('lang=en')&&!Url.includes('lang=id')?'	استبيان لدعم وتمكين وكالات العمرة الخارجية':
				'Questionnaire to Supporting and Enabling External Umrah Travel Agents'}
					{/* <i class="fas fa-times pointer" onClick={closeModal}></i> */}
				</div>

				<ModalBody className="payModal">
{				iframe? 		<div
						className="container"
						style={{ width: "100%", overflow: "auto" }}
					>
						<div class="text-center" role="alert">
							<img width={'80%'} className="m-auto" src={mail} alt="" />
						
						</div>

					<button onClick={e=>setIframe(!iframe)} className='btn w-100 bg-nxt mb-3 mt-3'>{commons.wouldLikeToParticipate}</button>
					</div>
:
Url.includes('lang=en')  ?	<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeTWLGx_cNw7UyWoFT7F4Q8Fyvq0hDgIPY6pSEVo2tq2DKC0A/viewform?embedded=true" width="100%" style={{height:"80vh"}}  frameborder="0" marginheight="0" marginwidth="0" title='OpenSurvey'>جارٍ التحميل…</iframe>


:Url.includes('lang=id')  ?<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf9dvtddWVTEqShPnjsw_mwa2M9pfVZHvIv4nbsy3YMZmRvBg/viewform?embedded=true"  width="100%" style={{height:"80vh"}}  frameborder="0" marginheight="0" marginwidth="0" title='OpenSurvey'>جارٍ التحميل…</iframe>
:

<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeaI354D-oCDgTVYIfQOKx9BPABTe4wkkoO5jA8S4RWUwSW1Q/viewform?embedded=true" 
					width="100%" style={{height:"80vh"}}  frameborder="0" marginheight="0" marginwidth="0" title='OpenSurvey'>جارٍ التحميل…</iframe>
					

				
				}
				</ModalBody>
			</Modal>
		</>
	);
}
