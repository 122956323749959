import React from "react";

export default function BedIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<g clip-path="url(#clip0_77_5086)">
			<path d="M3 7V18M3 14H21M21 18V10C21 9.46957 20.7893 8.96086 20.4142 8.58579C20.0391 8.21071 19.5304 8 19 8H11V14" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M6 10C6 10.2652 6.10536 10.5196 6.29289 10.7071C6.48043 10.8946 6.73478 11 7 11C7.26522 11 7.51957 10.8946 7.70711 10.7071C7.89464 10.5196 8 10.2652 8 10C8 9.73478 7.89464 9.48043 7.70711 9.29289C7.51957 9.10536 7.26522 9 7 9C6.73478 9 6.48043 9.10536 6.29289 9.29289C6.10536 9.48043 6 9.73478 6 10Z" stroke="#707170" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		</g>
		<defs>
			<clipPath id="clip0_77_5086">
				<rect width="24" height="24" fill="white"/>
			</clipPath>
		</defs>
	</svg>
	);
}
 