import React from "react";
import OnlineBankingTable from "./OnlineBankingTable";

export default function OnlineBanking(props) {
	return (
		<>
			<div className="payment-admin">
				<div className="text-center py-4 text-title ">
					<h2 className="text-decor text-dark">Menu</h2>
				</div>
				<OnlineBankingTable />
			</div>
		</>
	);
}
