import React, { useEffect, useState } from "react";
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import AccountingHotelPricing from "./HotelPricing";
import moment from "moment";
import Locale from 'translations';
import ShowForPermission from 'helpers/showForPermission';

export default function AccountingHotelItem({
	isPricePerService,
	hotel,
	setAccountingStatement,
	accountingStatement,
	canEdit
}) {
	const { operationStatement } = Locale;

	const [isPriceTableShown, setIsPriceTableShown] = useState(false);
	const handlePriceingFields = (value, roomType, type) => {
		const total = `total_${type}`
		let accountingStatementClone = { ...accountingStatement }
		let RoomsClone = { ...hotel };
		let roomItem = RoomsClone.rooms.filter((room) => room.room_type == roomType)[0];
		roomItem[type] = value.toString();
		roomItem[total] = +(roomItem[type] * +roomItem.room_count).toString();
		accountingStatementClone = {
			...accountingStatementClone, hotels: accountingStatementClone.hotels.map(item => {
				if (item.id === RoomsClone.id) {
					return { ...RoomsClone, update: `${RoomsClone.id}_${type}_${value}` }
				}
				return item
			})
		};

		setAccountingStatement(accountingStatementClone)
	}
	const handlePriceingFieldsTotal = (value, roomType, type) => {
		const total = `total_${type}`
		let accountingStatementClone = { ...accountingStatement }
		const RoomsClone = { ...hotel };
		const roomItem = RoomsClone.rooms.filter((room) => room.room_type == roomType)[0];
		roomItem[total] = value.toString();
		roomItem[type] = +(roomItem[total] / +roomItem.room_count).toString()
		accountingStatementClone = {
			...accountingStatementClone, hotels: accountingStatementClone.hotels.map(item => {
				if (item.id === RoomsClone.id) {
					return { ...RoomsClone, update: `${RoomsClone.id}_${total}_${value}` }
				}
				return item
			})
		};
		setAccountingStatement(accountingStatementClone)

	}
	// show price table if there is price
	useEffect(() => {
		const roomsPricing = hotel?.rooms?.filter((item) => item.buying_price > 0 || item.selling_price > 0)
		if (roomsPricing?.length > 0) {
			setIsPriceTableShown(true);
		}
	}, []);
	// remove hotel pricing
	const removeHotelPrice = () => {

		let accountingStatementClone = { ...accountingStatement }
		const HotelClone = { ...hotel };
		const hotelItem = HotelClone?.rooms?.map(item => {
			return {
				...item,
				buying_price: "",
				selling_price: "",
				total_buying_price: 0,
				total_selling_price: 0
			}
		})
		const pricingItem = HotelClone?.pricing?.map(item => {
			return {
				...item,
				buying_price: "",
				selling_price: "",
				total_buying_price: 0,
				total_selling_price: 0
			}
		})
		accountingStatementClone = {
			...accountingStatementClone, hotels: accountingStatementClone.hotels.map(item => {
				if (item.id === HotelClone.id) {
					return { ...HotelClone, pricing: pricingItem, rooms: hotelItem, update: `Remove_${HotelClone.id}_${HotelClone.meal_type}` }
				}
				return item
			})
		};
		setAccountingStatement(accountingStatementClone);
		setIsPriceTableShown(false);
	}
	return (
		<>
			<div className="attraction-item">
				<h4 className="header-title">{hotel.city_name}</h4>
				<div className="content">
					<div className="d-flex flex-column flex-grow-1 gap-10">
						<p className="d-flex align-items-center flex-wrap">
							<span className="col-md-5 col-12 p-0 label">{operationStatement.Hotelname} </span>
							<span className="col-md-7 col-12 p-0">{hotel.hotel.name}</span>
						</p>
						<p className="d-flex align-items-center flex-wrap">
							<span className="col-md-5 col-12 p-0 label">{operationStatement.Nights}</span>
							<span className="col-md-7 col-12 p-0">{hotel.nights}</span>
						</p>
						<p className="d-flex align-items-center flex-wrap">
							<span className="col-md-5 col-12 p-0 label"> {operationStatement.Meals} </span>
							<span className="col-md-7 col-12 p-0">{hotel.meal_type}</span>
						</p>
					</div>

					<div className="d-flex flex-column flex-grow-1 gap-10">
						<p className="d-flex align-items-center flex-wrap">
							<span className="col-md-5 col-12 p-0 label">{operationStatement.Checkin}</span>
							<span className="col-md-7 col-12 p-0">
								{moment(hotel.check_in_date).format("DD/MM/YYYY")}
							</span>
						</p>
						<p className="d-flex align-items-center flex-wrap">
							<span className="col-md-5 col-12 p-0 label">{operationStatement.checkout}</span>
							<span className="col-md-7 col-12 p-0">
								{moment(hotel.check_out_date).format("DD/MM/YYYY")}
							</span>
						</p>
					</div>
				</div>
			</div>

			{isPricePerService && !isPriceTableShown && canEdit ? (
				<ShowForPermission permission='Manage-operation-accounting'>
					<button
						className="btn-plus-operation p-0 align-self-baseline"
						onClick={() => setIsPriceTableShown(true)}
					>
						<span>
							<i className="fas fa-plus"></i>
						</span>
						{operationStatement.AddBuyingSelling}
					</button>
				</ShowForPermission>
			) : null}

			{isPricePerService && isPriceTableShown ? (
				<div className="mt-4">
					<AccountingHotelPricing
						hotel={hotel}
						handlePriceingFields={handlePriceingFields}
						handlePriceingFieldsTotal={handlePriceingFieldsTotal}
						canEdit={canEdit}
					/>
					{canEdit &&
						<ShowForPermission permission='Manage-operation-accounting'>
							<button
								className="btn delete-pricing-btn"
								onClick={() => {

									removeHotelPrice();
								}}
							>
								<DeleteIcon />
								<span>{operationStatement.DeleteBuyingSelling}</span>
							</button>
						</ShowForPermission>
					}
				</div>
			) : null}
		</>
	);
}
