import { useState } from 'react';
import Locale from "translations";

export default function Select({ name, options, placeholder, errors, ...props }) {
  const { commons } = Locale;
  const [isFoucsed, setIsFoucsed] = useState(false);
  const generateErrorsMsgs = (errors) => {
    let message = [];
    if (errors) {
      for (const [k, v] of Object.entries(errors)) {
        if (k === "required" && v) {
          message.push(commons.isRequired);
        }
      }
      if (message.length === 1) {
        return `${props.label} ${message[0]}`;
      } else if (message.length > 1) {
        return `${props.label} ${message.join(" & ")}`;
      } else {
        return;
      }
    }
  };
  return (
    <div className={`row no-gutters control-field overflow-hidden my-1 ${props.color ? " control-field--" + props.color : ""}
      ${isFoucsed && !props.color ? "custom-control-field" : ""} ${props.disabled ? "isDisabled" : ""}`}
    >
      <select name={name} id={name} placeholder={placeholder} className="select_component" {...props} onFocus={() => setIsFoucsed(true)}>
        {options?.map(option => {
          return <option key={option?.id} value={option?.id} selected={+props.value === +option?.id}> {option?.label || option?.name}</option>
        })}
      </select>
      <small
        className={`control-field__feedback control-field__feedback--${props.color} d-block error-message`}
      >
        {generateErrorsMsgs(props.errors)}
      </small>
    </div>
  )
}
