import React, { useEffect, useState } from "react";
import BreadCrump from "../shared/BreadCrump";
import Locale from "translations";
import { SendIcon } from "../shared/Icons";
import OperationTabs from "../shared/Tabs";
import { ReactComponent as ExportExcelIcon } from "assets/images/umrah-operations/export-excel-icon.svg";
import { ReactComponent as OperatingArrivalDepartureIcon } from "assets/images/umrah-operations/arrival-departure.svg";
import { ReactComponent as BuildingIcon } from "assets/images/umrah-operations/building-icon.svg";
import { ReactComponent as OperationsIcon } from "assets/images/umrah-operations/operations-icon.svg";
import OperationsCenterFilters from "./shared/Filters";
import {
	getAgentsLookup,
	getMovements,
	fetchHotels,
	fetchDelegarationReport,
} from "services/operationStatement";
import { useSBSState } from "context/global";
import OperatingCenterOperations from "./Operations";
import OperatingCenterArrivalDeparture from "./ArrivalDeparture";
import OperatingCenterHotels from "./HotelsOperations";
import OperatingCenterRawdaReservations from "./RawdaReservations";
import { DelegateReport } from "components/Printing/delegateReport";
import ShowForPermission from "helpers/showForPermission";
import Pagination from "components/Pagination";
import moment from "moment";
import { fetchPorts } from "services/lookups";
import ShowSubscription from 'helpers/ShowSubscription';

export default function OperationCenter() {
	const { operationStatement, inventory } = Locale;
	const { locale } = useSBSState();

	const initalFilters = {
		date_from: null,
		date_to: null,
		agent: null,
		port: null,
		operator: null,
		sort_by: { name: operationStatement.dateTimeOfBusArrival, id: "movement_date" },
		pax_from: "",
		pax_to: "",
		city_from: null,
		city_to: null,
		hotel_from: null,
		hotel_to: null,
		status: null,
		type: null,
		city_type: { name: operationStatement.allCityType, id: "all" },
		list_type: null,
		search: "",
		page: null,
	};

	// operating center tabs
	const operationsCenterTabs = [
		{
			title: operationStatement.operations,
			icon: <OperationsIcon />,
			url: "operations",
		},
		{
			title: operationStatement.ArrivalDeparture,
			icon: <OperatingArrivalDepartureIcon />,
			url: "arrival-departure",
		},
		{
			title: operationStatement.makkahMadinahOperations,
			icon: <BuildingIcon />,
			url: "makkah-madinah-operations",
		},
		{
			title: operationStatement.rawdaReservations,
			icon: <BuildingIcon />,
			url: "rawda-reservations",
		},
	];

	const [activeTab, setActiveTab] = useState("operations");
	const [movmentsList, setMovmentList] = useState(null);
	const [metaData, setMetaData] = useState();
	const [filters, setFilters] = useState(initalFilters);

	const [lookups, setLookups] = useState({
		agents: [],
		hotelCitiesLookup: [
			{ label: "MECCA", name: " MECCA", id: 3 },
			{ label: "AL_MADINAH", name: "AL_MADINAH", id: 4 },
			{ label: "RIYADH", name: "RIYADH", id: 194 },
			{ label: "JEDDAH", name: "JEDDAH", id: 134 },
			{ label: "TAIF", name: "TAIF", id: 214 },
			{ label: "YANBU", name: "YANBU", id: 224 },
		],
		statusListLookup: [
			{ name: operationStatement.executed, id: "executed" },
			{ name: inventory.messages.pending, id: "pending" },
		],
		sortByLookup: [
			{ name: operationStatement.dateTimeOfBusArrival, id: "movement_date" },
			{ name: operationStatement.dateTimeOfEndTime, id: "end_date" },
		],
	});

	function formatFilters(searchFilters) {
		searchFilters = searchFilters || filters;
		const filter = {
			dates: [
				searchFilters?.date_from
					? moment(searchFilters?.date_from).format("YYYY-MM-DD")
					: null,
				searchFilters?.date_to
					? moment(searchFilters?.date_to).format("YYYY-MM-DD")
					: null,
			],
			agent_id: searchFilters?.agent?.id,
			port_id: searchFilters?.port?.id,
			transporter_id: searchFilters?.operator?.id,
			"pax[from]": searchFilters?.pax_from,
			"pax[to]": searchFilters?.pax_to,
			from_city_id: searchFilters?.city_from?.id,
			to_city_id: searchFilters?.city_to?.id,
			from_hotel: searchFilters?.hotel_from?.id,
			to_hotel: searchFilters?.hotel_to?.id,
			status: searchFilters?.status?.id,
			type: searchFilters?.type,
			search: searchFilters?.search,
			list_type: searchFilters?.list_type,
			"city_type[type]": searchFilters?.city_type?.type,
			"city_type[id]": searchFilters?.city_type?.city_id,
			sort_by: searchFilters?.sort_by?.id === "movement_date" ? null : searchFilters?.sort_by?.id,
			page: searchFilters?.page,
		};
		return filter;
	}

	async function searchWithFilters(searchFilters) {
		const formatedFilters = formatFilters(searchFilters || filters);
		const res = await getMovements(formatedFilters);
		if (res?.status === 200) {
			setMovmentList(res?.data?.data);
			setMetaData(res?.data?.meta);
		}
	}

	function resetFilters(currentActiveTab) {
		currentActiveTab = currentActiveTab || activeTab;
		let searchFilters = initalFilters;
		if (currentActiveTab === "operations") {
			searchFilters = {
				...initalFilters,
				list_type: null,
				type: null,
				page: null,
			};
		} else if (currentActiveTab === "arrival-departure") {
			searchFilters = {
				...initalFilters,
				list_type: "arrival_departure",
				type: null,
				page: null,
			};
		} else if (currentActiveTab === "makkah-madinah-operations") {
			searchFilters = {
				...initalFilters,
				list_type: "makkah_madinah",
				city_type: null,
				page: null,
			};
		}

		setFilters(searchFilters);

		// check useffect dependencies if not changed to prevent call movment api twice
		if (
			searchFilters?.list_type === filters?.list_type &&
			searchFilters?.type === filters?.type &&
			searchFilters?.city_type === filters?.city_type &&
			searchFilters?.page === filters?.page &&
			currentActiveTab !== "rawda-reservations"
		) {
			searchWithFilters(searchFilters);
		}
	}

	// call movments
	useEffect(() => {
		if (activeTab !== "rawda-reservations") {
			searchWithFilters();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		filters?.search,
		filters?.type,
		filters?.city_type,
		filters?.list_type,
		filters?.page,
	]);

	// fetch ports
	async function fethcPortsLookups() {
		let portsRes = await fetchPorts(966, [1, 2]);
		let formatPorts = portsRes?.map((port) => {
			return {
				...port,
				name: port.name?.[locale] || port.name,
				value: port.id,
				label: port.name?.[locale] || port.name,
			};
		});
		return formatPorts;
	}
	// get agents lookups
	async function fethcAgentLookups() {
		let res = await getAgentsLookup();
		let formatAgents = res?.data?.data?.map((agent) => {
			return {
				...agent,
				value: agent?.id,
				label: `${agent?.name} - ${agent?.country?.name?.[locale]}`,
				name: `${agent?.name} - ${agent?.country?.name?.[locale]}`,
			};
		});
		return formatAgents;
	}
	// get hotels lookups
	async function fetchHotelsLookup(id, cities_ids) {
		const request = await fetchHotels(id, cities_ids);
		if (request.hotels && request.hotels.length > 0) {
			let formatHotels = request.hotels.map((Hotel) => {
				return {
					...Hotel,
					label: Hotel.name,
					value: Hotel.id,
				};
			});
			return formatHotels;
		}
	}

	function goTo(page) {
		setFilters({ ...filters, page });
	}

	useEffect(() => {
		async function fetchLookupList() {
			const [agents, hotels, ports] = await Promise.all([
				fethcAgentLookups(),
				fetchHotelsLookup(966, [3, 4, 5]),
				fethcPortsLookups(),
			]);
			setLookups({ ...lookups, agents, hotels, ports });
		}
		fetchLookupList();
	}, []);

	const dawnloadDelegationReport = async () => {
		const formatedFilters = formatFilters(filters);
		const { data } = await fetchDelegarationReport(formatedFilters);
		let init = 1;
		console.log(init);
		const URL = process.env.REACT_APP_API_URL + "/v1/files/fetch";
		const tableRows =
			data?.data?.length > 0
				? data?.data
					.map((item, index) => {
						// if(index!==0&& index%14==0){
						//   return `<tr key=${item.id} class="tr-14"}></tr>`
						// }
						// ${index!==0&& index%14===0?<tr class="tr-14"></tr>:""}
						// (index !== 0 && index == 12) ||
						// (index !== 0 && index !== 14 && index % 14 == 0)

						// (index !== 0 && index == 4) 
						// ||index == 11 || index == 11 + (7 * init)
						return `
              <tr class=${(index !== 0 && index === 3)
								|| index === 9 || index === 15 || index === 21 || index === 27 || index === 33 || index === 39 || index === 45 || index === 51 || index === 57 || index === 63 || index === 69 || index === 75 || index === 81 || index === 87
								? 'tr-14 '
								: "no-tr-14"
							}></tr>
              <tr key=${item.id} class="tr" >
              <td>${index + 1}</td>
              <td>${item?.company_statement_reference ?? "-"}</td>
              <td>${item?.agent?.name} - ${item?.agent?.country?.name}</td>
              <td>${item?.company_name}</td>
              <td>${item?.pax}</td>
              <td>${item?.operator}</td>
              <td>
              <p>${item?.supervisor?.name ?? "-"}</p>
              <p>${item?.supervisor?.phone_code ?? ""}${item?.supervisor?.phone ?? ""
							}</p>
              </td>
              
              <td>${item?.pickupPoints[0]?.title ?? "-"}</td>
							<td>${item?.destinations[0]?.title ?? "-"}</td>
              <td>
                <p>${moment(item?.start_date).format("DD/MM/YYYY")}</p>
                <p>${moment(item?.start_date).format("hh:mm a")}</p>
              </td>
              <td width="120px">
								${item?.drivers?.length > 0 ?
								item?.drivers?.map((driver, index) => {
									return (
										`
											${index === 0 ? `<p>${driver?.name ?? "-"}</p>` : ""}
											<p>${driver?.phone_code ?? ""} ${driver?.phone ?? ""}</p>
										`
									)
								}).join("")
								:
								"-"
							}
              </td>
            </tr>
            
						`;
					})
					?.join("")
				: [];
		const dir = locale === "en" ? "ltr" : "rtl";
		const len = data?.data?.length;
		//   const media=x%16 > 15 ?`.footer{
		// 			position:absolute
		//   }`:`.footer {
		//     position:relative
		// }`

		const footerPosition = len < 2 ? "absolute" : "relative";
		const today = new Date().toLocaleDateString();

		if (data.data) {
			let respos = DelegateReport;

			const keyValuePair = {
				"[Detection_daily_visits]": operationStatement.Detectionofdailyvisits,
				"[date]": operationStatement.Date,
				"[printed_date]": operationStatement.Printeddate,
				"[OperationNo]": operationStatement.OperationNo,
				"[AgentName]": operationStatement.AgentName,
				"[UmrahCompany]": operationStatement.UmrahCompany,
				"[Pax]": operationStatement.Pax,
				"[TransportationCompany]": operationStatement.TransportationCompany,
				"[Supervisor]": operationStatement.Supervisor,
				"[From]": operationStatement.From,
				"[Date]": operationStatement.Date,
				"[To]": operationStatement.To,
				"[Drivers]": operationStatement.Drivers,
				"[tableRows]": tableRows,
				"[footer_position]": footerPosition,



				// "[media]":media

				"[avater]": `${URL}/${data?.data[0]?.company_header_image}`,
				// "[date_api]": data?.data[0]?.date ? moment(data?.data[0]?.date).format("DD/MM/YYYY") : "-",
				"[print_date_api]": data?.print_date
					? moment(data?.print_date).format("DD/MM/YYYY hh:mm a")
					: "-",
				"[first_movement_date]": data?.first_movement_date
					? moment(data?.first_movement_date).format("DD/MM/YYYY")
					: "-",
				"[last_movement_date]": data?.last_movement_date
					? moment(data?.last_movement_date).format("DD/MM/YYYY")
					: "-",
				"[footer_api]": data?.data[0]?.company_footer_details ?? "-",
				"[position-image]": `image-${data?.data[0]?.company_header_image_setting}`
			};

			for (const key in keyValuePair) {
				respos = respos.replace(key, keyValuePair[key]);
			}

			var params =
				"width=" +
				window.innerWidth +
				", height=" +
				window.innerHeight +
				", top=0, left=0" +
				",toolbar=no,scrollbars=no,status=no,menubar=no";
			//"top=0,left=0,height=80%,width=auto"
			var popupWin = window.open("", " ", params);
			popupWin.document.open();
			popupWin.document.write(`
						<html dir="${dir}">
						<head>
						<title>Delegate Report ${today}</title>
						<style>
						@page {
							size: landscape;	
						}
					
						

						@media print {
							.footer{
								position: fixed !important;
								botton:0;
								left: 0; 
								right: 0; 
								height: 45px;
							}
											
						}
						</style>
						<link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" rel="stylesheet">
						</head>
						<body onLoad="{()=>window.print()}">${respos}</body>
						</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};

	return (
		<ShowSubscription module="operation_center">
			<div className="operations-center-container px-new-26">
				{activeTab !== "rawda-reservations" ?
					<>
						<BreadCrump title={operationStatement.operationsCenter}>
							<ShowForPermission permission="Manage-operation-center">
								<button className="btn export-excel-btn mx-2 py-2">
									<ExportExcelIcon />
									<span className="mx-1">{operationStatement.exportExcel}</span>
								</button>

								<button
									className="btn outline-btn mx-2"
									onClick={dawnloadDelegationReport}
								>
									<SendIcon color="#D2AB67" />
									<span className="mx-2">{operationStatement.delegateReport}</span>
								</button>
							</ShowForPermission>
						</BreadCrump>


						<div className="wrapper">
							<OperationTabs
								tabs={operationsCenterTabs}
								activeTab={activeTab}
								IsNotPushUrl={true}
								setActiveTab={setActiveTab}
								classNames=" col-md-12 col-12  mx-auto"
								resetFilters={resetFilters}
							/>

							{activeTab !== "rawda-reservations" ? (
								<OperationsCenterFilters
									filters={filters}
									lookups={lookups}
									setFilters={setFilters}
									searchWithFilters={searchWithFilters}
									resetFilters={resetFilters}
								/>
							) : null}


							<ShowForPermission permission="View-operation-center">
								{activeTab === "operations" ? (
									<OperatingCenterOperations
										filters={filters}
										movmentsList={movmentsList}
										setFilters={setFilters}
										resetFilters={resetFilters}
									/>
								) : null}
								{activeTab === "arrival-departure" ? (
									<OperatingCenterArrivalDeparture
										filters={filters}
										movmentsList={movmentsList}
										setFilters={setFilters}
										resetFilters={resetFilters}
									/>
								) : null}
								{activeTab === "makkah-madinah-operations" ? (
									<OperatingCenterHotels
										filters={filters}
										movmentsList={movmentsList}
										setFilters={setFilters}
										resetFilters={resetFilters}
									/>
								) : null}


								{activeTab !== "rawda-reservations" ? (
									<div className="mt-4 pb-3">
										<Pagination info={metaData} goTo={goTo} />
									</div>
								) : null}
							</ShowForPermission>
						</div>
					</>
					:
					activeTab === "rawda-reservations" ?
						<OperatingCenterRawdaReservations
							lookups={lookups}
							operationsCenterTabs={operationsCenterTabs}
							activeTab={activeTab}
							setActiveTab={setActiveTab}
						/>
						: null
				}
			</div>
		</ShowSubscription>
	);
}
