import axios from "axios";

const onlineVisaURL = process.env.REACT_APP_API_URL + "/v1";


export const TDSListVisaConfigurations = async () => {
  return await axios.get(`${onlineVisaURL}/online-visa/consumer-ui/issue-request/list-visa-configurations`);
};
export const TDSSendOTP = async (email) => {
  return await axios
    .post(`${onlineVisaURL}/auth/send-mail-otp`, email);
};
export const TDSVerifyEmailOTP = async (data) => {
  return await axios
    .post(`${onlineVisaURL}/auth/recieve-mail-otp`, data);
};
export const TDSCreateVisaRequest = async (data) => {
  return await axios
    .post(`${onlineVisaURL}/online-visa/consumer-ui/issue-request/create-visa-request`, data);
};
export const TDSupdatePassengerPhoto = async (data) => {
	return await axios
		.post(
			`${onlineVisaURL}/online-visa/consumer-ui/issue-request/update-passenger-photo`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const TDSuploadPassengerPassport = async (data) => {
	return await axios
		.post(
			`${onlineVisaURL}/online-visa/consumer-ui/issue-request/upload-passport`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const TDSeditinvalidPassengers = async (data) => {
	return await axios
		.post(
			`${onlineVisaURL}/online-visa/consumer-ui/issue-request/edit-invalid-passenger`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const TDSviewVisaOnline = async (id) => {
	return await axios.get(
		`${onlineVisaURL}/online-visa/consumer-ui/requests/view-request/${id}`
	);
};

export const TDSeditRequestPassengers = async (data) => {
	return await axios
		.post(
			`${onlineVisaURL}/online-visa/consumer-ui/issue-request/edit-request-passengers`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const TDSentryStampPassengers = async (data) => {
	return await axios
		.post(
			`${onlineVisaURL}/online-visa/consumer-ui/issue-request/add-passengers-entry-stamp`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const TDSsendRequestPassengers = async (data) => {
	return await axios
		.post(
			`${onlineVisaURL}/online-visa/consumer-ui/issue-request/send-request-passengers`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};


export const TDSdownloadVisaInsuranceDocuments = async (data)=> {
	return await axios.post(`${onlineVisaURL}/online-visa/consumer-ui/issue-request/download/request-insurances`, data, {responseType: "blob",})
	.then((res) => res)
	.catch((err) => err.response);
}
export const TDSdownloadVisaDocuments = async (data)=> {
	return await axios.post(`${onlineVisaURL}/online-visa/consumer-ui/issue-request/download/request-visas`, data, {responseType: "blob",})
	.then((res) => res)
	.catch((err) => err.response);
}