import React from "react";
import { ReactComponent as DraghIcon } from "assets/images/hotelAccommodation/drag.svg";
import { useLocation } from "react-router-dom";

export const EditBox = ({
	children,
	actions,
	editMode,
	position = "center",
	defaultIcon,
}) => {
	const location = useLocation();
	const preview = !location.pathname.includes('edit');
	return editMode && !preview ? (
		<>
			<div className={`edit-box-actions-parent justify-content-${position}`}>
				<div className="edit-box-actions">
					{defaultIcon ?? <DraghIcon />}
					{actions && actions.map((action) => action)}
				</div>
			</div>
			<div className="edit-box-container flex-fill">{children}</div>
		</>
	) : (
		children
	);
};
