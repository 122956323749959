import React from 'react'
import Locale from 'translations'
import AccountingTableRow from './AccountingTableRow'

const AccountingList = ({
	statusCount,
	accountingStatement,
	filters,
	setFilters,
	activeStatus,
	setActiveStatus,
	getStatementAccounting
}) => {
	// ** hooks
	const { operationStatement } = Locale
	const statusList = [
		{ title: operationStatement.All, id: 'all', count: statusCount?.all },
		{
			title: operationStatement.New,
			id: 'new',
			count: statusCount?.new
		},
		{
			title: operationStatement.Paid,
			id: 'paid',
			count: statusCount?.paid
		},
		{
			title: operationStatement.Unpaid,
			id: 'unpaid',
			count: statusCount?.unpaid
		}
	]



	return (
		<div className='operation-statement-list'>
			<div className='d-flex flex-wrap justify-content-between align-items-center w-100 border-bottom'>
				<ul className='accounting-border-bottom status-filter'>
					{statusList?.map((item) => {
						return (
							<li
								className={`${
									activeStatus === item.id ? 'active' : ''
								}`}
								onClick={() => {
									setActiveStatus(item.id)
									setFilters({
										...filters,
										payment_status: item?.id
									})
								}}
								key={item?.id}
							>
								{item.title}
								<span className={`badge mx-1`}>
									{item?.count}
									{/* {item.id === 'new' ? ' New' : ''} */}
								</span>
							</li>
						)
					})}
				</ul>

				<div className='table'>
					{accountingStatement?.map((statement, idx) => (
						<AccountingTableRow
							key={`${statement?.id}-${idx}`}
							statement={statement}
							getStatementAccounting={getStatementAccounting}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

export default AccountingList
