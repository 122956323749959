import React from 'react'
import TextField from 'components/Form/TextField/TextField';
import NumberField from "components/Form/NumberField/NumberField";
import Locale from 'translations';
import SelectField from 'components/Form/SelectField/SelectField';

export default function TDSVisaContactInfo({ visaData, errors, countriesLookup, handleInputChanges }) {
  const { onlineVisa, visa, marketPlace } = Locale;


  return (
    <div className='col-md-8 col-12'>
      <div className='base-border p-3'>
        {/* name */}
        <div className='tds-visa-input col-md-6 col-12 mb-2'>
          <TextField
            label={visa.Name}
            name="tds-name"
            id="tds-name"
            value={visaData.name}
            onChange={(e) => handleInputChanges(e.target.value, "name")}
            color={errors?.name?.required ? "danger" : ""}
            errors={errors?.name}
          />
        </div>
        {/* phone */}
        <div className="col-md-6 phone">
          {/* phone code  */}
          <div className='phone_code'>
            <SelectField
              name="phonecode"
              id="phonecode"
              value={visaData.phoneCode?.phone_code}
              onChange={(e) => handleInputChanges(e, "phoneCode")}
              options={countriesLookup}
              color={errors?.phoneCode?.required ? "danger" : ""}
            />
          </div>
          <TextField
            label={visa.Phone}
            type="number"
            name="phone"
            id="phone"
            className="phone_number_textfield control-field__input w-100"
            value={visaData?.phone}
            min={9}
            max={13}
            onWheel={e => e.currentTarget.blur()}
            onChange={(e) => handleInputChanges(e.target.value, "phone")}
            errors={errors?.phone}
            color={errors?.phone?.required || errors?.phone?.min || errors?.phone?.max ? "danger" : ""}
          />
        </div>
        {/* pax */}
        <div className='tds-visa-input col-md-6 col-12'>
          <NumberField
            type={"number"}
            removeArrow={true}
            label={`${onlineVisa.Pax}`}
            name="tds-pax"
            id="tds-pax"
            min={+visaData?.package_plan?.range_from}
            max={+visaData?.package_plan?.range_to}
            value={visaData.pax}
            onChange={(e) => handleInputChanges(e.target.value, "pax")}
            color={errors?.pax?.required ? "danger" : ""}
            errors={errors?.pax}
          />
        </div>

        {/* residence */}
        <div className='tds-visa-input col-md-6 col-12'>
          <SelectField
            label={marketPlace.messages.residence}
            value={visaData?.residenceCountry?.label}
            name="tds-residence"
            id="tds-residence"
            options={countriesLookup}
            onChange={(e) => handleInputChanges(e, "residenceCountry")}
            color={errors?.residenceCountry?.required ? "danger" : ""}
            errors={errors?.residenceCountry}
          />
        </div>


      </div>
    </div>
  )
}
