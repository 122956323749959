import dateExpireImage from "assets/images/datePicker.svg";
import walletImage from "assets/images/newWallat.svg";
import NumberField from "components/Form/NumberField/NumberField";
import SelectField from "components/Form/SelectField/SelectField";
import {
	useMarketplaceDispatch
} from "context/marketplace";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TabPane } from "reactstrap";
import { createGroundRequest, GroundServiceLookups } from "services/groundService";
import Locale from "translations";
import CustomModal from "./components/Modal";
import Payment from './components/Payment';
import PaymentSucess from './components/PaymentSucess';
import useShowPermission from 'hooks/useShowPermission';
import NewPaymentAction from 'components/NewPaymentAction';

export default function GroundSearch({ isMarketSearch = true, toggleModel }) {
	// isMarketSearch is props to make some changes in ui due to it is used in online visa main page

	const { marketPlace, onlineVisa, inventory } = Locale;
	const [errors, setErrors] = useState({});
	const [destinationLookups, setDestinationLookups] = useState([]);
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const tokenSafaVisa = localStorage.getItem("VisaToken");
	const dispatch = useMarketplaceDispatch();
	const history = useHistory();
	const [serviceTypeLookups, setServiceTypeLookups] = useState([]);
	const [isSearchDone, setIsSearchDone] = useState(false);
	const [ModalPayment, setModalPayment] = useState(false);
	const [ModalPaymentSucess, setModalPaymentSucess] = useState(false);

	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK? JSON.parse(process.env.REACT_APP_OTP_CHECK):false

	const [actionType, setActionType] = useState('');
  const [formData, setFormData] = useState({});

	const [groundData, setGroundData] = useState({
		destination: null,
		pax: "",
		service_type: null,
	});

	const inventoryPermission = useShowPermission({
		permission: [
			"View-Inventory-Hotels",
			"Manage-Inventory-Hotels",
		],
	});
	const toggleModelPayment = () => {
		setModalPayment(!ModalPayment);
	};

	const toggleModelPaymentSucess = () => {
		setModalPaymentSucess(!ModalPaymentSucess);
	}

	useEffect(() => {
		const fetchDestinations = async () => {
			const response = await GroundServiceLookups();
			if (response.status === 200) {
				const formatDestination = response?.data?.data.map((item) => ({
					id: item.country_id,
					name: item.name,
					...item,
				}));
				setDestinationLookups(formatDestination);
			}
		};
		fetchDestinations();
	}, []);


	const checkFormErrors = () => {
		let SubmitError = {};
		Object.keys(groundData).forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{ name: key, value: groundData[key], },
					{ required: true }
				),
			};
		});
		setErrors(() => SubmitError);
	};

	const sumbit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	async function payGroundService(paymentType) {
		const data = {
			destination_id: groundData?.destination?.id,
			service_type_id: groundData?.service_type?.id,
			pax: +groundData?.pax,
			currency: groundData?.residence?.currency_code,
			price_per_one: groundData?.residence?.price,
			expiration_days: groundData?.residence?.expiration_days,
			payment_method: paymentType
		}
		const response = await createGroundRequest(data);
		if (response.status === 200) {
			history.push({ pathname: inventoryPermission?"/inventory/ground-services":"/statistics" });
			dispatch({
				type: "groundServiceSearch",
				payload: [],
			});
			toggleModelPayment();
		}else if (response?.status === 202){
      setActionType(response?.data?.actionType)
      setFormData(response?.data?.data?.formData)

    }
	}

	useEffect(() => {
		if (isFormValid(errors)) {
			// send request to api to get available ground
			const handleSearch = async () => {
				dispatch({ type: "groundServiceSearch", payload: groundData })
				if (!isSearchDone) {
					setGroundData({
						...groundData,
						expireDate: moment(new Date()).add(groundData?.residence?.expiration_days, "d"),
					})
					setIsSearchDone(true);
				} else {
					setModalPayment(true);
				}
			}
			handleSearch();
		} else {
			setIsSearchDone(false);
			setModalPayment(false);
		}
	}, [isErrorLoaded]);

	return (
		<>
			<TabPane tabId="1" className="w-100">
				<div className="col-md-10 HotelSearch m-auto pb-3">
					<div
						className={`row px-2 ${isMarketSearch
							? " justify-content-start"
							: " justify-content-center"
							}`}
					>
						<div className="col-md-10">
							<SelectField
								hasLabel={true}
								label={marketPlace.messages.destination}
								errors={errors?.destination}
								color={errors?.destination?.required ? "danger" : ""}
								options={destinationLookups}
								value={groundData?.destination?.label}
								onChange={(e) => {
									setGroundData({
										...groundData,
										destination: e,
										service_type: null,
									});
									setServiceTypeLookups(e?.residences?.map(residence => {
										return { ...residence, ...residence?.service_type, id: residence?.service_type?.id, label: residence?.service_type?.name }
									}),)
									setErrors({
										...errors,
										...validate(
											{ name: "destination", value: e.id },
											{ required: true, }
										),
									});
								}}
							/>
						</div>
						<div className="col-md-10">
							<SelectField
								hasLabel={true}
								label={inventory.messages.serviceType}
								errors={errors?.service_type}
								color={errors?.service_type?.required ? "danger" : ""}
								options={serviceTypeLookups}
								value={groundData?.service_type?.label}
								onChange={(e) => {
									setGroundData({
										...groundData,
										service_type: e,
										residence: e,
									});
									setIsSearchDone(false);
									setErrors({
										...errors,
										...validate(
											{ name: "service_type", value: e.id },
											{ required: true, }
										),
									});
								}}
							/>
						</div>

						<div className="col-md-10">
							<div className="mybooking-input-wrapper marketplace-date">
								<NumberField
									hasLabel={true}
									removeArrow={true}
									placeholder={onlineVisa.paxPlaceholder}
									label={onlineVisa.Pax}
									name="pax"
									min={"1"}
									errors={errors?.pax}
									color={errors?.pax?.required ? "danger" : ""}
									value={groundData.pax}
									type={"number"}
									onChange={(e) => {
										setGroundData({
											...groundData,
											pax: e.target.value > 0 ? e.target.value : "",
										});
										setErrors({
											...errors,
											...validate(e.target, { required: true, }),
										});
									}}
								/>
							</div>
						</div>
						{/* search result */}
						{isSearchDone ?
							<div className="col-md-10 d-flex justify-content-between align-items-center mt-3 mb-2 visa_search_result">
								<div className="d-flex align-items-center">
									<img src={dateExpireImage} alt="" width="28px" className="mr-3" />
									<p className="d-flex flex-column font-weight-normal txt-blue">
										{marketPlace.messages.requestExpireDate}
										<span>{moment(groundData?.expireDate).format("DD-MM-YYYY")}</span>

									</p>
								</div>
								<div className="d-flex align-items-center">
									<img src={walletImage} alt="" width="28px" className="mx-3" />
									<p className="d-flex flex-column font-weight-normal txt-blue">
										{marketPlace.messages.totalPrice}
										<span>
											{groundData?.residence?.currency_code} {" "}
											{(groundData?.pax * groundData?.residence?.price).toFixed(2)}
										</span>
									</p>
								</div>
							</div>
							:
							null
						}
						<div className="col-md-10">
							<button className="btn w-100 bg-nxt mb-3 mt-3 px-0" type="button" onClick={sumbit}>
								<p className="d-flex align-items-center justify-content-center">
									{isSearchDone ? inventory.messages.pay : onlineVisa.search}
								</p>
							</button>
						</div>
					</div>
				</div>
			</TabPane>
			

			{ModalPayment && (
				<NewPaymentAction
					isOpen={ModalPayment}
					toggleModal={toggleModelPayment}
					OTP={OTP_CHECK}
					canPayLater={false}
					formData={formData} 
					actionType={actionType}
					Price={groundData?.residence?.price}
					currency={groundData?.residence?.currency_code}
					onPayment={payGroundService}
					setActionType={setActionType}
					setFormData={setFormData}
				/>
			)}




			<CustomModal
				modalIsOpen={ModalPaymentSucess}
				header={onlineVisa.ChoosePayment}
				toggle={toggleModelPaymentSucess}
				size={"lg"}
				centered={true}
			>
				<PaymentSucess />
			</CustomModal>
		</>
	);
}
