import clearIcon from "assets/images/wallet/clearIcon.svg";
import correctIcon from "assets/images/wallet/correctIcon.svg";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import { useSBSState } from 'context/global';
import { useEffect, useState } from "react";
import { onlineVisaTypeLookups } from "services/VisaOnline";
import Locale from "translations";

export default function Filter({ filter, setFilter, search }) {
	const { allCountries } = useSBSState();
	const { payment, inventory } = Locale;
	const status = [
		{ id: 0, name: `${payment.messages.Pending}`, title: "pending" },
		{ id: 1, name: `${inventory.messages.waitingVisa}`, title: "waiting" },
		{ id: 2, name: `${inventory.messages.issuedVisas}`, title: "issued" },
		{ id: 3, name: `${payment.messages.Expired}`, title: "expired" },
		{ id: 4, name: `${payment.messages.Reject}`, title: "rejected" },
	];
	const [types, setTypes] = useState();

	useEffect(() => {
		async function fechType() {
			const res = await onlineVisaTypeLookups();
			setTypes(res?.data?.data);
		}
		fechType();
	}, []);

	const countries = allCountries;

	return (
		<>
			<div className="filter-update bg-filter-gray">
				<div className="product-buttons col-md-11">
					<div className="main-filter-payment d-flex justify-contain-between">
						{/* <div className="main-label controls-field col-2">
							<TextField
								label={payment.messages.search}
								type="text"
								placeholder={payment.messages.search}
								onChange={(e) =>
									setFilter({ ...filter, search: e.target.value })
								}
								value={filter.search}
							/>
						</div> */}
						<div className="main-label controls-field col-md-2 col-6">
							<SelectField
								label={payment.messages.destination}
								placeholder={payment.messages.destination}
								options={countries}
								value={filter?.destination_id?.name}
								onChange={(e) =>
									setFilter({
										...filter,
										destination_id: e,
									})
								}
							/>
						</div>
						<div className="main-label controls-field col-md-2 col-6">
							<SelectField
								label={inventory.messages.visaType}
								placeholder={inventory.messages.visaType}
								options={types}
								value={filter?.visa_type_id?.name}
								onChange={(e) =>
									setFilter({
										...filter,
										visa_type_id: e,
									})
								}
							/>
						</div>
						<div className="main-label date-filter col-md-2 col-6">
							{" "}
							<DatePickerField
								label={inventory.messages.requestDate}
								placeholder="DD/MM/YYY"
								date={filter.request_date}
								onChangeDate={(e) => {
									setFilter({ ...filter, request_date: e });
								}}
								isOutsideRange={(day) => {
									return false;
								}}
							/>
						</div>
						<div className="main-label controls-field col-md-2 col-6">
							<SelectField
								label={payment.messages.status}
								placeholder={payment.messages.status}
								options={status}
								value={filter?.status?.name}
								onChange={(e) =>
									setFilter({
										...filter,
										status: {
											id: e.value,
											name: e.label,
											title: status[e.value].title,
										},
									})
								}
							/>
						</div>
						<div className="filter-btn col-md-4 col-6 d-flex align-items-center justify-content-start">
							<div className="apply pointer">
								<button className="btn btn-light px-3" onClick={search}>
									<img src={correctIcon} alt=" " />
									{payment.messages.apply}
								</button>
							</div>
							<div
								className="clear text-secondary pointer"
								onClick={() => {
									setFilter({
										search: "",
										destination: "",
										visaType: "",
										date: "",
										status: "",
										page: 1,
									});
									search();
								}}
							>
								<button className="btn btn-light px-3 mx-2">
									<img src={clearIcon} alt=" " />
									{payment.messages.clear}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
