import { SORT_ITEMS_IN_CONTAINER_ACTION } from 'modules/WebBuilder-V2/interfaces/blockTypes';
import DragAndDropContainer from 'modules/WebBuilder-V2/shared/DragAndDropContainer';
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import React from 'react'
import Carousel from "react-multi-carousel";
import CustomRightArrow from 'modules/WebBuilder-V2/shared/CustomRightArrow';
import CustomLeftArrow from 'modules/WebBuilder-V2/shared/CustomLeftArrow';
import ServiceCardWithButton from 'modules/WebBuilder-V2/shared/ServiceCardWithButton';

const packagesBlocksType = "packagesBlocksType";


export default function HotelCarouselView({ items, sortHotelsItems, focusContainer, editItem, itemsBoxActions, openEditModal, type }) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1
    }
  };

  return (
    <Carousel
      responsive={responsive}
      itemClass={"hotel-slide"}
      className=""
      slidesToSlide={1}
      keyBoardControl={true}
      customRightArrow={<CustomRightArrow />}
      customLeftArrow={<CustomLeftArrow />}
      renderButtonGroupOutside={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      renderArrowsWhenDisabled={true}
      infinite={true}
    >
      {items.map((props, index) => (
        <div className="px-2" key={props.id || index}>
          <DragAndDropContainer
            accept={packagesBlocksType}
            type={packagesBlocksType}
            id={props.id}
            index={index}
            action={SORT_ITEMS_IN_CONTAINER_ACTION}
            moveItemInContainer={(dragIndex, hoverIndex) =>
              sortHotelsItems({ dragIndex, hoverIndex })
            }
          >
            <EditBox
              editMode={focusContainer === type}
              actions={[
                ...editItem(props, index),
                ...itemsBoxActions(props.id),
              ]}
            >
              <ServiceCardWithButton index={index} {...props} openEditModal={openEditModal} cardType="hotels" />
            </EditBox>
          </DragAndDropContainer>
        </div>
      ))}
    </Carousel>
  )
}