
import { ReactComponent as HotelIcon } from "assets/images/market-place/services-hotel-icon.svg";
import { ReactComponent as VisaIcon } from "assets/images/market-place/services-visaonline-icon.svg";
import { ReactComponent as DepartureIcon } from "assets/images/market-place/departur-transport-icon.svg";
import { ReactComponent as InternalIcon } from "assets/images/market-place/services-transport-icon.svg";
import { ReactComponent as SightSeenIcon } from "assets/images/market-place/sightseen-icon.svg";
import { ReactComponent as InsuranceIcon } from "assets/images/market-place/insurance-icon.svg";
import { ReactComponent as OtherServicesIcon } from "assets/images/market-place/otherservices-icon.svg";

export let itineraryElementsIds = {
  external: 1,
  internal: 2,
  visa: 4,
  insurance: 5,
  hotel: 6,
  sightseeing: 7,
  otherServices: 8
}

const packageIcons = {
  1: <DepartureIcon />,
  2: <InternalIcon />,
  4: <VisaIcon />,
  5: <InsuranceIcon />, //insurance
  6: <HotelIcon />,
  7: <SightSeenIcon />, // Sightseen 
  8: <OtherServicesIcon />, // other services 
}

export function formatProductItems(productItems, locale) {

  let hotels = productItems?.filter(product => product?.item?.id === itineraryElementsIds.hotel)
  hotels = hotels?.map(hotel => {
    return {
      id: hotel?.item?.id,
      serviceId: hotel?.id,
      product_hotel_id: hotel?.itemable?.id,
      name: hotel?.itemable?.hotel?.name,
      icon: packageIcons[hotel?.item?.id],
      prices: hotel?.prices?.find(price => price?.pricing_for === "b2b")
    }
  })

  let internalCount = productItems?.filter(product => product?.item?.id === itineraryElementsIds.internal).length;
  let servicesItems = [];
  let internalIdSeen = false;

  // loop over product items without hotels
  productItems?.filter(product => product?.item?.id !== itineraryElementsIds.hotel)?.forEach(product => {
    const productItemId = product?.item?.id;

    // handle visa or insurace
    if ((productItemId === itineraryElementsIds.visa || productItemId === itineraryElementsIds.insurance) && product?.itemable?.included === 1) {
      servicesItems.push(
        {
          id: productItemId,
          serviceId: product?.id,
          name: product?.item?.name,
          price_adult: product?.prices?.find(price => price?.pricing_for === "b2b")?.price_adult,
          price_child: product?.prices?.find(price => price?.pricing_for === "b2b")?.price_child,
          icon: packageIcons[product?.item?.id],
        }
      )
    }

    else if (productItemId === itineraryElementsIds.internal && !internalIdSeen) {
      internalIdSeen = true;
      servicesItems.push(
        {
          id: productItemId,
          serviceId: product?.id,
          name: product?.item?.name,
          price_adult: +product?.prices?.find(price => price?.pricing_for === "b2b")?.price_adult * internalCount,
          price_child: +product?.prices?.find(price => price?.pricing_for === "b2b")?.price_child * internalCount,
          icon: packageIcons[product?.item?.id],
        }
      );
    }

    else if (productItemId !== itineraryElementsIds.internal && productItemId !== itineraryElementsIds.visa && productItemId !== itineraryElementsIds.insurance) {
      servicesItems.push(
        {
          id: productItemId,
          serviceId: product?.id,
          name: productItemId === itineraryElementsIds.external ? "Global Transportation" : (product?.itemable?.name?.[locale] || product?.itemable?.name || product?.item?.name),
          destination_type: product?.itemable?.destination_type?.name,
          price_adult: product?.prices?.find(price => price?.pricing_for === "b2b")?.price_adult,
          price_child: product?.prices?.find(price => price?.pricing_for === "b2b")?.price_child,
          icon: packageIcons[product?.item?.id],
        }
      )
    }
  })
  return [...hotels, ...servicesItems];
}