import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import TextField from "components/Form/TextField/TextField";
import { useMarketplaceState } from 'context/marketplace';
import moment from "moment";
import Locale from "translations";

function TransferSearchBar({ modify }) {
	const { marketPlace } = Locale;

	const { transferCriteria } = useMarketplaceState();

	

	return (
		<>
			<div className="container HotelSearch m-md-auto p-3 mt-4">
				<div className="row py-3 px-2 bg-white justify-content-center">
					<div className="col-md-2 col-6 ">
						<TextField
							disabled={true}
							hasLabel={true}
							label={marketPlace.From}
							placeholder={marketPlace.From}
							//value={hotel.from?.name}
							value={transferCriteria?.from?.name || ""}
						/>
					</div>
					{/* cycle type */}
					<div className="col-md-2 col-6 ">
						<TextField
							disabled={true}
							hasLabel={true}
							label={marketPlace.to}
							placeholder={marketPlace.to}
							value={transferCriteria?.to?.name || ""}
						/>
					</div>

					{/* Date */}
					<div className={`${true ? "col-md-2 col-6" : "col-md-3 col-12"} `}>
						<DatePickerField
							disabled={true}
							label={marketPlace.departureDate}
							date={moment(transferCriteria?.departureDate) ?? ""}
							placeholder="DD/MM/YYYY"
						/>
					</div>

					{transferCriteria?.returnDate && (
						<div className="col-md-2 col-6">
							<DatePickerField
								disabled={true}
								label={marketPlace.returnDate}
								date={moment(transferCriteria?.returnDate)}
								placeholder="DD/MM/YYYY"
							/>
						</div>
					)}

					{/* vehicles_count */}
					<div className="col-md-3 col-12 row px-3 transfer-passanger">
						<div className="col-6 ">
							<TextField
								disabled={true}
								hasLabel={true}
								placeholder={marketPlace.adult}
								label={marketPlace.adult}
								value={transferCriteria?.adult?.value}
							/>
						</div>

						<div className="col-6 ">
							<TextField
								disabled={true}
								hasLabel={true}
								placeholder={marketPlace.children}
								label={
									<>
										{marketPlace.children}
										<i
											class="fas fa-info-circle new-color"
											title={marketPlace.messages.childrenAge}
										/>
									</>
								}
								name="children"
								value={transferCriteria?.children?.value}
							/>
						</div>
					</div>

					<div className="col-md-1 col-12 ">
						<button
							className="btn w-100 bg-nxt mb-3 px-0 py-2"
							type="button"
							onClick={modify}
							style={{ marginTop: 30 }}
						>
							<p className="d-flex align-items-center justify-content-center">
								{marketPlace.modify}
							</p>
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default TransferSearchBar;
