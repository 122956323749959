import PasswordField from "components/Form/PasswordField/PasswordField";
import validate, { isFormValid } from "helpers/validate";
// import useFetchCountries from "hooks/useFetchCountries";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { changeNewPassword } from "services/auth";
import Locale from "translations";

export default function ChangePassword() {
	// const { userDetails, companyDetails, productsBuilder, commons } = Locale;
	// const [countries] = useFetchCountries();

	const { backOffice, login, passwordReset } = Locale;
	let history = useHistory();

	// const backTeamManagement = () => {
	// 	history.push(`../../back-office-layout`);
	// };

	const [changePassword, setChangePassword] = useState({
		oldPassword: "",
		newPassword: "",
		passwordConfirmation: "",
	});

	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const isPasswordMatch =
		changePassword.passwordConfirmation === changePassword.newPassword;

	//Check Errors
	const checkFormErrors = () => {
		setErrors({
			...errors,

			...validate(
				{ name: "oldPassword", value: changePassword.oldPassword },
				{ required: true, password: true, min: 3 }
			),
			...validate(
				{ name: "newPassword", value: changePassword.newPassword },
				{ required: true, min: 3 }
			),
			...validate(
				{
					name: "passwordConfirmation",
					value: isPasswordMatch,
				},
				{ required: true }
			),
		});
	};

	const handleCreate = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
			// backTeamManagement();
		}
	};
	useEffect(() => {
		if (isFormValid(errors)) {
			const update = async () => {
				let date = {
					old_password: changePassword.oldPassword,
					new_password: changePassword.newPassword,
					new_password_confirmation: changePassword.passwordConfirmation,
				};
				const res = await changeNewPassword(date);
				if (res.status === 200 || res.status === 201) {
					store.addNotification({
						title: "Info!",
						message: login.passwordChangeSuccess,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
						onRemoval: () => {
							localStorage.clear()
							// history.push("/user-profile");
						},
					});
				}
			};

			update();
		}
	}, [isErrorLoaded]);

	return (
		<>
			<div className=" container bg-white  border rounded p-0">
				<div className="d-flex justify-content-between p-4 border-bottom">
					<h6 className="font-weight-bold">{backOffice.changePassword}</h6>
				</div>
				<div className="row m-0 p-4 align-items-center">
					<div className="col-md-6 m-auto">
						<div className="row m-0 w-80 m-auto">
							<div className="col-md-12">
								<PasswordField
									placeholder={backOffice.oldPasswordPlaceholder}
									label={backOffice.oldPassword}
									name="old Password"
									value={changePassword.oldPassword}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: "oldPassword", value: e.target.value },
												{
													required: true,
													password: true,
													min: 8,
												}
											),
										});
										setChangePassword({
											...changePassword,
											oldPassword: e.target.value,
										});
									}}
									min={8}
									color={
										errors?.oldPassword?.required ||
											errors?.oldPassword?.password ||
											errors?.oldPassword?.min
											? "danger"
											: "success"
									}
									errors={errors?.oldPassword}
								/>
							</div>
							<div className="col-md-12">
								<PasswordField
									placeholder={backOffice.newPasswordPlaceholder}
									label={backOffice.newPassword}
									name="old Password"
									value={changePassword.newPassword}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: "newPassword", value: e.target.value },
												{
													required: true,
													password: true,
													min: 8,
												}
											),
										});
										setChangePassword({
											...changePassword,
											newPassword: e.target.value,
										});
									}}
									min={8}
									color={
										errors?.newPassword?.required ||
											errors?.newPassword?.password ||
											errors?.newPassword?.min
											? "danger"
											: "success"
									}
									errors={errors?.newPassword}
								/>
							</div>
							<div className="col-md-12">
								<PasswordField
									placeholder={passwordReset.newConfirmPasswordPlaceholder}
									label={backOffice.confirmPassword}
									name="confirmationPassword"
									value={changePassword.passwordConfirmation}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{
													name: "passwordConfirmation",
													value: !isPasswordMatch,
												},
												{
													required: true,
												}
											),
										});
										setChangePassword({
											...changePassword,
											passwordConfirmation: e.target.value,
										});
									}}
									min={8}
									color={
										errors?.passwordConfirmation?.required ||
											errors?.passwordConfirmation?.password ||
											errors?.passwordConfirmation?.min
											? "danger"
											: "success"
									}
									errors={errors?.passwordConfirmation}
								/>
							</div>
							<div className="col-md-12">
								<button
									className="btn text-white bg-blue-black rounded-0 py-2 w-100 my-2"
									onClick={handleCreate}
								>
									{backOffice.changePassword}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
