import React, { useRef, useState } from "react";
import SinglePromo from "./SinglePromo";
import JSZip from "jszip";
import { toBlob, toCanvas } from "html-to-image";
import { saveAs } from "file-saver";
import { useSBSDispatch, useSBSState } from "context/global";
import jsPDF from "jspdf";
import {
	DOWNLOAD_FLYER_TEMPLETE,
	TEMPLETE_TYPE_MULTIPLE,
} from "../helpers/globales";
import { ModalActions } from "./ModalActions";
import { ReactComponent as PdfIcon } from "assets/images/promo/pdf-icon.svg";
import { ReactComponent as ImageIcon } from "assets/images/promo/image-icon.svg";
import MultiplePromo from './MultiplePromo';
import Locale from 'translations';

export function DownLoadFlyer({
	selectedHotels,
	logoSrc,
	headerText,
	disclaimerText,
	pageType,
	handleNextPage,
	handlePrevPage,
	templateType,
}) {
	const { locale } = useSBSState();
	const { marketPlace } = Locale;
	const downloadPage = pageType === DOWNLOAD_FLYER_TEMPLETE;
	const [downloadType, setDownloadType] = useState("pdf");
	const dispatch = useSBSDispatch();

	const printRef = useRef(null);

	const downloadTypeHandler = (e) => {
		const value = e.target.value;
		setDownloadType(value);
	};

	const downloadComponents = async (components) => {
		const zip = new JSZip();
		const promises = [];

		// Loop through the components array and convert each component to an image
		components.forEach((component, index) => {
			const promise = toBlob(component).then((blob) => {
				zip.file(`promo_image_${index}.png`, blob);
			});
			promises.push(promise);
		});

		// Wait for all promises to resolve
		dispatch({ type: "setLoading", payload: true });
		await Promise.all(promises);

		// Generate and download the zip file
		zip.generateAsync({ type: "blob" }).then((content) => {
			saveAs(content, "promo.zip");
		});
		dispatch({ type: "setLoading", payload: false });
	};

	const generatePDF = async (components) => {
		const images = components; // Replace with actual image URLs or paths
		const [width, height] =
			templateType === TEMPLETE_TYPE_MULTIPLE ? [1080, 1440] : [1440, 1080];
		const pdf = new jsPDF();
		let currentY = 0;
		const generatePage = async (component) => {
			const canvas = await toCanvas(component);
			const imageData = canvas.toDataURL("image/jpeg");
			pdf.internal.pageSize.height = height;
			pdf.internal.pageSize.width = width;
			pdf.addImage(
				imageData,
				"JPEG",
				0,
				currentY,
				pdf.internal.pageSize.getWidth(),
				0
			);

			if (component !== images[images.length - 1]) {
				pdf.addPage();
			}
		};

		dispatch({ type: "setLoading", payload: true });

		for (const imageUrl of images) {
			await generatePage(imageUrl);
		}
		dispatch({ type: "setLoading", payload: false });

		pdf.save("promo-images.pdf");
	};
	const downloadImage = () => {
		const child = printRef.current.children || [];
		downloadComponents([...child]);
	};
	const downloadPdf = () => {
		const child = printRef.current.children || [];
		generatePDF([...child]);
	};

	const downloadFlyerFunction =
		downloadType === "pdf" ? downloadPdf : downloadImage;

	const promoLength = selectedHotels.length;
	const multipleTemplate = templateType === TEMPLETE_TYPE_MULTIPLE;
	const promoPreviewStyles = {
		height: multipleTemplate ? 411 : 227 * promoLength,
	};
	return (
		<>
			<div className="col-lg-5 col-12 px-3">
				<div
					className={` ${
						multipleTemplate ? `multiple-promo-preview-${locale}` : `single-promo-preview-${locale}`
					}`}
					style={promoPreviewStyles}
					ref={printRef}
				>
					{multipleTemplate ? (
						<MultiplePromo 
						selectedHotels={selectedHotels}
						logoSrc={logoSrc}
						headerText={headerText}
						disclaimerText={disclaimerText}
						/>
							
					) : (
						selectedHotels.map((hotel) => (
							<SinglePromo
								key={hotel.id}
								hotel={hotel}
								logoSrc={logoSrc}
								headerText={headerText}
								disclaimerText={disclaimerText}
							/>
						))
					)}
				</div>
			</div>
			{downloadPage && (
				<div className="col-12 select-download-type ">
					<h4 className="h4">{marketPlace.selectFormat}</h4>
					<div className="download-label-container">
						<label
							htmlFor="pdf-radio"
							className={`select-download-label ${
								downloadType === "pdf" ? "checked-active" : ""
							}`}
						>
							<input
								type="radio"
								id="pdf-radio"
								value="pdf"
								checked={downloadType === "pdf"}
								onChange={downloadTypeHandler}
							/>
							<PdfIcon />
							<span className="h3">PDF</span>
						</label>
						<label
							htmlFor="image-radio"
							className={`select-download-label ${
								downloadType === "image" ? "checked-active" : ""
							}`}
						>
							<input
								type="radio"
								id="image-radio"
								value="image"
								checked={downloadType === "image"}
								onChange={downloadTypeHandler}
							/>
							<ImageIcon />
							<span className="h3">Image</span>
						</label>
					</div>
				</div>
			)}
			<ModalActions
				handlePrevPage={handlePrevPage}
				downloadFlyerFunction={downloadFlyerFunction}
				handleNextPage={handleNextPage}
				pageType={pageType}
			/>
		</>
	);
}
