import axios from "axios";
import { useEffect, useState } from "react";

export default function useLookupsApi() {
	const [isLoaded, setIsLoaded] = useState(false);
	const [lookups, setLookups] = useState({
		product_types: [],
		product_classifications: [],
		product_price_types: [],
		hotel_types: [],
		room_types: [],
		meal_types: [],
		destination_types: [],
		transportation_types: [],
		transportation_cycle_types: [],
		visa_options: [],
		discount_types: [],
	});

	const fetchLookups = () => {
		axios
			.get(
				`${process.env.REACT_APP_PRODUCTS_BUILDER}/lookups_common_product_builder`
			)
			.then((res) => {
				// setLookups(res.data.data);
				setIsLoaded(true);
				// to set two product_types
				setLookups({
					...res.data.data,
				
				});
			});
	};

	useEffect(() => {
		fetchLookups();
	}, []);

	return [lookups, isLoaded];
}
