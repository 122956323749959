import NumberField from 'components/Form/NumberField/NumberField';
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField'
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ReactComponent as SaveIcon } from "assets/images/umrah-operations/save-icon.svg";
import validate, { isFormValid } from "helpers/validate";
import Locale from 'translations';


export default function EditSupervisorModal({ toggle, supervisorData, setSupervisorModal, saveSupervisorData, countries }) {
  const { operationStatement, marketPlace } = Locale;
  const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  function handleInputChanges(keyName, value) {
    setSupervisorModal({
      ...supervisorData,
      data: {
        ...supervisorData?.data,
        [keyName]: value
      }
    });

    setErrors({
      ...errors,
      ...validate(
        { name: keyName, value },
        { required: keyName !== "phone_country" }
      ),
    })
  }

  function checkFormErrors() {
    let submitErrors = {}
    Object.keys(supervisorData?.data).forEach((key) => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: key, value: supervisorData?.data?.[key] },
          { required: key !== "phone_country" }
        ),
      }
    })
    setErrors({ ...submitErrors });
  }

  function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      saveSupervisorData();
    }
  }, [isErrorLoaded]);


  return (
    <Modal isOpen={supervisorData?.isOpen} centered className='operation-modal'>
      <ModalHeader toggle={toggle} className='align-items-center'>
        {supervisorData?.type === "edit" ? operationStatement.editSupervisor : operationStatement.addSupervisor}
      </ModalHeader>
      <ModalBody>
        <div className="col-12">
          <TextField
            label={operationStatement.Supervisor}
            placeholder={operationStatement.responsibleEmployee}
            id="supervisor_name"
            name="supervisor_name"
            value={supervisorData?.data?.name}
            onChange={(e) => handleInputChanges("name", e.target.value)}
            color={errors?.name?.required ? "danger" : ""}
            errors={errors?.name}
          />
        </div>
        <div className="mt-3">
          <div className="row mx-0">
            {/* phone code */}
            <div className="col-3 company_phone_code">
              <SelectField
                label={operationStatement.PhoneNumber}
                id="supervisor_phone_code"
                name="supervisor_phone_code"
                value={
                  supervisorData?.data?.phone_code?.flag ?
                    <img
                      src={`${fetchFlag}/${supervisorData?.data?.phone_code?.flag}`}
                      width="35px"
                      alt={supervisorData?.data?.phone_code?.label || supervisorData?.data?.phone_code?.name}
                    />
                    :
                    supervisorData?.data.phone_code?.label || supervisorData?.data?.phone_code?.phone_code
                }
                onChange={(e) => handleInputChanges("phone_code", e)}
                options={countries}

              />
            </div>
            {/* phone */}
            <div className="col-8 px-0">
              <NumberField
                type={"phone"}
                label=""
                // hasLabel={false}
                id="supervisor_phone"
                name="supervisor_phone"
                value={supervisorData?.data.phone}
                removeArrow={true}
                placeholder={"000 0000 000"}
                min={3}
                onChange={(e) => handleInputChanges("phone", e.target.value)}
                extraTextPosition="prepend"
                extraText={
                  supervisorData?.data?.phone_code?.phone_code ||
                  supervisorData?.data.phone_code?.label ||
                  supervisorData?.data?.phone_code?.value
                }
                color={errors?.phone?.required ? "danger" : ""}
                errors={errors?.phone}
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <button className='btn save-btn' onClick={submit}>
            <SaveIcon />
            <span className='mx-1'>{marketPlace.SaveChanges}</span>
          </button>
        </div>

      </ModalBody>
    </Modal>
  )
}
