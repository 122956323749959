import Pagination from "components/Pagination";
import moment from "moment";
import { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { Table } from "reactstrap";
import { getWalletRequests } from "services/wallet";
import Locale from "translations";
import FilterRequests from "./FilterRequests";

export default function Requests({ id, currency }) {
	const { payment } = Locale;
	// let { id } = useParams();
	// let history = useHistory();
	const [requestList, setRequestList] = useState([]);

	const [filter, setFilter] = useState({
		receipt_number: "",
		status: { id: null, name: "", title: "" },
		created_at: undefined,
		page: 1,
	});
	const [meta, setMeta] = useState();
	const [searchStatus, setSearchStatus] = useState("");

	useEffect(() => {
		async function fetchRequsts() {
			const res = await getWalletRequests(id, {
				...filter,
				status: filter?.status?.title,
				created_at: filter.created_at
					? moment(filter.created_at).format("YYYY-MM-DD")
					: "",
				//page: 1,
			});
			setRequestList(res.data);
			setMeta(res?.data?.meta);
		}
		fetchRequsts();
	}, [searchStatus, filter.page]);
	const search = () => {
		setSearchStatus(!searchStatus);
	};
	const AllProducts =
		requestList?.data?.length > 0 ? (
			requestList?.data?.map((productItem, index) => {
				return (
					<tr key={productItem.id}>
						<td>{productItem.receipt_number}</td>
						<td>{productItem.created_at}</td>
						<td>
							{productItem.amount} {currency}
						</td>
						<td>
							{productItem.status === "pending" ? (
								<span className="text-warning">{payment.messages.Pending}</span>
							) : productItem.status === "approved" ? (
								<span className="text-success">
									{payment.messages.Approved}
								</span>
							) : (
								<span className="text-danger">{payment.messages.Reject}</span>
							)}
						</td>
						{/* 						<td className="actions">
						 */}{" "}
						{/* <span className="text-yellow font-15">
								<i class="fas fa-cloud-download-alt  text-yellow"></i>{" "}
								{payment.messages.download}
							</span>
							<span className="mx-4 text-yellow font-15">
								<i class="fas fa-print text-yellow"></i>{" "}
								{payment.messages.print}
							</span> */}
						{/* <span onClick={() => showRequests(productItem.id)}>
								<i onClick={toggle} className="far fa-eye text-primary"></i>
							</span>

							<span onClick={() => showRequests(productItem.id)}>
								<i
									onClick={toggleNotes}
									className="fas fa-sticky-note text-primary"
								></i>
							</span>

							<i
								className="fas fa-trash-alt text-danger"
								onClick={() => removeItem(index)}
							></i> */}
						{/* 						</td>
						 */}{" "}
					</tr>
				);
			})
		) : (
			<tr>
				<td colSpan="9">
					<div className="product-build__product-no-data fullHeight">
						<i className="icx icx-eye-slash" aria-hidden="true"></i>
						<h4>{payment.messages.noResults}</h4>
					</div>
				</td>
			</tr>
		);
	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};
	return (
		<div className="wallet-wrraper0">
			<div className="product-builder-list bg-transparent ">
				<h6 className="font-weight-bold dark-blue-color   my-4 mx-2">
					{payment.messages.allRequests} ({requestList?.data?.length})
				</h6>
				<FilterRequests filter={filter} setFilter={setFilter} search={search} />
				<div className='table-container'>

				<Table striped className="table-update p-2 bg-white">
					<thead>
						<tr>
							<th>{payment.messages.transactionId}</th>
							<th>
								<i class="fas fa-sort"></i>
								{payment.messages.date}
							</th>
							<th>
								<i class="fas fa-sort"></i>
								{payment.messages.amount}
							</th>
							<th>{payment.messages.status}</th>
{/* 							<th width="25%">{payment.messages.actions}</th>
 */}						</tr>
					</thead>
					<tbody>{AllProducts}</tbody>
				</Table>
				</div>
				{requestList?.data?.length > 0 ? (
					<Pagination info={meta} goTo={goTo} />
				) : null}
			</div>
		</div>
	);
}
