import React from 'react'
import Locale from 'translations';
// category info object contain servicename, service icon, color default grey

export default function CategoryCard({ categroyTitle, categroyInfo, categorySub }) {

  return (
    <div className='statement-category-card'>
      <div className='category-icon'
        style={{
          borderColor: `${categroyInfo?.color ? `${categroyInfo.color}29` : "rgba(152, 162, 179, 0.20)"}`,
          backgroundColor: `${categroyInfo?.color ? `${categroyInfo.color}20` : "rgba(234, 236, 240, 0.50)"}`,
        }}
      >
        {categroyInfo.icon}
      </div>

      <div className='info-container'>
        <span className='category-type'>
          {categroyTitle || "-"}
        </span>
        <span className='category-service-name' title={categroyInfo.name}>
          {categroyInfo?.name ? categroyInfo?.name?.length > 25 ? `${categroyInfo.name?.slice(22)}...` : categroyInfo?.name : "-"}
        </span>

        <div className='category-sub-data'>
          {categorySub?.map((sub, index) => {
            return (
              <p key={`sub-category-${index}`} className='d-flex align-items-center'>
                {sub.icon}
                <span className='mx-1'>
                  {sub.name || "-"}
                </span>
              </p>
            )
          })}
        </div>

      </div>

    </div>
  )
}
