
	export	const GeneratedPDF = 
	`
	<style>
	html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
margin: 0;
padding: 0;
}
a {
text-decoration: none !important;
cursor: pointer;
}
a:hover {
text-decoration: none !important;
}

fieldset,
img {
border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
font-style: normal;
font-weight: normal;
}
ol,
ul {
list-style: none;
}
caption,
th {
text-align: var(--left);
}
h1,
h2,
h3,
h4,
h5,
h6 {
font-size: 100%;
font-weight: normal;
}
q:before,
q:after {
content: "";
}
abbr,
acronym {
border: 0;
font-variant: normal;
}
sup {
vertical-align: text-top;
}
sub {
vertical-align: text-bottom;
}
input,
textarea,
select {
font-family: inherit;
font-size: inherit;
font-weight: inherit;
}
legend {
color: #000;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
outline: none;
}
* {
font-family: "Cairo", sans-serif;
box-sizing: border-box;
}

.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
width: 100%;
padding-right: 10px;
padding-left: 10px;
margin-right: auto;
margin-left: auto;
}
.container {
max-width: 1160px;
}
.py-4 {
padding-bottom: 1.5rem !important;
padding-top: 1.5rem !important;
}
.mx-3 {
margin-left: 1rem !important;
 margin-right: 1rem !important;
}
.mt-2 {
margin-top: 0.5rem !important;
}
.mt-3 {
margin-top: 1rem !important;
}
.bg-gray {
background: #e8e8e8 !important;
}
.p-4 {
padding: 1.5rem !important;
}
.align-items-center {
align-items: center !important;
}
.justify-content-around {
justify-content: space-around !important;
}
.font-weight-bold {
font-weight: 700 !important;
}

.mb-1, .my-1 {
margin-bottom: 0.25rem !important;
}


.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
padding-right: 5px;
padding-left: 5px;
}


.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
padding-right: 5px;
padding-left: 5px;
}
.col-2 {
flex: 0 0 16.6666666667%;
max-width: 16.6666666667%;
}
.col-8 {
flex: 0 0 66.6666666667%;
max-width: 66.6666666667%;
}
.col-1 {
flex: 0 0 8.3333333333%;
max-width: 8.3333333333%;
}
.col-3 {
flex: 0 0 25%;
max-width: 25%;
}
.col-6 {
flex: 0 0 50%;
max-width: 50%;
}
.col-4 {
flex: 0 0 33.3333333333%;
max-width: 33.3333333333%;
}
.img-fluid {
max-width: 100%;
height: auto;
}
.w-50{
width : 50%
}


.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
padding-right: 5px;
padding-left: 5px;
}

.justify-content-between {
justify-content: space-between !important;
}

.row {
display: flex;
flex-wrap: wrap;
margin-right: -15px;
margin-left: -15px;
}


	




.p-5{
padding: 2rem;
}
.d-flex {
display: flex !important;
}
.justify-content-center {
justify-content: center!important;
}
* {
	font-family: sans-serif;
}

table {
	width: 100%;
	border-collapse: collapse;
}

th,
td {
	border: 2px solid rgba(219, 218, 222, 1);
	padding: 5px;
}

th,
td {
	text-align: start;
}
body {
	padding: 0;
	margin: 0;
}
.generated-pdf {
	background: #fbfbfb;
}
.head {
	position: relative;
}
header {
	
	background-image: url([header_img]);
	width: 100%;
	height: 325px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	position: relative;
}
header .img-header-bottom {
	position: absolute;
	width: 100%;
	bottom: -15px;
	height: 142px;
}
.logo {
	position: absolute;
	bottom: 0;
	left: 45px;
}
.logo img {
	width: 200px;
	height: 200px;
	border-radius: 50%;
}
.head-info {
	padding: 0px 16px 10px 16px;
	margin-bottom: 10px;
}
.head-info .title h6 {
	color: rgba(0, 0, 0, 1);
	font-size: 22px; /**14**/
	font-weight: 700;
	margin-bottom: 12px;
	margin-top:22px
}
.head-info .icons {
	display: flex;
	gap: 25px;
	height: 23px;
}
.head-info .icon-item {
	display: flex;
	gap: 7px;
	border-right: 1px solid #707170;
	align-items: center;
	padding-right: 13px;
}
.icon-item span {
	color: #707170;
	font-size: 15px; /**10**/
	font-weight: 400;
}
.no-border {
	border: none !important;
}
.flight {
	margin: 0px 16px 10px 16px;

	border: 1px solid rgba(235, 235, 235, 1);
	border-radius: 8px;
	padding: 12px;
}
.flight .flight-row {
	display: flex;
	flex-wrap: wrap;
}
.flight-row .icon {
	display: flex;
	align-items: center;
	gap: 14px;
	width: 150px;
	border-right: 1px solid rgba(221, 220, 220, 1);
}
.flight-row .icon img {
	width: 50px;
	height: 50px;
}
.flight-row .icon p {
	display: flex;
	flex-direction: column;
	color: #050001;
	gap: 8px;
	font-weight: 500;
}
.flight-row .all-data{
	width:70%
}
.flight-row .data {
	display: flex;
	justify-content: space-between;
	width: 100%;
  align-items: center;
	margin-bottom: 7px;
}
.flight-row .data .data-item{
	display: flex;
	gap:7px;
	width:50%
}
.flight-row .data span {
	color: #707170;
	padding-inline: 17px;
}
.flight-row .data p {
	color: #050001;
	font-weight: 600;
}
.flight hr {
	border: 0.8px solid rgb(235 235 235 / 29%);
}
.hotel {
	margin: 14px 16px 10px 16px;
	border: 1px solid rgba(235, 235, 235, 1);
	border-radius: 8px;
	padding: 12px;
	display: flex;
}
.hotel .icon {
	display: flex;
	align-items: center;
	gap: 12px;
	width: 173px;
	border-right: 1px solid rgba(221, 220, 220, 1);
}
.hotel .icon img {
	width: 50px;
	height: 50px;
}
.hotel .icon p {
	height: 100%;
	display: flex;
	flex-direction: column;
	color: #050001;
	gap: 4px;
	font-weight: 500;
	justify-content: center;
}
.hotel .data {
	padding-inline: 19px;
	width: 100%;
}
.hotel .data hr {
	border: 0.8px solid rgb(235 235 235 / 29%);
}
.hotel .hotel-row div {
	display: flex;
	gap: 35px;
	width: 100%;
}
.hotel-row .hotel-night {
	margin-top: 12px;
	margin-bottom: 8px;
}
.hotel-row .hotel-night p {
	color: #707170;
}
.hotel-row .hotel-night span {
	color: #050001;
}
.hotel .attraction-data {
	padding-inline: 19px;
	width: 100%;
}
.attraction-data .attraction-item {
	display: flex;
	gap: 35px;
	// border-bottom: 0.8px solid rgb(235 235 235 / 29%);
	align-items: center;
	margin-bottom: 5px;
}
.hr-attraction{
	border: 0.8px solid rgb(235 235 235 / 29%) !important;

}
.attraction-item h6 {
	color: #050001;
	font-size: 16px;
	font-weight: 600;
	margin: 5px 0;
}
.attraction-item p {
	color: #050001;
}
.attraction-item span {
	color: #707170;
}
.transport-data {
	padding-inline: 19px;
	width: 100%;
}
.transport-data .head {
	display: flex;
	align-items: center;
	gap: 24px;
	margin-bottom: 5px;
}
.transport-data .head h6 {
	color: #050001;
	font-weight: 600;
	font-size: 14px;
	margin: 5px 0;
}
.transport-data .head p {
	color: #707170;
}
.transport-data .head span {
	color: #050001;
	margin-inline: 12px;
}
.transport-data thead {
	background-color: #f7f6f6;
}
.transport-data thead th {
	color: #707170;
	font-size: 16px;
	font-weight: 500;
}
.transport-data tbody {
	background-color: #fff;
}
.footer {
	border-top: 1px solid rgba(221, 220, 220, 1);
	margin: 14px 16px 10px 16px;
					// position: absolute;
					left: 0;
					bottom: 0;
			
			

}
.footer p {
	padding-top: 20px;
	text-align: center;
	color:#4E4F54;
}
p {
	margin: 0;
}
.title{
	display: flex;
	gap:25px;
}
.title ul{
	display: flex;
	
}

.title ul li{
	color:#f5b950 !important;
	margin-top:25px;
}
.gray-stars{
color:#dddcdc !important
}
.px-0{
	padding-inline: 0 !important;
}
.icon-item-visa {
	display: flex;
	gap: 7px;
	border-left: 1px solid #707170 !important;
	align-items: center;
	padding-left: 13px;
}
.icon-item-visa  i{
	color:#15d43f !important;
}
.icon-item-visa span {
	color: #707170;
	font-size: 15px; 
	font-weight: 400;
}
</style>
<div class="generated-pdf">
			<div class="head">
				<header >
					<img
						src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/b796721a-34e3-4269-a492-0d47a70d93c7"
						alt=""
						class="img-header-bottom"
					/>
				</header>
				<div class="logo">
					<img
						src=[logo_img]
						alt=""
					/>
				</div>
			</div>
			<div class="head-info">
				<div class="title">
					<h6>[name_trip] </h6>
					<ul>
					[colored_starts]
					[gray_starts]
					</ul>
				</div>
				<div class="icons">
					<div class="icon-item">
						<img
							src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/1fb1ad19-8b17-444d-a9dd-5cbf1bba1151"
							alt=""
						/>
						<span> [country]</span>
					</div>
					<div class="icon-item">
						<img
							src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/23c3d7e0-f102-40b9-a1f9-07f3c18ef86f"
							alt=""
						/>
						<span> [duration] Days </span>
					</div>
				

				[has_visa]
				</div>
			</div>
		[flight_data]
			

			<!-- Attraction -->
			
			[allAttraction]
		<!-- Hotel -->
			[allHotel]
			<!-- Transport -->
			[allTransport]


			<!-- Footer -->
			<div class="footer" id="footer">
				<p>
				[footer]
				</p>
			</div>
		</div>
	
`