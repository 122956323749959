export let itineraryElementsIds = {
  external: 1,
  internal: 2,
  visa: 4,
  insurance: 5,
  hotel: 6,
  sightseeing: 7,
  otherServices: 8
}

export let roomTypesLookup = {
  1: "Single",
  2: "Double",
  3: "Triple",
  4: "Quadruple",
  5: "Quintuple",
  6: "Hexagonal",
  7: "heptathlon",
  8: "suite",
  9: "flat",
};