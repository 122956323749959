import React, { useEffect, useState } from "react";
import BestDealsNav from "../nav";
import HeroSection from "../heroSection";
import CitiesSection from "../citiesSection";
import CityHotelsSection from "../cityHotels";
import BestDealsFooter from "../ bestDealsFooter";
import Flyerbar from "../flyerbar";
import { hotelPromo } from "services/promo";
import AddPromoDetailsModal from '../../Modals/AddPromoDetailsModal';


const BestDealsPage = () => {
	const [create, setCreate] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [hotelPromoList, setHotelPromoList] = useState([]);
	const [selectedHotels, setSelectedHotels] = useState([]);

const handelModalState = ()=> {
	setOpenModal(prev => !prev)
}
	const scrollToCity = (id) => {
		let city = document.getElementById(id);
		city.scrollIntoView({ block: "start", behavior: "smooth" });
	};

	const fetchHotelPromo = async () => {
		const response = await hotelPromo();
		if (response.status === 200) {
			setHotelPromoList(response.data.data);
		}
	};
	useEffect(() => {
		fetchHotelPromo();
		
		return () => {};
	}, []);

	return (
		<div className="w-100" style={{ backgroundColor: "#F6F8FF" }}>
			<BestDealsNav />
			<HeroSection hotelPromoList={hotelPromoList} />
			<CitiesSection cityList={hotelPromoList} scrollToCity={scrollToCity} />
			<Flyerbar
				create={create}
				setCreate={setCreate}
				setSelectedHotels={setSelectedHotels}
				selectedHotels={selectedHotels}
				handelModalState={handelModalState}
			/>
			{hotelPromoList.map((city) => (
				<CityHotelsSection
					key={city?.id}
					create={create}
					cityDetails={city}
					selectedHotels={selectedHotels}
					setSelectedHotels={setSelectedHotels}
				/>
			))}
			<BestDealsFooter />
			<AddPromoDetailsModal openModal={openModal} handelModalState={handelModalState} setSelectedHotels={setSelectedHotels} selectedHotels={selectedHotels} />
		</div>
	);
};

export default BestDealsPage;
