import ShowForPermission from "helpers/showForPermission";
import useShowPermission from 'hooks/useShowPermission';
import { useEffect, useMemo, useState } from "react";
import { store } from "react-notifications-component";
import { AddListRemark, getListRemark } from "services/marketplace";
import Locale from "translations";

const BookingRemarks = ({ details }) => {
	const { inventory } = Locale;
	const [notesState, setNotesState] = useState();
	const [notesInputs, setNotesInputs] = useState("");
	const fetchNotes = async () => {
		const res = await getListRemark(details?.id);
		setNotesState(res?.data?.data);
	};
	useEffect(() => {
		fetchNotes();
	}, [details?.id]);

	const addNotes1 = async () => {
		const res = await AddListRemark(details?.id, {
			content: notesInputs,
		});
		if (res.status == 200) {
			store.addNotification({
				title: "Info!",
				message: "Add Remark successfully!",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					fetchNotes();
				},
			});
			setNotesInputs("");
		}
	};
	const allNoteList = useMemo(() => {
		return notesState?.length > 0
			? notesState?.map((datas, index) => {
					return (
						<div className="bg-gray-100 p-2  mt-2 border rounded" key={index}>
							<p className="m-0 txt-blue font-weight-bold">{datas.user_name}</p>
							<p className="m-0">{datas.content}</p>
						</div>
					);
			  })
			: [];
	}, [notesState]);

	const hotelPermission = useShowPermission({
		permission:  "Manage-Inventory-Hotels",
	});

	return (
		<section className="marketplace-details booking-remark bg-white py-3">
			<div className="d-flex justify-content-between hotel-name px-4">
				<div className="d-flex">
					<h6 className="font-weight-bold dark-blue-color bg-white pt-3 text-headline">
						{details?.hotel_name}
					</h6>
				</div>
			</div>
			<hr className="hr my-2" />
			<div className="remark px-4">
				{notesState?.length > 0 ? allNoteList : null}
				<div className="">
					<div className="mt-2">
						<textarea
							value={notesInputs}
							className="w-100 p-3"
							placeholder={inventory.messages.addRemark}
							onChange={(e) => setNotesInputs(e.target.value)}
							disabled={!hotelPermission}
						/>
					</div>
					<div className="d-flex justify-content-end">
						<ShowForPermission permission="Manage-Inventory-Hotels">
							<button
								className="btn bg-nxt"
								onClick={() => {
									addNotes1();
								}}
								disabled={notesInputs === ""}
							>
								{inventory.messages.addRemark}
							</button>
						</ShowForPermission>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BookingRemarks;
