import hotel from "assets/images/hotel-1.png";
import safaLogo from "assets/images/safaLogo.svg";

export default function Application() {
	const style = {
		blueDark: {
			color: "#003C5D",
		},
		header: {
			color: "#003C5D",
			fontSize: "20px",
			fontWeight: "700",
		},
		hr: {
			borderTop: "2px solid rgba(0, 0, 0, 0.1)",
		},
		hrNoMargin: {
			margin: "0px !important",
		},
		hrDark: {
			borderTop: "2px solid #003C5D",
		},
		status: {
			span: {
				border: "1px solid #28A745",
				padding: "1px 85px",
				borderRadius: "5px",
				color: "#28A745",
				fontWeight: "700",
			},
		},
		reservation: {
			h3: {
				color: "#003C5D",
				fontWeight: "700",
			},
		},
		resInfo: {
			h4: {
				color: "#0C3B5C",
				fontSize: "18px",
				fontWeight: "700",
			},
			p: {
				color: "#2D2D2D",
				fontSize: "13px",
			},
		},
	};
	return (
		<div
			className="container"
			style={{ background: "#fff", width: "900px !important" }}
		>
			<div className="mx-5">
				<div className="header pt-2">
					<div className="d-flex justify-content-between align-items-center">
						<img src={safaLogo} alt="logo" />
						<h1 style={style.header}>Thank You</h1>
					</div>
				</div>
				<hr style={style.hr} />
				<div className="status text-center">
					<h1 style={{ fontSize: "20px", fontWeight: "700", color: "#28A745" }}>
						Your Booking is Confirmed
					</h1>
					<p className="mb-2">Your booking reference:</p>

					<span style={style.status.span} className="my-5">
						SAF-1
					</span>
					<p className="mt-2">Thanks for booking with us!</p>
				</div>
				<hr style={style.hrDark} />
				<div className="reservation1">
					<h3 style={style.reservation.h3} className="mb-3">
						Reservation Details
					</h3>
					<div className="d-flex">
						<div className="res-img w-25">
							<img src={hotel} alt="logo" className="img-fluide w-100" />
						</div>
						<div className="res-info w-75 mx-3">
							<h4 style={style.resInfo.h4}>
								Movenpick Hotel And Resort Al Bida’a
							</h4>
							<p style={style.resInfo.p}>
								Al Tawoun Street, P.O.Box 7306, Al Bida’a, Hawally
							</p>
							<div className="d-flex">
								<i class="fas fa-star"></i>
								<i class="fas fa-star"></i>
								<i class="fas fa-star"></i>
								<i class="fas fa-star"></i>
								<i class="far fa-star"></i>
							</div>
							<hr style={style.hrNoMargin} />
							<p style={style.resInfo.p}>
								<span style={{ color: "#656464" }}>Telephone: </span> 971-971 4
								3519111
							</p>
							<hr style={style.hrNoMargin} />

							<div className="d-flex justify-content-between">
								<p style={style.resInfo.p}>
									<span style={{ color: "#656464" }}>Check-in: </span> 18 Oct
									2021
								</p>
								<p style={style.resInfo.p}>
									<span style={{ color: "#656464" }}>Check-out: </span> 20 Oct
									2021
								</p>
							</div>
							<p style={style.resInfo.p}>1 Room(s) / 3 Night(s)</p>
							<hr style={style.hrNoMargin} />
							<div className="d-flex justify-content-between">
								<p
									style={{
										color: "#2D2D2D",
										fontSize: "16px",
										fontWeight: "700",
									}}
								>
									Standard Room Deluxe
								</p>
								<p style={{ fontSize: "13px", color: "#0C3B5C" }}>Room Only</p>
							</div>
						</div>
					</div>
				</div>
				<hr style={style.hrDark} />
				<div className="passenger">
					<div className="d-flex justify-content-between">
						<h3 style={style.reservation.h3} className="mb-3">
							Passengers
						</h3>
						<p style={style.resInfo.p}>2 Adult(s) - 1 Children(s)</p>
					</div>
					<p style={style.resInfo.p}>
						<span style={{ color: "#656464" }}>Leader Name: </span>Ahmed Mohamed
					</p>
					<p style={style.resInfo.p}>
						<span style={{ color: "#656464" }}>Email Address: </span>
						ahmed.mohamed@gmail.com
					</p>
				</div>
				<div className="text-center">
					<p style={{ fontStyle: "italic" }}>Best Regards!</p>
					<h3 style={style.header}>The SBS Team</h3>
				</div>
				<hr style={style.hr} />
				<div>
					<p style={{ color: "#727272" }}>
						For questions regarding your Registration, please email
						operation@safasoft.com or call +201017555338
					</p>
				</div>
				<hr style={style.hr} />
				<div className="d-flex justify-content-between py-2">
					<div className="icons d-flex" style={{ gap: "8px" }}>
						<i class="fab fa-facebook-f" style={{ color: "#A7A7A7" }}></i>
						<i class="fab fa-twitter" style={{ color: "#A7A7A7" }}></i>
						<i class="fab fa-youtube" style={{ color: "#A7A7A7" }}></i>
						<i class="fab fa-linkedin" style={{ color: "#A7A7A7" }}></i>
						<i class="fab fa-instagram" style={{ color: "#A7A7A7" }}></i>
					</div>
					<p style={{ color: "#004DFF" }}>www.safasoft.com</p>
				</div>
			</div>
		</div>
	);
}

// import React, { useState } from "react";
// import { Link, useHistory } from "react-router-dom";
// import { Table } from "reactstrap";
// import CreateAppModel from "./createAppModel";
// import Filter from "./Filter";
// import ApplicationFilter from "./Filter";
// import TableContent from "./tableContent";
// export default function Application(props) {
// 	const history = useHistory();
// 	const [isOpenModel, setIsOpenModel] = useState(false);
// 	const toggleModel = () => setIsOpenModel(!isOpenModel);

// 	const data = [
// 		{
// 			ref: "Taha",
// 			app_no: "12",
// 			app_owner: "taha",
// 			pax: "20",
// 			booking_date: "12/5/2010",
// 			source: "Source Name",
// 		},
// 		{
// 			ref: "Taha",
// 			app_no: "12",
// 			app_owner: "taha",
// 			pax: "20",
// 			booking_date: "12/5/2010",
// 			source: "Source Name",
// 		},
// 		{
// 			ref: "Taha",
// 			app_no: "12",
// 			app_owner: "taha",
// 			pax: "20",
// 			booking_date: "12/5/2010",
// 			source: "Source Name",
// 		},
// 		{
// 			ref: "Taha",
// 			app_no: "12",
// 			app_owner: "taha",
// 			pax: "20",
// 			booking_date: "12/5/2010",
// 			source: "Source Name",
// 		},
// 		{
// 			ref: "Taha",
// 			app_no: "12",
// 			app_owner: "taha",
// 			pax: "20",
// 			booking_date: "12/5/2010",
// 			source: "Source Name",
// 		},
// 	];
// 	const teamRaw =
// 		data?.length > 0 ? (
// 			data?.map((member, index) => (
// 				<TableContent member={member} index={index} />
// 			))
// 		) : (
// 			<tr>
// 				<td colSpan="9">
// 					<div className="product-build__product-no-data fullHeight">
// 						<i className="icx icx-eye-slash" aria-hidden="true"></i>
// 						<h4>{"No Result"}</h4>
// 					</div>
// 				</td>
// 			</tr>
// 		);
// 	return (
// 		<>
// 			{/*Filter */}
// 			<Filter />

// 			<div className="content">
// 				<div className="table-header d-flex align-items-center justify-content-between mx-3">
// 					<h3 className="font-weight-bold  mt-2" style={{ color: "#324356" }}>
// 						Application
// 					</h3>
// 					<button
// 						className="btn btn-success  px-4 py-2 mt-2 "
// 						onClick={toggleModel}
// 					>
// 						Add New
// 					</button>
// 				</div>
// 				<Table striped className="custom-table p-2">
// 					<thead>
// 						<tr>
// 							<th>{"Ref.No"}</th>
// 							<th>{"Application.No"}</th>
// 							<th>{"Application Owmer"}</th>
// 							<th>{"Pax"}</th>
// 							<th>{"Booking Date"}</th>
// 							<th>{"Source"}</th>
// 							<th width="10%">{"Actions"}</th>
// 						</tr>
// 					</thead>

// 					<tbody>{teamRaw}</tbody>
// 				</Table>
// 			</div>
// 			<CreateAppModel toggleModel={toggleModel} isOpenModel={isOpenModel} />
// 		</>
// 	);
// }
