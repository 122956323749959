function findPhoneCode(countriesLookup, phoneCode, initalPhoneCode) {
    if(phoneCode) {
      const countryObj = countriesLookup?.find(country => country?.phone_code === phoneCode || country?.id === +phoneCode) 
      return {
        ...countryObj,
        label: countryObj?.name
      }
    }
    return {...initalPhoneCode}
}

export function formatBaiscSettings(data, countriesLookup, headerImageSettingsLookup, initalPhoneCode) {
  const fetchImage = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
  return {
    ...data,
    account_management_phone_code: findPhoneCode(countriesLookup, data?.account_management_phone_code, initalPhoneCode),
    account_management_whats_app_phone_code: findPhoneCode(countriesLookup, data?.account_management_whats_app_phone_code, initalPhoneCode),
    operation_management_phone_code: findPhoneCode(countriesLookup, data?.operation_management_phone_code, initalPhoneCode),
    operation_management_whats_app_phone_code: findPhoneCode(countriesLookup, data?.operation_management_whats_app_phone_code, initalPhoneCode),
    reservation_management_phone_code: findPhoneCode(countriesLookup, data?.reservation_management_phone_code, initalPhoneCode),
    reservation_management_whats_app_phone_code: findPhoneCode(countriesLookup, data?.reservation_management_whats_app_phone_code, initalPhoneCode),
    header_image_preview: data?.header_image ? `${fetchImage}/${data?.header_image}` : null,
    header_image_setting: headerImageSettingsLookup.find(setting => setting?.id === data?.header_image_setting),
  }
}