import { useParams } from 'react-router-dom';
import VisaSearch from "./VisaSearch";
import GlobalVisaSearch from './GlobalVisaSearch';

function OnlineVisa({ allCountries }) {
	const { status } = useParams();

	return (
		<>
			{status === "umrah"
				?
				<VisaSearch allCountries={allCountries} />
				:
				<GlobalVisaSearch allCountries={allCountries} />
			}
		</>
	);
}

export default OnlineVisa;
