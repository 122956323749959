import React, { useState } from "react";
import { Collapse } from "reactstrap";
import Locale from "translations";
import KeyValue from "./KeyValue";

function PassengersDetails({ passanger, passengerType, index, children,daweloadItinerary }) {
	const { inventory, marketPlace } = Locale;
	const [isOpen, setisOpen] = useState(false);
	const toggle = () => {
		setisOpen(!isOpen);
	};
	return (
		<>
			<div className="form-box mb-2 radius">
				<div className="form-head">
					<p className="bold">
						{passanger.psgType === "ADT"
							? inventory.messages.Adult
							: inventory.messages.Child}{" "}
						{index + 1}
					</p>
					{(isOpen && index !== 0)? (
						<i class="fas fa-angle-up pointer" onClick={toggle} />
					) : (
						<i class="fas fa-angle-down pointer" onClick={toggle} />
					)}
					{/* <i class="fas fa-angle-down pointer" onClick={toggle}></i> */}
				</div>
				<Collapse
					isOpen={index === 0 && passengerType === "adults" ? true : isOpen}
					toggle={toggle}
				>
					<div className="d-flex justify-content-between px-3 py-1 ">
						<div>
							<p className="text-black-50">{inventory.messages.name} ({inventory.messages.GivenName}/{inventory.messages.lastName})</p>
							<p>{`${passanger.firstName} / ${passanger.lastName}`}</p>
						</div>
						<button className="btn bold text-custome" onClick={()=>daweloadItinerary(passanger)}>
							{marketPlace.itinerary} <i className="fas fa-download ms-1"></i>
						</button>
					</div>
					{
						<KeyValue
							data={[
								{ key: marketPlace.gender,value: passanger.sex == "M" ? "Male" : "Female", },
								{ key: marketPlace.Dateofbirth, value: passanger.birthday },
								{ key: marketPlace.idNumber, value: passanger.cardNum },
								{ key: marketPlace.idExpirydate, value: passanger.cardExpiredDate },
								{ key:marketPlace.nationality , value: passanger.nationality },
							]}
						/>
					}
					{children}
				</Collapse>
			</div>
		</>
	);
}

export default PassengersDetails;
