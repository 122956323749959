import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";
import moment from "moment";
import { useParams } from "react-router-dom";
import { store } from "react-notifications-component";
import arrowTwoDirectionsSrc from "assets/images/inventory/arrow-two-directions.svg";
import { cancelTransferItem, cancelTransfers } from "services/marketplace";

export default function CancelModel({
	model,
	toggleModel,
	details,
	cancelType,
	setTransferDetails
}) {
	const { inventory } = Locale;
	const [acceptCancel, setAcceptCancel] = useState(false);
	const { id } = useParams();
	const singleItem = cancelType === "single-item";
	const total = singleItem ? details?.price : details?.total_amount;
	async function cancelTransfer() {
		let res;
		if (singleItem) {
			res = await cancelTransferItem(id, details?.id);
		} else {
			res = await cancelTransfers(id);
		}
		toggleModel();
		if (res.status === 200) {
			setTransferDetails(res.data.data)
			store.addNotification({
				title: "cancel Done",
				message: "cancel Done successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	return (
		<Modal
			size="lg"
			isOpen={model}
			toggle={toggleModel}
		>
			<ModalHeader
				className="align-items-center font-weight-bold hd-title-model"
				toggle={toggleModel}
			>
				{inventory.messages.cancleService}
			</ModalHeader>

			<ModalBody>
				<div className="my-3">
					{/* tours details */}
					{singleItem ? (
						<CancelCardDetails details={details} />
					) : (
						details?.items?.map((item) => {
							return item.status !=="CANCELED" &&(
								<div key={item?.id}>
									<CancelCardDetails details={item} />
									<hr />
								</div>
							);
						})
					)}
					{/* cancel summary */}
					<div className="d-flex flex-column align-items-end mt-2 py-2">
						{/* <div>
							<span className="text-muted text-15">
								{inventory.messages.finalPrice} :
							</span>
							<span className="font-weight-bold h6"> {`${preCancel?.currency} ${preCancel?.currency}`}</span>
						</div> */}
						<div>
							<span className="text-muted text-15">
								{inventory.messages.cancellationFees} :
							</span>
							<span className="font-weight-bold h6">
								{" "}
								{`${details?.charge} ${details?.currency}`}
							</span>
						</div>
						{moment().isBefore(details?.cancellation_policies?.[0]?.from) && (
							<p className="text-success">Free Cancellation</p>
						)}
					</div>
					{/* summary */}
					<div className="d-flex flex-column align-items-end border-top-gray mt-2 py-2">
						<div>
							<span className="text-muted text-15">
								{inventory.messages.paidAmount} :
							</span>
							<span className="font-weight-bold h6">{`${total} ${details?.currency}`}</span>
						</div>
						<div>
							<span className="text-muted text-15">
								{inventory.messages.totalToBeRefunded} :{" "}
							</span>
							<span className="font-weight-bold h6">
								{" "}
								{`${total - details?.charge} ${details?.currency}`}
							</span>
						</div>
					</div>
					{/* accept and proceed to cancel */}
					<div className="d-flex justify-content-center">
						<input
							className='me-1'
							type="Checkbox"
							name="cancle"
							id="cancle"
							checked={acceptCancel}
							onChange={() => setAcceptCancel(!acceptCancel)}
						/>
						<label className="text-muted text-14 m-0" htmlFor='cancle'>
							By Clicking On “Cancel Service” You Are Accepting Cancellation
							Charges
						</label>
					</div>
					<div className="d-flex justify-content-center">
						<button
							className="btn btn-yellow py-2 px-5 font-weight-bold mt-3 text-15"
							disabled={!acceptCancel}
							onClick={() => cancelTransfer()}
						>
							{inventory.messages.cancelService}
						</button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
}

function CancelCardDetails({ details }) {
	const { inventory } = Locale;
	return (
		<div className="d-flex justify-content-between">
			<div className="d-flex flex-md-nowrap flex-wrap">
				<div className="tour-card-img">
					<img
						src={details?.image}
						alt="tours"
						className="img-fluid transfer-booking-item-img"
					/>
				</div>
				<div className="d-flex flex-column" style={{ flex: "1 0 max-content" }}>
					<h2 className="mb-2">
						<span className="font-weight-bold text-dark-blue h5">
							{details?.type}{" "}
						</span>
						<span className="text-dark-blue">{details?.category} </span>
						<span className="h6 text-uppercase text-muted">
							{details?.vehicle}{" "}
						</span>
					</h2>
					<i className="fal fa-sort-alt"></i>

					<div className="transfer-booking-item-info">
						<i className="fas fa-map-marker-alt"></i>
						<p className="mx-1">
							<span>{details?.from_name}</span>
							<img
								src={arrowTwoDirectionsSrc}
								alt=""
								className="img-fluid mx-1"
							/>
							<span>{details?.to_name}</span>
						</p>
					</div>

					<div className="transfer-booking-item-info">
						<i className="far fa-calendar-alt"></i>
						<span className="mx-1 font-weight-bold">
							{moment(details?.booking_date).format("DD/MM/YYYY")} (
							{moment(details?.booking_date).format("dddd")})
						</span>
					</div>

					<div className="transfer-booking-item-info">
						<i className="far fa-user"></i>
						<span className="mx-2">
							{`${details?.trip_type} , ${details?.adults} ${inventory.messages.adults}`}
						</span>
						{details?.children?.length > 0 ? (
							<span>
								{details?.children?.length} {inventory.messages.children} (
								{details?.children?.map((age, index) => {
									return (
										<span key={`age-${index}-${age}`}>
											{age}
											{index !== details?.children?.length - 1 ? ", " : " "}
										</span>
									);
								})}
								Years Old )
							</span>
						) : null}
					</div>
				</div>
			</div>
			<span className="text-success">
				({details?.price?.toFixed(2)} {details?.currency})
			</span>
		</div>
	);
}
