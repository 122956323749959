import BedIcon from "modules/market-place/NewMarketplace/icons/bed";
import PaxIcon from "modules/market-place/NewMarketplace/icons/paxIcon";
import PlusIcon from "modules/market-place/NewMarketplace/icons/plusIcon";
import TimesIcon from "modules/market-place/NewMarketplace/icons/timesIcon";
import { useState } from "react";
import Locale from "translations";

export default function ImportantInfoItem({
	setRoomIsOpen,
	room,
	hotelRequest,
	rate,
	roomIsOpen,
	index,
}) {
	const { productsBuilder, marketPlace } = Locale;

	return (
		<div className="item">
			<div className="head-item">
				<div className="room">
					<BedIcon />
					<div className="data">
						<span>
							{productsBuilder.Room} #{index + 1}
						</span>
						<p>{room.name}</p>
					</div>
				</div>
				<div className="pax">
					<PaxIcon />
					<p>
						{room?.paxes?.adults} {productsBuilder.Adult} ,{" "}
						{room?.paxes?.children} {productsBuilder.children}{" "}
					</p>
				</div>
				<p
					className="mx-2"
					role="button"
					onClick={() => setRoomIsOpen((prev) => index)}
				>
					{roomIsOpen === index ? <TimesIcon /> : <PlusIcon />}
				</p>
			</div>

			<div className="col-md-12">
				<div className="row">
					{roomIsOpen === index &&
						rate?.cancellation_policy.map((polic) => {
							return (
								<>
									{!polic.hasOwnProperty("fromDate") ? (
										<>
											<p className="col-md-4 d-flex justify-content-center align-items-center align-content-center text-center">
												{marketPlace.before} {polic.toDate.slice(0, 16)}
											</p>
											<div className="col-md-4 mt-3">
												{polic.cancelCharge == 0 ? (
													<div className="boxCancellation newboxCancellation bg-Ground">
														<p> {marketPlace.NoCancellationCharge}</p>
													</div>
												) : polic.cancelRestricted ? (
													<div className="boxCancellation newboxCancellation bg-dangerr">
														<p> {marketPlace.CancellationRestricted}</p>
													</div>
												) : (
													<div className="boxCancellation newboxCancellation">
														{marketPlace.CancellationCharge}
														<p>
															{" "}
															{polic.cancelCharge} {rate?.room_price_currency}
														</p>
													</div>
												)}
											</div>
											<div className="col-md-4 mt-3 ">
												{polic.amendCharge == 0 ? (
													<div className="boxCancellation newboxCancellation bg-Ground">
														<p> {marketPlace.NoAmendCharge}</p>
													</div>
												) : polic.amendRestricted ? (
													<div className="boxCancellation newboxCancellation bg-dangerr">
														{marketPlace.AmendRestricted}
													</div>
												) : hotelRequest.gds != 5 && hotelRequest.gds != 6 ? (
													<div className="boxCancellation newboxCancellation">
														<p> {marketPlace.AmendmentCharge}</p>
														{polic.amendCharge}
														{rate?.room_price_currency}
													</div>
												) : (
													""
												)}
											</div>
										</>
									) : !polic.hasOwnProperty("toDate") &&
									  !polic.hasOwnProperty("noShowPolicy") ? (
										<>
											<p className="col-md-4 d-flex justify-content-center align-items-center align-content-center text-center">
												{" "}
												{marketPlace.after} {polic.fromDate.slice(0, 16)}
											</p>
											<div className="col-md-4 mt-3">
												{polic.cancelCharge == 0 ? (
													<div className="boxCancellation newboxCancellation bg-Ground">
														<p> {marketPlace.NoCancellationCharge}</p>
													</div>
												) : polic.cancelRestricted ? (
													<div className="boxCancellation newboxCancellation bg-dangerr">
														<p> {marketPlace.CancellationRestricted}</p>
													</div>
												) : (
													<div className="boxCancellation newboxCancellation">
														{marketPlace.CancellationCharge}
														<p>
															{" "}
															{polic.cancelCharge} {rate?.room_price_currency}
														</p>
													</div>
												)}
											</div>
											<div className="col-md-4 mt-3 ">
												{polic.amendCharge == 0 ? (
													<div className="boxCancellation newboxCancellation bg-Ground">
														<p> {marketPlace.NoAmendCharge}</p>
													</div>
												) : polic.amendRestricted ? (
													<div className="boxCancellation newboxCancellation bg-dangerr">
														<p> {marketPlace.AmendRestricted}</p>
													</div>
												) : hotelRequest.gds != 5 && hotelRequest.gds != 6 ? (
													<div className="boxCancellation newboxCancellation">
														<p> {marketPlace.AmendmentCharge}</p>
														{polic.amendCharge} {rate?.room_price_currency}
													</div>
												) : (
													""
												)}
											</div>
										</>
									) : polic.hasOwnProperty("noShowPolicy") ? (
										<>
											<p className="col-md-4 d-flex justify-content-center align-items-center align-content-center text-center">
												{" "}
												{marketPlace.after} {polic.fromDate.slice(0, 16)}
											</p>

											<div className="col-md-12">
												<div className="boxCancellation newboxCancellation bg-dangerNew ">
													<p> {marketPlace.NoShowPolicyCharge}</p>
													{polic.charge} {rate?.room_price_currency}
												</div>
											</div>
										</>
									) : (
										<>
											<p className="col-md-4 d-flex justify-content-center align-items-center align-content-center flex-column text-center">
												{" "}
												{marketPlace.between} {polic.fromDate.slice(0, 16)}{" "}
												{marketPlace.and}
												<p>{polic.toDate.slice(0, 16)}</p>
											</p>
											<div className="col-md-4 mt-3">
												{polic.cancelCharge == 0 ? (
													<div className="boxCancellation newboxCancellation bg-Ground">
														<p> {marketPlace.NoAmendCharge}</p>
													</div>
												) : polic.cancelRestricted ? (
													<div className="boxCancellation newboxCancellation bg-dangerr">
														<p>{marketPlace.CancellationRestricted}</p>{" "}
													</div>
												) : (
													<div className="boxCancellation newboxCancellation">
														<p> {marketPlace.CancellationCharge}</p>
														<p>
															{" "}
															{polic.cancelCharge} {rate?.room_price_currency}
														</p>
													</div>
												)}
											</div>
											<div className="col-md-4 mt-3 ">
												{polic.amendCharge == 0 ? (
													<div className="boxCancellation newboxCancellation bg-Ground">
														<p> {marketPlace.NoAmendCharge}</p>
													</div>
												) : polic.amendRestricted ? (
													<div className="boxCancellation newboxCancellation bg-dangerr">
														<p>{marketPlace.AmendRestricted}</p>
													</div>
												) : hotelRequest.gds != 5 && hotelRequest.gds != 6 ? (
													<div className="boxCancellation newboxCancellation">
														<p> {marketPlace.AmendmentCharge}</p>
														<p>
															{" "}
															{polic.amendCharge} {rate?.room_price_currency}
														</p>
													</div>
												) : (
													""
												)}
											</div>
										</>
									)}
								</>
							);
						})}
				</div>
			</div>
			{/* {	roomIsOpen===index&&<div className="content-item">

				<div className="cancle">
					<p>{productsBuilder.CancellationPolicies}</p>
					<span>Before: 14/2/2024 11:59 PM</span>
				</div>

				<div className="charge">
					<p>{productsBuilder.CancellationCharge}</p>
					<span>
						<span>130</span> SAR
					</span>
				</div>
				<div className="charge">
					<p>{productsBuilder.AmendmentCharge}</p>
					<span>
						<span>130</span> SAR
					</span>
				</div>
			</div>} */}
		</div>
	);
}
