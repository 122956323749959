import { createContext, useContext, useReducer } from "react";
const VisaContext = createContext();
const VisaDispatchContext = createContext();
const tokenSafaVisa = localStorage.getItem("tokenSafaVisa");
const loginData = localStorage.getItem("loginData");

const initialState = {
	tokenSafaVisa: tokenSafaVisa ?? undefined,
	loginData: loginData ?? undefined,
};

function VisaReducer(state = initialState, action) {
	switch (action.type) {
		case "getVisaToken":
			localStorage.setItem("tokenSafaVisa", action?.payload);
			return { ...state };
		case "getPrintData":
			return { ...state, printData: action?.payload };
		case "getMehremName":
			return { ...state, mehremName: action?.payload };
		case "getLoginData":
			localStorage.setItem("loginData", JSON.stringify(action?.payload));
			return { ...state, loginData: action?.payload };
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useVisaState() {
	const context = useContext(VisaContext);
	if (context === undefined) {
		throw new Error("useVisa State must be used within a Visa Provider");
	}
	return context;
}

function useVisaDispatch() {
	const context = useContext(VisaDispatchContext);
	if (context === undefined) {
		throw new Error("useVisaDispatch must be used within a VisaProvider");
	}
	return context;
}

function VisaProvider({ children }) {
	const [state, dispatch] = useReducer(VisaReducer, initialState);
	return (
		<VisaContext.Provider value={state}>
			<VisaDispatchContext.Provider value={dispatch}>
				{children}
			</VisaDispatchContext.Provider>
		</VisaContext.Provider>
	);
}

export { VisaProvider, useVisaState, useVisaDispatch };
