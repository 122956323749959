import { SORT_ITEMS_IN_CONTAINER_ACTION } from 'modules/WebBuilder-V2/interfaces/blockTypes';
import CustomLeftArrow from 'modules/WebBuilder-V2/shared/CustomLeftArrow'
import CustomRightArrow from 'modules/WebBuilder-V2/shared/CustomRightArrow'
import DragAndDropContainer from 'modules/WebBuilder-V2/shared/DragAndDropContainer'
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import ServiceCardWithButton from 'modules/WebBuilder-V2/shared/ServiceCardWithButton';
import React from 'react'
import Carousel from 'react-multi-carousel';


const toursBlocksType = "hotelBlocksType";

export default function ToursCarouselView({ items, sortToursItems, focusContainer, editItem, itemsBoxActions, openEditModal }) {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1
    }
  };

  return (
    <Carousel
      responsive={responsive}
      itemClass={"tour-slide"}
      className=""
      slidesToSlide={1}
      keyBoardControl={true}
      customRightArrow={<CustomRightArrow />}
      customLeftArrow={<CustomLeftArrow />}
      renderButtonGroupOutside={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      renderArrowsWhenDisabled={true}
      infinite={true}
    >
      {items.map((props, index) => (
        <div className="px-2" key={props.id || index}>
          <DragAndDropContainer
            accept={toursBlocksType}
            type={toursBlocksType}
            id={props.id}
            index={index}
            action={SORT_ITEMS_IN_CONTAINER_ACTION}
            moveItemInContainer={(dragIndex, hoverIndex) =>
              sortToursItems({ dragIndex, hoverIndex })
            }
          >
            <EditBox
              editMode={focusContainer === "tours"}
              actions={[
                ...editItem(props, index),
                ...itemsBoxActions(props.id),
              ]}
            >
              <ServiceCardWithButton index={index} {...props} openEditModal={openEditModal} cardType="tours" />
            </EditBox>
          </DragAndDropContainer>
        </div>
      ))}
    </Carousel>
  )
}
