import React from 'react'
import TextField from 'components/Form/TextField/TextField'
import SearchIcon from 'assets/images/crm/search.svg'
import SelectField from 'components/Form/SelectField/SelectField'
import Locale from 'translations'
import ClearIcon from 'modules/products-builder/Pricing/IconsSVG/ClearIcon'

export default function TripPlannerListFilters({ filter, setFilter, packageLookups }) {
  // hooks
  const { productsBuilder, packagePlanner, marketPlace } = Locale
  const resetFilter = () => {
    setFilter({
      product_type_id: "",
      package_name: "",
      package_num: "",
      trip_ref: "",
      page: 1,
    })
  }
  return (
    <div className='filter-wrapper'>
      <div className='filter-inputs'>
        <TextField
          type='text'
          id='search'
          name='search'
          label={productsBuilder.search}
          placeholder={packagePlanner.typePackageName}
          isImage={true}
          image={SearchIcon}
          prependImage={true}
          onChange={(e) => setFilter({ ...filter, package_name: e.target.value })}
          value={filter.package_name}
        />
      </div>

      <div className='filter-inputs'>
        <SelectField
          id="package_type"
          name="package_type"
          placeholder={productsBuilder.all}
          label={marketPlace.PackageType}
          options={packageLookups}
          value={filter?.product_type_id?.name}
          onChange={(e) => {
            setFilter({ ...filter, product_type_id: e })
          }}
        />
      </div>

      <div className='filter-inputs'>
        <TextField
          type='text'
          id="package_number"
          name="package_number"
          label={packagePlanner.packageRef}
          placeholder={packagePlanner.packageRef}
          onChange={(e) => setFilter({ ...filter, package_num: e.target.value })}
          value={filter.package_num}

        />
      </div>

      <div className='filter-inputs'>
        <TextField
          type='text'
          id="trip_ref"
          name="trip_ref"
          label={productsBuilder.tripRef}
          placeholder={productsBuilder.tripRef}
          onChange={(e) => setFilter({ ...filter, trip_ref: e.target.value })}
          value={filter.trip_ref}
        />
      </div>

      <div className='filter-inputs'>
        <button className='btn filter-clear py-2' onClick={resetFilter}>
          <ClearIcon />
          <span>{productsBuilder.clear}</span>
        </button>
      </div>
    </div>
  )
}