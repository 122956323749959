import React from 'react'
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export default function RejectPackage({isOpen,toggle,reason,setReason,rejectFn}) {
  return (
    <Modal isOpen={isOpen} className='cs-reject-package-modal'>
					<ModalHeader toggle={toggle}>
						<span className='text-capitalize'>Rejecting Package</span>
					</ModalHeader>
					<ModalBody>
						
						<h3>You are about to reject "Umrah Package"</h3>
            <p>Please fill in your reason below...</p>
							<textarea
								className=" w-100 p-3 border rounded-lg"
								placeholder="Rejection Reason"
								value={reason}
								onChange={(e) => setReason( e.target.value)}
							/>
					
						{/* action button */}
						<div className='mt-2 d-flex align-items-center justify-content-end'>
							
								<button
									className="btn btn-nxt me-2  "
									style={{ color: "#fff", backgroundColor: "#C30101" }}
									 onClick={() => rejectFn(3)}
									 disabled={!reason || reason?.length < 3}
								>
								Submit
								</button>
							
						</div>
					</ModalBody>
				</Modal>
  )
}
