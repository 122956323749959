import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import DragAndDropContainer from "modules/WebBuilder/shared/DragAndDropContainer";
import { EditBox } from "modules/WebBuilder/shared/EditBox";
import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import {
	SORT_ITEMS_IN_CONTAINER_ACTION,
	customContainerItems,
} from "../../../interfaces/blockTypes";
// import MapItem from "./MapItem";
import ParagraphItem from "./ParagraphItem";
import PhotoItem from "./PhotoItem";
import VideoItem from "./VideoItem";
import FAQs from "../FAQs";
import { useState } from "react";

function CustomContainer({ details, onFocus, focusContainer }) {
	const { selectedLanguage } = useWebBuilderState();
	const { id, body, title, items } = details;
	const [faqModal, setFaqModal] = useState(false);
	const dispatch = useWebBuilderDispatch();

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};
	const updateCustomContainer = ({ name, value, itemId, questionIndex, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				itemId,
				questionIndex,
				language
			},
		});
	};

	const sortCustomContainerItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};

	const handleFaqModal = () => {
		setFaqModal((prev) => !prev);
	};
	const itemsTypes = Object.keys(customContainerItems);

	const {
		// customContainerMap,
		customContainerParagraph,
		customContainerPhoto,
		customContainerVideo,
		customContainerFaqs,
	} = customContainerItems;

	const containerItems = {
		// [customContainerMap]: {
		// 	component: MapItem,
		// 	componentProps: {
		// 		onChange: (value, itemId) =>
		// 			updateCustomContainer({ name: "latLng", value, itemId, selectedLanguage }),
		// 	},
		// },
		[customContainerParagraph]: {
			component: ParagraphItem,
			componentProps: {
				onChange: (value, itemId, language) =>
					updateCustomContainer({ name: "body", value, itemId, language }),
			},
		},
		[customContainerPhoto]: {
			component: PhotoItem,
			componentProps: {
				onChange: (value, itemId) =>
					updateCustomContainer({ name: "url", value, itemId }),
			},
		},
		[customContainerVideo]: {
			component: VideoItem,
			componentProps: {
				onChange: (value, itemId) =>
					updateCustomContainer({ name: "url", value, itemId }),
			},
		},
		[customContainerFaqs]: {
			component: FAQs,
			componentProps: {
				onChange: ({ value, itemId, name, questionIndex, language }) =>
					updateCustomContainer({ name, value, itemId, questionIndex, language }),
				openModal: faqModal,
				handleOpenModal: handleFaqModal,
			},
		},
	};

	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={`${itemId}.trash`}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "container_item" })
				}
			>
				<TrashIcon />
			</span>,
		];
	};

	const faqActions = (itemId) => {
		return [
			<span
				key={`${itemId}.setting`}
				onClick={handleFaqModal}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};

	const containerBoxActions = [
		<span
			onClick={() => deleteBodyContent({ deleteType: "container" })}
			className="pointer-event"
		>
			<TrashIcon />
		</span>,
	];
	return (
		<EditBox editMode={focusContainer === id} actions={containerBoxActions}>
			<div
				onClick={() => onFocus(id)}
				className="web-builder-content-custom-container"
			>
				<div className="custom-container-top-section">
					<ServicesContent
						header={title}
						body={body}
						changeHeader={(value) =>
							updateCustomContainer({ value, name: "title", questionIndex: null, language: selectedLanguage })
						}
						changebody={(value) =>
							updateCustomContainer({ value, name: "body" , questionIndex: null, language: selectedLanguage})
						}
					/>
				</div>

				<div className="custom-container-detials">
					{items.map((item, index) => {
						const itemDetails = containerItems?.[item?.type];

						const Component = itemDetails?.component;
						const componentProps = itemDetails?.componentProps;
						const faqType = item.type === customContainerFaqs
						const editActions =
							faqType
								? [...itemsBoxActions(item.id), ...faqActions(item.id)]
								: itemsBoxActions(item.id);
						return (
							<DragAndDropContainer
								accept={itemsTypes}
								type={item.type}
								id={item.id}
								index={index}
								action={SORT_ITEMS_IN_CONTAINER_ACTION}
								moveItemInContainer={(dragIndex, hoverIndex) =>
									sortCustomContainerItems({ dragIndex, hoverIndex })
								}
								key={item.id}
								className={faqType&& items?.length ===1 ? "faq-fill-width":"" }
							>
								<EditBox editMode={focusContainer === id} actions={editActions}>
									<Component {...item} title={item?.title?.[selectedLanguage]} body={item?.body?.[selectedLanguage]} {...componentProps} />
								</EditBox>
							</DragAndDropContainer>
						);
					})}
				</div>
			</div>
		</EditBox>
	);
}

export default CustomContainer;
