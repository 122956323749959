import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import React from "react";
import Locale from "translations";
import { useSBSState } from "context/global";
import validate from "helpers/validate";

function PassengerDetails({
	passengersData,
	setPassengersData,
	errors,
	setErrors,
	passengerType,
	setPassengerType,
}) {
	const { inventory, marketPlace, backOffice } = Locale;

	const { allCountries: countries } = useSBSState();

	const contriesCode = countries.map((i) => {
		return {
			id: i.id,
			value: i.id,
			label: i.name.toString(),
			name: i.name.toString(),
		};
	});

	return (
		<div className="transfer-passenger-details">
			<div className="transfer-booking-details-header">
				<h2>{inventory.messages.passengerDetails}</h2>
			</div>
			<div className="border bg-white p-3 mt-3">
				<div className="col-md-12 d-flex">
					<div className="me-3">
						<input
							className="mx-1"
							type="radio"
							name="leadTrip"
							id="leadId"
							value="lead"
							checked={passengerType === "lead"}
							onChange={(e) => {
								setPassengerType(e.target.value);
							}}
						/>
						<label className="text-center " htmlFor="leadId">
							{marketPlace.messages.leadPassengerData}
						</label>
					</div>
					<div className="mx-1">
						<input
							className="mx-1"
							type="radio"
							name="allOnly"
							id="allId"
							value="all"
							checked={passengerType === "all"}
							onChange={(e) => {
								setPassengerType(e.target.value);
							}}
						/>
						<label className="text-center " htmlFor="allId">
							{marketPlace.messages.allPassengersData}
						</label>
					</div>
				</div>

				<div className="mt-3">
					<p className="text-dark-blue">{marketPlace.messages.leadPassenger}</p>

					<div className="mt-3 row m-0">
						<div className="col-md-3 col-6">
							<TextField
								type="text"
								hasLabel={true}
								placeholder={marketPlace.messages.enterName}
								label={marketPlace.messages.nameAdult + "*"}
								value={passengersData.leadName}
								onChange={(e) => {
									setPassengersData((prev) => ({
										...prev,
										leadName: e.target.value,
									}));
									setErrors({
										...errors,
										...validate(
											{ name: "leadName", value: e.target.value },
											{ required: true }
										),
									});
								}}
								errors={errors?.leadName}
								color={errors?.leadName?.required ? "danger" : ""}
							/>
						</div>
						<div className="col-md-3 col-6">
							<TextField
								type="text"
								hasLabel={true}
								placeholder={marketPlace.messages.enterSurname}
								label={marketPlace.messages.surnameAdult + "*"}
								value={passengersData.leadSurname}
								onChange={(e) => {
									setPassengersData((prev) => ({
										...prev,
										leadSurname: e.target.value,
									}));
									setErrors({
										...errors,
										...validate(
											{ name: "leadSurname", value: e.target.value },
											{ required: true }
										),
									});
								}}
								errors={errors?.leadSurname}
								color={errors?.leadSurname?.required ? "danger" : ""}
							/>
						</div>
					</div>
				</div>
				{passengerType === "all" && (
					<div className="mt-3">
						{passengersData.allPassengers.length  ? (
							<p className="text-dark-blue">
								{marketPlace.messages.otherPassenger}
							</p>
						):null}

						{passengersData.allPassengers.map((i, index) => (
								<div className="mt-3 row m-0">
									<div className="col-md-3 col-6">
										<TextField
											type="text"
											hasLabel={true}
											placeholder={marketPlace.messages.enterName}
											label={marketPlace.messages.nameAdult + "*"}
											value={passengersData?.allPassengers?.[index]?.name}
											onChange={(e) => {
												const dataClone = [...passengersData.allPassengers];
												dataClone[index].name = e.target.value;
												setPassengersData((prev) => ({
													...prev,
													allPassengers: dataClone,
												}));

												//  error handler
												const errorClone = errors?.all ? [...errors?.all] : [];
												errorClone[index] = {
													...validate(
														{ name: "name", value: e.target.value },
														{ required: true }
													),
												};
												setErrors({
													...errors,
													all: errorClone,
												});
											}}
											errors={errors?.all?.[index]?.name}
											color={
												errors?.all?.[index]?.name?.required ? "danger" : ""
											}
										/>
									</div>
									<div className="col-md-3 col-6">
										<TextField
											type="text"
											hasLabel={true}
											placeholder={marketPlace.messages.enterSurname}
											label={marketPlace.messages.surnameAdult + "*"}
											value={passengersData?.allPassengers?.[index]?.surname}
											onChange={(e) => {
												// data handleer
												const dataClone = [...passengersData.allPassengers];
												dataClone[index].surname = e.target.value;
												setPassengersData((prev) => ({
													...prev,
													allPassengers: dataClone,
												}));

												//  error handler
												const errorClone = errors?.all ? [...errors?.all] : [];
												errorClone[index] = {
													...validate(
														{ name: "surname", value: e.target.value },
														{ required: true }
													),
												};
												setErrors({
													...errors,
													all: errorClone,
												});
											}}
											errors={errors?.all?.[index]?.surname}
											color={
												errors?.all?.[index]?.surname?.required ? "danger" : ""
											}
										/>
									</div>
								</div>
							))}
					</div>
				)}

				<div className="mt-3">
					<p className="text-dark-blue">
						{marketPlace.messages.travellerContactDetails}
					</p>
					<small>{marketPlace.messages.validPhoneNumber}</small>

					<div className="mt-3 row m-0">
						<div className="col-md-6 col-md-8 col-12 row me-1">
							<div className="col-12 px-2">
								<label className="m-0 w-100" htmlFor="">
									{marketPlace.messages.mobileCompulsory}
								</label>
							</div>
							<div className="col-md-4 col-6">
								<SelectField
									hasLabel={false}
									options={contriesCode}
									placeholder={marketPlace.messages.countryCode}
									label={marketPlace.messages.countryCode}
									name="adult"
									value={passengersData?.countryCode?.label}
									onChange={(e) => {
										setPassengersData((prev) => ({
											...prev,
											countryCode: e,
										}));
										setErrors({
											...errors,
											...validate(
												{ name: "countryCode", value: e.value },
												{ required: true }
											),
										});
									}}
									errors={errors?.countryCode}
									color={errors?.countryCode?.required ? "danger" : ""}
								/>
							</div>
							<div className="col-md-8 col-6">
								<TextField
									type="number"
									hasLabel={false}
									placeholder={marketPlace.messages.mobileNo}
									label={marketPlace.messages.mobileNo}
									value={passengersData.mobile}
									onChange={(e) => {
										setPassengersData((prev) => ({
											...prev,
											mobile: e.target.value,
										}));
										setErrors({
											...errors,
											...validate(
												{ name: "mobile", value: e.target.value },
												{ required: true, min: 9, max: 13 }
											),
										});
									}}
									min={9}
									max={13}
									errors={errors?.mobile}
									color={
										errors?.mobile?.required ||
										errors?.mobile?.min ||
										errors?.mobile?.max
											? "danger"
											: ""
									}
								/>
							</div>
						</div>

						<div className="col-md-4 col-6">
							<TextField
								type="text"
								hasLabel={true}
								placeholder={marketPlace.messages.enterEmail}
								label={backOffice.email}
								value={passengersData.email}
								onChange={(e) => {
									setPassengersData((prev) => ({
										...prev,
										email: e.target.value,
									}));
									setErrors({
										...errors,
										...validate(
											{ name: "email", value: e.target.value },
											{ email: true }
										),
									});
								}}
								errors={errors?.email}
								color={errors?.email?.email ? "danger" : ""}
							/>
						</div>
					</div>
				</div>

				<div className="mt-3">
					<p className="text-dark-blue">{inventory.messages.bookingDetails}</p>

					<div className="mt-3 row m-0">
						<div className="col-8">
							<TextField
								type="text"
								hasLabel={true}
								label={marketPlace.messages.agencyReference}
								value={passengersData.agencyReference}
								onChange={(e) => {
									setPassengersData((prev) => ({
										...prev,
										agencyReference: e.target.value,
									}));
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PassengerDetails;
