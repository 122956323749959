import React, { createContext, useContext, useReducer } from "react";
const TeamManagementContext = createContext();
const TeamManagementDispatchContext = createContext();
const initialState = {};

function teamManagementReducer(state, action) {
	switch (action.type) {
		case "members": {
			return {
				...state,
				members: action.payload,
			};
		}
		case "teams": {
			return {
				...state,
				teams: action.payload,
			};
		}
		case "branches": {
			return {
				...state,
				branches: action.payload,
			};
		}
		case "branchesLookup": {
			return {
				...state,
				branchesLookup: action.payload,
			};
		}
		case "currentPage":
			return { ...state, currentPage: action.payload };

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useTeamManagementState() {
	const context = useContext(TeamManagementContext);
	if (context === undefined) {
		throw new Error(
			"useTeamManagementState must be used within a TeamManagementProvider"
		);
	}
	return context;
}

function useTeamManagementDispatch() {
	const context = useContext(TeamManagementDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useTeamManagementDispatch must be used within a TeamManagementProvider"
		);
	}
	return context;
}

function TeamManagementProvider({ children }) {
	const [state, dispatch] = useReducer(teamManagementReducer, initialState);
	return (
		<TeamManagementContext.Provider value={state}>
			<TeamManagementDispatchContext.Provider value={dispatch}>
				{children}
			</TeamManagementDispatchContext.Provider>
		</TeamManagementContext.Provider>
	);
}

export {
	TeamManagementProvider,
	useTeamManagementState,
	useTeamManagementDispatch,
};
