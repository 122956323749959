import React from "react";

export default function WalletCurrenciesPagination() {
	let url = "";
	return (
		<div className="d-flex flex-row-reverse p-3">
			<nav aria-label="Page navigation example">
				<ul className="pagination">
					<li className="page-item">
						<a className="page-link mr-2" href="#" aria-label="Previous">
							<span aria-hidden="true" className="p-0">
								<i className="icx icx-left-solid-arrow"></i>
							</span>
							<span className="sr-only">Previous</span>
						</a>
					</li>
					<li className="page-item">
						<a className="page-link" href={url}>
							1
						</a>
					</li>
					<li className="page-item">
						<a className="page-link" href={url}>
							2
						</a>
					</li>
					<li className="page-item">
						<a className="page-link" href={url}>
							3
						</a>
					</li>
					<li className="page-item">
						<a className="page-link" href={url}>
							4
						</a>
					</li>
					<li className="page-item ml-2">
						<a className="page-link" href={url} aria-label="Next">
							<span aria-hidden="true" className="p-0">
								<i className="icx icx-right-solid-arrow"></i>
							</span>
							<span className="sr-only">Next</span>
						</a>
					</li>
				</ul>
			</nav>
		</div>
	);
}
