import React from "react";
import SelectField from "components/Form/SelectField/SelectField";


export default function AdditionalServiceAccommodation(props) {


	return (
		<>
			<div className="additional-services py-3 ">
				<h2 className="head">Additional Service</h2>
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-md-3">
						<SelectField
							label={"Extra Items"}
							defaultValue="Select Extra Items"
						></SelectField>
					</div>
					<div className="col-md-3">
						<SelectField
							label={"Transportation conditions"}
							defaultValue="Select Visa Type"
						/>
					</div>
					<div className="col-md-3">
						<SelectField
							label={"accommodation conditions"}
							defaultValue="Select Accomodation option"
						/>
					</div>
				</div>
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-md-4">
						<ul className="extra-item">
							<li>
								<p className="title">{"Extra Item"} 1</p>
								<p className="price">200 EGP</p>
							</li>
							<li>
								<p className="title">{"Extra Item"} 2</p>
								<p className="price">400 EGP</p>
							</li>
							<li>
								<p className="title">{"Extra Item"} 3</p>
								<p className="price">600 EGP</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}
