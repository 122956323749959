import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { SORT_ITEMS_IN_CONTAINER_ACTION } from "modules/WebBuilder-V2/interfaces/blockTypes";
import { AddElement } from "modules/WebBuilder-V2/shared/AddElement";
import AddService from "modules/WebBuilder-V2/shared/AddService";
import DragAndDropContainer from "modules/WebBuilder-V2/shared/DragAndDropContainer";
import { EditBox } from "modules/WebBuilder-V2/shared/EditBox";
import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import { useCallback, useMemo, useState } from "react";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import PackageCardwithImage from "./PackageCardwithImage";
import Locale from "translations";

const packagesBlocksType = "packagesBlocksType";

function OurPackagesWithImage({ details, onFocus, focusContainer }) {
	const { webBuilder } = Locale;
	const { selectedLanguage } = useWebBuilderState();
	let { id, title, body, items } = details;
	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);
	const dispatch = useWebBuilderDispatch();

	
	items  = items?.filter((i) => i !== null && i !== undefined)

	const handleOpenAddModal = useCallback(() => {
		setOpenAdd((prev) => !prev);
		setUpdateItem(null);
	}, []);

	const openEditModal = (item) => {
		handleOpenAddModal();
		setUpdateItem(item);
	};

	const editItem = (item, index) => {
		return [
			<span
				key={`${item.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};

	const addItemToPackages = useCallback(
		(item) => {
			updateItem
				? dispatch({
						type: "editItemInContainer",
						payload: {
							id,
							item,
							itemIndex: updateItem.index,
						},
				  })
				: dispatch({
						type: "addItemToContainer",
						payload: {
							id,
							item,
						},
				  });
			setUpdateItem(null);
		},
		[dispatch, id, updateItem]
	);

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};

	const sortPackagesItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};
	const updatePackages = ({ name, value, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				language,
			},
		});
	};
	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={itemId}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "container_item" })
				}
				className="pointer-event"
			>
				<TrashIcon />
			</span>,
		];
	};

	const containerBoxActions = [
		// <span
		// 	onClick={() => deleteBodyContent({ deleteType: "container" })}
		// 	className="pointer-event"
		// >
		// 	<TrashIcon />
		// </span>,
	];

	const selectedIds = useMemo(
		() => (items ? items.map((i) => i?.id) : []),
		[items]
	);

	console.log(items);

	return (
		<EditBox editMode={focusContainer === id} actions={containerBoxActions}>
			<div
				className="web-builder-content-our-packages-with-image container"
				onClick={() => onFocus(id)}
				id={id}
			>
				<div className="service-top-section">
					<ServicesContent
						header={title}
						body={body}
						changeHeader={(value) =>
							updatePackages({
								name: "title",
								value,
								language: selectedLanguage,
							})
						}
						changebody={(value) =>
							updatePackages({
								name: "body",
								value,
								language: selectedLanguage,
							})
						}
					/>

					<AddElement
						onClick={handleOpenAddModal}
						title={webBuilder.addMorePackages}
						className="add-element-box-packages"
					/>
				</div>

				{items.length > 0 && (
					<div className="all-web-builder-our-service-images">
						{items.map((props, index) => (
							<DragAndDropContainer
								accept={packagesBlocksType}
								type={packagesBlocksType}
								id={props.id}
								index={index}
								action={SORT_ITEMS_IN_CONTAINER_ACTION}
								moveItemInContainer={(dragIndex, hoverIndex) =>
									sortPackagesItems({ dragIndex, hoverIndex })
								}
								key={props.id}
								className="our-service-item"
							>
								<EditBox
									editMode={focusContainer === id}
									actions={[
										...editItem(props, index),
										...itemsBoxActions(props.id),
									]}
								>
									<PackageCardwithImage
										{...props}
										key={index}
										openEditModal={openEditModal}
									/>
								</EditBox>
							</DragAndDropContainer>
						))}
					</div>
				)}
				{/* <div className="my-2 our-packages-detials">
				</div> */}
				{openAdd && (
					<AddService
						handleModalState={handleOpenAddModal}
						open={openAdd}
						label="packages"
						header={"packages"}
						onAdd={addItemToPackages}
						details={updateItem}
						selectedIds={selectedIds}
					/>
				)}
			</div>
		</EditBox>
	);
}

export default OurPackagesWithImage;
