import OrdersViolationsList from 'modules/UmrahOperations/OrdersViolations/List'
import React, { useEffect, useState } from 'react'
import { fetchOrdersViolations } from 'services/operationStatement';
import { useParams } from 'react-router-dom';
import Pagination from 'components/Pagination';

export default function ViewAgentViolations() {

  const { id } = useParams();
  const [ordersViolations, setOrdersViolations] = useState({
    list: [],
    meta: {},
  });


  async function getOrderViolations(params) {
    const res = await fetchOrdersViolations(params);
    setOrdersViolations({
      list: res.data?.data,
      meta: res?.data?.meta
    });
  };

  function goTo(page) {
    getOrderViolations({ page, agent_id: id });
  };


  useEffect(() => {
    if (id) {
      getOrderViolations({ agent_id: id });
    }
  }, [id]);



  return (
    <div className="operation-statement-container">
      <OrdersViolationsList
        violationsList={ordersViolations.list}
        showAgentViolations={false}
      />

      <div className="py-3">
        <Pagination info={ordersViolations.meta} goTo={goTo} />
      </div>

    </div>
  )
}
