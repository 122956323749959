import DateTimePickerField from "components/Form/DateTimePickerField/DateTimePickerField";
import React from "react";
import WathcIconsSrc from "assets/images/umrah-operations/watch-icon.svg";
import { ClockTimeIcon } from "modules/UmrahOperations/shared/Icons";
import Locale from 'translations';
import moment from 'moment';

export default function MovementDuraion({ state, setsettingsState }) {
	const { operationStatement } = Locale;

	const data = state?.city_durations;
	const today = new Date().toLocaleDateString();

	return (
		<div className="item-row w-100 movement-row">
			<h4 className="header-title">
				<ClockTimeIcon />
				<span className="mx-3">
					{operationStatement.Durationofthebus}
					
				</span>
			</h4>
			<div className="item-content">
				<div className="header-tabs-move header-tabs ">
					<div className="tab tab-65">
						<label htmlFor="">{operationStatement.Movement}</label>
					</div>
					<div className="tab tab-30">
						<label htmlFor="">{operationStatement.Duration}</label>
					</div>
				</div>

				<div>
					<div className="content-tabs-move content-tabs">
						<div className="all-label-data">
							<div className="label-data tab ">
								<p>{operationStatement.From}</p>
								<h1> {operationStatement.Makkahhotel}</h1>
							</div>
							<div className="label-data tab ">
								<p>{operationStatement.From}</p>
								<h1> {operationStatement.Madinahhotel}</h1>
							</div>
						</div>

						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="movement_dur_mak_mad"
									id="movement_dur_mak_mad"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) =>
												item.from_city_name === "Makkah" &&
												item.to_city_name == "Madinah"
										)?.duration
									}
									onChangeDate={(time) => {
										let clone = data?.find(
											(item) =>
												item.from_city_name === "Makkah" &&
												item.to_city_name == "Madinah"
										);
										clone.duration = time;
										setsettingsState({ ...state });
									}}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												let clone = data?.find(
													(item) =>
														item.from_city_name === "Makkah" &&
														item.to_city_name == "Madinah"
												);
												clone.duration = moment(`${today} ${"00:00"}`).toDate();
												setsettingsState({ ...state });
											}
										}}

								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
					</div>
					<div className="content-tabs-move content-tabs">
						<div className="all-label-data">
							<div className="label-data tab ">
								<p>{operationStatement.From}</p>
								<h1> {operationStatement.Makkahhotel}</h1>
							</div>
							<div className="label-data tab ">
								<p>{operationStatement.From}</p>
								<h1> {operationStatement.Jaddahhotel}</h1>
							</div>
						</div>

						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
						open={false}
									name="movement_dur_mak_jed"
									id="movement_dur_mak_jed"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) =>
												item.from_city_name === "Makkah" &&
												item.to_city_name == "Jeddah"
										)?.duration
									}
									onChangeDate={(time) => {
										let clone = data?.find(
											(item) =>
												item.from_city_name === "Makkah" &&
												item.to_city_name == "Jeddah"
										);
										clone.duration = time;
										setsettingsState({ ...state });
									}}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												let clone = data?.find(
													(item) =>
													item.from_city_name === "Makkah" &&
													item.to_city_name == "Jeddah"
												);
												clone.duration = moment(`${today} ${"00:00"}`).toDate();
												setsettingsState({ ...state });
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
					</div>
					<div className="content-tabs-move content-tabs">
						<div className="all-label-data">
							<div className="label-data tab ">
								<p>{operationStatement.From}</p>
								<h1> {operationStatement.Madinahhotel}</h1>
							</div>
							<div className="label-data tab ">
								<p>{operationStatement.From}</p>
								<h1> {operationStatement.Jaddahhotel}</h1>
							</div>
						</div>

						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
open={false}
									name="movement_dur_mad_jed"
									id="movement_dur_mad_jed"
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									date={
										data?.find(
											(item) =>
												item.from_city_name === "Madinah" &&
												item.to_city_name == "Jeddah"
										)?.duration
									}
									onChangeDate={(time) => {
										let clone = data?.find(
											(item) =>
												item.from_city_name === "Madinah" &&
												item.to_city_name == "Jeddah"
										);
										clone.duration = time;
										setsettingsState({ ...state });
									}}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if(e.keyCode==32 || e.keyCode==8){
												let clone = data?.find(
													(item) =>
													item.from_city_name === "Madinah" &&
													item.to_city_name == "Jeddah"
												);
												clone.duration = moment(`${today} ${"00:00"}`).toDate();
												setsettingsState({ ...state });
											}
										}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
