import React, { memo } from "react";
import { ReactComponent as DraghIcon } from "assets/images/hotelAccommodation/drag.svg";
import { ReactComponent as MaleIcon } from "assets/images/hotelAccommodation/male.svg";
import { ReactComponent as FemaleIcon } from "assets/images/hotelAccommodation/female.svg";
import RoomTypeBadge from "../shared/RoomTypeBadge";
import { useDrag } from "react-dnd";
import { ROOMS_TYPES } from "../helpers/RoomsTypes";
import { useProductsBuilderState } from "context/productsBuilder";
import Locale from "translations";

function TravelerDetails({ item }) {
	const { marketPlace } = Locale;

	const { selected_hotel_accommodation_id: selectedHotelId } =
		useProductsBuilderState();

	const roomType = item.roomAvailabilities.find(
		(room) => room.product_hotel_id === selectedHotelId
	)?.room_type;


	// const [{ opacity }, drag] = useDrag(
	// 	() => ({
	// 		type: ROOMS_TYPES[roomType],
	// 		item: { id: item.id, type: item.is_adult ? "adult" : "child" },
	// 		collect: (monitor) => ({
	// 			opacity: monitor.isDragging() ? 0.4 : 1,
	// 		}),
	// 	}),
	// 	[item, selectedHotelId]
	// );

	return (
		<div
			className="traveler-details"
			// ref={drag}
			// style={{ opacity }}
			data-testid="box"
		>
			<DraghIcon className="drag-icon" />
			{item.gender === "M" ? (
				<MaleIcon className="person-icon" />
			) : (
				<FemaleIcon className="person-icon" />
			)}
			{console.log("itemitemitem", item)}
			<div className="traveler-type">
			<p className="traveler-name">
						{`${item.first_name} ${item.last_name}`}{" "}
					</p>
				<div className="d-flex justify-content-between w-100">
					<span className="person-type">
						{item.is_adult ? marketPlace.adult : marketPlace.children}
					</span>
					<span className="person-type mx-2">
						{item.gender == "M" ? "Male" : "Female"}{" "}
					</span>
					<RoomTypeBadge roomType={roomType} />
				</div>
				<div className="d-flex justify-content-between w-100">
					<span className="person-type">{item?.reference_num}</span>
					<span className="person">
						{item?.reservation_category }{" "}
					</span>
				</div>
			</div>
		</div>
	);
}

export default memo(TravelerDetails);
