import React from "react";
import EditableParagraph from "./EditableParagraph";

function ServicesContent({ header, body, changeHeader, changebody }) {
	return (
		<div className="services-content">
			<EditableParagraph
				className="service-header h4"
				contentEditable={true}
				onInput={(value) => {
					changeHeader(value);
				}}
				text={header}
							
				/>
				
			<EditableParagraph
				className="service-body"
				contentEditable={true}
				onInput={(value) => {
          changebody(value);
				}}
				text={body}
							
				/>
		</div>
	);
}

export default ServicesContent;
