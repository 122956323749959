import { TransportationIcon } from 'modules/UmrahOperations/shared/Icons'
import { Collapse } from 'reactstrap'
import { ReactComponent as EditIcon } from "assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import { useState } from 'react';
import moment from 'moment';
import Locale from 'translations';


export default function ItineraryTransportationView({ item, setCurrentView, removeItem, canEdit }) {
  const { companyDetails } = Locale;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="itinerary-item-view-wrapper">
      <div className="item-view">
        <div className="item-info" onClick={() => setIsOpen(!isOpen)}>
          <span className="trip-planner-icon-wrapper transportation">
            <TransportationIcon color="#EA5455" />
          </span>
          <p className="d-flex flex-column w-50">
            <span>{item?.from?.name}</span>
            <span className="secondry-text">
              {item?.pickup_time ? moment(item?.pickup_time).format("hh:mm A") : ""} - {item?.pickup_point}
            </span>
          </p>
          <span> - </span>
          <p className="d-flex flex-column w-50">
            <span>{item?.to?.name}</span>
            <span className="secondry-text">
              {item?.drop_off_time ? moment(item?.drop_off_time).format("hh:mm A") : ""} - {item?.drop_off_point}
            </span>
          </p>

          {/* arrow */}
          {isOpen
            ? <i className="fas fa-chevron-up collapse-arrow"></i>
            : <i className="fas fa-chevron-down collapse-arrow"></i>
          }
        </div>
        {canEdit ?
          <div className="item-action-btns">
            <button className="btn shadow-none edit-btn" onClick={() => setCurrentView("form")}>
              <EditIcon />
            </button>
            <button className="btn shadow-none delete-btn" onClick={() => removeItem()}>
              <DeleteIcon />
            </button>
          </div>
          :
          null
        }

      </div>


      <Collapse isOpen={isOpen}>
        <div className="item-data">

          <div className="d-flex align-items-center">
            <span className="trip-planner-icon-wrapper transportation">
              <TransportationIcon color="#EA5455" />
            </span>
            <p className="d-flex flex-column mx-2">
              <span className="duration">{item?.pickup_time ? moment(item?.pickup_time).format("hh:mm A") : ""}</span>
              <span className="mt-1">{item?.from?.name}</span>
            </p>
          </div>

          <div className="d-flex flex-column align-items-center align-self-center text-secondary text-center">
            <span>{item?.carrier_name?.name}</span>
            <svg width="119" height="8" viewBox="0 0 119 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M118.854 4.35355C119.049 4.15829 119.049 3.84171 118.854 3.64645L115.672 0.464466C115.476 0.269203 115.16 0.269203 114.964 0.464466C114.769 0.659728 114.769 0.97631 114.964 1.17157L117.793 4L114.964 6.82843C114.769 7.02369 114.769 7.34027 114.964 7.53553C115.16 7.7308 115.476 7.7308 115.672 7.53553L118.854 4.35355ZM10.5 3.49998C10.2239 3.49998 10 3.72384 10 3.99998C10 4.27613 10.2239 4.49998 10.5 4.49998L10.5 3.49998ZM118.5 3.5L10.5 3.49998L10.5 4.49998L118.5 4.5L118.5 3.5Z" fill="#707170" />
            </svg>
          </div>

          <div className="d-flex align-items-center">
            <span className="trip-planner-icon-wrapper transportation">
              <TransportationIcon color="#EA5455" />
            </span>
            <p className="d-flex flex-column mx-2">
              <span className="duration">{item?.drop_off_time ? moment(item?.drop_off_time).format("hh:mm A") : ""}</span>
              <span className="mt-1">{item?.to?.name}</span>
            </p>
          </div>
        </div>
        {item?.description &&
          <div className="px-3 mb-3">
            <p className="mt-3 font-weight-bold">{companyDetails.description}:</p>
            <p className="secondry-text">
              {item?.description}
            </p>
          </div>
        }
      </Collapse>
    </div>
  )
}
