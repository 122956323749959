import bankImg from "assets/images/wallet/bankImg.png";
import creditImg from "assets/images/wallet/payment.svg";
import TextFieldWithSelect from "components/Form/TextFieldWithSelect/TextFieldWithSelect";
import { useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { payByCredit } from "services/wallet";
import Locale from "translations";
import validator from "validator";
import CurrentBalance from "../CurrentBalance";
import WalletBoxs from "../walletBoxs";
import useWallet from "./useWallet";

export default function CreditCard(props) {
	const history = useHistory();
	const { payment } = Locale;
	const [image, setImage] = useState();
	const handleUpload = (e) => {
		const files = e.target.files;
		setImage(files[0]);
	};
	const [amount, setAmount] = useState(null);
	const { fetchWallets } = useWallet();
	const submit = () => {
		if (validator.isNumeric(`${amount}`, ["ar-EG"])) {
			AddCredit();
		} else {
			store.addNotification({
				title: "info!",
				message: "Amount should be Number",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	async function AddCredit() {
		const formData = new FormData();
		formData.append("amount", amount);
		formData.append("photo", image?.name);

		///{ amount: amount, photo: image?.name }
		const res = await payByCredit(formData);
		if (res.status == 200 || res.status == 201) {
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setTimeout(() => {
				history.push("/payment-successful");
				fetchWallets();
			}, 200);
		}
	}

	return (
		<>
			<div className="wallet-wrraper">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-3">
							<CurrentBalance />
						</div>
						<WalletBoxs />
						{/* <div className="col-md-4 text-center">
							<Summary />
						</div> */}
					</div>

					{/* <RequestBank /> */}
					<div className="my-5 d-hiden">0</div>

					<h6 className="credit-card-head my-5">
						{payment.messages.walletByCreditCard}
					</h6>

					<div className="credit-card-section">
						<div className="credit-payment">
							<div className="payment-img-box">
								<img src={creditImg} alt="img" />
							</div>

							<div className="d-flex px-2">
								<div className="w-100">
									<div className="form-group full-input w-100">
										<TextFieldWithSelect
											label={payment.messages.amount}
											placeholder={payment.messages.amount}
											// selectPosition="append"
											// feedbackMessage={
											// 	payment.validation.receiptAmountValidationMessage
											// }
											value={amount}
											onChange={(e) => setAmount(e.target.value)}
										//onBlur={validateAmountField}
										//	color={formValidation.amount ? "" : "danger"}
										//isInvalid={!formValidation.amount}
										>
											<option value="ar">EGP</option>
											<option value="en">SAR</option>
										</TextFieldWithSelect>

										<div className="bg-white p-2 text-center  position-relative upload-images w-100 mt-3">
											<p className="text-left">{"Upload"}</p>
											<p className="text-left text-gray">
												{image && image.name}
											</p>
											<button className="chose">{"choose Image"}</button>
											<input
												onChange={handleUpload}
												type="file"
												id="uploadFile"
												accept="image/*"
											/>
										</div>

										<p className="text-left text-caption">
											{payment.messages.agreeTo}
											<a href="#" className="text-primary">
												{payment.messages.privatePolicy}
											</a>
										</p>
									</div>
								</div>
							</div>

							<div className="img-bank-box ">
								<img src={bankImg} alt="Image" />
							</div>
						</div>

						<div className="credit-buttons">
							<div>
								<i className="fas fa-caret-left pr-1"></i>
								<a onClick={() => history.push("/wallet")} id="backLogin">
									{payment.messages.back}
								</a>
							</div>
							<button className="btn btn-primary submit-btn" onClick={submit}>
								{payment.messages.payNow}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
