import paymentSuccess from "assets/images/wallet/tick.svg";
import React from "react";
import { useHistory } from "react-router-dom";
import Locale from "translations";

export default function PaymentSuccessful(props) {
	const history = useHistory();
	const { payment } = Locale;
	// useEffect(() => {
	// 	window.location.reload();
	// }, []);

	return (
		<>
			<div className="payment-successful">
				<img src={paymentSuccess} alt="ImageSuccess" className="pb-3" />
				<h6 className="text-success mb-1">
					{payment.messages.paymentSuccessful}
				</h6>
				{/* <p>{payment.messages.transactionNumber}: #DF56677</p> */}
				<button
					className="btn btn-primary px-5 mb-3 my-2"
					onClick={() => history.push("/wallet")}
				>
					{payment.messages.backToWallet}
				</button>
			</div>
		</>
	);
}
