import SearchFilghtForm from "./SearchFilghtForm";

export default function Flights() {
	//Locale

	return (
		<>
			<SearchFilghtForm />
		</>
	);
}
