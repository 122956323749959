import myWalletImg from "assets/images/dashboard/3.svg";
import { useMarketplaceState } from "context/marketplace";
import moment from 'moment';
import { useState } from "react";
import { useParams } from "react-router-dom";
import Locale from "translations";
import GroundSearch from "../GroundSearch";
import CustomModal from "./Modal";

export default function ListHeader() {
	const { marketPlace, onlineVisa, inventory } = Locale;
	const [openModal, setOpenModal] = useState(false);
	const { groundServiceSearch } = useMarketplaceState();

	const toggleModel = () => {
		setOpenModal(!openModal);
	};
	const { id } = useParams();
	return (
		<>
			<div className="rounded row m-0  justify-content-between bg-white border p-0">
				<div className="rounded m-0 row col-10  justify-content-between align-items-center  p-0">
					<div className="p-5 bg-gray text-center">
						<p className="m-0 font-weight-bold">GROUND</p>
						<p className="m-0 font-weight-bold"> DETAILS</p>
					</div>
					{/* left col */}
					<div className="row col-5 ">
						<div className="col-5  txt-blue">
							<p className="m-0 ">ID</p>
							<p className="m-0 ">{onlineVisa.Destination}</p>
							<p className="m-0 ">{inventory.messages.serviceType}</p>
							<p className="m-0 ">{onlineVisa.Pax}</p>
						</div>

						<div className="col-7 ">
							<p className="m-0">{id}</p>
							<p className="m-0">{groundServiceSearch?.destination}</p>
							<p className="m-0">{groundServiceSearch?.service_type ? groundServiceSearch?.service_type : "-"} </p>
							<p className="m-0">{groundServiceSearch?.pax}</p>
						</div>
					</div>
					{/* right col */}
					<div className="row col-5 ">
						<div className="col-6  txt-blue font-weight-bold">
							<p className="m-0 ">{onlineVisa.Departure}</p>
							<p className="m-0 ">{onlineVisa.return}</p>
							<p className="m-0 ">{onlineVisa.RequestDate}</p>
							<p className="m-0 ">{marketPlace.messages.requestExpireDate}</p>
							{/* <p className="m-0 ">{onlineVisa.GroundPriceOne}</p> */}
							<p className="m-0 ">{onlineVisa.Status}</p>
						</div>

						<div className="col-6">
							<p className="m-0">
								{groundServiceSearch?.departure_date ? moment(groundServiceSearch?.departure_date).format("DD/MM/YYYY") : "-"}
							</p>
							<p className="m-0">
								{groundServiceSearch?.return_date ? moment(groundServiceSearch?.return_date).format("DD/MM/YYYY") : "-"}
							</p>
							<p className="m-0 ">
								{moment(groundServiceSearch?.requestDate).format("DD/MM/YYYY")}
							</p>
							<p className="m-0 text-danger">
								{moment(groundServiceSearch?.expiration_date).format("DD/MM/YYYY")}
							</p>
							<p className={`m-0 font-weight-normal text-capitalize text-${groundServiceSearch?.status === "waiting" ? "waiting" :
								groundServiceSearch?.status === "incomplete" || groundServiceSearch?.status === "expired" ? "danger" :
									groundServiceSearch?.status === 'pending' ? 'pending' : 'issued'
								}`}
							>
								{groundServiceSearch.status ? groundServiceSearch.status : "-"}
							</p>
						</div>
					</div>

				</div>

				<div className="d-flex justify-content-between align-items-end col-2 p-0 flex-column">
					<div className="d-flex  bg-gray px-4 text-center rounded justify-content-center h-100 align-items-center">
						<img src={myWalletImg} alt="" className="online-vise-img" />
						<div className="mx-3">
							<p className="m-0 txt-blue">{onlineVisa.TotalPrice}</p>
							<p className="m-0 text-success font-weight-bold">
								{groundServiceSearch?.residence?.currency_code?.toLocaleUpperCase()}{" "}
								{groundServiceSearch?.residence?.price * +groundServiceSearch?.pax}{" "}
							</p>
						</div>
					</div>
				</div>
			</div>

			<CustomModal
				modalIsOpen={openModal}
				header={onlineVisa.EditPax}
				toggle={toggleModel}
				size={"md"}
				centered={true}
			>
				<GroundSearch isMarketSearch={false} toggleModel={toggleModel} />
			</CustomModal>
		</>
	);
}
