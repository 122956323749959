import axios from "axios";
import { cleanEmpty } from "./general";

const walletURL = process.env.REACT_APP_API_URL + "/v1/payment-card";
const walletBackOfficeURL = process.env.REACT_APP_API_URL + "/v1";

export const getTransactions = async (filter) => {
	return await axios
		.get(`${walletURL}/transactions`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const getRequests = async (filter) => {
	return await axios
		.get(`${walletURL}/transaction-requests`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
//add balance
export const payByCredit = async (data) => {
	return await axios
		.post(`${walletURL}/transaction-requests`, data) //pay-by-credit
		.then((res) => res)
		.catch((err) => err.response);
};
//bank transfer
export const payByDebit = async (data) => {
	return await axios
		.post(`${walletURL}/pay-credit-by-debit`, data) //pay-by-debit
		.then((res) => res)
		.catch((err) => err.response);
};
export const getCredit = async () => {
	return await axios
		.get(`${walletURL}/get-credit`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getDebit = async () => {
	
	return await axios
		.get(`${walletURL}/get-debit`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const test = async () => {
	
	return await axios
		.get(`${walletURL}/get-debit`)
		.then((res) => res)
		.catch((err) => err.response);
};

/**************Api New Wallet******************* */
//list Wallets

export const getListWallets = async (data = {}) => {
	return await axios
		.get(`${walletURL}/manage-wallets`, {
			params: cleanEmpty(data),
		})
		.then((res) => res)
		.catch((err) => err.response);
};


export const getPrebookListWallets = async (data = {}) => {
	return await axios
		.get(`${walletURL}/prebook-manage-wallets`, {
			params: cleanEmpty(data),
		})
		.then((res) => res)
		.catch((err) => err);
};
//pay from safa wallet
export const payFromSafa = async (data) => {
	return await axios
		.post(`${walletURL}/pay-credit-by-debit`, data) //pay-by-debit
		.then((res) => res)
		.catch((err) => err.response);
};
//Bank transfer
export const payFromBankTransfer = async (data) => {
	return await axios
		.post(`${walletURL}/transaction-requests`, data) //pay-by-credit
		.then((res) => res)
		.catch((err) => err.response);
};
//**** */
export const getWalletTransactions = async (id, filter = {}) => {
	return await axios
		.get(`${walletURL}/transactions?payment_card_id=${id}`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const getWalletRequests = async (id, filter = {}) => {
	return await axios
		.get(`${walletURL}/transaction-requests?payment_card_id=${id}`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
/*************Recharge Wallet******************* */
export const rechargeWallet = async (data) => {
	return await axios
		.post(`${walletURL}/recharge-wallet`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
/*************Bank Deatails******************* */
export const getBankDetails = async (currency) => {
	return await axios
		.get(
			`${walletBackOfficeURL}/back-office/wallet-transfer-details?currency=${currency}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};
