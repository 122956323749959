import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export default function ReservationTable({ travelers }) {
	const [modal, setModal] = useState(false);
	const [Travelers, setTravelers] = useState([]);
	const [traveler, setTraveler] = useState(null);

	//Modal Delete
	const DeleteModal = () => {
		return (
			<Modal isOpen={modal} className="">
				<ModalHeader>Delete Element</ModalHeader>
				<ModalBody className="text-center">
					Are you sure you want to delete this Element?
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={() => removeItem()}>
						Delete
					</Button>{" "}
					<Button color="secondary" onClick={() => setModal(false)}>
						Cancle
					</Button>
				</ModalFooter>
			</Modal>
		);
	};

	//Remove Item
	const removeItem = () => {
		//EndPoint DELETE Traveler
		const filtered = travelers.filter((k, i) => i !== traveler);
		setTravelers(filtered);
		setModal(false);
	};

	const deleteItem = (id) => {
		setModal(true);
		setTraveler(id);
	};

	return (
		<>
			<tr key={travelers.id}>
				<td>
					<div className="form-check">
						<input
							type="checkbox"
							name="traveller"
							className="form-check-input my-1 select-item"
							id={travelers.ID_No}
						/>
						<label
							className="form-check-label pointer"
							htmlFor={travelers.ID_No}
						>
							{travelers.Travelers_Name}
						</label>
					</div>
				</td>
				<td> {travelers.Type}</td>
				<td> {travelers.Gender}</td>
				<td> {travelers.Travelers_Name}</td>
				<td> {travelers.Nationality}</td>
				<td> {travelers.ID_No}</td>
				<td> {travelers.Price} EGP</td>
				<td>
					<i className="fas fa-check text-success"></i>
				</td>
				<td className="actions">
					<i className="fas fa-edit text-primary"></i>
					<i
						className="fas fa-trash-alt text-danger"
						onClick={() => deleteItem(travelers.id)}
					></i>
				</td>
			</tr>
			{modal ? <DeleteModal /> : null}
		</>
	);
}
