import Locale from 'translations'
import { EditIcon } from 'modules/UmrahOperations/shared/Icons'
import React, { useEffect, useState } from 'react'
import SearchComponent from '../components/Search'
import CRMEditTravelerModal from '../models/EditTravelerModal'
import { TravelerRoomIcon } from 'modules/CRM/Icons'


const fileUrl = `${process.env.REACT_APP_FILEUPLOAD_URL}/fetch`;


const PaxDetails = ({ travelers, setReloadData, roomsCombinations }) => {
	// translation
	const { inventory } = Locale;
	// states
	const [travelerModal, setTravelerModal] = useState({
		isOpen: false,
		travelerData: null
	});
	const [filteredTravelers, setFilteredTravelers] = useState(travelers);
	const [travelerSearchText, setTravelerSearchText] = useState("")




	function toggleTravelerModal(traveler) {
		setTravelerModal({
			isOpen: !travelerModal?.isOpen,
			travelerData: traveler
		})
	}
	function searchByTravelerName(value) {
		value = value.toLowerCase();
		if (value !== "") {
			const filter = travelers?.filter((traveler) => {
				const travelerFullName = `${traveler?.first_name} ${traveler?.last_name}`
				return (
					(travelerFullName.toLowerCase().includes(value) || traveler?.passport_number?.toLowerCase()?.includes(value)) && traveler 
				);
			});
			setFilteredTravelers(filter);
		} else {
			setFilteredTravelers(travelers);
		}
		setTravelerSearchText(value);
	}

	useEffect(()=> {
		setFilteredTravelers(travelers)
	},[travelers])


	return (
		<div className='extra-service-wrapper'>
			<SearchComponent onChangeHandler={searchByTravelerName} value={travelerSearchText} source={"pax"} />
			<div className='table-responsive table-bordered'>
				<table className='table'>
					<thead className='thead-light'>
						<tr>
							<th>{inventory.messages.name}</th>
							<th>{inventory.messages.type}</th>
							<th>ROOM No.</th>
							<th>{inventory.messages.passport}</th>
							<th>{inventory.messages.visa}</th>
						</tr>
					</thead>
					<tbody>
						{filteredTravelers?.length > 0 ? (filteredTravelers?.map((traveler) => (
							<tr key={traveler.id}>
								<td>
									{traveler?.personal_photo ?
										<img className="me-1 rounded"
											src={`${fileUrl}/${traveler?.personal_photo}`}
											alt={`${traveler?.first_name || "-"} ${traveler?.last_name || "-"}`}
											width="32px"
											height="32px"

										/>
										:
										null
									}
									<span>{`${traveler?.first_name || "-"} ${traveler?.last_name || "-"}`}</span>
								</td>
								<td>
									<TravelerRoomIcon roomType={traveler?.roomAvailabilities?.[0]?.room_type} />
									<span className='ml-1'>
										{traveler?.is_adult === 1 ? 'Adult' : 'Child'}
									</span>
								</td>
								<td>{traveler?.room_number || '-'}</td>
								<td>
									<button className="btn p-0 shadow-none" onClick={() => toggleTravelerModal(traveler)}>
										<EditIcon />
									</button>
									<span className="mx-1">
										{traveler?.passport_number || '-'}
									</span>
								</td>
								<td>{traveler?.visa_number || '-'}</td>
							</tr>
						))
						) : (
							<tr>
								<td colSpan="5" className="text-center">No Travelers</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			{travelerModal.isOpen ?
				<CRMEditTravelerModal
					isOpen={travelerModal.isOpen}
					traveler={travelerModal.travelerData}
					toggle={toggleTravelerModal}
					setReloadData={setReloadData}
					roomsCombinations={roomsCombinations}
				/>
				:
				null
			}
		</div>
	)
}

export default PaxDetails;



