import { ExportIcon } from "modules/UmrahOperations/shared/Icons";
import { uuidv4 } from "modules/WebBuilder/shared/DragAndDropContainer";
import { useState } from "react";
import { store } from "react-notifications-component";
import { uploadFile } from "services/auth";
import Locale from "translations";

export default function UploadInput({
	label,
	onUpload = () => {},
	accept = "",
	bucket = "",
	errorMsg,
}) {
	if (!bucket) {
		throw new Error("Bucket is required for UploadInput component");
	}

	const id = uuidv4();
	const [file, setFile] = useState(null);
	const { operationStatement } = Locale;
	const [uploadError, setUploadError] = useState();

	const upload = async (file) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("name", file?.name);
		formData.append("bucket", bucket);

		setUploadError(null);
    setUploadError(null);
		
    const response = await uploadFile(formData);

		if (response?.data?.data?.uuid) {
			return response.data.data.uuid;
		} else {
      store.addNotification({
        title: "Error!",
        message: "Failed to upload file",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });

      setFile(null);
      setUploadError("Failed to upload file");
      return null
    }
	};

	const changeHandler = async (e) => {
		const file = e.target.files[0];
		setFile(file);

		if (!file) {
			return;
		}

		const fileId = await upload(file);

		onUpload(file, fileId);
	};

	return (
		<div className={`fileUpload-wrapper ${(uploadError || errorMsg) ? "error" : ""}`}>
			{!!label && <p>{label}</p>}
			<label for={id} className="my-1 fileUpload-input">
				<div>{file ? file.name : operationStatement.upladFile}</div>
				<ExportIcon color="#D4A655" />
				<input
					type="file"
					id={id}
					className="d-none"
					onChange={changeHandler}
					accept={accept}
				/>
			</label>
			{(uploadError || errorMsg) && (
				<small className={`${errorMsg ? "has-error" : ""}`}>{errorMsg}</small>
			)}
		</div>
	);
}
