import Locale from "translations";
import MoreInfoIcon from "modules/market-place/NewMarketplace/icons/moreInfoIcon";
import SelectField from "components/Form/SelectField/SelectField";
import MessageIcon from "modules/market-place/NewMarketplace/icons/messageIcon";
import DangerInfoIcon from "modules/market-place/NewMarketplace/icons/dangerInfoIcon";
import { useState } from "react";

export default function RoomRow({
	room,
	hotelRate,
	addSelectedRoom,
	item,
	sethotelRate,
	setSelectedData,
	toggleNoteReta,
	toggleCancellationModel,
	toggleModalDays,
	toggleModalTerms,
	rate,
}) {
	const room_rate = rate;
	const { productsBuilder,marketPlace } = Locale;
	const [seleted, setSeleted] = useState({});
	console.log(room,room_rate);

	return (
		<div className="option-row room-row">
			<div className="item">
				<span>{productsBuilder.RoomType}</span>
				<p
					onClick={(e) => {
						toggleModalTerms();
						setSelectedData({
							room_rate: room_rate,
							room: room,
						});
					}}
				>
					{room?.name} <MoreInfoIcon />
				</p>
			</div>
			<div className="item">
				<span>{productsBuilder.BoardBasis}</span>
				<p>{room_rate?.mealTitle}</p>
			</div>
			<div className="item">
				<span> {productsBuilder.Rate}</span>
				<div>
					<p
						onClick={() => {
							toggleModalDays();
							setSelectedData({
								room_rate: room_rate,
								room: room,
							});
						}}
					>
						{room_rate?.room_total_price}{" "}
						<span>{hotelRate?.hotel?.currency}</span> <MoreInfoIcon />
					</p>
				</div>
			</div>

			<span
				style={{ cursor: "pointer" }}
				onClick={() => {
					toggleCancellationModel();
					setSelectedData({
						room_rate: room_rate,
						room: room,
					});
				}}
			>
				<DangerInfoIcon />
			</span>
			<span
				onClick={() => {
					toggleNoteReta();
					setSelectedData({
						room_rate: room_rate,
						room: room,
					});
				}}
				style={{ cursor: "pointer" }}
			>
				<MessageIcon />
			</span>
			{room_rate.onRequest ? (
				<p className="rate-yellow">{productsBuilder.OneRequest}</p>
			) : <p className="rate">{marketPlace.Available}</p>}
			{room_rate.isRefundable ? (
				<p className="rate">{productsBuilder.nonRefundable}</p>
			) : <div style={{minWidth:"50px"}}></div >}

			<div className="num-rooms">
				<SelectField
					hasLabel={false}
					labelInner={true}
					label={productsBuilder.numberOfRooms}
					placeholder={productsBuilder.select}
					value={seleted.label}
					onChange={(e) => {
						addSelectedRoom(e, room, rate);
						setSeleted(e);
					}}
					options={[
						{
							id: 0,
							label: 0,
							name: 0,
							value: 0,
						},
						...[...Array(+room?.availabilityCount).keys()].map((res, index) => {
							return {
								id: index + 1,
								label: index + 1,
								name: index + 1,
								value: index + 1,
							};
						}),
					]}
				/>
			</div>
		</div>
	);
}
