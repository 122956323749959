import { useSBSDispatch } from 'context/global';
import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import { instantSafakeyLogin } from 'services/auth';


const redirectTable = {
  "operation-statement": "/safa_offline/operation-statement",
  "trip-builder": "/safa_offline/products-builder?version=2"
}
export default function InstantSafaLogin() {
  const dispatch = useSBSDispatch();
  const { search } = useLocation();
  const history = useHistory();

  async function handleInstantSafaKeyLogin() {
    // if (requestParams.hasOwnProperty("expires") && requestParams.hasOwnProperty("token") && requestParams.hasOwnProperty("signature")) {
    const response = await instantSafakeyLogin(search);
    if (response?.status === 200) {
      dispatch({
        type: "login",
        isAuth: true,
        token: response.data.data.token,
        userInfo: {
          ...response.data.data.user,
          is_connected_to_safa_visa: response.data.data.company?.is_connected_to_safa_visa,
          is_first_login: response?.data?.data?.user?.is_first_login
        },
        companyInfo: response.data.data.company,
        share_b2b: response.data.data.company?.share_b2b,
      });
      history.push(`/welcome_safalive?redirect=${redirectTable[response?.data?.data?.path] || "/dashboard"}`);
    } else {
      history.push("/auth/login")
    }
    // }
  }


  useEffect(() => {
    handleInstantSafaKeyLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (
    <div></div>
  )
}
