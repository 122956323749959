import React, { useCallback, useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import OTPInput from 'react-otp-input';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { sendOtp } from 'services/marketplace';
import Locale from 'translations';



export default function VerifyOTPModal({ header,toggle, isOpen, actionData, onVerify }) {
  const { marketPlace } = Locale;
  const timeoutValue = 60;
  const [otpNumber, setOtpNumber] = useState("");
  const [countDown, setCountDown] = useState(timeoutValue);
  const [resendOtpBtnDisabled, setResendOtpBtnDisabled] = useState(false);

  let countDownTimeOut;

  const handleCountDown = useCallback(() => {
    countDown > 0 && setTimeout(() => setCountDown(countDown - 1), 1000);
  }, [countDown]);

  async function handleResendOTP() {
    setResendOtpBtnDisabled(true);
    setOtpNumber("");
    const res = await sendOtp();
    if (res?.status === 200) {
      handleCountDown();
      store.addNotification({
				message: marketPlace.messages.otpHasSent,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
    }else {
      setResendOtpBtnDisabled(false);
    }
  }

  useEffect(() => {
    if (countDown > 0 && countDown < timeoutValue) {
      setResendOtpBtnDisabled(true);
      handleCountDown();
    }
    if (countDown === 0) {
      setResendOtpBtnDisabled(false);
      setCountDown(timeoutValue);
    }
    return () => {
      clearTimeout(countDownTimeOut);
    };
  }, [countDown, countDownTimeOut, handleCountDown]);

  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader toggle={toggle}>
        {header}
      </ModalHeader>
      <ModalBody>
        <div className='my-3'>
          <OTPInput
            className="w-100"
            value={otpNumber}
            onChange={setOtpNumber}
            numInputs={4}
            renderSeparator={<span className="mx-2">-</span>}
            inputStyle={"w-25 input-OTP"}
            renderInput={(props) => (
              <input style={{ width: "25%" }} {...props} />
            )}
            shouldAutoFocus={true}
            placeholder='asdsad'
          />
        </div>
        <div className='d-flex justify-content-center'>
          <button
            disabled={resendOtpBtnDisabled}
            onClick={handleResendOTP}
            className='btn bg-nxt resend-otp-btn'
          >
            {marketPlace.messages.resendOTP} {countDown} {marketPlace.messages.second}
          </button>
        </div>
        <button
          className="btn bg-nxt mt-3 w-100"
          disabled={otpNumber.length !== 4}
          onClick={() => onVerify({ ...actionData, otp: otpNumber })}
        >
          {marketPlace.messages.verify}
        </button>
      </ModalBody>
    </Modal>
  )
}
