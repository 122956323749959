import SelectField from "components/Form/SelectField/SelectField";
import { useEffect, useState } from "react";
import validate, { isFormValid } from "helpers/validate";
import Locale from "translations";
import TextField from "components/Form/TextField/TextField";
import HotelItem from "./HotelItem";
import { store } from "react-notifications-component";
import {
	GenerateHotels,
	addHotelStatements,
} from "services/operationStatement";
import { useParams, useLocation } from "react-router-dom";
import moment from "moment";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import { uploadFile } from "services/auth";
import { ReactComponent as TrashIcon } from "assets/images/umrah-operations/trash.svg";
import { ReactComponent as Attachment } from "assets/images/umrah-operations/attachment.svg";
import { useSBSState } from "context/global";
import maleIconSrc from "assets/images/umrah-operations/male-icon.svg";
import femaleIconSrc from "assets/images/umrah-operations/female-icon.svg";
import { formatViewOperationStatement } from "modules/UmrahOperations/helper/formatUmrahOperations";
import GenerateSeviceModal from '../Modals/generateServiceModal';
import { getOperationsLookup } from 'modules/UmrahOperations/helper/operationsLookup';

export default function Hotels({
	operationHasSent,
	OperationStatement,
	getStatementMain,
	setOperationStatement,
	isDisabled,
	// errors,
	// setErrors,
	// prevActiveTab,
	// setActiveTab,
	// activeTab,
	// setSaveTabName,
	productsOption
}) {
	const { productsBuilder, interests, operationStatement } = Locale;
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const { reference, id } = useParams();
	const location = useLocation();
	const isPublic = location.pathname.includes("public");

	const statementId = isPublic ? id : reference;



	const cities = OperationStatement.hotels;
	const [isFirst, setisFirst] = useState(false);
	const checkFormErrors = () => {
		let Errors = {};

		if (cities && cities.length > 0) {
			cities.map((city, index) => {
				Errors = {
					...Errors,
					...validate(
						{ name: "city" + index, value: city?.city?.name },
						{ required: true }
					),
					// ...validate(
					// 	{ name: "rawda_attachment" + index, value: city.rawda_attachment },
					// 	{ required: city.rawda_reservation_date&&city?.city?.value === 4 ? true : false }
					// ),
				};
				if (
					city.rawda_reservation_date ||
					city.rawda_males_count ||
					city.rawda_females_count
				) {
					Errors = {
						...Errors,
						...validate(
							{
								name: "rawda_reservation_date" + index,
								value: city?.rawda_reservation_date,
							},
							{ required: true }
						),
						...Errors,
						...validate(
							{
								name: "rawda_females_count" + index,
								value: city?.rawda_females_count,
							},
							{ required: city?.rawda_females_count == "0" ? false : true }
						),
						...Errors,
						...validate(
							{
								name: "rawda_males_count" + index,
								value: city?.rawda_males_count,
							},
							{
								required:
									city?.rawda_males_count == "0"
										? city?.rawda_females_count == "0"
											? true
											: false
										: true,
							}
						),
					};
				}
			});

			cities.map((city, indexCity) => {
				city.hotel.map((hote, index) => {
					Errors = {
						...Errors,
						...validate(
							{ name: "hotel" + indexCity + index, value: hote?.hotel?.value },
							{ required: true }
						),
						...validate(
							{
								name: "check_in_date" + indexCity + index,
								value: hote?.check_in_date,
							},
							{ required: true, date: true }
						),
						...validate(
							{ name: "no_nights" + indexCity + index, value: hote.no_nights },
							{ required: true }
						),
						...validate(
							{ name: "no_nights" + indexCity + index, value: hote.no_nights },
							{ required: true }
						),
						...validate(
							{
								name: "meal_type" + indexCity + index,
								value: hote?.meal_type?.name,
							},
							{ required: true }
						),
					};
				});
			});
		}

		setErrors({
			...Errors,
		});
	};

	useEffect(() => {
		async function CreateHotelDetails() {
			if (isFormValid(errors)) {
				let data =
					cities && cities.length > 0
						? cities.map((res) => {
							return {
								city_id: res.city.id,
								confirmation_number: res.confirmation_number,
								rawda_reservation_date: res.rawda_reservation_date
									? moment(res.rawda_reservation_date).format("YYYY-MM-DD")
									: null,
								rawda_males_count: +res.rawda_males_count,
								rawda_females_count: +res.rawda_females_count,
								rawda_attachment: res.rawda_attachment || null,
								hotels:
									res.hotel && res.hotel.length > 0
										? res.hotel.map((hote) => {
											return {
												...hote,
												hotel_id: hote?.hotel?.id,
												meal_type: hote?.meal_type?.id,
												rooms_count: {
													single: +hote.single,
													double: +hote.double,
													triple: +hote.triple,
													quadruple: +hote.quadruple,
													quintuple: +hote.quintuple,
													sixtuple: +hote.sixtuple,
												},
											};
										})
										: null,
							};
						})
						: null;

				const response = await addHotelStatements(statementId, { cities: data });

				if (response.status === 200 || response.status === 201) {
					getStatementMain();
					store.addNotification({
						title: `${interests.messages.updateSuccessfully}`,
						message: `${interests.messages.EntriesUpdateSuccess}`,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		}
		if (isFirst) {
			CreateHotelDetails();
		}
		// if (activeTab === "operation-hotels") {
		// 	if (!isFormValid(errors)) {
		// 		setActiveTab("operation-hotels");
		// 		//setTabsValidation(prev=>({ ...prev , operationDetails:{required:true}  }))
		// 	} else {
		// 		//setTabsValidation(prev=>({ ...prev , operationDetails:{required:false}  }))
		// 	}
		// }
	}, [isErrorLoaded]);

	const saveProduct = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	// useEffect(() => {
	// 	if (activeTab === "operation-hotels") {
	// 		//  if(tabChanges.operationDetails){
	// 		saveProduct();
	// 		// }
	// 	}
	// }, [cities]);
	function changeCityValue(value, key, index) {
		let citiesData = [...cities];
		citiesData[index] = { ...citiesData[index], [key]: value };
		if (key == "city") {
			let hotels = citiesData[index].hotel.map((res) => {
				return { ...res, hotel: {} };
			});
			citiesData[index] = { ...citiesData[index], hotel: hotels };
		}
		setOperationStatement({ ...OperationStatement, hotels: citiesData });
	}

	function changeCityObj(Obj, index) {
		let citiesData = [...cities];
		citiesData[index] = { ...citiesData[index], ...Obj };
		setOperationStatement({ ...OperationStatement, hotels: citiesData });
	}

	function changeHotelValue(value, key, indexCity, indexHotel) {
		let citiesData = [...cities];
		citiesData[indexCity].hotel[indexHotel] = {
			...citiesData[indexCity].hotel[indexHotel],
			[key]: value,
		};
		setOperationStatement({ ...OperationStatement, hotels: citiesData });
	}

	function changeHotelNightValue(value, key, indexCity, indexHotel) {
		let citiesData = [...cities];
		citiesData[indexCity].hotel[indexHotel] = {
			...citiesData[indexCity].hotel[indexHotel],
			[key]: value,
			check_out_date: moment(
				citiesData[indexCity].hotel[indexHotel].check_in_date
			).add(+value, "d"),
		};
		setOperationStatement({ ...OperationStatement, hotels: citiesData });
	}

	function addHotel(indexCity) {
		let citiesData = [...cities];
		citiesData[indexCity].hotel.push({});
		setOperationStatement({ ...OperationStatement, hotels: citiesData });
	}
	function deleteHotel(indexCity, Index) {
		let citiesData = [...cities];
		citiesData[indexCity].hotel = citiesData[indexCity].hotel.filter(
			(res, index) => index !== Index
		);
		setOperationStatement({ ...OperationStatement, hotels: citiesData });
	}

	function addCity() {
		let citiesData = [...cities];
		citiesData.push({ hotel: [{}] });
		setOperationStatement({ ...OperationStatement, hotels: citiesData });
	}

	function deleteCity(indexCity) {
		let citiesData = [...cities];
		citiesData = citiesData.filter((res, index) => index !== indexCity);
		setOperationStatement({ ...OperationStatement, hotels: citiesData });
	}

	function resetHotels(indexCity) {
		let citiesData = [...cities];
		let hotels = citiesData[indexCity].hotel.map((res) => {
			return { ...res, hotel: {} };
		});
		citiesData[indexCity] = { ...citiesData[indexCity], hotel: hotels };
		// citiesData.map((res, index) => index !== indexCity);
		setOperationStatement({ ...OperationStatement, hotels: citiesData });
	}

	// Generate Hotel
	const [productSelected, setProductSelected] = useState();
	const [generateHotelIsOpen, setGenerateHotelIsOpen] = useState(false);
	const generateHotelToggle = () => {
		setGenerateHotelIsOpen((prev) => !prev);
	};


	const generationHotel = async () => {
		const data = { product_id: productSelected?.id };
		const res = await GenerateHotels(statementId, data);
		if (res?.status === 200 || res?.status === 201) {
			console.log("resssssssss", res);
			let result = await formatViewOperationStatement({
				...OperationStatement,
				hotels: res?.data,
				generate_hotel: true,
			}, Locale);
			setOperationStatement(result);
			store.addNotification({
				title: "Info",
				message: "Generate Hotel Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setProductSelected();
				},
			});

			generateHotelToggle();
		}

	};


	return (

		<>
			<div className="w-100 mt-3">
				{OperationStatement?.hotels?.length === 0 && !isPublic && (
					<div className="d-flex justify-content-end">
						{!operationHasSent ?
							<button
								className="btn btn-success py-2 px-4"
								onClick={generateHotelToggle}
								style={{
									fontSize: "16px"
								}}
							>
								{operationStatement.generateHotel}
							</button>
							: null}
					</div>
				)}
				{cities && cities.length > 0
					? cities.map((city, index) => {
						return (
							<City
								key={index}
								city={city}
								changeCityObj={changeCityObj}
								changeCityValue={changeCityValue}
								changeHotelValue={changeHotelValue}
								changeHotelNightValue={changeHotelNightValue}
								index={index}
								cities={cities}
								setErrors={setErrors}
								resetHotels={resetHotels}
								errors={errors}
								addHotel={addHotel}
								deleteCity={deleteCity}
								deleteHotel={deleteHotel}
								operationHasSent={operationHasSent}
							/>
						);
					})
					: null}
			</div>

			<div className="col-md-12 justify-content-start d-flex align-items-start">
				{!operationHasSent ?
					<button
						className="btn-plus-operation"
						onClick={() => {
							addCity();
						}}
						disabled={isDisabled}
					>
						<span>
							<i class="fas fa-plus"></i>
						</span>
						{operationStatement.AddCity}
					</button>
					: null}
			</div>

			<div className="w-100 mt-3  d-flex justify-content-end">
				{!operationHasSent ?
					<button
						className="btn-Product-Filter bg-nxt justify-content-center"
						onClick={() => {
							// setSaveTabName("operation-hotels")
							saveProduct();
							setisFirst(true);
						}}
						disabled={isDisabled}
					>
						{productsBuilder.Save}
					</button>
					: null}
			</div>
			<GenerateSeviceModal
				isOpen={generateHotelIsOpen}
				toggle={generateHotelToggle}
				option={productsOption}
				state={productSelected}
				setState={setProductSelected}
				generateFn={generationHotel}
				header={operationStatement.generateHotel}

			/>
		</>
	);
}

function City({
	operationHasSent,
	city,
	setErrors,
	errors,
	index,
	changeCityObj,
	changeCityValue,
	resetHotels,
	changeHotelValue,
	changeHotelNightValue,
	cities,
	deleteCity,
	deleteHotel,
	addHotel,
}) {
	const { operationStatement, productsBuilder, productElements } = Locale;
	const { citiesLookup } = getOperationsLookup(Locale);
	let firstCities =
		cities && cities.length > 0
			? cities.findIndex((res) => res?.city?.id === 4)
			: null;

	const upload = async (file) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("name", file?.name);
		formData.append("bucket", "rawda");
		const response = await uploadFile(formData);
		if (response.data.data.uuid) {
			return { uuid: response.data.data.uuid, name: file?.name };
		}
	};

	const handleUpload = async (e, key, index) => {
		const files = e.target.files;
		if (!files?.[0]) return;
		const file = (await upload(files[0])) ?? "";
		changeCityObj({ [key]: file.uuid, fileName: file.name }, index);
	};
	const { locale } = useSBSState();

	return (
		<>
			<p className="city-title mb-2">
				{operationStatement.City} ({index + 1})
			</p>
			<div className="col-md-12 OperationDetails">
				<div className="row">
					<div className="col-md-2">
						<div className="form-group full-input">
							<SelectField
								label={productsBuilder.City + "*"}
								value={city?.city?.name}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{ name: "city" + index, value: e.value },
											{ required: true }
										),
									});
									changeCityValue(e, "city", index);
								}}
								options={citiesLookup}
								placeholder={productsBuilder.select}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "city" + index,
												value: city?.city?.id,
											},
											{ required: true }
										),
									})
								}
								color={errors["city" + index]?.required ? "danger" : ""}
								errors={errors["city" + index]}
								disabled={operationHasSent}
							/>
						</div>
					</div>
					<div className="col-md-2">
						<div className="form-group full-input">
							<TextField
								placeholder={operationStatement.confirmation_number}
								label={operationStatement.confirmation_number}
								type={"text"}
								name="confirmation_number"
								value={city.confirmation_number}
								onChange={(e) => {
									changeCityValue(e.target.value, "confirmation_number", index);
								}}
								color={
									errors["confirmation_number" + index]?.required
										? "danger"
										: ""
								}
								errors={errors["confirmation_number" + index]}
								disabled={operationHasSent}

							/>
						</div>
					</div>

					<div className="offset-md-4 col-md-4 justify-content-end d-flex align-items-end">
						{!operationHasSent ? <>
							<button
								className="btn-plus-operation btn-plus-operation-sp"
								onClick={() => {
									addHotel(index);
								}}
							>
								<span>
									<i class="fas fa-plus"></i>
								</span>
								{operationStatement.AddHotel}
							</button>

							<button
								onClick={() => deleteCity(index)}
								className="btn-Delete-operation"
							>
								<span>
									<i class="far fa-trash-alt"></i>
								</span>
								{operationStatement.DeleteCity}
							</button></>
							: null}
					</div>
				</div>
				{firstCities === index ? (
					<div className="row">
						<div className="col-md-2">
							<DatePickerField
								label={operationStatement.RawdaReservation}
								placeholder={productElements.dateFormatPlaceholder}
								date={
									city?.rawda_reservation_date
										? moment(city?.rawda_reservation_date)
										: null
								}
								onChangeDate={(e) => {
									// setCheckInDate(e);
									changeCityValue(e, "rawda_reservation_date", index);
								}}
								color={
									errors["rawda_reservation_date" + index]?.required
										? "danger"
										: ""
								}
								// // isOutsideRange={(day) =>
								// // 	!day.isAfter(
								// // 		moment(datesRange.start).subtract(1, "d"),
								// // 		"day"
								// // 	) || !isInclusivelyBeforeDay(day, datesRange.end)
								// // }
								errors={errors["rawda_reservation_date" + index]}
								disabled={operationHasSent}
							/>
						</div>

						<div className="col-md-2">
							<div className="form-group full-input">
								<TextField
									placeholder={operationStatement.NoMales}
									label={operationStatement.NoMales}
									type={"number"}
									name="rawda_males_count"
									isImage={true}
									image={maleIconSrc}
									prependImage={true}
									value={city.rawda_males_count}
									onChange={(e) => {
										changeCityValue(e.target.value, "rawda_males_count", index);
									}}
									color={
										errors["rawda_males_count" + index]?.required
											? "danger"
											: ""
									}
									errors={errors["rawda_males_count" + index]}
									disabled={operationHasSent}
								/>
							</div>
						</div>

						<div className="col-md-2">
							<div className="form-group full-input">
								<TextField
									placeholder={operationStatement.NoFemales}
									label={operationStatement.NoFemales}
									type={"number"}
									name="rawda_females_count"
									isImage={true}
									image={femaleIconSrc}
									prependImage={true}
									value={city.rawda_females_count}
									onChange={(e) => {
										changeCityValue(
											e.target.value,
											"rawda_females_count",
											index
										);
									}}
									color={
										errors["rawda_females_count" + index]?.required
											? "danger"
											: ""
									}
									errors={errors["rawda_females_count" + index]}
									disabled={operationHasSent}

								/>
							</div>
						</div>

						<div className="col-md-3">
							<div
								className={`box-input-file d-flex align-items-center justify-content-center ${errors["rawda_attachment" + index]?.required
									? "border-danger"
									: ""
									}`}
							>
								{!city.rawda_attachment ? (
									<>
										{" "}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="17"
											height="16"
											viewBox="0 0 17 16"
											fill="none"
										>
											<path
												d="M14.75 10V12.6667C14.75 13.0203 14.6095 13.3594 14.3595 13.6095C14.1094 13.8595 13.7703 14 13.4167 14H4.08333C3.72971 14 3.39057 13.8595 3.14052 13.6095C2.89048 13.3594 2.75 13.0203 2.75 12.6667V10"
												stroke="#1E85FF"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M12.0834 5.33333L8.75002 2L5.41669 5.33333"
												stroke="#1E85FF"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M8.75 2V10"
												stroke="#1E85FF"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
										<p className="mx-2">
											{locale === "ar"
												? `حدد ملف الحجز أو قم بالسحب والإسقاط هنا`
												: `Select reservation file or drag and drop here`}
										</p>{" "}
									</>
								) : (
									<div className="w-100 d-flex justify-content-between align-items-center ">
										<div className="d-flex align-items-center">
											<Attachment />
											<p className="mx-1">
												{" "}
												{city.fileName ?? "Reservation File"}
											</p>
										</div>
										<span
											name="rawda_attachment"
											onClick={(e) => {
												changeCityValue("", "rawda_attachment", index);
											}}
										>
											<TrashIcon />
										</span>
									</div>
								)}

								{!city.rawda_attachment ? (
									<input
										type="file"
										name="file"
										onChange={(e) => {
											handleUpload(e, "rawda_attachment", index);
											setErrors({
												...errors,
												...validate(
													{ name: "rawda_attachment" + index, value: e },
													{ required: true }
												),
											});
										}}
										id="file"
										disabled={operationHasSent}
									/>
								) : null}
							</div>
							{errors["rawda_attachment" + index]?.required ? (
								<small className="text-danger">
									{" "}
									rawda_attachment is required
								</small>
							) : (
								""
							)}
						</div>
					</div>
				) : null}
			</div>
			{city.hotel && city.hotel.length > 0
				? city.hotel.map((hotelItem, IND) => {
					return (
						<HotelItem
							operationHasSent={operationHasSent}

							hotelLength={city.hotel.length}
							key={IND}
							hotel={hotelItem}
							indexCity={index}
							cityID={city?.city?.id}
							index={IND}
							changeHotelValue={changeHotelValue}
							changeHotelNightValue={changeHotelNightValue}
							setErrors={setErrors}
							errors={errors}
							deleteHotel={deleteHotel}
						/>
					);
				})
				: null}
		</>
	);
}
