import NumberField from 'components/Form/NumberField/NumberField'
import CheckBox from 'components/chekbox'
import React from 'react'
import Locale from 'translations';

const fixedDiscountId = 2;
const percentageDiscountId = 1;


export default function PricingDiscountVAT({ pricingForText, pricing, toggleVatDiscount, handleVatDiscountInputs }) {
  const { packagePlanner, productsBuilder } = Locale;
  return (

    <div className="pricing-discount-vat-wrapper">
      {/* vat */}
      <div className="col-md-6 col-12">
        <div className="checkBox-input summery-checkBox">
          <CheckBox
            checked={pricing?.hasVat}
            id={'pricing_vat'}
            name={'pricing_vat'}
            text={`${packagePlanner.addVatFor} ${pricingForText?.toUpperCase()}`}
            onChange={() => {
              toggleVatDiscount({ key: "hasVat" })
            }}
          />
        </div>
        {/* vat input */}
        {pricing?.hasVat ?
          <div className="col-md-6 col-12 p-0 mt-3">
            <NumberField
              hasLabel={false}
              removeArrow={true}
              placeholder={packagePlanner.enterValue}
              extraTextPosition="append"
              extraText={"%"}
              value={pricing?.vat}
              onChange={(e) => handleVatDiscountInputs({ key: "vat", value: e.target.value })}
            />
          </div>
          :
          null
        }
      </div>
      {/* discount */}
      <div className="col-md-5 col-12">
        <div className="checkBox-input summery-checkBox">
          <CheckBox
            checked={pricing?.hasDiscount}
            id={'pricing_discount'}
            name={'pricing_discount'}
            text={`${packagePlanner.addDiscountFor} ${pricingForText?.toUpperCase() }`}
            onChange={() => {
              toggleVatDiscount({ key: "hasDiscount" })
            }}
          />
        </div>


        {/* discount inputs */}
        {pricing?.hasDiscount ?
          <>
            <div className="col-md-6 col-12 p-0 mt-2">
              <NumberField
                label={packagePlanner.fixedValue}
                // hasLabel={false}
                removeArrow={true}
                placeholder={"Value"}
                id={"fixed_value"}
                name={"fixed_value"}
                extraTextPosition="append"
                extraText={"SAR"}
                value={pricing?.discount?.discount_type_id === fixedDiscountId ? pricing?.discount?.discount : ""}
                onChange={(e) => {
                  handleVatDiscountInputs({
                    key: "discount",
                    value: {
                      discount: e.target?.value,
                      discount_type_id: e.target?.value?.length > 0 ? fixedDiscountId : null,
                      plan_name: "discount"
                    }
                  })
                }}
                disabled={pricing?.discount?.discount_type_id === percentageDiscountId}
              />
            </div>

            <div className="col-md-6 col-12 p-0">
              <NumberField
                label={productsBuilder.percentage}
                // hasLabel={false}
                removeArrow={true}
                placeholder={"Value"}
                id={"percentage_value"}
                name={"percentage_value"}
                extraTextPosition="append"
                extraText={"%"}
                value={pricing?.discount?.discount_type_id === percentageDiscountId ? pricing?.discount?.discount : ""}
                onChange={(e) => {
                  if (e.target.value < 100) {
                    handleVatDiscountInputs({
                      key: "discount",
                      value: {
                        discount: e.target?.value,
                        discount_type_id: e.target?.value?.length > 0 ? percentageDiscountId : null,
                        plan_name: "discount"
                      }
                    })
                  }
                }}
                disabled={pricing?.discount?.discount_type_id === fixedDiscountId}
              />
            </div>

          </>
          :
          null
        }

      </div>
    </div>
  )
}
