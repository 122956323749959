// ** Imports
import React, { useEffect, useState } from 'react'
import Locale from 'translations'
import BreadCrump from '../shared/BreadCrump'
import TextField from 'components/Form/TextField/TextField'
import ShowForPermission from 'helpers/showForPermission'
import { ReactComponent as ExportExcelIcon } from 'assets/images/umrah-operations/export-excel-icon.svg'
import SeachIcon from 'assets/images/market-place/servicesIcon/searchIcon.png'
import Pagination from 'components/Pagination'
import AccountingList from './List'
import AccountingStatementFilter from './Filter'
import {
	getAgentsLookup,
	getListOperation,
	listOperationExportToExcel
} from 'services/operationStatement'
import { useSBSState } from 'context/global'
import moment from 'moment'
import { store } from 'react-notifications-component'
import ShowSubscription from 'helpers/ShowSubscription'

const AccountingStatement = () => {
	// ** hooks
	const { operationStatement, inventory } = Locale
	const { locale, allCountries } = useSBSState()

	const allItem = { id: 'all', value: 'all', label: operationStatement.All, name: operationStatement.All }

	const initialFilters = {
		page: 1,
		payment_status: 'all',
		search: '',
		hotel_id: null,
		agent_ids: [allItem],
		country_ids: [allItem],
		arrival_dates: null,
		pricing_type: allItem
	}

	// ** states
	const [filters, setFilters] = useState(initialFilters)
	const [accountingStatemnet, setAccountingStatement] = useState([])
	const [metaData, setMetaData] = useState(null)

	// ** tabs filter
	const [statusCount, setStatusCount] = useState({
		all: 0,
		new: 0,
		paid: 0,
		unpaid: 0
	})
	const [activeStatus, setActiveStatus] = useState(filters?.payment_status)

	const [lookups, setLookups] = useState({
		agents: [],
		hotelCitiesLookup: [
			{ label: 'MECCA', name: 'MECCA', id: 3 },
			{ label: 'AL_MADINAH', name: 'AL_MADINAH', id: 4 },
			{ label: 'RIYADH', name: 'RIYADH', id: 194 },
			{ label: 'JEDDAH', name: 'JEDDAH', id: 134 },
			{ label: 'TAIF', name: 'TAIF', id: 214 },
			{ label: 'YANBU', name: 'YANBU', id: 224 }
		],
		statusListLookup: [
			{ name: operationStatement.executed, id: 'executed' },
			{ name: inventory.messages.pending, id: 'pending' }
		],
		sortByLookup: [
			{ name: operationStatement.dateTimeOfBusArrival, id: 'movement_date' },
			{ name: operationStatement.dateTimeOfEndTime, id: 'end_date' }
		],
		countries: [allItem, ...allCountries]
	})

	function formatFilters(filters) {
		let filteredData = {
			page: filters?.page,
			payment_status:
				filters?.payment_status !== 'all' ? filters?.payment_status : null,
			search: filters?.search,
			pricing_type:
				filters?.pricing_type?.id !== 'all' ? filters?.pricing_type?.id : null,
			hotel_id: filters?.hotel_id?.id,
			agent_ids: filters?.agent_ids?.find((agent) => agent?.value === 'all')
				? null
				: filters?.agent_ids?.map((agent) => agent?.id),
			country_ids: filters?.country_ids?.find(
				(country) => country?.value === 'all'
			)
				? null
				: filters?.country_ids?.map((country) => country?.id),

			arrival_dates: filters?.arrival_dates
				? [
					moment(filters?.arrival_dates[0]).format('YYYY-MM-DD'),
					moment(filters?.arrival_dates[1]).format('YYYY-MM-DD')
				]
				: null
		}
		return filteredData
	}

	const getStatementAccounting = async (type, page) => {
		let filtersData = formatFilters(
			type === 'reset' ? initialFilters : { ...filters, page }
		)
		const res = await getListOperation(filtersData)
		if (res?.status === 200) {
			const { meta, status_counts, data } = res?.data
			setMetaData(meta)
			setStatusCount(status_counts)
			setAccountingStatement(data)
		}
	}

	// TODO => Export Excel Functionality
	const exportExcel = async () => {
		let filteredData = {
			page: null,
			payment_status:
				filters?.payment_status !== 'all' ? filters?.payment_status : null,
			search: filters?.search,
			pricing_type:
				filters?.pricing_type !== 'all' ? filters?.pricing_type : null,
			hotel_id: filters?.hotel_id?.id,
			agent_ids: filters?.agent_ids?.find((agent) => agent?.value === 'all')
				? null
				: filters?.agent_ids?.map((agent) => agent?.id),
			country_ids: filters?.country_ids?.find(
				(country) => country?.value === 'all'
			)
				? null
				: filters?.country_ids?.map((country) => country?.id),

			arrival_dates: filters?.arrival_dates
				? [
					moment(filters?.arrival_dates[0]).format('YYYY-MM-DD'),
					moment(filters?.arrival_dates[1]).format('YYYY-MM-DD')
				]
				: null
		}
		const res = await listOperationExportToExcel(filteredData)
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type
				})
				const objectUrl = window.URL.createObjectURL(blob)
				var anchor = document.createElement('a')
				anchor.target = '_blank'
				anchor.href = objectUrl
				anchor.setAttribute(
					'download',
					`operation-statement-${moment().format('DD-MM-YYYY')}`
				)
				anchor.click()
			}
			store.addNotification({
				// title: 'Info!',
				message: `Export To Excel Successful`,
				type: 'success',
				insert: 'top',
				container: 'top-right',
				animationIn: ['animated', 'fadeIn'],
				animationOut: ['animated', 'fadeOut'],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true
				}
			})
		}
	}

	// handle paginations
	function goTo(page) {
		// setFilters({ ...filters, page });
		getStatementAccounting(null, page)
	}

	// ** reset form filter
	function resetFilters() {
		setFilters(initialFilters)
		if (initialFilters?.payment_status === filters?.payment_status) {
			getStatementAccounting('reset')
		}
	}

	// get agents lookups
	async function fethcAgentLookups() {
		let res = await getAgentsLookup()
		let formatAgents = res?.data?.data?.map((agent) => {
			return {
				...agent,
				value: agent?.id,
				label: `${agent?.name} - ${agent?.country?.name?.[locale]}`,
				name: `${agent?.name} - ${agent?.country?.name?.[locale]}`
			}
		})
		return formatAgents
	}
	// get hotels lookups
	// async function fetchHotelsLookup(id, cities_ids) {
	// 	const request = await fetchHotels(id, cities_ids)
	// 	if (request.hotels && request.hotels.length > 0) {
	// 		let formatHotels = request.hotels.map((Hotel) => {
	// 			return {
	// 				...Hotel,
	// 				label: Hotel.name,
	// 				value: Hotel.id
	// 			}
	// 		})
	// 		return formatHotels
	// 	}
	// }

	useEffect(() => {
		getStatementAccounting()
	}, [filters?.payment_status, filters?.page])

	useEffect(() => {
		async function fetchLookupList() {
			const [agents] = await Promise.all([
				fethcAgentLookups()
				// fetchHotelsLookup(966, [3, 4, 5])
			])
			setLookups({ ...lookups, agents })
		}
		fetchLookupList()
	}, [])

	return (
		<ShowSubscription module="operation_accounting">
			<div className="operation-statement-container" style={{ padding: "0 26px" }}>
				<div className='d-flex justify-content-between align-items-center d-column-mob m-mob '>
					<BreadCrump title={operationStatement.accounting}></BreadCrump>
					<div className='d-flex align-items-center text-filed-visibility-hidden'>
						<TextField
							type='text'
							id='statement-operation-no'
							name='statement-operation-no'
							hasLabel={false}
							placeholder={operationStatement.quickOperationNo}
							isImage={true}
							image={SeachIcon}
							prependImage={true}
						/>
						<ShowForPermission permission='Manage-operation-accounting'>
							<button
								className='btn export-excel-btn mx-2 py-2 flex-grow-1'
								onClick={exportExcel}
								style={{ width: '15rem' }}
							>
								<ExportExcelIcon />
								<span className='mx-1'>{operationStatement.exportExcel}</span>
							</button>
						</ShowForPermission>
					</div>
				</div>

				<AccountingStatementFilter
					filters={filters}
					setFilters={setFilters}
					allItem={allItem}
					resetFilters={resetFilters}
					getListOperation={getStatementAccounting}
					lookups={lookups}
				/>

				{/* <ShowForPermission permission='View-operation-accounting'> */}
				<AccountingList
					statusCount={statusCount}
					accountingStatement={accountingStatemnet}
					filters={filters}
					setFilters={setFilters}
					activeStatus={activeStatus}
					setActiveStatus={setActiveStatus}
					getStatementAccounting={getStatementAccounting}
				/>

				<div className='mt-4 pb-3'>
					<Pagination info={metaData} goTo={goTo} />
				</div>
			</div>
		</ShowSubscription>
	)
}

export default AccountingStatement
