import React, { useEffect } from 'react';
import BreadCrump from '../shared/BreadCrump';
import { useParams, useHistory } from 'react-router-dom';
import { useSBSState } from 'context/global';
import PlusIcon from 'modules/products-builder/Pricing/IconsSVG/PlusIcon';
import { ReactComponent as ArrowLeftIcon } from "assets/images/Tds/arrow-left.svg";
import { EditIcon } from '../shared/Icons';
import Locale from 'translations';
import ShowForPermission from 'helpers/showForPermission';
// import ShowSubscription from 'helpers/ShowSubscription';
import AddEditAgentForm from './AddEditForm';
import useAgentHook from './useAgentHook';


export default function OperationAddEditAgent() {
  const { operationStatement, companyDetails } = Locale;
  const { allCountries, locale } = useSBSState();
  const { reference, id, bundle } = useParams();
  const history = useHistory();
  const viewStatus = reference === "view";
  const editStatus = reference === "edit";
  const addStatus = reference === "add";
  
  const { addEditAgent, getAgentData, agentData, handleInputChanges, agentErrors } = useAgentHook();

  

  function goToAgentsList() {
    history.push(`/${bundle}/agents`)
  }
  useEffect(() => {
    if (editStatus || viewStatus) {
      allCountries?.length > 0 && getAgentData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCountries?.length, editStatus, id, viewStatus])

  return (
    // <ShowSubscription module="operation_agent">
      <div className="bg-white operations-agents">
        <div className='container-fluid' style={{ padding: "0 26px" }}>
          <BreadCrump title={operationStatement.agents}>
            {viewStatus ?
              <ShowForPermission permission="Manage-operation-statement">
                <button className='btn bg-nxt d-flex align-items-center'
                  onClick={() => history.push(`/${bundle}/agents/edit/${id}`)}
                >
                  <EditIcon color='#fff' />
                  <span className='mx-2'>{operationStatement.editAgent}</span>
                </button>
              </ShowForPermission>
              :
              null
            }
          </BreadCrump>

          <div className="add-edit-agent">
            {/* add edit Form */}
            <div className="add-edit-form">
              <div className="title">
                {addStatus && operationStatement.addNewAgent}
                {viewStatus && agentData?.name}
                {editStatus && operationStatement.editAgent}
              </div>
              {addStatus || editStatus ?
                <AddEditAgentForm
                  agentData={agentData}
                  handleInputChanges={handleInputChanges}
                  errors={agentErrors}
                />
                :
                <ViewAgentDetails agentData={agentData} locale={locale} />
              }
            </div>


            <div className='d-flex align-items-center justify-content-between py-4'>
              <button className='btn back-btn d-flex align-items-center' onClick={goToAgentsList}>
                <ArrowLeftIcon />
                <span className='mx-2'>{companyDetails.back}</span>
              </button>
              {addStatus || editStatus ?
                <ShowForPermission permission="Manage-operation-statement">
                  <button className='btn bg-nxt d-flex align-items-center' onClick={() => addEditAgent(goToAgentsList)}>
                    {addStatus ?
                      <>
                        <PlusIcon />
                        <span className='mx-2'>
                          {operationStatement.addAgent}
                        </span>
                      </>
                      :
                      <span className='mx-2'>{operationStatement.editAgent}</span>
                    }

                  </button>
                </ShowForPermission>
                :
                null
              }

            </div>
          </div>

        </div>
      </div>
    // </ShowSubscription>
  )
}

export function ViewAgentDetails({ agentData, locale }) {
  const { operationStatement, userDetails, login, companyDetails, inventory } = Locale;

  return (
    <div className='d-flex gap-24 flex-wrap view-agent'>
      <div className='d-flex flex-column flex-grow-1 gap-10'>
        <p className='d-flex align-items-center'>
          <span className='col-5 type'>{inventory.messages.country}</span>
          <span className='col-7'>{agentData?.country?.label[locale]}</span>
        </p>
        <p className='d-flex align-items-center'>
          <span className='col-5 type'>{login.emailAddress}</span>
          <span className='col-7'>{agentData?.email}</span>
        </p>
        <p className='d-flex align-items-center'>
          <span className='col-5 type'>{userDetails.phoneNumber}</span>
          <span className='col-7'>{agentData?.phone_code?.phone_code}{agentData?.phone}</span>
        </p>
        <p className='d-flex align-items-center'>
          <span className='col-5 type'>{companyDetails.address}</span>
          <span className='col-7'>{agentData.address}</span>
        </p>
      </div>

      <div className='d-flex flex-column flex-grow-1 gap-10'>
        <p className='d-flex align-items-center'>
          <span className='col-5 type'>{operationStatement.responsibleEmployee}</span>
          <span className='col-7'>{agentData?.responsible_name}</span>
        </p>
        <p className='d-flex align-items-center'>
          <span className='col-5 type'>{login.emailAddress}</span>
          <span className='col-7'>{agentData?.responsible_email}</span>
        </p>
        <p className='d-flex align-items-center'>
          <span className='col-5 type'>{userDetails.phoneNumber}</span>
          <span className='col-7'>{agentData?.responsible_phone_code?.phone_code}{agentData?.responsible_phone}</span>
        </p>
      </div>
    </div>
  )
}
