import { useEffect, useState } from "react";

export default function CustomPagination({ groupsList, filter, setFilter }) {
	const [activePage, setActivePage] = useState(0);
	const arrSize = Math.ceil(groupsList?.total / 10);
	let arr = arrSize ? [...Array(arrSize).keys()] : [];
	useEffect(() => {
		arr = arrSize ? [...Array(arrSize).keys()] : [];

	}, [arrSize]);

	const data = arr.map((elem, index) => (
		<li className="page-item">
			<button
				className={activePage == elem ? "activePage page-link" : "page-link"}
				onClick={() => {
					setFilter({ ...filter, page: elem, search: filter.search });
					setActivePage(elem);
				}}
			>
				{elem + 1}
			</button>
		</li>
	));

	return (
		<div className="d-flex flex-row-reverse ">
			<nav aria-label="Page navigation example">
				<ul className="pagination">
					<li className="page-item">
						<a
							href={() => {
								return false;
							}}
							className="page-link "
							aria-label="Previous"
							onClick={() => {
								if (filter.page != 0) {
									setActivePage(activePage != 0 ? activePage - 1 : 0);
									setFilter({ ...filter, page: activePage - 1 });
								}
							}}
						>
							<span aria-hidden="true" className="p-0">
								{/* <i className="icx icx-left-solid-arrow">
								</i> */}
								Previous
							</span>
						</a>
					</li>

					{data}
					<li className="page-item ">
						<a
							className="page-link"
							href={() => {
								return false;
							}}
							aria-label="Next"
							onClick={() => {
								if (filter.page < arr.length) {
									setActivePage(
										activePage != arr.length - 1
											? activePage + 1
											: arr.length - 1
									);
									setFilter({
										...filter,
										page:
											activePage != arr.length - 1
												? activePage + 1
												: arr.length - 1,
									});
								}
							}}
						>
							<span aria-hidden="true" className="p-0">
								{/* <i className="icx icx-right-solid-arrow"></i> */}
								Next
							</span>
							<span className="sr-only">Next</span>
						</a>
					</li>
				</ul>
			</nav>
		</div>
	);
}
