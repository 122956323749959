import React from 'react'
import safaLogo from "assets/images/safaLogo.svg";
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { useHistory } from "react-router-dom";
import Locale from 'translations';

function BestDealsNav() {
  const { marketPlace } = Locale;
  const history = useHistory()
  return (
		<nav className="nav-deals">
			<div className="container py-2">
				<div className="d-flex">
					<div className="w-50 d-flex align-items-center">
						<img src={safaLogo} alt="" />
					</div>
					<div className="w-50">
						<div className="d-flex justify-content-between align-items-center">
							<button className="btn btn-type ">{marketPlace.hotels}</button>
							<button
								className="btn btn-agent"
								onClick={() => history.push("/")}
							>
								{marketPlace.agentLogin}
							</button>
							<LanguageSwitcher className="bg-white" />
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
}

export default BestDealsNav