import Pagination from "components/Pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { getActivityLogs } from "services/activity";
import Locale from "translations";
import Filter from "./Filter";

export default function Logs() {
	const { activity } = Locale;

	const [activityList, setActivityList] = useState([]);
	const [meta, setMeta] = useState();

	const [filter, setFilter] = useState({
		search: "",
		user_id: "",
		log_name: "",
		date_from: undefined,
		date_to: undefined,
		page: 1,
	});
	const [searchStatus, setSearchStatus] = useState("");
	useEffect(() => {
		async function fetchActivity() {
			const res = await getActivityLogs({
				...filter,
				user_id: filter?.user_id?.id,
				log_name: filter?.log_name?.name,
				date_from: filter.date_from
					? moment(filter.date_from).format("YYYY-MM-DD")
					: "",
				date_to: filter.date_to
					? moment(filter.date_to).format("YYYY-MM-DD")
					: "",
				// page:
				// 	filter?.user_id != "" ||
				// 	filter?.log_name != "" ||
				// 	filter?.search != "" ||
				// 	filter.date != undefined
				// 		? 1
				// 		: filter.page,
			});
			setActivityList(res.data?.data);
			setMeta(res?.data?.meta);
		}
		fetchActivity();
	}, [searchStatus, filter.page]);

	const search = () => {
		setSearchStatus(!searchStatus);
	};
	const AllActivity =
		activityList?.length > 0 ? (
			activityList?.map((activity, index) => {
				return (
					<tr key={activity.id}>
						<td>{activity.user_name}</td>
						<td>{activity?.email}</td>
						<td>{activity.created_at}</td>
						<td>{activity.full_description}</td>
					</tr>
				);
			})
		) : (
			<tr>
				<td colSpan="9">
					<div className="product-build__product-no-data fullHeight">
						<h4 className="no-data-color">{activity.noActivity}</h4>
					</div>
				</td>
			</tr>
		);
	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};
	return (
		<div className="wallet-wrraper0">
			<div className="product-builder-list bg-transparent">
				<Filter filter={filter} setFilter={setFilter} search={search} />
				<div className='table-container'>
				<Table striped className="table-update p-2 bg-white ">
					<thead>
						<tr>
							<th>{activity.teamMember}</th>
							<th>{activity.email}</th>
							<th>{activity.Date}</th>
							<th width="40%">{activity.Event}</th>
						</tr>
					</thead>
					<tbody> {AllActivity}</tbody>
				</Table>
				</div>
				{activityList?.length > 0 ? (
					<Pagination info={meta} goTo={goTo} />
				) : null}
			</div>

			<div></div>
		</div>
	);
}
