export const DelegateReport = `
<style>
			* {
				font-family:  sans-serif;
				margin: 0;
			}

			table {
				width: 100%;
				border-collapse: collapse;
			}

			th,
			td {
				/* border: 1px solid black; */
				padding: 16px;
				text-align: start;
			}

			.container {
				border-top: 16px solid #d2ab67;
			}
			.delegate {
				margin-top: 45px;
				margin-inline: 32px;
				min-height: 90vh;
				position: relative;
			}
			.delegate .data-info {
				margin-block: 16px;
			}
			.delegate .data-info h6 {
				color: #1d2d3e;
				font-size: 16px;
				font-weight: 700;
				margin-bottom: 2px;
			}
			.delegate .data-info .data-info-item {
				display: flex;
				flex-direction: column;
			}
			.delegate .data-info-item div {
				width: 50%;
				display: flex;
				justify-content: space-between;
				margin-bottom: 2px;
			}
			.delegate .data-info-item div span {
				color: #707170;
				font-size: 14px;
				font-weight: 400;
			}
			.delegate .data-info-item div p {
				color: #000;
				font-size: 14px;
				font-weight: 400;
			}
			.delegate .table-details {
				border: 1px solid #dbdade;
				margin-bottom: 16px;
			}
			.delegate .table-details thead {
				border-bottom: 1px solid #dbdade;
				background-color: #dbdade;
				color: #707170;
				margin-block: 16px;

				font-size: 10px;
				font-weight: 700;
				letter-spacing: 1px;
				text-transform: uppercase;
			}
			.delegate .table-details tbody tr {
				border-bottom: 1px solid #dbdade;
				color: #050001;
				font-size: 12px;
				font-weight: 400;
			}
			.footer {
				border-top:1px solid rgba(0, 0, 0, 0.1);
				padding-block: 16px;
				// position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				/* background-color: red; */
				color: white;
				text-align: center;
				height: 50px;

			}
			.footer p {
				color: #4e4f54;
				text-align: center;
				font-size: 15px;
				font-weight: 400;
			}
			.tr-14,.no-tr-14{
				 visibility: hidden;
			}
			.img-container{
				position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		// max-height: 200px;
		// margin-top: 8px;
		// padding: 16px 14px;
		// gap: 12px;
		// border-radius: 4px;
		// border: 2px dashed #EAE9E9;
		overflow: hidden;
			}
			.image-banner {
				width: 100%;
				// height: 100%;
				justify-content: center;
				.preview {
					flex-basis: 100%;
					max-width: 100% !important;
				}
			}
			.image-left {
				justify-content: flex-start;
			}
			.image-right {
				justify-content: flex-end;
			}
			[dir="rtl"] .image-right {
				justify-content: flex-start;
			}
			[dir="rtl"] .image-left {
				justify-content: flex-end;
			}
			
			@media print {
				// .footer{
				// 	display: block;
				// 	position: fixed !important;
				// 	left: 0; 
				// 	right: 0; 
				// 	height: 45px;
				// }
				.no-tr-14{
					visibility: hidden;
				}
				.tr-14{
					visibility: visible;
					display:table-row !important;
					height:80px  ;
					background-color:#fff;
					border: 1px solid #fff !important;
				}
				.delegate .table-details tbody .tr {
					height:98px ! important;
				}
				
				// .from-print{
				// 	width:20% !important;
				// }
				
			}
			
		</style>
		<div class="container">
		<div class="delegate">
			<div class="header">
			<div class="img-container [position-image]">
				<img
					src=[avater]
					alt="logo"
					width="100"
					height="100"
					style="border: 1px solid #D2AB67"
				/>
				</div>

				<div class="data-info">
					<h6>[Detection_daily_visits]</h6>
					<div class="data-info-item">
						<div>
							<span>[date]:</span>
							<p>[first_movement_date] - [last_movement_date]</p>
						</div>
						<div>
							<span>[printed_date]:</span>
							<p>[print_date_api]</p>
						</div>
					</div>
				</div>
			</div>

			<table class="table-details">
				<thead>
					<tr>
						<th>#</th>
						<th>[OperationNo]</th>
						<th>[AgentName]</th>
						<th>[UmrahCompany]</th>
						<th>[Pax]</th>
						<th>[TransportationCompany]</th>
						<th>[Supervisor]</th>
						<th class="from-print">[From]</th>
						<th class="from-print">[To]</th>
						<th>[Date]</th>
						<th>[Drivers]</th>
					</tr>
				</thead>
				<tbody>
					[tableRows]
					
				</tbody>
			</table>

			<div class="footer" style="position:[footer_position]">
				
				<p>[footer_api]</p>
			</div>
		</div>
	</div>
`;
