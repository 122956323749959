import React, { useEffect, useState } from "react";
import Filter from "./filter";
import ArrowIcon from "assets/images/crm/Vector.png";

import checkIcon from "assets/images/crm/tick-circle.png";
import closeIcon from "assets/images/crm/close-circle.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import { listCustomrrs } from 'services/CRM';
import Pagination from 'components/Pagination';
import NoDataIcon from "assets/images/crm/no-data.png";
import Locale from 'translations';

export default function Customers() {
	const {CRM}=Locale
	const history = useHistory();
	const { pathname } = useLocation();

	const [data, setData] = useState([]);
	const [meta, setMeta] = useState();

	const [filter, setFilter] = useState({
		search: "",
		page: 1,
	});
	
	useEffect(() => {
		async function fetchTransaction() {
			const res = await listCustomrrs({
				...filter,
			});
			debugger
			setData(res?.data?.data);
			setMeta(res?.data?.meta);
		}
		fetchTransaction();
	}, [filter]);
	const AllCustomers =
		data.length > 0
			? data.map((item, index) => {
					return (
						<tr key={index}>
							<td>{item?.name}</td>
							<td>{item?.email}</td>
							<td>
								<div className="d-flex flex-column">
									{/* {item.phone.map((item) => (
										<span>{item}</span>
									))} */}

									{item?.phone}
								</div>
							</td>
							<td>-</td>
							<td>
								<img
									src={item?.history_package ? checkIcon : closeIcon}
									alt="History Package"
								/>
							</td>

							<td>
								<span
									className="view"
									role="button"
									// onClick={() => history.push(`/crm/customers/${item.id}`)}
									onClick={()=>{history.push({
										pathname: `/crm/customers/${+index+1}`,
										state: {
											data: item?.email,
										},
									})
								
								localStorage.setItem("custumers_email",item?.email)
								}
								
								}

								>
									<img src={ArrowIcon} alt="view customer" />
								</span>
							</td>
						</tr>
					);
			  })
			: <tr>
			<td colSpan="6">
				<div className="product-build__product-no-data fullHeight no-result">
					<img src={NoDataIcon} alt=" No Results" />
					<h4>{"No Results Yet!"}</h4>
					<p>You need to fill the search fields first to get results</p>
				</div>
			</td>
		</tr>;
			const goTo = (page) => {
				setFilter({ ...filter, page: page });
			};
	return (
		<>
				<div className="cs-requests customers">
					<div className="title">
						<h4>{CRM.Customers}</h4>
						<hr />
					</div>
					<Filter filter={filter} setFilter={setFilter} />
					<div className="table-cs-request">
						<table className="p-2 bg-white table ">
							<thead>
								<tr>
									<th>{CRM.name}</th>
									<th>{CRM.Email}</th>
									<th>{CRM.phonenumber}</th>
									<th>{CRM.whatsapp}</th>
									<th>{CRM.historyPackage}</th>

									<th width="7%">{CRM.Action}</th>
								</tr>
							</thead>
							<tbody> {AllCustomers}</tbody>
						</table>
					</div>
					{data?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}

				</div>
			
		</>
	);
}
