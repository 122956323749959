import TourCardThemeFive from './TourCard';
import DragAndDropContainer from 'modules/WebBuilder-V2/shared/DragAndDropContainer';
import { SORT_ITEMS_IN_CONTAINER_ACTION } from 'modules/WebBuilder-V2/interfaces/blockTypes';
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';

const toursBlocksType = "toursBlocksType";

export default function ToursTheme5({
  items,
  sortToursItems,
  focusContainer,
  editItem,
  itemsBoxActions,
  openEditModal,
}) {


  return (
    <div className="tours-cards-container">
      {items.map((props, index) => (
        <DragAndDropContainer
          accept={toursBlocksType}
          type={toursBlocksType}
          id={props.id}
          index={index}
          action={SORT_ITEMS_IN_CONTAINER_ACTION}
          moveItemInContainer={(dragIndex, hoverIndex) =>
            sortToursItems({ dragIndex, hoverIndex })
          }
        >
          <EditBox
            editMode={focusContainer === "tours"}
            actions={[
              ...editItem(props, index),
              ...itemsBoxActions(props.id),
            ]}
          >
            <TourCardThemeFive index={index} {...props} openEditModal={openEditModal} cardType="tours" />
          </EditBox>
        </DragAndDropContainer>
      ))}
    </div>
  )
}