import React from 'react';
import egyptFlag from 'assets/images/flags/egypt-flag.png';
import ksaFlag from 'assets/images/flags/saudi-arabia-flag.png';
import indonesiaFlag from 'assets/images/flags/indonesia-flag.png';
import uaeFlag from 'assets/images/flags/uae-flag.png';
import { useSBSState } from 'context/global';
import Locale from 'translations';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import SafaLogo from "assets/images/dashboard/safaLogoFull.svg";
import AppFooter from 'layouts/AppFooter';


export default function ContactUsPage() {
  const { locale, isAuth } = useSBSState();
  const { newLand,companyDetails } = Locale;
  const history = useHistory()
  const officesData = [
    {
      name: { ar: "المملكة العربية السعودية", en: "Saudi Arabia" },
      address: { ar: "شارع الأمير سلطان مبنى صباح سنتر جدة", en: "Prince Sultan Rd, 3th floor, Sabah Center Jeddah" },
      phones: ["+966 53 311 1682", "+966 50 293 0527"],
      flagSrc: ksaFlag,
    },
    {
      name: { ar: "جمهورية مصر العربية", en: "Egypt" },
      address: { ar: "9 ابراج عثمان فوق المتوسط، كورنيش النيل، المعادي – القاهرة", en: "9 Othman Towers, Kornish El-Nile, Maadi – Cairo" },
      phones: ["+202 25258862", "+202 25258863"],
      flagSrc: egyptFlag
    },
    {
      name: { ar: "إندونيسيا", en: "Indonesia" },
      address: { ar: "سليبي برج بلازا جاكرتا", en: "GP Plaza, Lt. 2 Unit 3 Palmerah, Tanah Abang Jakarta Pusat – Jakarta" },
      phones: ["+6287 7170 00070", "+6221 3973 7070"],
      flagSrc: indonesiaFlag
    },
    {
      name: { ar: "الإمارات العربية المتحدة", en: "United Arab Emirates" },
      address: { ar: "مركز التجارة العالمي، الدور الثالث، مكتب 3 – دبي", en: "Executive Center, Level 3,Office 3, World Trade Center – Dubai" },
      phones: [],
      flagSrc: uaeFlag,
      addtionData: "(SAFA TTMC FZCO)"
    },
  ]
  return (
    <div className={isAuth ? "bg-white" : "pre-auth-layout"}>
    <div className="bg-layout contact-us-page" style={{fontSize: "1.1rem"}}>
      {!isAuth && 
      <div className=" container px-3 co">
        <div className="row m-0 mb-2">

        
            

            <figure className="col-6 m-0 hidden-mobile login-page-img">
					<div className="d-flex justify-content-between py-2 align-items-center w-100">
							<Link to="/" className="text-dark">
								<img src={SafaLogo} alt="" srcset="" width="160px" />
							</Link>
							
						</div>
            </figure>
            <div className="col-md-6 col-sm-9 d-flex hidden-mobile  flex-column  justify-content-between login-page-contant">
						<div className="d-flex hidden-mobile justify-content-between py-2 align-items-center w-100">
						<Link to="/" className="text-dark linkHeader ">
								{newLand.home}
							</Link>
              <Link to="/about-us" className="text-dark linkHeader">
								{newLand.aboutUs}
							</Link>
							<Link to="/contact-us" className="text-dark linkHeader linkHeaderActive">
								{newLand.contactUs}
							</Link>
					
							<LanguageSwitcher />
						</div>
            </div>
            </div>
      </div>
      }
      <div className="container">
        <header className='my-2 d-flex hidden-desktop justify-content-between'>
          <div></div>
          <h1 className='h1 text-center font-weight-bold'>{newLand.contactUs}</h1>
          <div className='mx-2'>
 <button className="btn btn-warning" onClick={()=>history.goBack()}>
						{companyDetails.back}
						<i className='fa fa-arrow-left mx-1'></i>
						 </button> 
				</div>
        </header>
        {/* offices */}
        <section className='py-4'>
          <h2 className='h2'>{newLand.safaOffices}</h2>
          <div className='row justify-content-between mx-0 pt-4'>
            {officesData.map(office => {
              return (
                <div className='col-md-3 col-12 d-flex flex-column align-items-center my-4 text-center'>
                  <img src={office.flagSrc} alt={office.name?.[locale]} width="70px" />
                  <h3 className='h5 font-weight-bold py-2 m-0 w'>{office.name?.[locale]}</h3>
                  <h4 className='pb-3'>{office.addtionData}</h4>
                  <p className="mx-auto w-75">{office.address[locale]}</p>
                  {/* <p className='py-2 d-flex flex-column mt-auto'>
                    {office.phones.map(phone => <span className='font-weight-bold'>&#x200E;{phone}</span>)}
                  </p> */}
                </div>
              )
            })}
          </div>
        </section>
      </div>
      {/* map */}
      <section>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1728.266647594635!2d31.248362254589434!3d29.964100143415024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjnCsDU3JzUwLjgiTiAzMcKwMTQnNTAuNSJF!5e0!3m2!1sar!2seg!4v1601974475673!5m2!1sar!2seg"
          width="600"
          height="450"
          frameborder="0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
          title='map'
          style={{ width: "100%", maxWidth: "100%", }}
        >
        </iframe>
      </section>
      {!isAuth && 
        <div className="mt-3">
          <AppFooter />
        </div>
      }
    </div>
    </div>
  )
}
