import DiscountIcon from 'modules/market-place/NewMarketplace/icons/discountIcon'
import SettingIcon from 'modules/market-place/NewMarketplace/icons/settingIcon'
import { useEffect, useState } from 'react'
import Locale from 'translations'
import CalenderIcon from 'modules/market-place/NewUI/icons/calenderIcon'

export default function OptionRow({ item, interval, getHotelRate }) {
	const [matches, setMatches] = useState(
		window.matchMedia('(max-width: 600px)').matches
	)

	useEffect(() => {
		window
			.matchMedia('(max-width: 600px)')
			.addEventListener('change', (e) => setMatches(e.matches))
	}, [])

	const { productsBuilder } = Locale
	return (
		<>
			{!matches ? (
				<div className='option-row'>
					<div className='item'>
						<span>{productsBuilder.From}</span>
						<p>{interval.from}</p>
					</div>
					<div className='item'>
						<span>{productsBuilder.To}</span>
						<p>{interval.to}</p>
					</div>
					<div className='item'>
						<span>{productsBuilder.StartingRate}</span>
						<div>
							<p>
								{interval.total_price} <span>{item.currency}</span>{' '}
							</p>
						</div>
					</div>

					<p className='rate'>
						<DiscountIcon /> {productsBuilder.specialRate}
					</p>
					{/* <p className="rate-yellow">
				<SettingIcon /> {productsBuilder.OneRequest}
			</p> */}
					<button
						className='btn btn-yellow'
						onClick={() =>
							getHotelRate({ date_from: interval.from, date_to: interval.to })
						}
					>
						{productsBuilder.Book}
					</button>
				</div>
			) : (
				<div className='card m-2'>
					<div className='d-flex flex-column p-3'>
						<p className='rate w-50'>
							<DiscountIcon /> {productsBuilder.specialRate}
						</p>

						<div className='d-flex justify-content-between'>
							<div className='d-flex align-items-center'>
								<CalenderIcon />

								<div className='d-flex flex-column ml-2'>
									<span style={{ color: '#828382', fontSize: '13px ' }}>
										{productsBuilder.From}
									</span>
									<p>{interval.from}</p>
								</div>
							</div>

							<div className='d-flex align-items-center'>
								<CalenderIcon />
								<div className='d-flex flex-column ml-2'>
									<span style={{ color: '#828382', fontSize: '13px' }}>
										{productsBuilder.To}
									</span>
									<p>{interval.to}</p>
								</div>
							</div>
						</div>

						<hr className='hr w-100' color='#F5F5F5' style={{ opacity: 0.5 }} />

						<div className='d-flex justify-content-between align-items-center'>
							<div className='d-flex flex-column mx-1'>
								<span style={{ color: '#828382', fontSize: '13px' }}>
									{productsBuilder.StartingRate}
								</span>
								<p>
									<span style={{ fontWeight: 'bold', fontSize: '1rem' }}>
										{interval.total_price}
									</span>{' '}
									<span>{item.currency}</span>{' '}
								</p>
							</div>
							<div>
								<button
									className='btn btn-yellow'
									style={{ width: '99px', height: '32px' }}
									onClick={() =>
										getHotelRate({
											date_from: interval.from,
											date_to: interval.to
										})
									}
								>
									{productsBuilder.Book}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
