import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import React from 'react';
import Locale from 'translations';
import correctIcon from "assets/images/wallet/correctIcon.svg";
import clearIcon from "assets/images/wallet/clearIcon.svg";



export default function TransferFilter({ filters, setFilters,search }) {
  const { payment, inventory } = Locale;

  const status = [
    { id: 0, name: `${payment.messages.Pending}`, title: "pending" },
    { id: 1, name: `${inventory.messages.confirmed}`, title: "confirmed" },
    { id: 2, name: `${inventory.messages.Canceled}`, title: "canceled" },
    { id: 3, name: `${payment.messages.Expired}`, title: "expired" },
  ];

  function resetFilters() {
    setFilters({
      search: "",
      booking_date: "",
      transfer_date: "",
      status: "",
      page: 1,
    });
    search();
  }

  return (
    <div className="filter-update bg-filter-gray">
      <div className="product-buttons col-md-11">
        <div className="main-filter-payment d-flex justify-contain-between">
          {/* search text */}
          <div className="main-label controls-field col-md-2 col-6">
            <TextField
              label={payment.messages.search}
              type="text"
              placeholder={payment.messages.search}
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value,page:1 })
              }
              value={filters.search}
              on
            />
          </div>
          {/* booking date */}
          <div className="main-label controls-field  col-md-2 col-6">
            <DatePickerField
              label={inventory.messages.BookingDate}
              placeholder="DD/MM/YYY"
              date={filters.booking_date}
              onChangeDate={(e) => {
                setFilters({ ...filters, booking_date: e,page:1 });
              }}
              isOutsideRange={(day) => {
                return false;
              }}
            />
          </div>
          {/* transfer date */}
          <div className="main-label controls-field  col-md-2 col-6">
            <DatePickerField
              label={inventory.messages.transferDate}
              placeholder="DD/MM/YYY"
              date={filters.transfer_date}
              onChangeDate={(e) => {
                setFilters({ ...filters, transfer_date: e,page:1 });
              }}
              isOutsideRange={(day) => {
                return false;
              }}
            />
          </div>
          {/* status */}
          <div className="main-label controls-field col-md-2 col-6">
            <SelectField
              label={payment.messages.status}
              placeholder={payment.messages.status}
              options={status}
              value={filters?.status?.name}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  status: {
                    id: e.value,
                    name: e.label,
                    title: status[e.value].title,
                  },
                  page:1
                })
              }
            />
          </div>
          {/* fiter buttons */}
          <div className="filter-btn col-md-4 col-12 d-flex align-items-center justify-content-start">
            <div className="apply pointer">
              <button className="btn btn-light px-3" onClick={()=>search()}>
                <img src={correctIcon} alt=" " />
                {payment.messages.apply}
              </button>
            </div>
            <div
              className="clear text-secondary pointer"
              onClick={resetFilters}
            >
              <button className="btn btn-light px-3 mx-2">
                <img src={clearIcon} alt=" " />
                {payment.messages.clear}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
