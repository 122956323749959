import React from 'react'

export default function MoreIcon() {
  return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 26 26"
			fill="none"
		>
			<path
				d="M25 16.6V9.4C25 3.4 22.6 1 16.6 1H9.4C3.4 1 1 3.4 1 9.4L1 16.6C1 22.6 3.4 25 9.4 25H16.6C22.6 25 25 22.6 25 16.6Z"
				fill="#FAF4EB"
				stroke="#EED9BA"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13 9.00354V8.99456"
				stroke="#050001"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13 13.0045V12.9955"
				stroke="#050001"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13 17.0055V16.9965"
				stroke="#050001"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
