import SelectField from 'components/Form/SelectField/SelectField'
import TextField from 'components/Form/TextField/TextField'
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { SaveIcon } from './Icons'
import NumberField from 'components/Form/NumberField/NumberField'
import { useSBSState } from 'context/global'
import { fetchDelegateCompanies, fetchGroupsLookup, fetchOfficersLookup } from 'services/operationStatement'
import Locale from 'translations'
import UploadInput from 'components/UploadInput'
import validate, { isFormValid } from 'helpers/validate'



const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

export default function ChooseOfficialModal({ isOpen, toggle, actionFn, isEdit, data }) {
  // **  hooks
  const { operationStatement, movementManagement, commons, guideRequests } = Locale;
  const { allCountries, companyInfo } = useSBSState();
  const isServiceCompany = companyInfo?.company_category === "services_company";


  // ** states
  const [officialData, setOfficialData] = useState({
    attach_type: data?.group || isServiceCompany ? "officer" : data?.company ? "company" : null,
    company: null,
    officer: {
      id: data?.id || null,
      name: data?.name || null,
      phone_code: data?.phone_country || null,
      phone_number: data?.phone || null,
      email: data?.email || null,
    },
    officer_id: data?.id,
    group: data?.group,
    attach_file: null
  });

  console.log({officialData})

  const [errors, setErrors] = useState({});
  console.log({errors})

  const [lookups, setLookups] = useState({
    groups: null,
    companies: null,
    officers: [],
  });


  // ** variables
  const showAttachFileInput = officialData.attach_type && (data?.movmentType === "departure" || data?.movmentType === "arrival")


  // ** functions 
  function checkFormErrors() {
    let reqiredKeys = officialData.attach_type === "officer" ? ["group", "officer_id"] : ["company"];
    let submitError = {};
    Object.keys(officialData).forEach((key) => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: officialData[key] },
          { required: reqiredKeys.includes(key) || (showAttachFileInput && key === "attach_file") }
        ),
      };
    });
    setErrors(() => submitError);
    return submitError;
  }



  async function getOfficersFromGroup({ groupId }) {
    const res = await fetchOfficersLookup(groupId);
    if (res?.status === 200) {
      const officers = res?.data?.data?.map(officer => ({
        id: officer?.id,
        name: officer?.name,
        phone_code: officer?.phone_country,
        phone_number: officer?.phone,
        email: officer?.email,
      }))
      setLookups({ ...lookups, officers });
    }
  }


  useEffect(() => {
    async function getGroupsLookup() {
      const res = await fetchGroupsLookup();
      if (res?.status === 200) {
        setLookups({ ...lookups, groups: res?.data });
      }
    };

    async function getCompniesLookup() {
      const res = await fetchDelegateCompanies("delegation_management");
      if (res?.status === 200) {
        setLookups({ ...lookups, companies: res?.data?.data });
      }
    };
    // get groups in case officer and there is no groups lookup
    if (!lookups.groups && officialData.attach_type === "officer") {
      getGroupsLookup();
    }
    // get companies in case company and there is no companies lookup
    if (!lookups.companies && officialData.attach_type === "company") {
      getCompniesLookup()
    }

  }, [lookups, officialData.attach_type]);
  
  // get compnay data from companies lookup
  useEffect(() => {
    if (data?.company && lookups.companies) {
      setOfficialData({ ...officialData, company: lookups.companies?.find(company => company?.name === data?.company) || null })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, lookups.companies]);


  


  // ** handle upload file **
  async function handleUploadFile(file, fileId) {
    setOfficialData({
      ...officialData,
      attach_file: fileId
    });
    setErrors({
      ...errors,
      ...validate(
        { name: "attach_file", value: fileId },
        { required: true }
      ),
    });
  }


  return (
    <Modal isOpen={isOpen} size={"lg"} centered className="operation-modal">
      <ModalHeader toggle={toggle}>
        {guideRequests.chooseOfficial}
      </ModalHeader>


      <ModalBody>
        {/* Attach Type */}
        <div className="d-flex align-items-center gap-24">
          {!isServiceCompany &&
            <div className="d-flex align-items-center">
              <input
                id="company"
                name="company"
                type="radio"
                class="mx-2 radio-input"
                checked={officialData.attach_type === "company"}
                value={"company"}
                onChange={(e) => setOfficialData({ ...officialData, attach_type: e.target.value })}
              />
              <label htmlFor="company" className="mb-0 pointer user-select-none">
                {guideRequests.delegateManagementCompany}
              </label>
            </div>
          }

          <div className="d-flex align-items-center">
            <input
              id="officer"
              name="officer"
              type="radio"
              class="mx-2 radio-input"
              checked={officialData.attach_type === "officer"}
              value={"officer"}
              onChange={(e) => setOfficialData({ ...officialData, attach_type: e.target.value })}
            />
            <label htmlFor="officer" className="mb-0 pointer user-select-none">
              {guideRequests.myDelegates}
            </label>
          </div>
        </div>


        {/* inputs */}
        <div className="row mx-0  mt-3">
          {officialData.attach_type === "company" ?
            // Company
            <div className="col-12">
              <SelectField
                label={guideRequests.selectCompany}
                name="delegate_company"
                id="delegate_company"
                options={lookups.companies}
                value={officialData.company?.name}
                onChange={(e) => {
                  setOfficialData({
                    ...officialData,
                    company: e
                  });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "company", value: e },
                      { required: true }
                    ),
                  });
                }}
                errors={errors?.company}
                color={errors?.company?.required ? "danger" : ""}
              />
            </div>
            :
            null
          }

          {/* if user choose to select from exist officers */}
          {officialData.attach_type === "officer" ?
            <>
              {/* groups */}
              <div className="col-6">
                <SelectField
                  label={guideRequests.selectGroup}
                  name="group"
                  id="group"
                  options={lookups.groups || []}
                  value={officialData.group?.name}
                  onChange={(e) => {
                    setOfficialData({
                      ...officialData,
                      group: e,
                      officer: {
                        id: null,
                        name: "",
                        phone_code: "",
                        phone_number: "",
                        email: "",
                      },
                      officer_id: null
                    });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "group", value: e },
                        { required: true }
                      ),
                    });
                    getOfficersFromGroup({ groupId: e.id });
                  }}
                  errors={errors?.group}
                  color={errors?.group?.required ? "danger" : ""}
                />
              </div>

              {/* officers */}
              <div className="col-6">
                <SelectField
                  label={guideRequests.selectDelegates}
                  name="delegates"
                  id="delegates"
                  options={lookups.officers || []}
                  value={officialData.officer?.name}
                  onChange={(e) => {
                    setOfficialData({
                      ...officialData,
                      officer: e,
                      officer_id: e?.id
                    });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "officer_id", value: e },
                        { required: true }
                      ),
                    });
                  }}
                  errors={errors?.officer_id}
                  color={errors?.officer_id?.required ? "danger" : ""}
                  disabled={!officialData.group}
                />
              </div>

              {/* phone number with country code */}
              <div className="col-md-6 col-12 row mx-0 mt-1">
                <label className="control-field__label col-12">{operationStatement.PhoneNumber}</label>
                {/* phone code */}
                <div className="col-4 company_phone_code">
                  <SelectField
                    label={guideRequests.code}
                    hasLabel={false}
                    id="officer-phone_code"
                    name="officer-phone_code"
                    placeholder={"Select"}
                    value={
                      officialData.officer?.phone_code?.flag ?
                        <img
                          src={`${fetchFlag}/${officialData.officer?.phone_code?.flag}`}
                          width="35px"
                          alt={officialData.officer.phone_code?.label}
                        />
                        :
                        officialData.officer.phone_code?.label || officialData.officer.phone_code
                    }
                    options={allCountries}
                    disabled={true}
                  />
                </div>
                {/* phone */}
                <div className="col-8 px-0">
                  <NumberField
                    type={guideRequests.phone}
                    label={operationStatement.PhoneNumber}
                    hasLabel={false}
                    id="officer-phone"
                    name="officer-phone"
                    value={officialData.officer.phone_number}
                    removeArrow={true}
                    placeholder={"000 0000 000"}
                    min={3}
                    extraTextPosition="prepend"
                    extraText={officialData.officer?.phone_code?.phone_code}
                    disabled={true}
                  />
                </div>
              </div>

              {/* email */}
              <div className="col-6">
                <TextField
                  label={guideRequests.email}
                  type="email"
                  id="officer-email"
                  name="officer-email"
                  value={officialData.officer.email}
                  disabled={true}
                />
              </div>
            </>
            :
            null
          }

          {showAttachFileInput &&
            <div className="col-12 mt-3">
              <UploadInput
                key={officialData.attach_type}
                label={`${operationStatement.attach} (PDF, Text, Doc)`}
                onUpload={handleUploadFile}
                accept=".pdf, .txt, .doc, .docx, image/png, image/jpeg"
                bucket="official"
                errorMsg={errors.attach_file?.required ? movementManagement.attachFile + " " + commons.isRequired : ""}
              />
            </div>
          }

        </div>


        {/* action button */}
        <div className='col-12 mt-3'>
          <button className="btn bg-nxt w-100 d-flex gap-10 align-items-center justify-content-center py-2"
            onClick={() => {
              let formErrors = checkFormErrors();
              if (isFormValid(formErrors)) {
                actionFn(officialData)
              }
            }}
            disabled={!officialData.attach_type}
          >
            <SaveIcon color="#D4A655" />
            {guideRequests.save}
          </button>
        </div>


      </ModalBody>
    </Modal>
  )
}