import { useWebBuilderState } from "context/webBuilder";
import EditableParagraph from "modules/WebBuilder-V2/shared/EditableParagraph";
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import React, { useState } from "react";
import { Collapse } from 'reactstrap';
import faqImageBG from "assets/images/webBuilder/theme10-dots-bg.png"

export default function FAQTheme10({
	title,
	handleFaqs,
	faqs,
	focusContainer,
	id,
	itemsBoxActions,
	faqImage,
	body
}) {

	
	const { selectedLanguage } = useWebBuilderState();
	const [collapsedItems, setCollapsedItems] = useState([]);


	return (
		<div className="faq-container">
			<div className="faq-questions">
				<EditableParagraph
					className="h4 font-weight-bold"
					contentEditable={true}
					onInput={(value) =>
						handleFaqs({
							value,
							name: "title",
							language: selectedLanguage,
						})
					}
					text={title}
				/>
				<EditableParagraph
					className="mb-3 body"
					contentEditable={true}
					onInput={(value) =>
						handleFaqs({
							value,
							name: "body",
							language: selectedLanguage,
						})
					}
					text={body}
				/>
				{faqs &&
					faqs?.map((que, index) => {
						const isOpen = collapsedItems.includes(index);
						const openCollapse = () => {
							const updated = [...collapsedItems, index];
							setCollapsedItems(updated);
						};
						const closeCollapse = () =>
							setCollapsedItems(
								collapsedItems.filter((i) => i !== index)
							);
						return (
							<div className="faqs-question-wrapper" key={que.id}>
								<EditBox
									editMode={focusContainer === id}
									actions={[itemsBoxActions(que.id)]}
									defaultIcon=""
									className="mb-2"
								>
									<div className="faqs-container-question">

										<EditableParagraph
											// className="faqs-container-body"
											contentEditable={true}
											onInput={(value) =>
												handleFaqs({
													value,
													name: "question",
													questionIndex: index,
													language: selectedLanguage,
												})
											}
											text={que.question[selectedLanguage]}
										/>

										{/* <span> {que.question[selectedLanguage]} </span> */}
										{isOpen ? (
											<button
												className="bg-transparent border-0 faq-btn"
												onClick={closeCollapse}
											>
												<i className="fas fa-chevron-up"></i>
											</button>
										) : (
											<button
												className="bg-transparent border-0 faq-btn"
												onClick={openCollapse}
											>
													<i className="fas fa-chevron-down"></i>
											</button>
										)}
									</div>
									<Collapse isOpen={isOpen}>
										<EditableParagraph
											className="faqs-container-body"
											contentEditable={true}
											onInput={(value) =>
												handleFaqs({
													value,
													name: "body",
													questionIndex: index,
													language: selectedLanguage,
												})
											}
											text={que.body[selectedLanguage]}
										/>
									</Collapse>
								</EditBox>
							</div>
						)
					})}
			</div>
			<div className="faq-image">
				<div className="abstract-bg" style={{ backgroundImage: `url(${faqImageBG})` }}></div>
				{faqImage}
			</div>
		</div>
	);
}
