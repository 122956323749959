import React, { useEffect, useState } from "react";
import moment from "moment";

// ** components
import SubscriptionPlanFilter from "./Filter";
import SubscriptionPlanList from "./List";
import SubscriptionPlanModel from "./models/SubscriptionPlanModel";
import Pagination from "components/Pagination";
import usePrintingSubscriptionInvoice from "./usePrintingSubscriptionInvoice";

// services
import { FetchEmailForAgree, SendSubscriptionOTP, subscriptionPlanList } from "services/subscriptionPlan";
import { useSBSState } from "context/global";
import Locale from "translations";
import SubscriptionTermsModel from './models/SubscriptionTermsModel';
import SubscriptionOTPModel from './models/SubscriptionOTPModel';
import { store } from 'react-notifications-component';

const SubscriptionPlan = () => {
	// ** vars
	const { locale } = useSBSState();
	const { subscriptionModule } = Locale;

	const initialFilters = {
		pages: 1,
		status: {},
		payment_status: {},
		date: undefined,
	};

	// ** states
	const [isOpen, setIsOpen] = useState(false);
	const [subscriptionList, setSubscriptionList] = useState([]);
	const [filters, setFilters] = useState(initialFilters);
	const [meta, setMeta] = useState(null);
	const [isTermsOpen, setIsTermsOpen] = useState(false);
	const [isOtpOpen, setIsOtpOpen] = useState(false);
	const [otp, setOtp] = useState("");
const [selectedId, setSelectedId] = useState(null)
	const toggleTerms=(id=null)=>{
		setIsTermsOpen(prev=>!prev);
		setSelectedId(id)
	}
	const [reload, setReload] = useState(false)
	const toggleOTP=()=>{
		setIsOtpOpen(prev=>!prev)
	}
	// ** hooks
	const {
		printSubscriptionInvoice,
		printFreeTrialInvoice,
		getAllModules,
		subscriptionModulesData,
		setPlanId,
	} = usePrintingSubscriptionInvoice();

	// ** toggle model
	const toggleSubscriptionPlanModal = () => {
		setIsOpen(!isOpen);
	};

	function formatFilters(filters) {
		let filteredData = {
			page: filters?.page,
			status: filters?.status?.label,
			payment_status: filters?.payment_status?.label,
			date: filters?.date ? moment(filters?.date).format("YYYY-MM-DD") : null,
		};
		return filteredData;
	}

	// ** get all subscription list
	const getSubscriptionList = async (type, page) => {
		let filtersData = formatFilters(
			type === "reset" ? initialFilters : { ...filters, page }
		);

		const res = await subscriptionPlanList(filtersData);
		if (res?.status === 200) {
			setSubscriptionList(res?.data?.data);
			setMeta(res?.data?.meta);
		}
	};

	// handle pagination
	function goTo(page) {
		getSubscriptionList(null, page);
	}

	// ** reset form filter
	function resetFilters() {
		setFilters(initialFilters);
		getSubscriptionList("reset");
	}

	useEffect(() => {
		getSubscriptionList();
	}, [filters?.page,reload]);

	const sentEmail=async(type=null)=>{
		const res=await FetchEmailForAgree(selectedId,{});
		if(res.status===200){
			store.addNotification({
				title: "info!",
				message: "Check Your Email ",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true,
					pauseOnHover: true,
				},onRemoval:()=>{
					if(type!=='resend'){
						toggleOTP();
					}
				}
			})
		}
		
	}

	const sentOTP=async()=>{
		const res=await SendSubscriptionOTP(selectedId,{code:otp});
		if(res.status===200){
			store.addNotification({
				title: "info!",
				message: "Send OTP Successfully ",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true,
					pauseOnHover: true,
				},onRemoval:()=>{
					toggleOTP();
					setSelectedId(null);
					setReload(prev=>!prev);
					setOtp("")
				}
			})
		}
		
	}
	return (
		<>
			<div className="subscription-plan-wrapper">
				<h3 className="subscription-title">
					{subscriptionModule.SubscriptionPlan}
				</h3>
				<p className="subscriptionPlan-text">
					
					{subscriptionModule.AllPlan} ({subscriptionList?.length})

				</p>
				<SubscriptionPlanFilter
					resetFilters={resetFilters}
					filters={filters}
					setFilters={setFilters}
					getSubscriptionList={getSubscriptionList}
				/>
				<SubscriptionPlanList
					setIsOpen={setIsOpen}
					isOpen={isOpen}
					subscriptionList={subscriptionList}
					getAllModules={getAllModules}
					printSubscriptionInvoice={printSubscriptionInvoice}
					printFreeTrialInvoice={printFreeTrialInvoice}
					setPlanId={setPlanId}
					toggleTerms={toggleTerms}
				/>
				<div className="mt-4 pb-3">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>
			{isOpen ? (
				<SubscriptionPlanModel
					isOpen={isOpen}
					toggleSubscriptionPlanModal={toggleSubscriptionPlanModal}
					subscriptionModulesData={subscriptionModulesData}
				/>
			) : null}

		{isTermsOpen&&	<SubscriptionTermsModel
				isOpen={isTermsOpen}
				setIsOpen={setIsTermsOpen}
				toggle={toggleTerms}
				sentEmail={sentEmail}
			/>}
		{	isOtpOpen&&<SubscriptionOTPModel
			isOpen={isOtpOpen}
			toggle={toggleOTP}
			otp={otp}
			setOtp={setOtp}
			sentOTP={sentOTP}
			sentEmail={sentEmail}

			/>}
		</>
	);
};

export default SubscriptionPlan;
