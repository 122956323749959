import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ReactComponent as ChangeEmailIcon } from "assets/images/Tds/change-email.svg";
import TextField from 'components/Form/TextField/TextField';
import validate, { isFormValid } from "helpers/validate";
import Locale from 'translations';


export default function ChangeEmailModal({ isOpen, toggle, visaData, setVisaData }) {
  const { tdsVisa, inventory } = Locale;
  const [newEmail, setNewEmail] = useState({
    email: null,
    confirmEmail: null,
    matchCurrentEmail: false,
  });
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const currentEmail = visaData?.email;

  function checkEmailErrors() {
    setErrors({
      ...errors,
      ...validate(
        { name: "email", value: newEmail.email },
        { required: true, email: true }
      ),
      ...validate(
        { name: "confirmEmail", value: newEmail.email === newEmail.confirmEmail },
        { required: true, confirm: true }
      ),
    })
  }


  function submit() {
    checkEmailErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  useEffect(() => {
    if (isFormValid(errors) && !newEmail.matchCurrentEmail) {
      setVisaData({ ...visaData, email: newEmail.email, isEmailVerified: false, isEmailDisabled: true });
      toggle();
    }
  }, [isErrorLoaded]);

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle} />

      <ModalBody>
        <div className='text-center d-flex flex-column align-items-center'>
          <h4 className='font-weight-bold my-2'>{tdsVisa.changeEmail}</h4>
          <ChangeEmailIcon className='my-2' />
          <p className='my-2'>
            {tdsVisa.yourCurrentEmail} :  <span className='font-weight-bold'> {currentEmail} </span>
          </p>
          <div className='d-flex flex-column w-100 my-2'>
            {/* new email */}
            <div className='mb-2'>
              <TextField
                label={tdsVisa.newEmail}
                value={newEmail.email}
                name="tds-new-email"
                id="tds-new-email"
                onChange={(e) => {
                  setNewEmail({ ...newEmail, email: e.target.value, matchCurrentEmail: e.target.value === currentEmail });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "email", value: e.target.value },
                      { required: true, email: true }
                    ),
                  });
                }}
                color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
                errors={errors?.email}
              />
            </div>
            {/* confirm email */}
            <TextField
              label={tdsVisa.confirmNewEmail}
              value={newEmail.confirmEmail}
              name="tds-confirm-email"
              id="tds-confirm-email"
              onChange={(e) => {
                setNewEmail({ ...newEmail, confirmEmail: e.target.value, matchCurrentEmail: newEmail.email === currentEmail });
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "confirmEmail", value: newEmail.email === e.target.value },
                    {
                      required: true,
                      confirm: true,
                    }
                  ),
                });
              }}
              color={errors?.confirmEmail?.required || errors?.confirmEmail?.confirm ? "danger" : ""}
              errors={errors?.confirmEmail}
            />
          </div>

          {newEmail.matchCurrentEmail && <p className='text-danger'>{tdsVisa.newEmailMustBeDifferent}</p>}

          <button className='btn align-self-end tds-generic-btn mt-3'
            onClick={submit}
          >
            {inventory.messages.save}
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}
