import React, { useState } from 'react'
import walletImg from "assets/images/wallet/wallet2.svg";
import Locale from 'translations';
import PriceBreakdownModal from './Modals/PriceBreakdownModal';
import { formatPrice } from 'helpers/utils';

function PriceBooking({ flightData }) {
  const { marketPlace, inventory } = Locale
  const [isOpen, setIsOpen] = useState()
  const togglePriceBreakdownModal = () => {
    setIsOpen(!isOpen)
  }
  const adultsChildrenCount = (+flightData?.adults) + (+flightData?.children);
  // adults
  const fareForAllAdults = (+flightData?.adtFare) * (+flightData?.adults) || 0;
  const taxForAllAdults = (+flightData?.adtTax) * (+flightData?.adults) || 0;
  const adultsTotalPrice = fareForAllAdults + taxForAllAdults + (+flightData?.tktFee) || 0;
  // children
  const fareForAllChildren = (+flightData?.chdFare) * (+flightData?.children) || 0;
  const taxForAllChildren = (+flightData?.chdTax) * (+flightData?.children) || 0;
  const childrenTotalPrice = fareForAllChildren + taxForAllChildren + (+flightData?.tktFee) || 0;

  return (
    <>
      <div className='bg-white p-2 text-capitalize'>
        <span className='text-right text-capitalize mb-2 pointer d-flex justify-content-end' onClick={togglePriceBreakdownModal}
          style={{ color: "#D29D4D", fontSize: "small", fontWeight: "bold", display: "inline-block", }}>
          {marketPlace.Pricebreakdown}
        </span>
        {/* adults price summary */}
        <div className='adults-price-summary'>
          <div className='d-flex justify-content-between price-color'>
            <h5 className='bold'>{flightData?.adults} X {inventory.messages.Adult}</h5>
            <h5 className='bold'>
              {formatPrice(fareForAllAdults + taxForAllAdults)} {" "}
              {flightData?.currency}
            </h5>
          </div>
          <div className='d-flex justify-content-between ms-4 price-color'>
            <div>
            <p>{inventory.messages.FarePerAdult}</p>
							<p>{inventory.messages.TaxesPerAdult}</p>
            </div>
            <div>
              <p>{formatPrice(flightData?.adtFare)} {" "} {flightData?.currency}</p>
              <p>{formatPrice(flightData?.adtTax)} {" "}  {flightData?.currency}</p>
            </div>
          </div>
        </div>
        {/* child price summary */}
        {flightData?.children > 0
          ?
          <div className='children-price-summary my-2'>
            <div className='d-flex justify-content-between price-color'>
              <h5 className='bold'>{flightData?.children} X {inventory.messages.Child}</h5>
              <h5 className='bold'>
                {formatPrice(fareForAllChildren + taxForAllChildren)}
                {flightData?.currency}
              </h5>
            </div>
            <div className='d-flex justify-content-between ms-4 price-color'>
              <div>
                <p>Fare Per Child</p>
                <p>Taxes Per Child</p>
              </div>
              <div>
                <p>{formatPrice(flightData?.chdFare)} {" "} {flightData?.currency}</p>
                <p>{formatPrice(flightData?.chdTax)} {" "}  {flightData?.currency}</p>
              </div>
            </div>
          </div>
          :
          null
        }

        {/* tickets fees */}
        <div className='d-flex justify-content-between price-color'>
          <h5 className='bold'>{adultsChildrenCount} X Ticketing Fee</h5>
          <h5 className='bold'>
            {formatPrice(+flightData?.tktFee)}
            {flightData?.currency}
          </h5>
        </div>
      </div>
      {/* total price and penalties */}
      <div className='d-flex mt-4 justify-content-between align-items-end'>
        <div className='d-flex align-items-end'>
          <img src={walletImg} alt="" />
          <div className='mx-2'>
            <p style={{ fontSize: "14px", lineHeight: "1" }}>{marketPlace.total}</p>
            <p style={{ fontSize: "16px", color: "#28A745", fontWeight: "bold", lineHeight: "1" }}>
              {flightData?.totalPrice >0? formatPrice(flightData?.totalPrice) :null}{" "}
              {flightData?.currency}
            </p>
          </div>
        </div>
        <span className="roules text-right">Not Including Payment Fee</span>
      </div>
      <p className='roules p-1 mt-4'>
        {marketPlace.flightpromises}
      </p>
      <PriceBreakdownModal 
        isOpen={isOpen}
        toggle={togglePriceBreakdownModal}
        flightData={flightData}
        adultsChildrenCount={adultsChildrenCount}
        adultsTotalPrice={adultsTotalPrice}
        childrenTotalPrice={childrenTotalPrice}
      />
    </>
  )
}

export default PriceBooking