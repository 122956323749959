import React from "react";

import moment from "moment";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Locale from 'translations';

export default function IssueProcessing({ issueData }) {
	const history = useHistory();
	const { id } = useParams();
	const { inventory, marketPlace } = Locale;

	const pindingStatus = issueData.status === "pending";
	const canceledStatus = issueData.status === "canceled";
	const expiredStatus = issueData.status === "expired";
	const invalidStatus = canceledStatus && expiredStatus;

	return (
		<>
			<div className="flight_item my-3 d-flex justify-content-between align-items-center py-2 px-3">
				{issueData.status === "issued" ? (
					<>
						<div className="d-flex justify-content-between align-items-center w-100 ">
							<div className="text-success">
								<i class="far fa-check-square text-success me-1"></i>
								<span className="bold">Issued</span>
							</div>
							<div className="d-flex justify-content-between">
								<button
									className="btn btn-light terms-color bold ms-2 px-4 py-2"
									onClick={() =>
										history.push(`/inventory/flight/reservation/${id}/change`)
									}
								>
									{marketPlace.change}
								</button>
								<button
									className="btn btn-light terms-color bold ms-2 px-4 py-2"
									onClick={() =>
										history.push(`/inventory/flight/reservation/${id}/refund`)
									}
								>
									{marketPlace.refund}
								</button>
								{issueData.isVoid && issueData.status === "issued" && (
									<button
										className="btn btn-light terms-color bold ms-2 px-4 py-2"
										onClick={() =>
											history.push(`/inventory/flight/reservation/${id}/void`)
										}
									>
										{marketPlace.void}
									</button>
								)}
							</div>
						</div>
					</>
				) : (
					<>
						<p
							className={`bold ${
								invalidStatus ? "text-danger" : "terms-color"
							}`}
						>
							{pindingStatus && (
								<i class="fas fa-hourglass-half me-2 terms-color"></i>
							)}
							{pindingStatus ? `${marketPlace.IssueProcessing}` : issueData.status}
						</p>
						{pindingStatus && (
							<div className="d-flex">
								<p className="bold">
									{moment(issueData?.orderDate).format("HH:mm")}
								</p>
								<div className="text-center p-1 px-3">
									<p className="spent mt-2" style={{ borderColor: "#EDEDED" }}>
										<span>{marketPlace.TicketWillIssued}										</span>
									</p>
								</div>
								<p className="bold">
									{moment(issueData?.orderDate).add(2, "hours").format("HH:mm")}
								</p>
							</div>
						)}
					</>
				)}
			</div>
		</>
	);
}
