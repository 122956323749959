import React, { useState } from "react";
import { ReactComponent as DeleteIcon } from "assets/images/subscription-plan/close-circle.svg";
import { ReactComponent as CheckIcon } from "assets/images/subscription-plan/check.svg";
import { ReactComponent as InfoIcon } from "assets/images/subscription-plan/info-circle.svg";
import { ReactComponent as DownloadIcon } from "assets/images/umrah-operations/download-cloud.svg";
import moment from "moment";
import Locale from "translations";
import NewPaymentAction from "components/NewPaymentAction";
import { paySubscriptionPlan } from "services/subscriptionPlan";

const SubscriptionPlanList = ({
	setIsOpen,
	isOpen,
	subscriptionList,
	getAllModules,
	printSubscriptionInvoice,
	printFreeTrialInvoice,
	toggleTerms,
}) => {
	const { subscriptionModule } = Locale;
	const [paymentModalOpen, setPaymentModalOpen] = useState(false);
	const [selectedPlan, setSelectedPlan] = useState(null);
	const [actionType, setActionType] = useState("");
	const [formData, setFormData] = useState({});

	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK
		? JSON.parse(process.env.REACT_APP_OTP_CHECK)
		: false;

	function togglePaymentModal() {
		setActionType("");
		setFormData({});
		setPaymentModalOpen((prev) => !prev);
	}

	async function pay(type, currency, otp) {
		const data = {
			payment_method: type,
			currency,
		};
		const payRes = await paySubscriptionPlan(selectedPlan?.id, data);
		if (payRes.status === 200) {
			togglePaymentModal();
			window.location.reload();
		}
	}

	const handleSubscriptionModules = (planId) => {
		setIsOpen(!isOpen);
		if (planId) {
			getAllModules(planId);
		}
	};

	const downloadInvoice = (planId, isFreeTrial) => {
		if (planId) {
			if (isFreeTrial === 1) printFreeTrialInvoice(planId);
			else printSubscriptionInvoice(planId);
		}
	};

	const statusTranslate = (status) => {
		switch (status) {
			case "new":
				return subscriptionModule.new;
			case "paid":
				return subscriptionModule.paid;
			case "pending":
				return subscriptionModule.pending;
			case "canceled":
				return subscriptionModule.canceled;
			case "free":
				return subscriptionModule.free;
			case "expired":
				return subscriptionModule.expired;
			case "active":
				return subscriptionModule.active;
			case "inactive":
				return subscriptionModule.inactive;
			default:
				return status;
		}
	};

	return (
		<div className="table-responsive table-bordered">
			<table className="table">
				<thead className="thead-bg">
					<tr>
						<th className="scope">{subscriptionModule.RefNo}</th>
						<th className="scope">{subscriptionModule.PlanDuration}</th>
						<th className="scope"> {subscriptionModule.Amount}</th>
						<th className="scope"> {subscriptionModule.FreeTrial}</th>
						<th className="scope"> {subscriptionModule.OptionDate}</th>
						<th className="scope"> {subscriptionModule.Status}</th>
						<th className="scope"> {subscriptionModule.PaymentStatus}</th>
						<th className="scope"> {subscriptionModule.Actions}</th>
					</tr>
				</thead>

				<tbody>
					{subscriptionList?.map((ele) => (
						<tr key={ele?.id}>
							<td>{ele?.id ? `#${ele?.id}` : "_"}</td>
							<td>
								{moment(ele?.start_date).format("DD/MM/YYYY")} -{" "}
								{moment(ele?.end_date).format("DD/MM/YYYY")}
							</td>

							<td>{ele?.price ? `${ele?.price} ${ele?.currency}` : "_"}</td>
							<td>
								{ele?.is_free_trial === 0 ? <DeleteIcon /> : <CheckIcon />}
							</td>

							<td>
								<span>
									{ele?.payment_expiration
										? moment(ele?.payment_expiration).format("DD/MM/YYYY")
										: "-"}
								</span>
							</td>
							<td>
								<div
									className={`payment-status-color text-capitalize ${
										ele?.status === "canceled"
											? "payment-status-color-cancelled"
											: ele?.status === "expired"
											? "payment-status-color-expired"
											: ele?.status === "active"
											? "payment-status-color-success"
											: "payment-status-color-expired"
									}`}
								>
									{statusTranslate(ele?.status)}
									{/* {ele?.status} */}
								</div>
							</td>

							<td>
								<div
									className={`payment-status-color text-capitalize ${
										ele?.payment_status === "pending"
											? "payment-status-color-pending"
											: ele?.payment_status === "free"
											? "payment-status-color-free"
											: ele?.payment_status === "paid"
											? "payment-status-color-success"
											: "payment-status-color-expired"
									}`}
								>
									{statusTranslate(ele?.payment_status)}
									{/* {ele?.payment_status} */}
								</div>
							</td>
							{/* pay button */}
							<td className="column-flex">
								{ele?.payment_status === "pending" &&
								ele?.status === "inactive" &&
								ele?.accept_terms ? (
									<button
										className="btn pay-subscription-plan-btn"
										onClick={() => {
											togglePaymentModal();
											setSelectedPlan(ele);
										}}
									>
										{subscriptionModule.payNow}
									</button>
								) : null}

							{!ele?.accept_terms&&	<button
									className="btn btn-link"
									onClick={() => {
										toggleTerms(ele?.id);
									}}
								>
									<i
										class="far fa-file-alt"
										style={{ color: "#d4a655", fontSize: "18px" }}
									></i>
								</button>}

								<button
									className="btn payment-status-color payment-status-color-download"
									onClick={() =>
										downloadInvoice(
											ele?.id,
											ele?.is_free_trial,
											ele?.payment_status
										)
									}
								>
									<DownloadIcon />
									<span>{subscriptionModule.Invoice}</span>
								</button>

								<button
									className="btn"
									onClick={() => handleSubscriptionModules(ele?.id)}
								>
									<InfoIcon />
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{paymentModalOpen && selectedPlan ? (
				<NewPaymentAction
					isOpen={paymentModalOpen}
					toggleModal={togglePaymentModal}
					canPayLater={false}
					formData={formData}
					actionType={actionType}
					Price={selectedPlan?.pending_payment_amount}
					currency={selectedPlan?.currency}
					onPayment={pay}
					setActionType={setActionType}
					setFormData={setFormData}
					OTP={OTP_CHECK}
				/>
			) : null}
		</div>
	);
};

export default SubscriptionPlanList;
