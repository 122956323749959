import "scss/pr.scss"
export default function PrivacyPolicy(props) {

	// ----------------------------------------------------------------------------------------------------
	// Mini Components UI
	// ----------------------------------------------------------------------------------------------------
	
	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	return (
		<>
		
			<div className="bg-layout  login-page PrivacyPolicy">
			<div className="col-md-12" >

<div className="container">
	<div className="c11 doc-content">
		<div>
			<p className="c10 c12"><span className="c15"></span></p>
		</div>
		<p className="c0"><span className="c16">Privacy Policy for SBS</span></p>
		<h1 className="c0" id="h.z8gtgprc4fry"><span className="c8">Privacy Policy</span></h1>
		<p className="c2"><span className="c1">Last updated: March 13, 2023</span></p>
		<p className="c2"><span className="c1">This Privacy Policy describes Our policies and procedures on the collection, use
				and
				disclosure of Your information when You use the Service and tells You about Your privacy rights and how the
				law
				protects You.</span></p>
		<p className="c2"><span className="c13">We use Your Personal data to provide and improve the Service. By using the
				Service,
				You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy
				has been created with the help of the </span><span className="c13"><a className="c14"
					href="https://www.google.com/url?q=https://www.freeprivacypolicy.com/free-privacy-policy-generator/&amp;sa=D&amp;source=editors&amp;ust=1679480846767380&amp;usg=AOvVaw1AVI5GzNEcTorwBhva_1Lf">Free
					Privacy Policy Generator</a></span><span className="c1">.</span></p>
		<h1 className="c0" id="h.lwkzc0ky86r"><span className="c8">Interpretation and Definitions</span></h1>
		<h2 className="c0" id="h.13l6lv3be7s7"><span className="c9">Interpretation</span></h2>
		<p className="c2"><span className="c1">The words of which the initial letter is capitalized have meanings defined under
				the
				following conditions. The following definitions shall have the same meaning regardless of whether they appear
				in
				singular or in plural.</span></p>
		<h2 className="c0" id="h.6em263uo42s7"><span className="c9">Definitions</span></h2>
		<p className="c2"><span className="c1">For the purposes of this Privacy Policy:</span></p>
		<ul className="c3 lst-kix_cvte9mtw6gjc-0 start">
			<li className="c4 li-bullet-0"><span className="c5">Account</span><span className="c1">&nbsp;means a unique account created
					for You to access our Service or parts of our Service.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">Affiliate</span><span className="c1">&nbsp;means an entity that
					controls,
					is controlled by or is under common control with a party, where "control" means ownership of 50% or more of
					the shares, equity interest or other securities entitled to vote for election of directors or other managing
					authority.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">Application</span><span className="c1">&nbsp;refers to The Egyptian
					pilgrim, the software program provided by the Company.</span></li>
			<li className="c4 li-bullet-0"><span className="c13">Ministry</span><span className="c1">&nbsp;refers to the Ministry of
					Tourism and Antiquities, Zamalek head office 3 El Adel Abou Bakr St., Zamalek , Cairo.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">Country</span><span className="c1">&nbsp;refers to: Egypt</span></li>
			<li className="c4 li-bullet-0"><span className="c5">Device</span><span className="c1">&nbsp;means any device that can access
					the Service such as a android.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">Personal Data</span><span className="c1">&nbsp;is any information that
					relates to an identified or identifiable individual.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">Service</span><span className="c1">&nbsp;refers to the
					Application.</span>
			</li>
			<li className="c4 li-bullet-0"><span className="c5">Service Provider</span><span className="c1">&nbsp;means any natural or
					legal person who processes the data on behalf of the Company. It refers to third-party companies or
					individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the
					Company, to perform services related to the Service or to assist the Company in analyzing how the Service is
					used.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">Usage Data</span><span className="c1">&nbsp;refers to data collected
					automatically, either generated by the use of the Service or from the Service infrastructure itself (for
					example, the duration of a page visit).</span></li>
			<li className="c4 li-bullet-0"><span className="c5">You</span><span className="c1">&nbsp;means the individual accessing or
					using the Service, or the company, or other legal entity on behalf of which such individual is accessing or
					using the Service, as applicable.</span></li>
		</ul>
		<h1 className="c0" id="h.1nq2fa1n7yln"><span className="c8">Collecting and Using Your Personal Data</span></h1>
		<h2 className="c0" id="h.pw3unzsu0of3"><span className="c9">Types of Data Collected</span></h2>
		<h3 className="c0" id="h.8qecy68rfjma"><span className="c7">Personal Data</span></h3>
		<p className="c2"><span className="c1">While using Our Service, We may ask You to provide Us with certain personally
				identifiable information that can be used to contact or identify You. Personally identifiable information may
				include, but is not limited to:</span></p>
		<ul className="c3 lst-kix_88huk5lckt1-0 start">
			<li className="c4 li-bullet-0"><span className="c1">Email address</span></li>
			<li className="c4 li-bullet-0"><span className="c1">Phone number</span></li>
			<li className="c4 li-bullet-0"><span className="c1">Usage Data</span></li>
		</ul>
		<h3 className="c0" id="h.tp63407miv0s"><span className="c7">Usage Data</span></h3>
		<p className="c2"><span className="c1">Usage Data is collected automatically when using the Service.</span></p>
		<p className="c2"><span className="c1">Usage Data may include information such as Your Device's Internet Protocol address
				(e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date
				of
				Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</span></p>
		<p className="c2"><span className="c1">When You access the Service by or through a mobile device, We may collect certain
				information automatically, including, but not limited to, the type of mobile device You use, Your mobile
				device
				unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet
				browser You use, unique device identifiers and other diagnostic data.</span></p>
		<p className="c2"><span className="c1">We may also collect information that Your browser sends whenever You visit our
				Service or when You access the Service by or through a mobile device.</span></p>
		<h3 className="c0" id="h.siw7s8s5i3u0"><span className="c7">Information Collected while Using the Application</span></h3>
		<p className="c2"><span className="c1">While using Our Application, in order to provide features of Our Application, We
				may
				collect, with Your prior permission:</span></p>
		<ul className="c3 lst-kix_7n24p97ezjr2-0 start">
			<li className="c4 li-bullet-0"><span className="c1">Information regarding your location</span></li>
		</ul>
		<p className="c2"><span className="c1">We use this information to provide features of Our Service, to improve and
				customize
				Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it
				may be simply stored on Your device.</span></p>
		<p className="c2"><span className="c1">You can enable or disable access to this information at any time, through Your
				Device
				settings.</span></p>
		<h2 className="c0" id="h.v4yu6qvc12jk"><span className="c9">Use of Your Personal Data</span></h2>
		<p className="c2"><span className="c1">The Company may use Personal Data for the following purposes:</span></p>
		<ul className="c3 lst-kix_bxq2hd72o4bw-0 start">
			<li className="c4 li-bullet-0"><span className="c5">To provide and maintain our Service</span><span className="c1">,
					including
					to monitor the usage of our Service.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">To manage Your Account:</span><span className="c1">&nbsp;to manage Your
					registration as a user of the Service. The Personal Data You provide can give You access to different
					functionalities of the Service that are available to You as a registered user.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">For the performance of a contract:</span><span className="c1">&nbsp;the
					development, compliance and undertaking of the purchase contract for the products, items or services You
					have
					purchased or of any other contract with Us through the Service.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">To contact You:</span><span className="c1">&nbsp;To contact You by
					email,
					telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's
					push notifications regarding updates or informative communications related to the functionalities, products
					or
					contracted services, including the security updates, when necessary or reasonable for their
					implementation.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">To provide You</span><span className="c1">&nbsp;with news, special
					offers
					and general information about other goods, services and events which we offer that are similar to those that
					you have already purchased or enquired about unless You have opted not to receive such information.</span>
			</li>
			<li className="c4 li-bullet-0"><span className="c5">To manage Your requests:</span><span className="c1">&nbsp;To attend and
					manage Your requests to Us.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">For business transfers:</span><span className="c1">&nbsp;We may use Your
					information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or
					other
					sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy,
					liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the
					assets transferred.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">For other purposes</span><span className="c1">: We may use Your
					information for other purposes, such as data analysis, identifying usage trends, determining the
					effectiveness
					of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your
					experience.</span></li>
		</ul>
		<p className="c2"><span className="c1">We may share Your personal information in the following situations:</span></p>
		<ul className="c3 lst-kix_qnuvvlsuzoew-0 start">
			<li className="c4 li-bullet-0"><span className="c5">With Service Providers:</span><span className="c1">&nbsp;We may share
					Your
					personal information with Service Providers to monitor and analyze the use of our Service, to contact
					You.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">For business transfers:</span><span className="c1">&nbsp;We may share or
					transfer Your personal information in connection with, or during negotiations of, any merger, sale of
					Company
					assets, financing, or acquisition of all or a portion of Our business to another company.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">With Affiliates:</span><span className="c1">&nbsp;We may share Your
					information with Our affiliates, in which case we will require those affiliates to honor this Privacy
					Policy.
					Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies
					that We control or that are under common control with Us.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">With business partners:</span><span className="c1">&nbsp;We may share
					Your
					information with Our business partners to offer You certain products, services or promotions.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">With other users:</span><span className="c1">&nbsp;when You share
					personal
					information or otherwise interact in the public areas with other users, such information may be viewed by
					all
					users and may be publicly distributed outside.</span></li>
			<li className="c4 li-bullet-0"><span className="c5">With Your consent</span><span className="c1">: We may disclose Your
					personal information for any other purpose with Your consent.</span></li>
		</ul>
		<h2 className="c0" id="h.ie8n4zft6qoo"><span className="c9">Retention of Your Personal Data</span></h2>
		<p className="c2"><span className="c1">The Company will retain Your Personal Data only for as long as is necessary for the
				purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to
				comply with our legal obligations (for example, if we are required to retain your data to comply with
				applicable
				laws), resolve disputes, and enforce our legal agreements and policies.</span></p>
		<p className="c2"><span className="c1">The Company will also retain Usage Data for internal analysis purposes. Usage Data
				is
				generally retained for a shorter period of time, except when this data is used to strengthen the security or
				to
				improve the functionality of Our Service, or We are legally obligated to retain this data for longer time
				periods.</span></p>
		<p className="c2 c10"><span className="c1"></span></p>
		<h2 className="c0" id="h.7epltwpev83o"><span className="c9">Disclosure of Your Personal Data</span></h2>
		<h3 className="c0" id="h.rh5on5d382cw"><span className="c7">Business Transactions</span></h3>
		<p className="c2"><span className="c1">If the Company is involved in a merger, acquisition or asset sale, Your Personal
				Data
				may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a
				different Privacy Policy.</span></p>
		<h3 className="c0" id="h.21m11vtckq4d"><span className="c7">Law enforcement</span></h3>
		<p className="c2"><span className="c1">Under certain circumstances, the Company may be required to disclose Your Personal
				Data if required to do so by law or in response to valid requests by public authorities .</span></p>
		<h3 className="c0" id="h.v9q8vtubejx4"><span className="c7">Other legal requirements</span></h3>
		<p className="c2"><span className="c1">The Company may disclose Your Personal Data in the good faith belief that such
				action
				is necessary to:</span></p>
		<ul className="c3 lst-kix_ewoztdy34bug-0 start">
			<li className="c4 li-bullet-0"><span className="c1">Comply with a legal obligation</span></li>
			<li className="c4 li-bullet-0"><span className="c1">Protect and defend the rights or property of the Company</span></li>
			<li className="c4 li-bullet-0"><span className="c1">Prevent or investigate possible wrongdoing in connection with the
					Service</span></li>
			<li className="c4 li-bullet-0"><span className="c1">Protect the personal safety of Users of the Service or the
					public</span></li>
			<li className="c4 li-bullet-0"><span className="c1">Protect against legal liability</span></li>
		</ul>
		<h2 className="c0" id="h.fepue47mc5x"><span className="c9">Security of Your Personal Data</span></h2>
		<p className="c2"><span className="c1">The security of Your Personal Data is important to Us, but remember that no method
				of
				transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use
				commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</span>
		</p>
		<h1 className="c0" id="h.451u7qnw093f"><span className="c8">Children's Privacy</span></h1>
		<p className="c2"><span className="c1">Our Service does not address anyone under the age of 13. We do not knowingly
				collect
				personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You
				are
				aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have
				collected Personal Data from anyone under the age of 13 without verification of parental consent, We take
				steps
				to remove that information from Our servers.</span></p>
		<p className="c2"><span className="c1">If We need to rely on consent as a legal basis for processing Your information and
				Your country requires consent from a parent, We may require Your parent's consent before We collect and use
				that
				information.</span></p>
		<h1 className="c0" id="h.9gdhgwa7cn4s"><span className="c8">Links to Other Websites</span></h1>
		<p className="c2"><span className="c1">Our Service may contain links to other websites that are not operated by Us. If You
				click on a third party link, You will be directed to that third party's site. We strongly advise You to review
				the Privacy Policy of every site You visit.</span></p>
		<p className="c2"><span className="c1">We have no control over and assume no responsibility for the content, privacy
				policies or practices of any third party sites or services.</span></p>
		<h1 className="c0" id="h.rzpytdb0kgaf"><span className="c8">Changes to this Privacy Policy</span></h1>
		<p className="c2"><span className="c1">We may update Our Privacy Policy from time to time. We will notify You of any
				changes
				by posting the new Privacy Policy on this page.</span></p>
		<p className="c2"><span className="c1">We will let You know via email and/or a prominent notice on Our Service, prior to
				the
				change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</span></p>
		<p className="c2"><span className="c1">You are advised to review this Privacy Policy periodically for any changes. Changes
				to this Privacy Policy are effective when they are posted on this page.</span></p>
		<h1 className="c0" id="h.u2f4ezcbj2bd"><span className="c8">Contact Us</span></h1>
		<p className="c2"><span className="c1">If you have any questions about this Privacy Policy, You can contact us:</span></p>
		<ul className="c3 lst-kix_ao0gwnmf6zkx-0 start">
			<li className="c4 li-bullet-0"><span className="c1">By email: info@savavisa.Com</span></li>
		</ul>
		<p className="c6"><span className="c13">Generated using </span><span className="c13"><a className="c14">Free
					Privacy Policy Generator</a></span></p>
		<p className="c12 c10"><span className="c15"></span></p>
	</div>
</div>
</div>
			</div>
		</>
	);
}
