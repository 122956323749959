import ArrowRightIcon from "modules/market-place/NewMarketplace/icons/arrowRightIcon";
import RoomRow from "./roomRow";
import Locale from "translations";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useMemo, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import createDOMPurify from "dompurify";
import moment from "moment";
import { useMarketplaceDispatch } from 'context/marketplace';

export default function AvailableRooms({
	setOptionId,
	item,
	hotelRate,
	sethotelRate,
}) {

	const { productsBuilder, inventory, marketPlace } = Locale;
	const [selectedData, setSelectedData] = useState({});
	const DOMPurify = createDOMPurify(window);
	const dispatch = useMarketplaceDispatch();
	
	const [modalTerms, setModalTerms] = useState(false);
	const toggleModalTerms = () => setModalTerms(!modalTerms);

	const [modalDays, setModalDays] = useState(false);
	const toggleModalDays = () => setModalDays(!modalDays);

	const [NoteReta, setNoteReta] = useState(false);
	const toggleNoteReta = () => setNoteReta(!NoteReta);

	const [CancellationModel, setCancellationModel] = useState(false);
	const toggleCancellationModel = () =>
		setCancellationModel(!CancellationModel);

	const [DataS, setDataS] = useState({});

	const addSelectedRoom = (e, room, rate) => {
		if (Object.keys(DataS).length === 0) {
			if (e.value!=0) {
				let SelectedRoom = { ...room };
				SelectedRoom.room_rates = [];
				SelectedRoom.room_rates.push({ ...rate, count: e.value });
				setDataS({ ...item, ...hotelRate.hotel, room: [SelectedRoom] });
			}
		
		} else {
			if (DataS.id === item.id) {
				if (DataS.room.filter((res) => res.code === room?.code).length > 0) {
					let spRoom = DataS.room.filter((res) => res.code === room?.code)[0];
					if (
						spRoom.room_rates.filter(
							(res) => res.room_total_price == rate.room_total_price
						).length > 0
					) {
						let data = { ...DataS };
						let IndexRoom = data.room.findIndex(
							(res) => res.code === room.code
						);
						let IndexRate = data.room[IndexRoom].room_rates.findIndex(
							(res) => res.room_total_price == rate.room_total_price
						);
						if (e.value===0) {
							delete data.room[IndexRoom]
							data.room= data.room.filter(function (el) {
								return el != null;
							});
						}else{
							data.room[IndexRoom].room_rates[IndexRate] = {
								...data.room[IndexRoom].room_rates[IndexRate],
								count: e.value,
							};
						}
						
						
						setDataS({ ...DataS, ...data });
					}
				} else {
					if (e.value!=0) {
					let roomD = [...DataS.room];
					let SelectedRoom = { ...room };
					SelectedRoom.room_rates = [];
					SelectedRoom.room_rates.push({ ...rate, count: e.value });
					roomD.push(SelectedRoom);
					setDataS({ ...DataS, room: roomD });
					}
				}
			} else {
				if (e.value!=0) {
					let SelectedRoom = { ...room };
					SelectedRoom.room_rates = [];
					SelectedRoom.room_rates.push({ ...rate, count: e.value });
					setDataS({ ...item, ...hotelRate.hotel, room: [SelectedRoom] });
				}
			}
		}
	};


	const history = useHistory();
	let x = hotelRate?.hotel?.currency !== hotelRate?.hotel?.providerCurrency ? "exchange_room_rates":"room_rates"
	const AllRooms = useMemo(() => {
		return hotelRate.hotel.rooms.map((room, index) =>
			room[x] && room[x].length > 0
				? room[x].map((rate) => (
						<RoomRow
							sethotelRate={sethotelRate}
							addSelectedRoom={addSelectedRoom}
							key={index}
							room={room}
							item={item}
							rate={rate}
							toggleNoteReta={toggleNoteReta}
							toggleCancellationModel={toggleCancellationModel}
							toggleModalDays={toggleModalDays}
							toggleModalTerms={toggleModalTerms}
							setSelectedData={setSelectedData}
							hotelRate={hotelRate}
						/>
				  ))
				: null
		);
	}, [JSON.stringify(hotelRate), JSON.stringify(DataS)]);
	return (
		<div className="pricing">
			<div className="availableRooms">
				<div className="select-option">
					<div className="head">
						<h6>{productsBuilder.availableRooms}</h6>
						<button
							className="btn btn-light"
							onClick={() => setOptionId((prev) => !prev)}
						>
							<ArrowRightIcon /> {productsBuilder.BackToAvailableDates}
						</button>
					</div>
					{AllRooms}
				</div>
				<div className="d-flex justify-content-end requestResAction ">
			
					<button
					disabled={!DataS?.room||DataS?.room?.length ===0}
						onClick={() => {
							
							dispatch({
								type: "saveHotelsSearch",
								payload: { ...DataS },
							});
							history.push("/market-place-new/umrah/special-allotment/summary");
						}}
					>
						{productsBuilder.requestReservation}
					</button>
				</div>

				<Modal
					size="lg"
					className="mt-8"
					isOpen={modalTerms}
					toggle={toggleModalTerms}
				>
					<ModalHeader
						className=" align-items-center"
						toggle={toggleModalTerms}
					>
						{selectedData?.room?.name}
					</ModalHeader>
					<ModalBody className="mx-3">
						<div className="row">
							<div className="col-md-12 bg-text p-4">
								<h6 className="d-flex h5 font-weight-bold">
									{marketPlace.messages.Roomoccupancy}
								</h6>
								<div className="col-md-12 ">
									<p className="d-flex">
										<span className="">
											{marketPlace.messages.MaximumAdults} :
										</span>
										<strong className="font-weight-bold ">
											{selectedData?.room?.paxes?.maxAdult}
										</strong>
									</p>
									<p className="d-flex">
										<span className="">
											{marketPlace.messages.MaximumChildren} :
										</span>

										<strong className="font-weight-bold ">
											{selectedData?.room?.paxes?.maxChildren}
										</strong>
									</p>
									<p className="d-flex">
										<span className="">
											{marketPlace.messages.maxExtraBed} :
										</span>
										<strong className="font-weight-bold ">
											{selectedData?.room?.paxes?.maxExtraBed}
										</strong>
									</p>
								</div>
							</div>
							{selectedData?.room?.features &&
							selectedData?.room?.features.length > 0 ? (
								<div className="col-md-12 bg-text p-4 mt-3">
									<p className="h5 font-weight-bold">
										{marketPlace.messages.ROOMAMENITIES}
									</p>
									<div className="col-md-12 ">
										<div className="row">
											{selectedData?.room?.features.map((res) => {
												return (
													<div className="col-md-4 text-muted">
														<i className="fa fa-check px-2"></i>
														{res}
													</div>
												);
											})}
										</div>
									</div>
								</div>
							) : null}
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							className="bg-gray-150 text-dark border-0 px-4"
							onClick={toggleModalTerms}
						>
							{inventory.messages.cancel}
						</Button>
					</ModalFooter>
				</Modal>

				<Modal
					size="md"
					isOpen={modalDays}
					toggle={toggleModalDays}
					style={{ maxWidth: "500px !important" }}
				>
					<ModalHeader className="align-items-center" toggle={toggleModalDays}>
						{inventory.messages.DailyRates}
					</ModalHeader>
					<ModalBody className="mx-3">
						<div className="row">
							<div className="col-md-12 bg-text p-4">
								{/* <h6 className="h5 font-weight-bold">
								{marketPlace.messages.Roomoccupancy}
							</h6> */}
								<div className="col-md-12 ">
									<p className="d-flex">
										<span className="font-weight-bold">
											{inventory.messages.date}
										</span>
										&nbsp;
										<strong className="font-weight-bold ">
											{inventory.messages.Price}
										</strong>
									</p>
									{selectedData?.room_rate?.dates.map((res) => {
										return (
											<p className="d-flex">
												{" "}
												<span className="w-105 mx-2">
													{moment(res.date).format("DD-MM-YYYY")}{" "}
												</span>
												<strong className="w-105">
													{res.total_price}{" "}
													{selectedData?.room_rate?.room_price_currency}
												</strong>
											</p>
										);
									})}
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							className="bg-gray-150 text-dark border-0 px-4"
							onClick={toggleModalDays}
						>
							{inventory.messages.cancel}
						</Button>
					</ModalFooter>
				</Modal>

				<Modal size="lg" isOpen={NoteReta} toggle={toggleNoteReta}>
					<ModalHeader className="align-items-center" toggle={toggleNoteReta}>
						{inventory.messages.rateNotes}
					</ModalHeader>
					<ModalBody className="mx-3">
						<div className="row">
							<div className="row">
								<p className="h5 font-weight-bold">
									{/* {marketPlace.messages.termsAndConditions} */}
								</p>
								<div className="col-md-12 text-left">
									<div
										dangerouslySetInnerHTML={{
											__html: DOMPurify.sanitize(
												selectedData?.room_rate?.termsAndConditions
											),
										}}
									/>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							className="bg-gray-150 text-dark border-0 px-4"
							onClick={toggleNoteReta}
						>
							{inventory.messages.cancel}
						</Button>
					</ModalFooter>
				</Modal>

				<Modal
					size="lg"
					isOpen={CancellationModel}
					toggle={toggleCancellationModel}
					className="mt-8 T-cancellation-modal"
				>
					<ModalHeader
						className="align-items-center"
						toggle={toggleCancellationModel}
					>
						{inventory.messages.CancellationAndAmendmentsPolicies}
					</ModalHeader>
					<ModalBody className="mx-3">
						<div className="col-md-11 m-auto">
							<div className="row">
								{selectedData?.room_rate?.cancellation_policy.map((res) => {
									return (
										<>
											{!res.hasOwnProperty("fromDate") ? (
												<>
													<p className="w-100 mt-2">
														{inventory.messages.before} {res.toDate}
													</p>
													<div className="col">
														{res.cancelCharge == 0 ? (
															<div className="boxCancellation bg-Ground">
																<p>{inventory.messages.noCancellationCharge}</p>
															</div>
														) : res.cancelRestricted ? (
															<div className="boxCancellation bg-dangerr">
																<p>
																	{" "}
																	{inventory.messages.cancellationRestricted}
																</p>
															</div>
														) : (
															<div className="boxCancellation">
																{inventory.messages.cancellationCharge}
																<p>
																	{" "}
																	{res.cancelCharge}{" "}
																	{selectedData?.room_rate?.room_price_currency}
																</p>
															</div>
														)}
													</div>
													<>
														{res.amendCharge == 0 ? (
															<div className="col ">
																<div className="boxCancellation bg-Ground">
																	<p>{inventory.messages.noAmendCharge}</p>
																</div>
															</div>
														) : res.amendRestricted ? (
															<div className="col ">
																<div className="boxCancellation bg-dangerr">
																	{inventory.messages.amendRestricted}
																</div>
															</div>
														) : hotelRate.gds != 5 && hotelRate.gds != 6 ? (
															<div className="col ">
																<div className="boxCancellation">
																	<p>{inventory.messages.amendmentCharge}</p>
																	{res.amendCharge}
																	{selectedData?.room_rate?.room_price_currency}
																</div>
															</div>
														) : (
															""
														)}
													</>
												</>
											) : !res.hasOwnProperty("toDate") &&
											  !res.hasOwnProperty("noShowPolicy") ? (
												<>
													<p className="w-100 mt-3">
														{inventory.messages.after} {res.fromDate}
													</p>
													<div className="col">
														{res.cancelCharge == 0 ? (
															<div className="boxCancellation bg-Ground">
																<p>{inventory.messages.noCancellationCharge}</p>
															</div>
														) : res.cancelRestricted ? (
															<div className="boxCancellation bg-dangerr">
																<p>
																	{inventory.messages.cancellationRestricted}
																</p>
															</div>
														) : (
															<div className="boxCancellation">
																{inventory.messages.cancellationCharge}
																<p>
																	{" "}
																	{res.cancelCharge}{" "}
																	{selectedData?.room_rate?.room_price_currency}
																</p>
															</div>
														)}
													</div>
													<>
														{res.amendCharge == 0 ? (
															<div className="col ">
																<div className="boxCancellation bg-Ground">
																	<p>{inventory.messages.noAmendCharge}</p>
																</div>
															</div>
														) : res.amendRestricted ? (
															<div className="col ">
																<div className="boxCancellation bg-dangerr">
																	<p>{inventory.messages.amendRestricted}</p>
																</div>
															</div>
														) : hotelRate.gds != 5 && hotelRate.gds != 6 ? (
															<div className="col ">
																<div className="boxCancellation">
																	<p>{inventory.messages.amendmentCharge}</p>
																	{res.amendCharge}{" "}
																	{selectedData?.room_rate?.room_price_currency}
																</div>
															</div>
														) : (
															""
														)}
													</>
												</>
											) : res.hasOwnProperty("noShowPolicy") ? (
												<>
													<p className="w-100 mt-3">
														{inventory.messages.after} {res.fromDate}
													</p>

													<div className="col-md-12">
														<div className="boxCancellation bg-dangerNew ">
															<p>{inventory.messages.noShowPolicyCharge}</p>
															{res.charge}{" "}
															{selectedData?.room_rate?.room_price_currency}
														</div>
													</div>
												</>
											) : (
												<>
													<p className="w-100 mt-3">
														{" "}
														between {res.fromDate} and {res.toDate}
													</p>
													<>
														{res.cancelCharge == 0 ? (
															<div className="col">
																<div className="boxCancellation bg-Ground">
																	<p> {inventory.messages.noAmendCharge}</p>
																</div>
															</div>
														) : res.cancelRestricted ? (
															<div className="col">
																<div className="boxCancellation bg-dangerr">
																	<p>
																		{inventory.messages.cancellationRestricted}
																	</p>{" "}
																</div>
															</div>
														) : hotelRate.gds != 6 ? (
															<div className="col">
																<div className="boxCancellation">
																	<p>{inventory.messages.cancellationCharge}</p>
																	<p>
																		{" "}
																		{res.cancelCharge}{" "}
																		{
																			selectedData?.room_rate
																				?.room_price_currency
																		}
																	</p>
																</div>
															</div>
														) : (
															""
														)}
													</>
													<>
														{res.amendCharge == 0 ? (
															<div className="col ">
																<div className="boxCancellation bg-Ground">
																	<p>{inventory.messages.noAmendCharge}</p>
																</div>
															</div>
														) : res.amendRestricted ? (
															<div className="col">
																<div className="boxCancellation bg-dangerr">
																	<p>{inventory.messages.amendRestricted}</p>
																</div>
															</div>
														) : hotelRate.gds != 5 && hotelRate.gds != 6 ? (
															<div className="col">
																<div className="boxCancellation">
																	<p>{inventory.messages.amendmentCharge}</p>
																	<p>
																		{" "}
																		{res.amendCharge}{" "}
																		{
																			selectedData?.room_rate
																				?.room_price_currency
																		}
																	</p>
																</div>
															</div>
														) : (
															""
														)}
													</>
												</>
											)}
										</>
									);
								})}
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							className="bg-gray-150 text-dark border-0 px-4"
							onClick={toggleCancellationModel}
						>
							{inventory.messages.cancel}
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		</div>
	);
}
