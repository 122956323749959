import { useState } from 'react'

// ** components
import { SubscriptionInvoice } from 'components/Printing/SubscriptionPlan/SubscriptionInvoice'
import { FreeTrialInvoice } from 'components/Printing/SubscriptionPlan/FreeTerial'

// services
import { subscriptionModules } from 'services/subscriptionPlan'
import moment from 'moment'

const usePrintingSubscriptionInvoice = () => {
	// ** states
	const [subscriptionModulesData, setSubscriptionModulesData] = useState({})

	const getAllModules = async (planId) => {
		const res = await subscriptionModules(planId)
		if (res?.status === 200) {
			setSubscriptionModulesData(res?.data?.data)
		}
	}
	const printSubscriptionInvoice = async (planId) => {
		if (planId !== 0) {
			const res = await subscriptionModules(planId)
			if (res?.status === 200) {
				const invoiceData = res?.data?.data;

				let repos = SubscriptionInvoice
				let tableData =
					invoiceData?.modules?.length > 0 ? invoiceData.modules?.filter(module => module?.status === "active")?.map((row) => {
						return `
								<tr>
									<td style="font-weight: 700;">${row?.name}</td>
									<td>
										${row?.subModules?.length > 0 ?
								row?.subModules?.filter(subModule => subModule?.status === "active")
									?.map((ele) => ele?.name).join(" - ") :
								" - "
							}
									</td>
									<td>
										${moment(invoiceData?.start_date).format('DD/MM/YYYY')} 
										- 
										${moment(invoiceData?.end_date).format('DD/MM/YYYY')}
									</td>
								</tr>`
					})
						: []

				const keyValuePair = {
					'[invoice_number]': invoiceData?.id,
					'[invoice_date]': moment(new Date()).format('DD/MM/YYYY'),
					'[agent_name]': invoiceData?.company?.name ? invoiceData?.company?.name : "",
					'[travel_agent_name]': invoiceData?.company?.name || "",
					'[country_name]': invoiceData?.company?.country?.name || "",
					'[phone_number]': invoiceData?.company?.phone
						? `${invoiceData?.company?.phone?.phone_code} ${invoiceData?.company?.phone?.phone}`
						: '-',
					'[mail]': invoiceData?.company?.business_email || "",
					// '[price]': `${invoiceData?.price} ${invoiceData?.currency}`,
					// '[tax]': `${invoiceData?.price * 0.15} ${invoiceData?.currency
					// 	}`,
					// '[total_price]': `${invoiceData?.price + invoiceData?.price * 0.15} ${invoiceData?.currency}`,
					'[total_price]': `${invoiceData?.price} ${invoiceData?.currency}`,
					'[all_rows]': tableData.join("")
				}

				for (const key in keyValuePair) {
					repos = repos.replace(key, keyValuePair[key])
				}

				var popupWin = window.open(
					'',
					' ',
					'top=0,left=0,height=100%,width=auto'
				)
				popupWin.document.open()
				popupWin.document.write(`
						<html>
						<head>
						<title> print Invoice</title>
						<style>
						@page {
	
							size: A4;
							
							margin:15px;			
							border: 1px solid black;
							
							}
						</style>
						</head>
						<body onLoad="{()=>window.print()}">${repos}</body>
						</html>`)
				popupWin.document.close()

				setTimeout(() => {
					popupWin.print()
				}, 100)
			}
		}
	}


	const printFreeTrialInvoice = async (planId) => {
		if (planId !== 0) {
			const res = await subscriptionModules(planId)
			if (res?.status === 200) {
				let repos = FreeTrialInvoice;
				const freeInvoiceData = res?.data?.data
				let tableData =
					freeInvoiceData?.modules?.length > 0
						? freeInvoiceData?.modules?.filter(module => module?.status === "active")?.map((row) => {
							return `
								<tr>
								<td><strong>${row?.name}</strong></td>
								<td>
									${row?.subModules?.length > 0 ?
									row?.subModules?.filter(subModule => subModule?.status === "active")
										?.map((ele) => ele?.name).join(" - ") : " - "}
								</td>
									<td>
										${moment(freeInvoiceData?.start_date).format('DD/MM/YYYY')} 
										- 
										${moment(freeInvoiceData?.end_date).format('DD/MM/YYYY')}
									</td>
								</tr>`
						})
						: []

				const keyValuePair = {
					'[invoice_number]': freeInvoiceData?.id,
					'[invoice_date]': moment(new Date()).format('DD/MM/YYYY'),
					'[agent_name]': freeInvoiceData?.company?.name || "",
					'[travel_agent_name]': freeInvoiceData?.company?.name || "",
					'[country_name]': freeInvoiceData?.company?.country?.name || "",
					'[phone_number]': freeInvoiceData?.company?.phone
						? `${freeInvoiceData?.company?.phone?.phone_code} ${freeInvoiceData?.company?.phone?.phone}`
						: '-',
					'[free_trial_duration]': moment(freeInvoiceData?.end_date).diff(moment(freeInvoiceData?.start_date), 'days'),
					'[mail]': freeInvoiceData?.company?.business_email || "",
					// '[price]': `${invoiceData?.price} ${invoiceData?.currency}`,
					// '[tax]': `${invoiceData?.price * 0.15} ${invoiceData?.currency
					// 	}`,
					// '[total_price]': `${invoiceData?.price + invoiceData?.price * 0.15} ${invoiceData?.currency}`,
					'[total_price]': `${freeInvoiceData?.price} ${freeInvoiceData?.currency}`,
					'[all_rows]': tableData.join("")
				}

				for (const key in keyValuePair) {
					repos = repos.replace(key, keyValuePair[key])
				}

				var popupWin = window.open(
					'',
					' ',
					'top=0,left=0,height=100%,width=auto'
				)
				popupWin.document.open()
				popupWin.document.write(`
						<html>
						<head>
						<title> print Invoice</title>
						<style>
						@page {
	
							size: A4;
							
							margin:15px;			
							border: 1px solid black;
							
							}
						</style>
						</head>
						<body onLoad="{()=>window.print()}">${repos}</body>
						</html>`)
				popupWin.document.close()

				setTimeout(() => {
					popupWin.print()
				}, 100)
			}
		}
	}

	return {
		printSubscriptionInvoice,
		printFreeTrialInvoice,
		subscriptionModulesData,
		getAllModules,
	}
}

export default usePrintingSubscriptionInvoice
