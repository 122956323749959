export const Bus45 = `


<style>
			html,
			body,
			div,
			dl,
			dt,
			dd,
			ul,
			ol,
			li,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			pre,
			code,
			form,
			fieldset,
			legend,
			input,
			textarea,
			p,
			blockquote,
			th,
			td {
				margin: 0;
				padding: 0;
			}

			a {
				text-decoration: none !important;
				cursor: pointer;
			}

			a:hover {
				text-decoration: none !important;
			}

			fieldset,
			img {
				border: 0;
			}

			address,
			caption,
			cite,
			code,
			dfn,
			em,
			strong,
			th,
			var {
				font-style: normal;
				font-weight: normal;
			}

			ol,
			ul {
				list-style: none;
			}

			caption,
			th {
				text-align: var(--left);
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-size: 100%;
				font-weight: normal;
			}

			q:before,
			q:after {
				content: "";
			}

			abbr,
			acronym {
				border: 0;
				font-variant: normal;
			}

			sup {
				vertical-align: text-top;
			}

			sub {
				vertical-align: text-bottom;
			}

			input,
			textarea,
			select {
				font-family: inherit;
				font-size: inherit;
				font-weight: inherit;
			}

			legend {
				color: #000;
			}

			input:focus,
			select:focus,
			textarea:focus,
			button:focus {
				outline: none;
			}

			* {
				font-family: "Cairo", sans-serif;
				box-sizing: border-box;
			}

			.container,
			.container-fluid,
			.container-xl,
			.container-lg,
			.container-md,
			.container-sm {
				width: 100%;
				padding-right: 10px;
				padding-left: 10px;
				margin-right: auto;
				margin-left: auto;
			}

			/* .container {
				max-width: 1090px;
			} */

			.py-4 {
				padding-bottom: 1.5rem !important;
				padding-top: 1.5rem !important;
			}

			.mx-3 {
				margin-left: 1rem !important;
				margin-right: 1rem !important;
			}

			.mt-2 {
				margin-top: 0.5rem !important;
			}

			.mt-3 {
				margin-top: 1rem !important;
			}

			.bg-gray {
				background: #e8e8e8 !important;
			}

			.p-4 {
				padding: 1.5rem !important;
			}

			.align-items-center {
				align-items: center !important;
			}

			.justify-content-around {
				justify-content: space-around !important;
			}

			.font-weight-bold {
				font-weight: 700 !important;
			}

			.mb-1,
			.my-1 {
				margin-bottom: 0.25rem !important;
			}

			.col,
			.col-1,
			.col-10,
			.col-11,
			.col-12,
			.col-2,
			.col-3,
			.col-4,
			.col-5,
			.col-6,
			.col-7,
			.col-8,
			.col-9,
			.col-auto,
			.col-lg,
			.col-lg-1,
			.col-lg-10,
			.col-lg-11,
			.col-lg-12,
			.col-lg-2,
			.col-lg-3,
			.col-lg-4,
			.col-lg-5,
			.col-lg-6,
			.col-lg-7,
			.col-lg-8,
			.col-lg-9,
			.col-lg-auto,
			.col-md,
			.col-md-1,
			.col-md-10,
			.col-md-11,
			.col-md-12,
			.col-md-2,
			.col-md-3,
			.col-md-4,
			.col-md-5,
			.col-md-6,
			.col-md-7,
			.col-md-8,
			.col-md-9,
			.col-md-auto,
			.col-sm,
			.col-sm-1,
			.col-sm-10,
			.col-sm-11,
			.col-sm-12,
			.col-sm-2,
			.col-sm-3,
			.col-sm-4,
			.col-sm-5,
			.col-sm-6,
			.col-sm-7,
			.col-sm-8,
			.col-sm-9,
			.col-sm-auto,
			.col-xl,
			.col-xl-1,
			.col-xl-10,
			.col-xl-11,
			.col-xl-12,
			.col-xl-2,
			.col-xl-3,
			.col-xl-4,
			.col-xl-5,
			.col-xl-6,
			.col-xl-7,
			.col-xl-8,
			.col-xl-9,
			.col-xl-auto {
				padding-right: 5px;
				padding-left: 5px;
			}

			element.style {
			}

			.col,
			.col-1,
			.col-10,
			.col-11,
			.col-12,
			.col-2,
			.col-3,
			.col-4,
			.col-5,
			.col-6,
			.col-7,
			.col-8,
			.col-9,
			.col-auto,
			.col-lg,
			.col-lg-1,
			.col-lg-10,
			.col-lg-11,
			.col-lg-12,
			.col-lg-2,
			.col-lg-3,
			.col-lg-4,
			.col-lg-5,
			.col-lg-6,
			.col-lg-7,
			.col-lg-8,
			.col-lg-9,
			.col-lg-auto,
			.col-md,
			.col-md-1,
			.col-md-10,
			.col-md-11,
			.col-md-12,
			.col-md-2,
			.col-md-3,
			.col-md-4,
			.col-md-5,
			.col-md-6,
			.col-md-7,
			.col-md-8,
			.col-md-9,
			.col-md-auto,
			.col-sm,
			.col-sm-1,
			.col-sm-10,
			.col-sm-11,
			.col-sm-12,
			.col-sm-2,
			.col-sm-3,
			.col-sm-4,
			.col-sm-5,
			.col-sm-6,
			.col-sm-7,
			.col-sm-8,
			.col-sm-9,
			.col-sm-auto,
			.col-xl,
			.col-xl-1,
			.col-xl-10,
			.col-xl-11,
			.col-xl-12,
			.col-xl-2,
			.col-xl-3,
			.col-xl-4,
			.col-xl-5,
			.col-xl-6,
			.col-xl-7,
			.col-xl-8,
			.col-xl-9,
			.col-xl-auto {
				padding-right: 5px;
				padding-left: 5px;
			}

			.col-2 {
				flex: 0 0 16.6666666667%;
				max-width: 16.6666666667%;
			}

			.col-8 {
				flex: 0 0 66.6666666667%;
				max-width: 66.6666666667%;
			}

			.col-1 {
				flex: 0 0 8.3333333333%;
				max-width: 8.3333333333%;
			}

			.col-3 {
				flex: 0 0 25%;
				max-width: 25%;
			}

			.col-6 {
				flex: 0 0 50%;
				max-width: 50%;
			}

			.col-4 {
				flex: 0 0 33.3333333333%;
				max-width: 33.3333333333%;
			}

			.img-fluid {
				max-width: 100%;
				height: auto;
			}

			.w-50 {
				width: 50%;
			}

			element.style {
			}

			.col,
			.col-1,
			.col-10,
			.col-11,
			.col-12,
			.col-2,
			.col-3,
			.col-4,
			.col-5,
			.col-6,
			.col-7,
			.col-8,
			.col-9,
			.col-auto,
			.col-lg,
			.col-lg-1,
			.col-lg-10,
			.col-lg-11,
			.col-lg-12,
			.col-lg-2,
			.col-lg-3,
			.col-lg-4,
			.col-lg-5,
			.col-lg-6,
			.col-lg-7,
			.col-lg-8,
			.col-lg-9,
			.col-lg-auto,
			.col-md,
			.col-md-1,
			.col-md-10,
			.col-md-11,
			.col-md-12,
			.col-md-2,
			.col-md-3,
			.col-md-4,
			.col-md-5,
			.col-md-6,
			.col-md-7,
			.col-md-8,
			.col-md-9,
			.col-md-auto,
			.col-sm,
			.col-sm-1,
			.col-sm-10,
			.col-sm-11,
			.col-sm-12,
			.col-sm-2,
			.col-sm-3,
			.col-sm-4,
			.col-sm-5,
			.col-sm-6,
			.col-sm-7,
			.col-sm-8,
			.col-sm-9,
			.col-sm-auto,
			.col-xl,
			.col-xl-1,
			.col-xl-10,
			.col-xl-11,
			.col-xl-12,
			.col-xl-2,
			.col-xl-3,
			.col-xl-4,
			.col-xl-5,
			.col-xl-6,
			.col-xl-7,
			.col-xl-8,
			.col-xl-9,
			.col-xl-auto {
				padding-right: 5px;
				padding-left: 5px;
			}

			.txt-blue {
				color: #0c3b5c;
				font-weight: 700;
			}

			.container-wrapper {
				border: 1px solid #0c3b5c;
			}

			.justify-content-between {
				justify-content: space-between !important;
			}

			.row {
				display: flex;
				flex-wrap: wrap;
				/* margin-right: -15px;
				margin-left: -15px; */
			}
			.col-6 {
				display: inline-block;
				width: 48%;
			}
			.border-header {
				border-bottom: 1px solid #2d2d2d;
			}

			.f-12 {
				font-size: 12px;
			}

			table {
				border-collapse: collapse;
			}

			td {
				border: 1px solid #0c3b5c;
				padding: 0.5rem;
			}

			th {
				border: 1px solid #0c3b5c;
				padding: 0.5rem;
			}

			thead th {
				border: 1px solid #0c3b5c;
				padding: 0.5rem;
			}

			/* tr:first-child td {
				border-top: 0;
			}

			tr td:first-child {
				border-left: 0;
			}

			tr td:last-child {
				border-right: 0;
			}

			tr th:first-child {
				border-left: 0;
			}

			tr th:last-child {
				border-right: 0;
			} */

			.width-10 {
				width: 11% !important;
			}

			.container-wrapper {
				height: 95vh !important;
			}

			.p-5 {
				padding: 2rem;
			}

			.d-flex {
				display: flex !important;
			}

			.justify-content-center {
				justify-content: center !important;
			}

			.flex-column {
				flex-direction: column;
			}

			.border {
				border: 1px solid #dfdfdf;
			}

			.fw-600 {
				font-weight: 600;
			}

			.fw-700 {
				font-weight: 700;
			}

			.hr-lg {
				height: 4px;
				background-color: #f3f5f5;
				border: none !important;
			}
			.container {
				max-width: 1250px;
			}
			.font-bold {
				font-weight: 700;
			}

			.all_bus {
				margin-block: 25px;
				border: 1px solid #222;
			}
			.bus45 .bus_head {
				display: grid;
				grid-template-columns: 2fr 1fr 1fr 1fr;
				grid-template-areas: "driver0 space0 supervisor0 door0";
			}
			.bus45 .bus_head .driver {
				grid-area: driver0;
				border: 1px solid #222;
				border-top: none;
				height: 55px;
			}

			.bus45 .bus_head .space {
				grid-area: space0;
				/* background-color: #ffa08c; */
			}
			.bus45 .bus_head .supervisor {
				grid-area: supervisor0;
				border: 1px solid #222;
				height: 55px;
				border-top: none;
			}
			.bus45 .bus_head .door {
				grid-area: door0;
				border: 1px solid #222;
				height: 55px;
				border-top: none;
			}

			.bus45 .bus {
				display: grid;
				grid-template-columns: repeat(5, 1fr);
				grid-template-areas: "driver1 driver2 space supervisor door";
			}

			.bus45 .bus .driver1 {
				grid-area: driver1;
				border: 1px solid #222;
				border-top: none;
				height: 55px;
			}
			.bus45 .bus .driver2 {
				grid-area: driver2;
				border: 1px solid #222;
				border-top: none;
				height: 55px;
			}
			.bus45 .bus .space {
				grid-area: space;
				/* background-color: #ffa08c; */
			}
			.bus45 .bus .supervisor {
				grid-area: supervisor;
				border: 1px solid #222;
				height: 55px;
				border-top: none;
			}
			.bus45 .bus .door {
				grid-area: door;
				border: 1px solid #222;
				height: 55px;
				border-top: none;
			}
			.wc {
				grid-column: 4/6;
				border-bottom: 1px solid #222;
				border-inline: 1px solid #222;

				text-align: center;
			}
			.backDoor {
				grid-column: 4/6;
				border-bottom: 1px solid #222;
				border-inline: 1px solid #222;
				text-align: center;
			}
			.text-center {
				text-align: center;
			}
			.bus45 .company {
				margin-block: 25px;
			}

			.bus45 .data {
				border: 1px solid #222;
				display: flex;
				justify-content: space-around;
				padding-block: 30px;
			}
			.bus45 .data div {
				display: flex;
			}
			.bus45 .data div p {
				font-weight: 800;
			}
			.bus45 .data div span {
				font-weight: 800;
				margin-inline:5px
			}
			.bus29 .company p {
				font-weight: 800;
				 font-size: 18px;
			}
			.bus45 .company span {
				font-weight: 800;
				// margin-inline: 15px;
			}
			.border-block{
				border-block:1px solid #222
			}
      .mt-15{
        margin-top: 15px;
        font-weight: 600;
      }
		</style>
    <div class="bus45" >
    <div class="container">
		<div style="direction:[dir]">
      <div class="company">
      <p>[Company] <span>[company_name]</span></p>
      </div>
      <div class="data">
        <div>
        <p>[date] :</p>
        <span>[created_at]</span>
        </div>

        <div>
          <p>[BusNo] :</p>
          <span>[BusNo_api]</span>
        </div>

				<div>
				<p>[BusType] :</p>
				<span>45</span>
			</div>
      </div>
			</div>
      <div class="all_bus" style="direction:ltr">
        <div class="bus_head">
          <div class="driver text-center">
          <p class="text-center  mt-15">[driver]</p>

          </div>
          <div class="space"></div>
          <div class="supervisor text-center">
          <p class="text-center  mt-15">[supervisor]</p>

          </div>
          <div class="door text-center">
          <p class="text-center  mt-15">[door]</p>

          </div>
        </div>

        <div class="bus">
          <div class="driver1">
            1
            <p class="text-center">[passenger_1]</p>
            </div>
          <div class="driver2">2
          <p class="text-center">[passenger_2]</p>

          </div>
          <div class="space"></div>
          <div class="supervisor">3
          <p class="text-center">[passenger_3]</p>

          </div>
          <div class="door">4
          <p class="text-center">[passenger_4]</p>

          </div>
        </div>

        <div class="bus">
          <div class="driver1">5
          <p class="text-center">[passenger_5]</p>

          </div>
          <div class="driver2">6
          <p class="text-center">[passenger_6]</p>

          </div>
          <div class="space"></div>
          <div class="supervisor">7
          <p class="text-center">[passenger_7]</p>

          </div>
          <div class="door">8
          <p class="text-center">[passenger_8]</p>

          </div>
        </div>

        <div class="bus">
          <div class="driver1">9
          <p class="text-center">[passenger_9]</p>

          </div>
          <div class="driver2">10
          <p class="text-center">[passenger_10]</p>

          </div>
          <div class="space"></div>
          <div class="supervisor">11
          <p class="text-center">[passenger_11]</p>

          </div>
          <div class="door">12
          <p class="text-center">[passenger_12]</p>

          </div>
        </div>

        <div class="bus">
          <div class="driver1">13
          <p class="text-center">[passenger_13]</p>

          </div>
          <div class="driver2">14
          <p class="text-center">[passenger_14]</p>

          </div>
          <div class="space"></div>
          <div class="supervisor">15
          <p class="text-center">[passenger_15]</p>

          </div>
          <div class="door">16
          <p class="text-center">[passenger_16]</p>

          </div>
        </div>

        <div class="bus">
          <div class="driver1">17
          <p class="text-center">[passenger_17]</p>

          </div>
          <div class="driver2">18
          <p class="text-center">[passenger_18]</p>

          </div>
          <div class="space"></div>
          <div class="supervisor">19
          
          <p class="text-center">[passenger_19]</p>

          </div>
          <div class="door">20
          <p class="text-center">[passenger_20]</p>

          </div>
        </div>
        <div class="bus">
          <div class="driver1">21
          <p class="text-center">[passenger_21]</p>

          </div>
          <div class="driver2">22
          <p class="text-center">[passenger_22]</p>

          </div>
          <div class="space"></div>
          <!-- <div class="supervisor">19</div>
          <div class="door">20</div> -->
          <div class="wc">
          <p class="text-center  mt-15">[wc]</p>
          </div>
        </div>

        <div class="bus">
          <div class="driver1">23
          <p class="text-center">[passenger_23]</p>

          </div>
          <div class="driver2">24
          <p class="text-center">[passenger_24]</p>

          </div>
          <div class="space"></div>
          <!-- <div class="supervisor">19</div>
          <div class="door">20</div> -->
          <div class="backDoor">

            <p class="text-center  mt-15">[BackDoor]</p>

          </div>
        </div>
        <div class="bus">
          <div class="driver1">25
          <p class="text-center">[passenger_25]</p>

          </div>
          <div class="driver2">26
          <p class="text-center">[passenger_26]</p>

          </div>
          <div class="space"></div>
          <div class="supervisor">27
          <p class="text-center">[passenger_27]</p>

          </div>
          <div class="door">28
          <p class="text-center">[passenger_28]</p>

          </div>
        </div>

        <div class="bus">
          <div class="driver1">29
          
          <p class="text-center">[passenger_29]</p>

          </div>
          <div class="driver2">30
          <p class="text-center">[passenger_30]</p>

          </div>
          <div class="space"></div>
          <div class="supervisor">31
          <p class="text-center">[passenger_31]</p>

          </div>
          <div class="door">32
          <p class="text-center">[passenger_32]</p>

          </div>
        </div>

        <div class="bus">
          <div class="driver1">33
          <p class="text-center">[passenger_33]</p>

          </div>
          <div class="driver2">34
          <p class="text-center">[passenger_34]</p>

          </div>
          <div class="space"></div>
          <div class="supervisor">35
          <p class="text-center">[passenger_35]</p>

          </div>
          <div class="door">36
          <p class="text-center">[passenger_36]</p>

          </div>
        </div>
        <div class="bus">
          <div class="driver1">37
          <p class="text-center">[passenger_37]</p>

          </div>
          <div class="driver2">38
          <p class="text-center">[passenger_38]</p>

          </div>
          <div class="space"></div>
          <div class="supervisor">39
          <p class="text-center">[passenger_39]</p>

          </div>
          <div class="door">40
          
          <p class="text-center">[passenger_40]</p>

          </div>
        </div>

        <div class="bus">
          <div class="driver1">41
          <p class="text-center">[passenger_41]</p>

          </div>
          <div class="driver2">42
          <p class="text-center">[passenger_42]</p>

          </div>
          <div class="space border-block">43
          <p class="text-center">[passenger_43]</p>

          </div>
          <div class="supervisor">44
          <p class="text-center">[passenger_44]</p>

          </div>
          <div class="door">45
          
          <p class="text-center">[passenger_45]</p>

          </div>
        </div>

      </div>
    </div>
  </div>


`;
