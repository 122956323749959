import Locale from "translations";

export default function Pagination({ info, goTo, withDetailsText = true }) {
	const { productElements } = Locale;

	const paginate = (page) => {
		let current;
		if (page.url !== null) {
			current =
				page.label === "Next" || page.label === "التالي"
					? info.current_page + 1
					: page.label === "Previous" ||  page.label === "السابق"
						? info.current_page - 1
						: page.label;
		}

		goTo(current);
	};

	return (
		<>
			{info?.total > info?.per_page ? (
				<div className="d-flex justify-content-between align-items-center mb-2 mr-2 ml-2 pagination-container">
					{withDetailsText ?
						<div className="text-body">
							{/* {productElements.show} {info?.from} {productElements.of}{" "}
						{info?.total} {productElements.entries} */}
							{productElements.show} {info.from} {productElements.to} {info.to}{" "}
							{productElements.of} {info.total} {productElements.entries}
						</div>
						: null
					}

					<nav aria-label="Page navigation example">
						<ul className="pagination">

							{info?.links?.map((page) => {
								return page.url ? (
									<li
										key={`page-${page.label}`}
										className={page.active ? "page-item active" : "page-item"}
										onClick={() => paginate(page)}
									>
										<a
											className={`page-link ${page.url === null ? "false" : ""
												}`}
										>
											{/* {page.label === "Next" ? (
												<i className="fas fa-caret-right"></i>
											) : page.label === "Previous" ? (
												<i className="fas fa-caret-left"></i>
											) : (
												page.label
											)} */}
												{page.label === "Next" || page.label === "Previous"
												? productElements[page.label.toLowerCase()]
												: page.label}
										</a>
									</li>
								) : (
									<li
										key={`page-${page.label}`}
										className={"page-item disabled"}
										onClick={() => false}
									>
										<a className={`page-link disabled`}>
											{page.label === "Next" && (
												// <i className="fas fa-caret-right"></i>
												productElements[page.label.toLowerCase()]

											)}
											{page.label === "Previous" && (
																				productElements[page.label.toLowerCase()]
												)}
												{/* <i className="fas fa-caret-left"></i> */}
											
										</a>
									</li>
								);
							})}
						</ul>
			
					</nav>
				</div>
			) : null}
		</>
	);
}
