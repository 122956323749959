
import Locale from "translations";
import CustomModal from "./Modal";
import TransferSearchForm from 'modules/market-place/Search/Transfer/TransferSearchForm';

function ModifySearch({ isOpen, toggleModal, header }) {
	const { onlineVisa } = Locale;


	return (
		<CustomModal
			modalIsOpen={isOpen}
			header={header || onlineVisa.ChoosePayment}
			toggle={toggleModal}
			size="md"
			centered={true}
		>
			<TransferSearchForm
				modify
				closeModal={() => {
					toggleModal();
					// deleteFromCart();
				}}
			/>
		</CustomModal>
	);
}

export default ModifySearch;
