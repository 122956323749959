import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import NumberField from 'components/Form/NumberField/NumberField';
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import { useSBSState } from 'context/global';
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { SaveIcon } from 'modules/UmrahOperations/shared/Icons';
import Locale from 'translations';
import validate, { isFormValid } from 'helpers/validate';
import moment from 'moment';
import { handleResponesNotification } from '../helper/accountingHelpers';
import { createGuideRequest, updateGuideRequest } from 'services/operationStatement';


const requiredKeys = ["location", "description"];
const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";


export default function AddEditRequestModal({ isOpen, guideData, toggle, setGuideModal, setReload }) {
  // ** hooks
  const { operationStatement, guideRequests } = Locale;
  const { allCountries } = useSBSState();

  // ** states
  const [errors, setErrors] = useState({});

  // ** functions

  // check if guide request form has errors
  function checkFormErrors() {
    let submitError = {};
    Object.keys(guideData.data).forEach((key) => {
      if (requiredKeys.includes(key)) {
        submitError = {
          ...submitError,
          ...validate(
            { name: key, value: guideData.data[key] },
            { required: true }
          ),
        };
      }
    });
    setErrors(() => submitError);
    return submitError;
  }

  // guide request modal input changes
  function handleGuideInputsChanges({ key, value }) {
    let guideDataClone = { ...guideData.data }
    guideDataClone = { ...guideDataClone, [key]: value };
    
    if (requiredKeys.includes(key)) {
      setErrors({
        ...errors,
        ...validate(
          { name: key, value },
          { required: true, email: key === "email" }
        ),
      });
    }
    setGuideModal({ ...guideData, data: guideDataClone })
  }


  // handle create and update guide request 
  async function handleCreateUpdateGroup(requestId) {
    let formErrors = checkFormErrors();
    let res;
    if (isFormValid(formErrors)) {
      let message;
      let data = {
        address: guideData.data?.location,
        date: guideData.data?.date ? moment(guideData.data?.date).format("YYYY-MM-DD") : null,
        passport_number: guideData.data?.passport || null,
        phone_code: guideData.data?.phone_code?.phone_code || guideData.data?.phone_code?.value?.toString() || null,
        phone_number: guideData.data?.phone || null,
        description: guideData.data?.description
      }
      res = requestId ? await updateGuideRequest(requestId, data) : await createGuideRequest(data);
      if (res?.status === 200 || res?.status === 201) {
        message = requestId ? "Request Updated Succesfully" : "Request Added Succesfully";
        handleResponesNotification({ alertType: "success", message, title: "" });

        toggle({ data: null, type: null });
        setReload(prev=> !prev)
      }
    }
  }

  useEffect(() => {
    if (guideData.data.phone_code && typeof guideData.data.phone_code === "string") {
      let phoneCode = allCountries?.find(country => country?.phone_code === guideData.data.phone_code)
      handleGuideInputsChanges({ key: "phone_code", value: phoneCode })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCountries, guideData.data.phone_code])



  return (
    <Modal isOpen={isOpen} size='lg' className="operation-modal">

      <ModalHeader toggle={toggle}>
        {guideData.type === "Add" ? Locale.guideRequests.addNewRequest : Locale.guideRequests.editRequest}
        
      </ModalHeader>

      <ModalBody>
        <div className="row mx-0">
          {/* Location */}
          <div className="col-md-6 col-12">
            <TextField
              label={Locale.guideRequests.location}
              placeholder={Locale.guideRequests.typeLocation}
              id={"guide-location"}
              name={"guide-location"}
              value={guideData.data?.location}
              onChange={(e) => handleGuideInputsChanges({ key: "location", value: e.target.value })}
              color={errors?.location?.required ? "danger" : ""}
              errors={errors?.location}
            />
          </div>
          {/* Request Date */}
          <div className="col-md-6 col-12">
            <DatePickerField
              label={Locale.guideRequests.requestDate}
              placeholder={Locale.guideRequests.date}
              id={"guide-date"}
              name={"guide-date"}
              date={guideData.data?.date}
              onChangeDate={(date) => handleGuideInputsChanges({ key: "date", value: date })}
            />
          </div>
          {/* Passport No. */}
          <div className="col-md-6 col-12">
            <TextField
              label={Locale.guideRequests.passport}
              placeholder={Locale.guideRequests.typePassportNo}
              id={"guide-passport"}
              name={"guide-passport"}
              value={guideData.data?.passport}
              onChange={(e) => handleGuideInputsChanges({ key: "passport", value: e.target.value })}
            />
          </div>
          {/* Phone Number */}
          <div className="col-md-6 col-12 row mx-0 mt-1">
            <label className="control-field__label col-12">{operationStatement.PhoneNumber}</label>
            {/* phone code */}
            <div className="col-4 company_phone_code">
              <SelectField
                label={"Code"}
                hasLabel={false}
                id="guide-phone_code"
                name="guide-phone_code"
                placeholder={Locale.guideRequests.choose}
                value={
                  guideData?.data?.phone_code?.flag ?
                    <img
                      src={`${fetchFlag}/${guideData?.data?.phone_code?.flag}`}
                      width="35px"
                      alt={guideData?.data.phone_code?.label}
                    />
                    :
                    guideData?.data.phone_code?.label || guideData?.data.phone_code
                }
                onChange={(e) => handleGuideInputsChanges({ key: "phone_code", value: e })}
                options={allCountries}
                color={errors?.phone_code?.required ? "danger" : ""}
                errors={errors?.phone_code}
              />
            </div>
            {/* phone */}
            <div className="col-8 px-0">
              <NumberField
                type={"phone"}
                label={Locale.guideRequests.phoneNumber}
                hasLabel={false}
                id="guide-phone"
                name="guide-phone"
                value={guideData?.data.phone}
                removeArrow={true}
                placeholder={"000 0000 000"}
                min={3}
                onChange={(e) => handleGuideInputsChanges({ key: "phone", value: e.target.value })}
                extraTextPosition="prepend"
                extraText={
                  guideData?.data?.phone_code?.phone_code ||
                  guideData?.data.phone_code?.label ||
                  guideData?.data?.phone_code?.value
                }
                color={errors?.phone?.required || errors?.phone_code?.required ? "danger" : ""}
                errors={errors?.phone || errors?.phone_code}
              />
            </div>
          </div>
          {/* desciption */}
          <div className="col-12 textarea-container mt-1">
            <label htmlFor="guide-description" className="control-field__label">{Locale.guideRequests.description}</label>
            <textarea
              name="guide-description"
              id="guide-description"
              rows={8}
              className={`my-1 ${errors?.description?.required ? "control-field--danger" : ""} `}
              value={guideData.data?.description}
              onChange={(e) => handleGuideInputsChanges({ key: "description", value: e.target.value })}
            />
            {errors?.description?.required &&
              <small className="control-field__feedback--danger mt-1">{guideRequests.descriptionIsRequired}</small>
            }
          </div>

          {/* action button */}
          <div className='col-12 mt-1'>
            <button className="btn save-btn"
              onClick={() => handleCreateUpdateGroup(guideData.id)}
            >
              <SaveIcon color="#D4A655" />
              {guideRequests.save}
            </button>
          </div>

        </div>
      </ModalBody>
    </Modal>
  )
}
