export function generateMiniBusSeats(totalSeats, busId, travelers, disabledSeats) {

  let nextOneColSeat = 1;
  const seatsArray = [...Array(totalSeats).keys()].map(number => {
    let currentSeat = number + 1;
    // traver has seat
    const traveler = travelers?.filter(traveler => +traveler?.bus_seat?.seat_number === +currentSeat && busId === traveler?.bus_seat?.bus_id)[0] || null;
    // check if seat disabled
    const isSeatDisabled = disabledSeats.filter(seat => seat?.bus?.id === busId).map(seat => seat?.seat_number);
    let isBlock = currentSeat === 13 || currentSeat === 14 ? true : false;
    let isOneCol = false;

    if (currentSeat === nextOneColSeat) {
      if (isBlock) {
        nextOneColSeat = nextOneColSeat + 2;
      } else if (nextOneColSeat) {
        nextOneColSeat = nextOneColSeat + 3;
      }
      isOneCol = true;
    }
    if (totalSeats === 30 && currentSeat > 26) {
      isOneCol = false;
    }
    return {
      seatNumber: (currentSeat).toString().padStart(2, '0'),
      seatStatus: traveler ? "booked" : isSeatDisabled.includes(+currentSeat) ? "disabled" : "available",
      passenger_id: traveler ? traveler?.id : null,
      isBlock,
      isOneCol,
      isLastSeats: totalSeats === 30 && currentSeat > 27,
    }
  });
  return seatsArray;
}

export function generateBigBusSeats(totalSeats, busId, travelers, disabledSeats) {
  let lastSeatsCount = 5;
  let nextLeftColSeat = 1;
  let leftCol = [];

  const seatsArray = [...Array(totalSeats).keys()].map(number => {
    let currentSeat = number + 1;
    // traver has seat
    const traveler = travelers?.filter(traveler => +traveler?.bus_seat?.seat_number === +currentSeat && busId === traveler?.bus_seat?.bus_id)[0] || null;
    // check if seat disabled
    const isSeatDisabled = disabledSeats.filter(seat => seat?.bus?.id === busId).map(seat => seat?.seat_number);


    if (currentSeat === nextLeftColSeat) {
      nextLeftColSeat = nextLeftColSeat + 4;
      currentSeat < (totalSeats - lastSeatsCount) ? leftCol.push(currentSeat, currentSeat + 1) : leftCol.push(currentSeat)
    }
    return {
      seatNumber: (currentSeat).toString().padStart(2, '0'),
      seatStatus: traveler ? "booked" : isSeatDisabled.includes(+currentSeat) ? "disabled" : "available",
      passenger_id: traveler ? traveler?.id : null,
      inLeftCol: leftCol.includes(currentSeat),
      isLastSeats: currentSeat > (totalSeats - lastSeatsCount),
      isOneCol: false,
      isBlock: false
    }
  });

  return seatsArray;
}