import TextField from 'components/Form/TextField/TextField';
import CustomModal from "modules/WebBuilder-V2/shared/CustomModal";
import useWBHeader from '../useWBHeader';
import { useEffect, useState } from 'react';
import { isArray } from "lodash"
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import validate, { isFormValid } from 'helpers/validate';



export default function ContactPhonesModal({ isOpen, toggle }) {

  const {
    headerData,
    updateHeroHeader
  } = useWBHeader();


  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [errors, setErrors] = useState({});


  useEffect(() => {
    if (!isArray(headerData?.phoneNumber)) {
      setPhoneNumbers([headerData?.phoneNumber])
    } else {
      setPhoneNumbers(headerData?.phoneNumber)
    }
  }, [headerData?.phoneNumber]);


  function checkFormErrors() {
    let submitError = {};
    phoneNumbers?.forEach((phone, index) => {
      submitError = {
        ...submitError,
        ...validate(
          { name: `phone-${index}`, value: phone },
          { required: true }
        )
      }
    });
    setErrors(() => submitError);
    return submitError;
  }

  function addNewPhoneRow() {
    setPhoneNumbers(prev => [...prev, ""])
  }
  function removePhoneRow(index) {
    let clonePhoneNumbers = [...phoneNumbers];
    clonePhoneNumbers.splice(index, 1)
    setPhoneNumbers(clonePhoneNumbers);
  }

  function handleInputChanges(index, value) {
    let clonePhoneNumbers = [...phoneNumbers]
    clonePhoneNumbers[index] = value;
    setPhoneNumbers(clonePhoneNumbers);

    setErrors({
      ...errors,
      ...validate(
        { name: [`phone-${index}`], value },
        { required: true },
      ),
    });
  }

  function savePhoneNumbers() {
    let formErrors = checkFormErrors();
    if (!isFormValid(formErrors)) return;
    updateHeroHeader({ name: "phoneNumber", value: phoneNumbers })
    toggle()
  }


  return (
    <CustomModal isOpen={isOpen} toggle={toggle} header={"Upload Phones"} size="md">
      <div>
        {phoneNumbers.map((number, index) => {
          return (
            <div className="d-flex align-items-start justify-content-between gap-10" key={`${index}`}>
              <div className="w-100">
                <TextField
                  hasLabel={false}
                  label={`Phone-${index + 1}`}
                  id={`phone-number-${index}`}
                  name={`phone-number-${index}`}
                  value={number}
                  onChange={(e) => {
                    if (/^\d*$/.test(e.target.value)) {
                      handleInputChanges(index, e.target.value)
                    }
                  }}
                  errors={errors[`phone-${index}`]}
                  color={errors[`phone-${index}`]?.required ? "danger" : ""}
                />
              </div>
              {index > 0 && index !== phoneNumbers.length - 1 &&
                <button className="btn px-2" onClick={() => removePhoneRow(index)}>
                  <TrashIcon />
                </button>
              }
              {index === phoneNumbers.length - 1 &&
                <button className="btn p-0 mt-1" onClick={addNewPhoneRow}>
                  <AddIcon />
                </button>
              }
            </div>
          )
        })}


        <button className="btn bg-nxt mt-3 w-100" onClick={savePhoneNumbers}>
          Save
        </button>
      </div>
    </CustomModal>
  )
}
