import myWalletImg from "assets/images/dashboard/3.svg";
import { useMarketplaceState } from "context/marketplace";
import moment from 'moment';
import { useParams } from "react-router-dom";
import Locale from "translations";

export default function ListHeader() {
	const { onlineVisa, productElements, inventory, marketPlace } = Locale;
	// const [openModal, setOpenModal] = useState(false);
	const { transportationSearch } = useMarketplaceState();


	// const toggleModel = () => {
	// 	setOpenModal(!openModal);
	// };
	const { id } = useParams();
	return (
		<>
			<div className="rounded row m-0  justify-content-between bg-white border p-0">
				<div className="rounded m-0 row col-10  justify-content-between align-items-center  p-0">
					<div className="p-5 bg-gray text-center">
						<p className="m-0  font-weight-bold text-uppercase">
							{inventory.messages.transport}
						</p>
						<p className="m-0 font-weight-bold">
							{" "}
							{inventory.messages.details}
						</p>
					</div>

					<div className="row col-5 ">
						<div className="col-5  txt-blue">
							<p className="m-0 ">ID</p>
							<p className="m-0 ">{onlineVisa.Destination}</p>
							<p className="m-0 ">{productElements.cycleType}</p>
							<p className="m-0 ">{productElements.vehicleType}</p>
							<p className="m-0 ">{productElements.seats}</p>
							<p className="m-0 ">{productElements.model}</p>
						</div>

						<div className="col-7 ">
							<p className="m-0">{id}</p>
							<p className="m-0">{transportationSearch?.destination?.name}</p>
							<p className="m-0">
								{transportationSearch?.cycle_type?.name || transportationSearch?.cycle_type}{" "}
								{productElements.cycle}
							</p>
							<p className="m-0">{transportationSearch?.vehicle_type?.name ? transportationSearch?.vehicle_type?.name : "-"}</p>
							<p className="m-0">{transportationSearch?.seats_count ? transportationSearch?.seats_count : "-"}</p>
							<p className="m-0">{transportationSearch?.vehicle_model?.name ? transportationSearch?.vehicle_model?.name : "-"}</p>

						</div>
					</div>

					<div className="row col-5 ">
						<div className="col-6  txt-blue font-weight-bold">
							<p className="m-0 ">{productElements.vehiclesCount}</p>
							<p className="m-0 ">{inventory.messages.departure}</p>
							<p className="m-0 ">{onlineVisa.return}</p>
							<p className="m-0 ">{inventory.messages.requestDate}</p>
							<p className="m-0 ">{marketPlace.messages.requestExpireDate}</p>
							<p className="m-0 ">{inventory.messages.status}</p>
						</div>

						<div className="col-6  ">
							<p className="m-0">{transportationSearch?.vehicle_count ? transportationSearch?.vehicle_count : "-"}</p>
							<p className="m-0">
								{transportationSearch?.departure_date ? moment(transportationSearch?.departure_date).format("DD-MM-YYYY") : "-"}
							</p>
							<p className="m-0">
								{transportationSearch?.return_date ? moment(transportationSearch?.return_date).format("DD-MM-YYYY") : "-"}
							</p>
							<p className="m-0 ">
								{moment(transportationSearch?.requestDate).format("DD/MM/YYYY")}
							</p>
							<p className="m-0 text-danger">
								{moment(transportationSearch?.expiration_date).format("DD/MM/YYYY")}
							</p>
							<p className={`m-0 font-weight-normal text-capitalize text-${transportationSearch?.status === "waiting" ? "waiting" :
								transportationSearch?.status === "incomplete" || transportationSearch?.status === "expired" ? "danger" :
									transportationSearch?.status === 'pending' ? 'pending' : 'issued'
								}`}
							>
								{transportationSearch.status ? transportationSearch.status : "-"}
							</p>
						</div>
					</div>
				</div>

				<div className="d-flex justify-content-between align-items-end col-2 p-0 flex-column">
					<div className="d-flex  bg-gray px-4 text-center rounded justify-content-center h-100 align-items-center">
						<img src={myWalletImg} alt="" className="online-vise-img" />
						<div className="mx-3">
							<p className="m-0 txt-blue">{onlineVisa.TotalPrice}</p>
							<p className="m-0 text-success font-weight-bold">
								{transportationSearch?.residence?.currency_code?.toLocaleUpperCase()}{" "}
								{transportationSearch?.residence?.price * +transportationSearch?.vehicle_count}{" "}
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
