import React, { useState } from "react";
import { ReactComponent as HomeIcon } from "assets/images/webBuilder/page-home.svg";
import { ReactComponent as ElementIcon } from "assets/images/webBuilder/page-element.svg";
import { Collapse } from "reactstrap";
import { useWebBuilderState } from "context/webBuilder";

function Pages() {
	const [collapse, setCollapse] = useState(false);
	const { ourServices } = useWebBuilderState();
	const serviecs = ourServices?.services;

	const handleCollapse = () => {
		setCollapse((prev) => !prev);
	};
	const scrollToSection = (id) => {

		const element = document.getElementById(id);
		element.scrollIntoView({ behavior: "smooth" });
	};
	return (
		<div className="sidebar-pages">
			<div className="content-header">
				<h4>Pages</h4>
			</div>

			<div className="pages-details">
				<div className="pages-element">
					<HomeIcon />
					<span>Home</span>
				</div>

				<div className="pages-element" >
					<div className="pages-element d-flex align-items-center " onClick={handleCollapse}>
						<ElementIcon />
						<span className='flex-fill'>
							Services
						</span>
							<i className={`fas fa-chevron-${collapse ? "up" : "down"}`}  />
					</div>
					<Collapse isOpen={collapse}>
						{serviecs &&
							serviecs.map((service) =>
								service.visible ? (
									<div
										className="ps-4 pages-element pointer-event"
										key={service.type}
										onClick={() => scrollToSection(service.id)}
									>
										<ElementIcon />
										<span>{service.type}</span>
									</div>
								) : null
							)}
					</Collapse>
				</div>
				<div
					className="pages-element pointer-event"
					onClick={() => scrollToSection("about-us-section")}
				>
					<ElementIcon />
					<span>About us</span>
				</div>
			</div>
		</div>
	);
}

export default Pages;
