import { useSBSState } from 'context/global';
import SightseeingIcon from 'modules/market-place/PackageResponse/Details/shared/PackageIcon'
import React from 'react'

export default function ItinerarySightseeing({ isMobile, element }) {
  const { locale } = useSBSState();

  return (
    <div className="boxcollapse">
      <div className="closecollapse">
        <div className="collapseIcon">
          <SightseeingIcon />
        </div>
        {isMobile &&
          <p
            style={{
              color: "##0F3E5E",
              fontWeight: "bold",
              marginRight: "auto",
              paddingLeft: "5px",
            }}
          >
            {element?.item?.name}
          </p>
        }
        {!isMobile &&
          <>
            <div className={"info"}>
              <div className="d-flex wrap">
                <p style={{ color: "#0F3E5E" }}>
                  {element?.itemable?.name?.[locale]}{" "}
                </p>
              </div>

            </div>
          </>
        }
      </div>
    </div>
  )
}
