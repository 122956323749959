import React from "react";
import pdf from "assets/images/pdf.svg";
import gallery from "assets/images/gallery.svg";
import Locale from 'translations';

export default function Flyerbar({
	setCreate,
	create,
	setSelectedHotels,
	selectedHotels,
	handelModalState
}) {
		    const { marketPlace } = Locale;
	const handleCreateFlyer = () => {
		setCreate(true);
	};

	const handleDisableSelection = () => {
		setCreate(false);
		setSelectedHotels([]);
	};
	const selectedHotelLength = selectedHotels?.length;
const handleFlyerButton = selectedHotelLength ? handelModalState : handleCreateFlyer
	return (
		<div className="flyer">
			<div className="btn-flyer" onClick={handleFlyerButton} role="button">
				<div className="imgs">
					<img src={pdf} alt="" />
					<img src={gallery} alt="" />
				</div>
				{create ? (
					selectedHotelLength ? (
						<div className="create">
							<p>
								{marketPlace.downloadShare} ({`${selectedHotelLength}`}
								{marketPlace.HotelSelected} )
							</p>
						</div>
					) : (
						<div className="select">
							<p>{marketPlace.selectHotels}</p>
						</div>
					)
				) : (
					<div className="create">
						<p> {marketPlace.createFlyer}</p>
					</div>
				)}
			</div>
			{create && (
				<p
					role="button"
					onClick={handleDisableSelection}
					className="disable-selection"
				>
					{marketPlace.disableSelection}
				</p>
			)}
		</div>
	);
}
