import React from "react";

import { Redirect } from 'react-router-dom/cjs/react-router-dom';
const ErroLoginAuth = (props) => {
	return (
		<div className="error-404 text-center">
			    <Redirect to="/"/>

			{/* <div className="row align-items-end h-100">
				<div className="col-md-4 mx-auto">
					<h4 className="error-404__title">{Locale.Error_Title}</h4>
					<p className="error-404__content">{Locale.Error_Content}</p>
					<Link className="btn btn-primary w-75 mt-2" to="/">
						Back To Home
					</Link>
				</div>
			</div> */}
		</div>
	);
};

export default ErroLoginAuth;
