import { useMarketplaceState } from "context/marketplace";
import moment from "moment";
import { useEffect, useState } from "react";
import Locale from "translations";

function Hotel({ icon }) {
	const { marketPlace, rooms } = Locale;
	const { selected,filters } = useMarketplaceState();
	const [hotel, setHotel] = useState(selected);
	// const [filter, setFilter] = useState(filters);

	//	const roomDetails = data.filter((item, index) => item.name == hotel.name);
	const { price } = useMarketplaceState();




	const roomTypes = [
		{
			id: 1,
			name: rooms.x1,
		},
		{
			id: 2,
			name: rooms.x2,
		},
		{
			id: 3,
			name: rooms.x3,
		},
		{
			id: 4,
			name: rooms.x4,
		},
		{
			id: 5,
			name: rooms.x5,
		},
		{
			id: 6,
			name: rooms.x6,
		},
		{
			id: 7,
			name: rooms.x7,
		},
		{
			id: 8,
			name: rooms.suite,
		},
		{
			id: 9,
			name: rooms.flatRate,
		},
	];

	useEffect(() => {
		if (!selected) {
			setHotel(JSON.parse(localStorage.getItem("selected")));
			// setFilter(JSON.parse(localStorage.getItem("filters")));
		}
	}, [setHotel]);

	return !hotel ? null : (
		<div className="b2c-invoice-wrapper">
			<div className="container">
				<div className="hotel-detail-section py-0">
					<div className="img-box">
						<img src={icon} alt="" />
					</div>
					<div className="hotel-detail-item">
						<div className="hotel-header w-100">
							<h1
								className="font-weight-bold text-capitalize"
								style={{ fontSize: "18px" }}
							>
								{hotel.name}
							</h1>
							{/* <p>{selected.address} </p> */}
							<div className="d-flex align-items-center my-2 text-dark">
								<i className="fas fa-map-marker-alt px-2 fa-lg"></i>
								<p className="w-75 mx-2">{hotel.address}</p>
							</div>
							<div className="hotel-star-rate">
								{[...Array(hotel.rating).keys()].map((i) => (
									<i key={i} className="fa fa-star" aria-hidden="true"></i>
								))}
							</div>

							<div className="d-flex">
								<ul className="font-weight-bold col-4">
									<li>{marketPlace.messages.arrivalDate}</li>
									<li>{marketPlace.messages.departureDate}</li>
									<li>{marketPlace.messages.adult}</li>
									<li>{marketPlace.messages.child}</li>
									<li>
										<span>{marketPlace.messages.roomType}</span>
									</li>
									<li>{marketPlace.messages.rooms}</li>
								</ul>
								<ul className="col-6">
									<li>{moment(hotel.from).format("YYYY-MM-DD")}</li>
									<li>{moment(hotel.to).format("YYYY-MM-DD")}</li>
									<li>{hotel.adult}</li>
									<li>{hotel.child}</li>
									<li>
										{roomTypes.find((room) => room.id == hotel.type) &&
											roomTypes.find((room) => room.id == hotel.type).name}
									</li>
									<li>{Math.ceil(+filters.adult / +filters.roomType)}</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="">
						<div className="total-price-box">
							<h6>{marketPlace.messages.totalPrice}</h6>
							<p className="price-number">
								<span
									className="h6 font-weight-bold mx-2"
									style={{ fontSize: "20px" }}
								>
									{/* {Math.ceil(hotel.price)}* */}
									{Math.ceil(price?.room_total_price)}
								</span>
								<span className="text-caption3 font-weight-bold">
									{marketPlace.messages.sar}
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Hotel;
