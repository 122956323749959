import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { SORT_ITEMS_IN_CONTAINER_ACTION } from "modules/WebBuilder/interfaces/blockTypes";
import { AddElement } from "modules/WebBuilder/shared/AddElement";
import AddService from "modules/WebBuilder/shared/AddService";
import CustomCarousel from "modules/WebBuilder/shared/CustomCarousel";
import DragAndDropContainer from "modules/WebBuilder/shared/DragAndDropContainer";
import { EditBox } from "modules/WebBuilder/shared/EditBox";
import { ServiceCardDetails } from "modules/WebBuilder/shared/ServiceCardDetails";
import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import { useCallback, useMemo, useState } from "react";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import PackageCard from "./PackageCard";

const packagesBlocksType = "packagesBlocksType";

function OurPackages({ details, onFocus, focusContainer }) {
	const { selectedLanguage } = useWebBuilderState();
	const { id, title, body, items } = details;
	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);
	const dispatch = useWebBuilderDispatch();

	const handleOpenAddModal = useCallback(() => {
		setOpenAdd((prev) => !prev);
		setUpdateItem(null)
	}, []);

	const openEditModal = (item) => {
		handleOpenAddModal();
		setUpdateItem(item);
	};

	const editItem = (item, index) => {
		return [
			<span
				key={`${item.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};

	const addItemToPackages = useCallback(
		(item) => {
			updateItem
				? dispatch({
						type: "editItemInContainer",
						payload: {
							id,
							item,
							itemIndex: updateItem.index,
						},
				  })
				: dispatch({
						type: "addItemToContainer",
						payload: {
							id,
							item,
						},
				  });
			setUpdateItem(null);
		},
		[dispatch, id, updateItem]
	);

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};

	const sortPackagesItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};
	const updatePackages = ({ name, value, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				language
			},
		});
	};
	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={itemId}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "container_item" })
				}
				className="pointer-event"
			>
				<TrashIcon />
			</span>,
		];
	};

	const containerBoxActions = [
		<span
			onClick={() => deleteBodyContent({ deleteType: "container" })}
			className="pointer-event"
		>
			<TrashIcon />
		</span>,
	];

	const selectedIds = items ? items.map((i) => i.id) : [];

	return (
		<EditBox editMode={focusContainer === id} actions={containerBoxActions}>
			<div
				className="web-builder-content-our-packages container"
				onClick={() => onFocus(id)}
			>
				<ServicesContent
					header={title}
					body={body}
					changeHeader={(value) => updatePackages({ name: "title", value, language: selectedLanguage })}
					changebody={(value) => updatePackages({ name: "body", value, language: selectedLanguage })}
				/>
				<AddElement onClick={handleOpenAddModal} title="Add Package" />
				{items.length > 0 && (
					<CustomCarousel desktopItemNumber={4}>
						{items.map((props, index) => (
							<DragAndDropContainer
								accept={packagesBlocksType}
								type={packagesBlocksType}
								id={props.id}
								index={index}
								action={SORT_ITEMS_IN_CONTAINER_ACTION}
								moveItemInContainer={(dragIndex, hoverIndex) =>
									sortPackagesItems({ dragIndex, hoverIndex })
								}
								key={props.id}
							>
								<EditBox
									editMode={focusContainer === id}
									actions={[
										...editItem(props, index),
										...itemsBoxActions(props.id),
									]}
								>
									<PackageCard {...props} key={index} /> 
								</EditBox>
							</DragAndDropContainer>
						))}
					</CustomCarousel>
				)}
				{/* <div className="my-2 our-packages-detials">
				</div> */}
				{openAdd && (
					<AddService
						handleModalState={handleOpenAddModal}
						open={openAdd}
						label={"Packages"}
						header={"Packages"}
						onAdd={addItemToPackages}
						details={updateItem}
						selectedIds={selectedIds}
					/>
				)}
			</div>
		</EditBox>
	);
}

export default OurPackages;
