import React, { useEffect, useState } from 'react'
import { store } from 'react-notifications-component';
import { CRMListChangePriority } from 'services/CRM';
import Locale from 'translations';

export default function PriorityBox({ isPopupShow, selectedItems, priority, actionsAfterSuccess }) {
	const { CRM } = Locale

  const [reservationPriority, setReservationPriority] = useState("");

  const handleChangePriority = (e) => {
    const { value } = e.target;
    setReservationPriority(value);
  }

  async function savePriority() {
    const data = {
      priority: reservationPriority,
      reservationNums: selectedItems
    }
    const priorityRes = await CRMListChangePriority(data);
    if (priorityRes?.status === 200) {
      store.addNotification({
        message: "Priority changed successfuly",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      actionsAfterSuccess();
      setReservationPriority("");
    }
  }

  useEffect(() => {
    if (priority) {
      setReservationPriority(priority)
    }
  }, [priority])

  return (
    <div className={`priority-wrapper ${isPopupShow ? 'popup-translate-20' : ''}`} >
      <p className='priority-title font-weight-bold'>{CRM.priority}</p>
      <hr />
      <div className='priority-items'>
        <h5 className='priority-item-title font-weight-bold'>{CRM.changePriority}</h5>
        <div className='priority-item' >
          <input
            type='radio'
            id="crm-priority-hight"
            name="crm-priority-hight"
            value='high'
            checked={reservationPriority === "high"}
            onChange={(e) => handleChangePriority(e)}
          />
          <label htmlFor="crm-priority-hight" className='item-text priority-text-danger pointer mb-0'>{CRM.High}</label>
        </div>
        <div className='priority-item'>
          <input
            type='radio'
            id="crm-priority-medium"
            name="crm-priority-medium"
            value='medium'
            checked={reservationPriority === "medium"}
            onChange={(e) => handleChangePriority(e)}
          />
          <label htmlFor="crm-priority-medium" className='item-text priority-text-medium pointer mb-0'>{CRM.Medium}</label>
        </div>
        <div className='priority-item'>
          <input
            type='radio'
            id="crm-priority-low"
            name="crm-priority-low"
            value='low'
            checked={reservationPriority === "low"}
            onChange={(e) => handleChangePriority(e)}
          />
          <label htmlFor="crm-priority-low" className='item-text priority-text-low pointer mb-0'>{CRM.Low}</label>
        </div>
      </div>
      <div className='priority-item-btn'>
        <button
          className='btn btn-block item-btn active'
          onClick={savePriority}
          disabled={!reservationPriority}
        >
          {CRM.Save}
          
        </button>
      </div>
    </div>
  )
}
