import moment from "moment";

export const cleanEmpty = (obj) => {
	if (Array.isArray(obj)) {
		return obj
			.map((v) =>
				v && typeof v === "object" && !(v instanceof Date) ? cleanEmpty(v) : v
			)
			.filter((v) => !(v == null));
	} else {
		return Object.entries(obj)
			.map(([k, v]) => [
				k,
				v && typeof v === "object" && !(v instanceof Date) ? cleanEmpty(v) : v,
			])
			.reduce(
				(a, [k, v]) =>
					v == null ||
					v.length == 0 ||
					(Object.keys(v).length === 0 && v.constructor === Object)
						? a
						: ((a[k] = v), a),
				{}
			);
	}
};
export const addDays = (theDate, days) => {
	return moment(theDate?.getTime() + days * 24 * 60 * 60 * 1000);
};

export function convertObjToQueryParams(obj) {
	if (typeof obj !== 'object' && Array.isArray(obj) && obj === null)  return "";
	obj = cleanEmpty(obj)
	const params = [];
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const value = encodeURIComponent(obj[key]);
			params.push(`${key}=${value}`);
		}
	}
	return params.join('&');
}
