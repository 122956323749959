import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import axios from "axios";
import OnlineBankingFilter from "./OnlineBankingFilter";
import OnlineBankingPagination from "./OnlineBankingPagination";
import Locale from "translations";

export default function OnlineBankingTable(props) {
	const { paymentAdmin } = Locale;
	const [onlineBankList, setOnlineBankList] = useState([]);

	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		axios.get("../onlineBankingList.json").then((res) => {
			setOnlineBankList(res.data.onlineBankingList);
		});
	}, []);

	const removeItem = (index) => {
		setOnlineBankList(onlineBankList.filter((k, i) => i !== index));
	};

	const memberStatus = (status) => {
		if (status === "Active") {
			return "Active";
		} else {
			return "Deactive";
		}
	};

	const memberCheck = (status) => {
		if (status === 0) {
			return "fa-stop text-danger";
		} else {
			return "fa-play text-success";
		}
	};

	//Filter Currency Name
	const filterCurrencyName = ({ bankName }) => {
		return bankName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
	};

	// Reset Filter
	const resetFilter = () => {
		setSearchValue("");
	};

	const AllProducts = onlineBankList
		.filter(filterCurrencyName)
		.map((onlineBankList, index) => {
			return (
				<tr key={onlineBankList.id}>
					<th scope="row">{onlineBankList.id}</th>
					<td>{onlineBankList.bankName}</td>
					<td>{onlineBankList.bankCurrencies}</td>
					<td>{onlineBankList.selectedCountries}</td>
					<td>
						<p
							className={`${memberStatus(onlineBankList.status)} member-status`}
						>
							{memberStatus(onlineBankList.status)}
						</p>
					</td>
					<td className="actions">
						<i className="fas fa-edit fa-fw text-primary"></i>
						<i
							className={`fas fa-fw ${memberCheck(onlineBankList.checkICon)}`}
						></i>
						<i
							className="fas fa-trash-alt fa-fw text-danger"
							onClick={() => removeItem(index)}
						></i>
					</td>
				</tr>
			);
		});

	return (
		<>
			<OnlineBankingFilter
				onSearch={setSearchValue}
				value={searchValue}
				onReset={resetFilter}
			/>
			<div className="product-builder-list">
				<div className="product-header-list p-0">
					<h6 className="font-weight-bold dark-blue-color">
						{paymentAdmin.messages.onlineBanking}
					</h6>
					<div className="product-buttons">
						<span>{paymentAdmin.messages.show}</span>
						<select className="py-1 px-3 mx-2">
							<option>10</option>
							<option>15</option>
							<option>25</option>
						</select>
						<span>{paymentAdmin.messages.entries}</span>
					</div>
				</div>

				<Table striped className="custom-table">
					<thead>
						<tr>
							<th>#</th>
							<th>{paymentAdmin.messages.bankName}</th>
							<th>{paymentAdmin.messages.bankCurrencies}</th>
							<th>{paymentAdmin.messages.selectedCountries}</th>
							<th>{paymentAdmin.messages.status}</th>
							<th width="11%">{paymentAdmin.messages.tools}</th>
						</tr>
					</thead>
					<tbody>{AllProducts}</tbody>
				</Table>

				<OnlineBankingPagination />
			</div>
		</>
	);
}
