import axios from "axios";
import { cleanEmpty } from './general';

const baseURL = process.env.REACT_APP_API_URL + "/v1";
const tripPlannerURL = baseURL + "/packages-management";
const productURL = process.env.REACT_APP_PRODUCTS_BUILDER;


export const createNewPackage = async (data) => {
	return await axios
		.post(`${tripPlannerURL}/packages`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateExistPackage = async (packageId, data) => {
	return await axios
		.put(`${tripPlannerURL}/packages/${packageId}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewPackageDetails = async (packageId) => {
	return await axios
		.get(`${tripPlannerURL}/packages/${packageId}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addNewHotelSegemet = async (packageId, data) => {
	return await axios
		.post(`${tripPlannerURL}/packages/${packageId}/hotels`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateHotelSegemet = async (packageId, hotelId, data) => {
	return await axios
		.post(`${tripPlannerURL}/packages/${packageId}/hotels/${hotelId}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addNewFlightSegemet = async (packageId, data) => {
	return await axios
		.post(`${tripPlannerURL}/packages/${packageId}/flights`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateFlightSegemet = async (packageId, flightId, data) => {
	return await axios
		.post(`${tripPlannerURL}/packages/${packageId}/flights/${flightId}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const addNewTransportationSegemet = async (packageId, data) => {
	return await axios
		.post(`${tripPlannerURL}/packages/${packageId}/transportations`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateTransportationSegemet = async (packageId, transportationsId, data) => {
	return await axios
		.post(`${tripPlannerURL}/packages/${packageId}/transportations/${transportationsId}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addNewAttractionSegemet = async (packageId, data) => {
	return await axios
		.post(`${tripPlannerURL}/packages/${packageId}/attractions`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateAttractionSegemet = async (packageId, attractionId, data) => {
	return await axios
		.post(`${tripPlannerURL}/packages/${packageId}/attractions/${attractionId}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteSegemet = async (packageId, itemId) => {
	return await axios
		.delete(`${tripPlannerURL}/packages/${packageId}/items/${itemId}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const tripsDurationDates = async (packageId, data) => {
	return await axios
		.post(`${tripPlannerURL}/packages/${packageId}/set-dates`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchPackagesList = async (params) => {
	return await axios
		.get(`${tripPlannerURL}/packages`,{
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err);
}
export const ViewTripPlanner = async (id) => {
	return await axios
		.get(`${tripPlannerURL}/packages/${id}`)
		.then((res) => res)
		.catch((err) => err);
}
export const deleteTripPlannerPackage = async (packageId) => {
	return await axios
		.delete(`${tripPlannerURL}/packages/${packageId}`)
		.then((res) => res)
		.catch((err) => err);
}
export const FetchpackagesNameLookups = async () => {
	return await axios
		.get(`${tripPlannerURL}/packages/look-up`)
		.then((res) => res)
		.catch((err) => err);
}


export const saveTripPricing = async (packageUUID, data) => {
	return await axios
		.post(`${productURL}/products/${packageUUID}/price`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const saveTripAvailability = async (packageUUID, data) => {
	return await axios
		.post(`${tripPlannerURL}/trip/${packageUUID}/set-availability`, data)
		.then((res) => res)
		.catch((err) => err.response);
};


export const publishAllTrips = async (packageId, data) => {
	return await axios
		.post(`${tripPlannerURL}/packages/${packageId}/publish-all`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const deleteSingleTrip = async (packageUUID) => {
	return await axios
		.delete(`${productURL}/products/${packageUUID}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const deleteAllTrips = async (packageId) => {
	return await axios
		.delete(`${tripPlannerURL}/packages/${packageId}/trips`)
		.then((res) => res)
		.catch((err) => err.response);
};

