import React from 'react'

export default function FlightSideStep({ step }) {
  return (
    <>
      {step ?
        <div className="flight-actions-side-steps">
          <span>{step}</span>
        </div>
        :
        null
      }
    </>
  )
}
