import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BusHeader from "./BusHeader";
import BusTravelers from "./BusTravelers";
import {
	AutoFillIcon,
	DeleteAllSeatsIcon,
	DisableSeatIcon,
	NoBusesIcon,
	PrintIcon,
	SaveIcon,
} from "./Icons";
import BusSeat from "./BusSeat";
import { fetchBusTypes } from "services/lookups";
import {
	addBus,
	assignBusTravelers,
	deleteBus,
	downloadBusDocuments,
	editBus,
	getSingleProduct,
} from "services/productbuilder";
import { useParams } from "react-router-dom";
import { formatProductDetails } from "./helper/formatProduct";
import generateUniqueID from "helpers/generateUniqueID";
import { store } from "react-notifications-component";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Locale from "translations";
import { useSBSState } from "context/global";
import { Bus29 } from "components/Printing/bus_29";
import { Bus30 } from "components/Printing/bus_30";
import { Bus45 } from "components/Printing/bus_45";
import { Bus49 } from "components/Printing/bus_49";

export default function BusesAccommodations() {
	const { productsBuilder, inventory } = Locale;
	const { locale } = useSBSState();
	const { id } = useParams();
	const busBluePrint = {
		bus_number: "",
		bus_type: "",
		company_name: "",
		driver_name: "",
		guide_name: "",
	};
	const [productDetails, setProductDetails] = useState({
		id: null,
		busesData: [],
		travelersData: [],
	});
	const [selectedData, setSelectedData] = useState({
		bus: null,
		traveler: null,
		seat: null,
	});
	// const [selectedBusIndex, setSelectedBusIndex] = useState();
	const [busTypesLookup, setBusTypesLookup] = useState(null);
	const [deleteBusData, setDeleteBusData] = useState({
		bus: null,
		isOpen: false,
	});
	const selectedBus = productDetails?.busesData?.find(
		(bus) => bus?.id === selectedData?.bus?.id
	);

	function toggleDeleteConfirmationModal(busData) {
		setDeleteBusData({ bus: busData || null, isOpen: !deleteBusData?.isOpen });
	}

	// changes in bus details form
	function handleBusDetailsInputs(keyName, value) {
		let cloneBusData = [...productDetails.busesData];
		let busIndex = cloneBusData.findIndex((bus) => bus?.id === selectedBus?.id);
		let selectedBusData = { ...cloneBusData[busIndex], [keyName]: value };
		cloneBusData[busIndex] = selectedBusData;
		setProductDetails({ ...productDetails, busesData: cloneBusData });
	}

	// add new bus
	function addNewBus() {
		setProductDetails({
			...productDetails,
			busesData: [
				...productDetails?.busesData,
				{ ...busBluePrint, uuid: generateUniqueID() },
			],
		});
	}

	// select bus
	function handleSelectBus(bus) {
		setSelectedData({ bus, traveler: null, seat: null });
	}

	// edit bus
	function editBusData() {
		let cloneBusData = [...productDetails.busesData];
		let busIndex = cloneBusData.findIndex((bus) => bus?.id === selectedBus?.id);
		let selectedBusData = { ...cloneBusData[busIndex], editMode: true };
		cloneBusData[busIndex] = selectedBusData;
		setProductDetails({ ...productDetails, busesData: cloneBusData });
	}

	// handle select seat and change seat status to selected
	function handleSelectSeat(currentSeatIndex) {
		let clonedBusesData = [...productDetails.busesData];
		let selectedBusData = selectedBus;
		let newBusSeats = [...selectedBusData?.seats].map((seat, index) => {
			if (currentSeatIndex === index && seat?.seatStatus === "available") {
				return { ...seat, seatStatus: "selected" };
			}
			return {
				...seat,
				seatStatus:
					seat?.seatStatus === "selected" ? "available" : seat?.seatStatus,
			};
		});
		selectedBusData.seats = newBusSeats;
		const currentSeat = clonedBusesData.find(
			(bus) => bus?.id === selectedBus?.id
		)?.seats[currentSeatIndex];
		// prevent select any non normal status
		if (currentSeat.seatStatus !== "available") {
			setSelectedData({ ...selectedData, traveler: null, seat: currentSeat });
		} else {
			setSelectedData({ ...selectedData, traveler: null, seat: null });
		}
		setProductDetails({ ...productDetails, busesData: clonedBusesData });
	}

	// assign traveler to seat
	function assignTravelerToSeat(traveler, seat) {
		let travelersClone = [...productDetails.travelersData];
		let cloneBusesData = [...productDetails.busesData];
		let currentBus = selectedBus;
		let currentTraveler = traveler || selectedData.traveler || null;
		let currentSeat = seat || selectedData.seat || null;

		if (currentTraveler && currentSeat) {
			if (
				currentSeat?.seatStatus !== "booked" &&
				currentSeat?.seatStatus !== "disabled"
			) {
				let busSeats = [...currentBus?.seats].map((seat) => {
					return {
						...seat,
						seatStatus:
							currentSeat?.seatNumber === seat?.seatNumber
								? "booked"
								: seat?.seatStatus,
						passenger_id:
							currentSeat?.seatNumber === seat?.seatNumber
								? currentTraveler?.id
								: seat?.passenger_id,
						isNew: currentSeat?.seatNumber === seat?.seatNumber,
					};
				});

				let travelerIndex = travelersClone.findIndex(
					(t) => t.id === currentTraveler?.id
				);
				travelersClone[travelerIndex] = {
					...currentTraveler,
					bus: {
						seat: {
							...currentSeat,
							seatStatus: "booked",
							passenger_id: currentTraveler?.id,
						},
						id: selectedBus?.id,
					},
				};
				currentBus.seats = busSeats;
				setProductDetails({
					...productDetails,
					busesData: cloneBusesData,
					travelersData: travelersClone,
				});
			}
			setSelectedData({ ...selectedData, traveler: null, seat: null });
		}
	}

	// unassign traveler from seat
	function unassignTraverlerSeat(traveler, seat, seatIndex) {
		const passengerId = traveler?.id || seat?.passenger_id;
		let clonedBuses = [...productDetails.busesData];
		let clonedTravelers = [...productDetails.travelersData];
		let currentBus = selectedBus;
		// handle disabled seat
		if (!passengerId) {
			currentBus.seats[seatIndex] = {
				...seat,
				seatStatus: "available",
				isNew: false,
			};
		}
		// handle assigned seat
		else {
			const travelerIndex = productDetails.travelersData.findIndex(
				(t) => t.id === passengerId
			);
			traveler = clonedTravelers[travelerIndex];
			seatIndex = seatIndex || +traveler?.bus?.seat?.seatNumber - 1;
			let currentSeat = currentBus?.seats[seatIndex];
			clonedTravelers[travelerIndex] = { ...traveler, bus: null };
			currentBus.seats[seatIndex] = {
				...currentSeat,
				seatStatus: "available",
				passenger_id: null,
				isNew: false,
			};
		}

		setProductDetails({
			...productDetails,
			busesData: clonedBuses,
			travelersData: clonedTravelers,
		});
		setSelectedData({ ...selectedData, traveler: null, seat: null });
	}

	// disable selected seat seat
	function disableSeat() {
		let cloneBusesData = [...productDetails.busesData];
		let currentBus = selectedBus;
		let currentBusSeats = [...currentBus?.seats];
		let seatIndex = currentBusSeats.findIndex(
			(seat) => seat?.seatNumber === selectedData.seat?.seatNumber
		);
		let newSeat = {
			...selectedData.seat,
			passenger_id: null,
			seatStatus:
				selectedData.seat?.seatStatus !== "booked"
					? "disabled"
					: selectedData.seat?.seatStatus,
			isNew: true,
		};
		currentBusSeats[seatIndex] = newSeat;
		currentBus.seats = currentBusSeats;
		setProductDetails({ ...productDetails, busesData: cloneBusesData });
		setSelectedData({ ...selectedData, seat: null });
	}

	// reset all seats to normal status
	function resetAllSeats() {
		let cloneBusesData = [...productDetails.busesData];
		let travelersClone = [...productDetails.travelersData];
		let currentBus = selectedBus;
		let busSeats = [...currentBus?.seats].map((bus) => ({
			...bus,
			seatStatus: "available",
			passenger_id: null,
			isNew: false,
		}));
		travelersClone = travelersClone.map((traveler) => {
			return {
				...traveler,
				bus: selectedData?.bus?.id === traveler?.bus?.id ? null : traveler?.bus,
			};
		});
		currentBus.seats = busSeats;
		setProductDetails({
			...productDetails,
			busesData: cloneBusesData,
			travelersData: travelersClone,
		});
		setSelectedData({ ...selectedData, seat: null, traveler: null });
	}

	// auto fill seats
	function autoFillSeats() {
		let filtereddTravelersPrivate =productDetails.travelersData?.filter((item)=>item.reservation_category==="private");
		let filtereddTravelersShared =productDetails.travelersData?.filter((item)=>item.reservation_category==="shared");


		let clonedTravelers = [...filtereddTravelersPrivate ,...filtereddTravelersShared];
		let clonedBuses = [...productDetails.busesData];
		let currentBus = selectedBus;
		let busSeats = [...selectedBus?.seats];
		let pendingTravelers = [];

		for (let index = 0; index < clonedTravelers.length; index++) {
			const traveler = clonedTravelers[index];
			let firstAvaliableSeat;
			let firstAvaliableSeatIndex;
			if (!traveler?.bus) {
				firstAvaliableSeat = busSeats?.find((seat) => seat?.seatStatus !== "disabled" && seat?.seatStatus !== "booked");
				firstAvaliableSeatIndex = busSeats?.findIndex((seat) => seat?.seatStatus !== "disabled" && seat?.seatStatus !== "booked");
				// check if current traveler is female and there is available seat => true (next)
				// check prev seat has female or empty =>  true (next) =>> add the traveler
				// check prev seat has male or !empty add this traveler to the pendingTravelers,
				// if pendingTravelers not empty assign to seats

			

				if (firstAvaliableSeat) {
					clonedTravelers[index] = {
						...traveler,
						bus: {
							id: currentBus?.id,
							seat: {
								...firstAvaliableSeat,
								seatStatus: "booked",
								passenger_id: traveler?.id || null,
							},
						},
					};
					busSeats[firstAvaliableSeatIndex] = {
						...firstAvaliableSeat,
						seatStatus: "booked",
						passenger_id: traveler?.id || null,
					};
				}

				if (filtereddTravelersPrivate?.length ===index) {
					busSeats[firstAvaliableSeatIndex] = {
						...firstAvaliableSeat,
						seatStatus: "disabled",
						passenger_id: null,
					};

					firstAvaliableSeat = busSeats[firstAvaliableSeatIndex + 1]
					firstAvaliableSeatIndex = firstAvaliableSeatIndex + 1

					clonedTravelers[index] = {
						...traveler,
						bus: {
							id: currentBus?.id,
							seat: {
								...firstAvaliableSeat,
								seatStatus: "booked",
								passenger_id: traveler?.id || null,
							},
						},
					};
					
					busSeats[firstAvaliableSeatIndex] = {
						...firstAvaliableSeat,
						seatStatus: "booked",
						passenger_id: traveler?.id || null,
					};
					 
				}
			}
		}
		currentBus.seats = busSeats;
		setProductDetails({
			...productDetails,
			busesData: clonedBuses,
			travelersData: clonedTravelers,
		});
	}

	function handleSelectTraveler(traveler) {
		if (!traveler.bus) {
			setSelectedData({ ...selectedData, seat: null, traveler });
		}
	}

	function successfulResponse(message) {
		store.addNotification({
			title: "success",
			message: message,
			type: "success",
			insert: "top",
			container: "top-right",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: {
				duration: 2000,
				onScreen: true,
				pauseOnHover: true,
			},
		});
	}

	// save assigned travelers
	async function saveAssignedSeats() {
		let bus_with_travellers = productDetails.busesData
			?.filter((bus) => bus?.id)
			.map((bus) => {
				const seats = bus?.seats?.filter(
					(seat) =>
						seat?.passenger_id !== null || seat?.seatStatus === "disabled"
				);
				return {
					bus_id: bus?.id,
					product_id: productDetails.id,
					travellers: seats?.map((seat) => {
						return {
							traveller_id: seat?.passenger_id,
							seat_number: +seat?.seatNumber,
							is_disabled: seat?.seatStatus === "disabled" ? 1 : 0,
						};
					}),
				};
			});
		let data = { bus_with_travellers };
		const res = await assignBusTravelers(data);
		if (res?.status === 200) {
			const formatProduct = formatProductDetails(res?.data?.data, busBluePrint);
			setProductDetails(formatProduct);
			successfulResponse(
				res?.data?.message || "Travelers assigned successfully"
			);
		}
	}

	// save bus data
	async function saveBusData() {
		const data = {
			product_id: productDetails?.id,
			bus_number: selectedBus?.bus_number,
			bus_type_id: selectedBus?.bus_type?.id,
			company_name: selectedBus?.company_name,
			driver_name: selectedBus?.driver_name,
			guide_name: selectedBus?.guide_name,
		};
		const res = await addBus(data);
		if (res?.status === 200) {
			const formatProduct = formatProductDetails(res?.data?.data, busBluePrint);
			setProductDetails(formatProduct);
			successfulResponse(res?.data?.message || "bus added successfully");
		}
	}

	// edit bus data and send it to server
	async function editBusDetails(busId) {
		const data = {
			product_id: productDetails?.id,
			bus_number: selectedBus?.bus_number,
			bus_type_id: selectedBus?.bus_type?.id,
			company_name: selectedBus?.company_name,
			driver_name: selectedBus?.driver_name,
			guide_name: selectedBus?.guide_name,
		};
		let cloneBusData = [...productDetails.busesData];
		let busIndex = cloneBusData.findIndex((bus) => bus?.id === selectedBus?.id);
		let selectedBusData = { ...cloneBusData[busIndex], editMode: false };
		cloneBusData[busIndex] = selectedBusData;
		const res = await editBus(busId, data);
		if (res?.status === 200) {
			const formatProduct = formatProductDetails(res?.data?.data, busBluePrint);
			setProductDetails(formatProduct);
			successfulResponse(res?.data?.message || "bus updated successfully");
		}
	}

	// remove bus
	async function removeBus(busId, busUUID) {
		let cloneBusData = [...productDetails.busesData];
		if (busId) {
			const res = await deleteBus(busId);
			if (res?.status === 200) {
				const formatProduct = formatProductDetails(
					res?.data?.data,
					busBluePrint
				);
				setProductDetails(formatProduct);
				successfulResponse(res?.data?.message || "bus deleted successfully");
				setDeleteBusData({ bus: null, isOpen: false });
			}
		} else {
			cloneBusData = cloneBusData.filter((bus) => bus?.uuid !== busUUID);
			setProductDetails({ ...productDetails, busesData: [...cloneBusData] });
			// if (selectedBusIndex === cloneBusData.length) {
			//   setSelectedBusIndex(cloneBusData.length - 1)
			// }
		}
	}

	function handlePrint() {
		window.print();
	}

	// fetch product data
	useEffect(() => {
		async function getProductDetails() {
			const res = await getSingleProduct(id);
			if (res?.status === 200) {
				const formatProduct = formatProductDetails(res?.data?.data, {
					...busBluePrint,
					uuid: generateUniqueID(),
				});
				setProductDetails(formatProduct);
				setSelectedData({
					bus: formatProduct?.busesData[0],
					traveler: null,
					seat: null,
				});
			}
		}
		if (!productDetails.id) {
			getProductDetails();
		}
	}, []);

	// fetch bus types lookup
	useEffect(() => {
		async function getBusTypesLookup() {
			const res = await fetchBusTypes();
			if (res?.status === 200) {
				const formatBusTypes = res?.data?.data?.map((busType) => {
					return {
						...busType,
						value: busType?.id,
						label: `${busType?.name} ${busType?.capacity}`,
						name: `${busType?.name} ${busType?.capacity}`,
					};
				});
				setBusTypesLookup(formatBusTypes);
			}
		}
		if (!busTypesLookup) {
			getBusTypesLookup();
		}
	}, []);

	/*** Start Bus Accommendation */
	const dawnloadBus = async () => {
		const { data } = await downloadBusDocuments(selectedBus?.id);

		const dir = locale == "en" ? "ltr" : "rtl";
		var respos;
		if (data) {
			switch (data?.data?.bus_type?.product) {
				case 29:
					respos = Bus29;
					break;
				case 30:
					respos = Bus30;
					break;
				case 45:
					respos = Bus45;
					break;
				case 49:
					respos = Bus49;
					break;
				default:
					break;
			}
			let EmptyPassenger = {};
			for (let i = 1; i <= 49; i++) {
				EmptyPassenger = { ...EmptyPassenger, [`[passenger_${i}]`]: "" };
			}
			let all = {};
			data?.data?.travellers.forEach(
				(item, index) =>
				(EmptyPassenger = {
					...EmptyPassenger,
					[`[passenger_${item.seat_number}]`]: item.is_disabled
						? "X"
						: item.name,
				})
			);
			const keyValuePair = {
				"[dir]": dir,
				"[Company]": inventory.Company,
				"[date]": inventory.date,
				"[BusNo]": inventory.BusNo,
				"[BusNo_api]": data.data.bus_number,
				"[BusType]": inventory.BusType,
				"[driver]": inventory.driver,
				"[supervisor]": inventory.supervisor,
				"[door]": inventory.door,
				"[wc]": inventory.wc,
				"[BackDoor]": inventory.BackDoor,
				"[company_name]": data.data.company_name,
				"[created_at]": data.data.created_at,
				...EmptyPassenger,
				...all,
			};

			for (const key in keyValuePair) {
				respos = respos.replace(key, keyValuePair[key]);
			}

			var params =
				"width=" +
				window.innerWidth +
				", height=" +
				window.innerHeight +
				", top=0, left=0" +
				",toolbar=no,scrollbars=no,status=no,menubar=no";
			//"top=0,left=0,height=80%,width=auto"
			var popupWin = window.open("", " ", params);
			popupWin.document.open();
			popupWin.document.write(`
			<html>
			<head>
			<title> print Invoice</title>
			<style>

			</style>
			</head>
			<body onLoad="{()=>window.print()}">${respos}</body>
			</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};
	/*** End Bus Accommendation */

	return (
		<>
			{productDetails?.busesData?.length > 0 ? (
				<div className="buses-accommodation-container">
					<div className="container">
						{/* bread crumb */}
						<div className="row mb-3">
							<h1 className="h5">
								<Link to="/products-builder/products" className="text-yellow">
									{productsBuilder.tripManagement}/{" "}
								</Link>
								{productsBuilder.busAccommodation}
							</h1>
						</div>

						<div className="row">
							<div className="col-md-4">
								<BusTravelers
									selectedBus={selectedBus}
									travelersData={productDetails?.travelersData}
									selectedTraveler={selectedData.traveler}
									handleSelectTraveler={handleSelectTraveler}
									assignTravelerToSeat={assignTravelerToSeat}
									unassignTraverlerSeat={unassignTraverlerSeat}
								/>
							</div>
							{/* buses data */}
							<div className="col-md-8">
								<div className="bus-details-container">
									<BusHeader
										busesData={productDetails?.busesData}
										selectedBus={selectedBus}
										busTypesLookup={busTypesLookup}
										handleBusDetailsInputs={handleBusDetailsInputs}
										saveBusData={saveBusData}
										editBusData={editBusData}
										editBusDetails={editBusDetails}
										addNewBus={addNewBus}
										handleSelectBus={handleSelectBus}
										toggleDeleteConfirmationModal={
											toggleDeleteConfirmationModal
										}
										removeBus={removeBus}
									/>

									<hr className="seperator" />
									{selectedBus?.isSaved ? (
										<>
											<div className="action-btns">
												<button
													className="btn btn-outline font-weight-bold d-flex"
													onClick={saveAssignedSeats}
													// disabled={selectedBus?.seats?.filter(seat => seat?.passenger_id).length === 0}
													disabled={
														!productDetails.busesData?.find((bus) =>
															bus?.seats?.find((seat) => seat?.passenger_id)
														)
													}
												>
													<SaveIcon />
													<span className="mx-2">
														{inventory.messages.savechanges}
													</span>
												</button>

												<button
													className="btn btn-outline font-weight-bold"
													onClick={handlePrint}
												>
													<PrintIcon />
													<span className="mx-2">{productsBuilder.print}</span>
												</button>

												<button
													className="btn btn-outline font-weight-bold"
													onClick={() => dawnloadBus()}
												>
													<PrintIcon />
													<span className="mx-2">
														{productsBuilder.BusaccommodationReport}
													</span>
												</button>
											</div>

											<div className="bus-seats-container">
												<div className="row">
													{/* bus seats seats */}
													<div className="col-md-8 d-flex flex-column justify-content-center">
														<div
															className={`seats-container ${selectedBus?.seats?.length > 30 ? "big-bus" : ""
																}`}
														>
															{selectedBus.seats?.map((seat, seatIndex) => {
																return (
																	<BusSeat
																		key={seat?.seatNumber}
																		seatIndex={seatIndex}
																		seat={seat}
																		handleSelectSeat={handleSelectSeat}
																		assignTravelerToSeat={assignTravelerToSeat}
																		unassignTraverlerSeat={
																			unassignTraverlerSeat
																		}
																	/>
																);
															})}
														</div>
													</div>
													{/* seat action buttons */}
													<div className="col-md-4">
														<div className="seats-action-btns">
															<button
																className="btn btn-outline w-100 mt-2 justify-content-start"
																onClick={autoFillSeats}
															>
																<AutoFillIcon />
																<span className="mx-2">
																	{productsBuilder.autoFillSeats}
																</span>
															</button>
															<button
																className="btn btn-outline w-100 mt-2 justify-content-start"
																disabled={
																	selectedData.seat === null ||
																	selectedData.seat?.seatStatus === "booked" ||
																	selectedData.seat?.seatStatus === "disabled"
																}
																onClick={() => disableSeat()}
															>
																<DisableSeatIcon />
																<span className="mx-2">
																	{productsBuilder.disableSelectedSeat}
																</span>
															</button>
															<button
																className="btn btn-outline w-100 mt-2 justify-content-start"
																onClick={resetAllSeats}
															>
																<DeleteAllSeatsIcon />
																<span className="mx-2">
																	{productsBuilder.deleteAllSeats}
																</span>
															</button>
														</div>
													</div>
													{/* seats colors guide */}
													<div className="col-md-10 col-12 py-2 d-flex justify-content-between flex-wrap">
														<div className="seats-guide available">
															<span className="circle"></span>
															<span className="mx-2">
																{productsBuilder.availableSeat}
															</span>
														</div>
														<div className="seats-guide selected">
															<span className="circle"></span>
															<span className="mx-2">
																{productsBuilder.selectedSeat}
															</span>
														</div>
														<div className="seats-guide booked">
															<span className="circle"></span>
															<span className="mx-2">
																{productsBuilder.bookedSeat}
															</span>
														</div>
														<div className="seats-guide disabled">
															<span className="circle"></span>
															<span className="mx-2">
																{productsBuilder.disabledSeat}
															</span>
														</div>
													</div>
												</div>
											</div>
										</>
									) : (
										<div className="d-flex flex-column justify-content-center align-items-center my-5">
											<NoBusesIcon />
											<span className="mt-4 text-grey-500 font-weight-bold">
												{" "}
												“ {productsBuilder.pleaseAddBusDetailsFirst} ”
											</span>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}

			{/* delete bus confiramtion */}
			<Modal isOpen={deleteBusData.isOpen}>
				<ModalHeader toggle={toggleDeleteConfirmationModal}>
					delete bus "{deleteBusData.bus?.bus_number}"
				</ModalHeader>
				<ModalBody>
					Are you sure you want to delete the bus? all your unsaved changes in
					other buses will be lost
				</ModalBody>
				<ModalFooter>
					<div className="d-flex justify-content-end">
						<button
							className="btn bg-nxt px-3 py-1 mx-2"
							onClick={() => removeBus(deleteBusData.bus?.id)}
						>
							Yes
						</button>
						<button
							className="btn bg-nxt px-3 py-1"
							onClick={() => toggleDeleteConfirmationModal(null)}
						>
							No
						</button>
					</div>
				</ModalFooter>
			</Modal>
		</>
	);
}
