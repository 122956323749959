import React from "react";

export default function SearchColoredIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="Layer_2"
			data-name="Layer 2"
			width="14.185"
			height="14.185"
			viewBox="0 0 14.185 14.185"
		>
			<path
				id="search"
				d="M16.952,15.833l-2.678-2.671A6.239,6.239,0,0,0,15.6,9.3a6.3,6.3,0,1,0-6.3,6.3,6.239,6.239,0,0,0,3.86-1.331l2.671,2.678a.791.791,0,1,0,1.119-1.119ZM4.576,9.3A4.727,4.727,0,1,1,9.3,14.029,4.727,4.727,0,0,1,4.576,9.3Z"
				transform="translate(-3 -3)"
				fill="#d19d4e"
			/>
		</svg>
	);
}
