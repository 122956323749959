// ** imports
import React, { useEffect, useState } from 'react'

// ** components
import TextField from 'components/Form/TextField/TextField'
import SearchIcon from 'assets/images/crm/search.svg'

// ** hooks
import Locale from 'translations'
import DatePickerField from 'components/Form/DatePickerField/DatePickerField'
import moment from 'moment'

export default function FiltersAvailablePackages({ setSearchedPackages, getAvailablePackages, currentPackageReservationNum, currentPackage }) {
	// ** hooks
	const { CRM } = Locale;
	const [searchedPackagesFiler, setSearchedPackagesFiler] = useState({
		search: "",
		departure_date: null,
	});

	useEffect(() => {
		if (searchedPackagesFiler.search || searchedPackagesFiler.departure_date) {
			const formatFilters = {
				...searchedPackagesFiler,
				departure_date: searchedPackagesFiler?.departure_date ? moment(searchedPackagesFiler?.departure_date).format("YYYY-MM-DD") : ""
			}
			getAvailablePackages(currentPackageReservationNum, { search_for: "change", ...formatFilters })
		}
	}, [currentPackageReservationNum, searchedPackagesFiler])

	return (
		<div className='actions-wrapper'>
			<div className='items-wrapper'>
				<div className='items'>
					<h3>{CRM.changePackage}</h3>
				</div>
				<div className='items'>
					<TextField
						type='text'
						id='packages-search'
						name='packages-search'
						hasLabel={false}
						placeholder={CRM.searchOrFilter}
						isImage={true}
						image={SearchIcon}
						prependImage={true}
						value={searchedPackagesFiler?.search}
						onChange={(e) => {
							setSearchedPackagesFiler({ ...searchedPackagesFiler, search: e.target.value });
						}}
					/>
				</div>
				<div className='items'>
					<DatePickerField
						placeholder='DD/MM/YYYY'
						hasLabel={false}
						id='packages-date'
						name='packages-date'
						date={searchedPackagesFiler?.departure_date}
						onChangeDate={(e) => {
							setSearchedPackagesFiler({ ...searchedPackagesFiler, departure_date: e });
						}}
						isOutsideRange={(day) => {
							return day.isBefore(moment(currentPackage?.departure_date_at).subtract("5", "days")) ||  day.isAfter(moment(currentPackage?.departure_date_at).add("5", "days"))
						}}
					/>
				</div>
			</div>
			<div className='items-wrapper'>
				<button
					className='btn item active width-150'
					onClick={() =>
						setSearchedPackages({
							isOpen: false,
							currentPackage: null,
							availablePackagesList: []
						})}
				>
					{CRM.Cancel}
				</button>
			</div>
		</div>
	)
}
