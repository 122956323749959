import Locale from "translations";
export default function RequestStatus(props) {
	const { inventory } = Locale;
	const translate = inventory.viewReservation;

	// const [promoOrVoucherState, setPromoOrVoucherState] = useState(null);
	// const [promoState, setPromoState] = useState(null);

	// useEffect(() => {
	// 	const Test = async () => {
	// 		const res = await getPromoVoucher({
	// 			brn: props?.hotelInfo?.brn,
	// 		});
	// 		// 
	// 		

	// 		setPromoOrVoucherState(res?.data?.voucherCode?.files);

	// 		setPromoState(res?.data?.voucherCode?.code);
	// 	};
	// 	Test();
	// }, []);

	const allSteps = (
		<ul className="progressbar mt-3 mb-2">
			{props.status.map((item, index) => {
				return (
					<li className={`progressbarItem   `}>
						<div
							className={`step-holder   ${item.status === "inactive" ? "" : "active-item"
								}  ${index === +props.status.length - 1
									? "remove-after adjust-step-holder"
									: ""
								} `}
						>
							<div
								className={`step step-dashes mb-1 ${item.status === "inactive"
										? props.status[+index - 1]?.status === "active" &&
											props.status[+index]?.status === "inactive"
											? "bg-yellow-progressbar"
											: //active-status
											""
										: "active-status-bg"
									} `}
							>
								{item.status === "inactive" ? (
									// <i class="fas fa-ellipsis-h icon-step  "></i>
									<i className="far fa-clock icon-step"></i>
								) : (
										<i className="fas fa-check text-success icon-step"></i>
								)}
							</div>
						</div>
						<span
							className={`item-names dark-blue-color mb-2 ${item.status === "inactive"
									? "item-name-inactive"
									: "item-name-active"
								}`}
						>
							{item.name}
						</span>
						{props.status[0].status === "active" &&
							item.status === "inactive" &&
							(item.name === inventory.messages.PayDeposit ||
								item.name === inventory.messages.PayFullAmount) ? (
							<button
								className="btn btn-link btn-pay text-underline"
								onClick={(e) => {
									props.togglePayModal(!props.modalPay);
								}}
							>
								{translate.PayNow}
								{/* pay Now */}
							</button>
						) : item.status === "active" &&
							item.name === "Promo Code Issuance" ? (
							<button
								className="btn btn-link text-success font-weight-bold text-underline"
								onClick={(e) => {
									props.togglePromocodeModal();
								}}
							>
								{/* {props?.promoState} */}
								{props.promoVoucher?.voucher_code?.code ??
									props.promoVoucher?.voucher_code?.code}
								{props.promoVoucher?.voucher_code == null ? (
									""
								) : (
									// <p className="text-primary">Wait For promo</p>
									<i className="far fa-clone text-primary mx-1	"></i>
								)}
							</button>
						) : item.status === "active" &&
							item.name === inventory.messages.VoucherIssuance ? (
							// props.promoVoucher?.voucher_code?.files != null &&
							(props?.hotelInfo.reservation_status === "paid" &&
								props?.hotelInfo.reservation_type === "auto") ||
								(props?.hotelInfo.reservation_status === "confirmed" &&
									props?.hotelInfo.reservation_type === "manual") ? (
								""
							) : (
								<small className="text-primary">
									{translate.WaitForVoucher}
								</small>
							)
						) : (
							<button className="btn btn-link btn-pay"></button>
						)}
					</li>
				);
			})}
		</ul>
	);
	return (
		<div className="progressbar-info  bg-white my-3 rounded mx-2 ">
			<div className="bar-header rounded ">
				{props.hotelInfo.gds != 4 ? (
					<h6 className="px-2 py-2 bg-gray-200 rounded font-weight-bold dark-blue-color">
						{props.title}
					</h6>
				) : null}
			</div>
			<div className="p-3 box-container">{allSteps}</div>
		</div>
	);
}
