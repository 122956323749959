// ** imports
import React, { useEffect, useState } from 'react'

// ** components
import Actions from './Actions'
import CRMFilters from './Filters'
import CRMList from './List'
import CreateApplicationModel from './models/CreateApplicationModel'
import { useSBSState } from 'context/global'
import CurrentPackage from './CurrentPackage'
import FiltersAvailablePackages from './FiltersAvailablePackages'
import ListAvailablePackage from './ListAvailablePackage'

// ** Services
import { CRMexportExcel, fetchSearchedPackages, reservationPackagesList } from 'services/CRM'
import Pagination from 'components/Pagination'
import moment from 'moment'
import { store } from 'react-notifications-component'

const CRMMain = () => {

	const initialFilters = {
		page: 1,
		search: "",
		reservation_from: ["offline", "b2b", "website"],
		priority: {},
		reservation_status: {},
		request_date: undefined,
		departure_date: undefined,
		total_from: "",
		total_to: "",
	}

	// ** states
	const [toggleFilter, setToggleFilter] = useState(false)
	const [createApplicationIsOpen, setCreateApplicationIsOpen] = useState(false)
	const [reservationList, setListReservationList] = useState([])
	const [metaData, setMetaData] = useState(null)
	const [filters, setFilters] = useState(initialFilters);
	const [selectedRows, setSelectedRows] = useState([]);
	const [searchedPackages, setSearchedPackages] = useState({
		isOpen: false,
		currentPackage: null,
		availablePackagesList: [],
	});


	// ** Lookups
	const { allCountries } = useSBSState()
	const countries = allCountries

	// ** toggle create application
	const toggleCreateApplicationModal = () => {
		setCreateApplicationIsOpen(!createApplicationIsOpen)
	}

	function formatFilters(filters) {
		let filteredData = {
			page: filters?.page,
			search_for: filters?.search,
			reservation_from: filters?.reservation_from?.value || filters?.reservation_from,
			priority: filters?.priority?.value || filters?.priority,
			reservation_status: filters?.reservation_status?.value || filters?.reservation_status,
			request_date: filters?.request_date
				? moment(filters.request_date).format('YYYY-MM-DD')
				: null,
			departure_date: filters?.departure_date
				? moment(filters?.departure_date).format('YYYY-MM-DD')
				: null,
			total_from: filters?.total_from,
			total_to: filters?.total_to
		}
		return filteredData
	}

	// ** Get all reservation list
	const getAllReservationPackage = async (type, searchFilters, page) => {
		let filtersData = formatFilters(type === 'reset' ? initialFilters : { ...searchFilters, page })

		const res = await reservationPackagesList(filtersData)
		if (res?.status === 200) {
			setListReservationList(res?.data?.data);
			setMetaData(res?.data?.meta);
		}
	}

	// ** reset form filter
	function resetFilters() {
		setFilters(initialFilters)
		getAllReservationPackage('reset')
	}
	// handle paginations
	function goTo(page) {
		getAllReservationPackage(null, initialFilters, page);
		setSelectedRows([]);
	}
	// select and descelect rows
	function handleSelectRows(checkedFlag, uuid) {
		if (checkedFlag) {
			setSelectedRows([...selectedRows, uuid]);
		} else {

			setSelectedRows([...selectedRows.filter((row) => row !== uuid)]);
		}
	}

	// get searched pacakges
	async function getAvailablePackages(packageReservationNumber, packagesFilters) {
		const res = await fetchSearchedPackages(packageReservationNumber, packagesFilters);
		const currentPackage = reservationList?.find(item => item?.reservation_num === packageReservationNumber)
		if (res?.status === 200 && res?.data?.data?.length > 0) {
			setSearchedPackages({
				isOpen: true,
				currentPackage: { ...res?.data?.package_data, reservation_num: packageReservationNumber, pax: currentPackage?.pax },
				availablePackagesList: res?.data?.data
			});
		} else {
			store.addNotification({
				message: "No Packages found",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2500,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	useEffect(() => {
		getAllReservationPackage('reset')
	}, []);

	async function exportToExcel() {
		const filterData = formatFilters({ ...filters, page: null })
		const res = await CRMexportExcel(filterData);
		if (res?.status === 200) {
			const blob = new Blob([res.data], {
				type: res.data.type,
			});
			const objectUrl = window.URL.createObjectURL(blob);
			var anchor = document.createElement("a");
			anchor.target = "_blank";
			anchor.href = objectUrl;
			anchor.setAttribute(
				"download",
				`CRM-${moment().format("DD-MM-YYYY")}`
			);
			anchor.click();
		}
		store.addNotification({
			title: "Info!",
			message: `Export To Excel Successful`,
			type: "success",
			insert: "top",
			container: "top-right",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: {
				duration: 3000,
				onScreen: true,
				pauseOnHover: true,
			},
		});
	}


	return (
		<>
			<div className='crm-wrapper'>
				<Actions
					toggleCreateApplicationModal={toggleCreateApplicationModal}
					getAllReservationPackage={getAllReservationPackage}
					resetFilters={resetFilters}
					selectedRows={selectedRows}
					setSelectedRows={setSelectedRows}
					getAvailablePackages={getAvailablePackages}
					searchedPackagesOpen={searchedPackages.isOpen}
					exportToExcel={exportToExcel}
				/>

				{searchedPackages.isOpen ? (
					<>
						<FiltersAvailablePackages
							setSearchedPackages={setSearchedPackages}
							getAvailablePackages={getAvailablePackages}
							currentPackageReservationNum={selectedRows[0]}
							currentPackage={searchedPackages.currentPackage}
						/>
						<CurrentPackage
							packageDetails={searchedPackages.currentPackage}
						/>
						<ListAvailablePackage
							availablePackagesList={searchedPackages.availablePackagesList}
							currentPackage={searchedPackages.currentPackage}
							setSearchedPackages={setSearchedPackages}
							resetFilters={resetFilters}

						/>
					</>
				) : (
					<>
						<CRMFilters
							toggleFilter={toggleFilter}
							setToggleFilter={setToggleFilter}
							filters={filters}
							setFilters={setFilters}
							getAllReservationPackage={getAllReservationPackage}
							resetFilters={resetFilters}
						/>
						<CRMList
							reservationList={reservationList}
							selectedRows={selectedRows}
							handleSelectRows={handleSelectRows}
						/>

						<div className='mt-4 pb-3'>
							<Pagination info={metaData} goTo={goTo} withDetailsText={true} />
						</div>
					</>
				)}
			</div>
			<CreateApplicationModel
				createApplicationIsOpen={createApplicationIsOpen}
				toggleCreateApplicationModal={toggleCreateApplicationModal}
				countries={countries}
			/>
		</>
	)
}

export default CRMMain
