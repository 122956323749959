import { formatPrice } from 'helpers/utils';
import React, { useEffect } from 'react'
import Locale from 'translations';

export default function TourSelectCategory({ tourData, selectedCategory, setSelectedCategory }) {
  const { marketPlace } = Locale;
  const tourCategories = tourData?.modalities;
  // set inital state for selected categories
  useEffect(() => {

    setSelectedCategory({
      category: tourCategories?.[0],
      rate: tourCategories?.[0]?.rates?.[0]?.rateDetails?.[0],
      rateKey: tourCategories?.[0]?.rates?.[0]?.rateDetails?.[0]?.rateKey
    });
  }, [tourCategories]);



  return (
    <section className='tour-details-select-category mt-4'>
      <h3 className='mb-2 font-weight-bold'>{marketPlace.selectCategory}</h3>
      <ul className='tour-details-categories'>
        {tourCategories?.map((category, index) => {
          return (
            <li key={category?.code} className={`tour-details-modality d-flex flex-column ${selectedCategory?.category?.code === category?.code ? "selected" : ""}`}>
              <div className='w-100 d-flex flex-column'>
                <div className='d-flex align-items-center justify-content-between p-3'>
                  <div className='d-flex align-items-center'>
                    {tourCategories?.length > 1 &&
                      <input
                        type="radio"
                        id={category?.code}
                        name={category?.code}
                        value={category?.code}
                        checked={selectedCategory?.category ? selectedCategory?.category?.code === category?.code : false}
                        onChange={() => {
                          setSelectedCategory({
                            ...selectedCategory,
                            category: category,
                            rate: category?.rates[0]?.rateDetails[0],
                            rateKey: category?.rates[0]?.rateDetails[0]?.rateKey
                          })
                        }}
                      />
                    }
                    <label htmlFor={category?.code} className="mx-2 my-0">{category?.name}</label>
                  </div>
                  <span className='price'>
                    {selectedCategory?.category?.code === category?.code ?
                      formatPrice(selectedCategory.rate?.totalAmount?.amount)
                      :
                      formatPrice(category?.rates[0]?.rateDetails[0]?.totalAmount?.amount)
                    }
                    {" "} {tourData?.currency}
                  </span>
                </div>

                <ul className='w-100 d-flex flex-column bg-white'>
                  <div className='d-flex flex-wrap  px-3'>
                    {category?.rates?.map(rates => {
                      return rates?.rateDetails?.map((rate, rateIdx) => {
                        return rate?.languages?.length > 0 || rate?.sessions?.length > 0 ?
                          <li className={`d-flex align-items-center bg-white col-4 ${rateIdx > 0 ? "" : ""}`} key={rate?.rateKey}>
                            <input
                              type="radio"
                              id={`${rate?.rateKey}`}
                              name={rate?.rateKey}
                              value={rate?.rateKey}
                              checked={rate?.rateKey === selectedCategory.rateKey}
                              onChange={(e) => {
                                setSelectedCategory({
                                  ...selectedCategory,
                                  category: category,
                                  rate: rate,
                                  rateKey: rate?.rateKey,
                                })
                              }}
                            />
                            <label htmlFor={`${rate?.rateKey}`} className="mx-1 my-0">
                              {rate?.languages?.[0]?.description || rate?.languages?.[0]}  {rate?.sessions?.[0]?.name || rate?.sessions?.[0]}
                            </label>
                          </li>
                          :
                          null

                      })
                    })}
                  </div>
                </ul>

              </div>
            </li>
          )
        })}
      </ul>
    </section>
  )
}
