import React from 'react'
import RoomsPricing from './shared/RoomsPricing'
import PricingDiscountVAT from './shared/PricingDiscountVAT'
import Locale from 'translations';

export default function TripPlannerFullPackagePricing({
  pricingForText,
  pricingData,
  setPricingData,
  toggleVatDiscount,
  selectedPricingType,
}) {
  const { packagePlanner } = Locale;
  const fullPricing = pricingData?.pricing?.find(price => price?.for === pricingData?.selected_pricing_for);
  const productHasHotel = pricingData?.product_items?.find(item => item?.item?.id === 6) ? true : false;

  function handleInputsChanges({ key, value, roomIndex }) {
    let pricingClone = [...pricingData?.pricing];
    let selectedPricingIndex = pricingClone?.findIndex(price => price?.for === pricingData?.selected_pricing_for);
    let selectedRoom = pricingClone[selectedPricingIndex].rooms[roomIndex];
    selectedRoom = {
      ...selectedRoom,
      [key]: Number(value).toString()
    };
    pricingClone[selectedPricingIndex].rooms[roomIndex] = selectedRoom;

    setPricingData({ ...pricingData, pricing: pricingClone })
  }

  function handleVatDiscountInputs({ key, value }) {
    let pricingClone = [...pricingData?.pricing];
    let selectedPricingIndex = pricingClone?.findIndex(price => price?.for === pricingData?.selected_pricing_for)
    pricingClone[selectedPricingIndex][key] = value;
    setPricingData({ ...pricingData, pricing: pricingClone })
  }


  return (
    <div className="pricing-type-wrapper">

      <RoomsPricing
        pricing={fullPricing}
        handleInputsChanges={handleInputsChanges}
        selectedPricingType={selectedPricingType}
        productHasHotel={productHasHotel}
      >
        <div className="header font-weight-bold">
          {packagePlanner.totalPackagePriceFor} <span className="text-uppercase">{pricingForText}</span> 
        </div>
      </RoomsPricing>

      <PricingDiscountVAT
        pricing={fullPricing}
        pricingForText={pricingForText}
        toggleVatDiscount={toggleVatDiscount}
        handleVatDiscountInputs={handleVatDiscountInputs}
      />

    </div>
  )
}
