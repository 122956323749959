import DatePickerField from 'components/Form/DatePickerField/DatePickerField'
import React, { useRef, useState } from 'react'
import { Collapse } from 'reactstrap'
import { ArrowDownIcon } from 'modules/UmrahOperations/shared/Icons';
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import { ReactComponent as FilterIcon } from "assets/images/umrah-operations/filter-icon.svg";
import { ReactComponent as ResetFilterIcon } from "assets/images/market-place/refresh-circle-icon.svg";
import { ReactComponent as SearchIcon } from "assets/images/umrah-operations/search-icon.svg";
import Locale from 'translations';
import moment from 'moment';
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import { fetchTransporters } from 'services/lookups';
import { fetchHotels } from "services/operationStatement";

import { useSBSState } from 'context/global';



export default function OperationsCenterFilters({ filters, setFilters, lookups, searchWithFilters, resetFilters }) {
  const { locale } = useSBSState();
  const { marketPlace, inventory, payment, operationStatement, productElements, productsBuilder } = Locale;
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [operatorsList, setOperatorsList] = useState([]);
  const [hotelsList, setHotelsList] = useState([]);
  let prevValue = useRef(null);


  const getOperatorsList = async (inputValue) => {
    if (inputValue.length > 2) {
      const transportersRes = await fetchTransporters(1, inputValue, "966");
      let result = []
      transportersRes?.forEach(item => {
        result.push({
          ...item,
          name: item.name?.[locale] || item.name,
          value: item.id,
          label: item.name?.[locale] || item.name,
        })
      });
      setOperatorsList(result);
    }
  };

  const getHotelsList = async (inputValue, filterKey) => {
    if (inputValue.length > 2) {
      const hotelsRes = await fetchHotels(966, [3, 4, 194, 134, 214, 224], inputValue);
      let result = [];
      hotelsRes?.hotels?.forEach(item => {
        result.push({
          ...item,
          name: item.name?.[locale] || item.name,
          value: item.id,
          label: item.name?.[locale] || item.name,
        })
      });
      let hotels = { [filterKey]: result }
      setHotelsList(hotels);
    }
  };



  function clearSearchText(e, key) {
    prevValue.current = filters[key];
    const value = e.target.value;
    if (value.length > 0) {
      setFilters({ ...filters, [key]: null });
    }
  }


  return (
    <div className='filter-container mt-3'>
      <button className='btn filter-collapse-btn opened px-0'
        onClick={() => setIsFilterOpen(!isFilterOpen)}
      >
        <FilterIcon />
        <span className="title me-2">{operationStatement.advancedFilter}</span>
        <ArrowDownIcon color="#000000" />
      </button>



      <Collapse isOpen={isFilterOpen}>
        <div className="row w-100 mx-0 inputs-container">

          {/* date from */}
          <div className="col-md-2 col-12">
            <DatePickerField
              label={operationStatement.Date}
              placeholder="DD/MM/YYYY"
              id="date_from"
              name="date_from"
              date={filters.date_from || null}
              onChangeDate={(date) =>
                setFilters({
                  ...filters,
                  date_from: date,
                  date_to: filters?.date_to && moment(filters?.date_to).isBefore(moment(date)) ? null : filters?.date_to
                })
              }
              isOutsideRange={() => false}
            />
          </div>

          {/* date to */}
          <div className="col-md-2 col-12">
            <DatePickerField
              label={""}
              placeholder="DD/MM/YYYY"
              id="date_to"
              name="date_to"
              date={filters.date_to || null}
              onChangeDate={(date) =>
                setFilters({
                  ...filters,
                  date_to: date
                })
              }
              isOutsideRange={(day) => filters?.date_from ? !day.isAfter(moment(filters.date_from)) : false}
            // isOutsideRange={() => false}
            />
          </div>

          {/* agent */}
          <div className="col-md-2 col-12">
            <SelectField
              label={operationStatement.Agent}
              placeholder={productsBuilder.select}
              id="agent_name"
              name="agent_name"
              options={lookups?.agents}
              value={filters?.agent?.name}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  agent: e
                })
              }}
            />
          </div>

          {/* Port */}
          <div className="col-md-2 col-12">
            <SelectField
              label={operationStatement.port}
              placeholder={productsBuilder.select}
              id="port_name"
              name="port_name"
              options={lookups?.ports}
              value={filters?.port?.name}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  port: e
                })
              }}
            />
          </div>

          {/* Operator */}
          <div className="col-md-2 col-12">
            <AutoCompleteField
              label={operationStatement.operator}
              placeholder={productsBuilder.select}
              id="operator_name"
              name="operator_name"
              listAuto={operatorsList}
              setListAuto={setOperatorsList}
              getListAuto={getOperatorsList}
              value={filters?.operator?.name || ""}
              onFocus={(e) => clearSearchText(e, "operator")}
              onBlur={() =>
                setFilters({
                  ...filters,
                  operator: prevValue.current,
                })
              }
              onChange={(e) => {
                setFilters({ ...filters, operator: { name: e } });
              }}
              onSelectValue={(e) => {
                setFilters({ ...filters, operator: e });
              }}
            />
          </div>

          {/* Sort by */}
          <div className="col-md-2 col-12">
            <SelectField
              label={operationStatement.sortby}
              placeholder={productsBuilder.select}
              id="sort_by"
              name="sort_by"
              options={lookups?.sortByLookup}
              value={filters?.sort_by?.name}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  sort_by: e
                })
              }}
            />
          </div>

          {/* pax */}
          <div className="col-md-2 col-12 row mx-0 p-0">
            {/* Pax from */}
            <div className='col-6'>
              <TextField
                type="number"
                label={marketPlace.messages.pax}
                placeholder={productsBuilder.From}
                id="pax_from"
                name="pax_from"
                value={filters?.pax_from}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    pax_from: e.target.value,
                    // pax_to: filters?.pax_to !== "" && +filters?.pax_to < +e.target.value ? "" : filters?.pax_to
                  })
                }}
              />
            </div>
            {/* Pax to */}
            <div className='col-6'>
              <TextField
                type="number"
                label={""}
                placeholder={productsBuilder.To}
                id="pax_to"
                name="pax_to"
                value={filters?.pax_to}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    pax_to: e.target.value,
                    // pax_from: filters?.pax_from !== "" && +filters?.pax_from < +e.target.value ? "" : filters?.pax_from
                  })
                }}
              />
            </div>
          </div>

          {/* From City */}
          <div className="col-md-2 col-12">
            <SelectField
              label={productElements.fromCity}
              placeholder={productsBuilder.select}
              id="city_from"
              name="city_from"
              options={lookups?.hotelCitiesLookup}
              value={filters?.city_from?.name}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  city_from: e
                })
              }}
            />
          </div>

          {/* To City */}
          <div className="col-md-2 col-12">
            <SelectField
              label={productElements.toCity}
              placeholder={productsBuilder.select}
              id="city_to"
              name="city_to"
              options={lookups?.hotelCitiesLookup}
              value={filters?.city_to?.name}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  city_to: e
                })
              }}
            />
          </div>

          {/* From Hotel */}
          <div className="col-md-2 col-12">
            <AutoCompleteField
              label={operationStatement.fromHotel}
              placeholder={productsBuilder.select}
              id="hotel_from"
              name="hotel_from"
              listAuto={hotelsList?.["hotel_from"]}
              setListAuto={setHotelsList}
              getListAuto={(e) => getHotelsList(e, "hotel_from")}
              value={filters?.hotel_from?.name || ""}
              onFocus={(e) => clearSearchText(e, "hotel_from")}
              onBlur={() =>
                setFilters({
                  ...filters,
                  hotel_from: prevValue.current,
                })
              }
              onChange={(e) => {
                setFilters({ ...filters, hotel_from: { name: e } });
              }}
              onSelectValue={(e) => {
                setFilters({ ...filters, hotel_from: e });
              }}
            />
          </div>

          {/* To Hotel */}
          <div className="col-md-2 col-12">
            <AutoCompleteField
              label={operationStatement.toHotel}
              placeholder={productsBuilder.select}
              id="hotel_to"
              name="hotel_to"
              listAuto={hotelsList?.["hotel_to"]}
              setListAuto={setHotelsList}
              getListAuto={(e) => getHotelsList(e, "hotel_to")}
              value={filters?.hotel_to?.name || ""}
              onFocus={(e) => clearSearchText(e, "hotel_to")}
              onBlur={() =>
                setFilters({
                  ...filters,
                  hotel_to: prevValue.current,
                })
              }
              onChange={(e) => {
                setFilters({ ...filters, hotel_to: { name: e } });
              }}
              onSelectValue={(e) => {
                setFilters({ ...filters, hotel_to: e });
              }}
            />
          </div>

          {/* Status */}
          <div className="col-md-2 col-12">
            <SelectField
              label={inventory.messages.Status}
              placeholder={productsBuilder.select}
              id="status"
              name="status"
              options={lookups?.statusListLookup}
              value={filters?.status?.name}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  status: e
                })
              }}
            />
          </div>


          {/* filters action buttons */}
          <div className='d-flex align-items-center flex-wrap col-12 gap-10'>
            <button className='btn bg-nxt search-btn mt-0' onClick={() => searchWithFilters({ ...filters, page: null })}>
              <SearchIcon />
              <span className='mx-2'>{marketPlace.messages.search}</span>
            </button>

            <button className='btn reset-btn mt-0' onClick={() => resetFilters()}>
              <ResetFilterIcon />
              <span className='mx-2'>{payment.messages.clear}</span>
            </button>
          </div>

        </div>
      </Collapse>

    </div>
  )
}
