import { ReactComponent as WalletIcon } from "assets/images/Tds/wallet.svg";
import { useTDSVisaState } from "context/TDSVisa";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Locale from "translations";
import VisaSearch from "../VisaSearchOld";
import CustomModal from "./Modal";

export default function ListHeader() {
	const { marketPlace, onlineVisa, inventory, payment, reservation } = Locale;
	const [openModal, setOpenModal] = useState(false);
	const [agreementModal, setAgreementModal] = useState(false);
	const { onlineVisaSearch } = useTDSVisaState();

	const toggleModel = () => {
		setOpenModal(!openModal);
	};
	const toggleAgreementModal = () => {
		setAgreementModal(!agreementModal);
	};
	const { id } = useParams();
	const statusLocale = (status) => {
		switch (status) {
			case "pending":
				return inventory.messages.pending;
			case "waiting":
				return inventory.messages.waitingVisa;
			case "cancelled":
				return inventory.messages.cancelled;
			case "issued":
				return inventory.messages.issued;
			case "expired":
				return inventory.messages.expired;
			case "rejected":
				return payment.messages.Reject;
			default:
				return status;
		}
	};

	return (
		<>
			<div className="d-flex justify-content-between flex-column bg-white border tds-visa-details-container rounded">
				{/* header */}
				<div >
					<p style={{ fontWeight: "600" }}>
						{reservation.messages.visaDetails}
					</p>
				</div>
				{/* details */}

				<div className="visa-details">
					<div className="flex-fill d-flex " >
						<div className="flex-fill">
							<p className="tds-details-title ">ID</p>
							<p className="tds-details-title ">{onlineVisa.Destination}</p>
							<p className="tds-details-title ">{onlineVisa.VisaType}</p>
							{/* <p className="tds-details-title ">{onlineVisa.TransportationType}</p> */}
							<p className="tds-details-title ">{marketPlace.messages.pax}</p>
							{/* <p className="m-0 ">{onlineVisa.Nationality}</p> */}
							{/* <p className="m-0 ">{onlineVisa.Embassy}</p> */}
							{/* <p className="m-0 ">{onlineVisa.VisaPriceOne}</p> */}
						</div>

						<div className="flex-fill">
							<p className="tds-details-subtitle">{id}</p>
							<p className="tds-details-subtitle">
								{onlineVisaSearch?.destination?.country ||
									onlineVisaSearch?.destination}
							</p>
							<p className="tds-details-subtitle">
								{onlineVisaSearch?.visaType?.name
									? onlineVisaSearch?.visaType?.name
									: "-"}
							</p>
							{/* <p className="tds-details-subtitle text-capitalize">
								{onlineVisaSearch?.transportation_type?.label
									? onlineVisaSearch?.transportation_type?.label
									: "-"}
							</p> */}
							<p className="tds-details-subtitle">{onlineVisaSearch?.pax}</p>
							{/* <p className="m-0">{onlineVisaSearch?.residence?.label}</p> */}
							{/* <p className="m-0">{onlineVisaSearch?.embassy?.label}</p> */}
							{/* <p className="m-0">
								{onlineVisaSearch?.transportation_type?.price}{" "}
								{onlineVisaSearch?.transportation_type?.currency?.toLocaleUpperCase()}
							</p> */}
						</div>
					</div>

					<div className="flex-fill d-flex" >
						<div className="flex-fill">
							{/* <p className="m-0 ">{onlineVisa.departureDate}</p>
							<p className="m-0 ">{onlineVisa.returnDate}</p> */}
							<p className="tds-details-title ">
								{inventory.messages.requestDate}
							</p>
							<p className="tds-details-title ">
								{marketPlace.messages.requestExpireDate}
							</p>

							<p className="tds-details-title ">{inventory.messages.Status}</p>
							{onlineVisaSearch?.entry_stamp ? (
								<p className="tds-details-title ">
									{onlineVisa.contractAndAgreement}
								</p>
							) : null}
						</div>
						<div className="flex-fill">
							{/* <p className="m-0">
								{onlineVisaSearch?.departure_date
									? moment(onlineVisaSearch?.departure_date).format(
										"DD/MM/YYYY"
									)
									: "-"}
							</p>
							<p className="m-0">
								{onlineVisaSearch?.return_date
									? moment(onlineVisaSearch?.return_date).format("DD/MM/YYYY")
									: "-"}
							</p> */}

							<p className="tds-details-subtitle">
								{moment(onlineVisaSearch?.requestDate).format("DD/MM/YYYY")}
							</p>
							<p className="tds-details-subtitle">
								{moment(onlineVisaSearch?.expirytDate).format("DD/MM/YYYY")}

							</p>
							<p className={`tds-details-subtitle status-class ${onlineVisaSearch?.status}`}>
								{statusLocale(onlineVisaSearch?.status)}
							</p>
							{onlineVisaSearch?.entry_stamp ? (
								<p
									className="tds-details-subtitle"
									onClick={toggleAgreementModal}
								>
									<i className="fa fa-eye me-1" />
									{inventory.messages.view}
								</p>
							) : null}
						</div>
					</div>
				</div>

				{/* price */}
				<div >
					<div >
						<p style={{ fontWeight: "600" }}> {onlineVisa.TotalPrice}</p>
						<p className='mt-2'>
							<WalletIcon />
							<span className='ps-2 tds-text-green'>
								{onlineVisaSearch?.currency}{" "}
								{onlineVisaSearch?.total?.toFixed(2) || ""}{" "}
							</span>
						</p>
					</div>
				</div>
			</div>

			<CustomModal
				modalIsOpen={openModal}
				header={onlineVisa.EditPax}
				toggle={toggleModel}
				size={"md"}
				centered={true}
			>
				<VisaSearch isMarketSearch={false} toggleModel={toggleModel} />
			</CustomModal>

			<CustomModal
				modalIsOpen={agreementModal}
				header={onlineVisa.contractAndAgreement}
				toggle={toggleAgreementModal}
				size={"md"}
				centered={true}
			>
				Agreement test
			</CustomModal>
		</>
	);
}
