import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import BedIcon from "modules/market-place/NewMarketplace/icons/bed";
import PaxIcon from "modules/market-place/NewMarketplace/icons/paxIcon";
import validate from "helpers/validate";

import { useEffect, useState } from "react";
import Switch from "react-switch";
import Locale from "translations";

export default function GusetDetails({ doneCheck,index,room,hotelRequest,setHotelRequest, RoomIndex,rateIndex,setErrorsClients,checkFormallErrors,errorsClients, setDoneCheck }) {
	const { productsBuilder } = Locale;
	const groups = [
		{ name: "Dr.", id: "558" },
		{ name: "Madame", id: "1671" },
		{ name: "Mademoiselle", id: "74195" },
		{ name: "Messrs", id: "9234" },
		{ name: "Miss", id: "15134" },
		{ name: "Monsieur", id: "74185" },
		{ name: "Mr.", id: "147" },
		{ name: "Mrs", id: "149" },
		{ name: "Ms", id: "148" },
		{ name: "Sir", id: "1328" },
		{ name: "Sir/Madam", id: "3801" },
	];
	const [isSwitch, setIsSwitch] = useState(false);

	const [startVaild, setstartVaild] = useState(false);

	const [clientData, setclientData] = useState({
		salutation: "",
		first_name: "",
		last_name: "",
	});

	useEffect(() => {
		let hotelGests={...hotelRequest}
		
		if (!hotelGests.room?.[RoomIndex].room_rates[rateIndex].guests) {
			let x=+hotelGests.room[RoomIndex].room_rates[rateIndex].minPassengersNum
				hotelGests.room[RoomIndex].room_rates[rateIndex].guests=[...Array(x).keys()]
		}

		hotelGests.room[RoomIndex].room_rates[rateIndex].guests=hotelGests.room[RoomIndex].room_rates[rateIndex].guests.map((res,inde)=>{
		return inde == index ?{...res,...clientData}:{...res}
	})

	setHotelRequest(hotelGests)

	}, [clientData]);

	useEffect(() => {
		if (startVaild) {
			checkFormErrorsfileds();
			setDoneCheck(!doneCheck);
		}
		setstartVaild(true);
	}, [checkFormallErrors]);

	/* check errors */
	const checkFormErrorsfileds = () => {

		let error = errorsClients;
		let newError = {
			...validate(
				{
					name: "salutation",
					value: clientData.salutation,
				},
				{
					required: true,
					serverError: false,
				}
			),
			...validate(
				{
					name: "first_name",
					value: clientData.first_name,
				},
				{
					required: true,
					serverError: false,
				}
			),
			...validate(
				{
					name: "last_name",
					value: clientData.last_name,
				},
				{
					required: true,
					serverError: false,
				}
			),
		};
		
		if (!Array.isArray(error[RoomIndex])) error[RoomIndex] = [];
		error[RoomIndex][index] = newError;

		setErrorsClients(error);
	};

	const setErrorsClientsfiled = (name, value, role) => {
		let error = errorsClients;
		if (!Array.isArray(error[RoomIndex])) error[RoomIndex] = [];

		error[RoomIndex][index] = {
			...error[RoomIndex][index],
			...validate({ name: name, value: value }, role),
		};
		setErrorsClients(error);
	};

	return (
		<div className="details">
			<div className="head-info">
				<div className="room">
					<BedIcon />
					<div className="data">
						<span>{productsBuilder.Room} #{index+1}</span>
						<p>{room.name}</p>
					</div>
				</div>
				<div className="pax">
					<PaxIcon />
					<p>
					{room?.paxes?.adults} {productsBuilder.Adult} , {room?.paxes?.children} {productsBuilder.Child}{" "}
					</p>
				</div>
			</div>
			<hr />
			<div className="inputs">
				<div className="input-item">
					<SelectField
						hasLabel={true}
						label={productsBuilder.Title}
						placeholder={productsBuilder.select}
						options={groups}
						value={clientData?.salutationData?.label}
						name="salutation"
						onChange={(e) => {
							setclientData({
								...clientData,
								salutationData: e,
								salutation: e.id,
							});
							setErrorsClientsfiled("salutation", e, {
								required: true,
							});
						}}
						onBlur={() => false}
						errors={errorsClients?.[RoomIndex]?.[index]?.salutation}
						color={
							errorsClients?.[RoomIndex]?.[index]?.salutation?.required ||
							errorsClients?.[RoomIndex]?.[index]?.salutation?.serverError
								? "danger"
								: ""
						}
					/>
				</div>
				<div className="input-item">
					<TextField
						hasLabel={true}
						label={productsBuilder.FirstName}
						placeholder={productsBuilder.FirstName}
						value={clientData?.first_name}
						onChange={(e) => {
							setclientData({
								...clientData,
								first_name: e.target.value,
							});
							setErrorsClientsfiled("first_name", e.target.value, {
								required: true,
							});
						}}
						errors={errorsClients?.[RoomIndex]?.[index]?.first_name}
						color={
							errorsClients?.[RoomIndex]?.[index]?.first_name?.required ||
							errorsClients?.[RoomIndex]?.[index]?.first_name?.serverError
								? "danger"
								: ""
						}
					/>
				</div>
				<div className="input-item">
					<TextField
						hasLabel={true}
						label={productsBuilder.LastName}
						placeholder={productsBuilder.LastName}
						value={clientData?.last_name}
						name="last_name"
						onChange={(e) => {
							setclientData({
								...clientData,
								last_name: e.target.value,
							});
							setErrorsClientsfiled("last_name", e.target.value, {
								required: true,
							});
						}}
						errors={errorsClients?.[RoomIndex]?.[index]?.last_name}
						color={
							errorsClients?.[RoomIndex]?.[index]?.last_name?.required ||
							errorsClients?.[RoomIndex]?.[index]?.last_name?.serverError
								? "danger"
								: ""
						}
					/>
				</div>
			</div>
			{/* <div className="switch">
				<span>{productsBuilder.SpecialRequest}</span>
				<Switch
					height={24}
					width={48}
					handleDiameter={29}
					className="mx-2 align-middle"
					onChange={() => setIsSwitch((prev) => !prev)}
					checked={isSwitch}
					onColor={isSwitch ? "#1DC9A0" : "#D1D1D6"}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
					id="material-switch"
				/>

				{isSwitch && (
					<div className="input-item w-100">
						<TextField placeholder={productsBuilder.SpecialRequest} />
					</div>
				)}
			</div> */}
		</div>
	);
}
