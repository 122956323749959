import React from "react";
import { ReactComponent as LocationIcon } from "assets/images/promo/location.svg";
import { ReactComponent as EarthIcon } from "assets/images/promo/earth.svg";

function SinglePromo({ hotel, logoSrc, headerText, disclaimerText, portrait }) {
	const headerBold = headerText?.bold ? "font-weight-bold" : "";
	const headerItalic = headerText?.italic ? "font-italic" : "";
	const headerUnderline = headerText?.underline ? "font-underline" : "";

	const disclaimerBold = disclaimerText?.bold ? "font-weight-bold" : "";
	const disclaimerItalic = disclaimerText?.italic ? "font-italic" : "";
	const disclaimerUnderline = disclaimerText?.underline ? "font-underline" : "";
	const hotelRating = +hotel?.rating > 5 ? 5 : +hotel?.rating;

	return (
		<div className={`${portrait ? "single-promo-portrait" : "single-promo"}`}>
			<div className="px-3">
				{logoSrc && (
					<img
						src={logoSrc}
						alt="logo"
						className="img-fluid my-3"
						style={{ maxHeight: 55 }}
					/>
				)}
			</div>
			<div
				style={{
					backgroundImage: `url(${hotel?.main_image})`,
				}}
				className="text-white px-2 w-100 promo-cover-img"
			>
				<div className="promo-cover-img-container">
					<div className="sale">
						<h3 className="h3 promo-sale">{`${hotel?.discounts[0]?.discount}% OFF`}</h3>
					</div>

					<div className="promo-content">
						<h3
							className={`h3 promo-header ${headerBold}  ${headerItalic}  ${headerUnderline}  `}
						>
							{headerText.value}
						</h3>
						<div>
							<div className="d-flex">
								{Array(hotelRating)
									.fill(2)
									.map((i, index) => (
										<i key={i + index + ""} className="fas fa-star"></i>
									))}
							</div>
							<h2 className="h2 promo-hotel-name"> {hotel?.name}</h2>
							<p className="d-block promo-hotel-location">
								<LocationIcon width={53.624} />
								<span className="px-1">{hotel?.address}</span>
							</p>
							<p className="promo-hotel-city px-2">
								<EarthIcon width={27.5} />
								<span className="px-2"> {hotel?.city_name}</span>
							</p>
						</div>
					</div>

					<div
						className={`d-flex justify-content-between w-100 mb-5 ${
							portrait ? "flex-column" : "flex-row-reverse"
						}`}
					>
						<div className="d-flex justify-content-between  flex-column">
							<p className="promo-date-details">Starting From</p>
							<p className="promo-date-details"> {`${hotel?.currency} ${hotel.price_starting_from} / double room `}</p>
						</div>

						<div className="d-flex justify-content-between flex-column">
							<p>
								<i className="fas fa-calendar-alt px-2 text-title"></i>{" "}
								<span className="promo-calender">
									{`Valid booking date: ${hotel.booking_date_from} - ${hotel.booking_date_to}`}
								</span>
							</p>
							<p>
								<i className="fas fa-calendar-alt px-2 text-title"></i>{" "}
								<span className="promo-calender">
									{`Valid travel date: ${hotel.travel_date_from} - ${hotel.travel_date_to}`}
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-between w-100 p-3">
				<p
					className={`promo-disclaimer ${disclaimerBold}  ${disclaimerItalic}  ${disclaimerUnderline}`}
				>
					{disclaimerText.value ||
						`You can use /n this field to type here your name, contact details and
						address. You can also add details about any specific promotion that
						may apply.`}
				</p>
				{logoSrc && (
					<img
						src={logoSrc}
						alt="logo"
						className="img-fluid my-3"
						style={{ maxHeight: 38 }}
					/>
				)}
			</div>
		</div>
	);
}

export default SinglePromo;
