import { useWebBuilderDispatch, useWebBuilderState } from 'context/webBuilder';
import { AddElement } from 'modules/WebBuilder-V2/shared/AddElement';
import AddMedia from 'modules/WebBuilder-V2/shared/AddMedia';
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import EditableParagraph from 'modules/WebBuilder-V2/shared/EditableParagraph';
import React, { useState } from 'react'
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import { ReactComponent as AlignLeftIcon } from "assets/images/webBuilder/align-left20.svg";
import { ReactComponent as AlignRightIcon } from "assets/images/webBuilder/align-right20.svg";
import { ReactComponent as AlignCenterIcon } from "assets/images/webBuilder/align-vertically20.svg";
import Locale from 'translations';

export default function CustomPageAboutUs({ details, onFocus, focusContainer, pageId, mainHeader }) {
  const { selectedLanguage } = useWebBuilderState();
  const { items, id } = details;
  const dispatch = useWebBuilderDispatch();
  const [mediaModal, setmediaModal] = useState(false);


  function toggleMedia() {
    setmediaModal((prev) => !prev);
  };

  // const sortAboutUsItems = ({ dragIndex, hoverIndex }) => {
  //   debugger
  //   dispatch({
  //     type: "sortItemsInContainer",
  //     payload: {
  //       id,
  //       dragIndex,
  //       hoverIndex,
  //     },
  //   });
  // };

  const updateAboutUs = ({ name, value, itemId, selectedLanguage }) => {
    dispatch({
      type: "updateCustomPageAbout",
      payload: {
        name,
        value,
        id,
        itemId,
        pageId,
        language: selectedLanguage ? selectedLanguage : null
      },
    });
  };

  const itemsBoxMediaActions = (itemId) => {
    return [
      <span
        key={`${itemId}-edit-icon`}
        onClick={toggleMedia}
        className="pointer-event"
      >
        <SettingIcon />
      </span>,
    ];
  };

  const itemsBoxContentActions = (itemId) => {
    return [
      <span
        key={`${itemId}-align-left-icon`}
        onClick={() =>
          updateAboutUs({ name: "textAlign", value: "start", itemId })
        }
        className="pointer-event"
      >
        <AlignLeftIcon />
      </span>,
      <span
        key={`${itemId}-align-center-icon`}
        onClick={() =>
          updateAboutUs({ name: "textAlign", value: "center", itemId })
        }
        className="pointer-event"
      >
        <AlignCenterIcon />
      </span>,
      <span
        key={`${itemId}-align-right-icon`}
        onClick={() =>
          updateAboutUs({ name: "textAlign", value: "end", itemId })
        }
        className="pointer-event"
      >
        <AlignRightIcon />
      </span>,
    ];
  };

  const contentActions = {
    onChangeTitle: (value, itemId) =>
      updateAboutUs({ name: "title", value, itemId, selectedLanguage: selectedLanguage }),
    onChangeHead: (value, itemId) =>
      updateAboutUs({ name: "head", value, itemId, selectedLanguage: selectedLanguage }),
    onChangeBody: (value, itemId) =>
      updateAboutUs({ name: "body", value, itemId, selectedLanguage: selectedLanguage }),
  };
  const mediaActions = {
    onChangeMedia: ({ value, itemId, name }) =>
      updateAboutUs({ name, value, itemId }),
    mediaModal,
    toggleMedia,
  };

  const AboutItems = {
    content: AboutContent,
    media: AboutMedia,
  };

  return (
    <div onClick={() => onFocus(id)} id={id}>
      <EditBox editMode={focusContainer === id} defaultIcon={""}>
        <div className="web-builder-content-about-us container" >
          {items?.map((item, index) => {
            const componentType = item.id.includes("media") ? "media" : "content";
            const Component = AboutItems[componentType];
            const actions = componentType === "content" ? contentActions : mediaActions;
            const editBoxActions = componentType === "content" ? [...itemsBoxContentActions(item.id)] : [...itemsBoxMediaActions(item.id)];
            return (
              <div>

                <EditBox
                  key={item.id || index}
                  editMode={focusContainer === id}
                  actions={editBoxActions}
                  defaultIcon={""}
                >
                  <Component details={{ ...item, ...actions, body: item?.body?.[selectedLanguage] }} mainHeader={mainHeader[selectedLanguage]} />
                </EditBox>
              </div>
            )
          })}
        </div>
      </EditBox>
    </div>
  )
}


const AboutContent = ({ details, mainHeader }) => {
  const { body, onChangeBody, id, textAlign = "start" } = details;
  const { webBuilder } = Locale;
  return (
    <div className="about-us-details" style={{ textAlign }}>
      <h3 className="about-us-title">{`${webBuilder.about} ${mainHeader}`}</h3>

      <EditableParagraph
        contentEditable={true}
        onInput={(value) => onChangeBody(value, id)}
        className="text-body mt-3"
        text={body}
      />
    </div>
  );
};


const AboutMedia = ({ details }) => {
  const { mediaUrl, mediaType, id, onChangeMedia, mediaModal, toggleMedia } = details;

  const onChange = ({ mediaType, mediaUrl }) => {
    onChangeMedia({ value: mediaType, itemId: id, name: "mediaType" });
    onChangeMedia({ value: mediaUrl, itemId: id, name: "mediaUrl" });
  };

  return (
    <>
      <div className={`about-media-container ${mediaType === "image" ? "about-media-image" : ""}`}>
        {mediaUrl ? (
          mediaType === "video" ? (
            <iframe
              style={{ maxWidth: "100%" }}
              className="w-100 h-100"
              width="auto"
              height="auto"
              src={`https://www.youtube.com/embed/${mediaUrl}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          ) : (
            <img src={mediaUrl} className="img-fluid" alt="about us" />
          )
        ) : (
          <AddElement onClick={toggleMedia} />
        )}
      </div>

      <AddMedia
        handleModalState={toggleMedia}
        label="Media"
        onAdd={onChange}
        open={mediaModal}
      />
    </>
  );
};