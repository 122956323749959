import arrowRight from 'assets/images/icons/arrow-right-icons.svg';
import visaToken from "assets/images/printvisa/visa.png";
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import NumberField from 'components/Form/NumberField/NumberField';
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import {
	useMarketplaceDispatch,
	useMarketplaceState
} from "context/marketplace";
import moment from 'moment';
import { useEffect, useState } from "react";
import { store } from 'react-notifications-component';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { editgroundServiceRequest, sendRequestPassengers, splitRequestPassengers, viewgroundServicesRequest } from 'services/groundService';
import Locale from "translations";
import AddPassengers from "./components/AddPassengers";
import ListHeader from "./components/ListHeader";
import CustomModal from "./components/Modal";
import MRZ from "./components/MRZ";
import useShowPermission from 'hooks/useShowPermission';

export default function GroundListPassengers() {
	const { groundServiceSearch, groundServicePassengers } = useMarketplaceState();
	let history = useHistory();
	const location = useLocation();
	const dispatch = useMarketplaceDispatch();
	const { productsBuilder, onlineVisa, success } = Locale;
	const [ModalAddGroupMembers, setModalAddGroupMembers] = useState(false);
	const tokenSafaVisa = localStorage.getItem("tokenSafaVisa");
	const loginData = JSON.parse(localStorage.getItem("loginData"));
	const { status, id } = useParams();

	const [ModalNewMember, setModalNewMember] = useState(false);
	const [searchVal, setSearchVal] = useState(null);
	const [searching, setSearching] = useState(false);
	const { locale } = useSBSState();
	const [checkedIds, setCheckedIds] = useState(
		groundServicePassengers?.map((item) => item.id ? item.id : item.uniqueIdFrontend)
	);
	const [sendTravelersModal, setSendTravelersModal] = useState(false);
	const [passengersList, setPassengersList] = useState([]);
	const [serverPassengersList, setServerPassengersList] = useState([])
	const [groundDates, setgroundDates] = useState(
		{
			departure_date: null,
			return_date: null,
		}
	)
	const [splitCount, setSplitCount] = useState(0);
	const [modalSplit, setModalSplit] = useState(false);
	const [downloadList, setDownloadList] = useState([]);

	const inventoryPermission = useShowPermission({
		permission: [
			"View-Inventory-Hotels",
			"Manage-Inventory-Hotels",
		],
	});

	const search = (value) => {
		setSearching(true);
		if (groundServicePassengers.length > 0 && value) {
			let filteredData = [];
			for (let index = 0; index < groundServicePassengers.length; index++) {
				for (const key in groundServicePassengers[index]) {
					if (
						groundServicePassengers[index][key]
							?.toString()
							.includes(value?.toLowerCase()) ||
						groundServicePassengers[index][key]
							?.toString()
							.includes(value?.toUpperCase())
					) {
						filteredData.push(groundServicePassengers[index]);
						break;
					}
				}
			}
			setPassengersList(filteredData);
			setSearching(false);
		} else {
			setPassengersList(groundServicePassengers);
			setSearching(false);
		}
	};

	const Clearsearch = () => {
		setPassengersList(groundServicePassengers);
		setSearchVal("");
	};

	const toggleModelAddGroupMembers = () => {
		setModalAddGroupMembers(!ModalAddGroupMembers);
	};

	const toggleModelSplit = () => {
		setModalSplit(!modalSplit);
	}
	const toggleModelSendTravelers = () => {
		setSendTravelersModal(!sendTravelersModal);
	}

	const toggleModelNewMember = () => {
		setModalNewMember(!ModalNewMember);
	};

	const addSelectedPassangers = (data) => {
		setPassengersList([...passengersList, ...data]);
		dispatch({
			type: "groundServicePassengers",
			payload: data.map(passenger => {
				return {
					...passenger,
					national_id: passenger?.nationality_id?.toString(),
					issue_country: passenger?.national_id?.toString() || passenger?.nationality_id?.toString(),
					first_name_ar: passenger?.full_name_ar?.split(" ")[0],
					father_name_ar: passenger?.full_name_ar?.split(" ")[1],
					grandfather_name_ar: passenger?.full_name_ar?.split(" ")[2],
					last_name_ar: passenger?.full_name_ar?.split(" ")[3],
					first_name_en: passenger?.full_name_en?.split(" ")[0],
					father_name_en: passenger?.full_name_en?.split(" ")[1],
					grandfather_name_en: passenger?.full_name_ar?.split(" ")[2],
					last_name_en: passenger?.full_name_en?.split(" ")[3],
				}
			}),
		});
		toggleModelAddGroupMembers();
	};

	const removePassanger = (id) => {
		let newFIlteredPassanger = groundServicePassengers.filter(
			(item) => id !== item.id && id !== item.uniqueIdFrontend
		);

		setPassengersList(newFIlteredPassanger);
		dispatch({
			type: "groundServicePassengersRemove",
			payload: newFIlteredPassanger,
		});
		let newCheckedIds = checkedIds.filter((item) => item != id);
		setCheckedIds(newCheckedIds);
		setSearchVal("");
	};

	function handelCheck(passenger, checked) {
		if (checked) {
			setDownloadList([...downloadList, passenger.id])
		} else {
			setDownloadList(downloadList.filter((item) => item != passenger.id));
		}
	}
	function handelCheckAll(checked) {
		if (checked) {
			setDownloadList(serverPassengersList.map(passenger => passenger.id));
		} else {
			setDownloadList([]);
		}
	}

	const allPassengers =
		passengersList?.length > 0 && status !== "edit" && status !== "view"
			? passengersList?.map((item) => {
				return (
					<tr>
						<td className="d-flex align-items-center">
							<input
								type="checkbox"
								checked={downloadList.includes(item.id)}
								className="mx-1 insert-group-checkbox "
								onChange={(e) => {
									handelCheck(item, e.target.checked);
								}}
							/>
							{item.passport_number}
						</td>
						<td>
							{locale === "en"
								?
								item.name_en ? item.name_en : item.full_name_en
								:
								item.name_ar ? item.name_ar : item.full_name_ar
							}
						</td>
						<td>{item.gender}</td>
						<td>{item.birth_date}</td>
						<td>{item.age}</td>
						<td>{item.relationship[locale] ? item.relationship[locale] : item.relationship ? item.relationship : ""}</td>
						<td>{item.national_id}</td>
						<td>{item.nationality}</td>
						{status !== "edit" && status !== "view" ? (
							<td>
								<i class="fas fa-trash-alt text-danger pointer"
									onClick={() => removePassanger(item.id ? item.id : item.uniqueIdFrontend)}
								></i>
							</td>
						) : null}
						<td>
							{status === "edit" &&
								<span className='pointer'>
									<img src={arrowRight} alt="" onClick={() => {
										dispatch({
											type: "groundServiceEditPassenger",
											payload: item,
										});
										history.push("/ground-services/Edit/Passenger");
									}}
									/>
								</span>
							}
						</td>
					</tr>
				);
			})
			: [];


	const allPassengersEdit =
		(passengersList?.length > 0 && status === "edit" || status === "view")
			? passengersList?.map((item) => {
				return (
					<tr>
						<td className="d-flex align-items-center">
							<input
								type="checkbox"
								checked={downloadList.includes(item.id)}
								className="mx-1 insert-group-checkbox "
								onChange={(e) => {
									handelCheck(item, e.target.checked);
								}}
							/>
							{item.passport_number}
						</td>
						<td>{locale === "ar" ? item.full_name_ar : item.full_name_en}</td>
						<td>{item.gender}</td>
						<td>{item.date_of_birth}</td>
						<td>{item.age}</td>
						<td>{item.relationship ? item.relationship[locale] : ""}</td>
						<td>{item.national_id}</td>
						<td>{item.nationality}</td>
						{status !== "view" ? (
							<td>
								<i class="fas fa-trash-alt text-danger pointer"
									onClick={() => removePassanger(item.id ? item.id : item.uniqueIdFrontend)}
								></i>
							</td>
						) : (
							<td></td>
						)}
						<td>
							{status === "edit" &&
								<span className='pointer'>
									<img src={arrowRight} alt="" onClick={() => {
										dispatch({
											type: "groundServiceEditPassenger",
											payload: item,
										});
										history.push("/ground-services/Edit/Passenger");
									}}
									/>
								</span>
							}
						</td>
					</tr>
				);
			})
			: [];

	const fetchGroundServiceRequest = async (saveNewTravelers) => {
		if (location.state !== 'editPassenger' || saveNewTravelers) {
			const response = await viewgroundServicesRequest(id);
			if (response.status === 200 || response.status === 201) {
				let searchKeys = response.data.data;
				dispatch({
					type: "groundServiceSearch",
					payload: {
						departure_date: searchKeys?.departure_date,
						destination: searchKeys?.destination_name,
						destination_id: searchKeys?.destination_id,
						pax: searchKeys?.pax,
						residence: {
							price: searchKeys?.price_per_one,
							currency_code: searchKeys?.currency,
						},
						return_date: searchKeys?.return_date,
						status: searchKeys?.status || null,
						expiration_date: searchKeys?.expiration_date,
						service_type: searchKeys?.service_type_name
					},
				});

				dispatch({
					type: "groundServicePassengersRemove",
					payload: searchKeys?.passengers,
				});
				setPassengersList(searchKeys?.passengers);
				setServerPassengersList(searchKeys?.passengers);
			}
		} else {
			setPassengersList(groundServicePassengers)
		}
	};
	// start edit
	useEffect(() => {
		if (status === "edit" || status === "view") {
			fetchGroundServiceRequest(false);
		}
	}, [status]);


	useEffect(() => {
		dispatch({ type: "redirectURL", payload: location.pathname })
	}, []);

	function successfulRequest(response, redirect) {
		store.addNotification({
			title: "Success!",
			message: `${response.data.message}`,
			type: "success",
			insert: "top",
			container: "top-right",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: {
				duration: 5000,
				onScreen: true,
				pauseOnHover: true,
			},
		});
		if (redirect) {
			history.push(inventoryPermission?"/inventory/ground-services":"/statistics");
			dispatch({
				type: "groundServicePassengersRemove",
				payload: [],
			});
		}
	}

	async function splitTravelers() {
		const response = await splitRequestPassengers(id);
		if (response.status === 200) {
			successfulRequest(response, true);
		}
	}

	async function saveTravalers() {
		const data = {
			ground_work_request_id: +id,
			passengers: passengersList.map(passenger => {
				return {
					...passenger,
					national_id: passenger?.nationality_id?.toString(),
					first_name_ar: passenger?.full_name_ar?.split(" ")[0],
					father_name_ar: passenger?.full_name_ar?.split(" ")[1],
					grandfather_name_ar: passenger?.full_name_ar?.split(" ")[2],
					last_name_ar: passenger?.full_name_ar?.split(" ")[3],

					first_name_en: passenger?.full_name_en?.split(" ")[0],
					father_name_en: passenger?.full_name_en?.split(" ")[1],
					grandfather_name_en: passenger?.full_name_ar?.split(" ")[2],
					last_name_en: passenger?.full_name_en?.split(" ")[3],
				}
			})
		}
		const response = await editgroundServiceRequest(data)
		if (response.status === 200) {
			successfulRequest(response, false);
			fetchGroundServiceRequest(true);
		}
	}

	async function sendTravelers() {
		const data = {
			request_id: id,
			departure_date: moment(groundDates?.departure_date).format("YYYY-MM-DD"),
			return_date: moment(groundDates?.return_date).format("YYYY-MM-DD"),
		}
		const response = await sendRequestPassengers(data)
		if (response.status === 200) {
			successfulRequest(response, true);
			setSendTravelersModal(false);
		}
	}

	return (
		<>
			<div className="list-container">
				<div>
					<ListHeader />
				</div>

				<div className="mt-3 bg-white border rounded">
					<header className="p-3 border-bottom d-flex justify-content-between align-items-center">
						<p className="m-0 font-weight-bold">
							{onlineVisa.Travelers} ({groundServicePassengers?.length}/{" "}
							{groundServiceSearch?.pax})
						</p>
						{status !== "view" ?
							<button type='button' className="btn bg-nxt m-1"
								disabled={serverPassengersList.length === 0 || serverPassengersList.length === +groundServiceSearch?.pax}
								onClick={() => {
									setModalSplit(true);
									setSplitCount(+groundServiceSearch?.pax - serverPassengersList.length);
								}}
							>
								{onlineVisa.split}
							</button>
							:
							null
						}
					</header>

					<div className="d-flex   border-top px-3  align-items-center  justify-content-between List-passengers-header ">
						{status !== "view" ? (
							<div className="col-4 p-0">
								<button
									className="btn bg-nxt m-1"
									onClick={toggleModelAddGroupMembers}
									disabled={groundServicePassengers.length === Number(groundServiceSearch.pax)}
								>
									{onlineVisa.InsertFromGroups}
								</button>
								<button
									className="btn bg-nxt m-1"
									onClick={toggleModelNewMember}
									disabled={groundServicePassengers.length === Number(groundServiceSearch.pax)}
								>
									{onlineVisa.AddNew}
								</button>
							</div>
						) : (
							<div className="col-4"></div>
						)}

						<div className="col-6 m-0  justify-content-end d-flex align-items-end py-2 ">
							<div className=" mx-2 w-50">
								<TextField
									label={onlineVisa.search}
									placeholder={onlineVisa.search}
									value={searchVal}
									onChange={(e) => {
										search(e.target.value);
										setSearchVal(e.target.value);
									}}
								/>
							</div>

							<div className="clear text-secondary pointer my-2">
								<button
									className="btn bg-white px-4  onlineVisa-clear-bottom text-gray"
									onClick={() => Clearsearch()}
								>
									<i class="fas fa-undo mx-1"></i>
									{onlineVisa.clear}
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="border">
					<table class="table-update p-2  m-0 bg-white  table table-striped">
						<thead>
							<tr>
								<th>
									<input
										type="checkbox"
										checked={serverPassengersList.length > 0 && serverPassengersList.length === downloadList.length}
										onChange={(e) => {
											handelCheckAll(e.target.checked);
										}}
										className="mx-1 insert-group-checkbox "
									/>
									{onlineVisa.PassportNumber}
								</th>
								<th>{onlineVisa.Name}</th>
								<th>{onlineVisa.Gender}</th>
								<th>
									<i class="fas fa-sort"></i>
									{onlineVisa.Birthday}
								</th>
								<th>{onlineVisa.Age}</th>
								<th>{onlineVisa.Relationship}</th>
								<th>{onlineVisa.NationalId}</th>
								<th>{onlineVisa.Nationality}</th>
								{status !== "view" ? <th>{onlineVisa.actions}</th> : null}
							</tr>
						</thead>
						<tbody>
							{passengersList?.length > 0 && !searching ? (
								status === "edit" || status === "view" ? (
									allPassengersEdit
								) : (
									allPassengers
								)
							) : (
								<tr className="">
									<td
										colSpan={9}
										className="text-center online-visa-noResult  "
									>
										<p className="online-visa-noResult-tr">
											{searching ? "" : "No Travelers Added"}
											{searching && (
												<span
													className="spinner-border spinner-border-sm mx-2"
													role="status"
												/>
											)}
										</p>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>

				{status !== "view" ? (
					<footer className=" d-flex  justify-content-between  align-items-center py-4">
						<button type="button" className="btn btn-apply mt-2 px-3 py-2"
							onClick={() => history.push(inventoryPermission?"/inventory/ground-services":"/statistics")}
						>
							{onlineVisa.backTobooking}
						</button>
						{/* save current passengers */}
						<div className="d-flex align-items-center">
							{status !== "view" ? (
								<>
									<button type="button" class="btn  btn-green-300 mt-2 px-3 py-2"
										disabled={serverPassengersList.length !== +groundServiceSearch?.pax}
										onClick={() => {
											setSendTravelersModal(true);
										}}
									>
										{onlineVisa.sendTravelers}
									</button>
									<button type="button" class="btn bg-nxt mt-2 px-3 py-2 mx-2"
										disabled={serverPassengersList.length === passengersList.length || passengersList.length === 0}
										onClick={saveTravalers}
									>
										{productsBuilder.save}
									</button>
								</>
							) : null}
						</div>
					</footer>
				) : null}
			</div>

			<CustomModal
				modalIsOpen={ModalAddGroupMembers}
				header={onlineVisa.InsertFromGroups}
				toggle={toggleModelAddGroupMembers}
				size={tokenSafaVisa ? "xl" : "md"}
				centered={false}
			>
				{tokenSafaVisa && loginData?.safa_key ? (
					<AddPassengers
						checkedIds={checkedIds}
						setCheckedIds={setCheckedIds}
						addSelectedPassangers={addSelectedPassangers}
					/>
				) : (
					<>
						<div className="d-flex flex-column justify-content-center align-items-center  ">
							<div>
								<img src={visaToken} alt="" />
							</div>
							<p className="m-0 my-3">You must login with safa visa account</p>
							<div className="text-center w-100">
								<button
									className="btn bg-nxt w-75"
									onClick={() => {
										history.push("/visa/login");
									}}
								>
									LOGIN
								</button>
							</div>
						</div>
					</>
				)}
			</CustomModal>
			{/* split modal */}
			<CustomModal
				modalIsOpen={modalSplit}
				header={onlineVisa.split}
				toggle={toggleModelSplit}
				size={"md"}
				centered={true}
			>
				<div className='d-flex flex-column justify-content-center align-items-center w-75 m-auto py-5'>
					<NumberField
						type={"number"}
						hasLabel={true}
						readOnly={true}
						disabled={true}
						removeArrow={true}
						label={onlineVisa.count}
						placeholder={onlineVisa.enterCount}
						value={splitCount}
						name="split_count"
					/>
					<button type='button' className="btn bg-nxt m-1 w-100"
						onClick={splitTravelers}
					>
						{success.confirm}
					</button>
				</div>
			</CustomModal>

			{/* send travelers modal */}
			<CustomModal
				modalIsOpen={sendTravelersModal}
				toggle={toggleModelSendTravelers}
				header={onlineVisa.sendTravelers}
				size={"md"}
				centered={true}
			>
				<div className='d-flex flex-column justify-content-center align-items-center w-75 m-auto py-3'>
					<div className="col-12">
						<DatePickerField
							label={onlineVisa.Departure}
							date={groundDates.departure_date}
							onChangeDate={(departureDate) => {
								setgroundDates({
									...groundDates,
									departure_date: departureDate,
									return_date: moment(departureDate) >= moment(groundDates?.return_date) ? null : groundDates?.return_date,
								});
							}}
							isOutsideRange={(day) =>
								!day.isAfter(moment(new Date()).add(0, "d"), "day")
							}
						/>
					</div>
					<div className="col-12">
						<DatePickerField
							label={onlineVisa.return}
							date={groundDates.return_date}
							onChangeDate={(returnDate) => {
								setgroundDates({
									...groundDates,
									return_date: returnDate,
									departure_date: moment(returnDate) <= moment(groundDates?.departure_date) ? null : groundDates?.departure_date,
								});
							}}
							isOutsideRange={(day) =>
								!day.isAfter(moment(new Date()).add(1, "d"), "day")
							}
						/>
					</div>
					<button type='button' className="btn bg-nxt m-1 w-100"
						onClick={() => sendTravelers()}
					>
						{success.confirm}
					</button>
				</div>
			</CustomModal>

			<CustomModal
				modalIsOpen={ModalNewMember}
				header={onlineVisa.ScanFromMRZ}
				toggle={toggleModelNewMember}
				size={"md"}
				centered={false}
			>
				<MRZ checkedIds={checkedIds} />
			</CustomModal>
		</>
	);
}
