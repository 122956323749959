import TextField from 'components/Form/TextField/TextField';
import React, {  useEffect, useState } from 'react'
import Locale from 'translations';
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import OperatingCenterTableRow from '../shared/TableRow';
import { useSBSState } from 'context/global';
import EditSupervisorModal from '../Modals/EditSupervisorModal';
import VehiclesDriverModal from '../Modals/VehiclesDriverModal';
import ChooseOfficialModal from 'modules/UmrahOperations/shared/ChooseOfficialModal';
import useOperationMovementsModals from '../useOperationMovementsModals';
import ExecuteMovementModal from 'modules/UmrahOperations/shared/ExecuteMovementModal';



export default function OperatingCenterArrivalDeparture({ filters, movmentsList, setFilters, resetFilters }) {
  // ** hooks
  const { operationStatement, marketPlace, inventory, productElements, productsBuilder, backOffice } = Locale;
  const { allCountries } = useSBSState();

  const {
    toggleSupervisorModal,
    toggleOfficerModal,
    toggleDriversModal,
    supervisorModal,
    officerModal,
    driversModal,
    setSupervisorModal,
    setDriversModal,
    saveOfficerData,
    saveSupervisorData,
    saveDriversData,
    executeModalState,
    toggleExecuteModal,
    handleExecuteMovement,
  } = useOperationMovementsModals(resetFilters);

  // ** states
  const [activeStatus, setActiveStatus] = useState("all");

  
  // handle active sub type
  useEffect(() => {
    setActiveStatus(filters?.type ? filters?.type : "all");
  }, [filters]);


  const typesList = [
    { title: operationStatement.All, id: "all" },
    { title: operationStatement.arrival, id: "arrival" },
    { title: operationStatement.departure, id: "departure" },
  ];

  return (
    <div className='operation-center-list'>
      {/* top header */}
      <div className='d-flex align-items-center justify-content-between my-4 flex-wrap'>
        <ul className='types-fitler'>
          {typesList?.map(type => {
            return (
              <li key={type?.id} className={activeStatus === type.id ? "active" : ""}
                onClick={() => {
                  setActiveStatus(type?.id);
                  setFilters({ ...filters, type: type?.id === "all" ? null : type?.id, list_type: "arrival_departure" });
                }}
              >
                {type?.title}
              </li>
            )
          })}
        </ul>
        <div className="">
          <TextField
            type="text"
            id="operations-search"
            name="operations-search"
            hasLabel={false}
            placeholder={inventory.messages.search}
            isImage={true}
            image={SeachIcon}
            prependImage={true}
            value={filters?.search}
            onChange={(e) => {
              setFilters({
                ...filters,
                search: e.target.value,
              });
            }}
          />
        </div>
      </div>

      {/* table */}
      <div className='operation-movements-table table-container'>
        <table className="table table-update">
          <thead>
            <tr>
              <th>{productElements.type}</th>
              <th>{operationStatement.Agent}</th>
              <th>{inventory.messages.arrival}</th>
              <th>{operationStatement.Supervisor}</th>
              <th>{productsBuilder.From}</th>
              <th>{productsBuilder.To}</th>
              <th>{marketPlace.messages.pax}</th>
              <th>{operationStatement.operator}</th>
              <th>{operationStatement.vehicles}</th>
              <th>{operationStatement.startDateTime}</th>
              <th>{operationStatement.endDateTime}</th>
              <th>{operationStatement.movementOfficial}</th>
              <th>{inventory.messages.Status}</th>
              <th>{inventory.messages.Actions}</th>
            </tr>
          </thead>
          <tbody>
            {movmentsList?.length > 0 ?
              movmentsList?.map((movmentItem, index) => {
                return (
                  <OperatingCenterTableRow
                    key={`${movmentItem?.statement_reference}-${index}`}
                    movmentItem={movmentItem}
                    toggleSupervisorModal={toggleSupervisorModal}
                    toggleOfficerModal={toggleOfficerModal}
                    toggleDriversModal={toggleDriversModal}
                    toggleExecuteModal={toggleExecuteModal}
                    handleExecuteMovement={handleExecuteMovement}
                  />
                )
              })
              :
              <tr>
                <td colSpan="10">
                  <div className="product-build__product-no-data fullHeight">
                    <i className="icx icx-eye-slash" aria-hidden="true"></i>
                    <h4>{backOffice.noResult}</h4>
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
        {/*  */}
      </div>

      {/* modals */}
      {supervisorModal.isOpen &&
        <EditSupervisorModal
          toggle={toggleSupervisorModal}
          supervisorData={supervisorModal}
          countries={allCountries}
          setSupervisorModal={setSupervisorModal}
          saveSupervisorData={saveSupervisorData}
        />
      }

      {officerModal.isOpen &&
        <ChooseOfficialModal
          isOpen={officerModal.isOpen}
          toggle={toggleOfficerModal}
          actionFn={saveOfficerData}
          isEdit={officerModal?.type === "edit"}
          data={officerModal?.data}
        />
      }

      {driversModal.isOpen &&
        <VehiclesDriverModal
          toggle={toggleDriversModal}
          driversData={driversModal}
          countries={allCountries}
          setDriversModal={setDriversModal}
          saveDriversData={saveDriversData}
        />
      }

      {executeModalState.isOpen &&
        <ExecuteMovementModal
          isOpen={executeModalState.isOpen}
          toggle={toggleExecuteModal}
          actionFn={handleExecuteMovement}
        />
      }
    </div>
  )
}