import React from 'react';
import { ReactComponent as VerifyIcon } from "assets/images/Tds/verify.svg";
import TextField from 'components/Form/TextField/TextField';
import Locale from 'translations';


export default function TDSEmailVerification({ visaData, errors, emailActionModal, submitEmail, toggleEmailActionModal, handleInputChanges }) {
  const { tdsVisa, marketPlace, payment } = Locale;
  return (
    <div className='p-3 base-border'>
      <h6 className='d-flex align-items-center mb-2'>
        <VerifyIcon />
        <span className='mx-2'>{tdsVisa.emailVerification}</span>
      </h6>
      <div className='d-flex align-items-center'>
        {!visaData.isEmailVerified ?
          <div className='tds-visa-input'>
            <TextField
              label={payment.messages.Email}
              name="tds-email"
              id="tds-email"
              value={visaData?.email}
              disabled={emailActionModal.isOpen}
              onChange={(e) => handleInputChanges(e.target.value, "email")}
              color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
              errors={errors?.email}
            />
          </div>
          :
          <p>{visaData.email}</p>
        }


        <button className={`btn mx-2 shadow-none ${visaData?.isEmailVerified ? "tds-change-btn" : "verify-btn"}`}
          onClick={() =>
            visaData?.isEmailVerified ? toggleEmailActionModal('change') : submitEmail()
          }
        >
          {visaData?.isEmailVerified ? marketPlace.change : marketPlace.messages.verify}
        </button>
      </div>
    </div>
  )
}
