import TextField from "components/Form/TextField/TextField";
import React from "react";
import SearchIcon from "assets/images/crm/search-normal.png";
import clearIcon from "assets/images/wallet/clearIcon.svg";
import Locale from "translations";

export default function Filter({ filter, setFilter, search }) {
	const { payment, CRM } = Locale;
	const resetFilters = () => {
		setFilter({
			search: "",
			owner_name: "",
			package_name: "",
			page: 1,
		});
	};
	return (
		<div className="filter">
			<div className="row w-100">
				<div className="col-md-3">
					<TextField
						type="text"
						label={CRM.Search}
						hasLabel={false}
						placeholder={"Search"}
						isImage={true}
						image={SearchIcon}
						prependImage={true}
						value={filter.search}
						onChange={(e) => {
							setFilter({
								...filter,
								search: e.target.value,
							});
						}}
					/>
				</div>
				<div className="col-md-2">
					<TextField
						hasLabel={false}
						placeholder={CRM.OwnerName}
						value={filter.owner_name}
						onChange={(e) => {
							setFilter({
								...filter,
								owner_name: e.target.value,
							});
						}}
					/>
					{/* <SelectField
						hasLabel={false}
						placeholder={"Owner Name"}
						value={filter.owner_name?.name}
						options={[
							{name:"owner name",id:"owner_name"}
						]}
						onChange={(e) => {
							setFilter({
								...filter,
								owner_name: e,
							});
						}}
					/> */}
				</div>
				<div className="col-md-2">
					<TextField
						hasLabel={false}
						placeholder={CRM.PackageName}
						value={filter.package_name}
						onChange={(e) => {
							setFilter({
								...filter,
								package_name: e.target.value,
							});
						}}
					/>
					{/* <SelectField
						hasLabel={false}
						placeholder={"Package Name"}
						value={filter.package_name?.name}

						options={[
							{name:"package name",id:"package_name"}

						]}
						onChange={(e) => {
							setFilter({
								...filter,
								package_name: e,
							});
						}}
					/> */}
				</div>
			</div>
			<div className="clear text-secondary pointer">
				<button
					className="btn btn-light px-3 mx-2"
					onClick={() => {
						resetFilters();
						search();
					}}
				>
					<img src={clearIcon} alt=" " /> {payment.messages.clear}
				</button>
			</div>
			<button className="btn applay" onClick={() => search()}>
				{CRM.ApplyFilter}
			</button>
		</div>
	);
}
