import Pagination from "components/Pagination";
import { useMarketplaceDispatch } from "context/marketplace";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "reactstrap";
import { onlineVisaRequest } from "services/VisaOnline";
import Locale from "translations";
import Filter from "./Filter";
import ShowForPermission from 'helpers/showForPermission';
import { ReactComponent as EyeIcon } from 'assets/images/crm/eye.svg'


export default function VisaRequests() {
	const { inventory, payment, onlineVisa, marketPlace, messages } = Locale;
	let history = useHistory();
	const dispatch = useMarketplaceDispatch();

	const [visaList, setVisaList] = useState([]);
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		search: "",
		destination_id: "",
		visa_type_id: "",
		request_date: "",
		status: "",
		page: 1,
	});
	const [searchStatus, setSearchStatus] = useState("");
	const [total, setTotal] = useState();

	useEffect(() => {
		async function fetchRequests() {
			const res = await onlineVisaRequest({
				...filter,
				destination_id: filter?.destination_id?.value,
				request_date: filter.request_date
					? moment(filter.request_date).format("YYYY-MM-DD")
					: "",
				visa_type_id: filter?.visa_type_id?.value,
				status: filter?.status?.title,
			});
			setVisaList(res.data?.data);
			setMeta(res?.data?.meta);
			setTotal(res?.data?.counter);
		}
		fetchRequests();
		// reset
		dispatch({
			type: "onlineVisaPassengerRemove",
			payload: [],
		});
		dispatch({
			type: "onlineVisaSearch",
			payload: {},
		});
	}, [searchStatus, filter.page]);

	const search = () => {
		setSearchStatus(!searchStatus);
	};
	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};

	//test

	const statusLocale = (status) => {
		switch (status) {
			case "pending":
				return inventory.messages.pending;
			case "waiting":
				return inventory.messages.waiting;
			case "cancelled":
				return inventory.messages.cancelled;
			case "issued":
				return inventory.messages.issued;
			case "in progress":
				return inventory.messages.inProgress;
			case "expired":
				return inventory.messages.expired;
			case "incomplete":
				return inventory.messages.incomplete;
			case "rejected":
				return payment.messages.Reject;
			default:
				return status;
		}
	};



	const statusColor = (status) => {
		switch (status) {
			case "waiting":
				return "waiting"
			case "pending":
				return "pending";
			case "issued":
				return "issued";
			case "rejected":
			case "canceled":
			case "expired":
			case "incomplete":
				return "danger";
			default:
				return status;
		}
	};

	return (
		<div className="visa-requests">
			<div className="d-flex justify-content-base justify-content-between align-items-center flex-wrap p-3 header-visa inventory-header-totals">
				<h3>{inventory.messages.visaRequests}</h3>
				<div className=" d-flex justify-content-between flex-wrap totals_container">
					<span className="btn btn-yellow text-light mx-2">
						{inventory.messages.all} ({meta?.total})
					</span>
					<span className="btn btn-light border mx-2">
						{payment.messages.Pending}(
						{total?.filter((approved) => approved?.status === "pending")[0]
							?.total || 0}
						)
					</span>
					<span className="btn btn-light border my-2 my-md-0  mx-2">
						{inventory.messages.waitingVisa}(
						{total?.filter((approved) => approved?.status === "waiting")[0]
							?.total || 0}
						)
					</span>
					<span className="btn btn-light border  mx-2">
						{inventory.messages.issuedVisas}(
						{total?.filter((approved) => approved?.status === "issued")[0]
							?.total || 0}
						)
					</span>
					<span className="btn btn-light border mx-2">
						{payment.messages.Expired}(
						{total?.filter((expired) => expired?.status === "expired")[0]
							?.total || 0}
						)
					</span>
					<span className="btn btn-light border mx-2">
						{payment.messages.Reject}(
						{total?.filter((expired) => expired?.status === "rejected")[0]
							?.total || 0}
						)
					</span>
				</div>
			</div>
			<Filter filter={filter} setFilter={setFilter} search={search} />
			<div className="table-container">
				<Table striped className="table-update p-2 bg-white text-capitalize">
					<thead>
						<tr>
							<th>ID</th>
							<th>{inventory.messages.mainRequestId}</th>
							<th>{inventory.messages.destination}</th>
							<th>{inventory.messages.visaType}</th>
							{/* <th>{inventory.messages.transportationType}</th> */}
							<th>{onlineVisa.VisaCount}</th>
							<th>{inventory.messages.departureDate}</th>
							<th>{onlineVisa.returnDate}</th>
							<th>{inventory.messages.requestDate}</th>
							<th>{marketPlace.messages.requestExpireDate}</th>
 							<th>{inventory.messages.status}</th>
							<th>{inventory.messages.actions}</th>
						</tr>
					</thead>
					<tbody>
						{visaList?.length > 0 ? (
							visaList.map((item, index) => {
								return (
									<tr>
										<td>{item?.id}</td>
										<td>{item?.parent_id || "-"}</td>
										<td>{item?.destination}</td>
										<td>{item?.visa_type}</td>
										{/* <td>{item?.transportation_type}</td> */}
										{/* <td>{item?.pax}</td> */}
										<td>
											<div className='d-flex'>
												<span className='font-weight-bold mx-1'>{item?.pax}</span>
												<div>
													(
													<span className='text-danger'>{item?.passengers_count}</span>
													{" / "}
													<span className='text-success'>{+item?.pax - +item?.passengers_count}</span>
													)
												</div>
											</div>
										</td>
										<td>
											{item?.departure_date
												? moment(item?.departure_date).format("YYYY-MM-DD")
												: "-"}
										</td>
										<td>
											{item?.return_date
												? moment(item?.return_date).format("YYYY-MM-DD")
												: "-"}
										</td>
										<td>{item?.request_date?.slice(0, 10)}</td>

										<td>
											{item?.expiration_date
												? moment(item?.expiration_date).format("YYYY-MM-DD")
												: "-"}
										</td>
 
										<td>
											<span className={`status ${statusColor(item?.status)}`}>
												{item?.status === "waiting"
													? inventory.messages.waitingVisa
													: statusLocale(item?.status)}
											</span>
										</td>
										<td>
											<span className="text-yellow">
												{
												item?.status === "incomplete" ||
												item?.status === "pending" ? (
													<ShowForPermission permission="Manage-Inventory-Visa">
														<span
															className="pointer"
															onClick={() => {
																history.push(
																	`/inventory/visa-requests/edit/${item.id}`
																);
															}}
														>
															<i className="far fa-edit pointer"></i>{" "}
															{inventory.messages.edit}
														</span>
													</ShowForPermission>
												) : (
													<span
														className="pointer"
														onClick={() => {
															history.push(
																`/inventory/visa-requests/view/${item.id}`
															);
														}}
													>
														<EyeIcon />{" "}
														{inventory.messages.view}
													</span>
												)}
											</span>
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan="7">
									<div className="product-build__product-no-data fullHeight">
										<i className="icx icx-eye-slash" aria-hidden="true"></i>
										<h4>{messages.noResults}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</Table>
			</div>
			{visaList?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
		</div>
	);
}
