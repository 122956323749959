import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TextField from 'components/Form/TextField/TextField';

export default function InviteModel({toggle,isOpen,isOpenInviteData,setisOpenInviteData,submitInvite}) {
  return (
    <Modal size="md" isOpen={isOpen} toggle={toggle} hasfooter={false}>
    <ModalHeader className="align-items-center" toggle={toggle}>
      {"Invite"}
    </ModalHeader>
    <ModalBody>
			<div className='row mx-5 '>
			<div className='col-md-12'>
              <TextField
                label={"Name"}
                placeholder={"Name"}
                value={isOpenInviteData.name}
                onChange={(e) => {
                  setisOpenInviteData({...isOpenInviteData,name:e.target.value})
                }}
              />
            </div>
						<div className="col-md-12">
						<TextField
							label={"Email"}
							placeholder={"Email"}
							name="email"
              value={isOpenInviteData.email}
              onChange={(e) => {
                setisOpenInviteData({...isOpenInviteData,email:e.target.value})
              }}
						/>
					</div>
					<div className='col-md-12'>
        <TextField
							label={"phone Number"}
							name="phoneNumber"
              type="number"
              min={3}
							placeholder={"phone Number"}
              value={isOpenInviteData.phone_number}
              onChange={(e) => {
                setisOpenInviteData({...isOpenInviteData,phone_number:e.target.value})
              }}
									/>
        </div>
        <div className="col-md-12">
        <button type="submit" onClick={()=>submitInvite()} disabled={isOpenInviteData.email==""||isOpenInviteData.name==""} className=' btn bg-nxt w-100 mt-3'>Submit</button>
        </div>
			</div>
    </ModalBody>
  </Modal>  )
}
