import React from "react";

function ToggleCheckbox({ onChange, checked,label, disabled, ...restProps }) {
	return (
		<div className={`toggle-check-box ${disabled ? "disabled" : ""}`}>
      <span className='checkbox-label'>{label} </span>
			<label className="switch">
				<input
					{...restProps}
					type="checkbox"
					onChange={onChange}
					checked={checked}
					disabled={disabled}
				/>
				<span className="slider round"></span>
			</label>
		</div>
	);
}

export default ToggleCheckbox;
