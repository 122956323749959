import axios from "axios";
import { cleanEmpty } from "./general";

const URL = process.env.REACT_APP_API_URL + "/v1/inventory";
const URLTour = process.env.REACT_APP_API_URL + "/v1";

const URLMarketPackage = process.env.REACT_APP_API_URL + "/v1/market-packages/inventory";

export const FetchTransfersListRequests = async (filter) => {
	return await axios
		.get(`${URL}/transfers/reservations`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const ViewTransfersDetails = async (id) => {
	return await axios
		.get(`${URL}/transfers/reservations/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getTransferInvoice = async (id) => {
	return await axios
		.get(`${URL}/transfers/reservations/${id}/invoice`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getTransferVoucher = async (id, item_id) => {
	return await axios
		.get(
			`${URL}/transfers/reservations/${id}/get-voucher${
				item_id ? "?item_id=" + item_id : ""
			}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const FetchToursListRequests = async (filter) => {
	return await axios
		.get(`${URLTour}/tours/inventory/booking`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const Fetchflightlist = async (filter,page=1)=>{
	return await axios
	.get(`${URLTour}/flights/inventory/booking?page=${page}`,{
		params: cleanEmpty(filter)
	})
	.then((res)=>res)
	.catch((err)=>err.response)
}

export const viewflight = async (id)=>{
	return await axios
	.get(`${URLTour}/flights/inventory/booking/${id}`)
	.then((res)=>res)
	.catch((err)=>err.response)
}

export const viewflightRequests = async (id) => {
	return await axios
		.get(`${URLTour}/flights/request/list-booking-requests?flight_booking_id=${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const flightRefundRequest = async(data) => {
	return await axios
		.post(`${URLTour}/flights/request/refund/create`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const flightChangeRequest = async(data) => {
	return await axios
		.post(`${URLTour}/flights/request/change/create`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const flightRefundPendingApproval = async(data) => {
	return await axios
		.post(`${URLTour}/flights/request/refund/handle-pending-approval`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const flightChangePendingApproval = async(data) => {
	return await axios
		.post(`${URLTour}/flights/request/change/handle-pending-approval`, data)
		.then((res) => res)
		.catch((err) => err.response)
}
export const flightVoidPendingApproval = async(data) => {
	return await axios
		.post(`${URLTour}/flights/request/void/handle-pending-approval`, data)
		.then((res) => res)
		.catch((err) => err.response)
}

export const transferPaylater = async (id,payment_method)=>{
	return await axios
	.post(`${URLTour}/inventory/transfers/reservations/${id}/pay`,payment_method)
	.then((res)=>res)
	.catch((err)=>err.response)
}

export const packagePaylater = async (id,payment_method)=>{
	return await axios
	.post(`${URLTour}/market-packages/inventory/reservations/${id}/pay`,payment_method)
	.then((res)=>res)
	.catch((err)=>err.response)
}
export const getPakcageVoucher = async (id)=>{
	return await axios
	.get(`${URLTour}/market-packages/inventory/reservations/${id}/voucher`)
	.then((res)=>res)
	.catch((err)=>err.response)
}


export const createVoidRequest = async (data)=>{
	return await axios
	.post(`${URLTour}/flights/request/void/create`,data)
	.then((res)=>res)
	.catch((err)=>err.response)
}

export const fetchPackagesList = async (filter, page = 1) => {
	return await axios
		.get(`${URLTour}/market-packages/inventory/reservations?page=${page}`, {params: cleanEmpty(filter)})
		.then((res) => res)
		.catch((err) => err.response)
}
export const fetchPackagesReservation = async (id) => {
	return await axios
		.get(`${URLTour}/market-packages/inventory/reservations/${id}`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const fetchPackagesInvoice = async (id) => {
	return await axios
		.get(`${URLTour}/market-packages/inventory/reservations/${id}/invoice`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const packageEditTravler = async (travelerId, data)=>{
	return await axios
	.post(`${URLTour}/b2b/packages/edit-traveler/${travelerId}`,data)
	.then((res)=>res)
	.catch((err)=>err.response)
}

export const inventoryInvoice = async (id) => {
	return await axios
		.get(`${URLMarketPackage}/reservations/${id}/itinerary`)
		.then((res) => res)
		.catch((err) => err);
}