import TextField from "components/Form/TextField/TextField";
import React from "react";
import SearchIcon from "assets/images/crm/search-normal.png";
import Locale from 'translations';

export default function Filter({ filter, setFilter }) {
	const {CRM}=Locale
	return (
		<div className="filter">

			<div className="row w-100">
				<div className="col-md-3">
					<TextField
						type="text"
						label={CRM.Search}
						hasLabel={false}
						placeholder={CRM.Search}
						isImage={true}
						image={SearchIcon}
						prependImage={true}
						value={filter.search}
						onChange={(e) => {
							setFilter({
								...filter,
								search: e.target.value,
							});
						}}
					/>
				</div>
			
			</div>
		</div>
	);
}
