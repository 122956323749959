import React, { useState } from 'react'
import SearchComponent from '../components/Search'
import { ReactComponent as DeleteIcon } from 'assets/images/crm/delete.svg'
import AddPaymentModal from '../models/AddPaymentModal'
import moment from 'moment'
import { CRMDeletePayment } from 'services/CRM'
import { store } from 'react-notifications-component'
import Locale from 'translations'
import { formatPrice } from 'helpers/utils'

const Payment = ({ paymentsList, crmDetails, setReload }) => {
	const { CRM, inventory } = Locale;
	// ** states
	const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false)

	// ** toggle
	const togglePaymentModal = () => {
		setPaymentModalIsOpen(!paymentModalIsOpen)
	}

	async function handleDeletePayment(paymentId) {
		const res = await CRMDeletePayment(paymentId);
		if (res?.status === 200) {
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setReload((prev) => !prev);
		}
	}



	return (
		<>
			<div className='extra-service-wrapper'>

				<SearchComponent source={"payments"} buttonAction={togglePaymentModal} removeSearch={true} />

				<div className='table-responsive table-bordered'>
					<table className='table'>
						<thead className='thead-light'>
							<tr>
								<th className='scope'>{CRM.InvoiceId}#</th>
								<th className='scope'>{CRM.Amount}</th>
								<th className='scope'>{CRM.PayDate}</th>
								<th className='scope'>{inventory.messages.online}</th>
								<th className='scope'>{CRM.Action}</th>
							</tr>
						</thead>
						<tbody>
							{paymentsList?.length > 0 ?
								paymentsList?.map(paymentItem => {
									return (
										<tr key={paymentItem?.id} className="py-3">
											<td>{paymentItem?.id}</td>
											<td>{formatPrice(paymentItem?.amount)} {crmDetails?.currency}</td>
											<td>{paymentItem?.pay_date ? moment(paymentItem?.pay_date).format("DD.MMM.YYYY") : "-"}</td>
											<td>{paymentItem?.is_online ? "Yes" : "No"}</td>
											<td>
												<button className='btn p-0' onClick={() => handleDeletePayment(paymentItem?.id)}>
													<DeleteIcon />
												</button>
											</td>
										</tr>
									)
								})
								:
								<tr>
									<td colSpan="5" className="text-center"></td>
								</tr>
							}
						</tbody>
					</table>

				</div>
			</div>

			<AddPaymentModal isOpen={paymentModalIsOpen} toggle={togglePaymentModal} crmDetails={crmDetails} setReload={setReload} />
		</>
	)
}

export default Payment
