import {
	useProductsBuilderDispatch,
	useProductsBuilderState,
} from "context/productsBuilder";
import HotelDetailsAccommodation from "modules/products-builder/components/HotelAccommodation/HotelDetails";
import AccommodationTravelers from "modules/products-builder/components/HotelAccommodation/Travelers";
import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
	getAccommodationRoom,
	selectAndUpdateTravelerRoom,
} from "modules/products-builder/components/HotelAccommodation/helpers/updateHotelAccommodationState";
import { useParams } from "react-router";
import {
	fetchHotelDistribution,
	fetchHotelRooming,
	fetchRoomHotelReport,
	fetchRoomHotelsReport,
	fetchRoomingTravellers,
	hotelAccommodationListHotelTravelers,
} from "services/productbuilder";
import { HotelDistributionPrint } from "components/Printing/HotelDistributionPrint";
import Locale from "translations";
import { fetchGroupReservationPDF } from "services/marketplace";
import { HotelRoomingPrint } from "components/Printing/HotelRoomingPrint";
import { useSBSState } from "context/global";
import { RoomPlanHotel } from "components/Printing/RoomPlanHotel";
import { RoomPlanHotels } from "components/Printing/RoomPlanHotels";
import moment from "moment";
import { hotelAccommodationReport } from "components/Printing/hotelAccommodation";

function HotelAccommodation() {
	const { marketPlace, print, productsBuilder, inventory } = Locale;
	const { locale } = useSBSState();
	const dispatch = useProductsBuilderDispatch();
	const { id } = useParams();
	const [productId, setProductId] = useState();
	const getHotels = async () => {
		const response = await hotelAccommodationListHotelTravelers(id);
		if (response.status === 200) {
			const { hotels, travelers, accommodations } = response.data?.data;
			setProductId(response.data?.data?.id);

			const travelersClone = travelers.filter(
				(traveler) => traveler.first_name && traveler.last_name
			);
			accommodations?.forEach((item) => {
				let { selectedRoom } = getAccommodationRoom({
					hotels: hotels,
					hotelId: item.product_hotel_id,
					roomId: item.accommodation_room_id,
					newArray: false,
				});
				let { selectedTraveler } = selectAndUpdateTravelerRoom({
					travelers: travelersClone,
					hotelId: item.product_hotel_id,
					travelerId: item.traveler_id,
					value: item.accommodation_room_id,
					newArray: false,
				});

				let cloneSelectedRoom = selectedRoom?.["travelers"] ?? [];
				selectedRoom.travelers = [...cloneSelectedRoom, selectedTraveler];

				// selectedRoom = cloneSelectedRoom;
			});

			dispatch({
				type: "hotelAccommodationData",
				payload: {
					hotels: hotels,
					travelers: travelersClone,
				},
			});
		}
	};

	useEffect(() => {
		getHotels();

		return () => {};
	}, []);
	const { selected_hotel_accommodation_id: selectedHotelId } =
		useProductsBuilderState();

	const roomTypesLookup = {
		1: marketPlace.x1,
		2: marketPlace.x2,
		3: marketPlace.x3,
		4: marketPlace.x4,
		5: marketPlace.x5,
		6: marketPlace.x6,
		7: marketPlace.x7,
		8: marketPlace.suite,
		9: marketPlace.flatRate,
	};
	const dawnloadHotelAccommodationReport = async () => {
		const { data } = await fetchRoomingTravellers(selectedHotelId);
		const URL = process.env.REACT_APP_API_URL + "/v1/files/fetch";

		console.log(data);
		const roomType = {
			1: marketPlace.x1,
			2: marketPlace.x2,
			3: marketPlace.x3,
			4: marketPlace.x4,
			5: marketPlace.x5,
			6: marketPlace.x6,
			7: marketPlace.x7,
		};
		const tableRows =
			data?.data?.travellers?.length > 0
				? data?.data?.travellers
						.map((traveller, index) => {
							return `
						<tr>
						<td>${index + 1}</td>
						<td>${traveller?.name}</td>
						<td>${traveller?.passport_number}</td>
						<td>${roomType?.[traveller?.room_type]}</td>
						<td>${traveller?.meal_name}</td>
						<td>${traveller?.room_number}</td>
				</tr>
						`;
						})
						?.join("")
				: [];
		const dir = locale == "en" ? "ltr" : "rtl";

		if (data) {
			let respos = hotelAccommodationReport;

			const keyValuePair = {
				"[company_name_key]": productsBuilder.companyName,
				"[company_name_value]": data?.data?.company?.name?.[locale],
				"[report_key]": print.accommodationReport,
				"[report_value]": `${moment(data?.data?.product_departure_date).format(
					"YYYY-MM-DD"
				)} | ${data?.data?.product_name}`,
				"[city_key]": productsBuilder.City,
				"[city_value]": data?.data?.city?.name,
				"[hotel_key]": productsBuilder.hotel,
				"[hotel_value]": data?.data?.name,
				"[check_in_key]": marketPlace.messages.checkIn,
				"[check_in_value]": data?.data?.check_in_at
					? moment(data?.data?.check_in_at).format("YYYY-MM-DD")
					: "-",
				"[check_out_key]": inventory.messages.checkout,
				"[check_out_value]": data?.data?.check_out_at
					? moment(data?.data?.check_out_at).format("YYYY-MM-DD")
					: "-",
				"[table_rows]": tableRows,
				"[avater]": `${URL}/${data?.data?.company?.avatar}`,
				"[motamer_name]": marketPlace.motamerName,
				"[passport_numer]": marketPlace.passportNumer,
				"[room_type]": marketPlace.roomType,
				"[meals_type]": print.mealsType,
				"[room_number]": marketPlace.roomNumber,
				"[rooms_type]": print.roomsType,
				"[one_key]": print.x1,
				"[two_key]": print.x2,
				"[three_key]": print.x3,
				"[four_key]": print.x4,
				"[five_key]": print.x5,
				"[six_key]": print.x6,
				"[seven_key]": print.x7,
				"[total_travellers_key]": print.allTravellers,
				"[total_travellers_value]": data?.data?.total_travellers,
				"[total_men_key]": print.allMen,
				"[total_men_value]": data?.data?.total_men,
				"[total_women_key]": print.allWomen,
				"[total_women_value]": data?.data?.total_women,
				"[number]": print.num,
				"[num]": inventory.num,
			};
			data?.data?.rooms_counts?.length &&
				data?.data?.rooms_counts?.forEach((element) => {
					respos = respos.replace(
						`[${element.room_type}_value]`,
						element.quantity
					);
				});

			for (const key in keyValuePair) {
				respos = respos.replace(key, keyValuePair[key]);
			}

			var params =
				"width=" +
				window.innerWidth +
				", height=" +
				window.innerHeight +
				", top=0, left=0" +
				",toolbar=no,scrollbars=no,status=no,menubar=no";
			//"top=0,left=0,height=80%,width=auto"
			var popupWin = window.open("", " ", params);
			popupWin.document.open();
			popupWin.document.write(`
						<html dir="${dir}">
						<head>
						<title> print Invoice</title>
						<style>
	
						</style>
						<link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" rel="stylesheet">
						</head>
						<body onLoad="{()=>window.print()}">${respos}</body>
						</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};
	//downLoad Hotel Distribution
	const dawnloadHotelDistribution = async () => {
		const { data: hotel } = await fetchHotelDistribution(productId);
		const URL = process.env.REACT_APP_API_URL + "/v1/files/fetch";

		console.log(hotel);
		const allRooms =
			hotel?.data?.hotels?.length > 0
				? hotel?.data?.hotels
						.map((hotel, index) => {
							return `
						<div class="room-type border">
						<table>
						<tr class="first-tr">
								<td class="max-width-260" >
								${hotel?.city}
								
								</td>

								<td class="padding-start max-width-130">
								${marketPlace.roomNum}
								</td>

								
								<td class="width-50">${
									hotel?.rooms?.find((item) => item?.room_type == 1)
										?.room_count ?? "-"
								}</td>
								<td class="width-50">${
									hotel?.rooms?.find((item) => item?.room_type == 2)
										?.room_count ?? "-"
								}</td>
								<td class="width-50">${
									hotel?.rooms?.find((item) => item?.room_type == 3)
										?.room_count ?? "-"
								}</td>
								<td class="width-50">${
									hotel?.rooms?.find((item) => item?.room_type == 4)
										?.room_count ?? "-"
								}</td>
								<td class="width-50">${
									hotel?.rooms?.find((item) => item?.room_type == 5)
										?.room_count ?? "-"
								}</td>
								<td class="width-50">${
									hotel?.rooms?.find((item) => item?.room_type == 6)
										?.room_count ?? "-"
								}</td>
								<td class="width-50">${
									hotel?.rooms?.find((item) => item?.room_type == 7)
										?.room_count ?? "-"
								}</td>
							</tr>
						<tr >
								<td class="max-width-260" >
								${hotel?.name}
								</td>

								<td  class="padding-start max-width-130">
							${marketPlace.motamerNum}
								</td>

								<td class="width-50">${
									hotel?.rooms?.find((item) => item?.room_type == 1)
										?.room_reserved_availability ?? "-"
								}</td>
								<td class="width-50">${
									hotel?.rooms?.find((item) => item?.room_type == 2)
										?.room_reserved_availability ?? "-"
								}</td>
								<td class="width-50">${
									hotel?.rooms?.find((item) => item?.room_type == 3)
										?.room_reserved_availability ?? "-"
								}</td>
								<td class="width-50">${
									hotel?.rooms?.find((item) => item?.room_type == 4)
										?.room_reserved_availability ?? "-"
								}</td>
								<td class="width-50">${
									hotel?.rooms?.find((item) => item?.room_type == 5)
										?.room_reserved_availability ?? "-"
								}</td>
								<td class="width-50">${
									hotel?.rooms?.find((item) => item?.room_type == 6)
										?.room_reserved_availability ?? "-"
								}</td>
								<td class="width-50">${
									hotel?.rooms?.find((item) => item?.room_type == 7)
										?.room_reserved_availability ?? "-"
								}</td>
							</tr>
							
						</table>
						</div>
						`;
						})
						?.join("")
				: [];
		const dir = locale == "en" ? "ltr" : "rtl";

		if (hotel) {
			let respos = HotelDistributionPrint;

			const keyValuePair = {
				"[dir]": dir,
				"[CompanyKey]": marketPlace.company,
				"[Companyvalue]": hotel?.data?.company_name || "-",
				"[tripNumKey]": marketPlace.tripNum,
				"[tripNumValue]": hotel?.data?.trip_name || "-",

				"[dateKey]": marketPlace.date,
				"[dateKeyValue]": hotel?.data?.trip_date || "-",

				"[hotelsKey]": marketPlace.hotels,

				"[oneKey]": marketPlace.x1,
				"[twoKey]": marketPlace.x2,
				"[threeKey]": marketPlace.x3,
				"[fourKey]": marketPlace.x4,
				"[fiveKey]": marketPlace.x5,
				"[sixKey]": marketPlace.x6,
				"[sevenKey]": marketPlace.x7,
				"[motamerNumberKey]": marketPlace.motamerNum,
				"[roomNumberKey]": marketPlace.roomNum,
				"[allRooms]": allRooms,
				"[avater]": `${URL}/${hotel.data.logo}`,
			};

			for (const key in keyValuePair) {
				respos = respos.replace(key, keyValuePair[key]);
			}

			// respos = respos.replace("[allRooms]", allRooms);
			// respos = respos.replace("[Date]", hotel?.data?.created_at);
			// respos = respos.replace("[To]", hotel?.data?.date_to);
			// respos = respos.replace("[From]", hotel?.data?.date_from);
			// respos = respos.replace("[Attn]", "");
			// respos = respos.replace("[Fax]", "");

			// respos = respos.replace("[arrival_date]", hotel?.data?.date_to);
			// respos = respos.replace("[hotel_name]", hotel?.data?.hotel_name);
			// respos = respos.replace("[hotel_name1]", hotel?.data?.hotel_name);
			// respos = respos.replace("[depart_date]", hotel?.data?.date_from);

			// respos = respos.replace("[net_currency]", hotel?.data?.currency);
			// respos = respos.replace("[net_currency1]", hotel?.data?.currency);
			// respos = respos.replace("[net_amount]", hotel?.data?.total_sell_price);

			var params =
				"width=" +
				window.innerWidth +
				", height=" +
				window.innerHeight +
				", top=0, left=0" +
				",toolbar=no,scrollbars=no,status=no,menubar=no";
			//"top=0,left=0,height=80%,width=auto"
			var popupWin = window.open("", " ", params);
			popupWin.document.open();
			popupWin.document.write(`
						<html>
						<head>
						<title> print Invoice</title>
						<style>
	
						</style>
						<link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" rel="stylesheet">
						</head>
						<body onLoad="{()=>window.print()}">${respos}</body>
						</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};
	//downLoad Hotel Rooming
	const dawnloadHotelRooming = async (hotel_id) => {
		const { data: hotel } = await fetchHotelRooming(hotel_id);

		console.log(hotel);
		const allTravelers =
			hotel?.data?.travelers?.length > 0
				? hotel?.data?.travelers.map((travel, index) => {
						return `<tr>
						<td class=" td font-bold">${travel.traveler_name}</td>
						<td class="td font-bold">${travel.passport_number}</td>
						<td class="td font-bold">x${travel.room_type}</td>
						<td class="td font-bold">${travel.room_number}</td>
					</tr>
								</tr>`;
				  })
				: [];
		const dir = locale == "en" ? "ltr" : "rtl";
		if (hotel) {
			let respos = HotelRoomingPrint;
			const keyValuePair = {
				"[dir]": dir,
				"[hotelRoomingKey]": marketPlace.hotelRooming,
				"[tripKey]": marketPlace.trip,
				"[tripValue]": `${hotel?.data?.trip_name} | ${hotel?.data?.trip_date}`,

				"[hotelKey]": marketPlace.hotel,
				"[hotelValue]": hotel?.data?.hotel_name,
				"[motamerNameKey]": marketPlace.motamerName,
				"[passportNumerKey]": marketPlace.passportNumer,
				"[roomTypeKey]": marketPlace.roomType,
				"[roomNumberKey]": marketPlace.roomNumber,
				"[motamersTotalKey]": marketPlace.motamersTotal,
				"[motamersTotalValue]": hotel?.data?.total_count,

				"[malesTotalKey]": marketPlace.malesTotal,
				"[malesTotalValue]": hotel?.data?.male_count,
				"[femalesTotalKey]": marketPlace.femalesTotal,
				"[femalesTotalValue]": hotel?.data?.female_count,
				"[allTravelers]": allTravelers,
			};

			for (const key in keyValuePair) {
				respos = respos.replace(key, keyValuePair[key]);
			}

			var params =
				"width=" +
				window.innerWidth +
				", height=" +
				window.innerHeight +
				", top=0, left=0" +
				",toolbar=no,scrollbars=no,status=no,menubar=no";
			//"top=0,left=0,height=80%,width=auto"
			var popupWin = window.open("", " ", params);
			popupWin.document.open();
			popupWin.document.write(`
				<html>
				<head>
				<title> print Invoice</title>
				<style>

				</style>
				</head>
				<body onLoad="{()=>window.print()}">${respos}</body>
				</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};

	//Start DownLoad Digram Rooms For Each Hotel
	const dawnloadRoomHotel = async (hotel_id) => {
		const { data: hotel } = await fetchRoomHotelReport(id, hotel_id);
		const URL = process.env.REACT_APP_API_URL + "/v1/files/fetch";
		let allRoom =
			hotel?.data?.rooms?.length > 0
				? hotel?.data?.rooms.map((item, index) => {
						return `<div class="content">
						<div class="head_content">
							<div class="box">
								<div>
									<p><i class="fas fa-map-marker-alt"></i> ${marketPlace.city} :  </p>
									<span>${item.city.name}</span>
								</div>
								<div>
									<p><i class="fas fa-hotel"></i> ${marketPlace.hotel} :  </p>
									<span>${item.hotel_name}</span>
								</div>
								<div>
									<p><i class="fas fa-utensils"></i> ${marketPlace.mealType} : </p>
									<span>${item.meal_name ? item.meal_name : ""}</span>
								</div>
							
		
							</div>
							<div class="box">
							
							
								<div>
									<p><i class="fas fa-bed"></i> ${marketPlace.nightsNumber} : </p>
									<span>${item.nights ? item.nights : ""}</span>
								</div>
								<div>
								<p><i class="fas fa-key"></i> ${marketPlace.roomType} : </p>
								<span>${item.room_type ? roomTypesLookup[item.room_type] : ""}</span>
							</div>
								<div>
									<p><i class="fas fa-tag"></i> ${marketPlace.roomNumber} : </p>
									<span>${item.room_number ? item.room_number : ""}</span>
								</div>
		
							</div>
						</div>
						<div class="body_content">

						${
							item.travellers.length > 0
								? item.travellers.map(
										(traveler, in_tr) =>
											`<p><i class="far fa-user"></i> ${traveler?.full_name}</p>`
								  )
								: ""
						}
							
						</div>
	
					</div>`;
				  })
				: [];
		const dir = locale == "en" ? "ltr" : "rtl";
		console.log(allRoom);
		if (hotel) {
			let respos = RoomPlanHotel;
			const keyValuePair = {
				"[dir]": dir,
				"[company_name]": hotel.data.company.name[locale]
					? hotel.data.company.name[locale]
					: "",
				"[return_date]": hotel.data.departure_date.slice(0, 10),
				"[departure_date]": hotel.data.return_date.slice(0, 10),
				"[avater]": `${URL}/${hotel.data.company.avatar}`,
				"[one]": hotel.data.rooms_counts[0].quantity,
				"[two]": hotel.data.rooms_counts[1].quantity,
				"[three]": hotel.data.rooms_counts[2].quantity,
				"[four]": hotel.data.rooms_counts[3].quantity,
				"[five]": hotel.data.rooms_counts[4].quantity,
				"[six]": hotel.data.rooms_counts[5].quantity,
				"[seven]": hotel.data.rooms_counts[6].quantity,

				"[oneKey]": marketPlace.x1,
				"[twoKey]": marketPlace.x2,
				"[threeKey]": marketPlace.x3,
				"[fourKey]": marketPlace.x4,
				"[fiveKey]": marketPlace.x5,
				"[sixKey]": marketPlace.x6,
				"[sevenKey]": marketPlace.x7,

				"[companyName]": marketPlace.companyName,
				"[CheckinDate]": marketPlace.CheckinDate,
				"[CheckoutDate]": marketPlace.CheckoutDate,

				"[city]": marketPlace.city,
				"[hotel]": marketPlace.hotel,
				"[mealType]": marketPlace.mealType,
				"[nightsNumber]": marketPlace.nightsNumber,
				"[roomType]": marketPlace.roomType,
				"[roomNumber]": marketPlace.roomNumber,
				"[number]": marketPlace.number,
				"[allRoom]": allRoom.flat().join(" "),
			};

			for (const key in keyValuePair) {
				respos = respos.replace(key, keyValuePair[key]);
			}

			var params =
				"width=" +
				window.innerWidth +
				", height=" +
				window.innerHeight +
				", top=0, left=0" +
				",toolbar=no,scrollbars=no,status=no,menubar=no";
			//"top=0,left=0,height=80%,width=auto"
			var popupWin = window.open("", " ", params);
			popupWin.document.open();
			popupWin.document.write(`
				<html>
				<head>
				<title>Room Hotel</title>
				<link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" rel="stylesheet">

				<style>

				</style>
				</head>
				<body onLoad="{()=>window.print()}">${respos}</body>
				</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};
	//End DownLoad Digram Rooms For Each Hotel

	//Start DownLoad Digram Rooms For Each Hotels
	const dawnloadRoomHotels = async () => {
		const { data: hotel } = await fetchRoomHotelsReport(id);
		const URL = process.env.REACT_APP_API_URL + "/v1/files/fetch";
		let allRoom =
			hotel?.data?.rooms?.length > 0
				? hotel?.data?.rooms.map((item, index) => {
						return `<div class="content">
							<div class="head_content">
								<div class="box">
									<div>
										<p><i class="fas fa-map-marker-alt"></i> ${marketPlace.city}   </p>
										<span>: ${item.city.name}</span>
									</div>
									<div>
										<p class="hotel_name_label"><i class="fas fa-hotel"></i> ${marketPlace.hotel}   </p>
										<span>:${item.hotel_name}</span>
									</div>
									<div>
										<p><i class="fas fa-utensils"></i> ${marketPlace.mealType} </p>
										<span>: ${item.meal_name ? item.meal_name : ""}</span>
									</div>
									<div>
									<p><i class="fas fa-key"></i> ${marketPlace.roomType}  </p>
									<span> : ${item.room_type ? roomTypesLookup[item.room_type] : ""}</span>
								</div>
			
								</div>
								<div class="box">
								
								<div>
								<p><i class="fas fa-file-export"></i> ${marketPlace.CheckinDate}  </p>
								<span>: ${item.check_in_at ? item.check_in_at.slice(0,10) : ""}</span>

							</div>
							<div>
							<p><i class="fas fa-file-export fa-flip-horizontal"></i> ${
								marketPlace.CheckoutDate
							} </p>
							<span>: ${item.check_out_at ? item.check_out_at.slice(0,10) : ""}</span>

						</div>

								
									<div>
										<p><i class="fas fa-bed"></i> ${marketPlace.nightsNumber} </p>
										<span>: ${item.nights ? item.nights : ""}</span>
									</div>
									<div>
										<p><i class="fas fa-tag"></i> ${marketPlace.roomNumber} </p>
										<span>: ${item.room_number ? item.room_number : ""}</span>
									</div>
			
								</div>
							</div>
							<div class="body_content">
	
							${
								item.travellers.length > 0
									? item.travellers.map(
											(traveler, in_tr) =>
												`<p><i class="far fa-user"></i> ${traveler?.full_name}</p>`
									  )
									: ""
							}
								
							</div>
		
						</div>`;
				  })
				: [];
		const dir = locale == "en" ? "ltr" : "rtl";
		console.log(allRoom);
		if (hotel) {
			let respos = RoomPlanHotels;
			const keyValuePair = {
				"[dir]": dir,
				"[company_name]": hotel.data.company.name[locale]
					? hotel.data.company.name[locale]
					: "",
				"[return_date]": hotel.data.departure_date.slice(0, 10),
				"[departure_date]": hotel.data.return_date.slice(0, 10),
				"[avater]": `${URL}/${hotel.data.company.avatar}`,

				"[oneKey]": marketPlace.x1,
				"[twoKey]": marketPlace.x2,
				"[threeKey]": marketPlace.x3,
				"[fourKey]": marketPlace.x4,
				"[fiveKey]": marketPlace.x5,
				"[sixKey]": marketPlace.x6,
				"[sevenKey]": marketPlace.x7,
				"[one]": hotel.data.rooms_counts[0].quantity,
				"[two]": hotel.data.rooms_counts[1].quantity,
				"[three]": hotel.data.rooms_counts[2].quantity,
				"[four]": hotel.data.rooms_counts[3].quantity,
				"[five]": hotel.data.rooms_counts[4].quantity,
				"[six]": hotel.data.rooms_counts[5].quantity,
				"[seven]": hotel.data.rooms_counts[6].quantity,

				"[companyName]": marketPlace.companyName,
				"[CheckinDate]": marketPlace.CheckinDate,
				"[CheckoutDate]": marketPlace.CheckoutDate,

				"[city]": marketPlace.city,
				"[hotel]": marketPlace.hotel,
				"[mealType]": marketPlace.mealType,
				"[nightsNumber]": marketPlace.nightsNumber,
				"[roomType]": marketPlace.roomType,
				"[roomNumber]": marketPlace.roomNumber,
				"[number]": marketPlace.number,
				"[allRoom]": allRoom.flat().join(" "),
			};

			for (const key in keyValuePair) {
				respos = respos.replace(key, keyValuePair[key]);
			}

			var params =
				"width=" +
				window.innerWidth +
				", height=" +
				window.innerHeight +
				", top=0, left=0" +
				",toolbar=no,scrollbars=no,status=no,menubar=no";
			//"top=0,left=0,height=80%,width=auto"
			var popupWin = window.open("", " ", params);
			popupWin.document.open();
			popupWin.document.write(`
					<html>
					<head>
					<title>Room Hotel</title>
					<link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" rel="stylesheet">
	
					<style>
	
					</style>
					</head>
					<body onLoad="{()=>window.print()}">${respos}</body>
					</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};
	//End DownLoad Digram Rooms For Each Hotels

	return (
		<div className="row m-0 hotel-accommodation-container">
			<DndProvider backend={HTML5Backend}>
				<div className="col-md-3 sections-container accommodation-hotel-travelers">
					<AccommodationTravelers />
				</div>
				<div className="col-md-8 sections-container accommodation-hotel-details">
					<HotelDetailsAccommodation
						dawnloadHotelDistribution={dawnloadHotelDistribution}
						dawnloadHotelRooming={dawnloadHotelRooming}
						dawnloadRoomHotel={dawnloadRoomHotel}
						dawnloadRoomHotels={dawnloadRoomHotels}
						dawnloadHotelAccommodationReport={dawnloadHotelAccommodationReport}
					/>
				</div>
			</DndProvider>
		</div>
	);
}

export default HotelAccommodation;
