import moment from 'moment';
import React from 'react';
import Locale from 'translations';


export default function RequestItemHeader({ isOpen, toggleCollapse, requestTypeText, details, status }) {

  const statusClasses = (status) => {
    switch (status) {
      case "confirmed":
        return "text-success-status";
      case "rejected":
      case "expired":  
        return "text-danger-status";
      default:
        return "text-warning-status";
    }
  };

  const { inventory, visa } = Locale;
  return (
    <div className='flight-requests-header'>

      <div className='flight-requests-type px-2 py-3'>
        {requestTypeText}
      </div>

      <p className='d-flex flex-column my-1 px-md-1 px-2'>
        <span>{inventory.messages.requestNo}</span>
        <span className='font-weight-bold'>{details?.request_num}</span>
      </p>

      <p className='d-flex flex-column my-1 px-md-1 px-2'>
        <span>{inventory.messages.requestDate}</span>
        <span className='font-weight-bold'>
          {moment(details?.created_at).format("YYYY-MM-DD hh:mm A")}
        </span>
      </p>

      <p className='d-flex flex-column my-1 px-md-1 px-2'>
        <span>{inventory.messages.requestBy}</span>
        <span className='font-weight-bold'>{details?.request_by?.company_name}</span>
      </p>

      <p className='d-flex flex-column my-1 px-md-1 px-2'>
        <span>{visa.status}</span>
        <span className={`font-weight-bold text-capitalize ${statusClasses(status)}`}>
          {status === "waiting_approval" ? "Under Review" : status === "pending_approval" ? "Waiting for your approval" :status?.replace("_", " ")}
        </span>
      </p>


      <button className="btn-toggel btn" onClick={toggleCollapse}>
        {isOpen ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
      </button>

    </div>
  )
}
