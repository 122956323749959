import React, { useState, useRef } from "react";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useHistory } from "react-router-dom";
import useFetchCountries from "hooks/useFetchCountries";
import Locale from "translations";
import TextAreaField from "components/Form/TextAreaField/TextAreaField";

export default function AddBankTransfer(props) {
	const history = useHistory();
	const { paymentAdmin } = Locale;

	const [currency, setCurrency] = useState("");
	const [countries] = useFetchCountries();
	const [country, setCountry] = useState([]);
	const [notes, setNotes] = useState("");
	const [filePreview, setFilePreview] = useState("");

	//BankTransfer Image
	const inputFileRef = useRef(null);

	const [formValidation, setFormValidation] = useState({
		currency: true,
		country: true,
		filePreview: true,
	});

	// Validate CurrencyName Field
	const validateCurrencyField = () => {
		setFormValidation({
			...formValidation,
			currency: currency.length > 2 ? true : false,
		});
	};

	// Validate Country
	const validateCountry = () => {
		setFormValidation({
			...formValidation,
			country: country !== 0 ? true : false,
		});
	};

	// On File Change
	const onFilechange = (e) => {
		setFilePreview(window.URL.createObjectURL(e.target.files[0]));
	};

	const handleBtnClick = () => {
		inputFileRef.current.click();
	};

	//Submit Form
	const submit = () => {
		if (Object.values(formValidation).every((item) => item === true)) {
			alert(`
			Currency Name => : ${currency}
			Countries => : ${JSON.stringify(country)}
			Bank Logo => : ${filePreview}
			Notes => : ${notes}
		`);
		}
	};

	return (
		<>
			<div className="payment-admin">
				<div className="text-center py-4 text-title ">
					<h2 className="text-decor text-dark">{paymentAdmin.messages.menu}</h2>
				</div>

				<div className="bg-white py-3 px-2">
					<h2 className="font-weight-bold">
						{paymentAdmin.messages.addBankTransfer}
					</h2>
				</div>

				<div className="my-2">
					<div className="row m-0 justify-content-between">
						<div className="col-md-6">
							<div className="form-group full-input">
								<TextField
									label={paymentAdmin.messages.bankName}
									placeholder={paymentAdmin.messages.bankNamePlaceholder}
									selectPosition="append"
									feedbackMessage={
										paymentAdmin.validation.currencyNameValidationMessage
									}
									value={currency}
									onChange={(e) => setCurrency(e.target.value)}
									onBlur={validateCurrencyField}
									color={formValidation.currency ? "" : "danger"}
									isInvalid={!formValidation.currency}
								/>
							</div>
						</div>
						<div className="col-md-2">
							<div className="payment-upload" onClick={handleBtnClick}>
								<p className="mb-0 pointer px-1">
									{paymentAdmin.messages.bankLogo}
								</p>
								<label>
									<div className="shapes">
										{filePreview ? (
											<img
												src={filePreview}
												className="image-uploader__img"
												alt="logo placeholder"
												accept="image/*"
											/>
										) : (
											<i className="fa fa-plus"></i>
										)}
									</div>
								</label>
								<input type="file" ref={inputFileRef} onChange={onFilechange} />
							</div>
						</div>

						<div className="col-md-12">
							<div className="form-group full-input">
								<SelectField
									multi
									label={paymentAdmin.messages.selectCountries}
									options={countries}
									defaultValue={
										paymentAdmin.messages.selectCountriesPlaceholder
									}
									feedbackMessage={
										paymentAdmin.currencyCountryValidationMessage
									}
									onChange={(values) => setCountry([...country, values])}
									onBlur={validateCountry}
									color={formValidation.country ? "" : "danger"}
									isInvalid={!formValidation.country}
									value={country.label}
								/>
							</div>
						</div>

						{/* Notes */}
						<div className="col-md-12">
							<TextAreaField
								label="Bank Details"
								placeholder="Type Bank Details"
								value={notes}
								rows={5}
								onChange={(e) => setNotes(e.target.value)}
							/>
						</div>

						<div className="save-buttons d-flex py-4">
							<button
								className="btn btn-default mx-1 border"
								onClick={() => history.push("/admin-bank-transfer")}
							>
								{paymentAdmin.messages.back}
							</button>
							<button
								className="btn btn-primary px-5 mx-1"
								onClick={submit}
								disabled={
									!Object.values(formValidation).every(
										(item) => item === true
									) || [currency, country, filePreview, notes].includes("")
								}
							>
								{paymentAdmin.messages.saveChange}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
