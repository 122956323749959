import correctIcon from "assets/images/wallet/correctIcon.svg";

import clearIcon from "assets/images/wallet/clearIcon.svg";

import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import Locale from "translations";

export default function FilterRequests({ filter, setFilter, search }) {
	const { payment } = Locale;
	const status = [
		{ id: 0, name: `${payment.messages.Pending}`, title: "Pending" },
		{ id: 1, name: `${payment.messages.Approved}`, title: "Approved" },
		{ id: 2, name: `${payment.messages.Reject}`, title: "rejected" },
	];

	return (
		<>
			<div className="filter-update bg-filter-gray">
				<div className="product-buttons col-md-11">
					<div className="main-filter-payment d-flex justify-contain-between">
						<div className="main-label controls-field">
							{/*  onChange={this.filterList} */}
							{/* <input
								id="input-search"
								className="main-input-search"
								type="text"
								placeholder={payment.messages.search}
								onChange={(e) =>
									setFilter({ ...filter, transaction_number: e.target.value })
								}
								value={filter.transaction_number}
							/>
							<i className="fas fa-search fa-fw"></i> */}
							<TextField
								label={payment.messages.transactionId}
								type="text"
								placeholder={payment.messages.transactionId}
								onChange={(e) =>
									setFilter({ ...filter, receipt_number: e.target.value })
								}
								value={filter.receipt_number}
							/>
							{/* <i className="fas fa-search fa-fw"></i> */}
						</div>
						<div className="main-label mx-2 controls-field">
							<SelectField
								label={payment.messages.status}
								placeholder={payment.messages.status}
								options={status}
								value={filter?.status?.name}
								onChange={(e) =>
									setFilter({
										...filter,
										status: {
											id: e.value,
											name: e.label,
											title: status[e.value].title,
										},
									})
								}
							/>
						</div>
						<div className="main-label date-filter controls-field1">
							{" "}
							<p>{payment.messages.date}</p>
							<DatePickerField
								//label={"Date"}
								placeholder="DD/MM/YYY"
								date={filter.created_at}
								onChangeDate={(e) => {
									setFilter({ ...filter, created_at: e });
								}}
								isOutsideRange={(day) => {
									return false;
								}}
							/>
						</div>
						{/* <div>
							<button
								className="btn btn-link mt-3 mx-1"
								onClick={() => {
									setFilter({
										transaction_number: "",
										transaction_date: undefined,
										page: 1,
									});
								}}
							>
								<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
							</button>
						</div> */}
						<div className="filter-btn col-md-4 d-flex align-items-center justify-content-start">
							<div className="apply pointer">
								<button className="btn btn-light px-3 " onClick={search}>
									{/* <i className="fas  fa-check position-static "></i> */}
									<img src={correctIcon} alt=" " />
									{payment.messages.apply}
								</button>
							</div>
							<div
								className="clear text-secondary pointer"
								onClick={() => {
									setFilter({
										receipt_number: "",
										created_at: "",
										page: 1,
									});
									search();
								}}
							>
								<button className="btn btn-light px-3 mx-2">
									{/* <i className="fas  fa-retweet position-static px-1 text-secondary text-caption"></i> */}
									<img src={clearIcon} alt=" " /> {payment.messages.clear}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/************************************************************** */}
			{/* <div className="product-header-list0 p-0 ">
				<h6 className="font-weight-bold dark-blue-color">
					{payment.messages.allRequests}
				</h6>
				<br />{" "}
				<div className="product-buttons col-md-8">
					<div className="main-filter-payment d-flex">
						<div className="main-label ">
							<TextField
								label={payment.messages.transactionId}
								type="text"
								placeholder={payment.messages.transactionId}
								onChange={(e) =>
									setFilter({ ...filter, receipt_number: e.target.value })
								}
								value={filter.receipt_number}
							/>
						</div>
						<div className="main-label mx-2">
							<SelectField
								label={payment.messages.status}
								placeholder={payment.messages.status}
								options={status}
								value={filter?.status?.name}
								onChange={(e) =>
									setFilter({
										...filter,
										status: {
											id: e.value,
											name: e.label,
											title: status[e.value].title,
										},
									})
								}
							/>
						</div>
						<div className="main-label ">
							<DatePickerField
								label={"Date"}
								placeholder="DD/MM/YYY"
								date={filter.created_at}
								onChangeDate={(e) => {
									setFilter({ ...filter, created_at: e });
								}}
								isOutsideRange={(day) => {
									return false;
								}}
							/>
						</div>
						<div>
							<button
								className="btn btn-link mt-4 mx-1"
								onClick={() => {
									setFilter({
										receipt_number: "",
										created_at: "",
										page: 1,
									});
								}}
							>
								<i className="fas fa-retweet position-static  text-secondary text-caption"></i>
							</button>
						</div>
					</div>
				</div>
			</div> */}
		</>
	);
}
