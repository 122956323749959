import React from 'react'
import { ReactComponent as BedIcon } from "assets/images/hotelAccommodation/bed.svg";

function RoomTypeBadge({roomType = 1}) {
  return (
    <div className=' room-type-badge'>
      <BedIcon />
      <span>{`X${roomType}`}</span>
    </div>
  )
}

export default RoomTypeBadge