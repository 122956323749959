import useShowPermission from 'hooks/useShowPermission';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import { fetchTransportationRecent } from "services/statistics";
import Locale from "translations";

export default function TransportationTable(props) {
	const { inventory, productsBuilder, statistics } = Locale;
	const [ProductList, setProductList] = useState([]);

	const fetchData = async (limit = 5) => {
		const { data } = await fetchTransportationRecent(limit);
		setProductList(data.data);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const inventoryPermission = useShowPermission({
		permission: [
			"View-Inventory-Hotels",
			"Manage-Inventory-Hotels",
		],
	});

	const AllProducts = ProductList.map((productItem, index) => {
		return (
			<tr key={productItem.id}>
				<td>{productItem.brn}</td>
				<td width="30%">{productItem.transporter_company_name}</td>
				<td>{productItem.trans_type}</td>
				<td>{productItem.availablity}</td>
				<td>{productItem.start_date}</td>
				<td>
					{productItem.total_price} {inventory.messages.sar}
				</td>
				<td>
					<i class="fas fa-chevron-right"></i>
				</td>
			</tr>
		);
	});

	return (
		<div className="product-builder-list px-0">
			{ProductList.length > 0 ? (
				<div className="product-header-list p-3 bg-white">
					<div>
						<h6 className="font-weight-bold dark-blue-color">
							{statistics.recentTransportation}
						</h6>
						<p>5 {statistics.bookingMonth}</p>
					</div>

					<div className="product-buttons">
						<Link
							className="btn btn-link text-yellow "
							to={{
								pathname: inventoryPermission?"/inventory":"/statistics",
								state: { tab: "2" },
							}}
						>
							{statistics.viewBooking}
						</Link>
					</div>
				</div>
			) : null}

			<Table striped className="table-update  bg-white">
				<thead>
					<tr>
						<th>{inventory.messages.brn}</th>
						<th width="30%">{inventory.messages.companyName}</th>
						<th>{inventory.messages.transportType}</th>
						<th>{inventory.messages.transCount}</th>
						<th>
							<i class="fas fa-sort"></i>
							{inventory.messages.bookingDate}
						</th>
						<th>
							<i class="fas fa-sort"></i>
							{inventory.messages.totalPrice}
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{ProductList.length > 0 ? (
						AllProducts
					) : (
						<tr>
							<td colSpan="11">
								<div className="product-build__product-no-data">
									<i className="fas fa-info-circle fa-lg"></i>{" "}
									<h4>{productsBuilder.noResult}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</div>
	);
}
