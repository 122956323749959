import axios from "axios";
import { cleanEmpty } from './general';


const groundServiceURL = process.env.REACT_APP_API_URL + "/v1/ground-work";

export const GroundServiceLookups = async () => {
  return await axios
    .get(`${groundServiceURL}/issue-request/list-config`)
    .then((res) => res)
    .catch((err) => err.response);
};


export const paymentGroundService = async (data) => {
  return await axios
    .post(`${groundServiceURL}/issue-request/create-request`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const groundServicesRequest = async (data) => {
  return await axios
    .get(`${groundServiceURL}/issue-request/list-config`, { params: cleanEmpty(data) }
    );
};
export const groundServicesRequestsList = async (data) => {
  return await axios
    .get(`${groundServiceURL}/issue-request/requests`, { params: cleanEmpty(data), })
    .then((res) => res)
    .catch((err) => err.response);
};

export const viewgroundServicesRequest = async (id) => {
  return await axios.get(
    `${groundServiceURL}/issue-request/requests/${id}`
  );
};

export const editgroundServiceRequest = async (data) => {
  return await axios
    .post(`${groundServiceURL}/issue-request/update-request/`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const createGroundRequest = async (data) => {
  return await axios
    .post(`${groundServiceURL}/issue-request/create-request`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const splitRequestPassengers = async (id) => {
  return await axios.post(`${groundServiceURL}/issue-request/split-request-passengers/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}
export const sendRequestPassengers = async (data) => {
  return await axios.post(`${groundServiceURL}/issue-request/send-request-passengers`, data)
    .then((res) => res)
    .catch((err) => err.response);
}
