import NumberField from "components/Form/NumberField/NumberField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import DateRangeField from "components/Form/DateRangeField/DateRangeField";

import AddIcon from "assets/images/crm/add-square.png";

import { useSBSState } from "context/global";
import React, { useState } from "react";
import validate, { isFormValid } from "helpers/validate";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import moment from 'moment';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import Locale from 'translations';

export default function CsRequestAddModel({
	isOpen,
	toggle,
	data,
	setData,
	send,
	errors,
	setErrors,
	lookups,
	title
}) {
	const { allCountries } = useSBSState();
	const {CRM}=Locale
	const countries = allCountries?.map((country) => {
		return {
			...country,
			id: country?.phone_code,
			label: country?.phone_code,
			name: country?.phone_code,
			image: country?.image,
		};
	});
	const [isPhones, setIsPhones] = useState({
		phone:false,
		whatsApp:false,
	})
	const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

	return (
		<Modal isOpen={isOpen} className="cs-request-add-model" size="xl">
			<ModalHeader className="font-weight-bold" toggle={toggle}>
				{title!=="edit"?CRM.AddRequest:CRM.EditRequest}
			</ModalHeader>
			<ModalBody className="px-2">
				<div className="add-request">
					<div className="row mx-0">
						<div className="col-md-4 col-4">
							<TextField
								label={CRM.ClientName}
								type="text"
								name="client_name"
								placeholder={CRM.ClientName}
								withStar={true}
								value={data.client_name}
								onChange={(e) => {
									setData({
										...data,
										client_name: e.target.value,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "client_name", value: e.target.value },
											{ required: true }
										),
									});
								}}
								color={errors?.client_name?.required ? "danger" : ""}
								errors={errors?.client_name}
							/>
						</div>
						<div className="col-md-4 col-4">
							<TextField
								label={CRM.ClientEmail}
								type="email"
								name="client_email"
								placeholder={CRM.ClientEmail}
								withStar={true}
								value={data.client_email}
								onChange={(e) => {
									setData({
										...data,
										client_email: e.target.value,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "client_email", value: e.target.value },
											{ required: true, email: true }
										),
									});
								}}
								color={
									errors?.client_email?.required || errors?.client_email?.email
										? "danger"
										: ""
								}
								errors={errors?.client_email}
							/>
						</div>
						<div className="col-md-4 col-4">
							<div className="row mx-0">
								{/* phone code */}
								<div className="col-4 company_phone_code">
									<SelectField
										label={CRM.phoneNumber}
										name={"company_phone_code"}
										value={
											data.phones1?.flag ? (
												<img
													src={`${fetchFlag}/${data.phones1?.flag}`}
													width="30px"
													alt=""
												/>
											) : (
												data.phones1?.label??data.phones1?.label?.name
											)
										}
										onChange={(e) => {
											setData({
												...data,
												phones1: { ...data.phones1, value: e.value,label: e.label ,
													flag:e.flag
												},
											});
										}}
										options={countries}
										withStar={true}
									/>
								</div>
								{/* phone */}
								<div className="col-8 px-0">
									<NumberField
										type={"phone"}
										name="clint_phone"
										removeArrow={true}
										placeholder={"000 0000 000"}
										min={3}
										extraTextPosition="prepend"
										extraText={data.phones1?.value??data.phones1?.value?.name}
										value={data.phones1?.num}
										onChange={(e) => {
											setData({
												...data,
												phones1: { ...data.phones1, num: e.target.value },
											});
											setErrors({
												...errors,
												...validate(
													{ name: "phones1", value: e.target.value },
													{ required: true }
												),
											});
										}}
										color={errors?.phones1?.required ? "danger" : ""}
										errors={errors?.phones1}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="row mx-0">
						
						<div className="col-md-4 col-4">
					
						{isPhones.phone?
							<div className="row mx-0">
								{/* phone code */}
								<div className="col-4 company_phone_code">
									<SelectField
										label={CRM.phoneNumber}
										name={"company_phone_code"}
										value={
											data.phones2?.flag ? (
												<img
													src={`${fetchFlag}/${data.phones2?.flag}`}
													width="30px"
													alt=""
												/>
											) : (
												data.phones2?.label
											)
										}
										onChange={(e) => {
											setData({
												...data,
												phones2: { ...data.phones2, value: e.value,label: e.label ,
													flag:e.flag },
											});
										}}
										options={countries}
										withStar={true}
									/>
								</div>
								{/* phone */}
								<div className="col-8 px-0">
									<NumberField
										type={"phone"}
										removeArrow={true}
										placeholder={"000 0000 000"}
										min={3}
										extraTextPosition="prepend"
										extraText={data.phones2?.value}
										value={data.phones2[0]?.num}
										onChange={(e) => {
											setData({
												...data,
												phones2: { ...data.phones2, num: e.target.value },
											});
											setErrors({
												...errors,
												...validate(
													{ name: "phones2", value: e.target.value },
													{ required: true }
												),
											});
										}}
										color={errors?.phones2?.required ? "danger" : ""}
										errors={errors?.phones2}
									/>
								</div>
							</div>:
								<div className='d-flex mt-4' role='button' onClick={()=>{
									setIsPhones({...isPhones,phone:true})
								}}>
									<img src={AddIcon} alt="" width={32} height={32}/>
									<p className='mx-2'>{CRM.addPhone}</p>
								</div>
							}
						</div>

						<div className="col-md-4 col-4">
						
						{isPhones.whatsApp?
							<div className="row mx-0">
								{/* phone code */}
								<div className="col-4 company_phone_code">
									<SelectField
										label={CRM.WhatsAppNo}
										value={
											data.whats_phone?.flag ? (
												<img
													src={`${fetchFlag}/${data.whats_phone?.flag}`}
													width="30px"
													alt=""
												/>
											) : (
												data.whats_phone?.label
											)
										}
										onChange={(e) => {
											setData({
												...data,
												whats_phone: { ...data.whats_phone, value: e.value,label: e.label ,
													flag:e.flag },
											});
										}}
										options={countries}
										withStar={true}
									/>
								</div>
								{/* phone */}
								<div className="col-8 px-0">
									<NumberField
										type={"phone"}
										removeArrow={true}
										placeholder={"000 0000 000"}
										min={3}
										extraTextPosition="prepend"
										extraText={data.whats_phone?.value}
										value={data.whats_phone[0]?.num}
										onChange={(e) => {
											setData({
												...data,
												whats_phone: {
													...data.whats_phone,
													num: e.target.value,
												},
											});
											setErrors({
												...errors,
												...validate(
													{ name: "whats_phone", value: e.target.value },
													{ required: true }
												),
											});
										}}
										color={errors?.whats_phone?.required ? "danger" : ""}
										errors={errors?.whats_phone}
									/>
								</div>
							</div>:<div className='d-flex mt-4' role='button' onClick={()=>{
							setIsPhones({...isPhones,whatsApp:true})
						}}>
							<img src={AddIcon} alt="" width={32} height={32}/>
							<p className='mx-2'>{CRM.addWhats}</p>
						</div>}
						</div>
						<div className="col-md-4 col-4">
							<TextField
								label={CRM.csname}
								type="text"
								name="cs_name"
								placeholder={CRM.csname}
								withStar={true}
								value={data.cname}
								onChange={(e) => {
									setData({
										...data,
										cname: e.target.value,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "cname", value: e.target.value },
											{ required: true }
										),
									});
								}}
								color={errors?.cname?.required ? "danger" : ""}
								errors={errors?.cname}
							/>
						</div>
					</div>
					<div className="row mx-0">
						<div className="col-md-4 col-4">
							<SelectField
								label={CRM.RequestType}
								placeholder={CRM.RequestType}
								options={lookups.product_type}
								withStar={true}
								value={data.type?.name}
								onChange={(e) => {
									setData({
										...data,
										type: e,
									});
									setErrors({
										...errors,
										...validate({ name: "type", value: e }, { required: true }),
									});
								}}
								color={errors?.type?.required ? "danger" : ""}
								errors={errors?.type}
							/>
						</div>
						<div className="col-md-4 col-4">
							<SelectField
								label={CRM.Destination}
								placeholder={CRM.Destination}
								options={allCountries}
								withStar={true}
								value={data.destination?.name}
								onChange={(e) => {
									setData({
										...data,
										destination: e,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "destination", value: e },
											{ required: true }
										),
									});
								}}
								color={errors?.destination?.required ? "danger" : ""}
								errors={errors?.destination}
							/>
						</div>
						<div className="col-md-4 col-4">
							<SelectField
								label={CRM.Class}
								placeholder={CRM.Class}
								options={[
									{ id: 1, name: 1 },
									{ id: 2, name: 2 },
									{ id: 3, name: 3 },
									{ id: 4, name: 4 },
									{ id: 5, name: 5 },
								]}
								withStar={true}
								value={data.class?.name}
								onChange={(e) => {
									setData({
										...data,
										class: e,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "class", value: e },
											{ required: true }
										),
									});
								}}
								color={errors?.class?.required ? "danger" : ""}
								errors={errors?.class}
							/>
						</div>
					</div>
					<div className="row mx-0">
				
						<div className="col-md-4 col-4 multi-select multi-select-crm-cs">
							<SelectField
								multi
								id="cs-Services"
								name="cs-Services"
								label={CRM.Services}
								options={lookups.services}
								placeholder={data?.service?.length > 0 ? data?.service : ""}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
								withStar={true}
								
								onChange={(values, { action }) => {
									console.log(data?.service);
									debugger
									if (action === "remove-value" && !values) {
										setData({ ...data, service: [] });
										return;
									}
									setData({
										...data,
										service: values,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "service", value: values },
											{ required: true }
										),
									});
								}}
								color={errors?.service?.required ? "danger" : ""}
								errors={errors?.service}
							/>
						</div>
						<div className="col-md-4 col-4 ">
							<div className="radio-box">
								<div className="box me-4 d-flex  align-items-center  ">
									<input
										type="radio"
										name="travel_type"
										id="travel_date"
										value="travel_date"
										checked={
											data?.travel_type === "travel_date" ? true : false
										}
										onChange={(e) => {
											setData({
												...data,
												travel_type: e.target.value,
											});
										}}
									/>
									<label htmlFor="travel_date">{CRM.TravelDate}</label>
								</div>
								<div className="box d-flex  align-items-center ">
									<input
										type="radio"
										name="travel_type"
										id="travel_month"
										value="travel_month"
										checked={
											data?.travel_type === "travel_month"  ? true : false
										}
										onChange={(e) => {
											setData({
												...data,
												travel_type: e.target.value,
											});
										}}
									/>
									<label htmlFor="travel_month">{CRM.TravelMonth}</label>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-4">
							{data.travel_type==='travel_date' ?
							<DateRangeField
							label={"Duration"}
								startDate={data.duration_from}
								endDate={data.duration_to}
								startDatePlaceholder={"DD/MM/YYYY"}
								endDatePlaceholder={"DD/MM/YYYY"}
								onDatesChange={({ startDate, endDate }) => {
									setData({
										...data,
										duration_from: moment(startDate)._d,
										duration_to: endDate
											? moment(endDate)._d
											: moment(startDate).add(1, "d")._d,
									});
									setErrors({
										...errors,
										...validate({ name: "duration_from", value: startDate}, { required: true }),
									});
								}}
								withStar={true}
								isOutsideRange={(day) => {
									// return !day.isAfter(moment(), "day") || day.isAfter(moment(reservationDetails?.departure_date), "day");
									return !day.isAfter(moment(), "day");

								}}
								numberOfMonths={2}
								// minDate={moment(validationStartDate).add(1, "d")._d}
								color={errors?.duration_from?.required ? "danger" : ""}
								errors={errors?.duration_from}
							/>
								
							:
							
							<SelectField
								label={CRM.Month}
								placeholder={"MM"}
								options={lookups.months}
								withStar={true}
								value={data.month?.name}
								onChange={(e) => {
									setData({
										...data,
										month: e,
									});
									setErrors({
										...errors,
										...validate({ name: "month", value: e }, { required: true }),
									});
								}}
								color={errors?.month?.required ? "danger" : ""}
								errors={errors?.month}
							/>
						// 	<DatePickerField
						// 	label={"Month"}
						// 	placeholder={"DD/MM/YYYY"}
						// 	date={
						// 		data.month
									
						// 	}
						// 	name="month"
						// 	hasYears={false}
							
						// 	onChangeDate={(e) => {
						// 		setData({ ...data, month: e });
						// 		setErrors({
						// 			...errors,
						// 			...validate(
						// 				{ name: "month", value: e },
						// 				{ required: true }
						// 			),
						// 		});
						// 	}}
						// 	isOutsideRange={(day) => {
						// 		return !day.isBefore(moment(), "day");
						// 	}}
						// 	color={errors?.month?.required ? "danger" : ""}
						// 	errors={errors?.month}
							
						// />
							
							}
						</div>
					</div>
					<div className="row mx-0">
						<div className="col-md-4 col-4">
							<TextField
								label={CRM.Pax}
								type="text"
								name="pax"
								placeholder={CRM.Pax}
								withStar={true}
								value={data.pax}
								onChange={(e) => {
									setData({
										...data,
										pax: e.target.value,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "pax", value: e.target.value },
											{ required: true }
										),
									});
								}}
								color={errors?.pax?.required ? "danger" : ""}
								errors={errors?.pax}
							/>
						</div>
						<div className="col-md-4 col-4">
							<TextField
								label={CRM.Night}
								type="text"
								name="night"
								placeholder={CRM.Night}
								withStar={true}
								value={data.nights}
								onChange={(e) => {
									setData({
										...data,
										nights: e.target.value,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "nights", value: e.target.value },
											{ required: true }
										),
									});
								}}
								color={errors?.nights?.required ? "danger" : ""}
								errors={errors?.nights}
							/>
						</div>
						<div className="col-md-4 col-4">
							<SelectField
								label={CRM.Assignto}
								placeholder={CRM.select}
								options={[{name:"Sales Team",id:"Sales_Team"}]}
								withStar={true}
								value={data.assigned_to?.name}
								onChange={(e) => {
									setData({
										...data,
										assigned_to: e,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "assigned_to", value: e },
											{ required: true }
										),
									});
								}}
								color={errors?.assigned_to?.required ? "danger" : ""}
								errors={errors?.assigned_to}
							/>
						</div>
					</div>
					<div className="row mx-0">
						<div className="col-md-8 col-8">
							<TextField
								label={CRM.Notes}
								type="text"
								name="notes"
								placeholder={CRM.Notes}
								value={data.notes}
								onChange={(e) => {
									setData({
										...data,
										notes: e.target.value,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "notes", value: e.target.value },
											{ required: true }
										),
									});
								}}
							/>
						</div>

						<div className="col-md-4 col-4">
							<button className="btn add-request" onClick={send}>
								{title!=="edit"?CRM.AddRequest:CRM.EditRequest}
							</button>
						</div>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
}
