import { useEffect, useState } from "react";
import Locale from "translations";
import FlightBookingSummary from "./BookingSummary";
import IssueProcessing from "./IssueProcessing";
import KeyValue from "./KeyValue";
import PassengersDetails from "./PassengersDetails";
import { Table } from "reactstrap";
import PnrList from "./PrnList";
import FlightSummarySegmensts from "./FlightSummarySegmensts";
import ServiceStanderdModel from "./Modals/ServiceStanderdModel";
import FareRulesModel from "./Modals/FareRulesModel";
import FreeBagsModel from "./Modals/FreeBagsModel";
import PriceBreakdownModal from "./Modals/PriceBreakdownModal";
import PassangerModel from "./Modals/PassangerModel";
import moment from "moment";
import { InteneraryPrinte } from "assets/inteneraryPrinte";
import departureImg from "assets/images/departure_itinerary.png";
import arrivalImg from "assets/images/arrival_itinerary.png";

export default function FlightBookingDetails({ reservation }) {
	const { inventory, marketPlace, backOffice } = Locale;
	const [serviceStandardIsOpen, setServiceStandardIsOpen] = useState(false);
	const [rulesModelIsOpen, setRulesModelIsOpen] = useState(false);
	const [baggageIsOpen, setBaggageIsOpen] = useState(false);
	const [priceBreakdownIsOpen, setPriceBreakdownIsOpen] = useState(false);
	const [passangerIsOpen, setPassangerIsOpen] = useState(false);

	function refactorFlights() {
		let passengerFlights = [];
		reservation.passengers.forEach((passenger) => {
			let PassengerSegments = {};
			;
			passenger.tickets.forEach((ticket) => {
				let segment = PassengerSegments[`F${ticket.flight_journey_id}`];
				if (segment) {
					PassengerSegments[`F${ticket.flight_journey_id}`].push(ticket);
				} else {
					PassengerSegments[`F${ticket.flight_journey_id}`] = [ticket];
				}
			});
			passengerFlights.push(PassengerSegments);
		});

		return passengerFlights;
	}

	const formatedPassenger = refactorFlights();
	

	const toggleServiceStandard = () => {
		setServiceStandardIsOpen(!serviceStandardIsOpen);
	};
	const togglerules = () => {
		setRulesModelIsOpen(!rulesModelIsOpen);
	};

	const toggleBaggage = () => {
		setBaggageIsOpen(!baggageIsOpen);
	};
	const togglePriceBreakdown = () => {
		setPriceBreakdownIsOpen(!priceBreakdownIsOpen);
	};
	const togglePassanger = () => {
		setPassangerIsOpen(!passangerIsOpen);
	};

	const TRIP_TYPE = {
		1: "One Way",
		2: "Round Trip",
		3: "Multi Destination",
	};

	const [isVoidDate, setIsVoidDate] = useState();
	const [isVoid, setIsVoid] = useState();
	let isVoidTime =
		moment().format("22:00", "YYYY-MM-DD HH:mm").valueOf() >
		moment().format("YYYY-MM-DD HH:mm").valueOf();
	//let isVoid=moment(reservation?.order_date).isSame(new Date(), "day")&& isVoidTime
	useEffect(() => {
		if (reservation?.order_date) {
			setIsVoidDate(reservation.order_date);
			setIsVoid(
				moment(reservation?.order_date).isSame(moment(), "day") && isVoidTime
			);
		}
	}, [reservation]);
	const daweloadItinerary = async (passangerData) => {
		const initerary_info = formatedPassenger.map((passanger, indexFormated) => {
			return (
				reservation &&
				reservation?.passengers.map((adult, index) => {
					return indexFormated === index ? (
						`<div class="form-box mb-2 radius">
						<div class="form-head">
							<p class="bold">
							${adult.psgType === "ADT"
							? inventory.messages.Adult
							: inventory.messages.Child}
						${index + 1}
							</p>
							<i class="fas fa-angle-down pointer"></i>
						</div>
						<div>
							<div class="d-flex justify-content-between px-3 py-1 w-95 mx-auto ">
								<div>
									<p class="text-black-50">${inventory.messages.name} (${inventory.messages.GivenName}/${inventory.messages.lastName})</p>
									<p>${adult.firstName} / ${adult.lastName}</p>
								</div>
							</div>
		
							<div
								class="bg-white flight-key-value p-2 px-3 d-flex justify-content-between w-95 mx-auto "
							>
								<div>
									<p class="text-black-50">${marketPlace.gender}</p>
									<p>${adult.sex == "M" ? "Male" : "Female"}</p>
								</div>
								<div>
									<p class="text-black-50">${marketPlace.Dateofbirth}</p>
									<p>${adult.birthday}</p>
								</div>
								<div>
									<p class="text-black-50">${marketPlace.idNumber}</p>
									<p>${adult.cardNum}</p>
								</div>
								<div>
									<p class="text-black-50">${marketPlace.idExpirydate}</p>
									<p>${adult.cardExpiredDate}</p>
								</div>
								<div>
									<p class="text-black-50">${marketPlace.nationality}</p>
									<p>${adult.nationality}</p>
								</div>
							</div>
							${Object.keys(passanger).map((flight) => {
								const flightData = reservation.flights.find((f) =>
									flight.endsWith(f.id)
								);
								let segments=passanger[flight]
								let journyData=flightData;
								function toHoursAndMinutes(totalMinutes) {
									const hours = Math.floor(+totalMinutes / 60);
									const minutes = +totalMinutes % 60;
									return `${hours}h ${minutes}m`;
								}
							
								const departureSegment = segments?.[0];
								const arrivaleSegment = segments?.[segments?.length - 1];
								const departureTime = moment(journyData?.departure_date).format("HH:mm");
								const arrivalTime = moment(journyData?.arrival_date).format("HH:mm");
								const flightsNumbers = segments.reduce(
									(prev, current, index) =>
										prev +
										(index === 0 ? "" : " | ") +
										current?.airline?.code +
										current?.flight_num,
									""
								);
								return (
									`<div class="flight_item my-3 w-95 mx-auto border" style="margin:25px auto ; padding-top:45px">
									<div class="time-line">
										<div class="infoAir">
											<h4 class="takeoff">${departureTime}</h4>
											<p class="airport-name">
												<span class="blue">${departureSegment?.departure?.code}</span>
												<span>${departureSegment?.departure?.name}</span>
											</p>
											<p class='airport-name'>Departure Terminal <span class='blue'>${departureSegment?.departure_terminal}</span> </p>

										</div>
										<div class="text-center">
											<i
												class="fas fa-plane-departure"
												style="color: rgb(26, 97, 175)"
											></i>
											<p class="spent">${toHoursAndMinutes(journyData?.journey_time)}<span class="blue">${journyData?.stops_count > 0
												? journyData?.stops_count + " Stop"
												: "Non-stop"}</span></p>
										</div>
										<div class="Landing">
											<h4 class="takeoff">${arrivalTime}</h4>
											<p class="airport-name">
												<span class="blue">${arrivaleSegment?.arrival?.code} </span>
												<span>${arrivaleSegment?.arrival?.name}</span>
											</p>
											<p class='airport-name'>Arrival Terminal <span class='blue'>${arrivaleSegment?.arrival_terminal}</span> </p>
										</div>
									</div>
			
									<div class="collapse show mx-4" style="">

									${segments.map((segment, index) => {
										const stopTime = moment(
											`${segments[index + 1]?.departure_date}`
										).diff(`${segment?.arrival_date}`, "m");
										return (
											`<div class="mx-4 collapse-box p-2">
												<div class="d-flex align-items-center justify-content-between ">
													<div class="d-flex align-items-center px-1">
														<img
															class="img-company mr-2"
															src=${segment?.airline?.logo}
															alt=""
														/>
														<div class="mx-1">
															<p class="takeoff">${segment.airline?.name}</p>
															<p class="text-body">
																${segment?.airline?.code + segment?.flight_num}
															</p>
														</div>
													</div>
													<div class="text-body">
														${segment?.ticket_number ? (
																`<span class="font-weight-bold">
																	${marketPlace.ticketNumber}:
																</span>
																<span>${segment?.ticket_number}</span>`
														):""}
														${segment?.pnr ? (
																`<span class="m-2">/</span>
																<span class="font-weight-bold">PNR:</span>
																<span>${segment.pnr}</span>`
														):""}
													</div>
												</div>
				
												<div class="time-line">
													<div class="infoAir">
														<p class="airport-name">
															<span class="blue">${segment?.departure?.code}</span>
															<span>${segment?.departure?.name}</span>
														</p>
														<span class="all-date">
															${moment(segment?.departure_date).format("DD MMM YYYY HH:MM ddd")}
														</span>
														<p class='airport-name'>Departure Terminal <span class='blue'>${segment?.departure_terminal}</span> </p>
													</div>
				
													<div class="text-center">
														<p class="spent">
															${toHoursAndMinutes(segment.flight_time)}
														</p>
													</div>
				
													
													<div class="Landing">
														<p class="airport-name">
															<span class="blue">${segment?.arrival?.code}</span>
															<span>${segment?.arrival?.name}</span>
														</p>
														<span class="all-date">
															${moment(segment?.arrival_date).format("DD MMM YYYY HH:MM ddd")}
														</span>
														<p class='airport-name'>Arrival Terminal <span class='blue'>${segment?.arrival_terminal}</span> </p>

													</div>
												</div>
				
												${segments?.length > 1 && index < segments?.length - 1 ? (
													`<div class="mx-2 stop-box">
														<p
															class="pl-2"
															style= "display: "inline-block", width: "43%" "
														>
															<span>Stop </span>
															<span class="blue">${segment?.arrival?.name}</span>
														</p>
														<span class="stop">${toHoursAndMinutes(stopTime)} </span>
													</div>`
												):""}
											</div>`
										);
									})}
									</div>
									
									<div class="px-2">
									<div class="footer-toogle">
										<div class="d-flex justify-content-start align-items-center">
											<img
												class="img-company"
												src=${departureSegment?.airline?.logo}
												alt=""
											/>
											<div class="mx-1">
												<p class="takeoff">${departureSegment?.airline?.name ? departureSegment?.airline?.name : ""}</p>
												<p style="fontSize: 14px" >${flightsNumbers}</p>
											</div>
										</div>
				
										<div class="d-flex justify-content-start align-items-center ">
											${segments?.map((segment, index) => {
												return (
													`<p
														class="px-1"
														style="
															backgroundColor: #EBEBEB,
															borderRadius: 2px,
															marginInlineEnd: 4px,
														"
														key=${segment.segmentId}
													>
														${segment?.equipment}
													</p>`
												);
											})}
										</div>
										<div class="d-flex justify-content-end align-items-center ">
											<p>${departureSegment?.cabinClass?departureSegment.cabinClass:""}</p>
										</div>
									</div>
								</div>
								</div>`
								);
							})}
						</div>
					</div> 
					<div class="pagebreak"> </div>

					`
					) : "";
				})
			);
		})

		let respos = InteneraryPrinte;
		respos = respos.replace(
			"[Name]",
			`${passangerData.firstName} / ${passangerData.lastName}`
		);
		respos = respos.replace("[ETKT_NBR]", reservation?.passengers[0]?.tickets[0]?.ticket_number);
		respos = respos.replace("[itenerary_body]", initerary_info);
		respos = respos.replace("[TEL]", reservation?.contact?.phone);
		respos = respos.replace("[air_pnr]", reservation?.air_pnr);
		respos = respos.replace("[passport_num]", reservation?.passengers[0]?.cardNum);

		
		respos = respos.replace(
			"[airline_name]",
			reservation?.passengers[0]?.tickets[0]?.airline?.name
		);
	

	
		var params =
			"width=" +
			window.innerWidth +
			", height=" +
			window.innerHeight +
			", top=0, left=0" +
			",toolbar=no,scrollbars=no,status=no,menubar=no";
		//"toolbar=no,scrollbars=yes,,statusbar=yes,height=800,width=800"
		var popupWin = window.open("", " ", params);
		popupWin.document.open();
		popupWin.document.write(`
						<html>
						<head>
						<title> print Itinerary</title>
						<style>
					
						</style>
						</head>
						<body onLoad="{()=>window.print()}">${respos}</body>
						</html>`);
		popupWin.document.close();

		setTimeout(() => {
			popupWin.print();
		}, 100);
	};
	return (
		<>
			<div className="container summary-flight py-3">
				<div className="row">
					<div className="col-md-8 col-12">
						<div className="d-flex align-items-center mb-3">
							<p className="sum-type me-2 text-capitalize">
								{TRIP_TYPE?.[reservation?.type]}
							</p>
							<div className="d-flex align-items-center">
								<p className="bold">
									{reservation?.flights?.[0]?.departure?.name}
								</p>

								{reservation?.type === 2 ? (
									<i className="fas fa-exchange-alt mx-2"></i>
								) : (
									<i className="fas fa-long-arrow-alt-right mx-2"></i>
								)}

								<p className="bold">
									{reservation?.flights?.[0]?.arrival?.name}
								</p>
							</div>
						</div>
						<IssueProcessing
							issueData={{
								orderDate: reservation?.order_date,
								isVoid: isVoid,
								status: reservation?.status,
							}}
						/>
						<KeyValue
							data={[
								{ key: "Air order", value: reservation?.air_order },
								{
									key: `${marketPlace.Orderdate}`,
									value: reservation?.order_date,
								},
								{ key: "PNR", value: reservation?.pnr },
								{ key: "Airline Pnr", value: reservation?.air_pnr },
							]}
						/>

						{/* segmenests */}
						{/* {reservation &&
							reservation?.passengers?.map((ticket)=>(
								
								<FlightSummarySegmensts journyData={ticket} />
							))} */}

						<div className="d-flex justify-content-between mt-2">
							<p>{marketPlace.passanger}</p>
							<button
								className="btn bold text-custome"
								onClick={togglePassanger}
							>
								{marketPlace.details}{" "}
								<i className="fas fa-external-link-alt ms-1"></i>
							</button>
						</div>
						{formatedPassenger.map((passanger, indexFormated) => {
							return (
								reservation &&
								reservation?.passengers.map((adult, index) => {
									return indexFormated === index ? (
										<PassengersDetails
											index={index}
											passengerType={"adults"}
											passanger={adult}
											daweloadItinerary={daweloadItinerary}
										>
											{Object.keys(passanger).map((flight) => {
												const flightData = reservation.flights.find((f) =>
													flight.endsWith(f.id)
												);
												return (
													<FlightSummarySegmensts
														segments={passanger[flight]}
														journyData={flightData}
														gds={reservation?.gds}
													/>
												);
											})}
										</PassengersDetails>
									) : null;
								})
							);
						})}
						{/* Passengers section  */}
						{/* <div className="d-flex justify-content-between">
							<p>passanger</p>
							<button
								className="btn bold text-custome"
								onClick={togglePassanger}
							>
								Details <i className="fas fa-external-link-alt ms-1"></i>
							</button>
						</div>
						{reservation &&
							reservation?.passengers.map((adult, index) => {
								return (
									<PassengersDetails
										index={index}
										passengerType={"adults"}
										passanger={adult}
									/>
								);
							})} */}

						{reservation?.pnr_list && <PnrList list={reservation?.pnr_list} />}

						<div className="d-flex justify-content-center">
							{/* <div className=" text-center px-2 flex-grow-1">
								<div
									className="bg-white p-3 pointer"
									style={{ borderRadius: "10px" }}
									onClick={toggleServiceStandard}
								>
									<i class="fas fa-hand-holding-medical text-black-50"></i>
									<p className="bold text-custome">
										{inventory.messages.serviceStandard}
									</p>
								</div>
							</div> */}
							<div className=" text-center px-2 flex-grow-1">
								<div
									className="bg-white p-3 pointer"
									style={{ borderRadius: "10px" }}
									onClick={togglerules}
								>
									<i class="fas fa-file-alt text-black-50"></i>
									<p className="bold text-custome">{marketPlace.farerules}</p>
								</div>
							</div>
							<div className=" text-center px-2 flex-grow-1">
								<div
									className="bg-white p-3 pointer"
									style={{ borderRadius: "10px" }}
									onClick={toggleBaggage}
								>
									<i class="fas fa-suitcase  text-black-50" />
									<p className="bold text-custome">{marketPlace.freebaggage}</p>
								</div>
							</div>
						</div>

						<div className="table-container flight-table-height  mt-3">
							<p className="mb-1">{inventory.messages.contact}</p>
							<Table
								striped
								className="table-update p-2 bg-white flight-inventory-table"
								style={{ verticalAlign: "center", borderRadius: "10px" }}
							>
								<thead>
									<tr>
										<th>{backOffice.contactName}</th>
										<th>{backOffice.email}</th>
										<th>{backOffice.Phone}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{reservation?.contact?.name}</td>
										<td>{reservation?.contact?.email}</td>
										<td>{reservation?.contact?.phone}</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</div>

					{/* booking summary  */}
					<div className="col-md-4 col-12">
						<div className="filter-head terms-color text-uppercase">
							<h6 className="font-weight-bold h6 hotel-found my-3">
								{marketPlace.messages.bookingSummary}
							</h6>

							{reservation && (
								<FlightBookingSummary
									reservation={reservation}
									togglePriceBreakdown={togglePriceBreakdown}
								/>
							)}
							{/* </div> */}
						</div>
					</div>
				</div>
			</div>
			<ServiceStanderdModel
				toggle={toggleServiceStandard}
				isOpen={serviceStandardIsOpen}
				flightData={reservation}
			/>

			<PassangerModel
				toggle={togglePassanger}
				isOpen={passangerIsOpen}
				flightData={reservation}
			/>

			<FareRulesModel
				title={marketPlace.farerules}
				toggle={togglerules}
				isOpen={rulesModelIsOpen}
				flightData={reservation}
			/>

			<FreeBagsModel
				title={marketPlace.freebaggage}
				toggle={toggleBaggage}
				isOpen={baggageIsOpen}
				flightData={reservation}
			/>
			<PriceBreakdownModal
				isOpen={priceBreakdownIsOpen}
				toggle={togglePriceBreakdown}
				flightData={reservation}
				adultsChildrenCount={1}
				adultsTotalPrice={1}
				childrenTotalPrice={2}
			/>
		</>
	);
}
