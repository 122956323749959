import React from "react";
import UploadLogo from "./UploadLogo";
import { FlyerTextarea } from "./FlyerTextarea";
import {
	MAXIMUM_HEADER_CHARACTER,
	MAXIMUM_DISCLAIMER_CHARACTER
} from '../../helpers/globales';
import Locale from 'translations';

export function AddFlyerContent({
	logoSrc,
	setLogoSrc,
	handleHeaderTextStyle,
	headerText,
	handleHeaderChange,
	handleDisclaimerTextStyle,
	disclaimerText,
	handleDisclaimerChange,
}) {
	const headerLength = headerText.value.length;
	const { marketPlace } = Locale;
	const disclaimerLength = disclaimerText.value.length;

	return (
		<div className="col-lg-7 col-12 d-flex flex-wrap align-content-start">
			<UploadLogo logoSrc={logoSrc} setLogoSrc={setLogoSrc} />
			<div className=" w-100 mt-3">
				<h4 className="h4">{marketPlace.customiseContent}</h4>
				{/* Add a header */}
				<FlyerTextarea
					handleTextStyle={handleHeaderTextStyle}
					text={headerText}
					textLength={headerLength}
					handleTextChange={handleHeaderChange}
					title={marketPlace.addHeader}
					maximumCharacter={MAXIMUM_HEADER_CHARACTER}
					textPlaceholder={marketPlace.typeHeader}
				/>
				<div className="my-4" />
				{/* Add a disclaimer */}
				<FlyerTextarea
					handleTextStyle={handleDisclaimerTextStyle}
					text={disclaimerText}
					textLength={disclaimerLength}
					handleTextChange={handleDisclaimerChange}
					title={marketPlace.Disclaimer}
					maximumCharacter={MAXIMUM_DISCLAIMER_CHARACTER}
					textPlaceholder={marketPlace.typeDisclaimer}
				/>
			</div>
		</div>
	);
}
