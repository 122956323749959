import React, { memo, useEffect, useState } from "react";
import CustomModal from "./CustomModal";
import SelectField from "components/Form/SelectField/SelectField";
import { getProductsList } from "services/webBuilder";
import { ReactComponent as UploadIcon } from "assets/images/webBuilder/upload-image-icon.svg";
import UploadFileComponent from "./UploadFile";
import Locale from 'translations';

const formatPackageList = (data) => {
	// const hotelName
	return data.map((i) => {
		const hotelName = i.product_items.hotel
			? i.items.reduce((prev, current) => {
				if (current.item.id === 6) {
					return current?.itemable?.hotel?.name;
				}
			}, "")
			: "";

		return {
			product_uuid: i.product_uuid,
			id: i.id,
			name: i.name,
			departure_date_at: i.departure_date_at,
			return_date_at: i.return_date_at,
			stars: i.product_classification?.id ?? 0,
			hotelName,
			price: i?.price,
			currency: i?.currency
		};
	});
};

const formatServiceList = (data, pickName) => {
	return data.map((packages) => {
		return {
			product_uuid: packages.product_uuid,
			id: packages.id,
			name: packages.name ?? pickName(packages?.items?.[0]?.itemable),
			price: packages?.price,
			currency: packages?.currency
		};
	});
};

const formatFlightServiceList = (data) => {
	return data.map((flight) => {
		const flightItems = flight?.items?.map(item => {
			return {
				fromCountry: item?.itemable?.from_country,
				fromPort: item?.itemable?.from_port,
				toCountry: item?.itemable?.to_country,
				toPort: item?.itemable?.to_port,
			}
		})
		return {
			product_uuid: flight.product_uuid,
			id: flight.id,
			name: flight.name,
			price: flight?.price,
			currency: flight?.currency,
			flightItems,
		};
	});
}

const getServiceName = {
	1: () => null,
	2: () => null,
	6: (itemable) => itemable.hotel.name,
	7: (itemable) => itemable.name.en,
};

function AddService({
	open,
	handleModalState,
	onAdd,
	label,
	item_id,
	details,
	header,
	selectedIds = [],
}) {

	const {commons, webBuilder} = Locale;
	const [image, setImage] = useState(null);
	const [value, setValue] = useState(null);
	const [optios, setOptios] = useState([]);
	const isItemFlight = item_id === 1;

	const handleUpload = (value) => {
		setImage(value);
	};



	const fetchProductsList = async () => {
		const payload = {
			product_type_id: item_id ? 6 : undefined,
			...(!item_id && { no_service_type: 1 }),
			item_id,
		};
		const res = await getProductsList(payload);
		if (res.status === 200) {
			const data = res.data.data?.filter(product => product?.is_has_price);
			let formatedOptions;

			if (isItemFlight) {
				formatedOptions = formatFlightServiceList(data);
			} else if (item_id) {
				formatedOptions = formatServiceList(data, getServiceName[item_id])
			} else {
				formatedOptions = formatPackageList(data);
			}
			setOptios(formatedOptions);
		}
	};

	useEffect(() => {
		if (details) {
			setValue(details);
			setImage(details?.image);
		}
		return () => { };
	}, [details]);

	useEffect(() => {
		if (optios.length === 0) {
			fetchProductsList();
		}

		return () => { };
	}, []);

	const addService = () => {
		const data = {
			...value,
			image,
		};

		onAdd(data);
		handleModalState();
	};
	const onClosedModal = () => {
		setValue(null);
		setImage(null);
	};
	return (
		<CustomModal
			isOpen={open}
			header={`${commons?.add} ${commons?.[header]}`}
			toggle={handleModalState}
			onClosed={onClosedModal}
		>
			<div className="adding-service-modal">
				<SelectField
					label={commons?.[label]}
					placeholder={`${commons.selectPlaceholder} ${commons?.[label]}`}
					value={value?.name}
					options={optios.filter((ele) => !selectedIds.includes(ele.id))}
					onChange={(e) => {

						setValue(e);
					}}
				/>

				<UploadFileComponent
					icon={UploadIcon}
					// you must change this Id every time you use this component to avoid any conflict
					id={`${header}-${item_id || 0}-image`}
					label="upload image"
					onUpload={handleUpload}
				/>


				{image && <img alt={label} src={image} height={133} style={{ width: "100%" }} />}

				<hr />

				{(!image || !value) &&
					<p className="text-danger my-1" color="error">
						you should select {label} and image
					</p>
				}

				<button
					className="primary-button"
					onClick={addService}
					disabled={(isItemFlight && !value) || (!isItemFlight && (!image || !value))}
				>
					Add
				</button>
			</div>
		</CustomModal>
	);
}

export default memo(AddService);
