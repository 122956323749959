import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import UserSettings from "components/UserSettings";
import { useSBSDispatch, useSBSState } from "context/global";
import { useEffect, useState } from "react";
import { getPermissions, getSubscripedModules } from "services/auth";
import { NotifactionHeader } from "./notifactionHeader";
import WalletHeader from "./walletHeader";
import ShowForPermission from "helpers/showForPermission";
import CurrencySwitcher from "./CurrencySwitcher";
import LanguageSwitcherMobile from "components/LanguageSwitcher/LanguageSwitcherMobile";
import UserSettingsMobile from "components/UserSettingsMobile";
import WalletHeaderMobile from "./walletHeaderMobile";
import CurrencySwitcherMobile from "./CurrencySwitcherMobile";
import { NotifactionHeaderMobile } from "./notifactionHeaderMobile";
import SafaLogo from "assets/images/dashboard/safaLogoFull.svg";
import { Link } from 'react-router-dom';



export default function Header() {

	const search = window.location.search;
	const name = new URLSearchParams(search);
	const dispatch = useSBSDispatch();


	const { isAuth, token_data, token, companyInfo } = useSBSState();
	const isServiceCompnay = companyInfo?.company_category === "services_company"
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		async function fetchPermissions() {
			const res = await getPermissions();

			dispatch({ type: "getPermissions", payload: res?.data?.data });
		}
		let x = {};
		for (const [key, value] of name) {
			x = { ...x, [key]: value };
		}

		if (x.hasOwnProperty("token") && token != null) {
			fetchPermissions();
		} else if (!x.hasOwnProperty("token")) {
			fetchPermissions();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		async function fetchSubsciptionPlan() {
			const res = await getSubscripedModules();
			if (res?.status === 200) {
				dispatch({ type: "subscriptionPlan", payload: res?.data?.data });
			}
		}
		fetchSubsciptionPlan();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// const getData = async () => {
	// 	const response = await fetch("https://ipapi.co/json/");
	// 	const data = await response.json();
	// 	localStorage.setItem("IPv4", data.ip);
	// };


	// useEffect(() => {
	// 	async function getActivePlan() {
	// 		let res = await fetchsubscriptionActivePlan();
	// 		if (res?.status === 200 && res?.data?.data?.is_free_trial) {
	// 			setActivePlan(res?.data?.data);
	// 		}
	// 	}
	// 	if (!activePlan && companyInfo?.company_category === "umrah_company") {
	// 		getActivePlan();
	// 	}
	// }, [activePlan, companyInfo?.company_category])


	useEffect(() => {
		const getData = async () => {
			const response = await fetch("https://ipapi.co/json/");
			const data = await response.json();
			localStorage.setItem("IPv4", data.ip);
		};
		getData();
	}, []);

	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);



	return (
		<header className="navbar bg-white" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
			<div className="container-fluid">
				<Link to="/dashboard">
					<img src={SafaLogo} alt="safa soft logo" width="195px" />
				</Link>

				{width >= 320 && width <= 600 ? (
					<div className="dropdowns d-flex align-items-center" style={{ gap: "5px" }}>
						{!isServiceCompnay && <CurrencySwitcherMobile className="bg-white" />}

						{!search.includes("token") ? (
							<>
								{!isServiceCompnay &&
									<ShowForPermission
										permission={["View-Wallets", "Manage-Wallets"]}
									>
										<WalletHeaderMobile />
									</ShowForPermission>
								}
								<NotifactionHeaderMobile />
							</>
						) : token_data && token_data.discount != null ? (
							<p style={{ color: "#D92626" }} className="mx-2">
								<strong style={{ fontWeight: "700" }}>
									{token_data.discount}%
								</strong>{" "}
								OFF
							</p>
						) : (
							""
						)}

						<LanguageSwitcherMobile show={false} />
						{isAuth ? <UserSettingsMobile /> : null}
					</div>
				) : (
					<div className="dropdowns d-flex align-items-center">
						<LanguageSwitcher />

						{!isServiceCompnay && <CurrencySwitcher className="bg-white" />}

						{!search.includes("token") ? (
							<>
								{!isServiceCompnay &&
									<ShowForPermission
										permission={["View-Wallets", "Manage-Wallets"]}
									>
										<WalletHeader />
									</ShowForPermission>
								}
								{width >= 768 ? <NotifactionHeader /> : null}{" "}
							</>
						)
							: token_data && token_data.discount != null ? (
								<p style={{ color: "#D92626" }} className="mx-2">
									<strong style={{ fontWeight: "700" }}>
										{token_data.discount}%
									</strong>{" "}
									OFF
								</p>
							) : (
								""
							)}

						{isAuth ? <UserSettings /> : null}
					</div>
				)}
			</div>
		</header>
	);
}
