import CustomModal from "modules/WebBuilder/shared/CustomModal";
import React, { useMemo, useState } from "react";
import { useSBSState } from 'context/global';
import NumberField from 'components/Form/NumberField/NumberField';
import SelectField from 'components/Form/SelectField/SelectField';

const UpdateWhatsAppModal = ({ isOpen, toggle, updateWhatsAppNumber, phone_code, phone_number }) => {
	const { allCountries } = useSBSState();
	console.log(phone_code, phone_number);

	const [whatsAppPhone, setwhatsAppPhone] = useState({
		phone_code: { id: phone_code, label: phone_code, name: phone_code, phone_code: phone_code },
		phone_number: phone_number,
	});


	const countriesPhoneCodes = useMemo(() => {
		return allCountries?.map(country => ({ ...country, name: country?.phone_code }))
	}, [allCountries]);

	return (
		<CustomModal
			isOpen={isOpen}
			toggle={toggle}
			header={"Edit What’s App Number "}
			size="md"
			classNames={"operation-modal"}>
			<div className="container main-modal modal-publish">
				<div className="col-md-12">
					<div className="main-label">
						<h3>
							Please add a What's App number for configuration.
						</h3>
						<div className="contact-us-fields">
							<label htmlFor="">What’s App Number</label>
							<div className="row mx-0">
								{/* phone code */}
								<div className="col-2 px-0 company_phone_code">
									<SelectField
										// label={"whatsApp Number"}
										placeholder="Select"
										hasLabel={false}
										id="phone_code"
										name="phone_code"
										value={whatsAppPhone.phone_code?.label || whatsAppPhone?.phone_code?.phone_code}
										onChange={(e) => setwhatsAppPhone({ ...whatsAppPhone, phone_code: e })}
										options={countriesPhoneCodes}
									/>
								</div>
								{/* phone */}
								<div className="col-9 px-0">
									<NumberField
										type={"phone"}
										label=""
										hasLabel={false}
										id="phone_number"
										name="phone_number"
										value={whatsAppPhone.phone_number}
										removeArrow={true}
										placeholder={"000 0000 000"}
										min={3}
										onChange={(e) => setwhatsAppPhone({ ...whatsAppPhone, phone_number: e.target.value })}
									/>
								</div>
							</div>

						</div>
					</div>
				</div>
				<hr />
				{/* <button className="btn primary-button mt-2" onClick={apply}>{webBuilder.apply}</button> */}
				<div className='modal-foot'>
					
					<button className="btn  primary-button primary-button-outline"
						onClick={() => toggle()}
					>
						Cancel 
					</button>

					<button className="btn primary-button"
						onClick={() => updateWhatsAppNumber(whatsAppPhone.phone_code, whatsAppPhone.phone_number, toggle)}
						disabled={!whatsAppPhone.phone_code || !whatsAppPhone.phone_number}
					>
						Save
					</button>

				</div>
			</div>
		</CustomModal>
	);
};

export default UpdateWhatsAppModal;
