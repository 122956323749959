import React, { useEffect, useState } from 'react'
import SafaLogo from "assets/images/safa_logo.png";
import SafaLogoTransparent from "assets/safa_soft_logo 1.png";
import TextField from 'components/Form/TextField/TextField';
import validate, { isFormValid } from "helpers/validate";
import { useSBSDispatch, useSBSState } from 'context/global';
import SafaLogoNew from "assets/images/dashboard/safaLogoFull.svg";
import axios from 'axios';
import { store } from 'react-notifications-component';
import { Document, Page, } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import moment from 'moment';


const pdfSrcURL = "https://back-sbs.staging.safa.asia/madina_conference_pdf/madina.pdf";
const pdfURL = "https://back-sbs.staging.safa.asia/api/v1/download-madinah-conference-pdf";

// const options = {
//   cMapUrl: '/cmaps/',
//   standardFontDataUrl: '/standard_fonts/',
// };

export default function MadinaConferenceRegistration() {
  const { locale } = useSBSState();
  const dispatch = useSBSDispatch();

  const [formInputs, setFormInputs] = useState({
    company_name: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  const [numPages, setNumPages] = useState(null);


  function downloadPDF() {
    const config = {
      method: "GET",
      responseType: "blob",
    };
    axios.get(pdfURL, config).then((res) => {
      const blob = new Blob([res.data], {
        type: "application/pdf",
      });
      const objectUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.target = "_blank";
      anchor.href = objectUrl;
      anchor.setAttribute(
        "download",
        `pdf-${moment().format("DD-MM-YYYY")}.${res.data.type.split("/")[1]
        }`
      );
      anchor.click();
    });
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function checkFormErrors() {
    let submitError = {};
    Object.keys(formInputs).forEach((key) => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: formInputs[key] },
          { required: true }
        ),
      };
    });
    setErrors(submitError);
  }

  function submit(e) {
    e.preventDefault();
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  async function submitForm() {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/v1/madinah-conference`, formInputs);
    if (res.status === 200) {
      store.addNotification({
        title: "success",
        message: "message send successfuly",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      setFormInputs({
        company_name: "",
        email: ""
      })
    }
  }

  useEffect(() => {
    if (locale === "en") {
      dispatch({
        type: "setLocale",
        payload: "ar",
      });
    }
  }, [dispatch, locale])



  useEffect(() => {
    if (isFormValid(errors)) {
      submitForm();
    }
  }, [isErrorLoaded]);



  return (
    <div className="container" dir="rtl">
      <div className="madina-conference">
        <img
          src={SafaLogo}
          alt="safa soft logo"
          className="mb-3"
          width="195px"
        />
        <img src={SafaLogoTransparent} alt="" className="bg-transparent" />


        <div className="d-flex flex-column flex-md-row gap-10 justify-content-between align-items-center w-100">
          <h1 className="main-header"> مؤشرات العمرة</h1>
          <button className="btn download-btn"
            onClick={downloadPDF}
          >

            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.44 9.26562C20.04 9.57563 21.51 11.4256 21.51 15.4756V15.6056C21.51 20.0756 19.72 21.8656 15.25 21.8656H8.73998C4.26998 21.8656 2.47998 20.0756 2.47998 15.6056V15.4756C2.47998 11.4556 3.92998 9.60563 7.46998 9.27563" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12 2.36719V15.2472" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15.35 13.0156L12 16.3656L8.65002 13.0156" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

            <span>
              تحميل PDF
            </span>
          </button>
        </div>
        {/* 
        <object className="pdf-viewer" data="https://www.orimi.com/pdf-test.pdf#toolbar=0" type="application/pdf">
          <embed src="https://www.orimi.com/pdf-test.pdf#toolbar=0" type="application/pdf" />
        </object> */}
        <div id="pdf-viewer" className="pdf-viewer">
          <Document file={pdfSrcURL} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={document?.getElementById('pdf-viewer')?.clientWidth}
              />
            ))}
          </Document>
        </div>


        {/* <iframe src="https://www.orimi.com/pdf-test.pdf#toolbar=0" title="/2023-stats" frameBorder="0" style={{ height: "400px" }}></iframe> */}

        <section className="register-form-section">
          <h2 className="sub-header mb-4">هل ترغب في الحصول على إحصائيات وبيانات بشكل مستمر؟</h2>
          <form onSubmit={submit} className="row mx-0">

            <div className="col-md-5 col-12">
              <TextField
                label={"اسم الشركة"}
                placeholder={"ادخل اسم الشركة"}
                id="company_name"
                name="company_name"
                value={formInputs.company_name}
                onChange={(e) => {
                  setFormInputs({ ...formInputs, company_name: e.target.value });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "company_name", value: e.target.value },
                      { required: true }
                    ),
                  });
                }}
                errors={errors?.company_name}
                color={errors?.company_name?.required ? "danger" : ""}
              />
            </div>
            <div className="col-md-5 col-12">
              <TextField
                type="email"
                label={"البريد الالكتروني"}
                placeholder={"ادخل البريد الالكتروني"}
                id="email"
                name="email"
                value={formInputs.email}
                onChange={(e) => {
                  setFormInputs({ ...formInputs, email: e.target.value });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "email", value: e.target.value },
                      { required: true, email: true }
                    ),
                  });
                }}
                errors={errors?.email}
                color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
              />
            </div>

            <button className="btn reg-btn">
              تسجيل البيانات
            </button>
          </form>
        </section>

        <section className="safa-promo-section">
          <img src={SafaLogoNew} alt="safa new logo" />
          <p>
            <b> منصة صفا لايف </b>
            {" "}
            لإدارة الوجهات السياحية لشركات السياحة والسفر.
            {" "}
            <a href="https://safalive.com">
              اضغط للدخول على منصة صفا لايف
            </a>
          </p>
        </section>
        <footer>
          © 2024 — صفا. جميع الحقوق محفوظة
        </footer>
      </div>
    </div>
  )
}
