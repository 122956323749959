import React from 'react'
import SelectField from 'components/Form/SelectField/SelectField'
import LocationMarkerIcon from 'modules/WebBuilder-V2/shared/icons/LocationMarker'
import PackageTypeIcon from 'modules/WebBuilder-V2/shared/icons/PackageTypeIcon'
import SearchIcon from 'modules/WebBuilder-V2/shared/icons/SearchIcon'
import Locale from 'translations'

export default function SearchBarTheme5({ product_types, countries, preview, searchState, handleSearchInputs, handleSearch }) {

  const { commons } = Locale;

  return (
    <div className="searchbar_container">
      <div className="form_select">
        <span className="icon">
          <LocationMarkerIcon />
        </span>
        <SelectField
          hasLabel={false}
          placeholder={`${commons.selectCountry}`}
          id="search-country"
          name="search-country"
          options={countries}
          value={searchState?.country?.label}
          onChange={(e) => handleSearchInputs(e, "country")}
        />
      </div>
      <div className="form_select">
        <span className="icon">
          <PackageTypeIcon />
        </span>
        <SelectField
          hasLabel={false}
          placeholder={`${commons.packageType}`}
          id="search-product-type"
          name="search-product-type"
          options={product_types}
          value={searchState.proudctType?.label}
          onChange={(e) => handleSearchInputs(e, "proudctType")}
        />
      </div>

      <button className='cta_btn'
        onClick={() => preview && handleSearch()}
      >
        <SearchIcon className="icon" />
        <span>{commons.search}</span>
      </button>
    </div>
  )
}
