import React, { useMemo } from "react";
import ItemData from "./ItemData";
import { useSBSState } from "context/global";
import Locale from "translations";
import sortIcon from 'assets/images/icons/sortIcon.svg'
import { useState } from 'react';
import { useEffect } from 'react';
import { Collapse } from 'reactstrap';

export default function SpecialData({listSpecial,setListSpecial,setFilter,filter}) {
	const { locale } = useSBSState();
	const { productsBuilder } = Locale;

	const toggleShowDetails=(item,value)=>{
		let list=[...listSpecial]
		list.forEach((element)=> {
			element.showDetails = item.id===element.id?value:false;
		});
		setListSpecial(list)
	}
	const AllItem = useMemo(() => {
		return listSpecial && listSpecial.length > 0
			? listSpecial.map((item, index) => (
				<ItemData key={item.id} index={item.id} item={item} toggleShowDetails={toggleShowDetails} />
			  ))
			: [];
	}, [listSpecial]);
	const option = [
	{en:"Name",ar:"الاسم"},
	{en:"Price",ar:"السعر"}


];

const [filtersIsOpen, setFiltersIsOpen] = useState({
	allFilters:true
});

const [matches, setMatches] = useState(
	window.matchMedia("(max-width: 600px)").matches
)

useEffect(() => {
	window
	.matchMedia("(max-width: 600px)")
	.addEventListener('change', e => setMatches( e.matches ));
}, []);

	return (
		<div className="special-data">
			<div className="sort p-mob">
				{locale === "en" ? (
					<p style={{color:'#D4A655', fontWeight:400}}>
						Showing {listSpecial.length} of <span>{listSpecial.length} Hotel</span>
					</p>
				) : (
					<p style={{color:'#D4A655'}}>عرض {listSpecial.length} من<span> {listSpecial.length} فندق</span></p>
				)}
			
				{matches ? (
						<div>
						<label className='d-flex' onClick={() =>
							setFiltersIsOpen((prev) => ({ ...prev, allFilters: !prev.allFilters }))}>
						<img className='mr-1' src={sortIcon} alt='Sort'/>
						<p style={{fontWeight:400}}>Sort & Filter</p>
						</label>

						<Collapse isOpen={filtersIsOpen.allFilters}>
							<select onChange={(e)=>{
								setFilter({...filter, "sort":{
									"by": e.target.value==="Price" || e.target.value==="السعر" ? "price":"name" ,
									"type": filter?.sort?.type==="desc"? "asc" :"desc"
							}})
							}}>
								{option.map((item,index) => (
									<option  key={index} value={item[locale]}>{item[locale]}</option>
								))}
							</select>
						</Collapse>
					</div>
				): (
						<div>
						<label>{productsBuilder.SortBy}</label>
						 <select onChange={(e)=>{
							 setFilter({...filter, "sort":{
								 "by": e.target.value==="Price" || e.target.value==="السعر" ? "price":"name" ,
								 "type": filter?.sort?.type==="desc"? "asc" :"desc"
						 }})
						 }}>
							 {option.map((item,index) => (
								 <option  key={index} value={item[locale]}>{item[locale]}</option>
							 ))}
						 </select>
					 </div>
				)}


			</div>
			{AllItem}
		</div>
	);
}
