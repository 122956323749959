
/**
 * Function description.
 *
 * @param {string} hex - the hex code representation of the color.
 * @returns {rgb} the rgb color.
 */

export const hexToRGB = (hex)=>{
  try{
    if(hex){
      hex = hex.replace(/^#/, '');
      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);
      return `${r}, ${g}, ${b}`;
    }
    return null
  }
  catch{
    return null;
  }
}

/**
 * Function description.
 *
 * @param {string} rgb - the rgb representation of the color.
 * @returns {hex} the hex representation of color.
 */

export const rgbToHex = (rgb)=>{
  try{
    if(rgb){
      const rgb_sanitized = rgb.replace(/\s/g, '');
      const rbg_array = rgb_sanitized.split(',');
      const r = Math.max(0, Math.min(255, rbg_array[0]));
      const g = Math.max(0, Math.min(255, rbg_array[1]));
      const b = Math.max(0, Math.min(255, rbg_array[2]));
      return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toLowerCase();
    }
    return null
  }
  catch{
    return null;
  }
}