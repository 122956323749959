import React from "react";

export default function HotelCardTamplate({ hotel }) {

	const hotelRating = +hotel?.rating > 5 ? 5 : +hotel?.rating;

	return (
		<div className="tamplate-card">
			<div
				className="tamplate-img"
				style={{
					backgroundImage: `url(${hotel?.main_image})`,
				}}
			>
				<div className="badge-banner">
					<h1>{`${hotel?.discounts[0]?.discount}% OFF`}</h1>
				</div>
			</div>
			<div className="tamplate-info">
				<div />
				<div className="row-hotel">
					<div className="text-left">
						<div style={{ fontSize: "1.1em" }}>
							{Array(hotelRating)
								.fill(2)
								.map((i, index) => (
									<i key={i + index + ""} className="fas fa-star"></i>
								))}
						</div>
						<p className="bolder">{hotel?.name}</p>
						<p className="light">
							<i className="fas fa-map-marker-alt mr-1"></i> {hotel?.address}
						</p>
						<i className="fas fa-globe-africa mr-1"></i> {hotel?.city_name}
					</div>
					<div className="text-right">
						<p className="light">Starting from </p>
						<p className="bolder"> {`${hotel?.currency} ${hotel.price_starting_from} / double room `}</p>
					</div>
				</div>
				<div className="tamplate-footer">
					<span>{`Valid booking date: ${hotel.booking_date_from} `}</span>
					<span style={{ borderInlineStart: "1px solid #fff",whiteSpace:"nowrap" }}>
					{`Valid travel date: ${hotel.travel_date_from} / \n ${hotel.travel_date_to}`}
					</span>
				</div>
			</div>
		</div>
	);
}
