import React, { useEffect } from "react";
import HeroHeader from "../Components/Content/Header";
import PackageBreadCrumb from "./breadCrumb";
import SeachResult from "./searchResult";
import { useWebBuilderDispatch, useWebBuilderState } from 'context/webBuilder';
import { useParams } from 'react-router-dom';
import { getPageBuilder, getPageBuilderByCompany } from 'services/webBuilder';
import { changeBorder, changeColorTheme, changeFont } from '../helpers/styles';

export default function PackageSearch() {
	const { packagesResult, style } = useWebBuilderState();
	const dispatch = useWebBuilderDispatch();
	const { name } = useParams();


	const updateStyle = ({ name, value }) => {
		dispatch({
			type: 'updateStyle',
			payload: {
				name,
				value
			}
		})
	}

	const getPageDetails = async () => {
		let res
		if (!name) {
			res = await getPageBuilder()
		} else {
			res = await getPageBuilderByCompany(name)
		}
		if (res.status === 200) {
			let pageBuilderData = res.data.page_builder_config;
			dispatch({
				type: 'setInitialData',
				payload: pageBuilderData
			});
			const apiStyle = pageBuilderData?.style
			changeColorTheme(apiStyle?.color, updateStyle)
			changeBorder(apiStyle?.rounded, updateStyle)
			changeFont(apiStyle?.font, updateStyle)
		}
	}

	useEffect(() => {
		if (!packagesResult) {
			getPageDetails();
		} else {
			changeColorTheme(style?.color, updateStyle)
			changeBorder(style?.rounded, updateStyle)
			changeFont(style?.font, updateStyle)
		}
		return () => { }
	}, []);


	return (
		<div className="public-page-search web-builder-preview" id="main-web-builder">
			<HeroHeader />
			<PackageBreadCrumb />
			<SeachResult />
		</div>
	);
}
