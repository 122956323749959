import React from "react";
import RefundRequest from "./RefundRequest";
import Locale from "translations";
import ChangeRequest from "./ChangeRequest";
import VoidRequest from './VoidRequest';
import envlopOutline from 'assets/images/icons/envlop-outline.svg'

export default function FlightBookingRequests({ requests, fetchRequest }) {
	const { inventory } = Locale;
	
	const openedRequests = requests?.filter(request => request?.status !== "confirmed" && request?.status !== "rejected" && request?.status !== "expired");
	const closedRequests = requests?.filter(request => request?.status === "confirmed" || request?.status === "rejected" || request?.status === "expired");

	function renderRequestsByType(request) {
		switch (request?.type) {
			case "refund":
				return (
					<RefundRequest
						key={`${request?.id}-${request?.request_num}`}
						request={request}
						fetchRequest={fetchRequest}
					/>
				);
			case "change":
				return (
					<ChangeRequest
						key={`${request?.id}-${request?.request_num}`}
						request={request}
						fetchRequest={fetchRequest}
					/>
				);
			case "void":
				return (
					<VoidRequest
						key={`${request?.id}-${request?.request_num}`}
						request={request}
						fetchRequest={fetchRequest}
					/>
				);
			default:
				return null;
		}
	}

	return (
		<div className="p-md-3 py-2 bg-white">
			{/* open request  */}
			<div>
				<h1 className='font-weight-bold h5'>{inventory.messages.openRequests}</h1>
				<hr className="my-1 bg-black" />

				{openedRequests?.length > 0 ?
					openedRequests?.map((request) => renderRequestsByType(request))
					:
					<div className='py-3 d-flex align-items-center justify-content-center'>
						<img src={envlopOutline} alt="envlop icon" width="28px" />
						<span className='mx-2'>{inventory.messages.noRequestsFound}</span>
					</div>
				}

			</div>
			<hr className='my-4 border border-dark'/>
			{/* closed request */}
			<div>
				<h1 className='font-weight-bold h5'>{inventory.messages.closedRequests}</h1>
				<hr className="my-1 bg-black" />
				{closedRequests?.length > 0 ?
					closedRequests?.map((request) => renderRequestsByType(request))
					:
					<div className='py-3 text-center'>
						<img src={envlopOutline} alt="envlop icon" width="28px" />
						<span className='mx-2'>{inventory.messages.noRequestsFound}</span>
					</div>
				}

			</div>
		</div>
	);
}