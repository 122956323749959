import { InsuranceIcon } from 'modules/market-place/PackageResponse/Details/shared/PackageIcon'
import moment from 'moment/moment'

export default function ItineraryInsurance({ isMobile, element }) {

  return (<>
    <div className={"boxcollapse"}>
      <div className={"closecollapse"}>
        <div className="collapseIcon">
          <InsuranceIcon />
        </div>
        {isMobile &&
          <p style={{ color: "##0F3E5E", fontWeight: "bold", marginRight: "auto", paddingLeft: "5px" }} >
            insurance
          </p>
        }
        {!isMobile &&
          <>
            <div className={"info"}>
              <div className='d-flex wrap'>
                <p style={{ color: "#0F3E5E" }} p >{element?.item?.name} </p>
              </div>
              <span sx={{ color: "##2D2D2D" }} >{moment(element?.created_at).format("DD/MM/YYYY")}</span>
            </div>
          </>
        }
      </div>


    </div>
  </>
  )
}