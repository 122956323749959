import { useEffect } from "react";
const PAYMENTURL = process.env.REACT_APP_PAYMENT_URL;

export default function PaymentHyperpay() {
	const checkoutId = localStorage.getItem("checkoutId");

	useEffect(() => {
		setTimeout(() => {

			const script = document.createElement("script");
			// process.env.REACT_PAYMENT_URL =https://eu-prod.oppwa.com/v1/paymentWidgets.js

			script.src = `${PAYMENTURL}?checkoutId=${checkoutId}`;
			// script.src = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
			script.async = true;
			script.setAttribute("id", "oppwaId");
			document.body.appendChild(script);
			const rootDom = document.querySelector(".container_layout") ? document.querySelector(".container_layout") : document.body ;
			const form = document.createElement("form");
			form.action = "/PayOnline/hyperpay";
			form.setAttribute("class", "paymentWidgets");
			form.setAttribute("data-brands", "VISA MASTER");
			form.setAttribute("id", "oppwa2");

			rootDom.appendChild(form);
		}, 1000);

		return () => {

			const elements = document.getElementsByClassName("wpwl-container");
			const control = document.getElementsByClassName("wpwl-control");
			while (elements.length > 0) {
				elements[0].parentNode.removeChild(elements[0]);
			}
			while (control.length > 0) {
				control[0].parentNode.removeChild(control[0]);
			}
		};
	}, []);

	return <div>...</div>;
}
