import moment from 'moment';
import React from 'react'
import { getDistinctAirLines, getProductHotels } from './helpers/getProductItemsFromProduct';
import { useSBSState } from 'context/global';
import Locale from 'translations';

export default function CurrentPackage({ packageDetails }) {
	// context
	const { locale } = useSBSState();
	const { CRM } = Locale

	
	// local variables
	const nightsCount = packageDetails?.departure_date_at && packageDetails?.return_date_at
		? moment(packageDetails?.return_date_at).diff(moment(packageDetails?.departure_date_at), "days")
		: 0;

	const airlines = getDistinctAirLines(packageDetails?.product_items?.filter(item => item?.item?.id === 1));
	const makkahHotels = getProductHotels(packageDetails?.product_items?.filter(item => item?.item?.id === 6), "makkah");
	const madinahHotels = getProductHotels(packageDetails?.product_items?.filter(item => item?.item?.id === 6), "madinah");
	const packageHasMazar = packageDetails?.product_items?.filter(item => item?.item?.id === 7)?.length > 0

	return (
		<div className='available-packages text-center'>
			<div className='details flex-wrap-custom' style={{ background: '#FFF' }}>
				<div className="details-items w-12">
					<h3 className="font-weight-bold">{CRM.CurrentPackage} :</h3>
				</div>

				<div className='details-items w-12'>
					<p className='crm-details-text-primary'>{CRM.PackageName}</p>
					<p className='crm-details-text-secondary'>{packageDetails?.name?.[locale]}</p>
				</div>

				<div className='details-items w-12'>
					<p className='crm-details-text-primary'>{CRM.DepartureNights}</p>
					<p className='crm-details-text-secondary'>
						{packageDetails?.departure_date_at ? moment(packageDetails?.departure_date_at).format("DD MMM YYYY") : "-"}
						{"/"}
						{nightsCount || ""}
					</p>
				</div>

				<div className='details-items w-12 display-border'>
					<p className='crm-details-text-primary'>{CRM.Airline}</p>
					{
						airlines.length > 0 ?
							airlines?.map(airline => {
								return (
									<p className='crm-details-text-secondary'>{airline?.itemable?.transporter?.name}</p>
								)
							})
							:
							"-"
					}

				</div>

				<div className='details-items w-12'>
					<p className='crm-details-text-primary'>{CRM.MakkahHotel}</p>
					{makkahHotels?.length > 0 ?
						makkahHotels?.map(hotel => {
							return <p className='crm-details-text-secondary'>{hotel?.name}</p>
						})
						:
						"-"
					}

				</div>

				<div className='details-items w-12'>
					<p className='crm-details-text-primary'>{CRM.MadinahHotel}</p>
					{madinahHotels?.length > 0 ?
						madinahHotels?.map(hotel => {
							return <p className='crm-details-text-secondary'>{hotel?.name}</p>
						})
						:
						"-"
					}
				</div>

				<div className='details-items w-12'>
					<p className='crm-details-text-primary'>{CRM.Mazzar}</p>
					<p className='crm-details-text-secondary'>{packageHasMazar ? CRM.Yes: CRM.No}</p>
				</div>



				<div className='details-items w-12'>
					<p className='crm-details-text-primary'>{CRM.Pax}</p>
					<p className='crm-details-text-secondary'>{packageDetails?.pax}</p>
				</div>
			</div>
		</div>
	)
}
