import SelectField from "components/Form/SelectField/SelectField";
import Locale from "translations";

export default function FilterStatistics({
	monthLookups,
	value,
	handleChange,
}) {
	const { statistics } = Locale;
	return (
		<>
			<div className="filter-month">
				<SelectField
					placeholder={statistics.thisMonth}
					hasLabel={false}
					options={monthLookups}
					value={value}
					onChange={handleChange}
					className="px-3"
				/>
			</div>
		</>
	);
}
