import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import axios from "axios";
import CustomPagination from "../CustomPaginationResrv/CustomPagination";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function CustomTable(props) {
	const [ProductList, setProductList] = useState([]);

	useEffect(() => {
		axios.get("../reservList.json").then((res) => {
			setProductList(res.data.productList);
		});
	}, []);

	//Filter List
	// const filterList = (e) => {
	//     let updateList = this.productList;

	//     updateList = updateList.filter(item => {
	//         return item.toLowerCase().search(
	//             e.target.value.toLowerCase()
	//         ) !== -1;
	//     })

	// }

	//Remove Item
	const removeItem = (index) => {
		setProductList(ProductList.filter((k, i) => i !== index));
	};

	const { className } = props;
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const AllProducts = ProductList.map((productItem, index) => {
		return (
			<tr key={productItem.id}>
				<th scope="row">{productItem.refNo}</th>
				<td>{productItem.ApplicationNo}</td>
				<td>{productItem.ApplicationOwner}</td>
				<td>{productItem.Pax}</td>
				<td>{productItem.BookingDate}</td>
				<td>{productItem.Source}</td>
				<td className="actions">
					<i className="far fa-eye text-primary"></i>
					<i className="fas fa-edit text-primary"></i>
					<i
						className="fas fa-trash-alt text-danger"
						onClick={() => removeItem(index)}
					></i>
				</td>
			</tr>
		);
	});

	return (
		<div className="container-fluid">
			{/* <ProductFilterReserv /> */}
			<div className="main-filter">
				<div className="row align-items-center w-100 no-gutter">
					<div className="col-md-2 px-1">
						<div className="main-label">
							{/*  onChange={this.filterList} */}
							<input
								id="input-search"
								className="main-input-search"
								type="text"
								placeholder="Sreach By Name / ID"
							/>
							<i className="fas fa-search fa-fw"></i>
						</div>
					</div>

					<div className="col-md px-1">
						<div className="main-label">
							<input
								id="input-valid-from"
								className="main-input-search"
								type="text"
								placeholder="Valid from"
							/>
							<i className="fas fa-calendar-alt fa-fw"></i>
						</div>
					</div>

					<div className="col-md px-1">
						<select id="product-type" className="main-input-search">
							<option checked="chacked">Source</option>
							<option>Source 2</option>
							<option>Source 3</option>
							<option>Source 4</option>
							<option>Source 5</option>
						</select>
					</div>

					<div className="col-md px-1">
						<div className="d-flex align-items-center text-secondary pointer">
							<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
							<p className="text-caption">Reset Filter</p>
						</div>
					</div>
				</div>
			</div>

			<div className="product-builder-list">
				<div className="product-header-list p-0">
					<h6 className="font-weight-bold dark-blue-color">
						Applications <span className="text-primary">(Product Name)</span>
					</h6>
					<div className="product-buttons">
						<button className="btn btn-primary">Export Excel</button>
						<button className="btn btn-primary">Print</button>
						<Button color="success" onClick={toggle}>
							Add New
						</Button>
					</div>
				</div>

				<div>
					<Modal isOpen={modal} toggle={toggle} className={className}>
						<ModalHeader className="font-weight-bold" toggle={toggle}>
							Create Application
						</ModalHeader>
						<ModalBody>
							<div className="application-modal text-body">
								<div className="room-price">
									{/** Invisible Item **/}
									<div className="room-item invisible">
										<input type="text" placeholder="0" />
									</div>
									{/** Invisible Item **/}

									<div className="room-item">
										<input
											className="bg-gray dark-blue-color"
											type="text"
											value="Availability"
											readOnly
										/>
									</div>
									<div className="room-item">
										<input
											className="bg-gray dark-blue-color"
											type="text"
											value="Pax"
											readOnly
										/>
									</div>
									<div className="room-item">
										<input
											className="bg-gray dark-blue-color"
											type="text"
											value="Extra Bed"
											readOnly
										/>
									</div>
								</div>
								<div className="room-price">
									<div className="room-item flex-column bg-gray">
										<i className="fas fa-bed"></i>
										<input
											className="bg-gray dark-blue-color"
											type="text"
											value="Single"
											readOnly
										/>
									</div>
									<div className="room-item">
										<p className="room-text">
											<span className="text-success">2</span>/
											<span className="dark-blue-color">15</span>
										</p>
									</div>
									<div className="room-item">
										<input type="text" placeholder="0" />
									</div>
									<div className="room-item">
										<input type="text" placeholder="0" />
									</div>
								</div>
								<div className="room-price">
									<div className="room-item flex-column bg-gray">
										<i className="fas fa-bed"></i>
										<input
											className="bg-gray dark-blue-color"
											type="text"
											value="Double"
											readOnly
										/>
									</div>
									<div className="room-item">
										<p className="room-text">
											<span className="text-success">13</span>/
											<span className="dark-blue-color">15</span>
										</p>
									</div>
									<div className="room-item">
										<input type="text" placeholder="0" />
									</div>
									<div className="room-item">
										<input type="text" placeholder="0" />
									</div>
								</div>
								<div className="room-price">
									<div className="room-item flex-column bg-gray">
										<i className="fas fa-bed"></i>
										<input
											className="bg-gray dark-blue-color"
											type="text"
											value="Triple"
											readOnly
										/>
									</div>
									<div className="room-item">
										<p className="room-text">
											<span className="text-success">10</span>/
											<span className="dark-blue-color">13</span>
										</p>
									</div>
									<div className="room-item">
										<input type="text" placeholder="0" />
									</div>
									<div className="room-item">
										<input type="text" placeholder="0" />
									</div>
								</div>
								<div className="room-price">
									<div className="room-item flex-column bg-gray">
										<i className="fas fa-bed"></i>
										<input
											className="bg-gray dark-blue-color"
											type="text"
											value="Quadruple"
											readOnly
										/>
									</div>
									<div className="room-item">
										<p className="room-text">
											<span className="text-danger">7</span>/
											<span className="danger">7</span>
										</p>
									</div>
									<div className="room-item">
										<input type="text" placeholder="0" />
									</div>
									<div className="room-item">
										<input type="text" placeholder="0" />
									</div>
								</div>
								<div className="room-price">
									<div className="room-item flex-column bg-gray">
										<i className="fas fa-bed"></i>
										<input
											className="bg-gray dark-blue-color"
											type="text"
											value="Quintuple"
											readOnly
										/>
									</div>
									<div className="room-item">
										<p className="room-text">
											<span className="text-success">1</span>/
											<span className="dark-blue-color">2</span>
										</p>
									</div>
									<div className="room-item">
										<input type="text" placeholder="0" />
									</div>
									<div className="room-item">
										<input type="text" placeholder="0" />
									</div>
								</div>
								<div className="room-price">
									<div className="room-item flex-column bg-gray">
										<i className="fas fa-bed"></i>
										<input
											className="bg-gray dark-blue-color"
											type="text"
											value="Sextuple"
											readOnly
										/>
									</div>
									<div className="room-item">
										<p className="room-text">
											<span className="text-success">1</span>/
											<span className="dark-blue-color">2</span>
										</p>
									</div>
									<div className="room-item">
										<input type="text" placeholder="0" />
									</div>
									<div className="room-item">
										<input type="text" placeholder="0" />
									</div>
								</div>
								<div className="room-price">
									<div className="room-item flex-column bg-gray">
										<i className="fas fa-bed"></i>
										<input
											className="bg-gray dark-blue-color"
											type="text"
											value="Suit"
											readOnly
										/>
									</div>
									<div className="room-item">
										<p className="room-text">
											<span className="text-success">1</span>/
											<span className="dark-blue-color">2</span>
										</p>
									</div>
									<div className="room-item">
										<input type="text" placeholder="0" />
									</div>
									<div className="room-item">
										<input type="text" placeholder="0" />
									</div>
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button color="primary w-50" onClick={toggle}>
								Proceed
							</Button>{" "}
							<Button color="secondary" onClick={toggle}>
								Cancel
							</Button>
						</ModalFooter>
					</Modal>
				</div>

				<Table striped className="custom-table">
					<thead>
						<tr>
							<th>Ref No.</th>
							<th>Application No.</th>
							<th>Application Owner</th>
							<th>
								<i className="fas fa-sort px-1 pointer"></i>
								Pax
							</th>
							<th>
								<i className="fas fa-sort px-1 pointer"></i>
								Booking Date
							</th>
							<th>
								<i className="fas fa-sort px-1 pointer"></i>
								Source
							</th>

							<th width="10%">Actions</th>
						</tr>
					</thead>
					<tbody>
						{AllProducts}
						{/* <tr>
                            <th scope="row">Static-tr</th>
                            <td>Product name</td>
                            <td>Umrah</td>
                            <td>
                                <p className="text-success">12-5-2020</p>
                                <p className="text-danger">12-5-2020</p>
                            </td>
                            <td>
                                <p className="text-success">12-5-2020</p>
                                <p className="text-danger">12-5-2020</p>
                            </td>
                            <td className="products">
                                <span className="icx icx-hotel"></span>
                                <span className="icx icx-plane"></span>
                                <span className="icx icx-transport"></span>
                                <span className="icx icx-images"></span>
                                <span className="icx icx-eye-slash"></span>
                                <span className="icx icx-eye-slash"></span>
                                <span className="icx icx-eye-slash"></span>
                            </td>
                            <td>
                                5
                            </td>
                            <td>
                                <i className="fas fa-check text-success"></i>
                            </td>
                            <td>
                                <p className="text-primary">Published</p>
                            </td>
                            <td className="actions">
                                <i className="far fa-eye"></i>
                                <i className="fas fa-edit"></i>
                                <i className="fas fa-clone"></i>
                                <i className="fas fa-share-alt"></i>
                                <i className="fas fa-users"></i>
                                <i className="fas fa-trash-alt"></i>
                            </td>
                        </tr> */}
					</tbody>
				</Table>

				<CustomPagination />
			</div>
		</div>
	);
}
