import clearIcon from "assets/images/wallet/clearIcon.svg";
import correctIcon from "assets/images/wallet/correctIcon.svg";
import DateRangeField from "components/Form/DateRangeField/DateRangeField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from 'context/global';
import moment from "moment";
import { useEffect, useState } from "react";
import { getModulesDropdown, getUsersDropdown } from "services/activity";
import Locale from "translations";

export default function Filter({
	filter,
	setFilter,
	transaction_type,
	search,
}) {
	const { payment, activity ,productsBuilder} = Locale;
	const { locale } = useSBSState();

	const [usersOptions, setUsersOptions] = useState([]);
	const [modulesOptions, setModulesOptions] = useState([]);

	// const status = [
	// 	{ id: 0, name: `${payment.messages.add}`, title: "Add" },
	// 	{ id: 1, name: `${payment.messages.deduct}`, title: "Deduct" },
	// ];
	useEffect(() => {
		async function getUsers() {
			const res = await getUsersDropdown();
			let formated = res?.data?.data.map((item, index) => 
			{	debugger
				return {
					id: item.user_id,
					name: item.user_names[locale] ? item.user_names[locale]:item.user_names["en"],
				}}
			);
			setUsersOptions(formated);
		}
		async function getModules() {
			const res = await getModulesDropdown();

			let formated = res?.data.map((item, index) => ({
				id: "1",
				name: item.log_name,
			}));
			setModulesOptions(formated);
		}

		getUsers();
		getModules();
	}, []);
	return (
		<>
			<div className="filter-update bg-filter-gray">
				<div className="product-buttons col-md-12">
					<div className="main-filter-payment d-flex justify-contain-between">
						<div className="main-label controls-field col-6 col-md-2">
							<TextField
								label={payment.messages.search}
								type="text"
								placeholder={payment.messages.search}
								onChange={(e) =>
									setFilter({ ...filter, search: e.target.value })
								}
								value={filter.search}
							/>
						</div>
						<div className="main-label mx-2 col-6 col-md-2 controls-field">
							<SelectField
								label={activity.user}
								placeholder={activity.enterUsers}
								options={usersOptions}
								value={filter?.user_id?.name}
								onChange={(e) =>
									setFilter({
										...filter,
										user_id: e,
									})
								}
							/>
						</div>
						<div className="main-label mx-2 col-6 col-md-2  controls-field">
							<SelectField
								label={activity.Module}
								placeholder={activity.enterModules}
								options={modulesOptions}
								value={filter?.log_name?.name}
								onChange={(e) =>
									setFilter({
										...filter,
										log_name: e,
									})
								}
							/>
						</div>
						<div className="main-label date-filter col-6 col-md-3  controls-field1">
							{/* <DatePickerField
								label={activity.date}
								placeholder="DD/MM/YYY"
								date={filter.date}
								onChangeDate={(e) => {
									setFilter({ ...filter, date: e });
								}}
								isOutsideRange={(day) => {
									return false;
								}}
							/> */}
							<DateRangeField
								label={activity.date}
								startDate={filter.date_from}
								endDate={filter.date_to}
								startDatePlaceholder={productsBuilder.startDatePlaceholder}
								endDatePlaceholder={productsBuilder.EndDatePlaceholder}
								onDatesChange={({ startDate, endDate }) => {
									setFilter({
										...filter,
										date_from: moment(startDate)._d,
										date_to: endDate
											? moment(endDate)._d
											: moment(startDate).add(1, "d")._d,
									});
								}}
								isOutsideRange={(day) => {
									return false;
								}}
								numberOfMonths={1}
								// minDate={moment(validationStartDate).add(1, "d")._d}
							/>
						</div>

						<div className="filter-btn col-12 col-md-3 d-flex align-items-center justify-content-start">
							<div className="apply pointer">
								<button className="btn btn-light px-3" onClick={search}>
									<img src={correctIcon} alt=" " /> {payment.messages.apply}
								</button>
							</div>
							<div
								className="clear text-secondary pointer"
								onClick={() => {
									setFilter({
										search: "",
										user_id: "",
										date_from: undefined,
										date_to: undefined,
										log_name: "",
										page: 1,
									});
									search();
								}}
							>
								<button className="btn btn-light px-3 mx-2">
									<img src={clearIcon} alt=" " /> {payment.messages.clear}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
