import React, { useState } from "react";
import { Collapse, Modal, ModalBody, ModalHeader } from "reactstrap";
import RequestItemHeader from "./RequestItemHeader";
import Locale from "translations";
import { RequestContactInfo } from "./RefundRequest";
import { store } from "react-notifications-component";
import { flightVoidPendingApproval } from "services/inventory";
import questionMarkIcon from "assets/images/icons/question-mark.svg";

export default function VoidRequest({ request, fetchRequest }) {
	const { inventory, interests } = Locale;
	const [isOpen, setIsOpen] = useState(false);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	function toggleCollapse() {
		setIsOpen(!isOpen);
	}
	function toggleConfirmModal() {
		setConfirmModalOpen(!confirmModalOpen);
	}

	async function handleChangeStatus(action) {
		const data = {
			request_id: request?.id,
			action: action,
		};
		const changeStatusRes = await flightVoidPendingApproval(data);
		if (changeStatusRes?.status === 200) {
			fetchRequest();
			store.addNotification({
				title: interests.messages.updateSuccessfully,
				message: interests.messages.updateSuccessfully,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setConfirmModalOpen(false);
		}
	}
	// "pending" | "rejected" | "pending approval" | "under review" | "confirmed";
	const status = request?.status;

	return (
		<div className="my-3">
			<RequestItemHeader
				isOpen={isOpen}
				toggleCollapse={toggleCollapse}
				requestTypeText={inventory.messages.voidRequest || "Void Request"}
				status={status}
				details={request}
			/>

			<Collapse isOpen={isOpen}>
				<div className="flight-requests-details">
					<div className="row m-0">
						<div className="col-md-6 col-12">
							{/* passengers data */}
							<div className="d-flex flex-column">
								<h2 className="text-capitalize blue">
									{inventory.messages.passengers}
								</h2>
								{request?.passengers?.map((passenger) => {
									return (
										<div className="flight-requests-refund-passenger">
											<div className="col-4">
												<i className="far fa-user-circle"></i>
												<span className="mx-1">
													{passenger?.passenger_info?.full_name}
												</span>
											</div>
											<span className="col-3">
												{passenger?.passenger_info?.type}
											</span>
											{/* <span className="col-5">Ticket No. {passenger?.ticketNumber}</span> */}
										</div>
									);
								})}
							</div>
						</div>

						<div className="col-md-6 col-12">
							{/* contact info */}
							<RequestContactInfo requestDetails={request} />

							{/* actions and status */}
							{status === "pending_approval" && (
								<div className="flight-requests-refund-amount p-2 mt-3">
									<h3 className="text-capitalize mb-2">
										{inventory.messages.refundAmount}
									</h3>

									<p className="d-flex align-items-center justify-content-between font-weight-bold px-3 py-2 bg-white">
										<span>{inventory.messages.Amount}</span>
										<span className="text-danger">
											{request?.amount} {request?.currency}
										</span>
									</p>

									<div className="flight-requests-refund-actions mt-2">
										<button
											className="btn reject-btn mx-3"
											onClick={() => handleChangeStatus("reject")}
										>
											{inventory.messages.reject}
										</button>
										<button
											className="btn accept-btn"
											onClick={toggleConfirmModal}
										>
											{inventory.messages.confirm}
										</button>
									</div>
								</div>
							)}

							{(status === "confirmed"||status === "waiting_approval") && (
								<div className="flight-requests-refund-amount p-2 mt-3">
									<h3 className="text-capitalize mb-2">
										{inventory.messages.refundAmount}
									</h3>
									<p className="d-flex align-items-center justify-content-between font-weight-bold px-3 py-2 bg-white">
										<span>{inventory.messages.Amount}</span>
										<span className="text-danger">
											{request?.amount} {request?.currency}
										</span>
									</p>
								</div>
							)}

							{status === "rejected" && request?.reject_reason && (
								<div className="flight-requests-danger-box py-1 px-2 mt-3">
									<h3 className="text-capitalize my-2">
										{inventory.messages.refundRejectReason}
									</h3>
									<p>{request?.reject_reason}</p>
								</div>
							)}

							
						</div>
					</div>
				</div>
			</Collapse>

			{/* confirm request modal */}
			{toggleConfirmModal && (
				<Modal isOpen={confirmModalOpen} centered={true}>
					<ModalHeader toggle={toggleConfirmModal}>
						<span>{inventory.messages.confirmRequest}</span>
					</ModalHeader>
					<ModalBody>
						<div className="p-3 text-center">
							<img src={questionMarkIcon} alt="question mark icon" />
							<p className="py-4 h5 font-weight-bold">
								{inventory.messages.youSureConfirmRequest}
							</p>
							<button
								className="flight-checkout-btn w-100 font-weight-bold border-0 rounded"
								onClick={() => handleChangeStatus("accept")}
							>
								{inventory.messages.confirm}
							</button>
						</div>
					</ModalBody>
				</Modal>
			)}
		</div>
	);
}
