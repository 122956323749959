export function NavBar1() {
	return (
		<svg
			width="130"
			height="57"
			viewBox="0 0 130 57"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.5" y="0.5" width="129" height="56" rx="1.5" fill="white" />
			<rect x="22.5" y="20" width="17" height="17" rx="3.5" fill="#FBFBFA" />
			<path
				d="M25.5575 32.5517L28.4333 30.6209C28.8942 30.3117 29.5592 30.3467 29.9733 30.7026L30.1658 30.8717C30.6208 31.2626 31.3558 31.2626 31.8108 30.8717L34.2375 28.7892C34.6925 28.3984 35.4275 28.3984 35.8825 28.7892L36.8333 29.6059"
				stroke="#D4A655"
				stroke-width="0.875"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M29.25 34.3307H32.75C35.6667 34.3307 36.8334 33.1641 36.8334 30.2474V26.7474C36.8334 23.8307 35.6667 22.6641 32.75 22.6641H29.25C26.3334 22.6641 25.1667 23.8307 25.1667 26.7474V30.2474C25.1667 33.1641 26.3334 34.3307 29.25 34.3307Z"
				stroke="#D4A655"
				stroke-width="0.875"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M29.25 27.3294C29.8944 27.3294 30.4167 26.8071 30.4167 26.1628C30.4167 25.5184 29.8944 24.9961 29.25 24.9961C28.6057 24.9961 28.0834 25.5184 28.0834 26.1628C28.0834 26.8071 28.6057 27.3294 29.25 27.3294Z"
				stroke="#D4A655"
				stroke-width="0.875"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M25.75 32.5807L26.0417 33.1641L27.2083 33.7474H28.6667H33.625L35.0833 33.4557L36.25 32.5807L36.5417 29.9557L36.25 29.6641L35.9583 29.3724L35.375 28.7891H34.7917L34.5 29.0807L32.4583 30.8307L31.5833 31.4141H30.7083L28.9583 30.5391L25.75 32.5807Z"
				fill="#D4A655"
				stroke="#D4A655"
				stroke-width="0.583333"
			/>
			<rect x="22.5" y="20" width="17" height="17" rx="3.5" stroke="#EED9BA" />
			<path d="M60.5 28.5H77.5" stroke="#DDDCDC" stroke-width="4" />
			<rect x="93" y="23.5" width="20" height="10" fill="#D4A655" />
			<rect x="0.5" y="0.5" width="129" height="56" rx="1.5" stroke="#FAF4EB" />
		</svg>
	);
}

export function NavBar2() {
	return (
		<svg
			width="130"
			height="57"
			viewBox="0 0 130 57"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.5" y="0.5" width="129" height="56" rx="1.5" fill="white" />
			<rect x="17" y="23.5" width="20" height="10" fill="#D4A655" />
			<path d="M52.5 28.5H69.5" stroke="#DDDCDC" stroke-width="4" />
			<rect x="90.5" y="20" width="17" height="17" rx="3.5" fill="#FBFBFA" />
			<path
				d="M93.5575 32.5517L96.4333 30.6209C96.8942 30.3117 97.5592 30.3467 97.9733 30.7026L98.1658 30.8717C98.6208 31.2626 99.3558 31.2626 99.8108 30.8717L102.237 28.7892C102.692 28.3984 103.427 28.3984 103.882 28.7892L104.833 29.6059"
				stroke="#D4A655"
				stroke-width="0.875"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M97.25 34.3307H100.75C103.667 34.3307 104.833 33.1641 104.833 30.2474V26.7474C104.833 23.8307 103.667 22.6641 100.75 22.6641H97.25C94.3334 22.6641 93.1667 23.8307 93.1667 26.7474V30.2474C93.1667 33.1641 94.3334 34.3307 97.25 34.3307Z"
				stroke="#D4A655"
				stroke-width="0.875"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M97.25 27.3294C97.8943 27.3294 98.4167 26.8071 98.4167 26.1628C98.4167 25.5184 97.8943 24.9961 97.25 24.9961C96.6057 24.9961 96.0833 25.5184 96.0833 26.1628C96.0833 26.8071 96.6057 27.3294 97.25 27.3294Z"
				stroke="#D4A655"
				stroke-width="0.875"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M93.75 32.5807L94.0417 33.1641L95.2084 33.7474H96.6667H101.625L103.083 33.4557L104.25 32.5807L104.542 29.9557L104.25 29.6641L103.958 29.3724L103.375 28.7891H102.792L102.5 29.0807L100.458 30.8307L99.5834 31.4141H98.7084L96.9584 30.5391L93.75 32.5807Z"
				fill="#D4A655"
				stroke="#D4A655"
				stroke-width="0.583333"
			/>
			<rect x="90.5" y="20" width="17" height="17" rx="3.5" stroke="#EED9BA" />
			<rect x="0.5" y="0.5" width="129" height="56" rx="1.5" stroke="#FAF4EB" />
		</svg>
	);
}
export function NavBar3() {
	return (
		<svg
			width="130"
			height="57"
			viewBox="0 0 130 57"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.5" y="0.5" width="129" height="56" rx="1.5" fill="white" />
			<rect x="13.5" y="23.5" width="20" height="10" fill="#D4A655" />
			<rect x="59.5" y="20" width="17" height="17" rx="3.5" fill="#FBFBFA" />
			<path
				d="M62.5575 32.5517L65.4333 30.6209C65.8942 30.3117 66.5592 30.3467 66.9733 30.7026L67.1658 30.8717C67.6208 31.2626 68.3558 31.2626 68.8108 30.8717L71.2375 28.7892C71.6925 28.3984 72.4275 28.3984 72.8825 28.7892L73.8333 29.6059"
				stroke="#D4A655"
				stroke-width="0.875"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M66.25 34.3307H69.75C72.6667 34.3307 73.8334 33.1641 73.8334 30.2474V26.7474C73.8334 23.8307 72.6667 22.6641 69.75 22.6641H66.25C63.3334 22.6641 62.1667 23.8307 62.1667 26.7474V30.2474C62.1667 33.1641 63.3334 34.3307 66.25 34.3307Z"
				stroke="#D4A655"
				stroke-width="0.875"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M66.25 27.3294C66.8943 27.3294 67.4167 26.8071 67.4167 26.1628C67.4167 25.5184 66.8943 24.9961 66.25 24.9961C65.6057 24.9961 65.0833 25.5184 65.0833 26.1628C65.0833 26.8071 65.6057 27.3294 66.25 27.3294Z"
				stroke="#D4A655"
				stroke-width="0.875"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M62.75 32.5807L63.0417 33.1641L64.2083 33.7474H65.6667H70.625L72.0833 33.4557L73.25 32.5807L73.5417 29.9557L73.25 29.6641L72.9583 29.3724L72.375 28.7891H71.7917L71.5 29.0807L69.4583 30.8307L68.5833 31.4141H67.7083L65.9583 30.5391L62.75 32.5807Z"
				fill="#D4A655"
				stroke="#D4A655"
				stroke-width="0.583333"
			/>
			<rect x="59.5" y="20" width="17" height="17" rx="3.5" stroke="#EED9BA" />
			<path d="M101 28.5H118" stroke="#DDDCDC" stroke-width="4" />
			<rect x="0.5" y="0.5" width="129" height="56" rx="1.5" stroke="#FAF4EB" />
		</svg>
	);
}

export function NavBar4() {
	return (
		<svg
			width="130"
			height="57"
			viewBox="0 0 130 57"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.5" y="0.5" width="129" height="56" rx="1.5" fill="white" />
			<path d="M12 28.5H29" stroke="#DDDCDC" stroke-width="4" />
			<rect x="53.5" y="20" width="17" height="17" rx="3.5" fill="#FBFBFA" />
			<path
				d="M56.5575 32.5517L59.4333 30.6209C59.8942 30.3117 60.5592 30.3467 60.9733 30.7026L61.1658 30.8717C61.6208 31.2626 62.3558 31.2626 62.8108 30.8717L65.2375 28.7892C65.6925 28.3984 66.4275 28.3984 66.8825 28.7892L67.8333 29.6059"
				stroke="#D4A655"
				stroke-width="0.875"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M60.25 34.3307H63.75C66.6667 34.3307 67.8334 33.1641 67.8334 30.2474V26.7474C67.8334 23.8307 66.6667 22.6641 63.75 22.6641H60.25C57.3334 22.6641 56.1667 23.8307 56.1667 26.7474V30.2474C56.1667 33.1641 57.3334 34.3307 60.25 34.3307Z"
				stroke="#D4A655"
				stroke-width="0.875"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M60.25 27.3294C60.8943 27.3294 61.4167 26.8071 61.4167 26.1628C61.4167 25.5184 60.8943 24.9961 60.25 24.9961C59.6057 24.9961 59.0833 25.5184 59.0833 26.1628C59.0833 26.8071 59.6057 27.3294 60.25 27.3294Z"
				stroke="#D4A655"
				stroke-width="0.875"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M56.75 32.5807L57.0417 33.1641L58.2084 33.7474H59.6667H64.625L66.0834 33.4557L67.25 32.5807L67.5417 29.9557L67.25 29.6641L66.9584 29.3724L66.375 28.7891H65.7917L65.5 29.0807L63.4584 30.8307L62.5834 31.4141H61.7084L59.9584 30.5391L56.75 32.5807Z"
				fill="#D4A655"
				stroke="#D4A655"
				stroke-width="0.583333"
			/>
			<rect x="53.5" y="20" width="17" height="17" rx="3.5" stroke="#EED9BA" />
			<rect x="96.5" y="23.5" width="20" height="10" fill="#D4A655" />
			<rect x="0.5" y="0.5" width="129" height="56" rx="1.5" stroke="#FAF4EB" />
		</svg>
	);
}
export function Main1() {
	return (
		<svg
			width="302"
			height="90"
			viewBox="0 0 302 90"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.5" y="0.5" width="301" height="89" rx="1.5" fill="white" />
			<rect
				x="56.95"
				y="18.582"
				width="188.1"
				height="52.8359"
				fill="#FBFBFA"
			/>
			<path
				d="M143.224 41.8726L147.333 39.1139C147.991 38.6722 148.941 38.7222 149.533 39.2306L149.808 39.4723C150.458 40.0307 151.508 40.0307 152.158 39.4723L155.626 36.4969C156.276 35.9385 157.326 35.9385 157.976 36.4969L159.334 37.6637"
				stroke="#D4A655"
				stroke-width="1.25016"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M148.5 44.4149H153.5C157.668 44.4149 159.334 42.748 159.334 38.5808V33.5802C159.334 29.413 157.668 27.7461 153.5 27.7461H148.5C144.332 27.7461 142.666 29.413 142.666 33.5802V38.5808C142.666 42.748 144.332 44.4149 148.5 44.4149Z"
				stroke="#D4A655"
				stroke-width="1.25016"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M148.5 34.4119C149.42 34.4119 150.167 33.6656 150.167 32.745C150.167 31.8244 149.42 31.0781 148.5 31.0781C147.579 31.0781 146.833 31.8244 146.833 32.745C146.833 33.6656 147.579 34.4119 148.5 34.4119Z"
				stroke="#D4A655"
				stroke-width="1.25016"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M143.499 41.9135L143.916 42.7469L145.583 43.5803H147.666H154.75L156.834 43.1636L158.501 41.9135L158.918 38.163L158.501 37.7463L158.084 37.3295L157.251 36.4961H156.417L156.001 36.9128L153.084 39.4131L151.833 40.2466H150.583L148.083 38.9964L143.499 41.9135Z"
				fill="#D4A655"
				stroke="#D4A655"
				stroke-width="0.833442"
			/>
			<rect
				x="131.45"
				y="50.0859"
				width="39.1"
				height="5.83333"
				fill="#FAF4EB"
			/>
			<path d="M137 59.918H165" stroke="#DDDCDC" />
			<path d="M137 63.918H165" stroke="#DDDCDC" />
			<rect
				x="56.95"
				y="18.582"
				width="188.1"
				height="52.8359"
				stroke="#EED9BA"
			/>
			<rect x="0.5" y="0.5" width="301" height="89" rx="1.5" stroke="#FAF4EB" />
		</svg>
	);
}
export function Main2() {
	return (
		<svg
			width="302"
			height="90"
			viewBox="0 0 302 90"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.5" y="0.5" width="301" height="89" rx="1.5" fill="white" />
			<rect x="22.5" y="27.5" width="169.003" height="35.0026" fill="#FBFBFA" />
			<path
				d="M99.2253 50.7906L103.334 48.0319C103.993 47.5902 104.943 47.6402 105.534 48.1486L105.809 48.3903C106.46 48.9487 107.51 48.9487 108.16 48.3903L111.627 45.4149C112.277 44.8565 113.327 44.8565 113.977 45.4149L115.336 46.5817"
				stroke="#D4A655"
				stroke-width="1.25016"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M104.501 53.3329H109.502C113.669 53.3329 115.336 51.666 115.336 47.4988V42.4982C115.336 38.3309 113.669 36.6641 109.502 36.6641H104.501C100.334 36.6641 98.6669 38.3309 98.6669 42.4982V47.4988C98.6669 51.666 100.334 53.3329 104.501 53.3329Z"
				stroke="#D4A655"
				stroke-width="1.25016"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M104.501 43.3299C105.422 43.3299 106.168 42.5836 106.168 41.663C106.168 40.7424 105.422 39.9961 104.501 39.9961C103.58 39.9961 102.834 40.7424 102.834 41.663C102.834 42.5836 103.58 43.3299 104.501 43.3299Z"
				stroke="#D4A655"
				stroke-width="1.25016"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M99.5003 50.8314L99.9171 51.6649L101.584 52.4983H103.668H110.752L112.835 52.0816L114.502 50.8314L114.919 47.0809L114.502 46.6642L114.086 46.2475L113.252 45.4141H112.419L112.002 45.8308L109.085 48.3311L107.835 49.1646H106.585L104.084 47.9144L99.5003 50.8314Z"
				fill="#D4A655"
				stroke="#D4A655"
				stroke-width="0.833442"
			/>
			<rect
				x="22.5"
				y="27.5"
				width="169.003"
				height="35.0026"
				stroke="#EED9BA"
			/>
			<rect x="202.003" y="32" width="77.9974" height="10" fill="#FAF4EB" />
			<path d="M202.003 46H280" stroke="#DDDCDC" />
			<path d="M202.003 50H280" stroke="#DDDCDC" />
			<path d="M202.003 54H280" stroke="#DDDCDC" />
			<rect x="0.5" y="0.5" width="301" height="89" rx="1.5" stroke="#FAF4EB" />
		</svg>
	);
}
export function Main3() {
	return (
		<svg
			width="302"
			height="96"
			viewBox="0 0 302 96"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.5" y="3.5" width="301" height="89" rx="1.5" fill="white" />
			<rect x="95" y="12" width="112" height="10" fill="#FAF4EB" />
			<path d="M109 26H193" stroke="#DDDCDC" />
			<path d="M109 30H193" stroke="#DDDCDC" />
			<path d="M109 34H193" stroke="#DDDCDC" />
			<rect
				x="66.4987"
				y="48.5"
				width="169.003"
				height="35.0026"
				fill="#FBFBFA"
			/>
			<path
				d="M143.224 71.7906L147.333 69.0319C147.991 68.5902 148.941 68.6402 149.533 69.1486L149.808 69.3903C150.458 69.9487 151.508 69.9487 152.158 69.3903L155.626 66.4149C156.276 65.8565 157.326 65.8565 157.976 66.4149L159.334 67.5817"
				stroke="#D4A655"
				stroke-width="1.25016"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M148.5 74.3329H153.5C157.668 74.3329 159.334 72.666 159.334 68.4988V63.4982C159.334 59.3309 157.668 57.6641 153.5 57.6641H148.5C144.332 57.6641 142.666 59.3309 142.666 63.4982V68.4988C142.666 72.666 144.332 74.3329 148.5 74.3329Z"
				stroke="#D4A655"
				stroke-width="1.25016"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M148.5 64.3299C149.42 64.3299 150.167 63.5836 150.167 62.663C150.167 61.7424 149.42 60.9961 148.5 60.9961C147.579 60.9961 146.833 61.7424 146.833 62.663C146.833 63.5836 147.579 64.3299 148.5 64.3299Z"
				stroke="#D4A655"
				stroke-width="1.25016"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M143.499 71.8314L143.916 72.6649L145.583 73.4983H147.666H154.75L156.834 73.0816L158.501 71.8314L158.918 68.0809L158.501 67.6642L158.084 67.2475L157.251 66.4141H156.417L156.001 66.8308L153.084 69.3311L151.833 70.1646H150.583L148.083 68.9144L143.499 71.8314Z"
				fill="#D4A655"
				stroke="#D4A655"
				stroke-width="0.833442"
			/>
			<rect
				x="66.4987"
				y="48.5"
				width="169.003"
				height="35.0026"
				stroke="#EED9BA"
			/>
			<rect x="0.5" y="3.5" width="301" height="89" rx="1.5" stroke="#FAF4EB" />
		</svg>
	);
}
