import NewPaymentAction from 'components/NewPaymentAction';
import ShowForPermission from 'helpers/showForPermission';
import Company from 'modules/market-place/PackageResponse/Details/shared/company';
import React, { useState } from 'react'
import { store } from 'react-notifications-component';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { packagePaylater } from 'services/inventory';
import Locale from 'translations';
import walletImgWhiteSrc from "assets/images/inventory/walletSmWhite.png";
import { ReactComponent as TripInventory } from 'assets/images/inventory/document-text.svg'
import { ReactComponent as InvoiceInventoryIcon } from 'assets/images/inventory/task-square.svg'
import { ReactComponent as VouchersIcon } from 'assets/images/inventory/receipt-item.svg'
import useInventoryInvoices from './useInventoryInvoices'
import { formatPrice } from 'helpers/utils';

export default function PackageReservationSummary({ details, itineraryElementsIds,setPackageDetails,dawnloadPackageInvoice }) {
  const { packages, marketPlace , inventory} = Locale;

  const { printPackageItinerary, printPackageVoucher } = useInventoryInvoices()
  
  const [paymentModal, setPaymentModal] = useState(false);

	const [actionType, setActionType] = useState('');
  const [formData, setFormData] = useState({});
	const { id } = useParams();
	const handelpaymentModal = () => {
		setActionType('')
		setFormData({})
		setPaymentModal((prev) => !prev);
	};
	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK? JSON.parse(process.env.REACT_APP_OTP_CHECK):false

	const pay = async (paymentMethod, currency,otp) => {
		let data = { payment_method: paymentMethod, currency,otp:otp };
		const response = await packagePaylater(id, data);
		if (response.status === 200) {
			setPaymentModal(false);
			setPackageDetails(response.data.data);
			store.addNotification({
				title: "Payment Done",
				message: "Payment Done successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}else if (response?.status === 202){
      setActionType(response?.data?.actionType)
      setFormData(response?.data?.data?.formData)

    }
	};
  
  
  // const productPrice = details?.product_price;
  // const roomsAvailabilities = details?.product_room_price;
  // const roomAvailabilities = details?.roomAvailabilities;

  // let typeRooms = {
  //   1: "Single Room",
  //   2: "Double Room",
  //   3: "Treble Room",
  //   4: "Quad Room",
  //   5: "Quintuple Room",
  //   6: "Hexagonal Room",
  //   7: "Seven Room",
  //   8: "Eight Room"
  // };
  
  // const priceType = [
  //   { type: "adults_count", name: "Adult" },
  //   { type: "children_count", name: "Children" },
  //   { type: "infant_count", name: "Infant" },
  // ];

  const ExternalElements = () => {
    //To Return uniqe External Element
    let externalElementAirLines = details?.product_details?.filter(
      (element) => element?.item?.id === itineraryElementsIds?.external
    );
    let uniqueAirLinesIds = {}
    const uniqueExtrenalAirLines = externalElementAirLines?.filter((item) => {
      const airLineId = item?.itemable?.transporter?.id
      if (uniqueAirLinesIds[airLineId] !== undefined) {
        return false;
      }
      uniqueAirLinesIds = { ...uniqueAirLinesIds, [airLineId]: item }
      return item
    });
    if (uniqueExtrenalAirLines?.length > 0) {
      return uniqueExtrenalAirLines?.map(externalTransport => {
        return (
          <Company
            key={externalTransport.id}
            title={marketPlace.airlines}
            name={externalTransport?.itemable?.transporter?.name}
            logo={externalTransport?.itemable?.transporter?.logo}
          />
        );
      })
    } else {
      return null;
    }
  };

  return (
    <div className='package-booking-totalprice'>
      {/* <h6 className='terms-color font-weight-bold'>
        Summary
      </h6> */}
      {/* summary per room */}
      {/* <div className='bg-white my-2 p-2 shadow-sm rounded'>
        {roomAvailabilities?.map((room) => {
          
          const roomType = roomsAvailabilities?.find(
            (i) => i.room_type === room.room_type
          );
          return priceType.map((price) => {
            // debugger
            return room[price.type] > 0 ? (
              <>
                <div className="d-flex justify-content-between mb-1">
                  <span>
                    {`${room.adults_count}x ${price.name} `}
                    <span style={{ fontSize: "13px", color: "#0F3E5E" }}>
                      ({typeRooms[roomType?.room_type]})
                    </span>{" "}
                  </span>
                  <span>
                    {price.type === "adults_count"
                      ? +roomType?.adult_price * +room[price.type]
                      : price.type === "children_count"
                        ? +productPrice.price_child * +room[price.type]
                        : +productPrice.price_infant * +room[price.type]}
                    {` ${productPrice.currency_code}`}
                  </span>
                </div>
              </>
            ) : null;
          });
        })}
      </div> */}
      {/* price summary */}
      <h6 className='terms-color font-weight-bold'>
        {packages.priceSummary}
      </h6>
      <div className="bg-white my-2 p-2 shadow-sm rounded p-3">
        <div className="d-flex justify-content-between my-2">
          <span className='font-weight-bold'>{packages.basePrice}</span>
          <span>{`${formatPrice(details?.total_price)} ${details.currency}`}</span>
        </div>

        <hr className="my-1" />
        <div className="d-flex justify-content-between my-1 terms-color">
          <span className='font-weight-bold'>{packages.totalPackagePrice}</span>
          <span>{`${formatPrice(details?.total_price)} ${details.currency}`}</span>
        </div>
      </div>


      {ExternalElements()}

      {/* <ShowForPermission permission="Manage-Inventory-Package"> */}
        { details.reservation_status === "approved" && (
          <button className="btn mb-3" onClick={handelpaymentModal}>
            <img src={walletImgWhiteSrc} alt="wallet white" />
            <span className="mx-2">{inventory.messages.payNow}</span>
          </button>
        )}
      {/* </ShowForPermission> */}

      <div className='inventory-packages-reports-button'>
        <button className='btn btn-inventory-invoices' onClick={printPackageItinerary}>
					<InvoiceInventoryIcon />
					<span>{inventory.messages.downloadTripItinerary}</span>
				</button>

        <button className='btn btn-inventory-invoices' onClick={printPackageVoucher}>
					<TripInventory />
					<span>{inventory.messages.downloadVouchers}</span>
				</button>

				<button className='btn btn-inventory-invoices' onClick={dawnloadPackageInvoice}>
					<VouchersIcon />
					<span>{inventory.messages.downloadInvoice}</span>
				</button>
			</div>

      {paymentModal && (
				<NewPaymentAction
					actionType={actionType}
					formData={formData}
					isOpen={paymentModal}
					toggleModal={handelpaymentModal}
					onPayment={pay}
					canPayLater={false}
					Price= {details?.provider_total_price}
					currency= {details?.provider_currency}

					secondPrice= {details?.total_price}
					secondCurrency= {details?.currency}
					OTP={OTP_CHECK}
					setActionType={setActionType}
					setFormData={setFormData}
				/>
			)}
    </div>
  )
}
