
import React from 'react'

export default function DiscountIcon() {
  return (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.32408 12.2172L2.05742 10.9505C1.54076 10.4338 1.54076 9.58383 2.05742 9.06717L3.32408 7.80048C3.54075 7.58382 3.71575 7.15881 3.71575 6.85881V5.06713C3.71575 4.33379 4.31575 3.73381 5.04909 3.73381H6.84075C7.14075 3.73381 7.56575 3.55884 7.78242 3.34217L9.04908 2.07549C9.56574 1.55882 10.4158 1.55882 10.9324 2.07549L12.1991 3.34217C12.4158 3.55884 12.8407 3.73381 13.1407 3.73381H14.9324C15.6658 3.73381 16.2657 4.33379 16.2657 5.06713V6.85881C16.2657 7.15881 16.4407 7.58382 16.6574 7.80048L17.9241 9.06717C18.4408 9.58383 18.4408 10.4338 17.9241 10.9505L16.6574 12.2172C16.4407 12.4339 16.2657 12.8589 16.2657 13.1589V14.9504C16.2657 15.6838 15.6658 16.2839 14.9324 16.2839H13.1407C12.8407 16.2839 12.4158 16.4588 12.1991 16.6755L10.9324 17.9422C10.4158 18.4589 9.56574 18.4589 9.04908 17.9422L7.78242 16.6755C7.56575 16.4588 7.14075 16.2839 6.84075 16.2839H5.04909C4.31575 16.2839 3.71575 15.6838 3.71575 14.9504V13.1589C3.71575 12.8505 3.54075 12.4255 3.32408 12.2172Z" stroke="#019D4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.5 12.5L12.5 7.5" stroke="#019D4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.0791 12.0832H12.0866" stroke="#019D4C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.91209 7.91667H7.91957" stroke="#019D4C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>)
}
