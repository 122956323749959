import React from 'react'
import { CalenderDotsIcon, GlobalIcon, ItineraryIcon } from './Icons'
import Locale from 'translations'

export default function TripPlannerHeader({ destinations, duration, jourenyType }) {
  const { productsBuilder, operationStatement,} = Locale;

  return (
    <div className="trip-planner-header">
      <div className="item">
        <span className="icon">
          <GlobalIcon color="#778294" />
        </span>
        <div className="d-flex flex-column">
          <span className="text-secondary">{productsBuilder.destinations}</span>
          <p className="font-weight-bold">
            {destinations?.map((destination, index) => {
              return <span key={destination?.id}>
                {destination?.name}
                {index < destinations?.length - 1 ? ", " : ""}
              </span>
            })}
          </p>
        </div>
      </div>
      <div className="item">
        <span className="icon">
          <CalenderDotsIcon color="#778294" />
        </span>
        <p className="d-flex flex-column">
          <span className="text-secondary">{operationStatement.Duration}</span>
          <span className="font-weight-bold">{duration - 1 || 0} {productsBuilder.nights}</span>
        </p>
      </div>
      <div className="item">
        <span className="icon">
          <ItineraryIcon color="#778294" />
        </span>
        <p className="d-flex flex-column">
          <span className="text-secondary">{productsBuilder.journeyType}</span>
          <span className="font-weight-bold">{jourenyType?.name} </span>
        </p>
      </div>
    </div>
  )
}
