import React, { useState } from "react";
import { ReactComponent as BlockIcon } from "assets/images/webBuilder/block.svg";
import { ReactComponent as PagesIcon } from "assets/images/webBuilder/pages.svg";
import { ReactComponent as StyleIcon } from "assets/images/webBuilder/style.svg";
import ContentBlocks from "./ContentBlocks";
import Pages from "./Pages";
import Style from './Style';
import { ReactComponent as ThemesIcon } from "assets/images/webBuilder/choose-theme.svg";
import ChangeTheme from 'modules/WebBuilder-V2/Components/SideBar/ChangeTheme';

function SideBar() {
	const [activeTap, setActiveTap] = useState("blocks");
	const sidebarTaps = {
		blocks: <ContentBlocks />,
		themes: <ChangeTheme />,
		pages: <Pages />,
		style: <Style />,
	};
	const changeTapHandler = (value) => {
		setActiveTap(value);
	};
	return (
		<div className="web-builder-sidebar">
			<div className="sidebar-nav">
				<button
					className={`${activeTap === "blocks" ? "active" : ""}`}
					onClick={() => changeTapHandler("blocks")}
				>
					<BlockIcon />
				</button>
				<button className={`${activeTap === "themes" ? "active" : ""}`}
					onClick={() => changeTapHandler("themes")}
				>
					<ThemesIcon />
				</button>
				<button
					className={`${activeTap === "pages" ? "active" : ""}`}
					onClick={() => changeTapHandler("pages")}
				>
					<PagesIcon />
				</button>
				<button
					className={`${activeTap === "style" ? "active" : ""}`}
					onClick={() => changeTapHandler("style")}
				>
					<StyleIcon />
				</button>
			</div>
			<div className="sidebar-content">{sidebarTaps[activeTap]}</div>
		</div>
	);
}

export default SideBar;
