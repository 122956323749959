import moment from 'moment';

export const formatTransfer = (data) => {
	const cloneData = JSON.stringify(data);
	const newData = JSON.parse(cloneData);
	const hasChildren = newData.children?.value;
	const childsAge = {
		children: 0,
		children_ages: [],
		infants: 0,
		infants_ages: [],
	};
	if (newData.children?.value) {
		newData.ages.forEach((key) => {
			const value = key?.value;
			if (value > 3) {
				childsAge.children = ++childsAge.children;
				childsAge.children_ages.push(value);
			} else {
				childsAge.infants = ++childsAge.infants;
				childsAge.infants_ages.push(value);
			}
		});
	}
	
	return {
		from_type: newData.from_type,
		from_code: newData.from.id.toString(),
		to_type: newData.to_type,
		to_code: newData.to.id.toString(),
		pickup_time: moment(newData.departureDate).format("YYYY-MM-DD hh:mm:ss A"),
		return_time: newData.returnDate ?  moment(newData.returnDate).format("YYYY-MM-DD hh:mm:ss A") : null,
		adults: newData.adult.value,
		children: 0,
		infants: 0,
		...(hasChildren && childsAge),
	};
};
