export const HotelRoomingPrint = `
<style>
			.w-25 {
				width: 25% !important;
			}

			.w-50 {
				width: 50% !important;
			}

			.w-75 {
				width: 75% !important;
			}

			.w-100 {
				width: 100% !important;
			}

			.mr-3,
			.mx-3 {
				margin-right: 1rem !important;
			}

			.ml-3,
			.mx-3 {
				margin-left: 1rem !important;
			}

			html,
			body,
			div,
			dl,
			dt,
			dd,
			ul,
			ol,
			li,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			pre,
			code,
			form,
			fieldset,
			legend,
			input,
			textarea,
			p,
			blockquote,
			th,
			td {
				margin: 0;
				padding: 0;
			}
			.row {
				display: flex;
				flex-wrap: wrap;
				margin-right: -15px;
				margin-left: -15px;
			}
			.col,
			.col-1,
			.col-10,
			.col-11,
			.col-12,
			.col-2,
			.col-3,
			.col-4,
			.col-5,
			.col-6,
			.col-7,
			.col-8,
			.col-9,
			.col-auto,
			.col-lg,
			.col-lg-1,
			.col-lg-10,
			.col-lg-11,
			.col-lg-12,
			.col-lg-2,
			.col-lg-3,
			.col-lg-4,
			.col-lg-5,
			.col-lg-6,
			.col-lg-7,
			.col-lg-8,
			.col-lg-9,
			.col-lg-auto,
			.col-md,
			.col-md-1,
			.col-md-10,
			.col-md-11,
			.col-md-12,
			.col-md-2,
			.col-md-3,
			.col-md-4,
			.col-md-5,
			.col-md-6,
			.col-md-7,
			.col-md-8,
			.col-md-9,
			.col-md-auto,
			.col-sm,
			.col-sm-1,
			.col-sm-10,
			.col-sm-11,
			.col-sm-12,
			.col-sm-2,
			.col-sm-3,
			.col-sm-4,
			.col-sm-5,
			.col-sm-6,
			.col-sm-7,
			.col-sm-8,
			.col-sm-9,
			.col-sm-auto,
			.col-xl,
			.col-xl-1,
			.col-xl-10,
			.col-xl-11,
			.col-xl-12,
			.col-xl-2,
			.col-xl-3,
			.col-xl-4,
			.col-xl-5,
			.col-xl-6,
			.col-xl-7,
			.col-xl-8,
			.col-xl-9,
			.col-xl-auto {
				padding-right: 5px;
				padding-left: 5px;
			}

			.col,
			.col-1,
			.col-10,
			.col-11,
			.col-12,
			.col-2,
			.col-3,
			.col-4,
			.col-5,
			.col-6,
			.col-7,
			.col-8,
			.col-9,
			.col-auto,
			.col-lg,
			.col-lg-1,
			.col-lg-10,
			.col-lg-11,
			.col-lg-12,
			.col-lg-2,
			.col-lg-3,
			.col-lg-4,
			.col-lg-5,
			.col-lg-6,
			.col-lg-7,
			.col-lg-8,
			.col-lg-9,
			.col-lg-auto,
			.col-md,
			.col-md-1,
			.col-md-10,
			.col-md-11,
			.col-md-12,
			.col-md-2,
			.col-md-3,
			.col-md-4,
			.col-md-5,
			.col-md-6,
			.col-md-7,
			.col-md-8,
			.col-md-9,
			.col-md-auto,
			.col-sm,
			.col-sm-1,
			.col-sm-10,
			.col-sm-11,
			.col-sm-12,
			.col-sm-2,
			.col-sm-3,
			.col-sm-4,
			.col-sm-5,
			.col-sm-6,
			.col-sm-7,
			.col-sm-8,
			.col-sm-9,
			.col-sm-auto,
			.col-xl,
			.col-xl-1,
			.col-xl-10,
			.col-xl-11,
			.col-xl-12,
			.col-xl-2,
			.col-xl-3,
			.col-xl-4,
			.col-xl-5,
			.col-xl-6,
			.col-xl-7,
			.col-xl-8,
			.col-xl-9,
			.col-xl-auto {
				padding-right: 5px;
				padding-left: 5px;
			}

			.col-2 {
				flex: 0 0 16.6666666667%;
				max-width: 16.6666666667%;
			}

			.col-8 {
				flex: 0 0 66.6666666667%;
				max-width: 66.6666666667%;
			}
			.col-7 {
				flex: 0 0 62.6666666667%;
				max-width: 62.6666666667%;
			}
			.col-1 {
				flex: 0 0 8.3333333333%;
				max-width: 8.3333333333%;
			}

			.col-3 {
				flex: 0 0 25%;
				max-width: 25%;
			}

			.col-6 {
				flex: 0 0 50%;
				max-width: 50%;
			}

			.col-4 {
				flex: 0 0 29.3333333333%;
				max-width: 29.3333333333%;
			}
			.mb-3,
			.my-3 {
				margin-bottom: 1rem !important;
			}
			.mb-1 {
				margin-bottom: 0.2rem !important;
			}
			* {
				font-family: Helvetica;
			}

			.bg-gray-100 {
				background: #f4f4f4 !important;
			}

			.container {
				max-width: 1140px;
				margin: auto;
			}

			.ml-5,
			.mx-5 {
				margin-left: 3rem !important;
				margin-right: 3rem;
			}

			.pt-2,
			.py-2 {
				padding-top: 0.5rem !important;
			}

			.d-flex {
				display: flex !important;
			}

			.text-center {
				text-align: center !important;
			}

			.justify-content-between {
				justify-content: space-between !important;
			}

			.justify-content-around {
				justify-content: space-around !important;
			}

			.flex-column {
				flex-direction: column;
			}

			.align-items-center {
				align-items: center;
			}

			.nav {
				background-color: #ebebeb;
				border-bottom: 1px solid #a7a7a7;
				padding: 1rem 0 1rem 1rem;
			}
			.hero-section {
				padding: 0.5rem 1rem;
				justify-content: space-around;
				align-items: center;
				flex-wrap: wrap;
			}

			.v-label {
				transform: rotate(342deg);
			}

			.hero-section ul li:first-child {
				padding-bottom: 10px;
			}
			.border {
				border: 2px solid #0c3b5c;
			}
			.border-bottom {
				border-bottom: 1px solid #0c3b5c;
			}
			.border-bottom-bold {
				border-bottom: 2px solid #0c3b5c;
			}
			.sm-font {
				font-size: 13px;
				color: #2d2d2d;
				font-weight: 600;
			}

			.sm-font-light {
				font-size: 13px;
				color: #2d2d2d;
				font-weight: lighter;
			}

			table {
				width: 100%;
			}

			.table,
			.th,
			.td {
				border: 2px solid #0c3b5c;
				border-collapse: collapse;
			}

			.th {
				color: #2d2d2d;
				font-weight: bold;
				padding: 10px;
			}

			.td {
				color: #2d2d2d;
				font-weight: lighter;
				font-size: 13px;
				padding: 11px;
				text-align: center;
			}

			ul {
				list-style: none;
			}

			.main-color {
				color: #0c3b5c;
			}
			.nav1 {
				justify-content: center;
				padding: 12px 0;
			}
			.font-bold {
				font-weight: 700 !important;
			}
			.justify-content-start {
				justify-content: flex-start !important;
			}
			.py-5 {
				padding-bottom: 2.5rem !important;
				padding-top: 2.5rem !important;
			}
			.py-3 {
				padding-bottom: 1.5rem !important;
				padding-top: 1.5rem !important;
			}
			.my-3 {
				margin-top: 1rem !important;
				margin-bottom: 1rem !important;
			}
			.my-1 {
				margin-top: 2rem !important;
				margin-bottom: 2rem !important;
			}
			.mx-3 {
				margin-left: 1rem !important;
				margin-right: 1rem !important;
			}
			.text-center {
				text-align: center;
			}
			.mt-2 {
				margin-top: 10px;
			}
			@page {
				size: A4;
				margin: 15px;
			}
			@media print {
				.container {
					width: 100% !important;
				}
			}
			.hotel-rooming {
				padding: 45px 0;
			}
			.hotel-name h5 {
				font-weight: 600;
				font-size: 20px;
				margin-bottom: 20px;
			}
			.hotel-name div {
				margin-bottom: 20px;
			}
			.hotel-name div p {
				font-weight: 600;
				font-size: 18px;
			}
			.room-type .hotel-name {
				padding: 0 20px;
			}

			.room-type-head td,
			.room-type td {
				font-weight: 600;
				font-size: 18px;
			}
			.room-type,
			.room-type-head {
				margin-top: 15px;
				padding: 20px;
			}
			.statistics{
				margin: 20px 0;
			}
			.statistics p{
				width: 30%;
			}
			
		</style>
		<div class="hotel-rooming" style="direction:[dir]">
		<div class="container">
			<div class="hotel-name">
				<h5 class="text-center">[hotelRoomingKey]</h5>
				<div class="text-center">
					<p>[tripKey]: [tripValue]</p>
					<p class="mt-2">[hotelKey]: [hotelValue]</p>
				</div>
			</div>
			<table class="table" style="width: 100%">
				<thead>
					<tr>
						<th class="th font-bold">[motamerNameKey]</th>
						<th class="th font-bold">[passportNumerKey]</th>
						<th class="th font-bold">[roomTypeKey]</th>
						<th class="th font-bold">[roomNumberKey]</th>
					</tr>
				</thead>
				<tbody>
				[allTravelers]
				
				</tbody>
			</table>
			<div class="d-flex statistics">
				<p>[motamersTotalKey]:[motamersTotalValue]</p>
				<p>[malesTotalKey]: [malesTotalValue]</p>
				<p>[femalesTotalKey]: [femalesTotalValue]</p>
			</div>
		</div>
	</div>
`;
