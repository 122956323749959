import { getAccommodationRoom, selectAndUpdateTravelerRoom } from "modules/products-builder/components/HotelAccommodation/helpers/updateHotelAccommodationState";
import React, { createContext, useContext, useReducer } from "react";
const ProductsBuilderContext = createContext();
const ProductsBuilderDispatchContext = createContext();

const initialState = {
	product_items: [],
	hotel_accommodation: [
	],
	hotel_accommodation_travelers: [],
	selected_hotel_accommodation_id: "",
	pricingWarning: {
		isOpen: false,
		segementName: null,
		actionType: null
	},
	activeTab: "1",
	product_price: {}
};



function productsBuilderReducer(state = initialState, action) {
	switch (action.type) {
		case "saveBasicDetails": {
			return { ...state, isEditMode: false, ...action.payload };
		}

		case "deleteProduct": {
			return { isEditMode: false, product_items: [], ...action.payload };
		}

		case "appendItem": {
			return {
				...state,
				isEditing: true,
				product_items: [...state.product_items, action.payload],
			};
		}

		case "deleteItem": {
			const items = [...state.product_items].filter(
				(item) => item.id !== action.payload.index
			);
			// const index = items.findIndex((item) => item.id === action.payload.index);
			// items.splice(index, 1);
			return {
				...state,
				isEditing: false,
				product_items: items,
			};
		}

		case "deleteItemHotel": {
			const items = [...state.product_items].filter((item) => {
				return item.item.name == "hotel" && item.id < action.payload.index;
			});
			// const index = items.findIndex((item) => item.id === action.payload.index);
			// items.splice(index, 1);
			
			return {
				...state,
				isEditing: false,
				product_items: items,
			};
		}

		case "appendInfo": {
			const items = [...state.product_items];


			state.product_items.map((productItem, index) => {
				
				if (
					productItem.item?.id == action.payload?.item?.id &&
					!productItem.hasOwnProperty("itemable") &&
					!state.product_items.filter((res) => res.id == action.payload.id)
						.length > 0
				) {
					items[index] = { ...items[index], ...action.payload };
				}

				if (
					productItem?.item?.id == action?.payload?.item?.id &&
					productItem.hasOwnProperty("itemable") &&
					productItem.id == action.payload.id
				) {
					items[index] = { ...items[index], ...action.payload };
				}
			});

			return {
				...state,
				isEditing: false,
				product_items: items,
			};
		}

		case "price": {
			return { ...state, price: action.payload, product_price: action.payload };
		}
		//////////////////////////////////////// Hotel Accommodation ////////////////////////////////////////
		case "hotelAccommodationData": {
			return {
				...state,
				hotel_accommodation: action.payload.hotels,
				hotel_accommodation_travelers: action.payload.travelers,
				selected_hotel_accommodation_id: action.payload.hotels[0].id,
			};
		}
		case "changeSelectedHotelAccommodation": {
			return { ...state, selected_hotel_accommodation_id: action.payload };
		}
		case "duplicatedHotelAccommodationData": {
			debugger
			return {
				...state,
				hotel_accommodation: action.payload.hotels,
				hotel_accommodation_travelers: action.payload.travelers,
				//selected_hotel_accommodation_id: action.payload.hotels[0].id,
			};
		}
		

		case "addTravelerToRoom": {
			
			const { travelerId, roomId } = action.payload;
			const hotelId = state.selected_hotel_accommodation_id;
			/// update traveler room
			const { updatedTravelers, selectedTraveler } = selectAndUpdateTravelerRoom({
				hotelId,
				travelers:state.hotel_accommodation_travelers,
				travelerId,
				value: roomId,
			});

			///// update hotel room

			const { updatedHotels, selectedRoom: hotelRoom } = getAccommodationRoom({
				hotels: state.hotel_accommodation,
				hotelId,
				roomId,
			});
			console.log("hotelRoomhotelRoomhotelRoom",hotelRoom);
			let roomTravelers = hotelRoom["travelers"] ?? [];
			hotelRoom["travelers"] = [...roomTravelers, selectedTraveler];

			return {
				...state,
				hotel_accommodation_travelers: updatedTravelers,
				hotel_accommodation: updatedHotels,
			};
		}

		case "deleteTravelerFromRoom": {
			const { travelerId, roomId } = action.payload;
			const hotelId = state.selected_hotel_accommodation_id;
			/// update traveler room
			const { updatedTravelers } = selectAndUpdateTravelerRoom({
				hotelId,
				travelers:state.hotel_accommodation_travelers,
				travelerId,
				value: null,
			});

			///// update hotel room
			const { updatedHotels, selectedRoom: hotelRoom } = getAccommodationRoom({
				hotels: state.hotel_accommodation,
				hotelId,
				roomId,
			});
			hotelRoom["travelers"] = hotelRoom["travelers"].filter(
				(traveler) => traveler.id !== travelerId
			);

			return {
				...state,
				hotel_accommodation_travelers: updatedTravelers,
				hotel_accommodation: updatedHotels,
			};
		}
		case "deleteAllTraveler": {
			const { roomId, roomTravelers } = action.payload;
			const hotelId = state.selected_hotel_accommodation_id;
			/// update traveler room

			let travelers;
			roomTravelers.forEach((traveler) => {
				
				const { updatedTravelers } = selectAndUpdateTravelerRoom({
					hotelId,
					travelers:state.hotel_accommodation_travelers,
					travelerId: traveler.id,
					value: null,
				});
				travelers = updatedTravelers;
			});

			const { updatedHotels, selectedRoom: hotelRoom } = getAccommodationRoom({
				hotels: state.hotel_accommodation,
				hotelId,
				roomId,
			});
			hotelRoom["travelers"] = [];

			return {
				...state,
				hotel_accommodation_travelers: travelers,
				hotel_accommodation: updatedHotels,
			};
		}
		case "updateRoomDetails": {
			const { roomId, value, name } = action.payload;
			const hotelId = state.selected_hotel_accommodation_id;
			/// update traveler room

			const { updatedHotels, selectedRoom: hotelRoom } = getAccommodationRoom({
				hotels: state.hotel_accommodation,
				hotelId,
				roomId,
			});
			hotelRoom[name] = value;

			return {
				...state,
				hotel_accommodation: updatedHotels,
			};
		}
		case "updateAllRoomMealType": {
			const { meal_name } = action.payload;
			const hotelId = state.selected_hotel_accommodation_id;
			/// update traveler room

			let cloneHotels = [...state.hotel_accommodation];
			const selectedHotels = cloneHotels.find((hotel) => hotel.id === hotelId);
			selectedHotels.accommodationRooms.forEach((room) => (room["meal_name"] = meal_name));

			return {
				...state,
				hotel_accommodation: cloneHotels,
			};
		}
		case "priceWarningModal" : {
			return {
				...state, 
				pricingWarning: {
					isOpen:  action.payload.isOpen,
					segementName: action.payload.segementName, 
					actionType: action.payload.actionType
				}				
			}
		}
		case "changeActiveTab" : {
			return {...state , activeTab: action.payload}
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useProductsBuilderState() {
	const context = useContext(ProductsBuilderContext);
	if (context === undefined) {
		throw new Error(
			"useProductsBuilder State must be used within a ProductsBuilder Provider"
		);
	}
	return context;
}

function useProductsBuilderDispatch() {
	const context = useContext(ProductsBuilderDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useProductsBuilderDispatch must be used within a ProductsBuilderProvider"
		);
	}
	return context;
}

function ProductsBuilderProvider({ children }) {
	const [state, dispatch] = useReducer(productsBuilderReducer, initialState);
	return (
		<ProductsBuilderContext.Provider value={state}>
			<ProductsBuilderDispatchContext.Provider value={dispatch}>
				{children}
			</ProductsBuilderDispatchContext.Provider>
		</ProductsBuilderContext.Provider>
	);
}

export {
	ProductsBuilderProvider,
	useProductsBuilderState,
	useProductsBuilderDispatch,
};
