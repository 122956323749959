
import React from "react";
import Locale from "translations";
import TransferDetailsCard from './BookingDetailsCard';

export default function TransferBookingDetails({ Details, printVoucher,setTransferDetails }) {
	const { dashboard, inventory } = Locale;

	const sortedItems =
		Details?.items &&
		Details?.items.sort((a, b) => {
			const groupA = a?.group_id.toUpperCase();
			const groupB = b?.group_id.toUpperCase();
			if (groupA < groupB) {
				return -1;
			}
			if (groupA > groupB) {
				return 1;
			}

			return 0;
		});
	return (
		<div className="col-md-8 transfer-booking-details">
			<div className="transfer-booking-details-header">
				<h2>{dashboard.messages.reservation}</h2>
				<span>
					{inventory.messages.refNo}: {Details?.reference}
				</span>
			</div>
			{/* map on cart items */}
			{Details?.items && Details?.items?.length > 0
				? sortedItems?.map((item) => (
						<TransferDetailsCard
							Details={item}
							allData={Details}
							printVoucher={printVoucher}
							setTransferDetails={setTransferDetails}
						/>
				  ))
				: "-"}

			{/* <TransferBookingDetailsCard /> */}
		</div>
	);
}
