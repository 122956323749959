import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import paymentVisaSrc from "assets/images/payment-visacard.png";
import paymentMastercardSrc from "assets/images/payment-mastercard.png";
import { ReactComponent as FacebookIcon } from "assets/images/icons/facebook-logo.svg";
import { ReactComponent as XIcon } from "assets/images/icons/x-logo.svg";
import { ReactComponent as InstagramIcon } from "assets/images/icons/instagram-logo.svg";
import { ReactComponent as LinkedinIcon } from "assets/images/icons/linkedin-logo.svg";
import { ReactComponent as YoutubeIcon } from "assets/images/icons/youtube-logo.svg";
import { ReactComponent as TiktokIcon } from "assets/images/icons/tiktok-logo.svg";
import { ReactComponent as SnapchatIcon } from "assets/images/icons/snapcaht-logo.svg";
import { ReactComponent as CopyRightsIcon } from "assets/images/icons/copyrights-icon.svg";
import { ReactComponent as LiveChatIcon } from "assets/images/live-chat-icon.svg";
// import { ReactComponent as HelpIcon } from "assets/images/help-footer-icon.svg";
import moment from 'moment';
import Locale from 'translations';
import { useSBSState } from 'context/global';

const socialPlatforms = [
  { icon: <FacebookIcon />, link: "https://www.facebook.com/Safasoftofficial", title: "Facebook" },
  { icon: <XIcon />, link: "https://x.com/safa_soft", title: "X" },
  { icon: <InstagramIcon />, link: "https://www.instagram.com/safasoftofficial/", title: "Instagram" },
  { icon: <LinkedinIcon />, link: "https://www.linkedin.com/company/safa-soft/", title: "Linkedin" },
  { icon: <YoutubeIcon />, link: "https://www.youtube.com/channel/UCjWsZOOhf6QAry-92yT8_Dg", title: "Youtube" },
  { icon: <TiktokIcon />, link: "https://www.tiktok.com/@safasoftofficial", title: "Tiktok" },
  { icon: <SnapchatIcon />, link: "https://www.snapchat.com/add/safa_soft1?share_id=SG8jTJIZNPo&locale=en-EG", title: "Snapchat" },
]


export default function AppFooter() {
  const { AppFooter: AppFooterLocale } = Locale;
  const { locale } = useSBSState();

  const currentYear = moment().format("YYYY");
  return (
    <footer className="app-footer">

      <div className="footer-container">
        {/* Terms and Conditions */}
        <div className="terms">
          {/* <Link to="" className="terms-link">Terms and Conditions</Link>
          <Link to="" className="terms-link">Help Center</Link> */}
          <Link to="/terms-and-conditions" className="btn terms-link">
            {AppFooterLocale.TermsandConditions}
          </Link>
          <Link to="/privacy-policy" className="btn terms-link">
            Privacy Policy
          </Link>
          <Link to="/refund-policy" className="btn terms-link">
            Refund & Cancelation Policy
          </Link>
          <Link to="/about-us" className="btn terms-link">
            About Us
          </Link>
          <Link to="/contact-us" className="btn terms-link">
            Contact Us
          </Link>
          {/* <a href="https://bit.ly/3yENkbJ" target="_blank" rel="noopener noreferrer" className="btn terms-link">
            {AppFooterLocale.HelpCenter}
          </a> */}
        </div>



        {/* social links */}
        <div className="social-platforms">
          {socialPlatforms.map(platform =>
            <a className="platform" href={platform.link} target="_blank" rel="noreferrer" key={platform.link} title={platform.title}>
              {platform.icon}
            </a>
          )}
        </div>
      </div>
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <p className="copyrights" dir={locale === 'ar' ? "ltr" : "rtl"}>
          {AppFooterLocale.Copyright}
          <CopyRightsIcon />
          {currentYear} {AppFooterLocale.AllRightsReserved}
        </p>
        {/* payment methods */}
        <div className="d-flex align-items-center gap-10">
          <img src={paymentVisaSrc} alt="pay with visa card" />
          <img src={paymentMastercardSrc} alt="pay with master card" />
        </div>
      </div>

      {/* help and live chat */}
      <div className="help-live-chat">

        {/* <HelpIcon /> */}
        <a href="https://bit.ly/3yENkbJ" target="_blank" rel="noopener noreferrer">
          <LiveChatIcon />
        </a>
      </div>
    </footer>
  )
}
