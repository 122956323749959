import React from "react";
import { useWebBuilderState } from "context/webBuilder";
import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import usePreviewMode from "hooks/usePreviewMode";
import ServiceCardThemeFive from './ServiceCard';

export default function OurServicesThemeFive({ updateOurServices }) {
	const { ourServices, selectedLanguage } = useWebBuilderState();
	const { isPreview } = usePreviewMode();

	let services = Array.isArray(ourServices?.services)
		? ourServices?.services
		: [];
	services = isPreview
		? services?.filter((service) => service.icon && service.visible)
		: services;

	return (
		<section className="wb-our-service" id="our-service">
			<div className="container">
				<ServicesContent
					header={ourServices?.title[selectedLanguage]}
					body={ourServices?.body[selectedLanguage]}
					changeHeader={(value) =>
						updateOurServices({
							name: "title",
							value,
							type: null,
							language: selectedLanguage,
						})
					}
					changebody={(value) =>
						updateOurServices({
							name: "body",
							value,
							type: null,
							language: selectedLanguage,
						})
					}
				/>

				<div className="service-cards-container">
					{services.map((service, index) => (
						<ServiceCardThemeFive
							type={service?.type}
							header={service?.header[selectedLanguage]}
							body={service?.body[selectedLanguage]}
							key={service?.type + index}
							visible={service?.visible}
							editCardDetails={(value, name) =>
								updateOurServices({
									name,
									value,
									type: service?.type,
									language: selectedLanguage,
								})
							}
						/>
					))}
				</div>
			</div>
		</section>
	);
}
