import axios from "axios";

const walletBackOfficeURL = process.env.REACT_APP_API_URL;

export const getPageBuilder = async () => {
	return await axios
		.get(`${walletBackOfficeURL}/v1/company-management/get-page-builder-config`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getPageBuilderByCompany = async (name) => {
	return await axios
		.get(`${walletBackOfficeURL}/v1/company-management/public-page/${name}`)
		.then((res) => res)
		.catch((err) => err.response);
};


export const setPageBuilder = async (data) => {
	return await axios
		.post(
			`${walletBackOfficeURL}/v1/company-management/set-page-builder-config`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const publishPublicPage = async (data) => {
	return await axios
		.post(
			`${walletBackOfficeURL}/v1/company-management/publish-public-page`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

// export const getProductsList = async ({ product_type_id, item_id,no_service_type }) => {
// 	return await axios
// 		.post(`${walletBackOfficeURL}/trip-management/products_list`, {
// 			version: "2",
// 			product_type_id,
// 			item_id,
// 			no_service_type
// 		})
// 		.then((res) => res)
// 		.catch((err) => err.response);
// };
export const getProductsList = async ({ product_type_id, item_id, no_service_type }) => {
	return await axios
		.post(`${walletBackOfficeURL}/trip-management/list-products-for-public`, {
			version: "2",
			product_type_id,
			item_id,
			no_service_type
		})
		.then((res) => res)
		.catch((err) => err.response);
};


export const getPackage = async (product_type_id) => {
	return await axios
		.get(`${walletBackOfficeURL}/v1/company-management/packages/${product_type_id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const sendRequestPackage = async (name, data) => {
	return await axios
		.post(`${walletBackOfficeURL}/v1/company-management/public-page/${name}/request-package`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const sendGetInTouch = async (name, data) => {
	return await axios
		.post(`${walletBackOfficeURL}/v1/company-management/public-page/${name}/get-in-touch`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const transalteText = async (data) => {
	return await axios
		.post(`${walletBackOfficeURL}/v1/translate`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateCompanyWhatsAppNumber = async (data) => {
	return await axios
		.post(`${walletBackOfficeURL}/v1/company-management/update-whatsapp-number`, data)
		.then((res) => res)
		.catch((err) => err.response);
};