import usePreviewMode from 'hooks/usePreviewMode';
import Carousel from 'react-multi-carousel';
import { ReactComponent as RightICon } from "assets/images/webBuilder/right.svg";
import { ReactComponent as LeftIcon } from "assets/images/webBuilder/left.svg";
import ThemeSevenTourCard from './TourCard';
import EditableParagraph from 'modules/WebBuilder-V2/shared/EditableParagraph';
import { AddElement } from 'modules/WebBuilder-V2/shared/AddElement';
import { useWebBuilderState } from 'context/webBuilder';
import Locale from 'translations';
import { useMemo } from 'react';


export default function ToursTheme7({
  items,
  sortToursItems,
  focusContainer,
  editItem,
  itemsBoxActions,
  type,
  openEditModal,
  updateTours,
  title,
  body,
  handleOpenAddModal,
}) {


  const { isPreview } = usePreviewMode();
  const { selectedLanguage } = useWebBuilderState();
  const { webBuilder } = Locale;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1200, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const CustomRightArrow = ({ onClick }) => (
    <button
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      onClick={onClick}
    >
      <RightICon />
    </button>
  );

  const CustomLeftArrow = ({ onClick }) => (
    <button
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      onClick={onClick}
    >
      <LeftIcon className="r-left-icon" />
    </button>
  );

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    return (
      <div className="carousel-button-group">
        <CustomLeftArrow onClick={() => previous()} />
        <CustomRightArrow onClick={() => next()} />
      </div>
    );
  };

  const groupedToursItems = useMemo(() => {
    return Array.from({ length: Math.ceil(items.length / 3) }, (_, i) => items.slice(i * 3, i * 3 + 3)) || []
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(items)])

  // const groupedToursItems = Array.from({ length: Math.ceil(items.length / 3) }, (_, i) => items.slice(i * 3, i * 3 + 3)) || [];

  return (
    <section id="tours-section">
      <div className={`theme_tours_section section_padding__md ${isPreview ? '' : 'edit_mode'}`}>
        <div className="row d-flex align-items-center">
          <div className="col-xl-3 col-lg-4 mb-4">
            <div className="section_heading">
              <div className="section_title">


                <EditableParagraph
                  className="heading"
                  contentEditable={true}
                  onInput={(value) => {
                    updateTours({ name: "title", value, language: selectedLanguage })
                  }}
                  text={title}
                />

                <EditableParagraph
                  className="text__content"
                  contentEditable={true}
                  onInput={(value) => {
                    updateTours({ name: "body", value, language: selectedLanguage })
                  }}
                  text={body}
                />

                <AddElement
                  className="mt-3"
                  onClick={handleOpenAddModal}
                  title={webBuilder.addMoreAttractions}
                />


                {/* <h4 className='heading'>Tours & Attractions</h4>
                <p className="text__content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                </p> */}
              </div>
            </div>
          </div>

          <div className="col-xl-9 col-lg-8">
            <div className="section_content">
              <Carousel
                responsive={responsive}
                slidesToSlide={1}
                keyBoardControl={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                customButtonGroup={<ButtonGroup />}
                renderButtonGroupOutside={true}
                showDots={true}
                arrows={false}
                infinite={false}
                draggable={false}
              >


                {groupedToursItems.map((group, groupIndex) => (
                  <div className="row row_wrapper px-lg-4 px-3" key={groupIndex}>

                    {group.map((props, tourItemIndex) => {
                      const isFirstItem = tourItemIndex === 0;

                      if (isFirstItem) {
                        return (
                          <div className={`col-md-6 ${isFirstItem ? 'mb-md-0 mb-4' : ''}`} key={props.id}>
                            <ThemeSevenTourCard
                              {...props}
                              index={tourItemIndex}
                              openEditModal={openEditModal}
                              sortToursItems={sortToursItems}
                              focusContainer={focusContainer}
                              editItem={editItem}
                              itemsBoxActions={itemsBoxActions}
                              type={type}
                              cardStyleType={isFirstItem ? "super" : undefined}
                            />
                          </div>
                        );
                      } else if (tourItemIndex === 1) {
                        return (
                          <div className="col-md-6" key={props.id}>
                            <ThemeSevenTourCard
                              {...props}
                              index={tourItemIndex}
                              openEditModal={openEditModal}
                              sortToursItems={sortToursItems}
                              focusContainer={focusContainer}
                              editItem={editItem}
                              itemsBoxActions={itemsBoxActions}
                              type={type}
                            />
                            {group[2] && (
                              <ThemeSevenTourCard
                                {...group[2]}
                                index={tourItemIndex + 1}
                                openEditModal={openEditModal}
                                sortToursItems={sortToursItems}
                                focusContainer={focusContainer}
                                editItem={editItem}
                                itemsBoxActions={itemsBoxActions}
                                type={type}
                              />
                            )}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                ))}

              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}