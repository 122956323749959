import { useSBSDispatch, useSBSState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
import { fetchImages } from "services/auth";
import { fetchUserProfile } from "services/profile";
import Locale from "translations";
import sitting from "../assets/images/setting.png";

export default function UserSettingsMobile(props) {
	const { messages, backOffice } = Locale;
	
	
	const SBSState= useSBSState();
	const { isAuth, companyName,allDataLogin }=SBSState
	const dispatch = useSBSDispatch();
	const history = useHistory();
	const [avatar, setAvatar] = useState("");
	const [userData, setUserData] = useState();
	const URI = process.env.REACT_APP_FILEUPLOAD_URL;

	useEffect(() => {
		if (isAuth) {
			async function fetchData() {
				const user = await fetchUserProfile();
				setUserData(user);
				dispatch({ type: "getAllDataLogin", payload: user });

				if (user.avatar) {
					// const fetchImage = await fetchImages(user.avatar);
					// if (fetchImage.status === 200) {
					// 	setAvatar(fetchImage);
					// }
					const fetchImage = `${URI}/fetch/${user.avatar}`;
					setAvatar(fetchImage);
				}
			}
			fetchData();
		}
	}, [isAuth]);
	return (
		<UncontrolledDropdown className="setting-dropdown setting-dropdown-mobile lang-btn">
			<DropdownToggle
				caret
				className="btn-light px-2 d-flex align-items-center "
				style={{ gap: "12px",padding:"1px" }}
			>
				<div className="d-flex align-items-center" style={{ gap: "4px" }}>
					{avatar ? (
						<img
							className="img-lang p-1"
							src={avatar ? avatar : sitting}
							width="40"
							alt="sitting"
						/>
					) : (
						<div className="letter-logo">
							{userData?.full_name?.[0] || companyName[0]}
						</div>
					)}

					{/* <div className="d-flex flex-column align-items-start mt-2">
						<span className="font-weight-bold" style={{ lineHeight: 0.8 }}>
						{companyName}
						</span>
						<span style={{ textTransform: "capitalize" }}>
							{backOffice.hello}
							{allDataLogin?.full_name?allDataLogin?.full_name.slice(0, 12):null}
						</span>
					</div> */}
				</div>
			</DropdownToggle>

			<DropdownMenu right>
				<Link to="/user-profile">
					<DropdownItem>{messages.userProfile}</DropdownItem>
				</Link>
				<ShowForPermission
					permission={["View-Company-Settings", "Manage-Company-Settings"]}
				>
					<Link to="/company-profile">
						<DropdownItem>{messages.companyProfile}</DropdownItem>
					</Link>
				</ShowForPermission>
				<Link to="/change-password">
					<DropdownItem>{backOffice.changePassword}</DropdownItem>
				</Link>
				<DropdownItem
					onClick={() => {
						dispatch({ type: "logout" });
						history.push("/auth/login");
					}}
				>
					<span>{messages.logout}</span>
				</DropdownItem>
			</DropdownMenu>
		</UncontrolledDropdown>
	);
}
