import React, { useEffect, useState } from 'react';
import PassengersSelection from './shared/PassengersSelection';
import ActionReason from './shared/ActionReason';
import ContactInformation from './shared/ContactInformation';
import FlightSideStep from './shared/SideSteps';
import { useSBSState } from 'context/global';
import validate, { isFormValid } from 'helpers/validate';
import Locale from 'translations';
// import RefundModal from './Modals/RefundModal';
import { flightRefundRequest, viewflight } from 'services/inventory';
import { useParams } from 'react-router-dom';
import { uploadFile } from 'services/auth';
import SuccessModal from 'modules/market-place/HotelResponse/Summary/successModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


export default function FlightRefund() {
  const { inventory, marketPlace } = Locale;
  const { allCountries } = useSBSState();
  const { id } = useParams();
  const history = useHistory();

  const countries = allCountries
    ? allCountries?.map((country) => {
      return { ...country, label: country?.name };
    })
    : null;

  const reasonsLookup = [
    { id: 1, name: "Voluntarily" },
    { id: 2, name: "Flight reschedule/ cancellation" },
    { id: 3, name: "Reissue due to medical reason" },
    { id: 4, name: "Reissue due to visa refusal" },
    { id: 5, name: "Others" },
  ]

  const [reservation, setReservation] = useState();
  const [passengersList, setPassengersList] = useState([]);

  const [reasonData, setReasonData] = useState({
    reason: null,
    certFiles: [],
    remarks: ""
  });

  const [contactInfoData, setContactInfoData] = useState({
    name: "",
    email: "",
    phoneCode: "",
    phone: "",
  });

  // const [isOpenRefundModal, setIsOpenRefundModal] = useState(false);
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const [errors, setErrors] = useState({});
  const [successModalOpen, setModalsuccessModal] = useState(false);
  // function toggleRefundModal() {
  //   setIsOpenRefundModal(!isOpenRefundModal);
  // }

  const validations = { ...reasonData, ...contactInfoData };

  function checkFormErrors() {
    let submitError = {};
    Object.keys(validations).forEach(key => {
      if (key === 'remarks') return;
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: validations[key] },
          {
            required: (key === 'certFiles' && reasonData.reason?.name === "Voluntarily") ? false : true,
            email: key === "email",
            min: key === "phone" ? 9 : "",
            max: key === "phone" ? 13 : ""
          }
        )
      }

    })
    setErrors(() => submitError);
  }

  function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  async function submitRefundRequest() {
    if (isFormValid(errors)) {
      let data = {
        flight_booking_id: reservation?.id,
        reason: reasonData?.reason?.name,
        certification_files: [],
        remarks: reasonData?.remarks,
        contact_info: {
          full_name: contactInfoData?.name,
          email: contactInfoData?.email,
          phone_code: contactInfoData?.phoneCode?.phone_code,
          phone: contactInfoData?.phone
        },
        passengers: passengersList.filter(passenger => passenger.selected)?.map(passenger => passenger.id)
      }

      if (reasonData?.reason?.name !== "Voluntarily") {
        let promises = [];
        // upload multiple files
        reasonData.certFiles?.forEach(async (file) => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("name", file.name);
          formData.append("bucket", "flightrequests");
          promises.push(uploadFile(formData).then((res) => res.data.data.uuid))
        });
        Promise.all(promises).then(async (resFiles) => {
          const refundRes = await flightRefundRequest({ ...data, certification_files: resFiles })
          if (refundRes?.status >= 200 && refundRes?.status < 300) {
            setModalsuccessModal(true);
          }
        });
      } else {
        const refundRes = await flightRefundRequest(data)
        if (refundRes?.status >= 200 && refundRes?.status < 300) {
          setModalsuccessModal(true);
        }
      }

    }
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      submitRefundRequest();
    }
  }, [isErrorLoaded]);

  useEffect(() => {
    const fetchInventory = async () => {
      const res = await viewflight(id);
      if (res.status === 200) {
        
        setReservation(res.data.data);
        setPassengersList(res?.data?.data?.passengers);
      }
    };
    fetchInventory();
    return () => { };
  }, []);

  return (
    <div className='container flight-actions'>
      <div className="row">
        <div className="col-12">
          {/* action title */}
          <h1 className="flight-actions-title">
            {inventory.messages.refund}
            <span>
              {inventory.messages.order} {reservation?.air_order}
            </span>
          </h1>

          <div className="col-md-9 col-12 p-0 pb-3">

            <div className='flight-action-content'>
              {/* passengers list */}
              <div className='d-flex position-relative my-2'>
                <FlightSideStep step={"1"} />
                <PassengersSelection
                  passengersList={passengersList}
                  setPassengersList={setPassengersList}
                />
              </div>
              {/* refund reason */}
              <div className='d-flex position-relative my-2'>
                <FlightSideStep step={"2"} />
                <ActionReason
                  reasonTitle={inventory.messages.refundReason}
                  reasonsLookup={reasonsLookup}
                  reasonData={reasonData}
                  setReasonData={setReasonData}
                  errors={errors}
                  setErrors={setErrors}
                  areaPlaceholder={inventory.messages.remarksRefundReason}
                />
              </div>
              {/* contact information */}
              <div className='d-flex position-relative my-2'>
                <FlightSideStep step={"3"} />
                <ContactInformation
                  countries={countries}
                  contactInfoData={contactInfoData}
                  setContactInfoData={setContactInfoData}
                  errors={errors}
                  setErrors={setErrors}
                />
              </div>
            </div>
            {/* submit button */}
            <div className='d-flex justify-content-end mx-md-4 mx-1'>
              <button
                className="btn flight-checkout-btn"
                onClick={submit}
                disabled={passengersList.filter(passenger => passenger.selected).length === 0}
              >
                {marketPlace.submit}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <RefundModal
        modalIsOpen={isOpenRefundModal}
        toggle={toggleRefundModal}
        selectedPassengers={passengersList.filter(passenger => passenger.selected)}
        reservationDetails={reservation}
        submitRefundRequest={submitRefundRequest}
      /> */}
      {successModalOpen &&
        <SuccessModal
          isOpen={successModalOpen}
          title={"Refund information confirmation"}
          msg={
            <div className='mt-2'>
              <p>Refund request is submitted</p>
              <p>Please contact customer service to verify refund price</p>
              <button className="flight-checkout-btn h6 w-100 mt-3 mb-0 border-0" onClick={() => history.push(`/inventory/flight/reservation/${id}/requests`)}>View Order</button>
            </div>
          }
        />
      }

    </div>
  )
}
