import { customContainerItems } from "./blockTypes";

const {
	customContainerMap,
	customContainerParagraph,
	customContainerPhoto,
	customContainerVideo,
	customContainerFaqs,
} = customContainerItems;

export const initialContainersData = {
	aboutUs: {
		type: "aboutUs",
		items: [
			{
				id: "about-us-media",
				mediaType: "video",
				mediaUrl: "",
			},
			{
				id: "about-us-content",
				title: "About us",
				head: "this is example",
				body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore",
				textAlign:"start"
			},
		],
		id: "about-us-section",
	},
	contactUs: {
		type: "contactUs",
		title: "Get Free consultation",
		body: "Need travel advice worldwide?",
		id: "contact-us-section",
	},
	customContainer: {
		type: "customContainer",
		title: "Custom container",
		body: "this is example for Custom container body",
		items: [],
	},
	customContainerFaqs: {
		type: "customContainer",
		title: "Custom container",
		body: "this is example for Custom container body",
		items: [
			{
				type: customContainerFaqs,
				faqs: [
					{
						question: "Question 1?",
						body: "Body 1.",
					},
				],
			},
		],
	},
	packages: {
		type: "packages",
		title: "packages",
		body: "this is example for packages body",
		items: [],
	},
};

/// items container types

export const initialCustomContainerItemsData = {
	[customContainerMap]: {
		type: customContainerMap,
		latLng: {
			lng: "",
			lat: "",
		},
	},
	[customContainerParagraph]: {
		type: customContainerParagraph,
		body: "Enter your text",
	},
	[customContainerPhoto]: {
		type: customContainerPhoto,
		url: "",
	},
	[customContainerVideo]: {
		type: customContainerVideo,
		url: "",
	},
	[customContainerFaqs]: {
		type: customContainerFaqs,
		faqs: [{ question: "what 1?", body: "text 1" }],
	},
};
