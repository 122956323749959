import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { historyList } from 'services/CRM';
import { getApplicationHistory } from "services/productbuilder";

export default function HistoryLogs() {
	const [historyState, setHistoryState] = useState([]);
	const { id } = useParams();

	useEffect(() => {
		async function getProductHistory() {
			const res = await 	historyList(id);
			if (res?.status >= 200 && res?.status < 300) {
				debugger
				setHistoryState(res?.data?.data);
			}
		}
		getProductHistory();
	}, []);

	return (
		<>
			<div className=" px-2">
				{historyState.length > 0
					? historyState.map((res) => {
							return (
								<div className="mb-3">
									<div className="head">
										<p className='d-flex align-items-center' style={{gap:'3px'}}>
											<i class="fas fa-info-circle fa-2x" style={{ color:'#D4A655'}}></i>
											<span className='mx-1 crm-details-text-primary' style={{color:'#707170'}}>{res.action}</span>
										</p>
										<p className='crm-details-text-secondary' style={{color:'#707170'}}>
											{" "}
											{res.created_at}
										</p>
									</div>
									<span className="descrip crm-details-date-history" style={{paddingLeft:'30px'}}>{res.causer_name} {" "} {res.action}</span>
								</div>
							);
					  })
					: ""}


			</div>
		</>
	);
}
