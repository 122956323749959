import React from 'react'
import Locale from 'translations'

export default function TDSVisaSummary({ visaData }) {
  const { tdsVisa, inventory, marketPlace } = Locale;
  return (
    <div className='col-4'>
      <div className='base-border p-3'>
        <h6 className='font-weight-bold mb-3'>{tdsVisa.calculatedPrice}</h6>

        <p className='d-flex justify-content-between my-2'>
          <span>{inventory.messages.packageName}</span>
          <span>{visaData?.package_plan?.range_from} - {visaData?.package_plan?.range_to}</span>
        </p>
        <p className='d-flex justify-content-between my-2'>
          <span>{tdsVisa.NoOfPax}</span>
          <span>{visaData?.pax || "0"}</span>
        </p>

        <hr />

        <p className='d-flex justify-content-between my-2'>
          <span>{marketPlace.total}</span>
          <span className='visa-total-price'>
            {visaData?.totalPrice || "0"} {visaData?.residence?.provider_currency}
          </span>
        </p>

      </div>
    </div>
  )
}
