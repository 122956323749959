export const getAccommodationRoom = ({
	hotels,
	hotelId,
	roomId,
	newArray = true,
}) => {
	let cloneHotels = newArray ? [...hotels] : hotels;
	const selectedHotels = cloneHotels.find((hotel) => hotel.id === hotelId);
	const selectedRoom = selectedHotels.accommodationRooms.find(
		(room) => room.id === roomId
	);

	return { updatedHotels: cloneHotels, selectedRoom, selectedHotels };
};

export const selectAndUpdateTravelerRoom = ({
	travelers,
	travelerId,
	hotelId,
	value,
	newArray = true,
}) => {
	debugger
	const cloneTravelers = newArray ? [...travelers] : travelers;
	const selectedTraveler = cloneTravelers.find(
		(traveler) => traveler.id === travelerId
	);
	const selectedRoom = selectedTraveler.roomAvailabilities.find(
		(room) => room.product_hotel_id === hotelId
	);
	if (value || value === null) selectedRoom.roomId = value;
	return { updatedTravelers: cloneTravelers, selectedTraveler };
};
