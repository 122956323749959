import { ReactComponent as InfoIcon } from "assets/images/subscription-plan/info.svg"
// import { ReactComponent as TripManagementIcon } from "assets/images/subscription-plan/trip-management.svg"
import { ReactComponent as RightIcon } from "assets/images/subscription-plan/right-icon.svg"
import { useSBSState } from "context/global"
import { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { fetchsubscriptionPackage } from "services/subscription"
import { HexagonIconWrapper } from "./SubscriptionPlanDetails"
import useNavigationBar from "layouts/useNavigationBar"
import moment from 'moment'
import Locale from 'translations'


export default function SubscriptionPlanV2() {
  const {subscriptionModule} = Locale
  const { dynamicNav } = useSBSState();
  const { dynamicModulesRoutes, tabsIcons } = useNavigationBar();
  const history = useHistory()
  // ** states 
  const [plansState, setPlansState] = useState({
    // subscribedPlan: null,
    subscriptionPackages: [],
  });

  // ** logic functions
  useEffect(() => {
    async function getPackagesAndActivePlan() {
      let [packagesList] = await Promise.all([fetchsubscriptionPackage()]);
      setPlansState({ subscriptionPackages: packagesList?.data?.data })
    }
    getPackagesAndActivePlan();
  }, []);


  return (
    <div className="subscription-plan-v2">
      <div className="active-plan">

        <div className="active-plan-title">
          <h1 className="title">{subscriptionModule?.BasicPlan} </h1>
          <span className="plan-status">{subscriptionModule?.Active}</span>
        </div>

        <div className="active-plan-content">
          {dynamicNav?.filter(navItem => navItem?.isStatic && navItem?.is_subscribed).map(navItem => {
            return (
              <div className="active-plan-item">
                <h2 className="title">{navItem.name}</h2>
                <div className="content">
                  {navItem?.modules?.map(tabModule => {
                    return (
                      <div className="d-flex gap-10">
                        <RightIcon />
                        <p className="item-title">{tabModule.name}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="subscription-plans">
        {plansState.subscriptionPackages.map(packagePlan => {
          return (
            <div className="plan">
              <div className="plan-info">
                <h2 className="plan-title">{packagePlan?.title}</h2>
                <p className="text-muted mt-2">{packagePlan?.description}</p>
                <div className="d-flex align-items-center">
                  <p className="plan-price">{packagePlan?.total_price} {packagePlan?.currency}</p>
                  {packagePlan?.apply_discount > 0 &&
                    <p className="old-price text-muted">
                      {packagePlan?.price} {packagePlan?.currency}
                    </p>
                  }
                  <p className="mx-2 text-muted">
                    {" "} / {packagePlan?.duration} {subscriptionModule?.Days}
                  </p>
                </div>
              </div>

              <div className="plan-content">
                {packagePlan?.modules?.filter(module => module?.status === "active")?.map(module => {
                  return (
                    <div className="plan-content-item">
                      <div className="d-flex align-items-center gap-10">
                        {
                          dynamicModulesRoutes?.[module?.key]?.icon
                          ||
                          <HexagonIconWrapper icon={tabsIcons?.[packagePlan.category]} />
                        }
                        <p>{module?.name}</p>
                      </div>
                      {/* {plansState.subscribedPlan.modules?.find(subscribedModule => subscribedModule?.status === "active" && subscribedModule?.name === module.name) &&
                        <span className="plan-status">Subscribed</span>
                      } */}
                    </div>
                  )
                })}
              </div>
              <hr />
              {packagePlan?.is_subscribed ?
                <p className="btn subscribed-btn">
                  {subscriptionModule?.subscribed}
                </p>
                :
                <>
                  <p className="text-center">
                    <span>{subscriptionModule?.ValidTo} </span>
                    <span className="mx-2 font-weight-bold">{moment(packagePlan?.end_date).format("DD/MM/YYYY")}</span>
                  </p>
                  <button className="btn subscribe-btn"
                    onClick={() => history.push({ pathname: '/subscription/checkout', selectedBundle: packagePlan })}
                  >
                    {subscriptionModule?.Subscribe}
                  </button>
                </>
              }

              <div className="info">
                <InfoIcon />
                <Link to={`/plan-details/${packagePlan?.category}`} className="info-text">{subscriptionModule?.SeeAllFeatures}</Link>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}