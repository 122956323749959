import {
	useMarketplaceState,
} from "context/marketplace";
import React from "react";
import Locale from "translations";
import PassengerDetails from "./PassengerDetails";
import TransferBookingDetailsCard from './BookingDetailsCard';

function TransferReservationDetails({
	passengersData,
	setPassengersData,
	errors,
	setErrors,
	passengerType,
	setPassengerType,
}) {
	const { dashboard } = Locale;

	const { transferCart } = useMarketplaceState();

	const cartData = transferCart?.cart;
	const cartLength = cartData?.cartItems?.length;
	return (
		<div className="col-md-8 transfer-booking-details">
			<div className="transfer-booking-details-header">
				<h2>{dashboard.messages.reservation}</h2>
			</div>
			{/* map on cart items */}
			{cartLength > 0 &&
				cartData?.cartItems.map((transfer) => (
					<div className="bg-white">
						<TransferBookingDetailsCard  Details={transfer}  allData={cartData} />
					</div>
				))}

			<PassengerDetails
				passengersData={passengersData}
				setPassengersData={setPassengersData}
				errors={errors}
				setErrors={setErrors}
				passengerType={passengerType}
				setPassengerType={setPassengerType}
			/>
		</div>
	);
}

export default TransferReservationDetails;
